import React, { useState, useEffect } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import logo from '../../../assets/icons/logo_black.svg'
import { useNavigate } from "react-router-dom";
import userLogo from '../../../assets/users/user-4.png'
import crossLogo from '../../../assets/icons/CrossInCircle.svg'
import './AccountSelection.css'
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../../Layout/Loader/Loader";
import { retriveLoginAccounts } from "../../../actions/switchAccount/switchAccountAction";
const AccountSelection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const url = process.env.REACT_APP_URL;
    const { userprofileimage } = useSelector((state) => state.getavatar)
    const { userData } = useSelector((state) => state.activeUser)
    const [avatarimage, setAvatarimage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const frontendURL = window.location.origin;
    const [localStorageValues, setLocalStorageValues] = useState([]);
    const fetchAllLocalStorageValues = async () => {
        const localStorageKeys = Object.keys(localStorage);
        const filteredKeys = localStorageKeys.filter((key) =>
            key.startsWith("MemberID")
        );
        const values = filteredKeys.map((key) => localStorage.getItem(key));
        //API call to fetch account
        fetchData(values);
        // setLocalStorageValues(response.userData)
    };
    useEffect(() => {
        setAvatarimage(userprofileimage);
    }, [userprofileimage]);
        const fetchData = async (values) => {
            try {
                if(userData){
                    setLocalStorageValues(userData.userData);
                    setIsLoading(false)
                }else{
                    dispatch(retriveLoginAccounts(values))
                    setIsLoading(true)
                }
            } catch (error) {
            }
        };
    const switchAccount = async (id) => {
        const userID = id;
        try {
            const { data } = await axios.post(
                `${url}/accounts/switch`,
                { userID },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            if (data.success) {
                window.location.reload()
                window.location.href = `/dashboard`;
            } else {
                //show error
            }
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchAllLocalStorageValues();
    }, [userData]);
    useEffect(() => { }, [localStorageValues]);
    return (
        <div className="forgot_password_container" style={{ height: '100%', minHeight: '100vh', backgroundColor: 'white' }}>
            <div className="forgot_password_left" style={{ justifyContent: 'unset', height: '100%', minHeight: '100vh' }} >
                <div className="" style={{ marginRight: 'auto', marginTop: '20px' }}>
                    <img style={{ cursor: "pointer" }} onClick={() => { navigate('/dashboard') }} src={logo} alt="" srcset="" />
                </div>
                <h3 className="mb-5 text-white" style={{ marginTop: '50px' }}>Choose or add another account</h3>
                {isLoading ? <Loader /> :
                    <div>
                        <div className="text-white pt-1 ps-4 pe-5 custom-scrollbar" style={{ maxHeight: '322px', overflowY: 'auto' }}>
                            {localStorageValues &&
                                localStorageValues.map((val, i) => {
                                    const fullName = `${val.first_name} ${val.last_name}`;
                                    const words = fullName.split(" ");
                                    const initialss = words.map((word) => word.charAt(0).toUpperCase());
                                    // 
                                    let initials;
                                    if (initialss?.length >= 2) {
                                        initials = initialss.slice(0, 2).join("");
                                    } else {
                                        initials = initialss.join("");
                                    }
                                    const isDisabled = val.isCurrentUser;
                                    return (
                                        <div className="d-flex align-items-center mb-3 pb-3 border-bottom"
                                            key={i}
                                            onClick={() => {
                                                if (!isDisabled) {
                                                    switchAccount(val._id);
                                                }
                                            }}
                                            role="button"
                                            style={{ opacity: isDisabled ? '0.5' : '1' }}
                                        >
                                            <div className="user-info-avatar-container_OTC_Admin">
                                                {val?.avatar ? (
                                                    <img
                                                        src={val?.avatarurl}
                                                        alt="Profile"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius: "100%",
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        className="user-info-initials-overlay_OTC_Admin"
                                                        style={{ width: "40px", height: "40px" }}
                                                    >
                                                        {initials}
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <p className='mb-0' style={{ textTransform: "capitalize", fontSize: '16px' }}>{fullName}</p>
                                                <p className="mb-0 column1_text_OTC_Admin">{val.email}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                        <div className="text-white ps-4 pe-5">
                            <div className="d-flex align-items-center mb-3 pb-3 border-bottom" role="button" onClick={() => window.location.href = `${frontendURL}/login`}>
                                <div className="user-info-avatar-container_OTC_Admin">
                                    <img
                                        src={userLogo}
                                        alt="Profile"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "100%",
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className='mb-0' style={{ fontSize: '16px' }}>Add another account</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3 pb-3 border-bottom" role="button" onClick={() => navigate('/remove-account')}>
                                <div className="user-info-avatar-container_OTC_Admin">
                                    <img
                                        src={crossLogo}
                                        alt="Profile"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "100%",
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className='mb-0' style={{ fontSize: '16px' }}>Remove accounts from this browser</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="forgot_password_right" style={{ height: '100%', minHeight: '100vh' }}>
                <h3>Meet Kimba</h3>
                <img src={phoneImg} alt="phoneImg" />
            </div>
        </div>
    );
};
export default AccountSelection;
