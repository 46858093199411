import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Otc_UserHeaderWithIcons = (props) => {
    const [cardInfoTab, setCardInfoTab] = useState("Users");
    const { tab } = props;

    useEffect(() => {
        setCardInfoTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCardInfoTab(value);
        props.onChange(value);
    };

    return (
        <>
            <div className="onetap_connect_mycard_header_OTC_Admin">
                <div
                    onClick={() => handleClickOfTab("Users")}
                    className={
                        cardInfoTab === "Users"
                            ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                            : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
                    }
                >
                    <div
                        style={{
                            backgroundColor: cardInfoTab === "Users" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="9" fill="#E65925" />
                            <path d="M17 10.5C17.9283 10.5 18.8185 10.8687 19.4749 11.5251C20.1313 12.1815 20.5 13.0717 20.5 14C20.5 14.9283 20.1313 15.8185 19.4749 16.4749C18.8185 17.1313 17.9283 17.5 17 17.5C16.0717 17.5 15.1815 17.1313 14.5251 16.4749C13.8687 15.8185 13.5 14.9283 13.5 14C13.5 13.0717 13.8687 12.1815 14.5251 11.5251C15.1815 10.8687 16.0717 10.5 17 10.5ZM10 13C10.56 13 11.08 13.15 11.53 13.42C11.38 14.85 11.8 16.27 12.66 17.38C12.16 18.34 11.16 19 10 19C9.20435 19 8.44129 18.6839 7.87868 18.1213C7.31607 17.5587 7 16.7956 7 16C7 15.2044 7.31607 14.4413 7.87868 13.8787C8.44129 13.3161 9.20435 13 10 13ZM24 13C24.7956 13 25.5587 13.3161 26.1213 13.8787C26.6839 14.4413 27 15.2044 27 16C27 16.7956 26.6839 17.5587 26.1213 18.1213C25.5587 18.6839 24.7956 19 24 19C22.84 19 21.84 18.34 21.34 17.38C22.2119 16.2544 22.6166 14.8362 22.47 13.42C22.92 13.15 23.44 13 24 13ZM10.5 23.25C10.5 21.18 13.41 19.5 17 19.5C20.59 19.5 23.5 21.18 23.5 23.25V25H10.5V23.25ZM5 25V23.5C5 22.11 6.89 20.94 9.45 20.6C8.86 21.28 8.5 22.22 8.5 23.25V25H5ZM29 25H25.5V23.25C25.5 22.22 25.14 21.28 24.55 20.6C27.11 20.94 29 22.11 29 23.5V25Z" fill="white" />
                        </svg>
                    </div>
                    <p
                        className={
                            cardInfoTab === "Users"
                                ? "onetap_connect_mycard_active_header_OTC_Admin"
                                : "onetap_connect_mycard_headers_OTC_Admin"
                        }
                    >
                        Users
                    </p>
                </div>
                <div className="onetap_conn_user_header_right_OTC_Admin">
                    <div className="onetap_conn_user_header_allusers_OTC_Admin">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M1 21L12 2L23 21H1ZM4.45 19H19.55L12 6L4.45 19ZM12 18C12.2833 18 12.521 17.904 12.713 17.712C12.905 17.52 13.0007 17.2827 13 17C13 16.7167 12.904 16.479 12.712 16.287C12.52 16.095 12.2827 15.9993 12 16C11.7167 16 11.479 16.096 11.287 16.288C11.095 16.48 10.9993 16.7173 11 17C11 17.2833 11.096 17.521 11.288 17.713C11.48 17.905 11.7173 18.0007 12 18ZM11 15H13V10H11V15Z" fill="#333333" />
                            </svg>
                        </div>
                        <div>
                            Flagged
                        </div>
                    </div>
                    <div className={`onetap_conn_user_header_addusers_OTC_Admin`}>
                        {/* <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect width="34" height="34" rx="9" fill="#E65925" />
                                <path
                                    d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div>Add New Client</div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Otc_UserHeaderWithIcons;
