import axios from "axios";
const url = process.env.REACT_APP_URL;
const jsonconfig = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const formDataconfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
};
export const signUpHandler = (email) => async (dispatch) => {
  try {
    dispatch({ type: "SIGN_UP1_REQUEST" });
    const { data } = await axios.post(
      `${url}/register`,
      { email },
      jsonconfig
    );
    dispatch({ type: "SIGN_UP1_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "SIGN_UP1_FAILURE", payload: error.message });
  }
};
export const signUpHandler2 = (signupData, token, showFailedToastMessage, showToastMessage) => async (dispatch) => {
  try {
    dispatch({ type: "SIGN_UP1_REQUEST" });
    const { data } = await axios.post(
      `${url}/register/step-2/${token}`,
      { signupData },
      jsonconfig
    );
    const dynamicKeyName = data.user._id.slice(-8);
    localStorage.setItem(`MemberID_${dynamicKeyName}`, data.encryptedUserID);
    window.location.href = `/`;
    dispatch({ type: "SIGN_UP1_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "SIGN_UP1_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const googleLogin = (token) => async (dispatch) => {
  try {
    dispatch({ type: "LOGIN_REQUEST" });
    const { data } = await axios.post(
      `${url}/google-login`,
      { token },
      jsonconfig
    );
    dispatch(getCompanyProfileDetails());
    dispatch({ type: "LOGIN_SUCCESS", payload: data });
    window.location.href = `/dashboard`;
    const dynamicKeyName = data.user._id.slice(-8);
    localStorage.setItem(`MemberID_${dynamicKeyName}`, data.encryptedUserID);
  } catch (error) {
    dispatch({ type: "LOGIN_FAILURE", payload: error.response.data.message });
  }
};
export const googleSignUp = (token) => async (dispatch) => {
  const { data } = await axios.post(
    `${url}/google-sign-up`,
    { token },
    jsonconfig
  );
};
export const loginHandler = (email, password, showToastMessage, showFailedToastMessage) => async (dispatch) => {
  try {
    dispatch({ type: "LOGIN_REQUEST" });
    const { data } = await axios.post(
      `${url}/login`,
      { email, password },
      jsonconfig
    );
    setTimeout(() => {
      dispatch(fetchUserInformationDetails());
      dispatch(fetchCart(data.user));
    }, 2000);

    showToastMessage();

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
    const dynamicKeyName = data.user._id.slice(-8);
    localStorage.setItem(`MemberID_${dynamicKeyName}`, data.encryptedUserID);
    dispatch(getCompanyProfileDetails());
    dispatch(fetchUserInformationDetails());
    dispatch(loadUser());
    dispatch({ type: "LOGIN_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "LOGIN_FAILURE", payload: error.response.data.message });
  }
};
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: "LOAD_USER_REQUEST" });
    const { data } = await axios.get(`${url}/profile`, {
      withCredentials: true,
    });
    const userid = data.user._id;
    if (data.user.role === 'teammember' && data.user.status === 'Deactivate') {
      dispatch(logout(userid));
    }
    if (data.user.role !== 'superadmin' && data.user.status === 'Deactivate' && data.user.Account_status === 'is_Deactivated') {
      dispatch(logout(userid));
    }
    dispatch({ type: "LOAD_USER_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "LOAD_USER_FAIL", payload: error });
  }
};
export const checkouthandler = (userdata, id) => async (dispatch) => {
  try {
    dispatch({ type: "CHECKOUT_REQUEST" });
    const { data } = await axios.post(
      `${url}/checkout/${id}`,
      { userdata },
      {
        withCredentials: true,
      }
    );
    dispatch({ type: "CHECKOUT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "LOAD_USER_FAIL", payload: error });
  }
};
export const logout = (userid) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${url}/logout`,
      { userid },
      {
        withCredentials: true,
      }
    );
    if (data.success) {
      const memberIdKey = `MemberID_${userid.slice(-8)}`;
      localStorage.removeItem(memberIdKey);
      window.location.reload();
      window.location.replace(`/login`);
    }
    dispatch({ type: "LOGOUT_SUCCESS" });
  } catch (error) {
    dispatch({ type: "LOGOUT_FAIL", payload: error });
  }
};
export const getTeamMember = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USERS_REQUEST" });
    const { data } = await axios.get(`${url}/users`, {
      withCredentials: true,
    });
    dispatch({ type: "GET_USERS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_USERS_FAILURE", payload: error });
  }
};
export const getinvitedTeamMember = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_INVITEDUSERS_REQUEST" });
    const { data } = await axios.get(`${url}/invitedusers`, {
      withCredentials: true,
    });
    dispatch({ type: "GET_INVITEDUSERS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_INVITEDUSERS_FAILURE", payload: error });
  }
};
export const getProducts = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_PRODUCTS_REQUEST" });
    const { data } = await axios.get(`${url}/products`);
    dispatch({ type: "GET_PRODUCTS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_PRODUCTS_FAILURE", payload: error });
  }
};
export const deleteTeamMember = (selectedUsers) => async (dispatch) => {
  try {
    dispatch({ type: "DELETE_TEAMMEMBER_REQUEST" });
    const { data } = await axios.post(
      `${url}/delete/team-member`,
      { selectedUsers },
      {
        withCredentials: true,
      }
    );
    dispatch({ type: "DELETE_TEAMMEMBER_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "DELETE_TEAMMEMBER_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const SingleTeamMemberDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TEAMEMBER_DETAILS_REQUEST" });
    const { data } = await axios.get(`${url}/user/${id}`, jsonconfig);
    dispatch({ type: "GET_TEAMEMBER_DETAILS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_TEAMEMBER_DETAILS_FAILURE", payload: error });
  }
};
export const updateTeamMemberDetails = (id, userDetails, selectedUserForRedirect) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_TEAM_MEMBER_DETAILS_REQUEST" });
    const { data } = await axios.post(
      `${url}/user/update/${id}`,
      { userDetails, selectedUserForRedirect },
      jsonconfig
    );
    dispatch({ type: "UPDATE_TEAM_MEMBER_DETAILS_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "UPDATE_TEAM_MEMBER_DETAILS_FAILURE",
      payload: error.response.data.message,
    });
    return false;
  }
};
export const getUserInformation = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USER_INFORMATION_REQUEST" });
    const { data } = await axios.get(`${url}/get-user-information/${id}`, jsonconfig);
    dispatch({ type: "GET_USER_INFORMATION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_USER_INFORMATION_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const updateUserInformation = (id, userInformation) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_INFORMATION_REQUEST" });
    const { data } = await axios.post(
      `${url}/update-user-information/${id}`,
      { info: userInformation },
      jsonconfig
    );
    dispatch({ type: "UPDATE_USER_INFORMATION_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_INFORMATION_FAILURE",
      payload: error.response.data.message,
    });
    return false;
  }
};
export const getCompanyProfileDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_COMPANY_DETAILS_REQUEST" });
    const { data } = await axios.get(`${url}/company/profile`, {
      withCredentials: true,
    });
    dispatch({ type: "GET_COMPANY_DETAILS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_COMPANY_DETAILS_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const getcompanies_share_referral_data = () => async (dispatch) => {
  try {
    dispatch({ type: "getcompanies_share_referral_data_REQUEST" });
    const { data } = await axios.get(`${url}/company_share_referreldata`, {
      withCredentials: true,
    });
    dispatch({
      type: "getcompanies_share_referral_data_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getcompanies_share_referral_data_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const updatecompany_referral_data = (companyDetails) => async (dispatch) => {
  try {
    dispatch({ type: "updatecompany_referral_data_REQUEST" });
    const { data } = await axios.put(
      `${url}/updatecompany_referral_data`,
      { updateValues: companyDetails },
      jsonconfig
    );
    dispatch({ type: "updatecompany_referral_data_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "updatecompany_referral_data_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const updateCompanyDetails = (companyDetails) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_COMPANY_REQUEST" });
    const { data } = await axios.put(
      `${url}/updatePointOfContact`,
      { companyDetails },
      jsonconfig
    );
    dispatch({ type: "UPDATE_COMPANY_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "UPDATE_COMPANY_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: "FORGOT_PASSWORD_REQUEST" });
    const { data } = await axios.post(
      `${url}/forgot/password`,
      { email },
      jsonconfig
    );
    dispatch({ type: "FORGOT_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FORGOT_PASSWORD_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const resetPassword = (password, token) => async (dispatch) => {
  try {
    dispatch({ type: "RESET_PASSWORD_REQUEST" });
    const { data } = await axios.put(
      `${url}/reset/password/${token}`,
      { password },
      jsonconfig
    );
    dispatch({ type: "RESET_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "RESET_PASSWORD_FAILURE",
      payload: error.response.data.message,
    });
  }
};
export const updateBillingAddress = (firstName, lastName, billing_address, companyName) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_BILLING_ADDRESS_REQUEST" });
    const { data } = await axios.post(
      `${url}/update/billingAddress`,
      { firstName, lastName, billing_address, companyName },
      jsonconfig
    );
    dispatch({ type: "UPDATE_USER_BILLING_ADDRESS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_BILLING_ADDRESS_FAILED",
      payload: error,
    });
  }
};
export const updateCompanyDetailsInfo = (updatedCompanyDetails) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_COMPANY_DETAILS_INFO_REQUEST" });
    const { data } = await axios.post(
      `${url}/company/update/information`,
      { updatedCompanyDetails },
      jsonconfig
    );
    dispatch({ type: "UPDATE_COMPANY_DETAILS_INFO_SUCCESS", payload: data });
    return true;
  } catch (error) {
    dispatch({
      type: "UPDATE_COMPANY_DETAILS_INFO_FAILURE",
      payload: error.response.data.message,
    });
    return false;
  }
};
export const uploadProfilePicture = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_PROFILE_PICTURE_REQUEST" });
    const { data } = await axios.post(
      `${url}/upload-profile-picture/${id}`,
      formData,
      formDataconfig
    );
    dispatch({ type: "UPLOAD_PROFILE_PICTURE_SUCCESS", payload: data });
    return { success: true, data, imagePath: data.imagePath };
  } catch (error) {
    dispatch({
      type: "UPLOAD_PROFILE_PICTURE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.error ===
      "Profile picture size must be at most 300x300 pixels.."
    ) {
      return {
        ProfilepicSizeValidationError:
          "Profile picture size must be at most 300x300 pixels.",
      };
    }
    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};
export const uploadLogoPicture = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_LOGO_REQUEST" });
    const { data } = await axios.post(
      `${url}/uploadlogo`,
      formData,
      formDataconfig
    );
    dispatch({ type: "UPLOAD_LOGO_SUCCESS", payload: data });
    return { success: true, data, logoimagePath: data.imagePath };
  } catch (error) {
    dispatch({
      type: "UPLOAD_LOGO_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};
export const uploadFaviconPicture = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_FAVICON_REQUEST" });
    const { data } = await axios.post(
      `${url}/uploadfavicon`,
      formData,
      formDataconfig
    );
    dispatch({
      type: "UPLOAD_FAVICON_SUCCESS",
      payload: data,
      faviconpath: data.imagePath,
    });
    return { success: true, data };
  } catch (error) {
    dispatch({
      type: "UPLOAD_FAVICON_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    if (
      error.response &&
      error.response.data.error ===
      "Favicon size must be between 32x32 and 64x64 pixels."
    ) {
      return {
        faviconSizeValidationError:
          "Favicon size must be between 32x32 and 64x64 pixels.",
      };
    }
    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};
export const getAllShippingHandler = () => async (dispatch) => {
  try {
    dispatch({ type: "SHIPPINGADDRESSES_REQUEST" });
    const { data } = await axios.get(`${url}/user/all/shippingAddresses`, jsonconfig);
    dispatch({ type: "SHIPPINGADDRESSES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "SHIPPINGADDRESSES_FAILURE", payload: error.message });
  }
};
export const addShippingHandler = (shippingAddressData) => async (dispatch) => {
  try {
    dispatch({ type: "CREATE_SHIPPING_ADDRESS_REQUEST" });
    const { data } = await axios.post(
      `${url}/user/shippingAddress/add`,
      shippingAddressData,
      jsonconfig
    );
    dispatch({ type: "CREATE_SHIPPING_ADDRESS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "CREATE_SHIPPING_ADDRESS_FAILURE",
      payload: error.message,
    });
  }
};
export const fetchBillingAddressDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_BILLING_ADDRESS_REQUEST" });
    const { data } = await axios.get(`${url}/fetchbillingAddress`, jsonconfig);
    dispatch({ type: "FETCH_BILLING_ADDRESS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_BILLING_ADDRESS_FAILED", payload: error });
  }
};
export const fetchUserInformationDetails = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_USER_INFORMATION_REQUEST" });
    const { data } = await axios.get(`${url}/userInformation`, jsonconfig);
    dispatch({ type: "FETCH_USER_INFORMATION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_USER_INFORMATION_FAILURE", payload: error });
  }
};
export const getTeamName = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TEAMNAME_REQUEST" });
    const { data } = await axios.post(
      `${url}/user/teamdata`,
      {},
      {
        withCredentials: true,
      }
    );
    dispatch({ type: "GET_TEAMNAME_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_TEAMNAME_FAILURE", payload: error });
  }
};
export const fetchCart = (user) => async (dispatch) => {
  if(user){
    const _id = user?._id
    try {
    dispatch({ type: "FETCH_CART_REQUEST" });
    const { data } = await axios.post(
      `${url}/product/cart`,
      { _id },
      jsonconfig
    );
    dispatch({ type: "FETCH_CART_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_CART_FAILURE", payload: error.message });
  }
}
};
export const getProfileImage = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_PROFILE_IMAGE_REQUEST" });
    const { data } = await axios.get(`${url}/getProfileimages`, {
      withCredentials: true,
    });
    const userid = data.Avataruser?._id;
    if (data.Avataruser.role === 'teammember' && data.Avataruser.status === 'Deactivate') {
      dispatch(logout(userid));
    }
    if (data.Avataruser.role !== 'superadmin' && data.Avataruser.status === 'Deactivate' && data.Avataruser.Account_status === 'is_Deactivated') {
      dispatch(logout(userid));
    }
    dispatch({
      type: "GET_PROFILE_IMAGE_SUCCESS",
      payload: data.Avataruser.avatar,
    });
  } catch (error) {
    dispatch({ type: "GET_PROFILE_IMAGE_FAILURE", payload: error });
  }
};
export const checkUserSubscription = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${url}/payment/isactive`, jsonconfig);
    dispatch({ type: "FETCH_SUBSCRIPTION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_SUBSCRIPTION_FAILURE", payload: error.message });
  }
};
export const fetchSavedCards = (id) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${url}/payment/get-saved-cards`,
      { customerID: id },
      jsonconfig
    )
    dispatch({ type: "CARDS_FETCHED_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "CARDS_FETCHED_FAILED", payload: error });
  }
}
export const getUnique_slug = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_UNIQUE_SLUG_REQUEST" });
    const { data } = await axios.get(`${url}/user_slugs`, {
      withCredentials: true,
    });
    dispatch({ type: "GET_UNIQUE_SLUG_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_UNIQUE_SLUG_FAILURE", payload: error });
  }
};
export const getOrders = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_ORDERS_REQUEST" });
    const { data } = await axios.get(`${url}/getOrders`, jsonconfig);
    dispatch({ type: "GET_ORDERS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_ORDERS_FAILURE", payload: error.message });
  }
};
export const setLocalStorageChangeFlag = (flag) => (dispatch) => {
  try {
    dispatch({ type: "SET_LOCAL_STORAGE_CHANGE_FLAG", payload: flag });
  } catch (error) {
  }
};
export const getPlan = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PLANS_REQUEST" });
    const { data } = await axios.get(`${url}/plans`, jsonconfig);
    dispatch({ type: "FETCH_PLANS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_PLANS_FAILURE", payload: error.message });
  }
};