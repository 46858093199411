import {
  Box,
  Collapse,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loader from "../../components/Layout/Loader/Loader";
import "./Otc_Orders.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrderss } from "../Redux/Otc_UserActions";
import ProductIcon from "../Otc_assets/OTC_ICONS/ProductIcon.svg";
import AddonIcon from "../Otc_assets/OTC_ICONS/Otc_addons_icon.svg";
import UserIcon from "../Otc_assets/OTC_ICONS/Otc_users_icon.svg";
import PlanIcon from "../Otc_assets/OTC_ICONS/Otc_plan_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
  >
    <path
      d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
      fill="#E65925"
    />
    <path
      d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
      stroke="white"
      stroke-width="6.64"
      stroke-linecap="round"
      class="checkmark-path"
    />
  </svg>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const warningModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "#FDE9E9",
  p: 3,
  outline: "none",
  border: "1px solid E62525",
  borderRadius: "10px",
};
const dropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#F2F2F2",
    marginBottom: "45px",
    padding: "10px 0",
    border: state.isFocused ? "none" : "#F2F2F2",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "black",
    color: "white",
    borderRadius: "5px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "25px",
  }),
};
const Otc_OrdersoverviewPage = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectDropdownStatus, setSelectDropdownStatus] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState("Products");
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedOrderDate, setSelectedOrderDate] = useState("OrderDate");
  const [selectedType, setSelectedType] = useState("Type");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState({
    status: "",
  });
  const [validationMessage, setValidationMessage] = useState("");
  const [validationMessageOrderNumber, setValidationMessageOrderNumber] =
    useState("");
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [inputOrderNumber, setInputOrderNumber] = useState("");
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const handleWarningOpenModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningdModal = () => {
    setOpenWarningModal(false);
    setValidationMessageOrderNumber("");
    setInputOrderNumber("");
  };

  const { allOrders, loading } = useSelector((state) => state.allOrders);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_URL;

  function createData(
    id,
    avatar,
    name,
    status,
    orderDate,
    total,
    fulfillment,
    type,
    orderNumber,
    productName,
    subscription_details,
    smartAccessories,
    paymentStatus,
    userId
  ) {
    return {
      id,
      avatar,
      name,
      status,
      orderDate,
      total,
      fulfillment,
      type,
      orderNumber,
      productName,
      subscription_details,
      smartAccessories,
      paymentStatus,
      userId,
    };
  }

  function convertAPIDataToRow(apiData) {
    return apiData?.map((item) => {
      const id = item?._id;
      const userId = item?.user?._id;
      const orderNumber = item?.orderNumber;
      const avatar = item?.user?.avatar;
      const name = `${item?.first_name} ${item?.last_name}`;
      const status = item?.status;
      const paymentStatus = item?.paymentStatus;
      const orderDate = item?.paymentDate ? item?.paymentDate : item.createdAt;
      const subscription_details = item?.subscription_details;
      const smartAccessories = item?.smartAccessories;
      const totalAmount =
      item?.type === "Subscription"
          ? !item?.subscription_details?.total_amount
              ? "0"
              : item?.subscription_details?.total_amount
          : item?.type === "combined"
              ? 0
              : item?.totalAmount;
      const taxRate =
        item?.type === "Subscription"
          ? item?.subscription_details?.taxRate
          : (item?.tax || 0);
      const tax = (
        (parseFloat(totalAmount) * parseFloat(taxRate)) /
        100
      ).toFixed(2);
      let shipping;
      if (item?.shipping_method?.length > 0) {
        shipping =
          item?.type === "Subscription"
            ? item?.shipping_method[0]?.price
            : item?.shipping_method[0]?.price;
      } else {
        shipping = 0;
      }
      const total =
        parseInt(totalAmount, 10) + parseFloat(tax) + parseInt(shipping, 10);
      const type = item?.type;
      const fulfillment = "N/A";
      const productName = item?.smartAccessories
        ?.map((smartAccessory) => smartAccessory?.productId?.name)
        .filter(Boolean)
        .filter((name) => name !== "");
      return createData(
        id,
        avatar,
        name,
        status,
        orderDate,
        total,
        fulfillment,
        type,
        orderNumber,
        productName,
        subscription_details,
        smartAccessories,
        paymentStatus,
        userId
      );
    });
  }
  useEffect(() => {
    dispatch(getAllOrderss());
  }, []);

  useEffect(() => {
    setRows(convertAPIDataToRow(allOrders));
  }, [dispatch, allOrders]);

  const handleUpdateOrderStatus = async () => {
    try {
      if (selectedOrderStatus.status === "") {
        setValidationMessage("Please select a status");
        return;
      } else if (selectedOrder.length < 1) {
        setValidationMessage("Please select at least one user");
        return;
      }
      const updatedOrderData = selectedOrder.map((orderItem) => ({
        status: orderItem.status,
      }));
      const response = await axios.post(
        `${url}/admin/orders/update`,
        { orderIds, orderData: updatedOrderData },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setValidationMessage("");
      setModalOpen(false);
      dispatch(getAllOrderss());
      setSelectedOrder([]);
      setSelectedOrderStatus({ status: "" });
      setApiSuccess(true);
      setSuccessMessage("Order status updated successfully.");
      setTimeout(() => {
        setApiSuccess(false);
      }, 5000);
    } catch (error) {}
  };
  const handleDeleteOrder = async () => {
    try {
      if (selectedOrder.length < 1) {
        setValidationMessageOrderNumber("Please select at least one order.");
        return;
      } else if (inputOrderNumber === "") {
        setValidationMessageOrderNumber("Please enter Order count number.");
        return;
      } else if (selectedOrder.length !== parseInt(inputOrderNumber, 10)) {
        setValidationMessageOrderNumber("Order count doesn't match.");
        return;
      }
      const response = await axios.post(
        `${url}/admin/orders/delete`,
        { orderIds },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setOpenWarningModal(false);
      setValidationMessageOrderNumber("");
      dispatch(getAllOrderss());
      setSelectedOrder([]);
      setInputOrderNumber("");
      setApiSuccess(true);
      setSuccessMessage("Order deleted successfully.");
      setTimeout(() => {
        setApiSuccess(false);
      }, 5000);
    } catch (error) {}
  };

  const clearFilters = () => {
    setSearchQuery("");
    setSelectedType("Type");
    setSelectedStatus("Status");
    setSelectedProducts("Products");
    setSelectedDate(null);
  };

  const options = [
    { value: "Processing", label: "Processing" },
    { value: "Completed", label: "Completed" },
    { value: "Partially Completed", label: "Partially Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Failed", label: "Failed" },
    { value: "On Hold", label: "On Hold" },
  ];

  const handleChange = (selectedOption) => {
    setValidationMessage("");
    setSelectedOrderStatus({ status: selectedOption.value });
  };

  const handleOrderNumberChange = (e) => {
    setValidationMessageOrderNumber("");
    setInputOrderNumber(e.target.value);
  };

  useEffect(() => {
    if (selectedOrder.length > 0) {
      const updatedOrders = selectedOrder.map((order) => ({
        ...order,
        status: selectedOrderStatus.status,
      }));
      setSelectedOrder(updatedOrders);
    }
  }, [selectedOrderStatus]);

  const [displayedPages, setDisplayedPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredRows?.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedOrder([]);
    }
  };
  useEffect(() => {
    if (totalPages <= 5) {
      setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else {
      if (currentPage <= 3) {
        setDisplayedPages([1, 2, 3, 4, 5, ". . .", totalPages]);
      } else if (currentPage >= totalPages - 2) {
        setDisplayedPages([
          1,
          ". . .",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ]);
      } else {
        setDisplayedPages([
          1,
          ". . .",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          ". . .",
          totalPages,
        ]);
      }
    }
  }, [currentPage, totalPages]);

  const handleCheckboxChange = (e, orderData) => {
    const updatedOrder = { ...orderData, status: selectedOrderStatus.status };

    if (e.target.checked) {
      setSelectedOrder([...selectedOrder, updatedOrder]);
    } else {
      setSelectedOrder(
        selectedOrder.filter((order) => order.id !== orderData.id)
      );
    }
  };

  const orderIds = selectedOrder?.map((e) => e.id);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUniqueStatus = () => {
    const uniqueStatus = Array.from(new Set(rows?.map((row) => row?.status)));
    return uniqueStatus?.filter(
      (status, index, self) => self.indexOf(status) === index
    );
  };
  const getUniqueType = () => {
    const uniqueStatus = Array.from(new Set(rows?.map((row) => row?.type)));
    return uniqueStatus?.filter(
      (status, index, self) => self.indexOf(status) === index
    );
  };
  const getUniqueProducts = () => {
    const flatRows = rows
      ?.flatMap((row) => row?.productName)
      .filter((productName) => productName.length > 0);
    const uniqueProducts = Array.from(new Set(flatRows));
    return uniqueProducts;
  };

  const handleStatusChange = (selectedOption) => {
    setSelectDropdownStatus(selectedOption);
  };
  const handleupdatestatus = () => {};

  function getStatusColorClass(status) {
    if (status === "Processing") {
      return "order-Processing";
    } else if (status === "Completed") {
      return "order-Completed";
    } 
   else if (status === "On Hold") {
    return "order-hold";
  }else if (status === "Pending") {
      return "order-Pending";
    } else if (status === "Failed") {
      return "order-Failed";
    } else if (status === "Cancelled") {
      return "order-Cancelled";
    } else if (status === "Refunded") {
      return "order-Cancelled";
    } else if (status === "PartiallyRefunded") {
      return "order-Cancelled";
    } else {
      return "order-PartiallyCompleted";
    }
  }

  const filterRows = () => {
    return rows?.filter((row) => {
      const rowDate = row.orderDate ? new Date(row.orderDate) : null;
      const selectedMonthYear = selectedDate ? new Date(selectedDate) : null;
      const dateMatches =
        !selectedDate ||
        (rowDate &&
          selectedMonthYear &&
          rowDate.getMonth() === selectedMonthYear.getMonth() &&
          rowDate.getFullYear() === selectedMonthYear.getFullYear());

      return (
        (String(row.orderNumber).includes(searchQuery) ||
          row.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (selectedProducts === "Products" ||
          (Array.isArray(row.productName) &&
            row.productName.includes(selectedProducts))) &&
        (selectedStatus === "Status" || row.status === selectedStatus) &&
        (selectedOrderDate === "OrderDate" ||
          row.orderDate === selectedOrderDate) &&
        (selectedType === "Type" || row.type === selectedType) &&
        dateMatches
      );
    });
  };
  useEffect(() => {
    const filtered = filterRows();
    setFilteredRows(filtered);
    setCurrentPage(1);
  }, [
    searchQuery,
    selectedOrderDate,
    selectedType,
    selectedProducts,
    selectedStatus,
    rows,
    selectedDate,
  ]);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleNavigateCreateOrder = (userid, orderId) => {
    navigate(`/admin/orders/create-order/${userid}`, { state: { orderId } });
  };
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const fullName = row.name;
    const words = fullName.split(" ");
    const initialss = words.map((word) => word.charAt(0).toUpperCase());
    const initials = initialss.join("");

    const renderImagesBasedOnType = () => {
      if (row.type === "Subscription") {
        if (
          row?.subscription_details?.total_user[0]?.additionalUser > 0 &&
          row?.subscription_details?.addones?.length > 0 &&
          row?.smartAccessories?.length > 0
        ) {
          return (
            <>
              <img
                className="p-1"
                src={UserIcon}
                alt="User Icon"
                title="Additional user"
              />
              <img
                className="p-1"
                src={PlanIcon}
                alt="Plan Icon"
                title="Plan"
              />
              <img
                className="p-1"
                src={AddonIcon}
                alt="Addon Icon"
                title="Add-ons"
              />
              <img
                className="p-1"
                src={ProductIcon}
                alt="Product Icon Icon"
                title="Smart Accessories"
              />
            </>
          );
        } else if (row?.subscription_details?.addones?.length > 0) {
          return (
            <>
              <img
                className="p-1"
                src={PlanIcon}
                alt="Plan Icon"
                title="Plan"
              />
              <img
                className="p-1"
                src={AddonIcon}
                alt="Addon Icon"
                title="Add-ons"
              />
            </>
          );
        } else if (
          row?.subscription_details?.total_user[0]?.additionalUser > 0
        ) {
          return (
            <>
              <img
                className="p-1"
                src={PlanIcon}
                alt="Plan Icon"
                title="Plan"
              />
              <img
                className="p-1"
                src={UserIcon}
                alt="User Icon"
                title="Additional user"
              />
            </>
          );
        } else {
          return (
            <img className="p-1" src={PlanIcon} alt="Plan Icon" title="Plan" />
          );
        }
      } else if (row.type === "smartAccessories") {
        return (
          <img
            className="p-1"
            src={ProductIcon}
            alt="Product Icon"
            title="Smart Accessories"
          />
        );
      } else if (row.type === "AddonPurchase") {
        return (
          <img
            className="p-1"
            src={AddonIcon}
            alt="Addon Icon"
            title="Add-ons"
          />
        );
      } else if (row.type === "UserPurchase") {
        return (
          <img
            className="p-1"
            src={UserIcon}
            alt="Addon Icon"
            title="Additional user"
          />
        );
      } else {
        return null;
      }
    };
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell className="otc_onetap_conn_user_table_column1">
            <div className="otc_profile-info">
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/admin/order/details/${row.id}`}
                >
                  {row?.avatar ? (
                    <img
                      src={`${url}/profile/img/${row?.avatar}`}
                      alt="Profile"
                      style={{
                        width: "54px",
                        height: "54px",
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="otc_user-info-initials-overlay-order-overview"
                      style={{ width: "54px", height: "54px" }}
                    >
                      {initials}
                    </div>
                  )}
                </Link>
              </div>

              <div style={{ marginLeft: "16px" }} class="details">
                <div>
                  {row.paymentStatus === "paid" ? (
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      to={`/admin/order/details/${row.id}`}
                    >
                      <p
                        className="order_overview_order_number"
                        style={{
                          textTransform: "capitalize",
                          marginBottom: "0px",
                          cursor: "pointer",
                        }}
                      >
                        #{row.orderNumber}
                      </p>
                    </Link>
                  ) : (
                    <p
                      onClick={() =>
                        handleNavigateCreateOrder(row.userId, row.id)
                      }
                      className="order_overview_order_number"
                      style={{
                        textTransform: "capitalize",
                        marginBottom: "0px",
                        cursor: "pointer",
                      }}
                    >
                      #{row.orderNumber}
                    </p>
                  )}
                </div>
                <div>
                  <p
                    style={{ marginBottom: "0px" }}
                    className="order_overview_name_text"
                  >
                    {row.name}
                  </p>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell
            align="center"
            style={{ borderBottom: "none", textTransform: "capitalize" }}
          >
            <div
              style={{ padding: "10px 8px", borderRadius: "4px" }}
              className={`${getStatusColorClass(row.status)}`}
            >
              {row.status}
            </div>
            <span style={{ display: "none" }}>
              {row.productName.map((e) => e)}
            </span>
          </TableCell>
          <TableCell
            align="center"
            style={{ borderBottom: "none", textTransform: "capitalize" }}
          >
            {row?.orderDate ? new Date(row.orderDate).toLocaleDateString() : ""}
          </TableCell>
          <TableCell
            align="center"
            style={{
              borderBottom: "none",
              textTransform: "capitalize",
            }}
          >
            ${row.total}
          </TableCell>
          <TableCell
            align="center"
            style={{
              borderBottom: "none",
              textTransform: "capitalize",
            }}
          >
            {renderImagesBasedOnType()}
          </TableCell>
          <TableCell
            align="center"
            style={{
              borderBottom: "none",
              textTransform: "capitalize",
            }}
          >
            {"N/A"}
          </TableCell>
          <TableCell className="otc_onetap_conn_user_table_column5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {row.paymentStatus === "paid" && (
                <div>
                  <input
                    type="checkbox"
                    name="action_check"
                    style={{ margin: "0px 11px" }}
                    checked={selectedOrder.some((order) => order.id === row.id)}
                    value={row.id}
                    onChange={(e) => handleCheckboxChange(e, row)}
                  />
                </div>
              )}

              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  style={{ color: "#000000" }}
                  disableElevation
                  disableRipple
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none",
            }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="otc_onetap_conn_expanded_row_card">
                <div className="otc_onetap_conn_expanded_card card1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M3.9502 23.8333V8.66663H8.28353V23.8333H3.9502ZM11.5335 23.8333V2.16663H15.8669V23.8333H11.5335ZM19.1169 23.8333V15.1666H23.4502V23.8333H19.1169Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                  <div>Analytics</div>
                </div>
                <div className="otc_onetap_conn_expanded_card">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M13.1003 9.75C12.2383 9.75 11.4117 10.0924 10.8022 10.7019C10.1927 11.3114 9.85026 12.138 9.85026 13C9.85026 13.862 10.1927 14.6886 10.8022 15.2981C11.4117 15.9076 12.2383 16.25 13.1003 16.25C13.9622 16.25 14.7889 15.9076 15.3984 15.2981C16.0078 14.6886 16.3503 13.862 16.3503 13C16.3503 12.138 16.0078 11.3114 15.3984 10.7019C14.7889 10.0924 13.9622 9.75 13.1003 9.75ZM13.1003 18.4167C11.6637 18.4167 10.2859 17.846 9.2701 16.8302C8.25427 15.8143 7.68359 14.4366 7.68359 13C7.68359 11.5634 8.25427 10.1857 9.2701 9.16984C10.2859 8.15402 11.6637 7.58333 13.1003 7.58333C14.5368 7.58333 15.9146 8.15402 16.9304 9.16984C17.9462 10.1857 18.5169 11.5634 18.5169 13C18.5169 14.4366 17.9462 15.8143 16.9304 16.8302C15.9146 17.846 14.5368 18.4167 13.1003 18.4167ZM13.1003 4.875C7.68359 4.875 3.05776 8.24417 1.18359 13C3.05776 17.7558 7.68359 21.125 13.1003 21.125C18.5169 21.125 23.1428 17.7558 25.0169 13C23.1428 8.24417 18.5169 4.875 13.1003 4.875Z"
                      fill="white"
                    />
                  </svg>
                  <div>View Card</div>
                </div>
                <div className="otc_onetap_conn_expanded_card card4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M20.4004 23.8333C19.4976 23.8333 18.7303 23.5173 18.0983 22.8854C17.4664 22.2534 17.1504 21.4861 17.1504 20.5833C17.1504 20.4569 17.1594 20.3258 17.1775 20.19C17.1955 20.0543 17.2226 19.9326 17.2587 19.825L9.62122 15.3833C9.31428 15.6541 8.97122 15.8665 8.59206 16.0203C8.21289 16.1741 7.81567 16.2507 7.40039 16.25C6.49761 16.25 5.73025 15.934 5.09831 15.302C4.46636 14.6701 4.15039 13.9027 4.15039 13C4.15039 12.0972 4.46636 11.3298 5.09831 10.6979C5.73025 10.0659 6.49761 9.74996 7.40039 9.74996C7.81567 9.74996 8.21289 9.82688 8.59206 9.98071C8.97122 10.1345 9.31428 10.3465 9.62122 10.6166L17.2587 6.17496C17.2226 6.06663 17.1955 5.94493 17.1775 5.80988C17.1594 5.67482 17.1504 5.54374 17.1504 5.41663C17.1504 4.51385 17.4664 3.74649 18.0983 3.11454C18.7303 2.4826 19.4976 2.16663 20.4004 2.16663C21.3032 2.16663 22.0705 2.4826 22.7025 3.11454C23.3344 3.74649 23.6504 4.51385 23.6504 5.41663C23.6504 6.3194 23.3344 7.08676 22.7025 7.71871C22.0705 8.35065 21.3032 8.66663 20.4004 8.66663C19.9851 8.66663 19.5879 8.59007 19.2087 8.43696C18.8296 8.28385 18.4865 8.07151 18.1796 7.79996L10.5421 12.2416C10.5782 12.35 10.6053 12.472 10.6233 12.6078C10.6414 12.7436 10.6504 12.8743 10.6504 13C10.6504 13.1263 10.6414 13.2574 10.6233 13.3932C10.6053 13.529 10.5782 13.6507 10.5421 13.7583L18.1796 18.2C18.4865 17.9291 18.8296 17.7172 19.2087 17.564C19.5879 17.4109 19.9851 17.334 20.4004 17.3333C21.3032 17.3333 22.0705 17.6493 22.7025 18.2812C23.3344 18.9132 23.6504 19.6805 23.6504 20.5833C23.6504 21.4861 23.3344 22.2534 22.7025 22.8854C22.0705 23.5173 21.3032 23.8333 20.4004 23.8333Z"
                      fill="white"
                    />
                  </svg>
                  <div>Share Card</div>
                </div>
                <div className="otc_onetap_conn_expanded_card card5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                  >
                    <path
                      d="M4.05029 22.5V20H26.5503V22.5H4.05029ZM4.05029 16.25V13.75H26.5503V16.25H4.05029ZM4.05029 10V7.5H26.5503V10H4.05029Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                  <div>More</div>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow style={{ paddingBottom: "10px" }}>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="otc_userinfo_pagetabl"
          ></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      orderDate: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      fulfillment: PropTypes.string.isRequired,
      orderNumber: PropTypes.number.isRequired,
    }).isRequired,
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="otc_onetap_conn_users_tab_content">
            <div className="otc_onetap_conn_users_tap_filter">
              <div className="otc_onetap_conn_filter_search">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="otc_onetap_conn_filter_svg"
                >
                  <path
                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="otc_onetap_conn_filter_search">
                <select
                  name="Status"
                  id="Status"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="Status">Status</option>
                  {getUniqueStatus()?.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={group}
                      value={group}
                    >
                      {group?.charAt(0).toUpperCase() + group?.slice(1)}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="otc_onetap_conn_filter_svg"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div
                style={{ border: "none", outline: "none" }}
                className="onetap_conn_filter_search_OTC_Admin"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    views={["month", "year"]}
                    format="MM YYYY"
                    className="datepickerfilter"
                  />
                </LocalizationProvider>
              </div>
              <div className="otc_onetap_conn_filter_search">
                <select
                  name="Type"
                  id="Type"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="Type">Type</option>
                  {getUniqueType()?.map((status) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={status}
                      value={status}
                    >
                      {status}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="otc_onetap_conn_filter_svg"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="otc_onetap_conn_filter_search">
                <select
                  name="Products"
                  id="Products"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedProducts}
                  onChange={(e) => setSelectedProducts(e.target.value)}
                >
                  <option value="Products">Smart Accessories</option>
                  {getUniqueProducts()?.map((products) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={products}
                      value={products}
                    >
                      {products}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="otc_onetap_conn_filter_svg"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
            </div>
            <button
              className="clear-fillter"
              style={{
                marginTop: "10px",
                display:
                  searchQuery ||
                  selectedType !== "Type" ||
                  selectedStatus !== "Status" ||
                  selectedProducts !== "Products" ||
                  selectedDate !== null
                    ? "block"
                    : "none",
              }}
              onClick={clearFilters}
            >
              Clear filters
            </button>
            <div>
              <TableContainer
                style={{
                  borderRadius: "0px",
                  boxShadow: "none",
                  overflowX: "visible",
                }}
              >
                <Table aria-label="collapsible table">
                  <TableHead sx={{ backgroundColor: "white !important" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingLeft: "0px",
                          width: "20%",
                          color: "#333",
                          fontSize: "24px",
                          fontWeight: 400,
                          borderBottom: "0px",
                        }}
                      >
                        Orders
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Status
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Order date
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Total
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Type
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Fulfillment
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0px",
                          borderBottom: "0px",
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          style={{ position: "relative", width: "150px" }}
                          onClick={toggleDropdown}
                          ref={dropdownRef}
                        >
                          <div className="otc_onetap_conn_table_action_header">
                            <div>Actions</div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                            </svg>
                          </div>
                          {isDropdownOpen && (
                            <div className="otc_onetap_conn_table_action_header_dropdown">
                              <div
                                className="otc_onetap_conn_table_action_header_dropdown_option"
                                onClick={() => {
                                  setModalOpen(true);
                                  setDropdownOpen(false);
                                }}
                              >
                                Update status
                              </div>
                              <div
                                className="otc_onetap_conn_table_action_header_dropdown_option"
                                onClick={() => handleWarningOpenModal()}
                              >
                                Delete order
                              </div>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "#F2F2F2 !important" }}>
                    {paginatedData?.length > 0 ? (
                      paginatedData?.map((row) => (
                        <Row key={row.id} row={row} />
                      ))
                    ) : (
                      <div style={{ backgroundColor: "white" }}>
                        No Orders Available.
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {totalPages > 1 && (
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  {currentPage !== 1 ? (
                    <ArrowBackIosNewIcon
                      onClick={() => handlePageChange(currentPage - 1)}
                      fontSize="normal"
                      style={{ margin: "10px", cursor: "pointer" }}
                    />
                  ) : (
                    <ArrowBackIosNewIcon
                      fontSize="normal"
                      style={{ margin: "10px", opacity: "0.2" }}
                    />
                  )}
                  {displayedPages.map((page, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(page)}
                      style={{
                        backgroundColor:
                          currentPage === page ? "#e65925" : "transparent",
                        color: currentPage === page ? "white" : "black",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px 20px",
                        margin: "2px",
                      }}
                    >
                      {page}
                    </button>
                  ))}
                  {currentPage !== totalPages ? (
                    <ArrowForwardIosIcon
                      onClick={() => handlePageChange(currentPage + 1)}
                      fontSize="normal"
                      style={{ margin: "10px", cursor: "pointer" }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      fontSize="normal"
                      style={{ margin: "10px", opacity: "0.2" }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <Modal
            open={isModalOpen}
            onClose={() => {
              setModalOpen(false);
              setValidationMessage("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="otc_Modal-section">
                <h2 className="otc_order_modal_header">
                  Update order status{" "}
                  <CloseIcon
                    className="otc_icon"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  />
                </h2>
                <span className="otc_Team-Name-Text">Select Status</span>

                <span className="sec-modal-dropdwon">
                  <Select
                    styles={{
                      control: () => ({
                        margin: 0,
                        padding: 0,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      option: (provided) => ({
                        ...provided,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "97%",
                      }),
                    }}
                    className="otc-order-input_syn mb-3 form-select"
                    placeholder="Search status"
                    value={
                      options.find(
                        (option) => option.value === selectedOrderStatus.status
                      ) || null
                    }
                    onChange={handleChange}
                    options={options}
                  />
                </span>
                {validationMessage && (
                  <div className="error-message">{validationMessage}</div>
                )}
                <div className="otc_Modal-btn-group">
                  <button
                    className="otc_Modal-btn otc_first-modal-btn"
                    onClick={handleUpdateOrderStatus}
                  >
                    Save
                  </button>
                  <button
                    className="otc_Modal-btn otc_sec-modal-btn"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={openWarningModal}
            onClose={handleCloseWarningdModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={warningModalStyle}>
              <div className="video-Modal-section">
                <h2 className="warning-header border-bottom pb-2">
                  Delete Confirmation
                </h2>
                <div style={{ marginTop: "10px" }}>
                  <span className="warning-text-order-overview mb-2">
                    {selectedOrder.length > 1
                      ? `Are you sure you want to delete these orders? You cannot undo this action.`
                      : `Are you sure want to delete this order? You cannot undo this action.`}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontSize: "14px",
                  }}
                >
                  <span>
                    Confirm the number of orders you want to delete by entering
                    it here.
                  </span>
                </div>
                <input
                  className="w-100 border-0 p-2"
                  placeholder=""
                  type="text"
                  style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                  name="InternalPlanName"
                  value={inputOrderNumber}
                  onChange={handleOrderNumberChange}
                />
                {validationMessageOrderNumber && (
                  <div className="error-message">
                    {validationMessageOrderNumber}
                  </div>
                )}
                <div className="video-Modal-btn-group mt-4">
                  <button
                    className="video-Modal-btn video-first-modal-btn"
                    onClick={() => {
                      handleDeleteOrder();
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="video-Modal-btn video-sec-modal-btn"
                    onClick={() => setOpenWarningModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: "center" }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">{messageIcon}</div>
                </div>
                <span className="team_created_success">{successMessage}</span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "13%",
                }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#e65925" }}
                  />
                </span>
              </button>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Otc_OrdersoverviewPage;
