import React, { useState } from "react";
import "./myCard.css";
import Sidebar from "../../../components/Layout/Sidebar/Sidebar";
import MyCardsInfoTab from "./MyCardsInfoTab";
import MyCardHeaderWithIcons from "./MyCardHeaderWithIcons";

const Component = () => {
  const [cardInfoTab, setCardInfoTab] = useState("My cards");
  const getTabValue = (tabVal) => {
    setCardInfoTab(tabVal);
  };

  const getValueOfSaveAndContinueTab = (value) => {
    setCardInfoTab(value);
  };

  return (
    <div className="">
      <div className="onetap_company_card_syn">
        <MyCardHeaderWithIcons
          onChange={getTabValue}
          tab={cardInfoTab}
        />

        <div>
          {cardInfoTab === "My cards" && (
            <MyCardsInfoTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}

        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};

export default Dashboard;
