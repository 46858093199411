import React, { useState } from "react";
import "./checkout.scss";
import { Country, State } from "country-state-city";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setLocalStorageChangeFlag,
} from "../../../actions/userAction/userAction";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Select from "react-select"
import Header2 from "../../Layout/Header/Header2";

const GuestCheckout = () => {
  const [rates, setRates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_URL;
  const location = useLocation();
  const additionalUser = location?.state?.additionUser;
  const [selectedOption, setSelectedOption] = useState("free");
  const [plandata, setPlanData] = useState(null);
  const [same, setSame] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userCount, setUserCount] = useState(additionalUser);
  const stripe = useStripe();
  const elements = useElements();
  const [updatedYearlyPrice, setUpdatedYearlyPrice] = useState();
  const [isAccordion1Filled, setIsAccordion1Filled] = useState(false);
  const [isAccordion2Filled, setIsAccordion2Filled] = useState(false);
  const [isAccordion3Filled, setIsAccordion3Filled] = useState(false);
  const [isAccordion5Filled, setIsAccordion5Filled] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountryShiping, setSelectedCountryShipping] = useState(null);
  const [selectedStateShipping, setSelectedStateShipping] = useState(null);
  const [stateOptionsShipping, setStateOptionsShipping] = useState([]);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxId, setTaxId] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [buttonTextPay, setButtonTextPay] = useState("Pay");
  const [productTotalPrice, setProductTotalPrice] = useState();
  const [userShippingOrderNote, SetUserShippingOrderNote] = useState('');
  const [referrer, setReferrer] = useState("");
  const [referrerName, setReferrerName] = useState("");

  const [couponrelatedproducts, setCouponrelatedproducts] = useState();
  const [showCouponError, setshowCouponError] = useState({ showError: false, error: "" });
  const [totalDiscountValue, setTotalDiscountValue] = useState(null);
  const [visibleCouponForm, setvisibleCouponForm] = useState(false);
  const [couponCode, setCouponCode] = useState([]);
  const [fetchedCoupon, setFetchedCoupon] = useState({ coupon: {}, discounValue: 0, type: null });
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [applyCouponbtnActive, setApplyCouponbtnActive] = useState(false);
  const [applycoupondata, setApplycoupondata] = useState()

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const [paymentErrors, setPaymentErrors] = useState({
    cardName: "",
    cardNumber: "",
    expiryDate: "",
    csv: "",
    policyIsChecked: "",
  });
  const [isActive, setIsActive] = useState({
    a1: true,
    a2: false,
    a3: false,
    a5: false,
  });
  const [policyIsChecked, setPolicyIsChecked] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    cardName: "",
    cardNumber: null,
    csv: null,
    expiryDate: null,
  });
  const [billingAddress, setbillingAddress] = useState({
    Bstreet1: "",
    Bstreet2: "",
    Bcity: "",
    BpostalCode: "",
    Bstate: "",
    Bcountry: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    Sstreet1: billingAddress.Bstreet1,
    Sstreet2: billingAddress.Bstreet2,
    Scity: billingAddress.Bcity,
    SpostalCode: billingAddress.BpostalCode,
    Sstate: billingAddress.Bstate,
    Scountry: billingAddress.Bcountry,
  });

  const getVariationById = (variations, variationId) => {
    return variations.find(variation => variation._id === variationId) || null;
  };

  const removeAppliedCoupon = () => {
    setApplyCouponbtnActive(false)
    setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
    setApplyCouponbtnActive(false)
    setIsCouponApplied(false)
    setTotalDiscountValue(0)
    setCouponCode([]);
    setvisibleCouponForm(!visibleCouponForm)
  }

  const verifyCouponUsage = async (couponDetails) => {
    try {
      // API to check coupon usage
      const { data } = await axios.post(`${url}/admin/coupons/checkusage`,
        couponDetails,
        config
      )
      if (data.success) {
        return true
      } else {
        // show error that user exceed limit
        setshowCouponError({ showError: true, error: data.msg })
        setTimeout(() => {
          setshowCouponError({ showError: false, error: null })
        }, 2000);
        setApplyCouponbtnActive(false)
        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
        return false
      }
    } catch (error) {
      // Show internal server error
      setApplyCouponbtnActive(false)

      setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
      setApplyCouponbtnActive(false)
      setIsCouponApplied(false)
      setTotalDiscountValue(0)
      return false
    }

  }

  const couponInputHandler = (e) => {
    const couponCode = [e.target.value.trim().replace(/\s+/g, ' ')];
    setCouponCode(couponCode);
    setApplyCouponbtnActive(false)
  }

  const couponHandler = async () => {
    //start loader from here
    setApplyCouponbtnActive(true)
    if (couponCode.length <= 0) {
      // show erorr that field if empty
      setshowCouponError({ showError: true, error: "Please enter the coupon code." })
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      setApplyCouponbtnActive(false)
    } else {
      try {
        // api call to fetch coupon using code
        const { data } = await axios.post(`${url}/admin/coupons/get`,
          { codes: couponCode },
          config
        )
        if (data.success) {
          // function that will apply coupon with neccessary checks
          applyCouponToPrice(data.coupons)
          setApplyCouponbtnActive(false)
        } else {
          //show error
          setshowCouponError({ showError: true, error: data.msg })
          setTimeout(() => {
            setshowCouponError({ showError: false, error: null })
          }, 2000);
          setApplyCouponbtnActive(false)


          setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
          setApplyCouponbtnActive(false)
          setIsCouponApplied(false)
          setTotalDiscountValue(0)
        }
      } catch (error) {
        // show error
        setApplyCouponbtnActive(false)


        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
      }
    }
  }

  const applyCouponToPrice = async (coupons) => {
    if (!coupons || coupons.length === 0) {
      setshowCouponError({ showError: true, error: "No coupons Found" })
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      return; // No coupons provided
    }


    const firstCoupon = coupons[0];
    setApplycoupondata(firstCoupon)
    if (firstCoupon) {
      if (firstCoupon.usageLimit <= 0) {
        setshowCouponError({ showError: true, error: "This coupon has exceed a usage limit" })
        setTimeout(() => {
          setshowCouponError({ showError: false, error: null })
        }, 2000);
        return //show error that this coupon has exceed a uasge limit
      }
    }

    if (cartItems) {
      const matchedProducts = [];
      let totalQuantity = 0;
      let updatedCoupon = {}
      cartItems.forEach(cartProduct => {
        if (cartProduct.variations && cartProduct.variations.length > 0) {
          const matchingVariation = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.variations && couponProduct.variations.length > 0 &&
            couponProduct.variations.some(couponVariation =>
              cartProduct.variations.some(cartVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            )
          );

          if (matchingVariation) {
            const matchedCartVariation = cartProduct.variations.find(cartVariation =>
              matchingVariation.variations.some(couponVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            );

            if (matchedCartVariation) {
              matchedProducts.push({ product: cartProduct, variation: matchedCartVariation });
            }
          }
        } else {
          const matchingProd = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.product_id === cartProduct.product._id
          );

          if (matchingProd) {
            matchedProducts.push({ product: cartProduct });
          }
        }
      });



      const couponRelatedProductsidarray = matchedProducts?.map(item => ({
        variationID: item?.product?.variation && item?.product?.variation,
        productID: item?.product?.product?._id,
        name: item?.product?.product?.name
      }));

      setCouponrelatedproducts(couponRelatedProductsidarray)

      if (matchedProducts.length <= 0) {
        setshowCouponError({ showError: true, error: "This coupon is not applied on these products" })
        setTimeout(() => {
          setshowCouponError({ showError: false, error: null })
        }, 2000);
        return
      }

      const filterVariation = (variations, variationId) => {
        return variations.find(variation => variation._id === variationId) || null;
      };
      ///
      matchedProducts.forEach(matchingProduct => {
        if (matchingProduct.product.product.isOnSale) {
          totalQuantity += matchingProduct.product.quantity;
        }
      })

      const calculateTotalDiscount = () => {
        let productTotalPrice = 0;
        // Calculate the total price of products
        if (matchedProducts) {
          productTotalPrice = matchedProducts.reduce((sum, val) => {
            const variation = filterVariation(val.product.product.variations, val.product.variation);

            if (val.product.variation) {

              const productPrice = variation.saleprice
                ? variation.saleprice * val.product.quantity
                : variation.price * val.product.quantity;


              return sum + productPrice;
            } else {

              const productPrice = val.product.product.saleprice
                ? val.product.product.saleprice * val.product.quantity
                : val.product.product.price * val.product.quantity;

              return sum + productPrice;
            }
          }, 0);
        }

        const totalPrice = productTotalPrice


        return totalPrice;
      };

      // Usage example:
      let totalPrice = 0
      totalPrice = calculateTotalDiscount();


      let totalDiscount = 0;

      if (totalPrice) {

        const discountValue = firstCoupon.discountAmount;

        if (firstCoupon.discountType === 'Fix discount price') {

          totalDiscount = discountValue * totalQuantity;

        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalDiscount = totalPrice * discountValue / 100;

        } else if (firstCoupon.discountType === "Fix custom discount") {
          const products = firstCoupon.productDiscount;

          // Assuming 'matchedProducts' contains the products you want to apply the custom discount to
          let totalCustomDiscount = 0;

          matchedProducts.forEach(matchedProduct => {
            const quantity = matchedProduct.product.quantity || 1; // If quantity is not provided, default to 1

            if (matchedProduct.product.variation) {
              // If the product has a variation
              const matchingVariation = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                couponProduct.variations.some(couponVariation =>
                  couponVariation.variation_id === matchedProduct.product.variation
                )
              );

              if (matchingVariation) {
                const variationDiscount = matchingVariation.variations.find(variation =>
                  variation.variation_id === matchedProduct.product.variation
                );

                if (variationDiscount) {
                  totalCustomDiscount += (variationDiscount.variationDiscountedPrice || 0) * quantity;
                }
              }
            } else {
              // If the product doesn't have variations
              const matchingProduct = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                !couponProduct.variations.length
              );

              if (matchingProduct) {
                totalCustomDiscount += (matchingProduct.discountedPrice || 0) * quantity;
              }
            }
          });
          totalDiscount = totalCustomDiscount;



        } else {
          setshowCouponError({ showError: true, error: "Internal Server error" })
          setTimeout(() => {
            setshowCouponError({ showError: false, error: null })
          }, 2000);
          return
        }
      } else {
        if (matchedProducts.length <= 0) {
          setshowCouponError({ showError: true, error: "Internal Server error" })
          setTimeout(() => {
            setshowCouponError({ showError: false, error: null })
          }, 2000);
          return
        }
      }
      updatedCoupon.discounValue = totalDiscount;
      updatedCoupon.coupon = coupons[0];
      setFetchedCoupon(updatedCoupon);
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
      });



      if (verifiedCoupon) {
        setIsCouponApplied(true);
        setTotalDiscountValue(totalDiscount);
      }
    }
  }


  useEffect(() => {
    setShippingAddress({
      Sstreet1: billingAddress.Bstreet1,
      Sstreet2: billingAddress.Bstreet2,
      Scity: billingAddress.Bcity,
      SpostalCode: billingAddress.BpostalCode,
      Sstate: billingAddress.Bstate,
      Scountry: billingAddress.Bcountry,
    });
  }, [billingAddress]);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const cartData = JSON.parse(localStorage.getItem('cart')) || [];

        const productIds = cartData.map((item) => {
          return item.product._id;
        });

        const response = await axios.post(`${url}/products/fetch`, { productIds });

        const updatedCartItems = [];

        cartData.forEach((cartItem) => {
          const matchingProducts = response.data.selectedProducts.filter(
            (product) => product._id === cartItem.product._id
          );

          matchingProducts.forEach((matchingProduct) => {
            updatedCartItems.push({
              product: matchingProduct,
              quantity: cartItem.quantity,
              variation: cartItem.variation,
            });
          });
        });


        // Calculate total price
        const totalPrice = updatedCartItems.reduce((acc, item) => {
          const price = item.product.isOnSale ? item.product?.saleprice : item.product?.price; // Use sale price if available

          if (item.variation) {
            const selectedVariation = item.product.variations.find(
              (variation) => variation._id === item.variation // Replace with the actual identifier for the selected variation
            );

            if (selectedVariation) {
              const variationPrice = item.product.isOnSale ? selectedVariation?.saleprice : selectedVariation?.price;
              const productTotal = variationPrice * item.quantity;
              return acc + productTotal;
            }
          }

          return acc + price * item.quantity;
        }, 0);

        setProductTotalPrice(totalPrice);
        setUpdatedYearlyPrice(calculateTotalPrice(0, selectedOption, totalPrice));
        setCartItems(updatedCartItems);
      } catch (error) {
        ;
      }
    };

    fetchCartData();
  }, []);


  useEffect(() => {

    // For example, you can call handleCalculateRatesAndContinue() here
    handleCalculateRatesAndContinue()
  }, [cartItems]);
  const productWeights = [];
  const productquantity = [];
  const totalWeights = [];
  if (cartItems) {
    cartItems.forEach((product) => {
      const weight = product?.product?.weight;
      const quantity = product?.quantity;

      // Add weight and quantity to the respective arrays
      if (weight !== undefined) {
        productWeights.push(weight);
      }

      if (quantity !== undefined) {
        productquantity.push(quantity);
      }
    });
  }
  for (let i = 0; i < productWeights.length && i < productquantity.length; i++) {
    const totalWeight = productWeights[i] * productquantity[i];
    totalWeights.push(totalWeight);
  }
  const sumTotalWeights = totalWeights.reduce((acc, currentValue) => acc + currentValue, 0);




  const shipkey = process.env.REACT_APP_SHIPSTATION_APIKEY;
  const shipsecret = process.env.REACT_APP_SHIPSTATION_API_SECRET;

  const largeEnvelopeorflatWeight = 1.4;
  const largeEnvelopeorflat2Weight = 1.7;
  const packageWeight = 3.2;
  const mediumFlatRateBoxWeight = 5.4;
  const largeFlatRateBoxWeight = 9.4;


  const handleCalculateRatesAndContinue = async (addressData) => {
    const value = await someFunction()


    const fullweight = sumTotalWeights + value.selectedWeight

    try {
      setIsLoading(true);
      const apiKey = shipkey;
      const apiSecret = shipsecret;
      const shipmentData = {
        carrierCode: 'stamps_com',
        serviceCode: 'priority',
        packageCode: value.selectedPackageCode,
        fromPostalCode: '89120',
        toState: shippingAddress.Sstate || addressData.state,
        toCountry: shippingAddress.Scountry || addressData.country,
        toPostalCode: shippingAddress.SpostalCode || addressData.postal_code,
        weight: {
          value: fullweight,
          units: 'ounces',
        },
      };
      const response = await fetch('https://ssapi.shipstation.com/shipments/getrates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${apiKey}:${apiSecret}`)}`,
        },
        body: JSON.stringify(shipmentData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const modifiedData = data.map(item => {
        const originalShipmentCost = item.shipmentCost;
        const newShipmentCost = Math.floor(originalShipmentCost * 1.1 * 100) / 100; // Add 10%
        const serviceNameParts = item.serviceName.split('-');
        let modifiedServiceName = serviceNameParts[0]?.trim() || item.serviceName;
        if (modifiedServiceName === 'USPS Priority Mail') {
          modifiedServiceName = modifiedServiceName + ' (2-3 days) ';
        }

        return { ...item, shipmentCost: newShipmentCost, serviceName: modifiedServiceName };
      });

      setRates(modifiedData);
      setIsLoading();
    } catch (error) {
      ;
      setRates([]);
      setIsLoading();
    }
  };

  const someFunction = async () => {
    let selectedPackageCode = '';
    let selectedWeight = 0;
    if (sumTotalWeights + largeEnvelopeorflatWeight <= 5) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflatWeight;
    } else if (sumTotalWeights + largeEnvelopeorflat2Weight <= 10) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflat2Weight;
    } else if (sumTotalWeights + packageWeight <= 15) {
      selectedPackageCode = 'package';
      selectedWeight = packageWeight;
    } else if (sumTotalWeights + mediumFlatRateBoxWeight <= 50) {
      selectedPackageCode = 'package';
      selectedWeight = mediumFlatRateBoxWeight;
    } else if (sumTotalWeights + largeFlatRateBoxWeight <= 98) {
      selectedPackageCode = 'package';
      selectedWeight = largeFlatRateBoxWeight;
    }


    return { selectedPackageCode, selectedWeight };
  }

  const calculateShippingCost = () => {
    switch (selectedOption) {
      case "free":
        return 0;
      case "2-4":
        const twoToFourDayRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_first_class_mail" ||
            rate.serviceCode === "usps_first_class_mail_international"
        );
        return {
          cost: plandata && plandata?.smart_accessories?.length === 0 ? 20.00 : twoToFourDayRate?.shipmentCost || 0,
          serviceCode: twoToFourDayRate?.serviceCode || null,
        };
      case "overnight":
        const overnightRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_priority_mail" ||
            rate.serviceCode === "usps_priority_mail_international"
        );
        return {
          cost: plandata && plandata?.smart_accessories?.length === 0 ? 60.00 : overnightRate?.shipmentCost || 0,
          serviceCode: overnightRate?.serviceCode || null,
        };
      default:
        return 0;
    }
  };
  const shippingInfo = calculateShippingCost();

  const handleUpdateQuantity = (productId, newQuantity, variationId) => {
    // Update the quantity in the state
    const updatedCartItems = cartItems.map(item => {
      if (item.product._id === productId && item.variation === variationId) {
        return { ...item, quantity: newQuantity, variation: variationId };
      }
      return item;
    });

    setCartItems(updatedCartItems);

    const totalprice = updatedCartItems.reduce((acc, item) => {
      const price = item.product.isOnSale ? item.product?.saleprice : item.product?.price;  // Use sale price if available



      if (item.variation) {
        const selectedVariation = item.product.variations.find(
          (variation) => variation._id === item.variation // Replace with the actual identifier for the selected variation
        );

        if (selectedVariation) {
          const variationPrice = item.product.isOnSale ? selectedVariation?.saleprice : selectedVariation?.price;
          const productTotal = variationPrice * item.quantity;
          return acc + productTotal;
        }
      }

      return acc + price * item.quantity;
    }, 0);

    setProductTotalPrice(totalprice)
    setUpdatedYearlyPrice(calculateTotalPrice(0, selectedOption, totalprice))

    // Update the quantity in the local storage
    const updatedCartData = JSON.parse(localStorage.getItem('cart')) || [];
    const updatedCartDataWithQuantity = updatedCartData.map(item => {
      if (item.product._id === productId && item.variation === variationId) {
        return { product: item.product, quantity: newQuantity, variation: variationId };
      }
      return item;
    });
    localStorage.setItem('cart', JSON.stringify(updatedCartDataWithQuantity));
    dispatch(setLocalStorageChangeFlag(true));
  };


  const handleRemoveProduct = (productId, variationId) => {
    // Remove the product from the state
    const updatedCartItems = cartItems.filter(item => !(item.product._id === productId && item.variation === variationId));
    setCartItems(updatedCartItems);

    const totalprice = updatedCartItems.reduce((acc, item) => {
      const price = item.product.isOnSale ? item.product?.saleprice : item.product?.price;

      if (item.variation) {
        const selectedVariation = item.product.variations.find(
          (variation) => variation._id === item.variation // Replace with the actual identifier for the selected variation
        );

        if (selectedVariation) {
          const variationPrice = item.product.isOnSale ? selectedVariation?.saleprice : selectedVariation?.price;
          const productTotal = variationPrice * item.quantity;
          return acc + productTotal;
        }
      }

      return acc + price * item.quantity;
    }, 0);
    setProductTotalPrice(totalprice)
    setUpdatedYearlyPrice(calculateTotalPrice(0, selectedOption, totalprice))

    // Remove the product from the local storage
    const updatedCartData = (JSON.parse(localStorage.getItem('cart')) || []).filter(item => !(item?.product?._id === productId && item?.variation === variationId));

    localStorage.setItem('cart', JSON.stringify(updatedCartData));
    dispatch(setLocalStorageChangeFlag(true));

  };


  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [selectedAddOne, setSelectedAddOne] = useState([]);
  const navigate = useNavigate();
  const handleBillingContinue = async () => {
    const errors = {};
    if (billingAddress.Bcountry === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bcountry: "Please select a country.",
      };
    }

    if (billingAddress.Bcity === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bcity: "Please enter your city.",
      };
    }
    if (billingAddress.BpostalCode === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        BpostalCode: "Please enter your postal code.",
      };
    }
    if (billingAddress.Bstreet1 === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bstreet1: "Please enter your address.",
      };
    }
    if (selectedCountry && stateOptions.length > 0) {
      if (billingAddress.Bstate === "") {
        errors.billingAddress = {
          ...errors.billingAddress,
          Bstate: "Please enter your state",
        };
      }
    }
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const state = State?.getStateByCodeAndCountry(billingAddress.Bstate, billingAddress.Bcountry)?.name
      const apiUrl = `https://api.zippopotam.us/${billingAddress.Bcountry}/${billingAddress.BpostalCode.trim()}`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok !== true) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: "Please enter a valid postal code.",
          },
        }));
      } else if (data.places[0].state !== state) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: `Please enter a valid postal code for ${state}.`,
          },
        }));
      } else {
        // Your code to handle the valid API response
        setAccordion2(true);
        // Navigate or perform other actions as needed
        nextAccordion(2);
        handleCalculateRatesAndContinue()
        navigate("#shipping");
      }
    } catch (error) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          BpostalCode: "Please enter a valid postal code.",
        },
      }));
    }


  };
  // this function is used to handle billing address change
  const handleBillingAddressChange = (event) => {
    const { name, value } = event.target;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      billingAddress: {
        ...prevErrors.billingAddress,
        [name]: value ? "" : `This Field is Required.`,
      },
    }));
    // Validate postal code length
    if (name === "BpostalCode") {
      if (!selectedCountry) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: "Please select a country."
          }
        }));
        return;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: ""
          }
        }));
      }
    }
    setbillingAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleShippingContinue = async () => {
    // Check if postal code is valid and other validations here
    const errors = {};
    if (same && shippingAddress.Scountry === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Scountry: "Please select a country.",
      };
    }

    if (same && shippingAddress.Scity === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Scity: "Please enter your city.",
      };
    }
    if (same && shippingAddress.SpostalCode === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        SpostalCode: "Please enter your postal code.",
      };
    }
    if (same && shippingAddress.Sstreet1 === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Sstreet1: "Please enter your address.",
      };
    }
    if (selectedCountryShiping && stateOptionsShipping.length > 0) {
      if (shippingAddress.Sstate === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Sstate: "Please enter your state",
        };
      }
    }
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const state = State?.getStateByCodeAndCountry(shippingAddress.Sstate, shippingAddress.Scountry)?.name
      const apiUrl = `https://api.zippopotam.us/${shippingAddress.Scountry}/${shippingAddress.SpostalCode.trim()}`;
      const response = await fetch(apiUrl);
      const data = await response.json();


      if (response.ok !== true) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: "Please enter a valid postal code.",
          },
        }));
      } else if (data.places[0].state !== state) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: `Please enter a valid postal code for ${state}`,
          },
        }));
      } else {
        nextAccordion(3);
        setAccordion3(true);
        navigate("#payment");
      }
    } catch (error) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: "Please enter a valid postal code.",
        },
      }));
    }
  };

  const handleCountryChange = (selectedOption) => {

    setSelectedCountry(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          Bcountry: "", // Remove validation error for the country input
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          BpostalCode: ""
        }
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);

      setbillingAddress((prevData) => ({
        ...prevData,
        Bcountry: selectedOption.value,
      }));
      if (selectedState) {
        setbillingAddress((prevData) => ({
          ...prevData,
          Bstate: selectedState.value,
        }));
      }
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);

    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          Bstate: "", // Remove validation error for the country input
        },
      }));
      setbillingAddress((prevData) => ({
        ...prevData,
        Bstate: selectedOption.value,
      }));
    }
  };
  const handleCountryChangeShipping = (selectedOption) => {

    setSelectedCountryShipping(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Scountry: "", // Remove validation error for the country input
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: ""
        }
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShipping(states);

      setShippingAddress((prevData) => ({
        ...prevData,
        Scountry: selectedOption.value,
      }));
      if (selectedStateShipping) {
        setShippingAddress((prevData) => ({
          ...prevData,
          Sstate: selectedStateShipping.value,
        }));
      }
    }
  };
  const handleStateChangeShipping = (selectedOption) => {
    setSelectedStateShipping(selectedOption);
    // 

    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Sstate: "", // Remove validation error for the country input
        },
      }));
      setShippingAddress((prevData) => ({
        ...prevData,
        Sstate: selectedOption.value,
      }));
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryCode = selectedCountry?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedCountryShiping) {
      const selectedCountryCode = selectedCountryShiping?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShipping(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountryShiping]);

  // this function is used to handle shipping address change
  const handleShippingAddressChange = (event) => {
    const { name, value } = event.target;

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      shippingAddress: {
        ...prevErrors.shippingAddress,
        [name]: value ? "" : ``,
      },
    }));

    if (name === "SpostalCode") {
      if (!selectedCountryShiping) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: "Please select a country."
          }
        }));
        return;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: ""
          }
        }));
      }
    }
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
    handleCalculateRatesAndContinue(event.target.value);
    if ((shippingAddress?.Scountry == 'US')) {
      setSelectedOption("free");
    } else {
      setSelectedOption("2-4");
    }
  };

  const calculateTotalPrice = (userCountValue, shippingMethod, totalPriceSum) => {
    return (
      totalPriceSum
    );
  };

  const handleShippingMethod = (value) => {
    setSelectedOption(value);
    setUpdatedYearlyPrice(calculateTotalPrice(userCount, value, productTotalPrice));
  };

  // this toggle accorion visibility
  const showAccordion = (accordionNo) => {

    if (accordionNo === 1 && isAccordion1Filled) {
      setIsActive((prevActive) => ({
        a1: true,
        a2: false,
        a3: false,
        a4: false,
        a5: false,
      }));
    } else if (accordionNo === 2 && isAccordion2Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: true,
        a3: false,
        a4: false,
        a5: false,
      }));
    } else if (accordionNo === 3 && isAccordion3Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: false,
        a3: true,
        a4: false,
        a5: false,
      }));
    }
    else if (accordionNo === 5 && isAccordion5Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: false,
        a3: false,
        a4: false,
        a5: true,
      }));
    }
  };

  // this function is used to handle next accordion with current accordion validation
  const nextAccordion = async (accodionNo) => {
    if (accodionNo === 1) {
      const errors = {};
      if (fname === "") {
        errors.fname = "Please enter your first name.";
      }

      if (lname === "") {
        errors.lname = "Please enter your last name.";
      }

      if (fname === "" && lname !== "") {
        errors.fname = "Please enter your first name.";
        errors.lname = "-";
      }

      if (lname === "" && fname !== "") {
        errors.lname = "Please enter your last name.";
        errors.fname = "-";
      }

      // Validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === "") {
        errors.email = "Please enter your email address.";
      } else if (!emailRegex.test(email)) {
        errors.email = "Please enter a valid email address.";
      }

      if (contact === "") {
        errors.contact = "Please enter your contact number.";
      }
      setFormErrors(errors);

      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setIsAccordion1Filled(true);
        showAccordion(2);
        setAccordion1(true);
        setIsActive((prevActive) => ({
          ...prevActive,
          a1: false,
          a2: true,
          a3: false,
          a4: false,
          a5: false,
        }));
      }
    }
    if (accodionNo === 2) {
      setIsAccordion2Filled(true);
      showAccordion(3);
      setIsActive((prevActive) => ({
        ...prevActive,
        a1: false,
        a2: false,
        a3: true,
        a4: false,
        a5: false,
      }));
    }
    if (accodionNo === 3) {

      const response = await axios.post(
        `${url}/payment/tax`,
        { shippingAddress },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      let tax_percentage = 0;
      for (const taxRate of response.data.calculation.tax_breakdown) {
        const percentageDecimal = parseFloat(taxRate.tax_rate_details.percentage_decimal);
        tax_percentage += percentageDecimal;
      }
      setTaxPercentage(tax_percentage.toFixed(2));
      setTaxId(response.data.calculation.id);
      setIsAccordion3Filled(true);
      showAccordion(5);
      setIsActive((prevActive) => ({
        ...prevActive,
        a1: false,
        a2: false,
        a3: false,
        a4: false,
        a5: true,
      }));
    }
  };

  const handleInputChangeAccordion1 = (event) => {
    const { name, value } = event.target;

    // Reset form errors
    const errors = { ...formErrors };
    // Update the corresponding state
    if (name === "fname") {
      setFname(value);
      formErrors.fname = ""
    } else if (name === "lname") {
      setLname(value);
      formErrors.lname = ""
    } else if (name === "email") {
      setEmail(value);
      formErrors.email = ""

    } else if (name === "pnumber") {
      setContact(value);
      formErrors.contact = ""

    }
  };

  // this function is used to handle select and unselect addons
  const handleAddoneCheckBox = (e, item) => {
    const selectedValue = item._id;

    if (
      selectedAddOne.some((selectedItem) => selectedItem._id === selectedValue)
    ) {
      setSelectedAddOne(
        selectedAddOne.filter(
          (selectedItem) => selectedItem._id !== selectedValue
        )
      );
      setUpdatedYearlyPrice(updatedYearlyPrice - item.price);
    } else {
      setSelectedAddOne([...selectedAddOne, item]);
      setUpdatedYearlyPrice(updatedYearlyPrice + item.price);
    }
  };

  const handleCardNameChange = (event) => {
    setCardInfo({
      ...cardInfo,
      cardName: event.target.value,
    });

    // Validate card name and set error message
    if (!event.target.value) {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "Please enter the name on the card.",
      }));
    } else {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "", // Clear the error message if valid
      }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsButtonClicked(true);
    { isCouponApplied && await couponHandler(); }
    setButtonTextPay("Processing...");
    const billAddress = {
      first_name: fname,
      last_name: lname,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;

    if (same) {
      const errors = {};
      if (shippingAddress.Scountry === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scountry: "Please select a country for shipping address.",
        };
      }

      if (shippingAddress.Scity === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scity: "Please enter your city.",
        };
      }
      if (shippingAddress.SpostalCode === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          SpostalCode: "Please enter your postal code.",
        };
      }
      if (shippingAddress.Sstreet1 === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Sstreet1: "Please enter your address.",
        };
      }
      if (selectedCountryShiping && stateOptionsShipping.length > 0) {
        if (shippingAddress.Sstate === "") {
          errors.shippingAddress = {
            ...errors.shippingAddress,
            Sstate: "Please enter your state",
          };
        }
      }

      setFormErrors(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }
    }

    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }

    if (!cardInfo.cardNumber || !stripe || !stripe.createPaymentMethod) {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardNumber: "",
      }));
    } else {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardNumber: "",
      }));
    }

    try {
      const billingdata = billAddress
      const shippingData = shipAddress
      const userData = {
        first_name: fname,
        last_name: lname,
        email,
        contact,
        billing_address: billingdata,
        shipping_address: shippingData,
        userShippingOrderNote: (userShippingOrderNote || ""), referredby: (referrer || ''), referredName: (referrerName || ''),
        _id: "Guest"
      };

      if (!stripe || !elements) return;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(
        `${url}/payment/create-customer`,
        { user: userData },
        config
      )


      if (!response) {
        setIsButtonClicked(false);
        setButtonTextPay("Pay");
      }


      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      const cardDetails = {
        cardName: cardInfo.cardName,
        cardNumber: payload.paymentMethod.card.last4,
        brand: payload.paymentMethod.card.brand,
        cardExpiryMonth: payload.paymentMethod.card.exp_month,
        cardExpiryYear: payload.paymentMethod.card.exp_year,
      };

      const createOrderData = {
        customerID: response.data.customer.id,
        paymentToken: payload.paymentMethod.id,
      }

      const userrData = {
        first_name: fname,
        last_name: lname,
        email,
        contact,
        billing_address: billingdata,
        shipping_address: shippingData, userShippingOrderNote: (userShippingOrderNote || ''), referredby: (referrer || ''), referredName: (referrerName || '')
      }
      const totalAmount = updatedYearlyPrice ? ((updatedYearlyPrice - (totalDiscountValue || 0)) + (updatedYearlyPrice * taxPercentage) / 100 + (calculateShippingCost().cost || 0)).toFixed(2) : 0;
      const tax = (((updatedYearlyPrice * taxPercentage) / 100).toFixed(2));
      let couponData = {};

      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }
      const smartAccessories = []

      cartItems.forEach((cartItem) => {
        const { product, quantity, variation } = cartItem;
        const { _id, price, saleprice, isOnSale } = product;
        const matchingVariation = product?.variations?.find(item => item._id === variation);
        const VactualPrice = isOnSale ? matchingVariation?.saleprice : matchingVariation?.price; // Use saleprice if available, otherwise use price
        const productId = _id;
        const actualPrice = isOnSale ? saleprice : price; // Use saleprice if available, otherwise use price
        const subtotal = matchingVariation ? (VactualPrice * quantity)?.toFixed(2) : (actualPrice * quantity)?.toFixed(2);
        const formattedPrice = matchingVariation ? VactualPrice?.toFixed(2) : actualPrice?.toFixed(2);
        smartAccessories.push({
          productId,
          price: formattedPrice,
          subtotal,
          quantity,
          variationId: variation,
        });
      });

      const discountedPricesMap = {};

      applycoupondata?.productDiscount?.forEach((discount) => {
        if (discount.variations.length > 0) {
          discount.variations.forEach((variation) => {
            discountedPricesMap[variation.variation_id] = variation.variationDiscountedPrice;
          });
        } else {
          discountedPricesMap[discount.product_id] = discount.discountedPrice;
        }
      });

      // Add the discountAmount field to each element in the smartAccessories array
      const smartAccessoriesWithDiscount = smartAccessories.map((accessory) => {
        const discountedPrice = discountedPricesMap[accessory.variationId || accessory.productId];
        const Productmatch = couponrelatedproducts?.find((secondItem) =>
          (secondItem?.variationID !== null && secondItem?.variationID === accessory?.variationId) ||
          (secondItem.variationID === null && secondItem.productID === accessory?.productId)
        );

        const discountAmount =
          applycoupondata?.discountType === "Fix custom discount"
            ? discountedPrice || 0
            : applycoupondata?.discountType === "Fix discount price" && Productmatch
              ? applycoupondata?.discountAmount || 0
              : applycoupondata?.discountType === "Percentage discount price" && Productmatch
                ? (parseFloat(accessory.price) * applycoupondata?.discountAmount) / 100 || 0
                : 0;

        return {
          ...accessory,
          discountAmount,
        };
      });

      const shipping_method = {
        type: selectedOption,
        price:
          selectedOption === "free"
            ? 0
            : selectedOption === "2-4"
              ? 20
              : selectedOption === "overnight"
                ? 60
                : 0,
      };

      const { data } = await axios.post(
        `${url}/create-order`,
        { userId: "Guest", userData: userrData, shipping_method, cardDetails, smartAccessories: smartAccessoriesWithDiscount, totalAmount, tax, createOrderData, billingAddress: billingdata, shippingAddress: shippingData, sumTotalWeights: sumTotalWeights, totalShipping: shippingInfo.cost, serviceCode: shippingInfo.serviceCode, couponData },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const popup = document.getElementById("popup");
      const paymentFailure = document.getElementById("payment-failure");

      if (data.success) {
        const cardDetails = {
          cardName: cardInfo.cardName,
          cardNumber: payload.paymentMethod.card.last4,
          brand: payload.paymentMethod.card.brand,
          cardExpiryMonth: payload.paymentMethod.card.exp_month,
          cardExpiryYear: payload.paymentMethod.card.exp_year,
        };

        const shipping_method = {
          type: selectedOption,
          price:
            selectedOption === "free"
              ? 0
              : selectedOption === "2-4"
                ? 20
                : selectedOption === "overnight"
                  ? 60
                  : 0,
        };
        if (data.success === true) {
          const { data } = await axios.post(
            `${url}/guest_checkout`,
            { userData, billingdata, shippingData, shipping_method, cardDetails },
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (data.success) {
            localStorage.removeItem("cart");

            setTimeout(() => {
              navigate("/payment-success", { state: { email } });// Navigate to the dashboard
            }, 1000);
          }
        } else {
          setIsButtonClicked(false);
          setButtonTextPay("Pay");
        }
      } else {
        setIsButtonClicked(false);
        setButtonTextPay("Pay");
        setCardErrorMessage(data.error.message);
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
          popup.style.display = "none";
        }, 4000);
      }
    } catch (error) {
      setIsButtonClicked(false);
      setButtonTextPay("Pay");
    }
  };

  const numchange = (e) => {
    const value = e.target.value;
    const numericInput = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const maxLength = 15;
    const formattedInput = numericInput.slice(0, maxLength); // Limit to max length
    setContact(formattedInput);
    if (/^[0-9]{7,15}$/.test(formattedInput)) {
      setFormErrors({ ...formErrors, contact: "" });
    } else {
      setFormErrors({ ...formErrors, contact: "Please enter a valid cell phone number." });
    }

  };
  const [Accordion1, setAccordion1] = useState(false);
  const [Accordion2, setAccordion2] = useState(false);
  const [Accordion3, setAccordion3] = useState(false);
  const [Accordion5, setAccordion5] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const handleCheckboxChange = () => {
    setSame(!same); // Toggle the value of `same` when the checkbox is clicked

    if (!same) {
      setShippingAddress({
        Sstreet1: "",
        Sstreet2: "",
        Scity: "",
        SpostalCode: "",
        Sstate: "",
        Scountry: "",
      });
    } else {
      setShippingAddress({
        Sstreet1: billingAddress.Bstreet1,
        Sstreet2: billingAddress.Bstreet2,
        Scity: billingAddress.Bcity,
        SpostalCode: billingAddress.BpostalCode,
        Sstate: billingAddress.Bstate,
        Scountry: billingAddress.Bcountry,
      });
    }
  };

  const currentDate = new Date();

  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear() + 1;
  if (plandata?.type === "yearly") {
    const currentDate = new Date();
    day = currentDate.getDate();
    month = currentDate.getMonth() + 1;
    year = currentDate.getFullYear() + 1;
  }

  if (plandata?.type === "monthly") {
    const currentDate = new Date();
    day = currentDate.getDate();
    month = currentDate.getMonth() + 2;
    year = currentDate.getFullYear();
  }

  const formattedDate = `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
    }/${year}`;
  const parts = formattedDate.split("/");

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const areAllFieldsFilled = () => {
    const isAccordion1Filled =
      fname?.trim() !== "" &&
      lname?.trim() !== "" &&
      email?.trim() !== "" &&
      contact !== ""

    const isAccordion2Filled =
      billingAddress.Bcountry?.trim() !== "" &&
      billingAddress.Bcity?.trim() !== "" &&
      billingAddress.BpostalCode?.trim() !== "" &&
      billingAddress.Bstate?.trim() !== "" &&
      billingAddress.Bstreet1?.trim() !== ""

    const isAccordion3Filled = !same && (shippingAddress.Scountry?.trim() !== "" &&
      shippingAddress.Scity?.trim() !== "" &&
      shippingAddress.SpostalCode?.trim() !== "" &&
      shippingAddress.Sstate?.trim() !== "" && shippingAddress.Sstreet1?.trim() !== "");
    const isAccordion4Filled = same && (shippingAddress.Scountry?.trim() !== "" &&
      shippingAddress.Scity?.trim() !== "" &&
      shippingAddress.SpostalCode?.trim() !== "" &&
      shippingAddress.Sstate?.trim() !== "" && shippingAddress.Sstreet1?.trim() !== "");

    const allAccordionsFilled = isAccordion1Filled && isAccordion2Filled && (isAccordion3Filled || isAccordion4Filled);
    setAllFieldsFilled(allAccordionsFilled);
  };
  useEffect(() => {
    areAllFieldsFilled();
  }, [
    shippingAddress,
    billingAddress,
    fname,
    lname,
    contact,
    email,
    password,
    companyName,
    cardInfo,
    policyIsChecked,
  ]);


  const redirectToDashboard = () => {
    navigate("/dashboard");
  };

  const hideElements = () => {
    const popup = document.getElementById("popup");
    const paymentFailure = document.getElementById("payment-failure");
    if (paymentFailure) {
      paymentFailure.style.display = "none";
    }
    if (popup) {
      popup.style.display = "none";
    }
  };

  return (
    <>
      <Header2 />
      <div className="heading-container">
        <h2>Checkout</h2>
      </div>
      <div className="signup-container">
        {/* --------------------------------- accordion-1---------------------------------- */}
        <form className="signup-information">
          <div
            className={`accordion ${isActive.a1 ? "expand" : "collapsed"}`}
            id="account-information"
          >
            <div
              className={`  ${isActive.a1 ? "accordion_header-active" : "accordion-header"
                }`}
              onClick={() => showAccordion(1)}
            >
              <h2>Account Information</h2>
              <a
                style={{
                  color: `${Accordion1
                    ? isActive.a1 === true
                      ? "#fff"
                      : "#48AF25"
                    : ""
                    }`,
                }}
                href="#account-informtion"
              >
                Step-1
              </a>
            </div>
            {isActive.a1 && (
              <div className="accordion-content">
                <div className="names">
                  <div className="name">
                    <label htmlFor="fname">First name*</label>
                    <input
                      type="text"
                      name="fname"
                      value={fname}
                      className={
                        formErrors.fname === null ? "white-text" : ""
                      }
                      onChange={handleInputChangeAccordion1}
                    />
                    {formErrors.fname && (
                      <span
                        className={`error ${formErrors.fname === "-" ? "whiteTxt" : ""
                          }`}
                      >
                        {formErrors.fname}
                      </span>
                    )}
                  </div>
                  <div className="name">
                    <label htmlFor="lname">Last name*</label>
                    <input
                      type="text"
                      name="lname"
                      value={lname}
                      onChange={handleInputChangeAccordion1}
                    />
                    {formErrors.lname && (
                      <span
                        className={`error ${formErrors.lname === "-" ? "whiteTxt" : ""
                          }`}
                      >
                        {formErrors.lname}
                      </span>
                    )}
                  </div>
                </div>
                <div className="inputs">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleInputChangeAccordion1}
                  />
                  {formErrors.email && (
                    <div className="error">{formErrors.email}</div>
                  )}
                </div>

                <div className="inputs">
                  <label htmlFor="pnumber">
                    Cell phone number (we’ll send you order update
                    notification)*
                  </label>
                  <input
                    type="text"
                    name="pnumber"
                    value={contact}
                    onChange={numchange}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  {formErrors.contact && (
                    <div className="error">{formErrors.contact}</div>
                  )}
                </div>
                <button
                  className="accordion-btn"
                  type="button"
                  onClick={() => {
                    nextAccordion(1);
                    navigate("#billing-address");
                  }}
                >
                  Continue
                </button>
              </div>
            )}
          </div>

          {/* --------------------------------- accordion-2---------------------------------- */}

          <div
            className={`accordion ${isActive.a2 ? "expand" : "collapsed"}`}
            id="billing-address"
          >
            <div
              className={`  ${isActive.a2 ? "accordion_header-active" : "accordion-header"
                }`}
              onClick={() => showAccordion(2)}
            >
              <h2>Billing Address</h2>

              <a
                style={{
                  color: `${Accordion2
                    ? isActive.a2 === true
                      ? "#fff"
                      : "#48AF25"
                    : ""
                    }`,
                }}
                href="#billing-address"
              >
                Step-2
              </a>
            </div>
            {isActive.a2 ? (
              <div className="accordion-content">
                <label htmlFor="Bcountry">Country</label>
                <Select
                  styles={{
                    control: () => ({
                      margin: 0,
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: 0,
                      padding: 0,
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "97%",
                    }),
                  }}
                  className="onetap_conn_general_information_input_syn  BcountryInput mb-3 form-select"
                  placeholder="Select"
                  name="Bcountry"
                  options={Country.getAllCountries().map((item) => ({
                    value: item.isoCode,
                    label: item.name,
                  }))}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                />

                {formErrors.billingAddress?.Bcountry && (
                  <div className="error">
                    {formErrors.billingAddress.Bcountry}
                  </div>
                )}
                <div className="inputs">
                  <label htmlFor="Bstreet1">Address</label>
                  <input
                    type="text"
                    name="Bstreet1"
                    value={billingAddress.Bstreet1 ?? ""}
                    onChange={handleBillingAddressChange}
                  />
                  {formErrors.billingAddress?.Bstreet1 && (
                    <div className="error">
                      {formErrors.billingAddress.Bstreet1}
                    </div>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="Bstreet2">Apt, suite, etc</label>
                  <input
                    type="text"
                    name="Bstreet2"
                    value={billingAddress.Bstreet2}
                    onChange={handleBillingAddressChange}
                  />
                </div>
                <div className="inputs">
                  <label htmlFor="Bcity">City</label>
                  <input
                    type="text"
                    name="Bcity"
                    value={billingAddress.Bcity}
                    onChange={handleBillingAddressChange}
                  />
                  {formErrors.billingAddress?.Bcity && (
                    <div className="error">
                      {formErrors.billingAddress.Bcity}
                    </div>
                  )}
                </div>
                <label htmlFor="Bstate">State</label>
                <Select
                  styles={{
                    control: () => ({
                      margin: 0,
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: 0,
                      padding: 0,
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "97%",
                    }),
                  }}
                  className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                  placeholder="Select"
                  name="Bstate"
                  options={stateOptions}
                  value={selectedState}
                  menuPlacement="top"
                  onChange={handleStateChange}
                />
                {formErrors.billingAddress?.Bstate && (
                  <div className="error">
                    {formErrors.billingAddress.Bstate}
                  </div>
                )}
                <div className="inputs">
                  <label htmlFor="pcode">Postal code</label>
                  <input
                    type="text"
                    name="BpostalCode"
                    value={billingAddress.BpostalCode}
                    onChange={handleBillingAddressChange}
                  />
                  {formErrors.billingAddress?.BpostalCode && (
                    <div className="error">
                      {formErrors.billingAddress.BpostalCode}
                    </div>
                  )}
                </div>
                <button
                  className="accordion-btn"
                  type="button"
                  onClick={() => { handleBillingContinue() }}

                >
                  Continue
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* --------------------------------- accordion-3---------------------------------- */}

          <div
            className={`accordion ${isActive.a3 ? "expand" : "collapsed"}`}
            id="shipping"
          >
            <div
              className={`  ${isActive.a3 ? "accordion_header-active" : "accordion-header"
                }`}
              onClick={() => showAccordion(3)}
            >
              <h2>Shipping options</h2>
              {formErrors.shippingAddress?.Scountry ||
                formErrors.shippingAddress?.Sstreet1 ||
                formErrors.shippingAddress?.Scity ||
                formErrors.shippingAddress?.Sstate ||
                formErrors.shippingAddress?.SpostalCode ? (
                <a href="#shipping">Step-3</a>
              ) : (
                <a
                  style={{
                    color: `${Accordion3
                      ? isActive.a3 === true
                        ? "#fff"
                        : "#48AF25"
                      : ""
                      }`,
                  }}
                  href="#shipping"
                >
                  Step-3
                </a>
              )}
            </div>
            {isActive.a3 ? (
              <div className="accordion-content">
                <div className="same-address">
                  <input
                    type="checkbox"
                    name="sameaddress"
                    checked={same} // Bind the value of `same` to the checkbox
                    onChange={handleCheckboxChange} // Handle checkbox change
                  />
                  <p>Ship to a different address?</p>
                </div>
                {same && (<div>
                  <label htmlFor="country">Country</label>
                  {!same ? (<Select
                    styles={{
                      control: () => ({
                        margin: 0,
                        padding: 0,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      option: (provided) => ({
                        ...provided,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "97%",
                      }),
                    }}
                    className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                    placeholder="Select"
                    name="Scountry"
                    options={Country.getAllCountries().map((item) => ({
                      value: item.isoCode,
                      label: item.name,
                    }))}

                    value={selectedCountry}
                    onChange={handleCountryChangeShipping}
                  />) : (<Select
                    styles={{
                      control: () => ({
                        margin: 0,
                        padding: 0,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      option: (provided) => ({
                        ...provided,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "97%",
                      }),
                    }}
                    className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                    placeholder="Select"
                    name="Scountry"
                    options={Country.getAllCountries().map((item) => ({
                      value: item.isoCode,
                      label: item.name,
                    }))}

                    value={selectedCountryShiping}
                    onChange={handleCountryChangeShipping}
                  />)}
                  {formErrors.shippingAddress?.Scountry && (
                    <div className="error">
                      {formErrors.shippingAddress.Scountry}
                    </div>
                  )}
                  <div className="inputs">
                    <label htmlFor="Sstreet1">Address</label>
                    <input
                      type="text"
                      name="Sstreet1"
                      value={shippingAddress.Sstreet1}
                      onChange={handleShippingAddressChange}
                    />
                    {formErrors.shippingAddress?.Sstreet1 && (
                      <div className="error">
                        {formErrors.shippingAddress.Sstreet1}
                      </div>
                    )}
                  </div>
                  <div className="inputs">
                    <label htmlFor="Sstreet2">Apt, suite, etc</label>
                    <input
                      type="text"
                      name="Sstreet2"
                      value={shippingAddress.Sstreet2}
                      onChange={handleShippingAddressChange}
                    />
                  </div>
                  <div className="inputs">
                    <label htmlFor="Scity">City</label>
                    <input
                      type="text"
                      name="Scity"
                      value={shippingAddress.Scity}
                      onChange={handleShippingAddressChange}
                    />
                    {formErrors.shippingAddress?.Scity && (
                      <div className="error">
                        {formErrors.shippingAddress.Scity}
                      </div>
                    )}
                  </div>
                  <label htmlFor="Scountry">State</label>
                  {!same ? (<Select
                    styles={{
                      control: () => ({
                        margin: 0,
                        padding: 0,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      option: (provided) => ({
                        ...provided,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "97%",
                      }),
                    }}
                    className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                    placeholder="Select"
                    name="Sstate"
                    options={stateOptions}
                    value={selectedState}
                    menuPlacement="top"
                    onChange={handleStateChangeShipping}
                  />) : (<Select
                    styles={{
                      control: () => ({
                        margin: 0,
                        padding: 0,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: 0,
                        padding: 0,
                      }),
                      indicatorsContainer: () => ({
                        display: "none",
                      }),
                      option: (provided) => ({
                        ...provided,
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "97%",
                      }),
                    }}
                    className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                    placeholder="Select"
                    name="Sstate"
                    options={stateOptionsShipping}
                    value={selectedStateShipping}
                    menuPlacement="top"
                    onChange={handleStateChangeShipping}
                  />)}

                  {formErrors.shippingAddress?.Sstate && (
                    <div className="error">
                      {formErrors.shippingAddress.Sstate}
                    </div>
                  )}
                  <div className="inputs">
                    <label htmlFor="pcode">Postal code</label>
                    <input
                      type="text"
                      name="SpostalCode"
                      value={shippingAddress.SpostalCode}
                      onChange={handleShippingAddressChange}
                    />
                    {formErrors.shippingAddress?.SpostalCode && (
                      <div className="error">
                        {formErrors.shippingAddress.SpostalCode}
                      </div>
                    )}
                  </div>
                </div>)}

                <div className="options">
                  <div className="option">
                    <input
                      type="checkbox"
                      checked={selectedOption === "free"}
                      disabled={shippingAddress?.Scountry !== 'US'}
                      onChange={() => handleShippingMethod("free")}
                      style={{ display: shippingAddress?.Scountry !== 'US' ? 'none' : 'block' }}
                    />
                    <p style={{ display: shippingAddress?.Scountry !== 'US' ? 'none' : 'block' }}>Free Shipping</p>
                  </div>


                  { }
                  {rates && rates.length > 0 && shippingAddress?.Scountry !== 'US' && (
                    <div className="option">
                      <input
                        type="checkbox"
                        checked={selectedOption === "2-4"}
                        onChange={() => handleShippingMethod("2-4")}
                      />
                      {shippingAddress?.Scountry !== 'US' && rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international') ? (
                        <p>{rates.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}</p>
                      ) : (
                        <p>{rates.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}</p>
                      )}
                    </div>
                  )}
                  {((rates && rates.length > 0) && (shippingAddress?.Scountry === 'US' || shippingAddress?.Scountry === 'CA')) && (
                    <div className="option">
                      <input
                        type="checkbox"
                        checked={selectedOption === "overnight"}
                        onChange={() => handleShippingMethod("overnight")}
                      />
                      {rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international') ? (
                        <p>{rates.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.serviceName}: ${rates.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.shipmentCost}</p>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <h5 className='border-bottom pb-2'>Order Notes <span>(optional)</span> </h5>
                  <textarea className="w-100 border-0 p-2 mt-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='activitylog' value={userShippingOrderNote}
                    onChange={(e) => { SetUserShippingOrderNote(e.target.value) }} />
                </div>

                <div className='mb-3' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                  <span>Referred by (optional)</span>
                  <select
                    class="form-select py-2 p-2"
                    style={{ backgroundColor: "#f2f2f2" }}
                    name="referrer"
                    value={referrer}
                    onChange={(e) => setReferrer(e.target.value)}
                  >
                    <option selected>
                      Select
                    </option>
                    <option value="OTC connect form">
                      OTC connect form
                    </option>
                    <option value="Bing">Bing</option>
                    <option value="Blog post">Blog post</option>
                    <option value="Co-worker">Co-worker</option>
                    <option value="Friend and family">Friend and family</option>
                    <option value="Google">Google</option>
                    <option value="Direct mail">Direct mail</option>
                    <option value="Convention">Convention</option>
                    <option value="Tiktok">Tiktok</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Linkedin">Linkedin</option>
                    <option value="Text Message">Text Message</option>
                    <option value="Direct call">Direct call</option>
                    <option value="Existing OneTapConnect user">
                      Existing OneTapConnect user
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {(referrer === 'Existing OneTapConnect user' || referrer === 'Other') && (
                  <div className='mb-2'>
                    <p style={{ marginBottom: '0px' }}>Other</p>
                    <input type="text" className='w-100 border-0 p-2 no-spinners' name="referrerName" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} onChange={(e) => setReferrerName(e.target.value)} value={referrerName} />
                  </div>
                )}

                <button
                  type="button"
                  className="accordion-btn"
                  onClick={() => { handleShippingContinue() }}
                >
                  Continue
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* --------------------------------- accordion-4---------------------------------- */}

          <div
            className={`accordion ${isActive.a5 ? "expand" : "collapsed"}`}
            id="payment"
          >
            <div
              className={`  ${isActive.a5 ? "accordion_header-active" : "accordion-header"
                }`}
              onClick={() => showAccordion(5)}
            >
              <h2>Payment</h2>
              <a
                style={{
                  color: `${Accordion5
                    ? isActive.a5 === true
                      ? "#fff"
                      : "#48AF25"
                    : ""
                    }`,
                }}
                href="#payment"
              >
                Step-4
              </a>
            </div>
            {isActive.a5 ? (
              <div className="accordion-content">
                <div className="inputs">
                  <label htmlFor="card-name">Name on Card*</label>
                  <input
                    className="card-inputs"
                    type="text"
                    id="card-name"
                    value={cardInfo.cardName}
                    onChange={handleCardNameChange}
                  />
                  {paymentErrors.cardName && (
                    <div className="error">{paymentErrors.cardName}</div>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="card-number">Card number*</label>
                  <CardNumberElement
                    className="card-inputs"
                    onChange={(event) =>
                      setCardInfo({
                        ...cardInfo,
                        cardNumber: event.error
                          ? null
                          : event.complete
                            ? event.value
                            : "",
                      })
                    }
                  />
                  {paymentErrors.cardNumber && (
                    <div className="error">{paymentErrors.cardNumber}</div>
                  )}
                </div>
                <div className="names">
                  <div className="name">
                    <label htmlFor="expiry">Expiration date*</label>
                    <CardExpiryElement
                      className="card-expiry-sign-up"
                      onChange={(event) =>
                        setCardInfo({
                          ...cardInfo,
                          expiryDate: event.error
                            ? null
                            : event.complete
                              ? event.value
                              : "",
                        })
                      }
                    />
                    {paymentErrors.expiryDate && (
                      <div className="error">
                        {paymentErrors.expiryDate}
                      </div>
                    )}
                  </div>
                  <div className="name">
                    <label htmlFor="csv">CSV code*</label>
                    <CardCvcElement
                      className="card-expiry-sign-up"
                      onChange={(event) =>
                        setCardInfo({
                          ...cardInfo,
                          csv: event.error
                            ? null
                            : event.complete
                              ? event.value
                              : "",
                        })
                      }
                    />
                    {paymentErrors.csv && (
                      <div className="error">{paymentErrors.csv}</div>
                    )}
                  </div>
                </div>

                <div className="terms-conditions">
                  <input
                    type="checkbox"
                    checked={policyIsChecked}
                    onChange={() => {
                      setPolicyIsChecked(!policyIsChecked);
                    }}
                  />
                  <span>
                    By paying, you agree to our {" "}
                    <Link
                      to="/terms-of-use" target="_blank"
                      style={{ color: "#2572E6", textDecoration: "none" }}
                    >
                      terms and services,
                    </Link>{" "}
                    <Link
                      to="/refund-policy" target="_blank"
                      style={{ color: "#2572E6", textDecoration: "none" }}
                    >
                      refund policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/privacy-policy" target="_blank"
                      style={{ color: "#2572E6", textDecoration: "none" }}
                    >
                      privacy policy.
                    </Link>
                  </span>
                </div>

                <button
                  className="accordion-btn"
                  disabled={!policyIsChecked || !allFieldsFilled || isButtonClicked}
                  type="submit"
                  onClick={submitHandler}
                >
                  {buttonTextPay}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
        <div className="billing-information">
          <div className="billing-inner-div">
            <div className="checkout-product-section">
              <div className="add-one-heading">Products:</div>
              <div className="onetap_connect_user_product_headings_with_accessary_synr">
                <table className="onetap_connect_product_headings_synr">
                  {cartItems?.map((item, index) => {
                    const variation = getVariationById(item.product.variations, item.variation);
                    return (
                      <div className="d-flex align-items-center" key={index}>
                        <tr className="onetap_connect_product_row_with_para_synr">
                          <td className="onetap_connect_product_card_synr col">
                            {item?.variation ?
                              <img className="col-2" src={`${url}/product/img/${variation?.image[0]?.url}`} />
                              :
                              <img className="col-2" src={`${url}/product/img/${item.product?.image[0]?.url}`} />
                            }
                            <div className="onetap_connect_product_para_synr d-flex align-items-center">
                              {item?.variation ? variation?.Type : item.product.name}
                            </div>
                          </td>
                          <div className="onetap_connect_access_association_product_synr_main">

                            <td className="onetap_connect_access_association_product_synr">
                              <input type="number" name="product_qty" value={item.quantity} onChange={(e) => handleUpdateQuantity(item.product._id, parseInt(e.target.value), item.variation)} min={1} defaultValue={1} />
                            </td>
                            <td className="onetap_connect_access_association_product_synr">

                              {item?.variation ? (
                                <div>{item.product.isOnSale ? (variation?.saleprice * item.quantity).toFixed(2) : (variation?.price * item.quantity).toFixed(2)}</div>
                              ) : (
                                <div>{item.product.isOnSale ? <p>{(item.product.saleprice * item.quantity).toFixed(2)}</p> : <p>{(item.product.price * item.quantity).toFixed(2)}</p>}</div>
                              )}
                            </td>
                          </div>
                          {cartItems?.length > 1 && (<svg onClick={() => { handleRemoveProduct(item.product._id, item.variation) }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" style={{ marginTop: "7px", cursor: "pointer" }}>
                            <path d="M16.692 6.84375L12.449 11.0858L8.20697 6.84375L6.79297 8.25775L11.035 12.4998L6.79297 16.7418L8.20697 18.1558L12.449 13.9137L16.692 18.1558L18.106 16.7418L13.864 12.4998L18.106 8.25775L16.692 6.84375Z" fill="#E65925" />
                          </svg>)}

                        </tr>

                      </div>
                    )
                  })}
                </table>

              </div>
            </div>
            <span className="divider"></span>
            <div className="charges-section">
              <div className="charges">
                <p>Sub Total</p>
                <span>${productTotalPrice ? productTotalPrice.toFixed(2) : "00.00"}</span>
              </div>
              {
                isCouponApplied &&
                <div className="charges">
                  <p>Discount</p>
                  {fetchedCoupon.discounValue > 0 &&
                    <span>
                      -$ {fetchedCoupon.discounValue}
                    </span>
                  }
                </div>
              }
              <div className="charges">
                <p>Shipping</p>
                <span>
                  {selectedOption === "free" && "Free Shipping"}
                  {selectedOption === "2-4" && (`$${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}`)}
                  {selectedOption === "overnight" && (`$${rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.shipmentCost}`)}
                </span>
              </div>
              <div className="charges">
                <p>Tax ({taxPercentage}%)</p>
                <span>${((updatedYearlyPrice * taxPercentage) / 100).toFixed(2)}</span>
              </div>
            </div>
            <div className="discount-input-section">
              <div>
                {!visibleCouponForm ? (
                  <label htmlFor="discount-code" className="discount-code" onClick={() => { setvisibleCouponForm(!visibleCouponForm) }} style={{ cursor: 'pointer' }}>Add discount code</label>
                ) : (
                  <label htmlFor="discount-code" className="discount-code" onClick={removeAppliedCoupon} style={{ cursor: 'pointer' }}>Remove discount code</label>
                )
                }
              </div>
              {
                visibleCouponForm &&
                <div className="discountForm">
                  <input name="couponCode" onChange={(e) => { couponInputHandler(e) }} type="text" value={couponCode[0]} />
                  <button className="applyCoupon" disabled={applyCouponbtnActive} onClick={couponHandler} style={{ border: 'none' }}>Apply</button>
                </div>
              }
            </div>
            <div className="total-btn">
              <h3>Total</h3>
              <span>
                ${updatedYearlyPrice ? ((updatedYearlyPrice - totalDiscountValue) + (updatedYearlyPrice * taxPercentage) / 100 + (calculateShippingCost().cost || 0)).toFixed(2) : "00.00"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="popup" className="popup">
        <div class="popup-content" id="popup-content">
          <div id="payment-success" className="payment-success">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.1796 123C55.4032 123 52.6267 121.956 50.4972 119.842L9.17404 78.8107C4.94199 74.6086 4.94199 67.7834 9.17404 63.5813C13.4061 59.3792 20.2798 59.3792 24.5119 63.5813L58.1796 97.011L125.488 30.1516C129.72 25.9495 136.594 25.9495 140.826 30.1516C145.058 34.3537 145.058 41.1789 140.826 45.381L65.835 119.842C63.7325 121.929 60.9561 123 58.1796 123Z"
                fill="green"
              />
            </svg>

            <p style={{ fontSize: "20px" }}>Payment Successful!</p>
            <button
              className="onetap_conn_move_dashboard_button"
              onClick={redirectToDashboard}
            >
              Continue to dashboard
            </button>
          </div>
          <div id="payment-failure" className="payment-failure">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.5938 112.453L112.397 37.5371M112.397 112.453L37.5938 37.5371"
                stroke="red"
                stroke-width="20"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p style={{ fontSize: "20px" }}>{cardErrorMessage}</p>
            <button
              className="onetap_conn_move_dashboard_button"
              onClick={hideElements}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestCheckout;
