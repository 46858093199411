import { Box, Modal } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import searchIcon from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/searchIcon.svg"
import selectDropdownIcon from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
import Noimagefound from "../../OTC-AdminPanel/Otc_assets/images/Noimagefound.png"
import Loader from "../../components/Layout/Loader/Loader";
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function Otc_Addonsinfo({ showCreateAddon, onAddonNameClick, onClickSave }) {
    const warningModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "#FDE9E9",
        p: 3,
        outline: "none",
        border: "1px solid E62525",
        borderRadius: "10px",
    };
    const { adminuser } = useSelector((state) => state.OtcAdminuser);
    const url = process.env.REACT_APP_URL;
    const [isPermalinkEditable, setIsPermalinkEditable] = useState(false);
    const [showCreateProduct, setShowCreateProduct] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState();
    const [addonspaymentType, setaddonsPaymentType] = useState('subscription');
    const [isUserBased, setIsUserBased] = useState(false);
    const [isSynchronize, setIsSynchronize] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageNameError, setImageNameError] = useState('');
    const [altTextError, setAltTextError] = useState('');
    const [formData, setFormData] = useState({});
    const [selectedImageUpload, setselectedImageUpload] = useState(null)
    const [addons, setAddons] = useState([])
    const [selectedPCId, setSelectedPCId] = useState("")
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [CategoriesData, setCategoriesData] = useState([]);
    const [filteredCategoriesData, setFilteredCategoriesData] = useState([]);
    const [publicNameError, setPublicNameError] = useState('');
    const [permalinkError, setPermalinkError] = useState('');
    const [userbasedError, setUserbasedError] = useState('');
    const [shortDescriptionError, setShortDescriptionError] = useState('');
    const [internalNameError, setInternalNameError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedType, setSelectedType] = useState("Type");
    const [selectedStatus, setSelectedStatus] = useState("Status");
    const [selectedVisibility, SetSelectedVisibility] = useState("Visibility");
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    useEffect(() => {
        if (!showCreateAddon) {
            resetCategoryData();
        }
    }, [showCreateAddon]);

    const PublishedByname = `${adminuser?.firstName} ${adminuser?.lastName}`;

    const resetCategoryData = () => {
        setCurrentDateTime('')
        setSelectedPCId('')
        setselectedImageUpload(null)
        setSelectedImage(null)
        setaddonsPaymentType("subscription")
        const defaultFormData = {
            paymentType: "subscription",
            status: "Published",
            visibility: "Public",
        };
        setFormData(defaultFormData);
        setEditorState('')
        setEditorState2('')
    };

    const handlePaymentTypeChange = (e) => {
        const newPaymentType = e.target.value;
        setaddonsPaymentType(newPaymentType);
        // Clear fields for the inactive payment type
        if (newPaymentType === 'onetimepayment') {
            // Clear fields for subscription 
            setFormData((prevData) => ({
                ...prevData,
                Addonspaymentdata: {},
            }));
        } else if (newPaymentType === 'subscription') {
            // Clear fields for onetimepayment
            setFormData((prevData) => ({
                ...prevData,
                Addonspaymentdata: {},
            }));
        }
    };

    const handleUserBasedChange = (e) => {
        setIsUserBased(e.target.checked);
        setFormData({
            ...formData,
            Addonspaymentdata: {
                ...formData.Addonspaymentdata,
                isuserbased: e.target.checked,
            },
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {


            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setselectedImageUpload(file);

        }
    };

    const getAddons = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${url}/admin/getAddons`, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            setAddons(data.Addons)
            setLoading(false)
        } catch (error) {
            ;
        }
    }

    useEffect(() => {
        getAddons()
    }, [])

    const fetchcategory = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/productCategory/fetch`);
            setCategoriesData(data.categories)
        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        fetchcategory()
    }, [])

    useEffect(() => {

        const filteredData = CategoriesData?.filter(item => {
            // Check if categoryType is 'Add-on', status is 'Published', and visibility is 'Public'
            return (
                item.categoryType === 'Add-on' &&
                item.status === 'Published' &&
                item.Visibility === 'Public'
            );
        });
        setFilteredCategoriesData(filteredData);

    }, [CategoriesData]); // Trigger the filter effect when CategoriesData changes

    const handlecategoryChange = (e) => {
        const selectedCategoryId = e.target.value;

        // Update formData with the selected category _id
        setFormData({
            ...formData,
            category: selectedCategoryId,
        });
    };

    const handleChange = (e, name) => {
        const value = typeof e === "string" ? e : e.target.value;
        name = name || e.target.name;

        if (name.includes(".")) {
            const [outerField, innerField] = name.split(".");
            setFormData({
                ...formData,
                [outerField]: {
                    ...formData[outerField],
                    [innerField]: value,
                },
            });


        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        setFormData({
            ...formData,
            paymentType: addonspaymentType,
        });
    }, [addonspaymentType])

    // Filter the products based on selected filters
    const filteredProducts = addons?.filter((addon) => {
        const matchesSearch = addon?.publicname?.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesType = selectedType === "Type" || addon?.paymentType?.toLowerCase() === selectedType?.toLowerCase();
        const matchesStatus = selectedStatus === "Status" || addon?.status?.toLowerCase() === selectedStatus?.toLowerCase();
        const matchesVisibility = selectedVisibility === "Visibility" || addon?.visibility?.toLowerCase() === selectedVisibility?.toLowerCase();
        return matchesSearch && matchesType && matchesStatus && matchesVisibility;
    });


    const handleSaveAddon = async (e) => {
        const errors = {
            publicNameError: "",
            internalNameError: "",
            priceError: "",
            shortDescriptionError: "",
            permalinkError: "",
            userbasedError: ""
        };

        if (formData.publicname?.trim() === "" || !formData.publicname) {
            errors.publicNameError = ("Public name cannot be empty.");

        } else {
            setPublicNameError(null);
        }
        if (formData?.CustomPermalink?.trim() === "" || !formData.CustomPermalink) {
            errors.permalinkError = ("Permalink cannot be empty.");

        } else {
            setPermalinkError(null);
        }
        if (isUserBased) {
            if (
                (formData.Addonspaymentdata?.yearlyuserprice?.toString().trim() === "" || !formData.Addonspaymentdata?.yearlyuserprice) ||
                (formData.Addonspaymentdata?.monthlyuserprice?.toString().trim() === "" || !formData.Addonspaymentdata?.monthlyuserprice) ||
                (formData.Addonspaymentdata?.numofuser?.toString().trim() === "" || !formData.Addonspaymentdata?.numofuser)
            ) {
                errors.userbasedError = "Please enter all fields related to user-based add-on.";
            } else {
                setUserbasedError(null);
            }
        }

        if (formData.internalname?.trim() === "" || !formData.internalname) {
            errors.internalNameError = ("Internal name cannot be empty.");

        } else {
            setInternalNameError(null);
        }
        if ((addonspaymentType === 'onetimepayment') && (formData?.Addonspaymentdata?.price?.toString().trim() === "" || !formData?.Addonspaymentdata?.price)) {
            errors.priceError = ("Price cannot be empty.");
        } else if ((addonspaymentType === 'onetimepayment') && (formData?.Addonspaymentdata?.sku?.trim() === "" || !formData?.Addonspaymentdata?.sku)) {
            errors.priceError = ("SKU cannot be empty.");
        } else {
            setPriceError(null);
        }

        if ((addonspaymentType === 'subscription') && ((formData?.Addonspaymentdata?.yearlyprice?.toString().trim() === "" || !formData?.Addonspaymentdata?.yearlyprice) || (formData?.Addonspaymentdata?.monthlyprice?.toString().trim() === "" || !formData?.Addonspaymentdata?.monthlyprice))) {
            errors.priceError = ("Price cannot be empty.");
        } else if ((formData.paymentType === 'subscription') && ((formData?.Addonspaymentdata?.yearlysku?.trim() === "" || !formData?.Addonspaymentdata?.yearlysku) || (formData?.Addonspaymentdata?.monthlysku?.trim() === "" || !formData?.Addonspaymentdata?.monthlysku))) {
            errors.priceError = ("SKU cannot be empty.");
        } else {
            setPriceError(null);
        }
        if (formData.internalname?.trim() === "" || !formData.internalname) {
            errors.internalNameError = ("Internal  name cannot be empty.");

        } else {
            setInternalNameError(null);
        }
        const hasErrors = Object.values(errors).some((error) => error !== "");

        if (hasErrors) {
            setInternalNameError(errors.internalNameError);
            setPublicNameError(errors.publicNameError);
            setPriceError(errors.priceError);
            setPermalinkError(errors.permalinkError)
            setUserbasedError(errors.userbasedError)
            return;
        }
        if (selectedImage) {
            if (formData.imagename?.trim() === '' || !formData.imagename) {
                setImageNameError("Please enter Image name.");
                return;
            }
        }

        if (selectedImage) {
            if (formData.imagealttaxt?.trim() === '' || !formData.imagealttaxt) {
                setAltTextError("Please enter Alt text.");
                return
            }
        }

        const imageformData = new FormData();
        imageformData.append("image", selectedImageUpload);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        };
        const { data } = await axios.post(
            `${url}/admin/imageUpload?imageType=addonsimage`,
            imageformData,
            config
        );
        if (data.success) {
            let Updatedformdata;

            if (e === "Delete") {
                Updatedformdata = {
                    ...formData,
                    image: selectedImage ? data.imageNames[0].name : "",
                    status: "Archived"
                }
            } else {
                Updatedformdata = {
                    ...formData,
                    image: selectedImage ? data.imageNames[0].name : "",
                }
            }
            try {
                const addonData = {
                    id: selectedPCId._id,
                    updatedData: Updatedformdata,
                    name: PublishedByname
                };

                const response = await axios.post(`${url}/admin/updateAddons`, addonData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );
                if (response.status === 201 || response.status === 200) {
                    setFormData({})
                    setaddonsPaymentType("subscription")
                    onClickSave();
                    getAddons()
                } else {

                }
            } catch (error) {

                setPriceError("SKU must be unique.")
            }
        }
    };
    const handleCardClick = (selectedCard) => {
        const rawPublishedDate = new Date(selectedCard.createdAt); // Replace this with your actual date

        // Format the date and time
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(rawPublishedDate);

        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        }).format(rawPublishedDate);

        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
        setCurrentDateTime(formattedDateTime)
        setSelectedPCId(selectedCard)

        setFormData({
            ...selectedCard
        });
        if (selectedCard?.image !== "") {
            setSelectedImage(`${url}/admin/addons/img/${selectedCard.image}`);
        }
        setaddonsPaymentType(selectedCard?.paymentType)
        setShowCreateProduct(true);
        setIsUserBased(selectedCard?.Addonspaymentdata?.isuserbased);
        setIsSynchronize(selectedCard?.Addonspaymentdata?.issynchronize)
        setEditorStateFromHtml(selectedCard?.shortDescription, setEditorState);
        setEditorStateFromHtml(selectedCard?.longdescription, setEditorState2);
    };


    const clearFilters = () => {
        setSelectedStatus("Status");
        setSelectedType("Type");
        SetSelectedVisibility("Visibility");
    };
    const isFilterApplied =
        selectedStatus !== "Status" ||
        selectedType !== "Type" ||
        selectedVisibility !== "Visibility";

    const filterbtnStyle = {
        padding: "3px 15px",
        backgroundColor: "#e65925", // Change to your desired button color
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        display: isFilterApplied ? "block" : "none", // Show the button conditionally
        marginLeft: "auto",
        marginBottom: "auto",
        marginTop: "6px",
        height: "30px",
    };

    const handleWarningOpenModal = () => { setOpenWarningModal(true) }
    const handleCloseWarningdModal = () => { setOpenWarningModal(false) };

    const onEditorStateChange = (newEditorState, propertyName) => {
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const html = draftToHtml(rawContentState);
        console.log(`${propertyName} editorState`, html);

        setFormData({
            ...formData,
            [propertyName]: html
        });

        // Update the corresponding editor state
        switch (propertyName) {
            case 'shortDescription':
                setEditorState(newEditorState);
                break;
            case 'longdescription':
                setEditorState2(newEditorState);
                break;
            default:
                break;
        }
    };
    const setEditorStateFromHtml = (htmlContent, setEditorStateFunc) => {
        if (htmlContent) {
            const blocksFromHTML = htmlToDraft(htmlContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorStateFunc(newEditorState);
        }
    };
    return (
        <>
            {!showCreateAddon
                ?
                <div className="onetap_conn_users_tab_content_OTC_Admin">
                    <div className="">
                        <div className="plan-search-and-status">
                            <div className="plan-search-container">
                                <input
                                    style={{ paddingRight: "50px" }}
                                    className="plan-search-input"
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <img className='plan-search-icon' src={searchIcon} alt="" />
                            </div>
                            <div className="plan-status-dropdown-div">
                                <select
                                    name="status"
                                    id="status"
                                    className='pc-status-dropdown'
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                >
                                    <option value="Type">Type</option>
                                    <option value="onetimepayment">One Time Payment</option>
                                    <option value="subscription">Subscription</option>
                                </select>
                                <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                            </div>
                            <div className="plan-status-dropdown-div">
                                <select
                                    name="status"
                                    id="status"
                                    className='pc-status-dropdown'
                                    value={selectedVisibility}
                                    onChange={(e) => SetSelectedVisibility(e.target.value)}
                                >   <option value="Visibility">Visibility</option>
                                    <option value="Public">Public</option>
                                    <option value="Hidden">Hidden</option>
                                </select>
                                <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                            </div>
                            <div className="plan-status-dropdown-div">
                                <select
                                    name="status"
                                    id="status"
                                    className='pc-status-dropdown'
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="Status">Status</option>
                                    <option value="Published">Published</option>
                                    <option value="Draft">Draft</option>
                                    <option value="Archived">Archived</option>
                                </select>
                                <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                            </div>

                        </div>
                        {isFilterApplied && (
                            <button style={filterbtnStyle} onClick={clearFilters}>
                                Clear Filters
                            </button>
                        )}
                        <div className="pc-categories-main-section">
                            <div className="plan-category-title">
                                <div className="">
                                    <span></span>
                                    <div className="product-details">
                                        <span className="product-name-text"></span>
                                    </div>
                                </div>
                                <span className='pc-category-title-text' style={{ width: '70%' }}>Type</span>
                                <span className='pc-category-title-text'>Price</span>
                                <span className='pc-category-title-text'>Visibility</span>
                                <span className='pc-category-title-text'>Status</span>
                            </div>

                            <div className="pc-category-card-section">

                                {loading ? <Loader /> : filteredProducts?.map((Addons) => (<div className="plan-category-cards">
                                    <div className="pc-img-and-text-div" onClick={() => { handleCardClick(Addons); onAddonNameClick() }} >
                                        <div className="pc-img-div" style={{ cursor: "pointer" }}>
                                            {Addons.image !== "" ? <img className="pc-img" src={`${url}/admin/addons/img/${Addons.image}`} alt="" /> : <img className="pc-img" src={Noimagefound} alt="" />}
                                        </div>
                                        <div style={{ cursor: "pointer" }} className="product-details">
                                            <span className="product-name-text" >{Addons.publicname}</span>
                                        </div>
                                    </div>
                                    <span className={`status-text-smart-acc`} style={{ width: '70%', fontWeight: '500' }}>{Addons.paymentType === "onetimepayment" ? "One Time Payment" : "Subscription"} </span>
                                    <span className={`status-text-smart-acc`}> {Addons.paymentType === "subscription" ? `${Addons.Addonspaymentdata?.monthlyprice}(Month) & ${Addons.Addonspaymentdata?.yearlyprice}(Year)` : Addons.Addonspaymentdata?.price} </span>
                                    <span className={`status-text-smart-acc`}> {Addons.visibility} </span>
                                    <span className={`status-text-smart-acc ${Addons.status === 'Published' ? 'published-text' : Addons.status === 'Archived' ? 'archived-text' : ''}`}>
                                        {Addons.status} </span>
                                </div>))}

                            </div>

                        </div>
                    </div>
                </div>

                :
                <>
                    <div className="row">
                        <div className="w-100  d-flex flex-row-reverse gap-3 plan-btn-top">
                            <div class="dropdown">
                                <button class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: '#000', color: '#fff', }}>
                                    <span className='me-5'>Actions</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ left: 'unset' }}>

                                    <a class="dropdown-item" href="#" onClick={handleWarningOpenModal}>Archive</a>
                                </div>
                            </div>

                            {selectedPCId && selectedPCId.status !== 'Draft' ? (<button
                                disabled={formData.status === 'Draft'}
                                className="btn px-3 py-2 text-white"
                                onClick={() => {
                                    handleSaveAddon()
                                }}
                                style={{ background: '#E65925', width: '15%' }}
                            >
                                Update
                            </button>) : (<button className="btn px-3 py-2  text-white" style={{ background: '#48AF25', width: '15%' }} onClick={handleSaveAddon} disabled={formData.status === 'Draft'}> {formData.status === 'Archived' ? 'Archive' : 'Publish'} </button>)
                            }
                            <button
                                className="btn px-3 py-2 text-white"
                                disabled={formData.status !== 'Draft'}
                                style={{
                                    background: formData.status !== 'Draft' ? '#8A8A8A' : 'black',
                                    width: '15%',
                                }}
                                onClick={() => handleSaveAddon()}
                            >
                                Save draft
                            </button>



                        </div>
                    </div >
                    <div className="row" >
                        <div className="col-8">
                            <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                                <h5>Internal add-on’s Name*</h5>
                                <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="internalname"
                                    value={formData.internalname || ""}
                                    onChange={(e) => { handleChange(e); setInternalNameError("") }} />
                                {internalNameError && (<div className="error">{internalNameError} </div>
                                )}
                            </div>
                            <div className="bg-white p-4 mt-3" style={{ borderRadius: '8px' }}>
                                <h5>Add-on Public Name*</h5>
                                <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="publicname"
                                    value={formData.publicname || ""}
                                    onChange={(e) => {
                                        if (!isPermalinkEditable) {
                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setFormData({
                                                ...formData,
                                                publicname: e.target.value,
                                                CustomPermalink: slug,
                                            });
                                            setPublicNameError(""); setPermalinkError("")
                                        } else {
                                            handleChange(e); setPublicNameError("")
                                        }
                                    }}
                                />
                                {publicNameError && (<div className="error">{publicNameError} </div>
                                )}
                            </div>
                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Permalink*</h5>
                                <div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input className="w-100 border-0 me-2" type="text" style={{ background: "#f2f2f2" }}
                                        readOnly={!isPermalinkEditable} name="CustomPermalink"
                                        value={formData.CustomPermalink || ""}
                                        onChange={(e) => {
                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setFormData({
                                                ...formData,
                                                CustomPermalink: slug,
                                            }); setPermalinkError("")
                                        }}

                                    />
                                    <span style={{ color: '#E65925' }} role="button" onClick={() => setIsPermalinkEditable(!isPermalinkEditable)} >Edit</span>
                                </div> {permalinkError && (<div className="error">{permalinkError} </div>
                                )}
                            </div>

                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Add-on Description</h5>
                                <p className="mb-0 mt-2">Short description</p>
                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="shortDescription"
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'shortDescription')}
                                />
                                <p className="mb-0">Long description</p>
                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="longdescription"
                                    editorState={editorState2}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'longdescription')}
                                />
                            </div>
                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                    <h5 >Add-on Payment Type</h5>
                                    <div className="onetap_conn_filter_search_OTC_Admin" style={{ width: '30%' }}>
                                        <select value={addonspaymentType} onChange={handlePaymentTypeChange} style={{ appearance: 'none', outline: 'none', cursor: 'pointer' }} className=".onetap_conn_filter_search_OTC_Admin select">
                                            <option value="subscription">Subscription</option>
                                            <option value="onetimepayment">One time payment</option>
                                        </select>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none" class="onetap_conn_filter_svg_OTC_Admin"><path d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z" fill="#000000"></path></svg>
                                    </div></div>
                                {addonspaymentType === 'subscription' && (
                                    <div>
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="monthlyPrice">Monthly Price*</label>
                                                    <input className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} name="Addonspaymentdata.monthlyprice"
                                                        value={formData.Addonspaymentdata?.monthlyprice || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                        min='0' onWheel={(e) => {
                                                            e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                            e.preventDefault(); // Prevent mouse wheel changes
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="monthlySku">Monthly SKU*</label>
                                                    <input
                                                        className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                        name="Addonspaymentdata.monthlysku"
                                                        value={formData.Addonspaymentdata?.monthlysku || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="yearlyPrice">Yearly Price*</label>
                                                    <input
                                                        className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                        name="Addonspaymentdata.yearlyprice"
                                                        value={formData.Addonspaymentdata?.yearlyprice || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                        min='0' onWheel={(e) => {
                                                            e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                            e.preventDefault(); // Prevent mouse wheel changes
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="yearlySku">Yearly SKU*</label>
                                                    <input
                                                        className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                        name="Addonspaymentdata.yearlysku"
                                                        value={formData.Addonspaymentdata?.yearlysku || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {priceError && (<div className="error mb-2">{priceError}</div>
                                        )}
                                        <div className="row mb-3" style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                checked={isUserBased}
                                                onChange={handleUserBasedChange}
                                                id="addonUserBased"
                                                style={{ marginLeft: '12px', width: '2%' }}
                                            />
                                            <p style={{ marginBottom: '0px', paddingLeft: '5px' }}>
                                                Add-on Price is User Based
                                            </p>
                                        </div>

                                        {isUserBased && (
                                            <>
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="userIncluded">Number of Users Included in Default Price</label>
                                                            <input
                                                                className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                                name="Addonspaymentdata.numofuser"
                                                                value={formData.Addonspaymentdata?.numofuser || ""}
                                                                onChange={(e) => { handleChange(e); setPriceError(""); setUserbasedError("") }}
                                                                min='0' onWheel={(e) => {
                                                                    e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                                    e.preventDefault(); // Prevent mouse wheel changes
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="monthlyUserPrice">Monthly User Price*</label>
                                                            <input
                                                                className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                                name="Addonspaymentdata.monthlyuserprice"
                                                                value={formData.Addonspaymentdata?.monthlyuserprice || ""}
                                                                onChange={(e) => { handleChange(e); setPriceError(""); setUserbasedError("") }}
                                                                min='0' onWheel={(e) => {
                                                                    e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                                    e.preventDefault(); // Prevent mouse wheel changes
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="yearlyUserPrice">Yearly User Price*</label>
                                                            <input
                                                                className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                                                                name="Addonspaymentdata.yearlyuserprice"
                                                                value={formData.Addonspaymentdata?.yearlyuserprice || ""}
                                                                onChange={(e) => { handleChange(e); setPriceError(""); setUserbasedError("") }}
                                                                min='0'
                                                                onWheel={(e) => {
                                                                    e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                                    e.preventDefault(); // Prevent mouse wheel changes
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userbasedError && (<div className="error">{userbasedError}</div>)}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                {addonspaymentType === 'onetimepayment' && (
                                    <div className="">
                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="monthlyPrice">Price*</label>
                                                    <input className="w-100 border-0 p-2 no-spinners" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="Addonspaymentdata.price"
                                                        value={formData.Addonspaymentdata?.price || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                        min='0' onWheel={(e) => {
                                                            e.currentTarget.blur(); // Remove focus to prevent wheel change
                                                            e.preventDefault(); // Prevent mouse wheel changes
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="monthlySku">SKU*</label>
                                                    <input
                                                        className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="Addonspaymentdata.sku"
                                                        value={formData.Addonspaymentdata?.sku || ""}
                                                        onChange={(e) => { handleChange(e); setPriceError("") }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {priceError && (<div className="error">{priceError} </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-white" style={{ padding: '30px', borderRadius: '4px' }}>
                                <h5>Add-on Information</h5>
                                <div className="gap-3 d-flex flex-column">
                                    <div className="mt-2">
                                        <span>Status*</span>
                                        <select class="form-select" style={{ backgroundColor: '#f2f2f2' }} name="status"
                                            value={formData.status}
                                            onChange={handleChange} >
                                            <option value="Published" selected>Published</option>
                                            <option value="Draft" >Draft</option>
                                            <option value="Archived">Archived</option>
                                        </select>
                                    </div>
                                    <div>
                                        <span>Visibility*</span>
                                        <select class="form-select" style={{ backgroundColor: '#f2f2f2' }} name="visibility" value={formData.visibility}
                                            onChange={handleChange}>
                                            <option value="Public" selected>Public</option>
                                            <option value="Hidden">Hidden</option>
                                        </select>
                                    </div>
                                    <div>
                                        <span>Published date</span>
                                        <input className="w-100 border-0 py-2 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} value={currentDateTime} readOnly />
                                    </div>
                                    <div>
                                        <span>Published by</span>
                                        <input className="w-100 border-0 py-2 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="publishedBy"
                                            value={formData.publishedBy}
                                            readOnly />
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 gap-2 d-flex flex-column mt-2" style={{ borderRadius: '8px' }}>
                                <h5>Add-ons Categories</h5>
                                {filteredCategoriesData?.map(category => (
                                    <div key={category._id} style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            value={category._id}
                                            onChange={handlecategoryChange}
                                            checked={formData.category === category._id}
                                            style={{ width: '10%', cursor: 'pointer', transform: 'scale(1.5)', }}
                                        />
                                        <label style={{ textTransform: 'capitalize' }}>{category.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-white p-4 gap-2 d-flex flex-column mt-2" style={{ borderRadius: '8px' }}>
                                <div>
                                    <h5>Featured Image</h5>
                                    {selectedImage ? (
                                        <label style={{ width: "100%" }} htmlFor="imageUpload" className="upload-label">
                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept="image/*"
                                                name='productcategoryimage'
                                                style={{ display: 'none' }}
                                                onChange={handleImageUpload}
                                            />
                                            <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '0' }}>
                                                <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                                            </div>
                                        </label>
                                    ) : (
                                        <label style={{ width: "100%" }} className="upload-label" htmlFor="imageUpload">
                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept="image/*"
                                                name='productcategoryimage'
                                                style={{ display: 'none' }}
                                                onChange={handleImageUpload}
                                            />
                                            <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '50px' }}>
                                                <div style={{ cursor: "pointer" }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
                                                        <path
                                                            d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                                                            fill="#838383"
                                                        />
                                                    </svg>
                                                    <p>Upload</p>
                                                </div>
                                            </div>
                                        </label>
                                    )}
                                </div>
                                {selectedImage && (<div className='pc-remove-img' onClick={() => setSelectedImage('')}>Remove image</div>)}
                                <div>
                                    <span>Image Name</span>
                                    <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='imagename' value={formData.imagename || ""}
                                        onChange={(e) => { handleChange(e); setImageNameError("") }} />
                                    {imageNameError && <div className="error">{imageNameError}</div>}
                                </div>
                                <div>
                                    <span>Alt text</span>
                                    <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='imagealttaxt' value={formData.imagealttaxt || ""}
                                        onChange={(e) => { handleChange(e); setAltTextError("") }} />
                                    {altTextError && <div className="error">{altTextError}</div>}
                                </div>
                            </div>
                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                <h5>Activity Log</h5>
                                <textarea className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="activityLog"
                                    value={formData.activityLog || ""}
                                    disabled
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </>
            }
            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={warningModalStyle}>
                    <div className="video-Modal-section">
                        <h2 className="warning-header">
                            Warning
                        </h2>
                        <span className="warning-text-smart-acc">Are you sure you want to Archive this Add-on?</span>
                        <div className="video-Modal-btn-group mt-4" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={() => { handleCloseWarningdModal(); handleSaveAddon("Delete") }}>Delete</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
                        </div>

                    </div>
                </Box>
            </Modal>
        </>
    )
}
