import React, { useEffect, useState } from "react";
import {
     fetchCart, setLocalStorageChangeFlag
} from "../../../actions/userAction/userAction";
import axios from "axios";
import Header2 from '../../../components/Layout/Header/Header2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./ShopSmartAccessories.css"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLocation } from 'react-router-dom';


export default function ProductPage() {
    const { state } = useLocation()
    const id = state.productId;
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "35%",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: "10px",
    };
    const url = process.env.REACT_APP_URL;
    const { user, isAuthenticated, loading, message, error } = useSelector(
        (state) => state.user
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [product, setProduct] = useState()
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [productQuantities, setProductQuantities] = useState({});
    const [initialAddedProducts, setInitialAddedProducts] = useState([]);
    const [addedProducts, setAddedProducts] = useState(initialAddedProducts);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [hasInitial, setHasInitial] = useState(false);
    const { Cartproducts } = useSelector(
        (state) => state.Cartproducts
    );
    useEffect(() => {
        if (!Cartproducts) {
            dispatch(fetchCart(user))
        }
        setInitialAddedProducts(Cartproducts);

    }, [user, Cartproducts])
    useEffect(() => {
        if (initialAddedProducts?.length > 0 && !hasInitial) {
            setAddedProducts([...initialAddedProducts]);
            setHasInitial(true);
        }
    }, [initialAddedProducts, hasInitial]);
    const fetchProducts = async () => {
        try {
            const { data } = await axios.get(`${url}/product/getproduct/${id}`);
            setProduct(data?.products)
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchProducts()
    }, [])


    const updateProductQuantity = (productId, quantity) => {
        setProductQuantities({ ...productQuantities, [productId]: quantity });
    };

    const handleAddToCart = (product) => {
        const productId = product._id;
        const quantity = productQuantities[productId] || 1;
        const variation = selectedVariation;

        // Check if the product is already in the cart
        const existingProductIndex = addedProducts?.findIndex(
            (addedProduct) => addedProduct.product._id === productId && addedProduct?.variation === variation
        );

        if (existingProductIndex !== -1) {
            // Product with the same variation already exists in the cart, update the quantity
            const updatedProducts = addedProducts?.map((addedProduct, index) => {
                if (index === existingProductIndex) {
                    return {
                        ...addedProduct,
                        quantity: addedProduct.quantity + quantity,
                    };
                }
                return addedProduct;
            });

            setAddedProducts(updatedProducts);
        } else {
            // Product with this variation is not in the cart, add it
            const addedProduct = { product, quantity, variation };
            setAddedProducts([...addedProducts, addedProduct]);
        }
        // Display success message or navigate to the cart page, etc.
        setOpenModal(true);
    };


    const updateCart = async () => {
        try {
            // Extract product IDs, quantities, and variations from addedProducts
            const productData = addedProducts.map(item => ({
                product: item.product,
                quantity: item.quantity,
                variation: item.variation || null,
            }));

            // Get existing data from local storage
            const existingCartData = JSON.parse(localStorage.getItem('cart')) || [];

            // Merge the existing data with the new data
            const updatedCartData = existingCartData.map(existingItem => {
                const matchingProduct = productData.find(newItem => newItem.id === existingItem.id && newItem.variation === existingItem.variation);

                if (matchingProduct) {
                    // Update the quantity if the product with the same ID and variation already exists
                    return {
                        ...existingItem,
                        quantity: existingItem.quantity + matchingProduct.quantity,
                    };
                } else {
                    // Return the existing item if neither the product ID nor the variation ID matches
                    return existingItem;
                }
            });

            // Add any new products that don't exist in the existing data
            productData.forEach(newItem => {
                const existsInUpdatedCart = updatedCartData.some(existingItem => existingItem.id === newItem.id && existingItem.variation === newItem.variation);

                if (!existsInUpdatedCart) {
                    updatedCartData.push(newItem);
                }
            });

            if (!user) {
                // Store the updated data in local storage
                localStorage.setItem('cart', JSON.stringify(updatedCartData));
                dispatch(setLocalStorageChangeFlag(true));
            }

            const _id = user?._id
            // Make a POST request to your backend API endpoint using Axios
            const { data } = await axios.post(
                `${url}/cart/update`,
                { addedProducts, _id },

                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );


            dispatch(fetchCart(user));
        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        updateCart()
    }, [addedProducts])
    return (
        <div className=" mb-5" style={{ backgroundColor: "" }}>
            <Header2 />
            <div className="container mt-4">
                <div className="row">
                    <h5>Product details</h5>
                    <div className="col-6 d-flex mb-2 gap-3" >
                        <div className="w-25" style={{ maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden' }}>
                            {product?.media?.map((image, index) => {
                                return (
                                    <div className="mb-3 p-1 d-flex align-items-center justify-content-center" key={index} onClick={() => setSelectedImage(image)} style={{ background: selectedImage === image ? "white" : '', }}>
                                        {image.endsWith('.mp4') ? (

                                            <video
                                                className="video-player"
                                                controls
                                                width={120}
                                                height={120}
                                            >
                                                <source src={`${url}/product/img/${image}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <img
                                                className="img-fluid"
                                                src={`${url}/product/img/${image}`}
                                                width={150}
                                                height={150}
                                                alt="product Image"
                                                role="button"
                                            />
                                        )}
                                    </div>
                                )
                            })}

                        </div>

                        <div className="main_img_div ">

                            {selectedImage ? (
                                selectedImage.endsWith('.mp4') ? (
                                    <video
                                        className="video-player"
                                        controls
                                        width="350px"
                                        height="350px"
                                    >
                                        <source src={`${url}/product/img/${selectedImage}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        className="img-fluid"
                                        src={`${url}/product/img/${selectedImage}`}
                                        alt="product Image"
                                        width="350px"
                                        height="350px"
                                    />
                                )
                            ) : (
                                <img
                                    className="img-fluid"
                                    src={`${url}/product/img/${product?.image[0]?.url}`}
                                    alt="product Image"
                                    width="350px"
                                    height="350px"
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-6 mb-2">
                        <h1>{product?.name ? product.name.charAt(0).toUpperCase() + product.name.slice(1) : ''}</h1>
                        {product?.hasVariations ? (
                            <p style={{ color: '#E65925', fontWeight: '600' }}>
                                Starting at{' '}
                                {Math.min(
                                    ...product.variations.map(variation =>
                                        product?.isOnSale ? variation?.saleprice : variation?.price
                                    )
                                ).toFixed(2)}
                            </p>
                        ) : (
                            <p style={{ color: '#E65925', fontWeight: '600' }}>
                                Starting at {product?.isOnSale ? product?.saleprice?.toFixed(2) : product?.price?.toFixed(2)}
                            </p>
                        )}
                        <p
                            dangerouslySetInnerHTML={{ __html: product?.shortDescription }}
                            style={{ minHeight: '50px' }}
                        />
                        {product?.hasVariations &&
                            <>
                                <h5 className="mb-3">Options:</h5>
                                <div className="d-flex gap-1 pb-3" style={{ overflowX: "auto" }}>
                                    {product?.variations?.map((val, index) => {
                                        return (
                                            <div key={index} className="text-center d-block p-3" style={{ background: selectedImage === val?.image[0]?.url ? "white" : '', minWidth: '250px', display: 'inline-block' }}>
                                                <div className="d-flex align-items-center " style={{ minHeight: "230px" }}>
                                                    <img
                                                        onClick={() => { setSelectedImage(val?.image[0]?.url); setSelectedVariation(val?._id) }}
                                                        className="img-fluid"
                                                        src={`${url}/product/img/${val?.image[0]?.url}`}
                                                        alt={`Variation ${index}`}
                                                        style={{ width: '250px' }}
                                                        role="button"

                                                    />
                                                </div>
                                                <div>
                                                    <span className="col-12 mt-2">{val.Type}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                        <div className='d-flex text-center gap-3 py-3'>
                            <div className='SSA_addbtn d-flex' style={{
                            }}>
                                <input type='number' className='custom-number-input' defaultValue={1} min={1} value={productQuantities[product?._id] || 1} onChange={(e) => updateProductQuantity(product?._id, parseInt(e.target.value))} />
                            </div>
                            {product?.hasVariations ?
                                <div className='SSA_button text-center d-flex' style={{ opacity: selectedVariation ? 1 : 0.6 }} onClick={() => selectedVariation ? handleAddToCart(product) : null}>
                                    <span style={{ width: '90%', cursor: "pointer" }}>Add to cart</span>
                                    <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11 9V6H8V4H11V1H13V4H16V6H13V9H11ZM7 22C6.45 22 5.979 21.804 5.587 21.412C5.195 21.02 4.99933 20.5493 5 20C5 19.45 5.196 18.979 5.588 18.587C5.98 18.195 6.45067 17.9993 7 18C7.55 18 8.021 18.196 8.413 18.588C8.805 18.98 9.00067 19.4507 9 20C9 20.55 8.804 21.021 8.412 21.413C8.02 21.805 7.54933 22.0007 7 22ZM17 22C16.45 22 15.979 21.804 15.587 21.412C15.195 21.02 14.9993 20.5493 15 20C15 19.45 15.196 18.979 15.588 18.587C15.98 18.195 16.4507 17.9993 17 18C17.55 18 18.021 18.196 18.413 18.588C18.805 18.98 19.0007 19.4507 19 20C19 20.55 18.804 21.021 18.412 21.413C18.02 21.805 17.5493 22.0007 17 22ZM7 17C6.25 17 5.675 16.6707 5.275 16.012C4.875 15.3533 4.86667 14.6993 5.25 14.05L6.6 11.6L3 4H1V2H4.275L8.525 11H15.55L19.425 4L21.175 4.95L17.3 11.95C17.1167 12.2833 16.875 12.5417 16.575 12.725C16.275 12.9083 15.9333 13 15.55 13H8.1L7 15H19V17H7Z" fill="white" />
                                    </svg>
                                </div>
                                :
                                <div className='SSA_button text-center d-flex' onClick={() => handleAddToCart(product)}>
                                    <span style={{ width: '90%', cursor: "pointer" }}>Add to cart</span>
                                    <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11 9V6H8V4H11V1H13V4H16V6H13V9H11ZM7 22C6.45 22 5.979 21.804 5.587 21.412C5.195 21.02 4.99933 20.5493 5 20C5 19.45 5.196 18.979 5.588 18.587C5.98 18.195 6.45067 17.9993 7 18C7.55 18 8.021 18.196 8.413 18.588C8.805 18.98 9.00067 19.4507 9 20C9 20.55 8.804 21.021 8.412 21.413C8.02 21.805 7.54933 22.0007 7 22ZM17 22C16.45 22 15.979 21.804 15.587 21.412C15.195 21.02 14.9993 20.5493 15 20C15 19.45 15.196 18.979 15.588 18.587C15.98 18.195 16.4507 17.9993 17 18C17.55 18 18.021 18.196 18.413 18.588C18.805 18.98 19.0007 19.4507 19 20C19 20.55 18.804 21.021 18.412 21.413C18.02 21.805 17.5493 22.0007 17 22ZM7 17C6.25 17 5.675 16.6707 5.275 16.012C4.875 15.3533 4.86667 14.6993 5.25 14.05L6.6 11.6L3 4H1V2H4.275L8.525 11H15.55L19.425 4L21.175 4.95L17.3 11.95C17.1167 12.2833 16.875 12.5417 16.575 12.725C16.275 12.9083 15.9333 13 15.55 13H8.1L7 15H19V17H7Z" fill="white" />
                                    </svg>
                                </div>

                            }
                        </div>

                        <Modal
                            open={openModal}

                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="Modal-section">
                                    <h2 className="header border-0">
                                    </h2>
                                    <div className="d-flex header">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
                                            <path d="M8.52812 18.0003L0.453125 9.92526L2.47188 7.90651L8.52812 13.9628L21.526 0.964844L23.5448 2.98359L8.52812 18.0003Z" fill="#48AF25" />
                                        </svg>
                                        <span className="ms-2">Product  successfully added to your cart</span>
                                    </div>
                                    <div className="Modal-btn-group">
                                        <button
                                            onClick={() => { navigate(`/shop`); setOpenModal(false) }}
                                            className="Modal-btn first-modal-btn" style={{ backgroundColor: "#000" }}
                                        >
                                            Continue shopping
                                        </button>
                                        <button
                                            className="Modal-btn first-modal-btn"
                                            onClick={() => { (user ? navigate(`/checkout`) : setOpenModal2(true)); setOpenModal(false) }}
                                        >
                                            Checkout now
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                        <Modal
                            open={openModal2}
                            onClose={() => setOpenModal2(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="Modal-section">
                                    <h2 className="header" style={{ marginBottom: '1rem', font: "Assistant", fontWeight: "700", fontSize: "22px", lineHeight: "28px" }}>Checkout options
                                    </h2>
                                    <div className="gap-4 d-flex">
                                        <button
                                            className="Modal-btn first-modal-btn py-3"
                                            onClick={() => { navigate(`/login`); setOpenModal2(false) }}
                                        >
                                            <div className="mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                <path d="M19.8866 19.119C18.4236 16.4887 16.169 14.6027 13.5379 13.7086C14.8394 12.9029 15.8506 11.6753 16.4161 10.2141C16.9817 8.75295 17.0704 7.13913 16.6686 5.62048C16.2668 4.10182 15.3968 2.76231 14.1921 1.80764C12.9874 0.852972 11.5146 0.335938 10 0.335938C8.48537 0.335938 7.01262 0.852972 5.80792 1.80764C4.60321 2.76231 3.73316 4.10182 3.33138 5.62048C2.9296 7.13913 3.0183 8.75295 3.58387 10.2141C4.14944 11.6753 5.1606 12.9029 6.46207 13.7086C3.83095 14.6017 1.5764 16.4877 0.113387 19.119C0.0597359 19.2099 0.0241494 19.3112 0.00872733 19.4167C-0.00669474 19.5222 -0.00164002 19.6298 0.0235932 19.7333C0.0488265 19.8367 0.0937273 19.9339 0.155646 20.019C0.217565 20.1041 0.295248 20.1754 0.384111 20.2288C0.472975 20.2822 0.571219 20.3165 0.673047 20.3298C0.774875 20.343 0.878224 20.3349 0.976997 20.306C1.07577 20.277 1.16797 20.2278 1.24815 20.1612C1.32832 20.0946 1.39486 20.0119 1.44383 19.9181C3.25363 16.6655 6.45246 14.7236 10 14.7236C13.5475 14.7236 16.7464 16.6655 18.5562 19.9181C18.6051 20.0119 18.6717 20.0946 18.7519 20.1612C18.832 20.2278 18.9242 20.277 19.023 20.306C19.1218 20.3349 19.2251 20.343 19.327 20.3298C19.4288 20.3165 19.527 20.2822 19.6159 20.2288C19.7048 20.1754 19.7824 20.1041 19.8444 20.019C19.9063 19.9339 19.9512 19.8367 19.9764 19.7333C20.0016 19.6298 20.0067 19.5222 19.9913 19.4167C19.9758 19.3112 19.9403 19.2099 19.8866 19.119ZM4.62058 7.53112C4.62058 6.4247 4.93607 5.34313 5.52717 4.42318C6.11827 3.50323 6.95842 2.78622 7.94138 2.36281C8.92434 1.9394 10.006 1.82862 11.0495 2.04447C12.093 2.26032 13.0515 2.79311 13.8038 3.57546C14.5562 4.35782 15.0685 5.3546 15.2761 6.43976C15.4836 7.52491 15.3771 8.64971 14.9699 9.6719C14.5628 10.6941 13.8733 11.5678 12.9886 12.1825C12.104 12.7972 11.0639 13.1253 10 13.1253C8.57376 13.1237 7.20637 12.5338 6.19786 11.485C5.18935 10.4363 4.6221 9.01429 4.62058 7.53112Z" fill="white" />
                                            </svg>
                                            </div>
                                            Login to my account
                                        </button>
                                        <button
                                            onClick={() => { navigate(`/sign-up`); setOpenModal2(false) }}
                                            className="Modal-btn first-modal-btn py-3" style={{ backgroundColor: "#000" }}
                                        >
                                            <div className="mb-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                    <path d="M19.9154 11.7533H11.4154V20.2533H8.58203V11.7533H0.0820312V8.91992H8.58203V0.419922H11.4154V8.91992H19.9154V11.7533Z" fill="white" />
                                                </svg>
                                            </div>
                                            Create account
                                        </button>
                                    </div>
                                    <div className="justify-content-center d-flex mt-3 mb-3">
                                        <span>Or</span>
                                    </div>

                                    <div className="Modal-btn-groupjustify-content-center">
                                        <div
                                            role="button"
                                            onClick={() => { navigate(`/guest-checkout`); setOpenModal2(false) }}
                                            className="d-flex border-top border-bottom justify-content-center w-100 py-3" style={{ backgroundColor: "" }}
                                        >
                                            Checkout as a guest
                                            <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                                <path d="M13.0009 17.3669L17.8669 12.5009L13.0009 7.63494L11.2978 9.33804L13.2442 11.2844H8.13494V13.7174H13.2442L11.2978 15.6638L13.0009 17.3669ZM13.0009 24.6659C11.3181 24.6659 9.73666 24.3464 8.25659 23.7073C6.77651 23.0683 5.48905 22.2017 4.3942 21.1077C3.29935 20.0128 2.4328 18.7254 1.79454 17.2453C1.15628 15.7652 0.836748 14.1838 0.835938 12.5009C0.835938 10.8181 1.15547 9.23666 1.79454 7.75659C2.43361 6.27651 3.30016 4.98905 4.3942 3.8942C5.48905 2.79935 6.77651 1.9328 8.25659 1.29454C9.73666 0.656283 11.3181 0.336748 13.0009 0.335938C14.6838 0.335938 16.2652 0.655472 17.7453 1.29454C19.2254 1.93361 20.5128 2.80016 21.6077 3.8942C22.7025 4.98905 23.5695 6.27651 24.2085 7.75659C24.8476 9.23666 25.1667 10.8181 25.1659 12.5009C25.1659 14.1838 24.8464 15.7652 24.2073 17.2453C23.5683 18.7254 22.7017 20.0128 21.6077 21.1077C20.5128 22.2025 19.2254 23.0695 17.7453 23.7085C16.2652 24.3476 14.6838 24.6667 13.0009 24.6659Z" fill="black" />
                                            </svg>
                                        </div>

                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <div>
                    <ul class="mt-3 SSA_tab nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <h3 class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Description</h3>
                        </li>
                        <li class="nav-item" role="presentation">
                            <h3 class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Shipping</h3>
                        </li>
                        <li class="nav-item" role="presentation">
                            <h3 class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Compatibility</h3>
                        </li>
                    </ul>
                    <div class="tab-content bg-white p-4" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"> <div dangerouslySetInnerHTML={{ __html: product?.description }} /></div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><div dangerouslySetInnerHTML={{ __html: product?.ShippingAndReturnInformation }} /></div>
                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab"><div dangerouslySetInnerHTML={{ __html: product?.CompatibilityInformation }} /></div>
                    </div>

                </div>
            </div >
        </div >
    )
}
