import axios from "axios";
const url = process.env.REACT_APP_URL;

export const retriveLoginAccounts = (values) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${url}/accounts/active`,
      { values },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "ACCOUNTS_FETCH_SUCCESS", payload: data });
  } catch (error) { 
    dispatch({ type: "ACCOUNTS_FETCH_FAILURE", payload: error.message });
  }
};