import React, { useEffect, useState } from "react";

const OtcGuestUsersHeaderWithIcons = (props) => {
    const [cardInfoTab, setCardInfoTab] = useState("Users");
    const { tab } = props;

    useEffect(() => {
        setCardInfoTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCardInfoTab(value);
        props.onChange(value);
    };

    return (
        <>
            <div className="onetap_connect_mycard_header_OTC_Admin">
                <div
                    onClick={() => handleClickOfTab("Users")}
                    className={
                        cardInfoTab === "Users"
                            ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                            : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
                    }
                >
                    <div
                        style={{
                            backgroundColor: cardInfoTab === "Users" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >

                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="34" height="34" rx="9" fill="#E65925" />
                            <path d="M17 10.5C17.9283 10.5 18.8185 10.8687 19.4749 11.5251C20.1313 12.1815 20.5 13.0717 20.5 14C20.5 14.9283 20.1313 15.8185 19.4749 16.4749C18.8185 17.1313 17.9283 17.5 17 17.5C16.0717 17.5 15.1815 17.1313 14.5251 16.4749C13.8687 15.8185 13.5 14.9283 13.5 14C13.5 13.0717 13.8687 12.1815 14.5251 11.5251C15.1815 10.8687 16.0717 10.5 17 10.5ZM10 13C10.56 13 11.08 13.15 11.53 13.42C11.38 14.85 11.8 16.27 12.66 17.38C12.16 18.34 11.16 19 10 19C9.20435 19 8.44129 18.6839 7.87868 18.1213C7.31607 17.5587 7 16.7956 7 16C7 15.2044 7.31607 14.4413 7.87868 13.8787C8.44129 13.3161 9.20435 13 10 13ZM24 13C24.7956 13 25.5587 13.3161 26.1213 13.8787C26.6839 14.4413 27 15.2044 27 16C27 16.7956 26.6839 17.5587 26.1213 18.1213C25.5587 18.6839 24.7956 19 24 19C22.84 19 21.84 18.34 21.34 17.38C22.2119 16.2544 22.6166 14.8362 22.47 13.42C22.92 13.15 23.44 13 24 13ZM10.5 23.25C10.5 21.18 13.41 19.5 17 19.5C20.59 19.5 23.5 21.18 23.5 23.25V25H10.5V23.25ZM5 25V23.5C5 22.11 6.89 20.94 9.45 20.6C8.86 21.28 8.5 22.22 8.5 23.25V25H5ZM29 25H25.5V23.25C25.5 22.22 25.14 21.28 24.55 20.6C27.11 20.94 29 22.11 29 23.5V25Z" fill="white" />
                        </svg>
                    </div>

                    <p
                        className={
                            cardInfoTab === "Users"
                                ? "onetap_connect_mycard_active_header_OTC_Admin"
                                : "onetap_connect_mycard_headers_OTC_Admin"
                        }
                    >
                        Guest Users
                    </p>
                </div>
            </div>
        </>
    );
};

export default OtcGuestUsersHeaderWithIcons;
