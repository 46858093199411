import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllUsersOfCompany,
  getAllUsersOfSelectedCompany,
} from "../Redux/Otc_UserActions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import video from "../../assets/images/video.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import {
  SingleTeamMemberDetails,
  fetchUserInformationDetails,
  getProducts,
  getTeamMember,
} from "../../actions/userAction/userAction";
import axios from "axios";
import selectDropdownIcon from "../Otc_assets/OTC_ICONS/selectDropdownIcon.svg";
import canceliconOrange from "../Otc_assets/OTC_ICONS/canceliconOrange.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
  >
    <path
      d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
      fill="#E65925"
    />
    <path
      d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
      stroke="white"
      stroke-width="6.64"
      stroke-linecap="round"
      class="checkmark-path"
    />
  </svg>
);

const ClientProfileSmartAccessories = () => {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getAllUsersOfCompany(id));
  }, [dispatch, id]);

  const { loading, companydata, userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  const { usersofcompany } = useSelector(
    (state) => state.AllcompanyUser
  );

  function capitalizeWords(str) {
    const words = str?.split(" ");
    const capitalizedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords?.join(" ");
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28.125rem",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: ".625rem",
  };
  const warningModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28.125rem",
    bgcolor: "#FDE9E9",
    p: 3,
    outline: "none",
    border: "1px solid E62525",

    borderRadius: "10px",
  };
  const { users, users_loading } = useSelector((state) => state.Users);

  const { products, message } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.user);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [smartAccessories, setSmartAccessories] = useState("");
  const [allSmartAccessories, setAllSmartAccessories] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectedSmartAcc, setSelectedSmartAcc] = useState("");
  const [deletedSmartAccId, setdeletedSmartAccId] = useState("");
  const [selectUserValidationError, setselectUserValidationError] =
    useState("");
  const [smartAccessoryIDs, setSmartAccessoryIDs] = useState([""]); 
  const [usersData, setUsersData] = useState([]);
  const [userPlan, setUserPlan] = useState("");
  const [uniqueIdError, setUniqueIdError] = useState("");
  const [removeError, setRemoveError] = useState("");
  const handleClearFilters = () => {
    setSelectedProduct("");
    setSelectedStatus("");
    setSelectedUser("");
    setSearchTerm("");
    setFiltersApplied(false); 
  };
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openSecondModal, setOpenSecondModal] = React.useState(false);
  const [openThirdModal, setOpenThirdModal] = React.useState(false);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenSecondModal = () => {
    setOpen(false);
    setOpenSecondModal(true);
  };
  const handleCloseSecondModal = () => {
    setOpenSecondModal(false);
    setSelectedTeamMember("");
  };
  const handleOpenThirdModal = () => {
    if (userPlan?.includes("Team")) {
      if (!selectedTeamMember) {
        setselectUserValidationError("Please select a user before continuing.");
        return;
      }
    }
    setselectUserValidationError("");
    setOpen(false);
    setOpenSecondModal(false);
    setOpenThirdModal(true);
  };
  const handleCloseThirdModal = () => {
    setOpenThirdModal(false);
    setSelectedTeamMember("");
    setUniqueIdError("");
    setSmartAccessoryIDs([""]);
  };
  const handleWarningOpenModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningdModal = () => {
    setOpenWarningModal(false);
    setRemoveError("");
    setdeletedSmartAccId("");
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    dispatch(getAllUsersOfSelectedCompany(id));
  }, [id]);
  useEffect(() => {
    setUsersData(usersofcompany?.map((e) => e?.user_id));
    setUserPlan(usersofcompany?.map((e) => e?.subscription_details?.plan));
  }, [dispatch, usersofcompany, selectedTeamMember]);

  useEffect(() => {
    if (userPlan?.includes("Professional") || userPlan?.includes("Free")) {
      setSelectedTeamMember(usersofcompany && usersofcompany[0]?.user_id);
    }
  }, [selectedTeamMember, usersofcompany, userPlan]);

  useEffect(() => {
    if (!users) {
      dispatch(getTeamMember());
    }
  }, [dispatch, users]);
  useEffect(() => {
    dispatch(fetchUserInformationDetails());
  }, []);

  useEffect(() => {
    if (!user) {
      dispatch(SingleTeamMemberDetails());
    }
  }, [user, dispatch]);
  useEffect(() => {
    if (!products) {
      dispatch(getProducts());
    }
  }, [products]);
  const options = usersData
    ? usersData?.map((user) => ({
        value: user, 
        label: `${user.first_name
          .charAt(0)
          .toUpperCase()}${user.first_name.slice(1)} ${user.last_name
          .charAt(0)
          .toUpperCase()}${user.last_name.slice(1)}`,
      }))
    : [];

  const handleUniqueId = (index, value) => {
    const updatedIDs = [...smartAccessoryIDs];
    updatedIDs[index] = value;
    setSmartAccessoryIDs(updatedIDs);
    setUniqueIdError("");
  };

  const handleAddAccessoryInput = () => {
    setSmartAccessoryIDs((prevIDs) => [...prevIDs, ""]);
  };
  const handleRemoveAccessoryInput = (index) => {
    setSmartAccessoryIDs((prevIDs) => prevIDs.filter((_, i) => i !== index));
  };
  const fullName =
    userPlan?.includes("Professional") || userPlan?.includes("Free")
      ? `${selectedTeamMember?.first_name} ${selectedTeamMember?.last_name}`
      : `${selectedTeamMember?.value?.first_name} ${selectedTeamMember?.value?.last_name}`;
  const words = fullName.split(" ");
  const initials = words
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
  const uniqueProductNames = [
    ...new Set(
      smartAccessories &&
        smartAccessories?.map((product) => product?.productName)
    ),
  ];
  const uniqueUsersNames = [
    ...new Set(
      smartAccessories &&
        smartAccessories?.map(
          (product) =>
            `${
              product.userId.first_name.charAt(0).toUpperCase() +
              product.userId.first_name.slice(1)
            } ${
              product.userId.last_name.charAt(0).toUpperCase() +
              product.userId.last_name.slice(1)
            }`
        )
    ),
  ];
  const uniqueStatusNames = [
    ...new Set(
      smartAccessories && smartAccessories?.map((product) => product?.status)
    ),
  ];
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setFiltersApplied(true);
  };
  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
    if (e.target.value !== "") {
      setFiltersApplied(true); 
    } else if (
      selectedProduct === "" &&
      selectedStatus === "" &&
      selectedUser === ""
    ) {
      setFiltersApplied(false);
    }
  };

  const handleCheckboxChange = (uniqueId) => {
    if (selectedSmartAcc.includes(uniqueId)) {
      setSelectedSmartAcc((prevSelected) =>
        prevSelected.filter((id) => id !== uniqueId)
      );
    } else {
      setSelectedSmartAcc((prevSelected) => [...prevSelected, uniqueId]);
    }
  };

  const matchedRemoveSmartAcc =
    smartAccessories &&
    smartAccessories?.filter((e) => e._id === selectedSmartAcc[0]);
  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
    if (e.target.value !== "") {
      setFiltersApplied(true); 
    } else if (
      selectedProduct === "" &&
      selectedStatus === "" &&
      selectedUser === ""
    ) {
      setFiltersApplied(false);
    }
  };
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    if (e.target.value !== "") {
      setFiltersApplied(true);
    } else if (
      selectedProduct === "" &&
      selectedStatus === "" &&
      selectedUser === ""
    ) {
      setFiltersApplied(false);
    }
  };
  const uniqueIdArray =
    allSmartAccessories && allSmartAccessories?.map((e) => e.uniqueId);
  const userId =
    userPlan?.includes("Professional") || userPlan?.includes("Free")
      ? selectedTeamMember?._id
      : selectedTeamMember?.value?._id;
  const handleAssignSmartAccessory = async () => {
    if (smartAccessoryIDs.length === 0) {
      setUniqueIdError("Please enter at least one smart accessory ID.");
      return;
    }

    for (const id of smartAccessoryIDs) {
      if (id.trim() === "") {
        setUniqueIdError("Please enter valid smart accessory IDs.");
        return;
      } else if (!uniqueIdArray.includes(id)) {
        setUniqueIdError("Invalid smart accessory ID: " + id);
        return;
      }
    }

    const response = await axios.post(
      `${url}/assignSmartAccessroiesToUser`,
      {
        userId: userId,
        companyId: companydata?._id,
        uniqueIds: smartAccessoryIDs,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      setUniqueIdError("");
      getUserAssignSmartAccessoriesForCompany();
      handleCloseThirdModal();
      setApiSuccess(true);
      setSuccessMessage("New smart accessory added successfully.");
      setTimeout(() => {
        setApiSuccess(false);
      }, 5000);
    }
  };
  const handleUpdateStatus = async (status) => {
    if (selectedSmartAcc.length === 0) {
      return;
    }
    const response = await axios.post(
      `${url}/updateSmartAccessoryStatus`,
      { status: status, Ids: selectedSmartAcc },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      await getUserAssignSmartAccessoriesForCompany();
      setSelectedSmartAcc("");
      setDropdownOpen(false);
      if (status === "Activate") {
        setApiSuccess(true);
        setSuccessMessage("Smart accessory successfully activated.");
        setTimeout(() => {
          setApiSuccess(false);
        }, 5000);
      } else if (status === "Deactivate") {
        setApiSuccess(true);
        setSuccessMessage("Smart accessory successfully deactivated.");
        setTimeout(() => {
          setApiSuccess(false);
        }, 5000);
      }
    }
  };
  const handleRemoveSmartAcc = async () => {
    if (selectedSmartAcc?.length > 1) {
      setRemoveError("Please select only one smart accessory");
      return;
    } else if (deletedSmartAccId?.trim() === "") {
      setRemoveError("Please enter at least one smart accessory ID.");
      return;
    } else if (
      deletedSmartAccId?.trim() !== matchedRemoveSmartAcc[0]?.uniqueId?.trim()
    ) {
      setRemoveError("You are entering the wrong smart accessory ID");
      return;
    }
    const response = await axios.post(
      `${url}/removeUserFromSmartAccessories`,
      { uniqueIds: selectedSmartAcc },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      await getUserAssignSmartAccessoriesForCompany();
      setSelectedSmartAcc("");
      setdeletedSmartAccId("");
      setDropdownOpen(false);
      handleCloseWarningdModal();
      setApiSuccess(true);
      setSuccessMessage("Smart accessory removed successfully.");
      setTimeout(() => {
        setApiSuccess(false);
      }, 5000);
    }
  };
  const getUserAssignSmartAccessoriesForCompany = async () => {
    const { data } = await axios.get(
      `${url}/getUserAssignSmartAccessoriesForCompany`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    setSmartAccessories(
      data?.smartAccessories.filter((e) => e.companyId === companydata?._id)
    );
    setAllSmartAccessories(data.smartAccessorieswithoutUserId);
  };
  useEffect(() => {
    getUserAssignSmartAccessoriesForCompany();
  }, []);


  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {" "}
          <div
            className="onetap_conn_pointofcontect"
            style={{ padding: "20px", paddingTop: "0px", marginBottom: "15px" }}
          >
            <div
              className="row bg-white"
              style={{ marginLeft: "0", borderRadius: "4px" }}
            >
              <Accordion
                style={{ boxShadow: "none", padding: "0px" }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  className=""
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{
                    minHeight: "70px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography
                    className="onetap_connect_free_user_accordian_heading_OTC_Admin"
                    style={{ flexShrink: 0 }}
                  >
                    Point of Contact
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    borderTop: "1px solid #F2F2F2",
                    padding: "16px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div style={{ display: "flex", gap: "40px" }}>
                    {companydata.primary_billing.map((billingUser, index) => (
                      <div
                        className="profile-info_OTC_Admin"
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="user-info-avatar-container_OTC_Admin">
                          {billingUser?.avatar !== "" ? (
                            <img
                              src={`${url}/profile/img/${billingUser?.avatar}`}
                              alt="Profile"
                              style={{
                                width: "54px",
                                height: "54px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <div
                              className="user-info-initials-overlay_OTC_Admin"
                              style={{
                                width: "54px",
                                height: "54px",
                                position: "relative",
                              }}
                            >
                              {billingUser.first_name.charAt(0).toUpperCase()}
                              {billingUser.last_name.charAt(0).toUpperCase()}
                            </div>
                          )}
                        </div>

                        <div className="details_OTC_Admin">
                          <p
                            style={{
                              textTransform: "capitalize",
                              fontSize: "16px",
                              fontWeight: "600",
                              margin: "0px",
                            }}
                          >
                            {capitalizeWords(billingUser.first_name)}{" "}
                            {capitalizeWords(billingUser.last_name)}
                          </p>
                          <p
                            className=""
                            style={{ fontSize: "13px", margin: "0px" }}
                          >
                            {capitalizeWords(billingUser.designation[0])}
                          </p>
                          <p
                            className="column1_text_OTC_Admin"
                            style={{ fontSize: "12px", margin: "0px" }}
                          >
                            {capitalizeWords(billingUser.role)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div
            className="onetap_conn_pointofcontect"
            style={{ padding: "20px" }}
          >
            <>
              <div className="onetap_connect_const_smartaccessoriestab">
                <div className="">
                  <div
                    className="onetap_connect_smart_access_synr"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Smart Accessories</h3>
                    <button
                      style={{
                        backgroundColor: "#000",
                        color: "white",
                        borderRadius: "4px",
                      }}
                    >
                      Place an order
                    </button>
                  </div>
                  <div className="onetap_connct_filters_synr">
                    <div class="onetap_connect_search_box_synr">
                      <input
                        style={{ paddingRight: "50px" }}
                        className="onetap_connect_search_input_synr"
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <svg
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                    <div
                      style={{ position: "relative" }}
                      className="onetap_connect_select_option_synr"
                    >
                      <select
                        className="select-smart-acc pc-status-dropdown"
                        value={selectedProduct}
                        onChange={handleProductChange}
                      >
                        <option value="">Smart Accessories</option>
                        {uniqueProductNames.map((productName, index) => (
                          <option key={index} value={productName}>
                            {productName}
                          </option>
                        ))}
                      </select>
                      <img
                        className="order-downarrow-icon"
                        src={selectDropdownIcon}
                        alt=""
                      />
                    </div>
                    <div
                      style={{ position: "relative" }}
                      className="onetap_connect_select_option_synr "
                    >
                      <select
                        className="select-smart-acc pc-status-dropdown"
                        value={selectedUser}
                        onChange={handleUserChange}
                      >
                        <option value="">User</option>
                        {uniqueUsersNames.map((userName, index) => (
                          <option key={index} value={userName}>
                            {userName}
                          </option>
                        ))}
                      </select>
                      <img
                        className="order-downarrow-icon"
                        src={selectDropdownIcon}
                        alt=""
                      />
                    </div>
                    <div
                      style={{ position: "relative" }}
                      className="onetap_connect_select_option_synr"
                    >
                      <select
                        className="select-smart-acc pc-status-dropdown"
                        value={selectedStatus}
                        onChange={handleStatusChange}
                      >
                        <option value="">Status</option>
                        {uniqueStatusNames.map((statusName, index) => (
                          <option key={index} value={statusName}>
                            {statusName}
                          </option>
                        ))}
                      </select>
                      <img
                        className="order-downarrow-icon"
                        src={selectDropdownIcon}
                        alt=""
                      />
                    </div>
                  </div>
                  <button
                    className="clear-fillter"
                    style={{
                      marginTop: "10px",
                      display:
                        selectedProduct ||
                        selectedStatus ||
                        selectedUser ||
                        searchTerm
                          ? "block"
                          : "none",
                    }}
                    onClick={handleClearFilters}
                  >
                    Clear filters
                  </button>
                  <div className="main-smart-acc-section">
                    <div className="header-smart-acc-section">
                      <div className="add-new-btn-group">
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={handleOpen}
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                        >
                          <rect width="34" height="34" rx="9" fill="#E65925" />
                          <path
                            d="M25.4997 18.4138H18.4163V25.4972C18.4163 25.8729 18.2671 26.2332 18.0014 26.4989C17.7357 26.7646 17.3754 26.9138 16.9997 26.9138C16.624 26.9138 16.2636 26.7646 15.9979 26.4989C15.7323 26.2332 15.583 25.8729 15.583 25.4972V18.4138H8.49967C8.12395 18.4138 7.76362 18.2646 7.49794 17.9989C7.23226 17.7332 7.08301 17.3729 7.08301 16.9972C7.08301 16.6214 7.23226 16.2611 7.49794 15.9954C7.76362 15.7298 8.12395 15.5805 8.49967 15.5805H15.583V8.49717C15.583 8.12145 15.7323 7.76111 15.9979 7.49543C16.2636 7.22976 16.624 7.0805 16.9997 7.0805C17.3754 7.0805 17.7357 7.22976 18.0014 7.49543C18.2671 7.76111 18.4163 8.12145 18.4163 8.49717V15.5805H25.4997C25.8754 15.5805 26.2357 15.7298 26.5014 15.9954C26.7671 16.2611 26.9163 16.6214 26.9163 16.9972C26.9163 17.3729 26.7671 17.7332 26.5014 17.9989C26.2357 18.2646 25.8754 18.4138 25.4997 18.4138Z"
                            fill="white"
                          />
                        </svg>
                        <p
                          style={{ marginLeft: "12px", cursor: "pointer" }}
                          className="addNew-text-smart header-text-smart-acc"
                          onClick={
                            userPlan?.length === 1
                              ? handleOpenThirdModal
                              : handleOpenSecondModal
                          }
                        >
                          Add new
                        </p>
                      </div>
                      <span
                        style={{ marginLeft: "16px" }}
                        className="header-text-smart-acc"
                      >
                        User
                      </span>
                      <span className="header-text-smart-acc">Id</span>
                      <span className="header-text-smart-acc">Status</span>
                      <div className="div-dropdown-smart">
                        <div ref={dropdownRef}>
                          <div
                            onClick={toggleDropdown}
                            className="dropdown-action-smart-acc"
                          >
                            {" "}
                            <span className="action-text-smart-acc">
                              Action
                            </span>{" "}
                            <svg
                              width="11"
                              height="5"
                              viewBox="0 0 11 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.5013 4.74984L0.917969 0.166504H10.0846L5.5013 4.74984Z"
                                fill="#333333"
                              />
                            </svg>
                          </div>
                          {isDropdownOpen && (
                            <div className="onetap_conn_shop_acc_dropdown">
                              <div
                                onClick={() => handleUpdateStatus("Activate")}
                                className="onetap_conn_add_user_dropdown_show_acc"
                              >
                                <div className="modalText-smart-acc">
                                  Activate
                                </div>
                              </div>
                              <div
                                onClick={() => handleUpdateStatus("Deactivate")}
                                className="onetap_conn_add_user_dropdown_show_acc"
                              >
                                <div className="modalText-smart-acc">
                                  Deactivate
                                </div>
                              </div>
                              <div
                                className="onetap_conn_add_user_dropdown_show_acc"
                                onClick={handleWarningOpenModal}
                              >
                                <div className="modalText-smart-acc">
                                  Remove
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="all-products-section">
                      {smartAccessories?.length > 0 ? (
                        smartAccessories &&
                        smartAccessories
                          ?.filter(
                            (e) =>
                              (!selectedProduct ||
                                e.productName === selectedProduct) &&
                              (!selectedStatus ||
                                e.status === selectedStatus) &&
                              (!selectedUser ||
                                `${
                                  e.userId.first_name?.charAt(0).toUpperCase() +
                                  e.userId.first_name?.slice(1)
                                } ${
                                  e.userId.last_name.charAt(0).toUpperCase() +
                                  e.userId.last_name.slice(1)
                                }` === selectedUser) &&
                              (!searchTerm ||
                                e.productName
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()) ||
                                e.userId.first_name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()) ||
                                e.uniqueId
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase()))
                          )
                          ?.map((e, index) => {
                            const productsWithVariations = products?.filter(
                              (product) => product.variations.length > 0
                            );
                            const matchedProduct = productsWithVariations?.find(
                              (product) => product._id === e.productId._id
                            );
                            const matchedVariation =
                              matchedProduct?.variations?.find(
                                (variation) => variation._id === e.variationId
                              );
                            return (
                              <div
                                key={index}
                                className="header-smart-acc-section product-cards"
                              >
                                <div className="add-new-btn-group">
                                  <img
                                    style={{
                                      width: "14%",
                                      backgroundColor: "white",
                                      borderRadius: "4px",
                                    }}
                                    className="img-smart-acc"
                                    src={
                                      e?.variationId
                                        ? `${url}/product/img/${matchedVariation?.image[0]?.url}`
                                        : `${url}/product/img/${e.productId?.image[0]?.url}`
                                    }
                                    alt=""
                                  />
                                  <div className="product-details">
                                    <span className="product-name-text">
                                      {e.productId.name}
                                    </span>
                                    {matchedVariation ? (
                                      <span className="">
                                        {" "}
                                        style:{" "}
                                        <span className="style-text-smart">
                                          {" "}
                                          {matchedVariation && (
                                            <span>
                                              {matchedVariation.Type || "-"}
                                            </span>
                                          )}
                                        </span>{" "}
                                      </span>
                                    ) : (
                                      <span>
                                        style:{" "}
                                        <span className="style-text-smart">
                                          Regular
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="user-detail">
                                  <span className="userName-text-smart-acc">
                                    {e.userId.first_name
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      e.userId.first_name?.slice(1)}{" "}
                                    {e.userId.last_name
                                      .charAt(0)
                                      .toUpperCase() +
                                      e.userId.last_name.slice(1)}
                                  </span>
                                  <span className="style-text-smart">
                                    {e.userId?.designation[0]}
                                  </span>
                                </div>
                                <span className="cardId-text-smart-acc">
                                  {e.uniqueId}
                                </span>
                                <span
                                  className="status-text-smart-acc"
                                  style={{
                                    color:
                                      e.status === "Activate"
                                        ? "#48AF25"
                                        : "#E62525",
                                  }}
                                >
                                  {e.status}
                                </span>
                                <input
                                  type="checkbox"
                                  checked={selectedSmartAcc.includes(e._id)}
                                  onChange={() => handleCheckboxChange(e._id)}
                                  className="Checkbox-smart-acc"
                                />
                              </div>
                            );
                          })
                      ) : (
                        <div className="mt-4">
                          <span className="mt-3">
                            No Smart Accessory integrated with the user.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="video-Modal-section">
                    <h2 className="video-header">
                      How to connect a smart accessory
                    </h2>
                    <div className="video-img">
                      <img src={video} />
                    </div>
                    <div className="video-Modal-btn-group">
                      <button
                        className="video-Modal-btn video-first-modal-btn"
                        onClick={
                          userPlan?.length === 1
                            ? handleOpenThirdModal
                            : handleOpenSecondModal
                        }
                      >
                        Continue
                      </button>
                      <button
                        className="video-Modal-btn video-sec-modal-btn"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="video-checkbox-and-text">
                      <input type="checkbox" />
                      <span>Got it! Do not show again</span>
                    </div>
                  </div>
                </Box>
              </Modal>
              <Modal
                open={openSecondModal}
                onClose={handleCloseSecondModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="sec-Modal-section">
                    <div className="sec-modal-header-group">
                      <h2 className="sec-modal-header">
                        New smart accessories - select user
                      </h2>
                      <span>
                        Select the user would you like to connect the smart
                        accessory to
                      </span>
                    </div>
                    <span role="button" className="sec-modal-dropdwon ">
                      <Select
                        styles={{
                          control: () => ({
                            margin: 0,
                            padding: 0,
                            border: "none",
                            boxShadow: "none",
                          }),
                          input: (provided) => ({
                            ...provided,
                            margin: 0,
                            padding: 0,
                          }),
                          indicatorsContainer: () => ({
                            display: "none",
                          }),
                          option: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            width: "97%",
                          }),
                        }}
                        className="onetap_conn_general_information_input_syn   mb-3 form-select"
                        placeholder="Search user"
                        value={selectedTeamMember}
                        onChange={(selectedOption) => {
                          setSelectedTeamMember(selectedOption);
                          setselectUserValidationError("");
                        }}
                        options={options}
                      />
                    </span>
                    {selectUserValidationError && (
                      <span className="error-message">
                        {selectUserValidationError}
                      </span>
                    )}
                    <div className="video-Modal-btn-group">
                      <button
                        className="video-Modal-btn video-first-modal-btn"
                        onClick={handleOpenThirdModal}
                      >
                        Continue
                      </button>

                      <button
                        className="video-Modal-btn video-sec-modal-btn"
                        onClick={handleCloseSecondModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={openThirdModal}
                onClose={handleCloseThirdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="third-Modal-section">
                    <div>
                      <h2 className="third-modal-header">
                        New smart accessories - ID
                      </h2>
                      <span>
                        All smart accessories come with a unique ID.
                        <br />
                        <a
                          style={{ textDecoration: "none", color: "#2572E6" }}
                          href="#"
                        >
                          Where can I found the ID?
                        </a>{" "}
                      </span>
                    </div>
                    <div className="select-card-users-section selected">
                      <div className="user-info-avatar-container_OTC_Admin pb-0 ms-0 me-0  ">
                        {userPlan?.includes("Professional") ||
                        userPlan?.includes("Free") ? (
                          selectedTeamMember?.avatar ? (
                            <img
                              src={`${url}/profile/img/${selectedTeamMember?.avatar}`}
                              alt="Profile"
                              style={{
                                width: "54px",
                                height: "54px",
                                borderRadius: "100%",
                                paddingBottom: "0px",
                              }}
                            />
                          ) : (
                            <div
                              className="user-info-initials-overlay_OTC_Admin ms-0"
                              style={{
                                width: "54px",
                                height: "54px",
                                paddingBottom: "7px",
                              }}
                            >
                              {initials}
                            </div>
                          )
                        ) : selectedTeamMember?.value?.avatar ? (
                          <img
                            src={`${url}/profile/img/${selectedTeamMember?.value?.avatar}`}
                            alt="Profile"
                            style={{
                              width: "54px",
                              height: "54px",
                              borderRadius: "100%",
                              paddingBottom: "0px",
                            }}
                          />
                        ) : (
                          <div
                            className="user-info-initials-overlay_OTC_Admin ms-0"
                            style={{
                              width: "54px",
                              height: "54px",
                              paddingBottom: "7px",
                            }}
                          >
                            {initials}
                          </div>
                        )}
                      </div>
                      <div className="select-card-users-info">
                        <p>
                          {userPlan?.includes("Professional") ||
                          userPlan?.includes("Free")
                            ? selectedTeamMember?.first_name
                                ?.charAt(0)
                                .toUpperCase() +
                              selectedTeamMember?.first_name?.slice(1)
                            : selectedTeamMember?.value?.first_name
                                ?.charAt(0)
                                .toUpperCase() +
                              selectedTeamMember?.value?.first_name?.slice(1)}
                          &nbsp;
                          {userPlan?.includes("Professional") ||
                          userPlan?.includes("Free")
                            ? selectedTeamMember?.last_name
                                ?.charAt(0)
                                .toUpperCase() +
                              selectedTeamMember?.last_name?.slice(1)
                            : selectedTeamMember?.value?.last_name
                                ?.charAt(0)
                                .toUpperCase() +
                              selectedTeamMember?.value?.last_name.slice(1)}
                        </p>
                        <p>
                          {userPlan?.includes("Professional") ||
                          userPlan?.includes("Free")
                            ? selectedTeamMember?.type
                            : selectedTeamMember?.value?.type}
                        </p>
                      </div>
                    </div>
                    <div className="third-modal-input-group">
                      <span className="mb-2">Enter smart accessory ID</span>
                      {smartAccessoryIDs?.map((id, index) => (
                        <div className="mb-2" key={index}>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <input
                              className="w-100"
                              type="text"
                              placeholder="VIP-23-84399"
                              value={id}
                              onChange={(e) =>
                                handleUniqueId(index, e.target.value)
                              }
                            />
                            {smartAccessoryIDs?.length > 1 && (
                              <img
                                role="button"
                                className=" ms-2"
                                onClick={() =>
                                  handleRemoveAccessoryInput(index)
                                }
                                disabled={
                                  index !== smartAccessoryIDs?.length - 1
                                }
                                src={canceliconOrange}
                                alt=""
                              />
                            )}
                          </div>

                          {uniqueIdError &&
                            index === smartAccessoryIDs?.length - 1 && (
                              <span
                                style={{ color: "red", fontSize: "16px" }}
                                className="error-message"
                              >
                                {uniqueIdError}
                              </span>
                            )}
                        </div>
                      ))}
                    </div>
                    <div
                      onClick={handleAddAccessoryInput}
                      className="third-modal-addSmartAccessory"
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                          fill="#E65925"
                        />
                      </svg>
                      <span>Add another smart accessory to this card</span>
                    </div>
                    <div className="third-Modal-btn-group">
                      <button
                        onClick={handleAssignSmartAccessory}
                        className="third-Modal-btn third-first-modal-btn"
                      >
                        Save
                      </button>
                      <button
                        className="third-Modal-btn third-sec-modal-btn"
                        onClick={handleCloseThirdModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={warningModalStyle}>
                  <div className="video-Modal-section">
                    <h2 className="warning-header">Warning</h2>
                    <span className="warning-text-smart-acc">
                      All the selected smart accessories will be permanently
                      remove for your account.
                    </span>
                    <input
                      className="w-100 border-0 selected-item-Id"
                      type="text"
                      placeholder="VIP-23-84399"
                      value={deletedSmartAccId}
                      onChange={(e) => {
                        setdeletedSmartAccId(e.target.value);
                        setRemoveError("");
                      }}
                    />
                    {removeError && (
                      <span
                        style={{ color: "red", fontSize: "16px" }}
                        className="error-message"
                      >
                        {removeError}
                      </span>
                    )}
                    <div className="video-Modal-btn-group">
                      <button
                        className="video-Modal-btn video-first-modal-btn"
                        onClick={handleRemoveSmartAcc}
                      >
                        Remove
                      </button>
                      <button
                        className="video-Modal-btn video-sec-modal-btn"
                        onClick={handleCloseWarningdModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={apiSuccess}
                onClose={() => setApiSuccess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={successModalStyle} style={{ textAlign: "center" }}>
                  <div className="Modal-section">
                    <div className="checkmark-container">
                      <div class="checkmark-container">{messageIcon}</div>
                    </div>
                    <span className="team_created_success">
                      {successMessage}
                    </span>
                  </div>
                  <button
                    className="Modal-btn sec-modal-btn"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      width: "13%",
                    }}
                    onClick={() => setApiSuccess(false)}
                  >
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: "#e65925" }}
                      />
                    </span>
                  </button>
                </Box>
              </Modal>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default ClientProfileSmartAccessories;
