import React, { useEffect, useState } from 'react'
import SettingHeadersWithIcon from './SettingHeadersWithIcon';
import SettingContentControlTab from './SettingContentControlTab';
import Sidebar from '../../../components/Layout/Sidebar/Sidebar';
import SettingUserProfileTab from './SettingUserProfileTab';
import SettingLeadcapTab from './SettingLeadcapTab';
import SettingShareReferTab from './settingShareReferTab';
import { useSelector } from 'react-redux';

function Component(props) {
  const { userInfo } = useSelector((state) => state.userInformation);
  const isProfessionalPlan = userInfo?.subscription_details?.plan === "Professional";
  const [companyProfileTab, setCompanyProfileTab] = useState(isProfessionalPlan ? "Lead Capture" : "General");


  useEffect(() => {
    if (isProfessionalPlan) {
      setCompanyProfileTab("Lead Capture");
    } else {
      setCompanyProfileTab("General");
    }
  }, [isProfessionalPlan]);

  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  }

  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value)
  }

  return (
    <div className='onetap_company_profile_syn'>
      <SettingHeadersWithIcon tab={companyProfileTab} onChange={getTabValue} />
      {companyProfileTab === 'General' && <SettingContentControlTab onClickSave={getValueOfSaveAndContinueTab} />}
      {companyProfileTab === 'User profile' && <SettingUserProfileTab onClickSave={getValueOfSaveAndContinueTab} />}
      {companyProfileTab === 'Lead Capture' && <SettingLeadcapTab onClickSave={getValueOfSaveAndContinueTab} />}
      {companyProfileTab === 'Share / Referral' && <SettingShareReferTab onClickSave={getValueOfSaveAndContinueTab} />}
    </div>
  )
}

const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};

export default Dashboard;