
import React, { useState } from "react";
import CategoriesHeaderWithIcon from "./CategoriesHeaderWithIcon"
import Otc_Sidebar from "../../Layout/Sidebar/Otc_Sidebar";
import Categories from "./Categories"


const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Categories");
    const [showAddCategory, setShowAddCategory] = useState(false);

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    const handleCategoryClick = () => {
        setShowAddCategory(false);
    };
    const handleAddNewCategoryClick = () => {
        setShowAddCategory(true);
    }
    const handleEditCategory = () => {
        setShowAddCategory(true);
    };
    return (
        <div className="onetap_conn_user">
            <CategoriesHeaderWithIcon onChange={getTabValue} tab={companyProfileTab} onAddNewCategoryClick={handleAddNewCategoryClick} onCategoryClick={handleCategoryClick} showAddCategory={showAddCategory} />

            {companyProfileTab === "Categories" && (
                <Categories onClickSave={getValueOfSaveAndContinueTab} showAddCategory={showAddCategory} handleEditCategoryOpen={handleEditCategory} back={handleCategoryClick} />
            )}
        </div>
    );
};


export default function CategoriesMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} productsServicesOpen={true} />
        </>
    )
}



