import React, { useEffect, useState } from "react";
import GenInfoToggleButton from "./GenInfoToggleButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getCompanyProfileDetails,
  loadUser,
  updateCompanyDetailsInfo,
} from "../../../../actions/userAction/userAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../components/Layout/Loader/Loader";
import { Country, State } from "country-state-city";
import moment from "moment-timezone";
import Select from "react-select";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CompanyProfileGeneralInfoTab = (props) => {
  const dispatch = useDispatch();
  const { company, loading, error, message } = useSelector(
    (state) => state.Company
  );
  const { user } = useSelector((state) => state.user);
  const [openWelcomeModal, setOpenWelcomeModal] = React.useState(false);
  const [timeZone, setTimezone] = useState("");
  const [countryValue, setCountryValue] = useState(""); // State for selected country
  const [stateValue, setStateValue] = useState(""); // State for selected state
  const [industries, setIndustries] = useState([]);
  const [companyDetails, setCompanydetails] = useState({});
  const url = process.env.REACT_APP_URL;

  const [companyNamePermission, setCompanyNamePermission] = useState(false);
  const [websiteUrlPermission, setWebsiteUrlPermission] = useState(false);
  const [globalEmailAddressPermission, setGlobalEmailAddressPermission] =
    useState(false);
  const [primaryOfficeNumberPermission, setPrimaryOfficeNumberPermission] =
    useState(false);
  const [faxNumberPermission, setFaxNumberPermission] = useState(false);
  const [primaryActivitiesPermission, setPrimaryActivitiesPermission] =
    useState(false);
  const [makePrivatePermission, setMakePrivatePermission] = useState(false);
  const [addressPermission, setAddressPermission] = useState(false);
  const [apartmentPermission, setApartmentPermission] = useState(false);
  const [cityPermission, setCityPermission] = useState(false);
  const [statePermission, setStatePermission] = useState(false);
  const [postalCodePermission, setPostalCodePermission] = useState(false);
  const [websiteurlError, setWebsiteurlError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [keywordsError, setKeywordsError] = useState("");
  const [contactError, setContactError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [line1Error, setLine1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const { userInfo } = useSelector((state) => state.userInformation);
  const plan = userInfo?.subscription_details?.plan;
  const [apiSuccess, setApiSuccess] = useState(false);

  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const handleToggleCompanyNamePermission = () => {
    setCompanyNamePermission(!companyNamePermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_name_permission: !companyNamePermission,
    }));
    // 
  };

  const handleToggleWebsiteUrlPermission = () => {
    setWebsiteUrlPermission(!websiteUrlPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      website_url_permission: !websiteUrlPermission,
    }));
  };

  const handleToggleGlobalEmailAddressPermission = () => {
    setGlobalEmailAddressPermission(!globalEmailAddressPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      global_email_address_permission: !globalEmailAddressPermission,
    }));
  };

  const handleTogglePrimaryOfficeNumberPermission = () => {
    setPrimaryOfficeNumberPermission(!primaryOfficeNumberPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      primary_office_number_permission: !primaryOfficeNumberPermission,
    }));
  };

  const handleToggleFaxNumberPermission = () => {
    setFaxNumberPermission(!faxNumberPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      fax_number_permission: !faxNumberPermission,
    }));
  };
  const handleTogglePrimaryActivitiesPermission = () => {
    setPrimaryActivitiesPermission(!primaryActivitiesPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      primary_activities_permission: !primaryActivitiesPermission,
    }));
  };

  const handleToggleMakePrivatePermission = () => {
    setMakePrivatePermission(!makePrivatePermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      make_private_permission: !makePrivatePermission,
    }));
  };

  const handleToggleAddressPermission = () => {
    setAddressPermission(!addressPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_line1_address_permission: !addressPermission,
    }));
  };

  const handleToggleApartmentPermission = () => {
    setApartmentPermission(!apartmentPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_line2_apartment_permission: !apartmentPermission,
    }));
  };

  const handleToggleCityPermission = () => {
    setCityPermission(!cityPermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_city_permission: !cityPermission,
    }));
  };

  const handleToggleStatePermission = () => {
    setStatePermission(!statePermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_state_permission: !statePermission,
    }));
  };

  const handleTogglePostalCodePermission = () => {
    setPostalCodePermission(!postalCodePermission);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_postal_code_permission: !postalCodePermission,
    }));
  };
  const getGMTOffsetString = (timeZone) => {
    const offsetMinutes = moment.tz(timeZone).utcOffset();
    const hours = Math.abs(Math.floor(offsetMinutes / 60));
    const minutes = Math.abs(offsetMinutes % 60);
    const sign = offsetMinutes >= 0 ? "+" : "-";
    return `GMT${sign}${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCountryChange = (value) => {
    setCountryValue(value);
    setStateValue(""); // Clear the selected state when the country changes
    // Update the state with the selected country
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        country: value,
        state: "",
        postal_code: ""
      },
    }));
  };

  const handleStateChange = (value) => {
    setStateValue(value);
    // Update the state with the selected state
    setStateError("")
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        state: value,
        postal_code: ""
      },
    }));
  };

  const handleAddressChange = (propertyName, value) => {
    setPostalCodeError("");
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        [propertyName]: value,
      },
    }));
  };
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const response = await axios.get(`${url}/getallcompanies`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }); // Adjust the URL if needed
        setCompanyNames(
          response.data.companies.map((company) => company.company_name)
        );
      } catch (error) {
        ;
      }
    };

    fetchCompanyNames();
  }, []);

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getCompanyProfileDetails());
  }, []);
  useEffect(() => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    } else {
      setTimezone(company?.timeZone);
      setCompanyNamePermission(company?.company_name_permission || false);
      setWebsiteUrlPermission(company?.website_url_permission || false);
      setGlobalEmailAddressPermission(
        company?.global_email_address_permission || false
      );
      setPrimaryOfficeNumberPermission(
        company?.primary_office_number_permission || false
      );
      setFaxNumberPermission(company?.fax_number_permission || false);
      setMakePrivatePermission(company?.make_private_permission || false);
      setAddressPermission(company?.company_line1_address_permission || false);
      setApartmentPermission(
        company?.company_line2_apartment_permission || false
      );
      setCityPermission(company?.company_city_permission || false);
      setStatePermission(company?.company_state_permission || false);
      setPostalCodePermission(company?.company_postal_code_permission || false);
      setPrimaryActivitiesPermission(company?.primary_activities_permission);
    }
  }, [company]);

  useEffect(() => {
    setCompanydetails(company);
  }, [company]);

  const fetchIndustries = async () => {
    try {
      const { data } = await axios.get(`${url}/industries`);
      setIndustries(data.industries);
    } catch (error) {

    }
  };
  //  Regular expression for a basic URL validation
  const urlPattern = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/;
  const isValidUrl = (url) => urlPattern.test(url);

  // Regular expression for a basic email validation
  const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  const isValidEmail = (email) => emailPattern.test(email);

  const handleSave = async (e) => {
    const errors = {
      websiteurlError: "",
      emailError: "",
      companyNameError: "",
      keywordsError: "",
      contactError: "",
      addressCountryError: "",
      addressLine1Error: "",
      addressCityError: "",
      addressStateError: "",
      addressPostalCodeError: "",
    };
    if (!companyDetails?.address?.country) {
      errors.addressCountryError = "country is required.";
    }
    if (!companyDetails?.address?.line1) {
      errors.addressLine1Error = "Adress is required.";
    }
    if (!companyDetails?.address?.city) {
      errors.addressCityError = "City is required.";
    }
    if (!companyDetails?.address?.state) {
      errors.addressStateError = "State is required.";
    }
    if (!companyDetails?.address?.postal_code) {
      errors.addressPostalCodeError = "Postal code is required.";
    }
    if (
      companyDetails?.website_url?.trim() !== "" &&
      !isValidUrl(companyDetails?.website_url)
    ) {
      errors.websiteurlError = "Please enter a valid URL.";
    } else {
      setWebsiteurlError(null);
    }

    if (
      companyDetails?.global_email?.trim() !== "" &&
      !isValidEmail(companyDetails?.global_email)
    ) {
      errors.emailError = "Please enter a valid Email.";
    } else {
      setEmailError(null);
    }
    if (!companyDetails?.global_email?.trim()) {
      errors.emailError = "Email is required.";
    } else {
      setEmailError(null);
    }
    if (userInfo?.subscription_details?.plan === "Team") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);

      if (!trimmedCompanyName) {
        errors.companyNameError = "Company name is required.";
      } else if (matchingCompany) {
        errors.companyNameError = "Company name already exists.";
      }
      else {
        setCompanyNameError(null);
      }
    }
    if (!companyDetails?.keywords?.trim()) {
      errors.keywordsError =
        "Primary Activities / Contact Keyword(s) is required.";
    } else {
      setKeywordsError(null);
    }

    // Validate "Primary Office Number"
    if (
      companyDetails?.contact !== null &&
      (isNaN(companyDetails.contact) || companyDetails.contact === "")
    ) {
      errors.contactError = "Primary Office Number is required.";
    } else {
      setContactError("");
    }

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {

      setWebsiteurlError(errors.websiteurlError);
      setEmailError(errors.emailError); // Fixed a typo here
      setLine1Error(errors.addressLine1Error);
      setCityError(errors.addressCityError);
      setStateError(errors.addressStateError);
      setCompanyNameError(errors.companyNameError);
      setKeywordsError(errors.keywordsError);
      setContactError(errors.contactError);
      setPostalCodeError(errors.addressPostalCodeError);
      return;
    }
    if (e === "continue") {
      // setApiSuccess(true);
      setTimeout(() => {
        props.onClickSave("About");
      }, 3000);
    }

    const postalCode = companyDetails?.address?.postal_code;

    const state = State?.getStateByCodeAndCountry(
      companyDetails?.address?.state,
      companyDetails?.address?.country
    )?.name;
    const apiUrl = `https://api.zippopotam.us/${companyDetails?.address?.country}/${postalCode.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (response.ok !== true) {
      setPostalCodeError("Please enter a valid postal code.");
    } else if (data.places[0].state !== state) {
      setPostalCodeError(`Please enter a valid postal code for ${state}`);
      return;
    } else {
      dispatch(updateCompanyDetailsInfo(companyDetails))
        .then(() => {
          // Reset the form fields and update the state after the API call is successful
          setCompanydetails({ ...companyDetails }); // Replace `initialFormState` with the initial state of your form
          dispatch(getCompanyProfileDetails());
          setApiSuccess(true);
        })
        .catch((error) => {
          // Handle error if the API call fails
          ;
        });
    }

  };
  useEffect(() => {
    if (user?.first_login === true && plan === "Team") {
      setOpenWelcomeModal(true);
    }
  }, [user, plan]);

  const updateFirstLogin = async () => {
    try {
      // API call to update logged in user
      const { data } = await axios.post(
        `${url}/user/updatelogin`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // Check the response data for success or handle it as needed
      if (data.success) {
        dispatch(loadUser());
        setOpenWelcomeModal(false);
      } else {
        // Handle an unsuccessful response, e.g., show an error message
        ;
      }
    } catch (error) {
      // Handle any network or Axios-related errors here
      ;
    }
  };
  function handleNumericInput(event) {
    event.target.value = event.target.value.replace(/\D/, "");
  }

  useEffect(() => {
    fetchIndustries();
  }, []);

  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />

      <>
        <div className="row mb-3">
          <Companygeneralinfo />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row" style={{ gap: "20px" }}>
              <div className="col p-0 mt-2">
                <div className="onetap_conn_general_information_syn ">
                  <div className="d-flex justify-content-between">
                    <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                      Contact Information
                    </p>
                    <p className="fs-6 fw-medium" style={{ color: "#E65925" }}>
                      Allow users to personalize this field?
                    </p>
                  </div>
                  <div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Company name*
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="company_name"
                          value={companyDetails?.company_name}
                          onChange={(e) => {
                            setCompanyNameError(null);
                            handleCompanyInputChange(e);
                          }}
                        />
                        <GenInfoToggleButton
                          isOn={companyNamePermission}
                          onToggle={handleToggleCompanyNamePermission}
                        />
                      </div>
                      {companyNameError ? (
                        <p className="error">{companyNameError}</p>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Website URL
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder="yourwebsite.com"
                          name="website_url"
                          value={companyDetails?.website_url || null}
                          onChange={(e) => {
                            setWebsiteurlError(null); // Clear the email error
                            handleCompanyInputChange(e); // Call your existing input change handler
                          }}
                        />
                        <GenInfoToggleButton
                          isOn={websiteUrlPermission}
                          onToggle={handleToggleWebsiteUrlPermission}
                        />
                      </div>
                      {websiteurlError ? (
                        <p className="error">{websiteurlError}</p>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Global Email Address*
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder="contact@yourcompanyname.com"
                          name="global_email"
                          value={companyDetails?.global_email}
                          onChange={(e) => {
                            setEmailError(null);
                            handleCompanyInputChange(e);
                          }}
                        />
                        <GenInfoToggleButton
                          isOn={globalEmailAddressPermission}
                          onToggle={handleToggleGlobalEmailAddressPermission}
                        />
                      </div>
                      {emailError ? <p className="error">{emailError}</p> : null}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Primary Office Number*
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="contact"
                          value={companyDetails?.contact}
                          onChange={(e) => {
                            setContactError(null);
                            handleCompanyInputChange(e);
                          }}
                          onInput={handleNumericInput}
                        />
                        <GenInfoToggleButton
                          isOn={primaryOfficeNumberPermission}
                          onToggle={handleTogglePrimaryOfficeNumberPermission}
                        />
                      </div>
                      {contactError && <p className="error">{contactError}</p>}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Fax Number
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="fax_number"
                          value={companyDetails?.fax_number}
                          onChange={handleCompanyInputChange}
                          onInput={handleNumericInput}
                        />
                        <GenInfoToggleButton
                          isOn={faxNumberPermission}
                          onToggle={handleToggleFaxNumberPermission}
                        />
                      </div>
                    </div>
                  </div>
                  <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                  <div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Industry
                      </p>
                      <div style={{ position: "relative" }}>
                        <Select
                          styles={{
                            container: (provided) => ({
                              // ...provided,
                              width: "90%", // Set the width of the container as needed
                            }),
                            control: () => ({
                              // No border or box-shadow
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0 0",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              marginLeft: 0,
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0, // Remove margin
                              padding: 0, // Remove padding
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                              // Your custom option styles here
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "90%",
                            }),
                            // More styles for other components if needed...
                          }}
                          className="onetap_conn_general_information_input_syn mb-3 form-select"
                          placeholder="Select Industry"
                          options={industries.map((item) => ({
                            value: item.name,
                            label: item.name,
                          }))}
                          value={{
                            value: companyDetails?.industry,
                            label: companyDetails?.industry || "Select Industry",
                          }}
                          onChange={(selectedOption) =>
                            handleCompanyInputChange({
                              target: {
                                name: "industry",
                                value: selectedOption.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Primary Activities / Contact Keyword(s)*
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder="Ex: Corporate Law, Real Estate Law"
                          name="keywords"
                          value={companyDetails?.keywords}
                          onChange={(e) => {
                            setKeywordsError(null);
                            handleCompanyInputChange(e);
                          }}
                        />
                        <GenInfoToggleButton
                          isOn={primaryActivitiesPermission}
                          onToggle={handleTogglePrimaryActivitiesPermission}
                        />
                      </div>
                      {keywordsError && <p className="error">{keywordsError}</p>}
                    </div>
                  </div>
                  <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                  <div>
                    <div className="mb-3">
                      <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                        Time Zone
                      </h5>
                      <div style={{ position: "relative" }}>
                        <Select
                          styles={{
                            container: (provided) => ({
                              // ...provided,
                              width: "90%", // Set the width of the container as needed
                            }),
                            control: () => ({
                              // No border or box-shadow
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0 0",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              marginLeft: 0,
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0, // Remove margin
                              padding: 0, // Remove padding
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                              // Your custom option styles here
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "90%",
                            }),
                            // More styles for other components if needed...
                          }}
                          className="onetap_conn_general_information_input_syn mb-3 form-select"
                          placeholder="Select timezone"
                          options={moment.tz.names().map((tz) => ({
                            value: tz,
                            label: `${getGMTOffsetString(tz)} - ${tz}`,
                          }))}
                          value={{
                            value: timeZone,
                            label: timeZone
                              ? `${getGMTOffsetString(timeZone)} - ${timeZone}`
                              : "Select Timezone",
                          }}
                          onChange={(selectedOption) => {
                            setTimezone(selectedOption.value);

                            setCompanydetails((prevDetails) => ({
                              ...prevDetails,
                              timeZone: selectedOption.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="row">
                  <div className="onetap_conn_general_information_syn">
                    <div className="d-flex justify-content-between">
                      <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                        Address
                      </p>
                      <p className="fs-6 fw-medium" style={{ color: "#E65925" }}>
                        Make Private
                      </p>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Country*
                      </p>
                      <div
                        className="dropdown countrydrop"
                        style={{ width: "90%" }}
                      >
                        <Select
                          styles={{
                            container: (provided) => ({
                              // ...provided,
                              width: "100%", // Set the width of the container as needed
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              padding: "0 0",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              marginLeft: 0,
                            }),
                            control: () => ({
                              // No border or box-shadow
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0, // Remove margin
                              padding: 0, // Remove padding
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                              // Your custom option styles here
                            }),
                            // More styles for other components if needed...
                          }}
                          className="onetap_conn_general_information_input_syn mb-3 form-select"
                          placeholder="Select"
                          name="address.country"
                          options={Country.getAllCountries().map((item) => ({
                            value: item.isoCode,
                            label: item.name,
                          }))}
                          value={{
                            value: companyDetails?.address?.country,
                            label: Country.getCountryByCode(
                              companyDetails?.address?.country
                            )?.name,
                          }}
                          onChange={(selectedOption) =>
                            handleCountryChange(selectedOption.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Address*
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.line1"
                          value={companyDetails?.address?.line1}
                          onChange={(e) => { handleAddressChange("line1", e.target.value); setLine1Error("") }
                          }
                        />
                        <GenInfoToggleButton
                          isOn={addressPermission}
                          onToggle={handleToggleAddressPermission}
                        />
                      </div>
                      {line1Error && (
                        <p className="error_message">{line1Error}</p>
                      )}</div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Apt, suite, etc
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.line2"
                          value={companyDetails?.address?.line2}
                          onChange={(e) =>
                            handleAddressChange("line2", e.target.value)
                          }
                        />
                        <GenInfoToggleButton
                          isOn={apartmentPermission}
                          onToggle={handleToggleApartmentPermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        City*
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.city"
                          value={companyDetails?.address?.city}
                          onChange={(e) => { handleAddressChange("city", e.target.value); setCityError("") }
                          }
                        />
                        <GenInfoToggleButton
                          isOn={cityPermission}
                          onToggle={handleToggleCityPermission}
                        />
                      </div>
                      {cityError && (
                        <p className="error_message">{cityError}</p>
                      )}</div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        State*
                      </p>
                      <div className="d-flex justify-content-between ">
                        <div
                          className="dropdown countrydrop"
                          style={{ width: "90%" }}
                        >
                          <Select
                            styles={{
                              container: (provided) => ({
                                // ...provided,
                                width: "100%", // Set the width of the container as needed
                              }),
                              control: () => ({
                                // No border or box-shadow
                                margin: 0,
                                padding: 0,
                                border: "none",
                                boxShadow: "none",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0 0",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                marginLeft: 0,
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: 0, // Remove margin
                                padding: 0, // Remove padding
                              }),
                              indicatorsContainer: () => ({
                                display: "none",
                              }),
                              option: (provided) => ({
                                ...provided,
                                // Your custom option styles here
                              }),
                              // More styles for other components if needed...
                            }}
                            className="onetap_conn_general_information_input_syn mb-3 form-select"
                            placeholder="Select"
                            name="address.state"
                            options={State.getStatesOfCountry(
                              companyDetails?.address?.country
                            ).map((item) => ({
                              value: item.isoCode,
                              label: item.name,
                            }))}
                            value={{
                              value: companyDetails?.address?.state,
                              label: State.getStateByCodeAndCountry(
                                companyDetails?.address?.state,
                                companyDetails?.address?.country
                              )?.name,
                            }}
                            onChange={(selectedOption) => { handleStateChange(selectedOption.value); setStateError("") }
                            }
                          />
                        </div>
                        <GenInfoToggleButton
                          isOn={statePermission}
                          onToggle={handleToggleStatePermission}
                        />
                      </div>
                      {stateError && (
                        <p className="error_message">{stateError}</p>
                      )}</div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Postal code*
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.postal_code"
                          value={companyDetails?.address?.postal_code}
                          onChange={(e) =>
                            handleAddressChange("postal_code", e.target.value)
                          }
                        />
                        <GenInfoToggleButton
                          isOn={postalCodePermission}
                          onToggle={handleTogglePostalCodePermission}
                        />
                      </div>
                      {postalCodeError && (
                        <p className="error_message">{postalCodeError}</p>
                      )}
                    </div>
                    <div className="d-flex border-top p-3 mt-5">
                      <div style={{ width: "95%" }}>
                        <p>
                          Allow users to personalize the address shown on their
                          own card.
                        </p>
                        <p style={{ color: "#E65925" }}>
                          You may override this setting on a per template/user
                          basis.
                        </p>
                      </div>
                      <GenInfoToggleButton
                        isOn={makePrivatePermission}
                        onToggle={handleToggleMakePrivatePermission}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="d-flex justify-content-end">
                <button
                  className="onetap_connect__comp_profile_save_button_syn"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  onClick={(e) => {
                    handleSave("continue");
                  }}
                  className="onetap_connect__comp_profile_save_and_cont_button_syn"
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </>
        )}
      </>

      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: "center" }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                  class="circle"
                >
                  <path
                    d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                    fill="#E65925"
                  />
                  <path
                    d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
                    stroke="white"
                    stroke-width="6.64"
                    stroke-linecap="round"
                    class="checkmark-path"
                  />
                </svg>
              </div>
            </div>
            <span className="team_created_success">
              Data updated successfully!
            </span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              width: "13%",
            }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
            </span>
          </button>
        </Box>
      </Modal>
      <Modal
        open={openWelcomeModal}
        onClose={() => updateFirstLogin()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <div className="Modal-section">
            <h4 className="header"> Onboarding</h4>
            <div className="w-100 mb-3">
              <iframe
                style={{ borderRadius: "8px" }}
                width="100%"
                height="200"
                src="https://www.youtube.com/embed/D0UnqGm_miA?si=ciAdQoP2ywrG9jYd"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>

            <p>
              Hi {user?.first_name ? user?.first_name : ""}, welcome onboard!{" "}
            </p>
            <p>
              Setting up your account is very simple. If you purchase our
              <span style={{ color: "#2572E6" }}> 1 to 1 onboarding</span>, one
              of our team member will be reaching out shortly. Until then, feel
              free to discover the OneTapConnect platform on your own.
            </p>

            <b>First let’s start with your company information...</b>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn w-100"
                onClick={() => {
                  updateFirstLogin();
                }}
              >
                Start now
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const Companygeneralinfo = () => {
  return (
    <div className="onetap_conn_general_information_syn">
      <h4 className="onetap_conn_general_information_heading_syn">
        Company Information
      </h4>
      <hr style={{ height: "px", marginBottom: "unset" }} />
      <p className="onetap_conn_general_information_para_syn" style={{ marginTop: '10px' }}>
        The company information will be used as the default values for your
        OneTapConnect cards. They can be override when creating templates and
        cards. If you’re looking to update your company information for billing,
        visit Account & Billing.
      </p>
    </div>
  );
};

export default CompanyProfileGeneralInfoTab;
export { Companygeneralinfo };
