import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import {
  getTeamMember,
} from "../../../actions/userAction/userAction";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Box, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const url = process.env.REACT_APP_URL;
const CompanyBillingUserRolesTab = () => {
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  const dispatch = useDispatch();
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { user } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.Users);
  const { userInfo } = useSelector((state) => state.userInformation);
  const [selectedMembers, setSelectedMembers] = useState({
    superAdmins: [],
    administrators: [],
    managers: [],
  });
  const [oldSelectedValues, setoldSelectedValues] = useState({
    superAdmins: [],
    administrators: [],
    managers: [],
  });
  useEffect(() => {
    dispatch(getTeamMember());
  }, []);
  useEffect(() => {
    if (!users) {
      dispatch(getTeamMember());
    } else {
      // Initialize selectedMembers based on users' roles once user data is available
      const initialSelectedMembers = {
        superAdmins: users
          .filter((user) => user.role === "superadmin")
          .map((user) => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name} (${user.email})`,
            role: user.role,
          })),
        administrators: users
          .filter((user) => user.role === "administrator")
          .map((user) => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name} (${user.email})`,
            role: user.role,
          })),
        managers: users
          .filter((user) => user.role === "manager")
          .map((user) => ({
            value: user._id,
            label: `${user.first_name} ${user.last_name} (${user.email})`,
            role: user.role,
          })),
      };
      setSelectedMembers(initialSelectedMembers);
      setoldSelectedValues(initialSelectedMembers);
    }
  }, [users]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      padding: "10px",
      alignItems: "left",
      alignSelf: "stretch",
      borderRadius: "4px",
      background: "#f2f2f2",
      border: "none",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      background: '#E65925 !important',
      color: "#FFFFFF !important", // Change the text color here
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#FFFFFF", // Change the text color here
    }),
  };
  const superAdminStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      padding: "10px",
      alignItems: "left",
      alignSelf: "stretch",
      borderRadius: "4px",
      background: "#f2f2f2",
      border: "none",
      cursor: "pointer",
    }),
    multiValue: (provided) => ({
      ...provided,
      background: '#E65925 !important',
      color: "#FFFFFF !important", // Change the text color here
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#FFFFFF", // Change the text color here
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      display: (selectedMembers.superAdmins.length > 1) ? 'inline-block' : 'none',
    }),
  };
  // Define options based on your users' data, but filter out preselected users
  const options = users
    ? users
      .filter(
        (user) =>
          !selectedMembers.superAdmins.some(
            (selected) => selected._id === user._id
          ) &&
          !selectedMembers.administrators.some(
            (selected) => selected._id === user._id
          ) &&
          !selectedMembers.managers.some(
            (selected) => selected._id === user._id
          )
      )
      .map((user) => ({
        value: user._id,
        label: `${user.first_name} ${user.last_name} (${user.email})`,
        role: user.role,
      }))
    : [];
  // Filter options based on selections
  const superAdminOptions = options
    ? options.filter(
      (option) =>
        !selectedMembers.administrators.some(
          (selected) => selected.value === option.value
        ) &&
        !selectedMembers.managers.some(
          (selected) => selected.value === option.value
        )
    )
    : [];
  const adminOptions = options
    ? options.filter(
      (option) =>
        !selectedMembers.superAdmins.some(
          (selected) => selected.value === option.value
        ) &&
        !selectedMembers.managers.some(
          (selected) => selected.value === option.value
        )
    )
    : [];
  const managerOptions = options
    ? options.filter(
      (option) =>
        !selectedMembers.superAdmins.some(
          (selected) => selected.value === option.value
        ) &&
        !selectedMembers.administrators.some(
          (selected) => selected.value === option.value
        )
    )
    : [];
  const copyUserplanfunction = async (userIds, subscriptions) => {
    const updatedPlanData = {
      userID: userIds,
      subscriptionDetails: subscriptions,
    };
    try {
      const { data } = await axios.post(
        `${url}/user/updateplanonrolechange`,
        updatedPlanData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setApiSuccess(true);
      setSuccessMessage("User role updated successfully.");
      setTimeout(() => {
        setApiSuccess(false)
      }, 5000);
    } catch (error) {
      ;
    }
  }
  const teammembersubdetails = {
    subscription_id: null,
    addones: [],
    plan: null,
    auto_renewal: true,
    total_user: []
  }
  const handleSuperAdminChange = async (selectedOptions) => {
    setSelectedMembers((prevSelectedMembers) => ({
      ...prevSelectedMembers,
      superAdmins: selectedOptions,
    }));
  };
  const handleAdminChange = async (selectedOptions) => {
    setSelectedMembers((prevSelectedMembers) => ({
      ...prevSelectedMembers,
      administrators: selectedOptions,
    }));
  };
  const handleManagerChange = async (selectedOptions) => {
    setSelectedMembers((prevSelectedMembers) => ({
      ...prevSelectedMembers,
      managers: selectedOptions,
    }));
  };
  const handleSave = async () => {
    try {
      // Find deselected users and add them to the updatedData with role "teammember"
      const deselectedSuperAdmins = oldSelectedValues.superAdmins
        .filter((user) => !selectedMembers.superAdmins.some((selected) => selected.value === user.value))
        .map((user) => user.value);
      const deselectedAdministrators = oldSelectedValues.administrators
        .filter((user) => !selectedMembers.administrators.some((selected) => selected.value === user.value))
        .map((user) => user.value);
      const deselectedManagers = oldSelectedValues.managers
        .filter((user) => !selectedMembers.managers.some((selected) => selected.value === user.value))
        .map((user) => user.value);
      // Function to check if a user is already in one of the other three categories
      const isUserInOtherCategories = (userId) => {
        return (
          selectedMembers.superAdmins.some((user) => user.value === userId) ||
          selectedMembers.administrators.some((user) => user.value === userId) ||
          selectedMembers.managers.some((user) => user.value === userId)
        );
      };
      // Prepare data for the backend update
      const updatedData = {
        superAdmins: selectedMembers.superAdmins.map((user) => ({ id: user.value, role: 'superadmin' })),
        administrators: selectedMembers.administrators.map((user) => ({ id: user.value, role: 'administrator' })),
        managers: selectedMembers.managers.map((user) => ({ id: user.value, role: 'manager' })),
        teammember: [
          ...deselectedSuperAdmins.filter((id) => !isUserInOtherCategories(id)).map(id => ({ id, role: 'teammember' })),
          ...deselectedAdministrators.filter((id) => !isUserInOtherCategories(id)).map(id => ({ id, role: 'teammember' })),
          ...deselectedManagers.filter((id) => !isUserInOtherCategories(id)).map(id => ({ id, role: 'teammember' }))
        ]
      };
      // Make an Axios POST request to update user roles
      const response = await axios.post(`${url}/user/updaterole`, updatedData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      // Handle the response from the backend (you can display a success message using toast)
      if (response.data.success) {
        // Extract the IDs from the updatedData
        const superAdminIds = updatedData.superAdmins.map((user) => user.id);
        const administratorIds = updatedData.administrators.map((user) => user.id);
        const managerIds = updatedData.managers.map((user) => user.id);
        const teammembersIds = updatedData.teammember.map((user) => user.id);
        // Update the plan based on the new roles
        if (updatedData.teammember.length > 0) {
          // Update the plan for team members
          copyUserplanfunction(teammembersIds, teammembersubdetails);
        }
        if (updatedData.superAdmins.length > 0) {
          // Update the plan for super admins
          copyUserplanfunction(superAdminIds, userInfo?.subscription_details);
        }
        if (updatedData.administrators.length > 0) {
          // Update the plan for administrators
          copyUserplanfunction(administratorIds, userInfo?.subscription_details);
        }
        if (updatedData.managers.length > 0) {
          // Update the plan for managers
          copyUserplanfunction(managerIds, userInfo?.subscription_details);
        }
      } else {
        ;
      }
    } catch (error) {
      ;
    }
  };
  return (
    <>
      <div>
        <div className="main-container-billing">
          <div className="upper-main-section">
            <div className="upper-info-section">
              <h2 className="upper-info-h2">User Roles</h2>
              <span className="upper-info-span">
                By default all users invited that join your company have a user
                role of “Team member” which give them access to only manage
                their information (depending on the global access you have set
                in the company settings).
                <br />
                Below you can provide specific users additional privileges to
                allow them to manage the account with you.
              </span>
            </div>
          </div>
        </div>
        <div className="main-container-billing mt-3">
          <div className="upper-main-section d-block">
            {user.role === "superadmin" && (
              <div className="upper-info-section">
                <h2 className="upper-info-h2">Super Admin</h2>
                <span className="upper-info-span">
                  SuperAdmin are the “owners” of the account. They have full
                  access to the platform and can create or remove other
                  SuperAdmin users.
                </span>
                <div
                  className="onetap_conn_billing_form_input_syn mt-2"
                  style={{ display: "grid" }}
                >
                  <Select
                    styles={superAdminStyles}
                    isMulti
                    options={superAdminOptions}
                    value={selectedMembers.superAdmins}
                    onChange={handleSuperAdminChange}
                    isClearable={false}
                    onKeyDown={(e) => {
                      if (e.keyCode === 8 || e.keyCode === 46) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {user.role === "superadmin" && (
              <div className="upper-info-section mt-2">
                <h2 className="upper-info-h2 mt-4">Administrator</h2>
                <span className="upper-info-span">
                  Administrators have access to everything (including billing)
                  but cannot create/remove SuperAdmin. This is the role to give
                  to the person in charge of managing the account.
                </span>
                <div
                  className="onetap_conn_billing_form_input_syn mt-2"
                  style={{ display: "grid" }}
                >
                  <Select
                    styles={customStyles}
                    isMulti
                    options={adminOptions}
                    value={selectedMembers.administrators}
                    onChange={handleAdminChange}
                  />
                </div>
              </div>
            )}
            <div className="upper-info-section mt-2">
              <h2 className="upper-info-h2 mt-4">Manager</h2>
              <span className="upper-info-span">
                A manager can manage everything related to the company users.
                They can invite, enable/disable users, edit user information and
                digital card. But they do not have access to the company
                settings, billing, template design, etc.
              </span>
              <div
                className="onetap_conn_billing_form_input_syn mt-2"
                style={{ display: "grid" }}
              >
                <Select
                  styles={customStyles}
                  isMulti
                  options={managerOptions}
                  value={selectedMembers.managers}
                  onChange={handleManagerChange}
                />
              </div>
            </div>
          </div>
          <button className="mt-5" style={{ width: '25%', border: 'none', borderRadius: "4px", float: 'right', color: '#FFF', background: '#E65925' }} onClick={handleSave} >Save</button>
        </div>
      </div>
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                  class="circle">
                  <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                    fill="#E65925" />
                  <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                </svg>
              </div>
            </div>
            <span className="team_created_success">{successMessage}</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
          </button>
        </Box>
      </Modal>
    </>
  );
};
export default CompanyBillingUserRolesTab;
