
import React, { useState } from "react";
import ProductAndServicesHeaderWithIcons from "./ProductAndServicesHeaderWithIcons";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import ProductsInfo from "./ProductsInfo";

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Smart Accessories");
    const [showCreateProduct, setShowCreateProduct] = useState(false);

    const handleProductClick = () => {
        setShowCreateProduct(false);
    };
    const handleAddNewProductClick = () => {
        setShowCreateProduct(true);
    }
    const handleProductNameClick = () => {
        setShowCreateProduct(true);
    };
    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };
    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="onetap_conn_user">
            <ProductAndServicesHeaderWithIcons onChange={getTabValue} tab={companyProfileTab}
                showCreateProduct={showCreateProduct}
                onProductClick={handleProductClick} 
                onAddNewProductClick={handleAddNewProductClick} 
            />
            {companyProfileTab === "Smart Accessories" && (
                <ProductsInfo onClickSave={getValueOfSaveAndContinueTab}
                    showCreateProduct={showCreateProduct} onProductNameClick={handleProductNameClick} handleProductClick={handleProductClick}
                />
            )}
        </div>
    );
};

export default function ProductAndServices() {
    return (
        <>
            <Otc_Sidebar Component={Component} productsServicesOpen={true} />
        </>
    )
}





