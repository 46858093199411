import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../../../components/Layout/Loader/Loader";

const PlanComarison = ({ handleEditPlanOpen, showFeatureDetail, back }) => {
  const url = process.env.REACT_APP_URL;
  const { adminuser } = useSelector((state) => state.OtcAdminuser);
  const [features, setFeatures] = useState([]);
  const [nameError, setNameError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [featureData, setFeatureData] = useState(
    {
      visibility: 'Public',
      status: 'Published',
      publishedBy: adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : '',
    }
  );
  const [currentDateTime, setCurrentDateTime] = useState('');
  const getFeature = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${url}/admin/featureData`);
      if (response) {
        const data = response.data.feature;
        setFeatures(data);
        setLoading(false)
      } else {
      }
    } catch (error) {
    }
  };

  const handleEditPlan = (selectedCard) => {
    setFeatureData(selectedCard)
  };
  useEffect(() => {
    getFeature();
  }, []);
  useEffect(() => {
    const formatDateTime = () => {
      const now = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      const formattedDate = now.toLocaleDateString('en-US', options);
      const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
      const hour = now.getHours() % 12 || 12;
      const formattedTime = `${hour}:${now.getMinutes()} ${amPm}`;
      setCurrentDateTime(`${formattedDate} @ ${formattedTime}`);
    };

    formatDateTime(); 
    const interval = setInterval(formatDateTime, 60000);
    return () => {
      clearInterval(interval); 
    };
  }, [showFeatureDetail]);


  useEffect(() => {
    if (!showFeatureDetail) {
      setCurrentDateTime('')
      setNameError("");
      setFeatureData(
        {
          visibility: 'Public',
          status: 'Published',
          publishedBy: adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : '',
        }
      )
    }
  }, [showFeatureDetail])
  const handleAddClick = () => {
    const initialFeature = {
      featureName: '',
      free: { value: '', comment: '' },
      professional: { value: '', comment: '' },
      team: { value: '', comment: '' },
    };

    setFeatureData((prev) => ({
      ...prev,
      features: [...(prev.features || []), initialFeature], 
    }));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFeatureData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFeatureInputChange = (index, field, value) => {
    const updatedFeatures = [...featureData.features];
    updatedFeatures[index][field] = value;

    setFeatureData({
      ...featureData,
      features: updatedFeatures,
    });
  };
  const handlePublish = async () => {
    if (!featureData?.fieldName) {
      setNameError("Name is required.");
      return;
    }
    setNameError("");
    const response = await axios.post(
      `${url}/admin/updateComparisionData`,
      {
        id: featureData?._id,
        featureData
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      getFeature()
      back()
    }
  }
  const formattedDate = featureData?.createdAt
    ? new Date(featureData?.createdAt).toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).replace(',', ' @')
    : currentDateTime;

  return (
    <>
      {!showFeatureDetail ? (

        <div className="pc-main-container">
          <div className="pc-child-container">
            <h3 className="pc-header-text"></h3>
            <div
              style={{ paddingTop: "20px" }}
              className="plan-search-and-status"
            >
              <div className="plan-search-container"></div>
              <div className="plan-status-dropdown-div"></div>
              <div className="plan-status-dropdown-div"></div>
            </div>

            <div className="pc-categories-main-section">
              <div
                className="plan-category-title"
                style={{
                  gridTemplateColumns: "1fr 16% 16% 14% 15%",
                }}
              >
                <div className="">
                  <span></span>
                  <div className="product-details">
                    <span className="product-name-text">Feature</span>
                  </div>
                </div>
              </div>
              {loading ? <Loader /> : features?.length === 0 ? (
                <>
                  <div className="pc-category-card-section">
                    <div className="plan-category-cards">
                      <div className="pc-img-and-text-div">
                        <div className="product-details">
                          <span className="product-name-text">
                            No Data found
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {features &&
                    features?.map((item) => (
                      <div className="pc-category-card-section">
                        <div className="plan-category-cards">
                          <div className="pc-img-and-text-div">
                            <div
                              style={{ cursor: "pointer" }}
                              className="product-details"
                              onClick={() => {
                                handleEditPlan(item);
                                handleEditPlanOpen();
                              }}
                            >
                              <span className="product-name-text">
                                {item?.fieldName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>

      ) : (
        <>
          <div className="">
            <div className="w-100  d-flex flex-row-reverse gap-3 mb-4 plan-btn-top">
              <button
                className="btn px-3 py-2  text-white"
                style={{
                  background: featureData?._id ? '#e65925' : "#48AF25",
                  width: "15%",
                }}
                disabled={featureData.status === "Draft"}
                onClick={() => handlePublish("Published")}
              >
                {featureData?._id ? 'Update' : 'Publish'}
              </button>
              <button
                className="btn px-3 py-2 text-white"
                style={{
                  background: featureData.status !== "Draft" ? "#8A8A8A" : '#595555',
                  width: "15%",
                }}
                disabled={featureData.status !== "Draft"}
                onClick={() => handlePublish("Draft")}
              >
                Save draft
              </button>
            </div>

            <div className="row">
              <div className="col-8">
                <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                  <h5>Name </h5>
                  <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="fieldName"
                    value={featureData.fieldName}
                    onChange={(e) => { handleInputChange(e) }}

                  />
                  {nameError && (
                    <span className="error-message">{nameError}</span>
                  )}
                </div>

                <div className="bg-white p-4 mt-3" style={{ borderRadius: '8px' }}>

                  <div className="d-flex justify-content-between mb-3">
                    <h5>Feature List</h5>
                    <div role="button" onClick={handleAddClick}>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                      >
                        <rect width="34" height="34" rx="9" fill="#E65925" />
                        <path
                          d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                          fill="white"
                        />
                      </svg>
                      <span className="ms-2">Add </span>
                    </div>
                  </div>

                  <div class="accordion" id="accordionExample">
                    {featureData?.features?.map((val, index) => (
                      <div className="d-flex gap-2">
                        <div class="accordion-item mb-3" key={index} style={{ width: "95%" }}>
                          <h2 class="accordion-header" id={`heading${index}`} style={{ backgroundColor: '#f2f2f2' }}>
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === featureData?.features?.length - 1 ? 'true' : 'false'} aria-controls={`collapse${index}`} style={{ backgroundColor: '#f2f2f2', boxShadow: 'unset' }}>
                              {val.featureName ? val.featureName : 'Feature'}
                            </button>
                          </h2>
                          <div id={`collapse${index}`} class={`accordion-collapse collapse ${index === featureData?.features?.length - 1 ? 'show' : ''}`} aria-labelledby={`heading${index}`}>
                            <div className="accordion-body">
                              <>
                                <h5>Feature Name</h5>
                                <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="name"
                                  value={val?.featureName}
                                  onChange={(e) => handleFeatureInputChange(index, 'featureName', e.target.value)}
                                />
                                <div className="w-100 d-flex gap-3 mt-3">
                                  <div className="w-100">
                                    <span>Free</span>
                                    <select
                                      class="form-select py-2 p-2"
                                      style={{ backgroundColor: "#f2f2f2" }}
                                      name="visibility"
                                      value={val?.free?.value}
                                      onChange={(e) => handleFeatureInputChange(index, 'free', { ...val.free, value: e.target.value })}
                                    >
                                      <option selected className="d-none" >Select</option>
                                      <option value="Yes">
                                        Yes
                                      </option>
                                      <option value="No">No</option>
                                      <option value="Comment">Comment</option>
                                    </select>
                                    <input
                                      className={`w-100 border-0 p-2 mt-3 ${val?.free?.value === 'Comment' ? '' : 'd-none'}`}
                                      type="text"
                                      style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                      name="name"
                                      value={val?.free?.comment}
                                      onChange={(e) => handleFeatureInputChange(index, 'free', { ...val.free, comment: e.target.value })}
                                    />
                                  </div>

                                  <div className="w-100">
                                    <span>Professional</span>
                                    <select
                                      class="form-select py-2 p-2"
                                      style={{ backgroundColor: "#f2f2f2" }}
                                      name="visibility"
                                      value={val?.professional?.value}
                                      onChange={(e) => handleFeatureInputChange(index, 'professional', { ...val.professional, value: e.target.value })}

                                    >
                                      <option selected className="d-none" >Select</option>
                                      <option value="Yes">
                                        Yes
                                      </option>
                                      <option value="No">No</option>
                                      <option value="Comment">Comment</option>
                                    </select>

                                    <input
                                      className={`w-100 border-0 p-2 mt-3 ${val?.professional?.value === 'Comment' ? '' : 'd-none'}`}
                                      type="text"
                                      style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                      name="name"
                                      value={val?.professional?.comment}
                                      onChange={(e) => handleFeatureInputChange(index, 'professional', { ...val.professional, comment: e.target.value })}
                                    />
                                  </div>

                                  <div className="w-100">
                                    <span>Team</span>
                                    <select
                                      class="form-select py-2 p-2"
                                      style={{ backgroundColor: "#f2f2f2" }}
                                      name="visibility"
                                      value={val?.team?.value}
                                      onChange={(e) => handleFeatureInputChange(index, 'team', { ...val.team, value: e.target.value })}

                                    >
                                      <option selected className="d-none" >Select</option>
                                      <option value="Yes">
                                        Yes
                                      </option>
                                      <option value="No">No</option>
                                      <option value="Comment">Comment</option>
                                    </select>

                                    <input
                                      className={`w-100 border-0 p-2 mt-3 ${val?.team?.value === 'Comment' ? '' : 'd-none'}`}
                                      type="text"
                                      style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                      name="name"
                                      value={val?.team?.comment}
                                      onChange={(e) => handleFeatureInputChange(index, 'team', { ...val.team, comment: e.target.value })}
                                    />
                                  </div>
                                </div>
                              </>

                            </div>
                          </div>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                          <span
                            style={{ paddingTop: "10px", cursor: "pointer", color: 'red', fontSize: 'small' }}
                            onClick={() => {
                              const updatedVariationData = featureData?.features?.filter((_, i) => i !== index);
                              setFeatureData((prev) => ({
                                ...prev,
                                features: updatedVariationData,
                              }));
                            }}
                          >
                            Remove
                          </span>
                        </div>

                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="bg-white p-4" style={{ borderRadius: "4px" }}>
                  <h6 className="border-bottom pb-2">
                    Information
                  </h6>
                  <div className="gap-3 d-flex flex-column">
                    <div className="mt-2">
                      <span>Status*</span>
                      <select
                        class="form-select py-2 p-2"
                        style={{ backgroundColor: "#f2f2f2" }}
                        name="status"
                        value={featureData.status}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option value="Draft">Draft</option>
                        <option selected value="Published">
                          Published
                        </option>
                        <option value="Archived">Archived</option>
                      </select>
                    </div>
                    <div>
                      <span>Visibility*</span>
                      <select
                        class="form-select py-2 p-2"
                        style={{ backgroundColor: "#f2f2f2" }}
                        name="visibility"
                        value={featureData.visibility}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option selected value="Public">
                          Public
                        </option>
                        <option value="Hidden">Hidden</option>
                      </select>
                    </div>
                    <div>
                      <span>Published date</span>
                      <input
                        value={formattedDate}
                        className="w-100 border-0 p-2"
                        readOnly
                        type="text"
                        style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                      />
                    </div>
                    <div>
                      <span>Published by</span>
                      <input
                        className="w-100 border-0 p-2"
                        type="text"
                        readOnly
                        style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                        name="publishedBy"
                        value={featureData?.publishedBy || adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : ''}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="bg-white p-4 mt-2"
                  style={{ borderRadius: "8px" }}
                >
                  <h5>Activity log</h5>
                  <textarea
                    rows={4}
                    className="w-100 border-0 p-2"
                    type="text"
                    readOnly="true"
                    style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                    name="activityLog"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PlanComarison;
