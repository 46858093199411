import React, { useState, useEffect } from "react";
import Otc_Sidebar from "../../Layout/Sidebar/Otc_Sidebar";
import { useParams } from "react-router-dom";
import { getProducts } from "../../../actions/userAction/userAction";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../Redux/Otc_UserActions";
import ProductIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/ProductIcon.svg";
import AddonIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_addons_icon.svg";
import UserIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_users_icon.svg";
import PlanIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_plan_icon.svg";

function ClientCompanyOrderSummaryinner() {
  const dispatch = useDispatch();
  const { orderNumber } = useParams();
  const url = process.env.REACT_APP_URL;
  const { products } = useSelector((state) => state.products);
  const [shippingAddress, setShippingAddress] = useState({});
  const [shippingMethod, setShippingMethod] = useState();
  const [billingAddress, setBillingAddress] = useState({});
  const [orderData, setOrderData] = useState("");
  const [addons, setAddons] = useState([]);
  const [smartAccessories, setSmartAccessories] = useState([]);
  const [addonPurchase, setAddonPurchase] = useState([]);
  const [userPurchase, setUserPurchase] = useState([]);
  const { order, loading } = useSelector((state) => state.order);
  const [planArray, setPlanArray] = useState([]);
  const [addonsubtotal, setAddonsubtotal] = useState(0);

  useEffect(() => {
    if (!products) {
      dispatch(getProducts());
    }
  }, [products, dispatch]);

  useEffect(() => {
    dispatch(getSingleOrder(orderNumber));
  }, [orderNumber]);

  useEffect(() => {
    if (order) {
      setOrderData(order);
    }
  }, [order]);

  useEffect(() => {
    if (orderData) {
      setAddons(orderData?.subscription_details?.addones);
      setSmartAccessories(orderData?.smartAccessories);
      setShippingAddress(
        orderData?.shippingAddress && orderData?.shippingAddress[0]
      );
      setBillingAddress(
        orderData?.billingAddress && orderData?.billingAddress[0]
      );
      setAddonPurchase(orderData?.addaddons);
      setUserPurchase(orderData?.addusers);
      setAddonsubtotal(orderData?.totalAmount);

      // setShippingMethod(orderData?.shipping_method && orderData?.shipping_method[0]);
      setShippingMethod(orderData?.totalShipping);
      if (
        orderData.subscription_details &&
        typeof orderData.subscription_details === "object"
      ) {
        // Extract properties from subscription_details and create an array with a single object
        const subscriptionDetailsArray = [
          Object.entries(orderData.subscription_details)
            .filter(([key]) => key !== "addones")
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        ];

        setPlanArray(subscriptionDetailsArray);
      }
    }
  }, [orderData]);

  return (
    <div className="d-flex row">
      <div className=" mt-2 col-6 ">
        <div
          className="row left-side-section"
          style={{ marginRight: 0, marginLeft: 0 }}
        >
          <div className="info-and-btn-section">
            <h4 className="left-info-h4">Shipping address</h4>
          </div>
          <div className="left-textArea">
            <span className="left-span">
              <p style={{ marginBottom: "0" }}>
                {shippingAddress?.first_name} {shippingAddress?.last_name}
              </p>
              <p style={{ marginBottom: "0" }}>
                {shippingAddress?.company_name}
              </p>
              <p style={{ marginBottom: "0" }}>
                {shippingAddress?.line1}
                {shippingAddress?.line2}
              </p>
              <p style={{ marginBottom: "0" }}>
                {shippingAddress?.city},&nbsp;,&nbsp;
                {shippingAddress?.postal_code}
              </p>
              <p style={{ marginBottom: "0" }}>{shippingAddress?.country}</p>
            </span>
          </div>
        </div>
        <div
          className="row left-side-section mt-2"
          style={{ marginRight: 0, marginLeft: 0 }}
        >
          <div className="info-and-btn-section">
            <h4 className="left-info-h4">Billing address</h4>
          </div>
          <div className="left-textArea">
            <span className="left-span">
              <p style={{ marginBottom: "0" }}>
                {billingAddress?.first_name} {billingAddress?.last_name}
              </p>
              <p style={{ marginBottom: "0" }}>
                {billingAddress?.company_name}{" "}
              </p>
              <p style={{ marginBottom: "0" }}>
                {billingAddress?.line1} {billingAddress?.line2}
              </p>
              <p style={{ marginBottom: "0" }}>
                {billingAddress?.city},&nbsp;&nbsp;
                {billingAddress?.postal_code}{" "}
              </p>
              <p style={{ marginBottom: "0" }}>{billingAddress?.country}</p>
            </span>
          </div>
        </div>

        <div className="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
          <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
            <h5>Order Note</h5>
            <hr />
            <div
              style={{
                borderRadius: "4px",
                background: "#f2f2f2",
                border: "none",
                height: "72px",
                padding: "8px",
                overflowY: "auto",
              }}
              suppressContentEditableWarning={true}
            >
              How can we help ?
            </div>
          </div>
        </div>
        <div className="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
          <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
            <h5>Payment Method</h5>
            <hr />
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
              className="order-ful-header-component"
            >
              <span className="pc-category-title-text justify-content-start">
                Method
              </span>
              <span className="pc-category-title-text ">Status</span>
              <span className="pc-category-title-text ">Amount Due Today</span>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                backgroundColor: "rgb(242, 242, 242)",
                height: "50px",
                marginTop: "10px",
              }}
              className="order-ful-header-component"
            >
              <span className="pc-category-title-text  justify-content-start"></span>
              <span className="pc-category-title-text "></span>
              <span className="pc-category-title-text "></span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 col-6">
        <div className="bg-white p-4 " style={{ borderRadius: "8px" }}>
          <div className="smartAcc-text-order">Order Details </div>
          <div
            style={{
              marginTop: "30px",
              paddingLeft: "16px",
              paddingRight: "20px",
            }}
            className="order-ful-header-component"
          >
            <span className="">Items</span>
            <span className="pc-category-title-text ">Quantity</span>
            <span className="pc-category-title-text ">Unite price</span>
            <span className="pc-category-title-text ">Discount</span>
            <span className="pc-category-title-text justify-content-end">
              Total
            </span>
          </div>

          {orderData.type === "Subscription" &&
            planArray &&
            planArray?.map((e, index) => {
              const discountedPrice = e?.perUserDiscountPrice || 0;
              const InitialSetupFee = e?.InitialSetupFee || 0;
              const quantity = e?.total_user[0]?.baseUser || 1;
              const perUser_price =
                e?.billing_cycle === "yearly"
                  ? e?.perUser_price * 12
                  : e?.perUser_price;
              const total =
                InitialSetupFee + quantity * (perUser_price - discountedPrice);
              return (
                <div key={index} className="main-paln-product-card">
                  <div
                    style={{ marginRight: "0", paddingRight: "20px" }}
                    className="order-Product-cards"
                  >
                    <div className="pc-img-and-text-div">
                      <div className="">
                        <img style={{ width: "85%" }} src={PlanIcon} alt="" />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="product-details"
                      >
                        <span className="plan-commman-name-text">
                          {e?.plan}-{e?.billing_cycle}
                        </span>
                      </div>
                    </div>
                    <div className="pc-category-title-text">{"1"}</div>
                    <div className="pc-category-title-text">
                      {InitialSetupFee + quantity * perUser_price}
                    </div>
                    <div
                      style={{ color: "#E62525" }}
                      className="pc-category-title-text"
                    >
                      -${discountedPrice * quantity}
                    </div>
                    <div className="pc-category-title-text justify-content-end">
                      ${total || "0"}
                    </div>
                  </div>
                </div>
              );
            })}

          {orderData.type === "Subscription" &&
            planArray &&
            planArray?.map((e, index) => {
              const discountedPrice = e?.perUserDiscountPrice || 0;
              const quantity = e?.total_user[0]?.additionalUser || 0;
              const perUser_price =
                e?.billing_cycle === "yearly"
                  ? e?.perUser_price * 12
                  : e?.perUser_price;

              const total = quantity * (perUser_price - discountedPrice);
              return (
                <>
                  {quantity > 0 && (
                    <div key={index} className="main-paln-product-card">
                      <div
                        style={{ marginRight: "0", paddingRight: "20px" }}
                        className="order-Product-cards"
                      >
                        <div className="pc-img-and-text-div">
                          <div className="">
                            {/* {/ <span className='pc-img'>img</span> /}
                            {/ <img className="pc-img" src={`${url}/product/img/${e.image[0]?.url}`} alt="" /> /} */}
                            <img
                              style={{ width: "85%" }}
                              src={UserIcon}
                              alt=""
                            />
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            className="product-details"
                          >
                            <span className="plan-commman-name-text">
                              {"Users"}
                            </span>
                          </div>
                        </div>
                        <div className="pc-category-title-text">{quantity}</div>
                        <div className="pc-category-title-text">
                          {perUser_price}
                        </div>
                        <div
                          style={{ color: "#E62525" }}
                          className="pc-category-title-text"
                        >
                          -${discountedPrice * quantity}
                        </div>
                        <div className="pc-category-title-text justify-content-end">
                          ${total || 0}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          {addons &&
            addons?.map((e, index) => {
              const discountedPrice = e?.addonDiscountPrice || 0;
              const quantity = 1;
              const total =
                parseInt(quantity, 10) *
                (parseInt(e?.price, 10) - discountedPrice);
              return (
                <div key={index} className="main-paln-product-card">
                  <div
                    style={{ marginRight: "0", paddingRight: "20px" }}
                    className="order-Product-cards"
                  >
                    <div className="pc-img-and-text-div">
                      <div className="">
                        {/* <span className='pc-img'>img</span> */}
                        {/* <img className="pc-img" src={`${url}/product/img/${e.image[0]?.url}`} alt="" /> */}
                        <img style={{ width: "85%" }} src={AddonIcon} alt="" />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="product-details"
                      >
                        <span className="plan-commman-name-text">
                          {e?.addonId?.publicname}
                        </span>
                      </div>
                    </div>
                    <div className="pc-category-title-text">{"1"}</div>
                    <div className="pc-category-title-text">${e?.price}</div>
                    <div
                      style={{ color: "#E62525" }}
                      className="pc-category-title-text"
                    >
                      -${discountedPrice}
                    </div>
                    <div className="pc-category-title-text justify-content-end">
                      ${total || 0}
                    </div>
                  </div>
                </div>
              );
            })}

          {orderData.type === "AddonPurchase" &&
            addonPurchase &&
            addonPurchase?.map((e, index) => {
              const discountedPrice = e.addonDiscountPrice;
              const quantity = 1;
              const total =
                parseInt(quantity, 10) * parseInt(e?.price, 10) -
                discountedPrice;
              return (
                <div key={index} className="main-paln-product-card">
                  <div
                    style={{ marginRight: "0", paddingRight: "20px" }}
                    className="order-Product-cards"
                  >
                    <div className="pc-img-and-text-div">
                      <div className="">
                        <img style={{ width: "85%" }} src={AddonIcon} alt="" />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="product-details"
                      >
                        <span className="plan-commman-name-text">
                          {e?.addonId.publicname}
                        </span>
                      </div>
                    </div>
                    <div className="pc-category-title-text">{"1"}</div>
                    <div className="pc-category-title-text"></div>
                    <div
                      style={{ color: "#E62525" }}
                      className="pc-category-title-text"
                    >
                      ${discountedPrice || 0}
                    </div>
                    <div className="pc-category-title-text justify-content-end">
                      ${total || 0}
                    </div>
                  </div>
                </div>
              );
            })}
          {orderData.type === "UserPurchase" && userPurchase && (
            <div className="main-paln-product-card">
              <div
                style={{ marginRight: "0", paddingRight: "20px" }}
                className="order-Product-cards"
              >
                <div className="pc-img-and-text-div">
                  <div className="">
                    <img style={{ width: "85%" }} src={UserIcon} alt="" />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="product-details"
                  >
                    <span className="plan-commman-name-text">{"Users"}</span>
                  </div>
                </div>
                <div className="pc-category-title-text">
                  {userPurchase?.addusercount}
                </div>
                <div className="pc-category-title-text">
                  ${userPurchase?.price / (userPurchase?.addusercount || 1)}
                </div>
                <div
                  style={{ color: "#E62525" }}
                  className="pc-category-title-text"
                >
                  -
                </div>
                <div className="pc-category-title-text justify-content-end">
                  ${userPurchase?.price || 0}
                </div>
              </div>
            </div>
          )}
          {smartAccessories &&
            smartAccessories?.map((e, index) => {
              const discountedPrice = e?.discountAmount || 0;
              const total = e.quantity * (e.price - discountedPrice);
              return (
                <div key={index} className="main-paln-product-card">
                  <div
                    style={{ marginRight: "0", paddingRight: "20px" }}
                    className="order-Product-cards"
                  >
                    <div className="pc-img-and-text-div">
                      <div className="">
                        <img
                          style={{ width: "85%" }}
                          src={ProductIcon}
                          alt=""
                        />
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="product-details"
                      >
                        <span className="plan-commman-name-text">
                          {e?.productId?.name}
                        </span>
                      </div>
                    </div>
                    <div className="pc-category-title-text">{e?.quantity}</div>
                    <div className="pc-category-title-text">{e.price}</div>
                    <div
                      style={{ color: "#E62525" }}
                      className="pc-category-title-text"
                    >
                      -${discountedPrice}
                    </div>
                    <div className="pc-category-title-text justify-content-end">
                      ${total}
                    </div>
                  </div>
                </div>
              );
            })}
          {orderData?.type === "Subscription" &&
            planArray &&
            planArray?.map((e) => {
              const subtotal = e?.total_amount - (shippingMethod || 0);
              return (
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                  <span>Items Subtotal:</span>
                  <span>${subtotal}</span>
                </div>
              );
            })}
          {orderData?.type !== "Subscription" && (
            <div className="d-flex align-items-center justify-content-between p-2 mt-2">
              <span>Items Subtotal:</span>
              <span>
                $
                {parseFloat(orderData?.totalAmount, 10) -
                  parseFloat(shippingMethod || 0, 10)}
              </span>
            </div>
          )}
          {orderData?.type === "Subscription" &&
            planArray &&
            planArray?.map((e) => {
              const subtotal = shippingMethod || 0;
              return (
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                  <span>Fulfillment & Shipping:</span>
                  <span>${subtotal}</span>
                </div>
              );
            })}
          {orderData?.type !== "Subscription" && (
            <div className="d-flex align-items-center justify-content-between p-2 mt-2">
              <span>Fulfillment & Shipping:</span>
              <span>${shippingMethod || 0}</span>
            </div>
          )}
          {orderData.type === "Subscription" &&
            planArray?.map((e) => {
              const subtotal = e?.total_amount;
              return (
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                   <span>Tax</span>
                    <span>
                        ${e?.taxRate}
                    </span>
                </div>
              );
            })}
          {orderData.type !== "Subscription" && (
            <div className="d-flex align-items-center justify-content-between p-2 mt-2">
              <span>Tax({orderData?.tax}%):</span>
              <span>
                ${orderData?.tax?.toFixed(2)}
              </span>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between p-2 mt-2"></div>
          <div className="d-flex align-items-center justify-content-between p-2 mt-2">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <span>Discount code:</span>{" "}
              <input
                className="border-0 p-2"
                type="text"
                readOnly
                style={{ background: "#F2F2F2", borderRadius: "4px" }}
              ></input>
            </div>
            <span style={{ color: "#E65925" }}>-</span>
          </div>
          <div className="d-flex align-items-center justify-content-between p-2 mt-2"></div>
          {orderData.type === "Subscription" &&
            orderData.subscription_details &&
            orderData.subscription_details.creditBalance !== undefined &&
            orderData.subscription_details.creditBalance !== null && (
              <div
                className="d-flex align-items-center justify-content-between p-2 mt-2"
                style={{
                  borderRadius: "4px",
                  border: "1px solid #EBE551",
                  background: "#FDFCE9",
                  color: "#E65925",
                }}
              >
                <span>Prorated Amount:</span>
                <span>-</span>
              </div>
            )}
          {orderData?.type === "Subscription" &&
            planArray &&
            planArray?.map((e) => {
              let totalproductprice = 0;
              smartAccessories?.forEach((pe, index) => {
                const discountedPrice = pe?.discountAmount || 0;
                const total = pe.quantity * (pe.price - discountedPrice);
                totalproductprice += total;
              });
              const subtotal = e?.total_amount;
              const taxammount = (
                (totalproductprice * (e?.taxRate || 0)) /
                100
              ).toFixed(2);
              return (
                <div
                  className="d-flex align-items-center justify-content-between text-white bordered p-2 mt-2"
                  style={{ borderRadius: "4px", background: "#000" }}
                >
                  <span>Total:</span>
                  <span>
                    $
                    {subtotal + parseFloat(shippingMethod || 0, 10) + parseFloat(taxammount, 10)}
                  </span>
                </div>
              );
            })}
          {orderData?.type !== "Subscription" && (
            <div
              className="d-flex align-items-center justify-content-between text-white bordered p-2 mt-2"
              style={{ borderRadius: "4px", background: "#000" }}
            >
              <span>Total:</span>
              <span>
                $
                {orderData?.totalAmount +
                  parseFloat(
                    orderData?.tax?.toFixed(
                      2
                    ),
                    10
                  )}
              </span>
            </div>
          )}
          {orderData?.type === "Subscription" &&
            planArray &&
            planArray?.map((e) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between text-white bordered p-2 mt-2"
                  style={{ borderRadius: "4px", background: "#E65925" }}
                >
                  <span>Recurring total:</span>
                  <span>{e?.recurring_amount}</span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

const ClientCompanyOrderSummary = () => {
  return (
    <div>
      <Otc_Sidebar Component={ClientCompanyOrderSummaryinner} />
    </div>
  );
};

export default ClientCompanyOrderSummary;
