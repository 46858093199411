import React, { useEffect, useState } from 'react'
import "./CompanyBillingAddressTab.css"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import { State, Country } from "country-state-city";
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyProfileDetails,
  addShippingHandler,
  getAllShippingHandler,
} from "../../../actions/userAction/userAction";
import Select from "react-select";
import axios from 'axios';
import Loader from '../../Layout/Loader/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ErrorModal from '../../Layout/ErrorModal/ErrorModal';
const styleOfBilling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: 'background.paper',
  borderRadius: "10px",
  outline: "none",
  p: 4,
};
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  class="circle"
>
  <path
    d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
    fill="#E65925"
  />
  <path
    d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
    stroke="white"
    stroke-width="6.64"
    stroke-linecap="round"
    class="checkmark-path"
  />
</svg>
const CompanyBillingAddressTab = () => {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCompanyProfileDetails());
    dispatch(getAllShippingHandler())
  }, [])
  const [open, setOpen] = React.useState(false);
  const [editModal, setEditModal] = useState(false);
  const handleOpen = () => { setOpen(true); setShippingAddressData({}); setValidationErrors({}); setSelectedState(null); setSelectedCountry(null) };
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { shippingAddresses, loading, success } = useSelector((state) => state.shippingAddresses);
  const [maxAddressError, setMaxAddressError] = useState("")
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiSuccessForNewAddress, setApiSuccessForNewAddress] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleAddShippingAddressModalClose = () => {
    setOpen(false)
    setValidationErrors("")
    setShippingAddressData({
      address_name: "",
      first_name: "",
      last_name: "",
      company_name: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    })
    setSelectedCountry("")
    setSelectedState("")
    setMaxAddressError("")
  }
  const handleCountryChange = (selectedOption) => {
    setShippingAddressData((prevData) => ({
      ...prevData,
      postal_code: '',
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      postal_code: "",
    }));
    setSelectedState('')
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        country: "", // Remove validation error for the country input
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);
      setShippingAddressData((prevData) => ({
        ...prevData,
        country: selectedOption.value,
      }));
      if (selectedState) {
        setShippingAddressData((prevData) => ({
          ...prevData,
          state: selectedState.value,
        }));
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          state: "", // Remove validation error for the state input
        }));
      }
    }
  };
  const handleStateChange = (selectedOption) => {
    setShippingAddressData((prevData) => ({
      ...prevData,
      postal_code: '',
    }));
    setSelectedState(selectedOption);
    if (selectedOption) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        state: "", // Remove validation error for the state input
      }));
      setShippingAddressData((prevData) => ({
        ...prevData,
        state: selectedOption.value,
      }));
    }
  };
  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryCode = selectedCountry?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountry]);
  
  const [shippingAddressData, setShippingAddressData] = useState({
    address_name: "",
    first_name: "",
    last_name: "",
    company_name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "postal_code") {
      if (!selectedCountry) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a country.",
        }));
        return;
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
    if (
      name === "address_name" ||
      name === "line1" ||
      name === "state" ||
      name === "city" ||
      name === "country"
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Remove validation error for this input
      }));
    }
    if (
      name === "company_name" &&
      !value.trim() &&
      (!shippingAddressData?.first_name?.trim() ||
        !shippingAddressData?.last_name?.trim())
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First name is required.",
        last_name: "Last name is required.",
      }));
    } else if (name === "company_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
        last_name: "",
      }));
    } else if (name === "first_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    } else if (name === "last_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!shippingAddressData?.address_name?.trim()) {
      errors.address_name = "Address name is required.";
    }
    if (
      !shippingAddressData?.company_name?.trim() &&
      !shippingAddressData?.first_name?.trim() &&
      !shippingAddressData?.last_name?.trim()
    ) {
      errors.first_name = "First name is required.";
      errors.last_name = "Last name is required.";
    }
    if (!shippingAddressData?.line1?.trim()) {
      errors.line1 = "Address is required.";
    }
    if (!shippingAddressData?.city?.trim()) {
      errors.city = "City is required.";
    }
    if (!selectedCountry) {
      errors.country = "Country is required.";
    }
    if (selectedCountry && stateOptions.length > 0) {
      if (!selectedState) {
        errors.state = "State is required.";
      }
    }
    if (!shippingAddressData?.postal_code?.trim()) {
      errors.postal_code = "Postal code is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleAddShippingAddress = async () => {
    setIsButtonClicked(true);
    if (shippingAddresses) {
      if (shippingAddresses[0]?.shipping_address?.length >= 6) {
        setIsButtonClicked(false);
        setMaxAddressError("You can create only 6 addresses.")
      } else if (!validateForm()) {
        setIsButtonClicked(false);
        return; // If validation fails, don't proceed
      }
    }
    const state = State?.getStateByCodeAndCountry(shippingAddressData.state, shippingAddressData.country)?.name
    const apiUrl = `https://api.zippopotam.us/${shippingAddressData.country}/${shippingAddressData.postal_code.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    if (response.ok !== true) {
      setIsButtonClicked(false);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
    } else if (data.places[0].state !== state) {
      setIsButtonClicked(false);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${state}`
      }));
    }
    else {
      try {
        await dispatch(addShippingHandler(shippingAddressData))
        // dispatch(loadUser());
        await dispatch(getAllShippingHandler())
        handleAddShippingAddressModalClose()
        setApiSuccessForNewAddress(true)
        setTimeout(() => {
          setApiSuccessForNewAddress(false)
        }, 5000);
        setIsButtonClicked(false);
      } catch (error) {
        await dispatch(getAllShippingHandler())
        handleAddShippingAddressModalClose()
        setIsButtonClicked(false);
        setApiError(true);
        setErrorMessage('Error adding address. Please try again.');
      }
    }
  }
  const [selectedAddress, setSelectedAddress] = useState(null);
  const handleEditModalOpen = (address) => {
    
    const selectedCountry = {
      value: address.country, // Use the country code
      label: Country?.getCountryByCode(address.country)?.name,
    };
    
    const selectedState = {
      value: address.state, // Use the state code
      label: State?.getStateByCodeAndCountry(address.state, address.country)?.name,
    };
    
    setEditModal(true);
    setSelectedAddress(address);
    setSelectedCountry(selectedCountry);
    setSelectedState(selectedState);
    setShippingAddressData({
      address_name: address.address_name || "",
      first_name: address.first_name || "",
      last_name: address.last_name || "",
      company_name: address.company_name || "",
      line1: address.line1 || "",
      line2: address.line2 || "",
      city: address.city || "",
      country: address.country || "", // Use the decoded country name
      state: address.state || "", // Use the decoded state name
      postal_code: address.postal_code || "",
    });
    setId("");
    setValidationErrors("");
  };
  // dispatch(getAllShippingHandler())
  const handleEditModalClose = () => {
    setEditModal(false)
    setId("")
    setValidationErrors("")
    setShippingAddressData({
      address_name: "",
      first_name: "",
      last_name: "",
      company_name: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    })
  }
  const handleRemoveShippingAddress = async (addressId) => {
    setIsButtonClicked(true);
    try {
      const response = await axios.delete(`${url}/user/shippingAddress/remove/${addressId}`, { withCredentials: true });
      if (response.data.success) {
        dispatch(getAllShippingHandler());
        setIsButtonClicked(false);
      } else {
        setApiError(true);
        setErrorMessage('Error removing address. Please try again.');
        dispatch(getAllShippingHandler());
        setIsButtonClicked(false);
      }
    } catch (error) {
      setApiError(true);
      setErrorMessage('Error removing address. Please try again.');
      dispatch(getAllShippingHandler());
      setIsButtonClicked(false);
    }
  };
  const handleEditShippingAddress = async (editAddressId) => {
    setIsButtonClicked(true)
    if (!validateForm()) {
      setIsButtonClicked(false)
      return; // If validation fails, don't proceed
    }
    const state = State?.getStateByCodeAndCountry(shippingAddressData.state, shippingAddressData.country)?.name
    const apiUrl = `https://api.zippopotam.us/${shippingAddressData.country}/${shippingAddressData.postal_code.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    if (response.ok !== true) {
      setIsButtonClicked(false)
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
    } else if (data.places[0].state !== state) {
      setIsButtonClicked(false)
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${state}`
      }));
    } else {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const response = await axios.post(`${url}/shippingAddress/edit/${editAddressId}`, { shippingAddressData }, config);
        if (response.data.success) {
          setIsButtonClicked(false)
          handleEditModalClose()
          dispatch(getAllShippingHandler());
          setApiSuccess(true)
          setTimeout(() => {
            setApiSuccess(false)
          }, 5000);
        } else {
          setIsButtonClicked(false)
          handleEditModalClose()
          setApiError(true);
          setErrorMessage('Error updating address. Please try again.');
          dispatch(getAllShippingHandler());
        }
      } catch (error) {
        setIsButtonClicked(false)
        handleEditModalClose()
        setApiError(true);
        setErrorMessage('Error updating address. Please try again.');
        dispatch(getAllShippingHandler());
      }
    }
  };
  const [id, setId] = useState("")
  return (
    <>
      <>
        <div className="main-container-billing">
          <div className="upper-main-section">
            <div className="upper-info-section">
              <h2 className='upper-info-h2'>Shipping Addresses</h2>
              <span className='upper-info-span'>The below information are synchronized and displayed on the user OneTapConnect card. Any update will immediately be reflected on the user card.</span>
            </div>
            <div className="upper-btn-section">
              <button className='upper-btn' onClick={handleOpen}>Add new</button>
            </div>
          </div>
          {loading ? (<Loader />) : (
            <div className="down-main-section">
              {shippingAddresses && shippingAddresses[0]?.shipping_address?.map((e, index) =>
                <div className="left-side-section">
                  <div className="info-and-btn-section">
                    <h4 className='left-info-h4'>{e.address_name.charAt(0).toUpperCase() + e.address_name.slice(1)}</h4>
                    <div className="left-btn-group">
                      {/* <button className='left-edit-btn' >Edit</button> */}
                      <button className='left-edit-btn' onClick={() => { handleEditModalOpen(e); setId(e._id) }}>Edit</button>
                      {index !== 0 && (
                        <button className='left-remove-btn' onClick={() => handleRemoveShippingAddress(e._id)} disabled={isButtonClicked}>Remove</button>
                      )}
                    </div>
                  </div>
                  <div className="left-textArea">
                    <span className='left-span'>
                      <p style={{ marginBottom: "0" }}>{e.first_name} {e.last_name}</p>
                      <p style={{ marginBottom: "0" }}>{e.company_name} </p>
                      <p style={{ marginBottom: "0" }}>{e.line1} {e.line2}</p>
                      <p style={{ marginBottom: "0" }}>{e.city},&nbsp;
                        {State?.getStateByCodeAndCountry(e.state, e.country)?.name},&nbsp;
                        {e.postal_code} </p>
                      <p style={{ marginBottom: "0" }}>  {Country?.getCountryByCode(
                        e.country
                      )?.name}</p>
                    </span>
                  </div>
                </div>)}
            </div>
          )}
        </div>
        <Modal
          open={open}
          onClose={() => { handleAddShippingAddressModalClose() }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleOfBilling}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "0px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Add Address
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <form style={{ paddingTop: "10px" }}>
                <div className="shipping-main-section">
                  <div className="shipping-Company  shipping-all-section">
                    <label className='all-lable-shipping'>Save address as*</label>
                    <input type="text" name="address_name" placeholder='e.g. California Office, Primary Address' value={shippingAddressData.address_name} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.address_name && (
                      <div className="error-message">{validationErrors.address_name}</div>
                    )}
                  </div>
                  <div className="shipping-name-section shipping-all-section">
                    <div className="shipping-fname">
                      <label className='first-last-name'>First name*</label>
                      <input type="text" name="first_name" value={shippingAddressData.first_name} onChange={handleInputChange} className='shipping-all-input' />
                      {validationErrors.first_name && (
                        <div className="error-message">{validationErrors.first_name}</div>
                      )}
                    </div>
                    <div className="shipping-lname">
                      <label className='first-last-name'>Last name*</label>
                      <input type="text" name="last_name" value={shippingAddressData.last_name} onChange={handleInputChange} className='shipping-all-input' />
                      {validationErrors.last_name && (
                        <div className="error-message">{validationErrors.last_name}</div>
                      )}
                    </div>
                  </div>
                  <div className="shipping-Company  shipping-all-section">
                    <label className='all-lable-shipping'>Company name</label>
                    <input type="text" name="company_name" value={shippingAddressData.company_name} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.company_name && (
                      <div className="error-message">{validationErrors.company_name}</div>
                    )}
                  </div>
                  <div className="shipping-Country shipping-all-section">
                    <label className='all-lable-shipping'>Country*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.country"
                      options={Country.getAllCountries().map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    />
                    {validationErrors.country && (
                      <div className="error-message">{validationErrors.country}</div>
                    )}
                  </div>
                  <div className="shipping-address shipping-all-section">
                    <label className='all-lable-shipping'>Address*</label>
                    {/* <input type="text" name="email" value={memberData.email} onChange={handleInviteInputChange} /> */}
                    <input type="text" name="line1" value={shippingAddressData.line1} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.line1 && (
                      <div className="error-message">{validationErrors.line1}</div>
                    )}
                  </div>
                  <div className="shipping-apt shipping-all-section">
                    <label className='all-lable-shipping'>Apt, suite, etc</label>
                    <input type="text" name="line2" value={shippingAddressData.line2} onChange={handleInputChange} className='shipping-all-input' />
                  </div>
                  <div className="shipping-city shipping-all-section">
                    <label className='all-lable-shipping'>City*</label>
                    <input type="text" name="city" value={shippingAddressData.city} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.city && (
                      <div className="error-message">{validationErrors.city}</div>
                    )}
                  </div>
                  <div className="shipping-state shipping-all-section">
                    <label className='all-lable-shipping'>State*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.state"
                      options={stateOptions}
                      value={selectedState}
                      menuPlacement="top"
                      onChange={handleStateChange}
                    />
                    {validationErrors.state && (
                      <div className="error-message">{validationErrors.state}</div>
                    )}
                  </div>
                  <div className="shipping-postal shipping-all-section">
                    <label className='all-lable-shipping'>Postal code*</label>
                    <input type="text" name="postal_code" value={shippingAddressData.postal_code} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.postal_code && (
                      <div className="error-message">{validationErrors.postal_code}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {maxAddressError && (<div className="error-message" > {maxAddressError} </div>)}
            <div className="onetap_conn_send_email_medal_button">
              <button className="onetap_conn_send_email_medal_invite_button" onClick={() => { handleAddShippingAddress() }} disabled={isButtonClicked} >
                {isButtonClicked ? "Loading..." : "Add Address"}
              </button>
              <button
                className="onetap_conn_send_email_medal_cancel_button"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={editModal}
          onClose={() => setEditModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleOfBilling}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "0px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Edit address
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <form style={{ paddingTop: "10px" }}>
                <div className="shipping-main-section">
                  <div className="shipping-Company  shipping-all-section">
                    <label className='all-lable-shipping'>Save address as*</label>
                    <input type="text" name="address_name" placeholder='e.g. California Office, Primary Address' value={shippingAddressData.address_name} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.address_name && (
                      <div className="error-message">{validationErrors.address_name}</div>
                    )}
                  </div>
                  <div className="shipping-name-section shipping-all-section">
                    <div className="shipping-fname">
                      <label className='first-last-name'>First name*</label>
                      <input type="text" name="first_name" value={shippingAddressData.first_name} onChange={handleInputChange} className='shipping-all-input' />
                      {validationErrors.first_name && (
                        <div className="error-message">{validationErrors.first_name}</div>
                      )}
                    </div>
                    <div className="shipping-lname">
                      <label className='first-last-name'>Last name*</label>
                      <input type="text" name="last_name" value={shippingAddressData.last_name} onChange={handleInputChange} className='shipping-all-input' />
                      {validationErrors.last_name && (
                        <div className="error-message">{validationErrors.last_name}</div>
                      )}
                    </div>
                  </div>
                  <div className="shipping-Company  shipping-all-section">
                    <label className='all-lable-shipping'>Company name</label>
                    <input type="text" name="company_name" value={shippingAddressData.company_name} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.company_name && (
                      <div className="error-message">{validationErrors.company_name}</div>
                    )}
                  </div>
                  <div className="shipping-Country shipping-all-section">
                    <label className='all-lable-shipping'>Country*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select"
                      placeholder="Select"
                      name="address.country"
                      options={Country.getAllCountries().map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    />
                    {validationErrors.country && (
                      <div className="error-message">{validationErrors.country}</div>
                    )}
                  </div>
                  <div className="shipping-address shipping-all-section">
                    <label className='all-lable-shipping'>Address*</label>
                    <input type="text" name="line1" value={shippingAddressData.line1} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.line1 && (
                      <div className="error-message">{validationErrors.line1}</div>
                    )}
                  </div>
                  <div className="shipping-apt shipping-all-section">
                    <label className='all-lable-shipping'>Apt, suite, etc</label>
                    <input type="text" name="line2" value={shippingAddressData.line2} onChange={handleInputChange} className='shipping-all-input' />
                  </div>
                  <div className="shipping-city shipping-all-section">
                    <label className='all-lable-shipping'>City*</label>
                    <input type="text" name="city" value={shippingAddressData.city} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.city && (
                      <div className="error-message">{validationErrors.city}</div>
                    )}
                  </div>
                  <div className="shipping-state shipping-all-section">
                    <label className='all-lable-shipping'>State*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select"
                      placeholder="Select"
                      name="address.state"
                      options={stateOptions}
                      value={selectedState}
                      menuPlacement="top"
                      onChange={handleStateChange}
                    />
                    {validationErrors.state && (
                      <div className="error-message">{validationErrors.state}</div>
                    )}
                  </div>
                  <div className="shipping-postal shipping-all-section">
                    <label className='all-lable-shipping'>Postal code*</label>
                    <input type="text" name="postal_code" value={shippingAddressData.postal_code} onChange={handleInputChange} className='shipping-all-input' />
                    {validationErrors.postal_code && (
                      <div className="error-message">{validationErrors.postal_code}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="onetap_conn_send_email_medal_button">
              <button className="onetap_conn_send_email_medal_invite_button" onClick={() => { handleEditShippingAddress(id) }} disabled={isButtonClicked}>
                {isButtonClicked ? "Loading..." : "Save address"}
              </button>
              <button
                className="onetap_conn_send_email_medal_cancel_button"
                onClick={() => { handleEditModalClose(); setEditModal(false) }}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={apiSuccess}
          onClose={() => setApiSuccess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={successModalStyle} style={{ textAlign: "center" }}>
            <div className="Modal-section">
              <div className="checkmark-container">
                <div class="checkmark-container">
                  {messageIcon}
                </div>
              </div>
              <span className="team_created_success">
                Data updated successfully!
              </span>
            </div>
            <button
              className="Modal-btn sec-modal-btn"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "13%",
              }}
              onClick={() => setApiSuccess(false)}
            >
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
              </span>
            </button>
          </Box>
        </Modal>
        <Modal
          open={apiSuccessForNewAddress}
          onClose={() => setApiSuccessForNewAddress(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={successModalStyle} style={{ textAlign: "center" }}>
            <div className="Modal-section">
              <div className="checkmark-container">
                <div class="checkmark-container">
                  {messageIcon}
                </div>
              </div>
              <span className="team_created_success">
                New shipping address added successfully.
              </span>
            </div>
            <button
              className="Modal-btn sec-modal-btn"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "13%",
              }}
              onClick={() => setApiSuccessForNewAddress(false)}
            >
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
              </span>
            </button>
          </Box>
        </Modal>
        {apiError && (
          <ErrorModal
            errorMessage={errorMessage}
            onClose={() => setApiError(false)}
          />
        )}
      </>
    </>
  )
}
export default CompanyBillingAddressTab