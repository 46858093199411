import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Country, State } from "country-state-city";
import {
  fetchBillingAddressDetailsofcompany,
  getAllUsersOfCompany,
} from "../../Redux/Otc_UserActions";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Layout/Loader/Loader";
import { Modal, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
  >
    <path
      d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
      fill="#E65925"
    />
    <path
      d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
      stroke="white"
      stroke-width="6.64"
      stroke-linecap="round"
      class="checkmark-path"
    />
  </svg>
);

const ClientCompanyBillingandContactinformation = () => {
  const dispatch = useDispatch();
  const { companydata, userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  const { otcbillingAddresses, loading: billingLoading } = useSelector(
    (state) => state.otcbillingAddresses
  );

  const notteamplan =
    (userInformationTeamData &&
      userInformationTeamData.some(
        (user) => user.subscription_details.plan === "Professional"
      )) ||
    (userInformationTeamData &&
      userInformationTeamData.some(
        (user) => user.subscription_details.plan === "Free"
      ));

  const teamplan =
    userInformationTeamData &&
    userInformationTeamData.some(
      (user) => user.subscription_details.plan === "Team"
    );
  const url = process.env.REACT_APP_URL;
  const superAdminUser =
    userInformationTeamData &&
    userInformationTeamData.find((user) => user.user_id?.role === "superadmin");
  const superAdminUserid = superAdminUser?.user_id?._id;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [companyNameError, setCompanyNameError] = useState("");
  const [apt, setApt] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [error, setError] = useState({});
  const [errorSelect, setErrorSelect] = useState({});
  const [primaryAccount, setPrimaryAccount] = useState("");
  const [primaryBilling, setPrimaryBilling] = useState([]);
  const [primaryManager, setPrimaryManager] = useState("");
  const [isBillingEdited, setIsBillingEdited] = useState(false);
  const [isContactEdited, setIsContactEdited] = useState(false);
  const [postalCodeValid, setPostalCodeValid] = useState(true); // Initially assume valid
  const [selectedValues, setSelectedValues] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [billingload, setBillingloading] = useState(false);
  const [userRoalload, setUserroalloading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { id } = useParams();
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const response = await axios.post(`${url}/admin/getallcompanynames`, {id} , {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }); 
        setCompanyNames(
          response.data.companies.map((company) => company.company_name)
        );
      } catch (error) {}
    };

    fetchCompanyNames();
  }, []);

  const handleAddBillingPerson = () => {
    setPrimaryBilling((prevPerson) => [
      ...prevPerson,
      {
        id: generateUniqueId(),
        selectedId: "",
        selectedEmail: "",
        selectedFirstName: "",
        selectedLastName: "",
      },
    ]);
  };

  const generateUniqueId = () => {
    return `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleDeleteBillingPerson = (id) => {
    if (primaryBilling.length > 1) {
      const updatedPersons = primaryBilling?.filter(
        (billing) => billing.id !== id
      );

      setPrimaryBilling(updatedPersons);
    }
  };

  const handleBillingPersonSelectChange = (id, selectedOption) => {
    const updatedPersons = primaryBilling.map((billing) =>
      billing.id === id
        ? {
            ...billing,
            selectedId: selectedOption?.value, // Update the selectedId
            selectedFirstName: selectedOption?.label.split(" ")[0], // Update the selectedFirstName
            selectedLastName: selectedOption?.label.split(" ")[1], // Update the selectedLastName
            selectedEmail:
              selectedOption?.label
                .split(" ")[2]
                ?.replace("(", "")
                .replace(")", "") || "", // Update the selectedEmail
          }
        : billing
    );

    setPrimaryBilling(updatedPersons);
    handleContactInputChange(); // Call this to indicate that the form has changed

    // Update selected values
    const newSelectedValues = [...selectedValues, selectedOption?.value];
    setSelectedValues(newSelectedValues);
  };

  const filteredPrimaryBillingOptions = primaryBilling.map(
    (person) => person.selectedId
  );
  const remainingOptions = (userInformationTeamData || []).filter((item) => {
    const isSelected = selectedValues.includes(item?.user_id?._id);
    const isInPrimaryBilling = primaryBilling.some(
      (billing) => billing.selectedId === item?.user_id?._id
    );
    const wasInitiallySelected = filteredPrimaryBillingOptions.includes(
      item?.user_id?._id
    );

    return (
      (!wasInitiallySelected && !isSelected) ||
      (!isInPrimaryBilling && (!wasInitiallySelected || isSelected))
    );
  });

  // useEffect for fetching billing address details
  useEffect(() => {
    dispatch(fetchBillingAddressDetailsofcompany(superAdminUserid, id));
  }, []);

  // useEffect for setting state once all data is available
  useEffect(() => {
    if (companydata || userInformationTeamData || otcbillingAddresses) {
      setFirstName(superAdminUser?.user_id?.first_name);
      setLastName(superAdminUser?.user_id?.last_name);
      setCompanyName(companydata?.company_name);
      setCountry(otcbillingAddresses?.country);
      setAddress(otcbillingAddresses?.line1);
      setApt(otcbillingAddresses?.line2);
      setCity(otcbillingAddresses?.city);
      setState(otcbillingAddresses?.state);
      setPostalCode(otcbillingAddresses?.postal_code);
      setPrimaryAccount(companydata?.primary_account?._id);
      setPrimaryBilling([
        ...companydata?.primary_billing?.map((billing, index) => ({
          id: index,
          selectedId: billing._id,
          selectedEmail: billing.email,
          selectedFirstName: billing.first_name,
          selectedLastName: billing.last_name,
        })),
      ]);
      setPrimaryManager(companydata?.primary_manager?._id);
    }
  }, [companydata, userInformationTeamData, otcbillingAddresses]);
  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }
  const options = [
    {
      value: companydata?.primary_account?._id,
      label: `${capitalizeFirstLetter(
        companydata?.primary_account?.first_name
      )} ${capitalizeFirstLetter(companydata?.primary_account?.last_name)} (${
        companydata?.primary_account?.email
      })`,
    },
    ...(userInformationTeamData
      ? userInformationTeamData
          .filter(
            (item) =>
              item?.user_id?.email !== companydata?.primary_account?.email &&
              item?.user_id?.status === "active"
          )
          .map((item) => ({
            value: item?.user_id?._id,
            label: `${capitalizeFirstLetter(
              item?.user_id?.first_name
            )} ${capitalizeFirstLetter(item?.user_id?.last_name)} (${
              item?.user_id?.email
            })`,
          }))
      : []),
  ];

  const managerOptions = [
    {
      value: companydata?.primary_manager?._id,
      label: `${capitalizeFirstLetter(
        companydata?.primary_manager?.first_name
      )} ${capitalizeFirstLetter(companydata?.primary_manager?.last_name)} (${
        companydata?.primary_manager?.email
      })`,
    },
    ...(userInformationTeamData
      ? userInformationTeamData
          .filter(
            (item) =>
              item?.user_id?.email !== companydata?.primary_manager?.email &&
              item?.user_id?.status === "active"
          )
          .map((item) => ({
            value: item?.user_id?._id,
            label: `${capitalizeFirstLetter(
              item?.user_id?.first_name
            )} ${capitalizeFirstLetter(item?.user_id?.last_name)} (${
              item?.user_id?.email
            })`,
          }))
      : []),
  ];
  const handleChange = (selectedOption) => {
    setPrimaryAccount(selectedOption?.value);
    handleContactInputChange();
  };

  // Create an array of default values for each Select component
  const defaultValues = primaryBilling.map((billing) => ({
    value: billing.selectedId,
    label: `${capitalizeFirstLetter(
      billing.selectedFirstName
    )} ${capitalizeFirstLetter(billing.selectedLastName)} ${
      billing.selectedEmail === ""
        ? "Please select member"
        : "(" + billing.selectedEmail + ")"
    }`,
  }));
  const validateForm = () => {
    const newErrors = {};
    if (!country?.trim()) {
      newErrors.country = "Country is required";
    }

    if (!address?.trim()) {
      newErrors.address = "Address is required";
    }

    if (!apt?.trim()) {
      newErrors.apt = "Apt,suite,etc is required";
    }

    if (!city?.trim()) {
      newErrors.city = "City is required";
    }

    if (!state?.trim()) {
      newErrors.state = "State is required";
    }

    if (!postalCode) {
      newErrors.postalCode = "Postal Code is required";
    }

    setError(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const billing_address = {
    line1: address,
    line2: apt,
    city: city,
    country: country,
    state: state,
    postal_code: postalCode,
  };

  const handleEditbuttonbilling = async () => {
    const isValidForm = validateForm();
    const trimmedCompanyName = companyName?.replace(/\s/g, "").toLowerCase();
    const matchingCompany = companyNames.find(
      (name) => name.replace(/\s/g, "").toLowerCase() === trimmedCompanyName
    );

    if (!trimmedCompanyName) {
      setCompanyNameError("Company name is required.");
      return;
    } else if (matchingCompany) {
      setCompanyNameError("Company name already exists.");
      return;
    } else {
      setCompanyNameError(null);
    }
    const state = State?.getStateByCodeAndCountry(
      billing_address.state,
      billing_address.country
    )?.name;
    const apiUrl = `https://api.zippopotam.us/${
      billing_address.country
    }/${billing_address.postal_code.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (response.ok !== true) {
      setError((prevErrors) => ({
        ...prevErrors,
        postalCode: "Please enter a valid postal code.",
      }));
    } else if (data.places[0].state !== state) {
      setError((prevErrors) => ({
        ...prevErrors,
        postalCode: `Please enter a valid postal code for ${state}`,
      }));
    } else if (isValidForm) {
      try {
        setBillingloading(true)
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const response = await axios.post(
          `${url}/admin/updateBillingAddressofcompany`,
          {
            firstName,
            lastName,
            billing_address,
            companyName,
            companyID: id,
            superAdminUserid,
          },
          config
        );
        // Assuming the server sends a success message
        if (response.data.message === "Data Updated Successfully") {
          setApiSuccess(true);
          setSuccessMessage("Data updated successfully.");
          setTimeout(() => {
            dispatch(fetchBillingAddressDetailsofcompany(superAdminUserid, id));
            dispatch(getAllUsersOfCompany(id));
          }, 2000);
          setTimeout(() => {
            setApiSuccess(false);
          }, 5000);
        }
      } catch (err) {
        setError("Error updating billing address. Please try again.");
      }finally{
        setBillingloading(false)
      }
    }
  };

  const validateSelect = () => {
    const newSelectErrors = {};

    const hasEmptyPerson = primaryBilling.some((person) => !person.selectedId);

    if (hasEmptyPerson) {
      newSelectErrors.billingSelect = "Please select a billing person";
    }

    setErrorSelect(newSelectErrors);

    return Object.keys(newSelectErrors).length === 0;
  };

  const handleEditbuttoncontact = async () => {
    const isValidSelect = validateSelect();

    const selectedPrimaryBilling = primaryBilling.map(
      (person) => person.selectedId
    );

    const companyDetails = {
      primaryAccount,
      selectedPrimaryBilling,
      primaryManager,
    };

    if (isValidSelect) {
      try {
        // setDataloading(true);
        setUserroalloading(true)
        const response = await axios.post(
          `${url}/admin/updatePointOfContact`,
          {
            id,
            companyDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          setApiSuccess(true);
          setSuccessMessage("Data updated successfully.");
          setTimeout(() => {
          dispatch(getAllUsersOfCompany(id));
          }, 2000);
          setTimeout(() => {
            setApiSuccess(false);
          }, 5000);
        }
      } catch (error) {}
      finally{
        setUserroalloading(false)
      }
    }
  };
  const customStyless = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
  };

  const handleBillingInputChange = () => {
    setIsBillingEdited(true);
  };
  const handleContactInputChange = () => {
    setIsContactEdited(true);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      padding: "10px",
      alignItems: "left",
      alignSelf: "stretch",
      borderRadius: "4px",
      background: "#f2f2f2",
      border: "none",
      cursor: "pointer",
    }),
  };

  return (
    <>
      {billingLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className="onetap_conn_billing_contact_form">
            <div className="onetap_conn_billing_form">
              <div className="onetap_conn_billing_header_syn">
                <p>Billing Information</p>
              </div>
              <form>
                <div className="onetap_conn_billing_form_input_syn_section1">
                  {notteamplan && (
                    <>
                      <div className="onetap_conn_billing_form_input_firstlastname">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            handleBillingInputChange();
                          }}
                        />
                        {error.firstName && (
                          <p className="onetap_conn_billing_form_error_message">
                            {error.firstName}
                          </p>
                        )}
                      </div>
                      <div className="onetap_conn_billing_form_input_firstlastname">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            handleBillingInputChange();
                          }}
                        />
                        {error.lastName && (
                          <p className="onetap_conn_billing_form_error_message">
                            {error.lastName}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  <label htmlFor="company_name">Company name</label>
                  <input
                    type="text"
                    name="company_name"
                    id="company_name"
                    value={companyName}
                    onChange={(e) => {
                      setError((prevErrors) => ({
                        ...prevErrors,
                        companyName: "",
                      }));
                      setCompanyName(e.target.value);
                      handleBillingInputChange();
                    }}
                  />
                  {companyNameError && (
                    <p className="onetap_conn_billing_form_error_message">
                      {companyNameError}
                    </p>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  <label htmlFor="country">Country</label>
                  <div className="select-container" style={{ width: "100%" }}>
                    <Select
                      name="country"
                      id="country"
                      styles={{
                        container: (provided) => ({
                          // ...provided,
                          width: "100%", // Set the width of the container as needed
                        }),
                        control: () => ({
                          // No border or box-shadow
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0, // Remove margin
                          padding: 0, // Remove padding
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: 0, // Remove padding from ValueContainer
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn form-select"
                      value={{
                        value: country,
                        label: Country.getCountryByCode(country)?.name,
                      }}
                      onChange={(selectedOption) => {
                        setPostalCode("");
                        setError((prevErrors) => ({
                          ...prevErrors,
                          country: "",
                        }));
                        setCountry(selectedOption.value);
                        setState("");
                        handleBillingInputChange();
                      }}
                      options={Country.getAllCountries().map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                    />
                  </div>
                  {error.country && (
                    <p className="onetap_conn_billing_form_error_message">
                      {error.country}
                    </p>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  {" "}
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={address}
                    onChange={(e) => {
                      setError((prevErrors) => ({
                        ...prevErrors,
                        address: "",
                      }));
                      setAddress(e.target.value);
                      handleBillingInputChange();
                    }}
                  />
                  {error.address && (
                    <p className="onetap_conn_billing_form_error_message">
                      {error.address}
                    </p>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  {" "}
                  <label htmlFor="apt">Apt,suite,etc</label>
                  <input
                    type="text"
                    name="apt"
                    id="apt"
                    value={apt}
                    onChange={(e) => {
                      setApt(e.target.value);
                      handleBillingInputChange();
                    }}
                  />
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  {" "}
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={city}
                    onChange={(e) => {
                      setError((prevErrors) => ({
                        ...prevErrors,
                        city: "",
                      }));
                      setCity(e.target.value);
                      handleBillingInputChange();
                    }}
                  />
                  {error.city && (
                    <p className="onetap_conn_billing_form_error_message">
                      {error.city}
                    </p>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  {" "}
                  <label htmlFor="state">State</label>
                  <div className="select-container" style={{ width: "100%" }}>
                    <Select
                      name="state"
                      id="state"
                      styles={{
                        container: (provided) => ({
                          width: "100%",
                        }),
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                          width: "100%",
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: 0,
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn form-select"
                      value={{
                        value: state,
                        label: State.getStateByCodeAndCountry(state, country)
                          ?.name,
                      }}
                      onChange={(selectedOption) => {
                        setState(selectedOption.value);
                        setPostalCode("");
                        handleBillingInputChange();
                        setError((prevErrors) => ({
                          ...prevErrors,
                          state: "",
                        }));
                      }}
                      options={State.getStatesOfCountry(country).map(
                        (item) => ({
                          value: item.isoCode,
                          label: item.name,
                        })
                      )}
                    />
                  </div>
                  {error.state && (
                    <p className="onetap_conn_billing_form_error_message">
                      {error.state}
                    </p>
                  )}
                </div>
                <div className="onetap_conn_billing_form_input_syn">
                  {" "}
                  <label htmlFor="postal">Postal code</label>
                  <input
                    type="text"
                    name="postal"
                    id="postal"
                    value={postalCode}
                    onChange={(e) => {
                      setError((prevErrors) => ({
                        ...prevErrors,
                        postalCode: "",
                      }));
                      setPostalCode(e.target.value);
                      handleBillingInputChange();
                    }}
                  />
                  {error.postalCode && (
                    <p className="onetap_conn_billing_form_error_message">
                      {error.postalCode}
                    </p>
                  )}
                </div>
              </form>
              <div className="onetap_conn_billing_footer_button">
                <button
                  onClick={handleEditbuttonbilling}
                  disabled={!isBillingEdited}
                  style={{
                    background: (isBillingEdited) ? "#e65925" : "#ccc",
                  }}
                >
                 {billingload ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
            {teamplan && (
              <div className="onetap_conn_billing_form">
                <div className="onetap_conn_billing_header_syn">
                  <p>Point of contact</p>
                </div>
                <div className="onetap_conn_contact_primary_account">
                  <div className="onetap_conn_contact_primary_account_header">
                    Primary account contact
                  </div>
                  <div className="onetap_conn_contact_primary_account_content">
                    The main person managing the account. This user will receive
                    all important account notifications (including invoices,
                    upgrades, renewals etc.)
                  </div>
                  <div
                    className="onetap_conn_billing_form_input_syn"
                    style={{ display: "grid" }}
                  >
                    <Select
                      options={options}
                      value={options.find(
                        (option) => option?.value === primaryAccount
                      )}
                      onChange={handleChange}
                      styles={customStyles}
                    />
                  </div>
                </div>

                <div className="onetap_conn_contact_primary_account">
                  <div className="onetap_conn_contact_primary_account_header">
                    Primary Billing contact
                  </div>
                  <div className="onetap_conn_contact_primary_account_content">
                    Send copies of invoices, receipts, orders and other renewal
                    notifications to these users.
                  </div>
                  {primaryBilling.map((person) => (
                    <div
                      key={person?.id}
                      className="onetap_conn_billing_form_input_primary_billing_syn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className=""
                        style={{ display: "grid", width: "90%" }}
                      >
                        <Select
                          name={`billing_${person?.id}`}
                          className="onetap_conn_billing_form_input_primary_billing_syn"
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            handleBillingPersonSelectChange(
                              person?.id,
                              selectedOption
                            );
                            handleContactInputChange();
                          }}
                          value={defaultValues.find(
                            (value) => value.value === person.selectedId
                          )}
                          options={[
                            {
                              value: person?.selectedId,
                              label: `${capitalizeFirstLetter(
                                person?.selectedFirstName
                              )} ${capitalizeFirstLetter(
                                person?.selectedLastName
                              )}
                                ${
                                  person?.selectedEmail === ""
                                    ? "Please select member"
                                    : "(" + person?.selectedEmail + ")"
                                }`,
                            },
                            ...remainingOptions
                              .filter(
                                (item) => item?.user_id?.status === "active"
                              )
                              .map((item) => ({
                                value: item?.user_id?._id,
                                label: `${capitalizeFirstLetter(
                                  item?.user_id?.first_name
                                )} ${capitalizeFirstLetter(
                                  item?.user_id?.last_name
                                )} (${item?.user_id?.email})`,
                              })),
                          ]}
                        />
                      </div>

                      <div className="onetap_conn_billing_form_input_remove_icon">
                        {primaryBilling.length > 1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            onClick={() => {
                              handleDeleteBillingPerson(person?.id);
                              handleContactInputChange();
                            }}
                            role="button"
                          >
                            <path
                              d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999333 16.5493 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                              fill="#8A8A8A"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}

                  {errorSelect.billingSelect && (
                    <p className="onetap_conn_billing_form_error_message">
                      {errorSelect.billingSelect}
                    </p>
                  )}
                  <a
                    onClick={
                      primaryBilling.length < 3
                        ? handleAddBillingPerson
                        : undefined
                    }
                    className={`onetap_conn_contact_primary_account_add_person ${
                      primaryBilling.length >= 3 ? "inactive" : "active"
                    }`}
                  >
                    Add another person
                  </a>
                </div>
                <div className="onetap_conn_contact_primary_account">
                  <div className="onetap_conn_contact_primary_account_header">
                    Primary Manager
                  </div>
                  <div className="onetap_conn_contact_primary_account_content">
                    This user will be the point of contact for all aspect of
                    managing the account users, cards, integrations, team member
                    onboarding, etc.
                  </div>
                  <div
                    className="onetap_conn_billing_form_input_syn"
                    style={{ display: "grid" }}
                  >
                    {" "}
                    <Select
                      options={managerOptions}
                      value={managerOptions.find(
                        (managerOptions) =>
                          managerOptions?.value === primaryManager
                      )}
                      onChange={(selectedOption) => {
                        setPrimaryManager(selectedOption?.value);
                        handleContactInputChange();
                      }}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="onetap_conn_billing_footer_button">
                  <button
                    onClick={handleEditbuttoncontact}
                    disabled={!isContactEdited}
                    style={{
                      background: isContactEdited ? "#e65925" : "#ccc",
                    }}
                  >
                   {userRoalload ? "Saving..." : "Save"} 
                  </button>
                </div>
              </div>
            )}
          </div>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: "center" }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">{messageIcon}</div>
                </div>
                <span className="team_created_success">{successMessage}</span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "13%",
                }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#e65925" }}
                  />
                </span>
              </button>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default ClientCompanyBillingandContactinformation;
