import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ClientCompanySettingsHeaders = (props) => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const { tab } = props;
  useEffect(() => {
    setCompanyProfileTab(tab);
  }, [tab]);

  const handleClickOfTab = (value) => {
    setCompanyProfileTab(value);
    props.onChange(value);
  };
  const { userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  return (
    <>
      <div
        className="onetap_connect_info_headers_syn"
        style={{
          backgroundColor: "#000",
          borderRadius: "4px",
          marginBottom: "15x",
          marginTop: "15px",
        }}
      >
        {userInformationTeamData &&
          userInformationTeamData.some(
            (user) => user.subscription_details.plan === "Team"
          ) && (
            <>
              {" "}
              <div
                onClick={() => handleClickOfTab("General")}
                className={
                  companyProfileTab === "General"
                    ? "onetap_connect_info_header_icon_and_header_active_syn"
                    : "onetap_connect_info_header_icon_and_header_deactive_syn"
                }
                style={{
                  backgroundColor:
                    companyProfileTab === "General" ? "#E65925" : "transparent",
                  borderRadius:
                    companyProfileTab === "General" ? "4px 0px 0px 4px" : "0px",
                  marginRight: "15px",
                  paddingRight: "30px",
                  height: "42px",
                }}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2531 27L13.8531 23.8C13.6365 23.7167 13.4321 23.6167 13.2401 23.5C13.0481 23.3833 12.8608 23.2583 12.6781 23.125L9.70312 24.375L6.95312 19.625L9.52813 17.675C9.51146 17.5583 9.50312 17.4457 9.50312 17.337V16.663C9.50312 16.5543 9.51146 16.4417 9.52813 16.325L6.95312 14.375L9.70312 9.625L12.6781 10.875C12.8615 10.7417 13.0531 10.6167 13.2531 10.5C13.4531 10.3833 13.6531 10.2833 13.8531 10.2L14.2531 7H19.7531L20.1531 10.2C20.3698 10.2833 20.5741 10.3833 20.7661 10.5C20.9581 10.6167 21.1455 10.7417 21.3281 10.875L24.3031 9.625L27.0531 14.375L24.4781 16.325C24.4948 16.4417 24.5031 16.5543 24.5031 16.663V17.337C24.5031 17.4457 24.4865 17.5583 24.4531 17.675L27.0281 19.625L24.2781 24.375L21.3281 23.125C21.1448 23.2583 20.9531 23.3833 20.7531 23.5C20.5531 23.6167 20.3531 23.7167 20.1531 23.8L19.7531 27H14.2531ZM17.0531 20.5C18.0198 20.5 18.8448 20.1583 19.5281 19.475C20.2115 18.7917 20.5531 17.9667 20.5531 17C20.5531 16.0333 20.2115 15.2083 19.5281 14.525C18.8448 13.8417 18.0198 13.5 17.0531 13.5C16.0698 13.5 15.2405 13.8417 14.5651 14.525C13.8898 15.2083 13.5525 16.0333 13.5531 17C13.5531 17.9667 13.8905 18.7917 14.5651 19.475C15.2398 20.1583 16.0691 20.5 17.0531 20.5Z"
                    fill="white"
                  />
                </svg>

                <p
                  className={
                    companyProfileTab === "General"
                      ? "onetap_connect_info_active_header_syn"
                      : "onetap_connect_info_header_syn"
                  }
                  style={{ color: "#fff" }}
                >
                  General
                </p>
              </div>
              <div
                onClick={() => handleClickOfTab("User profile")}
                className={
                  companyProfileTab === "User profile"
                    ? "onetap_connect_info_header_icon_and_header_active_syn"
                    : "onetap_connect_info_header_icon_and_header_deactive_syn"
                }
                style={{
                  backgroundColor:
                    companyProfileTab === "User profile"
                      ? "#E65925"
                      : "transparent",
                  marginRight: "15px",
                  paddingRight: "30px",
                  height: "42px",
                }}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.0266 27.5C18.6099 27.5 18.2556 27.354 17.9636 27.062C17.6716 26.77 17.5259 26.416 17.5266 26V19C17.5266 18.5833 17.6726 18.229 17.9646 17.937C18.2566 17.645 18.6106 17.4993 19.0266 17.5H26.0266C26.4432 17.5 26.7976 17.646 27.0896 17.938C27.3816 18.23 27.5272 18.584 27.5266 19V26C27.5266 26.4167 27.3806 26.771 27.0886 27.063C26.7966 27.355 26.4426 27.5007 26.0266 27.5H19.0266ZM19.0266 26H26.0266V25.2C25.6099 24.6833 25.1016 24.2707 24.5016 23.962C23.9016 23.6533 23.2432 23.4993 22.5266 23.5C21.8099 23.5 21.1516 23.6543 20.5516 23.963C19.9516 24.2717 19.4432 24.684 19.0266 25.2V26ZM22.5266 22.5C22.9432 22.5 23.2976 22.354 23.5896 22.062C23.8816 21.77 24.0272 21.416 24.0266 21C24.0266 20.5833 23.8806 20.229 23.5886 19.937C23.2966 19.645 22.9426 19.4993 22.5266 19.5C22.1099 19.5 21.7556 19.646 21.4636 19.938C21.1716 20.23 21.0259 20.584 21.0266 21C21.0266 21.4167 21.1726 21.771 21.4646 22.063C21.7566 22.355 22.1106 22.5007 22.5266 22.5ZM13.7766 26.5L13.3766 23.3C13.1599 23.2167 12.9556 23.1167 12.7636 23C12.5716 22.8833 12.3842 22.7583 12.2016 22.625L9.22656 23.875L6.47656 19.125L9.05156 17.175C9.0349 17.0583 9.02656 16.9457 9.02656 16.837V16.163C9.02656 16.0543 9.0349 15.9417 9.05156 15.825L6.47656 13.875L9.22656 9.125L12.2016 10.375C12.3849 10.2417 12.5766 10.1167 12.7766 10C12.9766 9.88333 13.1766 9.78333 13.3766 9.7L13.7766 6.5H19.2766L19.6766 9.7C19.8932 9.78333 20.0976 9.88333 20.2896 10C20.4816 10.1167 20.6689 10.2417 20.8516 10.375L23.8266 9.125L26.5766 13.875L24.4516 15.5H19.9266C19.6932 14.7833 19.2766 14.1873 18.6766 13.712C18.0766 13.2367 17.3766 12.9993 16.5766 13C15.6099 13 14.7849 13.3417 14.1016 14.025C13.4182 14.7083 13.0766 15.5333 13.0766 16.5C13.0766 17.3 13.3016 18 13.7516 18.6C14.2016 19.2 14.7932 19.6167 15.5266 19.85V26.5H13.7766Z"
                    fill="white"
                  />
                </svg>

                <p
                  className={
                    companyProfileTab === "User profile"
                      ? "onetap_connect_info_active_header_syn"
                      : "onetap_connect_info_header_syn"
                  }
                  style={{ color: "#fff" }}
                >
                  User profile
                </p>
              </div>
            </>
          )}
        <div
          onClick={() => handleClickOfTab("Lead Capture")}
          className={
            companyProfileTab === "Lead Capture"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
          style={{
            backgroundColor:
              companyProfileTab === "Lead Capture" ? "#E65925" : "transparent",
            marginRight: "15px",
            paddingRight: "30px",
            height: "42px",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "10px" }}
          >
            <path
              d="M10 4C12.2 4 14 5.8 14 8C14 10.2 12.2 12 10 12C7.8 12 6 10.2 6 8C6 5.8 7.8 4 10 4ZM17 21L18.8 22.77C19.3 23.27 20 22.87 20 22.28V18L22.8 14.6C22.9114 14.4514 22.9793 14.2748 22.996 14.0898C23.0126 13.9048 22.9775 13.7189 22.8944 13.5528C22.8114 13.3867 22.6837 13.247 22.5257 13.1493C22.3678 13.0517 22.1857 13 22 13H15C14.2 13 13.7 14 14.2 14.6L17 18V21ZM15 18.7L12.7 15.9C12.3 15.4 12.1 14.8 12.1 14.2C11.4 14 10.7 14 10 14C5.6 14 2 15.8 2 18V20H15V18.7Z"
              fill="white"
            />
          </svg>
          <p
            className={
              companyProfileTab === "Lead Capture"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
            style={{ color: "#fff" }}
          >
            Lead Capture
          </p>
        </div>
        <div
          onClick={() => handleClickOfTab("Share/Referral")}
          className={
            companyProfileTab === "Share/Referral"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
          style={{
            backgroundColor:
              companyProfileTab === "Share/Referral"
                ? "#E65925"
                : "transparent",
            marginRight: "15px",
            paddingRight: "30px",
            height: "42px",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ paddingLeft: "3px" }}
          >
            <path
              d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.1167 8.99167 12.2377 8.975 12.363C8.95833 12.4883 8.93333 12.6007 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z"
              fill="white"
            />
          </svg>
          <p
            className={
              companyProfileTab === "Share/Referral"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
            style={{ color: "#fff" }}
          >
            Share/Referral
          </p>
        </div>
        <div
          onClick={() => handleClickOfTab("Connections")}
          className={
            companyProfileTab === "Connections"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
          style={{
            backgroundColor:
              companyProfileTab === "Connections" ? "#E65925" : "transparent",
            marginRight: "15px",
            paddingRight: "30px",
            height: "42px",
          }}
        >
          <svg
            width="30"
            height="24"
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ paddingLeft: "3px" }}
          >
            <path
              d="M15.1594 3.99375L10.6219 7.66875C9.86719 8.27812 9.72188 9.375 10.2938 10.1578C10.8984 10.9922 12.075 11.1562 12.8859 10.5234L17.5406 6.90469C17.8688 6.65156 18.3375 6.70781 18.5953 7.03594C18.8531 7.36406 18.7922 7.83281 18.4641 8.09062L17.4844 8.85L24 14.85V6H23.9672L23.7844 5.88281L20.3812 3.70312C19.6641 3.24375 18.825 3 17.9719 3C16.95 3 15.9562 3.35156 15.1594 3.99375ZM16.2281 9.825L13.8047 11.7094C12.3281 12.8625 10.1859 12.5625 9.07969 11.0437C8.03906 9.61406 8.30156 7.61719 9.675 6.50625L13.575 3.35156C13.0312 3.12187 12.4453 3.00469 11.85 3.00469C10.9688 3 10.1109 3.2625 9.375 3.75L6 6V16.5H7.32187L11.6063 20.4094C12.525 21.2484 13.9453 21.1828 14.7844 20.2641C15.0422 19.9781 15.2156 19.6453 15.3047 19.2984L16.1016 20.0297C17.0156 20.8687 18.4406 20.8078 19.2797 19.8937C19.4906 19.6641 19.6453 19.3969 19.7438 19.1203C20.6531 19.7297 21.8906 19.6031 22.6547 18.7687C23.4937 17.8547 23.4328 16.4297 22.5187 15.5906L16.2281 9.825ZM0.75 6C0.3375 6 0 6.3375 0 6.75V16.5C0 17.3297 0.670312 18 1.5 18H3C3.82969 18 4.5 17.3297 4.5 16.5V6H0.75ZM2.25 15C2.44891 15 2.63968 15.079 2.78033 15.2197C2.92098 15.3603 3 15.5511 3 15.75C3 15.9489 2.92098 16.1397 2.78033 16.2803C2.63968 16.421 2.44891 16.5 2.25 16.5C2.05109 16.5 1.86032 16.421 1.71967 16.2803C1.57902 16.1397 1.5 15.9489 1.5 15.75C1.5 15.5511 1.57902 15.3603 1.71967 15.2197C1.86032 15.079 2.05109 15 2.25 15ZM25.5 6V16.5C25.5 17.3297 26.1703 18 27 18H28.5C29.3297 18 30 17.3297 30 16.5V6.75C30 6.3375 29.6625 6 29.25 6H25.5ZM27 15.75C27 15.5511 27.079 15.3603 27.2197 15.2197C27.3603 15.079 27.5511 15 27.75 15C27.9489 15 28.1397 15.079 28.2803 15.2197C28.421 15.3603 28.5 15.5511 28.5 15.75C28.5 15.9489 28.421 16.1397 28.2803 16.2803C28.1397 16.421 27.9489 16.5 27.75 16.5C27.5511 16.5 27.3603 16.421 27.2197 16.2803C27.079 16.1397 27 15.9489 27 15.75Z"
              fill="white"
            />
          </svg>
          <p
            className={
              companyProfileTab === "Connections"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
            style={{ color: "#fff" }}
          >
            Connections
          </p>
        </div>
        <div
          onClick={() => handleClickOfTab("Analytics")}
          className={
            companyProfileTab === "Analytics"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
          style={{
            backgroundColor:
              companyProfileTab === "Analytics" ? "#E65925" : "transparent",
            marginRight: "15px",
            paddingRight: "30px",
            height: "42px",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ paddingLeft: "3px" }}
          >
            <path
              d="M3 1.5H1.5V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H22.5V21H3V1.5Z"
              fill="white"
            />
            <path
              d="M22.5 6.75H17.25V8.25H19.9425L14.25 13.9425L11.0325 10.7175C10.9628 10.6472 10.8798 10.5914 10.7884 10.5533C10.697 10.5153 10.599 10.4957 10.5 10.4957C10.401 10.4957 10.303 10.5153 10.2116 10.5533C10.1202 10.5914 10.0372 10.6472 9.9675 10.7175L4.5 16.1925L5.5575 17.25L10.5 12.3075L13.7175 15.5325C13.7872 15.6028 13.8702 15.6586 13.9616 15.6967C14.053 15.7347 14.151 15.7543 14.25 15.7543C14.349 15.7543 14.447 15.7347 14.5384 15.6967C14.6298 15.6586 14.7128 15.6028 14.7825 15.5325L21 9.3075V12H22.5V6.75Z"
              fill="white"
            />
          </svg>
          <p
            className={
              companyProfileTab === "Analytics"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
            style={{ color: "#fff" }}
          >
            Analytics
          </p>
        </div>
        <div
          onClick={() => handleClickOfTab("Notifications")}
          className={
            companyProfileTab === "Notifications"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
          style={{
            backgroundColor:
              companyProfileTab === "Notifications" ? "#E65925" : "transparent",
            marginRight: "15px",
            paddingRight: "30px",
            height: "42px",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1461 3.24792C10.2956 2.87864 10.5519 2.56238 10.8822 2.3397C11.2125 2.11701 11.6018 1.99805 12.0001 1.99805C12.3985 1.99805 12.7878 2.11701 13.1181 2.3397C13.4484 2.56238 13.7047 2.87864 13.8541 3.24792C15.3329 3.65461 16.6373 4.53548 17.5669 5.75526C18.4966 6.97504 19.0001 8.46627 19.0002 9.99992V14.6969L20.8322 17.4449C20.9326 17.5955 20.9903 17.7706 20.9991 17.9514C21.0079 18.1322 20.9674 18.3121 20.882 18.4717C20.7966 18.6313 20.6695 18.7648 20.5142 18.8578C20.3588 18.9508 20.1812 19 20.0002 18.9999H15.4651C15.3447 19.833 14.9282 20.5949 14.2919 21.1459C13.6555 21.6969 12.8419 22.0001 12.0001 22.0001C11.1584 22.0001 10.3448 21.6969 9.70845 21.1459C9.07209 20.5949 8.65555 19.833 8.53515 18.9999H4.00015C3.81911 19 3.64145 18.9508 3.48614 18.8578C3.33083 18.7648 3.20369 18.6313 3.11828 18.4717C3.03287 18.3121 2.99239 18.1322 3.00118 17.9514C3.00996 17.7706 3.06767 17.5955 3.16815 17.4449L5.00015 14.6969V9.99992C5.00015 6.77592 7.18015 4.05992 10.1461 3.24792ZM10.5862 18.9999C10.6894 19.2926 10.8809 19.546 11.1343 19.7252C11.3876 19.9045 11.6903 20.0007 12.0006 20.0007C12.311 20.0007 12.6137 19.9045 12.867 19.7252C13.1204 19.546 13.3119 19.2926 13.4151 18.9999H10.5851H10.5862ZM12.0001 4.99992C10.6741 4.99992 9.4023 5.52671 8.46462 6.46439C7.52693 7.40207 7.00015 8.67384 7.00015 9.99992V14.9999C7.00019 15.1975 6.94174 15.3906 6.83215 15.5549L5.86915 16.9999H18.1301L17.1671 15.5549C17.0579 15.3905 16.9998 15.1974 17.0001 14.9999V9.99992C17.0001 8.67384 16.4734 7.40207 15.5357 6.46439C14.598 5.52671 13.3262 4.99992 12.0001 4.99992Z"
              fill="white"
            />
          </svg>
          <p
            className={
              companyProfileTab === "Notifications"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
            style={{ color: "#fff" }}
          >
            Notifications
          </p>
        </div>
      </div>
    </>
  );
};

export default ClientCompanySettingsHeaders;