import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { State, Country } from "country-state-city";
import { getAllPlans, getSingleOrder } from "../Redux/Otc_UserActions";
import { getPlan, getProducts } from "../../actions/userAction/userAction";
import plusIconWithBG from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/plusIconWithBG.svg";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlanCard from "../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../components/PlanCard/PlanCard";


const warningModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "#FDE9E9",
  p: 3,
  outline: "none",
  border: "1px solid E62525",
  borderRadius: "10px",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
  overflowY: "scroll",
  maxHeight: "80%",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "#F2F2F2",
  p: 4,
  outline: "none",
  overflowY: "scroll",
  maxHeight: "95%",
};


export default function Otc_CreateOrder() {
  const { isOtcAdminAuthenticated, adminuser, Adminerror, adminloading } = useSelector((state) => state.OtcAdminuser);
  const { order, loading } = useSelector((state) => state.order);
  const { plans:OtcPlans } = useSelector(
    (state) => state.OtcPlans
  );  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = process.env.REACT_APP_URL;
  const { id } = useParams();
  const orderid = location?.state?.orderId || null;
  const { products, message, error } = useSelector(
    (state) => state.products
  );
  const [orderId, setOrderId] = useState(orderid)
  const { plans } = useSelector((state) => state.OtcPlans);
  const [isActive, setIsActive] = useState(false);
  const [plandata, setPlanData] = useState([]);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardInfo, setCardInfo] = useState({
    cardName: "",
    cardNumber: null,
    csv: null,
    expiryDate: null,
  });
  const [paymentErrors, setPaymentErrors] = useState({
    cardName: "",
    cardNumber: "",
    expiryDate: "",
    csv: "",
  });
  const [allAddons, setAllAddons] = useState([]);
  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openAddOnModal, setOpenAddOnModal] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState("");
  const [customShippingPrice, setCustomShippingPrice] = useState("");
  const [createdCustomerData, setcreatedCustomerData] = useState({});
  const [couponrelatedAddons, setCouponrelatedaddons] = useState();
  const [couponrelatedproducts, setCouponrelatedproducts] = useState();
  const [orderData, setOrderData] = useState({
    orderNotes: "",
    shipping_method: {
      type: "",
      price: 0,
    },
    addaddons: [],
    subscription_details: {
    },
    smartAccessories: [],
  });
  const [userInforamtionData, setUserInforamtionData] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [userData, setUserData] = useState("");
  const [Titlename, setTitlename] = useState("");
  const [TopAvatar, setTopAvatar] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBillingState, setSelectedBillingState] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [billingValidationErrors, setBillingValidationErrors] = useState({});
  const [shippingAddressData, setShippingAddressData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });
  const [billingAddressData, setBillingAddressData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });
  const [allFieldsFilled, setAllFieldsFilled] = useState(false)
  const [dealOwner, setDealOwner] = useState("");
  const [referrer, setReferrer] = useState("");
  const [referrerName, setReferrerName] = useState("");
  const [customerIp, setCustomerIp] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [visibleCouponForm, setVisibleCouponForm] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [inputOrderNumber, setInputOrderNumber] = useState('')
  const [validationMessageOrderNumber, setValidationMessageOrderNumber] = useState('');
  const [showCouponError, setshowCouponError] = useState({ showError: false, error: "" });
  const [couponCode, setCouponCode] = useState([]);
  const [fetchedCoupon, setFetchedCoupon] = useState({ coupon: {}, discounValue: 0, type: null });
  const [planpriceDiscount, setPlanDiscount] = useState(null);
  const [productpriceDiscount, setProductDiscount] = useState(null);
  const [addonpriceDiscount, setAddonDiscount] = useState(null);
  const [applycoupondata, setApplycoupondata] = useState()
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [applyCouponbtnActive, setApplyCouponbtnActive] = useState(false);
  const [totalDiscountValue, setTotalDiscountValue] = useState(null);
  const [rates, setRates] = useState([]);
  const popup = document.getElementById("popup");
  const paymentSuccess = document.getElementById("payment-success");
  const paymentFailure = document.getElementById("payment-failure");
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxId, setTaxId] = useState("");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const fetchData = async () => {
    const taxshippingAddress = {
      Sstreet1: shippingAddressData?.line1,
      Scity: shippingAddressData?.city,
      Sstate: shippingAddressData?.state,
      SpostalCode: shippingAddressData?.postal_code,
      Scountry: shippingAddressData?.country,
    }
    try {
      const response = await axios.post(
        `${url}/payment/tax`,
        { shippingAddress: taxshippingAddress },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      let tax_percentage = 0;
      for (const taxRate of response.data.calculation.tax_breakdown) {
        const percentageDecimal = parseFloat(taxRate.tax_rate_details.percentage_decimal);
        tax_percentage += percentageDecimal;
      }
      setTaxPercentage(tax_percentage.toFixed(2));
      setTaxId(response.data.calculation.id);
    } catch (error) {
      setTaxPercentage("0.00");
      setTaxId(null);
    }
  };
  useEffect(() => {
    fetchData()
  }, [shippingAddressData])

  useEffect(() => {
    handleCalculateRatesAndContinue()
  }, [orderData?.smartAccessories, selectedCountry]);

  const productWeights = [];
  const productquantity = [];
  const totalWeights = [];
  if (orderData?.smartAccessories) {
    orderData?.smartAccessories.forEach((product) => {
      const weight = product?.product?.weight;
      const quantity = product?.quantity;
      if (weight !== undefined) {
        productWeights.push(weight);
      }
      if (quantity !== undefined) {
        productquantity.push(quantity);
      }
    });
  }
  for (let i = 0; i < productWeights.length && i < productquantity.length; i++) {
    const totalWeight = productWeights[i] * productquantity[i];
    totalWeights.push(totalWeight);
  }
  const sumTotalWeights = totalWeights.reduce((acc, currentValue) => acc + currentValue, 0);
  const shipkey = process.env.REACT_APP_SHIPSTATION_APIKEY;
  const shipsecret = process.env.REACT_APP_SHIPSTATION_API_SECRET;
  const largeEnvelopeorflatWeight = 1.4;
  const largeEnvelopeorflat2Weight = 1.7;
  const packageWeight = 3.2;
  const mediumFlatRateBoxWeight = 5.4;
  const largeFlatRateBoxWeight = 9.4;

  const handleCalculateRatesAndContinue = async (addressData) => {
    const value = await someFunction()
    const fullweight = sumTotalWeights + value.selectedWeight
    try {
      const apiKey = shipkey;
      const apiSecret = shipsecret;
      const shipmentData = {
        carrierCode: 'stamps_com',
        serviceCode: 'priority',
        packageCode: value.selectedPackageCode,
        fromPostalCode: '89120',
        toState: shippingAddressData.state,
        toCountry: shippingAddressData.country,
        toPostalCode: shippingAddressData.postal_code,
        weight: {
          value: fullweight,
          units: 'ounces',
        },
      };
      const response = await fetch('https://ssapi.shipstation.com/shipments/getrates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${apiKey}:${apiSecret}`)}`,
        },
        body: JSON.stringify(shipmentData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const modifiedData = data.map(item => {
        const originalShipmentCost = item.shipmentCost;
        const newShipmentCost = Math.floor(originalShipmentCost * 1.1 * 100) / 100; // Add 10%
        const serviceNameParts = item.serviceName.split('-');
        let modifiedServiceName = serviceNameParts[0]?.trim() || item.serviceName;
        if (modifiedServiceName === 'USPS Priority Mail') {
          modifiedServiceName = modifiedServiceName + ' (2-3 days) ';
        }

        return { ...item, shipmentCost: newShipmentCost, serviceName: modifiedServiceName };
      });

      setRates(modifiedData);
    } catch (error) {
      setRates([]);
    }
  };

  const someFunction = async () => {
    let selectedPackageCode = '';
    let selectedWeight = 0;
    if (sumTotalWeights + largeEnvelopeorflatWeight <= 5) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflatWeight;
    } else if (sumTotalWeights + largeEnvelopeorflat2Weight <= 10) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflat2Weight;
    } else if (sumTotalWeights + packageWeight <= 15) {
      selectedPackageCode = 'package';
      selectedWeight = packageWeight;
    } else if (sumTotalWeights + mediumFlatRateBoxWeight <= 50) {
      selectedPackageCode = 'package';
      selectedWeight = mediumFlatRateBoxWeight;
    } else if (sumTotalWeights + largeFlatRateBoxWeight <= 98) {
      selectedPackageCode = 'package';
      selectedWeight = largeFlatRateBoxWeight;
    }
    return { selectedPackageCode, selectedWeight };
  }

  const calculateShippingCost = () => {
    switch (selectedShippingMethod) {
      case "free":
        return {
          cost: 0,
          serviceCode: "usps_first_class_mail",
        };
      case "2-4":
        const twoToFourDayRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_first_class_mail" ||
            rate.serviceCode === "usps_first_class_mail_international"
        );
        return {
          cost: orderData && orderData?.smartAccessories?.length === 0 ? 0 : twoToFourDayRate?.shipmentCost || 0,
          serviceCode: twoToFourDayRate?.serviceCode || null,
        };
      case "overnight":
        const overnightRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_priority_mail" ||
            rate.serviceCode === "usps_priority_mail_international"
        );
        return {
          cost: orderData && orderData?.smartAccessories?.length === 0 ? 0 : overnightRate?.shipmentCost || 0,
          serviceCode: overnightRate?.serviceCode || null,
        };
      default:
        return 0;
    }
  };

  const shippingInfo = calculateShippingCost();

  const areAllFieldsFilled = () => {
    const isAccordion5Filled =
      (cardInfo.cardName?.trim() !== "" &&
        cardInfo.cardNumber !== null &&
        cardInfo.csv !== null &&
        cardInfo.expiryDate !== null)
    const allAccordionsFilled = isAccordion5Filled;
    setAllFieldsFilled(allAccordionsFilled);
  }
  useEffect(() => {
    areAllFieldsFilled();
  }, [cardInfo]);

  useEffect(() => {
    dispatch(getSingleOrder(orderId))
  }, [])

  useEffect(() => {
    const selectedCountry = {
      value: order?.shippingAddress[0]?.country,
      label: Country?.getCountryByCode(order?.shippingAddress[0].country)?.name,
    };
    const selectedState = {
      value: order?.shippingAddress[0]?.state,
      label: State?.getStateByCodeAndCountry(order?.shippingAddress[0]?.state, order?.shippingAddress[0]?.country)?.name,
    };
    const selectedBillingCountry = {
      value: order?.billingAddress[0]?.country,
      label: Country?.getCountryByCode(order?.billingAddress[0]?.country)?.name,
    };
    // 
    const selectedBillingState = {
      value: order?.billingAddress[0]?.state,
      label: State?.getStateByCodeAndCountry(order?.billingAddress[0]?.state, order?.billingAddress[0]?.country)?.name,
    };
    const modifyAddonsStructure = (addons) => {
      return addons?.map((addon) => ({
        addonId: addon.addonId._id,
        status: addon.status,
        assignTo: addon.assignTo,
        addonName: addon?.addonName,
        price: addon.price,
        addonDiscountPrice: addon.addonDiscountPrice,
        _id: addon._id,
      }));
    };
    const modifySmartAccessoriesStructure = (smartAccessories) => {
      return smartAccessories?.map((item) => ({
        productId: item?.productId?._id,
        status: item?.status,
        subtotal: item?.subtotal,
        price: item?.price,
        variationId: item?.variationId,
        quantity: item?.quantity,
        discountAmount: item?.discountAmount,
        _id: item._id,
      }));
    };
    setOrderData({
      ...order,
      addaddons: modifyAddonsStructure(order?.addaddons),
      smartAccessories: modifySmartAccessoriesStructure(order?.smartAccessories),
      shipping_method: order?.shipping_method[0],
      subscription_details: {
        ...order?.subscription_details,
        total_user: order?.subscription_details?.total_user[0],
      }
    });
    setSpecialDiscount(order?.discount);
    setBillingAddressData(order?.billingAddress[0]);
    setSelectedBillingCountry(selectedBillingCountry);
    setSelectedBillingState(selectedBillingState);
    setShippingAddressData(order?.shippingAddress[0]);
    setSelectedCountry(selectedCountry);
    setSelectedState(selectedState);
    setSelectedShippingMethod(order?.shipping_method[0]?.type)
    const selectedPlanId = order?.subscription_details?.planID
    const matchingPlan = plandata?.find((plan) => plan._id === selectedPlanId);
    setSelectedPlan(matchingPlan);
    const addonIds = order?.addaddons?.map((addon) => addon?.addonId._id);
    setSelectedAddons(addonIds);
    const previousSelectedProducts = order?.smartAccessories?.map(
      (item) => ({ productId: item?.productId._id, variationId: item?.variationId })
    );
    if (order?.subscription_details?.billing_cycle === "yearly") {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
    setSelectedProducts(previousSelectedProducts);
    if (order?.isCouponUsed) {
      removeAppliedCoupon()
    }
  }, [order])


  const couponInputHandler = (e) => {
    const couponCode = [e.target.value.trim().replace(/\s+/g, ' ')];
    setCouponCode(couponCode);
    setApplyCouponbtnActive(false)
  }
  const couponHandler = async () => {
    setApplyCouponbtnActive(true)

    if (couponCode.length <= 0) {
      setshowCouponError({ showError: true, error: "Please enter the coupon code." })
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      setApplyCouponbtnActive(false)
    } else if (!orderData?.subscription_details?.planID && !orderData?.addaddons?.length > 0 && !orderData?.smartAccessories?.length > 0) {
      setshowCouponError({ showError: true, error: "Please select item to apply coupon code." })
      setIsCouponApplied(false)
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      removeAppliedCoupon()
    }

    else {
      try {
        const { data } = await axios.post(`${url}/admin/coupons/get`,
          { codes: couponCode },
          config
        )
        if (data.success) {
          applyCouponToPrice(data.coupons)
          setApplyCouponbtnActive(false)
        } else {
          setshowCouponError({ showError: true, error: data.msg })
          setTimeout(() => {
            setshowCouponError({ showError: false, error: null })
          }, 2000);
          setApplyCouponbtnActive(false)
          removeAppliedCoupon()


          setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
          setApplyCouponbtnActive(false)
          setIsCouponApplied(false)
          setTotalDiscountValue(0)
        }
      } catch (error) {
        setApplyCouponbtnActive(false)
        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
      }
    }
  }

  const applyCouponToPrice = async (coupons) => {
    const subTotal = orderData?.subscription_details?.recurring_amount
    let cycleData;
    if (isActive) { cycleData = "yearly" } else { cycleData = 'monthly' }
    let userCount = orderData?.subscription_details?.total_user?.additionalUser
    let cartAddedProducts = [];
    if (Array.isArray(orderData?.smartAccessories)) {
      cartAddedProducts = orderData.smartAccessories.map((item) => {
        const { productId, quantity, variationId } = item;
        const matchingAccessory = products?.find((item) => item._id === productId)
        return {
          product: matchingAccessory,
          variation: variationId,
          quantity,
        };
      });
    }

    let selectedAddOne = orderData?.addaddons
    if (!coupons || coupons.length === 0) {
      setshowCouponError({ showError: true, error: "No coupons Found" })
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      return;
    }

    const firstCoupon = coupons[0];

    setApplycoupondata(firstCoupon)
    if (firstCoupon) {
      if (firstCoupon.usageLimit <= 0) {
        setshowCouponError({ showError: true, error: "This coupon has exceed a usage limit" })
        setTimeout(() => {
          setshowCouponError({ showError: false, error: null })
        }, 2000);
        return
      }
    }
    let totalPlanDiscount = 0;
    let totalAddonDiscount = 0;
    let totalPrice = 0

    if (selectedPlan) {

      const planIds = firstCoupon.planDiscount.map(item => item.plan_id);
      const planMatch = firstCoupon.planDiscount.find(item => item.plan_id === selectedPlan?._id);

      if (planMatch) {
        const discountValue = firstCoupon.discountAmount;
        if (firstCoupon.discountType === 'Fix discount price') {
          totalPlanDiscount = (selectedPlan?.users + orderData?.subscription_details?.total_user
            ?.additionalUser) * discountValue;
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalPlanDiscount = subTotal * discountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          let usageLimit = 0;
          let planDiscount = 0;
          if (cycleData === "monthly") {
            planDiscount = (selectedPlan?.users + orderData?.subscription_details?.total_user
              ?.additionalUser) * (planMatch.monthly.discountedPrice);
            usageLimit = planMatch.monthly.usageLimit;
          } else if (cycleData === "yearly") {
            planDiscount = (selectedPlan?.users + orderData?.subscription_details?.total_user
              ?.additionalUser) * (planMatch.yearly.discountedPrice);
            usageLimit = planMatch.yearly.usageLimit;
          }
          totalPlanDiscount = planDiscount
        } else {
          return
        }
      }
      setPlanDiscount(totalPlanDiscount)
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
        userID: userData._id
      })
      if (verifiedCoupon) {
        setIsCouponApplied(true);
      }
    }
    if (selectedAddOne?.length > 0) {
      const addonsIds = selectedAddOne?.map(item => item.addonId || null);
      const validAddons = firstCoupon?.addonDiscount?.filter(item => addonsIds?.includes(item.addon_id));
      setCouponrelatedaddons(validAddons)
      const filteredSelectedAddOne = selectedAddOne?.filter(item =>
        validAddons?.some(validAddon => validAddon?.addon_id === item.addonId)
      );
      const totalPrice = filteredSelectedAddOne.reduce((sum, item) => sum + (item?.price || 0), 0);
      const discountValue = firstCoupon.discountAmount;
      if (totalPrice) {
        if (firstCoupon.discountType === 'Fix discount price') {
          totalAddonDiscount = (filteredSelectedAddOne?.length) * (discountValue);
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalAddonDiscount = totalPrice * discountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          validAddons.forEach(items => {
            if (!items.type) {
              totalAddonDiscount += items.discountedPrice
            } else if (items.type === "onetimepayment") {
              totalAddonDiscount += items.discountedPrice
            } else if (items.type === "subscription") {
              cycleData === 'monthly'
                ?
                totalAddonDiscount += items?.monthlyDiscountedPrice
                :
                totalAddonDiscount += items?.yearlyDiscountedPrice
            }
          })
        }
      }
      setAddonDiscount(totalAddonDiscount)
    }
    if (cartAddedProducts?.length > 0) {
      const matchedProducts = [];
      let totalQuantity = 0;
      cartAddedProducts.forEach(cartProduct => {
        if (cartProduct.variations && cartProduct.variations.length > 0) {
          const matchingVariation = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.variations && couponProduct.variations.length > 0 &&
            couponProduct.variations.some(couponVariation =>
              cartProduct.variations.some(cartVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            )
          );
          if (matchingVariation) {
            const matchedCartVariation = cartProduct.variations.find(cartVariation =>
              matchingVariation.variations.some(couponVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            );
            if (matchedCartVariation) {
              matchedProducts.push({ product: cartProduct, variation: matchedCartVariation });
            }
          }
        } else {
          const matchingProd = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.product_id === cartProduct.product._id
          );
          if (matchingProd) {
            matchedProducts.push({ product: cartProduct });
          }
        }
      });
      const couponRelatedProductsidarray = matchedProducts?.map(item => ({
        variationID: item?.product?.variation && item?.product?.variation,
        productID: item?.product?.product?._id,
        name: item?.product?.product?.name
      }));
      setCouponrelatedproducts(couponRelatedProductsidarray)

      const filterVariation = (variations, variationId) => {
        return variations.find(variation => variation._id === variationId) || null;
      };
      matchedProducts.forEach(matchingProduct => {
        if (matchingProduct.product.product.isOnSale) {
          totalQuantity += matchingProduct.product.quantity;
        }
      })

      const calculateTotalDiscount = () => {
        let productTotalPrice = 0;
        if (matchedProducts) {
          productTotalPrice = matchedProducts.reduce((sum, val) => {
            const variation = filterVariation(val.product.product.variations, val.product.variation);
            if (val.product.variation) {
              const productPrice = variation.saleprice
                ? variation.saleprice * val.product.quantity
                : variation.price * val.product.quantity;
              return sum + productPrice;
            } else {
              const productPrice = val.product.product.saleprice
                ? val.product.product.saleprice * val.product.quantity
                : val.product.product.price * val.product.quantity;
              return sum + productPrice;
            }
          }, 0);
        }
        const totalPrice = productTotalPrice
        return totalPrice;
      };
      totalPrice = calculateTotalDiscount();
      let totalDiscount = 0;
      if (totalPrice) {
        const discountValue = firstCoupon.discountAmount;
        if (firstCoupon.discountType === 'Fix discount price') {
          totalDiscount = discountValue * totalQuantity;
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalDiscount = totalPrice * discountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          const products = firstCoupon.productDiscount;
          let totalCustomDiscount = 0;
          matchedProducts.forEach(matchedProduct => {
            const quantity = matchedProduct.product.quantity || 1;
            if (matchedProduct.product.variation) {
              const matchingVariation = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                couponProduct.variations.some(couponVariation =>
                  couponVariation.variation_id === matchedProduct.product.variation
                )
              );
              if (matchingVariation) {
                const variationDiscount = matchingVariation.variations.find(variation =>
                  variation.variation_id === matchedProduct.product.variation
                );
                if (variationDiscount) {
                  totalCustomDiscount += (variationDiscount.variationDiscountedPrice || 0) * quantity;
                }
              }
            } else {
              const matchingProduct = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                !couponProduct.variations.length
              );
              if (matchingProduct) {
                totalCustomDiscount += (matchingProduct.discountedPrice || 0) * quantity;
              }
            }
          });
          totalDiscount = totalCustomDiscount;
        } else {
          setshowCouponError({ showError: true, error: "Internal Server error" })
          setTimeout(() => {
            setshowCouponError({ showError: false, error: null })
          }, 2000);
          return
        }
      }
      setProductDiscount(totalDiscount)
    }

    if (!totalPlanDiscount && !totalAddonDiscount && !totalPrice) {
      setshowCouponError({ showError: true, error: "Please enter a valid coupon." })
      setTimeout(() => {
        setshowCouponError({ showError: false, error: null })
      }, 2000);
      removeAppliedCoupon()
    }
  }

  useEffect(() => {
    if (planpriceDiscount || addonpriceDiscount || productpriceDiscount) {
      const updatedCoupon = {
        discounValue: (addonpriceDiscount + productpriceDiscount + planpriceDiscount) ?? null,
        coupon: applycoupondata
      };
      setFetchedCoupon(updatedCoupon);
      setTotalDiscountValue(addonpriceDiscount + productpriceDiscount + planpriceDiscount)
    }

    const modifiedAddonswithdiscount = orderData?.addaddons?.map((value) => {
      const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === value?.addonId);
      let addonDiscountPrice;
      if (validAddon?.type === "onetimepayment") {
        addonDiscountPrice = validAddon?.discountedPrice;
      } else {
        const isYearly = isActive;
        addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
      }
      return {
        addonId: value?.addonId,
        status: 'N/A',
        assignTo: '',
        addonName: value?.addonName,
        price: value?.price,
        addonDiscountPrice: (applycoupondata?.discountType === "Fix custom discount") ? addonDiscountPrice :
          (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
            (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (value?.price * applycoupondata?.discountAmount) / 100 :
              0,
      };
    });
    const planMatch = applycoupondata?.planDiscount?.find(item => item.plan_id === selectedPlan?._id);
    let perUserDiscountPrice;
    if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
      perUserDiscountPrice = applycoupondata?.discountAmount;
    } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
      perUserDiscountPrice = ((!isActive ? selectedPlan?.monthlyPrice_perUser : selectedPlan?.monthlyPrice_perUser) * applycoupondata?.discountAmount) / 100;
    } else if (applycoupondata?.discountType === "Fix custom discount" && planMatch) {
      if (!isActive) {
        perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
      } else {
        perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
      }
    }
    const discountedPricesMap = {};
    applycoupondata?.productDiscount?.forEach((discount) => {
      if (discount.variations.length > 0) {
        discount.variations.forEach((variation) => {
          discountedPricesMap[variation.variation_id] = variation.variationDiscountedPrice;
        });
      } else {
        discountedPricesMap[discount.product_id] = discount.discountedPrice;
      }
    });
    const updatedSmartAccessories = orderData?.smartAccessories?.map((accessory) => {
      const matchingAccessory = products?.find((val) => val?._id === accessory.productId);
      const accessoryQuantity = parseInt(accessory?.quantity) || 1;
      const Productmatch = couponrelatedproducts?.find(secondItem =>
        (secondItem?.variationID !== null && secondItem?.variationID === accessory?.variationId) ||
        (secondItem.variationID === null && secondItem.productID === accessory?.productId)
      );
      if (matchingAccessory?.hasVariations && accessory?.variationId) {
        const matchingVariation = matchingAccessory?.variations?.find((variation) => variation?._id === accessory.variationId);
        const variationPrice = matchingAccessory?.isOnSale ? matchingVariation?.saleprice : matchingVariation?.price;
        const price = !parseFloat(accessory?.price) ? parseFloat(variationPrice) : parseFloat(accessory?.price);
        const total = price * accessoryQuantity;
        accessory.price = !accessory?.price ? variationPrice : accessory?.price;
        accessory.subtotal = total;
        accessory.discountAmount = applycoupondata?.discountType === "Fix custom discount" ? discountedPricesMap[accessory.variationId || accessory.productId] :
          (applycoupondata?.discountType === "Fix discount price" && Productmatch) ? applycoupondata?.discountAmount :
            (applycoupondata?.discountType === "Percentage discount price" && Productmatch) ? (price * applycoupondata?.discountAmount) / 100 :
              0;
      } else {
        const productPrice = matchingAccessory?.isOnSale ? matchingAccessory?.saleprice : matchingAccessory?.price;
        const price = !parseFloat(accessory?.price) ? parseFloat(productPrice) : parseFloat(accessory?.price);
        const total = price * accessoryQuantity;
        accessory.price = !accessory?.price ? productPrice : accessory?.price;
        accessory.subtotal = total;
        accessory.discountAmount = (applycoupondata?.discountType === "Fix custom discount") ? discountedPricesMap[accessory.variationId || accessory.productId] :
          (applycoupondata?.discountType === "Fix discount price" && Productmatch) ? applycoupondata?.discountAmount :
            (applycoupondata?.discountType === "Percentage discount price" && Productmatch) ? (price * applycoupondata?.discountAmount) / 100 :
              0;
      }
      return accessory;
    });

    const modifiedOrderdata = {
      ...orderData,
      addaddons: modifiedAddonswithdiscount,
      smartAccessories: updatedSmartAccessories,
      subscription_details: {
        ...orderData?.subscription_details,
        perUserDiscountPrice: perUserDiscountPrice
      },
    };
    setOrderData(modifiedOrderdata);
  }, [addonpriceDiscount, productpriceDiscount, planpriceDiscount, isCouponApplied, couponCode])

  const verifyCouponUsage = async (couponDetails) => {
    try {
      const { data } = await axios.post(`${url}/admin/coupons/checkusage`,
        couponDetails,
        config
      )
      if (data.success) {
        return true
      } else {
        setshowCouponError({ showError: true, error: data.msg })
        setTimeout(() => {
          setshowCouponError({ showError: false, error: null })
        }, 2000);
        removeAppliedCoupon()
        setApplyCouponbtnActive(false)
        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
        return false
      }
    } catch (error) {
      setApplyCouponbtnActive(false)
      setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
      setApplyCouponbtnActive(false)
      setIsCouponApplied(false)
      setTotalDiscountValue(0)
      return false
    }

  }

  const removeAppliedCoupon = () => {
    setApplyCouponbtnActive(false)
    setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
    setApplyCouponbtnActive(false)
    setIsCouponApplied(false)
    setPlanDiscount(0)
    setAddonDiscount(0)
    setProductDiscount(0)
    setTotalDiscountValue(0)
    setApplycoupondata()
    setCouponCode([]);
    setVisibleCouponForm(!visibleCouponForm)
  }

  const handleShippingMethodChange = (value) => {
    setCustomShippingPrice("");
    setSelectedShippingMethod(value);
  };
  const handleWarningOpenModal = () => { setOpenWarningModal(true) }
  const handleCloseWarningdModal = () => { setOpenWarningModal(false); setValidationMessageOrderNumber(''); setInputOrderNumber('') }
  function getStatusColorClass(status) {
    if (status === "Published") {
      return "published";
    } else if (status === "Draft") {
      return "draft";
    } else if (status === "Archived") {
      return "archived";
    } else {
      return "";
    }
  }
  const fetchandSetPlans = () => {
    if (OtcPlans) {
      const AllfilteredPlans =  OtcPlans.filter(plan => {
        return plan.status === "Published" && plan.Visibility === "Public";
      });
      setPlanData(AllfilteredPlans);
    } else {
      dispatch(getAllPlans());
    }
  }
  useEffect(() => {
    fetchandSetPlans();
  }, [OtcPlans])
  useEffect(() => {
    if (!products) {
      dispatch(getProducts());
    }
  }, [products]);
  const handleCloseOfLinkModal = () => {
    setShowPlanModal(false);
  };
  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "postal_code") {
      if (!selectedCountry) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a country.",
        }));
        return;
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (
      name === "address_name" ||
      name === "line1" ||
      name === "state" ||
      name === "city" ||
      name === "country"
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (
      name === "company_name" &&
      !value.trim() &&
      (!shippingAddressData?.first_name?.trim() ||
        !shippingAddressData?.last_name?.trim())
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First name is required.",
        last_name: "Last name is required.",
      }));
    } else if (name === "company_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
        last_name: "",
      }));
    } else if (name === "first_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    } else if (name === "last_name" && value.trim()) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };
  const handleCountryChange = (selectedOption) => {
    setShippingAddressData((prevData) => ({
      ...prevData,
      postal_code: "",
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      postal_code: "",
    }));
    setSelectedState("");
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        country: "",
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode)?.map(
        (item) => ({
          value: item.isoCode,
          label: item.name,
        })
      );
      setStateOptions(states);

      setShippingAddressData((prevData) => ({
        ...prevData,
        country: selectedOption.value,
      }));
      if (selectedState) {
        setShippingAddressData((prevData) => ({
          ...prevData,
          state: selectedState.value,
        }));
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          state: "",
        }));
      }
    }
  };
  const handleStateChange = (selectedOption) => {
    setShippingAddressData((prevData) => ({
      ...prevData,
      postal_code: "",
    }));
    setSelectedState(selectedOption);
    if (selectedOption) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        state: "",
      }));
      setShippingAddressData((prevData) => ({
        ...prevData,
        state: selectedOption.value,
      }));
    }
  };
  const handleBillingInputChange = (e) => {
    const { name, value } = e.target;
    setBillingAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "postal_code") {
      if (!selectedBillingCountry) {
        setBillingValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a country.",
        }));
        return;
      } else {
        setBillingValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }

    }

    if (
      name === "address_name" ||
      name === "line1" ||
      name === "state" ||
      name === "city" ||
      name === "country"
    ) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    if (
      name === "company_name" &&
      !value.trim() &&
      (!billingAddressData?.first_name?.trim() ||
        !billingAddressData?.last_name?.trim())
    ) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "First name is required.",
        last_name: "Last name is required.",
      }));
    } else if (name === "company_name" && value.trim()) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
        last_name: "",
      }));
    } else if (name === "first_name" && value.trim()) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "",
      }));
    } else if (name === "last_name" && value.trim()) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "",
      }));
    }
  };
  const handleBillingCountryChange = (selectedOption) => {
    setBillingAddressData((prevData) => ({
      ...prevData,
      postal_code: "",
    }));

    setBillingValidationErrors((prevErrors) => ({
      ...prevErrors,
      postal_code: "",
    }));
    setSelectedBillingState("");
    setSelectedBillingCountry(selectedOption);
    if (selectedOption) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        country: "",
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode)?.map(
        (item) => ({
          value: item.isoCode,
          label: item.name,
        })
      );
      setStateOptions(states);

      setBillingAddressData((prevData) => ({
        ...prevData,
        country: selectedOption.value,
      }));
      if (selectedState) {
        setBillingAddressData((prevData) => ({
          ...prevData,
          state: selectedState.value,
        }));
        setBillingValidationErrors((prevErrors) => ({
          ...prevErrors,
          state: "",
        }));
      }
    }
  };
  const handleBillingStateChange = (selectedOption) => {
    setBillingAddressData((prevData) => ({
      ...prevData,
      postal_code: "",
    }));

    setSelectedBillingState(selectedOption);
    if (selectedOption) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        state: "",
      }));
      setBillingAddressData((prevData) => ({
        ...prevData,
        state: selectedOption.value,
      }));
    }
  };
  const handleCardNameChange = (event) => {
    setCardInfo({
      ...cardInfo,
      cardName: event.target.value,
    });

    if (!event.target.value) {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "Please enter the name on the card.",
      }));
    } else {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "",
      }));
    }
  };
  const validateForm = () => {
    const errors = {};
    if (
      !shippingAddressData?.company_name?.trim() &&
      !shippingAddressData?.first_name?.trim() &&
      !shippingAddressData?.last_name?.trim()
    ) {
      errors.first_name = "First name is required.";
      errors.last_name = "Last name is required.";
    }

    if (!shippingAddressData?.line1?.trim()) {
      errors.line1 = "Address is required.";
    }

    if (!shippingAddressData?.city?.trim()) {
      errors.city = "City is required.";
    }

    if (!selectedCountry) {
      errors.country = "Country is required.";
    }

    if (selectedCountry && stateOptions.length > 0) {
      if (!selectedState) {
        errors.state = "State is required.";
      }
    }
    if (!shippingAddressData?.postal_code?.trim()) {
      errors.postal_code = "Postal code is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const validateBillingForm = () => {
    const errors = {};
    if (
      !billingAddressData?.company_name?.trim() &&
      !billingAddressData?.first_name?.trim() &&
      !billingAddressData?.last_name?.trim()
    ) {
      errors.first_name = "First name is required.";
      errors.last_name = "Last name is required.";
    }
    if (!billingAddressData?.line1?.trim()) {
      errors.line1 = "Address is required.";
    }
    if (!billingAddressData?.city?.trim()) {
      errors.city = "City is required.";
    }
    if (!selectedBillingCountry) {
      errors.country = "Country is required.";
    }
    if (selectedBillingCountry && stateOptions.length > 0) {
      if (!selectedBillingState) {
        errors.state = "State is required.";
      }
    }
    if (!billingAddressData?.postal_code?.trim()) {
      errors.postal_code = "Postal code is required";
    }
    setBillingValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const fullName = Titlename;
  const words = fullName.split(" ");
  const initials = words
    .slice(0, 2)
    ?.map((word) => word.charAt(0).toUpperCase())
    .join("");
  const handleCopyAddress = () => {
    setShippingAddressData({ ...billingAddressData });
    setSelectedCountry(selectedBillingCountry);
    setSelectedState(selectedBillingState);
    handleCalculateRatesAndContinue(billingAddressData);
    setValidationErrors({})
  };
  const fetchUser = async () => {
    try {
      const { data } = await axios.get(`${url}/admin/user/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      setUserInforamtionData(data?.user[0]);
      setUserData(data?.user[0]?.user_id);
      setDealOwner(data?.user[0]?.user_id?.dealOwner);
      setCustomerIp(data?.user[0]?.user_id?.customerIp);
      setReferrer(data?.user[0]?.user_id?.referrer);
      setReferrerName(data?.user[0]?.user_id?.referrerName);
      setCompanyData(data.user[0].company_ID);
      setTitlename(
        data.user[0]?.user_id?.first_name +
        " " +
        data.user[0]?.user_id?.last_name
      );
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchUser();
  }, []);
  const getAddons = async () => {
    try {
      const { data } = await axios.get(`${url}/admin/getAddons`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAllAddons(data.Addons);
    } catch (error) {
    }
  };
  useEffect(() => {
    getAddons();
  }, []);

  const toggleProductSelection = (productId, variation) => {
    const variationId = variation || null;
    const isProductSelected = selectedProducts?.some(
      (item) => item.productId === productId && item.variationId === variationId
    );

    if (isProductSelected) {
      setSelectedProducts((prevSelected) => {
        if (Array.isArray(prevSelected)) {
          return prevSelected?.filter(
            (item) =>
              !(item.productId === productId && item.variationId === variationId)
          );
        }
        return [];
      });
    } else {
      setSelectedProducts((prevSelected) => [
        ...(Array.isArray(prevSelected) ? prevSelected : []),
        { productId, variationId },
      ]);
    }
  };

  const handleUpdateProductsClick = () => {
    const updatedSmartAccessories = selectedProducts?.map(
      ({ productId, variationId }) => {
        const existingSmartAccessory = orderData?.smartAccessories?.find(
          (accessory) =>
            accessory.productId === productId &&
            accessory.variationId === variationId
        );
        return (
          existingSmartAccessory || {
            productId,
            variationId,
            price: "",
            quantity: 1,
            subtotal: "",
          }
        );
      }
    );
    setOrderData((prev) => ({
      ...prev,
      smartAccessories: updatedSmartAccessories,
    }));
    setOpenProductModal(false);
  };

  const handleCancelProductsClick = () => {
    const previousSelectedProducts = orderData?.smartAccessories?.map(
      (item) => ({ productId: item.productId, variationId: item.variationId })
    );
    setSelectedProducts(previousSelectedProducts);
    setOpenProductModal(false);
  };

  const handleRemoveProduct = (productId, variation) => {
    const variationId = variation ? variation : null;
    const updatedSelectedProducts = selectedProducts?.filter(
      (selectedProduct) =>
        selectedProduct.productId !== productId ||
        selectedProduct.variationId !== variationId
    );
    setSelectedProducts(updatedSelectedProducts);

    const updatedSmartAccessories = orderData.smartAccessories?.filter(
      (accessory) =>
        accessory.productId !== productId ||
        accessory.variationId !== variationId
    );
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      smartAccessories: updatedSmartAccessories,
    }));
    removeAppliedCoupon();
  };

  const toggleAddonSelection = (addonId) => {
    setSelectedAddons((prevSelected) => {
      prevSelected = prevSelected || [];
      if (Array.isArray(prevSelected)) {
        if (prevSelected?.includes(addonId)) {
          return prevSelected?.filter((id) => id !== addonId);
        } else {
          return [...prevSelected, addonId];
        }
      } else {
        return [];
      }
    });
  };

  const handleUpdateAddonsClick = () => {
    const updatedAddonDiscount = selectedAddons?.map((Id) => {
      const existingDiscount = orderData.addaddons?.find(
        (discount) => discount.addonId === Id
      );
      const matchingAdd_ons = allAddons?.find((item) => item._id === Id);
      let price;
      if (matchingAdd_ons.paymentType === "onetimepayment") {
        price = matchingAdd_ons?.Addonspaymentdata?.price;
      } else {
        const isYearly = isActive;
        const isUserBased = matchingAdd_ons?.Addonspaymentdata?.isuserbased;
        const userPrice = isYearly ? matchingAdd_ons?.Addonspaymentdata?.yearlyuserprice : matchingAdd_ons?.Addonspaymentdata?.monthlyuserprice;
        const basePrice = isYearly ? matchingAdd_ons?.Addonspaymentdata?.yearlyprice : matchingAdd_ons?.Addonspaymentdata?.monthlyprice;
        const totalUser = (selectedPlan?.users + orderData?.subscription_details?.total_user
          ?.additionalUser)
        if (isUserBased && totalUser >= matchingAdd_ons?.Addonspaymentdata?.numofuser) {
          price = basePrice + ((totalUser - matchingAdd_ons?.Addonspaymentdata?.numofuser) * userPrice);
        } else {
          price = basePrice;
        }
      }
      return existingDiscount ||
      {
        addonId: matchingAdd_ons?._id,
        status: matchingAdd_ons?.status,
        assignTo: '',
        type: matchingAdd_ons?.paymentType,
        addonName: matchingAdd_ons?.publicname,
        price: price,
      }
    });

    setOrderData((prevCouponData) => ({
      ...prevCouponData,
      addaddons: updatedAddonDiscount,
    }));
    setOpenAddOnModal(false);
  };

  const handleCancelAddonsClick = () => {
    const addonIds = orderData.addaddons?.map((addon) => addon.addonId);
    setSelectedAddons(addonIds);
    setOpenAddOnModal(false);
  };

  const handleRemoveAddon = (addonId) => {
    const updatedSelectedAddons = selectedAddons?.filter(
      (selectedAddonId) => selectedAddonId !== addonId
    );
    setSelectedAddons(updatedSelectedAddons);

    const updatedAddonDiscount = orderData?.addaddons?.filter(
      (item) => item.addonId !== addonId
    );
    setOrderData((prevData) => ({
      ...prevData,
      addaddons: updatedAddonDiscount,
    }));
    removeAppliedCoupon();
  };

  const handleQuantityChange = (productId, variationId, quantity) => {
    setOrderData((prevOrderData) => {
      const updatedSmartAccessories = prevOrderData.smartAccessories?.map(
        (item) => {
          if (
            item.productId === productId &&
            item.variationId === variationId
          ) {
            return { ...item, quantity: quantity };
          }
          return item;
        }
      );
      return { ...prevOrderData, smartAccessories: updatedSmartAccessories };
    });
  };

  const calculateTotal = (product, variation, productPrice) => {
    const vId = variation ? variation?._id : null;
    const matchingAccessory = orderData?.smartAccessories?.find(
      (item) => item?.productId === product._id && item?.variationId === vId
    );
    const accessoryPrice = matchingAccessory?.price;
    const accessoryDiscountPrice = matchingAccessory?.discountAmount;
    const accessoryQuantity = matchingAccessory?.quantity || 1;
    const total =
      (accessoryDiscountPrice === "" || !accessoryDiscountPrice
        ? parseFloat(productPrice)
        : parseFloat(accessoryPrice - accessoryDiscountPrice)) * accessoryQuantity;
    return parseFloat(total);
  };

  const updateSubtotal = () => {
    const accessoryTotals = orderData?.smartAccessories?.map((item) => {
      const matchingAccessory = products?.find(
        (val) => val?._id === item.productId
      );
      const accessoryQuantity = item?.quantity || 1;
      if (matchingAccessory?.hasVariations && item?.variationId) {
        const matchingVariation = matchingAccessory?.variations?.find(
          (variation) => variation?._id === item.variationId
        );
        const variationPrice = matchingAccessory?.isOnSale
          ? matchingVariation?.saleprice
          : matchingVariation?.price;
        const price = isNaN(parseFloat(item?.price))
          ? parseFloat(variationPrice)
          : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        return total;
      } else {
        const productPrice = matchingAccessory?.isOnSale
          ? matchingAccessory?.saleprice
          : matchingAccessory?.price;
        const price = isNaN(parseFloat(item?.price))
          ? parseFloat(productPrice)
          : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        return total;
      }
    });
    const newSubtotal = accessoryTotals?.reduce((acc, total) => acc + total, 0);
    setSubtotal(newSubtotal);
  };

  useEffect(() => {
    updateSubtotal();
  }, [orderData?.smartAccessories]);

  const calculateTotalAddons = () => {
    const addonTotals = orderData?.addaddons?.map((addon) => {
      const matchingAddon = allAddons?.find(
        (item) => item._id === addon.addonId
      );
      const price = addon?.price;
      return price || 0;
    });
    return addonTotals?.reduce((acc, total) => acc + total, 0) || 0;
  };
  const removePlan = () => {
    setSelectedPlan("");
    setSelectedAddons();
    setOrderData((prev) => ({ ...prev, subscription_details: "", addaddons: [], smartAccessories: [] }));
    removeAppliedCoupon();
  };
  const removeAdditionalUsers = (selectedPlanId) => {
    const matchingPlan = plandata?.find((plan) => plan._id === selectedPlanId);
    const addUserCount = 0;
    setOrderData((prev) => ({
      ...prev,
      subscription_details: {
        ...prev.subscription_details,
        perUserDiscountPrice: '',
        total_user: {
          additionalUser: addUserCount,
          baseUser: matchingPlan?.users,
        },
        total_amount:
          prev?.subscription_details?.discountedPrice
            ?
            parseFloat(prev.subscription_details?.discountedPrice)
            :
            isActive
              ? parseFloat(matchingPlan?.yearly_fee)
              : parseFloat(matchingPlan?.monthly_fee),
        recurring_amount: isActive
          ? parseFloat(matchingPlan?.yearlyPrice_perUser * 12) *
          (parseFloat(matchingPlan?.users) + addUserCount)
          : parseFloat(matchingPlan?.monthlyPrice_perUser) *
          (parseFloat(matchingPlan?.users) + addUserCount),
      },
    }));
    removeAppliedCoupon();
  };
  const handleSelectPlan = (selectedPlanId, addUserCount) => {
    setShowPlanModal(false);
    const matchingPlan = plandata?.find((plan) => plan._id === selectedPlanId);
    setSelectedPlan(matchingPlan);
    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear() + 1;
    if (isActive) {
      const currentDate = new Date();
      day = currentDate.getDate();
      month = (currentDate.getMonth() + 1) % 12 || 12;
      year = currentDate.getFullYear() + 1;
    } else {
      const currentDate = new Date();
      day = currentDate.getDate();
      month = (currentDate.getMonth() + 2) % 12 || 12;
      year = currentDate.getFullYear() + 1;
    }
    const formattedDate = `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
      }/${year}`;
    const parts = formattedDate.split("/");
    const isoDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
    setOrderData((prev) => ({
      ...prev,
      subscription_details: {
        ...prev.subscription_details,
        discountedPrice: '',
        perUserDiscountPrice: '',
        planID: matchingPlan?._id,
        plan: matchingPlan?.PublicPlanName,
        billing_cycle: isActive ? "yearly" : "monthly",
        addOnsWithPlan: matchingPlan?.add_ons,
        smartAccessoriesWithPlan: matchingPlan?.smart_accessories,
        total_user: {
          additionalUser: (addUserCount - matchingPlan?.users),
          baseUser: matchingPlan?.users,
        },
        InitialSetupFee: isActive
          ? matchingPlan?.yearly_fee
          : matchingPlan?.monthly_fee,
        total_amount: isActive
          ? parseFloat(matchingPlan?.yearly_fee) +
          parseFloat(matchingPlan?.yearlyPrice_perUser * 12) * (parseFloat(matchingPlan?.users) + (addUserCount - matchingPlan?.users))
          : parseFloat(matchingPlan?.monthly_fee) +
          parseFloat(matchingPlan?.monthlyPrice_perUser) * (parseFloat(matchingPlan?.users) + (addUserCount - matchingPlan?.users)),
        recurring_amount: isActive
          ? parseFloat(matchingPlan?.yearlyPrice_perUser * 12) *
          (parseFloat(matchingPlan?.users) + (addUserCount - matchingPlan?.users))
          : parseFloat(matchingPlan?.monthlyPrice_perUser) *
          (parseFloat(matchingPlan?.users) + (addUserCount - matchingPlan?.users)),
        renewal_date: isoDate,
      },
    }));
  };
  const filterAddonsInSelectedPlan = () => {
    if (!selectedPlan?.add_ons || !orderData?.addaddons) {
      return;
    }
    const selectedAddonIds = selectedPlan.add_ons?.map((addon) => addon._id);
    const filteredAddons = orderData?.addaddons?.filter(
      (addon) => !selectedAddonIds?.includes(addon.addonId)
    );
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      addaddons: filteredAddons,
    }));
  };

  useEffect(() => {
    filterAddonsInSelectedPlan();
  }, [selectedPlan]);

  const submitHandler = async () => {
    const accessoryTotals = orderData?.smartAccessories?.map((item) => {
      const matchingAccessory = products?.find((val) => val?._id === item.productId);
      const accessoryQuantity = parseInt(item?.quantity) || 1;
      if (matchingAccessory?.hasVariations && item?.variationId) {
        const matchingVariation = matchingAccessory?.variations?.find((variation) => variation?._id === item.variationId);
        const variationPrice = matchingAccessory?.isOnSale ? matchingVariation?.saleprice : matchingVariation?.price;
        const price = !parseFloat(item?.price) ? parseFloat(variationPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        const matchingVariationType = matchingVariation?.Type;
        const capitalizedFirstLetter = matchingVariationType ? matchingVariationType.charAt(0).toUpperCase() + matchingVariationType.slice(1) : null;
        if (!item?.price) {
          return {
            ...item,
            price: variationPrice,
            subtotal: total,
            productName: capitalizedFirstLetter
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
            productName: capitalizedFirstLetter
          }
        };
      } else {
        const productPrice = matchingAccessory?.isOnSale ? matchingAccessory?.saleprice : matchingAccessory?.price;
        const price = !parseFloat(item?.price) ? parseFloat(productPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        const matchingVariationType = matchingAccessory?.name;
        const capitalizedFirstLetter = matchingVariationType ? matchingVariationType.charAt(0).toUpperCase() + matchingVariationType.slice(1) : null;
        if (!item?.price) {
          return {
            ...item,
            price: productPrice,
            subtotal: total,
            productName: capitalizedFirstLetter
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
            productName: capitalizedFirstLetter
          }
        };
      }
    });
    let order = {
      ...orderData,
      smartAccessories: accessoryTotals,
    }
    try {
      const totalAmount =
        calculateTotalAddons() +
        (subtotal || 0.0) +
        (orderData?.subscription_details?.total_amount || 0.0) +
        (orderData?.shipping_method?.price || 0.0) -
        (specialDiscount || 0)
      if (!billingAddressData) { ; return; }
      const company_name = companyData?.company_name;
      const billingdata = billingAddressData;
      const shippingData = shippingAddressData;
      const userr = {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.email,
        contact: userData?.contact,
        billing_address: billingdata,
        shipping_address: shippingData,
      };
      if (!stripe || !elements) {
        return;
      }
      const customerData = await createCustomer(userr);
      setcreatedCustomerData(customerData)
      let payload;
      let paymentData;
      let PlanPurchaseresult;
      payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: userData?.first_name + " " + userData?.last_name,
          email: userData?.email,
          address: {
            line1: billingdata?.line1,
            line2: billingdata?.line2,
            city: billingdata?.city,
            state: billingdata?.state,
            postal_code: billingdata?.postal_code,
            country: billingdata?.country,
          },
        },
        metadata: {
          custom_card_name: cardInfo?.cardName
        }
      });
      let perUserDiscountPrice;
      let xPaymentdiscount;
      const discountedPricesMap = {};
      applycoupondata?.productDiscount?.forEach((discount) => {
        if (discount.variations.length > 0) {
          discount.variations.forEach((variation) => {
            discountedPricesMap[variation.variation_id] = variation.variationDiscountedPrice;
          });
        } else {
          discountedPricesMap[discount.product_id] = discount.discountedPrice;
        }
      });
      const firstArrayWithDiscount = order?.smartAccessories?.map((accessory) => {
        const discountedPrice = discountedPricesMap[accessory.variationId || accessory.productId];
        const discountAmount = discountedPrice ? discountedPrice : 0;
        const Productmatch = couponrelatedproducts?.find(secondItem =>
          (secondItem?.variationID !== null && secondItem?.variationID === accessory?.variationId) ||
          (secondItem.variationID === null && secondItem.productID === accessory?.productId)
        );
        return {
          ...accessory,
          discountAmount: applycoupondata?.discountType === "Fix custom discount" ? discountAmount :
            (applycoupondata?.discountType === "Fix discount price" && Productmatch) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && Productmatch) ? (accessory.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      const planMatch = applycoupondata?.planDiscount?.find(item => item.plan_id === selectedPlan?._id);
      if (!isActive) {
        xPaymentdiscount = planMatch?.monthly?.usageLimit || 0;
      } else {
        xPaymentdiscount = planMatch?.yearly?.usageLimit || 0;
      }
      if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
        perUserDiscountPrice = applycoupondata?.discountAmount;
      } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
        perUserDiscountPrice = ((!isActive ? selectedPlan?.monthlyPrice_perUser : selectedPlan?.monthlyPrice_perUser) * applycoupondata?.discountAmount) / 100;
      } else if (applycoupondata?.discountType === "Fix custom discount" && planMatch) {
        if (!isActive) {
          perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
        } else {
          perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
        }
      }
      let couponData = {};
      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }

      const modifiedAddonswithdiscount = order?.addaddons?.map((addonId) => {
        const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId?.addonId);
        const matchingAdd_ons = allAddons?.find((item) => item?._id === addonId?.addonId);
        let addonDiscountPrice;
        if (validAddon?.type === "onetimepayment") {
          addonDiscountPrice = validAddon?.discountedPrice;
        } else {
          const isYearly = isActive;
          addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
        }
        return {
          addonId: addonId?.addonId,
          type: matchingAdd_ons?.paymentType,
          status: 'N/A',
          assignTo: '',
          addonName: matchingAdd_ons?.publicname,
          price: addonId?.price,
          addonDiscountPrice: (applycoupondata?.discountType === "Fix custom discount") ? addonDiscountPrice :
            (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (addonId?.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      let planDetails = {
        type: isActive ? 'yearly' : 'monthly',
        planName: selectedPlan?.planType,
        usersCount: (selectedPlan?.users + (orderData?.subscription_details?.total_user
          ?.additionalUser || 0)),
        planId: selectedPlan?._id,
        price: !isActive ? selectedPlan?.monthlyPrice_perUser : selectedPlan?.yearlyPrice_perUser * 12
      }
      paymentData = {
        amount: isCouponApplied ? (totalAmount - totalDiscountValue) : totalAmount,
        billingAddress: billingdata,
        name: userData?.first_name + " " + userData?.last_name,
        company_name: company_name,
        paymentToken: payload.paymentMethod.id,
        customerID: customerData.customer.id,
        plandata: planDetails,
        userID: "test",
        smartAccessories: firstArrayWithDiscount,
        addOns: modifiedAddonswithdiscount,
        couponData: couponData,
        isCouponApplied,
        initialSetupCharge: isActive
          ? selectedPlan?.yearly_fee
          : selectedPlan?.monthly_fee,
        appliedCouponData: {
          addonDiscount: modifiedAddonswithdiscount,
          planDiscount: { perUserDiscountPrice: perUserDiscountPrice, xPaymentValue: xPaymentdiscount },
          productDiscount: firstArrayWithDiscount
        },
        specialdiscount: specialDiscount,
      };
      try {
        PlanPurchaseresult = await axios.post(
          `${url}/admin/payment/plan-purchase`,
          paymentData,
          config
        );
      } catch (error) {
        return
      }
      const client_secret = PlanPurchaseresult?.data?.client_secret;
      const addonclient_secret = PlanPurchaseresult?.data?.addonClient_secret;
      const subscriptionID = PlanPurchaseresult?.data?.subscriptionID;
      const subscriptionScheduleID = PlanPurchaseresult?.data?.subscriptionScheduleID;
      const endDate = PlanPurchaseresult?.data?.endDate;
      const paymentStatus = PlanPurchaseresult?.data?.status;
      const addonpaymentStatus = PlanPurchaseresult?.data?.addonstatus;
      const subscriptionDetails = PlanPurchaseresult?.data?.subscriptionDetails;
      const sub_shed_itemId = subscriptionDetails.find(element => element.metadata.type === 'plan')?.id || null;

      if (client_secret) {
        let payment_method;
        payment_method = payload.paymentMethod.id

        if (paymentStatus === 'requires_action' || paymentStatus === 'requires_confirmation') {
          const paymentIntent = await stripe.confirmCardPayment(client_secret, {
            payment_method
          });
          if (paymentIntent.error) {
            return "erorr"
          }
        }
        if (addonpaymentStatus === 'requires_confirmation') {
          const paymentIntent = await stripe.confirmCardPayment(addonclient_secret, {
            payment_method
          });
          if (paymentIntent.error) {
            return "erorr"
          }
        }
      }
      if (PlanPurchaseresult.error) {
        setCardErrorMessage(PlanPurchaseresult.error.message);
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none";
          popup.style.display = "none";
        }, 4000);
      } else {
        const withplanproduct = selectedPlan?.smart_accessories.map((data) => {
          return {
            productId: data?._id,
            quantity: 1,
            price: 0,
            discountAmount: 0,
            productName: data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)
          }
        })
        const withplanaddons = selectedPlan?.add_ons?.map((data) => {
          return {
            addonId: data?._id,
            status: "N/A",
            price: 0,
            addonDiscountPrice: 0,
            addonName: data?.publicname
          }
        });
        const combinedArray = [...withplanproduct, ...firstArrayWithDiscount];
        const combinedaddonArray = [...withplanaddons, ...modifiedAddonswithdiscount];
        let cardDetails;
        cardDetails = {
          cardName: cardInfo.cardName,
          cardNumber: payload.paymentMethod.card.last4,
          brand: payload.paymentMethod.card.brand,
          cardExpiryMonth: payload.paymentMethod.card.exp_month,
          cardExpiryYear: payload.paymentMethod.card.exp_year,
        };
        let currentDate = new Date()
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear() + 1;
        const formattedDate = `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
          }/${year}`;
        const parts = formattedDate.split("/");
        const isoDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
        const planData = {
          planID: selectedPlan?._id,
          InitialSetupFee: isActive
            ? selectedPlan?.yearly_fee
            : selectedPlan?.monthly_fee,
          perUser_price: isActive ? selectedPlan?.yearlyPrice_perUser : selectedPlan?.monthlyPrice_perUser,
          perUserDiscountPrice: perUserDiscountPrice,
          smartAccessories: combinedArray,
          addones: combinedaddonArray,
          subscription_id: subscriptionID,
          subscriptionScheduleID: subscriptionScheduleID,
          sub_shed_itemId: sub_shed_itemId,
          total_amount: isCouponApplied ? (totalAmount - totalDiscountValue) : totalAmount,
          plan: selectedPlan?.planType,
          endDate: endDate,
          total_user: [{
            additionalUser: (orderData?.subscription_details?.total_user
              ?.additionalUser), baseUser: selectedPlan?.users
          }],
          billing_cycle: isActive ? "yearly" : "monthly",
          recurring_amount: orderData?.subscription_details?.recurring_amount,
          renewal_date: isoDate,
          taxRate: taxPercentage,
          customer_id: customerData.customer.id,
        };
        const companyID = companyData?._id;
        const userData = { userShippingOrderNote: orderData?.orderNotes, referredby: referrer, referredName: referrerName }
        if (PlanPurchaseresult.data.success === true) {
          const { data } = await axios.post(
            `${url}/admin/checkoutforclient`,
            { userData, billingdata, shippingData, company_name, planData, couponData, id, companyID, totalShipping: (shippingInfo.cost || 0), serviceCode: shippingInfo.serviceCode, cardDetails, oldorderid: order?._id },
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (data.success) {
            popup.style.display = "flex";
            paymentSuccess.style.display = "block";
            setTimeout(() => {
              navigate(`/admin/orders`)
            }, 2000);
          }
        }
      }
    } catch (error) {
      const popup = document.getElementById("popup");
      const paymentFailure = document.getElementById("payment-failure");
      setCardErrorMessage(error?.response?.data?.error);
      popup.style.display = "flex";
      paymentFailure.style.display = "block";
      setTimeout(() => {
        paymentFailure.style.display = "none";
        popup.style.display = "none";
      }, 4000);
    }
  };

  const createCustomer = async (userData) => {
    try {
      const response = await axios.post(
        `${url}/payment/create-customer`,
        { user: userData },
        config
      );
      if (!response.success) {
      }
      return response.data
    } catch (error) {
      return false
    }
  }
  const handleSendInvoice = async () => {
    validateForm()
    validateBillingForm()
    if (!validateBillingForm() || !validateForm()) {
      return;
    }
    const state = State?.getStateByCodeAndCountry(shippingAddressData.state, shippingAddressData.country)?.name
    const apiUrl = `https://api.zippopotam.us/${shippingAddressData.country}/${shippingAddressData.postal_code.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    if (response.ok !== true) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
      return
    } else if (data.places[0].state !== state) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${state}`
      }));
      return
    }
    const Bstate = State?.getStateByCodeAndCountry(billingAddressData.state, billingAddressData.country)?.name
    const BapiUrl = `https://api.zippopotam.us/${billingAddressData.country}/${billingAddressData.postal_code.trim()}`;
    const Bresponse = await fetch(BapiUrl);
    const Bdata = await Bresponse.json();
    if (Bresponse.ok !== true) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
      return
    } else if (Bdata.places[0].state !== Bstate) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${Bstate}`
      }));
      return
    }
    if (!orderData?.addaddons?.length && !orderData?.smartAccessories?.length && !orderData?.subscription_details?.plan) return;
    const accessoryTotals = orderData?.smartAccessories?.map((item) => {
      const matchingAccessory = products?.find((val) => val?._id === item.productId);
      const accessoryQuantity = parseInt(item?.quantity) || 1;
      if (matchingAccessory?.hasVariations && item?.variationId) {
        const matchingVariation = matchingAccessory?.variations?.find((variation) => variation?._id === item.variationId);
        const variationPrice = matchingAccessory?.isOnSale ? matchingVariation?.saleprice : matchingVariation?.price;
        const price = !parseFloat(item?.price) ? parseFloat(variationPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        if (!item?.price) {
          return {
            ...item,
            price: variationPrice,
            subtotal: total,
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
          }
        };
      } else {
        const productPrice = matchingAccessory?.isOnSale ? matchingAccessory?.saleprice : matchingAccessory?.price;
        const price = !parseFloat(item?.price) ? parseFloat(productPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        if (!item?.price) {
          return {
            ...item,
            price: productPrice,
            subtotal: total,
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
          }
        };
      }
    });
    let order = {
      ...orderData,
      smartAccessories: accessoryTotals,
    }
    try {
      const totalAmount =
        calculateTotalAddons() +
        (subtotal || 0.0) +
        (orderData?.subscription_details?.total_amount || 0.0) +
        (orderData?.shipping_method?.price || 0.0) -
        (specialDiscount || 0)
      const billingdata = billingAddressData
      const shippingData = shippingAddressData
      let perUserDiscountPrice;
      let xPaymentdiscount;
      const discountedPricesMap = {};
      applycoupondata?.productDiscount?.forEach((discount) => {
        if (discount.variations.length > 0) {
          discount.variations.forEach((variation) => {
            discountedPricesMap[variation.variation_id] = variation.variationDiscountedPrice;
          });
        } else {
          discountedPricesMap[discount.product_id] = discount.discountedPrice;
        }
      });
      const firstArrayWithDiscount = order?.smartAccessories?.map((accessory) => {
        const discountedPrice = discountedPricesMap[accessory.variationId || accessory.productId];
        const discountAmount = discountedPrice ? discountedPrice : 0;
        const Productmatch = couponrelatedproducts?.find(secondItem =>
          (secondItem?.variationID !== null && secondItem?.variationID === accessory?.variationId) ||
          (secondItem.variationID === null && secondItem.productID === accessory?.productId)
        );
        return {
          ...accessory,
          discountAmount: applycoupondata?.discountType === "Fix custom discount" ? discountAmount :
            (applycoupondata?.discountType === "Fix discount price" && Productmatch) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && Productmatch) ? (accessory.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      const planMatch = applycoupondata?.planDiscount?.find(item => item.plan_id === selectedPlan?._id);
      if (!isActive) {
        xPaymentdiscount = planMatch?.monthly?.usageLimit || 0;
      } else {
        xPaymentdiscount = planMatch?.yearly?.usageLimit || 0;
      }
      if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
        perUserDiscountPrice = applycoupondata?.discountAmount;
      } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
        perUserDiscountPrice = ((!isActive ? selectedPlan?.monthlyPrice_perUser : selectedPlan?.monthlyPrice_perUser) * applycoupondata?.discountAmount) / 100;
      } else if (applycoupondata?.discountType === "Fix custom discount" && planMatch) {
        if (!isActive) {
          perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
        } else {
          perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
        }
      }
      let couponData = {};
      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }
      const modifiedAddonswithdiscount = order?.addaddons?.map((addonId) => {
        const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId?.addonId);
        let addonDiscountPrice;
        if (validAddon?.type === "onetimepayment") {
          addonDiscountPrice = validAddon?.discountedPrice;
        } else {
          const isYearly = isActive;
          addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
        }
        return {
          addonId: addonId?.addonId,
          status: 'N/A',
          assignTo: '',
          price: addonId?.price,
          addonName: addonId?.addonName,
          addonDiscountPrice: (applycoupondata?.discountType === "Fix custom discount") ? addonDiscountPrice :
            (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (addonId?.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      let finalorder = {
        ...orderData,
        addaddons: modifiedAddonswithdiscount,
        smartAccessories: firstArrayWithDiscount,
        subscription_details: {
          ...orderData?.subscription_details,
          perUserDiscountPrice: perUserDiscountPrice
        },
      }
      const { data } = await axios.post(
        `${url}/admin/create-order-and-send-invoice`,
        {
          orderId: orderId ? orderId : null,
          userId: id,
          billingAddress: billingdata,
          shippingAddress: shippingData,
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          email: userData?.email,
          orderData: finalorder,
          totalAmount,
          customerIp,
          dealOwner,
          referrer,
          referrerName,
          "orderedBy": adminuser?.firstName + adminuser?.lastName,
          discount: specialDiscount,
          couponData: couponData,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        setSuccessMessage('Invoice sent successfully.')
        popup.style.display = "flex";
        paymentSuccess.style.display = "block";
        setTimeout(() => {
          paymentSuccess.style.display = "none";
          popup.style.display = "none";
          navigate("/admin/orders");
        }, 4000);
      } else {
        setCardErrorMessage('Failed to send invoice.');
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none";
          popup.style.display = "none";
        }, 4000);
      }
    } catch (error) {
      setCardErrorMessage('Failed to send invoice.');
      popup.style.display = "flex";
      paymentFailure.style.display = "block";
      setTimeout(() => {
        paymentFailure.style.display = "none";
        popup.style.display = "none";
      }, 4000);
    }
  }
  console.log(orderData)
  const handleCreateOrder = async () => {
    validateForm()
    validateBillingForm()
    if (!validateBillingForm() || !validateForm()) {
      return;
    }
    const state = State?.getStateByCodeAndCountry(shippingAddressData.state, shippingAddressData.country)?.name
    const apiUrl = `https://api.zippopotam.us/${shippingAddressData.country}/${shippingAddressData.postal_code.trim()}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    if (response.ok !== true) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
      return
    } else if (data.places[0].state !== state) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${state}`
      }));
      return
    }
    const Bstate = State?.getStateByCodeAndCountry(billingAddressData.state, billingAddressData.country)?.name
    const BapiUrl = `https://api.zippopotam.us/${billingAddressData.country}/${billingAddressData.postal_code.trim()}`;
    const Bresponse = await fetch(BapiUrl);
    const Bdata = await Bresponse.json();
    if (Bresponse.ok !== true) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: "Please enter a valid postal code."
      }));
      return
    } else if (Bdata.places[0].state !== Bstate) {
      setBillingValidationErrors((prevErrors) => ({
        ...prevErrors,
        postal_code: `Please enter a valid postal code for ${Bstate}`
      }));
      return
    }
    if (!orderData?.addaddons?.length && !orderData?.smartAccessories?.length && !orderData?.subscription_details?.plan) return;
    const accessoryTotals = orderData?.smartAccessories?.map((item) => {
      const matchingAccessory = products?.find((val) => val?._id === item.productId);
      const accessoryQuantity = parseInt(item?.quantity) || 1;
      if (matchingAccessory?.hasVariations && item?.variationId) {
        const matchingVariation = matchingAccessory?.variations?.find((variation) => variation?._id === item.variationId);
        const variationPrice = matchingAccessory?.isOnSale ? matchingVariation?.saleprice : matchingVariation?.price;
        const price = !parseFloat(item?.price) ? parseFloat(variationPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        if (!item?.price) {
          return {
            ...item,
            price: variationPrice,
            subtotal: total,
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
          }
        };
      } else {
        const productPrice = matchingAccessory?.isOnSale ? matchingAccessory?.saleprice : matchingAccessory?.price;
        const price = !parseFloat(item?.price) ? parseFloat(productPrice) : parseFloat(item?.price);
        const total = price * accessoryQuantity;
        if (!item?.price) {
          return {
            ...item,
            price: productPrice,
            subtotal: total,
          }
        }
        else {
          return {
            ...item,
            subtotal: total,
          }
        };
      }
    });
    let order = {
      ...orderData,
      smartAccessories: accessoryTotals,
    }
    try {
      const totalAmount =
        calculateTotalAddons() +
        (subtotal || 0.0) +
        (orderData?.subscription_details?.total_amount || 0.0) +
        (orderData?.shipping_method?.price || 0.0) -
        (specialDiscount || 0)
      const billingdata = billingAddressData
      const shippingData = shippingAddressData
      let perUserDiscountPrice;
      let xPaymentdiscount;
      const discountedPricesMap = {};
      applycoupondata?.productDiscount?.forEach((discount) => {
        if (discount.variations.length > 0) {
          discount.variations.forEach((variation) => {
            discountedPricesMap[variation.variation_id] = variation.variationDiscountedPrice;
          });
        } else {
          discountedPricesMap[discount.product_id] = discount.discountedPrice;
        }
      });
      const firstArrayWithDiscount = order?.smartAccessories?.map((accessory) => {
        const discountedPrice = discountedPricesMap[accessory.variationId || accessory.productId];
        const discountAmount = discountedPrice ? discountedPrice : 0;
        const Productmatch = couponrelatedproducts?.find(secondItem =>
          (secondItem?.variationID !== null && secondItem?.variationID === accessory?.variationId) ||
          (secondItem.variationID === null && secondItem.productID === accessory?.productId)
        );
        return {
          ...accessory,
          discountAmount: (applycoupondata?.discountType === "Fix custom discount") ? discountAmount :
            (applycoupondata?.discountType === "Fix discount price" && Productmatch) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && Productmatch) ? (accessory.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      const planMatch = applycoupondata?.planDiscount.find(item => item.plan_id === selectedPlan?._id);
      if (!isActive) {
        xPaymentdiscount = planMatch?.monthly?.usageLimit || 0;
      } else {
        xPaymentdiscount = planMatch?.yearly?.usageLimit || 0;
      }
      if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
        perUserDiscountPrice = applycoupondata?.discountAmount;
      } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
        perUserDiscountPrice = ((!isActive ? selectedPlan?.monthlyPrice_perUser : selectedPlan?.monthlyPrice_perUser) * applycoupondata?.discountAmount) / 100;
      } else if (applycoupondata?.discountType === "Fix custom discount" && planMatch) {
        if (!isActive) {
          perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
        } else {
          perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
        }
      }
      let couponData = {};
      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }
      const modifiedAddonswithdiscount = order?.addaddons?.map((addonId) => {
        const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId?.addonId);
        let addonDiscountPrice;
        if (validAddon?.type === "onetimepayment") {
          addonDiscountPrice = validAddon?.discountedPrice;
        } else {
          const isYearly = isActive;
          addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
        }
        return {
          addonId: addonId?.addonId,
          status: 'N/A',
          assignTo: '',
          price: addonId?.price,
          addonName: addonId?.addonName,
          addonDiscountPrice: (applycoupondata?.discountType === "Fix custom discount") ? addonDiscountPrice :
            (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
              (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (addonId?.price * applycoupondata?.discountAmount) / 100 :
                0,
        };
      });
      const modifiedOrderdata = {
        ...order,
        addaddons: modifiedAddonswithdiscount,
        smartAccessories: firstArrayWithDiscount,
        subscription_details: {
          ...order?.subscription_details,
          perUserDiscountPrice: perUserDiscountPrice
        }
      }
      const { data } = await axios.post(
        `${url}/admin/create-order-without-Payment`,
        {
          orderId: orderId ? orderId : null,
          userId: id,
          billingAddress: billingdata,
          shippingAddress: shippingData,
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          email: userData?.email,
          orderData: modifiedOrderdata,
          totalAmount,
          customerIp,
          dealOwner,
          referrer,
          referrerName,
          "orderedBy": adminuser?.firstName + adminuser?.lastName,
          discount: specialDiscount,
          couponData: couponData
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        setSuccessMessage(!orderId ? "Order created successfully." : "Order updated successfully.")
        popup.style.display = "flex";
        paymentSuccess.style.display = "block";
        setTimeout(() => {
          paymentSuccess.style.display = "none";
          popup.style.display = "none";
          navigate("/admin/orders");
        }, 4000);
      } else {
        setCardErrorMessage('Failed to create order.');
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none";
          popup.style.display = "none";
        }, 4000);
      }
    } catch (error) {
      setCardErrorMessage('Failed to create order.');
      popup.style.display = "flex";
      paymentFailure.style.display = "block";
      setTimeout(() => {
        paymentFailure.style.display = "none";
        popup.style.display = "none";
      }, 4000);
    }
  }

  const hideElements = () => {
    const popup = document.getElementById("popup");
    const paymentFailure = document.getElementById("payment-failure");
    if (paymentFailure) {
      paymentFailure.style.display = "none";
    }
    if (popup) {
      popup.style.display = "none";
    }
  };
  const handleOrderNumberChange = (e) => {
    setValidationMessageOrderNumber('')
    setInputOrderNumber(e.target.value);
  }
  const handleDeleteOrder = async () => {
    try {
      if (inputOrderNumber === '') {
        setValidationMessageOrderNumber("Please enter Order Number.");
        return;
      } else if (parseInt(inputOrderNumber, 10) !== orderData?.orderNumber) {
        setValidationMessageOrderNumber("Please enter correct Order Number.");
        return;
      }
      const response = await axios.post(`${url}/admin/orders/delete`, { orderIds: orderId }, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      setOpenWarningModal(false)
      if (response.data.success) {
        setSuccessMessage('Order deleted successfully.')
        popup.style.display = "flex";
        paymentSuccess.style.display = "block";
        setTimeout(() => {
          paymentSuccess.style.display = "none";
          popup.style.display = "none";
          navigate("/admin/orders");
        }, 4000);
      }
      setValidationMessageOrderNumber('')
      setInputOrderNumber('');
    } catch (error) {
    }
  }

  return (
    <>
      <div className="">
        <div className="w-100  d-flex flex-row-reverse gap-3 mb-4">
          <div class="dropdown">
            <button
              class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ background: "#000", color: "#fff" }}
            >
              <span className="me-5">Actions</span>
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{ left: "unset" }}
            >
              <a
                class="dropdown-item"
                href="#"
                onClick={() => handleWarningOpenModal()}
              >
                Delete
              </a>

            </div>
          </div>
          {!orderId ?
            <button
              className="btn px-3 py-2 text-white"
              style={{
                background: "#e65925",
                width: "15%",
              }}
              onClick={() => handleSendInvoice()}
            >
              Send invoice
            </button>
            :
            <button
              className="btn px-3 py-2  text-white"
              style={{ background: "#e65925", width: "15%" }}
              onClick={handleSendInvoice}
            >
              Update order
            </button>
          }
          {!orderId &&
            <button
              className="btn px-3 py-2  text-white"
              style={{ background: "#48AF25", width: "15%" }}
              onClick={handleCreateOrder}
            >
              Create order
            </button>
          }
        </div>
        <div className="row">
          <div className="col">
            <div className="bg-white p-4" style={{ borderRadius: "8px" }}>
              <div className="order-ful-header-component">
                <span>Orders #</span>
                <span className="pc-category-title-text">Status</span>
                <span className="pc-category-title-text">Date</span>
                <span className="pc-category-title-text">Total</span>
                <span className="pc-category-title-text">Fulfillment</span>
              </div>
              <div className="order-ful-header-component order-ful-header-component-values">
                <span>{orderData?.orderNumber || '-'}</span>
                <span className="pc-category-title-text">-</span>
                <span className="pc-category-title-text">-</span>
                <span className="pc-category-title-text">-</span>
                <span className="pc-category-title-text">-</span>
              </div>
            </div>

            <div className="d-flex align-items-center gap-3">
              <div
                className="bg-white p-4 mt-3 w-50"
                style={{ borderRadius: "8px", alignSelf: 'stretch' }}
              >
                <h5 className="pb-2 border-bottom">Billing Address</h5>
                <div className="">
                  <div className="shipping-all-section">
                    <label className="first-last-name">First name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={billingAddressData?.first_name}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors?.first_name && (
                      <div className="error-message">
                        {billingValidationErrors.first_name}
                      </div>
                    )}
                  </div>
                  <div className="shipping-all-section">
                    <label className="first-last-name">Last name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={billingAddressData?.last_name}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors.last_name && (
                      <div className="error-message">
                        {billingValidationErrors.last_name}
                      </div>
                    )}
                  </div>

                  <div className="shipping-Company  shipping-all-section">
                    <label className="all-lable-shipping">Company name</label>
                    <input
                      type="text"
                      name="company_name"
                      value={billingAddressData?.company_name}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors?.company_name && (
                      <div className="error-message">
                        {billingValidationErrors.company_name}
                      </div>
                    )}
                  </div>
                  <div className="shipping-Country shipping-all-section">
                    <label className="all-lable-shipping">Country</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.country"
                      options={Country.getAllCountries()?.map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                      value={selectedBillingCountry}
                      onChange={handleBillingCountryChange}
                    />
                    {billingValidationErrors.country && (
                      <div className="error-message">
                        {billingValidationErrors.country}
                      </div>
                    )}
                  </div>

                  <div className="shipping-address shipping-all-section">
                    <label className="all-lable-shipping">Address</label>
                    <input
                      type="text"
                      name="line1"
                      value={billingAddressData?.line1}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors?.line1 && (
                      <div className="error-message">
                        {billingValidationErrors.line1}
                      </div>
                    )}
                  </div>

                  <div className="shipping-apt shipping-all-section">
                    <label className="all-lable-shipping">
                      Apt, suite, etc
                    </label>
                    <input
                      type="text"
                      name="line2"
                      value={billingAddressData?.line2}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                  </div>

                  <div className="shipping-city shipping-all-section">
                    <label className="all-lable-shipping">City</label>
                    <input
                      type="text"
                      name="city"
                      value={billingAddressData?.city}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors?.city && (
                      <div className="error-message">
                        {billingValidationErrors.city}
                      </div>
                    )}
                  </div>
                  <div className="shipping-state shipping-all-section">
                    <label className="all-lable-shipping">State</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.state"
                      options={stateOptions}
                      value={selectedBillingState}
                      menuPlacement="top"
                      onChange={handleBillingStateChange}
                    />
                    {billingValidationErrors?.state && (
                      <div className="error-message">
                        {billingValidationErrors.state}
                      </div>
                    )}
                  </div>
                  <div className="shipping-postal shipping-all-section">
                    <label className="all-lable-shipping">Postal code</label>
                    <input
                      type="text"
                      name="postal_code"
                      value={billingAddressData?.postal_code}
                      onChange={handleBillingInputChange}
                      className="shipping-all-input"
                    />
                    {billingValidationErrors?.postal_code && (
                      <div className="error-message">
                        {billingValidationErrors?.postal_code}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="bg-white p-4 mt-3 w-50"
                style={{ borderRadius: "8px" }}
              >
                <div className="d-flex justify-content-between pb-2 border-bottom">
                  <h5 className="">Shipping Address</h5>
                  <span
                    style={{ color: "#E65925" }}
                    role="button"
                    onClick={() => {
                      handleCopyAddress();
                    }}
                  >
                    Copy billing address
                  </span>
                </div>
                <div className="">
                  <div className="shipping-all-section">
                    <label className="first-last-name">First name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={shippingAddressData?.first_name}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors?.first_name && (
                      <div className="error-message">
                        {validationErrors.first_name}
                      </div>
                    )}
                  </div>
                  <div className="shipping-all-section">
                    <label className="first-last-name">Last name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={shippingAddressData?.last_name}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors?.last_name && (
                      <div className="error-message">
                        {validationErrors.last_name}
                      </div>
                    )}
                  </div>
                  <div className="shipping-Company  shipping-all-section">
                    <label className="all-lable-shipping">Company name</label>
                    <input
                      type="text"
                      name="company_name"
                      value={shippingAddressData?.company_name}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors?.company_name && (
                      <div className="error-message">
                        {validationErrors.company_name}
                      </div>
                    )}
                  </div>
                  <div className="shipping-Country shipping-all-section">
                    <label className="all-lable-shipping">Country</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.country"
                      options={Country.getAllCountries()?.map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    />
                    {validationErrors?.country && (
                      <div className="error-message">
                        {validationErrors?.country}
                      </div>
                    )}
                  </div>
                  <div className="shipping-address shipping-all-section">
                    <label className="all-lable-shipping">Address</label>
                    <input
                      type="text"
                      name="line1"
                      value={shippingAddressData?.line1}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors?.line1 && (
                      <div className="error-message">
                        {validationErrors.line1}
                      </div>
                    )}
                  </div>
                  <div className="shipping-apt shipping-all-section">
                    <label className="all-lable-shipping">
                      Apt, suite, etc
                    </label>
                    <input
                      type="text"
                      name="line2"
                      value={shippingAddressData?.line2}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                  </div>
                  <div className="shipping-city shipping-all-section">
                    <label className="all-lable-shipping">City</label>
                    <input
                      type="text"
                      name="city"
                      value={shippingAddressData?.city}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors?.city && (
                      <div className="error-message">
                        {validationErrors?.city}
                      </div>
                    )}
                  </div>
                  <div className="shipping-state shipping-all-section">
                    <label className="all-lable-shipping">State</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                      placeholder="Select"
                      name="address.state"
                      options={stateOptions}
                      value={selectedState}
                      menuPlacement="top"
                      onChange={handleStateChange}
                    />
                    {validationErrors.state && (
                      <div className="error-message">
                        {validationErrors.state}
                      </div>
                    )}
                  </div>
                  <div className="shipping-postal shipping-all-section">
                    <label className="all-lable-shipping">Postal code</label>
                    <input
                      type="text"
                      name="postal_code"
                      value={shippingAddressData?.postal_code}
                      onChange={handleInputChange}
                      className="shipping-all-input"
                    />
                    {validationErrors.postal_code && (
                      <div className="error-message">
                        {validationErrors.postal_code}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white mt-3 p-4" style={{ borderRadius: "8px", alignSelf: 'stretch' }}>
              <div className="d-flex gap-3">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h5 className="">Plan Selection</h5>
                  <button
                    className="btn px-3 py-2 text-white mt-2 mb-0"
                    style={{
                      background: "#e65925",
                      width: "",
                    }}
                    onClick={() => {
                      setShowPlanModal(true);
                      setSelectedAddons();
                      removeAppliedCoupon();
                      setOrderData((prevData) => ({
                        ...prevData,
                        addaddons: [],
                        smartAccessories: []
                      }))
                    }}
                  >
                    Select Plan
                  </button>
                </div>
              </div>
              {selectedPlan && (
                <table class="table mt-4 product_table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-25 border-0"
                        style={{ textAlign: "left" }}
                      >
                        Items
                      </th>
                      <th scope="col" className="border-0">
                        Quantity
                      </th>
                      <th scope="col" className="border-0">
                        Unit price
                      </th>
                      <th scope="col" className="border-0">
                        Discounted price
                      </th>
                      <th scope="col" className="border-0">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-items-center">
                      <td className="w-25" style={{ textAlign: "left" }}>
                        {selectedPlan?.PublicPlanName} -{" "}
                        {isActive ? "Yearly" : "Monthly"}{" "}
                      </td>
                      <td>1</td>
                      <td>
                        {isActive
                          ? (selectedPlan?.yearlyPrice_perUser * 12) * selectedPlan?.users
                          : selectedPlan?.monthlyPrice_perUser * selectedPlan?.users}
                      </td>
                      <td>
                        <span style={{ color: "#E65925" }}>$</span>
                        <input
                          className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                          type="number"
                          style={{ borderRadius: "4px", color: "#E65925" }}
                          name="discounted price"
                          readOnly
                          value={
                            isCouponApplied ? (isActive
                              ? ((selectedPlan?.yearlyPrice_perUser * 12) - (orderData?.subscription_details?.perUserDiscountPrice || 0)) * selectedPlan?.users
                              : (selectedPlan?.monthlyPrice_perUser - (orderData?.subscription_details?.perUserDiscountPrice || 0)) * selectedPlan?.users) : "-"
                          }
                        />
                      </td>
                      <td>
                        $
                        {selectedPlan?.users *
                          ((orderData?.subscription_details?.perUserDiscountPrice !== undefined &&
                            orderData?.subscription_details?.perUserDiscountPrice !== null &&
                            orderData?.subscription_details?.perUserDiscountPrice !== "")
                            ? (isActive
                              ? ((selectedPlan?.yearlyPrice_perUser * 12) - orderData?.subscription_details?.perUserDiscountPrice)
                              : (selectedPlan?.monthlyPrice_perUser - orderData?.subscription_details?.perUserDiscountPrice))
                            : (isActive
                              ? (selectedPlan?.yearlyPrice_perUser * 12)
                              : selectedPlan?.monthlyPrice_perUser))}
                      </td>
                      <svg
                        role="button"
                        onClick={() => removePlan()}
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                          fill="#E65925"
                        />
                      </svg>
                    </tr>
                    {orderData?.subscription_details?.total_user
                      ?.additionalUser > 0 && (
                        <tr className="align-items-center">
                          <td className="w-25" style={{ textAlign: "left" }}>
                            Additional users{" "}
                          </td>
                          <td>
                            {
                              orderData?.subscription_details?.total_user
                                ?.additionalUser
                            }
                          </td>
                          <td>
                            {isActive
                              ? (selectedPlan?.yearlyPrice_perUser * 12)
                              : selectedPlan?.monthlyPrice_perUser}
                          </td>
                          <td>
                            <span style={{ color: "#E65925" }}>$</span>
                            <input
                              className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                              type="number"
                              style={{ borderRadius: "4px", color: "#E65925" }}
                              name="discounted price"
                              value={
                                isCouponApplied ? (isActive
                                  ? ((selectedPlan?.yearlyPrice_perUser * 12) - (orderData?.subscription_details?.perUserDiscountPrice || 0))
                                  : (selectedPlan?.monthlyPrice_perUser - (orderData?.subscription_details?.perUserDiscountPrice || 0))) : "-"
                              }
                              readOnly
                            />
                          </td>
                          <td>
                            $
                            {orderData?.subscription_details?.total_user?.additionalUser *
                              ((orderData?.subscription_details?.perUserDiscountPrice !== undefined &&
                                orderData?.subscription_details?.perUserDiscountPrice !== null &&
                                orderData?.subscription_details?.perUserDiscountPrice !== "")
                                ? (isActive
                                  ? ((selectedPlan?.yearlyPrice_perUser * 12) - orderData?.subscription_details?.perUserDiscountPrice)
                                  : (selectedPlan?.monthlyPrice_perUser - orderData?.subscription_details?.perUserDiscountPrice))
                                : (isActive
                                  ? (selectedPlan?.yearlyPrice_perUser * 12)
                                  : selectedPlan?.monthlyPrice_perUser))}
                          </td>
                          <svg
                            role="button"
                            onClick={() => {
                              removeAdditionalUsers(selectedPlan?._id);
                              setSelectedAddons();
                              setOrderData((prevData) => ({
                                ...prevData,
                                addaddons: [],
                              }))
                            }
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="#E65925"
                            />
                          </svg>
                        </tr>
                      )}
                    <tr>
                      <td
                        colSpan={4}
                        style={{ textAlign: "left", background: "white" }}
                      >
                        Recurring Items Subtotal:
                      </td>
                      <td style={{ background: "white" }}>
                        $

                        {orderData?.subscription_details?.recurring_amount}
                        /
                        {isActive ? "Yearly" : "Monthly"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="bg-white mt-3 p-4" style={{ borderRadius: "8px" }}>
              <div className="d-flex gap-3">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h5>Smart Accessories Selection</h5>
                  {selectedPlan ? <div
                    role="button"
                    className="plan-plus-btn-group"
                    onClick={() => { setOpenProductModal(true); removeAppliedCoupon() }}
                  >
                    <img
                      style={{ marginRight: "12px" }}
                      src={plusIconWithBG}
                      alt=""
                    />
                    <span>Add accessory</span>
                  </div> : <div
                    role="button"
                    className="plan-plus-btn-group"
                    style={{ opacity: '0.4' }}
                  >
                    <img
                      style={{ marginRight: "12px" }}
                      src={plusIconWithBG}
                      alt=""
                    />
                    <span>Add accessory</span>
                  </div>}
                </div>
              </div>
              {(orderData?.smartAccessories?.length > 0 ||
                selectedPlan?.smart_accessories?.length > 0) && (
                  <table class="table mt-4 product_table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-25 border-0"
                          style={{ textAlign: "left" }}
                        >
                          Items
                        </th>
                        <th scope="col" className="border-0">
                          Quantity
                        </th>
                        <th scope="col" className="border-0">
                          Unit price
                        </th>
                        <th scope="col" className="border-0">
                          Discounted price
                        </th>
                        <th scope="col" className="border-0">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPlan?.smart_accessories?.map((product, i) => (
                        <>
                          {product?.hasVariations === false ? (
                            <tr key={i} className="align-items-center">
                              <td className="w-25" style={{ textAlign: "left" }}>
                                {product?.name}
                              </td>
                              <td>
                                <input
                                  className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                  type="number"
                                  style={{
                                    borderRadius: "4px",
                                    color: "#E65925",
                                    backgroundColor: "#f2f2f2",
                                  }}
                                  name="quantity"
                                  readOnly
                                  value={1}
                                />
                              </td>
                              <td>
                                $
                                {product?.isOnSale
                                  ? product?.saleprice
                                  : product?.price}
                              </td>
                              <td>
                                <input
                                  className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                  type="text"
                                  style={{
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: "4px",
                                    color: "#E65925",
                                  }}
                                  name="discount"
                                  readonly
                                  value="$0"
                                />
                              </td>
                              <td>$0</td>
                              <svg
                                role="button"
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                  fill="none"
                                />
                              </svg>
                            </tr>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                      {products
                        ?.filter((product) =>
                          orderData?.smartAccessories?.some(
                            (item) =>
                              item.productId === product._id ||
                              (item.variationId &&
                                item.variationId === product.variations[0]?._id)
                          )
                        )
                        ?.map((product, i) => (
                          <>
                            {product?.hasVariations === false ? (
                              <tr key={i} className="align-items-center">
                                <td
                                  className="w-25"
                                  style={{ textAlign: "left" }}
                                >
                                  {product?.name}
                                </td>
                                <td>
                                  <input
                                    className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                    type="number"
                                    style={{
                                      borderRadius: "4px",
                                      color: "#E65925",
                                    }}
                                    name="quantity"
                                    onChange={(e) => {
                                      const enteredValue = parseInt(e.target.value, 10);
                                      if (e.target.value === '') {
                                        handleQuantityChange(product._id, null, '');
                                      } else if (enteredValue <= 0 || isNaN(enteredValue)) {
                                        handleQuantityChange(product._id, null, 1);
                                      } else {
                                        handleQuantityChange(product._id, null, enteredValue);
                                      };
                                      removeAppliedCoupon();
                                    }}
                                    value={
                                      orderData?.smartAccessories?.find(
                                        (item) =>
                                          item?.productId === product._id &&
                                          item?.variationId === null
                                      )?.quantity
                                    }
                                  />
                                </td>
                                <td>
                                  $
                                  {product?.isOnSale
                                    ? product?.saleprice
                                    : product?.price}
                                </td>
                                <td>
                                  <span style={{ color: "#E65925" }}>$</span>
                                  <input
                                    className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                    type="number"
                                    style={{
                                      borderRadius: "4px",
                                      color: "#E65925",
                                    }}
                                    name="discounted price"
                                    readOnly
                                    value={isCouponApplied &&
                                      ((orderData?.smartAccessories?.find(
                                        (item) =>
                                          item?.productId === product._id &&
                                          item?.variationId === null
                                      )?.price) - (orderData?.smartAccessories?.find(
                                        (item) =>
                                          item?.productId === product._id &&
                                          item?.variationId === null
                                      )?.discountAmount || 0))
                                    }
                                  />
                                </td>
                                <td>
                                  $
                                  {calculateTotal(
                                    product,
                                    null,
                                    product?.isOnSale
                                      ? product?.saleprice
                                      : product?.price
                                  )}
                                </td>
                                <svg
                                  role="button"
                                  onClick={() => handleRemoveProduct(product._id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="40"
                                  height="40"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                    fill="#E65925"
                                  />
                                </svg>
                              </tr>
                            ) : (
                              ""
                            )}
                            {product.hasVariations &&
                              product.variations.length > 0 &&
                              product.variations
                                ?.filter((variation) =>
                                  orderData?.smartAccessories?.some(
                                    (item) => item.variationId === variation._id
                                  )
                                )
                                ?.map((variation, index) => (
                                  <tr
                                    key={index}
                                    className="align-items-center ms-2"
                                  >
                                    <td
                                      className="w-25"
                                      style={{ textAlign: "left" }}
                                    >
                                      {variation?.Type}
                                    </td>
                                    <td>
                                      <input
                                        className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                        type="number"
                                        style={{
                                          borderRadius: "4px",
                                          color: "#E65925",
                                        }}
                                        name="quantity"
                                        onChange={(e) => {
                                          const enteredValue = parseInt(e.target.value, 10);
                                          if (e.target.value === '') {
                                            handleQuantityChange(product._id, variation._id, '');
                                          } else if (enteredValue <= 0 || isNaN(enteredValue)) {
                                            handleQuantityChange(product._id, variation._id, 1);
                                          } else {
                                            handleQuantityChange(product._id, variation._id, enteredValue);
                                          };
                                          removeAppliedCoupon();
                                        }}
                                        value={
                                          orderData.smartAccessories?.find(
                                            (item) =>
                                              item.productId === product._id &&
                                              item.variationId === variation._id
                                          )?.quantity
                                        }
                                      />
                                    </td>
                                    <td>
                                      $
                                      {product?.isOnSale
                                        ? variation?.saleprice
                                        : variation?.price}
                                    </td>
                                    <td>
                                      <span style={{ color: "#E65925" }}>$</span>
                                      <input
                                        className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                        type="number"
                                        style={{
                                          borderRadius: "4px",
                                          color: "#E65925",
                                        }}
                                        name="discount"
                                        readOnly
                                        value={isCouponApplied &&
                                          ((orderData.smartAccessories?.find(
                                            (item) =>
                                              item.productId === product._id &&
                                              item.variationId === variation._id
                                          )?.price) - ((orderData.smartAccessories?.find(
                                            (item) =>
                                              item.productId === product._id &&
                                              item.variationId === variation._id
                                          )?.discountAmount)))
                                        }
                                      />
                                    </td>
                                    <td>
                                      $
                                      {calculateTotal(
                                        product,
                                        variation,
                                        product?.isOnSale
                                          ? variation?.saleprice
                                          : variation?.price
                                      )}
                                    </td>
                                    <svg
                                      role="button"
                                      onClick={() =>
                                        handleRemoveProduct(
                                          product._id,
                                          variation._id
                                        )
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="40"
                                      height="40"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                        fill="#E65925"
                                      />
                                    </svg>
                                  </tr>
                                ))}
                          </>
                        ))}

                      <tr>
                        <td
                          colSpan={4}
                          style={{ textAlign: "left", background: "white" }}
                        >
                          Items Subtotal:
                        </td>
                        <td style={{ background: "white" }}>
                          ${subtotal - (productpriceDiscount || 0) || "0"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>
            <div className="bg-white mt-3 p-4" style={{ borderRadius: "8px" }}>
              <div className="d-flex gap-3">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h5>Add-ons Selection</h5>
                  {selectedPlan ?
                    <div
                      role="button"
                      className="plan-plus-btn-group"
                      onClick={() => { setOpenAddOnModal(true); removeAppliedCoupon() }}
                    >
                      <img
                        style={{ marginRight: "12px" }}
                        src={plusIconWithBG}
                        alt=""
                      />
                      <span>Add add-on</span>
                    </div> : <div
                      role="button"
                      style={{ opacity: '0.4' }}
                      className="plan-plus-btn-group"
                    >
                      <img
                        style={{ marginRight: "12px" }}
                        src={plusIconWithBG}
                        alt=""
                      />
                      <span>Add add-on</span>
                    </div>}
                </div>
              </div>
              {(selectedPlan?.add_ons?.length > 0 ||
                orderData?.addaddons?.length > 0) && (
                  <table class="table mt-4 product_table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-25 border-0"
                          style={{ textAlign: "left" }}
                        >
                          Items
                        </th>
                        <th scope="col" className="border-0">
                          Quantity
                        </th>
                        <th scope="col" className="border-0">
                          Unit price
                        </th>
                        <th scope="col" className="border-0">
                          Discounted price
                        </th>
                        <th scope="col" className="border-0">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPlan?.add_ons?.map((matchingAddon, i) => (
                        <tr key={i} className="align-items-center ms-2">
                          <td className="w-25" style={{ textAlign: "left" }}>
                            {matchingAddon?.publicname}
                          </td>
                          <td>
                            <input
                              className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                              type="text"
                              style={{
                                backgroundColor: "#f2f2f2",
                                borderRadius: "4px",
                                color: "#E65925",
                              }}
                              name="discount"
                              readonly
                              value="1"
                            />
                          </td>
                          <td>
                            {" "}
                            {matchingAddon?.Addonspaymentdata?.price ||
                              (isActive
                                ? `${matchingAddon?.Addonspaymentdata?.yearlyprice}`
                                : `${matchingAddon?.Addonspaymentdata?.monthlyprice}`)}
                          </td>
                          <td>
                            <input
                              className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                              type="text"
                              style={{
                                backgroundColor: "#f2f2f2",
                                borderRadius: "4px",
                                color: "#E65925",
                              }}
                              name="discount"
                              readonly
                              value="$0"
                            />
                          </td>
                          <td>$0</td>
                          <svg
                            role="button"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                              fill="none"
                            />
                          </svg>
                        </tr>
                      ))}

                      {orderData?.addaddons?.length > 0 &&
                        orderData?.addaddons?.map((addon, i) => {
                          const matchingAddon = allAddons?.find(
                            (item) => item._id === addon.addonId
                          );
                          return (
                            <tr key={i} className="align-items-center ms-2">
                              <td className="w-25" style={{ textAlign: "left" }}>
                                {matchingAddon?.publicname}
                              </td>
                              <td>
                                <input
                                  className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                  type="number"
                                  style={{
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: "4px",
                                    color: "#E65925",
                                  }}
                                  name="discount"
                                  value={1}
                                />
                              </td>
                              <td>
                                {" "}
                                {addon?.price}
                              </td>
                              <td>
                                <span style={{ color: "#E65925" }}>$</span>
                                <input
                                  className="w-50 text-center border-0 p-2 ms-1 coupon-number-input"
                                  type="number"
                                  style={{
                                    borderRadius: "4px",
                                    color: "#E65925",
                                  }}
                                  name="discount"
                                  value={isCouponApplied && (addon?.price - (addon.addonDiscountPrice || 0))}
                                  readOnly
                                />
                              </td>
                              <td>
                                {isCouponApplied ? addon?.price - (addon.addonDiscountPrice || 0) : addon?.price}
                              </td>
                              <svg
                                role="button"
                                onClick={() =>
                                  handleRemoveAddon(matchingAddon._id)
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                  fill="#E65925"
                                />
                              </svg>
                            </tr>
                          );
                        })}
                      <tr>
                        <td
                          colSpan={4}
                          style={{ textAlign: "left", background: "white" }}
                        >
                          Items Subtotal:
                        </td>
                        <td style={{ background: "white" }}>
                          ${calculateTotalAddons() - (addonpriceDiscount || 0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
            </div>
            {(orderData?.smartAccessories?.length > 0 ||
              selectedPlan?.smart_accessories?.length > 0) && <div className="bg-white p-4 mt-3" style={{ borderRadius: "8px" }}>
                <h5 className="pb-2 border-bottom">Shipping Method Selection</h5>
                <div>
                  {(selectedCountry?.value === 'US') && <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="free"
                      name="free"
                      checked={selectedShippingMethod === "free"}
                      onChange={() => handleShippingMethodChange("free")}
                    />
                    <label
                      className="form-check-label ms-2 text-black"
                      htmlFor="free"
                    >
                      Free shipping
                    </label>
                  </div>}
                  {((rates && rates.length > 0 && selectedCountry?.value !== 'US')) && (
                    <div className="option">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedShippingMethod === "2-4"}
                        onChange={() => handleShippingMethodChange("2-4")}
                      />
                      {(!selectedPlan) ? (
                        <label
                          className="form-check-label ms-2 text-black"
                          htmlFor="2-4"
                        >
                          2 to 4 days - $20
                        </label>
                      ) : (
                        (selectedPlan?.planType && selectedCountry?.value !== 'US') && rates?.find(rate => rate.serviceCode === 'usps_first_class_mail_international') ? (
                          <label
                            className="form-check-label ms-2 text-black"
                            htmlFor="overnight"
                          >{rates?.find(rate => rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}</label>
                        ) : <label
                          className="form-check-label ms-2 text-black"
                          htmlFor="overnight"
                        >{rates?.find(rate => rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost} </label>
                      )}
                    </div>
                  )}
                  {(((rates && rates.length > 0) && (selectedCountry?.value === 'US' || selectedCountry?.value === 'CA'))) && (
                    <div className="option">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedShippingMethod === "overnight"}
                        onChange={() => handleShippingMethodChange("overnight")}
                      />
                      {(!selectedPlan) ? (
                        <label
                          className="form-check-label ms-2 text-black"
                          htmlFor="overnight"
                        >
                          Overnight - $60
                        </label>
                      ) : (
                        rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international') ? (
                          <label
                            className="form-check-label ms-2 text-black"
                            htmlFor="overnight"
                          >{rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.shipmentCost} </label>
                        ) : null
                      )}
                    </div>
                  )}
                </div>
              </div>}
            <div className="bg-white p-4 mt-3" style={{ borderRadius: "8px" }}>
              <h5 className="pb-2 border-bottom">Order Summary</h5>
              <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                <span>Items Subtotal:</span>
                <span className="p-2 text-center" style={{ width: "80px" }}>
                  $
                  {(
                    calculateTotalAddons() +
                    (subtotal || 0.0) +
                    (orderData?.subscription_details?.recurring_amount || 0.0)
                  ).toFixed(2)}{" "}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                <span>One Time Setup Fee:</span>
                <span className="p-2 text-center" style={{ width: "80px" }}>
                  ${(orderData?.subscription_details?.InitialSetupFee?.toFixed(2) || '0.00')}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                <span>Fulfillment & Shipping:</span>
                <span className="p-2 text-center" style={{ width: "80px" }}>
                  {calculateShippingCost().cost || 0}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                <span>Tax:</span>
                <span className="p-2 text-center" style={{ width: "80px" }}>
                  {((subtotal - (productpriceDiscount || 0)) * taxPercentage) / 100}
                </span>
              </div>
              <div
                className="d-flex align-items-center justify-content-between p-2 mt-2"
                style={{ background: "#F2F2F2" }}
              >
                <span>Special discount (1 time)</span>
                <input
                  className="text-center border-0 p-2 ms-1 coupon-number-input"
                  type="number"
                  style={{
                    width: "80px",
                    borderRadius: "4px",
                    color: "#E65925",
                  }}
                  name="specialDiscount"
                  onChange={(e) => {
                    const enteredValue = parseFloat(e.target.value);
                    if (e.target.value === '') {
                      setSpecialDiscount('');
                      return;
                    }
                    const customTotal = calculateTotalAddons() +
                      (subtotal || 0.0) +
                      (orderData?.subscription_details?.total_amount || 0.0) +
                      (orderData?.shipping_method?.price || 0.0);
                    if (!isNaN(enteredValue) && enteredValue >= 0 && enteredValue <= customTotal) {
                      setSpecialDiscount(enteredValue);
                    }
                  }}
                  value={specialDiscount}
                />
              </div>
              {!isCouponApplied ?
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                  <span
                    role="button"
                    style={{ color: "#E65925" }}
                    onClick={() => setIsCouponApplied(true)}
                  >
                    Add discount code
                  </span>
                </div>
                :
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                  <span
                    role="button"
                    style={{ color: "#E65925" }}
                    onClick={removeAppliedCoupon}
                  >
                    Remove discount code
                  </span>
                </div>
              }
              {isCouponApplied && (
                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                  <div className="d-flex align-items-center justify-content-between align-items-center gap-2">
                    <span>Discount code:</span>
                    <input
                      className="text-center border-0 p-2"
                      type="text"
                      style={{ background: "#F2F2F2", borderRadius: "4px" }}
                      name="couponCode" onChange={(e) => { couponInputHandler(e) }} value={couponCode[0]}
                    ></input>
                    <button className="mb-0" disabled={applyCouponbtnActive} onClick={couponHandler} style={{ border: 'none' }}>Apply</button>
                  </div>
                  <span style={{ color: "#E65925" }}>-{totalDiscountValue}</span>
                </div>
              )}
              {
                showCouponError.showError &&
                <span className="error" >{showCouponError.error}</span>
              }
              <div
                className="d-flex align-items-center justify-content-between text-white bordered p-2 mt-2"
                style={{ borderRadius: "4px", background: "#000" }}
              >
                <span>Total today:</span>
                <span>
                  {" "}
                  $
                  {calculateTotalAddons() +
                    (subtotal || 0.0) +
                    (orderData?.subscription_details?.InitialSetupFee || 0.0) +
                    (orderData?.subscription_details?.recurring_amount || 0.0) + (calculateShippingCost().cost || 0) + (((subtotal - (productpriceDiscount || 0)) * taxPercentage) / 100) +
                    (orderData?.shipping_method?.price || 0.0) -
                    (totalDiscountValue || 0.0) -
                    (specialDiscount || 0)
                  }
                </span>
              </div>
              <div
                className="d-flex align-items-center justify-content-between text-white bordered p-2 mt-2"
                style={{ borderRadius: "4px", background: "#E65925" }}
              >
                <span>Recurring total:</span>
                <span>
                  $
                  {(
                    (parseFloat(orderData?.subscription_details?.recurring_amount) || 0) +
                    (orderData?.addaddons?.reduce((sum, addOn) => {
                      const matchingAddon = allAddons?.find((item) => item._id === addOn?.addonId);
                      const price = isActive
                        ? parseFloat(matchingAddon?.Addonspaymentdata?.yearlyprice) || 0
                        : parseFloat(matchingAddon?.Addonspaymentdata?.monthlyprice) || 0;
                      return sum + price;
                    }, 0) || 0)
                  ).toFixed(2)}
                  /{isActive ? "Year" : "Month"}
                </span>
              </div>
            </div>
            <div className="bg-white p-4 mt-3" style={{ borderRadius: "8px" }}>
              <h5 className="pb-2 border-bottom">Payment Method</h5>
              <div className="shipping-all-section">
                <label htmlFor="card-name">Name on Card*</label>
                <input
                  className="shipping-all-input"
                  type="text"
                  id="card-name"
                  value={cardInfo.cardName}
                  onChange={handleCardNameChange}
                />
                {paymentErrors.cardName && (
                  <div className="error">{paymentErrors.cardName}</div>
                )}
              </div>
              <div className="shipping-all-section ">
                <label htmlFor="card-number">Card number*</label>
                <CardNumberElement
                  style={{ padding: "10px" }}
                  className="shipping-all-input card-fields"
                  value={1}
                  onChange={(event) =>
                    setCardInfo({
                      ...cardInfo,
                      cardNumber: event.error
                        ? null
                        : event.complete
                          ? event.empty
                          : null,
                    })
                  }
                />
                {paymentErrors.cardNumber && (
                  <div className="error">{paymentErrors.cardNumber}</div>
                )}
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div className="shipping-all-section w-50">
                  <label htmlFor="expiry">Expiration date*</label>
                  <CardExpiryElement
                    className="shipping-all-input card-fields"
                    onChange={(event) =>
                      setCardInfo({
                        ...cardInfo,
                        expiryDate: event.error
                          ? null
                          : event.complete
                            ? event.empty
                            : null,
                      })
                    }
                    value={cardInfo.expiryDate}
                  />
                  {paymentErrors.expiryDate && (
                    <div className="error">{paymentErrors.expiryDate}</div>
                  )}
                </div>
                <div className="shipping-all-section w-50">
                  <label htmlFor="csv">CSV code*</label>
                  <CardCvcElement
                    className="shipping-all-input card-fields"
                    onChange={(event) =>
                      setCardInfo({
                        ...cardInfo,
                        csv: event.error
                          ? null
                          : event.complete
                            ? event.empty
                            : null,
                      })
                    }
                  />
                  {paymentErrors.csv && (
                    <div className="error">{paymentErrors.csv}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center gap-3 mt-2">
              {selectedPlan ? <button
                className="btn px-3 py-2  text-white w-50"
                style={{ background: "#48AF25", width: "15%" }}
                disabled={!allFieldsFilled}
                onClick={() => submitHandler()}
              >
                Process payment
              </button> : <button
                className="btn px-3 py-2  text-white w-50"
                style={{ background: "#48AF25", width: "15%" }}
                disabled
              >
                Process payment
              </button>}
              <button
                className="btn px-3 py-2 text-white w-50"
                style={{
                  background: "#e65925",
                  width: "15%",
                }}
                onClick={() => handleSendInvoice()}
              >
                Send invoice
              </button>
            </div>
          </div>
          <div className="col-4">
            <div className=" mb-2">
              <div
                style={{
                  padding: "20px 25px 25px 25px",
                  borderRadius: "4px",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStyle: "normal",
                      lineHeight: "normal",
                    }}
                  >
                    Client Information
                  </p>
                  <p
                    style={{

                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: userData?.status === 'active' ? "#48AF25" : "red",
                    }}
                  >
                    {userData?.status ? userData.status.charAt(0).toUpperCase() + userData.status.slice(1) : ''}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ height: "100%" }}>
                    <div className="profile-info_OTC_Admin">
                      <div className="user-info-avatar-container_OTC_Admin">
                        {TopAvatar !== "" ? (
                          <img
                            src={TopAvatar}
                            alt="Profile"
                            style={{
                              width: "54px",
                              height: "54px",
                              borderRadius: "100%",
                            }}
                          />
                        ) : (
                          <div
                            className="user-info-initials-overlay_OTC_Admin"
                            style={{ width: "54px", height: "54px" }}
                          >
                            {initials}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="name_john" style={{ fontWeight: "600" }}>
                        {userData?.first_name && userData?.last_name && (
                          <>
                            {userData?.first_name?.charAt(0).toUpperCase() +
                              userData?.first_name?.slice(1)}{" "}
                            {userData?.last_name?.charAt(0).toUpperCase() +
                              userData?.last_name?.slice(1)}
                          </>
                        )}
                      </div>
                      <div className="nick_name" style={{ color: "#e65925" }}>
                        {" "}
                        {companyData?.company_name?.charAt(0).toUpperCase() +
                          companyData?.company_name?.slice(1) || "-"}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "14px" }} className="role">
                  Role :{" "}
                  <span
                    className="nick_nmae"
                    style={{ color: "#e65925", fontWeight: "600" }}
                  >
                    {userData?.role}
                  </span>
                </div>
                <div className="role2">
                  Plan :{" "}
                  <span
                    className="nick_nmae"
                    style={{ color: "#2572E6", fontWeight: "600" }}
                  >
                    {userInforamtionData?.subscription_details?.plan}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <div className="role">Email:</div>
                    <div className="abc" style={{ fontWeight: "600" }}>
                      {userData?.email}
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="22"
                      viewBox="0 0 27 22"
                      fill="none"
                    >
                      <path
                        d="M26.9984 3.00121C26.9984 1.53477 25.7986 0.334961 24.3322 0.334961H3.00219C1.53575 0.334961 0.335938 1.53477 0.335938 3.00121V18.9987C0.335938 20.4651 1.53575 21.665 3.00219 21.665H24.3322C25.7986 21.665 26.9984 20.4651 26.9984 18.9987V3.00121ZM24.3322 3.00121L13.6672 9.66684L3.00219 3.00121H24.3322ZM24.3322 18.9987H3.00219V5.66746L13.6672 12.3331L24.3322 5.66746V18.9987Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <div className="role">Cell phone:</div>
                    <div className="abc" style={{ fontWeight: "600" }}>
                      {userData?.contact}
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="31"
                      viewBox="0 0 21 31"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.33698 3.49962C2.33698 3.19023 2.45989 2.89352 2.67865 2.67475C2.89742 2.45598 3.19414 2.33308 3.50352 2.33308H17.502C17.8114 2.33308 18.1081 2.45598 18.3269 2.67475C18.5456 2.89352 18.6685 3.19023 18.6685 3.49962V26.8304C18.6685 27.1398 18.5456 27.4365 18.3269 27.6553C18.1081 27.874 17.8114 27.9969 17.502 27.9969H3.50352C3.19414 27.9969 2.89742 27.874 2.67865 27.6553C2.45989 27.4365 2.33698 27.1398 2.33698 26.8304V3.49962ZM3.50352 0C2.57537 0 1.68523 0.368709 1.02892 1.02501C0.372615 1.68132 0.00390625 2.57146 0.00390625 3.49962V26.8304C0.00390625 27.7585 0.372615 28.6487 1.02892 29.305C1.68523 29.9613 2.57537 30.33 3.50352 30.33H17.502C18.4301 30.33 19.3203 29.9613 19.9766 29.305C20.6329 28.6487 21.0016 27.7585 21.0016 26.8304V3.49962C21.0016 2.57146 20.6329 1.68132 19.9766 1.02501C19.3203 0.368709 18.4301 0 17.502 0H3.50352ZM7.00314 24.8473C6.78657 24.8473 6.57887 24.9333 6.42573 25.0864C6.27259 25.2396 6.18656 25.4473 6.18656 25.6638C6.18656 25.8804 6.27259 26.0881 6.42573 26.2413C6.57887 26.3944 6.78657 26.4804 7.00314 26.4804H14.0024C14.2189 26.4804 14.4266 26.3944 14.5798 26.2413C14.7329 26.0881 14.8189 25.8804 14.8189 25.6638C14.8189 25.4473 14.7329 25.2396 14.5798 25.0864C14.4266 24.9333 14.2189 24.8473 14.0024 24.8473H7.00314Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <div className="role">Office number :</div>
                    <div className="abc" style={{ fontWeight: "600" }}>
                      {companyData?.contact || "-"}
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                    >
                      <path
                        d="M22 16.6708V20.9435C22.0001 21.2494 21.8842 21.544 21.6757 21.7678C21.4671 21.9916 21.1814 22.128 20.8762 22.1494C20.3482 22.1856 19.9168 22.205 19.5833 22.205C8.90529 22.205 0.25 13.5497 0.25 2.87162C0.25 2.53812 0.268125 2.10675 0.305583 1.57871C0.326998 1.27353 0.463369 0.987844 0.687179 0.779283C0.91099 0.570723 1.20558 0.454818 1.5115 0.454956H5.78417C5.93405 0.454805 6.07863 0.510369 6.18984 0.610856C6.30104 0.711343 6.37093 0.849577 6.38592 0.998706C6.41371 1.27662 6.43908 1.49775 6.46325 1.66571C6.70339 3.34157 7.1955 4.9715 7.92292 6.50025C8.03771 6.74192 7.96279 7.03071 7.74529 7.18537L5.13771 9.04862C6.73205 12.7636 9.69258 15.7241 13.4075 17.3185L15.2684 14.7157C15.3444 14.6094 15.4554 14.5331 15.5819 14.5002C15.7085 14.4673 15.8425 14.4799 15.9607 14.5357C17.4893 15.2618 19.1188 15.7527 20.7941 15.9917C20.962 16.0159 21.1832 16.0412 21.4587 16.069C21.6076 16.0843 21.7455 16.1543 21.8458 16.2655C21.946 16.3767 22.0002 16.5211 22 16.6708Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 mt-2" style={{ borderRadius: "4px" }}>
              <h5 className="border-bottom pb-2">Information</h5>
              <div className="gap-3 d-flex flex-column">
                <div className="mt-2">
                  <span>Deal owner:</span>
                  <input
                    className="w-100 border-0 p-2"
                    type="text"
                    style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                    name="DealOwner"
                    value={dealOwner}
                    onChange={(e) => setDealOwner(e.target.value)}
                  />
                </div>
                <div>
                  <span>Referrer:</span>
                  <select
                    class="form-select py-2 p-2"
                    style={{ backgroundColor: "#f2f2f2" }}
                    name="referrer"
                    value={referrer}
                    onChange={(e) => setReferrer(e.target.value)}
                  >
                    <option selected>
                      select
                    </option>
                    <option value="OTC connect form">
                      OTC connect form
                    </option>
                    <option value="Bing">Bing</option>
                    <option value="Blog post">Blog post</option>
                    <option value="Co-worker">Co-worker</option>
                    <option value="Friend and family">Friend and family</option>
                    <option value="Google">Google</option>
                    <option value="Direct mail">Direct mail</option>
                    <option value="Convention">Convention</option>
                    <option value="Tiktok">Tiktok</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Linkedin">Linkedin</option>
                    <option value="Text Message">Text Message</option>
                    <option value="Direct call">Direct call</option>
                    <option value="Existing OneTapConnect user">
                      Existing OneTapConnect user
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {(referrer === 'Existing OneTapConnect user' || referrer === 'Other') && (
                  <input
                    className="w-100 border-0 p-2"
                    type="text"
                    style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                    name="referrerName"
                    value={referrerName}
                    onChange={(e) => setReferrerName(e.target.value)}
                  />
                )}
                <div>
                  <span>Customer iP:</span>
                  <input
                    className="w-100 border-0 p-2"
                    type="text"
                    style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                    name="customerIp"
                    value={customerIp}
                    onChange={(e) => setCustomerIp(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
              <h5 className="border-bottom pb-2">Order Note</h5>
              <textarea
                className="w-100 border-0 p-2"
                type="text"
                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                name="orderNotes"
                value={orderData?.orderNotes}
                onChange={(e) => {
                  setOrderData((prev) => ({
                    ...prev,
                    orderNotes: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
              <h5 className="border-bottom pb-2">Activity Log</h5>
              <textarea
                className="w-100 border-0 p-2"
                type="text"
                style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                name="activitylog"
                readOnly
              />
            </div>
          </div>
        </div>
      </div >

      <Modal
        open={showPlanModal}
        onClose={handleCloseOfLinkModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div className="Modal-section">
            <h2 className="header" style={{ backgroundColor: "#F2F2F2" }}>
              Plan Selection
              <CloseIcon className="icon" onClick={handleCloseOfLinkModal} />
            </h2>
            <div className={`sliding-switch ${isActive ? "active" : ""}`}>
              <div className="switch-handle"></div>
              <div className="switch-text">
                <span
                  className={`switch-text-option ${isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Pay Monthly
                </span>
                <span
                  className={`switch-text-option ${!isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Yearly
                </span>
              </div>
            </div>
            {isActive ? (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata?.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "monthly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return (
                          ""
                        );
                      }
                      if (plans.planType === "Professional") {
                        return (
                          <YearlyCard
                            props={{ ...plans, isOtcAdminPanel: true }}
                            onSelectPlan={handleSelectPlan}
                            key={i}
                            cycle={isActive}
                          />
                        );
                      }
                      if (plans.planType === "Team") {
                        return (
                          <YearlyCard
                            props={{ ...plans, isOtcAdminPanel: true }}
                            onSelectPlan={handleSelectPlan}
                            key={i}
                            cycle={isActive}
                          />
                        );
                      }
                      if (plans.planType === "Enterprise") {
                        return (
                          ""
                        );
                      }
                    })}
                </div>
              </>
            ) : (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata?.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "yearly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return (
                          ""
                        );
                      }
                      if (plans.planType === "Professional") {
                        return (
                          <PlanCard
                            props={{ ...plans, isOtcAdminPanel: true }}
                            onSelectPlan={handleSelectPlan}
                            key={i}
                            cycle={isActive}
                          />
                        );
                      }
                      if (plans.planType === "Team") {
                        return (
                          <PlanCard
                            props={{ ...plans, isOtcAdminPanel: true }}
                            onSelectPlan={handleSelectPlan}
                            key={i}
                            cycle={isActive}
                          />
                        );
                      }
                      if (plans.planType === "Enterprise") {
                        return (
                          ""
                        );
                      }
                    })}
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={openProductModal}
        onClose={() => setOpenProductModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Modal-section">
            <h2 className="header"> Smart Accessories </h2>
            <table class="table mt-4 product_table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-25 border-0"
                    style={{ textAlign: "left" }}
                  ></th>
                  <th scope="col" className="border-0">
                    category
                  </th>
                  <th scope="col" className="border-0">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.map((product, i) => {
                  if (
                    product?.status !== "Published" ||
                    product?.visibility !== "Public"
                  )
                    return;
                  const isSelected = selectedProducts?.some(
                    (item) =>
                      item.productId === product._id &&
                      item.variationId === null
                  );

                  const rowStyle = {
                    backgroundColor: isSelected ? "#fdeee9" : "",
                    borderTop: isSelected ? "1px solid #e65925" : "none",
                    borderBottom: isSelected ? "1px solid #e65925" : "none",
                    cursor: 'pointer'
                  };
                  return (
                    <>
                      {product?.hasVariations === false ? (
                        <tr
                          key={i}
                          style={rowStyle}
                          onClick={() => toggleProductSelection(product._id)}
                        >
                          <td
                            className="w-50"
                            style={{
                              ...rowStyle,
                              borderLeft: isSelected
                                ? "1px solid #e65925"
                                : "none",
                              textAlign: "left",
                            }}
                          >
                            {product?.name}
                          </td>
                          <td style={rowStyle}>
                            <span>{product?.category?.name}</span>
                          </td>
                          <td
                            style={{
                              ...rowStyle,
                              borderRight: isSelected
                                ? "1px solid #e65925"
                                : "none",
                            }}
                            className={`status-cell ${getStatusColorClass(
                              product?.status
                            )}`}
                          >
                            {product?.status}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {product?.hasVariations &&
                        product.variations.length > 0 &&
                        product.variations?.map((variation, index) => {
                          const isSelected = selectedProducts?.some(
                            (item) =>
                              item.productId === product._id &&
                              item.variationId === variation._id
                          );
                          const rowStyle = {
                            backgroundColor: isSelected ? "#fdeee9" : "",
                            borderTop: isSelected
                              ? "1px solid #e65925"
                              : "none",
                            borderBottom: isSelected
                              ? "1px solid #e65925"
                              : "none",
                            cursor: 'pointer'
                          };
                          return (
                            <tr
                              key={i}
                              style={rowStyle}
                              onClick={() =>
                                toggleProductSelection(
                                  product._id,
                                  variation._id
                                )
                              }
                            >
                              <td
                                className="w-50"
                                style={{
                                  ...rowStyle,
                                  borderLeft: isSelected
                                    ? "1px solid #e65925"
                                    : "none",
                                  textAlign: "left",
                                }}
                              >
                                {variation?.Type}
                              </td>
                              <td style={rowStyle}>
                                <span>{product?.category?.name}</span>
                              </td>
                              <td
                                style={{
                                  ...rowStyle,
                                  borderRight: isSelected
                                    ? "1px solid #e65925"
                                    : "none",
                                }}
                                className={`status-cell ${getStatusColorClass(
                                  product?.status
                                )}`}
                              >
                                {product?.status}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="Modal-btn-group justify-content-end">
              <button
                className="Modal-btn plan-cancel-modal-btn w-25"
                onClick={handleCancelProductsClick}
              >
                Cancel
              </button>
              <button
                className="Modal-btn first-modal-btn w-25"
                onClick={handleUpdateProductsClick}
              >
                Add
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openAddOnModal}
        onClose={() => setOpenAddOnModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Modal-section">
            <h2 className="header"> Add-ons</h2>
            <table class="table mt-4 product_table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-25 border-0"
                    style={{ textAlign: "left" }}
                  ></th>
                  <th scope="col" className="border-0">
                    price
                  </th>
                  <th scope="col" className="border-0">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {allAddons?.map((addOn, i) => {
                  let price;
                  let typeText = "";
                  if (addOn.paymentType === "onetimepayment") {
                    price = addOn?.Addonspaymentdata?.price;
                    typeText = "(One time)";
                  } else {
                    const isYearly = isActive;
                    const isUserBased = addOn?.Addonspaymentdata?.isuserbased;
                    const userPrice = isYearly ? addOn?.Addonspaymentdata?.yearlyuserprice : addOn?.Addonspaymentdata?.monthlyuserprice;
                    const basePrice = isYearly ? addOn?.Addonspaymentdata?.yearlyprice : addOn?.Addonspaymentdata?.monthlyprice;
                    const totalUser = (selectedPlan?.users + orderData?.subscription_details?.total_user
                      ?.additionalUser)
                    if (isUserBased && totalUser >= addOn?.Addonspaymentdata?.numofuser) {
                      price = basePrice + ((totalUser - addOn?.Addonspaymentdata?.numofuser) * userPrice);
                    } else {
                      price = basePrice;
                    }
                    typeText = isYearly ? "/year" : "/month"
                  }
                  const isAlreadySelected = selectedPlan?.add_ons?.some(
                    (selectedAddon) => selectedAddon._id === addOn._id
                  );
                  if (
                    addOn?.status !== "Published" ||
                    addOn?.visibility !== "Public" ||
                    isAlreadySelected
                  )
                    return;
                  const isSelected = selectedAddons?.includes(addOn._id);
                  const rowStyle = {
                    backgroundColor: isSelected ? "#fdeee9" : "",
                    borderTop: isSelected ? "1px solid #e65925" : "none",
                    borderBottom: isSelected ? "1px solid #e65925" : "none",
                    cursor: 'pointer'
                  };
                  return (
                    <tr
                      key={i}
                      style={rowStyle}
                      onClick={() => toggleAddonSelection(addOn._id)}
                    >
                      <td
                        className="w-50"
                        style={{
                          ...rowStyle,
                          borderLeft: isSelected ? "1px solid #e65925" : "none",
                          textAlign: "left",
                        }}
                      >
                        {addOn?.publicname}
                      </td>
                      <td style={rowStyle}>
                        {price}{typeText}
                      </td>
                      <td
                        style={{
                          ...rowStyle,
                          borderRight: isSelected
                            ? "1px solid #e65925"
                            : "none",
                        }}
                        className={`status-cell ${getStatusColorClass(
                          addOn?.status
                        )}`}
                      >
                        {addOn?.status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="Modal-btn-group justify-content-end">
              <button
                className="Modal-btn plan-cancel-modal-btn w-25"
                onClick={handleCancelAddonsClick}
              >
                Cancel
              </button>
              <button
                className="Modal-btn first-modal-btn w-25"
                onClick={handleUpdateAddonsClick}
              >
                Add
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <div id="popup" className="popup">
        <div class="popup-content" id="popup-content">
          <div id="payment-success" className="payment-success">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M58.1796 123C55.4032 123 52.6267 121.956 50.4972 119.842L9.17404 78.8107C4.94199 74.6086 4.94199 67.7834 9.17404 63.5813C13.4061 59.3792 20.2798 59.3792 24.5119 63.5813L58.1796 97.011L125.488 30.1516C129.72 25.9495 136.594 25.9495 140.826 30.1516C145.058 34.3537 145.058 41.1789 140.826 45.381L65.835 119.842C63.7325 121.929 60.9561 123 58.1796 123Z"
                fill="green"
              />
            </svg>
            <p style={{ fontSize: "20px" }}>{successMessage}</p>
          </div>
          <div id="payment-failure" className="payment-failure">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.5938 112.453L112.397 37.5371M112.397 112.453L37.5938 37.5371"
                stroke="red"
                stroke-width="20"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p style={{ fontSize: "20px" }}>{cardErrorMessage}</p>
            <button
              className="onetap_conn_move_dashboard_button"
              onClick={hideElements}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={openWarningModal}
        onClose={handleCloseWarningdModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={warningModalStyle}>
          <div className="video-Modal-section">
            <h2 className="warning-header">
              Delete Confirmation
            </h2>
            <div style={{ marginTop: "10px" }}>
              <span className="warning-text-order-overview mb-2">Are you sure want to delete this order? You cannot undo this action.</span>
            </div>
            <div style={{ marginTop: "10px", marginBottom: "5px", fontSize: "14px" }}>

              <span >Confirm the number of orders you want to delete by entering it here.</span>
            </div>
            <input className="w-100 border-0 p-2" placeholder='' type="Number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='InternalPlanName' value={inputOrderNumber}
              onChange={handleOrderNumberChange} />
            {validationMessageOrderNumber && (
              <div className="error-message">{validationMessageOrderNumber}</div>
            )}
            <div className="video-Modal-btn-group mt-4" >
              <button className="video-Modal-btn video-first-modal-btn" onClick={() => { handleDeleteOrder() }}>Delete</button>
              <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}