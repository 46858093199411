import React, { useEffect, useState } from 'react'
import GenInfoToggleButton from '../../components/Customers/CompanyProfile/GenInfoToggleButton'
import selectDropdownIcon from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
import { useParams } from 'react-router-dom'
import "./UserProfile.css"
import { useDispatch } from 'react-redux'
import axios from 'axios'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Loader from '../../components/Layout/Loader/Loader';
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { updateAdminUserInformation } from '../Redux/Otc_UserActions'
const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
};
const messageIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
>
    <path
        d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
        fill="#E65925"
    />
    <path
        d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
        stroke="white"
        stroke-width="6.64"
        stroke-linecap="round"
        class="checkmark-path"
    />
</svg>

const UserProfileSetting = () => {
    const frontendURL = window.location.origin;
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [defaultConnectMessage, setDefaultConnectMessage] = useState(
        "Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon!"
    );
    const [userAllData, setUserAllData] = useState("")
    const [userData, setUserData] = useState("")
    const [companyData, setCompanyData] = useState("")
    const [teamData, setTeamData] = useState("")
    const [slugData, setSlugData] = useState(null);
    const [defaultCrmLink, setDefaultCrmLink] = useState("");
    const [connectButtonBehaviorPermission, setConnectButtonBehaviorPermission] = useState(false);
    const [allowUserEditBehaviorPermission, setAllowUserEditBehaviorPermission] = useState(false);
    const [defaultConnectMessagePermission, setDefaultConnectMessagePermission] = useState(false);
    const [defaultCrmLinkPermission, setDefaultCrmLinkPermission] = useState(false);
    const [companyShareReferData, setCompanyShareReferData] = useState("")
    const [allowUserEditToggle, setAllowUserEditToggle] = useState('');
    const [connectMessageInput, setConnectMessageInput] = useState(defaultConnectMessage);
    const [crmLinkInput, setCrmLinkInput] = useState(defaultCrmLink);
    const [connectButtonToggle, setConnectButtonToggle] = useState(connectButtonBehaviorPermission);
    const [companySlug, setCompanySlug] = useState('')
    const [apiSuccess, setApiSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    useEffect(() => {
        setConnectMessageInput(defaultConnectMessage)
        setCrmLinkInput(defaultCrmLink)
        setConnectButtonToggle(connectButtonBehaviorPermission)
        setAllowUserEditToggle(allowUserEditBehaviorPermission)
        setCompanySlug(companyData?.companyurlslug)
    }, [defaultConnectMessage, companySlug, defaultCrmLink, connectButtonBehaviorPermission, allowUserEditBehaviorPermission])

    const handleConnectMessageInput = (e) => {
        setConnectMessageInput(e.target.value);
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            default_connect_msg: {
                ...prevDetails.default_connect_msg,
                value: e.target.value,
            },
        }));
    };

    const handleCrmLinkInput = (e) => {
        setCrmLinkInput(e.target.value);
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            default_crm_link: {
                ...prevDetails.default_crm_link,
                value: e.target.value,
            },
        }));
    };

    useEffect(() => {
        const fetchSlugData = async () => {
            try {
                const response = await axios.get(`${url}/userslugs/${id}`, {
                    withCredentials: true,
                });
                setSlugData(response.data.users_slug);
            } catch (error) {
                ;
            }
        };
        const fetchShareReferData = async () => {
            try {
                const response = await axios.get(`${url}/admin/company_share_referreldata/${id}`, {
                    withCredentials: true,
                });
                setCompanyShareReferData(response.data.companyShareReferData);
            } catch (error) {
                ;
            }
        };
        fetchShareReferData();
        fetchSlugData();
    }, []);
    const fetchUser = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            setUserAllData(data?.user[0])
            setUserData(data?.user[0]?.user_id)
            setCompanyData(data.user[0].company_ID)
            setTeamData(data.allteams)
            setSelectedTeam(data.userTeamData[0]?.team?.team_name)
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchUser();
    }, []);
    const [selectedTeam, setSelectedTeam] = useState("");
    useEffect(() => {
        if (userAllData?.subscription_details?.plan === "Team" || userData?.role === "teammember") {
            if (!companyShareReferData) {
            } else {
                setDefaultConnectMessage(
                    companyShareReferData?.default_connect_msg.value
                );
                setDefaultConnectMessagePermission(
                    companyShareReferData?.default_connect_msg.permission
                );
                setDefaultCrmLink(companyShareReferData?.default_crm_link.value);
                setDefaultCrmLinkPermission(
                    companyShareReferData?.default_crm_link.permission
                );
                setConnectButtonBehaviorPermission(
                    companyShareReferData?.connect_button_behaviour
                );
            }
        } else {
            setDefaultConnectMessage(
                userAllData?.default_connect_msg?.value
            );
            setDefaultConnectMessagePermission(
                userAllData?.default_connect_msg?.permission
            );
            setDefaultCrmLink(userAllData?.default_crm_link?.value);
            setDefaultCrmLinkPermission(
                userAllData?.default_crm_link?.permission
            );
            setConnectButtonBehaviorPermission(
                userAllData?.connect_button_behaviour
            );
            setAllowUserEditBehaviorPermission(userAllData?.userProfile_edit_permission)
        }
    }, [companyShareReferData, userAllData, userData]);

    const handleTeamChange = (selectedTeamName) => {
        setSelectedTeam(selectedTeamName)
        const selectedTeam = teamData?.find((team) => team.team_name === selectedTeamName);
        const selectedTeamId = selectedTeam ? selectedTeam._id : null;
        setUserData((prevDetails) => ({
            ...prevDetails,
            team: selectedTeamId,
        }));
    };

    const handleAllowUserEDitToggle = () => {
        setAllowUserEditToggle(!allowUserEditToggle);
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            userProfile_edit_permission: !allowUserEditToggle,
        }));
    };

    const handleToggleConnectButton = () => {
        setConnectButtonToggle(!connectButtonToggle);
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            connect_button_behaviour: !connectButtonToggle,
        }));
    };

    const handleFieldClick = (value, setter, placeholder) => {
        const inputField = document.querySelector('.dyminput');
        const start = inputField.selectionStart;
        const end = inputField.selectionEnd;
        const currentValue = value || "";
        const updatedText =
            currentValue.slice(0, start) + placeholder + currentValue.slice(end);
        setter(updatedText);
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            default_connect_msg: {
                ...prevDetails.default_connect_msg,
                value: updatedText,
            },
        }));
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            default_connect_msg: {
                ...prevDetails.default_connect_msg,
                value: updatedText,
            },
        }));
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#E65925",
            color: "#fff",
            fontSize: 13,
            maxWidth: "none",
            padding: "10px",
        },
    }));
    const disableicon = (
        <span>
            Not editable. Your company has locked this field, and it is displayed for
            informational purposes.
        </span>
    );

    const handleSave = async () => {
        const res = await axios.post(
            `${url}/admin/user/update/${id}`,
            { userDetails: userData },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );
        fetchUser();
        dispatch(updateAdminUserInformation(id, userAllData))
        setApiSuccess(true)
        setSuccessMessage('Data Updated Successfully.')
        setTimeout(() => {
            setApiSuccess(false)
        }, 5000);
    }

    return (
        <>
            {!userAllData ? (<Loader />) : (<>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <div className="col">
                    <div className="onetap_conn_general_information_syn row">
                        <h4 className="onetap_conn_general_information_heading_syn">
                            User profile settings
                        </h4>
                        <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                        <div className='d-flex justify-content-between mt-4'>
                            <h5>Allow this users to edit their profile contact information.</h5>
                            <div style={{ display: "inline-block" }}>
                                <GenInfoToggleButton
                                    isOn={allowUserEditToggle}
                                    onToggle={handleAllowUserEDitToggle}
                                />
                            </div>
                        </div>
                        <p className="onetap_conn_general_information_para_syn">
                            If disabled, user won’t be allowed to edit their profile contact information giving you full control of the information displayed on their digital card.
                        </p>
                        <hr style={{ height: "px" }} />
                    </div>
                    <div className="row" style={{ gap: '10px' }}>
                        <div className="col-4 p-0">
                            {(userAllData?.subscription_details?.plan === "Team" || userData?.role === 'teammember') && (<div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                <h6 className="border-bottom pb-2 pc-Product information-text userSetting-com-text-header">Team</h6>
                                <div className="gap-3 d-flex flex-column">
                                    <div style={{ position: "relative" }} className=''>
                                        <span>Team:</span>
                                        <div>
                                            <select
                                                name="team"
                                                className="pc-status-dropdown"
                                                style={{ backgroundColor: "#f2f2f2" }}
                                                onChange={(e) => handleTeamChange(e.target.value)}
                                                value={selectedTeam}
                                            >
                                                {teamData && Array?.from(new Set(teamData?.map((team) => team.team_name)))?.map((teamName) => (
                                                    <option key={teamName} value={teamName}>
                                                        {teamName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <img className="add-pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                    </div>
                                </div>
                            </div>)}
                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                <h6 className="border-bottom pb-2  userSetting-com-text-header">Permalink</h6>
                                <div className="">
                                    <p className="setting_card_url_text">Card URL</p>
                                    <p className="setting_card_url_text2">The card URL is what visitors will see in their browser. You may personalize it.</p>
                                    {userAllData?.subscription_details?.plan === 'Team' || userData?.role === 'teammember' ? (<p className="user_setting_card_url_text" style={{ fontFamily: 'Assistant' }}>
                                        {frontendURL}/{companyData?.companyurlslug}/<span style={{ fontFamily: 'Assistant' }}>{userData?.userurlslug}</span>
                                    </p>) : (<p className="user_setting_card_url_text" style={{ fontFamily: 'Assistant' }}>
                                        {frontendURL}/<span style={{ fontFamily: 'Assistant' }}>{userData?.userurlslug}</span>
                                    </p>)}
                                </div>
                                <div className="mt-4">
                                    <p className="setting_card_url_text userSetting-com-text-header">Short URL</p>
                                    <p className="setting_card_url_text2">The short URL is a default link to the card and redirect to the URL shown above. As the short URL cannot be changed, it is the recommend link to use if you need to place the link in other web page.</p>
                                    <p className="user_setting_card_url_text" style={{ fontFamily: 'Assistant' }}>
                                        {frontendURL}/{slugData?.companyunique_slug[0]?.value}/{slugData?.unique_slugs[0]?.value}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row mb-2 mt-2">
                                <div className="onetap_bg_box">
                                    <h2 className="onetap_heading_h2">Connect button behavior</h2>
                                    {userAllData?.subscription_details?.plan === "Professional" ? <p className="onetap_ptag">
                                        What should be the default behavior of the Connect button?
                                    </p> : <p className="onetap_ptag">
                                        Control what your team member can personalized on their cards.
                                    </p>}
                                    <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                                    <div
                                        className=""
                                        style={{
                                            display: "inline-flex",
                                            alignItems: "baseline",
                                        }}
                                    >
                                        <h6 className="" style={{ marginRight: "20px", fontWeight: "600", color: "#000" }}>
                                            Text Message
                                        </h6>
                                        <span style={{ cursor: "pointer" }}>
                                            <GenInfoToggleButton
                                                isOn={connectButtonToggle}
                                                onToggle={handleToggleConnectButton}
                                            />
                                        </span>
                                        <h6
                                            style={{
                                                marginLeft: "20px",
                                                fontWeight: "600",
                                                color: "#000",
                                            }}
                                        >
                                            CRM form
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="onetap_bg_box">
                                    {userAllData?.subscription_details?.plan === "Professional" ? <h2 className="onetap_heading_h2">Text message Connect button</h2> : <h2 className="onetap_heading_h2">Connect button</h2>}
                                    <p className="onetap_ptag">
                                        The connect button allows you to connect with your prospect
                                        through text messages.{" "}
                                        <a
                                            href="https://support.onetapconnect.com/docs/how-to-use-the-onetap-connect-button"
                                            style={{ textDecoration: "none" }}
                                        >
                                            {" "}
                                            See video for demo
                                        </a>
                                    </p>
                                    <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                                    <div className="row">
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        </div>
                                        <h2 className="onetap_heading_h2">Default message</h2>
                                        <p className="onetap_ptag">
                                            Personalize what the connect button will display to fit your
                                            style! Use the predefined element to dynamically add information
                                            form your team member.
                                        </p>
                                        <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                                        {defaultConnectMessagePermission === true ? (<input
                                            type="text"
                                            className="lead_input dyminput"
                                            placeholder="Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon! "
                                            value={connectMessageInput}
                                            onInput={handleConnectMessageInput}
                                        />) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (<input
                                            type="text"
                                            className="lead_input dyminput"
                                            placeholder="Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon! "
                                            value={connectMessageInput}
                                            onInput={handleConnectMessageInput}
                                        />) : (
                                            <LightTooltip placement="top" title={disableicon}>
                                                <input
                                                    type="text"
                                                    className="lead_input dyminput"
                                                    placeholder="Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon! "
                                                    value={connectMessageInput}
                                                    readOnly
                                                />
                                            </LightTooltip>
                                        )}
                                        <div className="d-flex align-items-center">
                                        </div>
                                        <div className="d-flex gap-2">
                                            <div
                                                className="lead_dynamic_box"
                                                onClick={() =>
                                                    handleFieldClick(
                                                        defaultConnectMessage,
                                                        setDefaultConnectMessage,
                                                        "{user_name}",
                                                        "default_connect_msg"
                                                    )
                                                }
                                            >
                                                {"{user_name}"}
                                            </div>
                                            {companyData?.company_name ? <div
                                                className="lead_dynamic_box"
                                                onClick={() =>
                                                    handleFieldClick(
                                                        defaultConnectMessage,
                                                        setDefaultConnectMessage,
                                                        "{company_name}",
                                                        "default_connect_msg"
                                                    )
                                                }
                                            >
                                                {"{company_name}"}
                                            </div> : ""}
                                        </div>
                                        <p className="usercustomallowfieldp">
                                            **Drag and drop to add the dynamic field
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="onetap_bg_box">
                                    <h2 className="onetap_heading_h2">Default CRM form link</h2>
                                    <p className="onetap_ptag">
                                        Link a form from your CRM to help you gather leads.{" "}
                                        <a
                                            href="https://support.onetapconnect.com/docs/how-to-use-the-onetap-connect-button"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Learn more
                                        </a>
                                    </p>
                                    <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {defaultCrmLinkPermission === true ? (<input
                                            type="text"
                                            className="lead_input"
                                            placeholder="Ex: https://hubspot.com/myform"
                                            value={crmLinkInput}
                                            onChange={handleCrmLinkInput}
                                        />) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (<input
                                            type="text"
                                            className="lead_input"
                                            placeholder="Ex: https://hubspot.com/myform"
                                            value={crmLinkInput}
                                            onChange={handleCrmLinkInput}
                                        />) : (<LightTooltip placement="top" title={disableicon}>
                                            <input
                                                type="text"
                                                className="lead_input"
                                                placeholder="Ex: https://hubspot.com/myform"
                                                value={crmLinkInput}
                                                onChange={handleCrmLinkInput}
                                                readOnly
                                            />
                                        </LightTooltip>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={handleSave}
                                className="onetap_connect__comp_profile_save_and_cont_button_syn"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    open={apiSuccess}
                    onClose={() => setApiSuccess(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={successModalStyle} style={{ textAlign: "center" }}>
                        <div className="Modal-section">
                            <div className="checkmark-container">
                                <div class="checkmark-container">
                                    {messageIcon}
                                </div>
                            </div>
                            <span className="team_created_success">
                                {successMessage}
                            </span>
                        </div>
                        <button
                            className="Modal-btn sec-modal-btn"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                width: "13%",
                            }}
                            onClick={() => setApiSuccess(false)}
                        >
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                                <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
                            </span>
                        </button>
                    </Box>
                </Modal>
            </>)}
        </>
    )
}

export default UserProfileSetting
