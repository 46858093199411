import React from 'react'

const SmartAccessories = () => {
  return (
    <div>
    </div>
  )
}

export default SmartAccessories
