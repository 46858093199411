import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SingleTeamMemberDetails,
  fetchUserInformationDetails,
  getCompanyProfileDetails,
  updateCompanyDetailsInfo,
  updateTeamMemberDetails,
  getUnique_slug,
  getPlan,
} from "../../../../actions/userAction/userAction";
import Loader from "../../../Layout/Loader/Loader";
import upgradeicon from "../../../../assets/icons/upgradeicon.svg";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Box, Modal, Tooltip, styled, tooltipClasses } from "@mui/material";
import PlanCard, { YearlyCard } from "../../../PlanCard/PlanCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  bgcolor: "#F2F2F2",
  p: 4,
  outline: "none",
  overflowY: "scroll",
  height: "95%",
};

const CardProfileSettingTab = (props) => {
  const dispatch = useDispatch();
  const { company, loading, error, message } = useSelector(
    (state) => state.Company
  );
  const { teamMemberDetails, loading: teammemberloading } = useSelector(
    (state) => state.TeammemberDetails
  );
  const [companyDetails, setCompanydetails] = useState({});
  const [userDetails, setUserdetails] = useState({});
  const url = process.env.REACT_APP_URL;
  const [companyurlslug, setcompanyurlslug] = useState("");
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [plandata, setPlanData] = useState([]);
  const [initialCompanyurlslug, setInitialCompanyurlslug] = useState("");
  const [companyurlslugAvailability, setcompanyurlslugAvailability] =
    useState("");
  const [userurlslug, setuserurlslug] = useState("");
  const [initialUserurlslug, setInitialUserurlslug] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [company_url_edit_permission, setcompany_url_edit_permission] =
    useState(true);
  const [isNameAvailable, setIsNameAvailable] = useState(false); // Initialize as false
  const { userInfo } = useSelector((state) => state.userInformation);
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const plan = userInfo?.subscription_details?.plan;
  const { planData } = useSelector((state) => state.planData);
  const { id } = useParams();
  const navigate = useNavigate();
  const { unique_slug } = useSelector((state) => state.unique_slug);
  const lengthOfUniqueSlugs = unique_slug?.users_slug[0]?.unique_slugs.length;
  const latestIndex = lengthOfUniqueSlugs ? lengthOfUniqueSlugs - 1 : null;
  const uniqueslug = unique_slug && unique_slug?.users_slug[0]?.unique_slugs[latestIndex].value;
  const [apiSuccess, setApiSuccess] = useState(false);
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  useEffect(() => {
    setCompanydetails(company);
    setUserdetails(teamMemberDetails);

  }, [company, teamMemberDetails]);

  useEffect(() => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    } else {
      setCompanydetails(company);
      const initialSlug = company.companyurlslug;
      setcompanyurlslug(initialSlug);
      setInitialCompanyurlslug(initialSlug);
    }
  }, [company]);

  useEffect(() => {
    if (!teamMemberDetails) {
      dispatch(SingleTeamMemberDetails(id));
    } else {
      setUserdetails(teamMemberDetails);
      const userinitialSlug = teamMemberDetails.userurlslug;
      setuserurlslug(userinitialSlug);
      setInitialUserurlslug(userinitialSlug);
    }
  }, [teamMemberDetails]);
  useEffect(() => {
    dispatch(fetchUserInformationDetails());
  }, [id, dispatch]);
  const changehandler = (e) => {
    const newValue = e.target.value;
    setCompanydetails({ ...companyDetails, companyurlslug: newValue?.trim() });
    setcompanyurlslug(newValue);
  };
  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const fetchandSetPlans = () => {
    if (planData) {
      setPlanData(planData.plans);
    } else {
      dispatch(getPlan());
    }
  }

  useEffect(() => {
    fetchandSetPlans();
  }, [planData])

  useEffect(() => {
    dispatch(getUnique_slug())
  }, []);

  const userchangehandler = (e) => {
    const newValue = e.target.value;
    setUserdetails({ ...userDetails, userurlslug: newValue?.trim() });
    setuserurlslug(newValue);
  };
  const disableicon = (
    <span>
      Not editable. Your company has locked this field, and it is displayed for
      informational purposes.
    </span>
  );
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    // Custom styles for the tooltip
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#E65925",
      color: "#fff",
      fontSize: 13,
      maxWidth: "none",
      padding: "10px",
    },
  }));
  const handleSave = async () => {
    try {
      const availabilityError = await checkAvailability();

      if (!availabilityError) {
        return false;
      }
      const requestBody = {
        userurlslug: userurlslug,
        userID: user?._id
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(`${url}/updateUserSlug`, requestBody, config);
      if (response) {
        dispatch(updateCompanyDetailsInfo(companyDetails));
        dispatch(updateTeamMemberDetails(id, userDetails))
          .then(() => {
            setApiSuccess(true);
            setCompanydetails({ ...companyDetails });
            setUserdetails({ ...userDetails });
            dispatch(getCompanyProfileDetails());
            dispatch(SingleTeamMemberDetails(id));
            setIsSaveEnabled(false);
            setcompanyurlslugAvailability("");
          })
          .catch((error) => {
            ;
          });
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const checkAvailability = async () => {
    try {
      const response = await axios.post(
        `${url}/checkslug-availability`,
        {
          companyurlslug: companyDetails.companyurlslug,
          userurlslug: userurlslug,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const isAvailable =
        response.data.message === "companyurlslug is available.";
      setIsNameAvailable(isAvailable,);
      setcompanyurlslugAvailability(response.data.message);
      setIsSaveEnabled(isAvailable || company_url_edit_permission);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;

        if (errorMessage === "already active.") {
          setcompanyurlslugAvailability('This URL is active and taken by you.');
        } else if (errorMessage === "The user slug is already taken.") {
          setcompanyurlslugAvailability(errorMessage);
        }
      }
      setIsSaveEnabled(false);
    }
  };
  const handleCloseOfPlanModal = () => {
    setShowPlanModal(false);
  };
  const handleResetSlug = () => {
    // Reset the input value to the initial value
    setcompanyurlslug(initialCompanyurlslug);
    setCompanydetails({
      ...companyDetails,
      companyurlslug: initialCompanyurlslug,
    });
    setuserurlslug(uniqueslug);
    setUserdetails({ ...userDetails, userurlslug: uniqueslug });
  };
  function copyDefaultUrl() {
    // Find the element with id "defaultcardurl"
    const defaultCardUrlElement = document.getElementById("defaultcardurl");

    // Check if the element exists
    if (defaultCardUrlElement) {
      // Create a range and select the text within the element
      const range = document.createRange();
      range.selectNode(defaultCardUrlElement);

      // Create a selection
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Execute the copy command
      document.execCommand("copy");

      // Deselect the text
      selection.removeAllRanges();
      // Show the tooltip indicating successful copy
      setTooltipOpen(true);

      // Hide the tooltip after a short delay
      setTimeout(() => {
        setTooltipOpen(false);
      }, 900);
    }
  }
  useEffect(() => {
    setuserurlslug(uniqueslug || "");
  }, [uniqueslug]);

  const handleRedirectToURL = () => {
    const newURL = `/${companyurlslug}/${userurlslug}`;
    navigate(newURL);
  }

  return (
    <>
      {loading && teammemberloading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column gap-3">
          <div>
            <div className="onetap_bg_box">
              <div className="row">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h2 className="onetap_heading_h2">Permalink</h2>{" "}
                  {userInfo?.subscription_details?.plan === "Free" && (
                    <div
                      onClick={() => setShowPlanModal(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={upgradeicon} />{" "}
                      <span className="ugradeplantext">Upgrade plan</span>
                    </div>
                  )}{" "}
                </div>
                {userInfo?.subscription_details?.plan === "Free" && (
                  <p className="onetap_ptag">
                    Permalink personalization isn’t available in your free plan. Please upgrade to a professional or team plan to access this feature.{" "}
                  </p>
                )}
                {userInfo?.subscription_details?.plan === 'Professional' && userInfo?.subscription_details?.plan === 'Team' && (
                  <p className="onetap_ptag">
                    By default, your card URL are base on your name. You can
                    customize it here.{" "}
                  </p>
                )}

              </div>
              <hr className="" />
              {plan === "Free" ?
                (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      padding: "0px 0px 0px 0px",
                    }}
                  >
                    <div className="col-2">
                      <h6 style={{ fontWeight: "600" }} className="m-0">
                        Default card URL
                      </h6>
                    </div>
                    <div
                      className="col cardurltype"
                      style={{ alignItems: "center" }}
                    >
                      <div className="cardurlmake">
                        <span id="defaultcardurl">https://app.1tapconnect.com/{uniqueslug}</span>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center mt-2"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <span className="checkavailability" onClick={copyDefaultUrl}>
                        <Tooltip title={isTooltipOpen ? 'URL Copied!' : 'Copy to clipboard'} placement="bottom" arrow>
                          Copy Default URL
                        </Tooltip>
                      </span>
                      {companyurlslugAvailability !== "" && (
                        <>
                          <span
                            className="ms-2"
                            style={{
                              color:
                                companyurlslugAvailability ===
                                  "companyurlslug is available."
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {companyurlslugAvailability ===
                              "companyurlslug is available."
                              ? "Card URL is Available."
                              : "Card URL is Not Available."}
                          </span>
                          <span
                            className="checkavailability_reset_btn justify-content-center d-flex ms-2 p-1"
                          >
                            Reset
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      padding: "0px 0px 0px 0px",
                    }}
                  >
                    <div className="col-2">
                      <h6 style={{ fontWeight: "600" }} className="m-0">
                        Default card URL
                      </h6>
                    </div>
                    <div
                      className="col cardurltype"
                      style={{ alignItems: "center" }}
                    >
                      <div className="cardurlmake">
                        {teamMemberDetails?.role === "teammember" ||
                          teamMemberDetails?.role === "manager" ? <span>{window.location.origin}/{companyurlslug}</span> :
                          <span>{window.location.origin}</span>}
                        {teamMemberDetails?.companyID && (
                          <>
                            {plan === "Professional" ? (
                              <></>
                            ) : (
                              <>
                                {teamMemberDetails?.role === "teammember" ||
                                  teamMemberDetails?.role === "manager" ? (
                                  <></>
                                ) : (<>
                                  <span>/</span>
                                  <input
                                    type="text"
                                    className="companynameinput"
                                    placeholder="[company_name]"
                                    value={companyurlslug}
                                    onChange={(e) => changehandler(e)}
                                  />
                                </>)}
                              </>
                            )}
                          </>
                        )}
                        <span>/</span>
                        <span style={{ marginTop: "7px" }}>
                          {teamMemberDetails?.role === "teammember" ||
                            teamMemberDetails?.role === "manager" ? (
                            company?.company_url_edit_permission === true ? (
                              <input
                                type="text"
                                className="companynameinput"
                                placeholder="[your-name]"
                                value={userurlslug}
                                onChange={(e) => userchangehandler(e)}
                              />
                            ) : (
                              <LightTooltip placement="top" title={disableicon}>
                                <input
                                  type="text"
                                  className="companynameinput"
                                  placeholder="[your-name]"
                                  value={userurlslug}
                                  style={{ opacity: "0.6" }}
                                  readOnly
                                />
                              </LightTooltip>
                            )
                          ) : (
                            <input
                              type="text"
                              className="companynameinput"
                              placeholder="[your-name]"
                              value={userurlslug}
                              onChange={(e) => userchangehandler(e)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center mt-2"
                      style={{ justifyContent: "flex-end" }}
                    >
                      {teamMemberDetails?.role === "teammember" ||
                        teamMemberDetails?.role === "manager" ? (
                        company?.company_url_edit_permission === true ?
                          <span
                            className="checkavailability"
                            onClick={handleRedirectToURL}
                          >
                            View my card
                          </span> : <span
                            className="checkavailability"
                            onClick={handleRedirectToURL}
                          >
                            View my card
                          </span>) : <span
                            className="checkavailability"
                            onClick={checkAvailability}
                          >
                        Check availability
                      </span>}
                      {companyurlslugAvailability !== "" && (
                        <>
                          <span
                            className="ms-2"
                            style={{
                              color:
                                companyurlslugAvailability ===
                                  "companyurlslug is available."
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {companyurlslugAvailability ===
                              "companyurlslug is available."
                              ? "Card URL is Available."
                              : companyurlslugAvailability}
                          </span>
                          <span
                            className="checkavailability_reset_btn justify-content-center d-flex ms-2 p-1"
                            onClick={handleResetSlug}
                          >
                            Reset
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              <hr />
            </div>
          </div>

          <div className="d-flex flex-column gap-3">
            {userInfo?.subscription_details?.plan !== "Free" && <div className="row">
              <div className="d-flex justify-content-end">
                <button
                  className={`onetap_connect__comp_profile_save_button_syn ${!isSaveEnabled ? "disabled" : ""
                    }`}
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className={`onetap_connect__comp_profile_save_and_cont_button_syn ${!isSaveEnabled ? "disabled" : ""
                    }`}
                  onClick={async (e) => {
                    const saveSuccess = await handleSave();
                    if (saveSuccess) {
                      setTimeout(() => {
                        props.onClickSave("Edit Information");
                      }, 3000);
                    }
                  }}
                >
                  Save & Continue
                </button>
              </div>
            </div>}

          </div>
        </div>
      )}
      <Modal
        open={showPlanModal}
        onClose={handleCloseOfPlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style3}>
          <div className="Modal-section">
            <h2 className="header" style={{ backgroundColor: "#F2F2F2" }}>
              Change plan
              <CloseIcon className="icon" onClick={handleCloseOfPlanModal} />
            </h2>
            <div className={`sliding-switch ${isActive ? "active" : ""}`}>
              <div className="switch-handle"></div>
              <div className="switch-text">
                <span
                  className={`switch-text-option ${isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Pay Monthly
                </span>
                <span
                  className={`switch-text-option ${!isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Yearly and save 20%
                </span>
              </div>
            </div>
            {isActive ? (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "monthly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Professional") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Team") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Enterprise") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                    })}
                </div>
              </>
            ) : (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "yearly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Professional") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Team") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Enterprise") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }

                    })}
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                  class="circle">
                  <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                    fill="#E65925" />
                  <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                </svg>
              </div>
            </div>
            <span className="team_created_success">Data updated successfully!</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default CardProfileSettingTab;
