/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useClickAway } from "@uidotdev/usehooks";
import "./CompanyProfile.css";

const BrandingColorPicker = (props) => {
  const { defaultColor } = props;
  const { name } = props;
  const { addColorToAdditionalColors } = props; // Destructure the prop
  const refrence = useRef();
  const ref = useClickAway(() => {
    setIsOpen(false);
  });
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        // Execute your code here when the Escape key is pressed
        setIsOpen(false);
      }
    };

    // Add an event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Make sure to include all dependencies if needed

  const [color, setColor] = useState(defaultColor);
  const [tempColor, setTempColor] = useState(defaultColor);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    refrence.current.style.backgroundColor = defaultColor;
  }, []);

  const colorPreviewStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "50px",
    height: "40px",
    borderRadius: "4px",
    border: "0.5px solid black",
  };
  const onChange = (newColor) => {
    setColor(newColor);
    addColorToAdditionalColors(name, newColor);
  };

  return (
    <>

      <HexColorInput
        color={color}
        onChange={onChange}
        prefixed={true}
        className="onetap_connect_branding_comp_prof_color_default_picker_syn"
      />
      <div className="onetap_conn_brand_compprof_color_picker_syn">
        <div>
          <div
            className="swatch"
            style={{
              ...colorPreviewStyle,
              backgroundColor: isOpen ? tempColor : color || "00000",
              position: "relative",
              cursor: "pointer"
            }}
            ref={refrence}
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              className="pencil-svg"
              viewBox="0 0 24 24"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0, // Initially hidden
                transition: "opacity 0.3s",
              }}
            >
              <path d="M7.127 22.562l-7.127 1.438 1.438-7.128 5.689 5.69zm1.414-1.414l11.228-11.225-5.69-5.692-11.227 11.227 5.689 5.69zm9.768-21.148l-2.816 2.817 5.691 5.691 2.816-2.819-5.691-5.689z" />
            </svg>
          </div>
        </div>
        {isOpen && (
          <div
            className="popover d-flex justify-content-center flex-column align-items-center"
            ref={ref}
            style={{ position: "absolute", left: "-15px" }}
          >
            <HexColorPicker
              color={color}
              onChange={(e) => { setTempColor(e) }}
              style={{ width: "170px" }}
            />
            <HexColorInput
              style={{ borderRadius: "0 0 0.5rem 0.5rem" }}
              color={tempColor}
              onChange={(e) => { setTempColor(e) }}
              prefixed={true}
              className="onetap_connect_branding_comp_prof_color_default_picker_syn w-100 text-center"
            />
            <div className="d-flex w-100 mt-1 gap-1 p-1">
              <button className="w-50 mb-0 border-0" style={{ background: '#e65925', color: "white", padding: "6px 15px" }} onClick={() => { onChange(tempColor), setIsOpen(false) }}>Ok</button>

              <button className="w-50 mb-0 border-0" style={{ background: 'black', color: "white", padding: "6px 15px" }} onClick={() => { setTempColor(color), setIsOpen(false) }}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BrandingColorPicker;
