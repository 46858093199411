import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const SubscriptionHeaders = (props) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: "10px",
    };
    const [openAddClientModal, setOpenAddClientModal] = useState(false);
    const [isSelected, setIsSelected] = useState('')
    const [cardInfoTab, setCardInfoTab] = useState("Subscription");
    const { tab } = props;


    useEffect(() => {
        setCardInfoTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCardInfoTab(value);
        props.onChange(value);
    };

    return (
        <>
            <div className="onetap_connect_mycard_header_OTC_Admin">

                <div
                    onClick={() => handleClickOfTab("Subscription")}
                    className={
                        cardInfoTab === "Subscription"
                            ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                            : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
                    }
                >
                    <div
                        style={{
                            backgroundColor: cardInfoTab === "Subscription" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none" style={{ cursor: "pointer" }}>
                            <path d="M10 4V0H0V18H20V4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z" fill={cardInfoTab === "Subscription" ? "#ffff" : "#000000"} />
                        </svg>
                    </div>

                    <p
                        className={
                            cardInfoTab === "Subscription"
                                ? "onetap_connect_mycard_active_header_OTC_Admin"
                                : "onetap_connect_mycard_headers_OTC_Admin"
                        }
                    >
                        Subscriptions
                    </p>
                </div>
                <div className="onetap_conn_user_header_right_OTC_Admin">
                    <div className="onetap_conn_user_header_allusers_OTC_Admin">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M1 21L12 2L23 21H1ZM4.45 19H19.55L12 6L4.45 19ZM12 18C12.2833 18 12.521 17.904 12.713 17.712C12.905 17.52 13.0007 17.2827 13 17C13 16.7167 12.904 16.479 12.712 16.287C12.52 16.095 12.2827 15.9993 12 16C11.7167 16 11.479 16.096 11.287 16.288C11.095 16.48 10.9993 16.7173 11 17C11 17.2833 11.096 17.521 11.288 17.713C11.48 17.905 11.7173 18.0007 12 18ZM11 15H13V10H11V15Z" fill="#333333" />
                            </svg>
                        </div>
                        <div>
                            Flagged
                        </div>
                    </div>
               </div>
            </div>

            <Modal
                open={openAddClientModal}
                onClose={() => setOpenAddClientModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Add new client
                        </h2>
                        <div className="d-flex align-items-center gap-3 mb-2">
                            <div role="button" className="w-50 justify-content-center align-items-center d-flex flex-column" onClick={() => setIsSelected('Individual')} style={{ backgroundColor: isSelected === 'Individual' ? '#E65925' : 'white', color: isSelected === 'Individual' ? 'white' : '#333333', height: '100px', borderRadius: '4px', border: '1px solid #838383' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill='none'>
                                    <path d="M19.8866 19.5828C18.4236 16.9526 16.169 15.0666 13.5379 14.1725C14.8394 13.3668 15.8506 12.1391 16.4161 10.678C16.9817 9.21681 17.0704 7.603 16.6686 6.08434C16.2668 4.56569 15.3968 3.22618 14.1921 2.27151C12.9874 1.31684 11.5146 0.799805 10 0.799805C8.48537 0.799805 7.01262 1.31684 5.80792 2.27151C4.60321 3.22618 3.73316 4.56569 3.33138 6.08434C2.9296 7.603 3.0183 9.21681 3.58387 10.678C4.14944 12.1391 5.1606 13.3668 6.46207 14.1725C3.83095 15.0656 1.5764 16.9516 0.113387 19.5828C0.0597359 19.6738 0.0241494 19.775 0.00872733 19.8805C-0.00669474 19.9861 -0.00164002 20.0937 0.0235932 20.1971C0.0488265 20.3006 0.0937273 20.3977 0.155646 20.4828C0.217565 20.5679 0.295248 20.6393 0.384111 20.6927C0.472975 20.746 0.571219 20.7804 0.673047 20.7936C0.774875 20.8069 0.878224 20.7988 0.976997 20.7698C1.07577 20.7409 1.16797 20.6916 1.24815 20.625C1.32832 20.5584 1.39486 20.4758 1.44383 20.382C3.25363 17.1294 6.45246 15.1874 10 15.1874C13.5475 15.1874 16.7464 17.1294 18.5562 20.382C18.6051 20.4758 18.6717 20.5584 18.7519 20.625C18.832 20.6916 18.9242 20.7409 19.023 20.7698C19.1218 20.7988 19.2251 20.8069 19.327 20.7936C19.4288 20.7804 19.527 20.746 19.6159 20.6927C19.7048 20.6393 19.7824 20.5679 19.8444 20.4828C19.9063 20.3977 19.9512 20.3006 19.9764 20.1971C20.0016 20.0937 20.0067 19.9861 19.9913 19.8805C19.9758 19.775 19.9403 19.6738 19.8866 19.5828ZM4.62058 7.99498C4.62058 6.88857 4.93607 5.807 5.52717 4.88705C6.11827 3.9671 6.95842 3.25008 7.94138 2.82668C8.92434 2.40327 10.006 2.29249 11.0495 2.50834C12.093 2.72419 13.0515 3.25698 13.8038 4.03933C14.5562 4.82169 15.0685 5.81847 15.2761 6.90362C15.4836 7.98878 15.3771 9.11357 14.9699 10.1358C14.5628 11.158 13.8733 12.0316 12.9886 12.6463C12.104 13.261 11.0639 13.5891 10 13.5891C8.57376 13.5875 7.20637 12.9976 6.19786 11.9489C5.18935 10.9001 4.6221 9.47816 4.62058 7.99498Z" fill={isSelected === 'Individual' ? 'white' : '#333333'} />
                                </svg>
                                <span className="mt-2">Individual</span>
                            </div>

                            <div role="button" className="w-50 justify-content-center align-items-center d-flex flex-column" onClick={() => setIsSelected('Company')} style={{ backgroundColor: isSelected === 'Company' ? '#E65925' : 'white', color: isSelected === 'Company' ? 'white' : '#333333', height: '100px', borderRadius: '4px', border: '1px solid #838383' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
                                    <path d="M12.0017 5.24425V0.799805H0.890625V20.7998H23.1128V5.24425H12.0017ZM5.33507 18.5776H3.11285V16.3554H5.33507V18.5776ZM5.33507 14.1331H3.11285V11.9109H5.33507V14.1331ZM5.33507 9.68869H3.11285V7.46647H5.33507V9.68869ZM5.33507 5.24425H3.11285V3.02203H5.33507V5.24425ZM9.77951 18.5776H7.55729V16.3554H9.77951V18.5776ZM9.77951 14.1331H7.55729V11.9109H9.77951V14.1331ZM9.77951 9.68869H7.55729V7.46647H9.77951V9.68869ZM9.77951 5.24425H7.55729V3.02203H9.77951V5.24425ZM20.8906 18.5776H12.0017V16.3554H14.224V14.1331H12.0017V11.9109H14.224V9.68869H12.0017V7.46647H20.8906V18.5776ZM18.6684 9.68869H16.4462V11.9109H18.6684V9.68869ZM18.6684 14.1331H16.4462V16.3554H18.6684V14.1331Z" fill={isSelected === 'Company' ? 'white' : '#333333'} />
                                </svg>
                                <span className="mt-2">Company</span>
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-3 mb-2 ">
                            <div className="w-50">
                                <span className="Team-Name-Text">First name</span>
                                <br />
                                <div>
                                    <input
                                        type="text"
                                        name="teamName"
                                        className="input-field"
                                    />
                                </div>
                            </div>
                            <div className="w-50">
                                <span className="Team-Name-Text">Last name</span>
                                <br />
                                <div>
                                    <input
                                        type="text"
                                        name="teamName"
                                        className="input-field"
                                    />
                                </div>
                            </div>
                        </div>
                        <span className="Team-Name-Text">Email</span>
                        <br />
                        <div>
                            <input
                                type="text"
                                name="teamName"
                                className="input-field mb-2"
                            />
                        </div>

                        <span className="Team-Name-Text">Company name</span>
                        <br />
                        <div>
                            <input
                                type="text"
                                name="teamName"
                                className="input-field mb-2"
                            />
                        </div>

                        <span className="Team-Name-Text">Industry</span>
                        <br />
                        <div>
                            <input
                                type="text"
                                name="teamName"
                                className="input-field mb-2"
                            />
                        </div>
                        <div className="Modal-btn-group">
                            <button
                                className="Modal-btn first-modal-btn"
                                onClick={() => { setOpenAddClientModal(false) }}
                            >
                                Create client
                            </button>
                            <button
                                className="Modal-btn sec-modal-btn"
                                onClick={() => { setOpenAddClientModal(false) }}
                            >
                                Cancel
                            </button>
                        </div>

                    </div>
                </Box>
            </Modal >
        </>
    );
};

export default SubscriptionHeaders;
