import React, { useEffect, useState } from "react";
import "./Otc_Orders.css";
import { Box, Modal } from "@mui/material";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const Otc_OrdersHeadersWithIcons = (props) => {
  const [OtcOrdersTab, setOtcOrdersTab] = useState("Orders");
  const [openTeamModal, setOpenTeamModal] = React.useState(false);
  const { tab } = props;

  useEffect(() => {
    setOtcOrdersTab(tab);
  }, [tab]);

  const handleClickOfTab = (value) => {
    setOtcOrdersTab(value);
    props.onChange(value);
  };
  const handleClose = () => setOpenTeamModal(false);
  return (
    <div>
      <div className="otc_onetap_conn_otcorders_header_main">
        <div
          className="otc_onetap_otcorders_headers_syn"
          style={{ marginBottom: "0px" }}
        >
          <div
            onClick={() => handleClickOfTab("Orders")}
            className={
              OtcOrdersTab === "Orders"
                ? "otc_onetap_connect_otcorders_header_icon_and_header_active_syn"
                : "otc_onetap_connect_otcorders_header_icon_and_header_deactive_syn"
            }
          >
            {OtcOrdersTab === "Orders" ? (
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="34" height="34" rx="9" fill="#E65925" />
                <path
                  d="M17 15L15.6 13.6L17.175 12H13V10H17.175L15.575 8.4L17 7L21 11L17 15ZM12 27C11.45 27 10.979 26.804 10.587 26.412C10.195 26.02 9.99933 25.5493 10 25C10 24.45 10.196 23.979 10.588 23.587C10.98 23.195 11.4507 22.9993 12 23C12.55 23 13.021 23.196 13.413 23.588C13.805 23.98 14.0007 24.4507 14 25C14 25.55 13.804 26.021 13.412 26.413C13.02 26.805 12.5493 27.0007 12 27ZM22 27C21.45 27 20.979 26.804 20.587 26.412C20.195 26.02 19.9993 25.5493 20 25C20 24.45 20.196 23.979 20.588 23.587C20.98 23.195 21.4507 22.9993 22 23C22.55 23 23.021 23.196 23.413 23.588C23.805 23.98 24.0007 24.4507 24 25C24 25.55 23.804 26.021 23.412 26.413C23.02 26.805 22.5493 27.0007 22 27ZM6 9V7H9.275L13.525 16H20.525L24.425 9H26.7L22.3 16.95C22.1167 17.2833 21.8707 17.5417 21.562 17.725C21.2533 17.9083 20.916 18 20.55 18H13.1L12 20H24V22H12C11.25 22 10.679 21.675 10.287 21.025C9.895 20.375 9.88267 19.7167 10.25 19.05L11.6 16.6L8 9H6Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 15L15.6 13.6L17.175 12H13V10H17.175L15.575 8.4L17 7L21 11L17 15ZM12 27C11.45 27 10.979 26.804 10.587 26.412C10.195 26.02 9.99933 25.5493 10 25C10 24.45 10.196 23.979 10.588 23.587C10.98 23.195 11.4507 22.9993 12 23C12.55 23 13.021 23.196 13.413 23.588C13.805 23.98 14.0007 24.4507 14 25C14 25.55 13.804 26.021 13.412 26.413C13.02 26.805 12.5493 27.0007 12 27ZM22 27C21.45 27 20.979 26.804 20.587 26.412C20.195 26.02 19.9993 25.5493 20 25C20 24.45 20.196 23.979 20.588 23.587C20.98 23.195 21.4507 22.9993 22 23C22.55 23 23.021 23.196 23.413 23.588C23.805 23.98 24.0007 24.4507 24 25C24 25.55 23.804 26.021 23.412 26.413C23.02 26.805 22.5493 27.0007 22 27ZM6 9V7H9.275L13.525 16H20.525L24.425 9H26.7L22.3 16.95C22.1167 17.2833 21.8707 17.5417 21.562 17.725C21.2533 17.9083 20.916 18 20.55 18H13.1L12 20H24V22H12C11.25 22 10.679 21.675 10.287 21.025C9.895 20.375 9.88267 19.7167 10.25 19.05L11.6 16.6L8 9H6Z"
                  fill="#333333"
                />
              </svg>
            )}
            <p
              className={
                OtcOrdersTab === "Orders"
                  ? "otc_onetap_connect_otcorders_active_header_syn"
                  : "otc_onetap_connect_otcorders_header_syn"
              }
            >
              Orders
            </p>
          </div>
        </div>
        <div className="otc_onetap_conn_otcorders_header_right">
          <div className="otc_onetap_conn_otcorders_header_allusers">
            <div>
              <svg
                width="22"
                height="19"
                viewBox="0 0 22 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 19L11 0L22 19H0ZM3.45 17H18.55L11 4L3.45 17ZM11 16C11.2833 16 11.521 15.904 11.713 15.712C11.905 15.52 12.0007 15.2827 12 15C12 14.7167 11.904 14.479 11.712 14.287C11.52 14.095 11.2827 13.9993 11 14C10.7167 14 10.479 14.096 10.287 14.288C10.095 14.48 9.99933 14.7173 10 15C10 15.2833 10.096 15.521 10.288 15.713C10.48 15.905 10.7173 16.0007 11 16ZM10 13H12V8H10V13Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div>Flagged</div>
          </div>
          <div className="">
            <div
              className={`otc_onetap_conn_user_header_addusers`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <rect width="34" height="34" rx="9" fill="#E65925" />
                <path
                  d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                  fill="white"
                />
              </svg>
              <div>Create Order</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openTeamModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2
            style={{
              fontSize: "22px",
              marginBottom: "10px",
              fontWeight: "700",
            }}
          >
            Create order
          </h2>

          <span className="otc_Team-Name-Text">Select customer</span>
          <br />
          <div>
            <Select
              styles={{
                control: () => ({
                  margin: 0,
                  padding: 0,
                  border: "none",
                  boxShadow: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  margin: 0, 
                  padding: 0, 
                }),
                indicatorsContainer: () => ({
                  display: "none",
                }),
                option: (provided) => ({
                  ...provided,
                }),
              }}
              className="otc_onetap_conn_general_information_input_syn mb-3"
            />
          </div>
          <div className="Modal-btn-group">
            <button
              className="Modal-btn first-modal-btn"
            >
              Continue
            </button>
            <button className="Modal-btn sec-modal-btn" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Otc_OrdersHeadersWithIcons;
