import React, { useEffect, useState } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import webpimage from "../../../assets/images/Kimba Garcia-2023.webp";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./login.scss";
import GoogleAuth from "../Google/GoogleAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserInformationDetails,
  loginHandler,
  getProducts,
  fetchCart,
} from "../../../actions/userAction/userAction";
import open_eye from "../../../assets/icons/eye.svg";
import close_eye from "../../../assets/icons/eye-off.svg";
import logo from "../../../assets/icons/logo_black.svg";
import { Menu } from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { retriveLoginAccounts } from "../../../actions/switchAccount/switchAccountAction";

const Login = () => {
  const url = process.env.REACT_APP_URL;
  const { userData } = useSelector((state) => state.activeUser)
  const ErrorMessageDiv = {
    width: "100%",
    padding: "12px 40px 12px 18px",
    boxSizing: "border-box",
    fontSize: "18px",
    outline: "none",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#FDE9E9",
    color: "#ea4e4e",
    borderLeft: "5px solid",
  };
  const successMessageDiv = {
    width: "100%",
    padding: "12px 40px 12px 18px",
    boxSizing: "border-box",
    fontSize: "18px",
    outline: "none",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#F0FEEB",
    color: "#48AF25",
    borderLeft: "5px solid",
  };
  const errorIconstyle = {
    position: "absolute",
    top: "-8px",
    right: "12px",
    width: "25px",
  };
  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 64 64"
      id="Error"
    >
      <circle
        cx="32"
        cy="32"
        r="28"
        fill="none"
        stroke="#ea4e4e"
        stroke-miterlimit="10"
        stroke-width="8"
        class="colorStroke010101 svgStroke"
      ></circle>
      <line
        x1="32"
        x2="32"
        y1="18"
        y2="38"
        fill="none"
        stroke="#ea4e4e"
        stroke-miterlimit="10"
        stroke-width="8"
        class="colorStroke010101 svgStroke"
      ></line>
      <line
        x1="32"
        x2="32"
        y1="42"
        y2="46"
        fill="none"
        stroke="#ea4e4e"
        stroke-miterlimit="10"
        stroke-width="8"
        class="colorStroke010101 svgStroke"
      ></line>
    </svg>
  );
  const successIcon = (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99906 16.4209L0.789062 10.2109L3.61906 7.38086L6.99906 10.7709L16.8791 0.880859L19.7091 3.71086L6.99906 16.4209Z"
        fill="#48AF25"
      />
    </svg>
  );
  const loginInputsStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [type, setType] = useState("password");
  const [typeImg, setTypeImg] = useState(open_eye);
  const location = useLocation();
  const path = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [InvitedUserData, setInvitedUserData] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, message, error } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const { userInfo } = useSelector((state) => state.userInformationDetails);

  useEffect(() => {
    handleCartUpdate();
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(fetchUserInformationDetails());
    }
  }, []);

  const recoveryToken = async () => {
    try {
      const response = await axios.post(
        `${url}/recover_account`,
        { token: token, email: email, password: password },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        navigate("/login");
        setSuccessMessage(
          "Your account has been recovered. Please login again."
        );
      }
    } catch (error) {
      //
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Incorrect Email") {
          setEmailError(errorMessage);
        } else if (errorMessage === "Incorrect Password") {
          setPasswordError(errorMessage);
        }
      }
    }
  };

  // recoveryToken(token);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (token) {
      recoveryToken(token);
    } else {
      loginhandler(e);
    }
  };

  // this function is of handling password visibility
  const handleShowPassword = () => {
    if (type === "password") {
      setType("text");
      setTypeImg(close_eye);
    } else {
      setType("password");
      setTypeImg(open_eye);
    }
  };

  //this function is for login api calling
  const loginhandler = (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      if (!email) {
        setEmailError("Please enter your email.");

        return false;
      }
      if (!password) {
        setPasswordError("Please enter password.");

        return false;
      }
    }

    if (email !== "") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(email) === false) {
        setEmailError("Please enter a valid email.");
        return false;
      }
    }

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      return false;
    }
    dispatch(
      loginHandler(email, password, showToastMessage, showFailedToastMessage)
    );
    if (!products) {
      dispatch(getProducts());
    }
  }
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [localStorageValues, setLocalStorageValues] = useState([]);

  const fetchAllLocalStorageValues = async () => {
    const localStorageKeys = Object.keys(localStorage);
    const filteredKeys = localStorageKeys.filter((key) =>
      key.startsWith("MemberID")
    );
    const values = filteredKeys.map((key) => localStorage.getItem(key));
    //API call to fetch account
    fetchData(values, "loginScreen");
  };
  const fetchData = async (values) => {
    try {
        if(userData){
            setLocalStorageValues(userData.userData);
        }else{
            dispatch(retriveLoginAccounts(values))
        }
    } catch (error) {
    }
};

  const switchAccount = async (e) => {
    const userID = e.target.value;
    try {
      const { data } = await axios.post(
        `${url}/accounts/switch`,
        { userID },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (data.success) {
        window.location.href = `/dashboard`;
      } else {
        //show error
        alert("Error in Account switching");
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllLocalStorageValues();
  }, []);
  useEffect(() => {}, [localStorageValues]);

  useEffect(() => {
    if (error) {
      setPasswordError(error);

      dispatch({ type: "CLEAR_ERROR" });
    }
    if (message) {
      dispatch({ type: "CLEAR_MESSAGE" });
    }
  }, [user, error, message, dispatch, navigate]);

  const showToastMessage = () => {
    setSuccessMessage("Login Successful!");
  };
  const showFailedToastMessage = () => {
    setEmailError("Login Failed!");
  };

  // Helper function to merge two carts based on product ID and variation
  const mergeCarts = (localStorageCart, serverCart) => {
    const mergedCart = serverCart ? [...serverCart] : [];

    if (localStorageCart && Array.isArray(localStorageCart)) {
      localStorageCart.forEach((localStorageItem) => {
        if (
          mergedCart &&
          Array.isArray(mergedCart) &&
          localStorageItem &&
          localStorageItem.product
        ) {
          const existingProductIndex = mergedCart.findIndex(
            (serverItem) =>
              serverItem.product &&
              serverItem.product._id === localStorageItem.product._id &&
              serverItem.variation === localStorageItem.variation
          );

          if (existingProductIndex !== -1) {
            // Product already exists in the server cart, update the quantity
            if (mergedCart[existingProductIndex].quantity) {
              mergedCart[existingProductIndex].quantity +=
                localStorageItem.quantity;
            } else {
              // If quantity is undefined, set it to the localStorageItem quantity
              mergedCart[existingProductIndex].quantity =
                localStorageItem.quantity;
            }
          } else {
            // Product is not in the server cart, add it
            mergedCart.push(localStorageItem);
          }
        }
      });
    }

    return mergedCart;
  };

  const handleCartUpdate = async () => {
    // Assuming the user ID is available in the Redux state after login
    const userId = user?._id; // Please adjust this based on your actual user data structure

    let cartProducts = [];
    if (userId) {
      // Get cart data from local storage
      const cartData = JSON.parse(localStorage.getItem("cart"));
      try {
        const _id = user._id
        const { data } = await axios.post(
          `${url}/product/cart`,
          { _id },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        cartProducts = data.Cartproducts;
      } catch (error) {}

      if (cartData && cartData.length > 0) {
        // Merge the local storage cart with the server cart
        const mergedCart = mergeCarts(cartData, cartProducts);
        try {
          // Make API call to update user's cart on the server
          const _id = user?._id;
          const response = await axios.post(
            `${url}/cart/update`,
            {
              _id,
              addedProducts: mergedCart,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.message === "Cart updated successfully") {
            // Cart updated successfully

            dispatch(fetchCart(user));
            // Clear local storage after updating the cart
            localStorage.removeItem("cart");
          } else {
            // Handle error from the server
          }
        } catch (error) {
          // Handle network or other errors
        }
      }
    } else {
      dispatch(fetchCart(user));
    }
  };
  return (
    <div className="login_password_container">
      <div className="login_password_left">
        <div className="login_header">
          <Link to="/">
            {" "}
            <img src={logo} alt="oneTap Connect logo" srcset="" />
          </Link>
        </div>
        <div className="login_password_information">
          <h1>Login to Your Account</h1>
          <span>
            New to OneTapConnect ? <Link to="/sign-up">Create new account</Link>
          </span>
          <span className="google_button">
            {InvitedUserData != null ? (
              <GoogleAuth data={InvitedUserData} />
            ) : (
              <GoogleAuth />
            )}
          </span>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              style: {
                minWidth: anchorEl ? anchorEl.clientWidth : undefined,
              },
            }}
          >
            {localStorageValues &&
              localStorageValues.map((value, index) => (
                <div key={index}>
                  <button
                    value={value._id}
                    onClick={(e) => {
                      switchAccount(e);
                    }}
                    style={{
                      border: "none",
                      padding: "8px 16px",
                      borderRadius: "0",
                      backgroundColor: "white",
                      cursor: "pointer",
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  >
                    {value.email}
                  </button>
                  <br />
                </div>
              ))}
          </Menu>
          <div className="line">
            <span></span>OR<span></span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="login_inputs">
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
                autoComplete
                autoFocus
              />
            </div>
            <div className="login_inputs">
              <input
                type={type}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                  setPasswordError("");
                }}
              />
              <img
                src={typeImg}
                alt="Show"
                srcSet=""
                className="login_eye"
                onClick={handleShowPassword}
              />
            </div>
            {(emailError || passwordError) && (
              <div style={loginInputsStyle} className="login_inputs">
                <div style={ErrorMessageDiv}>
                  {emailError ? emailError : passwordError}
                </div>
                <span style={errorIconstyle} className="">
                  {errorIcon}
                </span>
              </div>
            )}

            {loading ? (
              <button
                type=""
                disabled
                style={{
                  opacity: "0.6",
                  cursor: "not-allowed",
                  marginBottom: "0px",
                }}
              >
                Loading...
              </button>
            ) : (
              <button type="submit" style={{ marginBottom: "0" }}>
                Login
              </button>
            )}
            {successMessage && (
              <div style={loginInputsStyle} className="login_inputs">
                <div style={successMessageDiv}>{successMessage}</div>
                <span style={errorIconstyle} className="">
                  {successIcon}
                </span>
              </div>
            )}
          </form>
          <Link style={{ marginTop: "40px" }} to="/forgot-password">
            Forgot your password?{" "}
          </Link>
        </div>
      </div>
      <div className="login_password_right">
        <h3>Meet Kimba</h3>
        <picture>
          {/* WebP format */}
          <source srcSet={webpimage} type="image/webp" />
          {/* Fallback for browsers that do not support WebP */}
          <img src={phoneImg} alt="phoneImg" />
        </picture>
      </div>
    </div>
  );
};

export default Login;
