
import React, { useState } from "react";
import ClientHeaderWithIcons from "./ClientHeaderWithIcons";
import ClientsInfo from "./ClientsInfo";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";


const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Clients");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user">
            <ClientHeaderWithIcons onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "Clients" && (
                <ClientsInfo
                    onClickSave={getValueOfSaveAndContinueTab}
                   />

            )}
        </div>
    );
};


export default function Client() {
    return (
        <>
            <Otc_Sidebar Component={Component} />
        </>
    )
}





