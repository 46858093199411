import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyProfileDetails,
  getTeamMember,
  getTeamName
} from "../../../actions/userAction/userAction";
import "./TeamOverview.css";
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import Loader from '../../Layout/Loader/Loader';
import addUserIcon from "../../../assets/icons/addUsericon.svg"
import removeUserIcon from "../../../assets/icons/removeUserIcon.svg"
import viewTemplateIcon from "../../../assets/icons/viewTemplateIcon.svg"
import moreIcon from "../../../assets/icons/moreIcon.svg"
import { Avatar } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ErrorModal from "../../Layout/ErrorModal/ErrorModal";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  class="circle"
>
  <path
    d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
    fill="#E65925"
  />
  <path
    d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
    stroke="white"
    stroke-width="6.64"
    stroke-linecap="round"
    class="checkmark-path"
  />
</svg>
const styleOfTeamOverview = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const renameModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};


const TeamsOverview = ({ onViewUsersClick, getTeamNameforfilter }) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { users, users_loading, error } = useSelector((state) => state.Users);
  const { team } = useSelector((state) => state.team);

  const [openEditTeamModal, setOpenEditTeamModal] = useState(false);
  const [openAddTeamModal, setOpenAddTeamModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState("");
  const [remainingUsers, setRemainingUsers] = useState("");
  const [usersInSelectedTeam, setUsersInSelectedTeam] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addUserErrors, setAddUserErrors] = useState({});
  const [removeUserErrors, setremoveUserErrors] = useState({});
  const [renameUserErrors, setrenameUserErrors] = useState({});
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [apiError, setApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    dispatch(getTeamName())
  }, [])

  const handleRemoveOpenModal = (teamName) => {
    setremoveUserErrors({ removeUserError: "" });
    setSelectedTeam(teamName);
    setSelectedUsers([]);
    setOpenEditTeamModal(true);
    const usersInSelectedTeam = users?.filter(user => user?.team === teamName);
    setUsersInSelectedTeam(usersInSelectedTeam)
    const allUsersInCompany = users?.map(user => user);
    const remainingUsers = allUsersInCompany.filter(user => usersInSelectedTeam?.includes(user));
    setUsersInSelectedTeam(remainingUsers);
  };

  const handleAddOpenModal = (teamName) => {
    setAddUserErrors({ addUserError: "" });
    setSelectedTeam(teamName);
    setSelectedUsers([]);
    setOpenAddTeamModal(true);
    const usersInSelectedTeam = users?.filter(user => !user?.team);

    setUsersInSelectedTeam(usersInSelectedTeam)
    const allUsersInCompany = users?.map(user => user);
    const remainingUsers = allUsersInCompany?.filter(user => usersInSelectedTeam?.includes(user));
    setRemainingUsers(remainingUsers);
  };

  const handleRenameOpenModal = (teamName) => {
    setOpenRenameModal(true);
    setSelectedTeam(teamName);
    setrenameUserErrors("")
  }
  const [renameTeam, setRenameTeam] = useState("");

  const handleTextRenameteam = (teamname) => {
    setRenameTeam(teamname);
  }

  const filteredUsersInSelectedTeam =
    usersInSelectedTeam &&
    usersInSelectedTeam?.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const designation = user.designation.join(" | ").toLowerCase();
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        designation.includes(searchQuery.toLowerCase())
      );
    });
  const filteredRemainingUsers =
    remainingUsers &&
    remainingUsers?.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const designation = user.designation.join(" | ").toLowerCase();
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        designation.includes(searchQuery.toLowerCase())
      );
    });

  const handleUserCheckboxChangeForDiv = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser._id === user._id
    );

    if (isSelected) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter(
          (selectedUser) => selectedUser._id !== user._id
        )
      );
    } else {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, { _id: user._id },]);
    }
  };

  const url = process.env.REACT_APP_URL;
  const teamCounts = {};

  users?.forEach((user) => {
    const teamId = user.team; 
    if (!teamCounts[teamId]) {
      teamCounts[teamId] = 0; // If not, initialize the count to 0
    }
    teamCounts[teamId]++; // Increment the count for the team
  });

  const handleAddUserTeam = async () => {
    setIsButtonClicked(true);
    if (selectedUsers?.length <= 0) {
      setIsButtonClicked(false);
      setAddUserErrors({ addUserError: "Please select team member." });
    } else {
      const { data } = await axios.post(
        `${url}/user/update/users/team`,
        { team: selectedTeam, selectedUsers: selectedUsers, type: 'add' },
        { withCredentials: true }
      );
      if (data.success === true) {
        setIsButtonClicked(false);
        setApiSuccess(true)
        setSuccessMessage('User successfully added to the team.')
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        dispatch(getTeamMember());
        dispatch(getCompanyProfileDetails());
        dispatch(getTeamName())
        setOpenAddTeamModal(false);
      } else {
        setIsButtonClicked(false);
        setApiError(true);
        setErrorMessage('Error updating team. Please try again.');
      }
    }
  };

  const handleRemoveTeamFromUsers = async () => {
    setIsButtonClicked(true);
    if (selectedUsers?.length <= 0) {
      setIsButtonClicked(false);
      setremoveUserErrors({ removeUserError: "Please select team member." });
    } else {
      const { data } = await axios.post(
        `${url}/user/update/users/team`,
        { selectedUsers: selectedUsers, type: 'remove' },
        { withCredentials: true }
      );
      if (data.success === true) {
        setIsButtonClicked(false);
        setApiSuccess(true)
        setSuccessMessage('User successfully removed from the team.')
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        dispatch(getTeamName())
        dispatch(getTeamMember());
        dispatch(getCompanyProfileDetails());
        setOpenEditTeamModal(false);
      } else {
        setIsButtonClicked(false);
        setApiError(true);
        setErrorMessage('Error updating team. Please try again.');
      }
    }
  };

  const handleDeleteTeam = async (teamName) => {
    try {
      const { data } = await axios.post(
        `${url}/user/modify/team`,
        { teamId: teamName, type: "delete" },
        { withCredentials: true }
      );

      if (data.success === true) {
        setApiSuccess(true)
        setSuccessMessage('Team successfully deleted.')
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        fetchTeamData();
        dispatch(getTeamName())
        dispatch(getTeamMember());
        dispatch(getCompanyProfileDetails());
      } else {
        setApiError(true);
        setErrorMessage('Error deleting team. Please try again.');
      }
    } catch (error) {
      setApiError(true);
      setErrorMessage('Error deleting team. Please try again.');
    }
  };

  const handleRenameTeam = async () => {
    setIsButtonClicked(true);
    try {
      if (!renameTeam || renameTeam.trim() === "") {
        setIsButtonClicked(false);
        setrenameUserErrors({ renameUserError: "Please enter a team name." });
      } else if (selectedTeam === renameTeam) {
        setIsButtonClicked(false);
        setrenameUserErrors({
          renameUserError: "Updated team name already exist.",
        });
      } else if (allteam.some((team) => team.trim().toLowerCase() === renameTeam.trim().toLowerCase())) {
        setIsButtonClicked(false);
        setrenameUserErrors({
          renameUserError: "Updated team name already exist.",
        });
      } else {
        const { data } = await axios.post(
          `${url}/user/modify/team`,
          { teamId: selectedTeam, newTeamName: renameTeam, type: "rename" },
          { withCredentials: true }
        );

        if (data.success === true) {
          setIsButtonClicked(false);
          setApiSuccess(true)
          setSuccessMessage('Team successfully renamed.')
          setTimeout(() => {
            setApiSuccess(false)
          }, 5000);

          fetchTeamData();
          dispatch(getTeamName())
          dispatch(getTeamMember());
          dispatch(getCompanyProfileDetails());
          setOpenRenameModal(false);
        } else {
          setIsButtonClicked(false);
          setApiError(true);
          setErrorMessage('Error updating rename team. Please try again.');
        }
      }
    } catch (error) {
      setIsButtonClicked(false);
      setApiError(true);
      setErrorMessage('Error updating rename team. Please try again.');
    }

  };

  const [showOptions, setShowOptions] = useState(null); // Track which team's options are visible

  const handleMoreClick = (teamName) => {
    if (showOptions === teamName) {
      setShowOptions(null); // Clicking again hides the options
    } else {
      setShowOptions(teamName);
    }
  };

  // Add event listener to handle clicks outside the options
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptions &&
        !e.target.classList.contains("more-options") &&
        !e.target.classList.contains("row-2-2") &&
        !e.target.classList.contains("more-icon")
      ) {
        setShowOptions(null);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showOptions]);

  const [teamData, setTeamData] = useState([])
  const fetchTeamData = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${url}/user/teamdata`, {},
        {
          withCredentials: true,
        }
      );

      setTeamData(response.data.team)
      setLoader(false);

    } catch (error) {
      setLoader(false);
      ;
    }
  }
  useEffect(() => {
    if (!team) {
      fetchTeamData();
    }
  }, [team])
  const allteam = teamData?.map((e) => e.team_name)
  const handleSelectedTeam = (team_name) => {
    setRenameTeam(team_name)
  }

  return (
    <>
      {users_loading ? (
        <Loader />
      ) : (
        <>
          <div className="main-container-teamOverview">
            <div className="main-inner-container">
              <div className="text_select">
                <span className="teams">Teams</span>
              </div>
              {loader ? (<Loader />) :
                <div className="four-card-conatiner">
                  {teamData && teamData?.length > 0 ? (
                    teamData.map((team, i) => (
                      <div
                        className="first-card commonCard"
                        style={{ position: "relative" }}
                      >
                        <div className="first-card-inner">
                          <div className="title">
                            <span>Name:</span>{" "}
                          </div>
                          <span className="team-name">
                            {team.team_name?.charAt(0).toUpperCase() +
                              team.team_name?.slice(1)}
                          </span>
                          <div class="grid-container">
                            <div class="row-1">
                              <div
                                class="column row-1-1"
                                onClick={() => {
                                  onViewUsersClick(team.team_name);
                                  getTeamNameforfilter(team.team_name);
                                }}
                              >
                                <span className="icon1-card count">
                                  {teamCounts[team._id] || 0}
                                </span>
                                <span className="text-card">View users</span>
                              </div>
                              <div
                                onClick={() => {
                                  handleAddOpenModal(team._id);
                                  handleSelectedTeam(team.team_name)
                                }}
                                class="column row-1-2"
                              >
                                <span>
                                  <img
                                    className="icon-card"
                                    src={addUserIcon}
                                    alt=""
                                  />{" "}
                                </span>{" "}
                                <span className="text-card">Add users</span>
                              </div>
                              <div
                                onClick={() => handleRemoveOpenModal(team._id)}
                                class="column row-1-3"
                              >
                                <span>
                                  <img
                                    className="icon-card"
                                    src={removeUserIcon}
                                    alt=""
                                  />
                                </span>{" "}
                                <span className="text-card">Remove users</span>
                              </div>
                            </div>
                            <div class="row-2">
                              <div class="column row-2-1">
                                <span>
                                  <img src={viewTemplateIcon} alt="" />
                                </span>{" "}
                                <span className="text-card">
                                  View assigned template
                                </span>
                              </div>
                              <div
                                onClick={() => handleMoreClick(team.team_name)}
                                class="column row-2-2"
                              >
                                <span>
                                  <img
                                    className="more-icon"
                                    src={moreIcon}
                                    alt=""
                                  />
                                </span>
                                <span className="text-card more-icon">More</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showOptions === team.team_name && (
                          <div className="more-options">
                            <div
                              className="rename-delete-option"
                              onClick={() => {
                                handleRenameOpenModal(team._id);
                                handleTextRenameteam(team.team_name)
                              }}
                            >
                              Rename team
                            </div>
                            <div
                              className="rename-delete-option"
                              onClick={() => {
                                handleDeleteTeam(team._id);
                              }}
                            >
                              Delete team
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="no-teams-message">No teams available.</div>
                  )}
                </div>
              }
            </div>
          </div>

          <Modal
            open={openAddTeamModal}
            onClose={() => {
              setOpenAddTeamModal(false);
              setSelectedTeam("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleOfTeamOverview}>
              <div className="Modal-section">
                <div className="header">
                  <div className="main_header">
                    Add users to{" "}
                    {renameTeam?.charAt(0).toUpperCase() +
                      renameTeam?.slice(1)}{" "}
                    team
                  </div>

                  <div className="sub_header">
                    Select the users you’d like to add to this team
                  </div>
                </div>

                <div className="onetap_conn_users_tab_content_addTeam">
                  <div className="onetap_conn_filter_search">
                    <input
                      type="text"
                      placeholder="Search user"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ marginBottom: "10px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      className="onetap_conn_filter_svg"
                    >
                      <path
                        d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <div className="addUserText">Add users</div>
                  {remainingUsers.length == 0 ? (
                    <div>No user available</div>
                  ) : (
                    ""
                  )}
                  <div className="mappingdiv">
                    {filteredRemainingUsers &&
                      filteredRemainingUsers?.map((user, index) => {
                        const designationText =
                          user?.designation.length > 0
                            ? user.designation.join(" | ")
                            : "-";

                        return (
                          <div
                            key={index}
                            className={`select-card-users ${selectedUsers?.some(
                              (item) => item._id === user._id
                            )
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => {
                              handleUserCheckboxChangeForDiv(user);
                              setAddUserErrors({ addUserError: "" });
                            }}
                          >
                            <div className="info-page">
                              <div className="header-avatar-container">
                                {user?.avatar ? (
                                  <Avatar
                                    style={{ width: "48px", height: "48px" }}
                                    src={`${url}/profile/img/${user?.avatar}`}
                                    alt={`${user?.first_name} ${user?.last_name}`}
                                  />
                                ) : (
                                  <div
                                    className="header-avatar-initials-overlay"
                                    style={{ width: "48px", height: "48px" }}
                                  >
                                    {`${user?.first_name[0].toUpperCase()}${user?.last_name[0].toUpperCase()}`}
                                  </div>
                                )}
                              </div>
                              <div className="select-card-users-info">
                                <p className="p1">{`${user?.first_name} ${user?.last_name}`}</p>
                                <p className="p2">{designationText}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {addUserErrors.addUserError && (
                    <div style={{ color: "red" }}>
                      {addUserErrors.addUserError}
                    </div>
                  )}
                </div>
                <div className="Modal-btn-group" style={{ marginTop: "0px" }}>
                  <button
                    className="Modal-btn first-modal-btn"
                    onClick={() => {
                      handleAddUserTeam(selectedUsers._id);
                    }}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? "Loading..." : "Add to team"}
                  </button>
                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => {
                      setOpenAddTeamModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={openEditTeamModal}
            onClose={() => {
              setOpenEditTeamModal(false);
              setSelectedTeam("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleOfTeamOverview}>
              <div className="Modal-section">
                <div className="header">
                  <div className="main_header">
                    Remove users from{" "}
                    {renameTeam?.charAt(0).toUpperCase() +
                      renameTeam?.slice(1)}{" "}
                    team
                  </div>

                  <div className="sub_header">
                    Select the users you’d like to remove from this team
                  </div>
                </div>

                <div className="onetap_conn_users_tab_content_addTeam">
                  <div className="onetap_conn_filter_search">
                    <input
                      type="text"
                      placeholder="Search user"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ marginBottom: "10px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      className="onetap_conn_filter_svg"
                    >
                      <path
                        d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <div className="addUserText">Remove users</div>
                  <div className="mappingdiv">
                    {filteredUsersInSelectedTeam &&
                      filteredUsersInSelectedTeam.length > 0 ? (
                      filteredUsersInSelectedTeam.map((user, index) => {
                        const designationText =
                          user?.designation.length > 0
                            ? user.designation.join(" | ")
                            : "-";
                        return (
                          <div
                            key={index}
                            className={`select-card-users ${selectedUsers.some(
                              (item) => item._id === user._id
                            )
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => {
                              handleUserCheckboxChangeForDiv(user);
                              setremoveUserErrors({ removeUserError: "" });
                            }}
                          >
                            <div className="info-page">
                              <div className="header-avatar-container">
                                {user?.avatar ? (
                                  <Avatar
                                    style={{ width: "48px", height: "48px" }}
                                    src={`${url}/profile/img/${user?.avatar}`}
                                    alt={`${user?.first_name} ${user?.last_name}`}
                                  />
                                ) : (
                                  <div
                                    className="header-avatar-initials-overlay"
                                    style={{ width: "48px", height: "48px" }}
                                  >
                                    {`${user?.first_name[0].toUpperCase()}${user?.last_name[0].toUpperCase()}`}
                                  </div>
                                )}
                              </div>

                              <div className="select-card-users-info">
                                <p className="p1">{`${user.first_name} ${user.last_name}`}</p>
                                <p className="p2">{designationText}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No users found</div>
                    )}
                  </div>
                  {removeUserErrors.removeUserError && (
                    <div style={{ color: "red" }}>
                      {removeUserErrors.removeUserError}
                    </div>
                  )}
                </div>
                <div className="Modal-btn-group" style={{ marginTop: "0px" }}>
                  <button
                    className="Modal-btn first-modal-btn"
                    onClick={() => {
                      handleRemoveTeamFromUsers();
                    }}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? "Loading..." : "Remove from team"}
                  </button>
                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => setOpenEditTeamModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={openRenameModal}
            onClose={() => setOpenRenameModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={renameModalStyle}>
              <div className="Modal-section">
                <h4 className="header">
                  Rename Team{" "}
                </h4>
                <span className="Team-Name-Text">Team Name</span>
                <br />
                <div>
                  <input
                    type="text"
                    name="teamame"
                    className="input-field"
                    value={renameTeam}
                    onChange={(e) => {
                      setRenameTeam(e.target.value);
                      setrenameUserErrors({ renameUserError: "" })

                    }}
                  />
                  {renameUserErrors.renameUserError && (
                    <div style={{ color: "red" }}>
                      {renameUserErrors.renameUserError}
                    </div>
                  )}
                </div>
                <div className="Modal-btn-group">
                  <button
                    className="Modal-btn first-modal-btn"
                    onClick={() => {
                      handleRenameTeam();
                    }}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? "Loading..." : "Rename Team"}
                  </button>
                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => setOpenRenameModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>


          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: "center" }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">
                    {messageIcon}
                  </div>
                </div>
                <span className="team_created_success">
                  {successMessage}
                </span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "13%",
                }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
                </span>
              </button>
            </Box>
          </Modal>

          {apiError && (
            <ErrorModal
              errorMessage={errorMessage}
              onClose={() => setApiError(false)}
            />
          )}

        </>
      )}
    </>
  );
};

export default TeamsOverview;
