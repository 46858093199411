import React, { useState, useEffect, useRef } from "react";
import "./user.css";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTeamMemberDetails,
  getTeamMember,
  getTeamName
} from "../../../actions/userAction/userAction";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Layout/Loader/Loader";
import axios from "axios";
import { State } from "country-state-city";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@mui/material";
import UserPopup from "../Mycard/userpop";
import ErrorModal from "../../Layout/ErrorModal/ErrorModal";

const UsersInfoPage = ({ teamName }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectDropdownTeam, setSelectDropdwonTeam] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryInModal, setSearchQueryInModal] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("Teams");
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [seletedUser, setSelectedUser] = useState([]);
  const [selectedUserForRedirect, setSelectedUserForRedirect] = useState();
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const { users, users_loading, error } = useSelector((state) => state.Users);
  const { team } = useSelector((state) => state.team);
  const { company } = useSelector((state) => state.Company);
  const { user } = useSelector((state) => state.user);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [apiError, setApiError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openActiveModel, setOpenActiveModel] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [activeUserCount, setActiveUserCount] = useState();
  const [openAddUserModel, setOpenAddUserModel] = useState(false);
  const { userInfo } = useSelector((state) => state.userInformation);
  const baseUser = userInfo?.subscription_details?.total_user[0]?.baseUser;
  const additionalUser = userInfo?.subscription_details?.total_user[0]?.additionalUser;
  const total_user = baseUser + additionalUser;
  const [ids, setId] = useState()
  const styleOfTeamOverview = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  let teamOptions = [];

  const handleTeamChange = (selectedOptions) => {
    setSelectDropdwonTeam(selectedOptions);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedUser([...seletedUser, e.target.value]);
    } else {
      setSelectedUser(seletedUser?.filter((id) => id !== e.target.value));
    }
  };

  const handleAllCheckboxChange = (e) => {
    if (seletedUser?.length === 0) {
      setSelectedUser(filteredRows?.map((row) => row.id));
      setDropdownOpen(false);
    } else {
      setSelectedUser([]);
      setDropdownOpen(false);
    }
  };
  //clear filter button
  const clearFilters = () => {
    setSelectedTeam("Teams");
    setSelectedLocation("Location");
    setSelectedStatus("Status");
  };
  const isFilterApplied =
    selectedTeam !== "Teams" ||
    selectedLocation !== "Location" ||
    selectedStatus !== "Status";
  useEffect(() => {
    if (isFilterApplied) {
      setSelectedUser([]);
    }
  }, [isFilterApplied]);

  const filterbtnStyle = {
    padding: "3px 15px",
    backgroundColor: "#e65925", // Change to your desired button color
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    display: isFilterApplied ? "block" : "none", // Show the button conditionally
    marginLeft: "auto",
    marginBottom: "auto",
    height: "30px",
  };

  for (let i = 0; i < team?.length; i++) {
    const teamName = team[i].team_name; // Store team_name in a variable
    const teamid = team[i]._id
    teamOptions.push({
      label: teamName.charAt(0).toUpperCase() + teamName.slice(1),
      value: teamName,
      id: teamid
    });
  }

  const redirectToViewCard = async (userId) => {
    const fetchSlugResponse = await axios.get(`${url}/userslugs/${userId}`);
    const uniqueslug2 = fetchSlugResponse.data; // Assuming the slug is retrieved from the response
    const lengthOfUniqueSlugs = uniqueslug2?.users_slug?.unique_slugs.length;
    const latestIndex = lengthOfUniqueSlugs ? lengthOfUniqueSlugs - 1 : null;
    const uniqueslug = uniqueslug2 && uniqueslug2?.users_slug?.unique_slugs[latestIndex].value;
    const individual = user && user.isIndividual;
    const userPlan = userInfo && userInfo?.subscription_details?.plan;
    const compny_uniq_slug = company && company.companyurlslug;
    const comp_slug = uniqueslug2?.users_slug?.[0]?.companyunique_slug?.slice(-1)[0]?.value;
    let redirectUrl;
    const companySlug = compny_uniq_slug || comp_slug;

    if (individual && (userPlan === 'Free' || userPlan === 'Professional')) {
      redirectUrl = `${uniqueslug}`;
    } else if (individual && userPlan === 'Team') {
      redirectUrl = `${companySlug}/${uniqueslug}`;
    } else if (userPlan === 'Free') {
      redirectUrl = `${uniqueslug}`;
    } else {
      redirectUrl = `${companySlug}/${uniqueslug}`;
    }
    navigate(`/${redirectUrl}`);
  };

  useEffect(() => {
    if (teamName) {
      setSelectedTeam(teamName);
    }
  }, [teamName]);

  //For Add to Team or Change Team of User.
  const handleAddtoTeam = async () => {
    if (!selectDropdownTeam) {
      alert("Please select one team");
      return;
    }

    const updateData = {
      users: seletedUser,
      team: selectDropdownTeam.id, // Wrap the value in an array
    };

    for (const userId of seletedUser) {
      const success = await dispatch(
        updateTeamMemberDetails(userId, updateData)
      );
    }

    setModalOpen(false);
    setUpdatedFlag(true);
    dispatch(getTeamName());
    setApiSuccess(true);
    setDropdownOpen(false)
    setSuccessMessage("Team updated successfully!");
    clearFilters();
    setSelectedUser([]);
    setSelectDropdwonTeam(null)
  };

  const handleRemovefromTeam = async () => {
    if (seletedUser.length <= 0) {
      alert("Please select at least one user and one team");
      return;
    }

    const updateData = {
      users: seletedUser,
      team: null, // Wrap the value in an array
    };

    for (const userId of seletedUser) {
      const success = await dispatch(
        updateTeamMemberDetails(userId, updateData)
      );
    }

    setModalOpen(false);
    setUpdatedFlag(true);
    dispatch(getTeamMember());
    setApiSuccess(true);
    setDropdownOpen(false)
    setSelectedUser([]);
    setSuccessMessage("Team updated successfully!");
    clearFilters();
  };

  //For Activate or User.
  const handleActivateStatus = async () => {
    if (seletedUser.length <= 0) {
      alert("Please select at least one user");
      return;
    }

    if (seletedUser.length > activeUserCount) {
      setOpenAddUserModel(true);
      setDropdownOpen(false);
      return;
    }

    const updateData = {
      users: seletedUser,
      status: "active",
    };

    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(`${url}/user/updatedata/status`, updateData, {
        withCredentials: true,
      });

      if (response.data.success) {
        clearFilters();
        setDropdownOpen(false)
        setApiSuccess(true);
        setSuccessMessage("Status updated successfully!");
        setSelectedUser([]);
      }
    } catch (error) {
      setApiError(true);
      setErrorMessage('Error updating status. Please try again.');
    }
    setUpdatedFlag(true);
  };

  //For Deactivate or User.
  const handleDeactivateStatus = async () => {
    if (seletedUser.length === 0) {
      return;
    }

    const filteredUsers = seletedUser.filter((userId) => {
      const user = rows.find((item) => item.id === userId);
      return user?.userrole !== "superadmin";
    });

    if (filteredUsers.length === 0) {
      alert("All selected users are superadmins. Cannot deactivate.");
      return;
    }
    const selectedUserIds = selectedUserForRedirect?.map(user => user._id);
    const updateData = {
      users: filteredUsers,
      status: "Deactivate",
      selectedUserForRedirect: selectedUserIds,
    };

    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(
        `${url}/user/updatedata/status`,
        updateData,
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        // Handle success, e.g., show a success message or update local state
        setApiSuccess(true);
        setOpenActiveModel(false);
        setSuccessMessage("Status updated successfully!");
        setSelectedUser([]);
        setSelectedUserForRedirect([]);
        clearFilters();
        setUpdatedFlag(true);
      }
    } catch (error) {
      setApiError(true);
      setErrorMessage('Error updating status. Please try again.');
    }
  };


  const dropdownStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F2F2F2",
      marginBottom: "45px",
      padding: "10px 0",
      border: state.isFocused ? "none" : "#F2F2F2",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      borderRadius: "5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "25px",
    }),
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  function createData(id, avatar, name, role, group, location, status, userrole) {
    return {
      id,
      avatar,
      name,
      role,
      group,
      location,
      status,
      userrole
    };
  }
  // Create a function to convert API response data to the desired format
  function convertAPIDataToRow(apiData) {
    return apiData?.map((item) => {
      const id = item._id;
      const avatar = item.avatar;
      const name = `${item.first_name} ${item.last_name}`;
      const userrole = item?.role
      const role = item.designation && item.designation[0] ? item.designation[0] : "- - - ";

      // Mapping the rest of the properties
      const group = item.teamName ? item.teamName : "No team"; // You can fill this based on your requirement
      const states = State.getStatesOfCountry(item.address.country);
      const selectedState = states.find(
        (state) => state.isoCode === item?.address?.state
      );
      const location = selectedState?.name ? selectedState?.name : "No Location";
      const status = item.status;
      return createData(id, avatar, name, role, group, location, status, userrole);
    });
  }

  // Convert the API response data to rows
  useEffect(() => {
    if (users === null) {
      dispatch(getTeamMember());
    } else {
      const updatedUsers = users.map(user => {
        const matchingTeam = team?.find(team => team._id === user.team);
        if (matchingTeam) {
          return { ...user, teamName: matchingTeam.team_name };
        }
        return user; // If no matching team is found, return the original user object
      });

      const activeUsers = updatedUsers.filter(user => user.status === "active");
      const remainingUser = total_user - activeUsers.length;
      setActiveUserCount(remainingUser)
      setRows(convertAPIDataToRow(updatedUsers));
    }

    if (updatedFlag) {
      dispatch(getTeamMember());
      setUpdatedFlag(false);
    }
  }, [users, dispatch, updatedFlag, getTeamMember]);

  const filterRows = () => {
    return rows?.filter(
      (row) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedTeam === "Teams" || row.group === selectedTeam) &&
        (selectedLocation === "Location" ||
          row.location === selectedLocation) &&
        (selectedStatus === "Status" || row.status === selectedStatus)
    );
  };

  // Update filteredRows when dependencies change
  useEffect(() => {
    const filtered = filterRows();
    setFilteredRows(filtered);
  }, [searchQuery, selectedTeam, selectedLocation, selectedStatus, rows, searchQueryInModal]);


  const [showSharePopup, setShowSharePopup] = useState(false);

  const toggleSharePopup = (id) => {
    setId(id)
    setShowSharePopup(!showSharePopup);
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const fullName = row.name; // Replace this with your variable
    const words = fullName.split(" ");
    const initialss = words.map((word) => word.charAt(0).toUpperCase());
    const initials = initialss.join("");
  
    return (
      <React.Fragment>

        <TableRow sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "#F2F2F2 !important" }}>
          <TableCell
            component="th"
            scope="row"
            className="onetap_conn_user_table_column1"
          >
            <div className="profile-info">
              <Link to={`/user/profile/${row.id}`} >
                <div className="user-info-avatar-container">
                  {row?.avatar ? (
                    <img
                      src={`${url}/profile/img/${row?.avatar}`}
                      alt="Profile"
                      style={{
                        width: "54px",
                        height: "54px",
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="user-info-initials-overlay"
                      style={{ width: "54px", height: "54px" }}
                    >
                      {initials}
                    </div>
                  )}
                </div>
              </Link>

              <div class="details">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  to={`/user/profile/${row.id}`}
                >
                  <p style={{ textTransform: "capitalize" }}>{row.name}</p>
                </Link>
                <p className="column1_text">{row.role}</p>
              </div>
            </div>
          </TableCell>
          <TableCell
            align="center"
            style={{ borderBottom: "none", textTransform: "capitalize" }}
          >
            {row.group?.charAt(0).toUpperCase() + row.group?.slice(1)}
          </TableCell>
          <TableCell
            align="center"
            style={{ borderBottom: "none", textTransform: "capitalize" }}
          >
            {row.location}
          </TableCell>
          <TableCell
            align="center"
            style={{
              borderBottom: "none",
              color: row.status === "active" ? "#5CE12F" : "#E62525",
              textTransform: "capitalize",
            }}
          >
            {row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
          </TableCell>
          <TableCell className="onetap_conn_user_table_column5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  name="action_check"
                  style={{ margin: "0px 11px" }}
                  checked={seletedUser.includes(row.id)}
                  value={row.id}
                  onChange={handleCheckboxChange}
                />
              </div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  style={{ color: "#000000" }}
                  disableElevation
                  disableRipple
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#F2F2F2 !important" }}>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none",
            }}
            colSpan={5}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="onetap_conn_expanded_row_card">
                <div className="onetap_conn_expanded_card card1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M3.9502 23.8333V8.66663H8.28353V23.8333H3.9502ZM11.5335 23.8333V2.16663H15.8669V23.8333H11.5335ZM19.1169 23.8333V15.1666H23.4502V23.8333H19.1169Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                  <div>Analytics</div>
                </div>
                <div className="onetap_conn_expanded_card">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="28"
                    viewBox="0 0 36 35"
                    fill="none"
                  >
                    <path
                      d="M24.5259 7.88641L27.6146 10.9737M26.5121 5.16662L18.1603 13.5185C17.7287 13.9494 17.4344 14.4985 17.3144 15.0964L16.543 18.9581L20.4046 18.1852C21.0026 18.0656 21.5509 17.7725 21.9826 17.3408L30.3344 8.98891C30.5854 8.73794 30.7845 8.43999 30.9203 8.11207C31.0561 7.78416 31.126 7.4327 31.126 7.07777C31.126 6.72284 31.0561 6.37138 30.9203 6.04346C30.7845 5.71555 30.5854 5.4176 30.3344 5.16662C30.0834 4.91565 29.7855 4.71656 29.4576 4.58074C29.1297 4.44491 28.7782 4.375 28.4233 4.375C28.0683 4.375 27.7169 4.44491 27.389 4.58074C27.0611 4.71656 26.7631 4.91565 26.5121 5.16662Z"
                      stroke="white"
                      stroke-width="3.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28.209 21.8763V26.2513C28.209 27.0249 27.9017 27.7667 27.3547 28.3137C26.8077 28.8607 26.0659 29.168 25.2923 29.168H9.25065C8.4771 29.168 7.73524 28.8607 7.18826 28.3137C6.64128 27.7667 6.33398 27.0249 6.33398 26.2513V10.2096C6.33398 9.43609 6.64128 8.69422 7.18826 8.14724C7.73524 7.60026 8.4771 7.29297 9.25065 7.29297H13.6257"
                      stroke="white"
                      stroke-width="3.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>Edit Card</div>
                </div>
                <div className="onetap_conn_expanded_card" onClick={() => redirectToViewCard(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M13.1003 9.75C12.2383 9.75 11.4117 10.0924 10.8022 10.7019C10.1927 11.3114 9.85026 12.138 9.85026 13C9.85026 13.862 10.1927 14.6886 10.8022 15.2981C11.4117 15.9076 12.2383 16.25 13.1003 16.25C13.9622 16.25 14.7889 15.9076 15.3984 15.2981C16.0078 14.6886 16.3503 13.862 16.3503 13C16.3503 12.138 16.0078 11.3114 15.3984 10.7019C14.7889 10.0924 13.9622 9.75 13.1003 9.75ZM13.1003 18.4167C11.6637 18.4167 10.2859 17.846 9.2701 16.8302C8.25427 15.8143 7.68359 14.4366 7.68359 13C7.68359 11.5634 8.25427 10.1857 9.2701 9.16984C10.2859 8.15402 11.6637 7.58333 13.1003 7.58333C14.5368 7.58333 15.9146 8.15402 16.9304 9.16984C17.9462 10.1857 18.5169 11.5634 18.5169 13C18.5169 14.4366 17.9462 15.8143 16.9304 16.8302C15.9146 17.846 14.5368 18.4167 13.1003 18.4167ZM13.1003 4.875C7.68359 4.875 3.05776 8.24417 1.18359 13C3.05776 17.7558 7.68359 21.125 13.1003 21.125C18.5169 21.125 23.1428 17.7558 25.0169 13C23.1428 8.24417 18.5169 4.875 13.1003 4.875Z"
                      fill="white"
                    />
                  </svg>
                  <div>View Card</div>
                </div>

                <div className="onetap_conn_expanded_card card4" onClick={() => toggleSharePopup(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                  >
                    <path
                      d="M20.4004 23.8333C19.4976 23.8333 18.7303 23.5173 18.0983 22.8854C17.4664 22.2534 17.1504 21.4861 17.1504 20.5833C17.1504 20.4569 17.1594 20.3258 17.1775 20.19C17.1955 20.0543 17.2226 19.9326 17.2587 19.825L9.62122 15.3833C9.31428 15.6541 8.97122 15.8665 8.59206 16.0203C8.21289 16.1741 7.81567 16.2507 7.40039 16.25C6.49761 16.25 5.73025 15.934 5.09831 15.302C4.46636 14.6701 4.15039 13.9027 4.15039 13C4.15039 12.0972 4.46636 11.3298 5.09831 10.6979C5.73025 10.0659 6.49761 9.74996 7.40039 9.74996C7.81567 9.74996 8.21289 9.82688 8.59206 9.98071C8.97122 10.1345 9.31428 10.3465 9.62122 10.6166L17.2587 6.17496C17.2226 6.06663 17.1955 5.94493 17.1775 5.80988C17.1594 5.67482 17.1504 5.54374 17.1504 5.41663C17.1504 4.51385 17.4664 3.74649 18.0983 3.11454C18.7303 2.4826 19.4976 2.16663 20.4004 2.16663C21.3032 2.16663 22.0705 2.4826 22.7025 3.11454C23.3344 3.74649 23.6504 4.51385 23.6504 5.41663C23.6504 6.3194 23.3344 7.08676 22.7025 7.71871C22.0705 8.35065 21.3032 8.66663 20.4004 8.66663C19.9851 8.66663 19.5879 8.59007 19.2087 8.43696C18.8296 8.28385 18.4865 8.07151 18.1796 7.79996L10.5421 12.2416C10.5782 12.35 10.6053 12.472 10.6233 12.6078C10.6414 12.7436 10.6504 12.8743 10.6504 13C10.6504 13.1263 10.6414 13.2574 10.6233 13.3932C10.6053 13.529 10.5782 13.6507 10.5421 13.7583L18.1796 18.2C18.4865 17.9291 18.8296 17.7172 19.2087 17.564C19.5879 17.4109 19.9851 17.334 20.4004 17.3333C21.3032 17.3333 22.0705 17.6493 22.7025 18.2812C23.3344 18.9132 23.6504 19.6805 23.6504 20.5833C23.6504 21.4861 23.3344 22.2534 22.7025 22.8854C22.0705 23.5173 21.3032 23.8333 20.4004 23.8333Z"
                      fill="white"
                    />
                  </svg>
                  <div>Share Card</div>
                </div>
                <div className="onetap_conn_expanded_card card5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                  >
                    <path
                      d="M4.05029 22.5V20H26.5503V22.5H4.05029ZM4.05029 16.25V13.75H26.5503V16.25H4.05029ZM4.05029 10V7.5H26.5503V10H4.05029Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                  <div>More</div>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow style={{ height: "10px" }}>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="userinfo_pagetabl"
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="userinfo_pagetabl"
          ></TableCell>
          <TableCell
            className="userinfo_pagetabl"
            style={{ backgroundColor: "white", borderBottom: "none" }}
          ></TableCell>
          <TableCell
            style={{ backgroundColor: "white", borderBottom: "none" }}
            className="userinfo_pagetabl"
          ></TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      group: PropTypes.number.isRequired,
      location: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
    }).isRequired,
  };

  const getUniqueTeams = () => {
    const uniqueTeams = Array.from(new Set(rows?.map((row) => row.group)));
    return uniqueTeams?.filter(
      (group, index, self) => self.indexOf(group) === index
    );
  };
  const getUniqueLocations = () => {
    const uniqueLocations = Array.from(
      new Set(rows?.map((row) => row.location))
    );
    return uniqueLocations?.filter(
      (location, index, self) => self.indexOf(location) === index
    );
  };
  const getUniqueStatus = () => {
    const uniqueStatus = Array.from(new Set(rows?.map((row) => row.status)));
    return uniqueStatus?.filter(
      (status, index, self) => self.indexOf(status) === index
    );
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 

  const filteredUsers =
    users &&
    users
      ?.filter((user) => user.status === 'active') // Filter only active status users
      .filter((user) => {
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        const designation = user.designation.join(' | ').toLowerCase();
        return (
          fullName.includes(searchQueryInModal.toLowerCase()) ||
          designation.includes(searchQueryInModal.toLowerCase())
        );
      }).filter((user) => !seletedUser.includes(user._id));


  const handleUserCheckboxChangeForDiv = (user) => {
    setSelectedUserForRedirect([user]); // Select only one user
    setIsSaveButtonDisabled(false);
  };

  const handleRedirectToAnotherUser = () => {
    handleDeactivateStatus();
    setIsSaveButtonDisabled(true);
  }

  const handleInactiveStatus = () => {
    if (seletedUser.length <= 0) {
      alert("Please select at least one user");
      return;
    }

    setOpenActiveModel(true);
    setDropdownOpen(false);
  }

  const handleRedirectToBillingPage = () => {
    navigate("/billing", {
      state: { popupValue: "openpopup" }
    });
  }

  return (
    <>
      <>
        <div className="onetap_conn_users_tab_content">
          <div className="onetap_conn_users_tap_filter">
            <div className="onetap_conn_filter_search">
              <input
                type="text"
                name="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ paddingRight: '50px' }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                className="onetap_conn_filter_svg"
              >
                <path
                  d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                  fill="#000000"
                />
              </svg>
            </div>
            <div className="onetap_conn_filter_search">
              <select
                name="teams"
                id="teams"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                <option value="Teams">Teams</option>
                {getUniqueTeams()?.map((group) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={group}
                    value={group}
                  >
                    {group?.charAt(0).toUpperCase() + group?.slice(1)}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
            <div className="onetap_conn_filter_search">
              <select
                name="location"
                id="location"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <option value="Location">Location</option>
                {getUniqueLocations()?.map((location) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={location}
                    value={location}
                  >
                    {location}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
            <div className="onetap_conn_filter_search">
              <select
                name="status"
                id="status"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                }}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="Status">Status</option>
                {getUniqueStatus()?.map((status) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={status}
                    value={status}
                  >
                    {status?.charAt(0).toUpperCase() + status?.slice(1)}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </div>
          {isFilterApplied && (
            <button style={filterbtnStyle} onClick={clearFilters}>
              Clear Filters
            </button>
          )}

          {users_loading ? (
            <Loader />
          ) : (<div>
            <TableContainer
              style={{ borderRadius: "0px", boxShadow: "none", overflowX: 'visible' }}
            >
              <Table aria-label="collapsible table">
                <TableHead sx={{ backgroundColor: "white !important" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingLeft: "0px",
                        width: "40%",
                        color: "#333",
                        fontSize: "24px",
                        fontWeight: 600,
                        borderBottom: "0px",
                      }}
                    >
                      Users
                    </TableCell>
                    <TableCell align="center" style={{ borderBottom: "0px" }}>
                      Team
                    </TableCell>
                    <TableCell align="center" style={{ borderBottom: "0px" }}>
                      Location
                    </TableCell>
                    <TableCell align="center" style={{ borderBottom: "0px" }}>
                      Status
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        padding: "0px",
                        borderBottom: "0px",
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "5px",
                      }}
                    >
                      <div
                        style={{ position: "relative", width: "200px" }}
                        ref={dropdownRef}
                      >
                        <div
                          className="onetap_conn_table_action_header"
                          onClick={toggleDropdown}
                        >
                          <div>Actions</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                          </svg>
                        </div>
                        {isDropdownOpen && (
                          <div className="onetap_conn_table_action_header_dropdown">
                            <div
                              className="onetap_conn_table_action_header_dropdown_option"
                              onClick={handleAllCheckboxChange}
                            >
                              {seletedUser?.length >= 1
                                ? "Deselect All"
                                : "Select All"}
                            </div>
                            <div
                              className="onetap_conn_table_action_header_dropdown_option"
                              onClick={handleActivateStatus}
                            >
                              Activate
                            </div>
                            <div
                              className="onetap_conn_table_action_header_dropdown_option"
                              onClick={handleInactiveStatus}
                            >
                              Deactivate
                            </div>
                            <div
                              className="onetap_conn_table_action_header_dropdown_option"
                              onClick={() => {
                                if (seletedUser.length <= 0) {
                                  alert("Please select at least one user and one team");
                                  return;
                                }
                                setModalOpen(true);
                                setDropdownOpen(false);
                              }}
                            >
                              Add to team
                            </div>
                            <div
                              className="onetap_conn_table_action_header_dropdown_option"
                              onClick={handleRemovefromTeam}
                            >
                              Remove from team
                            </div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >

                  {filterRows(rows)?.length > 0 ? (
                    filterRows(rows).map((row) => (
                      <Row key={row.id} row={row} />
                    ))
                  ) : (
                    <div style={{ backgroundColor: "white" }}>
                      No Users Available
                    </div>
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          </div>)}

        </div>
        <Modal
          open={apiSuccess}
          onClose={() => setApiSuccess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
            <div className="Modal-section">
              <div className="checkmark-container">
                <div class="checkmark-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                    class="circle">
                    <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                      fill="#E65925" />
                    <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                  </svg>
                </div>
              </div>
              <span className="team_created_success">{successMessage}</span>
            </div>
            <button
              className="Modal-btn sec-modal-btn"
              style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
              onClick={() => setApiSuccess(false)}
            >
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
            </button>
          </Box>
        </Modal>
        <Modal
          open={isModalOpen}
          onClose={() => { setModalOpen(false); setSelectDropdwonTeam(null) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="Modal-section">
              <h2 className="header">
                Select Team{" "}
                <CloseIcon
                  className="icon"
                  onClick={() => { setModalOpen(false); setSelectDropdwonTeam(null) }}
                />
              </h2>

              <span className="Team-Name-Text">Search team</span>
              <br />
              <Select
                // isMulti
                options={teamOptions}
                value={selectDropdownTeam}
                onChange={handleTeamChange}
                placeholder="Select teams..."
                styles={dropdownStyles}
              />
              <div className="Modal-btn-group">
                <button
                  className="Modal-btn first-modal-btn"
                  onClick={handleAddtoTeam}
                >
                  Add to team
                </button>
                <button
                  className="Modal-btn sec-modal-btn"
                  onClick={() => { setModalOpen(false); setSelectDropdwonTeam(null) }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openActiveModel}
          onClose={() => {
            setOpenActiveModel(false);
            setSelectedUserForRedirect([]);
            setIsSaveButtonDisabled(true);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleOfTeamOverview}>
            <div className="Modal-section">
              <div className="header">
                <div className="main_header">
                  Redirect visitor to:
                </div>

                <div className="sub_header">
                  To make sure your company never loose a prospect or client, the microsite link of the deactivated user(s) will be redirect to another user.
                </div>
              </div>

              <div className="onetap_conn_users_tab_content_addTeam">
                <div className="onetap_conn_filter_search">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search user"
                    value={searchQueryInModal}
                    onChange={(e) => setSearchQueryInModal(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    className="onetap_conn_filter_svg"
                  >
                    <path
                      d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                      fill="#000000"
                    />
                  </svg>
                </div>
                <div className="addUserText">Add users</div>
                <div className="mappingdiv">
                  {filteredUsers &&
                    filteredUsers?.map((user, index) => {
                      return (
                        <div
                          key={index}
                          className={`select-card-users ${selectedUserForRedirect?.some(
                            (item) => item._id === user._id
                          )
                            ? "selected"
                            : ""
                            }`}
                          onClick={() => {
                            handleUserCheckboxChangeForDiv(user);
                          }}
                        >
                          <div className="info-page">
                            <div className="header-avatar-container">
                              {user?.avatar ? (
                                <Avatar
                                  style={{ width: "48px", height: "48px" }}
                                  src={`${url}/profile/img/${user?.avatar}`}
                                  alt={`${user?.first_name} ${user?.last_name}`}
                                />
                              ) : (
                                <div
                                  className="header-avatar-initials-overlay"
                                  style={{ width: "48px", height: "48px" }}
                                >
                                  {`${user?.first_name[0]?.toUpperCase()}${user?.last_name[0]?.toUpperCase()}`}
                                </div>
                              )}
                            </div>
                            <div className="select-card-users-info">
                              <p className="p1">{`${user?.first_name} ${user?.last_name}`}</p>
                              <p className="p2">
                                {user?.designation.join(" | ")}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="Modal-btn-group" style={{ marginTop: "0px" }}>
                <button
                  className={`Modal-btn first-modal-btn ${isSaveButtonDisabled ? 'disabled' : ''}`}
                  onClick={() => {
                    handleRedirectToAnotherUser();
                  }}
                  disabled={isSaveButtonDisabled}
                >
                  Save
                </button>
                <button
                  className="Modal-btn sec-modal-btn"
                  onClick={() => {
                    setOpenActiveModel(false);
                    setSelectedUserForRedirect([]);
                    setIsSaveButtonDisabled(true);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openAddUserModel}
          onClose={() => { setOpenAddUserModel(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="Modal-section">
              <p style={{ marginBottom: "0px" }}>You have reached your user limitations. If you activate this user, you can purchase extra users. Please click 'Continue' to proceed to checkout.</p>
              <div className="Modal-btn-group">
                <button
                  className="Modal-btn first-modal-btn"
                  onClick={handleRedirectToBillingPage}
                >
                  Continue
                </button>
                <button
                  className="Modal-btn sec-modal-btn"
                  onClick={() => { setOpenAddUserModel(false) }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        {showSharePopup && <UserPopup onClose={toggleSharePopup} userId={ids} />}

        {apiError && (
          <ErrorModal
            errorMessage={errorMessage}
            onClose={() => setApiError(false)}
          />
        )}
      </>

    </>
  );
};

export default UsersInfoPage;
