import React, { useEffect } from "react";
import Header from '../../../components/Layout/Header/Header2'
import { useSelector, useDispatch } from "react-redux";
import {
    getProducts,
} from "../../../actions/userAction/userAction";
import Loader from "../../../components/Layout/Loader/Loader";
import "./ShopSmartAccessories.css"
import AccessoriesCard from "./AccessoriesCard"

const ShopSmartAccessories = () => {
    const dispatch = useDispatch();
    const { user, loading } = useSelector(
        (state) => state.products
    );
    useEffect(() => {
        dispatch(getProducts());
    }, [])

    return (
        <>

            <div className=" mb-5" style={{ backgroundColor: "" }}>
                <Header />
                <div className="container mt-4">
                    <div className="text-center" style={{ padding: "80px 50px 40px 50px" }}>
                        <h1 className="SSA_heading">OneTapConnect Smart Accessories</h1>
                        <h5 style={{ fontWeight: "600" }}>Experience the power of networking like never before.</h5>
                        <div className="d-flex align-items-center justify-content-center">
                            <h6 className="col-8">OneTapConnect in the industry leading digital business card for professional and businesses. All OneTapConnect smart accessories are NFC devices that connect to your digital card. </h6>
                        </div>
                        <h5 style={{ fontWeight: "600" }}>Don’t have an account yet?
                            <span style={{ color: '#E65925' }}> Purchase a smart accessories and get a free personal digital card!</span> </h5>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="gap-3">
                            <AccessoriesCard />
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};

export default ShopSmartAccessories;


