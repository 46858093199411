import React, { useState, useEffect, useRef } from "react";
import "../../components/Customers/User/user.css";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Country, State } from "country-state-city";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TableRow from "@mui/material/TableRow";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Layout/Loader/Loader";
import { Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon =     <svg
xmlns="http://www.w3.org/2000/svg"
width="80"
height="80"
viewBox="0 0 80 80"
fill="none"
class="circle"
>
<path
  d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
  fill="#E65925"
/>
<path
  d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
  stroke="white"
  stroke-width="6.64"
  stroke-linecap="round"
  class="checkmark-path"
/>
</svg>

const ClientsInfo = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tagssearchQuery, setTagssearchQuery] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("Industry");
  const [selectedTeam, setSelectedTeam] = useState("Teams");
  const [selectedCountry, setSelectedCountry] = useState("Country");
  const [selectedCity, setSelectedCity] = useState("City");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("Plan");
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [rows, setRows] = useState([]);
  const [companyData, setCompanydata] = useState([]);
  const [seletedUser, setSelectedUser] = useState([]);
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [loading, setLoading] = useState(false);
  const [allCompanyWithUserCounts, setAllCompanyWithUserCounts] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // Define an async function for the API call
  const fetchClientsWithPlan = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${url}/admin/allclients`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });setLoading(false);
      setRows(convertAPIDataToRow(response.data))
    } catch (error) {
    } finally{
      setLoading(false);
    }
  };

  // Define an async function for the API call
  const getActiveAndDeactiveUserOfCompany = async () => {
    try {
      const response = await axios.get(`${url}/admin/getActiveUsers`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }); // Replace with your actual API endpoint
      // 
      setAllCompanyWithUserCounts(response?.data)
    } catch (error) {
      ;
    }
  };

  useEffect(() => {
    fetchClientsWithPlan();
  }, []);

  useEffect(() => {
    getActiveAndDeactiveUserOfCompany()
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getUniqueIndustry = () => {
    const uniqueIndustry = Array.from(new Set(rows?.map((row) => row.industry)));
    return uniqueIndustry?.filter(
      (industry, index, self) => self.indexOf(industry) === index
    );
  };
  const getUniqueCountry = () => {
    const uniqueCountry = Array.from(new Set(rows?.map((row) => row?.country)));
    return uniqueCountry?.filter(
      (country, index, self) => self.indexOf(country) === index
    );
  };
  const getUniqueCity = () => {
    const uniqueCity = Array.from(new Set(rows?.map((row) => row?.city)));
    return uniqueCity?.filter(
      (city, index, self) => self.indexOf(city) === index
    );
  };
  const getUniquePlan = () => {
    const uniquePlan = Array.from(new Set(rows?.map((row) => row?.plan)));
    return uniquePlan?.filter(
      (plan, index, self) => self.indexOf(plan) === index
    );
  };
  const getUniqueStatus = () => {
    const uniqueStatus = Array.from(new Set(rows?.map((row) => row?.status)));
    return uniqueStatus?.filter(
      (status, index, self) => self.indexOf(status) === index
    );
  };

  const dropdownStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F2F2F2",
      marginBottom: "45px",
      padding: "10px 0",
      border: state.isFocused ? "none" : "#F2F2F2",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
      borderRadius: "5px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "25px",
    }),
  };
  const clearFilters = () => {
    setSelectedCity("City");
    setSelectedCountry("Country");
    setSelectedStatus("Status");
    setSelectedIndustry("Industry");
    setSelectedPlan("Plan");
    setSelectedDate(null);
    setShowMoreFilters(false)
    setTagssearchQuery("")
    // Reset any other filter states as needed

    // You may also want to trigger any functions to refetch or update data here
  };
  const isFilterApplied =
    selectedCountry !== "Country" ||
    selectedCity !== "City" ||
    selectedStatus !== "Status" ||
    selectedIndustry !== "Industry" ||
    selectedPlan !== "Plan" ||
    selectedDate !== null ||
    tagssearchQuery !== ""

  useEffect(() => {
    if (isFilterApplied) {
      setSelectedUser([]);
    }
  }, [isFilterApplied]);

  const filterbtnStyle = {
    padding: "3px 15px",
    backgroundColor: "#e65925", // Change to your desired button color
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    display: isFilterApplied ? "block" : "none", // Show the button conditionally
    marginLeft: "auto",
    marginBottom: "auto",
    height: "30px",
  };
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 
  function createData(id, avatar, name, role, status, userrole, city, country, createdAt, plan, industry, avatarurl, userid, companyID, renewalDate, TotalAmount,usertags,unq_compnany_id) {
    return {
      id,
      avatar,
      userid,
      companyID,
      name,
      role,
      status,
      userrole,
      city,
      country,
      createdAt,
      plan,
      avatarurl,
      industry,
      renewalDate,
      TotalAmount,
      usertags,
      unq_compnany_id
    };
  }
  
  // Create a function to convert API response data to the desired format
  function convertAPIDataToRow(apiData) {
    const clientsData = apiData.userInformationTeamData?.map((item) => {
      const id = item?.subscription_details?.plan === "Team" ? `${item?.company_ID?._id}` : `${item?.user_id?._id}`;
      const renewalDate = item?.subscription_details?.renewal_date || null;
      const TotalAmount = item?.subscription_details?.total_amount || null;
      const usertagsarray = item?.company_ID?.client_Tags && item?.company_ID?.client_Tags?.map((v)=>v?.value)
      const usertags = usertagsarray ? usertagsarray.join(" ") : "";
      const unq_compnany_id = item?.company_ID?.unq_compnany_id || null;
      const userid = item.company_ID?._id;
      const companyID = item.company_ID?._id;
      const avatar = item?.subscription_details?.plan === "Team" ? (item.company_ID?.logopath) : (item.user_id?.avatar);
      const name = item?.subscription_details?.plan === "Team" ? `${item.company_ID?.company_name}` : `${item?.user_id?.first_name} ${item?.user_id?.last_name}`;
      const userrole = item?.user_id?.role;
      const createdAt = item?.company_ID?.createdAt;
      const country = item?.user_id?.address.country ? Country.getCountryByCode(item?.user_id?.address.country)?.name : 'No Country';
      const city = item?.user_id?.address.city ? item?.user_id?.address.city : 'No City';
      const role = item?.user_id?.designation && item?.user_id?.designation[0] ? item?.user_id?.designation[0] : "- - - ";
      const status = item?.company_ID?.status ? item?.company_ID?.status : "";
      const industry = item?.company_ID?.industry ? item.company_ID?.industry : "No Industry";
      const fullName = name;
      const words = fullName.split(" ");
      const initials = words.slice(0, 2).map((word) => word.charAt(0).toUpperCase()).join("");
      const avatarurl = (avatar === item.company_ID?.logopath ? `${url}/logo/img/${item.company_ID?.logopath}` : avatar === item.user_id?.avatar ? `${url}/profile/img/${item.user_id?.avatar}` : { initials })
      const plan = item?.subscription_details?.plan;
      return createData(id, avatar, name, role, status, userrole, city, country, createdAt, plan, industry, avatarurl, userid, companyID, renewalDate, TotalAmount , usertags,unq_compnany_id);
    });
    return clientsData
  }


  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Define a state variable for filtered data
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    // Filter the rows based on searchQuery, selectedIndustry, selectedCountry, etc.
    const filteredData = rows.filter((row) => {
      const rowDate = row.createdAt ? new Date(row.createdAt) : null;
      const selectedMonthYear = selectedDate ? new Date(selectedDate) : null;

      const dateMatches =
        !selectedDate ||
        (rowDate &&
          selectedMonthYear &&
          rowDate.getMonth() === selectedMonthYear.getMonth() &&
          rowDate.getFullYear() === selectedMonthYear.getFullYear());
      return (
        row.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        row.usertags?.toLowerCase().includes(tagssearchQuery.toLowerCase()) &&
        (selectedCountry === "Country" || row.country === selectedCountry) &&
        (selectedCity === "City" || row.city === selectedCity) &&
        (selectedStatus === "Status" || row.status === selectedStatus) &&
        (selectedIndustry === "Industry" || row.industry === selectedIndustry) &&
        (selectedPlan === "Plan" || row.plan === selectedPlan) &&
        dateMatches
      );
    });

    setFilteredRows(filteredData);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [searchQuery, selectedIndustry, selectedCountry, selectedStatus, selectedCity, selectedPlan, selectedDate, rows , tagssearchQuery]);

  const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

  // Calculate the start and end index for pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredRows.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedUser([])
    }
  };

  const [displayedPages, setDisplayedPages] = useState([]);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedUser([...seletedUser, e.target.value]);
    } else {
      setSelectedUser(seletedUser?.filter((id) => id !== e.target.value));
    }
  };

  const handleAllCheckboxChange = (e) => {
    if (seletedUser?.length === 0) {
      // setSelectedUser(filteredRows?.map((row) => row.companyID));
      const currentPageRows = paginatedData.map((row) => row.companyID);
      setSelectedUser([...seletedUser, ...currentPageRows]);
      setDropdownOpen(false);
    } else {
      setSelectedUser([]);
      setDropdownOpen(false);
    }
  };
  // 
  const handleActivateStatus = async () => {
    if (seletedUser.length <= 0) {
      alert("Please select at least one user");
      return;
    }

    const updateData = {
      companies: seletedUser,
      status: "active",
    };

    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(`${url}/admin/company/update/status`, updateData, {
        withCredentials: true,
      });

      if (response.data.success) {
        setApiSuccess(true)
        setSuccessMessage('Status updated successfully.')
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        setSelectedUser([]);
        fetchClientsWithPlan()
      }
    } catch (error) {
      setSelectedUser([])
    }
  };

  const handleDeactivateStatus = async () => {
    if (seletedUser.length <= 0) {
      alert("Please select at least one user");
      return;
    }
    const updateData = {
      companies: seletedUser,
      status: "inactive",
    };
    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(`${url}/admin/company/update/status`, updateData, {
        withCredentials: true,
      });

      if (response.data.success) {
        // Handle success, e.g., show a success message or update local state
        // 
        setApiSuccess(true)
        setSuccessMessage('Status updated successfully.')
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        setSelectedUser([]);
        fetchClientsWithPlan()
      }
    } catch (error) {
      setSelectedUser([])
    }


  };

  useEffect(() => {
    if (totalPages <= 5) {
      // Display all pages if there are 5 or fewer
      setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else {
      // Determine which pages to display when there are more than 5 pages
      if (currentPage <= 3) {
        setDisplayedPages([1, 2, 3, 4, 5, '. . .', totalPages]);
      } else if (currentPage >= totalPages - 2) {
        setDisplayedPages([1, '. . .', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
      } else {
        setDisplayedPages([1, '. . .', currentPage - 1, currentPage, currentPage + 1, '. . .', totalPages]);
      }
    }
  }, [currentPage, totalPages]);
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const handleMenuOpen = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    };

    const handleMenuItemClick = () => {
      // Handle the click on menu items here
      setMenuOpen(false); // Close the menu after the click
    };

    const fullName = row.name; // Replace this with your variable
    const words = fullName.split(" ");
    const initialss = words.map((word) => word.charAt(0).toUpperCase());
    // 
    let initials; // Declare initials variable at the top level
    // Check if there are at least two words before joining their initials
    if (initialss?.length >= 2) {
      initials = initialss.slice(0, 2).join("");
    } else {
      initials = initialss.join("");
    }
    return (
      <>
        <React.Fragment>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell
              component="th"
              scope="row"
              className="onetap_conn_user_table_column1_OTC_Admin"
            >
              <div className="profile-info_OTC_Admin">
                <Link to={`/admin/client/profile/${row.userid}`} >
                  <div className="user-info-avatar-container_OTC_Admin">
                    {row?.avatar ? (
                      <img
                        src={row?.avatarurl}
                        alt="Profile"
                        style={{
                          width: "54px",
                          height: "54px",
                          borderRadius: "100%",
                        }}
                      />
                    ) : (
                      <div
                        className="user-info-initials-overlay_OTC_Admin"
                        style={{ width: "54px", height: "54px" }}
                      >
                        {initials}
                      </div>
                    )}
                  </div>
                </Link>

                <div class="details_OTC_Admin">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    to={`/admin/client/profile/${row.userid}`}
                  >
                    <p style={{ textTransform: "capitalize", fontSize: '16px' }}>{row.name}</p>
                  </Link> 
                  <p className="column1_text_OTC_Admin">ID:{row.unq_compnany_id}</p>
                </div>
              </div>
            </TableCell>

            <TableCell
              align="center"
              style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
            >
              {row?.createdAt ? new Date(row.createdAt).toLocaleDateString() : ''}
            </TableCell>
            <TableCell
              align="center"
              style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
            >
              {row?.plan}

            </TableCell>
            <TableCell
              align="center"
              style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
            >
              {row?.industry}
            </TableCell>
            <TableCell
              align="center"
              style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
            >
              {row?.city}
            </TableCell>
            <TableCell
              align="center"
              style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
            >
              {row?.country}
            </TableCell>
            <TableCell
              align="center"
              style={{
                borderBottom: "none",
                color: row.status === "active" ? "#5CE12F" : "#E62525",
                textTransform: "capitalize", fontSize: "14px"
              }}
            >
              {row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
            </TableCell>
            <TableCell className="onetap_conn_user_table_column5_OTC_Admin">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="action_check"
                    style={{ margin: "0px 11px", cursor: 'pointer' }}
                    checked={seletedUser.includes(row.companyID)}
                    value={row.companyID}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    style={{ color: "#000000" }}
                    disableElevation
                    disableRipple
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "none",
              }}
              colSpan={8}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="onetap_conn_expanded_row_card_OTC_Admin">
                  <div className="onetap_conn_expanded_card_OTC_Admin card1_OTC_Admin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <g clip-path="url(#clip0_988_12294)">
                        <path d="M12.3359 5.5C13.2642 5.5 14.1544 5.86875 14.8108 6.52513C15.4672 7.1815 15.8359 8.07174 15.8359 9C15.8359 9.92826 15.4672 10.8185 14.8108 11.4749C14.1544 12.1313 13.2642 12.5 12.3359 12.5C11.4077 12.5 10.5174 12.1313 9.86106 11.4749C9.20469 10.8185 8.83594 9.92826 8.83594 9C8.83594 8.07174 9.20469 7.1815 9.86106 6.52513C10.5174 5.86875 11.4077 5.5 12.3359 5.5ZM5.33594 8C5.89594 8 6.41594 8.15 6.86594 8.42C6.71594 9.85 7.13594 11.27 7.99594 12.38C7.49594 13.34 6.49594 14 5.33594 14C4.54029 14 3.77723 13.6839 3.21462 13.1213C2.65201 12.5587 2.33594 11.7957 2.33594 11C2.33594 10.2044 2.65201 9.44129 3.21462 8.87868C3.77723 8.31607 4.54029 8 5.33594 8ZM19.3359 8C20.1316 8 20.8946 8.31607 21.4573 8.87868C22.0199 9.44129 22.3359 10.2044 22.3359 11C22.3359 11.7957 22.0199 12.5587 21.4573 13.1213C20.8946 13.6839 20.1316 14 19.3359 14C18.1759 14 17.1759 13.34 16.6759 12.38C17.5478 11.2544 17.9525 9.8362 17.8059 8.42C18.2559 8.15 18.7759 8 19.3359 8ZM5.83594 18.25C5.83594 16.18 8.74594 14.5 12.3359 14.5C15.9259 14.5 18.8359 16.18 18.8359 18.25V20H5.83594V18.25ZM0.335938 20V18.5C0.335938 17.11 2.22594 15.94 4.78594 15.6C4.19594 16.28 3.83594 17.22 3.83594 18.25V20H0.335938ZM24.3359 20H20.8359V18.25C20.8359 17.22 20.4759 16.28 19.8859 15.6C22.4459 15.94 24.3359 17.11 24.3359 18.5V20Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_988_12294">
                          <rect width="24" height="24" fill="white" transform="translate(0.335938)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>Active users</div>
                    <div>
                      {(row?.plan === 'Professional' || row?.plan === 'Free') ? 1 :
                        (allCompanyWithUserCounts?.find(item => item.company === row.id)?.activeUserIds?.length || 0)
                      }
                    </div>
                  </div>
                  <div className="onetap_conn_expanded_card_OTC_Admin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                      <g clip-path="url(#clip0_988_12998)">
                        <path d="M1.45521 0.191217C1.06521 -0.116283 0.498961 -0.0450334 0.191461 0.344967C-0.116039 0.734967 -0.0447892 1.30122 0.345211 1.60872L22.5452 19.0087C22.9352 19.3162 23.5015 19.245 23.809 18.855C24.1165 18.465 24.0452 17.8987 23.6552 17.5912L16.5227 12H23.2015C23.644 12 24.0002 11.64 24.0002 11.2012C24.0002 8.99247 22.2077 7.19997 19.999 7.19997H18.4015C17.8052 7.19997 17.239 7.33122 16.729 7.56372C16.7777 7.83372 16.8002 8.11497 16.8002 8.39997C16.8002 9.53247 16.4065 10.575 15.7502 11.3962L14.8052 10.6575C15.304 10.0387 15.6002 9.25497 15.6002 8.39997C15.6002 6.41247 13.9877 4.79997 12.0002 4.79997C10.834 4.79997 9.79896 5.35497 9.13896 6.21372L7.61646 5.02122C8.10396 4.48872 8.40021 3.77997 8.40021 2.99997C8.40021 1.34247 7.05771 -3.33539e-05 5.40021 -3.33539e-05C4.36146 -3.33539e-05 3.44646 0.528717 2.90646 1.33122L1.45521 0.191217ZM4.00146 7.19997C1.79271 7.19997 0.000210787 8.99247 0.000210787 11.2012C0.000210787 11.64 0.360211 12 0.798961 12H8.82771C8.05521 11.3175 7.50771 10.395 7.29771 9.34497L4.56771 7.19997H4.00146ZM9.79896 13.2C7.03896 13.2 4.80021 15.4387 4.80021 18.1987C4.80021 18.75 5.24646 19.2 5.80146 19.2H18.199C18.5927 19.2 18.9302 18.975 19.0952 18.645L12.184 13.2H9.79896ZM19.2002 5.99997C19.9959 5.99997 20.7589 5.6839 21.3215 5.12129C21.8841 4.55868 22.2002 3.79562 22.2002 2.99997C22.2002 2.20432 21.8841 1.44126 21.3215 0.878646C20.7589 0.316037 19.9959 -3.33539e-05 19.2002 -3.33539e-05C18.4046 -3.33539e-05 17.6415 0.316037 17.0789 0.878646C16.5163 1.44126 16.2002 2.20432 16.2002 2.99997C16.2002 3.79562 16.5163 4.55868 17.0789 5.12129C17.6415 5.6839 18.4046 5.99997 19.2002 5.99997Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_988_12998">
                          <rect width="24" height="19.2" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>Inactive users</div>
                    {(row?.plan === 'Professional' || row?.plan === 'Free') ? 0 :
                      (allCompanyWithUserCounts?.find(item => item.company === row.id)?.inactiveUserIds?.length || 0)
                    }

                  </div>
                  <div className="onetap_conn_expanded_card_OTC_Admin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="26"
                      viewBox="0 0 27 26"
                      fill="none"
                    >
                      <path
                        d="M3.9502 23.8333V8.66663H8.28353V23.8333H3.9502ZM11.5335 23.8333V2.16663H15.8669V23.8333H11.5335ZM19.1169 23.8333V15.1666H23.4502V23.8333H19.1169Z"
                        fill="#F2F2F2"
                      />
                    </svg>
                    <div>Monthly views</div>
                    <p></p>
                  </div>
                  <div className="onetap_conn_expanded_card_OTC_Admin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <g clip-path="url(#clip0_988_12304)">
                        <path d="M2.83594 15H10.3359V16.25H1.58594V1.25H4.08594V0H5.33594V1.25H14.0859V0H15.3359V1.25H17.8359V10H16.5859V6.25H2.83594V15ZM2.83594 2.5V5H16.5859V2.5H15.3359V3.75H14.0859V2.5H5.33594V3.75H4.08594V2.5H2.83594ZM20.3359 11.25V15H16.5859V13.75H18.4414C18.1484 13.3594 17.7839 13.0534 17.3477 12.832C16.9115 12.6107 16.4492 12.5 15.9609 12.5C15.5768 12.5 15.2155 12.5651 14.877 12.6953C14.5384 12.8255 14.2292 13.0046 13.9492 13.2324C13.6693 13.4603 13.4414 13.737 13.2656 14.0625C13.0898 14.388 12.9661 14.7363 12.8945 15.1074L11.6543 14.8926C11.7454 14.3652 11.9212 13.8802 12.1816 13.4375C12.4421 12.9948 12.7611 12.6107 13.1387 12.2852C13.5163 11.9596 13.9492 11.7057 14.4375 11.5234C14.9258 11.3411 15.4336 11.25 15.9609 11.25C16.5534 11.25 17.1198 11.3639 17.6602 11.5918C18.2005 11.8197 18.6758 12.1517 19.0859 12.5879V11.25H20.3359ZM15.9609 18.75C16.3385 18.75 16.6999 18.6849 17.0449 18.5547C17.39 18.4245 17.6992 18.2454 17.9727 18.0176C18.2461 17.7897 18.474 17.513 18.6562 17.1875C18.8385 16.862 18.9622 16.5137 19.0273 16.1426L20.2676 16.3574C20.1764 16.8848 20.0007 17.3698 19.7402 17.8125C19.4798 18.2552 19.1608 18.6393 18.7832 18.9648C18.4056 19.2904 17.9727 19.5443 17.4844 19.7266C16.9961 19.9089 16.4883 20 15.9609 20C15.3685 20 14.8021 19.8861 14.2617 19.6582C13.7214 19.4303 13.2461 19.0983 12.8359 18.6621V20H11.5859V16.25H15.3359V17.5H13.4805C13.7734 17.8906 14.138 18.1966 14.5742 18.418C15.0104 18.6393 15.4727 18.75 15.9609 18.75Z" fill="white" />
                      </g>
                      <defs>
                        <clipPath id="clip0_988_12304">
                          <rect width="20" height="20" fill="white" transform="translate(0.335938)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>Renewal</div>
                    {row?.renewalDate ? new Date(row.renewalDate).toLocaleDateString() : null}
                  </div>
                  <div className="onetap_conn_expanded_card_OTC_Admin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clip-path="url(#clip0_988_12932)">
                        <path d="M12.8504 10.7143C16.4008 10.7143 19.279 9.4351 19.279 7.85714C19.279 6.27919 16.4008 5 12.8504 5C9.30004 5 6.42188 6.27919 6.42188 7.85714C6.42188 9.4351 9.30004 10.7143 12.8504 10.7143Z" stroke="white" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.42188 7.85718V16.4286C6.42188 18 9.27902 19.2858 12.8504 19.2858C16.4219 19.2858 19.279 18 19.279 16.4286V7.85718" stroke="white" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M19.2824 12.1429C19.2824 13.7143 16.4252 15 12.8538 15C9.28237 15 6.42522 13.7143 6.42522 12.1429M12.7109 2.14289C11.0416 1.11271 9.09855 0.614486 7.13951 0.71432C3.58237 0.71432 0.710938 2.00003 0.710938 3.57146C0.710938 4.41432 1.53951 5.17146 2.85379 5.71432" stroke="white" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.85379 14.2857C1.53951 13.7428 0.710938 12.9857 0.710938 12.1428V3.57141" stroke="white" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M2.85379 10C1.53951 9.45718 0.710938 8.70003 0.710938 7.85718" stroke="white" stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_988_12932">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div>Total payment</div>
                    {row?.TotalAmount}
                  </div>


                  <div className="onetap_conn_expanded_card_OTC_Admin card5_OTC_Admin" onClick={(e) => { setAnchorEl(e.currentTarget); setMenuOpen(true) }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="30"
                      viewBox="0 0 31 30"
                      fill="none"
                    >
                      <path
                        d="M4.05029 22.5V20H26.5503V22.5H4.05029ZM4.05029 16.25V13.75H26.5503V16.25H4.05029ZM4.05029 10V7.5H26.5503V10H4.05029Z"
                        fill="#F2F2F2"
                      />
                    </svg>
                    <div>More</div>
                  </div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => navigate(`/admin/client/profile/${row.userid}`)}>Client details</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Edit client</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Users</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Analytics</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Smart accessories</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Orders</MenuItem>
            <MenuItem onClick={handleMenuItemClick}>Subscription</MenuItem>
          </Menu>
          <TableRow style={{ paddingBottom: "10px" }}>
            <TableCell
              style={{ backgroundColor: "white", borderBottom: "none" }}
              className="userinfo_pagetabl_OTC_Admin"
            ></TableCell>
            <TableCell
              style={{ backgroundColor: "white", borderBottom: "none" }}
              className="userinfo_pagetabl_OTC_Admin"
            ></TableCell>
            <TableCell
              style={{ backgroundColor: "white", borderBottom: "none" }}
              className="userinfo_pagetabl_OTC_Admin"
            ></TableCell>
            <TableCell
              style={{ backgroundColor: "white", borderBottom: "none" }}
              className="userinfo_pagetabl_OTC_Admin"
            ></TableCell>
            <TableCell
              style={{ backgroundColor: "white", borderBottom: "none" }}
              className="userinfo_pagetabl_OTC_Admin"
            ></TableCell>
          </TableRow>
        </React.Fragment>
      </>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      group: PropTypes.number.isRequired,
      location: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      plan: PropTypes.string.isRequired,
      industry: PropTypes.string.isRequired,
      renewalDate: PropTypes.instanceOf(Date).isRequired
    }).isRequired,
  };
  const dropdownRef = useRef(null);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="onetap_conn_users_tab_content_OTC_Admin">
            <div className="onetap_conn_users_tap_filter_OTC_Admin">
              <div className="onetap_conn_filter_search_OTC_Admin">
                <input
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"

                >
                  <path
                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="onetap_conn_filter_search_OTC_Admin">
                <select
                  name="industry"
                  id="industry"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedIndustry}
                  onChange={(e) => setSelectedIndustry(e.target.value)}
                >
                  <option value="Industry">Industry</option>
                  {getUniqueIndustry()?.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={group}
                      value={group}
                    >
                      {group}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="onetap_conn_filter_search_OTC_Admin">
                <select
                  name="country"
                  id="country"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <option value="Country">Country</option>
                  {getUniqueCountry()?.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={group}
                      value={group}
                    >
                      {group}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="onetap_conn_filter_search_OTC_Admin">
                <select
                  name="status"
                  id="status"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="Status">Status</option>
                  {getUniqueStatus()?.map((group) => (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={group}
                      value={group}
                    >
                      {group?.charAt(0).toUpperCase() + group?.slice(1)}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div onClick={() => { setShowMoreFilters(!showMoreFilters) }} className="w-50 d-flex align-items-center justify-content-center" style={{ cursor: "pointer", color: '#E65925', borderRadius: '4px', border: '1px solid #E65925' }}>More filter</div>
            </div>
            {showMoreFilters &&
              <div className="onetap_conn_users_tap_filter_OTC_Admin">
                <div className="onetap_conn_filter_search_OTC_Admin">
                  {/* <input
                    type="date" // Use the "date" type for the date picker
                    name="date"
                    id="date"
                    style={{
                      outline: "none",
                      cursor: "pointer",
                    }}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      // label={'MM/YYYY'}
                      views={['month', 'year']}
                      format="MM YYYY"
                      className="datepickerfilter"
                    />
                  </LocalizationProvider>
                </div>

                <div className="onetap_conn_filter_search_OTC_Admin">
                  <select
                    name="city"
                    id="city"
                    style={{
                      appearance: "none",
                      outline: "none",
                      cursor: "pointer",
                    }}
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="City">City</option>
                    {getUniqueCity()?.map((group) => (
                      <option
                        style={{ textTransform: "capitalize" }}
                        key={group}
                        value={group}
                      >
                        {group?.charAt(0).toUpperCase() + group?.slice(1)}
                      </option>
                    ))}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    className="onetap_conn_filter_svg_OTC_Admin"
                  >
                    <path
                      d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                      fill="#000000"
                    />
                  </svg>
                </div>

                <div className="onetap_conn_filter_search_OTC_Admin">
                  <select
                    name="plan"
                    id="plan"
                    style={{
                      appearance: "none",
                      outline: "none",
                      cursor: "pointer",
                    }}
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                  >
                    <option value="Plan">Plan</option>
                    {getUniquePlan()?.map((group) => (
                      <option
                        style={{ textTransform: "capitalize" }}
                        key={group}
                        value={group}
                      >
                        {group}
                      </option>
                    ))}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                    className="onetap_conn_filter_svg_OTC_Admin"
                  >
                    <path
                      d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                      fill="#000000"
                    />
                  </svg>
                </div>

                <div className="onetap_conn_filter_search_OTC_Admin">
                <input
                  type="text"
                  name="search"
                  placeholder="Search Tags"
                  autoComplete="off"
                  value={tagssearchQuery}
                  onChange={(e) => setTagssearchQuery(e.target.value)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"

                >
                  <path
                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              </div>
            }
            {isFilterApplied && (
              <button style={filterbtnStyle} onClick={clearFilters}>
                Clear Filters
              </button>
            )}
            <div>
              <TableContainer
                style={{ borderRadius: "0px", boxShadow: "none" }}
              >
                <Table aria-label="collapsible table" style={{ overflowX: 'scroll' }}>
                  <TableHead sx={{ backgroundColor: "white !important" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingLeft: "0px",
                          width: "20%",
                          color: "#333",
                          fontSize: "24px",
                          fontWeight: 600,
                          borderBottom: "0px",
                        }}
                      >
                        Clients
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Created date
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Plan
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Industry
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        City
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Country
                      </TableCell>
                      <TableCell align="center" style={{ borderBottom: "0px" }}>
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0px",
                          borderBottom: "0px",
                          justifyContent: "end",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          style={{ position: "relative", width: "", marginRight: '0' }}
                          ref={dropdownRef}
                        >
                          <div
                            className="onetap_conn_table_action_header_OTC_Admin"
                            onClick={toggleDropdown}
                          >
                            <div className="me-2">Actions</div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                            </svg>
                          </div>
                          {isDropdownOpen && (
                            <div className="onetap_conn_table_action_header_dropdown_OTC_Admin">
                              <div
                                className="onetap_conn_table_action_header_dropdown_option_OTC_Admin"
                                onClick={handleAllCheckboxChange}
                              >
                                {seletedUser?.length >= 1
                                  ? "Deselect All"
                                  : "Select All"}
                              </div>
                              <div
                                className="onetap_conn_table_action_header_dropdown_option_OTC_Admin"
                                onClick={handleActivateStatus}
                              >
                                Activate
                              </div>
                              <div
                                className="onetap_conn_table_action_header_dropdown_option_OTC_Admin"
                                onClick={handleDeactivateStatus}
                              >
                                Deactivate
                              </div>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "#F2F2F2 !important" }}>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((row) => (
                        <Row key={row.id} row={row} />
                      ))
                    ) : (
                      <div style={{ backgroundColor: "white" }}>No Users Available</div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {
            totalPages > 1 && (
              <div className="" style={{ display: 'flex', justifyContent: 'flex-end',marginTop:'20px' }}>
                {
                  currentPage !== 1 ?
                    <ArrowBackIosNewIcon onClick={() => handlePageChange(currentPage - 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                    :
                    <ArrowBackIosNewIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />

                }
                {displayedPages.map((page, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(page)}
                    style={{
                      // Define your active button styles here
                      backgroundColor: currentPage === page ? '#e65925' : 'transparent',
                      color: currentPage === page ? 'white' : 'black',
                      border: 'none', // Add a border when the button is active
                      borderRadius: '4px',
                      padding: '4px 20px',
                      margin: '2px',
                    }}

                  >
                    {page}
                  </button>)
                )}
                {
                  currentPage !== totalPages ?
                    <ArrowForwardIosIcon onClick={() => handlePageChange(currentPage + 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                    :
                    <ArrowForwardIosIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />
                }
              </div>
            )
          }
            </div>
          </div>  
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: "center" }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
            {messageIcon}
              </div>
            </div>
            <span className="team_created_success">
            {successMessage}
            </span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              width: "13%",
            }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
            </span>
          </button>
        </Box>
      </Modal>
        </>
      )}
    </>
  );
};
export default ClientsInfo;