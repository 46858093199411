import React, { useEffect, useState } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loader from "../../components/Layout/Loader/Loader";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


function SubscriptionsInfo() {
    const url = process.env.REACT_APP_URL;
    const [subscriptions, setSubscriptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("Plan");
    const [selectedStatus, setSelectedStatus] = useState("Status");
    const [currentPage, setCurrentPage] = useState(1);
    const [seletedUser, setSelectedUser] = useState([]);
    const [displayedPages, setDisplayedPages] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredRows.length / itemsPerPage)

    const isFilterApplied =
        selectedStatus !== "Status" ||
        selectedPlan !== "Plan" ||
        selectedDate !== null

    const filterbtnStyle = {
        padding: "3px 15px",
        backgroundColor: "#e65925", 
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        display: isFilterApplied ? "block" : "none", 
        marginLeft: "auto",
        marginBottom: "auto",
        height: "30px",
    };

    const clearFilters = () => {
        setSelectedStatus("Status");
        setSelectedPlan("Plan");
        setSelectedDate(null);
    };

    useEffect(() => {
          const filteredData = subscriptions.filter((row) => {
            const rowDate = row.subscription_details.renewal_date ? new Date(row.subscription_details.renewal_date) : null;
            const selectedMonthYear = selectedDate ? new Date(selectedDate) : null;
      const fullName = `${row.first_name} ${row.last_name}`.toLowerCase();

            const dateMatches =
                !selectedDate ||
                (rowDate &&
                    selectedMonthYear &&
                    rowDate.getMonth() === selectedMonthYear.getMonth() &&
                    rowDate.getFullYear() === selectedMonthYear.getFullYear());
            return (
                fullName.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (selectedStatus === "Status" || row.status === selectedStatus) &&
                (selectedPlan === "Plan" || row.subscription_details.plan === selectedPlan) &&
                dateMatches
            );
        });
        setFilteredRows(filteredData);
        setCurrentPage(1)
    }, [searchQuery, selectedStatus, selectedPlan, subscriptions, selectedDate]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            setSelectedUser([])
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredRows.slice(startIndex, endIndex);

    useEffect(() => {
        if (totalPages <= 5) {
            setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
        } else {
            if (currentPage <= 3) {
                setDisplayedPages([1, 2, 3, 4, 5, '. . .', totalPages]);
            } else if (currentPage >= totalPages - 2) {
                setDisplayedPages([1, '. . .', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
            } else {
                setDisplayedPages([1, '. . .', currentPage - 1, currentPage, currentPage + 1, '. . .', totalPages]);
            }
        }
    }, [currentPage, totalPages]);

    const FetchSubscritionAdmin = async () => {
        try {
            const response = await axios.post(`${url}/admin/GetSubscriptionDetailsForAdmin`, {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            })
            setSubscriptions(response.data);
            setLoading(false)
        } catch (error) {
        }
    }

    useEffect(() => {
        FetchSubscritionAdmin()
    }, [])

    function Row(props) {
        const { row } = props;
        const fullname = row.first_name + row.last_name
        const words = fullname.split("")
        const initialss = words.map((word) => word.charAt(0).toUpperCase());
        let initials; 
        if (initialss?.length >= 2) {
            initials = initialss.slice(0, 2).join("");
        } else {
            initials = initialss.join("");
        }

        const getStatusStyles = (status) => {
            switch (status.toLowerCase()) {
                case 'active':
                    return { border: '1px solid #48AF25', backgroundColor: '#F0FEEB', borderRadius: '4px' };
                case 'expired':
                    return { border: '1px solid #DCDCDC', backgroundColor: '#DCDCDC' };
                case 'paused':
                    return { border: '1px solid #2572E6', backgroundColor: '#ECEEFD' };
                case 'on hold':
                    return { border: '1px solid #E65925', backgroundColor: '#FDEEE9' }
                case 'pending cancellation':
                    return { border: '1px solid #333', backgroundColor: '#FFF' }
                case 'cancelled':
                    return { border: '1px solid #DCDCDC', backgroundColor: '#DCDCDC' }
                default:
                    return { border: '1px solid gray', backgroundColor: 'lightgray' };
            }
        };

        const statusStyles = getStatusStyles('Active');
        const formatDate = (dateString) => {
            if (!dateString) return ''; 
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        };

        return (

            <React.Fragment>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell
                        component="th"
                        scope="row"
                        className="onetap_conn_user_table_column1_OTC_Admin"
                    >
                        <div className="profile-info_OTC_Admin">
                            <div className="user-info-avatar-container_OTC_Admin">
                                {row?.avatar ? (
                                    <img
                                        src={row?.avatarurl}
                                        alt="Profile"
                                        style={{
                                            width: "54px",
                                            height: "54px",
                                            borderRadius: "100%",
                                        }}
                                    />
                                ) : (
                                    <div
                                        className="user-info-initials-overlay_OTC_Admin"
                                        style={{ width: "54px", height: "54px" }}
                                    >
                                        {initials}
                                    </div>
                                )}
                            </div>
                            <div class="details_OTC_Admin">
                                <p className="column1_text_OTC_Admin" style={{ color: '#2572E6' }}>#{row.orderNumber}</p>
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "rgba(0, 0, 0, 0.87)",
                                    }}
                                    to={`/admin/subscriptiondetails/${row._id}`}
                                >
                                    <p style={{ textTransform: "capitalize", fontSize: '16px' }}>{row.first_name + row.last_name}</p>
                                </Link>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px", alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                    >
                        <div style={{ ...statusStyles, width: '150px', height: '50px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            {/* {row?.status.charAt(0).toUpperCase() + row.status?.slice(1)} */}
                            Active
                        </div>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
                    >
                        {formatDate(row?.subscription_details.renewal_date)}
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
                    >
                        ${row?.subscription_details.recurring_amount}
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{ borderBottom: "none", textTransform: "capitalize", fontSize: "14px" }}
                    >
                        {row?.subscription_details.plan}
                    </TableCell>
                </TableRow>
                <TableRow style={{ paddingBottom: "10px" }}>
                    <TableCell
                        style={{ backgroundColor: "white", borderBottom: "none" }}
                        className="userinfo_pagetabl_OTC_Admin"
                    ></TableCell>
                    <TableCell
                        style={{ backgroundColor: "white", borderBottom: "none" }}
                        className="userinfo_pagetabl_OTC_Admin"
                    ></TableCell>
                    <TableCell
                        style={{ backgroundColor: "white", borderBottom: "none" }}
                        className="userinfo_pagetabl_OTC_Admin"
                    ></TableCell>
                    <TableCell
                        style={{ backgroundColor: "white", borderBottom: "none" }}
                        className="userinfo_pagetabl_OTC_Admin"
                    ></TableCell>
                    <TableCell
                        style={{ backgroundColor: "white", borderBottom: "none" }}
                        className="userinfo_pagetabl_OTC_Admin"
                    ></TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
    Row.propTypes = {
        row: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.number.isRequired,
            plan: PropTypes.string.isRequired,
        }).isRequired,
    };

    const getUniqueStatus = () => {
        const uniqueStatus = [...new Set(subscriptions.map(subscription => subscription.status))];
        return uniqueStatus;
    };

    const getUniquePlan = () => {
        const uniquePlan = [...new Set(subscriptions.map(subscription => subscription.subscription_details.plan))];
        return uniquePlan;
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="onetap_conn_users_tab_content_OTC_Admin">
                        <div className="onetap_conn_users_tap_filter_OTC_Admin">
                            <div className="onetap_conn_filter_search_OTC_Admin">
                                <input
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    className="onetap_conn_filter_svg_OTC_Admin"

                                >
                                    <path
                                        d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                                        fill="#000000"
                                    />
                                </svg>
                            </div>
                            <div className="onetap_conn_filter_search_OTC_Admin">
                                <select
                                    name="status"
                                    id="status"
                                    style={{
                                        appearance: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                    }}
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="Status">Status</option>
                                    {getUniqueStatus()?.map((group) => (
                                        <option
                                            style={{ textTransform: "capitalize" }}
                                            key={group}
                                            value={group}
                                        >
                                            {group}
                                        </option>
                                    ))}
                                </select>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                    className="onetap_conn_filter_svg_OTC_Admin"
                                >
                                    <path
                                        d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                        fill="#000000"
                                    />
                                </svg>
                            </div>
                            <div className="onetap_conn_filter_search_OTC_Admin">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        views={['month', 'year']}
                                        format="MM YYYY"
                                        className="datepickerfilter"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="onetap_conn_filter_search_OTC_Admin">
                                <select
                                    name="plan"
                                    id="plan"
                                    style={{
                                        appearance: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                    }}
                                    value={selectedPlan}
                                    onChange={(e) => setSelectedPlan(e.target.value)}
                                >
                                    <option value="Plan">Plan</option>
                                    {getUniquePlan()?.map((group) => (
                                        <option
                                            style={{ textTransform: "capitalize" }}
                                            key={group}
                                            value={group}
                                        >
                                            {group}
                                        </option>
                                    ))}
                                </select>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                    className="onetap_conn_filter_svg_OTC_Admin"
                                >
                                    <path
                                        d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                        fill="#000000"
                                    />
                                </svg>
                            </div>
                        </div>
                        {isFilterApplied && (
                            <button style={filterbtnStyle} onClick={clearFilters}>
                                Clear Filters
                            </button>
                        )}
                        <div>
                            <TableContainer
                                style={{ borderRadius: "0px", boxShadow: "none" }}
                            >
                                <Table aria-label="collapsible table" style={{ overflowX: 'scroll' }}>
                                    <TableHead sx={{ backgroundColor: "white !important" }}>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingLeft: "0px",
                                                    width: "20%",
                                                    color: "#333",
                                                    fontSize: "24px",
                                                    fontWeight: 600,
                                                    borderBottom: "0px",
                                                }}
                                            >
                                                Subscriptions
                                            </TableCell>
                                            <TableCell align="center" style={{ borderBottom: "0px" }}>
                                                Status
                                            </TableCell>
                                            <TableCell align="center" style={{ borderBottom: "0px" }}>
                                                Renewal Date
                                            </TableCell>
                                            <TableCell align="center" style={{ borderBottom: "0px" }}>
                                                Renewal Amount
                                            </TableCell>
                                            <TableCell align="center" style={{ borderBottom: "0px" }}>
                                                Plan
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ backgroundColor: "#F2F2F2 !important" }}>
                                        {paginatedData.length > 0 ? (
                                            paginatedData.map((row) => (
                                                <Row key={row.id} row={row} />
                                            ))
                                        ) : (
                                            <div style={{ backgroundColor: "white" }}>No Users Available</div>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                totalPages > 1 && (
                                    <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                        {
                                            currentPage !== 1 ?
                                                <ArrowBackIosNewIcon onClick={() => handlePageChange(currentPage - 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                                :
                                                <ArrowBackIosNewIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />

                                        }
                                        {displayedPages.map((page, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(page)}
                                                style={{
                                                    backgroundColor: currentPage === page ? '#e65925' : 'transparent',
                                                    color: currentPage === page ? 'white' : 'black',
                                                    border: 'none', 
                                                    borderRadius: '4px',
                                                    padding: '4px 20px',
                                                    margin: '2px',
                                                }}
                                            >
                                                {page}
                                            </button>)
                                        )}
                                        {
                                            currentPage !== totalPages ?
                                                <ArrowForwardIosIcon onClick={() => handlePageChange(currentPage + 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                                :
                                                <ArrowForwardIosIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default SubscriptionsInfo;
