import { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import TeamMemberHeader from "./TeamMemberHeader";
import UserInformationPage from "./UserInformation";
import SmartAccessoriesPage from "./SmartAccessories";
import AnalyticsPage from "./Analytics";
import { useParams } from "react-router-dom";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("UserInfo");
  const { id } = useParams();
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  return (
    <div className="onetap_conn_user">
      <TeamMemberHeader onChange={getTabValue} tab={companyProfileTab} />
      {companyProfileTab === "UserInfo" && (
        <UserInformationPage
          onClickSave={getValueOfSaveAndContinueTab}
          id={id}
        />
      )}
      {companyProfileTab === "Team" && (
        <AnalyticsPage onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyProfileTab === "Invited" && (
        <SmartAccessoriesPage onClickSave={getValueOfSaveAndContinueTab} />
      )}
    </div>
  );
};

const TeamMembers = () => {
  return <Sidebar Component={Component} />;
};

export default TeamMembers;
