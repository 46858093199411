import React, { useState } from 'react'
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import SubscriptionDetailHeaders from './SubscriptionDetailsHeader';
import SubscriptionDetailsInfo from './SubscriptionDetailsInfo';
const Component = () => {

    const [companyProfileTab, setCompanyProfileTab] = useState("SubscriptionDetails");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };

    return (
        <div className="otc_onetap_conn_user">
            <SubscriptionDetailHeaders onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "SubscriptionDetails" && (
                <SubscriptionDetailsInfo onClickSave={getValueOfSaveAndContinueTab} />
            )}
        </div>
    )
}
// export default Component
const SubscriptionMain = () => {
    return (
        <div>
            <Otc_Sidebar Component={Component} />
        </div>
    )

}
export default SubscriptionMain;
