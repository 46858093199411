import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenInfoToggleButton from "../../../components/Customers/CompanyProfile/EditCompany/GenInfoToggleButton";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Layout/Loader/Loader";
import { getAllUsersOfCompany } from "../../Redux/Otc_UserActions";

function ClientCompanySettingsUserProfile(props) {
  const url = process.env.REACT_APP_URL;
  const { id } = useParams();
  const [user_profile_edit_permission, setuser_profile_edit_permission] =
    useState("");
  const [companyData, setcompanyData] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Add loading state
  const { companydata } = useSelector(
    (state) => state.usersOfCompany
  );
  useEffect(() => {
    if (companydata) {
      setuser_profile_edit_permission(companydata.user_profile_edit_permission);
      setLoading(false);
    }
  }, [companydata]);

  const handleToggleuser_profile_edit_permission = () => {
    setuser_profile_edit_permission(!user_profile_edit_permission);
  };
  const handleUpdateuserprofile_edit_permission = async () => {
    try {
      const response = await axios.post(
        `${url}/admin/UpdateCompanySettings`,
        {
          companyID: id,
          user_profile_edit_permission: user_profile_edit_permission,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setcompanyData({
        ...companyData,
        user_profile_edit_permission: companyData.user_profile_edit_permission,
      });
      dispatch(getAllUsersOfCompany(id));
    } catch (error) {}
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="row mb-3 mt-3"
            style={{ marginRight: 0, marginLeft: 0 }}
          >
            <div className="onetap_bg_box" style={{ padding: "25px 20px" }}>
              <h1 className="onetap_heading_main">User Profile Settings</h1>
              <p className="onetap_ptag">
                Set the default behavior of your OneTapConnect account and
                cards. Those setting will be applied by default to all your
                users but can be override on a per template and cards basis.
              </p>
              <hr />
            </div>
          </div>
          <div class="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
            <div className="onetap_bg_box">
              <div className="row">
                <h2 className="onetap_heading_h2">
                  Default user account settings
                </h2>
                <p className="onetap_ptag">
                  Control what your team member can personalized on their cards.
                </p>
              </div>
              <hr className="" />
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // padding: "0px 6px 0px 6px",
                }}
              >
                <h6 style={{ fontWeight: "600", color: "#000" }}>
                  Allow users to edit their profile contact information.
                  <br />{" "}
                  <p
                    className="mb-0"
                    style={{ marginTop: "12px", fontWeight: "300" }}
                  >
                    If disabled, user won’t be allowed to edit their profile
                    contact information giving you full control of the
                    information displayed on their digital card.
                  </p>{" "}
                </h6>

                <div className="" style={{ cursor: "pointer" }}>
                  {}
                  <GenInfoToggleButton
                    isOn={user_profile_edit_permission}
                    onToggle={handleToggleuser_profile_edit_permission}
                  />
                </div>
              </div>{" "}
              <hr className="m-0" />
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-end">
              <button
                className="onetap_connect__comp_profile_save_button_syn"
                onClick={handleUpdateuserprofile_edit_permission}
              >
                Save
              </button>
              <button
                className="onetap_connect__comp_profile_save_and_cont_button_syn"
                onClick={(e) => {
                  handleUpdateuserprofile_edit_permission();
                  setTimeout(() => {
                    props.onClickSave("Lead Capture");
                  }, 3000);
                }}
              >
                Save & Continue
              </button>
            </div>
          </div>
          <div className="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
            <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
              <h5>Activity log</h5>
              <hr />
              <div
                style={{
                  borderRadius: "4px",
                  background: "#f2f2f2",
                  border: "none",
                  height: "72px",
                  padding: "8px",
                  overflowY: "auto",
                }}
                suppressContentEditableWarning={true}
              >
                Basic log of recent client and OTC admin activity
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ClientCompanySettingsUserProfile;
