import React, { useState } from 'react'
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import SubscriptionHeaders from "./SubscriptionsHeader";
import SubscriptionsInfo from './SubscriptionsInfo';

function OTCSubscriptionsall() {
    const [companyProfileTab, setCompanyProfileTab] = useState("Subscription");
    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };
    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user">
            <SubscriptionHeaders onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "Subscription" && (
                <SubscriptionsInfo onClickSave={getValueOfSaveAndContinueTab} />
            )}
        </div>
    )
}

const OTCSubscriptions = () => {
    return (
        <div>
            <Otc_Sidebar Component={OTCSubscriptionsall} />
        </div>
    )
}

export default OTCSubscriptions

