import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    loadAdminUser,
} from "../../OTC-AdminPanel/Redux/Otc_UserActions";
import open_eye from "../../assets/icons/eye.svg";
import close_eye from "../../assets/icons/eye-off.svg";
import axios from 'axios';
import PasswordChecklist from "react-password-checklist"

function AdminMyAccount() {
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
    };
    const {adminuser} = useSelector((state) => state.OtcAdminuser);
    const [name, setName] = useState(adminuser?.firstName + ' ' + adminuser?.lastName || '')
    const [currentPwd, setCurrentPwd] = useState()
    const [newPwd, setNewPwd] = useState('')
    const [confirmPwd, setConfirmPwd] = useState('')
    const [error, setError] = useState('')
    const [currentError, setCurrentError] = useState('')
    const [success, setSuccess] = useState('')
    const [isCurrentPwdVisible, setIsCurrentPwdVisible] = useState(false);
    const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
    const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
    const [apiSuccess, setApiSuccess] = useState(false);

    useEffect(() => {
        if (!adminuser) {
            dispatch(loadAdminUser());
        } else {
            setName(adminuser?.firstName + ' ' + adminuser?.lastName || '')
        }
    }, [adminuser]);
    const handlePasswordInputClick = () => {
        setShowPasswordChecklist(true);
    };

    const toggleCurrentPwdVisibility = () => {
        setIsCurrentPwdVisible((prev) => !prev);
    };

    const toggleNewPwdVisibility = () => {
        setIsNewPwdVisible((prev) => !prev);
    };

    const toggleConfirmPwdVisibility = () => {
        setIsConfirmPwdVisible((prev) => !prev);
    };
    const handlePasswordValidation = (isValid) => {
        setIsPasswordValid(isValid);
    };
    const handlePasswordVerify = async () => {
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;

        if (!currentPwd) {
            setCurrentError("Please enter your password.");
            return;
        } else if (!newPwd) {
            setError("Please enter your password.");
            return;
        } else if (newPwd.length < 8) {
            setError("Password must be at least 8 characters.");
            return;
        } else if (!passwordRegex.test(newPwd)) {
            setError(
                "Must contain at least one uppercase, lowercase letter, one special character, and one number"
            );
            return;
        } else if (confirmPwd !== newPwd) {
            setError("Passwords do not match. Please make sure the confirmation password matches the new password.");
            return;
        }
        try {
            const response = await axios.post(`${url}/admin/verify_pass`,
                {
                    currentPassword: currentPwd,
                    newPassWord: newPwd
                },
                config);
            setError('')
            setCurrentError('')
            if (response.data.success) {
                setShowPasswordChecklist(false)
                setError('')
                setCurrentError('')
                setSuccess("Password updated.");
                setApiSuccess(true)
                setTimeout(() => {
                    setApiSuccess(false)
                }, 5000);
                setCurrentPwd("");
                setNewPwd("");
                setConfirmPwd("")
                // Clear the success message after 5 seconds
                setTimeout(() => {
                    setSuccess("");
                }, 5000);
            }
        } catch (error) {
            if (error.response.data.msg === 'old password is invalid') {
                setCurrentError("Incorrect password.");
                return;
            }
            setCurrentError(error.response.data.message)
        }
    }
    return (
        <div>
            <div className="onetap_conn_users_tab_content_OTC_Admin" style={{ minHeight: 'unset' }}  >
                <h3 className='mt-0 mb-2 pc-header-text'>My Account</h3>
                <p className=' pb-2 border-bottom'>
                    Your user account can be connected to multiple companies.
                </p>
                <div className='d-flex align-items-center pb-2'>
                    <span className="" style={{ width: '15%' }}>Name</span>
                    <input className="w-100 border-0 p-2 ms-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="name" readOnly value={name} onChange={(e) => setName(e.target.value)} />
                    <button className="mb-0 px-4 p-2 ms-2" style={{ color: '#E65925', border: '1px solid #E65925', borderRadius: " 4px", background: "#fff" }}
                        onClick={() => {
                            navigate(`/admin/otc-user-edit/${adminuser?._id}`)
                        }}
                    > Edit</button>
                </div>
                <div className='mt-3 d-flex align-items-center pb-2'>
                    <span className="" style={{ width: '14%' }}>Current Password</span>
                    <div className="w-100">
                        <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                            <input
                                className="w-100 border-0 p-2"
                                type={isCurrentPwdVisible ? "text" : "password"}
                                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                name="name"
                                value={currentPwd}
                                onChange={(e) => {
                                    setCurrentPwd(e.target.value);
                                    setCurrentError(""); // Clear the error message
                                }}
                            />
                            <img
                                src={isCurrentPwdVisible ? close_eye : open_eye}
                                alt="toggle-password-visibility"
                                className="eye-icon me-2"
                                onClick={toggleCurrentPwdVisibility}
                            />
                        </div>
                        <span className="mt-2" style={{ color: '#E62525' }}>{currentError}</span>
                    </div>
                </div>
                <div className=" w-100 d-flex align-items-center pb-2  justify-content-between">

                    <div className='w-50 mt-3 d-flex align-items-center'>
                        <span className="" style={{ width: '33%' }}>New Password</span>
                        <div className=" d-flex align-items-center w-100">
                            <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                                <input
                                    className="w-100 border-0 p-2"
                                    type={isNewPwdVisible ? "text" : "password"}
                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                    name="code"
                                    value={newPwd}
                                    onChange={(e) => { setNewPwd(e.target.value); setError('') }}
                                    onClick={handlePasswordInputClick}
                                />
                                <img
                                    src={isNewPwdVisible ? close_eye : open_eye}
                                    alt="toggle-password-visibility"
                                    className="eye-icon me-2"
                                    onClick={toggleNewPwdVisibility}
                                />
                            </div>
                        </div>

                    </div>

                    <div className='w-50 mt-3  ms-2 d-flex align-items-center '>
                        <span className="" style={{ width: '33%' }}>Confirm Password</span>
                        <div className="d-flex align-items-center w-100">
                            <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                                <input
                                    className="w-100 border-0 p-2"
                                    type={isConfirmPwdVisible ? "text" : "password"}
                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                    name="confirm"
                                    value={confirmPwd}
                                    onChange={(e) => setConfirmPwd(e.target.value)}
                                    onClick={handlePasswordInputClick}
                                />
                                <img
                                    src={isConfirmPwdVisible ? close_eye : open_eye}
                                    alt="toggle-password-visibility"
                                    className="eye-icon me-2"
                                    onClick={toggleConfirmPwdVisibility}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {showPasswordChecklist && <PasswordChecklist className="password-checklist" style={{ color: "white", display: "block" }}
                    rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                    minLength={8}
                    value={newPwd}
                    valueAgain={confirmPwd}
                    messages={{
                        minLength: "At least 8 characters",
                        specialChar: "Contains one speacial character (@,#,$,%,etc.)",
                        number: "Contains one number",
                        capital: "Contains one uppercase letter",
                        lowercase: "Contains one lowercase letter",
                        match: "Passwords match.",
                    }}
                    invalidTextColor="red"
                    validTextColor="green"
                    onChange={handlePasswordValidation}
                />}

                <style jsx>{`
  /* Override display: flex for the li elements in the PasswordChecklist component */
  .password-checklist li {
    display: block !important;
    /* Use !important to ensure the override */
    /* Add any additional styles or modifications as needed */
  }
  .password-checklist li span{
padding: 2px 7px !important;
  }
  .password-checklist{
    padding : 0 0 0 155px !important;
  }

  /* You may need to use even more specific selectors depending on the library's styles */
`}</style>
                <div className="d-flex justify-content-end">
                    <button style={{ opacity: isPasswordValid ? 1 : 0.5, width: '15%', color: '#fff', border: '1px solid #E65925', borderRadius: " 4px", background: "#E65925" }} disabled={!isPasswordValid} className="mb-0 p-2 px-0 ms-2 mt-3" onClick={() => handlePasswordVerify()}>Update password</button>
                </div>
                <span className="mt-2" style={{ color: '#E62525' }}>{error}</span>
                <span className="mt-2" style={{ color: 'green' }}>{success}</span>
            </div>
        </div>
    )
}

export default AdminMyAccount
