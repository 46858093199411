import React, { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import "./billing.css";
import CompanyBillingSubscriptionTab from "./CompanyBillingSubscriptionTab";
import CompanyBillingInfoTab from "./CompanyBillingInfoTab";
import BillingHeadersWithIcons from "./BillingHeadersWithIcons";
import CompanyBillingTransactionTab from "./CompanyBillingTransactionTab";
import CompanyBillingPaymentTab from "./CompanyBillingPaymentTab";
import CompanyBillingAddressTab from "./CompanyBillingAddressTab";
import CompanyBillingUserRolesTab from "./CompanyBillingUserRolesTab";
import { useSelector } from "react-redux";

const Component = () => {
  const { user } = useSelector((state) => state.user);

  const [teamMember, setTeammember] = useState(user?.role === 'teammember')

  const [companyBillingTab, setCompanyBillingTab] = useState(teamMember ? 'Billing & Contact Information' : "Subscription & Add-ons");

  const getTabValue = (tabVal) => {
    setCompanyBillingTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyBillingTab(value);
  };
  return (
    <div className="onetap_conn_billing">
      <BillingHeadersWithIcons onChange={getTabValue} tab={companyBillingTab} />
      {companyBillingTab === "Subscription & Add-ons" && (
        <CompanyBillingSubscriptionTab
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
      {companyBillingTab === "Billing & Contact Information" && (
        <CompanyBillingInfoTab onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyBillingTab === "Transactions" && (
        <CompanyBillingTransactionTab
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
      {companyBillingTab === "Payment" && (
        <CompanyBillingPaymentTab onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyBillingTab === "Address" && (
        <CompanyBillingAddressTab onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyBillingTab === "User Roles" && (
        <CompanyBillingUserRolesTab
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
    </div>
  );
};

const Billing = () => {
  return <Sidebar Component={Component} />;
};

export default Billing;
