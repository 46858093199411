import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
 fetchCart, setLocalStorageChangeFlag
} from "../../../actions/userAction/userAction";
import Loader from "../../../components/Layout/Loader/Loader";
import Header2 from "../../../components/Layout/Header/Header2";
import "./cart.css";

// Create a memoized CartRow component
const CartRow = React.memo(({ val, i, quantity, handleUpdateQuantity, handleRemoveproduct, url, cartAddedProducts, setCartAddedProducts, dispatch }) => {
    const [localQuantity, setLocalQuantity] = useState(val.quantity);

    const updateLocalQuantity = (newQuantity) => {
        if (newQuantity >= 1) {
            setLocalQuantity(newQuantity);
            if (val._id) {
                handleUpdateQuantity(val._id, newQuantity);
            }
            else {

                if (newQuantity >= 1) {
                    // Create a copy of the Cartproducts array and update the quantity of the specific product
                    const temp = cartAddedProducts.map((item) => {

                        if (item?.product?._id === val?.product?._id) {
                            // Create a new object with the updated quantity
                            return {
                                ...item,
                                quantity: newQuantity,
                            };
                        }
                        return item;

                    });

                    setCartAddedProducts(temp)
                    // Store the updated data in local storage
                    localStorage.setItem('cart', JSON.stringify(temp));

                    // Dispatch the action to indicate a local storage change
                    dispatch(setLocalStorageChangeFlag(true));
                }
            }

        }

    };
    const handleRemove = (i) => {
        const updatedCart = cartAddedProducts.filter((product, index) => index !== i);


        setCartAddedProducts(updatedCart);
        // Store the updated data in local storage
        localStorage.setItem('cart', JSON.stringify(updatedCart));

        // Dispatch the action to indicate a local storage change
        dispatch(setLocalStorageChangeFlag(true));
    };
    const getVariationById = (variations, variationId) => {
        return variations.find(variation => variation._id === variationId) || null;
    };
    const variation = getVariationById(val.product.variations, val.variation);

    return (
        <tr>
            <th scope="row">{i + 1}</th>
            <td className="" style={{ width: '10%' }}>
                <div className='col-12'>
                    {val?.variation ?
                        <img className="img-fluid" src={`${url}/product/img/${variation?.image[0]?.url}`} alt={variation.Type} />
                        :
                        <img className="img-fluid" src={`${url}/product/img/${val.product?.image[0]?.url}`} alt={val.product.name} />
                    }
                </div>
            </td>

            <td>{val?.variation ? variation?.Type : val.product.name}</td>
            {val?.variation ?
                variation?.saleprice ? <td>{variation?.saleprice.toFixed(2)}</td> : <td>{variation?.price.toFixed(2)}</td>
                :
                val.product.saleprice ? <td>{val.product.saleprice.toFixed(2)}</td> : <td>{val.product.price.toFixed(2)}</td>
            }
            <td>
                <div className='border d-flex'>
                    <div className='col d-flex justify-content-center p-1' role="button" onClick={() => updateLocalQuantity(localQuantity - 1)}>-</div>
                    <div className='col border border-top-0 border-bottom-0 d-flex justify-content-center p-1'>{localQuantity}</div>
                    <div className='col d-flex justify-content-center p-1' role="button" onClick={() => updateLocalQuantity(localQuantity + 1)}>+</div>
                </div>
            </td>
            {val?.variation ?

                variation?.saleprice ? <td>{(variation?.saleprice * localQuantity).toFixed(2)}</td> : <td>{(variation?.price * localQuantity).toFixed(2)}</td>
                :
                val.product.saleprice ? <td>{(val.product.saleprice * localQuantity).toFixed(2)}</td> : <td>{(val.product.price * localQuantity).toFixed(2)}</td>
            }
            <td>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: "pointer" }}
                    onClick={() => val._id ? handleRemoveproduct(val) : handleRemove(i)}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                        fill="#8A8A8A"
                    />
                </svg>
            </td>
        </tr>
    );
});



export default function Cart() {
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products, loading, message, error } = useSelector(
        (state) => state.products
    );
    const { user } = useSelector(
        (state) => state.user
    );

    const [cartAddedProducts, setCartAddedProducts] = useState([]);
    const [quantity, setQuantity] = useState({});
    const [updatedCart, setUpdatedCart] = useState([]);
    const localCartChange = useSelector((state) => state.localCartChange)

    const { Cartproducts, cartProductCounts } = useSelector(
        (state) => state.Cartproducts
    );
    useEffect(() => {
        if (!Cartproducts && user) {
            dispatch(fetchCart(user))
        }
        setCartAddedProducts(Cartproducts);
    }, [user, Cartproducts])

    useEffect(() => {
        // Your existing logic for handling user changes
        if (!user) {
            const updatedCartData = JSON.parse(localStorage.getItem('cart')) || [];
            setCartAddedProducts(updatedCartData);
            dispatch(setLocalStorageChangeFlag(false));
        }
    }, [user, localCartChange]);


    // Initialize the quantity state with values from Cartproducts
    useEffect(() => {
        if (Array.isArray(cartAddedProducts)) {
            const quantityState = {};
            cartAddedProducts.forEach((item) => {
                quantityState[item._id] = item.quantity;
            });
            setQuantity(quantityState);
        }
    }, [cartAddedProducts]);

    const getVariationById = (variations, variationId) => {
        return variations.find(variation => variation._id === variationId) || null;
    };
    const calculateTotalPrice = () => {
        if (cartAddedProducts) {
            const totalPrice = cartAddedProducts.reduce((sum, val) => {
                const variation = getVariationById(val.product.variations, val.variation);

                if (val.variation) {
                    const productPrice = variation.saleprice
                        ? variation.saleprice * val.quantity
                        : variation.price * val.quantity;
                    return sum + productPrice;
                } else {
                    const productPrice = val.product.saleprice
                        ? val.product.saleprice * val.quantity
                        : val.product.price * val.quantity;
                    return sum + productPrice;
                }
            }, 0);
            return totalPrice.toFixed(2); // Assuming prices are in decimal format
        }
        return "0.00";
    };

    const handleRemoveproduct = (val) => {
        const pid = val._id
        // Check if Cartproducts exists and is an array
        if (Array.isArray(cartAddedProducts)) {
            // Use filter to create a new array without the product to be removed
            const updatedCart = cartAddedProducts.filter((item) => item._id !== pid);
            const updateCart = async () => {
                try {
                    const _id = user?._id
                    // Make a POST request to your backend API endpoint using Axios
                    const { data } = await axios.post(
                        `${url}/cart/removeproduct`,
                        { addedProducts: updatedCart, _id },

                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            withCredentials: true,
                        }
                    );
                    dispatch(fetchCart(user));
                } catch (error) {
                    ;
                }
            }
            updateCart()
        }

    };
    const handleUpdateQuantity = async (productId, newQuantity) => {

        // Ensure that the new quantity is within valid bounds (e.g., not negative)
        if (newQuantity >= 1) {
            // Create a copy of the Cartproducts array and update the quantity of the specific product
            const updatedCarttemp = cartAddedProducts.map((item) => {

                if (item._id === productId) {
                    // Create a new object with the updated quantity
                    return {
                        ...item,
                        quantity: newQuantity,
                    };
                }
                return item;

            });
            setUpdatedCart(updatedCarttemp)

        }
    };

    const updateQuantity = async () => {
        try {
            const _id = user?._id
            // Make a POST request to your backend API endpoint using Axios
            const { data } = await axios.post(
                `${url}/cart/update`,
                { addedProducts: updatedCart, _id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            // Update the cart in Redux after a successful server update
            dispatch(fetchCart(user));
        } catch (error) {
            ;
        }
    }

    useEffect(() => { updateQuantity() }, [updatedCart])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Header2 />
                    <div className="container mt-5 pt-5">
                        {cartAddedProducts?.length > 0 ?
                            <table class="table table-bordered mt-5">
                                <thead>
                                    <tr>
                                        <th className="bg-light" scope="col"></th>
                                        <th className="bg-light" scope="col"></th>
                                        <th className="bg-light" scope="col">Product</th>
                                        <th className="bg-light" scope="col">Price</th>
                                        <th className="bg-light" scope="col">Quantity</th>
                                        <th className="bg-light" scope="col">Subtotal</th>
                                        <th className="bg-light" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartAddedProducts?.map((val, i) => (
                                        <CartRow
                                            key={val._id}
                                            i={i}
                                            val={val}
                                            quantity={quantity}
                                            handleUpdateQuantity={handleUpdateQuantity}
                                            handleRemoveproduct={handleRemoveproduct}
                                            url={url}
                                            cartAddedProducts={cartAddedProducts}
                                            setCartAddedProducts={setCartAddedProducts}
                                            dispatch={dispatch}
                                        />
                                    ))}

                                    <tr>
                                        <th colspan="5" style={{ textAlign: "right" }}>Total :</th>
                                        <th colspan="" >${calculateTotalPrice()}</th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <h3 className="mb-5">Your cart is currently empty.</h3>
                        }

                        <div className="w-100 d-flex justify-content-end mb-5 mt-3">
                            <button class="text-white btn w-25 " style={{ backgroundColor: '#e65925', marginRight: cartAddedProducts && cartAddedProducts?.length > 0 ? '' : 'auto', }} onClick={() => { navigate("/shop") }} > {(cartAddedProducts?.length > 0) ? 'Continue shopping' : 'Return to shop'}</button>
                            {cartAddedProducts?.length > 0 &&
                                <button class="btn text-white w-25 mx-3" style={{ backgroundColor: '#000', opacity: cartAddedProducts?.length === 0 ? 0.6 : 1 }}
                                    onClick={() => {
                                        if (user) {
                                            navigate("/checkout")
                                        } else {
                                            navigate("/guest-checkout")
                                        }
                                    }}
                                    disabled={cartAddedProducts?.length === 0} >Proceed to checkout</button>
                            }
                        </div>
                    </div>

                </>
            )
            }
        </>
    )
}


