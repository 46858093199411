//eslint-disable-next-line
import React, { useState } from "react";
import "./checkout.scss";
import { Country, State } from "country-state-city";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllShippingHandler,
  getCompanyProfileDetails,
  fetchCart,
  fetchBillingAddressDetails,
  loadUser,
  fetchUserInformationDetails,
  fetchSavedCards,
  getProducts
} from "../../../actions/userAction/userAction";
import Loader from "../../Layout/Loader/Loader";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Select from "react-select"
import Header2 from "../../Layout/Header/Header2";
import VisaCard from "../../../assets/images/Card_image.png"
import plusIcon from "../../../assets/icons/plusIcon.svg"

const Checkout = () => {
  const frontendURL = window.location.origin;
  const url = process.env.REACT_APP_URL;
  const location = useLocation();
  const selectedstateAddons = location.state?.selectedAddons ?? [];
  const existingAddons = location.state?.existingAddons ?? [];
  const oldUserstate = location.state?.oldUser;
  const selectedstateAddUser = location.state?.addUserCount || 0;
  const selectedstateAddUserPrice = location.state?.adduserPrice || 0;
  const planid = location.state && location.state.id;
  const cycleData = location.state && location?.state?.cycle;
  const additionalUser = location?.state?.additionUser || 0;
  const { user, loading, stripeSavedCards, isAuthenticated } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.userInformation);
  const { products } = useSelector((state) => state.products);
  const { company } = useSelector((state) => state.Company);
  const { shippingAddresses } = useSelector((state) => state.shippingAddresses);
  const { billingAddresses } = useSelector((state) => state.billingAddresses);
  const [cycle, setCycle] = useState(cycleData)
  const [selectedOption, setSelectedOption] = useState("free");
  const [planId, setPlanId] = useState(planid);
  const [PlanName, setPlanName] = useState("")
  const [plandata, setPlanData] = useState(null);
  const [addons, setAddOns] = useState(null);
  const [same, setSame] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [userCount, setUserCount] = useState(additionalUser);
  const [baseUserCount, setBaseUserCount] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [updatedYearlyPrice, setUpdatedYearlyPrice] = useState();
  const [fixedPrice, setFixedPrice] = useState(null);
  const [isAccordion1Filled, setIsAccordion1Filled] = useState(false);
  const [isAccordion2Filled, setIsAccordion2Filled] = useState(false);
  const [isAccordion3Filled, setIsAccordion3Filled] = useState(false);
  const [isAccordion4Filled, setIsAccordion4Filled] = useState(false);
  const [isAccordion5Filled, setIsAccordion5Filled] = useState(false);
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCountryShiping, setSelectedCountryShipping] = useState(null);
  const [selectedStateShipping, setSelectedStateShipping] = useState(null);
  const [stateOptionsShipping, setStateOptionsShipping] = useState([]);
  const [selectedCountryShipingAll, setSelectedCountryShippingAll] = useState(null);
  const [selectedStateShippingAll, setSelectedStateShippingAll] = useState(null);
  const [stateOptionsShippingAll, setStateOptionsShippingAll] = useState([]);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxId, setTaxId] = useState("");
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [buttonText, setButtonText] = useState("Submit");
  const [buttonTextPay, setButtonTextPay] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCouponError, setshowCouponError] = useState({ showError: false, error: "" });
  const [rates, setRates] = useState([]);
  const [adduserPrice, setAdduserPrice] = useState(0);
  const [adduserCount, setAdduserCount] = useState(0);
  const [userShippingOrderNote, SetUserShippingOrderNote] = useState('');
  const [referrer, setReferrer] = useState("");
  const [referrerName, setReferrerName] = useState("");
  const [couponrelatedAddons, setCouponrelatedaddons] = useState();
  const [shippingRateFetched, setShippingRateFetched] = useState(false)
  const baseUserforexistinguser = userInfo?.subscription_details?.total_user[0]?.baseUser;
  const additionalUserforexistinguser =
    userInfo?.subscription_details?.total_user[0]?.additionalUser;
  const total_user = baseUserforexistinguser + additionalUserforexistinguser;
  const { Cartproducts, cartProductCounts } = useSelector(
    (state) => state.Cartproducts
  );
  useEffect(() => {
    if(!isAuthenticated){
      navigate("/");
    }
  },[])
  useEffect(() => {
    if (!Cartproducts) {
      dispatch(fetchCart(user))
    }
    setCartAddedProducts(Cartproducts);
  }, [user, Cartproducts])
  useEffect(() => {
    fetchShippingRates()
  }, [Cartproducts, cartProductCounts]);

  const fetchShippingRates = () => {
    if (!shippingRateFetched) {
      handleCalculateRatesAndContinue()
    }
  }
  const productWeights = [];
  const productquantity = [];
  const totalWeights = [];
  if (Cartproducts) {
    Cartproducts.forEach((product) => {
      const weight = product?.product?.weight;
      const quantity = product?.quantity;
      if (weight !== undefined) {
        productWeights.push(weight);
      }
      if (quantity !== undefined) {
        productquantity.push(quantity);
      }
    });
  }
  for (let i = 0; i < productWeights.length && i < productquantity.length; i++) {
    const totalWeight = productWeights[i] * productquantity[i];
    totalWeights.push(totalWeight);
  }
  const sumTotalWeights = totalWeights.reduce((acc, currentValue) => acc + currentValue, 0);
  const shipkey = process.env.REACT_APP_SHIPSTATION_APIKEY;
  const shipsecret = process.env.REACT_APP_SHIPSTATION_API_SECRET;
  const largeEnvelopeorflatWeight = 1.4;
  const largeEnvelopeorflat2Weight = 1.7;
  const packageWeight = 3.2;
  const mediumFlatRateBoxWeight = 5.4;
  const largeFlatRateBoxWeight = 9.4;
  const handleCalculateRatesAndContinue = async (addressData) => {
    setShippingRateFetched(true)
    const value = await someFunction()
    const fullweight = sumTotalWeights + value.selectedWeight
    try {
      setIsLoading(true);
      const apiKey = shipkey;
      const apiSecret = shipsecret;
      const shipmentData = {
        carrierCode: 'stamps_com',
        serviceCode: 'priority',
        packageCode: value.selectedPackageCode,
        fromPostalCode: '89120',
        toState: shippingAddress.Sstate || addressData.state,
        toCountry: shippingAddress.Scountry || addressData.country,
        toPostalCode: shippingAddress.SpostalCode.trim() || addressData.postal_code.trim(),
        weight: {
          value: fullweight,
          units: 'ounces',
        },
      };
      const response = await fetch('https://ssapi.shipstation.com/shipments/getrates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${apiKey}:${apiSecret}`)}`,
        },
        body: JSON.stringify(shipmentData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const modifiedData = data.map(item => {
        const originalShipmentCost = item.shipmentCost;
        const newShipmentCost = Math.floor(originalShipmentCost * 1.1 * 100) / 100; // Add 10%
        const serviceNameParts = item.serviceName.split('-');
        let modifiedServiceName = serviceNameParts[0]?.trim() || item.serviceName;
        if (modifiedServiceName === 'USPS Priority Mail') {
          modifiedServiceName = modifiedServiceName + ' (2-3 days) ';
        }
        return { ...item, shipmentCost: newShipmentCost, serviceName: modifiedServiceName };
      });
      setRates(modifiedData);
      setIsLoading();
    } catch (error) {
      setRates([]);
      setIsLoading();
    }
  };
  // show coupon error
  const displayCouponError = (e) => {
    setshowCouponError({ showError: true, error: e })
    setTimeout(() => {
      setshowCouponError({ showError: false, error: null })
    }, 5000);
  }
  const someFunction = async () => {
    let selectedPackageCode = '';
    let selectedWeight = 0;
    if (sumTotalWeights + largeEnvelopeorflatWeight <= 5) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflatWeight;
    } else if (sumTotalWeights + largeEnvelopeorflat2Weight <= 10) {
      selectedPackageCode = 'large_envelope_or_flat';
      selectedWeight = largeEnvelopeorflat2Weight;
    } else if (sumTotalWeights + packageWeight <= 15) {
      selectedPackageCode = 'package';
      selectedWeight = packageWeight;
    } else if (sumTotalWeights + mediumFlatRateBoxWeight <= 50) {
      selectedPackageCode = 'package';
      selectedWeight = mediumFlatRateBoxWeight;
    } else if (sumTotalWeights + largeFlatRateBoxWeight <= 98) {
      selectedPackageCode = 'package';
      selectedWeight = largeFlatRateBoxWeight;
    }
    return { selectedPackageCode, selectedWeight };
  }
  const calculateShippingCost = () => {
    switch (selectedOption) {
      case "free":
        return {
          cost: 0,
          serviceCode: "usps_first_class_mail",
        };
      case "2-4":
        const twoToFourDayRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_first_class_mail" ||
            rate.serviceCode === "usps_first_class_mail_international"
        );
        return {
          cost: plandata && plandata?.smart_accessories?.length === 0 ? 20.00 : twoToFourDayRate?.shipmentCost || 0,
          serviceCode: twoToFourDayRate?.serviceCode || null,
        };
      case "overnight":
        const overnightRate = rates?.find(
          (rate) =>
            rate.serviceCode === "usps_priority_mail" ||
            rate.serviceCode === "usps_priority_mail_international"
        );
        return {
          cost: plandata && plandata?.smart_accessories?.length === 0 ? 60.00 : overnightRate?.shipmentCost || 0,
          serviceCode: overnightRate?.serviceCode || null,
        };
      default:
        return 0;
    }
  };
  const shippingInfo = calculateShippingCost();
  useEffect(() => {
    if (plandata?.planType) {
      setButtonTextPay("Pay and subscribe")
    } else {
      setButtonTextPay("Pay")
    }
  }, [plandata, cycle])
  const [firstLogin, setFirstLogin] = useState(false);
  const [saveAddress, setSaveAddress] = useState(true);
  const [selectedEditAddress, setSelectedEditAddress] = useState(null);
  const [showPaymentSection, setShowPaymentSection] = useState(null);
  const [setupFeeAdded, setsetuFeeAdded] = useState(false);
  const [subTotal, setsubTotal] = useState(null);
  const [selectedAddons, setselectedAddons] = useState([]);
  const [isModified, setisModified] = useState(true);
  const [couponCode, setCouponCode] = useState([]);
  const [fetchedCoupon, setFetchedCoupon] = useState({ coupon: {}, discounValue: 0, type: null });
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [applyCouponbtnActive, setApplyCouponbtnActive] = useState(false);
  const [totalDiscountValue, setTotalDiscountValue] = useState(null);
  const [visibleCouponForm, setvisibleCouponForm] = useState(false);
  const [perUserPrice, setPerUserPrice] = useState('')
  const [applycoupondata, setApplycoupondata] = useState()
  const [paymentErrors, setPaymentErrors] = useState({
    cardName: "",
    cardNumber: "",
    expiryDate: "",
    csv: "",
  });
  const [proratedPrice, setProratedPrice] = useState(0);
  const [isActive, setIsActive] = useState({
    a1: true,
    a2: false,
    a3: false,
    a4: false,
    a5: false,
  });
  const [policyIsChecked, setPolicyIsChecked] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    cardName: "",
    cardNumber: null,
    csv: null,
    expiryDate: null,
  });
  const [billingAddress, setbillingAddress] = useState({
    Bstreet1: "",
    Bstreet2: "",
    Bcity: "",
    BpostalCode: "",
    Bstate: "",
    Bcountry: "",
  });
  const [shippingAddress, setShippingAddress] = useState({
    Sstreet1: billingAddress.Bstreet1,
    Sstreet2: billingAddress.Bstreet2,
    Scity: billingAddress.Bcity,
    SpostalCode: billingAddress.BpostalCode,
    Sstate: billingAddress.Bstate,
    Scountry: billingAddress.Bcountry,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  const popup = document.getElementById("popup");
  const paymentSuccess = document.getElementById("payment-success");
  const paymentFailure = document.getElementById("payment-failure");
  setTimeout(() => {
    localStorage.removeItem("selectedPlan");
  }, 3000);
  useEffect(() => {
    setShippingAddress({
      Sstreet1: billingAddress.Bstreet1,
      Sstreet2: billingAddress.Bstreet2,
      Scity: billingAddress.Bcity,
      SpostalCode: billingAddress.BpostalCode,
      Sstate: billingAddress.Bstate,
      Scountry: billingAddress.Bcountry,
    });
  }, [billingAddress]);
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const response = await axios.get(`${url}/getallcompanies`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }); // Adjust the URL if needed
        setCompanyNames(
          response.data.companies.map((company) => company.company_name)
        );
      } catch (error) {
        ;
      }
    };
    fetchCompanyNames();
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllShippingHandler())
  }, [])
  const [selectedAddOne, setSelectedAddOne] = useState([]);
  const navigate = useNavigate();
  const [cartAddedProducts, setCartAddedProducts] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [updatedCart, setUpdatedCart] = useState([]);
  const getVariationById = (variations, variationId) => {
    return variations.find(variation => variation._id === variationId) || null;
  };
  const [savedCards, SetsavedCards] = useState([]);
  useEffect(() => {
    if (!products) {
      dispatch(getProducts())
    }
  }, [products])
  const filteredProducts = products?.filter(
    (product) => product.status === 'Published' && product.visibility === 'Public'
  );
  const filteredProductsWithVariations = filteredProducts?.flatMap((product) => {
    if (product.hasVariations && product.variations.length > 0) {
      // If hasVariations is true, replace the array with variations
      return product.variations.map((variation) => ({
        ...variation,
        productId: product._id, // Include productId in each variation
      }));
    }
    // If hasVariations is false, return the product as is
    return [product];
  });
  const selectedProductIds = plandata?.smart_accessories;
  // Match product IDs with filteredProductsExcludingUpdated
  const matchedProducts = filteredProductsWithVariations?.filter(product => selectedProductIds?.includes(product._id));
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const handleShowPayment = () => {
    setSelectedCardIndex(null);
    setShowPaymentSection(true)
    setSelectedCard(null)
  }
  useEffect(() => {
    areAllFieldsFilled();
  }, [showPaymentSection])
  const handleCardSelection = (index, Card) => {
    setShowPaymentSection(null);
    setSelectedCard(Card);
    setSelectedCardIndex(index);
    setCardInfo({
      cardName: "",
      cardNumber: null,
      csv: null,
      expiryDate: null
    })
  };
  useEffect(() => {
    const primaryCardIndices = savedCards?.map((card, index) => card.isPrimary === true ? index : -1)
      .filter(index => index !== -1);
    if (primaryCardIndices) {
      setSelectedCard(savedCards[primaryCardIndices[0]]);
      setSelectedCardIndex(primaryCardIndices[0]);
    }
  }, [savedCards])
  // Initialize the quantity state with values from Cartproducts
  useEffect(() => {
    if (Array.isArray(cartAddedProducts)) {
      const quantityState = {};
      cartAddedProducts.forEach((item) => {
        quantityState[item._id] = item.quantity;
      });
      setProductQuantities(quantityState);
    }
  }, [cartAddedProducts]);
  const handleUpdateQuantity = async (productId, newQuantity) => {
    // Ensure that the new quantity is within valid bounds (e.g., not negative)
    if (newQuantity >= 1) {
      setProductQuantities({ ...productQuantities, [productId]: newQuantity });
      // Create a copy of the Cartproducts array and update the quantity of the specific product
      const updatedCarttemp = cartAddedProducts.map((item) => {
        if (item._id === productId) {
          // Create a new object with the updated quantity
          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      });
      setUpdatedCart(updatedCarttemp)
    }
  };
  const updateQuantity = async () => {
    try {
      // Make a POST request to your backend API endpoint using Axios
      const _id = user?._id
      const { data } = await axios.post(
        `${url}/cart/update`,
        { addedProducts: updatedCart, _id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      // Update the cart in Redux after a successful server update
      dispatch(fetchCart(user));
    } catch (error) {
      ;
    }
  }
  useEffect(() => { updateQuantity() }, [updatedCart])
  const handleRemoveproduct = (pid) => {
    if (Array.isArray(cartAddedProducts)) {
      // Use filter to create a new array without the product to be removed
      const updatedCart = cartAddedProducts.filter((item) => item._id !== pid);
      const updateCart = async () => {
        const _id = user?._id
        try {
          // Make a POST request to your backend API endpoint using Axios
          const { data } = await axios.post(
            `${url}/cart/removeproduct`,
            { addedProducts: updatedCart, _id },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          dispatch(fetchCart(user));
        } catch (error) {
          ;
        }
      }
      updateCart()
    }
  };
  useEffect(() => {
    dispatch(fetchBillingAddressDetails())
  }, [])
  useEffect(() => {
    if (billingAddresses) {
      const countrynamee = Country?.getCountryByCode(billingAddresses?.country)?.name
      const statename = State?.getStateByCodeAndCountry(billingAddresses?.state, billingAddresses?.country)?.name
      const updatedBillingAddress = {
        Bstreet1: billingAddresses?.line1 || "",
        Bstreet2: billingAddresses?.line2 || "",
        Bcity: billingAddresses?.city || "",
        BpostalCode: billingAddresses?.postal_code || "",
        Bstate: billingAddresses?.state || "",
        Bcountry: billingAddresses?.country || "",
      };
      setbillingAddress(updatedBillingAddress);
      setSelectedCountry({
        value: billingAddresses.country,
        label: countrynamee
      })
      setSelectedState({
        value: billingAddresses.state,
        label: statename
      })
    }
  }, [billingAddresses]);
  const fetchPlanData = async () => {
    try {
      const { data } = await axios.get(`${url}/plan/${planId}`);
      setPlanData(data.plan);
      setPlanName(data.plan.planType)
      setBaseUserCount(data.plan.users)
      setPerUserPrice(cycle === 'monthly' ? data.plan.monthlyPrice_perUser : data.plan.yearlyPrice_perUser)
      setUserCount(additionalUser);
      const isTeamPlan = data.plan.planType === "Team" || data.plan.planType === "Professional";
      if (cycle === "yearly") {
        let addOnesPrice;
        if (oldUserstate) {
          addOnesPrice = selectedAddOne?.reduce((totalPrice, item) => {
            const selectedaddonPrice =
              item.paymentType === "onetimepayment"
                ? item?.Addonspaymentdata?.price
                : cycle === "yearly"
                  ? item.Addonspaymentdata?.yearlyprice
                  : item.Addonspaymentdata?.monthlyprice;
            return totalPrice + (selectedaddonPrice || 0); // Ensure selectedaddonPrice is a number, default to 0 if undefined
          }, 0);
        }
        const discountAmount = 0;
        const discountAmounts = data?.plan?.yearlyPrice_perUser - discountAmount;
        const discountedPrice = discountAmounts * data?.plan?.users * 12
        const roundedDiscountedPrice = isNaN(discountedPrice)
          ? 0
          : Math.floor(discountedPrice);
        let updatedYearlyPrice = ((roundedDiscountedPrice + (userCount - data?.plan?.users) * data?.plan?.yearlyPrice_perUser * 12));
        setsubTotal(updatedYearlyPrice)
        setFixedPrice(updatedYearlyPrice);
        if (isTeamPlan && userInfo?.isInitailUser === true && (userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === 'Free')) {
          updatedYearlyPrice += data?.plan?.yearly_fee;
          setsetuFeeAdded(true)
        }
        setUpdatedYearlyPrice(updatedYearlyPrice + (addOnesPrice || 0));
      } else {
        let addOnesPrice;
        if (oldUserstate) {
          addOnesPrice = selectedAddOne?.reduce((totalPrice, item) => {
            const selectedaddonPrice =
              item.paymentType === "onetimepayment"
                ? item?.Addonspaymentdata?.price
                : cycle === "yearly"
                  ? item.Addonspaymentdata?.yearlyprice
                  : item.Addonspaymentdata?.monthlyprice;
            return totalPrice + (selectedaddonPrice || 0); // Ensure selectedaddonPrice is a number, default to 0 if undefined
          }, 0);
        }
        let updatedPrice = ((data?.plan?.monthlyPrice_perUser * data?.plan?.users) + ((userCount - data?.plan?.users) * data?.plan?.monthlyPrice_perUser));
        setsubTotal(updatedPrice);
        setFixedPrice(updatedPrice);
        if (isTeamPlan && userInfo?.isInitailUser === true && (userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === 'Free')) {
          updatedPrice += data?.plan?.monthly_fee;
          setsetuFeeAdded(true)
        }
        setUpdatedYearlyPrice(updatedPrice + (addOnesPrice || 0));
      }
    } catch (error) {
    }
  };
  useEffect(() => {
  }, [subTotal])
  useEffect(() => {
    if (isModified) {
      setselectedAddons(selectedstateAddons)
      setAdduserCount(selectedstateAddUser)
      setAdduserPrice(selectedstateAddUserPrice)
    }
    setisModified(false)
  }, [selectedAddons, selectedstateAddons, selectedstateAddUser])
  useEffect(() => {
    // 
    setSelectedAddOne(existingAddons)
  }, [])
  const calculateTotalPriceOfProduct = () => {
    let productTotalPrice = 0;
    // Calculate the total price of products
    if (cartAddedProducts) {
      productTotalPrice = cartAddedProducts.reduce((sum, val) => {
        const variation = getVariationById(val.product.variations, val.variation);
        if (val.variation) {
          const productPrice = variation.saleprice
            ? variation.saleprice * val.quantity
            : variation.price * val.quantity;
          return sum + productPrice;
        } else {
          const productPrice = val.product.saleprice
            ? val.product.saleprice * val.quantity
            : val.product.price * val.quantity;
          return sum + productPrice;
        }
      }, 0);
    }
    const totalPrice = productTotalPrice - totalDiscountValue
    return totalPrice; // Assuming prices are in decimal format
  };
  // fetch all addons data
  const fetchAddons = async () => {
    try {
      const { data } = await axios.get(`${url}/addons`);
      setAddOns(data.addOns);
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, [cycle]);
  useEffect(() => {
    fetchAddons();
  }, [selectedAddOne])
  const planAddonsIds = plandata?.add_ons.map((e) => e._id)
  const planAddons = plandata?.add_ons.map((e) => e)
  const filterAddons = addons?.filter((e) => !planAddonsIds?.includes(e._id));
  useEffect(() => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    } else {
      setFname(user?.first_name);
      setLname(user?.last_name);
      setEmail(user?.email);
      setContact(user?.contact);
      setCompanyName(company?.company_name);
    }
  }, [user, company, dispatch]);
  const handleEditAddress = (address) => {
    setSelectedEditAddress(address);
  };
  const handleBillingAddressChange = async (event) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      billingAddress: {
        ...prevErrors.billingAddress,
        [name]: value ? "" : ``,
      },
    }));
    if (name === "BpostalCode") {
      if (!selectedCountry) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: "Please select a country."
          }
        }));
        return;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: ""
          }
        }));
      }
    }
    setbillingAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };
  const handleBillingContinue = async () => {
    // Check if postal code is valid and other validations here
    const errors = {};
    if (billingAddress.Bcountry === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bcountry: "Please select a country.",
      };
    }
    if (billingAddress.Bcity === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bcity: "Please enter your city.",
      };
    }
    if (billingAddress.BpostalCode === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        BpostalCode: "Please enter your postal code.",
      };
    }
    if (billingAddress.Bstreet1 === "") {
      errors.billingAddress = {
        ...errors.billingAddress,
        Bstreet1: "Please enter your address.",
      };
    }
    if (selectedCountry && stateOptions.length > 0) {
      if (billingAddress.Bstate === "") {
        errors.billingAddress = {
          ...errors.billingAddress,
          Bstate: "Please enter your state",
        };
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      setFirstLogin(true)
      const state = State?.getStateByCodeAndCountry(billingAddress.Bstate, billingAddress.Bcountry)?.name
      const apiUrl = `https://api.zippopotam.us/${billingAddress.Bcountry}/${billingAddress.BpostalCode.trim().replace(/\s+/g, '')}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.ok !== true) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: "Please enter a valid postal code.",
          },
        }));
      } else if (data.places[0].state.trim() !== state.trim()) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          billingAddress: {
            ...prevErrors.billingAddress,
            BpostalCode: `Please enter a valid postal code for ${state}.`,
          },
        }));
      } else {
        setAccordion2(true);
        {
          adduserCount !== 0
            ? (() => { nextAccordion(4); navigate("#payment"); })()
            : selectedAddons?.length > 0 ? (() => { nextAccordion(3); navigate("#add-ons"); })() : (() => { nextAccordion(2); navigate("#shipping"); })();
        }
      }
    } catch (error) {
      setIsButtonClicked(false);
      setButtonText("Submit");
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          BpostalCode: "Please enter a valid postal code.",
        },
      }));
    }
  };
  const handleShippingContinue = async () => {
    // Check if postal code is valid and other validations here
    const errors = {};
    if (same && shippingAddress.Scountry === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Scountry: "Please select a country.",
      };
    }
    if (same && shippingAddress.Scity === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Scity: "Please enter your city.",
      };
    }
    if (same && shippingAddress.SpostalCode === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        SpostalCode: "Please enter your postal code.",
      };
    }
    if (same && shippingAddress.Sstreet1 === "") {
      errors.shippingAddress = {
        ...errors.shippingAddress,
        Sstreet1: "Please enter your address.",
      };
    }
    if (selectedCountryShiping && stateOptionsShipping.length > 0) {
      if (shippingAddress.Sstate === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Sstate: "Please enter your state",
        };
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    try {
      const state = State?.getStateByCodeAndCountry(shippingAddress.Sstate, shippingAddress.Scountry)?.name
      const apiUrl = `https://api.zippopotam.us/${shippingAddress.Scountry}/${shippingAddress.SpostalCode.trim()}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.ok !== true) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: "Please enter a valid postal code.",
          },
        }));
      } else if (data.places[0].state.trim() !== state.trim()) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: `Please enter a valid postal code for ${state}`,
          },
        }));
      } else {
        if (!plandata && !selectedAddons?.length > 0 && cartAddedProducts) {
          nextAccordion(4);
          navigate("#payment");
          setAccordion3(true);
        }
        else {
          nextAccordion(3);
          navigate("#add-ons");
          setAccordion3(true);
        }
      }
    } catch (error) {
      setIsButtonClicked(false);
      setButtonText("Submit");
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: "Please enter a valid postal code.",
        },
      }));
    }
  };
  const handleCountryChange = (selectedOption) => {
    setbillingAddress((prevAddress) => ({
      ...prevAddress,
      Bstate: '',
      BpostalCode: '',
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      billingAddress: {
        ...prevErrors.billingAddress,
        BpostalCode: "",
      },
    }));
    setSelectedState('')
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          Bcountry: "", // Remove validation error for the country input
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          BpostalCode: ""
        }
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);
      setbillingAddress((prevData) => ({
        ...prevData,
        Bcountry: selectedOption.value,
      }));
    }
  };
  const handleStateChange = (selectedOption) => {
    setbillingAddress((prevAddress) => ({
      ...prevAddress,
      BpostalCode: '',
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      billingAddress: {
        ...prevErrors.billingAddress,
        BpostalCode: "",
      },
    }));
    setSelectedState(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        billingAddress: {
          ...prevErrors.billingAddress,
          Bstate: "", // Remove validation error for the country input
        },
      }));
      setbillingAddress((prevData) => ({
        ...prevData,
        Bstate: selectedOption.value,
      }));
    }
  };
  const handleCountryChangeShipping = (selectedOption) => {
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      SpostalCode: '',
      Sstate: ''
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      shippingAddress: {
        ...prevErrors.shippingAddress,
        SpostalCode: "",
      },
    }));
    setSelectedStateShipping('')
    setSelectedCountryShipping(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Scountry: "", // Remove validation error for the country input
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: ""
        }
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShipping(states);
      setShippingAddress((prevData) => ({
        ...prevData,
        Scountry: selectedOption.value,
      }));
    }
  };
  const handleCountryChangeShippingAll = (selectedOption) => {
    setSelectedCountryShippingAll(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Scountry: "", // Remove validation error for the country input
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: ""
        }
      }));
      const selectedCountryCode = selectedOption.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShippingAll(states);
      setShippingAddress((prevData) => ({
        ...prevData,
        Scountry: selectedOption.value,
      }));
      if (selectedStateShippingAll) {
        setShippingAddress((prevData) => ({
          ...prevData,
          Sstate: selectedStateShippingAll.value,
        }));
      }
    }
  };
  const handleStateChangeShipping = (selectedOption) => {
    setSelectedStateShipping(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Sstate: "", // Remove validation error for the country input
        },
      }));
      setShippingAddress((prevData) => ({
        ...prevData,
        Sstate: selectedOption.value,
      }));
    }
  };
  const handleStateChangeShippingAll = (selectedOption) => {
    setSelectedStateShippingAll(selectedOption);
    if (selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          Sstate: "", // Remove validation error for the country input
        },
      }));
      setShippingAddress((prevData) => ({
        ...prevData,
        Sstate: selectedOption.value,
      }));
    }
  };
  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryCode = selectedCountry?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptions(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountry]);
  useEffect(() => {
    if (selectedCountryShiping) {
      const selectedCountryCode = selectedCountryShiping?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShipping(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountryShiping]);
  useEffect(() => {
    if (selectedCountryShipingAll) {
      const selectedCountryCode = selectedCountryShipingAll?.value;
      const states = State.getStatesOfCountry(selectedCountryCode).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }));
      setStateOptionsShippingAll(states);
    } else {
      setStateOptions([]);
    }
  }, [selectedCountryShipingAll]);
  const handleShippingAddressChange = (event) => {
    const { name, value } = event.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      shippingAddress: {
        ...prevErrors.shippingAddress,
        [name]: value ? "" : ``,
      },
    }));
    if (name === "SpostalCode") {
      if (selectedShippingAddress ? !selectedCountryShiping : !selectedCountryShiping) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: "Please select a country."
          }
        }));
        return;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: ""
          }
        }));
      }
    }
    setShippingAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
    if ((!plandata?.planType && shippingAddress?.Scountry == 'US')) {
      setSelectedOption("free");
    } else {
      setSelectedOption("2-4");
    }
  };
  const calculateTotalPrice = (userCountValue, shippingMethod, initialCharge) => {
    const initialPrice =
      (cycle === "yearly"
        ? Math.floor(
          plandata?.yearlyPrice_perUser * plandata?.users * 12
        )
        : (plandata?.monthlyPrice_perUser * plandata?.users)) ?? 0;
    let additionalUsersPrice;
    if (cycle === 'monthly') {
      additionalUsersPrice = (userCountValue * plandata?.monthlyPrice_perUser) - (plandata?.monthlyPrice_perUser * plandata?.users);
    } else {
      additionalUsersPrice = (userCountValue * plandata?.yearlyPrice_perUser * 12) - (plandata?.users * plandata?.yearlyPrice_perUser * 12);
    }
    const addOnesPrice = selectedAddOne.reduce((totalPrice, item) => {
      let selectedaddonPrice;
      if (item.paymentType === "onetimepayment") {
        selectedaddonPrice = item?.Addonspaymentdata?.price;
      } else {
        const isYearly = cycle === "yearly";
        const isUserBased = item?.Addonspaymentdata?.isuserbased;
        const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
        const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
        const totalplan_users = userCountValue
        if (isUserBased && totalplan_users > item?.Addonspaymentdata?.numofuser) {
          selectedaddonPrice = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
        } else {
          selectedaddonPrice = basePrice;
        }
      }
      return totalPrice + (selectedaddonPrice || 0); // Ensure selectedaddonPrice is a number, default to 0 if undefined
    }, 0);
    setsubTotal((initialPrice || 0) + (additionalUsersPrice || 0))
    return (
      (initialPrice || 0) + (additionalUsersPrice || 0) + addOnesPrice + initialCharge
    );
  };
  const handleDecrement = () => {
    const isTeamPlan = plandata.planType === "Team" || plandata.planType === 'Professional';
    let initialCharge = 0;
    if (isTeamPlan && userInfo?.isInitailUser === true && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === 'Free')) {
      if (cycle === 'monthly') {
        initialCharge = plandata?.monthly_fee;
      } else {
        initialCharge = plandata?.yearly_fee;
      }
    }
    if (userCount > plandata?.users) {
      setUserCount(userCount - 1);
      setUpdatedYearlyPrice(calculateTotalPrice(userCount - 1, selectedOption, initialCharge));
    }
  };
  const handleIncrement = () => {
    const isTeamPlan = plandata.planType === "Team" || plandata.planType === 'Professional'
      ; let initialCharge = 0;
    if (isTeamPlan && userInfo?.isInitailUser === true && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === 'Free')) {
      if (cycle === 'monthly') {
        initialCharge = plandata?.monthly_fee;
      } else {
        initialCharge = plandata?.yearly_fee;
      }
    }
    setUserCount(userCount + 1);
    setUpdatedYearlyPrice(calculateTotalPrice(userCount + 1, selectedOption, initialCharge));
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.textContent;
    const sanitizedValue = inputValue.trim() === '' ? '0' : inputValue;
    if (!/^[1-9]\d*$/.test(sanitizedValue)) {
      return;
    }
    const parsedValue = parseInt(sanitizedValue, 10);
    const isTeamPlan = plandata.planType === 'Team' || plandata.planType === 'Professional';
    let initialCharge = 0;
    if (
      isTeamPlan &&
      userInfo?.isInitailUser === true &&
      (userInfo?.subscription_details?.plan === '' ||
        userInfo?.subscription_details?.plan === null ||
        userInfo?.subscription_details?.plan === 'Free')
    ) {
      if (cycle === 'monthly') {
        initialCharge = plandata?.monthly_fee;
      } else {
        initialCharge = plandata?.yearly_fee;
      }
    }
    if (!isNaN(parsedValue)) {
      setUserCount(parsedValue);
      setUpdatedYearlyPrice(calculateTotalPrice(parsedValue || 0, selectedOption, initialCharge));
    } else {
      setUserCount(0);
      setUpdatedYearlyPrice(calculateTotalPrice(0, selectedOption));
    }
  };
  const handleShippingMethod = (value) => {
    setSelectedOption(value);
    const isTeamPlan = plandata?.planType === "Team" || plandata?.planType === 'Professional';
    let initialCharge = 0;
    if (isTeamPlan && userInfo?.isInitailUser === true && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === 'Free')) {
      if (cycle === 'monthly') {
        initialCharge = plandata?.monthly_fee;
      } else {
        initialCharge = plandata?.yearly_fee;
      }
    }
    if (userCount > 0) {
      setUpdatedYearlyPrice(calculateTotalPrice(userCount, value, initialCharge));
    }
    setUpdatedYearlyPrice(calculateTotalPrice(userCount, value, initialCharge));
  };
  const showAccordion = (accordionNo) => {
    if (accordionNo === 1 && isAccordion1Filled) {
      setIsActive((prevActive) => ({
        a1: true,
        a2: false,
        a3: false,
        a4: false,
        a5: false,
      }));
    } else if (accordionNo === 2 && isAccordion2Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: true,
        a3: false,
        a4: false,
        a5: false,
      }));
    } else if (accordionNo === 3 && isAccordion3Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: false,
        a3: true,
        a4: false,
        a5: false,
      }));
    } else if (accordionNo === 4 && isAccordion4Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: false,
        a3: false,
        a4: true,
        a5: false,
      }));
    } else if (accordionNo === 5 && isAccordion5Filled) {
      setIsActive((prevActive) => ({
        a1: false,
        a2: false,
        a3: false,
        a4: false,
        a5: true,
      }));
    }
  };
  // this function is used to handle next accordion with current accordion validation
  const nextAccordion = async (accodionNo) => {
    if (accodionNo === 1) {
      const errors = {};
      if (fname === "") {
        errors.fname = "Please enter your first name.";
      }
      if (lname === "") {
        errors.lname = "Please enter your last name.";
      }
      if (fname === "" && lname !== "") {
        errors.fname = "Please enter your first name.";
        errors.lname = "-";
      }
      if (lname === "" && fname !== "") {
        errors.lname = "Please enter your last name.";
        errors.fname = "-";
      }
      // Validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === "") {
        errors.email = "Please enter your email address.";
      } else if (!emailRegex.test(email)) {
        errors.email = "Please enter a valid email address.";
      }
      if (contact === "" || !contact) {
        errors.contact = "Please enter your contact number.";
      }
      if (PlanName === "Team") {
        const trimmedCompanyName = companyName?.replace(/\s/g, '').toLowerCase();
        const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);
        if (!trimmedCompanyName) {
          errors.company = "Company name is required.";
        } else if (matchingCompany) {
          errors.company = "Company name already exists.";
        } else if (companyName === "") {
          errors.company = "Please enter your company name.";
        }
      }
      setFormErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setIsAccordion1Filled(true);
        showAccordion(2);
        setAccordion1(true);
        setIsActive((prevActive) => ({
          ...prevActive,
          a1: false,
          a2: true,
          a3: false,
          a4: false,
          a5: false,
        }));
      }
    }
    if (accodionNo === 2) {
      setIsAccordion2Filled(true);
      showAccordion(3);
      setIsActive((prevActive) => ({
        ...prevActive,
        a1: false,
        a2: false,
        a3: true,
        a4: false,
        a5: false,
      }));
    }
    if (accodionNo === 3) {
      setIsAccordion2Filled(true);
      setIsAccordion3Filled(true);
      showAccordion(4);
      setIsActive((prevActive) => ({
        ...prevActive,
        a1: false,
        a2: false,
        a3: false,
        a4: true,
        a5: false,
      }));
    }
    if (accodionNo === 4) {
      if (!plandata && cartAddedProducts && adduserCount === 0 && selectedAddons.length < 1) {
        try {
          const response = await axios.post(
            `${url}/payment/tax`,
            { shippingAddress },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          // 
          let tax_percentage = 0;
          for (const taxRate of response.data.calculation.tax_breakdown) {
            const percentageDecimal = parseFloat(taxRate.tax_rate_details.percentage_decimal);
            tax_percentage += percentageDecimal;
          }
          setTaxPercentage(tax_percentage.toFixed(2));
          setTaxId(response.data.calculation.id);
        } catch (error) {
          setTaxPercentage("0.00");
          setTaxId(null);
        }
      }
      setIsAccordion2Filled(true);
      if (isCouponApplied) {
        couponHandler();
      }
      setIsAccordion3Filled(true);
      setIsAccordion4Filled(true);
      setAccordion4(true);
      showAccordion(5);
      setIsActive((prevActive) => ({
        ...prevActive,
        a1: false,
        a2: false,
        a3: false,
        a4: false,
        a5: true,
      }));
    }
  };
  const handleInputChangeAccordion1 = (event) => {
    const { name, value } = event.target;
    const errors = { ...formErrors };
    if (name === "fname") {
      setFname(value);
      formErrors.fname = ""
    } else if (name === "lname") {
      setLname(value);
      formErrors.lname = ""
    } else if (name === "email") {
      setEmail(value);
      formErrors.email = ""
    } else if (name === "pnumber") {
      setContact(value);
      formErrors.contact = ""
    } else if (name === "cname") {
      setCompanyName(value);
      formErrors.company = ""
    }
  };
  const couponInputHandler = (e) => {
    const couponCode = [e.target.value.trim().replace(/\s+/g, ' ')];
    setCouponCode(couponCode);
    setApplyCouponbtnActive(false)
  }
  const couponHandler = async () => {
    setApplyCouponbtnActive(true)
    if (couponCode.length <= 0) {
      displayCouponError("Please enter the coupon code.")
      setApplyCouponbtnActive(false)
    } else {
      try {
        const { data } = await axios.post(`${url}/admin/coupons/get`,
          { codes: couponCode },
          config
        )
        if (data.success) {
          applyCouponToPrice(data.coupons)
          setApplyCouponbtnActive(false)
        } else {
          displayCouponError(data.msg)
          setApplyCouponbtnActive(false)
          setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
          setApplyCouponbtnActive(false)
          setIsCouponApplied(false)
          setTotalDiscountValue(0)
        }
      } catch (error) {
        setApplyCouponbtnActive(false)
        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
      }
    }
  }
  //modify price
  const applyCouponToPrice = async (coupons) => {
    if (!coupons || coupons.length === 0) {
      displayCouponError("No coupons Found")
      return; // No coupons provided
    }
    const firstCoupon = coupons[0];
    setApplycoupondata(firstCoupon)
    if (firstCoupon) {
      if (firstCoupon.usageLimit <= 0) {
        displayCouponError("This coupon has exceed a usage limit")
        return //show error that this coupon has exceed a uasge limit
      }
    }
    if (plandata && !selectedAddOne.length > 0) {
      const planIds = firstCoupon.planDiscount.map(item => item.plan_id);
      const planMatch = firstCoupon.planDiscount.find(item => item.plan_id === plandata._id);
      if (!planMatch) {
        displayCouponError("This coupon is not eligible for this plan.")
        removeAppliedCoupon()
        return
      }
      let couponValue = null;
      let updatedCoupon = {};
      let totalDiscount = 0;
      const discountValue = firstCoupon.discountAmount;
      if (firstCoupon.discountType === 'Fix discount price') {
        totalDiscount = (userCount) * discountValue;
      } else if (firstCoupon.discountType === "Percentage discount price") {
        totalDiscount = subTotal * discountValue / 100;
      } else if (firstCoupon.discountType === "Fix custom discount") {
        let usageLimit = 0;
        let planDiscount = 0;
        if (cycle === "monthly") {
          planDiscount = (userCount) * (planMatch.monthly.discountedPrice);
          usageLimit = planMatch.monthly.usageLimit;
        } else if (cycle === "yearly") {
          planDiscount = (userCount) * (planMatch.yearly.discountedPrice);
          usageLimit = planMatch.yearly.usageLimit;
        }
        totalDiscount = planDiscount
      } else {
        return
      }
      updatedCoupon = {
        discounValue: totalDiscount ?? null,
        coupon: coupons[0]
      };
      setFetchedCoupon(updatedCoupon);
      if (userInfo?.subscription_details?.subscription_id && firstCoupon.restrictions.forClients) {
        displayCouponError("This coupon is only for new users")
        return //show error that this coupon is only for new users
      }
      if (coupons.length > 1 && !firstCoupon.restrictions.allowMultipleCoupons) {
        displayCouponError('This coupon can not be used with other coupons')
        return //show error that this coupon cant be used with other coupons
      }
      if (couponValue) {
        if (couponValue.usageLimit <= 0) {
          displayCouponError('This coupon has exceed a usage limit');
          return //show error that this coupon has exceed a uasge limit
        }
      }
      if (plandata.planName === "Team") {
        if (firstCoupon.teamPlanUsage.minUsers < userCount && firstCoupon.teamPlanUsage.maxUsers > userCount) {
          displayCouponError('Min and Max user count doesnt match')
          return //show error that this error for user count min user and max users 
        }
      }
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
        userID: user._id
      })
      if (verifiedCoupon) {
        setIsCouponApplied(true);
        setTotalDiscountValue(totalDiscount)
      } else {
      }
    } else if (selectedAddOne.length > 0 && !plandata) {
      const addonsIds = selectedAddOne.map(item => item._id || null);
      const validAddons = firstCoupon.addonDiscount.filter(item => addonsIds.includes(item.addon_id));
      setCouponrelatedaddons(validAddons)
      const filteredSelectedAddOne = selectedAddOne.filter(item =>
        validAddons.some(validAddon => validAddon.addon_id === item._id)
      );
      if (!filteredSelectedAddOne || !filteredSelectedAddOne.length) {
        displayCouponError("This coupon doesn't apply to these addons")
        return;
      }
      const totalPrice = filteredSelectedAddOne.reduce((sum, item) => {
        let price = 0;
        if (item.paymentType === "onetimepayment") {
          price = item?.Addonspaymentdata?.price;
        } else {
          const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
          const isUserBased = item?.Addonspaymentdata?.isuserbased;
          const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
          const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
          const totalplan_users = userCount;
          if (isUserBased && totalplan_users >= item?.Addonspaymentdata?.numofuser) {
            price = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
          } else {
            price = basePrice;
          }
        }
        return sum + (price || 0);
      }, 0);
      if (!totalPrice) {
        displayCouponError("This coupon doesn't apply to these addons")
        return;
      }
      let updatedCoupon = {};
      let totalDiscount = 0;
      const discountValue = firstCoupon.discountAmount;
      if (firstCoupon.discountType === 'Fix discount price') {
        totalDiscount = (filteredSelectedAddOne?.length) * (discountValue);
      } else if (firstCoupon.discountType === "Percentage discount price") {
        totalDiscount = totalPrice * discountValue / 100;
      } else if (firstCoupon.discountType === "Fix custom discount") {
        validAddons.forEach(items => {
          if (items.type === "onetimepayment") {
            totalDiscount += items.discountedPrice
          }
          if (items.type === "subscription") {
            if (userInfo?.subscription_details?.billing_cycle === "monthly") {
              totalDiscount += items.monthlyDiscountedPrice
            } else {
              totalDiscount += items.yearlyDiscountedPrice
            }
          }
        })
      } else {
      }
      updatedCoupon = {
        discounValue: totalDiscount ?? null,
        coupon: coupons[0]
      };
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
        userID: user._id,
      });
      if (verifiedCoupon) {
        setIsCouponApplied(true);
        setTotalDiscountValue(totalDiscount);
      }
      setFetchedCoupon(updatedCoupon);
    } else if (cartAddedProducts && !plandata) {
      const matchedProducts = [];
      let totalQuantity = 0;
      let updatedCoupon = {}
      cartAddedProducts.forEach(cartProduct => {
        if (cartProduct.variations && cartProduct.variations.length > 0) {
          const matchingVariation = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.variations && couponProduct.variations.length > 0 &&
            couponProduct.variations.some(couponVariation =>
              cartProduct.variations.some(cartVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            )
          );
          if (matchingVariation) {
            const matchedCartVariation = cartProduct.variations.find(cartVariation =>
              matchingVariation.variations.some(couponVariation =>
                couponVariation.variation_id === cartVariation._id
              )
            );
            if (matchedCartVariation) {
              matchedProducts.push({ product: cartProduct, variation: matchedCartVariation });
            }
          }
        } else {
          const matchingProd = firstCoupon.productDiscount.find(couponProduct =>
            couponProduct.product_id === cartProduct.product._id
          );
          if (matchingProd) {
            matchedProducts.push({ product: cartProduct });
          }
        }
      });
      if (matchedProducts.length <= 0) {
        displayCouponError("This coupon is not applied on these products");
        return
      }
      const filterVariation = (variations, variationId) => {
        return variations.find(variation => variation._id === variationId) || null;
      };
      matchedProducts.forEach(matchingProduct => {
        if (matchingProduct.product.product.isOnSale) {
          totalQuantity += matchingProduct.product.quantity;
        }
      })
      const calculateTotalDiscount = () => {
        let productTotalPrice = 0;
        if (matchedProducts) {
          productTotalPrice = matchedProducts.reduce((sum, val) => {
            const variation = filterVariation(val.product.product.variations, val.product.variation);
            if (val.product.variation) {
              const productPrice = variation.saleprice
                ? variation.saleprice * val.product.quantity
                : variation.price * val.product.quantity;
              return sum + productPrice;
            } else {
              const productPrice = val.product.product.saleprice
                ? val.product.product.saleprice * val.product.quantity
                : val.product.product.price * val.product.quantity;
              return sum + productPrice;
            }
          }, 0);
        }
        const totalPrice = productTotalPrice
        return totalPrice;
      };
      let totalPrice = 0
      totalPrice = calculateTotalDiscount();
      let totalDiscount = 0;
      if (totalPrice) {
        const discountValue = firstCoupon.discountAmount;
        if (firstCoupon.discountType === 'Fix discount price') {
          totalDiscount = discountValue * totalQuantity;
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalDiscount = totalPrice * discountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          const products = firstCoupon.productDiscount;
          let totalCustomDiscount = 0;
          matchedProducts.forEach(matchedProduct => {
            const quantity = matchedProduct.product.quantity || 1; // If quantity is not provided, default to 1
            if (matchedProduct.product.variation) {
              const matchingVariation = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                couponProduct.variations.some(couponVariation =>
                  couponVariation.variation_id === matchedProduct.product.variation
                )
              );
              if (matchingVariation) {
                const variationDiscount = matchingVariation.variations.find(variation =>
                  variation.variation_id === matchedProduct.product.variation
                );
                if (variationDiscount) {
                  totalCustomDiscount += (variationDiscount.variationDiscountedPrice || 0) * quantity;
                }
              }
            } else {
              const matchingProduct = products.find(couponProduct =>
                couponProduct.product_id === matchedProduct.product.product._id &&
                !couponProduct.variations.length
              );
              if (matchingProduct) {
                totalCustomDiscount += (matchingProduct.discountedPrice || 0) * quantity;
              }
            }
          });
          totalDiscount = totalCustomDiscount;
        } else {
          displayCouponError("Internal Server error")
          return
        }
      } else {
        if (matchedProducts.length <= 0) {
          displayCouponError("Internal Server error")
          return
        }
      }
      updatedCoupon.discounValue = totalDiscount;
      updatedCoupon.coupon = coupons[0];
      setFetchedCoupon(updatedCoupon);
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
        userID: user._id,
      });
      if (verifiedCoupon) {
        setIsCouponApplied(true);
        setTotalDiscountValue(totalDiscount);
      }
    } else if (plandata && selectedAddOne.length > 0) {
      const planMatch = firstCoupon.planDiscount.find(item => item.plan_id === plandata._id);
      if (!planMatch) {
      }
      let couponValue = null;
      let updatedCoupon = {};
      let totalplanDiscount = 0;
      if (planMatch) {
        const discountValue = firstCoupon.discountAmount;
        if (firstCoupon.discountType === 'Fix discount price') {
          totalplanDiscount = (userCount) * discountValue;
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalplanDiscount = subTotal * discountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          let usageLimit = 0;
          let planDiscount = 0;
          if (cycle === "monthly") {
            planDiscount = (userCount) * (planMatch.monthly.discountedPrice);
            usageLimit = planMatch.monthly.usageLimit;
          } else if (cycle === "yearly") {
            planDiscount = (userCount) * (planMatch.yearly.discountedPrice);
            usageLimit = planMatch.yearly.usageLimit;
          }
          totalplanDiscount = planDiscount
        } else {
          return
        }
      }
      // ---------------------------for addons--------------------
      const addonsIds = selectedAddOne.map(item => item._id || null);
      const validAddons = firstCoupon.addonDiscount.filter(item => addonsIds.includes(item.addon_id));
      setCouponrelatedaddons(validAddons)
      const filteredSelectedAddOne = selectedAddOne.filter(item =>
        validAddons.some(validAddon => validAddon.addon_id === item._id)
      );
      if (!filteredSelectedAddOne || !filteredSelectedAddOne.length) {
      }
      // const totalPrice = filteredSelectedAddOne.reduce((sum, item) => sum + (item.Addonspaymentdata?.price || 0), 0);
      const totalPrice = filteredSelectedAddOne.reduce((sum, item) => {
        let price = 0;
        if (item.paymentType === "onetimepayment") {
          price = item?.Addonspaymentdata?.price;
        } else {
          const isYearly = cycle === "yearly";
          const isUserBased = item?.Addonspaymentdata?.isuserbased;
          const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
          const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
          const totalplan_users = userCount;
          if (isUserBased && totalplan_users >= item?.Addonspaymentdata?.numofuser) {
            price = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
          } else {
            price = basePrice;
          }
        }
        return sum + (price || 0);
      }, 0);
      if (!totalPrice) {
      }
      let totalDiscount = 0;
      const addondiscountValue = firstCoupon.discountAmount;
      if (totalPrice) {
        if (firstCoupon.discountType === 'Fix discount price') {
          totalDiscount = (filteredSelectedAddOne?.length) * (addondiscountValue);
        } else if (firstCoupon.discountType === "Percentage discount price") {
          totalDiscount = totalPrice * addondiscountValue / 100;
        } else if (firstCoupon.discountType === "Fix custom discount") {
          validAddons.forEach(items => {
            if (items.type === "onetimepayment") {
              totalDiscount += items.discountedPrice
            }
            if (items.type === "subscription") {
              if (cycle === "monthly") {
                totalDiscount += items.monthlyDiscountedPrice
              } else {
                totalDiscount += items.yearlyDiscountedPrice
              }
            }
          })
        } else {
        }
      }
      if (!totalPrice && !planMatch) {
        displayCouponError("Please enter a valid coupon.")
        return;
      }
      updatedCoupon = {
        discounValue: totalDiscount + totalplanDiscount ?? null,
        coupon: coupons[0]
      };
      setFetchedCoupon(updatedCoupon);
      const verifiedCoupon = await verifyCouponUsage({
        code: coupons[0].code,
        usageLimit: coupons[0].usageLimitPerUser,
        userID: user._id,
      });
      if (verifiedCoupon) {
        setIsCouponApplied(true);
        setTotalDiscountValue(totalDiscount + totalplanDiscount);
      }
    }
  }
  const verifyCouponUsage = async (couponDetails) => {
    try {
      // API to check coupon usage
      const { data } = await axios.post(`${url}/admin/coupons/checkusage`,
        couponDetails,
        config
      )
      if (data.success) {
        return true
      } else {
        // show error that user exceed limit
        displayCouponError(data.msg)
        setApplyCouponbtnActive(false)
        setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
        setApplyCouponbtnActive(false)
        setIsCouponApplied(false)
        setTotalDiscountValue(0)
        return false
      }
    } catch (error) {
      // Show internal server error
      setApplyCouponbtnActive(false)
      setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
      setApplyCouponbtnActive(false)
      setIsCouponApplied(false)
      setTotalDiscountValue(0)
      return false
      // 
    }
  }
  const removeAppliedCoupon = () => {
    setApplyCouponbtnActive(false)
    setFetchedCoupon({ coupon: {}, discounValue: 0, type: null })
    setApplyCouponbtnActive(false)
    setIsCouponApplied(false)
    setTotalDiscountValue(0)
    setCouponCode([]);
    setvisibleCouponForm(!visibleCouponForm)
  }
  useEffect(() => {
  }, [totalDiscountValue, fetchedCoupon])
  const handleAddoneCheckBox = (e, item) => {
    const selectedValue = item._id;
    let selectedaddonPrice;
    if (item.paymentType === "onetimepayment") {
      selectedaddonPrice = item?.Addonspaymentdata?.price;
    } else {
      const isYearly = cycle === "yearly";
      const isUserBased = item?.Addonspaymentdata?.isuserbased;
      const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
      const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
      const totalplan_users = userCount;
      if (isUserBased && totalplan_users >= item?.Addonspaymentdata?.numofuser) {
        selectedaddonPrice = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
      } else {
        selectedaddonPrice = basePrice;
      }
    }
    // Check if the item is already selected
    const isItemSelected = selectedAddOne.some(
      (selectedItem) => selectedItem._id === selectedValue
    );
    if (isItemSelected) {
      // If selected, remove it
      setSelectedAddOne((prevSelectedAddons) =>
        prevSelectedAddons.filter(
          (selectedItem) => selectedItem._id !== selectedValue
        )
      );
      setUpdatedYearlyPrice(updatedYearlyPrice - selectedaddonPrice);
    } else {
      // If not selected, add it
      setSelectedAddOne((prevSelectedAddons) => [
        ...prevSelectedAddons,
        item,
      ]);
      setUpdatedYearlyPrice(updatedYearlyPrice + selectedaddonPrice);
    }
  };
  // useEffect to handle initial selection
  useEffect(() => {
    if (selectedAddons && selectedAddons.length > 0) {
      let totalSelectedAddonPrice = 0;
      selectedAddons.forEach((item) => {
        let price;
        if (item.paymentType === "onetimepayment") {
          price = item?.Addonspaymentdata?.price;
        } else {
          const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
          const isUserBased = item?.Addonspaymentdata?.isuserbased;
          const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
          const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
          if (isUserBased && total_user > item?.Addonspaymentdata?.numofuser) {
            price = basePrice + ((total_user - item?.Addonspaymentdata?.numofuser) * userPrice);
          } else {
            price = basePrice;
          }
        }
        handleAddoneCheckBox(null, item);
        totalSelectedAddonPrice += price;
      });
      setsubTotal(totalSelectedAddonPrice);
      setUpdatedYearlyPrice(totalSelectedAddonPrice);
      setUserCount(0)
    }
  }, [selectedAddons]);
  useEffect(() => {
    if (adduserCount !== 0 && !plandata) {
      setsubTotal((adduserCount * adduserPrice) || 0)
      setUpdatedYearlyPrice((adduserCount * adduserPrice) || 0)
    }
  }, [selectedstateAddUserPrice, selectedstateAddUser, plandata, adduserCount]);
  const handleCardNameChange = (event) => {
    setCardInfo({
      ...cardInfo,
      cardName: event.target.value,
    });
    // Validate card name and set error message
    if (!event.target.value) {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "Please enter the name on the card.",
      }));
    } else {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardName: "", // Clear the error message if valid
      }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsButtonClicked(true);
    setButtonTextPay("Processing...");
    if (isCouponApplied) {
      await couponHandler();
    }
    const billAddress = {
      first_name: fname,
      last_name: lname,
      company_name: companyName,
      email: email,
      contact: contact,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;
    if (same) {
      const errors = {};
      if (shippingAddress.Scountry === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scountry: "Please select a country for shipping address.",
        };
      }
      if (shippingAddress.Scity === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scity: "Please enter your city.",
        };
      }
      if (shippingAddress.SpostalCode === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          SpostalCode: "Please enter your postal code.",
        };
      }
      if (shippingAddress.Sstreet1 === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Sstreet1: "Please enter your address.",
        };
      }
      if (selectedCountryShiping && stateOptionsShipping.length > 0) {
        if (shippingAddress.Sstate === "") {
          errors.shippingAddress = {
            ...errors.shippingAddress,
            Sstate: "Please enter your state",
          };
        }
      }
      setFormErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }
    if (!cardInfo.cardNumber || !stripe || !stripe.createPaymentMethod) {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardNumber: "",
        // cardNumber: "Please enter a valid card number.",
      }));
    } else {
      setPaymentErrors((prevErrors) => ({
        ...prevErrors,
        cardNumber: "",
      }));
    }
    try {
      const company_name = companyName
      const billingdata = billAddress
      const shippingData = shipAddress
      const userData = {
        first_name: fname,
        last_name: lname,
        email,
        contact,
        billing_address: billingdata,
        shipping_address: shippingData,
      };
      try {
        if (!stripe || !elements) return;
      } catch (error) {

      }
      const response = await axios.post(
        `${url}/payment/create-customer`,
        { user: userData },
        config
      )
      if (!response) {
        setIsButtonClicked(false);
        setButtonTextPay("Pay and subscribe");
        return alert("error in creating customer")
      }
      let payload;
      if (!selectedCard) {
        payload = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: fname + " " + lname,
            email: email,
            address: {
              line1: billingAddress.Bstreet1,
              line2: billingAddress.Bstreet2,
              city: billingAddress.Bcity,
              state: billingAddress.Bstate,
              postal_code: billAddress.postal_code,
              country: billingAddress.Bcountry,
            },
          },
          metadata: {
            custom_card_name: cardInfo.cardName
          }
        });
      }
      let createOrderData;
      let savedCardlast4Num;
      if (!selectedCard) {
        const enteredcard4num = payload.paymentMethod.card.last4
        savedCardlast4Num = savedCards?.filter(e => e.card.last4 === enteredcard4num);
      }
      let existingcard = false;
      if (!selectedCard) {
        if (savedCardlast4Num.length === 1) {
          existingcard = true
        } else {
          existingcard = false
        }
      }
      if (selectedCard) {
        createOrderData = {
          customerID: userInfo?.subscription_details?.customer_id ? userInfo?.subscription_details?.customer_id : response.data.customer.id,
          paymentToken: selectedCard.id,
        }
      } else if (existingcard === true) {
        const enteredcard4num = payload.paymentMethod.card.last4
        savedCardlast4Num = savedCards?.filter(e => e.card.last4 === enteredcard4num);
        createOrderData = {
          customerID: userInfo?.subscription_details?.customer_id ? userInfo?.subscription_details?.customer_id : response.data.customer.id,
          paymentToken: savedCardlast4Num[0].id,
        }
      } else {
        createOrderData = {
          customerID: userInfo?.subscription_details?.customer_id ? userInfo?.subscription_details?.customer_id : response.data.customer.id,
          paymentToken: payload.paymentMethod.id,
        }
      }
      if (cartAddedProducts && !plandata && !selectedAddons?.length > 0 && adduserCount === 0) { createOrder(createOrderData) }
      else if (selectedAddons?.length > 0) {
        { AddonpurchaseOrder(createOrderData, existingcard) }
      }
      else if (adduserCount !== 0 && !plandata) {
        AddUserpurchaseOrder(createOrderData, existingcard)
      }
      else {
        let savedCardlast4Num;
        if (!selectedCard) {
          const enteredcard4num = payload.paymentMethod.card.last4
          savedCardlast4Num = savedCards?.filter(e => e.card.last4 === enteredcard4num);
        }
        let existingcard = false;
        if (!selectedCard) {
          if (savedCardlast4Num.length === 1) {
            existingcard = true
          } else {
            existingcard = false
          }
        }
        let primary_card = false;
        let paymentData;
        let newUser;
        let totalAddons_value = 0;
        // calculating total price for addons
        const add = selectedAddOne.map((e) => e)
        const planMatch = applycoupondata?.planDiscount?.find(item => item.plan_id === plandata?._id);
        const combinedArray = [...add, ...planAddons];
        // Declaration of perUserDiscountPrice with conditions
        let perUserDiscountPrice;
        let xPaymentValue;
        if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
          perUserDiscountPrice = applycoupondata?.discountAmount;
          applycoupondata.planDiscount.forEach(element => {
            if (element.plan_id === plandata._id && cycle === "monthly") {
              xPaymentValue = element?.monthly?.usageLimit || 1
            }
            if (element.plan_id === plandata._id && cycle === "yearly") {
              xPaymentValue = element?.yearly?.usageLimit || 1
            }
          });
        } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
          perUserDiscountPrice = (perUserPrice * applycoupondata?.discountAmount) / 100;
          xPaymentValue = applycoupondata.planDiscount.forEach(element => {
            if (element.plan_id === plandata._id && cycle === "monthly") {
              xPaymentValue = element?.monthly?.usageLimit || 0
            }
            if (element.plan_id === plandata._id && cycle === "yearly") {
              xPaymentValue = element?.yearly?.usageLimit || 0
            }
          });
        } else if (applycoupondata?.discountType === "Fix custom discount") {
          if (cycle === "monthly") {
            perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
            xPaymentValue = planMatch?.monthly?.usageLimit || 0
          } else {
            perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
            xPaymentValue = planMatch?.yearly?.usageLimit || 0
          }
        }
        const Addonsarray = combinedArray?.map((addonId) => {
          const matchingAddon = planAddons?.find((addon) => addon._id === addonId._id);
          const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId._id);
          let price;
          let addonDiscountPrice;
          if (addonId.paymentType === "onetimepayment") {
            price = addonId?.Addonspaymentdata?.price;
            addonDiscountPrice = validAddon?.discountedPrice;
          } else {
            const isYearly = cycle === "yearly";
            const isUserBased = addonId?.Addonspaymentdata?.isuserbased;
            const userPrice = isYearly ? addonId?.Addonspaymentdata?.yearlyuserprice : addonId?.Addonspaymentdata?.monthlyuserprice;
            const basePrice = isYearly ? addonId?.Addonspaymentdata?.yearlyprice : addonId?.Addonspaymentdata?.monthlyprice;
            if (isUserBased && userCount > addonId?.Addonspaymentdata?.numofuser) {
              price = basePrice + ((userCount - addonId?.Addonspaymentdata?.numofuser) * userPrice);
            } else {
              price = basePrice;
            }
            addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
          }
          return {
            addonId: addonId._id,
            addonType: addonId.paymentType,
            status: 'N/A',
            assignTo: '',
            price: matchingAddon ? 0 : price,
            addonDiscountPrice: matchingAddon ? 0 : applycoupondata?.discountType === "Fix custom discount" ? addonDiscountPrice :
              (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
                (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (price * applycoupondata?.discountAmount) / 100 :
                  0,
          };
        });
        selectedAddOne.forEach(item => {
          let price = 0;
          let typeText = "";
          if (item.paymentType === "onetimepayment") {
            price = item?.Addonspaymentdata?.price || 0;
            typeText = "(One time)";
          } else if (cycle === "yearly") {
            price = item?.Addonspaymentdata?.yearlyprice || 0;
            typeText = "/year";
          } else {
            price = item?.Addonspaymentdata?.monthlyprice || 0;
            typeText = "/month";
          }
          totalAddons_value += price;
        });
        let planDetails = {
          type: cycle,
          planName: plandata?.planType,
          usersCount: userCount,
          planId: plandata._id,
          price: cycle === 'monthly' ? plandata.monthlyPrice_perUser : plandata.yearlyPrice_perUser * 12
        }
        if (selectedCard) {
          primary_card = false
          paymentData = {
            amount: updatedYearlyPrice - totalDiscountValue,
            billingAddress: billingAddress,
            name: `${fname} ${lname}`,
            company_name: companyName,
            selectedCard,
            paymentToken: selectedCard.id,
            customerID: response.data.customer.id,
            plandata: planDetails,
            taxId: taxId,
            userCount: userCount,
            userID: "test",
            subscriptionId: userInfo?.subscription_details?.subscription_id,
            subscriptionScheduleID: userInfo?.subscription_details?.subscription_schedules_id,
            sub_shed_itemId: userInfo?.subscription_details?.sub_shed_itemId,
            existingcard,
            primary_card,
            isCouponApplied,
            addonsData: Addonsarray,
            initialSetupCharge: setupFeeAdded ? cycle === 'monthly' ? plandata?.monthly_fee : plandata?.yearly_fee : null,
            totalAddons_value,
            appliedCouponData: {
              addonDiscount: Addonsarray,
              perUserDiscountPrice: perUserDiscountPrice,
              xPaymentValue: xPaymentValue
            }
          };
        } else if (existingcard === true) {
          paymentData = {
            amount: updatedYearlyPrice - totalDiscountValue,
            billingAddress: billingAddress,
            name: `${fname} ${lname}`,
            company_name: companyName,
            selectedCard,
            paymentToken: savedCardlast4Num[0].id,
            customerID: response.data.customer.id,
            plandata: planDetails,
            taxId: taxId,
            userID: "test",
            userCount: userCount,
            subscriptionId: userInfo?.subscription_details?.subscription_id,
            subscriptionScheduleID: userInfo?.subscription_details?.subscription_schedules_id,
            sub_shed_itemId: userInfo?.subscription_details?.sub_shed_itemId,
            existingcard,
            initialSetupCharge: setupFeeAdded ? cycle === 'monthly' ? plandata?.monthly_fee : plandata?.yearly_fee : null,
            totalAddons_value,
            isCouponApplied,
            addonsData: Addonsarray,
            appliedCouponData: {
              addonDiscount: Addonsarray,
              perUserDiscountPrice: perUserDiscountPrice,
              xPaymentValue: xPaymentValue
            }
          };
        } else if (userInfo?.subscription_details?.plan === null) {
          primary_card = false
          newUser = true
          paymentData = {
            amount: updatedYearlyPrice - totalDiscountValue,
            billingAddress: billingAddress,
            name: `${fname} ${lname}`,
            company_name: companyName,
            paymentToken: payload.paymentMethod.id,
            customerID: response.data.customer.id,
            plandata: planDetails,
            taxId: taxId,
            userID: "test",
            userCount: userCount,
            subscriptionId: userInfo?.subscription_details?.subscription_id,
            subscriptionScheduleID: userInfo?.subscription_details?.subscription_schedules_id,
            sub_shed_itemId: userInfo?.subscription_details?.sub_shed_itemId,
            existingcard,
            primary_card,
            newUser,
            isCouponApplied,
            addonsData: Addonsarray,
            initialSetupCharge: setupFeeAdded ? cycle === 'monthly' ? plandata?.monthly_fee : plandata?.yearly_fee : null,
            totalAddons_value,
            appliedCouponData: {
              addonDiscount: Addonsarray,
              perUserDiscountPrice: perUserDiscountPrice,
              xPaymentValue: xPaymentValue
            }
          };
        } else {
          paymentData = {
            amount: updatedYearlyPrice - totalDiscountValue,
            billingAddress: billingAddress,
            name: `${fname} ${lname}`,
            company_name: companyName,
            paymentToken: payload.paymentMethod.id,
            customerID: response.data.customer.id,
            plandata: planDetails,
            taxId: taxId,
            userID: "test",
            userCount: userCount,
            subscriptionId: userInfo?.subscription_details?.subscription_id,
            subscriptionScheduleID: userInfo?.subscription_details?.subscription_schedules_id,
            sub_shed_itemId: userInfo?.subscription_details?.sub_shed_itemId,
            existingcard,
            primary_card: true,
            newUser,
            initialSetupCharge: setupFeeAdded ? cycle === 'monthly' ? plandata?.monthly_fee : plandata?.yearly_fee : null,
            totalAddons_value,
            isCouponApplied,
            addonsData: Addonsarray,
            appliedCouponData: {
              addonDiscount: Addonsarray,
              perUserDiscountPrice: perUserDiscountPrice,
              xPaymentValue: xPaymentValue || 0
            }
          };
        }
        let result = [];
        if (userInfo?.subscription_details?.subscription_id) {
          result = await axios.post(
            `${url}/payment/change-plan`,
            paymentData,
            config
          );
        } else {
          result = await axios.post(
            `${url}/payment/process`,
            { Data: paymentData },
            config
          );
        }
        const client_secret = result?.data?.client_secret;
        const addonclient_secret = result?.data?.addonClient_secret;
        const subscriptionID = result?.data?.subscriptionID;
        const subscriptionScheduleID = result?.data?.subscriptionScheduleID;
        const endDate = result?.data?.endDate;
        const paymentStatus = result?.data?.status;
        const addonpaymentStatus = result?.data?.addonstatus;
        const subscriptionDetails = result?.data?.subscriptionDetails;
        const sub_shed_itemId = subscriptionDetails.find(element => element.metadata.type === 'plan')?.id || null;
        let couponData = {};
        if (isCouponApplied) {
          couponData = {
            appliedCouponCode: fetchedCoupon.coupon.code,
            discountValue: fetchedCoupon.discounValue
          };
        }
        if (client_secret !== "switch-plan") {
          let payment_method
          if (selectedCard) {
            payment_method = selectedCard.id
          } else {
            payment_method = payload.paymentMethod.id
          }
          if (paymentStatus === 'requires_action' || paymentStatus === 'requires_confirmation') {
            const paymentIntent = await stripe.confirmCardPayment(client_secret, {
              payment_method
            });
            if (paymentIntent.error) {
              setIsButtonClicked(false);
              setButtonTextPay("Pay and subscribe");
              popup.style.display = "flex";
              paymentFailure.style.display = "block";
              setCardErrorMessage(paymentIntent.error.message);
              setTimeout(() => {
                paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
                popup.style.display = "none";
              }, 4000);
              return false;
            }
          }
          if (addonpaymentStatus === 'requires_confirmation') {
            const paymentIntent = await stripe.confirmCardPayment(addonclient_secret, {
              payment_method
            });
            if (paymentIntent.error) {
              setIsButtonClicked(false);
              setButtonTextPay("Pay and subscribe");
              popup.style.display = "flex";
              paymentFailure.style.display = "block";
              setCardErrorMessage(paymentIntent.error.message);
              setTimeout(() => {
                paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
                popup.style.display = "none";
              }, 4000);
              return false;
            }
          }
        }
        const popup = document.getElementById("popup");
        const paymentSuccess = document.getElementById("payment-success");
        const paymentFailure = document.getElementById("payment-failure");
        if (result.error) {
          setIsButtonClicked(false);
          setButtonTextPay("Pay and subscribe");
          setCardErrorMessage(result.error.message);
          popup.style.display = "flex";
          paymentFailure.style.display = "block";
          setTimeout(() => {
            paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
            popup.style.display = "none";
          }, 4000);
        } else {
          const add = selectedAddOne.map((e) => e)
          const userData = { userShippingOrderNote: userShippingOrderNote, referredby: referrer, referredName: referrerName }
          const combinedArray = [...add, ...planAddons];
          const planMatch = applycoupondata?.planDiscount.find(item => item.plan_id === plandata?._id);
          // Declaration of perUserDiscountPrice with conditions
          let perUserDiscountPrice;
          if (applycoupondata?.discountType === "Fix discount price" && planMatch) {
            perUserDiscountPrice = applycoupondata?.discountAmount;
          } else if (applycoupondata?.discountType === "Percentage discount price" && planMatch) {
            perUserDiscountPrice = (perUserPrice * applycoupondata?.discountAmount) / 100;
          } else if (applycoupondata?.discountType === "Fix custom discount") {
            if (cycle === "monthly") {
              perUserDiscountPrice = planMatch?.monthly?.discountedPrice || 0;
            } else {
              perUserDiscountPrice = planMatch?.yearly?.discountedPrice || 0;
            }
          }
          const planData = {
            planID: plandata?._id,
            InitialSetupFee: setupFeeAdded ? cycle === 'monthly' ? plandata?.monthly_fee : plandata?.yearly_fee : null,
            perUser_price: perUserPrice,
            perUserDiscountPrice: perUserDiscountPrice,
            smartAccessories: matchedProducts?.map((e) => ({
              productId: e.productId, productName: e.productId ? e.Type : e.name, variationId: e._id.toString(), price: 0, subtotal: 0, quantity: 1
            })),
            addones: combinedArray?.map((addonId) => {
              const matchingAddon = planAddons?.find((addon) => addon._id === addonId._id);
              const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId._id);
              let price;
              let itemId;
              let addonDiscountPrice;
              if (addonId.paymentType === "onetimepayment") {
                price = addonId?.Addonspaymentdata?.price;
                addonDiscountPrice = validAddon?.discountedPrice || 0;
              } else {
                const isYearly = cycle === "yearly";
                const isUserBased = addonId?.Addonspaymentdata?.isuserbased;
                const userPrice = isYearly ? addonId?.Addonspaymentdata?.yearlyuserprice : addonId?.Addonspaymentdata?.monthlyuserprice;
                const basePrice = isYearly ? addonId?.Addonspaymentdata?.yearlyprice : addonId?.Addonspaymentdata?.monthlyprice;
                if (isUserBased && userCount > addonId?.Addonspaymentdata?.numofuser) {
                  price = basePrice + ((userCount - addonId?.Addonspaymentdata?.numofuser) * userPrice);
                } else {
                  price = basePrice;
                }
                addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
              }
              const matchingSubscription = subscriptionDetails?.find(subscription => {
                const metadata = subscription.metadata || {};
                return metadata.type === "addon" && metadata.id === addonId._id;
              });
              if (matchingSubscription) {
                itemId = matchingSubscription.id;
              }
              return {
                addonId: addonId._id,
                status: 'N/A',
                assignTo: '',
                addonName: addonId.publicname,
                itemId: itemId,
                price: matchingAddon ? 0 : price,
                addonDiscountPrice: applycoupondata?.discountType === "Fix custom discount" ? addonDiscountPrice :
                  (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
                    (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (price * applycoupondata?.discountAmount) / 100 :
                      0,
              };
            }),
            subscription_id: subscriptionID,
            subscriptionScheduleID: subscriptionScheduleID,
            sub_shed_itemId: sub_shed_itemId,
            total_amount: isCouponApplied ? (updatedYearlyPrice - totalDiscountValue) + parseFloat(shippingInfo.cost, 10) : updatedYearlyPrice + parseFloat(shippingInfo.cost, 10),
            plan: plandata.planType,
            endDate: endDate,
            total_user: [{ additionalUser: userCount - baseUserCount, baseUser: baseUserCount }],
            billing_cycle: cycle,
            recurring_amount:
              cycle === "yearly" ? fixedPrice : plandata?.monthlyPrice_perUser * plandata?.users,
            renewal_date: isoDate,
            taxRate: taxPercentage,
            customer_id: response.data.customer.id,
          };
          let cardDetails;
          if (selectedCard) {
            cardDetails = {
              cardName: "Test",
              cardNumber: selectedCard.card.last4,
              brand: selectedCard.card.brand,
              cardExpiryMonth: selectedCard.card.exp_month,
              cardExpiryYear: selectedCard.card.exp_year,
            };
          } else {
            cardDetails = {
              cardName: cardInfo.cardName,
              cardNumber: payload.paymentMethod.card.last4,
              brand: payload.paymentMethod.card.brand,
              cardExpiryMonth: payload.paymentMethod.card.exp_month,
              cardExpiryYear: payload.paymentMethod.card.exp_year,
            };
          }
          const shipping_method = {
            type: selectedOption,
            price:
              selectedOption === "free"
                ? 0
                : selectedOption === "2-4"
                  ? 20
                  : selectedOption === "overnight"
                    ? 60
                    : 0,
          };
          if (result.data.success === true) {
            const { data } = await axios.post(
              `${url}/checkout`,
              { userData, billingdata, shippingData, company_name, shipping_method, planData, cardDetails, saveAddress, selectedEditAddress, couponData, totalShipping: shippingInfo.cost, serviceCode: shippingInfo.serviceCode, },
              {
                withCredentials: true,
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (data.success) {
              popup.style.display = "flex";
              paymentSuccess.style.display = "block";
              setTimeout(() => {
                if (firstLogin === true && (PlanName === 'Free' || PlanName === "Professional")) {
                  window.location.href = `/profile/information/${user?._id}`;
                }
                else if (firstLogin === true && PlanName === 'Team') {
                  window.location.href = `/company/information`;
                } else {
                  window.location.href = `/dashboard`;
                }
              }, 4000);
            }
          } else {
            setIsButtonClicked(false);
            setButtonTextPay("Pay and subscribe");
            alert("There's some issue while processing payment ");
          }
        }
      }
    } catch (error) {
      const popup = document.getElementById("popup");
      const paymentFailure = document.getElementById("payment-failure");
      setIsButtonClicked(false);
      setButtonTextPay("Pay and subscribe");
      setCardErrorMessage(error.response.data.error);
      popup.style.display = "flex";
      paymentFailure.style.display = "block";
      setTimeout(() => {
        paymentFailure.style.display = "none";
        popup.style.display = "none";
      }, 4000);
    }
  }
  const handleSubmiteFreeAccount = async (e) => {
    e.preventDefault();
    setIsButtonClicked(true);
    setButtonText("Processing...");
    const billAddress = {
      first_name: fname,
      last_name: lname,
      company_name: companyName,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;
    if (same) {
      const errors = {};
      if (shippingAddress.Scountry === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scountry: "Please select a country for shipping address.",
        };
      }
      if (shippingAddress.Scity === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Scity: "Please enter your city.",
        };
      }
      if (shippingAddress.SpostalCode === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          SpostalCode: "Please enter your postal code.",
        };
      }
      if (shippingAddress.Sstreet1 === "") {
        errors.shippingAddress = {
          ...errors.shippingAddress,
          Sstreet1: "Please enter your address.",
        };
      }
      if (selectedCountryShiping && stateOptionsShipping.length > 0) {
        if (shippingAddress.Sstate === "") {
          errors.shippingAddress = {
            ...errors.shippingAddress,
            Sstate: "Please enter your state",
          };
        }
      }
      setFormErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }
    const company_name = companyName
    const billingdata = billAddress
    const shippingData = shipAddress
    try {
      const state = State?.getStateByCodeAndCountry(shippingAddress.Sstate, shippingAddress.Scountry)?.name
      const apiUrl = `https://api.zippopotam.us/${shippingAddress.Scountry}/${shippingAddress.SpostalCode.trim()}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (response.ok !== true) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: "Please enter a valid postal code.",
          },
        }));
      } else if (data.places[0].state?.trim() !== state.trim()) {
        setIsButtonClicked(false);
        setButtonText("Submit");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          shippingAddress: {
            ...prevErrors.shippingAddress,
            SpostalCode: `Please enter a valid postal code for ${state}`,
          },
        }));
      } else {
        const userData = {
          first_name: fname,
          last_name: lname,
          email,
          contact,
          billing_address: billingdata,
          shipping_address: shippingData,
        };
        const response = await axios.post(
          `${url}/payment/create-customer`,
          { user: userData },
          config
        )
        if (!response) {
        }
        const planData = {
          total_amount: updatedYearlyPrice,
          plan: plandata.planType,
          total_user: [{ additionalUser: userCount - baseUserCount, baseUser: baseUserCount }],
          recurring_amount: 0,
          taxRate: taxPercentage,
          customer_id: userInfo.subscription_details.customer_id
        };
        const shipping_method = {
          type: selectedOption,
          price:
            selectedOption === "free"
              ? 0
              : selectedOption === "2-4"
                ? 20
                : selectedOption === "overnight"
                  ? 60
                  : 0,
        };
        const { data } = await axios.post(
          `${url}/checkout/free`,
          { userData, billingdata, shippingData, company_name, shipping_method, planData, saveAddress, selectedEditAddress },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (data.success) {
          setTimeout(() => {
            if (firstLogin === true && (PlanName === 'Free' || PlanName === "Professional")) {
              window.location.href = `/profile/information/${user?._id}`
            }
            else if (firstLogin === true && PlanName === 'Team') {
              window.location.href = `/company/information`;
            } else {
              window.location.href = `/dashboard`;
            }
            // }
          }, 1000);
        } else {
        }
      }
    } catch (error) {
      ;
      setIsButtonClicked(false);
      setButtonText("Submit");
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: {
          ...prevErrors.shippingAddress,
          SpostalCode: "Please enter a valid postal code.",
        },
      }));
    }
  }
  const numchange = (e) => {
    const value = e.target.value;
    const numericInput = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const maxLength = 15;
    const formattedInput = numericInput.slice(0, maxLength); // Limit to max length
    setContact(formattedInput);
    if (/^[0-9]{7,15}$/.test(formattedInput)) {
      setFormErrors({ ...formErrors, contact: "" });
    } else {
      setFormErrors({ ...formErrors, contact: "Please enter a valid cell phone number." });
    }
  };
  const [Accordion1, setAccordion1] = useState(false);
  const [Accordion2, setAccordion2] = useState(false);
  const [Accordion3, setAccordion3] = useState(false);
  const [Accordion4, setAccordion4] = useState(false);
  const [Accordion5, setAccordion5] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    if (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null) {
      setSaveAddress(true);
      setShippingAddress({
        Sstreet1: billingAddress.Bstreet1,
        Sstreet2: billingAddress.Bstreet2,
        Scity: billingAddress.Bcity,
        SpostalCode: billingAddress.BpostalCode,
        Sstate: billingAddress.Bstate,
        Scountry: billingAddress.Bcountry,
      });
    } else {
      setSaveAddress(false);
    }
  }, [])
  const [previousRates, setPreviousRates] = useState(null);
  const handleCheckboxChange = async () => {
    setSame(!same); // Toggle the value of `same` when the checkbox is clicked
    if (!same) {
      setShippingAddress({
        Sstreet1: "",
        Sstreet2: "",
        Scity: "",
        SpostalCode: "",
        Sstate: "",
        Scountry: "",
      });
      setSelectedCountryShipping(null)
      setSelectedStateShipping(null)
    } else {
      // Use the same address as billing address
      setShippingAddress({
        Sstreet1: billingAddress.Bstreet1,
        Sstreet2: billingAddress.Bstreet2,
        Scity: billingAddress.Bcity,
        SpostalCode: billingAddress.BpostalCode,
        Sstate: billingAddress.Bstate,
        Scountry: billingAddress.Bcountry,
      });
    }
    if (same) {
      setRates(previousRates);
    }
    setSelectedShippingAddress(null);
    setSelectedEditAddress(null)
    setPreviousRates(rates);
    setSelectedOption('free')
  };
  const handleCheckboxChangeNew = (addressData) => {
    const country = Country?.getCountryByCode(addressData.country)?.name
    const statename = State?.getStateByCodeAndCountry(addressData.state, addressData.country)?.name
    setSelectedShippingAddress(addressData);
    setSelectedCountryShipping({
      value: addressData.country,
      label: country
    });
    setSelectedStateShipping({
      value: addressData.state,
      label: statename
    });
    // Use the selected address as the shipping address
    setShippingAddress({
      Sstreet1: addressData.line1,
      Sstreet2: addressData.line2,
      Scity: addressData.city,
      SpostalCode: addressData.postal_code,
      Sstate: addressData.state,
      Scountry: addressData.country,
    });
    setCheckboxChecked(!checkboxChecked);
    handleCalculateRatesAndContinue(addressData);
  };
  let currentDate = new Date()
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear() + 1;
  if (cycle === "yearly") {
    const currentDate = new Date();
    day = currentDate.getDate();
    month = (currentDate.getMonth() + 1) % 12 || 12;
    year = currentDate.getFullYear() + 1;
  }
  if (cycle === "monthly") {
    const currentDate = new Date();
    day = currentDate.getDate();
    month = (currentDate.getMonth() + 2) % 12 || 12;
    year = currentDate.getFullYear();
  }
  const formattedDate = `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
    }/${year}`;
  const parts = formattedDate.split("/");
  const isoDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [allFieldsFilledFree, setAllFieldsFilledFree] = useState(false);
  const areAllFieldsFilled = () => {
    const isAccordion1Filled =
      fname?.trim() !== "" &&
      lname?.trim() !== "" &&
      email?.trim() !== "" &&
      contact !== ""
    const isAccordion2Filled =
      billingAddress.Bcountry?.trim() !== "" &&
      billingAddress.Bcity?.trim() !== "" &&
      billingAddress.BpostalCode?.trim() !== "" &&
      billingAddress.Bstate?.trim() !== "" &&
      billingAddress.Bstreet1?.trim() !== ""
    const isAccordion3Filled = !same && (shippingAddress.Scountry?.trim() !== "" &&
      shippingAddress.Scity?.trim() !== "" &&
      shippingAddress.SpostalCode?.trim() !== "" &&
      shippingAddress.Sstate?.trim() !== "" && shippingAddress.Sstreet1?.trim() !== "");
    const isAccordion4Filled = same && (shippingAddress.Scountry?.trim() !== "" &&
      shippingAddress.Scity?.trim() !== "" &&
      shippingAddress.SpostalCode?.trim() !== "" &&
      shippingAddress.Sstate?.trim() !== "" && shippingAddress.Sstreet1?.trim() !== "");
    const isAccordion5Filled = (showPaymentSection === true ?
      (cardInfo.cardName?.trim() !== "" &&
        cardInfo.cardNumber !== null &&
        cardInfo.csv !== null &&
        cardInfo.expiryDate !== null) : (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details.plan === null) ?
        (cardInfo.cardName?.trim() !== "" &&
          cardInfo.cardNumber !== null &&
          cardInfo.csv !== null &&
          cardInfo.expiryDate !== null) : true);
    const allAccordionsFilled = isAccordion1Filled && isAccordion2Filled && (isAccordion3Filled || isAccordion4Filled) && isAccordion5Filled;
    const allAccordionsFilledFree = isAccordion1Filled && isAccordion2Filled
    setAllFieldsFilled(allAccordionsFilled);
    setAllFieldsFilledFree(allAccordionsFilledFree);
  };
  useEffect(() => {
    areAllFieldsFilled();
  }, [
    shippingAddress,
    billingAddress,
    fname,
    lname,
    contact,
    email,
    companyName,
    cardInfo,
    policyIsChecked,
  ]);
  const redirectToDashboard = () => {
    if (user && userInfo) {
      if (user?.first_login === true && (PlanName === 'Free' || PlanName === "Professional")) {
        window.location.href = `/profile/information/${user?._id}`
      }
      else if (user?.first_login === true && PlanName === 'Team') {
        window.location.href = `/company/information`;
      } else {
        window.location.href = `/dashboard`;
      }
    }
  };
  useEffect(() => {
    if (!user) {
      dispatch(loadUser())
    }
    if (!userInfo) {
      dispatch(fetchUserInformationDetails());
    }
  }, [user, userInfo])
  useEffect(() => {
    fetchCard()
  }, [userInfo, dispatch]);


  const fetchCard = () => {
    if (userInfo && stripeSavedCards.length < 1) {
      const customerID = userInfo.subscription_details.customer_id;
      dispatch(fetchSavedCards(customerID));
    }
  }
  useEffect(() => {
    SetsavedCards(stripeSavedCards)
  }, [stripeSavedCards])
  const hideElements = () => {
    const popup = document.getElementById("popup");
    const paymentFailure = document.getElementById("payment-failure");
    if (paymentFailure) {
      paymentFailure.style.display = "none";
    }
    if (popup) {
      popup.style.display = "none";
    }
  };
  const generateUniqueId = () => {
    // Generate a random number (you might want to use a more sophisticated method)
    const randomNumber = Math.floor(Math.random() * 10000);
    // Create the unique ID with a constant prefix and the dynamic number
    const uniqueId = `OTC-${new Date().getFullYear()}-${randomNumber}`;
    return uniqueId;
  };
  // Make the POST request using axios
  const createOrder = async (data) => {
    const billAddress = {
      first_name: fname,
      last_name: lname,
      company_name: companyName,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;
    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }
    const createOrderData = data;
    try {
      const totalAmount = calculateTotalPriceOfProduct()
      const tax = ((calculateTotalPriceOfProduct() * taxPercentage) / 100).toFixed(2)
      const smartAccessories = []
      const userData = { userShippingOrderNote: userShippingOrderNote, referredby: referrer, referredName: referrerName }
      // Iterate through cartAddedProducts to create smartAccessories
      cartAddedProducts.forEach((cartItem) => {
        const { product, quantity, variation } = cartItem;
        if (variation) {
          const variationn = getVariationById(product?.variations, variation);
          const { _id, price, saleprice } = variationn;
          const variationId = _id;
          const productName = product.name;
          const productId = product._id;
          const actualPrice = saleprice || price; // Use saleprice if available, otherwise use price
          const subtotal = (actualPrice * quantity).toFixed(2);
          const formattedPrice = actualPrice.toFixed(2);
          const uniqueId = generateUniqueId();
          smartAccessories.push({
            productId,
            productName,
            variationId,
            price: formattedPrice,
            subtotal,
            quantity,
            uniqueId
          });
        } else {
          const { _id, price, saleprice } = product;
          const productId = _id;
          const productName = product.name;
          const actualPrice = saleprice || price; // Use saleprice if available, otherwise use price
          const subtotal = (actualPrice * quantity).toFixed(2);
          const formattedPrice = actualPrice.toFixed(2);
          const uniqueId = generateUniqueId();
          smartAccessories.push({
            productId,
            price: formattedPrice,
            subtotal,
            quantity,
            productName,
            uniqueId
          });
        }
      });
      const billingdata = billAddress
      const shippingData = shipAddress
      let payload;
      if (!selectedCard) {
        payload = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: fname + " " + lname,
            email: email,
            address: {
              line1: billingAddress.Bstreet1,
              line2: billingAddress.Bstreet2,
              city: billingAddress.Bcity,
              state: billingAddress.Bstate,
              postal_code: billAddress.postal_code,
              country: billingAddress.Bcountry,
            },
          },
          metadata: {
            custom_card_name: cardInfo.cardName
          }
        });
      }
      let cardDetails;
      if (selectedCard) {
        cardDetails = {
          cardName: "Test",
          cardNumber: selectedCard.card.last4,
          brand: selectedCard.card.brand,
          cardExpiryMonth: selectedCard.card.exp_month,
          cardExpiryYear: selectedCard.card.exp_year,
        };
      } else {
        cardDetails = {
          cardName: cardInfo.cardName,
          cardNumber: payload.paymentMethod.card.last4,
          brand: payload.paymentMethod.card.brand,
          cardExpiryMonth: payload.paymentMethod.card.exp_month,
          cardExpiryYear: payload.paymentMethod.card.exp_year,
        };
      }
      let couponData = {};
      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }
      let savedCardlast4Num;
      if (!selectedCard) {
        const enteredcard4num = payload.paymentMethod.card.last4
        savedCardlast4Num = savedCards?.filter(e => e.card.last4 === enteredcard4num);
      }
      let existingcard = false;
      if (!selectedCard) {
        if (savedCardlast4Num.length === 1) {
          existingcard = true
        } else {
          existingcard = false
        }
      }
      const { data } = await axios.post(
        `${url}/create-order`,
        { userId: user._id, cardDetails, userData, smartAccessories, totalAmount, tax, createOrderData, billingAddress: billingdata, shippingAddress: shippingData, selectedCard, existingcard, saveAddress, selectedEditAddress, email: email, sumTotalWeights: sumTotalWeights, totalShipping: shippingInfo.cost, serviceCode: shippingInfo.serviceCode, couponData },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      ); // Close the Axios configuration object here
      if (data.success) {
        let payment_method
        if (selectedCard) {
          payment_method = selectedCard.id
        } else {
          payment_method = payload.paymentMethod.id
        }
        const confirmPayment = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method
        });
        if (confirmPayment.paymentIntent) {
          setTimeout(() => {
            paymentSuccess.style.display = "none"; // Hide the success message after 5 seconds
            popup.style.display = "none";
            navigate("/payment-success")
          }, 1000);
        } else {
          setIsButtonClicked(false);
          setButtonTextPay("Pay and subscribe");
          popup.style.display = "flex";
          paymentFailure.style.display = "block";
          setTimeout(() => {
            paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
            popup.style.display = "none";
          }, 4000);
          return false;
        }
        const updateCart = async () => {
          try {
            // Make a POST request to your backend API endpoint using Axios
            const updatedCart = []
            const _id = user?._id
            const { data } = await axios.post(
              `${url}/cart/removeproduct`,
              { addedProducts: updatedCart, _id },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              }
            );
            dispatch(fetchCart(user));
          } catch (error) {
            setIsButtonClicked(false);
            setButtonTextPay("Pay and subscribe");
          }
        }
        updateCart()
      } else {
        setIsButtonClicked(false);
        setButtonTextPay("Pay and subscribe");
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
          popup.style.display = "none";
        }, 4000);
      }
    } catch (error) {
      setIsButtonClicked(false);
      setButtonTextPay("Pay and subscribe");
    }
  };
  const AddonpurchaseOrder = async (data, existingcard) => {
    const billAddress = {
      first_name: fname,
      last_name: lname,
      company_name: companyName,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;
    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }
    const createOrderData = data;
    try {
      const totalAmount = updatedYearlyPrice; // Assuming updatedYearlyPrice is available in this scope
      const billingdata = billAddress;
      const shippingData = shipAddress;
      const shipping_method = {
        type: selectedOption,
        price:
          selectedOption === "free"
            ? 0
            : selectedOption === "2-4"
              ? 20
              : selectedOption === "overnight"
                ? 60
                : 0,
      };
      let couponData = {};
      if (isCouponApplied) {
        couponData = {
          appliedCouponCode: fetchedCoupon.coupon.code,
          discountValue: fetchedCoupon.discounValue
        };
      }
      const orderData = {
        userId: user?._id, // Set your user ID or obtain it from the data object as needed
        first_name: fname,
        last_name: lname,
        type: userInfo.subscription_details.billing_cycle,
        email,
        contact,
        customerID :userInfo?.subscription_details?.customer_id,
        isCouponApplied,
        subscriptionId: userInfo?.subscription_details?.subscription_id,
        subscriptionScheduleID: userInfo?.subscription_details?.subscription_schedules_id,
        sub_shed_itemId: userInfo?.subscription_details?.sub_shed_itemId,
        totalAmount: totalAmount - (totalDiscountValue || 0),
        tax: taxPercentage, // Update with the correct tax information if needed
        billingAddress: billingdata,
        shippingAddress: shippingData,
        addaddons: selectedAddons.map((addonId) => {
          const validAddon = couponrelatedAddons?.find((validAddon) => validAddon.addon_id === addonId._id);
          let price;
          let addonDiscountPrice;
          if (addonId.paymentType === "onetimepayment") {
            price = addonId?.Addonspaymentdata?.price;
            addonDiscountPrice = validAddon?.discountedPrice;
          } else {
            const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
            const isUserBased = addonId?.Addonspaymentdata?.isuserbased;
            const userPrice = isYearly ? addonId?.Addonspaymentdata?.yearlyuserprice : addonId?.Addonspaymentdata?.monthlyuserprice;
            const basePrice = isYearly ? addonId?.Addonspaymentdata?.yearlyprice : addonId?.Addonspaymentdata?.monthlyprice;
            if (isUserBased && total_user > addonId?.Addonspaymentdata?.numofuser) {
              price = basePrice + ((total_user - addonId?.Addonspaymentdata?.numofuser) * userPrice);
            } else {
              price = basePrice;
            }
            addonDiscountPrice = isYearly ? validAddon?.yearlyDiscountedPrice : validAddon?.monthlyDiscountedPrice;
          }
          return {
            addonId: addonId._id,
            addonDiscountPrice: applycoupondata?.discountType === "Fix custom discount" ? addonDiscountPrice :
              (applycoupondata?.discountType === "Fix discount price" && validAddon) ? applycoupondata?.discountAmount :
                (applycoupondata?.discountType === "Percentage discount price" && validAddon) ? (price * applycoupondata?.discountAmount) / 100 :
                  0,
            status: 'N/A',
            assignTo: '',
            price,
            addonType: addonId.paymentType,
            addonName: addonId.publicname
          };
        }),
        selectedCard, // Assuming selectedCard is available in this scope
        existingcard,
        saveAddress: false, // You may need to adjust this based on your logic
        selectedEditAddress: null, // You may need to adjust this based on your logic
        createOrderData,
        shipping_method,
        couponData: couponData,
      };
      const { data } = await axios.post(
        `${url}/addon-purchase`,
        orderData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        let payment_method
        if (selectedCard) {
          payment_method = selectedCard.id
        } else {
          payment_method = createOrderData.paymentMethod
        }
        const confirmPayment = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method
        });
        if (confirmPayment.paymentIntent){
          setTimeout(() => {
            navigate("/billing")
          }, 1000);
        }
        else {
          setIsButtonClicked(false);
          setButtonTextPay("Pay and subscribe");
          popup.style.display = "flex";
          paymentFailure.style.display = "block";
          setTimeout(() => {
            paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
            popup.style.display = "none";
          }, 4000);
          return false;
        }
      } else {
        setIsButtonClicked(false);
        setButtonTextPay("Pay and subscribe");
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
          popup.style.display = "none";
        }, 4000);
        return false;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      setIsButtonClicked(false);
      setButtonTextPay("Pay and subscribe");
      ;
    }
  };
  const AddUserpurchaseOrder = async (data, existingcard) => {
    const billAddress = {
      first_name: fname,
      last_name: lname,
      company_name: companyName,
      line1: billingAddress.Bstreet1,
      line2: billingAddress.Bstreet2,
      city: billingAddress.Bcity,
      state: billingAddress.Bstate,
      country: billingAddress.Bcountry,
      postal_code: billingAddress.BpostalCode,
    };
    let shipAddress;
    if (same) {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    } else {
      shipAddress = {
        first_name: fname,
        last_name: lname,
        company_name: companyName,
        line1: shippingAddress.Sstreet1,
        line2: shippingAddress.Sstreet2,
        city: shippingAddress.Scity,
        state: shippingAddress.Sstate,
        country: shippingAddress.Scountry,
        postal_code: shippingAddress.SpostalCode,
      };
    }
    const createOrderData = data;
    try {
      const totalAmount = updatedYearlyPrice; // Assuming updatedYearlyPrice is available in this scope
      const billingdata = billAddress;
      const shippingData = shipAddress;
      const shipping_method = {
        type: selectedOption,
        price:
          selectedOption === "free"
            ? 0
            : selectedOption === "2-4"
              ? 20
              : selectedOption === "overnight"
                ? 60
                : 0,
      };
      const orderData = {
        userId: user?._id, // Set your user ID or obtain it from the data object as needed
        first_name: fname,
        last_name: lname,
        email,
        contact,
        plandata: { type: userInfo?.subscription_details?.billing_cycle, planName: userInfo?.subscription_details?.plan },
        totalAmount: totalAmount,
        ammount: userInfo?.subscription_details?.total_amount,
        subscriptionId: userInfo?.subscription_details?.subscription_id,
        tax: taxPercentage, // Update with the correct tax information if needed
        billingAddress: billingdata,
        customerID: userInfo?.subscription_details?.customer_id,
        shippingAddress: shippingData,
        addusers: { addusercount: adduserCount, price: totalAmount, plan: userInfo?.subscription_details?.plan, billing_cycle: userInfo?.subscription_details?.billing_cycle },
        selectedCard, // Assuming selectedCard is available in this scope
        paymentToken: selectedCard.id,
        existingcard,
        saveAddress: false, // You may need to adjust this based on your logic
        selectedEditAddress: null, // You may need to adjust this based on your logic
        createOrderData,
        shipping_method
      };
      const { data } = await axios.post(
        `${url}/user-purchase`,
        orderData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        let payment_method
        if (selectedCard) {
          payment_method = selectedCard.id
        } else {
          payment_method = createOrderData.paymentMethod
        }
        setTimeout(() => {
          navigate("/billing")
        }, 1000);
      } else {
        setIsButtonClicked(false);
        setButtonTextPay("Pay and subscribe");
        popup.style.display = "flex";
        paymentFailure.style.display = "block";
        setTimeout(() => {
          paymentFailure.style.display = "none"; // Hide the failure message after 5 seconds
          popup.style.display = "none";
        }, 4000);
        return false;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      setIsButtonClicked(false);
      setButtonTextPay("Pay and subscribe");
      ;
    }
  };
  // this is code for upgrade plan
  useEffect(() => {
    if (userInfo) {
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
      const currentPrice = userInfo?.subscription_details?.total_amount || 0;
      const remainingDays = daysInMonth - currentDay;
      const costPerDay = currentPrice / daysInMonth;
      const proratedPrice = parseFloat((costPerDay * remainingDays).toFixed(2));
      setProratedPrice(proratedPrice);
    }
  }, [userInfo, dispatch])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header2 />
          <div className="heading-container">
            <h2>Checkout</h2>
          </div>
          <div className="signup-container">
            {/* --------------------------------- accordion-1---------------------------------- */}
            <form className="signup-information">
              <div
                className={`accordion ${isActive.a1 ? "expand" : "collapsed"}`}
                id="account-information"
              >
                <div
                  className={`  ${isActive.a1 ? "accordion_header-active" : "accordion-header"
                    }`}
                  onClick={() => showAccordion(1)}
                >
                  <h2>Account Information</h2>
                  <a
                    style={{
                      color: `${Accordion1
                        ? isActive.a1 === true
                          ? "#fff"
                          : "#48AF25"
                        : ""
                        }`,
                    }}
                    href="#account-informtion"
                  >
                    Step-1
                  </a>
                </div>
                {isActive.a1 && (
                  <div className="accordion-content">
                    <div className="names">
                      <div className="name">
                        <label htmlFor="fname">First name*</label>
                        <input
                          type="text"
                          name="fname"
                          value={fname}
                          className={
                            formErrors.fname === null ? "white-text" : ""
                          }
                          onChange={handleInputChangeAccordion1}
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(/\d/g, "");
                          }}
                        />
                        {formErrors.fname && (
                          <span
                            className={`error ${formErrors.fname === "-" ? "whiteTxt" : ""
                              }`}
                          >
                            {formErrors.fname}
                          </span>
                        )}
                      </div>
                      <div className="name">
                        <label htmlFor="lname">Last name*</label>
                        <input
                          type="text"
                          name="lname"
                          value={lname}
                          onChange={handleInputChangeAccordion1}
                          onInput={(event) => {
                            event.target.value = event.target.value.replace(/\d/g, "");
                          }}
                        />
                        {formErrors.lname && (
                          <span
                            className={`error ${formErrors.lname === "-" ? "whiteTxt" : ""
                              }`}
                          >
                            {formErrors.lname}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="inputs">
                      <label htmlFor="email">Email*</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChangeAccordion1}
                      />
                      {formErrors.email && (
                        <div className="error">{formErrors.email}</div>
                      )}
                    </div>
                    <div className="inputs">
                      <label htmlFor="pnumber">
                        Cell phone number (we’ll send you order update
                        notification)*
                      </label>
                      <input
                        type="text"
                        name="pnumber"
                        value={contact}
                        onChange={numchange}
                        inputMode="numeric"
                        pattern="[0-9]{7,15}"
                      />
                      {formErrors.contact && (
                        <div className="error">{formErrors.contact}</div>
                      )}
                    </div>
                    <div className="inputs">
                      <label htmlFor="cname">Company name</label>
                      <input
                        type="text"
                        name="cname"
                        value={companyName}
                        onChange={handleInputChangeAccordion1}
                      />
                      {formErrors.company && (
                        <div className="error">{formErrors.company}</div>
                      )}
                    </div>
                    <button
                      className="accordion-btn"
                      type="button"
                      onClick={() => {
                        nextAccordion(1);
                        navigate("#billing-address");
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
              {/* --------------------------------- accordion-2---------------------------------- */}
              <div
                className={`accordion ${isActive.a2 ? "expand" : "collapsed"}`}
                id="billing-address"
              >
                <div
                  className={`  ${isActive.a2 ? "accordion_header-active" : "accordion-header"
                    }`}
                  onClick={() => showAccordion(2)}
                >
                  <h2>Billing Address</h2>
                  <a
                    style={{
                      color: `${Accordion2
                        ? isActive.a2 === true
                          ? "#fff"
                          : "#48AF25"
                        : ""
                        }`,
                    }}
                    href="#billing-address"
                  >
                    Step-2
                  </a>
                </div>
                {isActive.a2 ? (
                  <div className="accordion-content">
                    <label htmlFor="Bcountry">Country*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn  BcountryInput mb-3 form-select"
                      placeholder="Select"
                      name="Bcountry"
                      options={Country.getAllCountries().map((item) => ({
                        value: item.isoCode,
                        label: item.name,
                      }))}
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    />
                    {formErrors.billingAddress?.Bcountry && (
                      <div className="error">
                        {formErrors.billingAddress.Bcountry}
                      </div>
                    )}
                    <div className="inputs">
                      <label htmlFor="Bstreet1">Address*</label>
                      <input
                        type="text"
                        name="Bstreet1"
                        value={billingAddress.Bstreet1 ?? ""}
                        onChange={handleBillingAddressChange}
                      />
                      {formErrors.billingAddress?.Bstreet1 && (
                        <div className="error">
                          {formErrors.billingAddress.Bstreet1}
                        </div>
                      )}
                    </div>
                    <div className="inputs">
                      <label htmlFor="Bstreet2">Apt, suite, etc</label>
                      <input
                        type="text"
                        name="Bstreet2"
                        value={billingAddress.Bstreet2}
                        onChange={handleBillingAddressChange}
                      />
                    </div>
                    <div className="inputs">
                      <label htmlFor="Bcity">City*</label>
                      <input
                        type="text"
                        name="Bcity"
                        value={billingAddress.Bcity}
                        onChange={handleBillingAddressChange}
                      />
                      {formErrors.billingAddress?.Bcity && (
                        <div className="error">
                          {formErrors.billingAddress.Bcity}
                        </div>
                      )}
                    </div>
                    <label htmlFor="Bstate">State*</label>
                    <Select
                      styles={{
                        control: () => ({
                          margin: 0,
                          padding: 0,
                          border: "none",
                          boxShadow: "none",
                        }),
                        input: (provided) => ({
                          ...provided,
                          margin: 0,
                          padding: 0,
                        }),
                        indicatorsContainer: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "97%",
                        }),
                      }}
                      className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                      placeholder="Select"
                      name="Bstate"
                      options={stateOptions}
                      value={selectedState}
                      menuPlacement="top"
                      onChange={handleStateChange}
                    />
                    {formErrors.billingAddress?.Bstate && (
                      <div className="error">
                        {formErrors.billingAddress.Bstate}
                      </div>
                    )}
                    <div className="inputs">
                      <label htmlFor="pcode">Postal code*</label>
                      <input
                        type="text"
                        name="BpostalCode"
                        value={billingAddress.BpostalCode}
                        onChange={handleBillingAddressChange}
                      />
                      {formErrors.billingAddress?.BpostalCode && (
                        <div className="error">
                          {formErrors.billingAddress.BpostalCode}
                        </div>
                      )}
                    </div>
                    {PlanName === "Free" ? (<button
                      type="submit"
                      className="accordion-btn"
                      disabled={isButtonClicked || !allFieldsFilledFree}
                      onClick={handleSubmiteFreeAccount}
                    >
                      {buttonText}
                    </button>) :
                      (<button
                        className="accordion-btn"
                        type="button"
                        onClick={() => {
                          handleBillingContinue();
                          handleCalculateRatesAndContinue();
                          if (shippingAddress?.Scountry !== 'US') {
                            setSelectedOption("2-4");
                          }
                        }}
                      >
                        Continue
                      </button>)}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* --------------------------------- accordion-3---------------------------------- */}
              {PlanName !== "Free" && adduserCount === 0 && !selectedAddons?.length > 0 && (<div
                className={`accordion ${isActive.a3 ? "expand" : "collapsed"}`}
                id="shipping"
              >
                <div
                  className={`  ${isActive.a3 ? "accordion_header-active" : "accordion-header"
                    }`}
                  onClick={() => showAccordion(3)}
                >
                  <h2>Shipping options</h2>
                  {formErrors.shippingAddress?.Scountry ||
                    formErrors.shippingAddress?.Sstreet1 ||
                    formErrors.shippingAddress?.Scity ||
                    formErrors.shippingAddress?.Sstate ||
                    formErrors.shippingAddress?.SpostalCode ? (
                    <a href="#shipping">Step-3</a>
                  ) : (
                    <a
                      style={{
                        color: `${Accordion3
                          ? isActive.a3 === true
                            ? "#fff"
                            : "#48AF25"
                          : ""
                          }`,
                      }}
                      href="#shipping"
                    >
                      Step-3
                    </a>
                  )}
                </div>
                {isActive.a3 ? (
                  <div className="accordion-content">
                    {same && userInfo?.subscription_details?.paln !== "" && (shippingAddresses && shippingAddresses[0]?.shipping_address?.map((e, index) => (
                      <div className="shipp-main-container">
                        <input key={index} className="shipping-select-checkbox" type="checkbox" name="" id="" checked={selectedShippingAddress === e}
                          onChange={() => handleCheckboxChangeNew(e)} />
                        <div className="fetchAddress-text-section">
                          <div className="shipping-name-checkout"> <span className="fetchShippName">{e.address_name}</span> <span className="fetchShippAddress">{e.line1}&nbsp;{e.line2}...</span></div>
                          <div className="fetchAddress-edit-text" onClick={() => { handleEditAddress(e); handleCheckboxChangeNew(e) }}>Edit</div>
                        </div>
                      </div>
                    )))}
                    <div>
                      <div className="same-address">
                        <input
                          type="checkbox"
                          name="sameaddress"
                          checked={same} // Bind the value of `same` to the checkbox
                          onChange={handleCheckboxChange} // Handle checkbox change
                        />
                        <p>Ship to a different address?</p>
                      </div>
                      {same && (<div>
                        <label htmlFor="country">Country*</label>
                        {!same ? (<Select
                          styles={{
                            control: () => ({
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0,
                              padding: 0,
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "97%",
                            }),
                          }}
                          className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                          placeholder="Select"
                          name="Scountry"
                          options={Country.getAllCountries().map((item) => ({
                            value: item.isoCode,
                            label: item.name,
                          }))}
                          value={selectedShippingAddress ? selectedCountryShipingAll : selectedCountry}
                          onChange={selectedShippingAddress ? handleCountryChangeShippingAll : handleCountryChangeShipping}
                        />) : (<Select
                          styles={{
                            control: () => ({
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0,
                              padding: 0,
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "97%",
                            }),
                          }}
                          className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                          placeholder="Select"
                          name="Scountry"
                          options={Country.getAllCountries().map((item) => ({
                            value: item.isoCode,
                            label: item.name,
                          }))}
                          value={selectedCountryShiping}
                          onChange={handleCountryChangeShipping}
                        />)}
                        {formErrors.shippingAddress?.Scountry && (
                          <div className="error">
                            {formErrors.shippingAddress.Scountry}
                          </div>
                        )}
                        <div className="inputs">
                          <label htmlFor="Sstreet1">Address*</label>
                          <input
                            type="text"
                            name="Sstreet1"
                            value={shippingAddress.Sstreet1}
                            onChange={handleShippingAddressChange}
                          />
                          {formErrors.shippingAddress?.Sstreet1 && (
                            <div className="error">
                              {formErrors.shippingAddress.Sstreet1}
                            </div>
                          )}
                        </div>
                        <div className="inputs">
                          <label htmlFor="Sstreet2">Apt, suite, etc</label>
                          <input
                            type="text"
                            name="Sstreet2"
                            value={shippingAddress.Sstreet2}
                            onChange={handleShippingAddressChange}
                          />
                        </div>
                        <div className="inputs">
                          <label htmlFor="Scity">City*</label>
                          <input
                            type="text"
                            name="Scity"
                            value={shippingAddress.Scity}
                            onChange={handleShippingAddressChange}
                            onBlur={(e) => handleCalculateRatesAndContinue(e.target.value)}
                          />
                          {formErrors.shippingAddress?.Scity && (
                            <div className="error">
                              {formErrors.shippingAddress.Scity}
                            </div>
                          )}
                        </div>
                        <label htmlFor="Scountry">State*</label>
                        {!same ? (<Select
                          styles={{
                            control: () => ({
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0,
                              padding: 0,
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "97%",
                            }),
                          }}
                          className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                          placeholder="Select"
                          name="Sstate"
                          options={selectedShippingAddress ? stateOptionsShippingAll : stateOptions}
                          value={selectedShippingAddress ? selectedStateShippingAll : selectedState}
                          menuPlacement="top"
                          onChange={selectedShippingAddress ? handleStateChangeShippingAll : handleStateChangeShipping}
                        />) : (<Select
                          styles={{
                            control: () => ({
                              margin: 0,
                              padding: 0,
                              border: "none",
                              boxShadow: "none",
                            }),
                            input: (provided) => ({
                              ...provided,
                              margin: 0,
                              padding: 0,
                            }),
                            indicatorsContainer: () => ({
                              display: "none",
                            }),
                            option: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "97%",
                            }),
                          }}
                          className="onetap_conn_general_information_input_syn BcountryInput mb-3 form-select"
                          placeholder="Select"
                          name="Sstate"
                          options={stateOptionsShipping}
                          value={selectedStateShipping}
                          menuPlacement="top"
                          onChange={handleStateChangeShipping}
                        />)}
                        {formErrors.shippingAddress?.Sstate && (
                          <div className="error">
                            {formErrors.shippingAddress.Sstate}
                          </div>
                        )}
                        <div className="inputs">
                          <label htmlFor="pcode">Postal code*</label>
                          <input
                            type="text"
                            name="SpostalCode"
                            value={shippingAddress.SpostalCode}
                            onChange={handleShippingAddressChange}
                            onBlur={(e) => handleCalculateRatesAndContinue(e.target.value)}
                          />
                          {formErrors.shippingAddress?.SpostalCode && (
                            <div className="error">
                              {formErrors.shippingAddress.SpostalCode}
                            </div>
                          )}
                        </div>
                        {selectedShippingAddress === null && (<div className="save-address-checkbox-section">
                          <div className="save-address-checkbox">
                            <input style={{ margin: "10px 10px 10px 0" }} className="shipping-select-checkbox" checked={saveAddress} onChange={(e) => setSaveAddress(e.target.checked)} type="checkbox" name="" id="" />
                          </div>
                          <div style={{ fontWeight: "600" }} className="save-address-text">
                            Save address?
                          </div>
                        </div>)}
                        {selectedEditAddress && (<div className="save-address-checkbox-section">
                          <div className="save-address-checkbox">
                            <input className="shipping-select-checkbox" checked={saveAddress} onChange={(e) => setSaveAddress(e.target.checked)} type="checkbox" name="" id="" />
                          </div>
                          <div className="save-address-text">
                            Edit & Save address?
                          </div>
                        </div>)}
                      </div>)}
                    </div>
                    <div class="row" className="shipping-method-text_main">
                      <div class="col-8" className="shipping-method-text">Shipping method</div>
                      {/* {plandata?.smart_accessories?.length === 0 ? null : (
                        <div class="col-4" style={{ textAlign: 'end', cursor: 'pointer', color: '#e65925' }}
                          disabled={!isAccordion3Filled || !isAccordion4Filled}
                          onClick={() => (plandata?.smart_accessories?.length !== 0 ? handleCalculateRatesAndContinue() : null)}>{isLoading ? 'Please wait...' : 'More shipping options?'}</div>
                      )} */}
                    </div>
                    <div className="options">
                      <div className="option">
                        <input
                          type="checkbox"
                          checked={selectedOption === "free"}
                          disabled={plandata?.smart_accessories?.length !== 0 && shippingAddress?.Scountry !== 'US'}
                          onChange={() => handleShippingMethod("free")}
                          style={{ display: plandata?.smart_accessories?.length !== 0 && shippingAddress?.Scountry !== 'US' ? 'none' : 'block' }}
                        />
                        <p style={{ display: plandata?.smart_accessories?.length !== 0 && shippingAddress?.Scountry !== 'US' ? 'none' : 'block' }}>Free Shipping</p>
                      </div>
                      {((rates && rates.length > 0 && shippingAddress?.Scountry !== 'US') || (selectedAddons?.length > 0)) && (
                        <div className="option">
                          <input
                            type="checkbox"
                            checked={selectedOption === "2-4"}
                            onChange={() => handleShippingMethod("2-4")}
                          />
                          {(plandata?.planType && shippingAddress?.Scountry !== 'US' && rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')) ? (
                            <p>{rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}</p>
                          ) : (
                            <p>{rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}</p>
                          )}
                        </div>
                      )}
                      {(((rates && rates.length > 0) && (plandata?.smart_accessories?.length > 0 || !plandata) && (shippingAddress?.Scountry === 'US' || shippingAddress?.Scountry === 'CA')) || (selectedAddons?.length > 0)) && (
                        <div className="option">
                          <input
                            type="checkbox"
                            checked={selectedOption === "overnight"}
                            onChange={() => handleShippingMethod("overnight")}
                          />
                          {rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international') ? (
                            <p>{rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.serviceName}: ${rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.shipmentCost}</p>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="mt-5">
                      <h5 className='border-bottom pb-2'>Order Notes <span>(optional)</span> </h5>
                      <textarea className="w-100 border-0 p-2 mt-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='activitylog' value={userShippingOrderNote}
                        onChange={(e) => { SetUserShippingOrderNote(e.target.value) }} />
                    </div>
                    <div className='mb-3' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                      <span>Referred by (optional)</span>
                      <select
                        class="form-select py-2 p-2"
                        style={{ backgroundColor: "#f2f2f2" }}
                        name="referrer"
                        value={referrer}
                        onChange={(e) => setReferrer(e.target.value)}
                      >
                        <option selected>
                          Select
                        </option>
                        <option value="OTC connect form">
                          OTC connect form
                        </option>
                        <option value="Bing">Bing</option>
                        <option value="Blog post">Blog post</option>
                        <option value="Co-worker">Co-worker</option>
                        <option value="Friend and family">Friend and family</option>
                        <option value="Google">Google</option>
                        <option value="Direct mail">Direct mail</option>
                        <option value="Convention">Convention</option>
                        <option value="Tiktok">Tiktok</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Text Message">Text Message</option>
                        <option value="Direct call">Direct call</option>
                        <option value="Existing OneTapConnect user">
                          Existing OneTapConnect user
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    {(referrer === 'Existing OneTapConnect user' || referrer === 'Other') && (
                      <div className='mb-2'>
                        <p style={{ marginBottom: '0px' }}>Other</p>
                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="referrerName" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} onChange={(e) => setReferrerName(e.target.value)} value={referrerName} />
                      </div>
                    )}
                    <button
                      type="button"
                      className="accordion-btn"
                      onClick={() => { handleShippingContinue() }}
                    >
                      Continue
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>)}
              {/* --------------------------------- accordion-4---------------------------------- */}
              {!plandata && !selectedAddons?.length > 0 && cartAddedProducts ? null : PlanName !== "Free" && adduserCount === 0 && (<div
                className={`accordion ${isActive.a4 ? "expand" : "collapsed"}`}
                id="add-ones"
              >
                <div
                  className={`  ${isActive.a4 ? "accordion_header-active" : "accordion-header"
                    }`}
                  onClick={() => showAccordion(4)}
                >
                  <h2>Add-ons</h2>
                  <a
                    style={{
                      color: `${Accordion4
                        ? isActive.a4 === true
                          ? "#fff"
                          : "#48AF25"
                        : ""
                        }`,
                    }}
                    href="#add-ons"
                  >
                    {selectedAddons.length > 0 ? `Step-3` : `Step-4`}
                  </a>
                </div>
                {isActive.a4 && (
                  <div className="accordion-content">
                    <div className="options">
                      {selectedAddons && selectedAddons.length > 0
                        ? selectedAddons &&
                        selectedAddons.map((item, i) => {
                          let price;
                          let frequency;
                          if (item.paymentType === "onetimepayment") {
                            price = item?.Addonspaymentdata?.price;
                            frequency = "(One time)";
                          } else {
                            const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
                            const isUserBased = item?.Addonspaymentdata?.isuserbased;
                            const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
                            const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
                            if (isUserBased && total_user >= item?.Addonspaymentdata?.numofuser) {
                              price = basePrice + ((total_user - item?.Addonspaymentdata?.numofuser) * userPrice);
                            } else {
                              price = basePrice;
                            }
                            frequency = isYearly ? "/year" : "/month";
                          }
                          return (
                            <div key={i}>
                              <div className="option">
                                <input
                                  type="checkbox"
                                  checked={selectedAddOne.some(
                                    (selectedItem) => selectedItem._id === item._id
                                  ) || (i === 0 && selectedAddOne.length === 0)}
                                />
                                <p>
                                  {item.publicname} - ${price} {frequency}
                                </p>
                              </div>
                              <p className="message">
                                <p
                                  className="mb-0"
                                  dangerouslySetInnerHTML={{
                                    __html: `<style> p { margin: 0; } </style> ${item.shortDescription}`,
                                  }}
                                />
                                <Link
                                  to={`${frontendURL}/addons/${item.CustomPermalink}`}
                                  style={{ textDecoration: "none" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Learn more
                                </Link>
                              </p>
                            </div>
                          )
                        })
                        : filterAddons &&
                        filterAddons
                          .filter(
                            (item) =>
                              item.visibility === "Public" && item.status === "Published"
                          )
                          .map((item, i) => {
                            let price;
                            let frequency;
                            if (item.paymentType === "onetimepayment") {
                              price = item?.Addonspaymentdata?.price;
                              frequency = "(One time)";
                            } else {
                              const isYearly = cycle === "yearly";
                              const isUserBased = item?.Addonspaymentdata?.isuserbased;
                              const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
                              const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
                              const totalplan_users = userCount
                              if (isUserBased && totalplan_users >= item?.Addonspaymentdata?.numofuser) {
                                price = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
                              } else {
                                price = basePrice;
                              }
                              frequency = isYearly ? "/year" : "/month";
                            }
                            return (
                              <div key={i}>
                                <div className="option">
                                  <input
                                    type="checkbox"
                                    checked={selectedAddOne.some(
                                      (selectedItem) => selectedItem._id === item._id
                                    )}
                                    onChange={(e) => handleAddoneCheckBox(e, item)}
                                  />
                                  <p>
                                    {item.publicname} - ${price} {frequency}
                                  </p>
                                </div>
                                <p className="message">
                                  <p
                                    className="mb-0"
                                    dangerouslySetInnerHTML={{
                                      __html: `<style> p { margin: 0; } </style> ${item.shortDescription}`,
                                    }}
                                  />
                                  <Link
                                    to={`${frontendURL}/addons/${item.CustomPermalink}`}
                                    style={{ textDecoration: "none" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn more
                                  </Link>
                                </p>
                              </div>
                            )
                          })}
                    </div>
                    <button
                      className="accordion-btn"
                      onClick={() => {
                        nextAccordion(4);
                        navigate("#payment");
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>)}
              {/* --------------------------------- accordion-5---------------------------------- */}
              {PlanName !== "Free" && (<div
                className={`accordion ${isActive.a5 ? "expand" : "collapsed"}`}
                id="payment"
              >
                <div
                  className={`  ${isActive.a5 ? "accordion_header-active" : "accordion-header"
                    }`}
                  onClick={() => showAccordion(5)}
                >
                  <h2>Payment</h2>
                  <a
                    style={{
                      color: `${Accordion5
                        ? isActive.a5 === true
                          ? "#fff"
                          : "#48AF25"
                        : ""
                        }`,
                    }}
                    href="#payment"
                  >
                    {adduserCount !== 0 ? `Step-3` : selectedAddons.length > 0 ? `Step-4` : !plandata && !selectedAddons?.length > 0 && cartAddedProducts ? `Step-4` : `Step-5`}
                  </a>
                </div>
                {isActive.a5 ? (
                  <div className="accordion-content">
                    <div className="all-cards-container">
                      {savedCards?.map((e, index) => (<div className="comman-card-checkout">
                        <input key={index} className="cards-select-checkbox" type="checkbox" name="" id={`card-${index}`}
                          onChange={() => handleCardSelection(index, e)} checked={
                            selectedCardIndex === index
                          } />
                        <div className="img-and-text-container">
                          <div className="img-cardname">
                            <div className="card-img-checkout"><img src={VisaCard} alt="" /></div>
                            <div className="card-name-num-detail">
                              <span className="card-name">{e.card.brand?.charAt(0).toUpperCase() +
                                e.card.brand?.slice(1)}</span> <span className="card-name">ending in</span>
                              <span className="card-number card-name">**{e.card.last4}</span>
                            </div>
                          </div>
                          <div className="card-expiry-primary">
                            <span className="card-expiry-date">{e.card.exp_month}/{e.card.exp_year?.toString()?.slice(-2)
                            }</span>
                            <span className={`card-primary ${e.isPrimary === true ? ' no-padding' : ''}`}>{e.isPrimary === true ? `primary` : ``}</span>
                          </div>
                        </div>
                      </div>))}
                    </div>
                    {savedCards?.length > 0 && (<div className="add-new-card-text-and-icon" onClick={handleShowPayment}>
                      <img src={plusIcon} alt="" />
                      <span className="add-new-card-text">Add new payment method </span>
                    </div>)}
                    <div>
                      {(showPaymentSection || savedCards?.length < 1) && (<div>
                        <div className="inputs">
                          <label htmlFor="card-name">Name on Card*</label>
                          <input
                            className="card-inputs"
                            type="text"
                            id="card-name"
                            value={cardInfo.cardName}
                            onChange={handleCardNameChange}
                          />
                          {paymentErrors.cardName && (
                            <div className="error">{paymentErrors.cardName}</div>
                          )}
                        </div>
                        <div className="inputs">
                          <label htmlFor="card-number">Card number*</label>
                          <CardNumberElement
                            className="card-inputs"
                            value={1}
                            onChange={(event) =>
                              setCardInfo({
                                ...cardInfo,
                                cardNumber: event.error
                                  ? null
                                  : event.complete
                                    ? event.empty : null
                                // : "",
                              })
                            }
                          />
                          {paymentErrors.cardNumber && (
                            <div className="error">{paymentErrors.cardNumber}</div>
                          )}
                        </div>
                        <div className="names">
                          <div className="name">
                            <label htmlFor="expiry">Expiration date*</label>
                            <CardExpiryElement
                              className="card-expiry-sign-up"
                              onChange={(event) =>
                                setCardInfo({
                                  ...cardInfo,
                                  expiryDate: event.error
                                    ? null
                                    : event.complete
                                      ? event.empty : null
                                  // : "",
                                })
                              }
                              value={
                                selectedCard
                                  ? `${selectedCard.cardExpiryMonth}/${selectedCard.cardExpiryYear?.toString()?.slice(-2)}`
                                  : cardInfo.expiryDate
                              }
                            />
                            {paymentErrors.expiryDate && (
                              <div className="error">
                                {paymentErrors.expiryDate}
                              </div>
                            )}
                          </div>
                          <div className="name">
                            <label htmlFor="csv">CSV code*</label>
                            <CardCvcElement
                              className="card-expiry-sign-up"
                              onChange={(event) =>
                                setCardInfo({
                                  ...cardInfo,
                                  csv: event.error
                                    ? null
                                    : event.complete
                                      ? event.empty : null
                                })
                              }
                            />
                            {paymentErrors.csv && (
                              <div className="error">{paymentErrors.csv}</div>
                            )}
                          </div>
                        </div>
                      </div>)}
                      <div className="terms-conditions">
                        <input
                          type="checkbox"
                          checked={policyIsChecked}
                          onChange={() => {
                            setPolicyIsChecked(!policyIsChecked);
                          }}
                        />
                        <span>
                          By subscribing, you agree to our{" "}
                          <Link
                            to="/terms-of-use" target="_blank"
                            style={{ color: "#2572E6", textDecoration: "none" }}
                          >
                            terms and services,
                          </Link>
                          &nbsp;
                          <Link
                            to="https://onetapconnect.com/terms-of-use/#refundpolicy" target="_blank"
                            style={{ color: "#2572E6", textDecoration: "none" }}
                          >
                            refund policy
                          </Link>{" "}
                          and{" "}
                          <Link
                            to="/privacy-policy" target="_blank"
                            style={{ color: "#2572E6", textDecoration: "none" }}
                          >
                            privacy policy
                          </Link>
                        </span>
                      </div>
                      {selectedCard && savedCards?.length > 0 && (<button
                        className="accordion-btn"
                        disabled={!policyIsChecked || !allFieldsFilled || isButtonClicked || !selectedCard}
                        type="submit"
                        onClick={submitHandler}
                      >
                        {buttonTextPay}
                      </button>)}
                      {!selectedCard && savedCards?.length > 0 && (<button
                        className="accordion-btn"
                        disabled={!policyIsChecked || !allFieldsFilled || isButtonClicked || !showPaymentSection}
                        type="submit"
                        onClick={submitHandler}
                      >
                        {buttonTextPay}
                      </button>)}
                      {savedCards && savedCards.length < 1 && (<button
                        className="accordion-btn"
                        disabled={!policyIsChecked || !allFieldsFilled || isButtonClicked}
                        type="submit"
                        onClick={submitHandler}
                      >
                        {buttonTextPay}
                      </button>)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>)}
            </form>
            <div className="billing-information">
              <div className="billing-inner-div">
                {plandata &&
                  <>
                    <h5>Selected plan:</h5>
                    <div className="header-link">
                      <h2>{plandata?.planType}</h2>
                      <Link to="/">Change plan</Link>
                    </div>
                    <span className="costover-view">Cost overview</span>
                    <div>
                      <div className="sign-up-plan-card">
                        <span className="subscription-type">
                          {plandata?.planType === "Free" ? 'Free' : cycle?.charAt(0).toUpperCase() +
                            cycle?.slice(1)}{" "}
                          subscription:
                        </span>
                        <span className="price">
                          $
                          {plandata?.planType === "Free"
                            ? 0
                            : cycle === "monthly"
                              ? subTotal
                              : cycle === "yearly"
                                ? Math.floor(
                                  subTotal
                                )
                                : null}
                          {plandata?.planType === "Free" ? '' : cycle === "monthly" && "/month"}
                          {plandata?.planType === "Free" ? '' : cycle === "yearly" && "/year"}
                        </span>
                        <span className="yearly-price">
                          {plandata?.planType === "Free" && ""}
                          {plandata?.planType === "Enterprise" && "No user limits!"}
                          {(plandata?.planType === "Team" ||
                            plandata?.planType === "Professional") &&
                            (cycle === "monthly"
                              ? `Billed at $${subTotal * 12}`
                              : "")}
                          {plandata?.planType === "Free" ? '' : cycle === "monthly" && "/year"}
                        </span>
                        {plandata?.planType === "Team" && (
                          <>
                            <span className="user-count"></span>
                            <p style={{ marginBottom: "0rem", color: '#000000' }}>
                              {cycle === 'monthly' ? (`Includes ${plandata?.users} users - $${plandata?.monthlyPrice_perUser} per additional user`) : (`Includes ${plandata?.users} users - $${plandata?.yearlyPrice_perUser * 12} per additional user`)}
                            </p>
                            <div id="main-div">
                              <div id="left-div">
                                <p>How many users do you need?</p>
                              </div>
                              <div style={{ top: "0px" }} id="right-div">
                                <div
                                  class="button"
                                  id="minus-btn"
                                  onClick={handleDecrement}
                                  style={{ userSelect: 'none' }}
                                >
                                  -
                                </div>
                                <div
                                  onInput={handleInputChange}
                                  style={{ outline: "none" }}
                                  id="center-number"
                                >
                                  {userCount}
                                </div>
                                <div
                                  class="button"
                                  id="plus-btn"
                                  onClick={handleIncrement}
                                  style={{ userSelect: 'none' }}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                }
                <div className="add-ones-section">
                  {(selectedAddOne.length > 0 && plandata && PlanName !== "Free") && <h2 className="add-one-heading">Selected add-on:</h2>}
                  {!plandata && adduserCount !== null && adduserCount !== 0 && (
                    <div className="add-one-item">
                      <input type="checkbox" checked={true} readOnly />
                      <p className="w-100">
                        Additional User (
                        <span >${adduserPrice}</span> x {adduserCount})
                        <span style={{ float: 'right' }}>${adduserCount * adduserPrice}</span>
                      </p>
                    </div>
                  )}
                  {!selectedAddons?.length > 0 && selectedAddOne &&
                    selectedAddOne?.map((item, i) => {
                      let price;
                      let frequency;
                      if (item.paymentType === "onetimepayment") {
                        price = item?.Addonspaymentdata?.price;
                        frequency = "(One time)";
                      } else {
                        const isYearly = cycle === "yearly";
                        const isUserBased = item?.Addonspaymentdata?.isuserbased;
                        const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
                        const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
                        const totalplan_users = userCount
                        if (isUserBased && totalplan_users >= item?.Addonspaymentdata?.numofuser) {
                          price = basePrice + ((totalplan_users - item?.Addonspaymentdata?.numofuser) * userPrice);
                        } else {
                          price = basePrice;
                        }
                        frequency = isYearly ? "/year" : "/month";
                      }
                      return (
                        <div className="add-one-item" key={i}>
                          <input type="checkbox" checked={true} readOnly />
                          <p>
                            {item.publicname} - ${price} {frequency}
                          </p>
                        </div>
                      );
                    })}
                  {!plandata && selectedAddOne &&
                    selectedAddOne?.map((item, i) => {
                      let price;
                      let frequency;
                      if (item.paymentType === "onetimepayment") {
                        price = item?.Addonspaymentdata?.price;
                        frequency = "(One time)";
                      } else {
                        const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
                        const isUserBased = item?.Addonspaymentdata?.isuserbased;
                        const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
                        const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
                        if (isUserBased && total_user >= item?.Addonspaymentdata?.numofuser) {
                          price = basePrice + ((total_user - item?.Addonspaymentdata?.numofuser) * userPrice);
                        } else {
                          price = basePrice;
                        }
                        frequency = isYearly ? "/year" : "/month";
                      }
                      return (
                        <div className="add-one-item" key={i}>
                          <input type="checkbox" checked={true} readOnly />
                          <p>
                            {item.publicname} - ${price} {frequency}
                          </p>
                        </div>
                      );
                    })}
                </div>
                {(selectedAddOne.length > 0) && <span className="divider"></span>}
                {!plandata && !selectedAddons?.length > 0 && cartAddedProducts && adduserCount === 0 &&
                  <div className="checkout-product-section">
                    <div className="add-one-heading">Smart Accessories:</div>
                    <div className="onetap_connect_user_product_headings_with_accessary_synr">
                      <table className="onetap_connect_product_headings_synr">
                        {cartAddedProducts?.map((val, i) => {
                          const variation = getVariationById(val.product.variations, val.variation);
                          return (
                            <div className="d-flex align-items-center" key={i}>
                              <tr className="onetap_connect_product_row_with_para_synr">
                                <td className="onetap_connect_product_card_synr col">
                                  {val?.variation ?
                                    <img className="col-2" src={`${url}/product/img/${variation?.image[0]?.url}`} />
                                    :
                                    <img className="col-2" src={`${url}/product/img/${val.product?.image[0]?.url}`} />
                                  }
                                  <div className="onetap_connect_product_para_synr d-flex align-items-center">
                                    {val?.variation ? variation?.Type : val.product.name}
                                  </div>
                                </td>
                                <div className="onetap_connect_access_association_product_synr_main">
                                  <td className="onetap_connect_access_association_product_synr">
                                    <input type="number" name="product_qty" value={productQuantities[val._id] || 1} onChange={(e) => handleUpdateQuantity(val._id, parseInt(e.target.value))} min={1} defaultValue={1} />
                                  </td>
                                  <td className="onetap_connect_access_association_product_synr">
                                    {val?.variation ? (
                                      <div>{variation?.saleprice ? (variation?.saleprice * productQuantities[val._id]).toFixed(2) : (variation?.price * productQuantities[val._id]).toFixed(2)}</div>
                                    ) : (
                                      <div>{val.product.saleprice ? <p>{(val.product.saleprice * productQuantities[val._id]).toFixed(2)}</p> : <p>{(val.product.price * productQuantities[val._id]).toFixed(2)}</p>}</div>
                                    )}
                                  </td>
                                </div>
                                {cartAddedProducts?.length > 1 && (<svg onClick={() => { handleRemoveproduct(val._id) }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" style={{ marginTop: "7px", cursor: "pointer" }}>
                                  <path d="M16.692 6.84375L12.449 11.0858L8.20697 6.84375L6.79297 8.25775L11.035 12.4998L6.79297 16.7418L8.20697 18.1558L12.449 13.9137L16.692 18.1558L18.106 16.7418L13.864 12.4998L18.106 8.25775L16.692 6.84375Z" fill="#E65925" />
                                </svg>)}
                              </tr>
                            </div>
                          )
                        })}
                      </table>
                    </div>
                  </div>
                }
                {(planAddons?.length > 0 && plandata) && <div className="add-ones-section pb-2" >
                  <h2 className="add-one-heading p-0 m-0">Free Add-on and Smart Accessories:</h2>
                </div>}
                {((planAddons?.length > 0 || plandata?.smart_accessories.length > 0) && plandata) && <span className="divider"></span>}
                {planAddons?.length > 0 && plandata && <div className="add-ones-section">
                  {(planAddons?.length > 0 && plandata) && <h2 className="add-one-heading">Add-on:</h2>}
                  {planAddons &&
                    planAddons?.map((item, i) => {
                      return (
                        <div className="add-one-item" key={i}>
                          <input type="checkbox" checked={true} readOnly />
                          <p className="w-100">
                            {item.publicname}  <span style={{ float: "right" }}>$0.00</span>
                          </p>
                        </div>
                      );
                    })}
                </div>}
                {plandata?.smart_accessories.length > 0 &&
                  <div className="checkout-product-section  m-0">
                    <div className="add-one-heading mb-2">Smart Accessories:</div>
                    <div className="onetap_connect_user_product_headings_with_accessary_synr">
                      <table className="onetap_connect_product_headings_synr">
                        {matchedProducts?.map((val, i) => {
                          const notvariation = val.hasVariations === false
                          return (
                            <div className="d-flex align-items-center" key={i}>
                              <tr className="onetap_connect_product_row_with_para_synr">
                                <td className="onetap_connect_product_card_synr col">
                                  {notvariation ?
                                    <img className="col-2" src={`${url}/product/img/${val.product?.image[0]?.url}`} /> : <img className="col-2" src={`${url}/product/img/${val?.image[0]?.url}`} />
                                  }
                                  <div className="onetap_connect_product_para_synr d-flex align-items-center">
                                    {notvariation ? val?.name : val?.Type}
                                  </div>
                                </td>
                                <div className="onetap_connect_access_association_product_synr_main">
                                  <td className="onetap_connect_access_association_product_synr">
                                    <div>$0.00</div>
                                  </td>
                                </div>
                              </tr>
                            </div>
                          )
                        })}
                      </table>
                    </div>
                  </div>
                }
                <div className="charges-section">
                  <div className="charges">
                    <p>Sub Total</p>
                    {(plandata || selectedAddons?.length > 0 || adduserCount !== 0) && <span>${subTotal ? subTotal + ".00" : "00.00"}</span>}
                    {(!plandata && !selectedAddons?.length > 0 && cartAddedProducts && adduserCount === 0) && <span>${calculateTotalPriceOfProduct().toFixed(2)}</span>}
                  </div>
                  {
                    isCouponApplied &&
                    <div className="charges">
                      <p>Discount</p>
                      {fetchedCoupon.discounValue > 0 &&
                        <span>
                          -${fetchedCoupon.discounValue}
                        </span>
                      }
                    </div>
                  }
                  {
                    setupFeeAdded &&
                    <div className="charges">
                      {userInfo?.isInitailUser === true && plandata?.planType === "Team" && (plandata?.monthly_fee || plandata?.yearly_fee) && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === 'Free') && <p>Initial Setup Fee (One Time)</p>}
                      {userInfo?.isInitailUser === true && plandata?.planType === "Profession" && (plandata?.monthly_fee || plandata?.yearly_fee) && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || userInfo?.subscription_details?.plan === 'Free') && <p>Initial Setup Fee (One Time)</p>}
                      {plandata && userInfo?.isInitailUser === true && plandata?.planType === "Team" && (plandata?.monthly_fee || plandata?.yearly_fee) && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === 'Free' || userInfo?.subscription_details?.plan === null) && <span>${updatedYearlyPrice ? (cycle === 'monthly' ? plandata?.monthly_fee + ".00" : plandata?.yearly_fee + ".00") : ("00.00")}</span>}
                      {plandata && userInfo?.isInitailUser === true && plandata?.planType === "Profession" && (plandata?.monthly_fee || plandata?.yearly_fee) && (userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === 'Free' || userInfo?.subscription_details?.plan === null) && <span>${updatedYearlyPrice ? (cycle === 'monthly' ? plandata?.monthly_fee + ".00" : plandata?.yearly_fee + ".00") : ("00.00")}</span>}
                    </div>
                  }
                  {plandata?.planType !== 'Free' && adduserCount === 0 && selectedAddons.length === 0 && <div className="charges">
                    <p>Shipping</p>
                    <span>
                      {selectedOption === "free" && "Free Shipping"}
                      {selectedOption === "2-4" && (plandata?.smart_accessories?.length === 0 || selectedAddons.length > 0 ? "$20.00" : `$${rates?.find(rate => rate.serviceCode === 'usps_first_class_mail' || rate.serviceCode === 'usps_first_class_mail_international')?.shipmentCost}`)}
                      {selectedOption === "overnight" && (plandata?.smart_accessories?.length === 0 || selectedAddons.length > 0 ? "$60.00" : `$${rates?.find(rate => rate.serviceCode === 'usps_priority_mail' || rate.serviceCode === 'usps_priority_mail_international')?.shipmentCost}`)}
                    </span>
                  </div>}
                  <div className="charges">
                    {plandata?.planType === "Free" ? '' : <p>Tax ({taxPercentage}%)</p>}
                    {plandata?.planType === "Free" ? '' : (plandata || selectedAddons?.length > 0) && <span>${(((updatedYearlyPrice - totalDiscountValue) * taxPercentage) / 100).toFixed(2)}</span>}
                    {plandata?.planType === "Free" ? '' : (!plandata && adduserCount !== 0) && <span>${(((updatedYearlyPrice - totalDiscountValue) * taxPercentage) / 100).toFixed(2)}</span>}
                    {!plandata && !selectedAddons?.length > 0 && adduserCount === 0 && cartAddedProducts && (
                      <span>${((calculateTotalPriceOfProduct() * taxPercentage) / 100).toFixed(2)}</span>
                    )}
                  </div>
                </div>
                {adduserCount === 0 && PlanName !== "Free" &&
                  <div className="discount-input-section">
                    <div>
                      {!visibleCouponForm ? (
                        <label htmlFor="discount-code" className="discount-code" onClick={() => { setvisibleCouponForm(!visibleCouponForm) }} style={{ cursor: 'pointer' }}>Add discount code</label>
                      ) : (
                        <label htmlFor="discount-code" className="discount-code" onClick={removeAppliedCoupon} style={{ cursor: 'pointer' }}>Remove discount code</label>
                      )
                      }
                    </div>
                    <form onSubmit={(e) => { e.preventDefault(); couponHandler(); }}>
                      {visibleCouponForm && (
                        <div className="discountForm">
                          <input
                            name="couponCode"
                            onChange={(e) => {
                              couponInputHandler(e);
                            }}
                            type="text"
                            value={couponCode[0]}
                          />
                          <button
                            className="applyCoupon"
                            disabled={applyCouponbtnActive}
                            type="submit"
                          >
                            Apply
                          </button>
                        </div>
                      )}
                      {showCouponError.showError && (
                        <span className="error">{showCouponError.error}</span>
                      )}
                    </form>
                  </div>}
                <div className="total-btn">
                  <h3>Total</h3>
                  {(plandata || selectedAddons?.length > 0) && <span> ${updatedYearlyPrice ? (updatedYearlyPrice - totalDiscountValue + ((updatedYearlyPrice - totalDiscountValue) * taxPercentage) / 100 + (calculateShippingCost().cost || 0)).toFixed(2) : "00.00"} </span>}
                  {!plandata && !selectedAddons?.length > 0 && cartAddedProducts && adduserCount === 0 && <span>${(calculateTotalPriceOfProduct() + (calculateTotalPriceOfProduct() * taxPercentage) / 100 + (calculateShippingCost().cost || 0)).toFixed(2)} </span>}
                  {!plandata && adduserCount !== 0 && <span>${(updatedYearlyPrice + ((updatedYearlyPrice - totalDiscountValue) * taxPercentage) / 100).toFixed(2)}</span>}
                </div>
                {plandata && PlanName !== "Free" && (
                  <div className="renew">
                    <p> Renewal date: {formattedDate}</p>
                    <p>
                      ${(subTotal + (subTotal * taxPercentage) / 100).toFixed(2)}
                      {cycle === "monthly" && "/month"}
                      {cycle === "yearly" && "/year"}
                    </p>
                  </div>)}
              </div>
            </div>
          </div>
          <div id="popup" className="popup">
            <div class="popup-content" id="popup-content">
              <div id="payment-success" className="payment-success">
                <svg
                  width="150"
                  height="150"
                  viewBox="0 0 150 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M58.1796 123C55.4032 123 52.6267 121.956 50.4972 119.842L9.17404 78.8107C4.94199 74.6086 4.94199 67.7834 9.17404 63.5813C13.4061 59.3792 20.2798 59.3792 24.5119 63.5813L58.1796 97.011L125.488 30.1516C129.72 25.9495 136.594 25.9495 140.826 30.1516C145.058 34.3537 145.058 41.1789 140.826 45.381L65.835 119.842C63.7325 121.929 60.9561 123 58.1796 123Z"
                    fill="green"
                  />
                </svg>
                <p style={{ fontSize: "20px" }}>Payment Successful!</p>
                <button
                  className="onetap_conn_move_dashboard_button"
                  onClick={redirectToDashboard}
                >
                  Continue to dashboard
                </button>
              </div>
              <div id="payment-failure" className="payment-failure">
                <svg
                  width="150"
                  height="150"
                  viewBox="0 0 150 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M37.5938 112.453L112.397 37.5371M112.397 112.453L37.5938 37.5371"
                    stroke="red"
                    stroke-width="20"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p style={{ fontSize: "20px" }}>{cardErrorMessage}</p>
                <button
                  className="onetap_conn_move_dashboard_button"
                  onClick={hideElements}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )
      }
    </>
  );
};
export default Checkout
