import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchUserInformationDetails, logout } from '../../../actions/userAction/userAction'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react'
import axios from "axios";
import OTPInput from "./AccountModelOTPinput"

const AccountModal = ({ isOpen, onClose }) => {
    const { user } = useSelector((state) => state.user);
    const email = user && user.email
    const firstname = user && user.first_name
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_URL;
  const { userInfo } = useSelector((state) => state.userInformation);
    const [sendingotpError, setsendingotpError] = useState('')

    const [otp, setOtp] = useState('');
    const [otpEntered, setOtpEntered] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [otpExpired, setOtpExpired] = useState(false);
    const [countdown, setCountdown] = useState(600);

    const handleDeleteAccount = async () => {
        try {
            const { data } = await axios.post(
                `${url}/generate-otp`,
                {
                    email: email,
                    firstname: firstname,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            if (data.success) {
                setOtpEntered(true);
            } else {
                setsendingotpError('Internal server error, Please try again later')
            }
        } catch (error) {
            setsendingotpError('Internal server error, Please try again later.');
        }
    };
    const verifyOTP = async () => {
        if (!otp) {
            setOtpError('Please enter OTP.');
            return;
        }
        setOtpError('');

        try {
            const { data } = await axios.post(
                `${url}/verify-otp`,
                {
                    email: email,
                    otp: otp,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            ); if (data.success) {
                let countdown = 5; // Set the initial countdown value
                const countdownInterval = setInterval(() => {
                    if (countdown > 0) {
                        setSuccessMessage(`OTP verified successfully. logging out in ${countdown} seconds...`);
                        countdown--;
                    } else {
                        clearInterval(countdownInterval);
                        dispatch(logout(user._id));

                    }
                }, 1000);
            } else {
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;

                if (errorMessage === "Incorrect OTP") {
                    setOtpError(errorMessage);
                } else if (errorMessage === "OTP has expired") {
                    setOtpError('OTP Expired');
                } else if (errorMessage === "Internal server error") {
                    setOtpError('Internal server error')
                }
            }
        }
    };
    const handleResendOTP = async () => {
        try {
            const { data } = await axios.post(
                `${url}/generate-otp`,
                {
                    email: email,
                    firstname: firstname,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            if (data.success) {
                setSuccessMessage(`OTP sent successfully.`);
                setOtpExpired(false);
                setCountdown(600);
            } else {
                setsendingotpError('Internal server error, Please try again later');
            }
        } catch (error) {
            setsendingotpError('Internal server error, Please try again later.');
        }
    };
    const fatchUserInfo = ()=>{
        if(userInfo === null){
            dispatch(fetchUserInformationDetails());
        }
    }
    useEffect(() => {
        fatchUserInfo()
    }, [userInfo]);
    
    useEffect(() => {
        let countdownInterval;

        if (otpEntered) {
            countdownInterval = setInterval(() => {
                if (countdown > 0) {
                    setCountdown(prevCountdown => prevCountdown - 1);
                } else {
                    clearInterval(countdownInterval);
                    setOtpExpired(true); // Set OTP as expired when countdown reaches zero
                }
            }, 1000);
        }

        return () => clearInterval(countdownInterval); // Cleanup the interval on component unmount
    }, [otpEntered, countdown]);

    const delete_account_modalStyle = {
        display: isOpen ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 9999,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.4)",
        pointerEvents: isOpen ? 'auto' : 'none',
    };

    const delete_account_modalContentStyle = {
        backgroundColor: "#fefefe",
        margin: "16% auto auto 45%",
        padding: "20px",
        border: "1px solid #888",
        width: "28%",
        borderRadius: "10px",
        textAlign: "center",
    };

    const deleteAccountbuttonStyle = {
        margin: "5px",
        padding: "10px 20px",
        width: "50%",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: "#e65925",
        border: "#e65925",
        color: "#fff",
    };
    const deleteAccountcanclebuttonStyle = {
        margin: "5px",
        padding: "9px 47px",
        width: "50%",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: "#fff",
        border: "#e65925",
        color: "#e65925",
        border: "1px solid #e65925"
    };
    const delete_account_iconContainer = {
        textAlign: "center"
    };
    const delete_account_buttonContainer = {
        display: "flex",
        justifyContent: "space-around"
    };
    const delete_accountText = {
        marginBottom: "20px",
        textAlign: "center",
        fontSize: "18px",
        letterSpacing: "1px",
    };
    const send_otpText = {
        marginBottom: "20px",
        textAlign: "center",
        fontSize: "18px",
        letterSpacing: "1px",
        fontWeight: "bold",
    };
    const delete_account_trashIconStyle = {
        color: "#e65925",
        marginBottom: "20px"
    };
    const pass_input_box = {
        width: "90%",
        borderRadius: "4px",
        border: "0px solid",
        backgroundColor: "#f2f2f2",
        height: "41px",
    }
    const conf_pass_head = {
        fontSize: "16px",
        marginBottom: "20px",
        letterSpacing: "1px",
    }
    const conf_pass_bottom = {
        fontSize: "16px",
        marginTop: "10px",
    }
    const handleCancel = () => {
        setOtpEntered(false);
        onClose();
        setCountdown(600);
        setOtpExpired(false);
        setOtpError(false);
        setSuccessMessage(false);
    };

    const renderContent = () => {
        if (!otpEntered) {
            return (
                <div>
                    <div style={delete_accountText}>Are you sure you want to delete this account?<br />Confirm account deletion with an OTP.</div>
                    <div style={delete_account_iconContainer}>
                        <FontAwesomeIcon icon={faTrash} bounce size="2xl" style={delete_account_trashIconStyle} />
                    </div>
                    {sendingotpError && <div style={{ color: 'red' }}>{sendingotpError}</div>}
                    <div style={delete_account_buttonContainer}>
                        <button style={deleteAccountbuttonStyle} onClick={handleDeleteAccount}>
                            Send OTP
                        </button>
                        <button style={deleteAccountcanclebuttonStyle} onClick={() => { onClose(); setsendingotpError(null); }}>
                            Cancel
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div style={conf_pass_head}>Check your Email <span style={{ color: '#e65925' }}>{email}</span> for OTP</div>
                    <div style={send_otpText}>Enter OTP</div>
                    <OTPInput length={6} onComplete={setOtp}
                        onChange={(e) => {
                            setOtp(e.target.value);
                            setOtpError('');
                        }} />
                    {otpError && <div style={{ color: 'red' }}>{otpError}</div>}
                    {otpEntered && (
                        <div style={{ fontSize: '15px', marginBottom: '10px' }}>
                            OTP expiring in &nbsp;
                            <span style={{ color: '#e65925' }}>
                                {Math.floor(countdown / 60).toString().padStart(2, '0')}
                            </span>:
                            <span style={{ color: '#e65925' }}>
                                {(countdown % 60).toString().padStart(2, '0')}
                            </span>
                        </div>
                    )}
                    <div style={delete_account_buttonContainer}>
                        <button style={deleteAccountbuttonStyle} onClick={verifyOTP}>
                            Confirm OTP
                        </button>
                        <button style={deleteAccountcanclebuttonStyle} onClick={handleCancel}>
                            Cancel
                        </button>
                    </div>
                    {successMessage && (
                        <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
                            {successMessage}
                        </div>
                    )}
                    {otpExpired && (
                        <div style={{ color: '#e65925', marginTop: '10px' }}>
                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleResendOTP}>Resend OTP</span>
                        </div>
                    )}
                    <div style={conf_pass_bottom}>For your protection, we need to verify your identity.</div>
                </div>
            );
        }
    };

    return (
        <div style={delete_account_modalStyle}>
            <div style={delete_account_modalContentStyle}>{renderContent()}</div>
        </div>
    );
};

export default AccountModal;

