import OTC_CLIENTSICON from "../Otc_assets/OTC_ICONS/OTC_CLIENTSICON.svg";
import OTC_DASHBOARDICON from "../Otc_assets/OTC_ICONS/OTC_DASHBOARDICON.svg";
import OTC_USERSICON from "../Otc_assets/OTC_ICONS/OTC_USERSICON.svg";
import OTC_SUBSCRPTIONICON from "../Otc_assets/OTC_ICONS/OTC_SUBSCRPTIONICON.svg";
import OTC_ORDERS from "../Otc_assets/OTC_ICONS/OTC_ORDERS.svg";
import OTC_AnalyticsIcon from "../Otc_assets/OTC_ICONS/OTC_AnalyticsIcon.svg";
import OTC_SmartAccessorieIcon from "../Otc_assets/OTC_ICONS/OTC_SmartAccessorieIcon.svg";
import OTC_TEMPLATE from "../Otc_assets/OTC_ICONS/OTC_TEMPLATE.svg";
import OTC_PRODUCT from "../Otc_assets/OTC_ICONS/OTC_PRODUCT.svg";
import OTC_SETING from "../Otc_assets/OTC_ICONS/OTC_SETING.svg";
import OTC_ACADAMY from "../Otc_assets/OTC_ICONS/OTC_ACADAMY.svg";
import OTC_BILLING from "../Otc_assets/OTC_ICONS/OTC_BILLING.svg";
import OTC_TUTORIAL from "../Otc_assets/OTC_ICONS/OTC_TUTORIAL.svg";
import SERVICES1 from "../Otc_assets/OTC_ICONS/SERVICES1.svg";
import SERVICES2 from "../Otc_assets/OTC_ICONS/SERVICES2.svg";
import SERVICES3 from "../Otc_assets/OTC_ICONS/SERVICES3.svg";
import CategoriesIcon from "../Otc_assets/OTC_ICONS/CategoriesIcon.svg";
import CouponsIcon from "../Otc_assets/OTC_ICONS/CouponsIcon.svg";
import OTC_ACCOUNT from "../Otc_assets/OTC_ICONS/OTC_ACCOUNT.svg";
import dropdownicon from "../Otc_assets/OTC_ICONS/dropdownicon.svg";
import dropdownicon2 from "../Otc_assets/OTC_ICONS/dropdownicon.svg";

const OTC_Routes = () => {
  const otc_routes = [
    {
      path: ["/admin/dashboard"],
      element: "",
      title: "Dashboard",
      icon: OTC_DASHBOARDICON,
      group: "",
    },
    {
      path: ["/admin/orders", "/admin/orders/create-order/:id"],
      element: "",
      title: "Orders",
      icon: OTC_ORDERS,
      group: "",
    },
    {
      path: ["/admin/subscriptions", "/admin/subscriptiondetails"],
      element: "",
      title: "Subscriptions",
      icon: OTC_SUBSCRPTIONICON,
      group: "",
    },
    {
      path: ["/admin/clients", "/admin/client/profile/:id"],
      element: "",
      title: "Clients",
      icon: OTC_CLIENTSICON,
      group: "",
    },
    {
      path: ["/admin/users", "/admin/user/profile/:id"],
      element: "",
      title: "Users",
      icon: OTC_USERSICON,
      group: "",
    },
    {
      path: ["/admin/guest-users"],
      element: "",
      title: "Guest Users",
      icon: OTC_USERSICON,
      group: "",
    },
    {
      path: ["/admin/analytics"],
      element: "",
      title: "Analytics",
      icon: OTC_AnalyticsIcon,
      group: "",
    },
    {
      path: ["/admin/otc-smart-accessories"],
      element: "",
      title: "Smart Accessories",
      icon: OTC_SmartAccessorieIcon,
      group: "",
    },
    {
      path: ["/admin/templates"],
      element: "",
      title: "Templates",
      icon: OTC_TEMPLATE,
      group: "Settings",
    },
    {
      path: [],
      element: "",
      title: "Accessories and Services",
      icon: OTC_PRODUCT,
      dropdownicon,
      group: "Settings",
      children: [
        {
          path: ["/admin/smart-accessories"],
          element: "",
          title: "Smart Accessories",
          icon: SERVICES1,
          group: "",
          child: true,
        },
        {
          path: ["/admin/addons"],
          element: "",
          title: "Add-ons",
          icon: SERVICES2,
          group: "",
          child: true,
        },
        {
          path: ["/admin/plans"],
          element: "",
          title: "Plans",
          icon: SERVICES3,
          group: "",
          child: true,
        },
        {
          path: ["/admin/Coupons"],
          element: "",
          title: "Coupons",
          icon: CouponsIcon,
          group: "",
          child: true,
        },
        {
          path: ["/admin/categories"],
          element: "",
          title: "Categories",
          icon: CategoriesIcon,
          group: "",
          child: true,
        },
      ],
    },
    {
      path: [],
      element: "",
      title: "Settings",
      icon: OTC_SETING,
      dropdownicon2,
      group: "Settings",
      childern2: [
        {
          path: ["/admin/onetapconnect-information"],
          element: "",
          title: "OneTapConnect Information",
          icon: OTC_CLIENTSICON,
          group: "",
          child2: true,
        },
        {
          path: ["/admin/otc-users"],
          element: "",
          title: "Team Members",
          icon: CategoriesIcon,
          group: "",
          child2: true,
        },
      ],
    },
    {
      path: ["/admin/academy"],
      element: "",
      title: "Academy",
      icon: OTC_ACADAMY,
      group: "Settings",
    },
    {
      path: ["/admin/tutorials"],
      element: "",
      title: "Tutorials",
      icon: OTC_TUTORIAL,
      group: "Settings",
    },

    {
      path: ["/admin/billing"],
      element: "",
      title: "Billing",
      icon: OTC_BILLING,
      group: "billing",
    },
    {
      path: ["/admin/account"],
      element: "",
      title: "Account ",
      icon: OTC_ACCOUNT,
      group: "billing",
    },
  ];
  return otc_routes;
};

export default OTC_Routes;
