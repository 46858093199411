import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./Otc_Header.css";
import notification from "../../Otc_assets/OTC_ICONS/notifiaction.svg";
import MoreIcon from "@mui/icons-material/MoreVert";
import userimg from "../../Otc_assets/users/user-4.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdminUser } from "../../Redux/Otc_UserActions";
import { useNavigate } from "react-router-dom";

const Otc_Header = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const {  adminuser  } =
    useSelector((state) => state.OtcAdminuser);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const logoutHandler = async () => {
    if (adminuser) {
      dispatch(logoutAdminUser());
    } else {
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();

  const initials =
    adminuser?.firstName?.charAt(0).toUpperCase() +
    adminuser?.lastName?.charAt(0).toUpperCase();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 3 new notifications"
          color="black"
        >
          <img src={notification} alt="notifiaction" />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="black"
        >
          <Avatar style={{ width: "48px", height: "48px" }} src={userimg} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <Box sx={{ flexGrow: 1 }} className="otc-header-navbar">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="black"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { xs: "block", sm: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <div style={{ marginRight: "30px" }}>
            <img src={notification} alt="notifiaction" />
          </div>
          <div role="button">
            <div>
              <div className="otc-header-avatar-container ">
                <div
                  className="header-avatar-initials-overlay"
                  style={{ width: "48px", height: "48px" }}
                  onClick={toggleDropdown}
                >
                  {initials}
                </div>
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div
              className="header-popup-modal"
              style={{ right: "22px", top: "70px" }}
            >
              <div
                role="button"
                className="header-popup-modal-text"
                onClick={() => navigate("/admin/dashboard")}
              >
                Dashboard
              </div>
              <div
                role="button"
                className="header-popup-modal-text"
                onClick={() => navigate("/admin/my-account")}
              >
                My Account
              </div>
              <div
                role="button"
                className="header-popup-modal-text"
                onClick={logoutHandler}
              >
                Logout
              </div>
            </div>
          )}
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="black"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
    </Box>
  );
};

export default Otc_Header;
