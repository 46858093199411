
import React, { useState } from "react";
import Otc_Sidebar from "../../Layout/Sidebar/Otc_Sidebar";
import OrderDetails from "./OrderDetails";
import OrderFulfillment from "./OrderFulfillment";
import OrderAddon from "./OrderAddon";
import OrderDetailsHeaderWithIcon from "./OrderDetailsHeaderWithIcon";


const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("OrderDetails");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user" style={{padding:'14px 0px'}}>
            <OrderDetailsHeaderWithIcon onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "OrderDetails" && (
                <OrderDetails
                    onClickSave={getValueOfSaveAndContinueTab}
                   />
            )}
            {companyProfileTab === "OrderFulfillment" && (
                <OrderFulfillment
                    onClickSave={getValueOfSaveAndContinueTab}
                   />
            )}
            {companyProfileTab === "OrderAddon" && (
                <OrderAddon
                    onClickSave={getValueOfSaveAndContinueTab}
                   />
            )}
         
        </div>
    );
};


export default function OrderDetailsMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} />
        </>
    )
}
