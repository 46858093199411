import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CompanyProfileHeadersWithIcons = (props) => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const { tab } = props;

  useEffect(() => {
    setCompanyProfileTab(tab);
  }, [tab]);

  const handleClickOfTab = (value) => {
    setCompanyProfileTab(value);
    props.onChange(value);
  };

  return (
    <>
      <p className="onetap_conn_dashbord_company_profile_syn">
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "black" }}
        >
          Dashboard
        </Link>
        &nbsp; {">"} &nbsp;
        <Link
          to="/company/information"
          style={{ textDecoration: "none", color: "black" }}
          onClick={() => handleClickOfTab("General")}
        >
          Company
        </Link>
        &nbsp; {">"} &nbsp; {""}
        <span className="onetap_conn_dashbord_company_profile_heighlight_syn">
          {tab}
        </span>
      </p>
      <div className="onetap_connect_info_headers_syn">
        <div
          onClick={() => handleClickOfTab("General")}
          className={
            companyProfileTab === "General"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
        >
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="34"
              height="34"
              rx="9"
              fill={companyProfileTab === "General" ? "#E65925" : "none"}
            />
            <path
              d="M23 20H21V22H23M23 16H21V18H23M25 24H17V22H19V20H17V18H19V16H17V14H25M15 12H13V10H15M15 16H13V14H15M15 20H13V18H15M15 24H13V22H15M11 12H9V10H11M11 16H9V14H11M11 20H9V18H11M11 24H9V22H11M17 12V8H7V26H27V12H17Z"
              fill={companyProfileTab === "General" ? "#ffff" : "#000000"}
            />
          </svg>
          <p
            className={
              companyProfileTab === "General"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
          >
            General
          </p>
        </div>

        <div
          onClick={() => handleClickOfTab("About")}
          className={
            companyProfileTab === "About"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <rect
              width="34"
              height="34"
              rx="10"
              fill={companyProfileTab === "About" ? "#E65925" : "none"}
            />
            <path
              d="M9.133 27.33C8.54643 27.33 8.0441 27.121 7.62604 26.7029C7.20797 26.2848 6.99929 25.7829 7 25.197V8.133C7 7.54643 7.20904 7.04411 7.6271 6.62604C8.04517 6.20797 8.54714 5.99929 9.133 6H17.665L24.064 12.399V15.7052L15.532 24.2105V27.33H9.133ZM17.665 27.33V25.0637L23.1575 19.5446L25.4504 21.7842L19.9313 27.33H17.665ZM26.2237 21.0377L23.9573 18.7713L24.7039 18.0248C24.9172 17.8115 25.1749 17.7048 25.4771 17.7048C25.7793 17.7048 26.0281 17.8115 26.2237 18.0248L26.9702 18.798C27.1657 19.0113 27.2635 19.2648 27.2635 19.5584C27.2635 19.8521 27.1657 20.0963 26.9702 20.2911L26.2237 21.0377ZM16.5985 13.4655H21.931L16.5985 8.133V13.4655Z"
              fill={companyProfileTab === "About" ? "#ffff" : "#000000"}
            />
          </svg>
          <p
            className={
              companyProfileTab === "About"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
          >
            About
          </p>
        </div>

        <div
          onClick={() => handleClickOfTab("Branding")}
          className={
            companyProfileTab === "Branding"
              ? "onetap_connect_info_header_icon_and_header_active_syn"
              : "onetap_connect_info_header_icon_and_header_deactive_syn"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <rect
              width="34"
              height="34"
              rx="10"
              fill={companyProfileTab === "Branding" ? "#E65925" : "none"}
            />
            <path
              d="M23.986 14.3563L19.6437 10.014L21.0996 8.55811C21.4913 8.16645 21.9725 7.97063 22.5433 7.97063C23.1141 7.97063 23.595 8.16645 23.986 8.55811L25.4419 10.014C25.8336 10.4057 26.0294 10.8869 26.0294 11.4577C26.0294 12.0285 25.8336 12.5094 25.4419 12.9004L23.986 14.3563ZM7.79183 26.2082V21.8659L12.3895 17.2682L7 11.8276L11.8276 7L17.2427 12.4406L18.2133 11.47L22.5556 15.7867L21.5849 16.7573L26.9745 22.198L22.1724 27L16.7318 21.5849L12.1341 26.2082H7.79183ZM13.8455 15.8123L15.7867 13.871L14.5607 12.645L13.3346 13.8455L11.9042 12.4151L13.1047 11.189L11.802 9.91188L9.88634 11.8531L13.8455 15.8123ZM22.1469 24.1137L24.0881 22.1724L22.7854 20.8953L21.5849 22.0958L20.129 20.6654L21.355 19.4393L20.1035 18.2133L18.1622 20.1545L22.1469 24.1137Z"
              fill={companyProfileTab === "Branding" ? "#ffff" : "#000000"}
            />
          </svg>

          <p
            className={
              companyProfileTab === "Branding"
                ? "onetap_connect_info_active_header_syn"
                : "onetap_connect_info_header_syn"
            }
          >
            Branding
          </p>
        </div>
      </div>
    </>
  );
};

export default CompanyProfileHeadersWithIcons;
