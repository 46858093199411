import React from "react";
import Sidebar from "../Layout/Sidebar/Sidebar";
import "./comingsoon.css";

const Component = () => {
  return (
    <>
      <div className="coming_main_h">
        <div className="coming_h">
          <h1>Coming Soon...!</h1>
        </div>
      </div>
    </>
  );
};
const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};
export default Dashboard;
