import React, { useState, useEffect } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Signup.scss";
import logo from "../../../assets/icons/logo_black.svg";
import Signup2 from "./Signup2";
import open_eye from "../../../assets/icons/eye.svg";
import close_eye from "../../../assets/icons/eye-off.svg";
import axios from "axios";
import PasswordChecklist from "react-password-checklist"


const SignUpPassword = () => {
  const ErrorMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#FDE9E9',
    color: "#ea4e4e",
    borderLeft: '5px solid'
  };

  const errorIconstyle = {
    position: 'absolute',
    top: '-8px',
    right: '12px',
    width: '25px',
  }
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="Error"><circle cx="32" cy="32" r="28" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></circle><line x1="32" x2="32" y1="18" y2="38" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line><line x1="32" x2="32" y1="42" y2="46" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line></svg>
  const loginInputsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passswordError, setPasswordError] = useState('');
  const [verifyPassword, setVerifyPassword] = useState();
  const [verifyPasswordError, setVerifyPasswordError] = useState();
  const [showPasswordPage, setShowPasswordPage] = useState(true);
  const [type, setType] = useState("password");
  const [typeImg, setTypeImg] = useState(open_eye);
  const [verifyType, setVerifyType] = useState("password");
  const [verifyTypeImg, setVerifyTypeImg] = useState(open_eye);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);


  const handlePasswordValidation = (isValid) => {
    setIsPasswordValid(isValid);
  };
  const handlePasswordInputClick = () => {
    setShowPasswordChecklist(true);
  };

  const url = process.env.REACT_APP_URL;
  // Extracting the pathname from the location object
  const pathname = location.pathname;
  const { token } = useParams();
  let data = {
    password,
    token,
  };
  if (location.state) {
    data = {
      token,
      googleData: location.state.googleData,
    };
  }

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;

  const handleShowPassword = () => {
    if (type === "password") {
      setType("text");
      setTypeImg(close_eye);
    } else {
      setType("password");
      setTypeImg(open_eye);
    }
  };

  const handleShowVerifyPassword = () => {
    if (verifyType === "password") {
      setVerifyType("text");
      setVerifyTypeImg(close_eye);
    } else {
      setVerifyType("password");
      setVerifyTypeImg(open_eye);
    }
  };

  //this function is for first form validation
  const step2Error = async (e) => {
    e.preventDefault();
    if (!password) {
      return setPasswordError("Please enter a password");
    } else if (password.length < 8) {
      return setPasswordError("Password must be 8 letter");
    } else if (!passwordRegex.test(password)) {
      return setPasswordError(
        "Must contain at least one uppercase, lowercase letter, one special character, and one number"
      );
    } else if (!verifyPassword) {
      return setVerifyPasswordError("Please enter a valid password");
    } else if (password !== verifyPassword) {
      return setVerifyPasswordError("Password does not match");
    }
    setPasswordError(passswordError);
    setVerifyPasswordError(verifyPasswordError);
    if (pathname.includes('/register')) { setShowPasswordPage(false); }
    else {
      updatePassword();
    }
  };
  const updatePassword = async () => {
    try {
      const response = await axios.post(
        `${url}/admin/createPassword`,
        { token, password },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        navigate('/login')
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (location.state) {
      const { isGoogle } = location?.state;
      if (isGoogle !== null) {
        setShowPasswordPage(!isGoogle);
      }
    }
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(null); // Clear password error when user starts typing
    setShowPasswordChecklist(true)
  };

  const handleVerifyPasswordChange = (e) => {
    setVerifyPassword(e.target.value);
    setVerifyPasswordError(null); // Clear verify password error when user starts typing
  };

  return (
    <>
      {showPasswordPage ? (
        <div className="login_password_container">
          <div className="login_password_left">
            <div className="login_header">
              <Link to="/login">
                <img src={logo} alt="oneTap Connect logo" srcset="" />
              </Link>
            </div>
            <div className="login_password_information">
              <h1 className="mb-4 login-password-header">Create a Password</h1>

              <form onSubmit={step2Error}>
                <div className="login_inputs">
                  <input
                    type={type}
                    placeholder="Enter Password"
                    autoFocus
                    name="companyName"
                    value={password}
                    onChange={handlePasswordChange}
                    onClick={handlePasswordInputClick}
                  />
                  <img
                    src={typeImg}
                    alt="Show"
                    srcset=""
                    className="login_eye"
                    onClick={handleShowPassword}
                  />
                </div>
                <div className="login_inputs">
                  <input
                    type={verifyType}
                    placeholder="Verify Password"
                    name="companyName"
                    value={verifyPassword}
                    onClick={handlePasswordInputClick}
                    onChange={handleVerifyPasswordChange}
                  />
                  <img
                    src={verifyTypeImg}
                    alt="Show"
                    srcset=""
                    className="login_eye"
                    onClick={handleShowVerifyPassword}
                  />
                </div>

                {showPasswordChecklist && <PasswordChecklist className="password-checklist" style={{ color: "white", display: "block" }}
                  rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                  minLength={8}
                  value={password}
                  valueAgain={verifyPassword}
                  messages={{
                    minLength: "At least 8 characters",
                    specialChar: "Contains one speacial character (@,#,$,%,etc.)",
                    number: "Contains one number",
                    capital: "Contains one uppercase letter",
                    lowercase: "Contains one lowercase letter",
                    match: "Passwords match.",
                  }}
                  invalidTextColor="red"
                  onChange={handlePasswordValidation}
                />}



                <style jsx>{`
  /* Override display: flex for the li elements in the PasswordChecklist component */
  .password-checklist li {
    display: block !important;
    /* Use !important to ensure the override */
    /* Add any additional styles or modifications as needed */
  }
  .password-checklist li span{
padding: 2px 7px !important;
  }

  /* You may need to use even more specific selectors depending on the library's styles */
`}</style>

                {(verifyPasswordError || passswordError) &&
                  <div style={loginInputsStyle} className="login_inputs">
                    <div style={ErrorMessageDiv}>{verifyPasswordError ? verifyPasswordError : passswordError}</div>
                    <span style={errorIconstyle} className="">{errorIcon}</span>
                  </div>}

                <div className="signup_btn_div">
                  {(pathname.includes('/register')) ?
                    <button style={{ opacity: isPasswordValid ? 1 : 0.5 }} disabled={!isPasswordValid} type="submit" className="signup_continuebtn">
                      Save Password
                    </button>
                    :
                    <button type="submit" className="signup_continuebtn" >
                      Continue
                    </button>}
                </div>
              </form>
            </div>
          </div>

          <div className="login_password_right">
            <h3>Meet Kimba</h3>
            <img src={phoneImg} alt="phoneImg" />
          </div>
        </div>
      ) : (
        <Signup2 data={data} />
      )}
    </>
  );
};

export default SignUpPassword;
