import React, { useState } from 'react';
import axios from 'axios';

const ShipStationTracking = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingInfo, setTrackingInfo] = useState(null);

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const trackShipment = async () => {
    const shipkey = process.env.REACT_APP_SHIPSTATION_APIKEY;
    const shipsecret = process.env.REACT_APP_SHIPSTATION_API_SECRET;
    const apiKey = shipkey;
    const apiSecret = shipsecret;
    try {
      const response = await axios.get(
        `https://ssapi.shipstation.com/shipments?trackingNumber=${trackingNumber}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${apiKey}:${apiSecret}`)}`,
          },
        }
      );

      // Extract relevant tracking information from the response
      const shipmentInfo = response.data; // Adjust based on ShipStation API response structure

      setTrackingInfo(shipmentInfo);
    } catch (error) {
    }
  };

  return (
    <div>
      <label>
        Tracking Number:
        <input
          type="text"
          value={trackingNumber}
          onChange={handleTrackingNumberChange}
        />
      </label>
      <button onClick={trackShipment}>Track Shipment</button>

      {trackingInfo && (
        <div>
          <h2>Tracking Information:</h2>
          {/* Display relevant tracking information here */}
          <pre>{JSON.stringify(trackingInfo, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default ShipStationTracking;
