import React, { useEffect, useState } from "react";
import "./templates.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer } from "react-toastify";
import phoneImg from "../../assets/images/card-image.svg";
import { Button, Menu, MenuItem } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: ".625rem",
  maxHeight: "85vh",
  overflowX: "auto",
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f2f2f2",
    borderRadius: "6px",
  },
};

const TemplatesTab = () => {
  const url = process.env.REACT_APP_URL;
  const cardEditorUrl = process.env.REACT_APP_CARD_EDITOR_URL
  const { adminuser } = useSelector((state) => state.OtcAdminuser);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Published");
  const [createdBy, setCreatedBy] = useState(
    adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : ""
  );
  const [editingId, setEditingId] = useState("");
  const [nameError, setNameError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUpload, setselectedImageUpload] = useState(null);
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setNameError("");
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setName("");
    setDescription("");
    setNameError("");
    setEditingId("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setselectedImageUpload(file);
    }
  };
  const getTemplates = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      const response = await axios.get(
        `${url}/admin/getAllTemplatesData`,
        config
      );

      if (response.data.success) {
        setAllTemplates(response.data.templates);
      } else {
      }
    } catch (error) { }
  };

  const handleCreate = async (id) => {
    if (!name) {
      setNameError(true);
      return;
    }
    setNameError(false);
    let datatoSend = {};
    const formData = new FormData();
    formData.append("image", selectedImageUpload);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    const { data } = await axios.post(
      `${url}/admin/templates/imageUpload`,
      formData,
      config
    );

    if (data.success) {
      datatoSend = {
        name,
        description,
        status,
        createdBy: adminuser
          ? `${adminuser?.firstName} ${adminuser?.lastName}`
          : "",
        image: selectedImageUpload ? data.imageNames[0].name : "",
      };

      const response = await axios.post(
        `${url}/admin/createTemplatesData`,
        {
          datatoSend,
          id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.data.success) {
        getTemplates();
        handleClose();
        handleEditClose();
        window.location.href = `${cardEditorUrl}/edit?uid=${adminuser?._id}&templateid=${response.data.newTemplate._id}`;
      }
    }
  };
  const handleDelete = async (id) => {
    const response = await axios.post(
      `${url}/admin/createTemplatesData`,
      {
        id,
        type: 'Delete'
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      getTemplates();
    }
  }

  useEffect(() => {
    if (!allTemplates) {
      getTemplates();
    }
  }, [allTemplates]);

  useEffect(() => {
    getTemplates();
  }, []);

  function getStatusColorClass(status) {
    if (status === "Published") {
      return "published";
    } else if (status === "Draft") {
      return "draft";
    } else if (status === "Archived") {
      return "archived";
    } else {
      return "";
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleEditDetails = (val) => {
    handleMenuClose();
    setName(val?.name);
    setDescription(val?.description);
    setStatus(val?.status);
    setCreatedBy(val?.createdBy);
    setOpenEdit(true);
    setEditingId(val?._id);
  };

  const handleEditTemplate = (val) => {
    handleMenuClose();
    setEditingId(val?._id);
    window.location.href = `${cardEditorUrl}/edit?uid=${adminuser?._id}&templateid=${val._id}`;
  };

  const setTemplateAsDefault = (templateId) => {
    axios
      .put(`${url}/admin/templates/set-default/${templateId}`)
      .then((response) => {
        if (response) {
          setAnchorEl(null);
          setDefaultTemplate(response.data);
          window.location.reload();
        }
      })
      .catch((error) =>
        console.error("Error setting default template:", error)
      );
  };

  return (
    <>
      <div className="onetap_connect_const_smartaccessoriestab">
        <div className="onetap_connect_only_accessary_child_synr">
          <div className="onetap_connect_smart_access_synr">
            <h3>Templates</h3>
            <p>Here is the list of all your available templates</p>
          </div>
        </div>
        <div
          className="onetap_connect_only_accessary_child_synr"
          style={{ minHeight: "50vh" }}
        >
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {allTemplates?.map((val, i) => {
              const handleMenuClick = (event) => {
                setAnchorEl(event.currentTarget);
                setSelectedIndex(i);
              };
              return (
                <div className="templates_templatebox mb-3" key={i}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30%" }}
                  >
                    <img
                      src={phoneImg}
                      alt="phoneImg"
                      width={100}
                      height={200}
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="d-flex justify-content-between mt-2"
                    style={{ width: "70%" }}
                  >
                    <div style={{ width: "90%" }}>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Name: </span>
                        <span style={{ fontWeight: "600" }}>{val?.name}</span>
                      </p>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Status: </span>
                        <span
                          style={{ fontWeight: "600" }}
                          className={`${getStatusColorClass(val?.status)}`}
                        >
                          {val?.status}
                        </span>
                      </p>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Created by:</span>
                        <br />
                        <span style={{ fontWeight: "600" }}>
                          {val?.createdBy}
                        </span>
                      </p>
                      <p>
                        {val.isDefault === true ? (
                          <span style={{ color: "#8A8A8A" }}>
                            Default Template
                          </span>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                    <div>
                      <Button
                        aria-controls={`menu-${i}`}
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                      >
                        <svg
                          role="button"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99935 10.666C8.35297 10.666 8.69211 10.8065 8.94216 11.0565C9.19221 11.3066 9.33268 11.6457 9.33268 11.9993C9.33268 12.353 9.19221 12.6921 8.94216 12.9422C8.69211 13.1922 8.35297 13.3327 7.99935 13.3327C7.64573 13.3327 7.30659 13.1922 7.05654 12.9422C6.80649 12.6921 6.66602 12.353 6.66602 11.9993C6.66602 11.6457 6.80649 11.3066 7.05654 11.0565C7.30659 10.8065 7.64573 10.666 7.99935 10.666ZM7.99935 6.66601C8.35297 6.66601 8.69211 6.80649 8.94216 7.05654C9.19221 7.30659 9.33268 7.64573 9.33268 7.99935C9.33268 8.35297 9.19221 8.69211 8.94216 8.94216C8.69211 9.1922 8.35297 9.33268 7.99935 9.33268C7.64573 9.33268 7.30659 9.1922 7.05654 8.94216C6.80649 8.69211 6.66602 8.35297 6.66602 7.99935C6.66602 7.64573 6.80649 7.30659 7.05654 7.05654C7.30659 6.80649 7.64573 6.66601 7.99935 6.66601ZM7.99935 2.66602C8.35297 2.66602 8.69211 2.80649 8.94216 3.05654C9.19221 3.30659 9.33268 3.64573 9.33268 3.99935C9.33268 4.35297 9.19221 4.69211 8.94216 4.94216C8.69211 5.19221 8.35297 5.33268 7.99935 5.33268C7.64573 5.33268 7.30659 5.19221 7.05654 4.94216C6.80649 4.69211 6.66602 4.35297 6.66602 3.99935C6.66602 3.64573 6.80649 3.30659 7.05654 3.05654C7.30659 2.80649 7.64573 2.66602 7.99935 2.66602Z"
                            fill="black"
                          />
                        </svg>
                      </Button>
                      <Menu
                        id={`menu-${i}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedIndex === i}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            boxShadow: "none",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: 35,
                        }}
                      >
                        <MenuItem onClick={() => handleEditTemplate(val)}>
                          Edit template
                        </MenuItem>
                        <MenuItem>View</MenuItem>
                        <MenuItem onClick={() => handleEditDetails(val)}>
                          Edit details
                        </MenuItem>
                        <MenuItem
                          disabled={val?.status === "Draft" || val.isDefault === true}
                          onClick={() => setTemplateAsDefault(val?._id)}
                        >
                          Set as a default
                        </MenuItem>

                        <MenuItem onClick={() => handleDelete(val?._id)} >Delete</MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="templates_add_new_box"
              role="button"
              onClick={() => {
                handleOpen();
              }}
            >
              <span style={{ fontSize: "36px", fontWeight: "600" }}>+</span>
              <div className="d-flex align-items-center justify-content-center">
                <p style={{ textAlign: "center" }}>Add New Template</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="sec-Modal-section">
            <div className="sec-modal-header-group border-0">
              <h3 className="header">
                Create Template{" "}
                <CloseIcon className="icon" onClick={handleClose} />
              </h3>
            </div>
            <span>Template name</span>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Ex: California Sales team"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <span className="error-message">Template name is required.</span>
            )}
            <p className="mt-3 mb-0">Description</p>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Card design for Sales team in state of California"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <p className="mt-3 mb-0">Status</p>
            <select
              name="status"
              id="status"
              style={{
                appearance: "none",
                outline: "none",
                cursor: "pointer",
              }}
              className="w-100 smartAccessories_addnew_modal_input"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </select>
            <p className="mt-3 mb-0">Image</p>
            {selectedImage ? (
              <label
                style={{ width: "100%" }}
                htmlFor="imageUpload"
                className="upload-label"
              >
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  name="productcategoryimage"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div
                  className="upload-label w-100 border-0 d-flex align-items-center justify-content-center"
                  style={{
                    position: "relative",
                    borderRadius: "4px",
                    background: "#f2f2f2",
                    padding: "0",
                    maxHeight: "200px",
                    overflowX: "auto",
                  }}
                >
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ width: "100%", maxHeight: "200px" }}
                  />
                </div>
              </label>
            ) : (
              <label
                style={{ width: "100%" }}
                className="upload-label"
                htmlFor="imageUpload"
              >
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  name="productcategoryimage"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div
                  className="upload-label w-100 border-0 d-flex align-items-center justify-content-center"
                  style={{
                    position: "relative",
                    borderRadius: "4px",
                    background: "#f2f2f2",
                    padding: "50px",
                  }}
                >
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="34"
                      viewBox="0 0 48 34"
                      fill="none"
                    >
                      <path
                        d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                        fill="#838383"
                      />
                    </svg>
                    <p>Upload</p>
                  </div>
                </div>
              </label>
            )}
            {selectedImage && (
              <div
                className="pc-remove-img"
                onClick={() => setSelectedImage("")}
              >
                Remove image
              </div>
            )}

            <div className="video-Modal-btn-group">
              <button
                className="video-Modal-btn video-first-modal-btn"
                onClick={() => {
                  if (!name) {
                    setNameError(true);
                    return;
                  }
                  setNameError(false);
                  setOpen(false);
                  handleCreate();
                }}
              >
                Create Template
              </button>
              <button
                className="video-Modal-btn video-sec-modal-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="sec-Modal-section">
            <div className="sec-modal-header-group border-0">
              <h3 className="header">
                Edit Template{" "}
                <CloseIcon className="icon" onClick={handleEditClose} />
              </h3>
            </div>
            <span>Template name</span>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Ex: California Sales team"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <span className="error-message">Template name is required.</span>
            )}
            <p className="mt-3 mb-0">Description</p>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Card design for Sales team in state of California"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <p className="mt-3 mb-0">Status</p>
            <select
              name="status"
              id="status"
              style={{
                appearance: "none",
                outline: "none",
                cursor: "pointer",
              }}
              className="w-100 smartAccessories_addnew_modal_input"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </select>
            <p className="mt-3 mb-0">Image</p>
            {selectedImage ? (
              <label
                style={{ width: "100%" }}
                htmlFor="imageUpload"
                className="upload-label"
              >
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  name="productcategoryimage"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div
                  className="upload-label w-100 border-0 d-flex align-items-center justify-content-center"
                  style={{
                    position: "relative",
                    borderRadius: "4px",
                    background: "#f2f2f2",
                    padding: "0",
                    maxHeight: "200px",
                    overflowX: "auto",
                  }}
                >
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ width: "100%", maxHeight: "200px" }}
                  />
                </div>
              </label>
            ) : (
              <label
                style={{ width: "100%" }}
                className="upload-label"
                htmlFor="imageUpload"
              >
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  name="productcategoryimage"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div
                  className="upload-label w-100 border-0 d-flex align-items-center justify-content-center"
                  style={{
                    position: "relative",
                    borderRadius: "4px",
                    background: "#f2f2f2",
                    padding: "50px",
                  }}
                >
                  <div style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="34"
                      viewBox="0 0 48 34"
                      fill="none"
                    >
                      <path
                        d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                        fill="#838383"
                      />
                    </svg>
                    <p>Upload</p>
                  </div>
                </div>
              </label>
            )}
            {selectedImage && (
              <div
                className="pc-remove-img"
                onClick={() => setSelectedImage("")}
              >
                Remove image
              </div>
            )}
            <div className="video-Modal-btn-group">
              <button
                className="video-Modal-btn video-first-modal-btn"
                onClick={() => {
                  if (!name) {
                    setNameError(true);
                    return;
                  }
                  setNameError(false);
                  setOpen(false);
                  handleCreate(editingId);
                }}
              >
                Edit Template
              </button>
              <button
                className="video-Modal-btn video-sec-modal-btn"
                onClick={handleEditClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default TemplatesTab;
