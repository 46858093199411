import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import "./googleAuth.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  fetchUserInformationDetails,
  googleLogin,
} from "../../../actions/userAction/userAction";


const GoogleAuth = (props) => {
  const { user } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.userInformation);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const invitedUsertoken = path.split("/sign-up/")[1];
  const { email, companyId, _id, first_name, last_name } = props.data;
  const userData = {
    email,
    companyId,
    _id,
    first_name,
    last_name
  };
  const url = process.env.REACT_APP_URL
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    dispatch(fetchUserInformationDetails());
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  let tokenValue = urlParams.get('token');
  const storedSelectedPlan = JSON.parse(localStorage.getItem("selectedPlan") || null);

  useEffect(() => {
    if (!tokenValue) {
      if (user && userInfo) {
        if (user?.role === 'teammember' || user?.role === 'manager') {
          navigate("/dashboard");
        }
        else if ((user) && (user?.role === 'superadmin') && (user?.Account_status === "is_Deactivated")) {
          navigate("/billing");
        }
        else if (user?.first_login === true && (userInfo?.subscription_details?.plan === 'Free' || userInfo?.subscription_details?.plan === "Professional")) {
          navigate(`/profile/information/${user?._id}`);
        }
        else if (user?.first_login === true && userInfo?.subscription_details?.plan === 'Team' && user?.role === 'superadmin') {
          navigate(`/company/information`);
        }
        else if ((user) && (user?.role === 'manager' || user?.role === 'superadmin' || user?.role === 'administrator') && (userInfo?.subscription_details?.plan)) {
          navigate("/dashboard");
        }
        else {
          if (!storedSelectedPlan) {
            navigate("/");
          } else {
            navigate("/checkout", {
              state: {
                id: storedSelectedPlan.id,
                planName: storedSelectedPlan.planName,
                additionUser: storedSelectedPlan.additionUser,
                cycle: storedSelectedPlan.cycle
              },
            });

          }
        }
      }
    }
  }, [navigate, userInfo, dispatch, user]);

  const recoveryToken = async () => {
    try {
      const response = await axios.post(
        `${url}/google_acc_recover`,
        { acc_recover_token: tokenValue },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        navigate('/login')
        setSuccessMessage('Your account has been recovered. Please login again.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Incorrect Email") {
        } else if (errorMessage === "Incorrect Password") {
        }
      }
    }
  };

  const responseGoogle = async (credentialResponse) => {
    try {
      const token = credentialResponse.credential;
      if (path === "/login") {
        dispatch(googleLogin(token));
      }
      if (path === "/sign-up") {
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/google-sign-up`,
          { token },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (data.success) {
          navigate(`/register/${data.token}`, {
            state: { isGoogle: true, googleData: data.googleData },
          });
        } else {
          alert("User with the same email already exists.")
        }
      }
      if (invitedUsertoken) {
        const invitedUserData = {
          userData: userData,
          token: token,
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_URL}/invited/google-sign-up`,
          { invitedUserData },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if(data.success){
          navigate("/login");
        }
      }
    } catch (error) {
      
    }
  };

  return (
    <div style={{ textAlign: '-webkit-center' }}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        className="googleButton"
      >
        <GoogleLogin
          onSuccess={tokenValue ? recoveryToken : responseGoogle}
          onError={() => {

          }}
        />
      </GoogleOAuthProvider>

      {successMessage && (
        <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default GoogleAuth;
