import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './productAndServices.css';

const ProductAndServicesHeaderWithIcons = (props) => {
    const { showCreateProduct, onProductClick, onAddNewProductClick } = props;

    const [productAndServicesTab, setProductAndServicesTab] = useState("Smart Accessories");
    const { tab } = props;
    useEffect(() => {
        setProductAndServicesTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setProductAndServicesTab(value);
        props.onChange(value);
        if (value === 'Smart Accessories') {
            onProductClick()
        }
    };

    return (
        <>
            <div className="OTC_Admin_mycard_header">
                <div
                    onClick={() => handleClickOfTab("Smart Accessories")}
                    className={
                        productAndServicesTab === "Smart Accessories"
                            ? "OTC_Admin_mycard_header_icon_and_header_active"
                            : "OTC_Admin_mycard_header_icon_and_header_deactive"
                    }
                    style={{ zIndex:'1'}}
                >
                    <div
                        style={{
                            backgroundColor: productAndServicesTab === "Smart Accessories" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M20 6H4V4H20V6ZM19 12C17.09 12 15.37 12.76 14.11 14H14V14.11C12.76 15.37 12 17.09 12 19C12 19.34 12.03 19.67 12.08 20H4V14H3V12L4 7H20L21 12V12.3C20.37 12.11 19.7 12 19 12ZM12 14H6V18H12V14ZM18.96 12L18.36 9H5.64L5.04 12H18.96ZM23.8 20.4C23.9 20.4 23.9 20.5 23.8 20.6L22.8 22.3C22.7 22.4 22.6 22.4 22.5 22.4L21.3 22C21 22.2 20.8 22.3 20.5 22.5L20.3 23.8C20.3 23.9 20.2 24 20.1 24H18.1C18 24 17.9 23.9 17.8 23.8L17.6 22.5C17.3 22.4 17 22.2 16.8 22L15.6 22.5C15.5 22.5 15.4 22.5 15.3 22.4L14.3 20.7C14.2 20.6 14.3 20.5 14.4 20.4L15.5 19.6V18.6L14.4 17.8C14.3 17.7 14.3 17.6 14.3 17.5L15.3 15.8C15.4 15.7 15.5 15.7 15.6 15.7L16.8 16.2C17.1 16 17.3 15.9 17.6 15.7L17.8 14.4C17.8 14.3 17.9 14.2 18.1 14.2H20.1C20.2 14.2 20.3 14.3 20.3 14.4L20.5 15.7C20.8 15.8 21.1 16 21.4 16.2L22.6 15.7C22.7 15.7 22.9 15.7 22.9 15.8L23.9 17.5C24 17.6 23.9 17.7 23.8 17.8L22.7 18.6V19.6L23.8 20.4ZM20.5 19C20.5 18.2 19.8 17.5 19 17.5C18.2 17.5 17.5 18.2 17.5 19C17.5 19.8 18.2 20.5 19 20.5C19.8 20.5 20.5 19.8 20.5 19Z" fill={productAndServicesTab === "Smart Accessories" ? "#ffff" : "#000000"} />
                        </svg>
                    </div>
                    <p
                        className={
                            productAndServicesTab === "Smart Accessories"
                                ? "OTC_Admin_mycard_active_header"
                                : "OTC_Admin_mycard_headers"
                        }
                    >
                        Smart Accessories
                    </p>
                </div>
                {!showCreateProduct && (
                    <div className="OTC_Admin_user_header_right" >
                        {productAndServicesTab === "Smart Accessories" && (<div className={`OTC_Admin_user_header_addusers`} onClick={onAddNewProductClick}>
                            <div
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                >
                                    <rect width="34" height="34" rx="9" fill="#E65925" />
                                    <path
                                        d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <div>Add New Smart Accessory</div>
                        </div>)}
                    </div>
                )}
            </div >
        </>
    );
};

export default ProductAndServicesHeaderWithIcons;
