import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@mui/material/Modal";
import OTCRoutes from "../../../Routes/otcRoutes";
import logo from "../../../assets/icons/logo_black.svg";
import { Link, useLocation } from "react-router-dom";
import logoutIcon from "../../../assets/icons/LogoutIcon.svg";
import "./sidebar.css";
import Header from "../Header/Header";
import { getPlan, loadUser, logout } from "../../../actions/userAction/userAction";
import { useDispatch, useSelector } from "react-redux";
import CompanyRoutes from "../../../Routes/CompanyRoutes";
import myCardsIcon from '../../../assets/icons/myCardsIcon.svg'
import AccountModal from "../../Customers/User/AccountModal.js";
import CloseIcon from "@mui/icons-material/Close";
import Popover from '@mui/material/Popover';
import cancelIcon from "../../../assets/icons/cancelIcon.svg"
import PlanCard from "../../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../../components/PlanCard/PlanCard";
const drawerWidth = 300;

function Sidebar(props) {

  const style3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "97%",
    bgcolor: "#F2F2F2",
    p: 4,
    outline: "none",
    overflowY: "scroll",
    height: "95%",
  };

  const { window, Component } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.Company);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [companyName, SetcompanyName] = React.useState();
  const location = useLocation();
  const [activePath, setActivePath] = React.useState("");
  const routes = CompanyRoutes();
  const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false);
  const [showPlanModal, setShowPlanModal] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [plandata, setPlanData] = React.useState([]);
  const { planData } = useSelector((state) => state.planData);

  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const fetchandSetPlans = () => {
    if (planData) {
      setPlanData(planData.plans);
    } else {
      dispatch(getPlan());
    }
  }

  React.useEffect(() => {
    fetchandSetPlans();
  }, [planData])
  React.useEffect(() => {
    loadUser();
  }, [])

  const handleCloseOfPlanModal = () => {
    setShowPlanModal(false);
  };
  const isActivePath = (paths) => {
    return paths.some(path => {
      if (path.endsWith('/:id')) {
        return location.pathname.startsWith(path.replace('/:id', ''));
      } else {
        return location.pathname === path;
      }
    });
  };

  const logoutHandler = () => {
    if (user) {
      dispatch(logout(user._id));
    } else {
    }
  };

  React.useEffect(() => {
    if (company && company.company_name) {
      SetcompanyName(company && company.company_name?.charAt(0).toUpperCase() + company.company_name?.slice(1));
    } else {
      SetcompanyName(`${user?.first_name?.charAt(0).toUpperCase()}${user?.first_name?.slice(1)} ${user?.last_name}`);
    }
  }, [user, company]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isCurrentUrlAdmin = () => {
    const currentPath = location.pathname;
    return currentPath.toLowerCase().startsWith('/admin');
  };

  const handleAccountOptionClick = () => {
    setIsAccountModalOpen(true);
  };
  const handleModalClose = () => {
    setIsAccountModalOpen(false);
  };
  const handleDeleteAccount = () => {
    setIsAccountModalOpen(false);
  };
  const [activePopover, setActivePopover] = React.useState(null);
  const [activeUpgradeGroup, setActiveUpgradeGroup] = React.useState(null);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, index, group, title) => {
    setAnchorEl(event.currentTarget);
    setActivePopover({ index, group, title });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveUpgradeGroup(null);
  };

  const open = Boolean(anchorEl);

  const drawer = (
    <div style={{ backgroundColor: 'black' }}>
      <div className="company-icon">
        <Link to="/dashboard">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      {user?.Account_status === "is_Deactivated" ? <div className="sidebar-list-container">
        <ListItem
          disablePadding
          component={"div"}
          disabled={true}
          className={`list-items ${activePath && activePath === '/my-cards' ? "active-link" : ""}`}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <img
              src={myCardsIcon}
              alt="icon"
              width={25}
              height={25}
              className={activePath && activePath === '#' ? "active-icon" : ""}
            />
          </ListItemIcon>
          <ListItemText primary="My Cards" />
        </ListItem>
      </div> : <div className="sidebar-list-container">
        <ListItem
          disablePadding
          component={Link}
          to={'/my-cards'}
          className={`list-items ${activePath && activePath === '/my-cards' ? "active-link" : ""}`}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <img
              src={myCardsIcon}
              alt="icon"
              width={25}
              height={25}
              className={activePath && activePath === '#' ? "active-icon" : ""}
            />
          </ListItemIcon>
          <ListItemText primary="My Cards" />
        </ListItem>
      </div>}
      <List className="sidebar-list-container">
        <span
          className="company-title"
        >
          {companyName}
        </span>

        {isCurrentUrlAdmin() && (
          <>
            {OTCRoutes.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                component={Link}
                to={item.path}
                className={`list-items ${activePath && activePath === item.path ? "active-link" : ""}`}
              >
                <ListItemIcon style={{ minWidth: '40px' }}>
                  <img
                    src={item.icon}
                    alt="icon"
                    width={25}
                    height={25}
                    className={
                      activePath && activePath === item.path
                        ? "active-icon"
                        : ""
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </>
        )}

        {!isCurrentUrlAdmin() && (
          <>
            {routes
              .filter(item => item.group === "")
              .map((item, index) => (

                <>
                  <ListItem
                    key={index}
                    disablePadding
                    component={item.disabled ? "div" : Link}
                    to={item.path[0]}
                    className={`list-items ${isActivePath(item.path) ? "active-link" : ""
                      } ${item.disabled ? "disabled-route" : ""}`}
                    style={item.disabled ? {} : {}}
                  >
                    <ListItemIcon style={{ minWidth: '40px' }}>
                      <img
                        src={item.icon}
                        style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                        alt="icon"
                        width={25}
                        height={25}
                        className={isActivePath(item.path) ? "active-icon" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}} primary={item.title} />

                    {item.upgradePlanIocn && (
                      <>
                        <ListItemIcon style={{ minWidth: '0px' }}
                          aria-describedby={`popover-${index}`}
                          onMouseEnter={(e) => handleClick(e, index, item.group)}
                          className="test">
                          <img
                            src={item.upgradePlanIocn ? item.upgradePlanIocn : null}
                            alt="icon"
                            width={16}
                            height={16}
                            className="upgrade-icon-sidebar"
                          />
                        </ListItemIcon>

                        <Popover
                          open={open && anchorEl && activePopover && activePopover.index + 1 === index + 1} // Ensure popover is open and anchorEl is set
                          id={`popover-${index + 1}`}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          className={`${item.disabled ? "popup-upgrade" : ""}`}
                          onClose={handleClose}
                          disableRestoreFocus
                        >
                          {activePopover && activePopover.group === "" && (
                            <>
                              {index === 1 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span>Get details analytics of visitors interacting with your OneTapConnect card.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true); }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                              {index === 2 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span>Unlock the ability to access your team members' OneTapConnect cards effortlessly.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true); }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                              {index === 3 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span>Access exclusive training and resources in our Academy to supercharge your prospecting skills.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true) }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                            </>
                          )}


                          {activePopover && activePopover.group === "settings" && (
                            <>
                              {index === 1 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span>Add detailed company information for more impactful interactions.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true); }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                              {index === 2 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span>Get access to premium templates for crafting compelling marketing messages.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true); }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                              {index === 3 && (<div className="main-upgrade">
                                <div className="text-upgrade">
                                  <span> Customize your OneTapConnect experience with advanced settings and options.</span>
                                  <img onClick={handleClose} className="cancel-icon-upgrade" src={cancelIcon} alt="" />
                                </div>
                                <div className="upgarde-btns">
                                  <button className="learnMore-upgrade-btn">Learn more</button>
                                  <button onClick={() => { setShowPlanModal(true); }} className="upgradeNow-btn">Upgrade now</button>
                                </div>
                              </div>)}
                            </>)}
                        </Popover>
                      </>
                    )}
                  </ListItem>
                </>
              ))}


            {routes
              .filter(item => item.group === "settings").length === 0 ? null : <span className="sidebarinfo-title" style={{ color: 'white' }}>Settings</span>
            }

            {routes
              .filter(item => item.group === "settings")
              .map((item, index) => (
                <ListItem
                  key={index + 1}
                  disablePadding
                  component={item.disabled ? "div" : Link}
                  to={item.path[0]}
                  className={`list-items  ${isActivePath(item.path) ? "active-link" : ""
                    } ${item.disabled ? "disabled-route" : ""}`}
                  style={item.disabled ? {} : {}}
                >
                  <ListItemIcon style={{ minWidth: '40px' }}>
                    <img
                      src={item.icon}
                      alt="icon"
                      style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                      width={25}
                      height={25}
                      className={isActivePath(item.path) ? "active-icon" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}} primary={item.title} />

                  {item.upgradePlanIocn && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon style={{ minWidth: '0px' }}  >
                        <img
                          src={item.upgradePlanIocn ? item.upgradePlanIocn : null}
                          alt="icon"
                          width={16}
                          height={16}
                          className="upgrade-icon-sidebar"
                          aria-describedby={`popover-${index}`}
                          onMouseEnter={(e) => handleClick(e, index + 1, item.group, item.title)}
                        />
                      </ListItemIcon>
                    </div>
                  )}
                </ListItem>
              ))}

            {routes
              .filter(item => item.group === "billing").length === 0 ? null : <span className="sidebarinfo-title" style={{ color: 'white' }}>Account & Billing</span>
            }

            {routes
              .filter(item => item.group === "billing")
              .map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  component={item.disabled ? "div" : Link}
                  to={item.path[0]}
                  className={`list-items ${isActivePath(item.path) ? "active-link" : ""
                    }`}
                >
                  <ListItemIcon style={{ minWidth: '40px' }}>
                    <img
                      src={item.icon}
                      alt="icon"
                      width={25}
                      height={25}
                      style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                      className={isActivePath(item.path) ? "active-icon" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}} primary={item.title} />
                </ListItem>
              ))}
          </>
        )}

      </List>
      {user?.role === "superadmin" &&
        <ListItem
          component="div"
          onClick={handleAccountOptionClick}
          className="list-items"
          style={{ padding: "0px 24px", cursor: "pointer" }}
        >
        </ListItem>}
      <List className="sidebar-bottom-list-container" style={{ marginTop: 'auto' }}>
        <ListItem
          component={Link}
          className="list-items"
          onClick={logoutHandler}
          style={{ padding: '0px 10px' }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <img src={logoutIcon} alt="icon" width={25} height={25} />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  React.useEffect(() => {
    const path = location.pathname;
    setActivePath(path);
  }, [setActivePath, location]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }} >
        <AppBar
          position="absolute"
          sx={{
            boxShadow: 'none',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Header handleDrawerToggle={handleDrawerToggle} />
        </AppBar>
        <AccountModal
          isOpen={isAccountModalOpen}
          onClose={handleModalClose}
          onDeleteAccount={handleDeleteAccount}
        />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                [`@media (max-width: 280px)`]: {
                  width: 200,
                },
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer variant="permanent"
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: '#000',
                [`@media (max-width: 280px)`]: {
                  width: 200,
                },
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar style={{ minHeight: "24px !important", boxShadow: "none" }} className="onetap_conn_header_extra_space" />
          <Component />
        </Box>

      </Box>

      <Modal
        open={showPlanModal}
        onClose={handleCloseOfPlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style3}>
          <div className="Modal-section">
            <h2 className="header" style={{ backgroundColor: '#F2F2F2' }}>
              Change plan
              <CloseIcon
                className="icon"
                onClick={handleCloseOfPlanModal}
              />
            </h2>
            <div className={`sliding-switch ${isActive ? "active" : ""}`}>
              <div className="switch-handle"></div>
              <div className="switch-text">
                <span
                  className={`switch-text-option ${isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Pay Monthly
                </span>
                <span
                  className={`switch-text-option ${!isActive ? "active" : ""}`}
                  onClick={handleToggle}
                >
                  Yearly and save 20%
                </span>
              </div>
            </div>
            {isActive ? (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "monthly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Professional") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Team") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Enterprise") {
                        return <YearlyCard props={plans} key={i} cycle={isActive} />;
                      }
                    })}
                </div>
              </>
            ) : (
              <>
                <div className="plan-selection-section">
                  {plandata &&
                    plandata.map((plans, i) => {
                      if (plans.isActive === false) {
                        return;
                      }
                      if (plans.type === "yearly") {
                        return;
                      }
                      if (plans.planType === "Free") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Professional") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Team") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }
                      if (plans.planType === "Enterprise") {
                        return <PlanCard props={plans} key={i} cycle={isActive} />;
                      }

                    })}
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
