import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Layout/Loader/Loader";
import { Box, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, fas } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
  >
    <path
      d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
      fill="#E65925"
    />
    <path
      d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
      stroke="white"
      stroke-width="6.64"
      stroke-linecap="round"
      class="checkmark-path"
    />
  </svg>
);

const url = process.env.REACT_APP_URL;

function ClientCompanySettingsGeneral(props) {
  const { id } = useParams();
  const frontendURL = window.location.origin;
  const [companyurlslugAvailability, setcompanyurlslugAvailability] =
    useState("");
  const [initialCompanyurlslug, setInitialCompanyurlslug] = useState("");
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [company_url_edit_permission, setcompany_url_edit_permission] =
    useState(true);
  const [companyData, setcompanyData] = useState("");
  const [companyurlslug, setcompanyurlslug] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [loading2, setLoading] = useState(true);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    axios
      .post(`${url}/admin/getCompanyDetailsforAdmin`, { companyID: id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
      .then((response) => {
        setCompanyID(response.data.company._id);
        setcompanyurlslug(response.data.company.companyurlslug);
        setInitialCompanyurlslug(response.data.company.companyurlslug);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const checkAvailability = async () => {
    try {
      const response = await axios.post(
        `${url}/admin/check-availability`,
        { companyurlslug: companyurlslug, companyID: companyID },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const isAvailable =
        response.data.message === "companyurlslug is available.";
      setcompanyurlslugAvailability(response.data.message);
      // Update the isSaveEnabled state based on both isNameAvailable and company_url_edit_permission
      setIsSaveEnabled(isAvailable || company_url_edit_permission);
      return true;
    } catch (error) {
      setcompanyurlslugAvailability(
        "Error checking companyurlslug availability"
      );
      setIsSaveEnabled(false);
      // Set isSaveEnabled to false in case of an error
      return false;
    }
  };

  const changehandler = (e) => {
    const newValue = e.target.value;
    setcompanyData({ ...companyData, companyurlslug: newValue });
    setcompanyurlslug(newValue);
  };

  const handleResetSlug = () => {
    // Reset the input value to the initial value
    setcompanyurlslug(initialCompanyurlslug);
    setcompanyData({ ...companyData, companyurlslug: initialCompanyurlslug });
  };

  const handleUpdateCompanySlug = async () => {
    // Check if availability check is successful
    const isAvailabilitySuccessful = await checkAvailability();

    // If availability check is successful, proceed with the axios post request
    if (isAvailabilitySuccessful) {
      setLoading(true)
      const response = await axios.post(
        `${url}/admin/UpdateCompanySlug`,
        {
          companyurlslug: companyurlslug,
          companyId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if(response.data.success){
          setApiSuccess(true);
          setSuccessMessage("Data updated successfully.");
          setLoading(false)
      }
    } else {
      setLoading(false)
    }
  };

  return (
    <>
      {loading2 ? (
        <Loader />
      ) : (
        <>
          <div
            className="row mb-3 mt-3"
            style={{ marginRight: 0, marginLeft: 0 }}
          >
            <div className="onetap_bg_box" style={{ padding: "25px 20px" }}>
              <h1 className="onetap_heading_main">General Settings</h1>
              <hr />
              <p className="onetap_ptag">
                These settings will be applied by default to all your users but
                can be overridden on a per-template and cards basis.
              </p>
            </div>
          </div>
          <div className="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
            <div className="onetap_bg_box">
              <div className="row">
                <h2 className="onetap_heading_h2">Permalink</h2>
                <p className="onetap_ptag">
                  By default, your company cards URL is based on your company
                  name and users first and last names.
                </p>
              </div>
              <hr className="" />
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  // padding: "0px 6px 0px 6px",
                }}
              >
                <div className="col-2">
                  <h6
                    style={{ fontWeight: "600", color: "#000" }}
                    className="m-0"
                  >
                    Default card URL
                  </h6>
                </div>
                <div
                  className="col cardurltype"
                  style={{ alignItems: "center" }}
                >
                  <div className="cardurlmake">
                    <span>{frontendURL}/</span>
                    <span style={{ marginTop: "7px" }}>
                      <input
                        type="text"
                        className="companynameinput"
                        placeholder="[company-name]"
                        value={companyurlslug}
                        onChange={(e) => changehandler(e)}
                      />
                    </span>
                    <span>/[firstnamelastname]</span>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center mt-2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <span
                    className="checkavailability ms-2"
                    onClick={checkAvailability}
                  >
                    Check availability.
                  </span>
                  {companyurlslugAvailability !== "" && (
                    <>
                      <span
                        className="ms-2"
                        style={{
                          color:
                            companyurlslugAvailability ===
                              "companyurlslug is available."
                              ? "green"
                              : "red",
                        }}
                      >
                        {companyurlslugAvailability ===
                          "companyurlslug is available."
                          ? "Card URL is Available."
                          : "Card URL is Not Available."}
                      </span>
                      <span
                        className="checkavailability_reset_btn justify-content-center d-flex ms-2 p-1"
                        onClick={handleResetSlug}
                      >
                        Reset
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-end">
              <button
                className={`onetap_connect__comp_profile_save_button_syn ${!isSaveEnabled ? "disabled" : ""
                  }`}
                onClick={handleUpdateCompanySlug}
                disabled={!isSaveEnabled}
                style={{ opacity: !isSaveEnabled ? 0.5 : 1 }}
              >
                Save
              </button>
              <button
                className={`onetap_connect__comp_profile_save_and_cont_button_syn ${!isSaveEnabled ? "disabled" : ""
                  }`}
                onClick={(e) => {
                  handleUpdateCompanySlug();
                  setTimeout(() => {
                    props.onClickSave("User profile");
                  }, 3000);
                }}
                disabled={!isSaveEnabled}
                style={{ opacity: !isSaveEnabled ? 0.5 : 1 }}
              >
                Save & Continue
              </button>
            </div>
          </div>
          <div className="row mb-3" style={{ marginRight: 0, marginLeft: 0 }}>
            <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
              <h5>Activity log</h5>
              <hr />
              <div
                style={{
                  borderRadius: "4px",
                  background: "#f2f2f2",
                  border: "none",
                  height: "72px",
                  padding: "8px",
                  overflowY: "auto",
                }}
                suppressContentEditableWarning={true}
              >
                Basic log of recent client and OTC admin activity
              </div>
            </div>
          </div>
          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: "center" }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">{messageIcon}</div>
                </div>
                <span className="team_created_success">{successMessage}</span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "13%",
                }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#e65925" }}
                  />
                </span>
              </button>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default ClientCompanySettingsGeneral;
