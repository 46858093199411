
import React, { useState } from "react";
import "./AccountModelOTPinput.css";

const OTPInput = ({ length, onComplete }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      if (newOtp.every((num) => num !== "")) {
        onComplete(newOtp.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <div className="otp-input-container">
      {otp.map((num, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          value={num}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength="1"
        />
      ))}
    </div>
  );
};

export default OTPInput;