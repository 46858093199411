import { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import ProfileMemberHeader from "./ProfileMemberHeader";
import ProfileInformation from "./ProfileInformation";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("Profile");
  const { id } = useParams();
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  return (
    <div className="onetap_conn_user">
      <ProfileMemberHeader onChange={getTabValue} tab={companyProfileTab} />
      {companyProfileTab === "Profile" && (
        <ProfileInformation
          onClickSave={getValueOfSaveAndContinueTab}
          id={id}
        />
      )}
    </div>
  );
};

const ProfileMember = () => {
  return <Sidebar Component={Component} />;
};

export default ProfileMember;
