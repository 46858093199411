import React from 'react'

const ShopSmartAccessoriesTab = () => {
  return (
    <div className="onetap_connect_user_analytics_only_accessary_child_synr">
      <div className="onetap_connect_user_analytic_smart_access_synr">
        <p>Shop Smart Accessories Tab Section</p>
      </div>
    </div>
  )
}

export default ShopSmartAccessoriesTab
