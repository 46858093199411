import React, { useEffect, useState } from 'react'
import searchIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/searchIcon.svg"
import selectDropdownIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
import plusIconWithBG from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/plusIconWithBG.svg"
import canceliconOrange from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/canceliconOrange.svg"
import plusIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/plusIcon.svg"
import "./Plan.css"
import axios from 'axios'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../../../actions/userAction/userAction'
import Noimagefound from "../../Otc_assets/images/Noimagefound.png"
import { getAllPlans } from '../../Redux/Otc_UserActions'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from '../../../components/Layout/Loader/Loader'
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

//handleEditPlan
//showAddPlan
const Plan = ({ handleEditPlanOpen, showAddPlan, back }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch()
    useEffect(() => {
        if (!showAddPlan) {
            setCurrentDateTime('')
            setSelectedPCId('')
            setselectedImageUpload(null)
            setSelectedImage(null)
            setPlanData({})
            setPlanFormData({})
            setAddonsChild([])
            setProducts([])
            setSelectedAddons([])
            setSelectedProducts([])
            setUpdatedAddons(null)
            setUpdatedProducts(null)
            setFieldErrors({});
        }
    }, [showAddPlan])

    const warningModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "#FDE9E9",
        p: 3,
        outline: "none",
        border: "1px solid E62525",
        borderRadius: "10px",
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: "10px",
        overflowY: "scroll",
        maxHeight: "80%",
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPCId, setSelectedPCId] = useState("")
    const [isPermalinkEditable, setIsPermalinkEditable] = useState("");
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [selectedImageUpload, setselectedImageUpload] = useState(null)
    const [addons, setAddons] = useState([])
    const [openModal, setOpenModal] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [Products, setProducts] = useState([]);
    const [UpdatedProducts, setUpdatedProducts] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [Addons, setAddonsChild] = useState([]);
    const [UpdatedAddons, setUpdatedAddons] = useState([]);
    const [openWarningModal, setOpenWarningModal] = React.useState(false);
    const handleWarningOpenModal = () => { setOpenWarningModal(true) }
    const handleCloseWarningdModal = () => {
        handleCreatePlan()
        setOpenWarningModal(false);
    }
    const { products, message, error } = useSelector(
        (state) => state.products
    );


    const { plans, loading } = useSelector(
        (state) => state.OtcPlans
    );


    useEffect(() => {
        if (!plans) {
            dispatch(getAllPlans())
        }
    }, [])
    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [products])


    const filteredProducts = products?.filter(
        (product) => product.status === 'Published' && product.visibility === 'Public'
    );

    const filteredProductsWithVariations = filteredProducts?.flatMap((product) => {
        if (product.hasVariations && product.variations.length > 0) {
            return product.variations.map((variation) => ({
                ...variation,
                productId: product._id,
            }));
        }
        return [product];
    });

    const productsToExclude = UpdatedProducts?.map((item) => item._id);
    const filteredProductsExcludingUpdated = filteredProductsWithVariations?.filter(
        (val) => !productsToExclude?.includes(val._id)
    );

    const filteredAddons = addons?.filter(
        (product) => product.status === 'Published' && product.visibility === 'Public'
    );
    const addonsToExclude = UpdatedAddons?.map((item) => item._id);
    const filteredAddonsExcludingUpdated = filteredAddons?.filter(
        (val) => !addonsToExclude?.includes(val._id)
    )
    function getStatusColorClass(status) {
        if (status === "Published") {
            return "published";
        } else if (status === "Draft") {
            return "draft";
        } else if (status === "Archived") {
            return "archived";
        } else {
            return "";
        }
    }

    const [fieldErrors, setFieldErrors] = useState({
        InternalPlanName: '',
        PublicPlanName: '',
        CustomPermalink: '',
        planType: '',
        users: '',
        monthlyPrice_perUser: '',
        monthly_sku: '',
        yearlyPrice_perUser: '',
        yearly_sku: '',
    });

    const initialPlanFormData = {
        InternalPlanName: '',
        PublicPlanName: '',
        CustomPermalink: '',
        description: '',
        features: '',
        image: "",
        imageName: '',
        altText: '',
        status: '',
        Visibility: '',
        activitylog: '',
        smart_accessories: [],
        add_ons: []
    };
    const initialPlanData = {
        planType: '',
        users: '',
        monthlyPrice_perUser: '',
        monthly_fee: '',
        monthly_sku: '',
        yearlyPrice_perUser: '',
        yearly_fee: '',
        yearly_sku: ''
    }
    const [planFormData, setPlanFormData] = useState(initialPlanFormData);
    const [planData, setPlanData] = useState(initialPlanData);
    let monthlyTotalPrice;
    let monthlyRecurringTotalPrice
    let YearlyTotalPrice
    let YearlyRecurringTotalPrice
    if (planData.planType === 'Team') {
        monthlyTotalPrice = (parseInt(planData.monthlyPrice_perUser) * parseInt(planData.users)) + parseInt(planData.monthly_fee);
        monthlyRecurringTotalPrice = (parseInt(planData.monthlyPrice_perUser) * parseInt(planData.users))

        YearlyTotalPrice = (parseInt(planData.yearlyPrice_perUser) * parseInt(planData.users) * 12) + parseInt(planData.yearly_fee);
        YearlyRecurringTotalPrice = (parseInt(planData.yearlyPrice_perUser) * parseInt(planData.users) * 12);
    } else {
        monthlyTotalPrice = (parseInt(planData.monthlyPrice_perUser) * 1) + parseInt(planData.monthly_fee);
        monthlyRecurringTotalPrice = (parseInt(planData.monthlyPrice_perUser) * 1)

        YearlyTotalPrice = (parseInt(planData.yearlyPrice_perUser) * 1 * 12) + parseInt(planData.yearly_fee);
        YearlyRecurringTotalPrice = (parseInt(planData.yearlyPrice_perUser) * 1 * 12);
    }

    let planformdata;

    const smart_accessoriesIds = UpdatedProducts?.map(item => item._id);
    const add_onsIds = UpdatedAddons?.map(item => item._id);

    const handleupdatePro = async () => {
        const fun = () => {
            setProducts(selectedProducts);
        }
        fun();
        setProducts((prevProducts) => {
            setUpdatedProducts(prevProducts);
            return prevProducts;
        });
    }

    const handleSelectProduct = (user) => {
        const isSelected = selectedProducts?.some(
            (selectedProduct) => selectedProduct._id === user._id
        );

        if (isSelected) {
            setSelectedProducts((prevSelectedUser) =>
                prevSelectedUser ? prevSelectedUser.filter((selectedProduct) => selectedProduct._id !== user._id) : []
            );
        } else {
            setSelectedProducts((prevSelectedUser) => (prevSelectedUser ? [...prevSelectedUser, user] : [user]));
        }
        if (Products.length > 0) {
            const isSelectedProduct = Products?.some(
                (Products) => Products._id === user._id
            );

            if (isSelectedProduct) {
                setProducts((prevSelectedUser) =>
                    prevSelectedUser ? prevSelectedUser.filter((Products) => Products._id !== user._id) : []
                );
            } else {
                setProducts((prevSelectedUser) => (prevSelectedUser ? [...prevSelectedUser, user] : [user]));
            }
        }
    };

    const handleupdateAddons = async () => {
        const fun = () => {
            setAddonsChild(selectedAddons);
        }
        fun();
        setAddonsChild((prevProducts) => {
            setUpdatedAddons(prevProducts);
            return prevProducts;
        });
    }

    const handleSelectAddons = (user) => {
        const isSelected = selectedAddons?.some(
            (selectedAddons) => selectedAddons._id === user._id
        );

        if (isSelected) {
            setSelectedAddons((prevSelectedUser) =>
                prevSelectedUser ? prevSelectedUser.filter((selectedAddons) => selectedAddons._id !== user._id) : []
            );
        } else {
            setSelectedAddons((prevSelectedUser) => (prevSelectedUser ? [...prevSelectedUser, user] : [user]));
        }
        if (Addons.length > 0) {

            const isSelectedAddons = Addons?.some(
                (Addons) => Addons._id === user._id
            );

            if (isSelectedAddons) {
                setAddonsChild((prevSelectedUser) =>
                    prevSelectedUser ? prevSelectedUser.filter((Addons) => Addons._id !== user._id) : []
                );
            } else {
                setAddonsChild((prevSelectedUser) => (prevSelectedUser ? [...prevSelectedUser, user, ...UpdatedAddons] : [user, ...UpdatedAddons]));
            }
        }
    };

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            setPlanFormData({
                ...planFormData,
                [name]: value,
            });
            setFieldErrors({
                ...fieldErrors,
                [name]: '',
            });
        } else {
            setPlanFormData({
                ...planFormData,
                features: e,
            });
        }
    };

    const handleInputClick = () => {
        setPlanData((prevPlanData) => ({
            ...prevPlanData,
        }));
    };
    const findAvailableSkuMonthly = (skuType, baseSku, plans) => {
        let availableSku = baseSku;
        let counter = 1;

        while (plans.some((plan) => plan.monthly_sku === availableSku)) {
            counter += 1;
            availableSku = `${baseSku}-${counter}`;
        }

        return availableSku;
    };
    const findAvailableSkuyearly = (skuType, baseSku, plans) => {
        let availableSku = baseSku;
        let counter = 1;

        while (plans.some((plan) => plan.yearly_sku === availableSku)) {
            counter += 1;
            availableSku = `${baseSku}-${counter}`;
        }

        return availableSku;
    };
    const handlePlanDataChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'monthly_sku' && name !== 'yearly_sku' && value !== '' && (isNaN(value) || parseFloat(value) <= 0)) {
            e.target.value = '';
        }
        if (name === 'monthly_sku') {
            const baseSku = value.trim();
            const MavailableSku = findAvailableSkuMonthly('monthly_sku', baseSku, plans);
            setPlanData({
                ...planData,
                monthly_sku: MavailableSku,
            });
        } else if (name === 'yearly_sku') {
            const baseSku = value.trim();
            const YavailableSku = findAvailableSkuyearly('yearly_sku', baseSku, plans);
            setPlanData({
                ...planData,
                yearly_sku: YavailableSku
            });
        } else {
            setPlanData({
                ...planData,
                [name]: value,
            });
        }
        if (name === "planType") {
            setPlanData((prevPlanData) => ({
                ...prevPlanData,
            }));
        }
        setFieldErrors({
            ...fieldErrors,
            [name]: '',
            planType: '',
        });
    };

    const handlePermalinkChange = (e) => {
        const slug = e.target.value
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '');
        setPlanFormData({
            ...planFormData,
            CustomPermalink: slug
            ,
        });
        setFieldErrors({
            ...fieldErrors,
            CustomPermalink: '',
        });
    };
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setselectedImageUpload(file);
        }
    };

    const getAddons = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/getAddons`, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            setAddons(data.Addons)

        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        getAddons()
    }, [])


    const handleCreatePlan = async (e) => {
        try {
            const errors = {};
            const requiredFields = {
                InternalPlanName: 'Internal plan name is required.',
                PublicPlanName: 'Public plan name is required.',
                CustomPermalink: 'Custom permalink is required.',
                planType: 'Plan type is required.',
                monthlyPrice_perUser: 'Monthly Price per User is required.',
                monthly_sku: 'Monthly SKU is required.',
                yearlyPrice_perUser: 'Yearly Price per User is required.',
                yearly_sku: 'Yearly SKU is required.',
            };

            for (const field in requiredFields) {
                if (!planFormData[field] && !planData[field]) {
                    errors[field] = requiredFields[field];
                }
            }

            if ((planData.planType === 'Team' || planData.planType === 'Enterprise') && !planData.users) {
                errors['users'] = 'Number of users is required for Team plans.';
            }

            if (planData.planType !== 'Free' && Object.keys(errors).length > 0) {

                setFieldErrors(errors);
                return;
            }

            const formData = new FormData();
            formData.append("image", selectedImageUpload);

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            };
            const { data } = await axios.post(
                `${url}/admin/imageUpload?imageType=plan`,
                formData,
                config
            );
            if (data.success) {
                if (e === 'Archived') {
                    planformdata = {
                        ...planFormData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                        status: 'Archived',
                        Visibility: 'Public',
                        smart_accessories: smart_accessoriesIds,
                        add_ons: add_onsIds,
                    }
                } else if (planFormData.status === '' || planFormData.Visibility === '') {
                    planformdata = {
                        ...planFormData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                        status: 'Published',
                        Visibility: 'Public',
                        smart_accessories: smart_accessoriesIds,
                        add_ons: add_onsIds,
                    }
                } else {
                    planformdata = {
                        ...planFormData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                        smart_accessories: smart_accessoriesIds,
                        add_ons: add_onsIds,
                    }
                }
                const response = await axios.post(`${url}/admin/plan/create`, { planFormData: planformdata, planData, id: selectedPCId._id }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });
                dispatch(getAllPlans())
                back()
                setselectedImageUpload(null)
                setSelectedImage(null)
                setPlanData({});
                setPlanFormData({});
                setUpdatedAddons(null)
                setUpdatedProducts(null)
                setAddonsChild([])
                setProducts([])
                setSelectedAddons([])
                setSelectedProducts([])
            }
        } catch (error) {
        }
    };

    const [searchQuery, setSearchQuery] = useState("");
    const [typeFilter, setTypeFilter] = useState("Type");
    const [statusFilter, setStatusFilter] = useState("Status");
    const [filteredPlans, setFilteredPlans] = useState(plans);

    const clearFilters = () => {
        setSearchQuery("");
        setTypeFilter("Type");
        setStatusFilter("Status");
    };


    useEffect(() => {
        if (searchQuery || typeFilter !== "Type" || statusFilter !== "Status") {
            const filtered = plans.filter((plan) =>
                plan.planType.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (typeFilter === "Type" || plan.planType === typeFilter) &&
                (statusFilter === "Status" || plan.status === statusFilter)
            );
            setFilteredPlans(filtered);
        } else {
            setFilteredPlans(plans);
        }
    }, [searchQuery, typeFilter, statusFilter, plans]);

    const handleEditPlan = (selectedCard) => {


        const selectedProductIds = selectedCard.smart_accessories;

        const matchedProducts = filteredProductsExcludingUpdated.filter(product => selectedProductIds.includes(product._id));

        const rawPublishedDate = new Date(selectedCard.publishedDate);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(rawPublishedDate);

        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        }).format(rawPublishedDate);

        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
        setCurrentDateTime(formattedDateTime)
        setSelectedPCId(selectedCard)
        setPlanData({
            planType: selectedCard.planType,
            users: selectedCard.users,
            monthlyPrice_perUser: selectedCard.monthlyPrice_perUser,
            monthly_fee: selectedCard.monthly_fee,
            monthly_sku: selectedCard.monthly_sku,
            yearlyPrice_perUser: selectedCard.yearlyPrice_perUser,
            yearly_fee: selectedCard.yearly_fee,
            yearly_sku: selectedCard.yearly_sku,

        });

        const htmlContent = selectedCard.features;
        if (htmlContent) {
            const blocksFromHTML = htmlToDraft(htmlContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
        setPlanFormData({
            InternalPlanName: selectedCard.InternalPlanName,
            PublicPlanName: selectedCard.PublicPlanName,
            description: selectedCard.description,
            // features: selectedCard.features,
            image: selectedCard.image,
            imageName: selectedCard.imageName,
            altText: selectedCard.altText,
            status: selectedCard.status,
            Visibility: selectedCard.Visibility,
            activitylog: selectedCard.activitylog,
            smart_accessories: selectedCard.smart_accessories,
            add_ons: selectedCard.add_ons,
            CustomPermalink: selectedCard.CustomPermalink?.split('/').pop() || '',
        });

        setUpdatedProducts(matchedProducts)
        setProducts(selectedCard.smart_accessories)
        setUpdatedAddons(selectedCard.add_ons)
        setAddonsChild(selectedCard.add_ons)

        if (selectedCard.image !== '') {
            setSelectedImage(`${url}/admin/plan/img/${selectedCard.image}`);
        } else {
            setSelectedImage(Noimagefound);
        }
    };

    const handleCancelIconClickProduct = (indexToRemove) => {
        const updatedProductsCopy = [...UpdatedProducts];
        updatedProductsCopy.splice(indexToRemove, 1);
        setUpdatedProducts(updatedProductsCopy);
        const updatedProductsCopyNew = [...selectedProducts];
        updatedProductsCopyNew.splice(indexToRemove, 1);
        setSelectedProducts(updatedProductsCopy);
    };
    const handleCancelIconClickAddon = (indexToRemove) => {
        const updatedAddonsCopy = [...UpdatedAddons];
        updatedAddonsCopy.splice(indexToRemove, 1);
        setUpdatedAddons(updatedAddonsCopy);
        const updatedAddonsCopynew = [...selectedAddons];
        updatedAddonsCopynew.splice(indexToRemove, 1);
        setSelectedAddons(updatedAddonsCopynew);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [displayedPages, setDisplayedPages] = useState([]);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredPlans?.length / itemsPerPage)
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredPlans?.slice(startIndex, endIndex);

    useEffect(() => {
        if (totalPages <= 5) {
            setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
        } else {
            if (currentPage <= 3) {
                setDisplayedPages([1, 2, 3, 4, 5, '. . .', totalPages]);
            } else if (currentPage >= totalPages - 2) {
                setDisplayedPages([1, '. . .', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
            } else {
                setDisplayedPages([1, '. . .', currentPage - 1, currentPage, currentPage + 1, '. . .', totalPages]);
            }
        }
    }, [currentPage, totalPages]);
    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const html = draftToHtml(rawContentState);
        console.log("editorState", html)
        setPlanFormData({
            ...planFormData,
            features: html

        });
    };
    return (
        <>
            {!showAddPlan ? (<div className="onetap_conn_users_tab_content_OTC_Admin">
                <div className="plan-search-and-status">
                    <div className="plan-search-container">
                        <input
                            style={{ paddingRight: "50px" }}
                            className="plan-search-input"
                            type="text"
                            name="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <img className='plan-search-icon' src={searchIcon} alt="" />
                    </div>
                    <div className="plan-status-dropdown-div">
                        <select
                            name="typeFilter"
                            id="typeFilter"
                            className="pc-status-dropdown"
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                        >
                            <option value="Type">Type</option>
                            <option value="Professional">Professional</option>
                            <option value="Team">Team</option>
                        </select>
                        <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                    </div>
                    <div className="plan-status-dropdown-div">
                        <select
                            name="statusFilter"
                            id="statusFilter"
                            className="pc-status-dropdown"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <option value="Status">Status</option>
                            <option value="Published">Published</option>
                            <option value="Draft">Draft</option>
                            <option value="Archived">Archived</option>
                        </select>
                        <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                    </div>
                </div>
                <button className="clear-fillter" style={{ marginTop: "10px", display: (searchQuery || typeFilter !== "Type" || statusFilter !== "Status") ? 'block' : 'none' }} onClick={clearFilters}>
                    Clear filters
                </button>
                <div className="pc-categories-main-section">
                    <div className="plan-category-title">
                        <div className="">
                            <span></span>
                            <div className="product-details">
                                <span className="product-name-text"></span>
                            </div>
                        </div>
                        <span className='pc-category-title-text'>Type</span>
                        <span className='pc-category-title-text'>Visibility</span>
                        <span className='pc-category-title-text'>Active subscribers</span>
                        <span className='pc-category-title-text'>Status</span>
                    </div>
                    <div className="pc-category-card-section">
                        {loading ? <Loader /> : paginatedData?.length > 0 ? paginatedData?.map((plans) => (
                            <div onClick={() => { handleEditPlan(plans); setFieldErrors({}); handleEditPlanOpen() }} className="plan-category-cards">
                                <div className="pc-img-and-text-div">
                                    <div className="pc-img-div">
                                        {plans.image !== '' ? (<img className="pc-img" src={`${url}/admin/plan/img/${plans.image}`} alt="" />) : (<img className="pc-img" src={Noimagefound} alt="" />)}

                                    </div>
                                    <div style={{ cursor: "pointer" }} className="product-details">
                                        <span className="product-name-text">{plans.PublicPlanName}</span>
                                        <span style={{ fontSize: "12px", color: "#E65925" }} className="product-name-text">{plans.InternalPlanName}</span>
                                    </div>
                                </div>

                                <span className="pc-category-slug-text" >{plans.planType} </span>
                                <span className={`status-text-smart-acc`}> {plans.Visibility} </span>
                                <span className={`status-text-smart-acc`}> {"583"} </span>
                                <span className={`status-text-smart-acc ${plans.status === 'Published' ? 'published-text' : plans.status === 'Archived' ? 'archived-text' : ''}`}> {plans.status} </span>
                            </div>
                        )) :
                            <div style={{ marginTop: "10px", marginLeft: "20px" }} className="no-teams-message">
                                No Plans available.
                            </div>
                        }

                    </div>
                    {
                        totalPages > 1 && (
                            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {
                                    currentPage !== 1 ?
                                        <ArrowBackIosNewIcon onClick={() => handlePageChange(currentPage - 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                        :
                                        <ArrowBackIosNewIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />

                                }
                                {displayedPages.map((page, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(page)}
                                        style={{
                                            backgroundColor: currentPage === page ? '#e65925' : 'transparent',
                                            color: currentPage === page ? 'white' : 'black',
                                            border: 'none',
                                            borderRadius: '4px',
                                            padding: '4px 20px',
                                            margin: '2px',
                                        }}

                                    >
                                        {page}
                                    </button>)
                                )}
                                {
                                    currentPage !== totalPages ?
                                        <ArrowForwardIosIcon onClick={() => handlePageChange(currentPage + 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                        :
                                        <ArrowForwardIosIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />
                                }

                            </div>
                        )
                    }

                </div>
            </div>
            ) :
                (<>
                    <div className="w-100  d-flex flex-row-reverse gap-3 mb-4 plan-btn-top">

                        <div class="dropdown">
                            <button class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: '#000', color: '#fff', }}>
                                <span className='me-5'>Actions</span>
                            </button>
                            <div onClick={() => handleWarningOpenModal("Archived")} class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ left: 'unset' }}>
                                <span style={{ paddingLeft: "16px" }}>Archive</span>
                            </div>
                        </div>
                        {selectedPCId && selectedPCId.status !== 'Draft' ? (<button
                            disabled={planFormData.status === 'Draft'}
                            className="btn px-3 py-2 text-white"
                            onClick={() => { handleCreatePlan() }}
                            style={{ background: '#E65925', width: '15%' }}
                        >
                            Update
                        </button>) : (<button
                            disabled={planFormData.status === 'Draft'}
                            className="btn px-3 py-2 text-white"
                            onClick={() => { handleCreatePlan() }}
                            style={{ background: '#48AF25', width: '15%' }}
                        >
                            {planFormData.status === 'Archived' ? 'Archived' : 'Publish'}
                        </button>)}

                        <button
                            onClick={handleCreatePlan}
                            disabled={planFormData.status !== 'Draft'}
                            className="btn px-3 py-2 text-white"
                            style={{
                                background: planFormData.status !== 'Draft' ? '#8A8A8A' : 'black',
                                width: '15%',
                            }}
                        >
                            Save draft
                        </button>

                    </div>
                    <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
                        <div className="col" style={{ padding: "0" }}>
                            <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                                <h5>Internal plan’s name*</h5>
                                <input className="w-100 border-0 p-2" placeholder='' type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='InternalPlanName' value={planFormData.InternalPlanName}
                                    onChange={handleChange} />
                                {fieldErrors.InternalPlanName && <div className="error-message">{fieldErrors.InternalPlanName}</div>}
                            </div>
                            <div className="bg-white mt-2 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Plan Public Name*</h5>
                                <input className="w-100 border-0 p-2" placeholder='' type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='PublicPlanName' value={planFormData.PublicPlanName}
                                    onChange={(e) => {
                                        if (!isPermalinkEditable) {

                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setPlanFormData({
                                                ...planFormData,
                                                PublicPlanName: e.target.value,
                                                CustomPermalink: slug,
                                            });
                                            setFieldErrors({
                                                ...fieldErrors,
                                                PublicPlanName: '',
                                                CustomPermalink: ""
                                            });
                                        } else {
                                            setPlanFormData({
                                                ...planFormData,
                                                PublicPlanName: e.target.value,
                                            });
                                            setFieldErrors({
                                                ...fieldErrors,
                                                PublicPlanName: '',
                                            });
                                        }
                                    }} />
                                {fieldErrors.PublicPlanName && <div className="error-message">{fieldErrors.PublicPlanName}</div>}

                            </div>


                            <div className="bg-white mt-2 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Permalink*</h5>

                                {!isPermalinkEditable ? (<div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input
                                        type="text"
                                        name="PublicPlanName"
                                        value={planFormData.CustomPermalink}
                                        onChange={handleChange}
                                        readOnly={!isPermalinkEditable}
                                        className="w-100 border-0 me-2"
                                        style={{ background: "#f2f2f2" }}
                                    />
                                    <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(!isPermalinkEditable)} role="button">Edit</span>
                                </div>) : (<div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input
                                        type="text"
                                        name="permalink"
                                        value={planFormData.CustomPermalink}
                                        onChange={handlePermalinkChange}
                                        readOnly={!isPermalinkEditable}
                                        className="w-100 border-0 me-2"
                                        style={{ background: "#f2f2f2" }}
                                    />
                                    <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(!isPermalinkEditable)} role="button">Edit</span>

                                </div>)}
                                {fieldErrors.CustomPermalink && <div className="error-message">{fieldErrors.CustomPermalink}</div>}
                            </div>

                            <div className=" mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }} >

                                        <h5 className='add-pc-description-text'>Plan Description</h5>
                                        <textarea
                                            name="description"
                                            value={planFormData.description}
                                            onChange={handleChange} className='add-pc-textarea w-100' id="" cols="30" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Plan Features</h5>
                            
                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '' }}
                                    name="features"
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                />
                            </div>

                            <div className=" mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }} >
                                        <div className="d-flex align-items-center">
                                            <h5>Plan Type*</h5>
                                            <div className={`add-plan-type-dropdown-div ${fieldErrors.planType ? 'error-border' : ''}`}>
                                                <select
                                                    name="planType"
                                                    value={planData.planType}
                                                    onChange={handlePlanDataChange}
                                                    id="status"
                                                    className={`pc-status-dropdown ${planData.error ? 'error-border' : ''}`}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Free">Free</option>
                                                    <option value="Professional">Individual</option>
                                                    <option value="Team">Team</option>
                                                    <option value="Enterprise">Enterprise</option>
                                                </select>
                                                <img className="pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                            </div>
                                        </div>
                                        <div className="planData-form">

                                            {
                                                planData.planType === 'Free' ? (
                                                    <span></span>
                                                ) : (
                                                    <div className="plan-yearly-users-div">
                                                        <span style={{ display: planData.planType === 'Team' || planData.planType === 'Enterprise' ? 'block' : 'none' }} className='plan-number-of-user-text'>Number of users included in default price</span>
                                                        <input onChange={handlePlanDataChange} value={planData.users} min={1} className={`w-100 border-0 p-2 no-spinners ${fieldErrors.users ? 'error-border' : ''}`} placeholder='' type="number" onKeyPress={(e) => {
                                                            if (e.key === 'e' || e.key === 'E') {
                                                                e.preventDefault();
                                                            }
                                                        }} style={{ display: planData.planType === 'Team' || planData.planType === 'Enterprise' ? 'block' : 'none', borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='users' />
                                                        <div className="">
                                                            <div className="plan-monthly-section mt-1 row">
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>Monthly price per user*</span>
                                                                    <input onClick={handleInputClick} onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault(); } }} onChange={handlePlanDataChange} value={planData.monthlyPrice_perUser} className={`w-100 border-0 p-2 no-spinners ${fieldErrors.monthlyPrice_perUser ? 'error-border' : ''}`} placeholder='' type="number" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='monthlyPrice_perUser' />
                                                                </div>
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>One time fee for monthly plan (ex: signup fee)*</span>
                                                                    <input onClick={handleInputClick} onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault(); } }} onChange={handlePlanDataChange} value={planData.monthly_fee} className={`w-100 border-0 p-2 no-spinners `} placeholder='' type="number" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='monthly_fee' />
                                                                </div>
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>SKU*</span>
                                                                    <input onChange={handlePlanDataChange} value={planData.monthly_sku} className={`w-100 border-0 p-2 ${fieldErrors.monthly_sku ? 'error-border' : ''}`} placeholder='' type="text" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='monthly_sku' />
                                                                </div>
                                                            </div>
                                                            <div className="plan-monthly-section mt-1 row">
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>Yearly price per user per month*</span>
                                                                    <input onClick={handleInputClick} onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault(); } }} onChange={handlePlanDataChange} value={planData.yearlyPrice_perUser} className={`w-100 border-0 p-2 no-spinners ${fieldErrors.yearlyPrice_perUser ? 'error-border' : ''}`} placeholder='' type="number" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='yearlyPrice_perUser' />
                                                                </div>
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>One time fee for yearly plan (ex: signup fee)*</span>
                                                                    <input onClick={handleInputClick} onKeyPress={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault(); } }} onChange={handlePlanDataChange} value={planData.yearly_fee} className={`w-100 border-0 p-2 no-spinners`} placeholder='' type="number" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='yearly_fee' />
                                                                </div>
                                                                <div className="plan-yearly-users-div col-md-4">
                                                                    <span className='plan-number-of-user-text'>SKU*</span>
                                                                    <input onChange={handlePlanDataChange} value={planData.yearly_sku} className={`w-100 border-0 p-2 ${fieldErrors.yearly_sku ? 'error-border' : ''}`} placeholder='' type="text" style={{ borderRadius: "4px", background: "#f2f2f2", marginTop: "5px" }} name='yearly_sku' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className=" mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }} >
                                        <div className="plan-include-user-header">
                                            <span className='add-pc-description-text'>Smart Accessories Selection</span>
                                            <div style={{ cursor: "pointer" }} onClick={setOpenModal} className="plan-plus-btn-group">
                                                <img style={{ marginRight: "12px" }} src={plusIconWithBG} alt="" />
                                                <span >Add product</span>
                                            </div>
                                        </div>
                                        {UpdatedProducts?.length > 0 && <span className='planitem-text'>Items</span>}
                                        {UpdatedProducts && UpdatedProducts?.map((e, index) => (
                                            <div className='main-paln-product-card'>
                                                <div className="plan-Product-cards">
                                                    <div className="pc-img-and-text-div">
                                                        <div className="plan-pc-img-div">
                                                            <img className="pc-img" src={`${url}/product/img/${e?.image[0]?.url}`} alt="" />
                                                        </div>
                                                        <div style={{ cursor: "pointer" }} className="product-details">
                                                            <span className="plan-commman-name-text">{e.hasVariations === false ? e.name : e.Type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className='plan-canceliconOrange' onClick={() => handleCancelIconClickProduct(index)} src={canceliconOrange} alt="" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }}>

                                        <div className="plan-include-user-header">
                                            <span className='add-pc-description-text'>Add-ons Selection</span>
                                            <div style={{ cursor: "pointer" }} onClick={setOpenModal2} className="plan-plus-btn-group">
                                                <img style={{ marginRight: "12px" }} src={plusIconWithBG} alt="" />
                                                <span>Add add-on</span>
                                            </div>
                                        </div>
                                        {UpdatedAddons?.length > 0 && <span className='planitem-text'>Items</span>}

                                        {UpdatedAddons && UpdatedAddons?.map((e, index) => (
                                            <div className='main-paln-product-card'>
                                                <div className="plan-Product-cards">
                                                    <div className="pc-img-and-text-div">
                                                        <div className="plan-pc-img-div">
                                                            <img className="pc-img" src={`${url}/addons/img/${e.image}`} alt="" />
                                                        </div>
                                                        <div style={{ cursor: "pointer" }} className="product-details">
                                                            <span className="plan-commman-name-text">{e.publicname}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img className='plan-canceliconOrange' onClick={() => handleCancelIconClickAddon(index)} src={canceliconOrange} alt="" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>



                            <div className=" mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }} >

                                        <div className="plan-order-price-header">
                                            <span className='plan-header-text'>Plan total preview</span>
                                            <span className='plan-header-text status-text-smart-acc'>Monthly</span>
                                            <span className=' plan-header-text plan-yearly-text'>Yearly</span>

                                        </div>
                                        <div className="plan-order-price-header plan-monthly-order">
                                            <span className='plan-com-text'>Total today:</span>
                                            <span className='plan-com-text status-text-smart-acc'>{monthlyTotalPrice ? monthlyTotalPrice : 0}</span>
                                            <span className=' plan-com-text plan-yearly-text '>{YearlyTotalPrice ? YearlyTotalPrice : 0}</span>

                                        </div>
                                        <div className="plan-order-price-header plan-yearly-order">
                                            <span className='plan-com-text'>Recurring total:</span>
                                            <span className='plan-com-text status-text-smart-acc'>{monthlyRecurringTotalPrice ? monthlyRecurringTotalPrice : 0}</span>
                                            <span className='plan-com-text plan-yearly-text'>{YearlyRecurringTotalPrice ? YearlyRecurringTotalPrice : 0}</span>

                                        </div>

                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col-4">
                            <div className="bg-white p-4" style={{ borderRadius: '4px' }}>
                                <h6 className="border-bottom pb-2 pc-Product information-text">Product information</h6>
                                <div className="gap-3 d-flex flex-column">
                                    <div style={{ position: "relative" }} className="mt-2 ">
                                        <span>Status*</span>
                                        <div>
                                            <select onChange={handleChange} value={planFormData.status || 'Published'} name='status' className={`pc-status-dropdown ${planFormData.status === 'Published' ? 'published-background' : ''}`} >
                                                <option className={`${planFormData.status === 'Published' ? 'pc-published-text' : ''}`} value="Published">Published</option>
                                                <option value="Draft">Draft</option>
                                                <option value="Archived">Archived</option>
                                            </select>
                                        </div>
                                        <img className="add-pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                    </div>
                                    <div style={{ position: "relative" }} className=''>
                                        <span>Visibility*</span>
                                        <div>

                                            <select onChange={handleChange} name='Visibility' value={planFormData.Visibility} className="pc-status-dropdown" style={{ backgroundColor: '#f2f2f2' }}>
                                                <option value="Public">Public</option>
                                                <option value="Hidden">Hidden</option>
                                            </select>
                                        </div>

                                        <img className="add-pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                    </div>
                                    <div>
                                        <span>Published date</span>
                                        <input readOnly value={currentDateTime} className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} />
                                    </div>
                                </div>
                            </div>




                            <div className="mt-2">
                                <div className="bg-white p-4 gap-2 d-flex flex-column " style={{ borderRadius: '8px' }}>
                                    <div>
                                        <h5>Featured Image</h5>
                                        {selectedImage ? (
                                            <label style={{ width: "100%" }} htmlFor="imageUpload" className="upload-label">
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    name='productcategoryimage'
                                                    style={{ display: 'none' }}
                                                    onChange={handleImageUpload}
                                                />
                                                <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '0' }}>
                                                    <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                                                </div>
                                            </label>
                                        ) : (
                                            <label style={{ width: "100%" }} className="upload-label" htmlFor="imageUpload">
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    name='productcategoryimage'
                                                    style={{ display: 'none' }}
                                                    onChange={handleImageUpload}
                                                />
                                                <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '50px' }}>
                                                    <div style={{ cursor: "pointer" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
                                                            <path
                                                                d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                                                                fill="#838383"
                                                            />
                                                        </svg>
                                                        <p>Upload</p>
                                                    </div>
                                                </div>
                                            </label>
                                        )}
                                    </div>
                                    {selectedImage && (<div className='pc-remove-img' onClick={() => setSelectedImage('')}>Remove image</div>)}
                                    <div>
                                        <span>Image name</span>
                                        <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='imageName' value={planFormData.imageName} onChange={handleChange} />
                                    </div>
                                    <div>
                                        <span>Alt text</span>
                                        <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='altText' value={planFormData.altText} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                <h5>Activity log</h5>
                                <textarea className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='activitylog' value={planFormData.activitylog}
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </>
                )}

            <Modal
                open={openModal}
                onClose={() => { setOpenModal(false); setSelectedProducts(null) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Smart Accessories
                        </h2>
                        <table class="table plan_product_table">
                            <thead>
                                <tr >
                                    <th scope="col" className='w-25' style={{ textAlign: 'left' }} >
                                    </th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {filteredProductsExcludingUpdated?.length > 0 ? filteredProductsExcludingUpdated?.map((val, index) =>
                                (
                                    <tr className='mt-3 product_row' onClick={() => handleSelectProduct(val)} key={index}>
                                        <td className={`  w-50  plan-product-row plan-left-td ${selectedProducts?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} role="button" scope="row" style={{ textAlign: 'left' }} >
                                            <img alt='' className="img-fluid me-2" width={40} src={`${url}/product/img/${val?.image[0]?.url}`} />
                                            {val.hasVariations === false ? val.name : val.Type}
                                        </td>
                                        {val.hasVariations ? (
                                            <td className={`  plan-product-row ${selectedProducts?.some(
                                                (item) => item._id === val._id
                                            )
                                                ? "selected"
                                                : ""
                                                }`}> {val.variations.length > 0 ? (
                                                    `${Math.min(...val.variations.map((variation) => val.isOnSale ? (variation.saleprice || variation.price) : variation.price))} - ${Math.max(...val.variations.map((variation) => val.isOnSale ? (variation.saleprice || variation.price) : variation.price))}`
                                                ) : ('N/A')} </td>
                                        ) : (
                                            <td className={`  plan-product-row  ${selectedProducts?.some(
                                                (item) => item._id === val._id
                                            )
                                                ? "selected"
                                                : ""
                                                }`}> {val.isOnSale ? val.saleprice : val.price}</td>
                                        )}

                                        <td className={` plan-product-row  ${selectedProducts?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} >  {val.hasVariations === false ? val.category?.name : products?.find(product => product._id === val.productId)?.category?.name}</td>

                                        <td style={{ position: "relative" }} className={` plan-product-row status-cell plan-right-td ${getStatusColorClass(val.status)}  ${selectedProducts?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} > <span>{val.status} </span>  </td>
                                    </tr>
                                )) : <div className="no-products-message">No smart-accessories available.</div>}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-end">
                            <button
                                className="Modal-btn plan-cancel-modal-btn w-25"
                                onClick={() => { setOpenModal(false); setSelectedProducts(null) }}
                            >
                                Cancel
                            </button>
                            <button
                                className="Modal-btn first-modal-btn w-25"
                                onClick={() => { handleupdatePro(); setOpenModal(false); }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openModal2}
                onClose={() => { setOpenModal2(false); setSelectedProducts(null) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Add-ons
                        </h2>
                        <table class="table plan_product_table">
                            <thead>
                                <tr >
                                    <th scope="col" className='w-25' style={{ textAlign: 'left' }} >
                                    </th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {filteredAddonsExcludingUpdated?.length > 0 ? filteredAddonsExcludingUpdated?.map((val, index) =>
                                (
                                    <tr className='mt-3 product_row' onClick={() => handleSelectAddons(val)} key={index}>
                                        <td className={`w-50  plan-product-row plan-left-td ${selectedAddons?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} role="button" scope="row" style={{ textAlign: 'left' }} >
                                            <img alt='' className="img-fluid me-2" width={40} src={`${url}/addons/img/${val?.image}`} />
                                            {val.publicname}
                                        </td>
                                        <td style={{ position: "relative" }} className={` plan-product-row ${selectedAddons?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} > <span>{val.Addonspaymentdata.price
                                            }  </span>  </td>
                                        <td style={{ position: "relative" }} className={` plan-product-row status-cell plan-right-td ${getStatusColorClass(val.status)}  ${selectedAddons?.some(
                                            (item) => item._id === val._id
                                        )
                                            ? "selected"
                                            : ""
                                            }`} > <span>{val.status} </span>  </td>
                                    </tr>
                                )) : <div className="no-products-message">No add-ons available.</div>}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-end">
                            <button
                                className="Modal-btn plan-cancel-modal-btn w-25"
                                onClick={() => { setOpenModal2(false); setSelectedProducts(null) }}
                            >
                                Cancel
                            </button>
                            <button
                                className="Modal-btn first-modal-btn w-25"
                                onClick={() => { handleupdateAddons(); setOpenModal2(false); }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={warningModalStyle}>
                    <div className="video-Modal-section">
                        <h2 className="warning-header">
                            Warning
                        </h2>
                        <span className="warning-text-smart-acc">Are you sure you want to Archive this product?</span>


                        <div className="video-Modal-btn-group mt-4" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={() => { handleCreatePlan('Archived'); setOpenWarningModal(false) }}>Archive</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
                        </div>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Plan
