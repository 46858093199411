import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
    getProducts, fetchCart,
} from "../../actions/userAction/userAction";
import Loader from "../../components/Layout/Loader/Loader";
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function ProductsInfo({ showCreateProduct, onProductNameClick, handleProductClick }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
    const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
    const [editorState4, setEditorState4] = useState(EditorState.createEmpty());
    const warningModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "#FDE9E9",
        p: 3,
        outline: "none",
        border: "1px solid E62525",
        borderRadius: "10px",
        // border: "#E62525"
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: "10px",
        overflowY: "scroll",
        maxHeight: "80%",
        // scrollbarWidth: "none",  // For Firefox
        // "&::-webkit-scrollbar": {
        //   display: "none",  // For Chrome, Safari, and Opera
        // },

        // scrollbarWidth: "thin",  // For Firefox
        "&::-webkit-scrollbar": {
            width: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "lightgray",
            borderRadius: "0 10px 10px 0",
            marginRight: "10px",  // Margin on the right side of the scrollbar
        },
        // "&::-webkit-scrollbar-track": {
        //   backgroundColor: "transparent",
        // },
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const mediaFileInputRef = useRef(null);
    const featuredImageFileInputRef = useRef(null);
    const variationFileInputRef = useRef(null);
    const url = process.env.REACT_APP_URL;
    const { isOtcAdminAuthenticated, adminuser, Adminerror, adminloading } = useSelector((state) => state.OtcAdminuser);
    const [shouldPermalinkSlugEdit, setShouldPermalinkSlugEdit] = useState(true)
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("Category");
    const [selectedStatus, setSelectedStatus] = useState("Status");
    const [selectedProduct, setSelectedProduct] = useState("Smart Accessory");
    const [isPermalinkEditable, setIsPermalinkEditable] = useState(false);
    const [showAddCatgory, setShowAddCatgory] = useState(false);
    const [CategoryName, setCategoryName] = useState('')
    const [allCategories, setAllCategories] = useState([])
    const [parentCategory, setParentCategory] = useState('Parent category')
    const [selectedFeaturedImage, setSelectedFeaturedImage] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVariationImages, setSelectedVariationImages] = useState([]);
    const [selectedId, setSelectedId] = useState("")
    const [openModal, setOpenModal] = React.useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isAddVariationClicked, setIsAddVariationClicked] = useState(false);
    const [fieldsEmpty, setFieldsEmpty] = useState(false);
    const [categoryNameEmpty, setCategoryNameEmpty] = useState(false)
    const [displayVariation, setDisplayVariation] = useState('');
    const [openWarningModal, setOpenWarningModal] = useState();
    const [isPermalinkUnique, setIsPermalinkUnique] = useState(true);
    const [isSkuUnique, setIsSkuUnique] = useState(true);
    const [categoryAlreadyExistsError, setCategoryAlreadyExistsError] = useState(false);
    const [isSpecialCharacterEntered, setIsSpecialCharacterEntered] = useState(false);
    const [isSpecialCharacterEnteredForFeatured, setIsSpecialCharacterEnteredForFeatured] = useState(false);
    const [isSpecialCharacterEnteredVarAlt, setIsSpecialCharacterEnteredVarAlt] = useState(false);
    const [isSpecialCharacterEnteredAlt, setIsSpecialCharacterEnteredAlt] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [invalidShippingDate, setInvalidShippingDate] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [invalidFileTypeForVar, setInvalidFileTypeForVar] = useState(false);
    const [saleError, setsaleError] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [variationData, setVariationData] = useState(
        [
            {
                Type: "",
                image: [{
                    url: "",
                    alt: "",
                    name: "",
                    file: "",
                }],
                stockStatus: "In Stock",
                sku: "",
                quantity: '',
                price: '',
                saleprice: '',
                LinkedCoupons: [],
                inventory: "",
                shippingDate: "",
                height: '',
                width: '',
                length: '',
                weight: '',
                CustomPermalink: ""
            }
        ]
    );
    const InitialVariation =

    {
        Type: "",
        image: [{
            url: "",
            alt: "",
            name: "",
            file: "",
        }],
        stockStatus: "In Stock",
        sku: "",
        quantity: '',
        price: '',
        saleprice: '',
        LinkedCoupons: [],
        inventory: "",
        shippingDate: "",
        height: '',
        width: '',
        length: '',
        weight: '',
        CustomPermalink: ""
    }
    const [productData, setProductData] = useState({
        name: '',
        status: 'Published',
        image: [
            {
                url: '',    // URL of the image
                alt: '',    // Alt text for the image
                name: ''    // Name of the image
            }
        ],
        visibility: "Public",
        media: [],
        sku: '',
        stockStatus: "In Stock", // Default to "In Stock" or "Out of Stock" as needed
        quantity: '',
        price: null,
        saleprice: null,
        category: null, // You can set the initial category as needed
        Tags: [],
        inventory: '',
        isFeatured: false,
        LinkedCoupons: [],
        CustomPermalink: '',
        producttype: "Simple", // Set an initial product type
        hasVariations: false,
        shortDescription: '',
        description: '',
        isActive: false,
        CompatibilityInformation: '',
        ShippingAndReturnInformation: '',
        shippingDate: '',
        height: '',
        width: '',
        length: '',
        weight: '',
        publishedBy: adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : '',
        createdAt: '',
        isOnSale: false,
        variations: [],
    });
    const [selecteddCategory, setSelecteddCategory] = useState(productData.category);
    const { products, loading, message, error } = useSelector(
        (state) => state.products
    );

    // Filter out categories with a parentCategory
    const filteredCategories = allCategories.filter((category) => !category.parentCategory);
    const handleWarningOpenModal = () => { setOpenWarningModal(true) }
    const handleCloseWarningdModal = () => { setOpenWarningModal(false); handlePublish("Archived") };
    const handleAddVariationClick = () => {
        setIsAddVariationClicked(true);


        // Validate that all existing variations have mandatory fields filled
        const allVariationsValid = variationData.every((existingVariation) => {
            return (
                existingVariation.Type
                &&
                existingVariation.price &&
                existingVariation.sku &&
                existingVariation.image[0].name &&
                existingVariation.image[0].alt
            );
        });

        if (allVariationsValid) {
            // Check if all variations have unique SKUs
            const allVariationsUnique = variationData.every((existingVariation, index) => {
                // Check if the current SKU is unique among existing variations
                const currentSKU = existingVariation.sku;
                return variationData.findIndex((variation, i) => i !== index && variation.sku === currentSKU) === -1;
            });
    
            if (allVariationsUnique) {
                setIsAddVariationClicked(false);
                setVariationData([...variationData, { ...InitialVariation }]);
            } else {
                setIsSkuUnique(false);
                console.log('here')
                return;
            }
         
        }else return

        // if (allVariationsValid && allVariationsUnique ) {
        //     setIsAddVariationClicked(false);
        //     setVariationData([...variationData, { ...InitialVariation }]);

        // } else return

    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "price") {
            setProductData({
                ...productData,
                [name]: value,
                saleprice: '',
            });
        }
        else {
            setProductData({
                ...productData,
                [name]: value,
            });
        }

        if (name === 'saleprice' && parseFloat(value) > parseFloat(productData.price)) {
            setsaleError(true)
        } else setsaleError(false)
    };

    const handleVariationChange = (e, index) => {
        const { name, value } = e.target;

        // Check if the date is not in the past for "shippingDate"
        if (name === "shippingDate" && new Date(value) < new Date(new Date().setHours(0, 0, 0, 0))) {
            setInvalidDate(true)

        } else setInvalidDate(false)

        const updatedVariations = [...variationData];
        if (name === "price") {
            updatedVariations[index] = {
                ...updatedVariations[index],
                [name]: value,
                saleprice: '',
            };
        } else {
            updatedVariations[index] = {
                ...updatedVariations[index],
                [name]: value,
            };
        }
        setVariationData(updatedVariations);

        // Ensure saleprice is smaller than price
        if (name === "saleprice" && parseFloat(value) > parseFloat(updatedVariations[index].price)) {
            setsaleError(true)

        } else setsaleError(false)
    };

    // Define a function to fetch the data
    const fetchProductCategories = async () => {
        // setLoading(true)
        try {
            const response = await axios.get(`${url}/admin/productCategory/fetch`);
            // Assuming the response contains an array of categories
            const smartAccessoriesCategories = response.data.categories.filter(category => category.categoryType === 'Smart-accessories');

            // Set only the categories with categoryType: 'Smart-accessories'
            setAllCategories(smartAccessoriesCategories);
            // setLoading(false)
            // Create the hierarchical structure
            createCategoryTree(smartAccessoriesCategories);
        } catch (error) {
            ;
        }
    };
    useEffect(() => {
        fetchProductCategories()
    }, [])
    // Use useEffect to set the initial value of showCreateProduct


    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [products])

    const getUniqueCategories = () => {
        const uniqueCategory = Array.from(new Set(products?.map((val) => val?.category?.name)));
        return uniqueCategory?.filter(
            (category) => category !== undefined && category !== null && category !== ""
        );
    };

    const getUniqueProducts = () => {
        const uniqueProducts = Array.from(new Set(products?.map((val) => val?.name)));
        return uniqueProducts?.filter(
            (product, index, self) => self.indexOf(product) === index
        );
    };
    // Filter the products based on selected filters
    const filteredProducts = products?.filter((product) => {
        const matchesSearch = product?.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesCategory = selectedCategory === "Category" || product?.category?.name.toLowerCase() === selectedCategory.toLowerCase();
        const matchesStatus = selectedStatus === "Status" || product?.status.toLowerCase() === selectedStatus.toLowerCase();
        const matchesProduct = selectedProduct === "Smart Accessory" || product?.name.toLowerCase() === selectedProduct.toLowerCase();
        return matchesSearch && matchesCategory && matchesStatus && matchesProduct;
    });

    // Call this function when the user clicks the "Add" button
    const handleAddCategory = () => {
        // Get the category name and parent category from your input fields or state
        const categoryName = CategoryName.trim()/* Get category name from your input */;
        if (categoryName.trim() === "") { setCategoryNameEmpty(true); setCategoryName(''); return }
        const selectedParentCategory = parentCategory === "Parent category" ? null : parentCategory /* Get selected parent category from your input or state */;

        // Check if the category already exists
        if (allCategories?.some(category => category.name === categoryName.trim())) {
            setCategoryAlreadyExistsError(true);
            return;
        }

        // Call the addCategory function to send the data to the backend
        addCategory(categoryName.trim(), selectedParentCategory);
        setCategoryName('')
        setParentCategory('')
        setShowAddCatgory(false)
        setCategoryNameEmpty(false)
        setCategoryAlreadyExistsError(false)
    };
    const addCategory = async (name, parentCategory) => {
        try {
            const slug = name
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, '');
            const response = await axios.post(`${url}/admin/createproductCategory`, {
                name: name,
                parentCategory: parentCategory,
                categoryType: "Smart-accessories",
                CustomPermalink: slug
                // Add other data as needed
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            // Handle the response as needed (e.g., show a success message).
            fetchProductCategories()
        } catch (error) {
            // Handle the error (e.g., show an error message).
        }
    };

    // Create a function to create a hierarchical structure of categories
    const createCategoryTree = (categories) => {
        const categoryMap = {}; // Create a map to store categories by their _id

        // First, create a map of categories using their _id as the key
        categories.forEach((category) => {
            categoryMap[category._id] = { ...category, children: [] };
        });

        // Then, organize categories into a tree structure
        const categoryTree = [];

        categories.forEach((category) => {
            if (category.parentCategory) {
                // If a category has a parentCategory, add it as a child to its parent
                categoryMap[category.parentCategory].children.push(categoryMap[category._id]);
            } else {
                // If it doesn't have a parentCategory, it's a root-level category
                categoryTree.push(categoryMap[category._id]);
            }
        });

        return categoryTree;
    };

    // Call the function to create the hierarchical structure
    const categoryTree = createCategoryTree(allCategories);

    // Function to render categories and their children
    const renderCategories = (categories, level = 0) => {
        return categories.map((category) => (
            <div key={category._id} style={{ marginLeft: `${level * 20}px` }}>
                <div class="form-check d-flex align-items-center">
                    <input class="form-check-input" type="checkbox" name="category" value={category._id} checked={selecteddCategory === category._id} id="category" onChange={(e) => {
                        const value = e.target.value;
                        const isChecked = e.target.checked;

                        if (isChecked) {
                            setSelecteddCategory(value);
                        } else {
                            setSelecteddCategory(null);
                        }

                        handleChange(e);
                    }}
                    />
                    <label class="form-check-label ms-2 text-black" for="category" style={{ textTransform: "capitalize" }}>
                        {category.name}
                    </label>
                </div>
                {category.children.length > 0 && renderCategories(category.children, level + 1)}
            </div>
        ));
    };

    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        const formatDateTime = () => {
            const now = new Date();
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            };
            const formattedDate = now.toLocaleDateString('en-US', options);
            const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
            const hour = now.getHours() % 12 || 12;
            const formattedTime = `${hour}:${now.getMinutes()} ${amPm}`;
            setCurrentDateTime(`${formattedDate} @ ${formattedTime}`);
        };

        formatDateTime(); // Call the function when the component mounts
        const interval = setInterval(formatDateTime, 60000);
        return () => {
            clearInterval(interval); // Cleanup the interval on component unmount
        };
    }, []);


    // const handleImageUpload = (isFeatured) => {
    //     if (isFeatured) {
    //         featuredImageFileInputRef.current.value = null; // Reset the featured file input
    //         featuredImageFileInputRef.current.click();
    //     } else {
    //         mediaFileInputRef.current.value = null; // Reset the media file input
    //         mediaFileInputRef.current.click();
    //     }
    // };
    const handleImageUpload = (inputRef) => {
        if (inputRef) {
            inputRef.current.value = null; // Reset the specified file input
            inputRef.current.click();
        }
    };

    // const handleImageChange = (e, isFeatured) => {
    //     const files = Array.from(e.target.files);
    //     if (isFeatured) {
    //         setSelectedFeaturedImage(files[0]);
    //     } else {
    //         setSelectedImages((prevImages) => [...prevImages, ...files]);
    //     }
    // };
    const handleImageChange = (e, inputRef) => {
        const files = Array.from(e.target.files);


        // Check if inputRef is featuredImageFileInputRef and if all selected files have the correct type
        if (inputRef === featuredImageFileInputRef) {
            const isValidFileType = files.every(file => file.type.startsWith('image/'));
            if (!isValidFileType) {
                setInvalidFileType(true);

            } else {
                setInvalidFileType(false);
            }
        }

        if (inputRef === variationFileInputRef) {
            const isValidFileType = files.every(file => file.type.startsWith('image/'));
            if (!isValidFileType) {
                setInvalidFileTypeForVar(true);

            } else
                setInvalidFileTypeForVar(false);
        }

        if (inputRef === featuredImageFileInputRef) {
            setSelectedFeaturedImage(files[0]);
        } else if (inputRef === mediaFileInputRef) {
            setSelectedImages((prevImages) => [...prevImages, ...files]);
        } else if (inputRef === variationFileInputRef) {
            setSelectedVariationImages((prevImages) => [...prevImages, ...files]);
        }
        else {
            ;
        }
    };
    const removeFeaturedImage = () => {
        setInvalidFileType(false)
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        setSelectedFeaturedImage(null);
        setProductData({
            ...productData,
            image: [
                {
                    ...productData.image[0],
                    url: null,
                },
            ],
        });
    }
    const removeEventListener = (index) => {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
        setProductData((prevProductData) => ({
            ...prevProductData,
            media: updatedImages,
        }));
    }
    const removeVariationImage = (index) => {
        setInvalidFileTypeForVar(false)
        const updatedVariationData = [...variationData];
        // Check if the variation at the given index and its properties exist before assignment
        if (updatedVariationData[index] && updatedVariationData[index].image) {
            // Check if the image array and its first element exist before assigning null to the file property
            if (updatedVariationData[index].image[0]) {
                updatedVariationData[index].image[0].file = null;
                setVariationData(updatedVariationData);
            }
        }

    }
    useEffect(() => {
        if (!showCreateProduct) {
            dispatch(getProducts())
            setProductData({
                name: '',
                status: 'Published',
                image: [
                    {
                        url: '',    // URL of the image
                        alt: '',    // Alt text for the image
                        name: ''    // Name of the image
                    }
                ],
                visibility: "Public",
                media: [],
                sku: '',
                stockStatus: "In Stock", // Default to "In Stock" or "Out of Stock" as needed
                quantity: '',
                price: '',
                saleprice: '',
                category: null, // You can set the initial category as needed
                Tags: [],
                inventory: '',
                isFeatured: false,
                LinkedCoupons: [],
                CustomPermalink: '',
                producttype: "Simple", // Set an initial product type
                hasVariations: false,
                shortDescription: '',
                description: '',
                isActive: false,
                CompatibilityInformation: '',
                ShippingAndReturnInformation: '',
                shippingDate: '',
                height: '',
                width: '',
                length: '',
                weight: '',
                publishedBy: adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : '',
                createdAt: '',
                isOnSale: false,
                variations: [],
            })
            setVariationData(
                [
                    {
                        Type: "",
                        image: [{
                            url: "",
                            alt: "",
                            name: "",
                            file: "",
                        }],
                        stockStatus: "In Stock",
                        sku: "",
                        quantity: '',
                        price: '',
                        saleprice: '',
                        LinkedCoupons: [],
                        inventory: "",
                        shippingDate: "",
                        height: '',
                        width: '',
                        length: '',
                        weight: '',
                        CustomPermalink: ""
                    }
                ]
            )
            setSelectedFeaturedImage(null)
            setSelectedImages([])
            setSelecteddCategory('')
            setIsPermalinkUnique(true);
            setIsSkuUnique(true);
            setFieldsEmpty(false);
            setShouldPermalinkSlugEdit(true)
        }

    }, [showCreateProduct])

    const handlePublish = async (statusVar) => {
        setShouldPermalinkSlugEdit(true)
        if (productData.name.trim() === '' || !productData.CustomPermalink || productData.producttype === "Simple" && !productData.weight || !selectedFeaturedImage || !productData.image[0].name || !productData.image[0].alt || (productData.producttype === "Simple" && !productData.price) || (productData.producttype === "Simple" && !productData.sku) || categoryAlreadyExistsError === true || isSpecialCharacterEntered === true || isSpecialCharacterEnteredAlt === true || isSpecialCharacterEnteredForFeatured === true || isSpecialCharacterEnteredVarAlt === true || invalidFileTypeForVar === true || invalidFileType === true || saleError || (productData.isOnSale && (productData.producttype === "Simple" && !productData.saleprice))
            || (productData.isOnSale && productData.producttype === "Variation" && variationData?.some(variation => !variation.saleprice))
        ) {
            if (productData.name.trim() === '') {
                setProductData((productData) => ({
                    ...productData,
                    name: ''
                }))
            }
            setFieldsEmpty(true);
            return;
        }
        if (productData.saleprice > productData.price) {
            setsaleError(true)
            return
        } else setsaleError(false)

        const checkPermalinkUniqueness = (permalink, currentProductId) => {
            // Assume products is an array of product objects
            const isUnique = !products.some(product => product.CustomPermalink === permalink && product?._id !== currentProductId);
            return isUnique;
        };
        // Check if the permalink is unique, excluding the current product
        const isUnique = checkPermalinkUniqueness(productData.CustomPermalink, productData?._id);
        if (!isUnique) {
            setIsPermalinkUnique(false);
            return;
        }
        const checkSKUUniqueness = (PSKU, currentProductId) => {
            // Assume products is an array of product objects
            const isUnique = !products.some(product => {
                if (product._id === currentProductId) {
                    return false; // Skip current product
                }
                if (product.sku === PSKU) {
                    return true; // Found same SKU in main product
                }
                // Check SKUs in variations
                if (product.variations && product.variations.length > 0) {
                    return product.variations.some(variation => variation.sku === PSKU);
                }
                return false;
            });
            return isUnique;
        };
        if (productData.producttype === "Simple"){
 // Check if the permalink is unique, excluding the current product
 const isSKUUnique = checkSKUUniqueness(productData.sku, productData?._id);
 if (!isSKUUnique) {
     setIsSkuUnique(false);
     return;
 }
        }
       

        // Validate that all existing variations have mandatory fields filled
        const allVariationsValid = variationData.every((existingVariation) => {
            return (
                existingVariation.Type
                &&
                existingVariation.price &&
                existingVariation.sku &&
                existingVariation.weight &&
                existingVariation.image[0].name &&
                existingVariation.image[0].alt
            );
        });
        if (productData.producttype === "Variation" && !allVariationsValid) {
            setIsAddVariationClicked(true);

            return;
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        };
        const saveDraftImages = async () => {
            const formData = new FormData();

            // Append selectedImages
            selectedImages.forEach((file) => {
                if (file instanceof File) {
                    formData.append('image', file);
                    formData.append(`fileType`, 'media');
                }
            });

            // Append selectedFeaturedImage
            if (selectedFeaturedImage instanceof File) {
                formData.append("image", selectedFeaturedImage);
                formData.append('fileType', 'featuredimage');

            }

            variationData.forEach((variation, index) => {
                if (variation.image && variation.image[0].file instanceof File) {
                    formData.append(`image`, variation.image[0].file);
                    formData.append(`fileType`, `variationimage-${index}`);

                }
            });
            // API to save images/videos in the database
            const { data } = await axios.post(
                `${url}/admin/imageUpload?imageType=product&multiple=true`,
                formData,
                config
            );
            if (data.success) {


                let dataToSend;
                let productDataWithImage = { ...productData };
                let variationDataWithImage = [...variationData];
                data.imageNames.forEach((file) => {
                    const { name, fileType } = file;
                    if (fileType === "media") {
                        // productDataWithImage = { ...productData };
                        productDataWithImage?.media?.push(name.name);
                    } else if (fileType === "featuredimage") {
                        productDataWithImage = {
                            ...productData,
                            image: [{
                                ...productData.image[0],
                                url: name?.name
                            }],
                            media: [name?.name, ...productData?.media]
                        }
                    } else if (fileType?.startsWith("variationimage-")) {
                        const variationIndex = parseInt(fileType.replace('variationimage-', ''));
                        // Assign the variation image name to the appropriate variable in your variation data.
                        if (variationIndex >= 0 && variationIndex < variationDataWithImage.length) {
                            // Update the 'url' key of the 'image' object in the variation
                            variationDataWithImage[variationIndex].image[0].url = name?.name;
                        }
                    }
                });



                const finalDataWithVariations = {
                    ...productDataWithImage,
                    // status: "Published",
                    name: productDataWithImage.name.trim(),
                    status: statusVar,
                    variations: variationDataWithImage
                }
                const finalData = {
                    ...productDataWithImage,
                    // status: "Published",
                    name: productDataWithImage.name.trim(),
                    status: statusVar,
                }

                if (productData?.producttype === 'Variation') {
                    dataToSend = finalDataWithVariations;
                } else {
                    dataToSend = finalData;
                }

                try {
                    const response = await axios.post(`${url}/admin/product`, { dataToSend, id: selectedId }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    });

                    handleProductClick()
                    setProductData({
                        name: '',
                        status: 'Published',
                        image: [
                            {
                                url: '',    // URL of the image
                                alt: '',    // Alt text for the image
                                name: ''    // Name of the image
                            }
                        ],
                        sku: '',
                        stockStatus: "In Stock", // Default to "In Stock" or "Out of Stock" as needed
                        quantity: '',
                        price: '',
                        saleprice: '',
                        category: null, // You can set the initial category as needed
                        Tags: [],
                        inventory: '',
                        isFeatured: false,
                        LinkedCoupons: [],
                        CustomPermalink: '',
                        producttype: "Simple", // Set an initial product type
                        hasVariations: false,
                        shortDescription: '',
                        description: '',
                        isActive: false,
                        CompatibilityInformation: '',
                        ShippingAndReturnInformation: '',
                        shippingDate: '',
                        height: '',
                        width: '',
                        length: '',
                        weight: '',
                        publishedBy: '',
                        isOnSale: false,
                    })
                    setVariationData(
                        [
                            {
                                Type: "",
                                image: [{
                                    url: "",
                                    alt: "",
                                    name: "",
                                    file: "",
                                }],
                                stockStatus: "In Stock",
                                sku: "",
                                quantity: '',
                                price: '',
                                saleprice: '',
                                LinkedCoupons: [],
                                inventory: "",
                                shippingDate: "",
                                height: '',
                                width: '',
                                length: '',
                                weight: '',
                                CustomPermalink: ""
                            }
                        ]
                    )
                    handleProductClick()
                    setSelectedFeaturedImage(null)
                    setSelectedImages([])
                    dispatch(getProducts())
                    setSelecteddCategory('')
                } catch (error) {
                    ;
                }
            }
        }
        saveDraftImages()
    };
    const handleDuplicate = async () => {
        if (productData.name.trim() === '' || !productData.CustomPermalink || !productData.weight || !selectedFeaturedImage || !productData.image[0].name || !productData.image[0].alt || (productData.producttype === "Simple" && !productData.price) || (productData.producttype === "Simple" && !productData.sku) || categoryAlreadyExistsError === true || isSpecialCharacterEntered === true || isSpecialCharacterEnteredAlt === true || isSpecialCharacterEnteredForFeatured === true || isSpecialCharacterEnteredVarAlt === true || invalidFileTypeForVar === true || invalidFileType === true || saleError || (productData.isOnSale && !productData.saleprice)
            || (productData.isOnSale && productData.producttype === "Variation" && variationData?.some(variation => !variation.saleprice))
        ) {
            if (productData.name.trim() === '') {
                setProductData((productData) => ({
                    ...productData,
                    name: ''
                }))
            }
            setFieldsEmpty(true);
            return;
        }

        // Validate that all existing variations have mandatory fields filled
        const allVariationsValid = variationData.every((existingVariation) => {
            return (
                existingVariation.Type
                &&
                existingVariation.price &&
                existingVariation.sku &&
                existingVariation.weight &&
                existingVariation.image[0].name &&
                existingVariation.image[0].alt
            );
        });
        if (productData.producttype === "Variation" && !allVariationsValid) {
            setIsAddVariationClicked(true);
            return;
        }


        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
        };

        const saveDraftImages = async () => {
            const formData = new FormData();

            // Append selectedImages
            selectedImages.forEach((file) => {
                if (file instanceof File) {
                    formData.append('image', file);
                    formData.append(`fileType`, 'media');
                }
            });

            // Append selectedFeaturedImage
            if (selectedFeaturedImage instanceof File) {
                formData.append("image", selectedFeaturedImage);
                formData.append('fileType', 'featuredimage');

            }

            variationData.forEach((variation, index) => {
                if (variation.image && variation.image[0].file instanceof File) {
                    formData.append(`image`, variation.image[0].file);
                    formData.append(`fileType`, `variationimage-${index}`);

                }
            });
            // API to save images/videos in the database
            const { data } = await axios.post(
                `${url}/admin/imageUpload?imageType=product&multiple=true`,
                formData,
                config
            );
            if (data.success) {


                let dataToSend;
                let productDataWithImage = { ...productData };
                let variationDataWithImage = [...variationData];
                data.imageNames.forEach((file) => {
                    const { name, fileType } = file;
                    if (fileType === "media") {
                        // productDataWithImage = { ...productData };
                        productDataWithImage?.media?.push(name.name);
                    } else if (fileType === "featuredimage") {
                        productDataWithImage = {
                            ...productData,
                            image: [{
                                ...productData.image[0],
                                url: name?.name
                            }],
                            media: [name?.name, ...productData?.media]
                        }
                    } else if (fileType?.startsWith("variationimage-")) {
                        const variationIndex = parseInt(fileType.replace('variationimage-', ''));
                        // Assign the variation image name to the appropriate variable in your variation data.
                        if (variationIndex >= 0 && variationIndex < variationDataWithImage.length) {
                            // Update the 'url' key of the 'image' object in the variation
                            variationDataWithImage[variationIndex].image[0].url = name?.name;
                        }
                    }
                });
                // Function to find the next available duplicate index for a given SKU
                const findNextDuplicateIndex = (baseSKU) => {


                    // Create an array to store all existing SKU values
                    const allSkus = [];

                    // Iterate through products and their variations to collect SKUs
                    products.forEach((product) => {
                        if (product?.sku?.startsWith(baseSKU)) {
                            allSkus.push(product.sku);
                        }

                        // Check variations for the same base SKU
                        if (product?.variations) {
                            product.variations.forEach((variation) => {
                                if (variation?.sku?.startsWith(baseSKU)) {
                                    allSkus.push(variation.sku);
                                }
                            });
                        }
                    });

                    // If there's only one value (baseSKU itself), start from 1
                    if (allSkus.length === 1) {
                        return 1;
                    }

                    // Log allSkus to inspect the values


                    // Filter out non-numeric values and find the next available index
                    const numericIndexes = allSkus
                        .map(sku => parseInt(sku.split('-').pop()))
                        .filter(index => !isNaN(index));

                    if (numericIndexes.length === 0) {
                        return 1; // No valid numeric indexes, start from 1
                    }

                    // Find the next available index by checking the value after "-"
                    const nextIndex = Math.max(...numericIndexes) + 1;

                    return nextIndex;
                };
                // Find the next available duplicate index for the SKU
                const duplicateIndex = findNextDuplicateIndex(productDataWithImage.sku);


                // Function to find the next available duplicate index for a given permalink
                const findNextDuplicatePermalinkIndex = (basePermalink) => {


                    // Create an array to store all existing permalink values
                    const allPermalinks = [];

                    // Iterate through products to collect permalinks
                    products.forEach((product) => {
                        if (product?.CustomPermalink?.startsWith(basePermalink)) {
                            allPermalinks.push(product.CustomPermalink);
                        }
                    });

                    // If there's only one value (basePermalink itself), start from 1
                    if (allPermalinks.length === 1) {
                        return 1;
                    }

                    // Log allPermalinks to inspect the values


                    // Filter out non-numeric values and find the next available index
                    const numericIndexes = allPermalinks
                        .map(permalink => parseInt(permalink.split('-').pop()))
                        .filter(index => !isNaN(index));

                    if (numericIndexes.length === 0) {
                        return 1; // No valid numeric indexes, start from 1
                    }

                    // Find the next available index by checking the value after "-"
                    const nextIndex = Math.max(...numericIndexes) + 1;

                    return nextIndex;
                };
                const duplicatePermalinkIndex = findNextDuplicatePermalinkIndex(productDataWithImage.CustomPermalink);


                const finalDataWithVariations = {
                    ...productDataWithImage,
                    name: `${productDataWithImage.name} (duplicate)`,
                    status: "Draft",
                    CustomPermalink: `${productDataWithImage.CustomPermalink}-${duplicatePermalinkIndex}`,
                    // sku: null,
                    variations: variationDataWithImage.map((variation, index) => {
                        const duplicateIndex = findNextDuplicateIndex(variation.sku);

                        const variationSKU = `${variation.sku}-${duplicateIndex}`;
                        return {
                            ...variation,
                            Type: `${variation.Type} (duplicate)`,
                            sku: variationSKU,
                        };
                    }),
                };
                const finalData = {
                    ...productDataWithImage,
                    status: "Draft",
                    name: `${productDataWithImage.name} (duplicate)`,
                    // sku: productDataWithImage.sku ? `${productDataWithImage.sku}-1` : '',
                    sku: productDataWithImage.sku ? `${productDataWithImage.sku}-${duplicateIndex}` : '',
                    CustomPermalink: `${productDataWithImage.CustomPermalink}-${duplicatePermalinkIndex}`,
                }

                if (productData?.producttype === 'Variation') {
                    dataToSend = finalDataWithVariations;
                } else {
                    dataToSend = finalData;
                }

                try {
                    const response = await axios.post(`${url}/admin/product`, { dataToSend }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    });

                    setProductData({
                        name: '',
                        status: 'Published',
                        image: [
                            {
                                url: '',    // URL of the image
                                alt: '',    // Alt text for the image
                                name: ''    // Name of the image
                            }
                        ],
                        sku: '',
                        stockStatus: "In Stock", // Default to "In Stock" or "Out of Stock" as needed
                        quantity: '',
                        price: '',
                        saleprice: '',
                        category: null, // You can set the initial category as needed
                        Tags: [],
                        inventory: '',
                        isFeatured: false,
                        LinkedCoupons: [],
                        CustomPermalink: '',
                        producttype: "Simple", // Set an initial product type
                        hasVariations: false,
                        shortDescription: '',
                        description: '',
                        isActive: false,
                        CompatibilityInformation: '',
                        ShippingAndReturnInformation: '',
                        shippingDate: '',
                        height: '',
                        width: '',
                        length: '',
                        weight: '',
                        publishedBy: '',
                        isOnSale: false,
                    })
                    setVariationData(
                        [
                            {
                                Type: "",
                                image: [{
                                    url: "",
                                    alt: "",
                                    name: "",
                                    file: "",
                                }],
                                stockStatus: "In Stock",
                                sku: "",
                                quantity: '',
                                price: '',
                                saleprice: '',
                                LinkedCoupons: [],
                                inventory: "",
                                shippingDate: "",
                                height: '',
                                width: '',
                                length: '',
                                weight: '',
                                CustomPermalink: ""
                            }
                        ]
                    )
                    handleProductClick()
                    setSelectedFeaturedImage(null)
                    setSelectedImages([])
                    dispatch(getProducts())
                    setSelecteddCategory('')
                } catch (error) {
                    ;
                }
            }
        }
        saveDraftImages()


    };
    const handleOpenEdit = async (val) => {

        const productIds = { productIds: [val._id] };
        setShouldPermalinkSlugEdit(false)
        try {
            const response = await axios.post(`${url}/products/fetch`, productIds);

            // Check if there are any products in the array
            if (response.data.selectedProducts && response.data.selectedProducts.length > 0) {
                onProductNameClick();
                setSelectedId(response.data.selectedProducts[0]?._id)
                setProductData({ ...response.data.selectedProducts[0] });
                setVariationData(response.data.selectedProducts[0]?.variations);
                setSelectedImages(response.data.selectedProducts[0]?.media);
                setSelectedFeaturedImage(response.data.selectedProducts[0]?.image[0]?.url);
                setSelecteddCategory(response.data.selectedProducts[0]?.category)
                if (response.data.selectedProducts[0]?.status === "Published") { setIsUpdating(true); }


                // const htmlContent = response.data.selectedProducts[0]?.shortDescription;
                // if (htmlContent) {
                //     const blocksFromHTML = htmlToDraft(htmlContent);
                //     const contentState = ContentState.createFromBlockArray(
                //         blocksFromHTML.contentBlocks,
                //         blocksFromHTML.entityMap
                //     );
                //     const newEditorState = EditorState.createWithContent(contentState);
                //     setEditorState(newEditorState);
                // }
                setEditorStateFromHtml(response.data.selectedProducts[0]?.shortDescription, setEditorState);
                setEditorStateFromHtml(response.data.selectedProducts[0]?.description, setEditorState2);
                setEditorStateFromHtml(response.data.selectedProducts[0]?.CompatibilityInformation, setEditorState3);
                setEditorStateFromHtml(response.data.selectedProducts[0]?.ShippingAndReturnInformation, setEditorState4);
            }
        } catch (error) {
            ;
        }
    };
    function getStatusColorClass(status) {
        if (status === "Published") {
            return "published";
        } else if (status === "Draft") {
            return "draft";
        } else if (status === "Archived") {
            return "archived";
        } else {
            return ""; // Default styling for other statuses
        }
    }
    const isFilterApplied = selectedProduct !== "Smart Accessory" || selectedStatus !== "Status" || selectedCategory !== "Category"
    const filterbtnStyle = {
        padding: "3px 15px",
        backgroundColor: "#e65925", // Change to your desired button color
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        display: isFilterApplied ? "block" : "none", // Show the button conditionally
        marginLeft: "auto",
        marginBottom: "0px",
        height: "30px",
    };

    //clear filter button
    const clearFilters = () => {
        setSelectedProduct("Smart Accessory")
        setSelectedStatus("Status")
        setSelectedCategory("Category")
    };
    const formattedDate = productData.createdAt
        ? new Date(productData.createdAt).toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).replace(',', ' @')
        : currentDateTime;

    const [error2, setError2] = useState('');

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const slug = inputValue.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

        // Check if the input contains only alphanumeric characters and hyphens
        const isValidInput = /^[a-zA-Z0-9-]*$/.test(inputValue);

        if (isValidInput) {
            setProductData((prevProductData) => ({
                ...prevProductData,
                name: inputValue,
                CustomPermalink: shouldPermalinkSlugEdit ? slug : prevProductData.CustomPermalink,
            }));
            setFieldsEmpty(false);
            setError2('');
        } else {
            setFieldsEmpty(true);
            setError2('Special characters are not allowed.');
        }
    };

    const handleInputChange3 = (e) => {
        const inputValue = e.target.value;

        // Check if the input contains only alphanumeric characters and hyphens
        const isValidInput = /^[a-zA-Z0-9-]*$/.test(inputValue);

        if (isValidInput) {
            setCategoryName(inputValue);
            setCategoryAlreadyExistsError(false)
            setError2('');
        } else {
            setError2('Special characters are not allowed.');
        }
    };

    // const onEditorStateChange = (newEditorState) => {
    //     setEditorState(newEditorState);
    //     const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    //     const html = draftToHtml(rawContentState);
    //     console.log("editorState", html)
    //     setProductData({
    //         ...productData,
    //         shortDescription: html

    //     });
    // };
    const onEditorStateChange = (newEditorState, propertyName) => {
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const html = draftToHtml(rawContentState);
        console.log(`${propertyName} editorState`, html);

        setProductData({
            ...productData,
            [propertyName]: html
        });

        // Update the corresponding editor state
        switch (propertyName) {
            case 'shortDescription':
                setEditorState(newEditorState);
                break;
            case 'description':
                setEditorState2(newEditorState);
                break;
            case 'CompatibilityInformation':
                setEditorState3(newEditorState);
                break;
            case 'ShippingAndReturnInformation':
                setEditorState4(newEditorState);
                break;
            default:
                break;
        }
    };
    const setEditorStateFromHtml = (htmlContent, setEditorStateFunc) => {
        if (htmlContent) {
            const blocksFromHTML = htmlToDraft(htmlContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorStateFunc(newEditorState);
        }
    };
    return (
        <>
            {!showCreateProduct
                ?
                <div className="onetap_conn_users_tab_content_OTC_Admin">

                    <div className="onetap_conn_users_tap_filter_OTC_Admin">
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="industry"
                                id="industry"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}
                            >
                                <option value="Smart Accessory">Smart Accessory</option>

                                {getUniqueProducts()?.map((group) => (
                                    <option
                                        style={{ textTransform: "capitalize" }}
                                        key={group}
                                        value={group}
                                    >
                                        {group}
                                    </option>
                                ))}

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="Category"
                                id="Category"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="Category">Category</option>
                                {getUniqueCategories()?.map((group) => (
                                    <option
                                        style={{ textTransform: "capitalize" }}
                                        key={group}
                                        value={group}
                                    >
                                        {group}
                                    </option>
                                ))}

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="status"
                                id="status"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="Status">Status</option>
                                <option value="Published">Published</option>
                                <option value="Draft">Draft</option>
                                <option value="Archived">Archived</option>

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>

                    </div>
                    <div className="onetap_conn_filter_search_OTC_Admin">
                        <button style={filterbtnStyle} onClick={clearFilters}>
                            Clear Filters
                        </button>
                    </div>
                    {loading ? <Loader /> : <table class="table product_table">
                        <thead>
                            <tr >
                                <th scope="col" className='w-25' style={{ textAlign: 'left' }} >

                                </th>
                                <th scope="col">SKU</th>
                                <th scope="col">Price</th>
                                <th scope="col">Category</th>
                                <th scope="col">Inventory</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredProducts?.map((val, index) =>
                            (
                                <tr className='mt-3 product_row' key={index}>
                                    <td role="button" scope="row" style={{ textAlign: 'left' }} onClick={() => handleOpenEdit(val)}>
                                        <div className="d-flex align-items-center">
                                            <img className="img-fluid me-2" width={40} src={`${url}/product/img/${val?.image[0]?.url}`} />
                                            <span>{val?.name ? val?.name?.charAt(0)?.toUpperCase() + val?.name?.slice(1) : ''}</span>
                                        </div>
                                    </td>
                                    <td>{val.sku}</td>
                                    {val.hasVariations ? (
                                        <td>
                                            {val.variations.length > 0 ? (
                                                `${Math.min(...val.variations.map((variation) => val.isOnSale ? (variation.saleprice || variation.price) : variation.price))} - ${Math.max(...val.variations.map((variation) => val.isOnSale ? (variation.saleprice || variation.price) : variation.price))}`
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                    ) : (
                                        <td>{val.isOnSale ? val.saleprice : val.price}</td>
                                    )}
                                    <td>{val.category?.name}</td>
                                    {val.hasVariations
                                        ?
                                        <td role="button" onClick={() => { setDisplayVariation(val); setOpenModal(true) }}
                                            style={{
                                                // padding: "30px",
                                                color: val?.variations?.some((variation) => variation?.inventory <= 5)
                                                    ? "white"
                                                    : "#000"
                                                ,
                                                backgroundColor: val?.variations?.some((variation) => variation?.inventory <= 5)
                                                    ? "#E65925"
                                                    : "#f2f2f2"
                                            }}
                                            title="Click to view Variations"
                                        >

                                            <div>
                                                <p className="mb-0" style={{ color: val?.variations?.some((variation) => variation?.inventory <= 5) ? "white" : (val?.variations?.some((variation) => variation?.stockStatus === "Backorder") ? "#E65925" : "#48AF25") }}>
                                                    {val?.variations?.some((variation) => variation?.stockStatus === "Backorder") ? "Backorder" : "In Stock"}
                                                </p>
                                                <p className="mb-0">Variations</p>
                                            </div>

                                        </td>
                                        :
                                        <td><div><p className="mb-0" style={{ color: val.stockStatus === "In Stock" ? "#48AF25" : "#E65925" }}>{val.stockStatus}</p><p className="mb-0" style={{ height: '1.5rem' }}>{val.inventory || " "} </p></div></td>
                                    }

                                    <td className={`status-cell ${getStatusColorClass(val.status)}`}>{val.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}

                </div>

                :

                <div className="">

                    <div className="w-100  d-flex flex-row-reverse gap-3 mb-4 plan-btn-top">
                        <div class="dropdown">
                            <button class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: '#000', color: '#fff', }}>
                                <span className='me-5'>Actions</span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ left: 'unset' }}>

                                <a class="dropdown-item" href="#" onClick={handleWarningOpenModal}>Archive</a>
                                <a class="dropdown-item" href="#" onClick={() => handleDuplicate()}>Duplicate</a>
                            </div>
                        </div>
                        <button className="btn px-3 py-2  text-white" style={{ background: isUpdating ? '#E65925' : '#48AF25', width: '15%' }} disabled={productData.status === "Draft"} onClick={() => handlePublish("Published")}>{isUpdating ? "Update" : "Publish"}</button>
                        <button
                            className="btn px-3 py-2 text-white"
                            style={{
                                background: productData.status !== "Draft" ? '#8A8A8A' : 'black',
                                width: '15%',
                            }}
                            disabled={productData.status !== "Draft"}
                            onClick={() => handlePublish("Draft")}
                        >
                            Save draft
                        </button>
                    </div>

                    <div className="row">
                        <div className="col-8" >
                            <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                                <h5>Smart Accessory Name*</h5>
                                <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="name" value={productData.name}
                                    onChange={(e) => {
                                        const slug = e.target.value.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                                        setProductData((prevProductData) => ({
                                            ...prevProductData,
                                            name: e.target.value,
                                            CustomPermalink: shouldPermalinkSlugEdit ? slug : prevProductData.CustomPermalink,
                                        }));
                                    }}
                                    // onChange={handleInputChange}
                                />
                                {fieldsEmpty && !productData.name && <p style={{ color: "red", marginBottom: "0px" }}>Name is required.</p>}

                            </div>
                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Permalink*</h5>
                                <div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input className="w-100 border-0 me-2" type="text" style={{ background: "#f2f2f2" }} readOnly={!isPermalinkEditable} name="customPermalink" value={productData.CustomPermalink}
                                        onChange={(e) => {
                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setProductData({
                                                ...productData,
                                                CustomPermalink: slug,
                                            });
                                            setIsPermalinkUnique(true);
                                        }} />
                                    <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(true)} role="button">Edit</span>
                                </div>
                                {fieldsEmpty && !productData.CustomPermalink && <p style={{ color: "red", marginBottom: "0px" }}>Permalink is required.</p>}
                                {!isPermalinkUnique && <p style={{ color: "red", marginBottom: "0px" }}>This permalink already exists.</p>}
                            </div>
                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Smart Accessory Description</h5>
                                <p className="mb-0 mt-2">Short description</p>
                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="shortDescription"
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'shortDescription')}
                                />

                                <p className="mb-0">Long description</p>
                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="description"
                                    editorState={editorState2}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'description')}
                                />
                            </div>
                            <div className="row mt-3">
                                <div className="col-7" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }}>
                                        <div className="d-flex justify-content-between">
                                            <h5>Media</h5>
                                            <p role="button" style={{ color: '#E65925' }} onClick={() => handleImageUpload(mediaFileInputRef)}>+ Add Media</p>
                                            <input
                                                type="file"
                                                accept="image/*, video/*"
                                                name="productImage"
                                                style={{ display: 'none' }}
                                                ref={mediaFileInputRef}
                                                onChange={(e) => handleImageChange(e, mediaFileInputRef)}
                                                multiple
                                            />
                                        </div>

                                        {selectedImages.length > 0 ? (
                                            <div className="d-flex flex-wrap mt-3 gap-4 h-75 pt-4" style={{ overflowY: "scroll", overflowX: "hidden" }}>
                                                {selectedImages.map((image, index) => (
                                                    <div key={index} style={{ position: 'relative', width: "25%" }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            {typeof image === 'object' && image.type?.startsWith('image/') ? (
                                                                <img
                                                                    src={URL.createObjectURL(image)}
                                                                    alt="Selected"
                                                                    style={{ maxWidth: '100%' }}
                                                                />
                                                            ) : typeof image === 'object' ? (
                                                                <video
                                                                    controls
                                                                    width="100%"
                                                                    src={URL.createObjectURL(image)}
                                                                />
                                                            ) : image.endsWith('.mp4') ? (

                                                                <video
                                                                    className="video-player"
                                                                    controls
                                                                    width='100%'

                                                                >
                                                                    <source src={`${url}/product/img/${image}`} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            ) :

                                                                (
                                                                    <img
                                                                        src={`${url}/product/img/${image}`}
                                                                        alt="Selected"
                                                                        style={{ maxWidth: '100%' }}
                                                                    />
                                                                )}
                                                        </div>
                                                        <span
                                                            className="remove-image"
                                                            style={{ cursor: 'pointer', position: 'absolute', top: '-20px', right: '-10px', fontSize: '10px' }}
                                                            onClick={() => removeEventListener(index)}
                                                        >
                                                            &#10005;
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : ""
                                        }
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="bg-white p-4 gap-2 d-flex flex-column " style={{ borderRadius: '8px' }}>
                                        <div>
                                            {selectedFeaturedImage ? (
                                                <div style={{ position: 'relative' }}>
                                                    <div style={{ cursor: 'pointer' }} >
                                                        {typeof selectedFeaturedImage === 'object' && selectedFeaturedImage.type?.startsWith('image/') ? (
                                                            <img
                                                                src={URL.createObjectURL(selectedFeaturedImage)}
                                                                alt="Selected"
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                        ) : typeof selectedFeaturedImage === 'object' ? (
                                                            <video
                                                                controls
                                                                width="100%"
                                                                src={URL.createObjectURL(selectedFeaturedImage)}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`${url}/product/img/${selectedFeaturedImage}`}
                                                                alt="Selected"
                                                                style={{ maxWidth: '100%' }}
                                                            />
                                                        )}
                                                    </div>
                                                    <span
                                                        className="remove-image"
                                                        style={{ cursor: 'pointer', position: 'absolute', top: '-10px', right: '-10px', fontSize: '10px' }}
                                                        onClick={removeFeaturedImage}
                                                    >
                                                        &#10005;
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ cursor: 'pointer' }} onClick={() => handleImageUpload(featuredImageFileInputRef)}>
                                                    <h5>Featured Image*</h5>
                                                    <div className="w-100 border-0 d-flex align-items-center justify-content-center" style={{ borderRadius: "4px", background: "#f2f2f2", padding: '50px' }}>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
                                                                <path d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0.24 24.0026 0.24C28.0651 0.24 31.5116 1.65528 34.3422 4.48583C37.1728 7.31639 38.5873 10.7622 38.5859 14.8233C40.9818 15.1011 42.97 16.1344 44.5505 17.9233C46.1311 19.7122 46.9207 21.8039 46.9193 24.1983C46.9193 26.8025 46.0075 29.0164 44.1839 30.84C42.3603 32.6636 40.1471 33.5747 37.5443 33.5733H26.0859V18.6775L29.4193 21.9067L32.3359 18.99L24.0026 10.6567L15.6693 18.99L18.5859 21.9067L21.9193 18.6775V33.5733Z" fill="#838383" />
                                                            </svg>
                                                            <p>Upload</p>
                                                        </div>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        name="productImage"
                                                        style={{ display: 'none' }}
                                                        ref={featuredImageFileInputRef}
                                                        onChange={(e) => handleImageChange(e, featuredImageFileInputRef)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {fieldsEmpty && !selectedFeaturedImage && <p style={{ color: "red", marginBottom: "0px" }}>Featured image is required.</p>}
                                        {selectedFeaturedImage && invalidFileType && <p style={{ color: "red", marginBottom: "0px" }}>Invalid file type.</p>}

                                        <div>
                                            <span>Image name*</span>
                                            <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="image name" value={productData.image[0]?.name}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!/^[a-zA-Z0-9\s]*$/.test(inputValue)){
                                                        setIsSpecialCharacterEnteredForFeatured(true);
                                                    } else {
                                                        setIsSpecialCharacterEnteredForFeatured(false);
                                                    }
                                                    const updatedProductData = { ...productData };
                                                    updatedProductData.image[0] = {
                                                        ...updatedProductData.image[0],
                                                        name: e.target.value
                                                    };
                                                    setProductData(updatedProductData);
                                                }} />
                                            {fieldsEmpty && !productData.image[0].name && <p style={{ color: "red", marginBottom: "0px" }}>Image name is required.</p>}
                                            {isSpecialCharacterEnteredForFeatured && <p style={{ color: "red", marginBottom: "0px" }}>Image name can not have special characters.</p>}

                                        </div>
                                        <div>
                                            <span>Alt text*</span>
                                            <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="image alt" value={productData.image[0]?.alt}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                     if (!/^[a-zA-Z0-9\s]*$/.test(inputValue)) {
                                                        setIsSpecialCharacterEnteredAlt(true);
                                                    } else {
                                                        setIsSpecialCharacterEnteredAlt(false);
                                                    }
                                                    const updatedProductData = { ...productData };
                                                    updatedProductData.image[0] = {
                                                        ...updatedProductData.image[0],
                                                        alt: e.target.value
                                                    };
                                                    setProductData(updatedProductData);

                                                }} />
                                            {fieldsEmpty && !productData.image[0].alt && <p style={{ color: "red", marginBottom: "0px" }}>Alt text is required.</p>}
                                            {isSpecialCharacterEnteredAlt && <p style={{ color: "red", marginBottom: "0px" }}>Alt text can not have special characters.</p>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white mt-3 p-4 gap-3 d-flex flex-column" style={{ borderRadius: '8px' }}>
                                <div className="d-flex align-items-center">
                                    <h5 className="ps-0 p-2">Smart Accessory Type</h5>
                                    <select class="form-select p-2 w-25 ms-2" style={{ backgroundColor: '#f2f2f2', borderRadius: '4px' }} name="producttype" value={productData.producttype}
                                        onChange={(e) => {
                                            if (e.target.value === 'Variation') {
                                                setProductData((prevProductData) => ({
                                                    ...prevProductData,
                                                    hasVariations: true,
                                                    producttype: e.target.value
                                                }));
                                            } else {
                                                setProductData((prevProductData) => ({
                                                    ...prevProductData,
                                                    hasVariations: false,
                                                    producttype: e.target.value
                                                }));
                                            }

                                        }}>
                                        {/* <option selected value="Product Type" disabled className="d-none">Product Type</option> */}
                                        <option selected value="Simple">Simple</option>
                                        <option value="Variation">Variation</option>
                                    </select>
                                </div>
                                {productData.producttype === "Variation" ?
                                    <>
                                        <div className="d-flex justify-content-end">
                                            {/* <h5>Variation Data</h5> */}
                                            <div role="button" onClick={() => handleAddVariationClick()}>

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="34"
                                                    height="34"
                                                    viewBox="0 0 34 34"
                                                    fill="none"
                                                >
                                                    <rect width="34" height="34" rx="9" fill="#E65925" />
                                                    <path
                                                        d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                <span className="ms-2">Add Variation</span>
                                            </div>
                                        </div>

                                        <div class="accordion" id="accordionExample">
                                            {variationData?.map((variation, index) => (
                                                <div className="d-flex gap-2">
                                                    <div class="accordion-item mb-3" key={index} style={{ width: "95%" }}>
                                                        <h2 class="accordion-header" id={`heading${index}`} style={{ backgroundColor: '#f2f2f2' }}>
                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === variationData.length - 1 ? 'true' : 'false'} aria-controls={`collapse${index}`} style={{ backgroundColor: '#f2f2f2', boxShadow: 'unset' }}>
                                                                {variation.Type ? variation.Type : "Variation Name"}
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${index}`} class={`accordion-collapse collapse ${index === variationData.length - 1 ? 'show' : ''}`} aria-labelledby={`heading${index}`}>
                                                            <div class="accordion-body">
                                                                <div className="d-flex gap-4">
                                                                    <div className=" mt-0 gap-2 d-flex flex-column">
                                                                        {/* <h5>Variation Data</h5> */}
                                                                        <div>
                                                                            <span>Variation name*</span>
                                                                            <input
                                                                                className="w-100 border-0 p-2"
                                                                                type="text"
                                                                                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                onChange={(e) => handleVariationChange(e, index)}
                                                                                name="Type"
                                                                                value={variation.Type}
                                                                            />
                                                                            {isAddVariationClicked && !variation.Type && <p style={{ color: "red", marginBottom: "0px" }}>Variation name is required.</p>}
                                                                        </div>
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <div className="mt-2 w-50">
                                                                                <span>Regular price*</span>
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="price"
                                                                                    value={variation.price}
                                                                                />


                                                                            </div>
                                                                            <div className="mt-2 w-50">
                                                                                <span>Sales price</span>
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="saleprice"
                                                                                    value={variation?.saleprice}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                        {isAddVariationClicked && !variation.price && <p style={{ color: "red", marginBottom: "0px" }}>Price is required.</p>}
                                                                        {fieldsEmpty && (productData?.isOnSale && !variation?.saleprice) && <p style={{ color: "red", marginBottom: "0px" }}>sale price is required.</p>}
                                                                        {saleError && variation?.saleprice && <p style={{ color: "red", marginBottom: "0px" }}>Sale price must be smaller than regular price.</p>}

                                                                        <div>
                                                                            <span>SKU*</span>
                                                                            <input
                                                                                className="w-100 border-0 p-2"
                                                                                type="text"
                                                                                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                onChange={(e) => handleVariationChange(e, index)}
                                                                                name="sku"
                                                                                value={variation.sku}
                                                                            />
                                                                        </div>
                                                                        {isAddVariationClicked && !variation.sku && <p style={{ color: "red", marginBottom: "0px" }}>SKU is required.</p>}
                                                                        {isAddVariationClicked && !isSkuUnique && <p style={{ color: "red", marginBottom: "0px" }}>This SKU already exists.</p>}

                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <div className="mt-2 w-50">
                                                                                <span>Stock status</span>
                                                                                <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} onChange={(e) => handleVariationChange(e, index)} name="stockStatus" value={variation.stockStatus}>

                                                                                    <option selected value="In Stock">In stock</option>
                                                                                    <option value="Backorder">Backorder</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="mt-2 w-50">
                                                                                <span>Inventory</span>
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2", }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="inventory"
                                                                                    value={variation.inventory}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {variation.stockStatus === "Backorder" &&
                                                                            <div>
                                                                                <span>Expected shipping day</span>
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="shippingDate"
                                                                                    value={variation.shippingDate}
                                                                                />
                                                                                {/* {invalidDate && <p style={{ color: "red", marginBottom: "0px" }}>Invalid date. Please select a date in the future.</p>}*/}

                                                                            </div>

                                                                        }
                                                                        <h5 className="mt-4 mb-0">Shipping</h5>
                                                                        <div>
                                                                            <span>Smart Accessory weight (oz)*</span>
                                                                            <div className="d-flex align-items-center">
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="weight"
                                                                                    value={variation.weight}
                                                                                />
                                                                                {/* <span className="ms-2">oz</span> */}
                                                                            </div>
                                                                            {isAddVariationClicked && !variation.weight && <p style={{ color: "red", marginBottom: "0px" }}>Weight is required.</p>}
                                                                        </div>
                                                                        <div>
                                                                            <span>Dimensions (L x W x H)</span>
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    placeholder="Length"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="length"
                                                                                    value={variation.length}
                                                                                />
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    placeholder="Width"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="width"
                                                                                    value={variation.width}
                                                                                />
                                                                                <input
                                                                                    className="w-100 border-0 p-2 coupon-number-input"
                                                                                    type="number"
                                                                                    placeholder="Height"
                                                                                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                    onChange={(e) => handleVariationChange(e, index)}
                                                                                    name="height"
                                                                                    value={variation.height}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="w-50 bg-white gap-2 d-flex flex-column" style={{ borderRadius: '8px', padding: '34px 0px' }}>
                                                                        <div className="w-100">
                                                                            <h5 className="border-bottom pb-2">Variation Image*</h5>
                                                                            {variation?.image[0]?.file ? (
                                                                                <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '20px' }}>
                                                                                    <div key={index} style={{ position: 'relative', width: "" }}>
                                                                                        {

                                                                                            <div style={{ position: 'relative' }}>

                                                                                                <div style={{ cursor: 'pointer' }} >
                                                                                                    <img
                                                                                                        src={URL.createObjectURL(variation?.image[0]?.file)}
                                                                                                        alt="Selected"
                                                                                                        style={{ maxWidth: '100%' }}
                                                                                                    />
                                                                                                </div>

                                                                                                <span
                                                                                                    className="remove-image"
                                                                                                    style={{ cursor: 'pointer', position: 'absolute', top: '-10px', right: '-10px', fontSize: '10px' }}
                                                                                                    onClick={() => removeVariationImage(index)}
                                                                                                >
                                                                                                    &#10005;
                                                                                                </span>
                                                                                            </div>

                                                                                        }
                                                                                    </div>
                                                                                </div>)
                                                                                :
                                                                                variation?.image?.length > 0 && variation?.image[0]?.url ? (
                                                                                    <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '20px' }}>
                                                                                        <div key={index} style={{ position: 'relative', width: "" }}>
                                                                                            {

                                                                                                <div style={{ position: 'relative' }}>

                                                                                                    <div style={{ cursor: 'pointer' }} >
                                                                                                        <img

                                                                                                            src={`${url}/product/img/${variation?.image[0]?.url}`}
                                                                                                            alt="Selected"
                                                                                                            style={{ maxWidth: '100%' }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <span
                                                                                                        className="remove-image"
                                                                                                        style={{ cursor: 'pointer', position: 'absolute', top: '-10px', right: '-10px', fontSize: '10px' }}
                                                                                                        onClick={() => {
                                                                                                            const updatedVariationData = [...variationData];


                                                                                                            if (updatedVariationData[index] && updatedVariationData[index].image && Array.isArray(updatedVariationData[index].image)) {

                                                                                                                if (updatedVariationData[index].image[0]) {
                                                                                                                    updatedVariationData[index].image[0].url = null;
                                                                                                                }

                                                                                                                setVariationData(updatedVariationData);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        &#10005;
                                                                                                    </span>
                                                                                                </div>

                                                                                            }
                                                                                        </div>
                                                                                    </div>)
                                                                                    :
                                                                                    (
                                                                                        <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" onClick={() => handleImageUpload(variationFileInputRef)} style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '50px' }}>
                                                                                            <input
                                                                                                type="file"
                                                                                                accept="image/*"
                                                                                                name="productImage"
                                                                                                style={{ display: 'none' }}
                                                                                                ref={variationFileInputRef}
                                                                                                // onChange={(e) => handleImageChange(e, variationFileInputRef)}
                                                                                                onChange={(e) => {
                                                                                                    const files = Array.from(e.target.files);

                                                                                                    const isValidFileType = files.every(file => file.type.startsWith('image/'));
                                                                                                    if (!isValidFileType) {
                                                                                                        setInvalidFileTypeForVar(true);
                                                                                                    } else {
                                                                                                        setInvalidFileTypeForVar(false);
                                                                                                    }
                                                                                                    const updatedVariations = [...variationData];
                                                                                                    updatedVariations[index] = {
                                                                                                        ...updatedVariations[index],
                                                                                                        image: [{
                                                                                                            ...updatedVariations[index].image[0],
                                                                                                            file: files[0]
                                                                                                        }]
                                                                                                    };
                                                                                                    setVariationData(updatedVariations);
                                                                                                }}
                                                                                            />
                                                                                            <div style={{ cursor: "pointer" }}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
                                                                                                    <path
                                                                                                        d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                                                                                                        fill="#838383"
                                                                                                    />
                                                                                                </svg>
                                                                                                <p>Upload</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                            {isAddVariationClicked && !variation?.image[0]?.file && !variation?.image[0]?.url && <p style={{ color: "red", marginBottom: "0px" }}>Variation image is required.</p>}
                                                                            {(variation?.image[0]?.file || variation?.image[0]?.url) && invalidFileTypeForVar && <p style={{ color: "red", marginBottom: "0px" }}>Invalid file type.</p>}

                                                                        </div>
                                                                        {/* Image name input */}
                                                                        <div>
                                                                            <span>Image name*</span>
                                                                            <input
                                                                                className="w-100 border-0 p-2"
                                                                                type="text"
                                                                                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                name="img name"
                                                                                value={variation?.image[0]?.name}

                                                                                onChange={(e) => {
                                                                                    const inputValue = e.target.value;
                                                                                     if (!/^[a-zA-Z0-9\s]*$/.test(inputValue)) {
                                                                                        setIsSpecialCharacterEntered(true);
                                                                                    } else {
                                                                                        setIsSpecialCharacterEntered(false);
                                                                                    }

                                                                                    const updatedVariations = [...variationData];
                                                                                    updatedVariations[index] = {
                                                                                        ...updatedVariations[index],
                                                                                        image: [
                                                                                            {
                                                                                                ...updatedVariations[index].image[0], // Keep the other properties of the image object
                                                                                                name: e.target.value,
                                                                                            },
                                                                                        ],
                                                                                    };
                                                                                    setVariationData(updatedVariations);
                                                                                }}

                                                                            />
                                                                            {isAddVariationClicked && !variation?.image[0]?.name && <p style={{ color: "red", marginBottom: "0px" }}>Image name is required.</p>}
                                                                            {isSpecialCharacterEntered && <p style={{ color: "red", marginBottom: "0px" }}>Image name can not have special characters.</p>}


                                                                        </div>
                                                                        {/* Alt text input */}
                                                                        <div>
                                                                            <span>Alt text*</span>
                                                                            <input
                                                                                className="w-100 border-0 p-2"
                                                                                type="text"
                                                                                style={{ borderRadius: "4px", background: "#f2f2f2" }}
                                                                                onChange={(e) => {
                                                                                    const inputValue = e.target.value;
                                                                                     if (!/^[a-zA-Z0-9\s]*$/.test(inputValue)) {
                                                                                        setIsSpecialCharacterEnteredVarAlt(true);
                                                                                    } else {
                                                                                        setIsSpecialCharacterEnteredVarAlt(false);
                                                                                    }
                                                                                    const updatedVariations = [...variationData];
                                                                                    updatedVariations[index] = {
                                                                                        ...updatedVariations[index],
                                                                                        image: [
                                                                                            {
                                                                                                ...updatedVariations[index].image[0], // Keep the other properties of the image object
                                                                                                alt: e.target.value,
                                                                                            },
                                                                                        ],
                                                                                    };
                                                                                    setVariationData(updatedVariations);
                                                                                }}

                                                                                name="alt"
                                                                                value={variation?.image[0]?.alt}
                                                                            />
                                                                            {isAddVariationClicked && !variation?.image[0]?.alt && <p style={{ color: "red", marginBottom: "0px" }}>Alt text is required.</p>}
                                                                            {isSpecialCharacterEnteredVarAlt && <p style={{ color: "red", marginBottom: "0px" }}>Alt text can not have special characters.</p>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingTop: "10px" }}>
                                                        <span style={{ paddingTop: "10px", cursor: "pointer", color: 'red', fontSize: 'small' }}
                                                            onClick={() => {
                                                                // Filter the variationData array to exclude the variation at the current index
                                                                const updatedVariationData = variationData.filter((_, i) => i !== index);
                                                                setVariationData(updatedVariationData);
                                                            }}
                                                        >
                                                            Remove
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </>
                                    :
                                    <div className="d-flex gap-4 w-100">
                                        <div className=" mt-0 gap-2 d-flex flex-column w-100">

                                            <div className="d-flex align-items-center gap-3">
                                                <div className="mt-2 w-50">
                                                    <span>Regular price*</span>
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="price" value={productData.price} onChange={(e) => handleChange(e)} />
                                                </div>
                                                <div className="mt-2 w-50">
                                                    <span>Sales price</span>
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="saleprice" value={productData.saleprice} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>
                                            {fieldsEmpty && !productData.price && <p style={{ color: "red", marginBottom: "0px" }}>Price is required.</p>}
                                            {fieldsEmpty && (productData.isOnSale && !productData.saleprice) && <p style={{ color: "red", marginBottom: "0px" }}>Sale price is required.</p>}
                                            {saleError && <p style={{ color: "red", marginBottom: "0px" }}>Sale price must be smaller than regular price.</p>}

                                            <div>
                                                <span>SKU*</span>
                                                <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="sku" value={productData.sku} onChange={(e) => {setIsSkuUnique(true);handleChange(e)}} />
                                                {fieldsEmpty && !productData.sku && <p style={{ color: "red", marginBottom: "0px" }}>SKU is required.</p>}
                                {!isSkuUnique && <p style={{ color: "red", marginBottom: "0px" }}>This SKU already exists.</p>}
                                            </div>
                                            <div className="d-flex align-items-center gap-3">
                                                <div className="mt-2 w-50">
                                                    <span>Stock status</span>
                                                    <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} name="stockStatus" value={productData.stockStatus} onChange={(e) => handleChange(e)}>

                                                        <option selected value="In Stock">In stock</option>
                                                        <option value="Backorder">Backorder</option>
                                                    </select>
                                                </div>
                                                <div className="mt-2 w-50">
                                                    <span>Inventory</span>
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2", }} name="inventory" value={productData.inventory} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>
                                            {productData.stockStatus === "Backorder" &&
                                                <div>
                                                    <span>Expected shipping day</span>
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="shippingDate" value={productData.shippingDate} onChange={(e) => handleChange(e)} />
                                                    {/* {invalidShippingDate && <p style={{ color: "red", marginBottom: "0px" }}>Invalid date. Please select a date in the future.</p>}*/}
                                                </div>
                                            }

                                            <h5 className="mt-4 mb-0">Shipping</h5>
                                            <div>
                                                <span>Smart Accessory weight (oz)*</span>
                                                <div className="d-flex align-items-center">
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="weight" value={productData.weight} onChange={(e) => handleChange(e)} />
                                                    {/* <span className="ms-2">oz</span> */}
                                                </div>
                                                {fieldsEmpty && !productData.weight && <p style={{ color: "red", marginBottom: "0px" }}>Weight is required.</p>}
                                            </div>
                                            <div>
                                                <span>Dimensions (L x W x H)</span>
                                                <div className="d-flex align-items-center gap-3">
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" placeholder="Length" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="length" value={productData.length} onChange={(e) => handleChange(e)} />
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" placeholder="Width" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="width" value={productData.width} onChange={(e) => handleChange(e)} />
                                                    <input className="w-100 border-0 p-2 coupon-number-input" type="number" placeholder="Height" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="height" value={productData.height} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                            <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Other information</h5>
                                <p className="mb-0 mt-2">Shipping & Return information</p>

                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="CompatibilityInformation"
                                    editorState={editorState3}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'CompatibilityInformation')}
                                />
                                <p className="mb-0">Compatibility information</p>

                                <Editor
                                    editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                    name="ShippingAndReturnInformation"
                                    editorState={editorState4}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(newEditorState) => onEditorStateChange(newEditorState, 'ShippingAndReturnInformation')}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-white p-4" style={{ borderRadius: '4px' }}>
                                <h6 className="border-bottom pb-2">Smart Accessory information</h6>
                                <div className="gap-3 d-flex flex-column">
                                    <div className="mt-2">
                                        <span>Status*</span>
                                        <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} name="status" value={productData.status} onChange={(e) => handleChange(e)}>
                                            {/* <option selected  className="d-none">Status</option> */}
                                            <option value="Draft">Draft</option>
                                            <option selected value="Published">Published</option>
                                            <option value="Archived">Archived</option>
                                        </select>
                                    </div>
                                    <div>
                                        <span>Visibility*</span>
                                        <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} name="visibility" value={productData.visibility} onChange={(e) => handleChange(e)}>
                                            {/* <option selected  className="d-none" >Visibility</option> */}
                                            <option selected value="Public">Public</option>
                                            <option value="Hidden">Hidden</option>
                                        </select>
                                    </div>
                                    <div>
                                        <span>Published date</span>
                                        <input value={formattedDate} className="w-100 border-0 p-2" readOnly type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} />
                                    </div>
                                    <div>
                                        <span>Published by</span>
                                        <input className="w-100 border-0 p-2" type="text" readOnly style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="publishedBy" value={productData.publishedBy} />
                                    </div>

                                    {/* <div className="d-flex align-items-center gap-2 w-100">
                                        <button className="btn px-3 py-2 w-50 text-white" style={{ background: isUpdating ? '#E65925' : '#48AF25', width: '15%' }} disabled={productData.status === "Draft"} onClick={() => handlePublish("Published")}>{isUpdating ? "Update" : "Publish"}</button>
                                        <button className="btn px-3 py-2 w-50  text-white" style={{ background: isUpdating ? '#E65925' : '#48AF25', width: '15%' }} disabled={productData.status === "Draft"} onClick={() => handlePublish("Published")}>{isUpdating ? "Update" : "Publish"}</button>
                                    </div>
                                    <u>Copy to new draft</u>
                                <u>Move to archive</u>*/}

                                </div>

                            </div>
                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                <div>
                                    <h6 className="border-bottom pb-2" >Special status</h6>
                                    <div class="form-check d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" name="isFeatured" checked={productData.isFeatured} value={productData.isFeatured} onChange={(e) => {
                                            setProductData({
                                                ...productData,
                                                isFeatured: e.target.checked
                                            });
                                        }} id="flexCheckDefault" />
                                        <label class="form-check-label ms-2 text-black" for="flexCheckDefault" >
                                            Featured
                                        </label>
                                    </div>
                                    <div class="form-check mt-2 mr-0  d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" name="isOnSale" checked={productData.isOnSale} value={productData.isOnSale}
                                            onChange={(e) => {
                                                setProductData({
                                                    ...productData,
                                                    isOnSale: e.target.checked
                                                });
                                            }} id="flexCheckDefault2" />
                                        <label class="form-check-label ms-2  text-black" for="flexCheckDefault2">
                                            On sale
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                <div className="d-flex justify-content-between border-bottom mb-2">
                                    <h6 className="">Smart Accessory Category</h6>
                                    <p style={{ color: '#E65925', textAlign: 'right', marginBottom: "10px" }} role="button" onClick={() => setShowAddCatgory(true)}>+ Add category</p>

                                </div>

                                {renderCategories(categoryTree)}
                                {showAddCatgory && <div className="d-flex flex-end flex-column">
                                    <input placeholder="Category Name" autoFocus className="mt-3 w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} value={CategoryName} onChange={handleInputChange3} />
                                    {categoryNameEmpty && !CategoryName && <p style={{ color: "red", marginBottom: "0px" }}>Category Name is required.</p>}
                                    {categoryAlreadyExistsError && CategoryName && <p style={{ color: "red", marginBottom: "0px" }}>Category already exists.</p>}
                                    <div className="d-flex">
                                        <button className="btn mt-2 ms-auto text-white px-4" style={{ backgroundColor: '#E65925' }} onClick={() => handleAddCategory()}>Add</button>
                                        <button className="btn mt-2 ms-2 text-white" style={{ backgroundColor: '#E65925' }} onClick={() => { setShowAddCatgory(false); setCategoryName('') }}>Cancel</button>
                                    </div>
                                </div>}
                            </div>
                            <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                <h5>Activity log</h5>
                                <textarea rows={4} className="w-100 border-0 p-2" type="text" readOnly="true" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="activityLog" value={productData.activityLog} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                    </div>

                </div >

            }
            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={warningModalStyle}>
                    <div className="video-Modal-section">
                        <h2 className="warning-header">
                            Warning
                        </h2>
                        <span className="warning-text-smart-acc">Are you sure you want to Archive this product?</span>


                        <div className="video-Modal-btn-group mt-4" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={handleCloseWarningdModal}>Archive</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
                        </div>

                    </div>
                </Box>
            </Modal>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Variation inventory
                        </h2>
                        <table class="table mt-4 product_table">
                            <thead>
                                <tr >
                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                        Name
                                    </th>
                                    <th scope="col" className='border-0'>SKU</th>
                                    <th scope="col" className='border-0'>Inventory</th>
                                    <th scope="col" className='border-0'>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {displayVariation?.variations?.map((val, index) =>
                                (
                                    <tr className='mt-3 product_row' key={index}>
                                        <td scope="row" style={{ textAlign: 'left' }} onClick={() => { handleOpenEdit(displayVariation); setOpenModal(false) }}>
                                            <img className="img-fluid me-2" width={40} src={`${url}/product/img/${val?.image[0]?.url}`} />
                                            {val?.Type}
                                        </td>
                                        <td>{val.sku}</td>
                                        <td><div><p style={{ color: val?.stockStatus === "In Stock" ? "#48AF25" : "#E65925" }}>{val?.stockStatus}</p><p>{val?.inventory}</p></div></td>
                                        <td className={`status-cell ${getStatusColorClass(displayVariation?.status)}`}>{displayVariation?.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-center">

                            <button
                                style={{ width: "163px" }}
                                className="Modal-btn first-modal-btn "
                                onClick={() => { setOpenModal(false) }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
