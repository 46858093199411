import React, { useEffect } from 'react'
import "./CompanyBillingTransactionTab.css";
import Loader from "../../Layout/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { State } from "country-state-city";
import pdfIcon from "../../../assets/icons/pdfIcon.svg"
import eyeIcon from "../../../assets/icons/eyeIcon.svg"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getOrders } from '../../../actions/userAction/userAction';
import logo from '../../../assets/icons/Logo2.png'
import { Link } from 'react-router-dom';
import ProductIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/ProductIcon.svg"
import AddonIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_addons_icon.svg"
import UserIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_users_icon.svg"
import PlanIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_plan_icon.svg"
const CompanyBillingTransactionTab = () => {
  const dispatch = useDispatch()
  const { orders, loading } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getOrders())
  }, [])
  let filteredOrders = orders;
  // Filter orders based on user role and user ID
  if (user?.role === 'teammember') {
    filteredOrders = orders?.filter((order) => order.user === user._id);
  } else {
    filteredOrders = orders
  }
  const generatePDF = (order) => {
    const accessoriesByOrder = [];
    const accessoriesInOrder = [];
    const addons = []
    if (order.addaddons.length > 0) {
      for (let j = 0; j < order.addaddons.length; j++) {
        const name = order.addaddons[j].addonId.internalname
        const qty = 1
        const price = order.addaddons[j].price
        addons.push({ name, qty, price })
      }
    }
    if (order?.smartAccessories?.length > 0) {
      for (let j = 0; j < order.smartAccessories.length; j++) {
        const accessory = order.smartAccessories[j];
        const productName = accessory?.productId?.name;
        const price = accessory.price;
        const quantity = accessory.quantity;
        accessoriesInOrder.push({ productName, price, quantity });
      }
      accessoriesByOrder?.push(accessoriesInOrder);
    }
    let Name;
    const productNames = [];
    if (order.type === 'Subscription') {
      Name = order.subscription_details.plan + " plan" + " - " + order.subscription_details.billing_cycle
    } else {
      //  Name = accessoriesByOrder[0][0].productName
      accessoriesByOrder[0]?.forEach(product => {
        productNames.push(product.productName);
      });
    }
     
    const paymentDate = new Date(order.paymentDate);
    const formattedPaymentDate = paymentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const totalAmount = order.type === 'Subscription' ? order.subscription_details.total_amount : order.totalAmount;
    // const tax = order.type === 'Subscription' ? order.subscription_details.taxRate : order.tax;
    const taxRate = order.type === 'Subscription' ? order.subscription_details.taxRate : order.tax;
    const tax = (parseFloat(totalAmount) * parseFloat(taxRate) / 100).toFixed(2); // Convert percentage to decimal and calculate tax
    let shipping;
    if (order.totalShipping) {
      shipping = order.totalShipping;
      // shipping = order.type === 'Subscription' ? order.shipping_method[0]?.price : order.shipping_method[0]?.price;
    } else {
      shipping = 0;
    }
    let IntialFee;
    if (order?.subscription_details?.InitialSetupFee) {
      IntialFee = order.subscription_details?.InitialSetupFee;
    } else {
      IntialFee = 0;
    }
    const billingState = State?.getStateByCodeAndCountry(order.billingAddress[0].state, order.billingAddress[0].country)?.name;
    const shippingState = State?.getStateByCodeAndCountry(order.shippingAddress[0].state, order.shippingAddress[0].country)?.name;
    const doc = new jsPDF();
    const payStatus = order.paymentStatus;
    // const paymentStatus = 'Paid';
    doc.setFont('helvetica');
    doc.setFillColor(255, 246, 243);
    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
    doc.setFont('helvetica');
    doc.setFontSize(30);
    doc.setTextColor(230, 89, 37);
    doc.text('Invoice', 14, 25);
    if (payStatus === 'paid') {
      const boxWidth = 20;
      const boxHeight = 8;
      const borderRadius = 2;
      const boxX = 50;
      const boxY = 18;
      doc.setFillColor(72, 175, 37);
      doc.setDrawColor(72, 175, 37);
      doc.roundedRect(boxX, boxY, boxWidth, boxHeight, borderRadius, borderRadius, 'FD');
      const textInsideBox = 'Paid';
      const textInsideBoxX = boxX + 6;
      const textInsideBoxY = boxY + 5.5;
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(11);
      doc.text(textInsideBox, textInsideBoxX, textInsideBoxY);
    } else {
      const boxWidth = 20;
      const boxHeight = 8;
      const borderRadius = 2;
      const boxX = 50;
      const boxY = 18;
      doc.setFillColor(233, 58, 58);
      doc.setDrawColor(233, 58, 58);
      doc.roundedRect(boxX, boxY, boxWidth, boxHeight, borderRadius, borderRadius, 'FD');
      const textInsideBox = 'Unpaid';
      const textInsideBoxX = boxX + 3.7;
      const textInsideBoxY = boxY + 5.5;
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(11);
      doc.text(textInsideBox, textInsideBoxX, textInsideBoxY);
    };
    doc.addImage(logo, 'PNG', 120, 5, 80, 30);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Billing Address', 14, 60);
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    if (order.billingAddress[0].line2 === '') {
      doc.text(order.billingAddress[0].line1, 14, 65);
      doc.text(order.billingAddress[0].city, 14, 70);
      doc.text(billingState, 14, 75);
      doc.text(order.billingAddress[0].postal_code, 14, 80);
      doc.text(order.userdata.email, 14, 85);
      doc.text(order.userdata.contact.toString(), 14, 90);
    } else {
      doc.text(order.billingAddress[0].line1, 14, 65);
      doc.text(order.billingAddress[0].line2, 14, 70);
      doc.text(order.billingAddress[0].city, 14, 75);
      doc.text(billingState, 14, 80);
      doc.text(order.billingAddress[0].postal_code, 14, 85);
      doc.text(order.userdata.email, 14, 90);
      doc.text(order.userdata.contact.toString(), 14, 95);
    }
    doc.setFontSize(11);
    doc.setFont('helvetica', 'bold');
    doc.text('Shipping Address', 80, 60);
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    doc.text(order.shippingAddress[0].line1, 80, 65);
    doc.text(order.shippingAddress[0].line2, 80, 70);
    doc.text(order.shippingAddress[0].city, 80, 75);
    doc.text(shippingState, 80, 80);
    doc.text(order.shippingAddress[0].postal_code, 80, 85);
    doc.text(order.userdata.contact.toString(), 80, 90);
    const entries = [
      { label: 'Invoice Date: ', value: formattedPaymentDate, xPosLabel: 140, xPosValue: 162, yPos: 60 },
      { label: 'Invoice No: ', value: order.orderNumber.toString(), xPosLabel: 140, xPosValue: 158, yPos: 65 },
      { label: 'Order No: ', value: order.orderNumber.toString(), xPosLabel: 140, xPosValue: 156, yPos: 70 },
      { label: 'Order Date: ', value: formattedPaymentDate, xPosLabel: 140, xPosValue: 159, yPos: 75 },
    ];
    entries?.forEach((entry) => {
      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal');
      doc.text(entry.label, entry.xPosLabel, entry.yPos);
      doc.setFont('helvetica', 'bold');
      doc.text(entry.value, entry.xPosValue, entry.yPos);
    });
    const tableData = [];
    const PlanTotal = order?.subscription_details?.total_user[0]?.baseUser * order?.subscription_details?.perUser_price;
    if (order.type === 'Subscription') {
      const PlanTotalFormatted = `$${PlanTotal.toFixed(2)}`;
      tableData.push([Name, '      1', '', PlanTotalFormatted]);
    }
    // Add rows for each product dynamically
    if (order.type === 'smartAccessories') {
      accessoriesByOrder[0]?.forEach(accessory => {
        tableData.push([accessory.productName, accessory.quantity, ' ', `$${accessory.price * accessory.quantity}`,]);
      });
    }
    if (order.type === 'AddonPurchase') {
      addons?.forEach((Addons => {
        tableData.push([Addons.name, Addons.qty, ' ', Addons.price])
      }))
    }
    const columnStyles = {
      2: {
        cellWidth: 20,
      },
    };
    const shippingTotal = parseFloat(shipping);
    const initialtotal = parseFloat(IntialFee);
    const taxTotal = parseFloat(tax);
    const discountTotal = 0;
    const subtotalTotal = parseFloat(totalAmount);
    const totalTotal = subtotalTotal + shippingTotal + taxTotal - discountTotal;
    const totalTextYPos = 110 + (tableData.length + 1) * 10;
    const lineHeight = 10; // Adjust this value based on your font size and spacing
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    // initial setup 
    doc.text(`Initail Setup Fee:`, 142, totalTextYPos);
    doc.text(`$${initialtotal}`, 180, totalTextYPos);
    // Shipping line
    doc.text(`Shipping:`, 142, totalTextYPos + lineHeight);
    doc.text(`$${shippingTotal}`, 180, totalTextYPos + lineHeight);
    // Taxes line
    doc.text(`Taxes`, 142, totalTextYPos + 2 * lineHeight);
    doc.text(`$${taxTotal}`, 180, totalTextYPos + 2 * lineHeight);
    // Discount line
    doc.text(`Discount`, 142, totalTextYPos + 3 * lineHeight);
    doc.text(`$${discountTotal}`, 180, totalTextYPos + 3 * lineHeight);
    doc.rect(142, totalTextYPos + 3 * lineHeight + 2, 43, 0.1, 'F'); // Border bottom
    doc.setFontSize(10);
    doc.text(`Total`, 142, totalTextYPos + 4 * lineHeight);
    doc.setFontSize(15);
    doc.setTextColor(230, 89, 37);
    doc.text(`$${totalTotal}`, 180, totalTextYPos + 4 * lineHeight);
    function numberToWords(number) {
      const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
      const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
      const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      function convertHundred(num) {
        if (num > 99) {
          return units[Math.floor(num / 100)] + ' Hundred ' + convertTen(num % 100);
        } else {
          return convertTen(num);
        }
      }
      function convertTen(num) {
        if (num < 10) {
          return units[num];
        } else if (num >= 11 && num <= 19) {
          return teens[num - 11];
        } else {
          return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
        }
      }
      if (number === 0) {
        return 'Zero';
      } else {
        const words = convertHundred(number);
        return words.trim() + ' Dollars';
      }
    }
    const totalTotalInWords = numberToWords(Math.floor(totalTotal));
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.setFont('helvetica', 'normal');
    doc.text('Invoice total in words ', 14, 145);
    doc.setFontSize(15);
    doc.setTextColor(230, 89, 37);
    doc.text(`${totalTotalInWords}`, 14, 153);
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Thank you for purchasing', 14, 190);
    doc.autoTable({
      head: [['Name', 'Quantity', '', 'Total Price']],
      body: tableData,
      // theme: 'grid',
      startY: 110,
      headStyles: {
        fillColor: '#E65925',
      },
      columnStyles: columnStyles,
      didDrawCell: function (data) {
        if (data.row.index === tableData.length - 1 && data.column.index === 3) {
          doc.setFont('bold');
        } else {
          doc.setFont('normal');
        }
      },
    });
    doc.save('billing_transactions.pdf');
  };
  return (
    <>
      <>
        <div className="onetap_conn_transaction_table">
          <div className="tran-title-main-div">
            <span className=''>Orders</span>
            <div className='trans-comm-text-center'>
              <span>Status</span>
            </div>
            <span className='trans-comm-text-center'>Total </span>
            <div className='trans-comm-text-center'>
              <span>Date</span>
            </div>
            <div className='trans-comm-text-center'>
              <span>Type</span>
            </div>
            <span>    </span>
          </div>
          {loading ?
            (<Loader />) : (
              <div className="trans-main-card-container">
                {filteredOrders?.map((e, index) => {
                  const paymentDate = new Date(e.paymentDate);
                  const formattedPaymentDate = paymentDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  });
                  const totalAmount = e.type === 'Subscription' ? (!e.subscription_details.total_amount ? "0" : e.subscription_details.total_amount) : e.totalAmount;
                  const taxRate = e.type === 'Subscription' ? e.subscription_details.taxRate : e.tax;
                  const tax = (parseFloat(totalAmount) * parseFloat(taxRate) / 100).toFixed(2); // Convert percentage to decimal and calculate tax
                  let shipping;
                  if (e.shipping_method.length > 0) {
                    shipping = e.type === 'Subscription' ? e.shipping_method[0]?.price : e.shipping_method[0]?.price;
                  } else {
                    shipping = 0;
                  }
                  const total = parseInt(totalAmount, 10) + parseFloat(tax) + parseInt(shipping, 10)
                  let SubscriptionText = '';
                  let Addons = '';
                  let Accessories = '';
                  let Usercount = ''
                  if (e.type === 'Subscription') {
                    if (e?.subscription_details?.total_user[0]?.additionalUser > 0 && e?.subscription_details?.addones?.length > 0) {
                      Usercount = UserIcon;
                      SubscriptionText = PlanIcon;
                      Addons = AddonIcon;
                    } else if (e?.subscription_details?.addones?.length > 0) {
                      SubscriptionText = PlanIcon;
                      Addons = AddonIcon;
                    } else if (e?.subscription_details?.total_user[0]?.additionalUser > 0) {
                      SubscriptionText = PlanIcon;
                      Usercount = UserIcon;
                    } else {
                      SubscriptionText = PlanIcon;
                    }
                  } else if (e.type === 'smartAccessories') {
                    Accessories = ProductIcon;
                  } else if (e.type === 'AddonPurchase') {
                    Addons = AddonIcon;
                  } else if (e.type === 'UserPurchase') {
                    Usercount = UserIcon;
                  }
                  return (<div key={index} className="trans-cards">
                    <div className="add-new-btn-group">
                      <div className="product-details">
                        <span className="product-name-text trans-ordernumber-text">#{e.orderNumber}</span>
                        <span className="trans-view-text"><Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/ordersummary/${e._id}`}>View</Link></span>
                      </div>
                    </div>
                    <div className="trans-comm-text-center">
                      <span className="trans-comm-text-status" style={{ backgroundColor: '#F2F2F2' }}>
                        <p style={{
                          backgroundColor: e.status === 'Failed' ? '#FDE9E9' : e.status === 'Pending' ? '#FFF' : '#F0FEEB',
                          color: e.status === 'Failed' ? '#E62525' : '#48AF25',
                          border: e.status === 'Pending' ? '1px solid #333' : '1px solid #48AF25', padding: '10px', borderRadius: '5px', width: '150px', display: 'inline-block', margin: '0px'
                        }}>
                          {e.status}
                        </p>
                      </span>
                    </div>
                    <span className="trans-comm-text-center trans-amount-text">${total}</span>
                    <span className="trans-comm-text-center trans-amount-text">
                      {formattedPaymentDate}
                    </span>
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                      {Accessories && <img src={Accessories} title="Smart Accessories" alt='' />}
                      {SubscriptionText && <img src={SubscriptionText} title="Plan" alt='' />}
                      {Addons && <img src={Addons} title="Add-ons" alt='' />}
                      {Usercount && <img src={Usercount} title="Additional user" alt='' />}
                    </div>
                    <div className="trans-pdf-icon-div trans-comm-text-center" style={{opacity:0.5}}>
                      <img className='trans-pdf-icon' src={pdfIcon} alt="" />
                      {/* <img className='trans-pdf-icon' onClick={() => generatePDF(e)} src={pdfIcon} alt="" /> */}

                    </div>
                    <div className="trans-checkbox-eye-icon-div">
                      <div className='trans-group-icon'>
                        { }
                        <Link to={`/ordersummary/${e._id}`}>
                          <img
                            style={{ cursor: 'pointer' }}
                            src={eyeIcon}
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </div>)
                })}
              </div>
            )}
        </div>
      </>
    </>
  )
}
export default CompanyBillingTransactionTab