import React, { useState } from "react";
import "./CardProfile.css";
import Sidebar from "../../../Layout/Sidebar/Sidebar";
import CardProfileHeadersWithIcons from "./CardProfileHeadersWithIcons";
import CardProfileGeneralInfoTab from "./cardProfileGeneralInfoTab";
import CardProfileAboutInfoTab from "./CardProfileAboutInfoTab";
import CardProfileBrandingInfoTab from "./CardProfileBrandingInfoTab";
import CardProfileEditCardTab from "./CardProfileEditCardTab";
import CardProfileSettingTab from "./CardProfileSettingTab";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("Edit Information");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };

  const getValueOfSaveAndContinueTab = (value) => {

    setCompanyProfileTab(value);
  };

  return (
    <div className="">
      <div className="onetap_company_profile_syn">
        <CardProfileHeadersWithIcons
          onChange={getTabValue}
          tab={companyProfileTab}
        />

        <div className="">
          {companyProfileTab === "Edit Information" && (
            <CardProfileGeneralInfoTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
          {companyProfileTab === "Edit About" && (
            <CardProfileAboutInfoTab onClickSave={getValueOfSaveAndContinueTab} />
          )}
          {companyProfileTab === "Edit Branding" && (
            <CardProfileBrandingInfoTab onClickSave={getValueOfSaveAndContinueTab} />
          )}
          {companyProfileTab === "Edit Card" && (
            <CardProfileEditCardTab onClickSave={getValueOfSaveAndContinueTab} />
          )}
          {companyProfileTab === "Edit Setting" && (
            <CardProfileSettingTab onClickSave={getValueOfSaveAndContinueTab} />
          )}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};

export default Dashboard;
