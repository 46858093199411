
import React, { useState } from "react";
import PlanHeaderWithIcon from "./PlanHeaderWithIcon"
import Otc_Sidebar from "../../Layout/Sidebar/Otc_Sidebar";
import Plan from "./Plan";
import PlanComarison from "./PlanComparison"
const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Plan");
    const [showAddPlan, setShowAddPlan] = useState(false); 
    const [showFeatureDetail, setShowFeatureDetail] = useState(false)
    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };
    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    const handlePlansClick = () => {
        setShowAddPlan(false);
        setShowFeatureDetail(false);
    };
    const handlePlanComparisonClick = () => {
        setShowFeatureDetail(false);
    };
    const handleAddNewPlanClick = () => {
        setShowAddPlan(true);
    }
    const handleAddNewClick = () => {
        setShowFeatureDetail(true);
    }
    const handleEditPlan = () => {
        setShowAddPlan(true);
    };
    const handlePlanComparisonDetail = () => {
        setShowFeatureDetail(true);
    };
    return (
        <div className="onetap_conn_user" style={{ padding: "0" }}>
            <PlanHeaderWithIcon onChange={getTabValue} tab={companyProfileTab} onPlansClick={handlePlansClick}
                onAddNewPlanClick={handleAddNewPlanClick} showAddPlan={showAddPlan} showFeatureDetail={showFeatureDetail} onAddNewClick={handleAddNewClick} />

            {companyProfileTab === "Plan" && (
                <Plan onClickSave={getValueOfSaveAndContinueTab} showAddPlan={showAddPlan} handleEditPlanOpen={handleEditPlan} back={handlePlansClick} />
            )}
            {companyProfileTab === "Plancomparison" && (
                <PlanComarison onClickSave={getValueOfSaveAndContinueTab} showFeatureDetail={showFeatureDetail} handleEditPlanOpen={handlePlanComparisonDetail} back={handlePlanComparisonClick} />
            )}
        </div>
    );
};


export default function PlanMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} productsServicesOpen={true} />
        </>
    )
}





