import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import open_eye from "../../../assets/icons/eye.svg";
import close_eye from "../../../assets/icons/eye-off.svg";
import { useSelector, useDispatch } from "react-redux";
import PasswordChecklist from "react-password-checklist"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal, Box } from '@mui/material';
import AccountModal from "../../Customers/User/AccountModal.js";
import axios from 'axios';
import {
  loadUser,
} from "../../../actions/userAction/userAction";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import OTPInput from "../User/AccountModelOTPinput"
import Loader from "../../Layout/Loader/Loader.jsx";

const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  class="circle"
>
  <path
    d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
    fill="#E65925"
  />
  <path
    d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
    stroke="white"
    stroke-width="6.64"
    stroke-linecap="round"
    class="checkmark-path"
  />
</svg>

export default function MyAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_URL;
  const { user, loading } = useSelector(
    (state) => state.user
  );
  const { userInfo } = useSelector((state) => state.userInformation);
  const plan = userInfo?.subscription_details?.plan
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState(user?.first_name + ' ' + user?.last_name || '')
  const [currentPwd, setCurrentPwd] = useState()
  const [newPwd, setNewPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')
  const [error, setError] = useState('')
  const [currentError, setCurrentError] = useState('')
  const [success, setSuccess] = useState('')
  const [isCurrentPwdVisible, setIsCurrentPwdVisible] = useState(false);
  const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
  const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);
  const [showDeactivatePopup, setShowDeactivatePopup] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const handlePasswordValidation = (isValid) => {
    setIsPasswordValid(isValid);
  };
  const handlePasswordInputClick = () => {
    setShowPasswordChecklist(true);
  };
  const useremail = user && user.email
  const userfirstname = user && user.first_name


  const toggleCurrentPwdVisibility = () => {
    setIsCurrentPwdVisible((prev) => !prev);
  };

  const toggleNewPwdVisibility = () => {
    setIsNewPwdVisible((prev) => !prev);
  };

  const toggleConfirmPwdVisibility = () => {
    setIsConfirmPwdVisible((prev) => !prev);
  };
  useEffect(() => {
    if (!user) {
      dispatch(loadUser());
    } else {
      setName(user?.first_name + ' ' + user?.last_name || '')
    }
  }, [user]);

  const handleAccountOptionClick = () => {
    setIsAccountModalOpen(true);
  };
  const handleModalClose = () => {
    setIsAccountModalOpen(false);
  };
  const handleDeleteAccount = () => {
    setIsAccountModalOpen(false);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const handlePasswordVerify = async () => {
    setLoader(true);
    const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;
    
    if (!currentPwd) {
      setLoader(false);
      setCurrentError("Please enter your password.");
      return;
    } else if (!newPwd) {
      setLoader(false);
      setError("Please enter your password.");
      return;
    } else if (newPwd.length < 8) {
      setLoader(false);
      setError("Password must be at least 8 characters.");
      return;
    } else if (!passwordRegex.test(newPwd)) {
      setLoader(false);
      setError(
        "Must contain at least one uppercase, lowercase letter, one special character, and one number"
        );
        return;
      } else if (confirmPwd !== newPwd) {
      setLoader(false);
      setError("Passwords do not match. Please make sure the confirmation password matches the new password.");
      return;
    }
    try {
      const response = await axios.post(`${url}/verify_pass`,
        {
          currentPassword: currentPwd,
          newPassword: newPwd
        },
        config);
      setError('')
      setCurrentError('')
      if (response.data.success) {
        setLoader(false);
        setShowPasswordChecklist(false)
        setError('')
        setCurrentError('')
        setSuccess("Password updated.");
        setApiSuccess(true)
        setTimeout(() => {
          setApiSuccess(false)
        }, 5000);
        // Clear the success message after 5 seconds
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.message === 'Old password is invalid') {
        setCurrentError("Incorrect password.");
        return;
      }
    }
  }

  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [sendingotpError, setsendingotpError] = useState('')
  const [otp, setOtp] = useState('');
  const [otpEntered, setOtpEntered] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [countdown, setCountdown] = useState(600);
  const openDeactivatePopup = () => {
    setShowDeactivatePopup(true);
  };
  const closeDeactivatePopup = () => {
    setShowDeactivatePopup(false);
    setOtpSent(false);
    setOtpError();
    setCountdown(600);
    setOtpExpired(false);
    setOtpError(false);
    setSuccessMessage(false);
    setOtpEntered(false);
    setsendingotpError(false)
  };
  const handleDeactivateAccount = async () => {
    try {
      const { data } = await axios.post(
        `${url}/generate-otp`,
        {
          email: useremail,
          firstname: userfirstname,
          status: 'deactivate',
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (data.success) {
        setOtpSent(true);
        setOtpEntered(true);
      } else {
        setsendingotpError('Internal server error, Please try again later')
      }
    } catch (error) {
      setsendingotpError('Internal server error, Please try again later')
    }
  };
  const verifydeactivateaccountOTP = async () => {
    if (!otp) {
      setOtpError('Please Enter OTP.');
      return;
    }
    setOtpError('');

    try {
      const { data } = await axios.post(
        `${url}/deactivate/verify-otp`,
        {
          email: useremail,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (data.success) {
        const sub_id = data.user_subscription_id;
        if (plan !== 'Free') {
          handlecancel_sub(sub_id);
        } else if (plan === 'Free') {
          let countdown = 5;
          const countdownInterval = setInterval(() => {
            if (countdown > 0) {
              setSuccessMessage(`OTP verified successfully. Reloding in ${countdown} seconds...`);
              countdown--;
            } else {
              clearInterval(countdownInterval);

              window.location.href = `/billing`;
            }
          }, 1000);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Incorrect OTP") {
          setOtpError(errorMessage);
        } else if (errorMessage === "OTP has expired") {
          setOtpError('OTP Expired');
        } else if (errorMessage === "Internal server error") {
          setOtpError('Internal server error')
        }
      }
    }
  };
  const handlecancel_sub = async (sub_id) => {
    try {

      const cancelSubscriptionResponse = await axios.post(`${url}/payment/cancelSubscription/deactivateaccount`,
        { subId: sub_id, currentPlan: plan },
      );
      if (cancelSubscriptionResponse.data.success) {

        let countdown = 5;
        const countdownInterval = setInterval(() => {
          if (countdown > 0) {
            setSuccessMessage(`OTP verified successfully. Reloding in ${countdown} seconds...`);
            countdown--;
          } else {
            clearInterval(countdownInterval);

            window.location.href = `/billing`;
          }
        }, 1000);
      } else {

      }
    } catch (error) {

    }
  };
  useEffect(() => {
    let countdownInterval;

    if (otpEntered) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(prevCountdown => prevCountdown - 1);
        } else {
          clearInterval(countdownInterval);
          setOtpExpired(true); // Set OTP as expired when countdown reaches zero
        }
      }, 1000);
    }

    return () => clearInterval(countdownInterval); // Cleanup the interval on component unmount
  }, [otpEntered, countdown]);
  const deleteAccountbuttonStyle = {
    margin: "5px",
    padding: "10px 20px",
    width: "45%",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#e65925",
    border: "#e65925",
    color: "#fff",
  };
  const deleteAccountcanclebuttonStyle = {
    margin: "5px",
    padding: "9px 47px",
    width: "45%",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "#e65925",
    color: "#e65925",
    border: "1px solid #e65925"
  };

  return (
    <>
    {loader || loading ? (
      <Loader />
    ) : (
    <>
      <div className="onetap_conn_users_tab_content_OTC_Admin" style={{ minHeight: 'unset' }}  >
        <h3 className='mt-0 mb-2 pc-header-text'>My Account</h3>
        <p className=' pb-2 border-bottom'>
          Welcome to your account page. You can change your password and edit your information/data here.
        </p>
        <div className='d-flex align-items-center pb-2'>
          <span className="" style={{ width: '15%' }}>Name</span>
          <input className="w-100 border-0 p-2 ms-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="name" readOnly={!isEditable} value={name} onChange={(e) => setName(e.target.value)} />
          <button className="mb-0 px-4 p-2 ms-2" style={{ color: '#E65925', border: '1px solid #E65925', borderRadius: " 4px", background: "#fff" }}
            onClick={() => {
              if (plan === 'Team') {
                navigate(`/user/information/${user?._id}`);
              } else {
                navigate(`/profile/information/${user?._id}`);
              }
            }}>Edit</button>
        </div>
        <>
          <div className='mt-3 d-flex align-items-center pb-2'>
            <span className="" style={{ width: '14%' }}>Current Password</span>
            <div className="w-100">
              <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                <input
                  className="w-100 border-0 p-2"
                  type={isCurrentPwdVisible ? "text" : "password"}
                  style={{ borderRadius: "4px", background: "#f2f2f2" }}
                  name="name"
                  value={currentPwd}
                  onChange={(e) => {
                    setCurrentPwd(e.target.value);
                    setCurrentError(""); // Clear the error message
                  }}
                />
                <img
                  src={isCurrentPwdVisible ? close_eye : open_eye}
                  alt="toggle-password-visibility"
                  className="eye-icon me-2"
                  onClick={toggleCurrentPwdVisibility}
                />
              </div>
              <span className="mt-2" style={{ color: '#E62525' }}>{currentError}</span>
            </div>
          </div>
          <div className=" w-100 d-flex align-items-center pb-2  justify-content-between">

            <div className='w-50 mt-3 d-flex align-items-center'>
              <span className="" style={{ width: '33%' }}>New Password</span>
              <div className=" d-flex align-items-center w-100">
                <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                  <input
                    className="w-100 border-0 p-2"
                    type={isNewPwdVisible ? "text" : "password"}
                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                    name="code"
                    value={newPwd}
                    onChange={(e) => { setNewPwd(e.target.value); setError('') }}
                    onClick={handlePasswordInputClick}
                  />
                  <img
                    src={isNewPwdVisible ? close_eye : open_eye}
                    alt="toggle-password-visibility"
                    className="eye-icon me-2"
                    onClick={toggleNewPwdVisibility}
                  />
                </div>
              </div>

            </div>

            <div className='w-50 mt-3  ms-2 d-flex align-items-center '>
              <span className="" style={{ width: '33%' }}>Confirm Password</span>
              <div className="d-flex align-items-center w-100">
                <div className="w-100 position-relative d-flex align-items-center" style={{ borderRadius: "4px", background: "#f2f2f2" }}>
                  <input
                    className="w-100 border-0 p-2"
                    type={isConfirmPwdVisible ? "text" : "password"}
                    style={{ borderRadius: "4px", background: "#f2f2f2" }}
                    name="confirm"
                    value={confirmPwd}
                    onChange={(e) => setConfirmPwd(e.target.value)}
                    onClick={handlePasswordInputClick}
                  />
                  <img
                    src={isConfirmPwdVisible ? close_eye : open_eye}
                    alt="toggle-password-visibility"
                    className="eye-icon me-2"
                    onClick={toggleConfirmPwdVisibility}
                  />
                </div>
              </div>
            </div>
          </div>

          {showPasswordChecklist && <PasswordChecklist className="password-checklist" style={{ color: "white", display: "block" }}
            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
            minLength={8}
            value={newPwd}
            valueAgain={confirmPwd}
            messages={{
              minLength: "At least 8 characters",
              specialChar: "Contains one speacial character (@,#,$,%,etc.)",
              number: "Contains one number",
              capital: "Contains one uppercase letter",
              lowercase: "Contains one lowercase letter",
              match: "Passwords match.",
            }}
            invalidTextColor="red"
            validTextColor="green"
            onChange={handlePasswordValidation}
          />}

          <style jsx>{`
  /* Override display: flex for the li elements in the PasswordChecklist component */
  .password-checklist li {
    display: block !important;
    /* Use !important to ensure the override */
    /* Add any additional styles or modifications as needed */
  }
  .password-checklist li span{
padding: 2px 7px !important;
  }
  .password-checklist{
    padding : 0 0 0 155px !important;
  }

  /* You may need to use even more specific selectors depending on the library's styles */
`}</style>
          <div className="d-flex justify-content-end">
            <button style={{ opacity: isPasswordValid ? 1 : 0.5, width: '15%', color: '#fff', border: '1px solid #E65925', borderRadius: " 4px", background: "#E65925" }} disabled={!isPasswordValid} className="mb-0 p-2 px-0 ms-2 mt-3" onClick={() => handlePasswordVerify()}>Update password</button>
          </div>
          <span className="mt-2" style={{ color: '#E62525' }}>{error}</span>
          <span className="mt-2" style={{ color: 'green' }}>{success}</span>
        </>
      </div>

      {user?.Account_status === 'is_Activated' && user?.role === 'superadmin' &&
        <div className="onetap_conn_users_tab_content_OTC_Admin mt-3" style={{ minHeight: 'unset' }} >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className='pc-header-text' >Deactivate account</h3>
            <button className="mb-0 p-2 px-0 ms-2 mt-0" style={{ width: '15%', color: '#fff', border: '1px solid #E65925', borderRadius: " 4px", background: "#E65925" }} onClick={openDeactivatePopup} >Deactivate account</button>
          </div>
          <p>By deactivating your account, your OneTapConnect subscription will be canceled (please note that no refund will be provided). All users account will be deactivated, and all access will be disabled.</p>
        </div>}
      {user?.role === 'superadmin' &&
        <div className="onetap_conn_users_tab_content_OTC_Admin mt-3" style={{ minHeight: 'unset' }} >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className='pc-header-text' >Delete account</h3>
            <button onClick={handleAccountOptionClick} className="mb-0 p-2 px-0 ms-2 mt-0" style={{ width: '15%', color: '#fff', border: '1px solid #F08871', borderRadius: " 4px", background: '#F08871' }}  >Delete account</button>
          </div>
          <p>Your information will be archived so you can regain access to your account and restore its data at a later time before 90 days. If you desire to completely delete your account and all associated personal data, An option to do so will be provided once your account has been deactivated.</p>
        </div>}
      <Dialog open={showDeactivatePopup} onClose={closeDeactivatePopup}>
        <DialogTitle style={{ textAlign: 'center' }}>Deactivate Account</DialogTitle>
        <DialogContent style={{ paddingBottom: '5px' }}>
          {otpSent ? (
            <>
              <p style={{ textAlign: 'center' }}>Enter the OTP received on your email:</p>
              <OTPInput length={6} onComplete={setOtp}
                onChange={(e) => {
                  setOtp(e.target.value);
                  setOtpError('');
                }} />
            </>
          ) : (
            <p style={{ textAlign: 'center' }}>Are you sure you want to deactivate your account?</p>
          )}
          {otpError && <p style={{ color: "red", textAlign: 'center' }}>{otpError}</p>}
          {otpEntered && (
            <div style={{ fontSize: '15px', marginBottom: '10px', textAlign: 'center' }}>
              OTP expiring in &nbsp;
              <span style={{ color: '#e65925' }}>
                {Math.floor(countdown / 60).toString().padStart(2, '0')}
              </span>:
              <span style={{ color: '#e65925' }}>
                {(countdown % 60).toString().padStart(2, '0')}
              </span>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', display: 'block' }}>
          {otpSent ? (
            <div>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={verifydeactivateaccountOTP} style={deleteAccountbuttonStyle}>Verify OTP</Button>
                <Button onClick={closeDeactivatePopup} style={deleteAccountcanclebuttonStyle}>Cancel</Button>
              </div>
              <p style={{ fontSize: '13px', color: 'red', margin: '10px 0px 0px 0px', textAlign: 'center' }}>
                Caution: Once you deactivate your account, your subscription will also be canceled.
              </p>
              <div>
                {otpExpired && (
                  <div style={{ color: '#e65925', marginTop: '10px', textAlign: 'center' }}>
                    <span style={{ cursor: 'pointer' }} >Resend OTP</span>
                  </div>
                )}
                {successMessage && (
                  <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
                    {successMessage}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={handleDeactivateAccount} style={deleteAccountbuttonStyle}>
                  Send OTP
                </Button>
                <Button onClick={closeDeactivatePopup} style={deleteAccountcanclebuttonStyle}>
                  Cancel
                </Button>
              </div>
              {sendingotpError && <div style={{ color: 'red' }}>{sendingotpError}</div>}
              <p style={{ fontSize: '13px', color: 'red', margin: '10px 0px 0px 0px', textAlign: 'center' }}>
                Caution: Once you deactivate your account, your subscription will also be canceled.
              </p>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: "center" }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                {messageIcon}
              </div>
            </div>
            <span className="team_created_success">
              Password updated successfully.
            </span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              width: "13%",
            }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
            </span>
          </button>
        </Box>
      </Modal>

      <AccountModal
        isOpen={isAccountModalOpen}
        onClose={handleModalClose}
        onDeleteAccount={handleDeleteAccount}
      />
    </>
  )}
  </>
  )
}
