import React, { useEffect, useState } from "react";
import "./templates.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer } from "react-toastify";
import phoneImg from "../../../assets/images/card-image.svg";
import { Button, Menu, MenuItem } from "@mui/material";
import {
  getTeamName,
  getCompanyProfileDetails,
} from "../../../actions/userAction/userAction";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: ".625rem",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: ".625rem",
};
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "37.125rem",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: ".625rem",
};

const TemplatesTab = () => {
  const url = process.env.REACT_APP_URL;
  const cardEditorUrl = process.env.REACT_APP_CARD_EDITOR_URL;
  const dispatch = useDispatch();
  const { company, loading: companyLoading } = useSelector(
    (state) => state.Company
  );
  const { user } = useSelector((state) => state.user);
  const { team } = useSelector((state) => state.team);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openStarter, setOpenStarter] = React.useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Published");
  const [selectedTeam, setSelectedTeam] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [editingId, setEditingId] = useState("");
  const [nameError, setNameError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [openPreview, setOpenPreview] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setNameError("");
    setSelectedTeam("");
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setName("");
    setDescription("");
    setNameError("");
    setEditingId("");
    setSelectedTeam("");
  };
  const handleCloseStarter = () => {
    setOpenStarter(false);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [defaultTemplateCardTemp, setDefaultTemplateCardTemp] = useState([]);

  const getTemplates = async () => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    }

    if (company) {
      const response = await axios.get(
        `${url}/getAllTemplatesOfCompany/${company?._id}`
      );

      if (response.data.success) {
        setAllTemplates(response.data.templates);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${url}/admin/templates/default`)
      .then((response) => {
        if (response) {
          setDefaultTemplate(response.data);
          console.log("crad tem--->", response.data.card_temp[0]);

          const newCardTemp = response.data.card_temp[0];

          if (newCardTemp && newCardTemp.content) {
            newCardTemp.content = newCardTemp.content.map((element) => {
              if (element.type === "Header" && element.props) {
                if (
                  "Designations" in element.props ||
                  "Name" in element.props ||
                  "Company" in element.props ||
                  "socialLinks" in element.props
                ) {
                  // delete element.props;
                  delete element.props.Designations;
                  delete element.props.Name;
                  delete element.props.Company;
                  delete element.props.socialLinks;
                }
              }
              return element;
            });
          }

          console.log("Modified card_temp--->", newCardTemp);
          setDefaultTemplateCardTemp(newCardTemp);
        }
      })
      .catch((error) =>
        console.error("Error fetching default template for client:", error)
      );
  }, []);

  const handleCreate = async (id, card_temp) => {
    if (!name) {
      setNameError(true);
      return;
    }
    setNameError(false);
    let datatoSend = {};
    if (id) {
      datatoSend = {
        _id: id,
        company: company?._id,
        name,
        description,
        status,
        Assigned_To: selectedTeam?.value,
      };
    } else {
      datatoSend = {
        company: company._id,
        name,
        description,
        status,
        Assigned_To: selectedTeam?.value,
        card_temp: [
          {
            content: card_temp.content,
            root: [],
          },
        ],
        // card_temp: card_temp
      };
    }

    const response = await axios.post(
      `${url}/createTemplatesOfCompany`,
      {
        datatoSend,
        id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (response.data.success) {
      getTemplates();
      handleClose();
      handleClosePreview();
      handleCloseStarter();
      handleEditClose();
      window.location.href = `${cardEditorUrl}/edit?uid=${user?._id}&templateid=${response.data.newTemplate._id}`;
    }
  };
  useEffect(() => {
    getTemplates();
  }, [company]);
  useEffect(() => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    }
  }, [company, dispatch]);
  useEffect(() => {
    dispatch(getCompanyProfileDetails());
    getTemplates();
    dispatch(getTeamName());
  }, []);
  useEffect(() => {
    if (!team) {
      dispatch(getTeamName());
    }
  }, [team]);

  function getStatusColorClass(status) {
    if (status === "Published") {
      return "published";
    } else if (status === "Draft") {
      return "draft";
    } else if (status === "Archived") {
      return "archived";
    } else {
      return ""; // Default styling for other statuses
    }
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null); // Reset the selected index when the menu is closed
  };
  const handleEditDetails = (val) => {
    const matchingTeam = team?.find((item) => item._id === val?.Assigned_To);
    handleMenuClose();
    setName(val?.name);
    setDescription(val?.description);
    setStatus(val?.status);
    setSelectedTeam({
      value: val?.Assigned_To,
      label: matchingTeam?.team_name,
    });
    setOpenEdit(true);
    setEditingId(val?._id);
  };

  // Filter teams that are not assigned to any templates
  const availableTeams = team?.filter((teamItem) => {
    return !allTemplates.some(
      (template) => template?.Assigned_To === teamItem?._id
    );
  });

  const teamOptions = availableTeams?.map((item) => ({
    value: item._id,
    label: item.team_name,
  }));
  const handleEditTemplate = (val) => {
    handleMenuClose();
    setEditingId(val?._id);
    window.location.href = `${cardEditorUrl}/edit?uid=${user?._id}&templateid=${val._id}`;
  };
  const handleDelete = async (id) => {
    const response = await axios.post(
      `${url}/createTemplatesOfCompany`,
      {
        id,
        type: 'Delete'
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.data.success) {
      getTemplates();
    }
  }
  return (
    <>
      <div className="onetap_connect_const_smartaccessoriestab">
        <div className="onetap_connect_only_accessary_child_synr">
          <div className="onetap_connect_smart_access_synr">
            <h3>Templates</h3>
            <p>Here is the list of all your available templates</p>
          </div>
        </div>

        <div
          className="onetap_connect_only_accessary_child_synr"
          style={{ minHeight: "50vh" }}
        >
          <div className="d-flex align-items-center gap-3 flex-wrap">
            {allTemplates?.map((val, i) => {
              const handleMenuClick = (event) => {
                setAnchorEl(event.currentTarget);
                setSelectedIndex(i); // Save the index of the clicked item
              };
              const matchingTeam = team?.find(
                (item) => item._id === val?.Assigned_To
              );

              return (
                <div className="templates_templatebox mb-3" key={i}>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30%" }}
                  >
                    <img
                      src={phoneImg}
                      alt="phoneImg"
                      width={100}
                      height={200}
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="d-flex justify-content-between mt-2"
                    style={{ width: "70%" }}
                  >
                    <div style={{ width: "90%" }}>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Name: </span>
                        <span style={{ fontWeight: "600" }}>{val?.name}</span>
                      </p>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Status: </span>
                        <span
                          style={{ fontWeight: "600" }}
                          className={`${getStatusColorClass(val?.status)}`}
                        >
                          {val?.status}
                        </span>
                      </p>
                      <p>
                        <span style={{ color: "#8A8A8A" }}>Assigned team:</span>
                        <br />
                        <span style={{ fontWeight: "600" }}>
                          {matchingTeam?.team_name}
                        </span>
                      </p>
                    </div>
                    <div>
                      <Button
                        aria-controls={`menu-${i}`}
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                      >
                        <svg
                          role="button"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99935 10.666C8.35297 10.666 8.69211 10.8065 8.94216 11.0565C9.19221 11.3066 9.33268 11.6457 9.33268 11.9993C9.33268 12.353 9.19221 12.6921 8.94216 12.9422C8.69211 13.1922 8.35297 13.3327 7.99935 13.3327C7.64573 13.3327 7.30659 13.1922 7.05654 12.9422C6.80649 12.6921 6.66602 12.353 6.66602 11.9993C6.66602 11.6457 6.80649 11.3066 7.05654 11.0565C7.30659 10.8065 7.64573 10.666 7.99935 10.666ZM7.99935 6.66601C8.35297 6.66601 8.69211 6.80649 8.94216 7.05654C9.19221 7.30659 9.33268 7.64573 9.33268 7.99935C9.33268 8.35297 9.19221 8.69211 8.94216 8.94216C8.69211 9.1922 8.35297 9.33268 7.99935 9.33268C7.64573 9.33268 7.30659 9.1922 7.05654 8.94216C6.80649 8.69211 6.66602 8.35297 6.66602 7.99935C6.66602 7.64573 6.80649 7.30659 7.05654 7.05654C7.30659 6.80649 7.64573 6.66601 7.99935 6.66601ZM7.99935 2.66602C8.35297 2.66602 8.69211 2.80649 8.94216 3.05654C9.19221 3.30659 9.33268 3.64573 9.33268 3.99935C9.33268 4.35297 9.19221 4.69211 8.94216 4.94216C8.69211 5.19221 8.35297 5.33268 7.99935 5.33268C7.64573 5.33268 7.30659 5.19221 7.05654 4.94216C6.80649 4.69211 6.66602 4.35297 6.66602 3.99935C6.66602 3.64573 6.80649 3.30659 7.05654 3.05654C7.30659 2.80649 7.64573 2.66602 7.99935 2.66602Z"
                            fill="black"
                          />
                        </svg>
                      </Button>
                      <Menu
                        id={`menu-${i}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedIndex === i}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            boxShadow: "none",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: 35,
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleEditTemplate(val);
                          }}
                        >
                          Edit template
                        </MenuItem>
                        <MenuItem>View</MenuItem>
                        <MenuItem onClick={() => handleEditDetails(val)}>
                          Edit details
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(val?._id)} >Delete</MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="templates_add_new_box"
              role="button"
              onClick={() => {
                handleOpen();
              }}
            >
              <span style={{ fontSize: "36px", fontWeight: "600" }}>+</span>
              <div className="d-flex align-items-center justify-content-center">
                <p style={{ textAlign: "center" }}>Add New Template</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="sec-Modal-section">
            <div className="sec-modal-header-group border-0">
              <h3 className="header">
                Create Template{" "}
                <CloseIcon className="icon" onClick={handleClose} />
              </h3>
            </div>
            <span>Template name</span>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Ex: California Sales team"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <span className="error-message">Template name is required.</span>
            )}
            <p className="mt-3 mb-0">Description</p>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Card design for Sales team in state of California"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <p className="mt-3 mb-0">Status</p>
            <select
              name="status"
              id="status"
              style={{
                appearance: "none",
                outline: "none",
                cursor: "pointer",
              }}
              className="w-100 smartAccessories_addnew_modal_input"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </select>
            <p className="mt-3 mb-0">Assigned team</p>
            <div className="dropdown countrydrop" style={{ width: "100%" }}>
              <div className="select-container">
                <Select
                  styles={{
                    container: (provided) => ({
                      // ...provided,
                      width: "100%", // Set the width of the container as needed
                    }),
                    control: () => ({
                      // No border or box-shadow
                      margin: 0,
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: 0, // Remove margin
                      padding: 0, // Remove padding
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  }}
                  className="onetap_conn_general_information_input_syn mb-3 form-select"
                  placeholder="Select"
                  name="team"
                  options={teamOptions}
                  value={selectedTeam}
                  onChange={(selectedOption) => {
                    setSelectedTeam(selectedOption);
                  }}
                />
              </div>
            </div>

            <div className="video-Modal-btn-group">
              <button
                className="video-Modal-btn video-first-modal-btn"
                onClick={() => {
                  if (!name) {
                    setNameError(true);
                    return;
                  }
                  setNameError(false);
                  setOpen(false);
                  setOpenStarter(true);
                }}
              >
                Create Template
              </button>
              <button
                className="video-Modal-btn video-sec-modal-btn"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="sec-Modal-section">
            <div className="sec-modal-header-group border-0">
              <h3 className="header">
                Edit Template{" "}
                <CloseIcon className="icon" onClick={handleEditClose} />
              </h3>
            </div>
            <span>Template name</span>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Ex: California Sales team"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && (
              <span className="error-message">Template name is required.</span>
            )}
            <p className="mt-3 mb-0">Description</p>
            <input
              className="w-100 smartAccessories_addnew_modal_input"
              type="text"
              placeholder="Card design for Sales team in state of California"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <p className="mt-3 mb-0">Status</p>
            <select
              name="status"
              id="status"
              style={{
                appearance: "none",
                outline: "none",
                cursor: "pointer",
              }}
              className="w-100 smartAccessories_addnew_modal_input"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="Published">Published</option>
              <option value="Draft">Draft</option>
            </select>
            <p className="mt-3 mb-0">Assigned team</p>
            <div className="dropdown countrydrop" style={{ width: "100%" }}>
              <div className="select-container">
                <Select
                  styles={{
                    container: (provided) => ({
                      // ...provided,
                      width: "100%", // Set the width of the container as needed
                    }),
                    control: () => ({
                      // No border or box-shadow
                      margin: 0,
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: 0, // Remove margin
                      padding: 0, // Remove padding
                    }),
                    indicatorsContainer: () => ({
                      display: "none",
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  }}
                  className="onetap_conn_general_information_input_syn mb-3 form-select"
                  placeholder="Select"
                  name="team"
                  options={[
                    { value: selectedTeam?.value, label: selectedTeam?.label },
                    ...(availableTeams
                      ? availableTeams.map((item) => ({
                        value: item._id,
                        label: item.team_name,
                      }))
                      : []),
                  ]}
                  value={selectedTeam}
                  onChange={(selectedOption) => {
                    setSelectedTeam(selectedOption);
                  }}
                />
              </div>
            </div>
            <div className="video-Modal-btn-group">
              <button
                className="video-Modal-btn video-first-modal-btn"
                onClick={() => {
                  if (!name) {
                    setNameError(true);
                    return;
                  }
                  setNameError(false);
                  setOpen(false);
                  handleCreate(editingId);
                }}
              >
                Edit Template
              </button>
              <button
                className="video-Modal-btn video-sec-modal-btn"
                onClick={handleEditClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openStarter}
        onClose={handleCloseStarter}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div className="sec-Modal-section">
            <div className="sec-modal-header-group border-0">
              <h3 className="header">
                Select a starter template{" "}
                <CloseIcon className="icon" onClick={handleCloseStarter} />
              </h3>
            </div>
            <div class="onetap_connect_search_box_synr w-50">
              <svg
                style={{ position: "absolute", top: "10px", right: "10px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                  fill="#000000"
                />
              </svg>
              <input
                style={{ paddingRight: "50px" }}
                className="onetap_connect_search_input_synr"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <div
              className="d-flex gap-3 mt-3 flex-wrap"
              style={{ maxHeight: "375px", overflowX: "auto" }}
            >
              {defaultTemplate && (
                <div className="starter_templates_templatebox">
                  <div className="d-flex justify-content-between w-100">
                    <div style={{ width: "90%" }}>
                      <p>Default</p>
                    </div>
                    <div
                      onClick={() => {
                        setOpenStarter(false);
                        setOpenPreview(true);
                      }}
                      role="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.99935 10.666C8.35297 10.666 8.69211 10.8065 8.94216 11.0565C9.19221 11.3066 9.33268 11.6457 9.33268 11.9993C9.33268 12.353 9.19221 12.6921 8.94216 12.9422C8.69211 13.1922 8.35297 13.3327 7.99935 13.3327C7.64573 13.3327 7.30659 13.1922 7.05654 12.9422C6.80649 12.6921 6.66602 12.353 6.66602 11.9993C6.66602 11.6457 6.80649 11.3066 7.05654 11.0565C7.30659 10.8065 7.64573 10.666 7.99935 10.666ZM7.99935 6.66601C8.35297 6.66601 8.69211 6.80649 8.94216 7.05654C9.19221 7.30659 9.33268 7.64573 9.33268 7.99935C9.33268 8.35297 9.19221 8.69211 8.94216 8.94216C8.69211 9.1922 8.35297 9.33268 7.99935 9.33268C7.64573 9.33268 7.30659 9.1922 7.05654 8.94216C6.80649 8.69211 6.66602 8.35297 6.66602 7.99935C6.66602 7.64573 6.80649 7.30659 7.05654 7.05654C7.30659 6.80649 7.64573 6.66601 7.99935 6.66601ZM7.99935 2.66602C8.35297 2.66602 8.69211 2.80649 8.94216 3.05654C9.19221 3.30659 9.33268 3.64573 9.33268 3.99935C9.33268 4.35297 9.19221 4.69211 8.94216 4.94216C8.69211 5.19221 8.35297 5.33268 7.99935 5.33268C7.64573 5.33268 7.30659 5.19221 7.05654 4.94216C6.80649 4.69211 6.66602 4.35297 6.66602 3.99935C6.66602 3.64573 6.80649 3.30659 7.05654 3.05654C7.30659 2.80649 7.64573 2.66602 7.99935 2.66602Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>

                  <img src={phoneImg} alt="phoneImg" height={250} width={125} />
                  <p
                    onClick={() => {
                      setOpenStarter(false);
                      setOpenPreview(true);
                    }}
                    role="button"
                  >
                    Preview
                  </p>
                </div>
              )}
              {/* <div className="starter_templates_templatebox">
                <div className="d-flex justify-content-between w-100">
                  <div style={{ width: "90%" }}>
                    <p>Default</p>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99935 10.666C8.35297 10.666 8.69211 10.8065 8.94216 11.0565C9.19221 11.3066 9.33268 11.6457 9.33268 11.9993C9.33268 12.353 9.19221 12.6921 8.94216 12.9422C8.69211 13.1922 8.35297 13.3327 7.99935 13.3327C7.64573 13.3327 7.30659 13.1922 7.05654 12.9422C6.80649 12.6921 6.66602 12.353 6.66602 11.9993C6.66602 11.6457 6.80649 11.3066 7.05654 11.0565C7.30659 10.8065 7.64573 10.666 7.99935 10.666ZM7.99935 6.66601C8.35297 6.66601 8.69211 6.80649 8.94216 7.05654C9.19221 7.30659 9.33268 7.64573 9.33268 7.99935C9.33268 8.35297 9.19221 8.69211 8.94216 8.94216C8.69211 9.1922 8.35297 9.33268 7.99935 9.33268C7.64573 9.33268 7.30659 9.1922 7.05654 8.94216C6.80649 8.69211 6.66602 8.35297 6.66602 7.99935C6.66602 7.64573 6.80649 7.30659 7.05654 7.05654C7.30659 6.80649 7.64573 6.66601 7.99935 6.66601ZM7.99935 2.66602C8.35297 2.66602 8.69211 2.80649 8.94216 3.05654C9.19221 3.30659 9.33268 3.64573 9.33268 3.99935C9.33268 4.35297 9.19221 4.69211 8.94216 4.94216C8.69211 5.19221 8.35297 5.33268 7.99935 5.33268C7.64573 5.33268 7.30659 5.19221 7.05654 4.94216C6.80649 4.69211 6.66602 4.35297 6.66602 3.99935C6.66602 3.64573 6.80649 3.30659 7.05654 3.05654C7.30659 2.80649 7.64573 2.66602 7.99935 2.66602Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                </div>

                <img src={phoneImg} alt="phoneImg" height={250} width={125} />
                <p
                  onClick={() => {
                    setOpenStarter(false);
                    setOpenPreview(true);
                  }}
                  role="button"
                >
                  Preview
                </p>
              </div> */}
            </div>
          </div>
        </Box>
      </Modal>

      {defaultTemplate && (
        <Modal
          open={openPreview}
          onClose={handleClosePreview}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style3}>
            <div className="sec-Modal-section">
              <div className="sec-modal-header-group border-0">
                <h3 className="header">Default preview </h3>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <img
                  src={phoneImg}
                  alt="phoneImg"
                  className="w-50 img-responsive"
                />
                <div className=" d-flex flex-column">
                  <p>
                    <span style={{ color: "#8A8A8A" }}>Name: </span>
                    <br />
                    <span style={{ fontWeight: "600" }}>
                      {defaultTemplate.name}
                    </span>
                  </p>
                  <p>
                    <span style={{ color: "#8A8A8A" }}>Description:</span>
                    <br />
                    <span style={{ fontWeight: "600" }}>
                      {defaultTemplate.description}
                    </span>
                  </p>
                  <button
                    className="video-first-modal-btn border-0 w-100 mb-0"
                    onClick={() => handleCreate("", defaultTemplateCardTemp)}
                  >
                    Use Template
                  </button>
                  <button
                    className=" video-sec-modal-btn border-0 w-100"
                    onClick={() => {
                      handleClosePreview();
                      setOpenStarter(true);
                    }}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default TemplatesTab;
