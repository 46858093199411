import axios from "axios";
const url = process.env.REACT_APP_URL;

export const loginAdminUser = (email, password, showToastMessage) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "LOGIN_ADMIN_USER_REQUEST" });
      const response = await axios.post(
        `${url}/admin/login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      showToastMessage();
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
      dispatch({
        type: "LOGIN_ADMIN_USER_SUCCESS",
        payload: response.data,
      });

      dispatch(loadAdminUser());
    } catch (error) {
      dispatch({
        type: "LOGIN_ADMIN_USER_FAILURE",
        payload: error.response.data,
      });
    }
  };
};

export const forgotPasswordAdmin = (email) => async (dispatch) => {
  try {
    dispatch({ type: "ADMIN_FORGOT_PASSWORD_REQUEST" });

    const { data } = await axios.post(
      `${url}/admin/forgot/password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "ADMIN_FORGOT_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ADMIN_FORGOT_PASSWORD_FAILURE",
      payload: error.response.data.message,
    });
  }
};

export const resetPasswordAdmin = (password, token) => async (dispatch) => {
  try {
    dispatch({ type: "ADMIN_RESET_PASSWORD_REQUEST" });

    const { data } = await axios.post(
      `${url}/admin/reset/password/${token}`,
      { password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "ADMIN_RESET_PASSWORD_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ADMIN_RESET_PASSWORD_FAILURE",
      payload: error.response.data.message,
    });
  }
};

export const logoutAdminUser = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${url}/admin/logout`, null, {
        withCredentials: true,
      });

      if (data.success) {
        window.location.reload();
        window.location.replace(`/admin`);
      }

      dispatch({ type: "LOGOUT_ADMIN_USER" });
    } catch (error) {
      dispatch({
        type: "LOGOUT_ADMIN_USER_FAILURE",
        payload: error.response.data,
      });
    }
  };
};

export const loadAdminUser = () => async (dispatch) => {
  try {
    dispatch({ type: "LOAD_ADMIN_USER_REQUEST" });

    const { data } = await axios.get(`${url}/admin/loadadmin`, {
      withCredentials: true,
    });

    dispatch({ type: "LOAD_ADMIN_USER_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "LOAD_ADMIN_USER_FAIL", payload: error });
  }
};

export const getAllUsersOfCompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USERS_OF_COMPANY_REQUEST" });

    const response = await axios.get(`${url}/admin/companyusers/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    const userInformationTeamData = response.data.userInformationTeamData;
    const companydata = response.data.companydata;
    const allteamdata = response.data.allteams;

    dispatch({
      type: "GET_USERS_OF_COMPANY_SUCCESS",
      payload: { userInformationTeamData, companydata, allteamdata },
    });
  } catch (error) {
    dispatch({ type: "GET_USERS_OF_COMPANY_FAIL", payload: error });
  }
};

export const getAllPlans = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_PLANS_REQUEST" });

    const { data } = await axios.get(`${url}/admin/plans`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "GET_PLANS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_PLANS_FAILURE", payload: error.message });
  }
};

export const OtcUploadProfilePicture = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_PROFILE_PICTURE_REQUEST" });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    const { data } = await axios.post(
      `${url}/admin/upload-profile-picture/${id}`,
      formData,
      config
    );

    dispatch({ type: "UPLOAD_PROFILE_PICTURE_SUCCESS", payload: data });

    return { success: true, data, imagePath: data.imagePath };
  } catch (error) {
    dispatch({
      type: "UPLOAD_PROFILE_PICTURE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (
      error.response &&
      error.response.data.error ===
      "Profile picture size must be at most 300x300 pixels.."
    ) {
      return {
        ProfilepicSizeValidationError:
          "Profile picture size must be at most 300x300 pixels.",
      };
    }

    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};

export const OtcUploadLogoPicture = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_LOGO_REQUEST" });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    const { data } = await axios.post(
      `${url}/admin/upload-logo-picture`,
      formData,
      config
    );

    dispatch({ type: "UPLOAD_LOGO_SUCCESS", payload: data });

    return { success: true, data, imagePath: data.imagePath };
  } catch (error) {
    dispatch({
      type: "UPLOAD_LOGO_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (
      error.response &&
      error.response.data.error === "logo size must be at most 300x300 pixels.."
    ) {
      return {
        ProfilepicSizeValidationError:
          "logo size must be at most 300x300 pixels.",
      };
    }

    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};
export const OtcUploadFaviconPicture = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "UPLOAD_FAVICON_REQUEST" });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    };

    const { data } = await axios.post(
      `${url}/admin/upload-favicon-picture`,
      formData,
      config
    );

    dispatch({ type: "UPLOAD_FAVICON_SUCCESS", payload: data });

    return { success: true, data, imagePath: data.imagePath };
  } catch (error) {
    dispatch({
      type: "UPLOAD_FAVICON_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (
      error.response &&
      error.response.data.error ===
      "favicon size must be at most 300x300 pixels.."
    ) {
      return {
        ProfilepicSizeValidationError:
          "favicon size must be at most 300x300 pixels.",
      };
    }

    return {
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    };
  }
};

export const updateCompanyData = (newCompanyData) => ({
  type: "UPDATE_COMPANY_DATA",
  payload: newCompanyData,
});

export const getinvitedTeamMemberforAdminpanel = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_INVITEDUSERS_REQUEST" });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const { data } = await axios.post(
      `${url}/admin/getinvitedUsersbyadmin`,
      { id },
      config
    );

    dispatch({ type: "GET_INVITEDUSERS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_INVITEDUSERS_FAILURE", payload: error });
  }
};

export const getAllUsersOfSelectedCompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_TEAM_AND_USERS_OF_COMPANY_REQUEST" });

    const response = await axios.get(`${url}/admin/companyusers/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    const usersofcompany = response.data.userInformationTeamData;
    const teamsofcompany = response.data.allteams;

    dispatch({
      type: "GET_TEAM_AND_USERS_OF_COMPANY_SUCCESS",
      payload: { usersofcompany, teamsofcompany },
    });
  } catch (error) {
    dispatch({ type: "GET_TEAM_AND_USERS_OF_COMPANY_FAIL", payload: error });
  }
};

export const getAllShippingadressofcompany = (id) => async (dispatch) => {
  try {
    dispatch({ type: "OTC_SHIPPINGADDRESSES_REQUEST" });

    const { data } = await axios.post(
      `${url}/admin/getAllShippingAddressofcompany`,
      { id },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    dispatch({ type: "OTC_SHIPPINGADDRESSES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "OTC_SHIPPINGADDRESSES_FAILURE", payload: error.message });
  }
};

export const addShippingofcompany = ({ shippingAddressData, id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "OTC_CREATE_SHIPPING_ADDRESS_REQUEST" });
      const { data } = await axios.post(
        `${url}/admin/createShippingAddressofcompany`,
        { ...shippingAddressData, id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )

      dispatch({ type: "OTC_CREATE_SHIPPING_ADDRESS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "OTC_CREATE_SHIPPING_ADDRESS_FAILURE",
        payload: error.message,
      });
    }
  };

export const fetchBillingAddressDetailsofcompany = (superAdminUserid, id) => async (dispatch) => {
  try {
    dispatch({ type: "OTC_FETCH_BILLING_ADDRESS_REQUEST" });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const { data } = await axios.post(
      `${url}/admin/fetchbillingaddressofcompany`,
      { superAdminUserid, id },
      config
    );

    dispatch({ type: "OTC_FETCH_BILLING_ADDRESS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "OTC_FETCH_BILLING_ADDRESS_FAILED", payload: error });
  }
};

export const getAllOrderss = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_ALLORDERS_REQUEST" });

    const { data } = await axios.get(`${url}/admin/orders`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "GET_ALLORDERS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_ALLORDERS_FAILURE", payload: error.message });
  }
};

export const getSingleOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ORDER_REQUEST" });

    const { data } = await axios.get(`${url}/admin/order/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "GET_ORDER_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_ORDER_FAILURE", payload: error.message });
  }
};

export const getTeamsofOtcAdmin = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_OTC_ADMIN_TEAMS_REQUEST" });

    const { data } = await axios.get(`${url}/admin/teamdata`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    dispatch({ type: "GET_OTC_ADMIN_TEAMS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "GET_OTC_ADMIN_TEAMS_FAILURE", payload: error.message });
  }
};

export const updateAdminUserInformation = (id, userInformation) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_USER_INFORMATION_REQUEST" });
    const { data } = await axios.post(
      `${url}/admin/update-user-information/${id}`,
      { info: userInformation },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch({ type: "UPDATE_USER_INFORMATION_SUCCESS", payload: data });

    return true;
  } catch (error) {
    dispatch({
      type: "UPDATE_USER_INFORMATION_FAILURE",
      payload: error.response.data.message,
    });

    return false;
  }
};

export const getAdminUserInformation = (id) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USER_INFORMATION_REQUEST" });
    const { data } = await axios.get(
      `${url}/admin/get-user-information/${id}`,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    dispatch({ type: "GET_USER_INFORMATION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_USER_INFORMATION_FAILURE",
      payload: error.response.data.message,
    });
  }
};