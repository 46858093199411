import { createReducer } from "@reduxjs/toolkit";

const otcTeamMembersState = {
  teamMembers: null,
  loading: false,
  message: null,
  error: null,
};

export const getOtcTeamMembers = createReducer(
  otcTeamMembersState,
  (builder) => {
    builder.addCase("GET_TEAM_MEMBERS_REQUEST", (state, payload) => {
      state.loading = true;
      state.teamMembers = null;
    });
    builder.addCase("GET_TEAM_MEMBERS_SUCCESS", (state, payload) => {
      state.loading = true;
      state.teamMembers = payload.teamMembers;
    });
    builder.addCase("GET_TEAM_MEMBERS_FAILURE", (state, action) => {
      state.loading = true;
      state.teamMembers = null;
      state.error = action.payload;
    });

    builder.addCase("CREATE_USER_REQUEST", (state, action) => {
      state.loading = true;
    });
    builder.addCase("CREATE_USER_SUCCESS", (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase("CREATE_USER_FAILURE", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ----------------------------------------------------------------

    builder.addCase("DELETE_USER_REQUEST", (state, action) => {
      state.loading = true;
    });

    builder.addCase("DELETE_USER_SUCCESS", (state, action) => {
      state.loading = action.xpayload.success;
      state.message = action.payload.message;
    });
    builder.addCase("DELETE_USER_FAILURE", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ------------------------------------------------------------------

    builder.addCase("CLEAR_MESSAGE", (state, action) => {
      state.message = null;
    });
    builder.addCase("CLEAR_ERROR", (state, action) => {
      state.error = null;
    });
  }
);

const userdetails = {
  userInfo: null,
  loading: false,
};

export const getUserInfoReducer = createReducer(userdetails, (builder) => {
  builder.addCase("GET_USER_INFO_REQUEST", (state, action) => {
    state.loading = true;
  });

  builder.addCase("GET_USER_INFO_SUCCESS", (state, action) => {
    state.loading = false;
    state.userInfo = action.payload.user;
  });

  builder.addCase("GET_USER_INFO_FAILURE", (state, action) => {
    state.loading = false;
  });
});
