import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Avatar } from "@mui/material";
import notification from "../../../assets/icons/notification.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./header.css";
import {
  getProfileImage, logout, fetchCart,
} from "../../../actions/userAction/userAction";
import { retriveLoginAccounts } from "../../../actions/switchAccount/switchAccountAction";

const Header = ({ handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { user } = useSelector((state) => state.user)
  const { userprofileimage } = useSelector((state) => state.getavatar)
  const initials = (user?.first_name?.charAt(0).toUpperCase() + user?.last_name?.charAt(0).toUpperCase())
  const [initialAddedProducts, setInitialAddedProducts] = useState([]);
  const { Cartproducts, cartProductCounts } = useSelector(
    (state) => state.Cartproducts
  );
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [avatarimage, setAvatarimage] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    if (!Cartproducts && user) {
      dispatch(fetchCart(user))
    }
    setInitialAddedProducts(Cartproducts);
  }, [user, Cartproducts])
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    dispatch(getProfileImage())
  }, [])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const { userData } = useSelector((state) => state.activeUser)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const url = process.env.REACT_APP_URL

  const dispatch = useDispatch();
  const [localStorageValues, setLocalStorageValues] = useState([]);

  const fetchAllLocalStorageValues = async () => {
    const localStorageKeys = Object.keys(localStorage);
    const filteredKeys = localStorageKeys.filter((key) =>
      key.startsWith("MemberID")
    );
    const values = filteredKeys.map((key) => localStorage.getItem(key));
    fetchData(values);
  };

  useEffect(() => {
    setAvatarimage(userprofileimage);
  }, [userprofileimage]);
  
  const fetchData = async (values) => {
    try {
        if(userData){
            setLocalStorageValues(userData.userData);
        }else{
            dispatch(retriveLoginAccounts(values))
        }
    } catch (error) {
    }
};

 
  useEffect(() => {
    fetchAllLocalStorageValues();
  }, []);
  useEffect(() => { }, [localStorageValues]);

  const logoutHandler = () => {
    if (user) {
      dispatch(logout(user._id));
    } else {
    }
  };

  const getVariationById = (variations, variationId) => {
    return variations?.find(variation => variation._id === variationId) || null;
  };
  // Function to calculate total price
  const calculateTotalPrice = () => {
    if (initialAddedProducts) {
      const totalPrice = initialAddedProducts.reduce((sum, val) => {
        const variation = getVariationById(val.product?.variations, val?.variation);

        if (val.variation) {
          const productPrice = variation.saleprice
            ? variation.saleprice * val.quantity
            : variation.price * val.quantity;
          return sum + productPrice;
        } else {
          const productPrice = val.product?.saleprice
            ? val.product.saleprice * val.quantity
            : val.product.price * val.quantity;
          return sum + productPrice;
        }

      }, 0);
      return totalPrice?.toFixed(2); // Assuming prices are in decimal format
    }
    return "0.00";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 3 new notifications"
          color="black"
        >
          <Badge badgeContent={'0'} color="error">
            <img src={notification} alt="notifiaction" />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="black"
        >
          <Avatar
            alt="Remy Sharp"
            src={`${url}/profile/img/${user?.avatar}`}
            style={{ width: "48px", height: "48px" }}
          />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }} className="header-navbar ">
      <Toolbar >
        <IconButton
          size="large"
          edge="start"
          color="black"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { xs: "block", sm: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <div class="dropdown">

            <div>
              <IconButton
                sx={{ display: { xs: "none", md: "flex" } }}
                className="carticon me-1"
                size="large"
                aria-label="show 3 new notifications"
                color="black"
                disableRipple
                style={{
                  width: "",
                  height: "65px",
                  top: "",
                  right: "6px",
                  pointerEvents: user?.Account_status === 'is_Deactivated' ? 'none' : 'auto',
                  opacity: user?.Account_status === 'is_Deactivated' ? 0.5 : 1,
                }}
              >
                <Badge color="error" badgeContent={initialAddedProducts?.length} >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M7.58203 23.8327C6.9862 23.8327 6.47595 23.6203 6.05128 23.1957C5.62662 22.771 5.41464 22.2611 5.41537 21.666C5.41537 21.0702 5.6277 20.5599 6.05237 20.1353C6.47703 19.7106 6.98692 19.4986 7.58203 19.4993C8.17787 19.4993 8.68812 19.7117 9.11278 20.1363C9.53745 20.561 9.74942 21.0709 9.7487 21.666C9.7487 22.2618 9.53637 22.7721 9.1117 23.1968C8.68703 23.6214 8.17714 23.8334 7.58203 23.8327ZM18.4154 23.8327C17.8195 23.8327 17.3093 23.6203 16.8846 23.1957C16.46 22.771 16.248 22.2611 16.2487 21.666C16.2487 21.0702 16.461 20.5599 16.8857 20.1353C17.3104 19.7106 17.8203 19.4986 18.4154 19.4993C19.0112 19.4993 19.5215 19.7117 19.9461 20.1363C20.3708 20.561 20.5828 21.0709 20.582 21.666C20.582 22.2618 20.3697 22.7721 19.945 23.1968C19.5204 23.6214 19.0105 23.8334 18.4154 23.8327ZM5.63203 4.33268H23.507L18.1175 14.0827H8.7737L7.58203 16.2493H20.582V18.416H3.92578L7.1487 12.566L3.2487 4.33268H1.08203V2.16602H4.60287L5.63203 4.33268Z" fill="#333333" />
                  </svg>
                </Badge>
              </IconButton>
            </div>
            {user?.Account_status !== 'is_Deactivated' && (Cartproducts && initialAddedProducts?.length !== 0)
              ?
              <div class="dropdown-menu p-3" style={{ width: '480%' }}>
                <div>
                  <div style={{ maxHeight: "180px", overflowY: "scroll", overflowX: "hidden" }}>
                    {initialAddedProducts?.map((val, i) => {
                      const variation = getVariationById(val.product?.variations, val?.variation);
                      return (
                        <div className='row' key={i}>
                          <div className='col-4'>
                            {val?.variation ?
                              <img className="img-fluid" src={`${url}/product/img/${variation?.image[0]?.url}`} alt={variation.Type} />
                              :
                              <img className="img-fluid" src={`${url}/product/img/${val.product?.image[0]?.url}`} alt={val?.product?.name} />
                            }
                          </div>
                          <div className='col d-flex flex-column justify-content-center'>
                            <span>{val?.variation ? variation?.Type : val?.product?.name}</span>
                            {val?.variation ? (
                              <div>{val.quantity} x {variation?.saleprice ? variation?.saleprice?.toFixed(2) : variation?.price?.toFixed(2)}</div>
                            ) : (
                              <div>{val.quantity} x {val?.product?.saleprice ? val.product?.saleprice?.toFixed(2) : val?.product?.price?.toFixed(2)}</div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className=' mt-3 border w-100 p-2 d-flex justify-content-center'>
                    Subtotal: ${calculateTotalPrice()}
                  </div>
                  <div className="d-flex w-100 mt-1 gap-1 p-1">
                    <button className="w-50 mb-0 border-0" style={{ background: '#e65925', color: "white", padding: "6px" }} onClick={() => { navigate(`/cart`) }}>View Cart</button>
                    <button className="w-50 mb-0 border-0" style={{ background: 'black', color: "white", padding: "6px" }} onClick={() => { navigate("/checkout") }}>Checkout</button>
                  </div>
                </div>
              </div>
              :
              <div class="dropdown-menu p-3">
                <span> No Smart Accessories in Cart.</span>
              </div>
            }
          </div>
          <div class="dropdown" style={{ marginRight: "30px" }}>
            <Badge badgeContent={'0'} color="error">
              <img src={notification} alt="notifiaction" />
            </Badge>
            <div class="dropdown-menu p-3">
              <span>No Notification.</span>
            </div>
          </div>

          <div
            className="img-profile dropdown   plan-selection-avatar-container" role="button" >
            <div ref={dropdownRef}>

              <div onClick={toggleDropdown} className="header-avatar-container dropdown ">
                {userprofileimage ? (
                  <Avatar
                    style={{ width: '48px', height: '48px' }}
                    src={`${url}/profile/img/${userprofileimage}`}
                    alt={`${user?.first_name} ${user?.last_name}`}
                  />
                ) : (
                  <div className="header-avatar-initials-overlay" style={{ width: "48px", height: "48px" }}>
                    {initials}
                  </div>
                )}

                {isDropdownOpen && (
                  <div className="header-popup-modal">
                    <div className="hover-popup-item">
                      <div className="header-popup-modal-text" onClick={() => navigate("/dashboard")} >Dashboard</div>
                      <div
                        className="header-popup-modal-text"
                        onClick={() => navigate("/my-cards")}
                        style={{
                          pointerEvents: user?.Account_status === 'is_Deactivated' ? 'none' : 'auto',
                          opacity: user?.Account_status === 'is_Deactivated' ? 0.5 : 1,
                        }}
                      >
                        My card
                      </div>
                    </div>
                    <div className="header-popup-modal-text" onClick={() => navigate("/my-account")} >My Account</div>
                    <div className="header-popup-modal-text" onClick={() => navigate("/account-selection")}>Switch Account</div>
                    <div onClick={logoutHandler} className="header-popup-modal-text" >Logout</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>

        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="black"
          >
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMobileMenu}
    </Box>
  );
};
export default Header;

