import React, { useEffect, useState } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import "./forgotPassword.scss";
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/icons/logo_black.svg'
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../actions/userAction/userAction";


const ForgotPassword = () => {
  const ErrorMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#FDE9E9',
    color: "#ea4e4e",
    borderLeft: '5px solid'
  };
  const successMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#F0FEEB',
    color: "#48AF25",
    borderLeft: '5px solid'
  };
  const errorIconstyle = {
    position: 'absolute',
    top: '-8px',
    right: '12px',
    width: '25px',
  }
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="Error"><circle cx="32" cy="32" r="28" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></circle><line x1="32" x2="32" y1="18" y2="38" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line><line x1="32" x2="32" y1="42" y2="46" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line></svg>
  const successIcon = <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99906 16.4209L0.789062 10.2109L3.61906 7.38086L6.99906 10.7709L16.8791 0.880859L19.7091 3.71086L6.99906 16.4209Z" fill="#48AF25" />
  </svg>
  const loginInputsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
  };
  const dispatch = useDispatch();
  const { message, error, loading } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [emailSuccess, setEmailSuccess] = useState("")
  const [emailError, setEmailError] = useState('')

  const sendHandler = (e) => {
    e.preventDefault();
    if (!email) {
      return setEmailError("Please enter email address")
    }
    else {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(email)) {
        return setEmailError("Please enter a valid email address")
      }
      else {
        dispatch(forgotPassword(email))
      }
    }
  }

  useEffect(() => {
    if (message) {
      setEmailSuccess(message);
      dispatch({ type: "CLEAR_MESSAGE" })
    }
    if (error) {
      setEmailError(error);
      dispatch({ type: "CLEAR_ERROR" })
    }
  }, [message, error, dispatch, navigate])

  return (
    <div className="forgot_password_container">
      <div className="forgot_password_left">
        <div className="forgot_header">
          <img style={{ cursor: "pointer" }} onClick={() => { navigate('/') }} src={logo} alt="" srcset="" />
        </div>
        <div className="forgot_password_information">
          <h1>Forgot Password</h1>
          <span>No problem! Let's get you back on track, Reset it now</span>
          <form onSubmit={sendHandler}>
            <div className="forgot_password_input">
              <input
                type="text"
                placeholder="Your Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");;
                  setEmailSuccess('')
                }}
                autoComplete="off"
                autoFocus
              />
            </div>
            {(emailError) &&
              <div style={loginInputsStyle} className="login_inputs">
                <div style={ErrorMessageDiv}>{emailError}</div>
                <span style={errorIconstyle} className="">{errorIcon}</span>
              </div>}
            <button type="submit">{loading ? 'Sending...' : 'Send Email'}</button>
            {emailSuccess &&
              <div style={loginInputsStyle} className="login_inputs">
                <div style={successMessageDiv}>{emailSuccess}</div>
                <span style={errorIconstyle} className="">{successIcon}</span>
              </div>}
            <button onClick={() => navigate("/login")} style={{ backgroundColor: 'white', color: 'black' }}>Back to Login</button>
          </form>
        </div>
      </div>
      <div className="forgot_password_right">
        <h3>Meet Kimba</h3>
        <img src={phoneImg} alt="phoneImg" />

      </div>
    </div>
  );
};

export default ForgotPassword;
