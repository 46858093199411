import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleOrder } from "../../Redux/Otc_UserActions";
import { getProducts } from "../../../actions/userAction/userAction";
import AddonIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_addons_icon.svg";
import selectDropdownIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
// import selectwhiteDropdownIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectWhiteDropdownIcon.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Layout/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = <svg
  xmlns="http://www.w3.org/2000/svg"
  width="80"
  height="80"
  viewBox="0 0 80 80"
  fill="none"
  class="circle"
>
  <path
    d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
    fill="#E65925"
  />
  <path
    d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
    stroke="white"
    stroke-width="6.64"
    stroke-linecap="round"
    class="checkmark-path"
  />
</svg>


const OrderAddon = () => {
  const url = process.env.REACT_APP_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState("");
  const [userInforamtionData, setUserInforamtionData] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [userData, setUserData] = useState("");
  const [Titlename, setTitlename] = useState("");
  const [TopAvatar, setTopAvatar] = useState("");
  const [smartAccessories, setSmartAccessories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [addonPurchase, setAddonPurchase] = useState([])

  const [orderStatus, setOrderStatus] = useState("");
  const [addonStatus, setAddonStatus] = useState("");
  const [activityLog, setActivityLog] = useState("");
  const [addonNotes, setAddonNotes] = useState("");
  const [otcUsers, setOtcUsers] = useState("");
  const [particularAddonStatus, setParticularAddonStatus] = useState("");
  const [particularAssignto, setParticularAssignto] = useState("");

  const [particularPurchaseAddonStatus, setParticularPurchaseAddonStatus] = useState("");
  const [particularPurchaseAssignto, setParticularPurchaseAssignto] = useState("");
  // const [smartAccessoriesStatus, setSmartAccessoriesStatus] = useState([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { order, loading } = useSelector((state) => state.order);
  const { products, message, error } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, []);

  const getOtcUsers = async () => {
    const res = await axios.get(`${url}/admin/otc_adminusers`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    setOtcUsers(res.data?.allUsers)
  }

  useEffect(() => {
    getOtcUsers()
  }, [])

  useEffect(() => {
    if (!products) {
      dispatch(getProducts());
    }
  }, [products]);

  useEffect(() => {
    if (order) {
      setOrderData(order);
      setUserData(order?.userdata);
      setCompanyData(order?.companydata);
      setUserInforamtionData(order?.userInformation);
    }
  }, [order]);



  useEffect(() => {
    if (userData || orderData) {
      setTitlename(`${userData?.first_name} ${userData?.last_name}`);
      setTopAvatar(
        userData?.avatar !== "" ? `${url}/profile/img/${userData?.avatar}` : ""
      );
      setSmartAccessories(orderData?.smartAccessories);
      setAddons(orderData?.subscription_details?.addones);
      setAddonPurchase(orderData?.addaddons)
      setOrderStatus(orderData?.status);
      // setFulfillment(orderData?.fulfillment);
      setActivityLog(orderData?.addOnsActivityLog);
      setAddonStatus(orderData?.addOnStatus);
      setAddonNotes(orderData?.addOnsNote);
    }
  }, [userData, orderData]);

  useEffect(() => {
    setOrderData((prevDetails) => {
      let updatedOrderData;
      if (particularAddonStatus.length > 0) {
        updatedOrderData = {
          ...prevDetails,
          status: orderStatus,
          addOnStatus: addonStatus,
          addOnsActivityLog: activityLog,
          addOnsNote: addonNotes,
          subscription_details: {
            ...prevDetails.subscription_details,
            addones: prevDetails.subscription_details.addones.map((addon, index) => ({
              ...addon,
              status: particularAddonStatus[index]?.status,
            })),
          },
        }
      } else {
        updatedOrderData = {
          ...prevDetails,
          status: orderStatus,
          addOnStatus: addonStatus,
          addOnsActivityLog: activityLog,
          addOnsNote: addonNotes,
        }
      }
      return updatedOrderData;
    });
  }, [orderStatus, activityLog, addonNotes, addonStatus, particularAddonStatus]);


  useEffect(() => {
    setOrderData((prevDetails) => {
      let updatedOrderData;
      if (particularPurchaseAddonStatus.length > 0) {
        updatedOrderData = {
          ...prevDetails,
          status: orderStatus,
          addOnStatus: addonStatus,
          addOnsActivityLog: activityLog,
          addOnsNote: addonNotes,
          addaddons: addonPurchase?.map((purchaseAddons, index) => {
            return {
              ...purchaseAddons,
              status: particularPurchaseAddonStatus[index]?.status,  // Replace smartAccessoriesStatus with the actual array you are using
            };
          }),
        }
      } else {
        updatedOrderData = {
          ...prevDetails,
          status: orderStatus,
          addOnStatus: addonStatus,
          addOnsActivityLog: activityLog,
          addOnsNote: addonNotes,
        }
      }
      return updatedOrderData;
    });
  }, [orderStatus, activityLog, addonNotes, addonStatus, particularPurchaseAddonStatus]);


  useEffect(() => {
    setOrderData((prevDetails) => {
      let updatedOrderData;
      if (particularAssignto.length > 0) {
        updatedOrderData = {
          ...prevDetails,
          subscription_details: {
            ...prevDetails.subscription_details,
            addones: prevDetails.subscription_details.addones.map((addon, index) => ({
              ...addon,
              assignTo: particularAssignto[index]?.assignTo,
            })),
          },
        }
      }
      return updatedOrderData;
    });
  }, [particularAssignto]);

  useEffect(() => {
    setOrderData((prevDetails) => {
      let updatedOrderData;
      if (particularPurchaseAssignto.length > 0) {
        updatedOrderData = {
          ...prevDetails,
          addaddons: addonPurchase?.map((purchaseAddons, index) => {
            return {
              ...purchaseAddons,
              assignTo: particularPurchaseAssignto[index]?.assignTo,  // Replace smartAccessoriesStatus with the actual array you are using
            };
          }),
        }
      }
      return updatedOrderData;
    });
  }, [particularPurchaseAssignto]);



  const handleAddonStatusChange = (index, value) => {
    let updatedAddons;
    if (addons.length > 0) {
      updatedAddons = [...addons];  // Shallow copy    
    }
    updatedAddons[index] = {
      ...updatedAddons[index],
      status: value,
    };
    setParticularAddonStatus(updatedAddons);

  };

  const handlePurchaseAddonStatusChange = (index, value) => {
    let updatedAddons;
    if (addonPurchase.length > 0) {
      updatedAddons = [...addonPurchase];  // Shallow copy    
    }
    updatedAddons[index] = {
      ...updatedAddons[index],
      status: value,
    };
    setParticularPurchaseAddonStatus(updatedAddons);

  };
  const handleAssigntoChange = (index, value) => {
    const updatedAssignto = [...addons];  // Shallow copy    
    updatedAssignto[index] = {
      ...updatedAssignto[index],
      assignTo: value,
    };
    setParticularAssignto(updatedAssignto);

  };

  const handlePurchaseAssigntoChange = (index, value) => {
    const updatedAssignto = [...addonPurchase];  // Shallow copy    
    updatedAssignto[index] = {
      ...updatedAssignto[index],
      assignTo: value,
    };
    setParticularPurchaseAssignto(updatedAssignto);

  };

  const fullName = Titlename;
  const words = fullName.split(" ");
  const initials = words
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add padding for single-digit months
    const day = date.getDate().toString().padStart(2, "0"); // Add padding for single-digit days
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const totalAmount =
    orderData?.type === "Subscription"
      ? !orderData?.subscription_details?.total_amount
        ? "0"
        : orderData?.subscription_details?.total_amount
      : orderData?.totalAmount;
  const taxRate =
    orderData?.type === "Subscription"
      ? orderData?.subscription_details?.taxRate
      : orderData?.tax;
  const tax = ((parseFloat(totalAmount) * parseFloat(taxRate)) / 100).toFixed(
    2
  ); // Convert percentage to decimal and calculate tax
  let shipping;
  if (orderData?.shipping_method?.length > 0) {
    shipping =
      orderData?.type === "Subscription"
        ? orderData?.shipping_method[0]?.price
        : orderData?.shipping_method[0]?.price;
  } else {
    shipping = 0;
  }
  const total =
    parseInt(totalAmount, 10) + parseFloat(tax) + parseInt(shipping, 10);

  // 
  // 
  // 
  // 

  const handleOrderUpdate = async () => {
    const res = await axios.post(`${url}/admin/order/update/${id}`, orderData, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    setApiSuccess(true)
    setSuccessMessage('Data updated successfully.')
    setTimeout(() => {
      setApiSuccess(false)
    }, 5000);
    dispatch(getSingleOrder(id));
    // showToastMessage()
  };

  const showToastMessage = () => {
    toast.success("Data Updated Successfully!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };



  return (
    <>
      {loading ? < Loader /> : <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="">
          <div className="w-100  d-flex flex-row-reverse gap-3 mb-4">
            <div class="dropdown">
              <button
                class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ background: "#000", color: "#fff" }}
              >
                <span className="me-5">Actions</span>
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{ left: "unset" }}
              >
                <span style={{ paddingLeft: "16px" }}>Delete</span>
              </div>
            </div>
            <button
              onClick={handleOrderUpdate}
              className="btn px-3 py-2 text-white"
              style={{ background: "#E65925", width: "15%" }}
            >
              Update
            </button>
          </div>
          <div className="row">
            <div className="col" style={{ padding: "0" }}>
              <div className="bg-white p-4" style={{ borderRadius: "8px" }}>
                <div className="order-ful-header-component">
                  <span>Orders #</span>
                  <span className="pc-category-title-text">Status</span>
                  <span className="pc-category-title-text">Date</span>
                  <span className="pc-category-title-text">Total</span>
                  <span className="pc-category-title-text">Add ons status</span>
                </div>
                <div className="order-ful-header-component order-ful-header-component-values">
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {orderData?.orderNumber}
                  </span>
                  <div style={{ position: "relative" }} className="">
                    <div>
                      <select
                        onChange={(e) => {
                          setOrderStatus(e.target.value);
                        }}
                        name="Status"
                        value={orderStatus}
                        className="pc-status-dropdown"
                        style={{ background: "#ffffff", borderRadius: "5px", fontSize: "14px" }}
                      >
                        <option value="Processing">Processing</option>
                        <option value="Completed">Completed</option>
                        <option value="Pending">Pending</option>
                        <option value="Failed">Failed</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="PartiallyRefunded">
                          Partially Refunded
                        </option>
                      </select>
                    </div>
                    <img
                      className="order-downarrow-icon"
                      src={selectDropdownIcon}
                      alt=""
                    />
                  </div>
                  <span className="pc-category-title-text">
                    {formatDate(orderData?.paymentDate)}
                  </span>
                  <span className="pc-category-title-text">${total}</span>
                  <div style={{ position: "relative" }} className="">
                    <div>
                      <select
                        onChange={(e) => {
                          setAddonStatus(e.target.value);
                        }}
                        name="addonStatus"
                        value={addonStatus}
                        className="pc-status-dropdown"
                        style={{ background: "#ffffff", borderRadius: "5px", fontSize: "14px" }}
                      >
                        <option value="To do">To do</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Partially completed">
                          Partially completed
                        </option>
                      </select>
                    </div>
                    <img
                      className="order-downarrow-icon"
                      src={selectDropdownIcon}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
                <div className="smartAcc-text-order">Add ons</div>
                <div
                  style={{
                    marginTop: "30px",
                    paddingLeft: "16px",
                    paddingRight: "20px",

                  }}
                  className="order-Product-cards-titles-header bg-white"
                >
                  <span className="">Items</span>
                  <span className="order-Product-cards-titles text-center">Quantity</span>
                  <span className="order-Product-cards-titles text-center">Assigned to</span>
                  <span className="order-Product-cards-titles text-center">Status</span>
                </div>

                {addons &&
                  addons?.map((e, index) => {
                    const discountedPrice = 0;
                    const quantity = 1;
                    const total =
                      parseInt(quantity, 10) *
                      parseInt(e?.price, 10) -
                      discountedPrice;
                    return (
                      <div key={index} className="main-paln-product-card">
                        <div
                          style={{ marginRight: "0", paddingRight: "20px" }}
                          className=" order-Product-cards-titles-header"
                        >
                          <div className="pc-img-and-text-div">
                            <div>
                              <img
                                style={{ width: "85%" }}
                                src={AddonIcon}
                                alt="iconimg"
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              className="product-details"
                            >
                              <span className="plan-commman-name-text">
                                {e?.addonId.publicname}
                              </span>
                              <span
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                  letterSpacing: "0.25px",
                                }}
                              >
                                SKU:
                              </span>
                            </div>
                          </div>
                          <div className="pc-category-title-text">{"1"}</div>
                          <div className="pc-category-title-text">
                            <div className="onetap_conn_filter_search_OTC_Admin">
                              <select
                                name={`addon_assign_${index}`}
                                value={e.assignTo || ''}
                                onChange={(e) => handleAssigntoChange(index, e.target.value)}
                                id="addon_assign"
                                style={{
                                  appearance: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  fontSize: "13px",
                                  background: "#fff",
                                }}
                              >
                                {otcUsers && otcUsers?.map((user, userIndex) => (
                                  <option key={userIndex} value={user.id}>
                                    {`${user.firstName} ${user.lastName}`}
                                  </option>
                                ))}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                                style={{ background: "#fff" }}
                              >
                                <path
                                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                          </div>
                          <div
                            style={{ color: "#E62525" }}
                            className="pc-category-title-text"
                          >
                            <div className="onetap_conn_filter_search_OTC_Admin ms-3">
                              <select
                                onChange={(e) => handleAddonStatusChange(index, e.target.value)}
                                value={e.status || ''}
                                name={`addon_status_${index}`}
                                id="addon_status"
                                style={{
                                  appearance: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  fontSize: "13px",
                                  background: "#fff",
                                }}
                              >
                                <option value="To do">To do</option>
                                <option value="Email sent">Email sent</option>
                                <option value="Scheduled">Scheduled</option>
                                <option value="Completed">Completed</option>
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                                style={{ background: "#fff" }}
                              >
                                <path
                                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {orderData?.type === 'AddonPurchase' && addonPurchase &&
                  addonPurchase?.map((e, index) => {
                    const discountedPrice = 0;
                    const quantity = 1;
                    const total =
                      parseInt(quantity, 10) *
                      parseInt(e?.price, 10) -
                      discountedPrice;
                    return (
                      <div key={index} className="main-paln-product-card">
                        <div
                          style={{ marginRight: "0", paddingRight: "20px" }}
                          className=" order-Product-cards-titles-header"
                        >
                          <div className="pc-img-and-text-div">
                            <div>
                              <img
                                style={{ width: "85%" }}
                                src={AddonIcon}
                                alt="iconimg"
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              className="product-details"
                            >
                              <span className="plan-commman-name-text">
                                {e?.addonId.publicname}
                              </span>
                              <span
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                  letterSpacing: "0.25px",
                                }}
                              >
                                SKU:
                              </span>
                            </div>
                          </div>
                          <div className="pc-category-title-text">{"1"}</div>
                          <div className="pc-category-title-text">
                            <div className="onetap_conn_filter_search_OTC_Admin">
                              <select
                                name={`addon_assign_${index}`}
                                value={e.assignTo || ''}
                                onChange={(e) => handlePurchaseAssigntoChange(index, e.target.value)}
                                id="addon_assign"
                                style={{
                                  appearance: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  fontSize: "13px",
                                  background: "#fff",
                                }}
                              >
                                {otcUsers && otcUsers?.map((user, userIndex) => (
                                  <option key={userIndex} value={user.id}>
                                    {`${user.firstName} ${user.lastName}`}
                                  </option>
                                ))}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                                style={{ background: "#fff" }}
                              >
                                <path
                                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                          </div>
                          <div
                            style={{ color: "#E62525" }}
                            className="pc-category-title-text"
                          >
                            <div className="onetap_conn_filter_search_OTC_Admin ms-3">
                              <select
                                onChange={(e) => handlePurchaseAddonStatusChange(index, e.target.value)}
                                value={e.status || ''}
                                name={`addon_status_${index}`}
                                id="addon_status"
                                style={{
                                  appearance: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  fontSize: "13px",
                                  background: "#fff",
                                }}
                              >
                                <option value="To do">To do</option>
                                <option value="Email sent">Email sent</option>
                                <option value="Scheduled">Scheduled</option>
                                <option value="Completed">Completed</option>
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                                style={{ background: "#fff" }}
                              >
                                <path
                                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-4">
              <div className=" mb-2">
                <div
                  style={{
                    padding: "20px 25px 25px 25px",
                    borderRadius: "4px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="border-bottom"
                  >
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontStyle: "normal",
                        lineHeight: "normal",
                      }}
                    >
                      Client Information
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "20px",
                        color: "#48AF25",
                      }}
                    >
                      Active
                    </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }} className="mt-3">
                    <div style={{ height: "100%" }}>
                      <div className="profile-info_OTC_Admin">
                        <div className="user-info-avatar-container_OTC_Admin">
                          {TopAvatar !== "" ? (
                            <img
                              src={TopAvatar}
                              alt="Profile"
                              style={{
                                width: "54px",
                                height: "54px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <div
                              className="user-info-initials-overlay_OTC_Admin"
                              style={{ width: "54px", height: "54px" }}
                            >
                              {initials}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="name_john">
                          {orderData?.first_name && orderData?.last_name && (
                            <>
                              {orderData?.first_name?.charAt(0).toUpperCase() + orderData?.first_name?.slice(1)}{' '}
                              {orderData?.last_name?.charAt(0).toUpperCase() + orderData?.last_name?.slice(1)}
                            </>
                          )}
                        </div>
                        <div className="nick_nmae">
                          {" "}
                          {companyData?.company_name?.charAt(0).toUpperCase() +
                            companyData?.company_name?.slice(1) || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "14px" }} className="role">
                    Role : <span className="nick_nmae">{userData?.role}</span>
                  </div>
                  <div className="role2">
                    Plan :{" "}
                    <span className="nick_nmae" style={{ color: "#2572E6" }}>
                      {userInforamtionData?.subscription_details?.plan}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div className="role">Email:</div>
                      <div className="abc">{orderData?.email}</div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="22"
                        viewBox="0 0 27 22"
                        fill="none"
                      >
                        <path
                          d="M26.9984 3.00121C26.9984 1.53477 25.7986 0.334961 24.3322 0.334961H3.00219C1.53575 0.334961 0.335938 1.53477 0.335938 3.00121V18.9987C0.335938 20.4651 1.53575 21.665 3.00219 21.665H24.3322C25.7986 21.665 26.9984 20.4651 26.9984 18.9987V3.00121ZM24.3322 3.00121L13.6672 9.66684L3.00219 3.00121H24.3322ZM24.3322 18.9987H3.00219V5.66746L13.6672 12.3331L24.3322 5.66746V18.9987Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div className="role">Cell phone:</div>
                      <div className="abc">{orderData?.contact}</div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="31"
                        viewBox="0 0 21 31"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.33698 3.49962C2.33698 3.19023 2.45989 2.89352 2.67865 2.67475C2.89742 2.45598 3.19414 2.33308 3.50352 2.33308H17.502C17.8114 2.33308 18.1081 2.45598 18.3269 2.67475C18.5456 2.89352 18.6685 3.19023 18.6685 3.49962V26.8304C18.6685 27.1398 18.5456 27.4365 18.3269 27.6553C18.1081 27.874 17.8114 27.9969 17.502 27.9969H3.50352C3.19414 27.9969 2.89742 27.874 2.67865 27.6553C2.45989 27.4365 2.33698 27.1398 2.33698 26.8304V3.49962ZM3.50352 0C2.57537 0 1.68523 0.368709 1.02892 1.02501C0.372615 1.68132 0.00390625 2.57146 0.00390625 3.49962V26.8304C0.00390625 27.7585 0.372615 28.6487 1.02892 29.305C1.68523 29.9613 2.57537 30.33 3.50352 30.33H17.502C18.4301 30.33 19.3203 29.9613 19.9766 29.305C20.6329 28.6487 21.0016 27.7585 21.0016 26.8304V3.49962C21.0016 2.57146 20.6329 1.68132 19.9766 1.02501C19.3203 0.368709 18.4301 0 17.502 0H3.50352ZM7.00314 24.8473C6.78657 24.8473 6.57887 24.9333 6.42573 25.0864C6.27259 25.2396 6.18656 25.4473 6.18656 25.6638C6.18656 25.8804 6.27259 26.0881 6.42573 26.2413C6.57887 26.3944 6.78657 26.4804 7.00314 26.4804H14.0024C14.2189 26.4804 14.4266 26.3944 14.5798 26.2413C14.7329 26.0881 14.8189 25.8804 14.8189 25.6638C14.8189 25.4473 14.7329 25.2396 14.5798 25.0864C14.4266 24.9333 14.2189 24.8473 14.0024 24.8473H7.00314Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div className="role">Office number :</div>
                      <div className="abc">{companyData?.contact || "-"}</div>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                      >
                        <path
                          d="M22 16.6708V20.9435C22.0001 21.2494 21.8842 21.544 21.6757 21.7678C21.4671 21.9916 21.1814 22.128 20.8762 22.1494C20.3482 22.1856 19.9168 22.205 19.5833 22.205C8.90529 22.205 0.25 13.5497 0.25 2.87162C0.25 2.53812 0.268125 2.10675 0.305583 1.57871C0.326998 1.27353 0.463369 0.987844 0.687179 0.779283C0.91099 0.570723 1.20558 0.454818 1.5115 0.454956H5.78417C5.93405 0.454805 6.07863 0.510369 6.18984 0.610856C6.30104 0.711343 6.37093 0.849577 6.38592 0.998706C6.41371 1.27662 6.43908 1.49775 6.46325 1.66571C6.70339 3.34157 7.1955 4.9715 7.92292 6.50025C8.03771 6.74192 7.96279 7.03071 7.74529 7.18537L5.13771 9.04862C6.73205 12.7636 9.69258 15.7241 13.4075 17.3185L15.2684 14.7157C15.3444 14.6094 15.4554 14.5331 15.5819 14.5002C15.7085 14.4673 15.8425 14.4799 15.9607 14.5357C17.4893 15.2618 19.1188 15.7527 20.7941 15.9917C20.962 16.0159 21.1832 16.0412 21.4587 16.069C21.6076 16.0843 21.7455 16.1543 21.8458 16.2655C21.946 16.3767 22.0002 16.5211 22 16.6708Z"
                          fill="#333333"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white p-4 mt-2" style={{ borderRadius: "4px" }}>
                <div className="border-bottom  mb-2 d-flex justify-content-between">
                  <h5>Add ons note</h5>
                  {/* <button
                  style={{
                    padding: "10px 16px",
                    background: "#E65925",
                    border: "none",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    fontSize: "14px",
                    borderRadius: "4px",
                  }}
                >
                  Add Note
                </button> */}
                </div>
                <div className="gap-3 d-flex flex-column">
                  <div className="mt-2">
                    <textarea
                      className="w-100 border-0 p-2"
                      type="text"
                      style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                      name="addonsnote"
                      value={addonNotes}
                      onChange={(e) => {
                        setAddonNotes(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white p-4 mt-2" style={{ borderRadius: "8px" }}>
                <h5>Activity log</h5>
                <textarea
                  readOnly
                  className="w-100 border-0 p-2"
                  type="text"
                  style={{ borderRadius: " 4px", background: "#f2f2f2" }}
                  name="activitylog"
                  value={activityLog}
                  onChange={(e) => {
                    setActivityLog(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={apiSuccess}
          onClose={() => setApiSuccess(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={successModalStyle} style={{ textAlign: "center" }}>
            <div className="Modal-section">
              <div className="checkmark-container">
                <div class="checkmark-container">
                  {messageIcon}
                </div>
              </div>
              <span className="team_created_success">
                {/* { deleteCardMessage === true ? 'Card deleted successfully.' : 'Data updated successfully!'}
            {buttonClicked === true  ? 'New card added successfully.' :  'Data updated successfully!'} */}
                {successMessage}
              </span>
            </div>
            <button
              className="Modal-btn sec-modal-btn"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                width: "13%",
              }}
              onClick={() => setApiSuccess(false)}
            >
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
              </span>
            </button>
          </Box>
        </Modal>
      </>}



    </>
  );
};

export default OrderAddon;
