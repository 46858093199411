import { useEffect, useState } from "react";
import "./TagsInput.css";
import axios from "axios";
import { useParams } from "react-router-dom";

function TagsInput() {
  const [tags, setTags] = useState([]);
  const url = process.env.REACT_APP_URL;
  const { id } = useParams();

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
  }
  useEffect(() => {
    handlesaveTAg();
  }, [tags]);

  function removeTag(index) {
    const removedTag = tags[index];
    setTags(tags.filter((el, i) => i !== index));
    handlesaveTAg(removedTag);
  }

  const handlesaveTAg = async (removedTag) => {
    try {
      const { data } = await axios.post(
        `${url}/admin/savetag`,
        {
          TagData: tags,
          compID: id,
          removedTag: removedTag,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (data.success) {
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    const fetchTagData = async () => {
      try {
        const { data } = await axios.post(
          `${url}/admin/getclienttags`,
          { compID: id },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setTags(data.clientTagValues);
      } catch (error) {
      }
    };
    fetchTagData();
  }, []);

  return (
    <div
      className="onetap_conn_client_tags-input-container "
      style={{ margin: "0px", marginTop: "15px" }}
    >
      {tags.map((tag, index) => (
        <div className="onetap_conn_client_tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        type="text"
        className="onetap_conn_client_tags-input"
        placeholder="Add tags"
      />
    </div>
  );
}

export default TagsInput;
