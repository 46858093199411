import React, { useEffect, useRef, useState } from "react";
import "./mycardinfotab.css";
import cover_image from "../../../assets/users/my_card_cover_image.png";
import { useDispatch, useSelector } from "react-redux";
import { getPlan, getUnique_slug, loadUser } from "../../../actions/userAction/userAction";
import Loader from "../../Layout/Loader/Loader";
import { useNavigate } from "react-router-dom";
import Sharepopup from "./Popup";
import upgradeicon from "../../../assets/icons/upgradeicon.svg";
import PlanCard from "../../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../../components/PlanCard/PlanCard";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';

const MyCardsInfoTab = () => {
  const [showMoreOption, setShowMoreOption] = useState(false);
  const cardRef = useRef(null);
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.userInformation);
  const { unique_slug } = useSelector((state) => state.unique_slug);
  const { planData } = useSelector((state) => state.planData);
  const initials = (user?.first_name.charAt(0).toUpperCase() + user?.last_name.charAt(0).toUpperCase())
  const url = process.env.REACT_APP_URL
  const navigate = useNavigate();
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [plandata, setPlanData] = React.useState([]);
  const style3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "97%",
    bgcolor: "#F2F2F2",
    p: 4,
    outline: "none",
    overflowY: "scroll",
    height: "95%",
  };

  useEffect(() => {
    if (!user) {
      dispatch(loadUser());
    }
    if (!unique_slug) {
      dispatch(getUnique_slug());
    }
  }, [user, unique_slug]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDivClick = () => {
    setShowMoreOption(!showMoreOption);
  };

  const handleDocumentClick = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setShowMoreOption(false);
    }
  };

  const redirectProfileHandler = () => {
    if (userInfo?.subscription_details?.plan === "Team" && user?.role !== "teammember") {
      navigate(`/user/profile/${user?._id}`);
    } else {
      navigate("/profile");
    }
  }


  const lengthOfUniqueSlugs = unique_slug?.users_slug[0]?.unique_slugs.length;
  const latestIndex = lengthOfUniqueSlugs ? lengthOfUniqueSlugs - 1 : null;
  const uniqueslug = unique_slug && unique_slug?.users_slug[0]?.unique_slugs[latestIndex].value;
  const userPlan = userInfo && userInfo?.subscription_details?.plan;
  const { company } = useSelector((state) => state.Company);
  const comp_slug = unique_slug?.users_slug?.[0]?.companyunique_slug?.slice(-1)[0]?.value;
  const compny_uniq_slug = company && company.companyurlslug;
  const individual = user && user.isIndividual;
  const constructRedirectUrl = () => {

    if (individual && (userPlan === 'Free' || userPlan === 'Professional')) {
      return `${uniqueslug}`;
    } else if (individual && userPlan === 'Team') {
      const companySlug = compny_uniq_slug || comp_slug;
      return `${companySlug}/${uniqueslug}`;
    } else if (userPlan === 'Free') {
      return `${uniqueslug}`;
    } else {
      const companySlug = compny_uniq_slug || comp_slug;
      return `${companySlug}/${uniqueslug}`;
    }
  };
  const redirectToViewCard = () => {
    const redirectUrl = constructRedirectUrl();
    navigate(`/${redirectUrl}`);
  }
  const toggleSharePopup = () => {

    setShowSharePopup(!showSharePopup);
  };

  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const fetchandSetPlans = () => {
    if (planData) {
      setPlanData(planData.plans);
    } else {
      dispatch(getPlan());
    }
  }

  useEffect(() => {
    fetchandSetPlans();
  }, [planData])

  useEffect(() => {
    loadUser();
  }, [])

  const handleCloseOfPlanModal = () => {
    setShowPlanModal(false);
  };

  return (<>
    {loading ? <Loader /> :
      <div className="onetap_conn_card_container">
        <div className="onetap_conn_personal_card">
          <div className="onetap_conn_card_image_container">
            <img
              src={cover_image}
              alt="cover_photo"
              className="onetap_conn_card_image"
            />
            <div className="onetap_conn_user_image_container">
              {(!user?.avatar || user?.avatar === "") ? (
                <div className="header-avatar-initials-overlay">
                  {initials}
                </div>
              ) : (
                <img
                  src={`${url}/profile/img/${user?.avatar}`}
                  className="onetap_conn_user_image"
                />
              )}
            </div>
          </div>
          <div className="onetap_conn_personal_card_info">
            <div className="onetap_conn_personal_card_info_name">
              {user?.first_name.charAt(0).toUpperCase() + user?.first_name.slice(1)} {user?.last_name.charAt(0).toUpperCase() + user?.last_name.slice(1)}
            </div>
            <div className="onetap_conn_personal_card_info_subname">
              {user?.designation &&
                user.designation.reduce((acc, designation, index) => {
                  if (index % 4 === 0) {
                    acc.push([designation]);
                  } else {
                    acc[acc.length - 1].push(designation);
                  }
                  return acc;
                }, []).map((group, groupIndex) => (
                  <div key={groupIndex}>
                    {group.join(" | ")}
                  </div>
                ))}
            </div>
          </div>
          <div style={{ position: "relative" }} className="onetap_conn_personal_card_actions">
            {userInfo?.subscription_details?.plan === "Free" && <img src={upgradeicon} className="upgrade-icon-for-card" alt="Upgrade Icon" />}

            <Tooltip title={userInfo?.subscription_details?.plan === "Free" ? "Get access to enhanced reports for detailed insights." : ""} arrow>
              <div
                className={userInfo?.subscription_details?.plan === "Free" ? " onetap_conn_personal_card_actions1_Free" : "onetap_conn_personal_card_actions1"}
                onClick={() => {
                  if (userInfo?.subscription_details?.plan !== "Free") {
                    navigate("/analytics");
                  }
                }}
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    style={{ opacity: userInfo?.subscription_details?.plan === "Free" ? 0.5 : 1, }}
                  >
                    <path
                      d="M4.33398 29.8346V11.168H9.66732V29.8346H4.33398ZM13.6673 29.8346V3.16797H19.0007V29.8346H13.6673ZM23.0007 29.8346V19.168H28.334V29.8346H23.0007Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div style={{ opacity: userInfo?.subscription_details?.plan === "Free" ? 0.5 : 1, }}>Analytics</div>
              </div>
            </Tooltip>

            <div className="onetap_conn_personal_card_actions2" onClick={redirectProfileHandler}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="35"
                  viewBox="0 0 36 35"
                  fill="none"
                >
                  <path
                    d="M24.5259 7.88641L27.6146 10.9737M26.5121 5.16662L18.1603 13.5185C17.7287 13.9494 17.4344 14.4985 17.3144 15.0964L16.543 18.9581L20.4046 18.1852C21.0026 18.0656 21.5509 17.7725 21.9826 17.3408L30.3344 8.98891C30.5854 8.73794 30.7845 8.43999 30.9203 8.11207C31.0561 7.78416 31.126 7.4327 31.126 7.07777C31.126 6.72284 31.0561 6.37138 30.9203 6.04346C30.7845 5.71555 30.5854 5.4176 30.3344 5.16662C30.0834 4.91565 29.7855 4.71656 29.4576 4.58074C29.1297 4.44491 28.7782 4.375 28.4233 4.375C28.0683 4.375 27.7169 4.44491 27.389 4.58074C27.0611 4.71656 26.7631 4.91565 26.5121 5.16662Z"
                    stroke="white"
                    stroke-width="3.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.209 21.8763V26.2513C28.209 27.0249 27.9017 27.7667 27.3547 28.3137C26.8077 28.8607 26.0659 29.168 25.2923 29.168H9.25065C8.4771 29.168 7.73524 28.8607 7.18826 28.3137C6.64128 27.7667 6.33398 27.0249 6.33398 26.2513V10.2096C6.33398 9.43609 6.64128 8.69422 7.18826 8.14724C7.73524 7.60026 8.4771 7.29297 9.25065 7.29297H13.6257"
                    stroke="white"
                    stroke-width="3.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>Edit</div>
            </div>
            <div
              className="onetap_conn_personal_card_actions3"
              onClick={handleDivClick}
              ref={cardRef}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="35"
                  viewBox="0 0 36 35"
                  fill="none"
                >
                  <path
                    d="M4.54297 26.25V23.3333H30.793V26.25H4.54297ZM4.54297 18.9583V16.0417H30.793V18.9583H4.54297ZM4.54297 11.6667V8.75H30.793V11.6667H4.54297Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>More</div>
              {/* {showMoreOption && (
                <div className="onetap_conn_personal_card_actions3_dropdown">
                  <div className="onetap_conn_personal_card_actions3_dropdown_item1">
                    Deactivate
                  </div>
                </div>
              )} */}
            </div>
          </div>

          <div className="onetap_conn_personal_card_buttons">
            <div className="onetap_conn_personal_card_buttons1" onClick={redirectToViewCard}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    d="M16.2507 12C15.1898 12 14.1724 12.4214 13.4222 13.1716C12.6721 13.9217 12.2507 14.9391 12.2507 16C12.2507 17.0609 12.6721 18.0783 13.4222 18.8284C14.1724 19.5786 15.1898 20 16.2507 20C17.3115 20 18.3289 19.5786 19.0791 18.8284C19.8292 18.0783 20.2507 17.0609 20.2507 16C20.2507 14.9391 19.8292 13.9217 19.0791 13.1716C18.3289 12.4214 17.3115 12 16.2507 12ZM16.2507 22.6667C14.4825 22.6667 12.7868 21.9643 11.5366 20.714C10.2864 19.4638 9.58398 17.7681 9.58398 16C9.58398 14.2319 10.2864 12.5362 11.5366 11.286C12.7868 10.0357 14.4825 9.33333 16.2507 9.33333C18.0188 9.33333 19.7145 10.0357 20.9647 11.286C22.2149 12.5362 22.9173 14.2319 22.9173 16C22.9173 17.7681 22.2149 19.4638 20.9647 20.714C19.7145 21.9643 18.0188 22.6667 16.2507 22.6667ZM16.2507 6C9.58398 6 3.89065 10.1467 1.58398 16C3.89065 21.8533 9.58398 26 16.2507 26C22.9173 26 28.6107 21.8533 30.9173 16C28.6107 10.1467 22.9173 6 16.2507 6Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>View Card</div>
            </div>
            <div className="onetap_conn_personal_card_buttons2" onClick={toggleSharePopup}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="36"
                  viewBox="0 0 37 36"
                  fill="none"
                >
                  <path
                    d="M27.75 33C26.5 33 25.4375 32.5625 24.5625 31.6875C23.6875 30.8125 23.25 29.75 23.25 28.5C23.25 28.325 23.2625 28.1435 23.2875 27.9555C23.3125 27.7675 23.35 27.599 23.4 27.45L12.825 21.3C12.4 21.675 11.925 21.969 11.4 22.182C10.875 22.395 10.325 22.501 9.75 22.5C8.5 22.5 7.4375 22.0625 6.5625 21.1875C5.6875 20.3125 5.25 19.25 5.25 18C5.25 16.75 5.6875 15.6875 6.5625 14.8125C7.4375 13.9375 8.5 13.5 9.75 13.5C10.325 13.5 10.875 13.6065 11.4 13.8195C11.925 14.0325 12.4 14.326 12.825 14.7L23.4 8.55C23.35 8.4 23.3125 8.2315 23.2875 8.0445C23.2625 7.8575 23.25 7.676 23.25 7.5C23.25 6.25 23.6875 5.1875 24.5625 4.3125C25.4375 3.4375 26.5 3 27.75 3C29 3 30.0625 3.4375 30.9375 4.3125C31.8125 5.1875 32.25 6.25 32.25 7.5C32.25 8.75 31.8125 9.8125 30.9375 10.6875C30.0625 11.5625 29 12 27.75 12C27.175 12 26.625 11.894 26.1 11.682C25.575 11.47 25.1 11.176 24.675 10.8L14.1 16.95C14.15 17.1 14.1875 17.269 14.2125 17.457C14.2375 17.645 14.25 17.826 14.25 18C14.25 18.175 14.2375 18.3565 14.2125 18.5445C14.1875 18.7325 14.15 18.901 14.1 19.05L24.675 25.2C25.1 24.825 25.575 24.5315 26.1 24.3195C26.625 24.1075 27.175 24.001 27.75 24C29 24 30.0625 24.4375 30.9375 25.3125C31.8125 26.1875 32.25 27.25 32.25 28.5C32.25 29.75 31.8125 30.8125 30.9375 31.6875C30.0625 32.5625 29 33 27.75 33Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>Share</div>
            </div>
          </div>
        </div>
      </div>}
    {showSharePopup && <Sharepopup onClose={toggleSharePopup} />}
    <Modal
      open={showPlanModal}
      onClose={handleCloseOfPlanModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style3}>
        <div className="Modal-section">
          <h2 className="header" style={{ backgroundColor: '#F2F2F2' }}>
            Change plan
            <CloseIcon
              className="icon"
              onClick={handleCloseOfPlanModal}
            />
          </h2>
          <div className={`sliding-switch ${isActive ? "active" : ""}`}>
            <div className="switch-handle"></div>
            <div className="switch-text">
              <span
                className={`switch-text-option ${isActive ? "active" : ""}`}
                onClick={handleToggle}
              >
                Pay Monthly
              </span>
              <span
                className={`switch-text-option ${!isActive ? "active" : ""}`}
                onClick={handleToggle}
              >
                Yearly and save 20%
              </span>
            </div>
          </div>
          {isActive ? (
            <>
              <div className="plan-selection-section">
                {plandata &&
                  plandata.map((plans, i) => {
                    if (plans.isActive === false) {
                      return;
                    }
                    if (plans.type === "monthly") {
                      return;
                    }
                    if (plans.planType === "Free") {
                      return <YearlyCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Professional") {
                      return <YearlyCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Team") {
                      return <YearlyCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Enterprise") {
                      return <YearlyCard props={plans} key={i} cycle={isActive} />;
                    }
                  })}
              </div>
            </>
          ) : (
            <>
              <div className="plan-selection-section">
                {plandata &&
                  plandata.map((plans, i) => {
                    if (plans.isActive === false) {
                      return;
                    }
                    if (plans.type === "yearly") {
                      return;
                    }
                    if (plans.planType === "Free") {
                      return <PlanCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Professional") {
                      return <PlanCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Team") {
                      return <PlanCard props={plans} key={i} cycle={isActive} />;
                    }
                    if (plans.planType === "Enterprise") {
                      return <PlanCard props={plans} key={i} cycle={isActive} />;
                    }

                  })}
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  </>
  );
};

export default MyCardsInfoTab;
