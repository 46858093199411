import React, { useEffect, useState } from "react";

const MyCardHeaderWithIcons = (props) => {
  const [cardInfoTab, setCardInfoTab] = useState("My cards");
  const { tab } = props;

  useEffect(() => {
    setCardInfoTab(tab);
  }, [tab]);

  const handleClickOfTab = (value) => {
    setCardInfoTab(value);
    props.onChange(value);
  };

  return (
    <>
      <div className="onetap_connect_mycard_header_syn">
        <div
          onClick={() => handleClickOfTab("My cards")}
          className={
            cardInfoTab === "My cards"
              ? "onetap_connect_mycard_header_icon_and_header_active_syn"
              : "onetap_connect_mycard_header_icon_and_header_deactive_syn"
          }
        >
          <div
            style={{
              backgroundColor: cardInfoTab === "My cards" ? "#E65925" : "",
              width: "34px",
              height: "32px",
              borderRadius: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              style={{ cursor: "pointer" }}
            >
              <path
                d="M2 4H0V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16V18H2V4ZM18 0C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H6C5.46957 16 4.96086 15.7893 4.58579 15.4142C4.21071 15.0391 4 14.5304 4 14V2C4 1.46957 4.21071 0.960859 4.58579 0.585786C4.96086 0.210714 5.46957 0 6 0H18ZM15 5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7956 2 12 2C11.2044 2 10.4413 2.31607 9.87868 2.87868C9.31607 3.44129 9 4.20435 9 5C9 5.79565 9.31607 6.55871 9.87868 7.12132C10.4413 7.68393 11.2044 8 12 8C12.7956 8 13.5587 7.68393 14.1213 7.12132C14.6839 6.55871 15 5.79565 15 5ZM6 13V14H18V13C18 11 14 9.9 12 9.9C10 9.9 6 11 6 13Z"
                fill={cardInfoTab === "My cards" ? "#ffff" : "#000000"}
              />
            </svg>
          </div>

          <p
            className={
              cardInfoTab === "My cards"
                ? "onetap_connect_mycard_active_header_syn"
                : "onetap_connect_mycard_headers_syn"
            }
          >
            My card
          </p>
        </div>
      </div>
    </>
  );
};

export default MyCardHeaderWithIcons;
