import React, { useState, useEffect } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import logo from '../../../assets/icons/logo_black.svg'
import { useNavigate } from "react-router-dom";
import exitLogo from '../../../assets/icons/exit.svg'
import './AccountSelection.css'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../Layout/Loader/Loader";
import { logout } from "../../../actions/userAction/userAction";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28.125rem",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: ".625rem",
};

const RemoveAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_URL;
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [id, setID] = React.useState("");
    const handleOpen = (e, b) => { setOpen(true); setEmail(e); setID(b) };
    const handleClose = () => { setOpen(false); };

    const [isLoading, setIsLoading] = useState(true);
    const { userprofileimage } = useSelector((state) => state.getavatar)
    const [avatarimage, setAvatarimage] = useState("");
    const [localStorageValues, setLocalStorageValues] = useState([]);
    const fetchAllLocalStorageValues = async () => {
        const localStorageKeys = Object.keys(localStorage);
        const filteredKeys = localStorageKeys.filter((key) =>
            key.startsWith("MemberID")
        );
        const values = filteredKeys.map((key) => localStorage.getItem(key));
        //API call to fetch account
        fetchData(values);
        // setLocalStorageValues(response.userData)
    };

    useEffect(() => {
        setAvatarimage(userprofileimage);
    }, [userprofileimage]);
    const fetchData = async (values) => {
        try {
            const { data } = await axios.post(
                `${url}`,
                { values },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            setLocalStorageValues(data.userData);
            setIsLoading(false)
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchAllLocalStorageValues();
    }, []);

    useEffect(() => { }, [localStorageValues]);

    const handleRemoveAccount = (userid) => {
        const memberIdKey = `MemberID_${userid.slice(-8)}`;
        localStorage.removeItem(memberIdKey);
        window.location.reload();
    };
    const removeAllAccounts = () => {
        const localStorageKeys = Object.keys(localStorage);
        const memberIDKeys = localStorageKeys.filter((key) => key.startsWith("MemberID"));
        memberIDKeys.forEach((key) => {
            localStorage.removeItem(key);
        });
    };
    const dispatchLogout = (userIds) => {
        // Dispatch the logout action for each userId
        userIds.forEach((userId) => {
            dispatch(logout(userId));
        });
    };

    return (
        <div className="forgot_password_container" style={{ height: '100%', minHeight: '100vh', backgroundColor: 'white' }}>
            <div className="forgot_password_left" style={{ justifyContent: 'unset', height: '100%', minHeight: '100vh' }}>
                <div className="" style={{ marginRight: 'auto', marginTop: '20px' }}>
                    <img style={{ cursor: "pointer" }} onClick={() => { navigate('/dashboard') }} src={logo} alt="" srcset="" />
                </div>
                <h3 className="mb-5 text-white" style={{ marginTop: '50px' }}>Remove accounts from this browser</h3>
                {isLoading ? <Loader /> :
                    <div>
                        <div className="text-white pt-1 ps-4 pe-5 custom-scrollbar" style={{ maxHeight: '322px', overflowY: 'auto' }}>
                            {localStorageValues &&
                                localStorageValues.map((val, i) => {
                                    const fullName = `${val.first_name} ${val.last_name}`;
                                    const words = fullName.split(" ");
                                    const initialss = words.map((word) => word.charAt(0).toUpperCase());

                                    let initials;
                                    if (initialss?.length >= 2) {
                                        initials = initialss.slice(0, 2).join("");
                                    } else {
                                        initials = initialss.join("");
                                    }

                                    return (
                                        <div className="d-flex align-items-center mb-3 pb-3 border-bottom"
                                            key={i}
                                            role="button"
                                            onClick={() => { handleOpen(val.email, val._id) }}
                                        >
                                            <div className="user-info-avatar-container_OTC_Admin">
                                                {val?.avatar ? (
                                                    <img
                                                        src={val?.avatarurl}
                                                        alt="Profile"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius: "100%",
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        className="user-info-initials-overlay_OTC_Admin"
                                                        style={{ width: "40px", height: "40px" }}
                                                    >
                                                        {initials}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex-grow-1"> {/* Added class to make this div take up remaining space */}
                                                <div>
                                                    <p className='mb-0' style={{ textTransform: "capitalize", fontSize: '16px' }}>{fullName}</p>
                                                    <p className="mb-0 column1_text_OTC_Admin">{val.email}</p>
                                                </div>
                                            </div>
                                            <div className="ms-2">
                                                <img
                                                    src={exitLogo}
                                                    alt="exit"
                                                    style={{
                                                        width: "24px",
                                                        height: "24px",
                                                        borderRadius: "100%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="text-white ps-4 pe-5" onClick={() => { dispatchLogout(localStorageValues.map((val) => val._id)); removeAllAccounts(); }}>
                            <div className="d-flex align-items-center mb-3 pb-3 border-bottom" role="button" >
                                <div className="text-center" style={{ width: '3.1rem', marginRight: '10px' }}>
                                    <img
                                        src={exitLogo}
                                        alt="exit"
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            borderRadius: "100%",
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className='mb-0' style={{ fontSize: '16px' }}>Remove all accounts </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="forgot_password_right" style={{ height: '100%', minHeight: '100vh' }}>
                <h3>Meet Kimba</h3>
                <img src={phoneImg} alt="phoneImg" />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="sec-Modal-section">
                        <div className="sec-modal-header-group border-0">
                            <h3 className="header">
                                Remove Account{" "}
                                {/* <CloseIcon className="icon" onClick={handleClose} /> */}
                            </h3>
                            <p>You can always add the account for {email} back to this browser when you're ready to use it again.</p>
                            <div className="video-Modal-btn-group" >
                                <button className="video-Modal-btn video-first-modal-btn" onClick={() => handleRemoveAccount(id)}>Remove</button>
                                <button className="video-Modal-btn video-sec-modal-btn" onClick={handleClose}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
export default RemoveAccount;
