import React, { useEffect, useState } from "react";
import "./Popup.css";
import Btn1icon from "../../../assets/icons/share_card-icon1.svg";
import Btn2icon from "../../../assets/icons/share_card_icon2.svg";
import Btn3icon from '../../../assets/icons/share_card_icon3.svg';
import sharecloseicon from "../../../assets/icons/share_card-icon6.svg";
import shareicon1 from "../../../assets/icons/share_card_icon5.svg";
import shareicon2 from "../../../assets/icons/share_card-icon4.svg";
import {
    getUnique_slug,
    getCompanyProfileDetails,
    fetchUserInformationDetails,
    getcompanies_share_referral_data,
    SingleTeamMemberDetails,
} from "../../../actions/userAction/userAction.js";
import { useDispatch, useSelector } from "react-redux";
import QrCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faPinterest, faTelegram, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserPopup = ({ onClose, userId }) => {

    const [qrCodeData, setQrCodeData] = useState("");
    const frontendURL = window.location.origin;
    const dispatch = useDispatch();
    const [showTooltip, setShowTooltip] = useState(false);
    const { teamMemberDetails, loading } = useSelector(state => state.TeammemberDetails);
    const { userInfo } = useSelector((state) => state.userInformation);
    const [individual, setIndividual] = useState(teamMemberDetails?.isIndividual);
    const [userid, setUserId] = useState(teamMemberDetails?._id);
    const [fname, setFirstName] = useState();
    const [lname, setLastName] = useState()
    const [currentContent, setCurrentContent] = useState("default");
    const [recipientName, setRecipientName] = useState('');
    const [recipientEmail, setRecipientEmail] = useState("");
    const [recipientText, setRecipientText] = useState('');
    const [recipientNameError, setRecipientNameError] = useState("");
    const [recipientEmailError, setRecipientEmailError] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [facebookShareUrl, setFacebookShareUrl] = useState("");
    const navigate = useNavigate();
    const [comp_slug, setCompanySlug] = useState()
    const [useruniqueslug, setusersuniqslug] = useState()
    const url = process.env.REACT_APP_URL;

    useEffect(() => {
        dispatch(getUnique_slug())
        dispatch(getCompanyProfileDetails())
        dispatch(fetchUserInformationDetails())
        dispatch(getcompanies_share_referral_data())
    }, [])

    useEffect(() => {
        dispatch(SingleTeamMemberDetails(userId))
    }, [dispatch, userId])

    const abadaba = async () => {
        try {
            const { data } = await axios.post(
                `${url}/getuniqueslugs`,
                { id: userId },
                {
                    headers: {
                      "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            // Accessing the users_slug array
            const usersSlugsArray = data.users_slug;

            // Checking if the array is not empty
            if (usersSlugsArray.length > 0) {
                // Accessing userurlslug and companyurlslug from the first object
                const userurlslug = usersSlugsArray[0].userurlslug;
                const companyurlslug = usersSlugsArray[0].companyurlslug;
                setCompanySlug(companyurlslug)
                setusersuniqslug(userurlslug)


            }
        } catch (error) {
            
        }
    };

    useEffect(() => {
        abadaba();
    }, [userId]);



    useEffect(() => {
        if (teamMemberDetails) {
            setRecipientName(teamMemberDetails.first_name);
            setRecipientText(`Hi there, it’s ${teamMemberDetails?.first_name} ${teamMemberDetails.last_name}. Please click the link below so we can share contact info. Talk soon!`)
        }
    }, [teamMemberDetails])

    const userPlan = userInfo && userInfo?.subscription_details?.plan;
    const { company } = useSelector((state) => state.Company);

    const compny_uniq_slug = company && company.companyurlslug;
    const { companyShareReferData } = useSelector((state) => state.companyShareReferData);

    const shareData2 = companyShareReferData && companyShareReferData.share_by_text;
    const shareData1 = companyShareReferData && companyShareReferData.share_by_socialmedia;

    const qrData = (() => {
        const baseLink = `${frontendURL}/`;

        if ((individual && userPlan === 'Free') || userPlan === 'Professional') {
            return `${baseLink}${useruniqueslug}`;
        } else if (individual && userPlan === 'Team') {
            const companySlug = comp_slug || compny_uniq_slug;
            return `${baseLink}${companySlug}/${useruniqueslug}`;
        } else if (userPlan === 'Free') {
            return `${baseLink}${useruniqueslug}`;
        } else {
            const companySlug = comp_slug || compny_uniq_slug;
            return `${baseLink}${companySlug}/${useruniqueslug}`;
        }
    })();


    useEffect(() => {
        setQrCodeData(qrData);
    }, [qrData]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(qrData);
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 1000);
    };

    const handleBtn2Click = () => {
        setCurrentContent(currentContent === "second" ? "default" : "second");
    };
    const handleBtn3Click = () => {
        setCurrentContent(currentContent === "third" ? "default" : "third");
    };
    const formData = {
        recipientName,
        recipientEmail,
        recipientText,
        UserID: userid,
        frontendURL,
        comp_slug,
        useruniqueslug,
    };
    const handleSubmit = async () => {
        setRecipientNameError("");
        setRecipientEmailError("");

        let isValid = true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!recipientName.trim()) {
            setRecipientNameError("Name is required.");
            isValid = false;
        }

        if (!recipientEmail.trim()) {
            setRecipientEmailError("Email is required.");
            isValid = false;
        } else if (!emailRegex.test(recipientEmail.trim())) {
            setRecipientEmailError("Please enter a valid email address.");
            isValid = false;
        }

        if (!isValid) {
            return;
        }
        try {
            const { data } = await axios.post(
                `${url}/share_mycard_email`,
                {
                    formData: formData,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );

            if (data.message) {
                setSuccessMessage("Email sent successfully");
                setTimeout(() => {
                    setSuccessMessage("")
                    setRecipientName(`${fname} ${lname}`);
                    setRecipientEmail("");
                    setRecipientText("Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon!");
                }, 3000);
            } else {
            }
        } catch (error) {
            ;
        }
    };
    const openPopupWindow = (url, width, height) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = (screenWidth - width) / 2;
        const top = (screenHeight - height) / 2;

        window.open(url, "_blank", `width=${width},height=${height},left=${left},top=${top}`);
    };
    const handleFacebookButtonClick = () => {
        const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=722669203106620&display=popup&href=${encodeURIComponent(qrData)}`;
        setFacebookShareUrl(facebookShareUrl);
        openPopupWindow(facebookShareUrl, 600, 400);
    };

    const handleTwitterButtonClick = () => {
        const replacedShareData2 = shareData1 && shareData1
            .replace('{user_name}', recipientName)
            .replace('{company_name}', company?.company_name);
        const customMessage = encodeURIComponent(`${replacedShareData2}`);
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(qrData)}&text=${customMessage}`;
        openPopupWindow(twitterShareUrl, 600, 400);
    };
    const handleLinkedInButtonClick = () => {
        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(qrData)}`;
        openPopupWindow(linkedInShareUrl, 600, 400);
    };
    const handlePinterestButtonClick = () => {
        const pinterestShareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(qrData)}`;
        openPopupWindow(pinterestShareUrl, 600, 400);
    };
    const handleTelegramButtonClick = () => {
        const replacedShareData2 = shareData1 && shareData1
            .replace('{user_name}', recipientName)
            .replace('{company_name}', company?.company_name);
        const customMessage = encodeURIComponent(`${replacedShareData2}`);
        const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(qrData)}&text=${customMessage}`;
        openPopupWindow(telegramShareUrl, 600, 400);
    };
    const handleWhatsappButtonClick = () => {
        const replacedShareData2 = shareData1 && shareData1
            .replace('{user_name}', recipientName)
            .replace('{company_name}', company?.company_name);
        const customMessage = encodeURIComponent(`${replacedShareData2}`);
        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${customMessage} ${encodeURIComponent(qrData)}`;
        openPopupWindow(whatsappShareUrl, 600, 400);
    };



    const handleSharebytext2 = () => {
        const replacedShareData = shareData2
            .replace('{user_name}', recipientName)
            .replace('{company_name}', company?.company_name);

        const combinedText = `${replacedShareData}\n${qrData}`;

        const smsLink = `sms:?&body=${encodeURIComponent(combinedText)}`;
        // Check if the browser supports the sms: URI scheme
        if (navigator.share && navigator.share.canShare && navigator.share.canShare({ url: smsLink })) {
            navigator.share({
                title: 'Check this out!',
                text: combinedText,
                url: smsLink,
            })
        } else {
            // Fallback for browsers that don't support the Web Share API
            window.location.href = smsLink;
        }
    }



    return (

        <div className="onetap_conn_share-popup_mycard_heaad">
            <div className="onetap_conn_popup-content_mycard_heaad">
                {currentContent === "default" ? (
                    <div>
                        <div class="row" style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: '10px' }}>
                            <div class="col-md-9" style={{ textAlign: 'left', fontSize: 'larger', fontWeight: '700', padding: 0 }}>Share this card</div>
                            <div class="col-5 col-md-3" style={{ textAlign: '-webkit-right', padding: 0 }}>
                                <button onClick={onClose} className="onetap_conn_share_marcard_close-button">
                                    <img src={sharecloseicon} alt="icon" />
                                </button>
                            </div>
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <QrCode value={qrCodeData} />
                        </div>
                        <div className="onetap_conn_mycard_popup_mid" onClick={handleCopyLink}>
                            Copy link <img src={shareicon1} alt="icon" />&nbsp;&nbsp;
                        </div>
                        {showTooltip && (<div className="onetap_conn_share_card_custom-tooltip">URL copied!</div>)}
                        <div className='onetap_conn_share_card_popup_mid2'>
                            Share Card
                        </div>
                        <div className='onetap_conn_share_card_mid3' >

                            <div onClick={handleSharebytext2} style={{ cursor: 'pointer' }}>
                                <img src={Btn1icon} alt="icon" />
                            </div>

                            <div onClick={handleBtn2Click} style={{ cursor: 'pointer' }}>
                                <img src={Btn2icon} alt="icon" />
                            </div>
                            <div onClick={handleBtn3Click} style={{ cursor: 'pointer' }}>
                                <img src={Btn3icon} alt="social" />
                            </div>
                        </div>
                        <div className="onetap_conn_share_card_endhead">
                            Tutorial
                        </div>
                        <div className="onetap_conn_share_card_end" onClick={() => navigate("/tutorials")} >
                            <img src={shareicon2} alt="icon" />&nbsp;&nbsp; How to share my card
                        </div>
                    </div>
                ) : currentContent === "second" ? (
                    <div>
                        <div class="row" >
                            <div class="col-md-9" style={{ textAlign: 'left', fontSize: 'larger', fontWeight: '700', padding: 0 }}></div>
                            <div class="col-5 col-md-3" style={{ textAlign: '-webkit-right', padding: 0 }}>
                                <button onClick={onClose} className="onetap_conn_share_marcard_close-button">
                                    <img src={sharecloseicon} alt="icon" />
                                </button>
                            </div>
                            <div className='onetap_conn_share_card_popup_mid2'>
                                Share By Email
                            </div>
                            <div style={{ textAlign: 'left', marginTop: '10px', fontWeight: '600' }}>
                                Please add your name and the recipient's email below. We'll take care of the rest.
                            </div>
                            <div style={{ textAlign: 'left', marginTop: '10px', fontWeight: '600' }}>
                                Thank you for sharing!
                            </div>
                            <div style={{ marginTop: '30px' }}>
                                <input type="text" placeholder='Enter your name' id="recipientName" className='onetap_conn_share_input' value={teamMemberDetails?.first_name + ' ' + teamMemberDetails?.last_name}
                                    onChange={(e) => { setRecipientName(e.target.value); setRecipientNameError(""); }} />
                                {recipientNameError && <div style={{ color: 'red' }}>{recipientNameError}</div>}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <input type="email" placeholder="Recipient's Email" id="recipientEmail" className='onetap_conn_share_input' value={recipientEmail} onChange={(e) => { setRecipientEmail(e.target.value); setRecipientEmailError(""); }} />
                                {recipientEmailError && <div style={{ color: 'red' }}>{recipientEmailError}</div>}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <textarea
                                    placeholder="Text message"
                                    id="textMessage"
                                    className='onetap_conn_share_input'
                                    value={recipientText}
                                    onChange={(e) => setRecipientText(e.target.value)}
                                    rows="2"
                                    cols="50"
                                />
                            </div>
                        </div>
                        <button className="onetap_conn_share_send_" onClick={handleSubmit}>SEND CARD</button>
                        {successMessage && (
                            <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
                                {successMessage}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <div class="row" style={{ borderBottom: '1px solid #F2F2F2', paddingBottom: '10px' }}>
                            <div class="col-md-9" style={{ textAlign: 'left', fontSize: 'larger', fontWeight: '700', padding: 0 }}>Share this card</div>
                            <div class="col-5 col-md-3" style={{ textAlign: '-webkit-right', padding: 0 }}>
                                <button onClick={onClose} className="onetap_conn_share_marcard_close-button">
                                    <img src={sharecloseicon} alt="icon" />
                                </button>
                            </div>
                        </div>
                        <div className='onetap_conn_share_card_popup_mid2'>
                            Social Media
                        </div>
                        <div class="ontap_conn_share_card_main-container">
                            <div class="ontap_conn_share_card_sub-container_fb" onClick={handleFacebookButtonClick}>
                                <div class="ontap_conn_share_card_sub-part_fb sub-part-30_fb">
                                    <FontAwesomeIcon icon={faFacebook} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part_fb sub-part-70_fb">
                                    Facebook
                                </div>
                            </div>
                            <div class="ontap_conn_share_card_sub-container_tw" onClick={handleTwitterButtonClick}>
                                <div class="ontap_conn_share_card_sub-part sub-part-30_tw">
                                    <FontAwesomeIcon icon={faXTwitter} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part sub-part-70_tw">
                                    X
                                </div>
                            </div>
                            <div class="ontap_conn_share_card_sub-container_lk" onClick={handleLinkedInButtonClick}>
                                <div class="ontap_conn_share_card_sub-part sub-part-30_lk">
                                    <FontAwesomeIcon icon={faLinkedin} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part sub-part-70_lk">
                                    LinkedIn
                                </div>
                            </div>
                        </div>
                        <div class="ontap_conn_share_card_main-container2">
                            <div class="ontap_conn_share_card_sub-container_pe" onClick={handlePinterestButtonClick}>
                                <div class="ontap_conn_share_card_sub-part_pe sub-part-30_pe">
                                    <FontAwesomeIcon icon={faPinterest} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part_fb sub-part-70_pe">
                                    Pinterest
                                </div>
                            </div>
                            <div class="ontap_conn_share_card_sub-container_te" onClick={handleTelegramButtonClick}>
                                <div class="ontap_conn_share_card_sub-part sub-part-30_te">
                                    <FontAwesomeIcon icon={faTelegram} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part sub-part-70_te">
                                    Telegram
                                </div>
                            </div>
                            <div class="ontap_conn_share_card_sub-container_wa" onClick={handleWhatsappButtonClick}>
                                <div class="ontap_conn_share_card_sub-part sub-part-30_wa">
                                    <FontAwesomeIcon icon={faWhatsapp} size="lg" style={{ color: "#ffffff", transform: "translateY(10px)" }} />
                                </div>
                                <div class="ontap_conn_share_card_sub-part sub-part-70_wa">
                                    WhatsApp
                                </div>
                            </div>
                        </div>
                        <div className="onetap_conn_share_card_endhead">
                            Tutorial
                        </div>
                        <div className="onetap_conn_share_card_end" onClick={() => navigate("/tutorials")}>
                            <img src={shareicon2} alt="icon" />&nbsp;&nbsp; How to share my card
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserPopup;