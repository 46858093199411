
import React, { useState } from "react";
import Otc_Sidebar from "../../Layout/Sidebar/Otc_Sidebar";
import Coupons from "./Coupons";
import CouponHeaderWithIcon from "./CouponHeaderWithIcon";


const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Coupons");
    const [showCreateCoupon, setShowCreateCoupon] = useState(false); 

    const handleCouponsClick = () => {
        setShowCreateCoupon(false);
    };

    const handleAddNewCouponClick = () => {
        setShowCreateCoupon(true);
    }
    const handleCouponNameClick = () => {
        setShowCreateCoupon(true);
    };

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    return (
        <div className="onetap_conn_user" >
            <CouponHeaderWithIcon onChange={getTabValue} tab={companyProfileTab}
                showCreateCoupon={showCreateCoupon}
                onCouponsClick={handleCouponsClick} 
                onAddNewCouponClick={handleAddNewCouponClick}
            />

            {companyProfileTab === "Coupons" && (
                <Coupons showCreateCoupon={showCreateCoupon} onCouponNameClick={handleCouponNameClick} handleCouponsClick={handleCouponsClick} />

            )}
        </div>
    );
};


export default function CouponMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} productsServicesOpen={true} />
        </>
    )
}





