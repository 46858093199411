import React, { useState } from 'react'
import OtcSidebar from '../Layout/Sidebar/Otc_Sidebar';
import OTCAdminusersheaders from './OTC_Adminusersheaders';
import OTCAdminusersinfo from './OTC_Adminusersinfo';
import OTC_adminTeams from './OTC_adminTeams';

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("OTC Users");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="onetap_conn_user">
            <OTCAdminusersheaders onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "OTC Users" && (
                <OTCAdminusersinfo
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
              {companyProfileTab === "Teams" && (
                <OTC_adminTeams
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
        </div>
    );
};

const Otc_Adminusers = () => {
  return (
    <OtcSidebar Component={Component}  productsServicesOpen2={true}/>
  )
}

export default Otc_Adminusers