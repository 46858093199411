import React, { useEffect, useState } from "react";
import '../../components/Customers/SmartAccessories/SmartAccessories.scss';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Loader from '../../components/Layout/Loader/Loader';
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../actions/userAction/userAction";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import selectDropdownIcon from "../Otc_assets/OTC_ICONS/selectDropdownIcon.svg"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "28.125rem",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: ".625rem",
};

const SmartAccessoryPrefix = () => {
    const url = process.env.REACT_APP_URL;
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { products } = useSelector((state) => state.products);
    const [openModal, setOpenModal] = React.useState(false)
    const [selectedAccessory, setSelectedAccessory] = useState('');
    const [prefix, setPrefix] = useState('');
    const [selectAccessoryValidationError, setselectAccessoryValidationError] = useState("");
    const [selectedProduct, setSelectedProduct] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);

    const uniqueProductNames = [...new Set(
        products
            ?.filter(item => item?.visibility === 'Public' && item?.status === 'Published')
            .map(product => product?.name)
    )];

    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFiltersApplied(true);
    };
    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
        if (e.target.value !== "") {
            setFiltersApplied(true); // Filters applied, show "Clear filters" div
        } else if (selectedProduct === "") {
            setFiltersApplied(false);
        }
    }
    const handleClearFilters = () => {
        setSelectedProduct('');
        setSearchTerm('');
        setFiltersApplied(false); // Reset filters and hide "Clear filters" div
    };
    const handleOpenModal = (product, variation) => {
        if (variation) {
            setSelectedAccessory({ product: product, variation: variation });
            setPrefix(variation?.prefix);
        } else {
            setSelectedAccessory({ product: product, variation: variation });
            setPrefix(product?.prefix);
        }

        setOpenModal(true)
    };
    const handleCloseModal = () => setOpenModal(false);


    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [])
    
    const showToast = () => {
        toast.success("Prefix Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
        });
    };
    const handleSave = async () => {
        if (!prefix) {
            setselectAccessoryValidationError("Please enter prefix.");
            return;
        }
        setselectAccessoryValidationError("");
        const id = selectedAccessory?.product?._id
        const variationId = selectedAccessory?.variation?._id || null
        const response = await axios.post(
            `${url}/admin/updatePrefixOfProduct`,
            {
                prefix,
                id,
                variationId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );
        
        if (response.data.success) {
            dispatch(getProducts())
            handleCloseModal()
            showToast();
            setSelectedAccessory('')
        }
    }
    const filteredProducts = products?.filter((val) => (
        val?.status === 'Published' &&
        val?.visibility === 'Public' &&
        (!searchTerm || val?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || val?.prefix?.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!selectedProduct || val?.name === selectedProduct)
    ));
    return (
        <div className="onetap_connect_const_smartaccessoriestab">
            <div className="onetap_conn_users_tab_content_OTC_Admin">

                <div className="onetap_connct_filters_synr" style={{ marginTop: '0px' }}>
                    <div class="onetap_connect_search_box_synr">
                        <input
                            style={{ paddingRight: "50px" }}
                            className="onetap_connect_search_input_synr"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <svg style={{ position: "absolute", top: "10px", right: "10px" }} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z" fill="#000000" />
                        </svg>
                    </div>

                    <div style={{ position: "relative" }} className="onetap_connect_select_option_synr">
                        <select
                            className="select-smart-acc pc-status-dropdown"
                            value={selectedProduct}
                            onChange={handleProductChange}
                        >
                            <option value="">Smart Accessories</option>
                            {uniqueProductNames.map((productName, index) => (
                                <option key={index} value={productName}>
                                    {productName.charAt(0).toUpperCase() + productName.slice(1).toLowerCase()}
                                </option>
                            ))}
                        </select>
                        <img className="order-downarrow-icon" src={selectDropdownIcon} alt="" />

                    </div>
                </div>
                <button className="clear-fillter" style={{ marginTop: "10px", display: (selectedProduct || searchTerm) ? 'block' : 'none' }} onClick={handleClearFilters}>
                    Clear filters
                </button>
                <div className="main-smart-acc-section">
                    <div className="otc-smartAccessories-table mt-3">
                        <div className="add-new-btn-group" >
                            <p style={{ marginLeft: "12px" }} className="addNew-text-smart header-text-smart-acc" >Name</p>
                        </div>
                        <span className="header-text-smart-acc">Prefix</span>
                    </div>
                    {
                        loading ? (<Loader />) :
                            <>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={2000}
                                    hideProgressBar
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss={false}
                                    draggable
                                    pauseOnHover
                                    theme="light"
                                />
                                {filteredProducts?.length > 0 ? (
                                    filteredProducts?.map((val, i) => {
                                        if (val?.status !== 'Published' || val?.visibility !== 'Public') return
                                        return (
                                            <>
                                                {val?.hasVariations === false ? (
                                                    <div key={i} className="otc-smartAccessories-table product-cards">
                                                        <span className="add-new-btn-group" role="button" onClick={() => handleOpenModal(val)}> {val?.name}
                                                        </span>
                                                        <span className="header-text-smart-acc">{val?.prefix}</span>
                                                    </div>
                                                )
                                                    :
                                                    val?.variations?.map((item, i) =>
                                                    (
                                                        <div key={i} className="otc-smartAccessories-table product-cards">
                                                            <span className="add-new-btn-group" role="button" onClick={() => handleOpenModal(val, item)}> {item?.Type}
                                                            </span>
                                                            <span className="header-text-smart-acc">{item?.prefix}</span>
                                                        </div>
                                                    )
                                                    )
                                                }
                                            </>
                                        )
                                    }))
                                    :
                                    (
                                        <div className="mt-4 ms-2">No Smart Accessories Available.</div>
                                    )
                                }
                            </>
                    }
                </div>
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="sec-Modal-section">
                        <div className="sec-modal-header-group">
                            <h2 className="sec-modal-header">
                                Smart Accessories
                            </h2>
                        </div>
                        <input
                            className="w-100 smartAccessories_addnew_modal_input mb-3 mt-3"
                            type="text"
                            placeholder="Accessory"
                            value={selectedAccessory?.variation?.Type || selectedAccessory?.product?.name}
                        />

                        <input
                            className="w-100 smartAccessories_addnew_modal_input"
                            type="text"
                            placeholder="Prefix"
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                        />
                        {selectAccessoryValidationError && (
                            <span className="error-message">{selectAccessoryValidationError}</span>
                        )}
                        <div className="video-Modal-btn-group" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={handleSave}>Save</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default SmartAccessoryPrefix