// Component.js
import React, { useEffect, useState } from "react";
import ClientuserHeadersWithIcons from "./ClientUsersHeaderswithicon";
import ClientUsersInfoPage from "./ClientUserinfo";
import ClientUserinvited from "./ClientUserinvited";
import ClientTeamOverview from "./ClientTeamOverview";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const ClientUser = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("Users");
  const [teamName, setteamName] = useState("");
  const { id } = useParams();
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };

  const navigateToUsersPage = () => {
    setCompanyProfileTab("Users");
  };

  const getTeamNameforfilter = (value) => {
    setteamName(value);
  };

  return (
    <div className="onetap_conn_user" style={{ padding: "0px" }}>
      <ClientuserHeadersWithIcons
        onChange={getTabValue}
        tab={companyProfileTab}
      />
      {companyProfileTab === "Users" && (
        <ClientUsersInfoPage
          onClickSave={getValueOfSaveAndContinueTab}
          teamName={teamName}
        />
      )}
      {companyProfileTab === "Team" && (
        <ClientTeamOverview
          onClickSave={getValueOfSaveAndContinueTab}
          onViewUsersClick={navigateToUsersPage}
          getTeamNameforfilter={getTeamNameforfilter}
        />
      )}
      {companyProfileTab === "Invited" && (
        <ClientUserinvited onClickSave={getValueOfSaveAndContinueTab} />
      )}
    </div>
  );
};

export default ClientUser;
