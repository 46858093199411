import React, { useEffect, useState } from "react";
import "./planSelection.css";
import button1 from "../../../assets/icons/Branding.svg";
import cross from "../../../assets/icons/notSelected.svg";
import selected from "../../../assets/icons/selected.svg";
import button2 from "../../../assets/icons/Branding (1).svg";
import PlanCard from "../../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../../components/PlanCard/PlanCard";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserInformationDetails, getPlan } from "../../../actions/userAction/userAction";

import axios from 'axios'
import Header2 from "../../Layout/Header/Header2";
const CheckBox = ({ select }) => {

  const [isChecked, setIschecked] = useState(select);

  return isChecked ? (
    <>
      <img
        src={selected}
        alt="OTC"
        className="checkBox"
      />
    </>
  ) : (
    <img src={cross} alt="OTC"
      className="checkBox" />
  );
};
const PlanSelection = () => {
  const [isActive, setIsActive] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [plandata, setPlanData] = useState([]);
  const dispatch = useDispatch();
  const { planData } = useSelector((state) => state.planData);
  const [feature, setFeatures] = useState([])

  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const fetchandSetPlans = () => {
    if (planData) {
      setPlanData(planData.plans);
    } else {
      dispatch(getPlan());
    }
  }

  useEffect(() => {
    fetchandSetPlans();
  }, [planData])

  const fetchData = async () => {
    if(feature.length < 1){
      try {
        const result = await axios.get(`${url}/admin/featureData`)
        setFeatures(result.data.feature)
      } catch (error) {
        // handle error
      }
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const renderTableRows = () => {
    if (!feature) {
      return null;
    }

    const featuresArray = Object.values(feature);

    return featuresArray.map((plan) => {
        // Skip plans that are not published or not public
    if (plan.status !== "Published" || plan.visibility !== "Public") {
      return null;
    }
      return(
      <React.Fragment key={plan._id.$oid}>
        <tr style={{ backgroundColor: 'white' }}>
          <td style={{ fontWeight: '700' }}>{plan.fieldName}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {plan.features.map((feature, index) => (
          <tr key={feature._id.$oid} style={{ backgroundColor: index % 2 === 0 ? '#e8e8e9' : 'white' }}>
            <td>{feature.featureName}</td>
            <td>{renderCellValue(feature.free)}</td>
            <td>{renderCellValue(feature.professional)}</td>
            <td>{renderCellValue(feature.team)}</td>
          </tr>
        ))}

      </React.Fragment>
    )}
    );
  };

  const renderCellValue = (valueObject) => {
    if (valueObject.value === 'Comment') {
      return valueObject.comment;
    } else if (valueObject.value === 'Yes') {
      return <CheckBox select={true} />;
    } else if (valueObject.value === 'No') {
      return <CheckBox select={false} />;
    }
    return null;
  };

  return (
    <>
      <Header2 />
      <div className="main-container">
        <h1>Get Started with OneTapConnect</h1>
        <p className="header-text text-center">
          Experience the power of networking like never before! Designed for
          business owners and sales professional
        </p>
        <p className="header-text text-center">
          Choose from below plans
        </p>
        <div className="top-buttons">
          <a href="#package"><button>
            <img src={button2} alt="oneTaoConnect Select plan" />
          </button></a>
          <span>or</span>
          <a target="blank" href="https://onetapconnect.com/contact-sales/"> <button>
            <img src={button1} alt="oneTaoConnect book a demo" />
          </button>
          </a>
        </div>
        <h2 id="package">Plan selection</h2>
        <div className={`sliding-switch ${isActive ? "active" : ""}`}>
          <div className="switch-handle"></div>
          <div className="switch-text">
            <span
              className={`switch-text-option ${isActive ? "active" : ""}`}
              onClick={handleToggle}
            >
              Pay Monthly
            </span>
            <span
              className={`switch-text-option ${!isActive ? "active" : ""}`}
              onClick={handleToggle}
            >
              Yearly and save 20%
            </span>
          </div>
        </div>
        {isActive ? (
          <>
            <div className="plan-selection-section">
              {plandata &&
                plandata.map((plans, i) => {
                  if (plans.isActive === false) {
                    return;
                  }
                  if (plans.type === "monthly") {
                    return;
                  }
                  if (plans.planType === "Free") {
                    return <YearlyCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Professional") {
                    return <YearlyCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Team") {
                    return <YearlyCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Enterprise") {
                    return <YearlyCard props={plans} key={i} cycle={isActive} />;
                  }
                })}
            </div>
          </>
        ) : (
          <>
            <div className="plan-selection-section">
              {plandata &&
                plandata.map((plans, i) => {
                  if (plans.isActive === false) {
                    return;
                  }
                  if (plans.type === "yearly") {
                    return;
                  }
                  if (plans.planType === "Free") {
                    return <PlanCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Professional") {
                    return <PlanCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Team") {
                    return <PlanCard props={plans} key={i} cycle={isActive} />;
                  }
                  if (plans.planType === "Enterprise") {
                    return <PlanCard props={plans} key={i} cycle={isActive} />;
                  }

                })}
            </div>
          </>
        )}
        <h2>Plan Comparision</h2>
        <div className="plan-comparision-section">
          <div className="table-container">
            <table className="scrollable-table table">
              <thead className="plan-selection-table-header">
                <tr>
                  <th style={{ fontSize: "23px" }}>Feature</th>
                  <th>Free</th>
                  <th>Professional</th>
                  <th>Team</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanSelection;
