import React, { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import SmartAccessoriesHeader from "./SmartAccessoriesHeader";
import SmartAccessoriesTab from "./SmartAccessoriesTab";
import ShopSmartAccessoriesTab from "./ShopSmartAccessoriesTab";

const Component = () => {
  const [newstate, Setnewstate] = useState("SmartAccessories");
  const getTabValue = (tabVal) => {
    Setnewstate(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    Setnewstate(value);
  };
  return (
    <>
      <div className="onetap_conn_user">
        <SmartAccessoriesHeader onChange={getTabValue} tab={newstate} />
        {newstate === "SmartAccessories" && (<SmartAccessoriesTab onClickSave={getValueOfSaveAndContinueTab} />)}
        {newstate === "SmartAccessoriesTT" && (<ShopSmartAccessoriesTab onClickSave={getValueOfSaveAndContinueTab} />)}
      </div>
    </>
  );
};
const SmartAccessories = () => {
  return <Sidebar Component={Component} />;
};
export default SmartAccessories;
