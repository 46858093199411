import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Checkout from "./components/Customers/Checkout/Checkout";
import CustomerLogin from "./components/Customers/Login/Login";
import store from "./store";
import {
  loadUser,
  getCompanyProfileDetails,
  fetchUserInformationDetails,
  checkUserSubscription,
} from "./actions/userAction/userAction";
import { useSelector } from "react-redux";
import ForgotPassword from "./components/Customers/Forgot/ForgotPassword";
import ResetPassword from "./components/Customers/Reset/ResetPassword";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import './Pages/OneTap/OTC_TeamMembers/membersdropdown.css'
import GoogleAuth from "./components/Customers/Google/GoogleAuth";
import SignUp from "./components/Customers/SignUp/SignUp";
import SignupPassword from "./components/Customers/SignUp/SignUpPassword";
import Billing from "./components/Customers/Billing/Billing";
import PlanSelection from "./components/Customers/Planselection/PlanSelection";
import PrivacyPolicy from "./components/Customers/TnC/Privacypolicy";
import Termsofuse from "./components/Customers/TnC/Termsofuse";
import RejectInvitation from "./Pages/Rejectinvitation/RejectInvitation";
import Addusermanually from "./components/Customers/Addusermanually/Addusermanually";
import ShopSmartAccessories from "./Pages/company/ShopSmartAccessories/ShopSmartAccessories";
import ProductPage from "./Pages/company/ShopSmartAccessories/ProductPage";
import GuestCheckout from "./components/Customers/Guest_Checkout/Checkout";
import Cart from "./Pages/company/ShopSmartAccessories/Cart";
import XmlDisplay from './shipstation'
import {
  superadminFreeRoute,
  superadminTeamRoute,
  superadminProfessionalRoute,
  managerTeamRoutes,
  administratorTeamRoutes,
  teammemberFreeRoutes,
} from "../src/Routes/CheckAuthorization";
import Refundpolicy from "./components/Customers/TnC/Refundpolicy";
import Otc_comigsoon, {
  Otc_comigsoonforaddons,
} from "./OTC-AdminPanel/OTC_ComingSoon/Otc_ComingSoon";
import ThankyouPage from "./Pages/company/ShopSmartAccessories/ThankyouPage";
import Order_Dashboard from "./OTC-AdminPanel/Orders/Otc_Orders";
import Client from "../src/OTC-AdminPanel/Client/Client";
import Otc_login from "./OTC-AdminPanel/Otc_login/Otc_login";
import { loadAdminUser } from "./OTC-AdminPanel/Redux/Otc_UserActions";
import ProductAndServices from "./OTC-AdminPanel/ProductAndServices/ProductAndServices";
import ViewCardComingsoon from "./components/ComingSoon/ViewCardComingSoon";
import Otc_User from "./OTC-AdminPanel/Otc_Users/Otc_User";
import Clientprofile from "./OTC-AdminPanel/Clientprofile/Clientprofile";
import Otc_Addons from "./OTC-AdminPanel/Otc_Addons/Otc_Addons";
import CategoriesMain from "./OTC-AdminPanel/ProductAndServices/Categories/CategoriesMain";
import PlanMain from "./OTC-AdminPanel/ProductAndServices/Plan/PlanMain";
import CouponMain from "./OTC-AdminPanel/ProductAndServices/Coupons/CouponMain";
import AddonsComingSoon from "./components/ComingSoon/AddonsComingSoon";
import MyAccount from "./components/Customers/MyAccount/MyAccountMain";
import UserProfileMain from "./OTC-AdminPanel/Otc_User_Profile/UserProfileMain";
import OTCSubscriptions from "./OTC-AdminPanel/OTCSubscriptions/OTCSubscriptions";
import SubscriptionMain from "./OTC-AdminPanel/Otc_SubscriptionDetails/SubscriptionMain";
import AdminAddusermanually from "./OTC-AdminPanel/Clientprofile/ClientUsers/AdminAddusermanually/AdminAddusermanually";
import CreateOrderMain from "./OTC-AdminPanel/Orders/Otc_CreateOrderMain";
import OrderDetailsMain from "./OTC-AdminPanel/Orders/Otc_OrderDetails/OrderDetailsMain";
import ClientCompanyOrderSummary from "./OTC-AdminPanel/Clientprofile/ClientCompanyBilling/ClientCompanyOrderSummary";
import Otc_Adminusers from "./OTC-AdminPanel/OTC_Adminusers/Otc_Adminusers";
import OTC_admincreate_edit_teammember from "./OTC-AdminPanel/OTC_Addnewadmin/OTC_Addnewadminmain";
import OTC_adminusers_edit_profile from "./OTC-AdminPanel/OTC_Editadmin/OTC_Editadminmain";
import MyAccountMain from "./OTC-AdminPanel/MyAccount/AdminMyAccountMain";
import SmartAccessoryMain from "./OTC-AdminPanel/OTC_SmartAccessory/SmartAccessoryMain";
import OtcGuestUsersMain from "./OTC-AdminPanel/Otc_Guest_Users/OtcGuestUsersMain";
import OtcForgotPassword from "./OTC-AdminPanel/Otc_ForgotPassword/OtcForgotPassword";
import OtcResetPassword from "./OTC-AdminPanel/Otc_ResetPassword/OtcResetPassword";
import AccountSelection from "./components/Customers/AccountSelection/AccountSelection";
import RemoveAccount from "./components/Customers/AccountSelection/RemoveAccount.jsx";
import Templates from "./components/Customers/Templates/Templates.jsx";
import Otc_Templates from "./OTC-AdminPanel/Otc_Templates/Templates.jsx";

function App() {
  const { isOtcAdminAuthenticated } = useSelector(
    (state) => state.OtcAdminuser
  );
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.userInformation);
  const userPlan = userInfo && userInfo?.subscription_details?.plan;
  const userRole = user && user.role;

  const frontendURL = window.location.origin;

  const [stripeApiKey, setStripeApiKey] = useState(
    process.env.REACT_APP_STRIPE_API_KEY
  );

  function checkRoleandPlan(userRole, userPlan, allowedRoles, allowedPlans) {
    return allowedRoles.includes(userRole) && allowedPlans.includes(userPlan);
  }
  function checkRole(userRole, allowedRoles) {
    return allowedRoles.includes(userRole);
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenValue = urlParams.get("token");
    if (window.location.href.includes(`${frontendURL}/login`) && tokenValue) {
      return null;
    } else if (window.location.href === `${frontendURL}/login`) {
      return null;
    } else {
      store.dispatch(loadUser());
      store.dispatch(getCompanyProfileDetails());
      store.dispatch(fetchUserInformationDetails());
      store.dispatch(checkUserSubscription());
      store.dispatch(loadAdminUser());
    }
  }, []);


  const openRoutes = [
    { path: "/templates", element: <Templates /> },
    { path: "/account-selection", element: <AccountSelection /> },
    { path: "/remove-account", element: <RemoveAccount /> },
    { path: "/create-password/:token", element: <SignupPassword /> },
    { path: "/my-account", element: <MyAccount /> },
    { path: "/", element: <PlanSelection /> },
    { path: "/:slug", element: <ViewCardComingsoon /> },
    { path: "/addons/:slug", element: <AddonsComingSoon /> },
    { path: "/:companyname/:slug", element: <ViewCardComingsoon /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/sign-up/:token", element: <SignUp /> },
    { path: "/register/:token", element: <SignupPassword /> },
    { path: "/login", element: <CustomerLogin /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password/:token", element: <ResetPassword /> },
    { path: "/google-auth", element: <GoogleAuth /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/refund-policy", element: <Refundpolicy /> },
    { path: "/terms-of-use", element: <Termsofuse /> },
    { path: "/users/add-manual-user", element: <Addusermanually /> },
    {
      path: "/email-invitations/:invitationToken",
      element: <RejectInvitation />,
    },
    { path: "/shop", element: <ShopSmartAccessories /> },
    { path: "/shop/product/:name", element: <ProductPage /> },
    { path: "/shop/:name", element: <ProductPage /> },
    { path: "/cart", element: <Cart /> },
    { path: "/payment-success", element: <ThankyouPage /> },
    { path: "/admin", element: <Otc_login /> },
    { path: "/admin/forgot-password", element: <OtcForgotPassword /> },
    { path: "/admin/reset-password/:token", element: <OtcResetPassword /> },
    { path: '/shipstation', element: <XmlDisplay /> },
  ];
  const otcadminroute = [
    { path: "/admin/clients", element: <Client /> },
    { path: "/admin/client/profile/:id", element: <Clientprofile /> },
    { path: "/admin/user/profile/:id", element: <UserProfileMain /> },
    { path: "/admin/dashboard", element: <Otc_comigsoon /> },
    { path: "/admin/orders", element: <Order_Dashboard /> },
    { path: "/admin/order/details/:id", element: <OrderDetailsMain /> },
    { path: "/admin/users", element: <Otc_User /> },
    { path: "/admin/guest-users", element: <OtcGuestUsersMain /> },
    { path: "/admin/subscriptions", element: <OTCSubscriptions /> },
    { path: "/admin/otc-smart-accessories", element: <SmartAccessoryMain /> },
    { path: "/admin/analytics", element: <Otc_comigsoon /> },
    { path: "/admin/onetapconnect-information", element: <Otc_comigsoonforaddons /> },
    { path: "/admin/templates", element: <Otc_Templates /> },
    { path: "/admin/add-manual-user/:id", element: <AdminAddusermanually /> },
    { path: "/admin/addons", element: <Otc_Addons /> },
    { path: "/admin/plans", element: <PlanMain /> },
    { path: "/admin/Coupons", element: <CouponMain /> },
    { path: "/admin/smart-accessories", element: <ProductAndServices /> },
    { path: "/admin/categories", element: <CategoriesMain /> },
    { path: "/admin/settings", element: <Otc_comigsoon /> },
    { path: "/admin/academy", element: <Otc_comigsoon /> },
    { path: "/admin/tutorials", element: <Otc_comigsoon /> },
    { path: "/admin/billing", element: <Otc_comigsoon /> },
    { path: "/admin/subscriptiondetails/:id", element: <SubscriptionMain /> },
    { path: "/admin/account", element: <Otc_comigsoon /> },
    { path: "/admin/*", element: <Otc_login /> },
    { path: "/admin/otc-users", element: <Otc_Adminusers /> },
    {
      path: "/admin/add-otcuser",
      element: <OTC_admincreate_edit_teammember />,
    },
    {
      path: "/admin/otc-user-edit/:id",
      element: <OTC_adminusers_edit_profile />,
    },
    { path: "/admin/clientordersummary/:orderNumber", element: <ClientCompanyOrderSummary /> },
    { path: "/admin/my-account", element: <MyAccountMain /> }
  ];
  if (stripeApiKey) {
    openRoutes.push(
      {
        exact: true,
        path: "/checkout",
        element: (
          <Elements stripe={loadStripe(stripeApiKey)}>
            <Checkout />
          </Elements>
        ),
      },
      {
        path: "/guest-checkout",
        element: (
          <Elements stripe={loadStripe(stripeApiKey)}>
            <GuestCheckout />
          </Elements>
        ),
      },
      {
        path: "/admin/orders/create-order/:id",
        element: (
          <Elements stripe={loadStripe(stripeApiKey)}>
            <CreateOrderMain />
          </Elements>
        ),
      },
      {
        path: "/admin/client/profile/:id",
        element: (
          <Elements stripe={loadStripe(stripeApiKey)}>
            <Clientprofile />
          </Elements>
        ),
      }
    );
  }
  if (userRole === "superadmin" || userRole === "administrator" || userRole === 'teammember') {
    if (stripeApiKey) {
      openRoutes.push({
        path: "/billing",
        element: (
          <Elements stripe={loadStripe(stripeApiKey)}>
            <Billing />
          </Elements>
        ),
      });
    }
  }

  return (
    <Router>
      <Routes>
        {openRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {isOtcAdminAuthenticated ? (
          otcadminroute.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))
        ) : (
          <Route path="/admin/*" element={<Otc_login />} />
        )}
        {isAuthenticated ? (
          <>
            {checkRoleandPlan(userRole, userPlan, ["superadmin"], ["Free"]) && user?.Account_status !== "is_Deactivated" &&
              superadminFreeRoute.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}
            {checkRoleandPlan(userRole, userPlan, ["superadmin"], ["Team"]) && user?.Account_status !== "is_Deactivated" &&
              superadminTeamRoute.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}
            {checkRoleandPlan(
              userRole,
              userPlan,
              ["superadmin"],
              ["Professional"]
            ) && user?.Account_status !== "is_Deactivated" &&
              superadminProfessionalRoute.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}
            {checkRole(userRole, ["administrator"]) &&
              administratorTeamRoutes.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}

            {checkRole(userRole, ["manager"]) &&
              managerTeamRoutes.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}
            {checkRole(userRole, ["teammember"]) &&
              teammemberFreeRoutes.map((route) =>
                route?.path === "/*" ? (
                  <Route path="/*" element={<Navigate to="/dashboard" />} />
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                )
              )}
          </>
        ) : null}
      </Routes>
    </Router>
  );
}

export default App;
