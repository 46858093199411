import React, { useEffect, useState } from "react";

const SmartAccessoryHeaderWithIcons = (props) => {
    const [SmartAccessoriesTab, setSmartAccessoriesTab] = useState("General");
    const { tab } = props;
    useEffect(() => {
        setSmartAccessoriesTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setSmartAccessoriesTab(value);
        props.onChange(value);
    };

    return (
        <>

            <div className="onetap_conn_user_header_main">
                <div className="onetap_conn_user_header_icon">
                    <div
                        onClick={() => handleClickOfTab("SmartAccessories")}
                        className={
                            SmartAccessoriesTab === "SmartAccessories"
                                ? "onetap_conn_user_header_icon_and_header_active"
                                : "onetap_conn_user_header_icon_and_header_deactive"
                        }
                    >
                        <div
                            style={{
                                backgroundColor: SmartAccessoriesTab === "SmartAccessories" ? "#E65925" : "",
                                width: "34px",
                                height: "32px",
                                borderRadius: "7px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 15V17C1.15661 17 1.80679 17.1293 2.41342 17.3806C3.02005 17.6319 3.57124 18.0002 4.03553 18.4645C4.49983 18.9288 4.86812 19.48 5.1194 20.0866C5.37067 20.6932 5.5 21.3434 5.5 22H7.5C7.5 20.1435 6.7625 18.363 5.44975 17.0503C4.13699 15.7375 2.35652 15 0.5 15ZM0.5 19V22H3.5C3.5 21.2044 3.18393 20.4413 2.62132 19.8787C2.05871 19.3161 1.29565 19 0.5 19ZM0.5 11V13C2.88695 13 5.17613 13.9482 6.86396 15.636C8.55179 17.3239 9.5 19.6131 9.5 22H11.5C11.5 15.92 6.58 11 0.5 11ZM15.5 0H5.5C4.96957 0 4.46086 0.210714 4.08579 0.585786C3.71071 0.960859 3.5 1.46957 3.5 2V9.37C4.19 9.53 4.86 9.74 5.5 10V4H15.5V17H12.47C13 18.25 13.31 19.59 13.42 21H15.5C16.0304 21 16.5391 20.7893 16.9142 20.4142C17.2893 20.0391 17.5 19.5304 17.5 19V2C17.5 1.46957 17.2893 0.960859 16.9142 0.585786C16.5391 0.210714 16.0304 0 15.5 0Z" fill={SmartAccessoriesTab === "SmartAccessories" ? "#ffff" : "#000000"} />
                            </svg>


                        </div>
                        <p
                            style={{ cursor: "pointer" }}
                            className={
                                SmartAccessoriesTab === "SmartAccessories" ? "onetap_conn_user_active_header" : "onetap_conn_user_deactive_header"
                            }
                        > Smart Accessories
                        </p>
                    </div>


                    <div
                        onClick={() => handleClickOfTab("Smart Accessories Prefix")}
                        className={
                            SmartAccessoriesTab === "Smart Accessories Prefix"
                                ? "onetap_conn_user_header_icon_and_header_active"
                                : "onetap_conn_user_header_icon_and_header_deactive"
                        }
                    >
                        <div
                            style={{
                                backgroundColor: SmartAccessoriesTab === "Smart Accessories Prefix" ? "#E65925" : "",
                                width: "34px",
                                height: "32px",
                                borderRadius: "7px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 15V17C1.15661 17 1.80679 17.1293 2.41342 17.3806C3.02005 17.6319 3.57124 18.0002 4.03553 18.4645C4.49983 18.9288 4.86812 19.48 5.1194 20.0866C5.37067 20.6932 5.5 21.3434 5.5 22H7.5C7.5 20.1435 6.7625 18.363 5.44975 17.0503C4.13699 15.7375 2.35652 15 0.5 15ZM0.5 19V22H3.5C3.5 21.2044 3.18393 20.4413 2.62132 19.8787C2.05871 19.3161 1.29565 19 0.5 19ZM0.5 11V13C2.88695 13 5.17613 13.9482 6.86396 15.636C8.55179 17.3239 9.5 19.6131 9.5 22H11.5C11.5 15.92 6.58 11 0.5 11ZM15.5 0H5.5C4.96957 0 4.46086 0.210714 4.08579 0.585786C3.71071 0.960859 3.5 1.46957 3.5 2V9.37C4.19 9.53 4.86 9.74 5.5 10V4H15.5V17H12.47C13 18.25 13.31 19.59 13.42 21H15.5C16.0304 21 16.5391 20.7893 16.9142 20.4142C17.2893 20.0391 17.5 19.5304 17.5 19V2C17.5 1.46957 17.2893 0.960859 16.9142 0.585786C16.5391 0.210714 16.0304 0 15.5 0Z" fill={SmartAccessoriesTab === "Smart Accessories Prefix" ? "#ffff" : "#000000"} />
                            </svg>


                        </div>
                        <p
                            style={{ cursor: "pointer" }}
                            className={
                                SmartAccessoriesTab === "Smart Accessories Prefix" ? "onetap_conn_user_active_header" : "onetap_conn_user_deactive_header"
                            }
                        > Smart Accessories Prefix
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SmartAccessoryHeaderWithIcons;
