import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Otc_Sidebar.css'
import OTC_Routes from '../../Routes/Otc_Routes';
import logo from '../../Otc_assets/OTC_ICONS/logo.svg';
import { AppBar, Box, Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import logoutIcon from '../../Otc_assets/OTC_ICONS/OTC_LOGOUTICON.svg'
import Otc_Header from '../Header/Otc_Header';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdminUser } from '../../Redux/Otc_UserActions';
const Otc_Sidebar = (props) => {
  const { window, Component } = props;
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const { adminuser } = useSelector((state) => state.OtcAdminuser);
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activePath, setActivePath] = React.useState("");
  const routes = OTC_Routes();
  const drawerWidth = 300;

  const isActivePath = (paths) => {
    return paths.some(path => {
      if (path.endsWith('/:id')) {
        return location.pathname.startsWith(path.replace('/:id', ''));
      } else {
        return location.pathname === path;
      }
    }) || paths.some(path => isActiveChildPath(path));
  };

  const isActiveChildPath = (children) => {
    if (Array.isArray(children)) {
      return children.some(child => isActivePath(child.path));
    }
    return false;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = async () => {
    if (adminuser) {
      dispatch(logoutAdminUser())
    } 
  };
  const [productsServicesOpen, setProductsServicesOpen] = useState(props.productsServicesOpen);
  const [productsServicesOpen2, setProductsServicesOpen2] = useState(props.productsServicesOpen2);
  const rotationStyle = productsServicesOpen ? { transform: 'rotate(180deg)' } : {};
  const rotationStyle2 = productsServicesOpen2 ? { transform: 'rotate(180deg)' } : {};

  const renderSidebarItems = (items) => {

    return items.map((item, index) => (
      <>
        <ListItem
          key={index}
          disablePadding
          component={item.disabled ? "div" : Link}
          to={item.path[0]}
          className={`otc-list-items ${isActivePath(item.path) || isActiveChildPath(item.children) || isActiveChildPath(item.childern2) ? "otc-active-link" : ""} ${item.disabled ? "disabled-route" : ""} `}
          style={item.disabled ? {} : {}}
          onClick={() => {
            if (item.children) {
              setProductsServicesOpen(!productsServicesOpen);
            }
            else if (item.childern2) {
              setProductsServicesOpen2(!productsServicesOpen2);
            }
            else if (item.child) {
              setProductsServicesOpen(true)
            } else if (item.child2) {

              setProductsServicesOpen2(true)
            } else {
              setProductsServicesOpen(false)
              setProductsServicesOpen2(false)
            }

          }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <img
              src={item.icon}
              alt="icon"
              width={25}
              height={25}
              className={isActivePath(item.path) || isActiveChildPath(item.children) || isActiveChildPath(item.childern2) ? "otc-active-icon" : ""}
            />
          </ListItemIcon>
          <ListItemText style={item.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}} primary={item.title} />
          {item.dropdownicon && <ListItemIcon style={{ minWidth: '40px', paddingLeft: '10px' }}>
            <img
              src={item.dropdownicon ? item.dropdownicon : null}
              alt="icon"
              width={25}
              height={25}
              style={rotationStyle}
              className={isActivePath(item.path) || isActiveChildPath(item.children) || isActiveChildPath(item.childern2) ? "otc-active-icon" : ""}
            />
          </ListItemIcon>}
          {item.dropdownicon2 && <ListItemIcon style={{ minWidth: '40px', paddingLeft: '10px' }}>
            <img
              src={item.dropdownicon2 ? item.dropdownicon2 : null}
              alt="icon"
              width={25}
              height={25}
              style={rotationStyle2}
              className={isActivePath(item.path) || isActiveChildPath(item.children) || isActiveChildPath(item.childern2) ? "otc-active-icon" : ""}
            />
          </ListItemIcon>}
        </ListItem >
        {
          item.children && item.children.length > 0 ? (
            <Collapse in={productsServicesOpen} className='otc-collapse'>
              {renderSidebarItems(item.children)}
            </Collapse>
          ) : item.childern2 && item.childern2.length > 0 && (
            <Collapse in={productsServicesOpen2} className='otc-collapse'>
              {renderSidebarItems(item.childern2)}
            </Collapse>
          )
        }
      </>
    ));
  };

  const drawer = (
    <div>
      <div className="otc-company-icon">
        <img src={logo} alt="Logo" />
      </div>
      <div className="otc-sidebar-list-container">
        <span className="otc-company-title">OneTapConnect</span>
        {renderSidebarItems(routes.filter(item => item.group === ""))}

        {routes.filter(item => item.group === "Settings").length === 0 ? null : (
          <span className="otc-sidebarinfo-title" style={{ color: '#000', fontWeight: '300' }}>Settings</span>
        )}
        {renderSidebarItems(routes.filter(item => item.group === "Settings"))}

        {routes.filter(item => item.group === "billing").length === 0 ? null : (
          <span className="otc-sidebarinfo-title" style={{ color: '#000', fontWeight: '300' }}>Account & Billing</span>
        )}
        {renderSidebarItems(routes.filter(item => item.group === "billing"))}
      </div>
      <List className="otc-sidebar-bottom-list-container" style={{ marginTop: 'auto' }}>
        <ListItem
          component={Link}
          className="otc-list-items"
          onClick={logoutHandler}
          style={{ padding: '0px 10px' }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <img src={logoutIcon} alt="icon" width={25} height={25} />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );
  React.useEffect(() => {
    const path = location.pathname;
    setActivePath(path);
  }, [setActivePath, location]);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
      <Box sx={{ display: "flex" }} >
        <AppBar
          position="fixed"
          sx={{
            boxShadow: 'none',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Otc_Header handleDrawerToggle={handleDrawerToggle} />
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, 
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                [`@media (max-width: 280px)`]: {
                  width: 200,
                },
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer variant="permanent"
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                [`@media (max-width: 280px)`]: {
                  width: 200,
                },
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar style={{ minHeight: "27px !important", boxShadow: "none" }} />
          <Component />
        </Box>
      </Box>
    </div>
  )
}

export default Otc_Sidebar