
import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import UserProfileHeaderWithIcon from "./UserProfileHeaderWithIcon";
import UserProfileGeneral from "./UserProfileGeneral";
import UserProfileAbout from "./UserProfileAbout";
import UserProfileSetting from "./UserProfileSetting";


const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("General");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user" style={{ padding: '14px 0px' }}>
            <UserProfileHeaderWithIcon onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "General" && (
                <UserProfileGeneral
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
            {companyProfileTab === "About" && (
                <UserProfileAbout
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
            {companyProfileTab === "Setting" && (
                <UserProfileSetting
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}

        </div>
    );
};


export default function UserProfileMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} />
        </>
    )
}
