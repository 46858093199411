import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};


const ErrorModal = ({ errorMessage, onClose }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
      onClose(); // Call onClose to handle any additional logic in the parent component
    }, 4000);
    return () => clearTimeout(timer); // Clear the timeout on component unmount
  }, []);

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };


  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" class="circle">
                  <path d="M40 1.5C62.0914 1.5 79.5 18.9086 79.5 41C79.5 63.0914 62.0914 80.5 40 80.5C17.9086 80.5 0.5 63.0914 0.5 41C0.5 18.9086 17.9086 1.5 40 1.5Z" fill="#E65925" />
                  <path d="M27.5 27.5L52.5 52.5" stroke="white" stroke-width="6.64" stroke-linecap="round" class="cancel-path" />
                  <path d="M52.5 27.5L27.5 52.5" stroke="white" stroke-width="6.64" stroke-linecap="round" class="cancel-path" />
                </svg>

              </div>
            </div>
            <span className="team_created_success">{errorMessage}</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
            onClick={() => closeModal(false)}
          >
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
          </button>
        </Box>
      </Modal>

    </div>
  )
}

export default ErrorModal
