import React from "react";
import "./pagenotfound.css";
import { Link } from "react-router-dom";
import Header2 from "../Layout/Header/Header2";
const PageNotFound = () => {
  return (
    <>
      <div className="pagenotfoundmain" style={{ backgroundColor: "#f2f2f2" }}>
        <Header2 />
        <div className="pagenotfound_main_h container">
          <h1 className="pagenotfound_h1">
            Oops! Page Not Found </h1>
          <div className="container" style={{ width: '60%', display: "flex", justifyContent: 'center', textAlign: 'center' }}>
            <p className="pagenotfound_p">We couldn't locate that page, sorry about that! Please check the URL and try again, or you can head back to our <Link to='/login' style={{ textDecoration: 'none', color: '#e65925' }}>homepage.</Link>
              <br /> <br />
            </p>
          </div>
          <div className="container" style={{ width: '60%', display: "flex", justifyContent: 'center', textAlign: 'center' }}>
            <Link to='/login' style={{ textDecoration: 'none', color: 'white' }}> <button style={{ backgroundColor: '#e65925', color: 'white', border: '0px' }}>Back to Homepage</button></Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
