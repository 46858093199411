import React, { useEffect, useState } from 'react'
import './ClientorderSummary.css'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import {
    getProducts, loadUser,
} from "../../../actions/userAction/userAction";
import { getSingleOrder } from '../../../OTC-AdminPanel/Redux/Otc_UserActions';
import ProductIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/ProductIcon.svg";
import AddonIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_addons_icon.svg";
import UserIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_users_icon.svg";
import PlanIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_plan_icon.svg";
import VisaCard from "../../../assets/images/Card_image.png"
import { State, Country } from "country-state-city";
import Loader from '../../Layout/Loader/Loader';
function ClinetOrderSummaryTab(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { orderNumber } = useParams();
    const { products } = useSelector((state) => state.products);
    const { user } = useSelector((state) => state.user);
    const [shippingAddress, setShippingAddress] = useState({});
    const [billingAddress, setBillingAddress] = useState({});
    const [planArray, setPlanArray] = useState([]);
    const [addons, setAddons] = useState([]);
    const [smartAccessories, setSmartAccessories] = useState([]);
    const [orderData, setOrderData] = useState("");
    const { order } = useSelector((state) => state.order);
    const [addonPurchase, setAddonPurchase] = useState([]);
    const [shippingMethod, setShippingMethod] = useState();
    const [userPurchase, setUserPurchase] = useState([]);
    const [card_details, setCard_Details] = useState({})
    const [orderNote, setOrderNote] = useState('');

    useEffect(() => {
        if (!user) {
            dispatch(loadUser());
        }
    }, []);

    const checkUserloggedin = () => {
        console.log(user)
        if (!user) {
            navigate("/login");
        }
    }
    useEffect(() => {
            setTimeout(() => {
                checkUserloggedin()
            }, 5000);
    }, [user]);
    // console.log('user23', user)
    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [products, dispatch])
    useEffect(() => {
        dispatch(getSingleOrder(orderNumber));
    }, [orderNumber]);
    // Function to filter products by productId
    useEffect(() => {
        if (order) {
            setOrderData(order);
        }
    }, [order]);
    useEffect(() => {
        if (orderData) {
            setAddons(orderData?.subscription_details?.addones);
            setSmartAccessories(orderData?.smartAccessories);
            setShippingAddress(orderData?.shippingAddress && orderData?.shippingAddress[0]);
            setBillingAddress(orderData?.billingAddress && orderData?.billingAddress[0]);
            setAddonPurchase(orderData?.addaddons);
            setShippingMethod(orderData?.totalShipping);
            setUserPurchase(orderData?.addusers);
            setCard_Details(orderData?.card_details)
            setOrderNote(orderData?.userShippingOrderNote);
            if (
                orderData.subscription_details &&
                typeof orderData.subscription_details === "object"
            ) {
                // Extract properties from subscription_details and create an array with a single object
                const subscriptionDetailsArray = [
                    Object.entries(orderData.subscription_details)
                        .filter(([key]) => key !== "addones")
                        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
                ];
                setPlanArray(subscriptionDetailsArray);
            } else {

            }
        }
    }, [orderData]);
    return (
        <div>
            {orderData ? (
                <div className='onetap_conn_user'>
                    <div className='d-flex justify-content-lg-between'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="34" height="34" rx="9" fill="#E65925" />
                                <path
                                    d="M17 15L15.6 13.6L17.175 12H13V10H17.175L15.575 8.4L17 7L21 11L17 15ZM12 27C11.45 27 10.979 26.804 10.587 26.412C10.195 26.02 9.99933 25.5493 10 25C10 24.45 10.196 23.979 10.588 23.587C10.98 23.195 11.4507 22.9993 12 23C12.55 23 13.021 23.196 13.413 23.588C13.805 23.98 14.0007 24.4507 14 25C14 25.55 13.804 26.021 13.412 26.413C13.02 26.805 12.5493 27.0007 12 27ZM22 27C21.45 27 20.979 26.804 20.587 26.412C20.195 26.02 19.9993 25.5493 20 25C20 24.45 20.196 23.979 20.588 23.587C20.98 23.195 21.4507 22.9993 22 23C22.55 23 23.021 23.196 23.413 23.588C23.805 23.98 24.0007 24.4507 24 25C24 25.55 23.804 26.021 23.412 26.413C23.02 26.805 22.5493 27.0007 22 27ZM6 9V7H9.275L13.525 16H20.525L24.425 9H26.7L22.3 16.95C22.1167 17.2833 21.8707 17.5417 21.562 17.725C21.2533 17.9083 20.916 18 20.55 18H13.1L12 20H24V22H12C11.25 22 10.679 21.675 10.287 21.025C9.895 20.375 9.88267 19.7167 10.25 19.05L11.6 16.6L8 9H6Z"
                                    fill="white"
                                />
                            </svg>
                            <div className='ms-2' style={{ color: '#E65925', fontWeight: 'bold' }}>Order #{orderData?.orderNumber}</div>
                        </div>
                        <div>
                            <div style={{ color: '#E65925', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/billing', { state: { moveToTransaction: true } }) }}>
                                Go Back
                            </div>
                        </div>
                    </div >
                    <div className="d-flex row">
                        <div className=" mt-2 col-6 ">
                            <div
                                className="row left-side-section"
                                style={{ marginRight: 0, marginLeft: 0 }}
                            >
                                <div className="info-and-btn-section" style={{ padding: '0px' }}>
                                    { }
                                    <h4 className="left-info-h4">Shipping address</h4>
                                </div>
                                <div className="left-textArea">
                                    <span className="left-span">
                                        <p style={{ marginBottom: "0" }}>{shippingAddress?.first_name} {shippingAddress?.last_name}</p>
                                        <p style={{ marginBottom: "0" }}>{shippingAddress?.company_name} </p>
                                        <p style={{ marginBottom: "0" }}>{shippingAddress?.line1} {shippingAddress?.line2}</p>
                                        <p style={{ marginBottom: "0" }}>{shippingAddress?.city},&nbsp;
                                            {State?.getStateByCodeAndCountry(shippingAddress?.state, shippingAddress?.country)?.name},&nbsp;
                                            {shippingAddress?.postal_code} </p>
                                        <p style={{ marginBottom: "0" }}>   {Country?.getCountryByCode(
                                            shippingAddress?.country
                                        )?.name}</p>
                                    </span>
                                </div>
                            </div>
                            <div
                                className="row left-side-section mt-2"
                                style={{ marginRight: 0, marginLeft: 0 }}
                            >
                                <div className="info-and-btn-section" style={{ padding: '0px' }}>
                                    <h4 className="left-info-h4">Billing address</h4>
                                </div>
                                <div className="left-textArea">
                                    <span className="left-span">
                                        <p style={{ marginBottom: "0" }}>
                                            {billingAddress?.first_name} {billingAddress?.last_name}
                                        </p>
                                        <p style={{ marginBottom: "0" }}>
                                            {billingAddress?.company_name}{" "}
                                        </p>
                                        <p style={{ marginBottom: "0" }}>
                                            {billingAddress?.line1} {billingAddress?.line2}
                                        </p>
                                        <p style={{ marginBottom: "0" }}>
                                            {billingAddress?.city},&nbsp;&nbsp;
                                            {billingAddress?.postal_code}{" "}
                                        </p>
                                        <p style={{ marginBottom: "0" }}>{billingAddress?.country}</p>
                                    </span>
                                </div>
                            </div>
                            <div className="bg-white mt-2" style={{ borderRadius: "8px", padding: '30px' }}>
                                <h5>Order Note</h5>
                                <hr />

                                <textarea readOnly className="w-100 border-0 p-2 mt-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='activitylog' value={orderNote}
                                    onChange={(e) => { setOrderNote(e.target.value) }}
                                />
                            </div>
                            {card_details && (
                                <div className="bg-white mt-2" style={{ borderRadius: "8px", padding: '30px' }}>
                                    <h5>Payment Method</h5>
                                    <hr />
                                    <div
                                        style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
                                        className="order-ful-header-component"
                                    >
                                        <span className="pc-category-title-text justify-content-start">
                                            Method
                                        </span>
                                        <span className="pc-category-title-text ">Status</span>
                                        <span className="pc-category-title-text ">Amount </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr 1fr",
                                            backgroundColor: "rgb(242, 242, 242)",
                                            height: "50px",
                                            marginTop: "10px",
                                        }}
                                        className="order-ful-header-component"
                                    >
                                        <span className="pc-category-title-text justify-content-start">
                                            <div className="card-img-checkout">
                                                <img src={VisaCard} alt="" />
                                                <span style={{ marginLeft: '8px' }}>****{card_details?.cardNumber}</span>
                                            </div>
                                        </span>
                                        <span className="pc-category-title-text "> {card_details ? 'Paid' : null}</span>
                                        {orderData?.type === "Subscription" &&
                                            planArray &&
                                            planArray?.map((e) => {
                                                let totalproductprice = 0;
                                                smartAccessories?.forEach((pe, index) => {
                                                    const discountedPrice = (pe?.discountAmount || 0);
                                                    const total = pe.quantity * (pe.price - discountedPrice);
                                                    totalproductprice += total;
                                                });
                                                const subtotal = e?.total_amount;
                                                const taxammount = ((totalproductprice * (e?.taxRate || 0)) / 100).toFixed(2);
                                                return (
                                                    <span className="pc-category-title-text ">${subtotal + parseFloat(shippingMethod, 10) || 0 + parseFloat(taxammount, 10)}</span>
                                                );
                                            })}
                                        {orderData?.type !== "Subscription" && (
                                            <span className="pc-category-title-text ">
                                                $
                                                {orderData?.totalAmount +
                                                    parseFloat(
                                                        (orderData?.tax).toFixed(
                                                            2
                                                        ),
                                                        10
                                                    )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mt-2 col-6">
                            <div className="bg-white p-4 " style={{ borderRadius: "8px" }}>
                                <div className="smartAcc-text-order">Order Details </div>
                                <div
                                    style={{
                                        marginTop: "30px", padding: '0px', paddingRight: '0px'
                                    }}
                                    className="order-ful-header-component"
                                >
                                    <span className="">Items</span>
                                    <span className="pc-category-title-text ">Quantity</span>
                                    <span className="pc-category-title-text ">Unite price</span>
                                    <span className="pc-category-title-text ">Discount</span>
                                    <span className="pc-category-title-text justify-content-end">
                                        Total
                                    </span>
                                </div>
                                {orderData.type === "Subscription" &&
                                    planArray &&
                                    planArray?.map((e, index) => {
                                        const discountedPrice = e?.perUserDiscountPrice || 0;
                                        const InitialSetupFee = e?.InitialSetupFee || 0;
                                        const quantity = e?.total_user[0]?.baseUser || 1;
                                        const perUser_price =
                                            e?.billing_cycle === "yearly"
                                                ? e?.perUser_price * 12
                                                : e?.perUser_price;
                                        const total =
                                            InitialSetupFee + (quantity * (perUser_price - discountedPrice));
                                        return (
                                            <div key={index} className="main-paln-product-card">
                                                <div
                                                    style={{ marginRight: "0", paddingRight: "20px" }}
                                                    className="order-Product-cards"
                                                >
                                                    <div className="pc-img-and-text-div">
                                                        <div className="">
                                                            <img style={{ width: "85%" }} src={PlanIcon} alt="" />
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            className="product-details"
                                                        >
                                                            <span className="plan-commman-name-text">
                                                                {e?.plan}-{e?.billing_cycle}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pc-category-title-text">{"1"}</div>
                                                    <div className="pc-category-title-text">
                                                        {InitialSetupFee + quantity * perUser_price}
                                                    </div>
                                                    <div
                                                        style={{ color: "#E62525" }}
                                                        className="pc-category-title-text"
                                                    >
                                                        -${discountedPrice * quantity}
                                                    </div>
                                                    <div className="pc-category-title-text justify-content-end">
                                                        ${total || "0"}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                {orderData.type === "UserPurchase" &&
                                    userPurchase &&
                                    <div className="main-paln-product-card">
                                        <div
                                            style={{ marginRight: "0", paddingRight: "20px" }}
                                            className="order-Product-cards"
                                        >
                                            <div className="pc-img-and-text-div">
                                                <div className="">

                                                    <img style={{ width: "85%" }} src={UserIcon} alt="" />
                                                </div>
                                                <div
                                                    style={{ cursor: "pointer" }}
                                                    className="product-details"
                                                >
                                                    <span className="plan-commman-name-text">
                                                        {"Users"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="pc-category-title-text">{userPurchase?.addusercount}</div>
                                            <div className="pc-category-title-text">
                                                ${(userPurchase?.price) / (userPurchase?.addusercount || 1)}
                                            </div>
                                            <div
                                                style={{ color: "#E62525" }}
                                                className="pc-category-title-text"
                                            >
                                                -
                                            </div>
                                            <div className="pc-category-title-text justify-content-end">
                                                ${userPurchase?.price || 0}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {orderData.type === "Subscription" &&
                                    planArray &&
                                    planArray?.map((e, index) => {
                                        const discountedPrice = e?.perUserDiscountPrice || 0;
                                        const quantity = e?.total_user[0]?.additionalUser || 0;
                                        const perUser_price =
                                            e?.billing_cycle === "yearly"
                                                ? e?.perUser_price * 12
                                                : e?.perUser_price;
                                        const total = quantity * (perUser_price - discountedPrice);
                                        return (<>
                                            {quantity > 0 && <div key={index} className="main-paln-product-card">
                                                <div
                                                    style={{ marginRight: "0", paddingRight: "20px" }}
                                                    className="order-Product-cards"
                                                >
                                                    <div className="pc-img-and-text-div">
                                                        <div className="">

                                                            <img style={{ width: "85%" }} src={UserIcon} alt="" />
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            className="product-details"
                                                        >
                                                            <span className="plan-commman-name-text">
                                                                {"Users"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pc-category-title-text">{quantity}</div>
                                                    <div className="pc-category-title-text">
                                                        {perUser_price}
                                                    </div>
                                                    <div
                                                        style={{ color: "#E62525" }}
                                                        className="pc-category-title-text"
                                                    >
                                                        -${discountedPrice * quantity}
                                                    </div>
                                                    <div className="pc-category-title-text justify-content-end">
                                                        ${total || 0}
                                                    </div>
                                                </div>
                                            </div>}
                                        </>
                                        );
                                    })}
                                {addons &&
                                    addons?.map((e, index) => {
                                        const discountedPrice = e?.addonDiscountPrice || 0;
                                        const quantity = 1;
                                        const total =
                                            parseInt(quantity, 10) * parseInt(e?.price, 10) -
                                            discountedPrice;
                                        return (
                                            <div key={index} className="main-paln-product-card">
                                                <div
                                                    style={{ marginRight: "0", paddingRight: "20px" }}
                                                    className="order-Product-cards"
                                                >
                                                    <div className="pc-img-and-text-div">
                                                        <div className="">

                                                            <img style={{ width: "85%" }} src={AddonIcon} alt="" />
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            className="product-details"
                                                        >
                                                            <span className="plan-commman-name-text">
                                                                {e?.addonId?.publicname}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pc-category-title-text">{"1"}</div>
                                                    <div className="pc-category-title-text">
                                                        ${e?.price}
                                                    </div>
                                                    <div
                                                        style={{ color: "#E62525" }}
                                                        className="pc-category-title-text"
                                                    >
                                                        ${discountedPrice}
                                                    </div>
                                                    <div className="pc-category-title-text justify-content-end">
                                                        ${total || 0}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                {orderData.type === "AddonPurchase" &&
                                    addonPurchase &&
                                    addonPurchase?.map((e, index) => {
                                        const discountedPrice = e.addonDiscountPrice;
                                        const quantity = 1;
                                        const total =
                                            parseInt(quantity, 10) * parseInt(e?.price, 10) -
                                            discountedPrice;
                                        return (
                                            <div key={index} className="main-paln-product-card">
                                                <div
                                                    style={{ marginRight: "0", paddingRight: "20px" }}
                                                    className="order-Product-cards"
                                                >
                                                    <div className="pc-img-and-text-div">
                                                        <div className="">
                                                            <img style={{ width: "85%" }} src={AddonIcon} alt="" />
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            className="product-details"
                                                        >
                                                            <span className="plan-commman-name-text">
                                                                {e?.addonId.publicname}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pc-category-title-text">{"1"}</div>
                                                    <div className="pc-category-title-text">
                                                        ${e?.price}
                                                    </div>
                                                    <div
                                                        style={{ color: "#E62525" }}
                                                        className="pc-category-title-text"
                                                    >
                                                        -${discountedPrice}
                                                    </div>
                                                    <div className="pc-category-title-text justify-content-end">
                                                        ${total || 0}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                {smartAccessories &&
                                    smartAccessories?.map((e, index) => {
                                        const discountedPrice = (e?.discountAmount || 0);
                                        const total = e.quantity * (e.price - discountedPrice);
                                        return (
                                            <div key={index} className="main-paln-product-card">
                                                <div
                                                    style={{ marginRight: "0", paddingRight: "20px" }}
                                                    className="order-Product-cards"
                                                >
                                                    <div className="pc-img-and-text-div">
                                                        <div className="">

                                                            <img
                                                                style={{ width: "85%" }}
                                                                src={ProductIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            className="product-details"
                                                        >
                                                            <span className="plan-commman-name-text">
                                                                {e?.productId?.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="pc-category-title-text">{e?.quantity}</div>
                                                    <div className="pc-category-title-text">
                                                        {e.price}
                                                    </div>
                                                    <div
                                                        style={{ color: "#E62525" }}
                                                        className="pc-category-title-text"
                                                    >
                                                        -${discountedPrice}
                                                    </div>
                                                    <div className="pc-category-title-text justify-content-end">
                                                        ${total}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                {orderData?.type === "Subscription" &&
                                    planArray &&
                                    planArray?.map((e) => {
                                        const subtotal = e?.total_amount - (shippingMethod || 0);
                                        return (
                                            <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                                <span>Items Subtotal</span>
                                                <span>${subtotal}</span>
                                            </div>
                                        );
                                    })}
                                {orderData?.type !== "Subscription" && (
                                    <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                        <span>Items Subtotal</span>
                                        <span>${parseFloat(orderData?.totalAmount, 10) - parseFloat((shippingMethod || 0), 10)}</span>
                                    </div>
                                )}
                                {orderData?.type === "Subscription" &&
                                    planArray &&
                                    planArray?.map((e) => {
                                        const subtotal = shippingMethod;
                                        return (
                                            <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                                <span>Fulfillment & Shipping</span>
                                                <span>${subtotal}</span>
                                            </div>
                                        );
                                    })}
                                {orderData?.type !== "Subscription" && (
                                    <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                        <span>Fulfillment & Shipping</span>
                                        <span>${(shippingMethod) || 0}</span>
                                    </div>
                                )}
                                {orderData.type === "Subscription" &&
                                    planArray?.map((e) => {
                                        const subtotal = e?.total_amount;
                                        return (
                                            <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                                 <span>Tax</span>
                                            <span>
                                                 ${e?.taxRate}
                                            </span>
                                            </div>
                                        );
                                    })}
                                {
                                    orderData.type !== "Subscription" && (
                                        <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                            <span>Tax</span>
                                            <span>
                                                $
                                                {orderData?.tax.toFixed(2)}
                                            </span>
                                        </div>
                                    )
                                }
                                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                                </div>
                                <div className="d-flex align-items-center justify-content-between pt-2 pb-2 mt-2" style={{ paddingLeft: '0px', paddingRight: '18px' }}>
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <span>Discount code</span>{" "}
                                        <input
                                            className="border-0 p-2"
                                            type="text"
                                            style={{ background: "#F2F2F2", borderRadius: "4px" }}
                                            readOnly
                                        ></input>
                                    </div>
                                    <span style={{ color: "#E65925" }}></span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between p-2 mt-2">
                                </div>
                                {orderData.type === "Subscription" &&
                                    orderData.subscription_details &&
                                    orderData.subscription_details.creditBalance !== undefined &&
                                    orderData.subscription_details.creditBalance !== null && (
                                        <div
                                            className="d-flex align-items-center justify-content-between  pt-2 pb-2 ps-2 mt-2"
                                            style={{
                                                borderRadius: "4px",
                                                border: "1px solid #EBE551",
                                                background: "#FDFCE9",
                                                color: "#E65925",
                                                paddingRight: '18px'
                                            }}
                                        >
                                            <span>Prorated Amount</span>
                                            { }
                                            <span>{orderData.subscription_details.creditBalance}</span>
                                        </div>
                                    )}
                                {orderData?.type === "Subscription" &&
                                    planArray &&
                                    planArray?.map((e) => {
                                        let totalproductprice = 0;
                                        smartAccessories?.forEach((pe, index) => {
                                            const discountedPrice = (pe?.discountAmount || 0);
                                            const total = pe.quantity * (pe.price - discountedPrice);
                                            totalproductprice += total;
                                        });
                                        const subtotal = e?.total_amount;
                                        const taxammount = e?.taxRate ;
                                        return (
                                            <div
                                                className="d-flex align-items-center justify-content-between text-white bordered pt-2 pb-2 ps-2 mt-2 "
                                                style={{ borderRadius: "4px", background: "#000", paddingRight: '18px' }}
                                            >
                                                <span>Total</span>
                                                <span>${subtotal + parseFloat((shippingMethod || 0), 10) + parseFloat(taxammount, 10)}</span>
                                            </div>
                                        );
                                    })}
                                {orderData?.type !== "Subscription" && (
                                    <div
                                        className="d-flex align-items-center justify-content-between text-white bordered  pt-2 pb-2 ps-2 mt-2"
                                        style={{ borderRadius: "4px", background: "#000", paddingRight: '18px' }}
                                    >
                                        <span>Total</span>
                                        <span>
                                            $
                                            {orderData?.totalAmount +
                                                parseFloat(
                                                    orderData?.tax.toFixed(
                                                        2
                                                    ),
                                                    10
                                                )}
                                        </span>
                                    </div>
                                )}
                                {orderData?.type === "Subscription" && planArray &&
                                    planArray?.map((e) => {
                                        return (
                                            <div
                                                className="d-flex align-items-center justify-content-between text-white bordered  pt-2 pb-2 ps-2 mt-2"
                                                style={{ borderRadius: "4px", background: "#E65925", paddingRight: '18px' }}
                                            >
                                                <span>Recurring total</span>
                                                <span>${e?.recurring_amount}</span>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>) : (<Loader />)
            }
        </div>
    );
}
export default ClinetOrderSummaryTab
