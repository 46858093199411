import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Otc_CreateOrdersHeadersWithIcons = (props) => {
    const navigate = useNavigate()
    const [couponTab, setCouponTab] = useState("Create Order");
    const { tab } = props;
    useEffect(() => {
        setCouponTab(tab);
    }, [tab]);
    return (
        <>
            <div className="OTC_Admin_mycard_header">
                <div onClick={() => { navigate("/admin/orders") }}
                    className={
                        couponTab === "Create Order" ? "OTC_Admin_mycard_header_icon_and_header_active" : "OTC_Admin_mycard_header_icon_and_header_deactive"
                    } >
                    <div
                        style={{
                            backgroundColor: couponTab === "Create Order" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 10L10.6 8.6L12.175 7H8V5H12.175L10.575 3.4L12 2L16 6L12 10ZM7 22C6.45 22 5.979 21.804 5.587 21.412C5.195 21.02 4.99933 20.5493 5 20C5 19.45 5.196 18.979 5.588 18.587C5.98 18.195 6.45067 17.9993 7 18C7.55 18 8.021 18.196 8.413 18.588C8.805 18.98 9.00067 19.4507 9 20C9 20.55 8.804 21.021 8.412 21.413C8.02 21.805 7.54933 22.0007 7 22ZM17 22C16.45 22 15.979 21.804 15.587 21.412C15.195 21.02 14.9993 20.5493 15 20C15 19.45 15.196 18.979 15.588 18.587C15.98 18.195 16.4507 17.9993 17 18C17.55 18 18.021 18.196 18.413 18.588C18.805 18.98 19.0007 19.4507 19 20C19 20.55 18.804 21.021 18.412 21.413C18.02 21.805 17.5493 22.0007 17 22ZM1 4V2H4.275L8.525 11H15.525L19.425 4H21.7L17.3 11.95C17.1167 12.2833 16.8707 12.5417 16.562 12.725C16.2533 12.9083 15.916 13 15.55 13H8.1L7 15H19V17H7C6.25 17 5.679 16.675 5.287 16.025C4.895 15.375 4.88267 14.7167 5.25 14.05L6.6 11.6L3 4H1Z" fill={couponTab === "Create Order" ? "#ffff" : "#000000"} />
                        </svg>
                    </div>
                    <p className={couponTab === "Create Order" ? "OTC_Admin_mycard_active_header" : "OTC_Admin_mycard_headers"} >
                        Create Order
                    </p>
                </div>
            </div >
        </>
    );
}

export default Otc_CreateOrdersHeadersWithIcons
