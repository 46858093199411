import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header2 from "../../components/Layout/Header/Header2";

function RejectInvitation() {
  const { invitationToken } = useParams();
  const [companyDetails, setCompanydetails] = useState({});
  const [companyName, setCompanyName] = useState("");
  const { company } = useSelector(
    (state) => state.Company
  );

  useEffect(() => {
    setCompanydetails(company);
  }, [company]);

  const [rejected, setRejected] = useState(false);
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    // Send a request to the server to update the invitation status to "Declined"
    axios
      .post(`${url}/reject-invitation/${invitationToken}`)
      .then((response) => {
        // Check if the response contains the company name
        if (response.data && response.data.companyName) {
          setCompanyName(response.data.companyName);
        }
        setRejected(true);
      })
      .catch((error) => {
        ;
      });
  }, [invitationToken]);

  return (
    <div>
      <Header2 />
      <div
        className="container mt-4"
        style={{ backgroundColor: "white", maxWidth: "1140px" }}
      >
        <div className="" style={{ padding: "50px 50px 30px 50px" }}>
          <h1 className="tnch1">Invitation Rejected</h1>

          <p className="tncp">
            We acknowledge your decision to decline our invitation for{" "}
            {companyName} as member. We respect your choice and understand that
            everyone has their commitments and priorities.
          </p>

          <p className="tncp">
            Please know that your presence was important to us, and we
            appreciate your consideration. We hope to have the opportunity to
            invite you to future events and occasions.
          </p>

          <p className="tncp">
            If you have any questions or would like to provide feedback, please
            feel free to reach out to us. Your input is valuable to us.
          </p>
          <p className="tncp">
            {" "}
            Once again, thank you for your time and understanding.
          </p>

          <p className="tncp">
            Best regards, <br />
            {companyName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default RejectInvitation;
