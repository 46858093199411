import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../../../actions/userAction/userAction'
import { getAllPlans } from '../../Redux/Otc_UserActions'
import { Tooltip } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import plusIconWithBG from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/plusIconWithBG.svg"
import Select from "react-select";
import { Country } from "country-state-city";
import './coupons.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from "../../../components/Layout/Loader/Loader";
import { EditorState, convertToRaw, ContentState, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function Coupons({ showCreateCoupon, onCouponNameClick, handleCouponsClick }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: "10px",
        overflowY: "scroll",
        maxHeight: "80%",
    };
    const warningModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "#FDE9E9",
        p: 3,
        outline: "none",
        border: "1px solid E62525",
        borderRadius: "10px",
    };

    const [shouldPermalinkSlugEdit, setShouldPermalinkSlugEdit] = useState(true);
    const [openWarningModal, setOpenWarningModal] = useState();
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const [openProductModal, setOpenProductModal] = useState(false);
    const [openAddOnModal, setOpenAddOnModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const url = process.env.REACT_APP_URL;
    const { products} = useSelector(
        (state) => state.products
    );
    const { plans } = useSelector(
        (state) => state.OtcPlans
    );
    const { adminuser } = useSelector((state) => state.OtcAdminuser);
    const [coupons, setCoupons] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCoupon, setSelectedCoupon] = useState("Coupon");
    const [selectedStatus, setSelectedStatus] = useState("Status");
    const [selectedCouponType, setSelectedCouponType] = useState("Coupon type");
    const [isTooltipOpen, setTooltipOpen] = useState(false);
    const [isPermalinkEditable, setIsPermalinkEditable] = useState(false);
    const [allCategories, setAllCategories] = useState([])
    const [showAddCatgory, setShowAddCatgory] = useState(false);
    const [categoryNameEmpty, setCategoryNameEmpty] = useState(false)
    const [CategoryName, setCategoryName] = useState('')
    const [productCategories, setProductCategories] = useState([])
    const [allAddons, setAllAddons] = useState([])
    const [fieldsEmpty, setFieldsEmpty] = useState(false);
    const [isCodeUnique, setIsCodeUnique] = useState(true);
    const [categoryAlreadyExistsError, setCategoryAlreadyExistsError] = useState(false);
    const [editingId, setEditingId] = useState('')
    const initialCouponData = {
        name: '',
        code: '',
        customPermaLink: '',
        description: '',
        discountType: '',
        discountAmount: '',
        planDiscount: [],
        productDiscount: [],
        addonDiscount: [],
        status: "Published",
        visibility: "Public",
        publishedBy: adminuser ? `${adminuser?.firstName} ${adminuser?.lastName}` : '',
        categories: [],
        startDate: '',
        expiryDate: '',
        usageLimit: null,
        usageLimitPerUser: null,
        autoApply: false,
        restrictions:
        {
            allowMultipleCoupons: false,
            forClients: false,
        },

        plan_restrictions: [
        ],
        teamPlanUsage: {
            minUsers: null,
            maxUsers: null,
        },
        freeShipping: false,
        minimumOrderPrice: null,
        freeShipCountries: [
        ],
        createdAt: null
    };
    const [couponData, setCouponData] = useState(initialCouponData)
    const [isTeamPlanSelected, setIsTeamPlanSelected] = useState(initialCouponData)

    useEffect(() => {
        const hasTeamPlan = couponData.plan_restrictions.some((restriction) => {
            const matchingPlan = plans?.find((plan) => plan._id === restriction.plan_id);
            return matchingPlan && matchingPlan.planType === "Team";
        });
        setIsTeamPlanSelected(hasTeamPlan);
    }, [couponData.plan_restrictions, plans])

    const getCoupons = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${url}/admin/getCoupon`);
            setCoupons(data.coupons)

            setLoading(false)
        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        getCoupons()
    }, [])

    const handleCopyClick = (code) => {
        const tempInput = document.createElement("input");
        tempInput.value = code;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setTooltipOpen(true);
        setTimeout(() => {
            setTooltipOpen(false);
        }, 900);
    };
    const [currentDateTime, setCurrentDateTime] = useState('');
    useEffect(() => {
        if (!plans) {
            dispatch(getAllPlans())
        }
    }, [])
    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [products])
    useEffect(() => {
        const formatDateTime = () => {
            const now = new Date();
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            };
            const formattedDate = now.toLocaleDateString('en-US', options);
            const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
            const hour = now.getHours() % 12 || 12;
            const formattedTime = `${hour}:${now.getMinutes()} ${amPm}`;
            setCurrentDateTime(`${formattedDate} @ ${formattedTime}`);
        };

        formatDateTime();
        const interval = setInterval(formatDateTime, 60000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const filteredCoupons = coupons?.filter((coupon) => {
        const matchesSearch = coupon?.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesCouponType = selectedCouponType === "Coupon type" || coupon?.discountType.toLowerCase() === selectedCouponType.toLowerCase();
        const matchesStatus = selectedStatus === "Status" || coupon?.status.toLowerCase() === selectedStatus.toLowerCase();
        const matchesCoupon = selectedCoupon === "Coupon" || coupon?.name.toLowerCase() === selectedCoupon.toLowerCase();
        return matchesSearch && matchesCouponType && matchesStatus && matchesCoupon;
    });
    const getUniqueCoupons = () => {
        const uniqueCoupons = Array.from(new Set(coupons?.map((val) => val?.name)));
        return uniqueCoupons?.filter(
            (product, index, self) => self.indexOf(product) === index
        );
    };
    const getUniqueCouponTypes = () => {
        const uniqueCoupons = Array.from(new Set(coupons?.map((val) => val?.discountType)));
        return uniqueCoupons?.filter(
            (product, index, self) => self.indexOf(product) === index
        );
    };
    const handleWarningOpenModal = () => { setOpenWarningModal(true) }
    const handleCloseWarningdModal = () => { setOpenWarningModal(false); handlePublish("Archived") };

    function getStatusColorClass(status) {
        if (status === "Published") {
            return "published";
        } else if (status === "Draft") {
            return "draft";
        } else if (status === "Archived") {
            return "archived";
        } else {
            return "";
        }
    }

    const fetchProductCategories = async () => {
        try {
            const response = await axios.get(`${url}/admin/productCategory/fetch`);
            const couponCategories = response.data.categories.filter(category => category.categoryType === 'Coupon');
            setAllCategories(couponCategories);
            const smartAccessoriesCategories = response.data.categories.filter(category => category.categoryType === 'Smart-accessories');
            setProductCategories(smartAccessoriesCategories);
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchProductCategories()
    }, [])

    const handleAddCategory = async () => {

        const name = CategoryName.trim();
        if (CategoryName.trim() === "" || CategoryName === " ") { setCategoryNameEmpty(true); setCategoryName(""); return }

        if (allCategories?.some(category => category.name === CategoryName.trim())) {
            setCategoryAlreadyExistsError(true);
            return;
        }

        try {
            const slug = name
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^a-z0-9-]/g, '');
            const response = await axios.post(`${url}/admin/createproductCategory`, {
                name: name,
                categoryType: "Coupon",
                CustomPermalink: slug
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            fetchProductCategories()
            setShowAddCatgory(false)
            setCategoryName('')
            setCategoryNameEmpty(false)
            setCategoryAlreadyExistsError(false)

        } catch (error) {
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'startDate') {
            setCouponData((prevData) => ({
                ...prevData,
                [name]: value,
                expiryDate: ''
            }));
        }
        setCouponData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleCategoryChange = (categoryId) => {
        setCouponData((prevCouponData) => {
            const updatedCategories = [...prevCouponData.categories];

            if (updatedCategories.includes(categoryId)) {
                updatedCategories.splice(updatedCategories.indexOf(categoryId), 1);
            } else {
                updatedCategories.push(categoryId);
            }

            return {
                ...prevCouponData,
                categories: updatedCategories,
            };
        });
    };
    const countryOptions = Country.getAllCountries().map((country) => ({
        value: country.isoCode,
        label: country.name,
    }));
    const handleCountryChange = (selectedOption) => {
        const isCountryAlreadySelected = couponData.freeShipCountries.some(
            (country) => country.countryCode === selectedOption.value
        );

        if (!isCountryAlreadySelected) {
            const selectedCountry = {
                countryName: selectedOption.label,
                countryCode: selectedOption.value,
            };

            setCouponData((prevCouponData) => ({
                ...prevCouponData,
                freeShipCountries: [...prevCouponData.freeShipCountries, selectedCountry],
            }));
        }
    };
    const removeCountry = (countryCode) => {
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            freeShipCountries: prevCouponData.freeShipCountries.filter(
                (country) => country.countryCode !== countryCode
            ),
        }));
    };
    const getAddons = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/getAddons`, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            setAllAddons(data.Addons)

        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        getAddons()
    }, [])
    const handlePlanChange = (event) => {
        const selectedPlanId = event.target.value;
        if (couponData.plan_restrictions.some((plan) => plan.plan_id === selectedPlanId)) {
            return;
        }
        const selectedPlan = plans?.find((plan) => plan._id === selectedPlanId);
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            plan_restrictions: [
                ...prevCouponData.plan_restrictions,
                {
                    plan_id: selectedPlanId,
                    plan_name: selectedPlan ? selectedPlan.PublicPlanName : "",
                    plan_type: selectedPlan ? selectedPlan.planType : "",
                },
            ],
        }));
    };
    const removePlan = (planId) => {
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            plan_restrictions: prevCouponData.plan_restrictions.filter(
                (plan) => plan.plan_id !== planId
            ),
        }));
    };
    const isFilterApplied = selectedCoupon !== "Coupon" || selectedStatus !== "Status" || selectedCouponType !== "Coupon type"
    const filterbtnStyle = {
        padding: "3px 15px",
        backgroundColor: "#e65925",
        color: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        display: isFilterApplied ? "block" : "none",
        marginLeft: "auto",
        marginBottom: "0px",
        height: "30px",
    };

    const clearFilters = () => {
        setSelectedCoupon("Coupon")
        setSelectedStatus("Status")
        setSelectedCouponType("Coupon type")
    };

    const handlePublish = async (status) => {
        setShouldPermalinkSlugEdit(true)
        try {
            if (couponData.name.trim() === '' || couponData.code.trim() === '' || couponData.customPermaLink.trim() === '' || couponData.discountType === '') {
                setFieldsEmpty(true)
                return;
            }
            if (couponData?.discountType === 'Fix discount price' || couponData?.discountType === 'Percentage discount price') {
                if (couponData?.discountAmount === '') {
                    setFieldsEmpty(true)
                    return;
                }
            }
            const checkCodeUniqueness = (code, currentCouponId) => {
                const isUnique = !coupons.some(coupon => coupon.code === code && coupon?._id !== currentCouponId);
                return isUnique;
            };

            const isCodeUnique = checkCodeUniqueness(couponData?.code, couponData?._id);
            if (!isCodeUnique) {
                setIsCodeUnique(false);
                return;
            }
            // Check if start date is empty, if so, set it to today's date
if (!couponData.startDate) {
    couponData.startDate = new Date().toISOString(); // Assuming ISO format
}

// Check if expiry date is empty, if so, set it to one year later from today
if (!couponData.expiryDate) {
    const oneYearLater = new Date();
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
    couponData.expiryDate = oneYearLater.toISOString(); // Assuming ISO format
}
            let updatedPlanRestrictions = couponData.plan_restrictions;
            if (couponData.plan_restrictions.length === 0) {
                updatedPlanRestrictions = plans.map(selectedPlan => ({
                    plan_id: selectedPlan ? selectedPlan._id : "",
                    plan_name: selectedPlan ? selectedPlan.PublicPlanName : "",
                    plan_type: selectedPlan ? selectedPlan.planType : "",
                }));
            }

            couponData.plan_restrictions = updatedPlanRestrictions;
            const updatedCouponData = { ...couponData, status: status };

            const response = await axios.post(`${url}/admin/coupon/create`, { couponData: updatedCouponData, id: editingId }, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            handleCouponsClick()
            getCoupons()
            setCouponData(initialCouponData)
            setEditingId('')
            setSelectedPlans([])
            setSelectedProducts([])
            setSelectedAddons([])
            setIsCodeUnique(true);
        } catch (error) {
            ;
        }
    }

    useEffect(() => {
        if (!showCreateCoupon) {
            setCouponData(initialCouponData)
            setEditingId('')
            setSelectedPlans([])
            setSelectedProducts([])
            setSelectedAddons([])
            setIsCodeUnique(true);
            setShouldPermalinkSlugEdit(true)
            setEditorState('')
        }

    }, [showCreateCoupon])

    const [selectedPlans, setSelectedPlans] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);

    const togglePlanSelection = (planId) => {
        if (selectedPlans.includes(planId)) {
            setSelectedPlans(prevSelected => prevSelected.filter(id => id !== planId));
        } else {
            setSelectedPlans(prevSelected => [...prevSelected, planId]);
        }
    };
    const handleUpdateClick = () => {
        const updatedPlanDiscount = selectedPlans.map((planId) => {
            const existingDiscount = couponData.planDiscount?.find((discount) => discount.plan_id === planId);
            return existingDiscount || { plan_id: planId };
        });

        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            planDiscount: updatedPlanDiscount,
        }));
        setOpenPlanModal(false);
    };

    const handleCancelClick = () => {
        setSelectedPlans(couponData.planDiscount.map(plan => plan.plan_id));
        setOpenPlanModal(false);
    };
    const handleRemovePlan = (planId) => {
        const updatedSelectedPlans = selectedPlans.filter((selectedPlanId) => selectedPlanId !== planId);
        setSelectedPlans(updatedSelectedPlans);
        const updatedPlanDiscount = couponData.planDiscount.filter((discount) => discount.plan_id !== planId);
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            planDiscount: updatedPlanDiscount,
        }));
    };

    const toggleProductSelection = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(prevSelected => prevSelected.filter(id => id !== productId));
        } else {
            setSelectedProducts(prevSelected => [...prevSelected, productId]);
        }
    };

    const handleUpdateProductsClick = () => {
        const updatedProductDiscount = selectedProducts.map((productId) => {
            const existingDiscount = couponData.productDiscount?.find((discount) => discount.product_id === productId);
            return existingDiscount || { product_id: productId };
        });

        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            productDiscount: updatedProductDiscount,
        }));
        setOpenProductModal(false);
    };

    const handleCancelProductsClick = () => {
        setSelectedProducts(couponData.productDiscount.map(product => product.product_id));
        setOpenProductModal(false);
    };
    const handleRemoveProduct = (productId) => {
        const updatedSelectedProducts = selectedProducts.filter((selectedProductId) => selectedProductId !== productId);
        setSelectedProducts(updatedSelectedProducts);
        const updatedProductDiscount = couponData.productDiscount.filter((discount) => discount.product_id !== productId);
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            productDiscount: updatedProductDiscount,
        }));
    };


    const toggleAddonSelection = (addonId) => {
        if (selectedAddons.includes(addonId)) {
            setSelectedAddons((prevSelected) => prevSelected.filter((id) => id !== addonId));
        } else {
            setSelectedAddons((prevSelected) => [...prevSelected, addonId]);
        }
    };

    const handleUpdateAddonsClick = () => {
        const updatedAddonDiscount = selectedAddons.map((addonId) => {
            const existingDiscount = couponData.addonDiscount?.find((discount) => discount.addon_id === addonId);
            const matchingAdd_ons = allAddons?.find((item) => item?._id === addonId)

            return existingDiscount || { addon_id: addonId, type: matchingAdd_ons?.paymentType, discountedPrice: '' };
        });

        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            addonDiscount: updatedAddonDiscount,
        }));

        setOpenAddOnModal(false);

    };


    const handleCancelAddonsClick = () => {
        setSelectedAddons(couponData?.addonDiscount?.map((addon) => addon.addon_id));
        setOpenAddOnModal(false);
    };

    const handleRemoveAddon = (addonId) => {
        const updatedSelectedAddons = selectedAddons?.filter((selectedAddonId) => selectedAddonId !== addonId);
        setSelectedAddons(updatedSelectedAddons);

        const updatedAddonDiscount = couponData?.addonDiscount?.filter((discount) => discount.addon_id !== addonId);
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            addonDiscount: updatedAddonDiscount,
        }));
    };

    const calculateDiscountedPrice = (originalPrice, discountAmount) => {
        const discountedPrice = originalPrice - discountAmount;
        return Math.max(discountedPrice, 0);
    };

    const calculateDiscountedPercentagePrice = (originalPrice, discountPercentage) => {

        if (!discountPercentage) return originalPrice;
        const percentage = parseFloat(discountPercentage) / 100;
        const discountedPrice = originalPrice * (1 - percentage);
        const result = Math.max(discountedPrice, 0);
        return result.toFixed(2);
    };
    const handleDiscountedPriceChange = (planId, period, value) => {
        const updatedPlanDiscount = couponData.planDiscount.map((plan) => {
            if (plan.plan_id === planId) {
                return {
                    ...plan,
                    [period]: {
                        ...plan[period],
                        discountedPrice: value,
                    },
                };
            }
            return plan;
        });
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            planDiscount: updatedPlanDiscount,
        }));
    };
    const handleUsageLimitChange = (planId, period, value) => {
        const updatedPlanDiscount = couponData.planDiscount.map((plan) => {
            if (plan.plan_id === planId) {
                return {
                    ...plan,
                    [period]: {
                        ...plan[period],
                        usageLimit: value,
                    },
                };
            }
            return plan;
        });
        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            planDiscount: updatedPlanDiscount,
        }));
    };
    const handleDiscountedPriceChangeOfProduct = (productId, value, originalPrice) => {
        const parsedValue = parseFloat(value);

        if (!isNaN(originalPrice)) {
            if (isNaN(parsedValue) || (parsedValue >= 0 && parsedValue <= originalPrice)) {
                const updatedProductDiscount = couponData.productDiscount.map((product) => {
                    if (product.product_id === productId) {
                        return {
                            ...product,
                            discountedPrice: value,
                        };
                    }
                    return product;
                });

                setCouponData((prevCouponData) => ({
                    ...prevCouponData,
                    productDiscount: updatedProductDiscount,
                }));
            } else {
            }
        } else {
            const updatedProductDiscount = couponData.productDiscount.map((product) => {
                if (product.product_id === productId) {
                    return {
                        ...product,
                        discountedPrice: value,
                    };
                }
                return product;
            });

            setCouponData((prevCouponData) => ({
                ...prevCouponData,
                productDiscount: updatedProductDiscount,
            }));
        }
    };

    const handleDiscountedPriceChangeOfVariationProduct = (productId, variationId, value) => {
        const updatedProductDiscount = couponData.productDiscount.map((product) => {
            if (product.product_id === productId) {
                const updatedVariations = product.variations || [];

                const updatedVariation = updatedVariations?.find((variation) => variation.variation_id === variationId) || {
                    variation_id: variationId,
                    variationDiscountedPrice: null,
                };

                updatedVariation.variationDiscountedPrice = value;

                const updatedVariationsArray = [...updatedVariations.filter((v) => v.variation_id !== variationId), updatedVariation];

                return {
                    ...product,
                    variations: updatedVariationsArray,
                };
            }
            return product;
        });

        setCouponData((prevCouponData) => ({
            ...prevCouponData,
            productDiscount: updatedProductDiscount,
        }));
    };

    const handleDiscountedPriceChangeOfAddOn = (Id, value, key, originalPrice) => {
        const parsedValue = parseFloat(value);

        if (!isNaN(originalPrice)) {
            if (isNaN(parsedValue) || (parsedValue >= 0 && parsedValue <= originalPrice)) {
                const updatedDiscount = couponData.addonDiscount.map((addOn) => {
                    if (addOn.addon_id === Id) {
                        return {
                            ...addOn,
                            [key]: value,
                        };
                    }
                    return addOn;
                });

                setCouponData((prevCouponData) => ({
                    ...prevCouponData,
                    addonDiscount: updatedDiscount,
                }));
            } else {
            }
        } else {
            const updatedDiscount = couponData.addonDiscount.map((addOn) => {
                if (addOn.addon_id === Id) {
                    return {
                        ...addOn,
                        [key]: value,
                    };
                }
                return addOn;
            });

            setCouponData((prevCouponData) => ({
                ...prevCouponData,
                addonDiscount: updatedDiscount,
            }));
        }
    };

    const handleEdit = (id) => {
        setShouldPermalinkSlugEdit(false)
        onCouponNameClick();
        const editedCoupon = coupons?.find(coupon => coupon._id === id);
        setCouponData(editedCoupon);
        setEditingId(id)
        setSelectedAddons(editedCoupon.addonDiscount.map(addOn => addOn.addon_id))
        setSelectedProducts(editedCoupon.productDiscount.map(product => product.product_id))
        setSelectedPlans(editedCoupon.planDiscount.map(plan => plan.plan_id))

        const htmlContent = editedCoupon?.description;
        if (htmlContent) {
            const blocksFromHTML = htmlToDraft(htmlContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }
    const formattedDate = couponData.createdAt
        ? new Date(couponData.createdAt).toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).replace(',', ' @')
        : currentDateTime;

    const [currentPage, setCurrentPage] = useState(1);
    const [displayedPages, setDisplayedPages] = useState([]);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredCoupons?.length / itemsPerPage)
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredCoupons?.slice(startIndex, endIndex);

    useEffect(() => {
        if (totalPages <= 5) {
            setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
        } else {
            if (currentPage <= 3) {
                setDisplayedPages([1, 2, 3, 4, 5, '. . .', totalPages]);
            } else if (currentPage >= totalPages - 2) {
                setDisplayedPages([1, '. . .', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
            } else {
                setDisplayedPages([1, '. . .', currentPage - 1, currentPage, currentPage + 1, '. . .', totalPages]);
            }
        }
    }, [currentPage, totalPages]);
    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const rawContentState = convertToRaw(newEditorState.getCurrentContent());
        const html = draftToHtml(rawContentState);
        console.log("editorState", html)
        setCouponData({
            ...couponData,
            description: html

        });
    };
    return (
        <>
            {!showCreateCoupon ?
                <div className="onetap_conn_users_tab_content_OTC_Admin" >
                    <div className="onetap_conn_users_tap_filter_OTC_Admin">
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <input
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="Coupon"
                                id="Coupon"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedCoupon}
                                onChange={(e) => setSelectedCoupon(e.target.value)}
                            >
                                <option value="Coupon">Coupon</option>

                                {getUniqueCoupons()?.map((group) => (
                                    <option
                                        style={{ textTransform: "capitalize" }}
                                        key={group}
                                        value={group}
                                    >
                                        {group}
                                    </option>
                                ))}

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="Category"
                                id="Category"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedCouponType}
                                onChange={(e) => setSelectedCouponType(e.target.value)}
                            >
                                <option value="Coupon type">Coupon type</option>
                                {getUniqueCouponTypes()?.map((group) => (
                                    <option
                                        style={{ textTransform: "capitalize" }}
                                        key={group}
                                        value={group}
                                    >
                                        {group}
                                    </option>
                                ))}

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                        <div className="onetap_conn_filter_search_OTC_Admin">
                            <select
                                name="status"
                                id="status"
                                style={{
                                    appearance: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                }}
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                <option value="Status">Status</option>
                                <option value="Published">Published</option>
                                <option value="Draft">Draft</option>
                                <option value="Archived">Archived</option>

                            </select>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="22"
                                viewBox="0 0 23 22"
                                fill="none"
                                className="onetap_conn_filter_svg_OTC_Admin"
                            >
                                <path
                                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="onetap_conn_filter_search_OTC_Admin">
                        <button style={filterbtnStyle} onClick={clearFilters}>
                            Clear Filters
                        </button>
                    </div>
                    {loading ? <Loader /> : Coupons?.length > 0 ?
                        <table class="table product_table">
                            <thead>
                                <tr >
                                    <th scope="col" className='w-25' style={{ textAlign: 'left' }} >
                                        Coupon name
                                    </th>
                                    <th scope="col">Coupon code</th>
                                    <th scope="col">Coupon type</th>
                                    {/* <th scope="col">Used in order</th> */}
                                    <th scope="col">Category</th>
                                    <th scope="col">Usage/Limit</th>
                                    <th scope="col">Expiry Date</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>

                                {paginatedData?.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td role="button" onClick={() => handleEdit(val?._id)} style={{ textAlign: 'left' }}>{val?.name ? val?.name?.charAt(0)?.toUpperCase() + val?.name?.slice(1) : ''}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <Tooltip
                                                            title={isTooltipOpen ? "Coupon Copied!" : "Copy to clipboard"}
                                                            placement="bottom"
                                                            arrow
                                                        >
                                                            <svg className='me-2' onClick={() => handleCopyClick(val?.code)} role="button" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M7.5 14.9998C7.04167 14.9998 6.64917 14.8365 6.3225 14.5098C5.99583 14.1832 5.83278 13.7909 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.65 1.82901 7.04222 1.66595 7.5 1.66651H15C15.4583 1.66651 15.8508 1.82984 16.1775 2.15651C16.5042 2.48317 16.6672 2.87539 16.6667 3.33317V13.3332C16.6667 13.7915 16.5033 14.184 16.1767 14.5107C15.85 14.8373 15.4578 15.0004 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332ZM4.16667 18.3332C3.70833 18.3332 3.31583 18.1698 2.98917 17.8432C2.6625 17.5165 2.49945 17.1243 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667Z" fill="#333333" />
                                                            </svg>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <span>{val?.code}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{val?.discountType}</td>
                                            <td>
                                                {val.categories?.map((categoryId, i, array) => {
                                                    const matchingCategory = allCategories?.find(category => category._id === categoryId);
                                                    if (matchingCategory) {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <span>{matchingCategory.name}</span>
                                                                {i < array.length - 1 && ', '}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </td>
                                            <td style={{ fontSize: val?.usageLimit ? '' : '23px' }}>{val?.usageLimit ? val?.usageLimit : '∞'}</td>
                                            <td>{val?.expiryDate?.split("T")[0] || ""} </td>
                                            <td className={`status-cell ${getStatusColorClass(val?.status)}`}>{val?.status}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        : "No coupons available."}
                    {
                        totalPages > 1 && (
                            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {
                                    currentPage !== 1 ?
                                        <ArrowBackIosNewIcon onClick={() => handlePageChange(currentPage - 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                        :
                                        <ArrowBackIosNewIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />

                                }
                                {displayedPages.map((page, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(page)}
                                        style={{
                                            backgroundColor: currentPage === page ? '#e65925' : 'transparent',
                                            color: currentPage === page ? 'white' : 'black',
                                            border: 'none',
                                            borderRadius: '4px',
                                            padding: '4px 20px',
                                            margin: '2px',
                                        }}

                                    >
                                        {page}
                                    </button>)
                                )}
                                {currentPage !== totalPages ?
                                    <ArrowForwardIosIcon onClick={() => handlePageChange(currentPage + 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                    :
                                    <ArrowForwardIosIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />
                                }
                            </div>
                        )
                    }
                </div>
                :
                <>
                    <div className="container" style={{ padding: '0px' }}>
                        <div className="w-100  d-flex flex-row-reverse gap-3 mb-4 plan-btn-top">
                            <div class="dropdown">
                                <button class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: '#000', color: '#fff', }}>
                                    <span className='me-5'>Actions</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ left: 'unset' }}>

                                    <a class="dropdown-item" href="#" onClick={() => handleWarningOpenModal()}>Archive</a>
                                    <a class="dropdown-item" href="#" >Duplicate</a>
                                </div>
                            </div>
                            <button className="btn px-3 py-2  text-white" style={{ background: editingId ? '#E65925' : '#48AF25', width: '15%' }} onClick={() => handlePublish("Published")}>{editingId ?'Update':'Publish'}</button>
                            <button
                                className="btn px-3 py-2 text-white"
                                disabled={couponData.status !== 'Draft'}
                                style={{
                                    background: couponData.status !== 'Draft' ? '#8A8A8A' : 'black',
                                    width: '15%',
                                }}
                                onClick={() => handlePublish("Draft")}
                            >
                                Save draft
                            </button>

                        </div>
                        <div className="row">
                            <div className="col-8" >
                                <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                                    <h5>Coupon Internal Name </h5>
                                    <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="name"
                                        value={couponData.name}
                                        onChange={handleInputChange}

                                    />
                                    {fieldsEmpty && !couponData.name && <p style={{ color: "red", marginBottom: "0px" }}>Coupon name is required.</p>}

                                </div>
                                <div className="mt-3 p-4 d-flex gap-3 align-items-center" style={{ borderRadius: '8px', backgroundColor: '#E65925' }}>
                                    <h5 className="w-25 text-white">Coupon Code</h5>

                                    <input className="w-100 border-0 p-2 ms-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="code" value={couponData.code}
                                        onChange={(e) => {
                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            const link = e.target.value.trim()
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setCouponData({
                                                ...couponData,
                                                code: slug,
                                                customPermaLink: shouldPermalinkSlugEdit ? link.trim() : couponData.customPermaLink,
                                            });
                                        }}
                                    />

                                </div>
                                {fieldsEmpty && !couponData.code && <p style={{ color: "red", marginBottom: "0px" }}>Coupon code is required.</p>}
                                {!fieldsEmpty && !isCodeUnique && <p style={{ color: "red", marginBottom: "0px" }}>This coupon code already exists.</p>}

                                <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                    <h5>Permalink</h5>
                                    <div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                        https://onetapconnect.com/
                                        <input className="w-100 border-0 me-2" type="text" style={{ background: "#f2f2f2" }} readOnly={!isPermalinkEditable} value={couponData.customPermaLink} name="customPermaLink"
                                            onChange={(e) => {
                                                const slug = e.target.value
                                                    .toLowerCase()
                                                    .replace(/\s+/g, '-')
                                                    .replace(/[^a-z0-9-]/g, '');
                                                setCouponData({
                                                    ...couponData,
                                                    customPermaLink: slug,
                                                });
                                            }}
                                        />
                                        <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(true)} role="button">Edit</span>
                                    </div>
                                    {fieldsEmpty && !couponData.customPermaLink && <p style={{ color: "red", marginBottom: "0px" }}>Permalink is required.</p>}

                                </div>
                                <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                    <h5>Coupon Description </h5>

                                  
                                    <Editor
                                        editorStyle={{ backgroundColor: '#f2f2f2', overflowWrap: 'break-word', minHeight: '150px', marginBottom: '2rem' }}
                                        name="description"
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </div>
                                <div className="bg-white mt-3 p-4 d-flex gap-3" style={{ borderRadius: '8px' }}>
                                    <div className="w-50 d-flex align-items-center">
                                        <h5>Discount Type</h5>
                                        <select class="form-select p-2 w-100 ms-2" style={{ backgroundColor: '#f2f2f2', borderRadius: '4px' }}
                                            name="discountType"
                                            value={couponData.discountType}
                                            onChange={handleInputChange}
                                        >
                                            <option selected value="" disabled className="d-none">Select</option>
                                            <option value="Fix discount price">Fix discount price</option>
                                            <option value="Percentage discount price">Percentage discount price</option>
                                            <option selected value="Fix custom discount">Fix custom discount</option>
                                            <option selected value="Percentage custom discount">Percentage custom discount</option>
                                        </select>
                                    </div>

                                    {(couponData?.discountType === 'Fix discount price' || couponData?.discountType === 'Percentage discount price') && (
                                        <div className="w-50 d-flex align-items-center">
                                            <h5>Discount amount</h5>
                                            <input className="w-100 border-0 p-2 ms-2 coupon-number-input" type="number"
                                                style={{
                                                    borderRadius: "4px",
                                                    background: "#f2f2f2",
                                                }}
                                                name="discountAmount"
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                value={couponData.discountAmount}
                                                onChange={handleInputChange} />
                                        </div>
                                    )}
                                </div>
                                {fieldsEmpty && !couponData.discountType && <p style={{ color: "red", marginBottom: "0px" }}>Discount type is required.</p>}
                                {fieldsEmpty && !couponData.discountAmount && (couponData?.discountType === 'Fix discount price' || couponData?.discountType === 'Percentage discount price') && <p style={{ color: "red", marginBottom: "0px" }}>Discount amount is required.</p>}

                                <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                    <div className="d-flex gap-3">
                                        <div className="w-100 d-flex justify-content-between align-items-center">
                                            <h5>Plan discount</h5>
                                            <div role="button" className="plan-plus-btn-group" onClick={() => setOpenPlanModal(true)}>
                                                <img style={{ marginRight: "12px" }} src={plusIconWithBG} alt="" />
                                                <span>Select a plan</span>
                                            </div>
                                        </div>
                                    </div>
                                    {couponData?.planDiscount.length > 0 && (
                                        <table class="table mt-4 product_table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                                        Items
                                                    </th>
                                                    <th scope="col" className='border-0'>Active for x payments</th>
                                                    <th scope="col" className='border-0'>Per user price</th>
                                                    <th scope="col" className='border-0'>Per user discounted price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {couponData.planDiscount.map((discount, i) => {
                                                    const plan = plans?.find(plan => plan._id === discount.plan_id);

                                                    if (!plan) {
                                                        return null;
                                                    }
                                                    return (
                                                        <React.Fragment key={i}>
                                                            { }

                                                            <tr key={`monthly-${plan._id}`} className="align-items-center">
                                                                <td className="w-25" style={{ textAlign: 'left' }}>{plan?.PublicPlanName} - Monthly</td>
                                                                <td>
                                                                    <input className="w-75 text-center border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "" }} name="usage" value={discount?.monthly?.usageLimit} onChange={(e) => handleUsageLimitChange(plan._id, 'monthly', e.target.value)} />
                                                                </td>
                                                                <td>{plan?.monthlyPrice_perUser || 0}</td>
                                                                <td>
                                                                    {couponData?.discountType === 'Fix custom discount' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }}>$</span>
                                                                            <input className="w-75 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={discount?.monthly?.discountedPrice} onChange={(e) => handleDiscountedPriceChange(plan._id, 'monthly', e.target.value)} />
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Fix discount price' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(plan?.monthlyPrice_perUser || 0, couponData?.discountAmount)}</span>
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Percentage discount price' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(plan?.monthlyPrice_perUser || 0, couponData?.discountAmount)}</span>
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Percentage custom discount' && (
                                                                        <>
                                                                            <input className="w-75 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={discount?.monthly?.discountedPrice} onChange={(e) => handleDiscountedPriceChange(plan._id, 'monthly', e.target.value)} />
                                                                            <span style={{ color: "#E65925", }}>%</span>
                                                                        </>
                                                                    )}
                                                                </td>

                                                                <svg onClick={() => handleRemovePlan(plan._id)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#E65925" />
                                                                </svg>
                                                            </tr>
                                                            <tr key={`yearly-${plan._id}`} className="align-items-center">
                                                                <td className="w-50" style={{ textAlign: 'left' }}>{plan?.PublicPlanName} - Yearly</td>
                                                                <td>
                                                                    <input className="w-75 text-center border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "" }} name="usage" value={discount?.yearly?.usageLimit} onChange={(e) => handleUsageLimitChange(plan._id, 'yearly', e.target.value)} />
                                                                </td>
                                                                <td>{plan?.yearlyPrice_perUser || 0}</td>
                                                                <td>
                                                                    {couponData?.discountType === 'Fix custom discount' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }}>$</span>
                                                                            <input className="w-75 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={discount?.yearly?.discountedPrice} onChange={(e) => handleDiscountedPriceChange(plan._id, 'yearly', e.target.value)} />
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Fix discount price' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(plan?.yearlyPrice_perUser || 0, couponData?.discountAmount)}</span>
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Percentage discount price' && (
                                                                        <>
                                                                            <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(plan?.yearlyPrice_perUser || 0, couponData?.discountAmount)}</span>
                                                                        </>
                                                                    )}
                                                                    {couponData?.discountType === 'Percentage custom discount' && (
                                                                        <>
                                                                            <input className="w-75 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={discount?.yearly?.discountedPrice} onChange={(e) => handleDiscountedPriceChange(plan._id, 'yearly', e.target.value)} />
                                                                            <span style={{ color: "#E65925", }}>%</span>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                    <div className="d-flex gap-3">
                                        <div className="w-100 d-flex justify-content-between align-items-center">
                                            <h5>Smart Accessories discount</h5>
                                            <div role="button" className="plan-plus-btn-group" onClick={() => setOpenProductModal(true)}>
                                                <img style={{ marginRight: "12px" }} src={plusIconWithBG} alt="" />
                                                <span>Select a product</span>
                                            </div>
                                        </div>
                                    </div>
                                    {couponData?.productDiscount.length > 0 && (
                                        <table class="table mt-4 product_table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                                        Items
                                                    </th>
                                                    <th scope="col" className='border-0'>Unit price</th>
                                                    <th scope="col" className='border-0'>Discounted price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products
                                                    .filter(product => couponData.productDiscount.some(discount => discount.product_id === product._id))
                                                    .map((product, i) => (
                                                        <>
                                                            <tr key={i} className="align-items-center">
                                                                <td className="w-50" style={{ textAlign: 'left' }}>{product?.name}</td>
                                                                <td>{product?.isOnSale ? product?.saleprice : product?.price}</td>
                                                                {product?.hasVariations === false ?
                                                                    <td>
                                                                        {couponData?.discountType === 'Fix custom discount' && (
                                                                            <>
                                                                                <span style={{ color: "#E65925", }}>$</span>
                                                                                <input className="w-50 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.productDiscount?.find(item => item.product_id === product?._id)?.discountedPrice} onChange={(e) => handleDiscountedPriceChangeOfProduct(product._id, e.target.value, product?.isOnSale ? product?.saleprice : product?.price)} />
                                                                            </>
                                                                        )}
                                                                        {couponData?.discountType === 'Fix discount price' && (
                                                                            <>
                                                                                <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(product?.isOnSale ? product?.saleprice : product?.price, couponData?.discountAmount)}</span>
                                                                            </>
                                                                        )}
                                                                        {couponData?.discountType === 'Percentage discount price' && (
                                                                            <>
                                                                                <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(product?.isOnSale ? product?.saleprice : product?.price, couponData?.discountAmount)}</span>
                                                                            </>
                                                                        )}
                                                                        {couponData?.discountType === 'Percentage custom discount' && (
                                                                            <>
                                                                                <input className="w-50 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.productDiscount?.find(item => item.product_id === product?._id)?.discountedPrice} onChange={(e) => handleDiscountedPriceChangeOfProduct(product._id, e.target.value)} />
                                                                                <span style={{ color: "#E65925", }}>%</span>
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    : <td></td>}
                                                                <svg onClick={() => handleRemoveProduct(product._id)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#E65925" />
                                                                </svg>
                                                            </tr>
                                                            {
                                                                product.hasVariations && product.variations.length > 0 &&
                                                                product.variations.map((variation, index) => (
                                                                    <tr key={index} className="align-items-center ms-2">

                                                                        <td className="w-50 ps-5  ms-5" style={{ textAlign: 'left' }}>{variation?.Type}</td>
                                                                        <td>{product?.isOnSale ? variation?.saleprice : variation?.price}</td>

                                                                        <td>
                                                                            {couponData?.discountType === 'Fix custom discount' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }}>$</span>
                                                                                    <input className="w-50 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={
                                                                                        couponData?.productDiscount
                                                                                            ?.find((item) => item.product_id === product?._id)
                                                                                            ?.variations?.find((item) => item?.variation_id === variation?._id)
                                                                                            ?.variationDiscountedPrice
                                                                                    } onChange={(e) => handleDiscountedPriceChangeOfVariationProduct(product._id, variation._id, e.target.value, product?.isOnSale ? variation?.saleprice : variation?.price)} />
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Fix discount price' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(product?.isOnSale ? variation?.saleprice : variation?.price, couponData?.discountAmount)}</span>
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Percentage discount price' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(product?.isOnSale ? variation?.saleprice : variation?.price, couponData?.discountAmount)}</span>
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Percentage custom discount' && (
                                                                                <>
                                                                                    <input className="w-50 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={
                                                                                        couponData?.productDiscount
                                                                                            ?.find((item) => item.product_id === product?._id)
                                                                                            ?.variations?.find((item) => item.variation_id === variation._id)
                                                                                            ?.variationDiscountedPrice
                                                                                    } onChange={(e) => handleDiscountedPriceChangeOfVariationProduct(product._id, variation._id, e.target.value)} />
                                                                                    <span style={{ color: "#E65925", }}>%</span>
                                                                                </>
                                                                            )}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }
                                                        </>
                                                    ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                <div className="bg-white mt-3 p-4" style={{ borderRadius: '8px' }}>
                                    <div className="d-flex gap-3">
                                        <div className="w-100 d-flex justify-content-between align-items-center">
                                            <h5> Add-on discount</h5>
                                            <div role="button" className="plan-plus-btn-group" onClick={() => setOpenAddOnModal(true)}>
                                                <img style={{ marginRight: "12px" }} src={plusIconWithBG} alt="" />
                                                <span>Select an add-on</span>
                                            </div>
                                        </div>
                                    </div>
                                    {couponData?.addonDiscount?.length > 0 && (
                                        <table class="table mt-4 product_table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                                        Items
                                                    </th>
                                                    <th scope="col" className='border-0'>Unit price</th>
                                                    <th scope="col" className='border-0'>Discounted price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allAddons
                                                    .filter(addOn => couponData.addonDiscount.some(discount => discount.addon_id === addOn._id))
                                                    .map((addOn, i) => (
                                                        <React.Fragment key={i}>
                                                            {
                                                                addOn.paymentType === "onetimepayment" ?
                                                                    <tr className="align-items-center">
                                                                        <td className="w-50" style={{ textAlign: 'left' }}>{addOn?.publicname}</td>
                                                                        <td>{addOn?.Addonspaymentdata?.price}</td>
                                                                        <td>
                                                                            {couponData?.discountType === 'Fix custom discount' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }}>$</span>
                                                                                    <input className="w-50 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.discountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "discountedPrice", addOn?.Addonspaymentdata?.price)} />
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Fix discount price' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(addOn?.Addonspaymentdata?.price, couponData?.discountAmount)}</span>
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Percentage discount price' && (
                                                                                <>
                                                                                    <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(addOn?.Addonspaymentdata?.price, couponData?.discountAmount)}</span>
                                                                                </>
                                                                            )}
                                                                            {couponData?.discountType === 'Percentage custom discount' && (
                                                                                <>
                                                                                    <input className="w-50 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.discountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "discountedPrice")} />
                                                                                    <span style={{ color: "#E65925", }}>%</span>
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                        <svg onClick={() => handleRemoveAddon(addOn._id)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#E65925" />
                                                                        </svg>
                                                                    </tr>
                                                                    :
                                                                    <>
                                                                        <tr className="align-items-center">
                                                                            <td className="w-50" style={{ textAlign: 'left' }}>{addOn?.publicname} - Monthly</td>
                                                                            <td>{addOn?.Addonspaymentdata?.monthlyprice || ''}</td>
                                                                            <td>
                                                                                {couponData?.discountType === 'Fix custom discount' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }}>$</span>
                                                                                        <input className="w-50 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.monthlyDiscountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "monthlyDiscountedPrice", addOn?.Addonspaymentdata?.monthlyprice)} />
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Fix discount price' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(addOn?.Addonspaymentdata?.monthlyprice, couponData?.discountAmount)}</span>
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Percentage discount price' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(addOn?.Addonspaymentdata?.monthlyprice, couponData?.discountAmount)}</span>
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Percentage custom discount' && (
                                                                                    <>
                                                                                        <input className="w-50 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.monthlyDiscountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "monthlyDiscountedPrice")} />
                                                                                        <span style={{ color: "#E65925", }}>%</span>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                            <svg onClick={() => handleRemoveAddon(addOn._id)} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#E65925" />
                                                                            </svg>
                                                                        </tr>
                                                                        <tr className="align-items-center">
                                                                            <td className="w-50" style={{ textAlign: 'left' }}>{addOn?.publicname} - Yearly</td>
                                                                            <td>{addOn?.Addonspaymentdata?.yearlyprice ? addOn?.Addonspaymentdata?.yearlyprice : ''}</td>
                                                                            <td>
                                                                                {couponData?.discountType === 'Fix custom discount' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }}>$</span>
                                                                                        <input className="w-50 text-center border-0 p-2 ms-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.yearlyDiscountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "yearlyDiscountedPrice", addOn?.Addonspaymentdata?.yearlyprice)} />
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Fix discount price' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPrice(addOn?.Addonspaymentdata?.yearlyprice, couponData?.discountAmount)}</span>
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Percentage discount price' && (
                                                                                    <>
                                                                                        <span style={{ color: "#E65925", }} >$</span> <span style={{ color: "#E65925", }}>{calculateDiscountedPercentagePrice(addOn?.Addonspaymentdata?.yearlyprice, couponData?.discountAmount)}</span>
                                                                                    </>
                                                                                )}
                                                                                {couponData?.discountType === 'Percentage custom discount' && (
                                                                                    <>
                                                                                        <input className="w-50 text-center border-0 p-2 me-1 coupon-number-input" type="number" style={{ borderRadius: "4px", color: "#E65925", }} name="discount" value={couponData?.addonDiscount?.find(item => item.addon_id === addOn._id)?.yearlyDiscountedPrice} onChange={(e) => handleDiscountedPriceChangeOfAddOn(addOn._id, e.target.value, "yearlyDiscountedPrice", addOn?.Addonspaymentdata?.yearlyprice)} />
                                                                                        <span style={{ color: "#E65925", }}>%</span>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="bg-white p-4" style={{ borderRadius: '4px' }}>
                                    <h5 className="border-bottom pb-2">Coupon information</h5>
                                    <div className="gap-3 d-flex flex-column">
                                        <div className="mt-2">
                                            <span>Status</span>
                                            <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} name="status"
                                                value={couponData.status}
                                                onChange={handleInputChange} >
                                                <option value="Draft">Draft</option>
                                                <option selected value="Published">Published</option>
                                                <option value="Archived">Archived</option>
                                            </select>
                                        </div>
                                        <div>
                                            <span>Visibility</span>
                                            <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} name="visibility"
                                                value={couponData.visibility}
                                                onChange={handleInputChange} >
                                                <option selected value="Public">Public</option>
                                                <option value="Hidden">Hidden</option>
                                            </select>
                                        </div>
                                        <div>
                                            <span>Created date</span>
                                            <input value={formattedDate} className="w-100 border-0 p-2" readOnly type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} />
                                        </div>
                                        <div>
                                            <span>Published by</span>
                                            <input className="w-100 border-0 p-2" type="text" readOnly style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="publishedBy" value={couponData.publishedBy} />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                    <div className="d-flex justify-content-between border-bottom mb-2">
                                        <h5 className="pb-2">Coupon Categories</h5>
                                        <p style={{ color: '#E65925', textAlign: 'right' }} role="button" onClick={() => setShowAddCatgory(true)} >+ Add category</p>
                                    </div>
                                    {allCategories?.map((val, i) => {
                                        if (val?.status !== 'Published' && val?.visibility !== 'Public') return
                                        return (
                                            <div class="form-check d-flex align-items-center" key={i}>
                                                <input class="form-check-input" type="checkbox" name="category" id={`category-${i}`}
                                                    value={val._id}
                                                    onChange={(e) => handleCategoryChange(e.target.value)}
                                                    checked={couponData.categories.includes(val._id)} />
                                                <label class="form-check-label ms-2 text-black" htmlFor={`category-${i}`} style={{ textTransform: "capitalize" }}>
                                                    {val.name}
                                                </label>
                                            </div>
                                        )
                                    })}
                                    {showAddCatgory && <div className="d-flex flex-end flex-column">
                                        <input placeholder="Category Name" autoFocus className="mt-3 w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} value={CategoryName} onChange={(e) => setCategoryName(e.target.value)} />
                                        {categoryNameEmpty && !CategoryName && <p style={{ color: "red", marginBottom: "0px" }}>Category Name is required.</p>}
                                        {categoryAlreadyExistsError && CategoryName && <p style={{ color: "red", marginBottom: "0px" }}>Category already exists.</p>}

                                        <div className="d-flex">
                                            <button className="btn mt-2 ms-auto text-white px-4" style={{ backgroundColor: '#E65925' }} onClick={() => handleAddCategory()}>Add</button>
                                            <button className="btn mt-2 ms-2 text-white" style={{ backgroundColor: '#E65925' }} onClick={() => { setShowAddCatgory(false); setCategoryName('') }}>Cancel</button>
                                        </div>
                                    </div>}
                                </div>
                                <div className="bg-white p-4 mt-2" style={{ borderRadius: '4px' }}>
                                    <h5 className="border-bottom pb-2">Usage limits</h5>
                                    <div className="gap-3 d-flex flex-column">
                                        <div className="mt-2">
                                            <span>Start date</span>
                                            <input className="w-100 border-0 p-2" type="date" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="startDate"
                                                value={couponData?.startDate ? couponData?.startDate?.split("T")[0] : ""}
                                                onChange={handleInputChange} />
                                        </div>
                                        <div className="mt-2">
                                            <span>Expiry date</span>
                                            <input className="w-100 border-0 p-2" type="date" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="expiryDate"
                                                value={couponData?.expiryDate ? couponData?.expiryDate?.split("T")[0] : ""}
                                                onChange={handleInputChange}
                                                min={couponData.startDate} />
                                        </div>
                                        <div className="mt-2 d-flex align-items-center">
                                            <span className="w-75">Usage limit per coupon</span>
                                            <input className="text-center w-25 ms-2 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="usageLimit"
                                                value={couponData?.usageLimit}
                                                onChange={handleInputChange} />
                                        </div>
                                        <div className="mt-2 d-flex align-items-center">
                                            <span className="w-75">Usage limit per user</span>
                                            <input className=" text-center w-25 ms-2 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="usageLimitPerUser"
                                                value={couponData?.usageLimitPerUser}
                                                onChange={handleInputChange} />
                                        </div>
                                        <div class="form-check d-flex align-items-center">
                                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" name="autoApply"
                                                value={couponData?.autoApply}
                                                checked={couponData?.autoApply}
                                                onChange={(e) => {
                                                    setCouponData({
                                                        ...couponData,
                                                        autoApply: e.target.checked
                                                    });
                                                }} />
                                            <label class="form-check-label ms-2 text-black" for="flexCheckDefault" >
                                                Auto apply
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white p-4 mt-2 gap-2 d-flex flex-column" style={{ borderRadius: '8px' }}>
                                    <h5 className="border-bottom pb-2" >Usage restrictions</h5>
                                    <div class="form-check d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault" name="allowMultipleCoupons"
                                            value={couponData.restrictions.allowMultipleCoupons}
                                            checked={couponData.restrictions.allowMultipleCoupons}
                                            onChange={(e) => {
                                                setCouponData({
                                                    ...couponData,
                                                    restrictions: {
                                                        ...couponData.restrictions,
                                                        allowMultipleCoupons: e.target.checked,
                                                    }
                                                });
                                            }} />
                                        <label class="form-check-label ms-2 text-black" for="flexCheckDefault" >
                                            Coupon can be used with other coupons?
                                        </label>
                                    </div>
                                    <div class="form-check d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault" name="forClients"
                                            value={couponData.restrictions.forClients}
                                            checked={couponData.restrictions.forClients}
                                            onChange={(e) => {
                                                setCouponData({
                                                    ...couponData,
                                                    restrictions: {
                                                        ...couponData.restrictions,
                                                        forClients: e.target.checked,
                                                    }
                                                });
                                            }} />
                                        <label class="form-check-label ms-2 text-black" for="flexCheckDefault" >
                                            Only for new clients
                                        </label>
                                    </div>
                                    <div className="mt-2">
                                        <span>Plan restrictions</span>
                                        <select class="form-select py-2 p-2" style={{ backgroundColor: '#f2f2f2' }} onChange={handlePlanChange}
                                            value={couponData.plan_restrictions.length > 0 ? couponData.plan_restrictions[couponData.plan_restrictions.length - 1].plan_id : ""} >
                                            <option selected className="d-none">Select Plan</option>
                                            {plans &&
                                                plans?.map((plan) => {
                                                    return (
                                                        <option key={plan._id} value={plan._id}> {plan.PublicPlanName} </option>
                                                    )
                                                })
                                            }
                                        </select>

                                        <div className="d-flex align-items-center flex-wrap">
                                            {couponData?.plan_restrictions?.map((plan) => {
                                                return (
                                                    <div key={plan.plan_id} className="p-2 mt-3 me-2 text-white d-flex align-items-center" style={{ borderRadius: '4px', backgroundColor: '#48AF25', width: 'fit-content' }}>
                                                        <span>{plan.plan_name}</span>
                                                        <svg className="ms-2" role="button" onClick={() => removePlan(plan.plan_id)} xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                            <path d="M5.24142 5.49786L9.18063 1.55481C9.25012 1.48497 9.2891 1.39044 9.28904 1.29191C9.28897 1.19339 9.24986 1.09891 9.18028 1.02916C9.04099 0.890577 8.79566 0.889877 8.65498 1.02986L4.71683 4.97291L0.777276 1.02881C0.63729 0.890577 0.391965 0.891277 0.252679 1.02951C0.218106 1.06395 0.190738 1.10492 0.172172 1.15005C0.153605 1.19517 0.144212 1.24354 0.14454 1.29234C0.14454 1.39173 0.183036 1.48482 0.252679 1.55376L4.19188 5.49751L0.253029 9.44161C0.18352 9.51156 0.144595 9.60622 0.144792 9.70483C0.144989 9.80344 0.184292 9.89794 0.254079 9.96761C0.321622 10.0345 0.417162 10.0729 0.515852 10.0729H0.517952C0.616992 10.0726 0.712532 10.0338 0.778675 9.96621L4.71683 6.02316L8.65638 9.96726C8.72602 10.0366 8.81911 10.075 8.9178 10.075C8.9666 10.0752 9.01495 10.0657 9.06006 10.0471C9.10517 10.0285 9.14616 10.0011 9.18067 9.9666C9.21518 9.93209 9.24252 9.89111 9.26113 9.84599C9.27974 9.80088 9.28925 9.75253 9.28911 9.70373C9.28911 9.60469 9.25062 9.51125 9.18063 9.44231L5.24142 5.49786Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                    {isTeamPlanSelected && (
                                        <div className="mt-2">
                                            <span>Team plan user Range</span>
                                            <div className="d-flex align-items-center">
                                                <div className="w-50 d-flex align-items-center">
                                                    <span>Min users</span>
                                                    <input className="text-center w-50 ms-2 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="minUsers"
                                                        value={couponData.teamPlanUsage.minUsers}
                                                        onChange={(e) => {
                                                            setCouponData({
                                                                ...couponData,
                                                                teamPlanUsage: {
                                                                    ...couponData.teamPlanUsage,
                                                                    minUsers: e.target.value,
                                                                }
                                                            });
                                                        }} />
                                                </div>
                                                <div className="w-50 d-flex align-items-center">
                                                    <span>Max users</span>
                                                    <input className="text-center w-50 ms-2 border-0 p-2 coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="maxUsers"
                                                        value={couponData.teamPlanUsage.maxUsers}
                                                        onChange={(e) => {
                                                            setCouponData({
                                                                ...couponData,
                                                                teamPlanUsage: {
                                                                    ...couponData.teamPlanUsage,
                                                                    maxUsers: e.target.value,
                                                                }
                                                            });
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                                <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                    <h5 className="border-bottom pb-2" >Shipping</h5>
                                    <div class="form-check d-flex align-items-center">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault" name="freeShipping"
                                            value={couponData.freeShipping}
                                            checked={couponData.freeShipping}
                                            onChange={(e) => {
                                                setCouponData({
                                                    ...couponData,
                                                    freeShipping: e.target.checked
                                                });
                                            }} />
                                        <label class="form-check-label ms-2 text-black" for="flexCheckDefault" >
                                            Free shipping
                                        </label>
                                    </div>
                                    {couponData?.freeShipping && <>
                                        <div className="mt-2 d-flex align-items-center">
                                            <span className="">Order minimum</span>
                                            <input className="w-25 ms-2 border-0 p-2 text-center coupon-number-input" type="number" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="minimumOrderPrice"
                                                value={couponData.minimumOrderPrice}
                                                onChange={handleInputChange} />
                                        </div>
                                        <div className="mt-2">
                                            <span>Free shipping country restriction</span>
                                            <Select
                                                className="form-select py-2 p-0"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: '#f2f2f2',
                                                    }),
                                                    valueContainer: (provided) => ({
                                                        ...provided,
                                                        padding: '8px',
                                                    }),
                                                    input: (provided) => ({
                                                        ...provided,
                                                        margin: 0,
                                                        padding: 0,
                                                    }),
                                                }}

                                                defaultValue={null}
                                                isSearchable={true}
                                                name="country"
                                                options={countryOptions}
                                                onChange={handleCountryChange}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                            {couponData?.freeShipCountries?.map((country) => {
                                                return (
                                                    <div className="p-2 mt-2 me-2 text-white d-flex align-items-center" style={{ borderRadius: '4px', backgroundColor: '#48AF25', width: 'fit-content' }}>
                                                        <span>{country.countryName}</span>
                                                        <svg className="ms-2" role="button" onClick={() => removeCountry(country.countryCode)} xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                                            <path d="M5.24142 5.49786L9.18063 1.55481C9.25012 1.48497 9.2891 1.39044 9.28904 1.29191C9.28897 1.19339 9.24986 1.09891 9.18028 1.02916C9.04099 0.890577 8.79566 0.889877 8.65498 1.02986L4.71683 4.97291L0.777276 1.02881C0.63729 0.890577 0.391965 0.891277 0.252679 1.02951C0.218106 1.06395 0.190738 1.10492 0.172172 1.15005C0.153605 1.19517 0.144212 1.24354 0.14454 1.29234C0.14454 1.39173 0.183036 1.48482 0.252679 1.55376L4.19188 5.49751L0.253029 9.44161C0.18352 9.51156 0.144595 9.60622 0.144792 9.70483C0.144989 9.80344 0.184292 9.89794 0.254079 9.96761C0.321622 10.0345 0.417162 10.0729 0.515852 10.0729H0.517952C0.616992 10.0726 0.712532 10.0338 0.778675 9.96621L4.71683 6.02316L8.65638 9.96726C8.72602 10.0366 8.81911 10.075 8.9178 10.075C8.9666 10.0752 9.01495 10.0657 9.06006 10.0471C9.10517 10.0285 9.14616 10.0011 9.18067 9.9666C9.21518 9.93209 9.24252 9.89111 9.26113 9.84599C9.27974 9.80088 9.28925 9.75253 9.28911 9.70373C9.28911 9.60469 9.25062 9.51125 9.18063 9.44231L5.24142 5.49786Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </>
                                    }
                                </div>
                                <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                    <h5>Activity log</h5>
                                    <textarea rows={4} className="w-100 border-0 p-2" type="text" readOnly style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="activityLog" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Modal
                open={openPlanModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Plans
                        </h2>
                        <table class="table mt-4 product_table">
                            <thead>
                                <tr>
                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                    </th>
                                    <th scope="col" className='border-0'>Type</th>
                                    <th scope="col" className='border-0'>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {plans?.map((plan, i) => {
                                    if (plan?.status !== "Published" || plan?.Visibility !== "Public") return;

                                    const isSelected = selectedPlans.includes(plan._id);

                                    const rowStyle = {
                                        backgroundColor: isSelected ? '#fdeee9' : '',
                                        borderTop: isSelected ? '1px solid #e65925' : 'none',
                                        borderBottom: isSelected ? '1px solid #e65925' : 'none',
                                    };
                                    return (
                                        <tr key={i} style={rowStyle} onClick={() => togglePlanSelection(plan._id)}>
                                            <td className="w-50" style={{ ...rowStyle, borderLeft: isSelected ? '1px solid #e65925' : 'none', textAlign: 'left' }}>{plan?.PublicPlanName}</td>
                                            <td style={rowStyle}>{plan?.planType}</td>
                                            <td style={{ ...rowStyle, borderRight: isSelected ? '1px solid #e65925' : 'none', }} className={`status-cell ${getStatusColorClass(plan?.status)}`}>{plan?.status}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-end">
                            <button
                                className="Modal-btn plan-cancel-modal-btn w-25"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                            <button
                                className="Modal-btn first-modal-btn w-25"
                                onClick={handleUpdateClick}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openProductModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Smart Accessories </h2>
                        <table class="table mt-4 product_table">
                            <thead>
                                <tr>
                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} ></th>
                                    <th scope="col" className='border-0'>category</th>
                                    <th scope="col" className='border-0'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products?.map((product, i) => {
                                    if (product?.status !== "Published" || product?.visibility !== "Public") return;
                                    const isSelected = selectedProducts.includes(product._id);
                                    const rowStyle = {
                                        backgroundColor: isSelected ? '#fdeee9' : '',
                                        borderTop: isSelected ? '1px solid #e65925' : 'none',
                                        borderBottom: isSelected ? '1px solid #e65925' : 'none',
                                    };
                                    return (
                                        <tr key={i} style={rowStyle} onClick={() => toggleProductSelection(product._id)}>
                                            <td className="w-50" style={{ ...rowStyle, borderLeft: isSelected ? '1px solid #e65925' : 'none', textAlign: 'left' }}>{product?.name}</td>
                                            <td style={rowStyle}>
                                                <span>{product?.category?.name}</span>
                                            </td>
                                            <td style={{ ...rowStyle, borderRight: isSelected ? '1px solid #e65925' : 'none', }} className={`status-cell ${getStatusColorClass(product?.status)}`}>{product?.status}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-end">
                            <button
                                className="Modal-btn plan-cancel-modal-btn w-25"
                                onClick={handleCancelProductsClick}
                            >
                                Cancel
                            </button>
                            <button
                                className="Modal-btn first-modal-btn w-25"
                                onClick={handleUpdateProductsClick}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openAddOnModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="Modal-section">
                        <h2 className="header"> Add-ons
                        </h2>
                        <table class="table mt-4 product_table">
                            <thead>
                                <tr>
                                    <th scope="col" className='w-25 border-0' style={{ textAlign: 'left' }} >
                                    </th>
                                    <th scope="col" className='border-0'>Monthly price</th>
                                    <th scope="col" className='border-0'>Yearly price</th>
                                    <th scope="col" className='border-0'>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {allAddons?.map((addOn, i) => {
                                    if (addOn?.status !== "Published" || addOn?.visibility !== "Public") return;
                                    const isSelected = selectedAddons.includes(addOn._id);
                                    const rowStyle = {
                                        backgroundColor: isSelected ? '#fdeee9' : '',
                                        borderTop: isSelected ? '1px solid #e65925' : 'none',
                                        borderBottom: isSelected ? '1px solid #e65925' : 'none',
                                    };
                                    return (
                                        <tr key={i} style={rowStyle} onClick={() => toggleAddonSelection(addOn._id)}>
                                            <td className="w-50" style={{ ...rowStyle, borderLeft: isSelected ? '1px solid #e65925' : 'none', textAlign: 'left' }}>{addOn?.publicname}</td>
                                            <td style={rowStyle}>{addOn?.Addonspaymentdata?.price || addOn?.Addonspaymentdata?.monthlyprice || ''}</td>
                                            <td style={rowStyle}>{addOn?.Addonspaymentdata?.yearlyprice ? addOn?.Addonspaymentdata?.yearlyprice : ''}</td>
                                            <td style={{ ...rowStyle, borderRight: isSelected ? '1px solid #e65925' : 'none', }} className={`status-cell ${getStatusColorClass(addOn?.status)}`}>{addOn?.status}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className="Modal-btn-group justify-content-end">
                            <button
                                className="Modal-btn plan-cancel-modal-btn w-25"
                                onClick={handleCancelAddonsClick}
                            >
                                Cancel
                            </button>
                            <button
                                className="Modal-btn first-modal-btn w-25"
                                onClick={handleUpdateAddonsClick}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>


            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={warningModalStyle}>
                    <div className="video-Modal-section">
                        <h2 className="warning-header">
                            Warning
                        </h2>
                        <span className="warning-text-smart-acc">Are you sure you want to Archive this Coupon?</span>


                        <div className="video-Modal-btn-group mt-4" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={handleCloseWarningdModal}>Archive</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
                        </div>

                    </div>
                </Box>
            </Modal>
        </>
    )
}
