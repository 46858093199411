import React, { useState } from "react";
import Otc_OrdersHeadersWithIcons from "./Otc_OrdersHeadersWithIcons";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import Otc_OrdersoverviewPage from "./Otc_OrdersoverviewPage";

const Otc_Orders = () => {
  const [OtcOrdersTab, setOtcOrdersTab] = useState("Orders");
  const getTabValue = (tabVal) => {
    setOtcOrdersTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setOtcOrdersTab(value);
  };
  return (
    <div>
      <div className="otc_onetap_otcorders_profile_syn">
        <Otc_OrdersHeadersWithIcons onChange={getTabValue} tab={OtcOrdersTab} />
        {OtcOrdersTab === "Orders" && (
          <Otc_OrdersoverviewPage onClickSave={getValueOfSaveAndContinueTab} />
        )}
      </div>
    </div>
  );
};

const Order_Dashboard = () => {
  return (
    <>
      <Otc_Sidebar Component={Otc_Orders} />
    </>
  );
};

export default Order_Dashboard;
