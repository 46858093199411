import React, { useEffect, useState } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Signup.scss";
import GoogleAuth from "../Google/GoogleAuth";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../assets/icons/logo_black.svg";
import axios from "axios";
import open_eye from "../../../assets/icons/eye.svg";
import close_eye from "../../../assets/icons/eye-off.svg";

const SignUp = () => {
  const ErrorMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#FDE9E9',
    color: "#ea4e4e",
    borderLeft: '5px solid'
  };
  const successMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#F0FEEB',
    color: "#48AF25",
    borderLeft: '5px solid'
  };
  const errorIconstyle = {
    position: 'absolute',
    top: '-8px',
    right: '12px',
    width: '25px',
  }
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="Error"><circle cx="32" cy="32" r="28" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></circle><line x1="32" x2="32" y1="18" y2="38" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line><line x1="32" x2="32" y1="42" y2="46" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line></svg>
  const successIcon = <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99906 16.4209L0.789062 10.2109L3.61906 7.38086L6.99906 10.7709L16.8791 0.880859L19.7091 3.71086L6.99906 16.4209Z" fill="#48AF25" />
  </svg>
  const loginInputsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
  };
  const { token } = useParams();
  const url = process.env.REACT_APP_URL;
  const location = useLocation()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("password");
  const [typeImg, setTypeImg] = useState(open_eye);
  const [verifyType, setVerifyType] = useState("password");
  const [verifyTypeImg, setVerifyTypeImg] = useState(open_eye);
  const [InvitedUserData, setInvitedUserData] = useState([]);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [chekMailMsg, setCheckMailMsg] = useState("");
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedPlan = location?.state?.selectedPlan;



  const selecPlan = selectedPlan ? selectedPlan : null


  useEffect(() => {
    if (selecPlan) {
      localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
    }

  }, [selectedPlan]);

  const { user, loading, message, error } = useSelector((state) => state.user);

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,4}$/;

  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;


  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(value);
  };

  const signupHandler = async (e) => {

    e.preventDefault();
    if (!email) {
      setEmailError("Please enter your email.");
      return;
    }
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    if (!checked) {
      setCheckError("Please accept terms and conditions.");
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await axios.post(
        `${url}/register`,
        { email },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        setCheckMailMsg("Please check your inbox! To activate your account, you must verify your email address.");
      }
    } catch (error) {
      setEmailError(error.response.data.message);
    }

    setIsLoading(false);

    setCheckError("");
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
      }, 2000);
      dispatch({ type: "CLEAR_ERROR" });
    }
    if (message) {
      dispatch({ type: "CLEAR_MESSAGE" });
    }
    setCheckError("");
  }, [error, message, dispatch, navigate]);
  const handleShowPassword = () => {
    if (type === "password") {
      setType("text");
      setTypeImg(close_eye);
    } else {
      setType("password");
      setTypeImg(open_eye);
    }
  };

  const handleShowVerifyPassword = () => {
    if (verifyType === "password") {
      setVerifyType("text");
      setVerifyTypeImg(close_eye);
    } else {
      setVerifyType("password");
      setVerifyTypeImg(open_eye);
    }
  };
  const fetchInvitedUserData = async () => {
    if (token) {
      try {
        const { data } = await axios.post(
          `${url}/invited/user`,
          { token },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (data.success) {
          setInvitedUserData(data.userData);
        }
      } catch (error) {

      }
    }
  };
  useEffect(() => {
    fetchInvitedUserData();
  }, []);

  const handleInvitedUser = async (e) => {
    e.preventDefault();
    if (!password) {
      setPasswordError("Please enter your password.");
      return;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      return;
    } else if (!passwordRegex.test(password)) {
      return setPasswordError(
        "Must contain at least one uppercase, lowercase letter, one special character, and one number"
      );
    } else if (password !== confirmPassword) {
      return setConfirmPasswordError("Your passwort doesn't match.");
    } else {
      try {
        const { data } = await axios.post(
          `${url}/invited/register-user`,
          { InvitedUserData },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (data.success) {
          navigate("/login");
        }
      } catch (error) {
        setEmailError(error.response.data.message);
        setConfirmPasswordError("Invalid invitation.")
      }
    }
  };

  return (
    <div className="login_password_container">
      <div className="login_password_left">
        <div className="login_header">
          <Link to="/">
            {" "}
            <img src={logo} alt="oneTap Connect logo" srcset="" />
          </Link>
        </div>
        <div className="login_password_information">
          <h1>Create Your Account</h1>
          <span>
            Already have an account? <Link to="/login">Login here</Link>
          </span>
          <span className="google_button">
            {InvitedUserData != null ? (
              <GoogleAuth data={InvitedUserData} />
            ) : (
              <GoogleAuth />
            )}
          </span>
          <div className="line">
            <span></span>OR<span></span>
          </div>
          {token ? (
            <form onSubmit={handleInvitedUser}>
              <div className="login_inputs">
                <input
                  type={type}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  autoComplete="off"
                  autoFocus
                />
                <img
                  src={typeImg}
                  alt="Show"
                  srcset=""
                  className="login_eye"
                  onClick={handleShowPassword}
                />
                <span className="login_error">{passwordError}</span>
              </div>
              <div className="login_inputs">
                <input
                  type={verifyType}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setInvitedUserData({
                      ...InvitedUserData,
                      password: e.target.value,
                    });
                    setConfirmPasswordError("");
                  }}
                  autoComplete="off"
                />
                <img
                  src={verifyTypeImg}
                  alt="Show"
                  srcset=""
                  className="login_eye"
                  onClick={handleShowVerifyPassword}
                />
                <span className="login_error">{confirmPasswordError}</span>
              </div>
              {(confirmPasswordError || passwordError) &&
                <div style={loginInputsStyle} className="login_inputs">
                  <div style={ErrorMessageDiv}>{passwordError ? passwordError : confirmPasswordError}</div>
                  <span style={errorIconstyle} className="">{errorIcon}</span>
                </div>}
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Loading..." : "Register"}
              </button>

              {chekMailMsg &&
                <div style={loginInputsStyle} className="login_inputs">
                  <div style={successMessageDiv}>{chekMailMsg}</div>
                  <span style={errorIconstyle} className="">{successIcon}</span>
                </div>}
            </form>
          ) : (
            <form onSubmit={signupHandler}>
              <div className="login_inputs">
                <input
                  type="text"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    setEmailError("");
                    setCheckMailMsg("");
                  }}
                  autoComplete="off"
                  autoFocus
                />

              </div>
              {(emailError) &&
                <div style={loginInputsStyle} className="login_inputs">
                  <div style={ErrorMessageDiv}>{emailError}</div>
                  <span style={errorIconstyle} className="">{errorIcon}</span>
                </div>}

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexChecksignup"
                  value={checked}
                  name="flexChecksignup"
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    if (!checked) {
                      setCheckError("");
                      setCheckMailMsg("");
                    } else {
                      return;
                    }
                  }}
                />
                <label class="form-check-label" for="flexChecksignup">
                  {" "}
                  Your privacy in important to us. By creating an account, you
                  agree to our{" "}
                  <span>
                    <Link
                      to="https://onetapconnect.com/terms-of-use"
                      style={{ color: "#2572E6" }}
                      target="_blank"
                    >
                      {" "}
                      terms of service
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    {" "}
                    <Link
                      to="https://onetapconnect.com/privacy-policy"
                      style={{ color: "#2572E6" }}
                      target="_blank"
                    >
                      privacy policy.
                    </Link>{" "}
                  </span>{" "}
                  The information you provide to us will be use to contact you
                  about your account, relevant content, products, and services.
                </label>
              </div>

              {(checkError) &&
                <div style={loginInputsStyle} className="login_inputs">
                  <div style={ErrorMessageDiv}>{checkError}</div>
                  <span style={errorIconstyle} className="">{errorIcon}</span>
                </div>}

              <button type="submit" disabled={isLoading}>
                {isLoading ? "Loading..." : "Verify my email"}
              </button>

              {chekMailMsg &&
                <div style={loginInputsStyle} className="login_inputs">
                  <div style={successMessageDiv}>{chekMailMsg}</div>
                  <span style={errorIconstyle} className="">{successIcon}</span>
                </div>}
            </form>
          )}
        </div>
      </div>
      <div className="login_password_right">
        <h3>Meet Kimba</h3>
        <img src={phoneImg} alt="phoneImg" />
      </div>
    </div>
  );
};

export default SignUp;
