import React, { useEffect, useRef, useState } from 'react'
import { Companygeneralinfo } from '../../components/Customers/CompanyProfile/companyProfileGeneralInfoTab';
import GenInfoToggleButton from "../../components/Customers/CompanyProfile/EditCompany/GenInfoToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { Country, State } from "country-state-city";
import { ToastContainer, toast } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import { styled } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import axios from "axios";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { OtcUploadProfilePicture, updateAdminUserInformation } from '../Redux/Otc_UserActions';
import Loader from '../../components/Layout/Loader/Loader';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
};
const messageIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
>
    <path
        d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
        fill="#E65925"
    />
    <path
        d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
        stroke="white"
        stroke-width="6.64"
        stroke-linecap="round"
        class="checkmark-path"
    />
</svg>
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    p: 2,
    outline: "none",
    borderRadius: "10px",
};
const cropingstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    backgroundColor: "white",
    padding: "16px",
    outline: "none",
    borderRadius: "10px",
};
const delete_account_modalStyle = {
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.4)",
};

const delete_account_modalContentStyle = {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "32%",
    borderRadius: "10px",
    textAlign: "center",
};

const deleteAccountbuttonStyle = {
    margin: "5px",
    width: "50%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#e65925",
    border: "#e65925",
    color: "#fff",
};
const deleteAccountcanclebuttonStyle = {
    margin: "5px",
    width: "50%",
    padding: "9px 47px",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "#e65925",
    color: "#e65925",
    border: "1px solid #e65925",
};
const delete_account_iconContainer = {
    textAlign: "center",
};
const delete_account_buttonContainer = {
    display: "flex",
    justifyContent: "space-around",
};
const delete_accountText = {
    marginBottom: "20px",
    textAlign: "center",
    fontSize: "20px",
};
const delete_account_trashIconStyle = {
    color: "#e65925",
    marginBottom: "20px",
    marginTop: "20px",
};

const UserProfileGeneral = (props) => {
    const url = process.env.REACT_APP_URL;
    const [teamMemberDetails, setTeamMemberDetails] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [newJobTitle, setNewJobTitle] = useState("");
    const [imageURL, setImageURL] = useState(null);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [addressPermission, setAddressPermission] = useState(false);
    const [apartmentPermission, setApartmentPermission] = useState(false);
    const [cityPermission, setCityPermission] = useState(false);
    const [statePermission, setStatePermission] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setcroppedImage] = useState(null);
    const [openCropModal, setOpenCropModal] = useState(false);
    const [isdeletemodalopen, setisdeletemodalopen] = useState(false);
    const [selectedDp, setSelectedDp] = useState(false);
    const [postalCodePermission, setPostalCodePermission] = useState(false);
    const [profilePictureerrormsj, setprofilePictureerrormsj] = useState("");
    const [emailError, setEmailError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");
    const [keywordsError, setKeywordsError] = useState("");
    const [contactError, setContactError] = useState("");
    const [postalCodeError, setPostalCodeError] = useState("");
    const [firstnameError, setfirstnameError] = useState("");
    const [designationErrors, setDesignationErrors] = useState("");
    const [lastnameError, setlastnameError] = useState("");
    const [urlError, setUrlError] = useState('');
    const cropperRef = useRef(null);
    const [userAllData, setUserAllData] = useState("")
    const [userData, setUserData] = useState("")
    const [companyData, setCompanyData] = useState("")
    const [apiSuccess, setApiSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const fetchUser = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/user/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });
            setUserAllData(data?.user[0])
            setUserData(data?.user[0]?.user_id)
            setCompanyData(data.user[0].company_ID)
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchUser();
    }, []);
    const { company, error, message } = useSelector((state) => state.Company);
    const { userInfo } = useSelector((state) => state.userInformation);

    useEffect(() => {
        if (userAllData) {
            if (
                userAllData?.primary_office_num === "" ||
                !userAllData?.primary_office_num ||
                userAllData?.keywords === "" ||
                !userAllData?.keywords
            ) {
                setUserAllData({
                    ...userAllData,
                    primary_office_num: companyData?.contact,
                    keywords: companyData?.keywords,
                });
            }
        }
    }, []);
    useEffect(() => {
        if (userData) {
            if (
                userData?.personlize_company_name === "" ||
                userData?.businessemail === ""
            ) {
                setUserData({
                    ...userData,
                    personlize_company_name: companyData?.company_name,
                    businessemail: companyData?.global_email,
                });
            }
        }
    }, []);

    const handleToggleAddressPermission = () => {
        const updatedPermission = !addressPermission;
        setAddressPermission(updatedPermission);
        setUserData((prevDetails) => ({
            ...prevDetails,
            user_line1_address_permission: !addressPermission,
        }));
    };

    const handleToggleApartmentPermission = () => {
        const updatedPermission = !apartmentPermission;
        setApartmentPermission(updatedPermission);
        setUserData((prevDetails) => ({
            ...prevDetails,
            user_line2_apartment_permission: !apartmentPermission,
        }));
    };

    const handleToggleCityPermission = () => {
        const updatedPermission = !cityPermission;
        setCityPermission(updatedPermission);
        setUserData((prevDetails) => ({
            ...prevDetails,
            user_city_permission: !cityPermission,
        }));
    };

    const handleToggleStatePermission = () => {
        const updatedPermission = !statePermission;
        setStatePermission(updatedPermission);
        setUserData((prevDetails) => ({
            ...prevDetails,
            user_state_permission: !statePermission,
        }));
    };
    const handleTogglePostalCodePermission = () => {
        const updatedPermission = !postalCodePermission;
        setPostalCodePermission(updatedPermission);
        setUserData((prevDetails) => ({
            ...prevDetails,
            user_postal_code_permission: !postalCodePermission,
        }));
    };
    useEffect(() => {
        if (userData) {
            setAddressPermission(
                userData?.user_line1_address_permission || false
            );
            setApartmentPermission(
                userData?.user_line2_apartment_permission || false
            );
            setCityPermission(userData?.user_city_permission || false);
            setStatePermission(userData?.user_state_permission || false);
            setPostalCodePermission(
                userData?.user_postal_code_permission || false
            );
        }
    }, [userData]);
    const allowedImageTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/svg+xml",
        "image/jpg",
        "image/avif",
    ];
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!allowedImageTypes.includes(file.type)) {
                return;
            }
            setSelectedDp(file);
            setOpenCropModal(true);
        }
    };
    useEffect(() => {
        if (openCropModal && selectedDp) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = () => {
                    cropperRef.current.src = e.target.result;
                    const cropper = new Cropper(cropperRef.current, {
                        dragMode: "move",
                        aspectRatio: 1 / 1,
                        viewMode: 1,
                    });
                    cropperRef.current.cropper = cropper;
                };
            };
            reader.readAsDataURL(selectedDp);
            setSelectedFile(selectedDp);
        }
    }, [openCropModal, selectedDp]);

    const handleCrop = async () => {
        if (cropperRef.current) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            const originalImageSrc = cropperRef.current.cropper.getImageData()?.src || "";
            const croppedImage = croppedCanvas.toDataURL(
                originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
            );
            const cropper = cropperRef.current.cropper;
            await cropper.destroy();
            setcroppedImage(croppedImage);
            setOpenCropModal(false);
        }
    };
    const handleimagedelete = async () => {
        try {
            if (userData?.avatar) {
                const response = await fetch(
                    `${url}/deleteProfileImage/${userData.avatar}`,
                    {
                        method: "DELETE",
                    }
                );

                if (response.ok) {
                    setUserData((prevDetails) => ({
                        ...prevDetails,
                        avatar: "",
                    }));
                    setisdeletemodalopen(false);
                    fetchUser()
                } else {
                }
            } else {
            }
        } catch (error) {
        }
    };

    const handleuploadorignal = () => {
        if (cropperRef.current) {
            const getorignalimages = cropperRef.current.cropper.reset();
            const getorignal = getorignalimages.clear();
            const getorignalimage = getorignal.getCroppedCanvas();
            const originalImageSrc = cropperRef.current.cropper.getImageData()?.src || "";
            const croppedImage = getorignalimage.toDataURL(
                originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
            );
            setcroppedImage(croppedImage);
            setOpenCropModal(false);
        }
    };
    const handlezoominlogo = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.zoom(0.1);
        }
    };
    const handleswapupdown = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const scaleY = cropper.getData().scaleY;
            cropper.scaleY(-scaleY);
        }
    };

    const handleswaprightleft = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const scaleX = cropper.getData().scaleX;
            cropper.scaleX(-scaleX);
        }
    };
    const handlezoomoutlogo = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.zoom(-0.1);
        }
    };
    const handlerotate = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.rotate(-90);
        }
    };
    const handlerotateback = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.rotate(90);
        }
    };
    const handlelogoup = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.move(0, -10);
        }
    };
    const handlelogodown = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.move(0, 10);
        }
    };
    const handlelogoleft = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.move(-10, 0);
        }
    };
    const handlelogoright = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.move(10, 0);
        }
    };
    const handleresetlogo = () => {
        if (cropperRef.current) {
            cropperRef.current.cropper.reset();
        }
    };

    const handleUploaderClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveImage = () => {
        setSelectedDp(null);
        setSelectedFile(null);
        setprofilePictureerrormsj(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };
    const handlecropmodalclose = () => {
        setcroppedImage(null);
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setOpenCropModal(false);
    };

    const handleAddressChange = async (propertyName, value) => {
        setPostalCodeError("");
        const postalCode = userData?.address?.postal_code;
        const state = State?.getStateByCodeAndCountry(
            userData?.address?.state,
            userData?.address?.country
        )?.name;
        const apiUrl = `https://api.zippopotam.us/${userData?.address?.country}/${postalCode.trim()}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (response.ok !== true) {
            setPostalCodeError("Please enter a valid postal code.");
            return
        } else if (data.places[0].state !== state) {
            setPostalCodeError(`Please enter a valid postal code for ${state}`);
            return
        }
    };
    const handleAddressField = (propertyName, value) => {
        setPostalCodeError("");
        setUserData((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails?.address,
                [propertyName]: value,
            },
        }));
    };

    const handleuserinfoInputField = (e) => {
        const { name, value } = e.target;
        const errors = {
            contactError: "",
            keywordsError: "",
            urlError: "",
        };
        if (name === "keywords" && value === "" && (company?.primary_activities_permission === true)) {
            errors.keywordsError = "Primary Activities / Contact Keyword(s) is required.";
        } else {
            setKeywordsError(null);
        }
        if (name === "primary_office_num" && value === "" && (company?.primary_office_number_permission === true)) {
            errors.contactError = "Office Number is required.";
        } else {
            setContactError("");
        }

        if (name === 'website_url') {
            const urlPattern = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/;
            if (value === "") {
                errors.urlError = null;
            } else if (!urlPattern.test(value)) {
                errors.urlError = 'Please enter valid URL.';
            } else {
                setUrlError('');
            }
        }

        const hasErrors = Object.values(errors).some((error) => error !== "");
        setUserAllData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (hasErrors) {
            setKeywordsError(errors.keywordsError);
            setContactError(errors.contactError);
            setUrlError(errors.urlError);
            return;
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (value !== "" || name === "contact") {
        }
    };
    const handleInputField = (e) => {
        const { name, value } = e.target;
        const errors = {
            firstnameError: "",
            lastnameError: "",
            companyNameError: "",
            emailError: "",
        };

        if (userInfo?.subscription_details?.plan === "Team") {
            if (name === "personlize_company_name" && value === "" && (company?.company_name_permission === true)) {
                errors.companyNameError = "Company name is required.";
            } else {
                setCompanyNameError(null);
            }
        }
        if (name === "first_name" && value === "") {
            errors.firstnameError = "First name is required.";
        } else {
            setfirstnameError(null);
        }
        if (name === "last_name" && value === "") {
            errors.lastnameError = "Last name is required.";
        } else {
            setlastnameError(null);
        }
        if (name === "businessemail" && value === "" && (company?.global_email_address_permission === true)) {
            errors.emailError = "Business email is required.";
        } else {
            setEmailError(null);
        }

        const hasErrors = Object.values(errors).some((error) => error !== "");

        setUserData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        if (hasErrors) {
            setEmailError(errors.emailError);
            setCompanyNameError(errors.companyNameError);
            setlastnameError(errors.lastnameError);
            setfirstnameError(errors.firstnameError);
            return;
        }
    };

    const [countryValue, setCountryValue] = useState(null);
    const [stateValue, setStateValue] = useState("");
    const handleCountryChange = (value) => {
        setCountryValue(value);
        setStateValue("");
        setPostalCodeError('')
        setUserData((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                country: value,
                postal_code: ''
            },
        }));
    };

    const handleStateChange = (value) => {
        setStateValue(value);
        setPostalCodeError('')
        setUserData((prevDetails) => ({
            ...prevDetails,
            address: {
                ...prevDetails.address,
                state: value,
                postal_code: ''
            },
        }));
    };
    function handleNumericInput(event) {
        event.target.value = event.target.value.replace(/[^\d+]/g, "");
    }
    const handleSave = async (e) => {
        const errors = {
            lastnameError: "",
            firstnameError: "",
            contactError: "",
            keywordsError: "",
            companyNameError: "",
            emailError: "",
            designationErrors: "",
            addressPostalCodeError: "",
        };
        if (userAllData?.subscription_details?.plan === "Team") {
            if (
                (userData?.personlize_company_name?.trim() === "" ||
                    !userData?.personlize_company_name) &&
                companyData?.company_name_permission === true
            ) {
                errors.companyNameError = "Company name is required.";
            }
            else {
                setCompanyNameError(null);
            }
        }
        if (!userData?.address?.postal_code) {
            errors.addressPostalCodeError = "Postal code is required.";
        }
        if (userData?.first_name?.trim() === "") {
            errors.firstnameError = "First name is required.";
        } else {
            setfirstnameError(null);
        }
        if (userData?.last_name?.trim() === "") {
            errors.lastnameError = "Last name is required.";
        } else {
            setlastnameError(null);
        }
        if (
            (userData?.businessemail?.trim() === "" ||
                !userData?.businessemail) &&
            companyData?.global_email_address_permission === true
        ) {
            errors.emailError = "Business email is required.";
        } else {
            setEmailError(null);
        }
        if (
            (userAllData?.keywords?.trim() === "" ||
                !userAllData?.keywords) &&
            companyData?.primary_activities_permission === true
        ) {
            errors.keywordsError =
                "Primary Activities / Contact Keyword(s) is required.";
        } else {
            setKeywordsError(null);
        }
        if (
            userAllData?.primary_office_num !== null &&
            (isNaN(userAllData?.primary_office_num) ||
                userAllData?.primary_office_num === "") &&
            companyData?.primary_office_number_permission === true
        ) {
            errors.contactError = "Office Number is required.";
        } else {
            setContactError("");
        }
        const trimmedDesignations = userData?.designation.map(
            (designation) => designation?.trim().toLowerCase()
        );
        trimmedDesignations.forEach((designation, index) => {
            if (!designation) {
                errors.designationErrors = "Please enter job title.";
            } else if (trimmedDesignations.indexOf(designation) !== index) {
                errors.designationErrors = "Job title already in use. Please select another.";
            }
        });

        if (errors.designationErrors.length > 0) {
            setDesignationErrors(errors.designationErrors);
            return;
        } else {
            setDesignationErrors("");
        }

        const hasErrors = Object.values(errors).some((error) => error !== "");
        if (hasErrors) {

            setEmailError(errors.emailError);
            setCompanyNameError(errors.companyNameError);
            setlastnameError(errors.lastnameError);
            setfirstnameError(errors.firstnameError);
            setKeywordsError(errors.keywordsError);
            setDesignationErrors(errors.designationErrors);
            setContactError(errors.contactError);
            setPostalCodeError(errors.addressPostalCodeError);
            return;
        }
        const postalCode = userData?.address?.postal_code;
        const state = State?.getStateByCodeAndCountry(
            userData?.address?.state,
            userData?.address?.country
        )?.name;

        const apiUrl = `https://api.zippopotam.us/${userData?.address?.country}/${postalCode.trim()}`;
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (response.ok !== true) {
            setPostalCodeError("Please enter a valid postal code.");
            return
        } else if (data.places[0].state !== state) {
            setPostalCodeError(`Please enter a valid postal code for ${state}`);
            return
        }
        if (e === "continue") {
            setTimeout(() => {
                props.onClickSave("About");
            }, 1000);
        }
        const trimmedDesignationss = userData?.designation?.map(
            (designation) => designation.trim()
        );

        let updatedTeamMemberDetails = {
            ...userData,
            designation: trimmedDesignationss,
        };

        if (selectedFile && croppedImage) {
            const formData = new FormData();
            formData.append("image", croppedImage);
            formData.append("imageType", "profile");
            const response = await dispatch(OtcUploadProfilePicture(id, formData));
            setSelectedFile(null);
            if (response.error) {
            } else if (response.success) {
                const avtarpath = response.imagePath;
                updatedTeamMemberDetails = {
                    ...updatedTeamMemberDetails,
                    avatar: avtarpath,
                };
            }
        }

        const res = await axios.post(
            `${url}/admin/user/update/${id}`,
            { userDetails: updatedTeamMemberDetails },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );
        fetchUser()
        Promise.all([
            dispatch(updateAdminUserInformation(id, userAllData)),
        ])
            .then(() => {
                setApiSuccess(true)
                setSuccessMessage('Data Updated Successfully.')
                setTimeout(() => {
                    setApiSuccess(false)
                }, 5000);
            })
            .catch((error) => {
            });
    };

    const MAX_JOB_TITLES = 6;
    const designationlength = teamMemberDetails?.designation?.length;
    const [jobTitleCount, setJobTitleCount] = useState(designationlength || 0);

    const handleDesignationChange = (index, value) => {
        const updatedDesignations = [...userData?.designation];
        updatedDesignations[index] = value;
        setUserData((prevDetails) => ({
            ...prevDetails,
            designation: updatedDesignations,
        }));
    };
    const handleNewJobTitleChange = (e) => {
        setNewJobTitle(e.target.value);
        document.getElementById("err").innerHTML = null;
    };
    const handleAddJobTitle = () => {
        if (!newJobTitle) {
            document.getElementById("err").innerHTML = "Please enter job title.";
            return;
        }
        const isMatching = userData?.designation
            .map((designation) => designation.trim().toLowerCase())
            .includes(newJobTitle.trim().toLowerCase());
        if (isMatching) {
            document.getElementById("err").innerHTML =
                "Job title already in use. Please select another.";
            return;
        }
        if (newJobTitle.trim() !== "") {
            const updatedDesignations = [...userData.designation];

            if (updatedDesignations.length < MAX_JOB_TITLES) {
                updatedDesignations.push(newJobTitle);
                setUserData((prevDetails) => ({
                    ...prevDetails,
                    designation: updatedDesignations,
                }));
                setJobTitleCount(jobTitleCount + 1);
                setNewJobTitle("");
                setModalOpen(false);
            } else {
                document.getElementById("err").innerHTML =
                    "Maximum six job titles are allowed";
            }
        }
    };

    const handleRemoveJobTitle = (indexToRemove) => {
        const updatedDesignations = [...userData.designation];
        updatedDesignations?.splice(indexToRemove, 1);
        setUserData((prevDetails) => ({
            ...prevDetails,
            designation: updatedDesignations,
        }));
    };
    const disableicon = (
        <span>
            Not editable. Your company has locked this field, and it is displayed for
            informational purposes.
        </span>
    );
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#E65925",
            color: "#fff",
            fontSize: 13,
            maxWidth: "none",
            padding: "10px",
        },
    }));
    return (
        <>
            {!userAllData ? (<Loader />) : (<>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <div class="row mb-2">
                    <Companygeneralinfo />
                </div>
                <div className="row" style={{ gap: "20px" }}>
                    <div className="col p-0 mt-2">
                        <div className="onetap_conn_general_information_syn">
                            <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                                Contact Information
                            </h5>
                            <div>
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        First name*
                                    </p>
                                    <input
                                        className="onetap_conn_general_information_input_syn"
                                        type="text"
                                        placeholder=""
                                        name="first_name"
                                        value={userData?.first_name}
                                        onBlur={(e) => {
                                            setfirstnameError(null);
                                            handleInputChange(e);
                                        }}
                                        onChange={(e) => {
                                            setfirstnameError(null);
                                            handleInputField(e);
                                        }}
                                    />
                                </div>
                                {firstnameError && <p className="error">{firstnameError}</p>}
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Last name*
                                    </p>
                                    <input
                                        className="onetap_conn_general_information_input_syn"
                                        type="text"
                                        placeholder=""
                                        name="last_name"
                                        onBlur={(e) => {
                                            setlastnameError(null);
                                            handleInputChange(e);
                                        }}
                                        onChange={(e) => {
                                            setlastnameError(null);
                                            handleInputField(e);
                                        }}
                                        value={userData?.last_name}
                                    />
                                </div>
                                {lastnameError && <p className="error">{lastnameError}</p>}
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Company name*
                                    </p>
                                    {companyData?.company_name_permission === true ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            placeholder=""
                                            name="personlize_company_name"
                                            onBlur={(e) => {
                                                setCompanyNameError(null);
                                                handleInputChange(e);
                                            }}
                                            onChange={(e) => {
                                                setCompanyNameError(null);
                                                handleInputField(e);
                                            }}
                                            value={userData?.personlize_company_name}
                                        />
                                    ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            placeholder=""
                                            name="personlize_company_name"
                                            onBlur={(e) => {
                                                setCompanyNameError(null);
                                                handleInputChange(e);
                                            }}
                                            onChange={(e) => {
                                                setCompanyNameError(null);
                                                handleInputField(e);
                                            }}
                                            value={userData?.personlize_company_name}
                                        />
                                    ) : (
                                        <LightTooltip placement="top" title={disableicon}>
                                            <input
                                                className="onetap_conn_general_information_input_syn"
                                                style={{ opacity: "0.6" }}
                                                type="text"
                                                name="company_name"
                                                value={companyData?.company_name}
                                                readOnly
                                            />{" "}
                                        </LightTooltip>
                                    )}
                                </div>
                                {companyNameError && (
                                    <p className="error">{companyNameError}</p>
                                )}
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Business email*
                                    </p>
                                    {companyData?.global_email_address_permission === true ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="email"
                                            placeholder=""
                                            onBlur={(e) => {
                                                setEmailError(null);
                                                handleInputChange(e);
                                            }}
                                            onChange={(e) => {
                                                setEmailError(null);
                                                handleInputField(e);
                                            }}
                                            name="businessemail"
                                            value={userData?.businessemail}
                                        />
                                    ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="email"
                                            placeholder=""
                                            onBlur={(e) => {
                                                setEmailError(null);
                                                handleInputChange(e);
                                            }}
                                            onChange={(e) => {
                                                setEmailError(null);
                                                handleInputField(e);
                                            }}
                                            name="businessemail"
                                            value={userData?.businessemail}
                                        />
                                    ) : (
                                        <LightTooltip placement="top" title={disableicon}>
                                            <input
                                                className="onetap_conn_general_information_input_syn"
                                                type="email"
                                                placeholder=""
                                                readOnly
                                                style={{ opacity: "0.7" }}
                                                name="email"
                                                value={companyData?.global_email}
                                            />
                                        </LightTooltip>
                                    )}
                                </div>
                                {emailError ? <p className="error">{emailError}</p> : null}
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Office Number*
                                    </p>
                                    {companyData?.primary_office_number_permission === true ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="primary_office_num"
                                            placeholder=""
                                            value={userAllData?.primary_office_num}
                                            onBlur={(e) => {
                                                setContactError(null);
                                            }}
                                            onChange={(e) => {
                                                setContactError(null);
                                                handleuserinfoInputField(e);
                                            }}
                                            onInput={handleNumericInput}
                                            minLength={10}
                                            maxLength={20}
                                        />
                                    ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="primary_office_num"
                                            placeholder=""
                                            value={userAllData?.primary_office_num}
                                            onBlur={(e) => {
                                                setContactError(null);
                                            }}
                                            onChange={(e) => {
                                                setContactError(null);
                                                handleuserinfoInputField(e);
                                            }}
                                            onInput={handleNumericInput}
                                            minLength={10}
                                            maxLength={20}
                                        />
                                    ) : (
                                        <LightTooltip placement="top" title={disableicon}>
                                            <input
                                                className="onetap_conn_general_information_input_syn"
                                                type="text"
                                                name="officeNumber"
                                                placeholder=""
                                                value={companyData?.contact}
                                                style={{ opacity: "0.6" }}
                                                readOnly
                                                minLength={10}
                                                maxLength={20}
                                            />
                                        </LightTooltip>
                                    )}
                                </div>
                                {contactError ? <p className="error">{contactError}</p> : null}
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Cell phone number
                                    </p>
                                    <input
                                        className="onetap_conn_general_information_input_syn"
                                        type="text"
                                        placeholder=""
                                        id=""
                                        name="contact"
                                        onBlur={handleInputChange}
                                        onChange={handleInputField}
                                        onInput={handleNumericInput}
                                        value={userData?.contact}
                                        minLength={10}
                                        maxLength={20}
                                    />
                                </div>
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Website URL
                                    </p>
                                    {companyData?.website_url_permission === true ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="website_url"
                                            value={userAllData?.website_url}
                                            onChange={handleuserinfoInputField}
                                        />
                                    ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="website_url"
                                            value={userAllData?.website_url}
                                            onChange={handleuserinfoInputField}
                                        />
                                    ) : (
                                        <LightTooltip placement="top" title={disableicon}>
                                            <input
                                                className="onetap_conn_general_information_input_syn"
                                                type="text"
                                                name="websiteUrl"
                                                value={companyData?.website_url}
                                                readOnly
                                                style={{ opacity: "0.6" }}
                                            />
                                        </LightTooltip>
                                    )}
                                </div>
                                {urlError && <p className="error">{urlError}</p>}
                            </div>
                            <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                            <div>
                                <div className="mb-3">
                                    <p className="onetap_conn_general_information_para_syn mb-1">
                                        Primary Activities / Contact Keyword(s)*
                                    </p>
                                    {companyData?.primary_activities_permission === true ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="keywords"
                                            placeholder="Ex: Corporate Law, Real Estate Law"
                                            value={userAllData?.keywords}
                                            onBlur={(e) => {
                                                setKeywordsError(null);
                                            }}
                                            onChange={(e) => {
                                                setKeywordsError(null);
                                                handleuserinfoInputField(e);
                                            }}
                                        />
                                    ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                        <input
                                            className="onetap_conn_general_information_input_syn"
                                            type="text"
                                            name="keywords"
                                            placeholder="Ex: Corporate Law, Real Estate Law"
                                            value={userAllData?.keywords}
                                            onBlur={(e) => {
                                                setKeywordsError(null);
                                            }}
                                            onChange={(e) => {
                                                setKeywordsError(null);
                                                handleuserinfoInputField(e);
                                            }}
                                        />
                                    ) : (
                                        <LightTooltip placement="top" title={disableicon}>
                                            <input
                                                className="onetap_conn_general_information_input_syn"
                                                style={{ opacity: "0.6" }}
                                                type="text"
                                                name="keywords"
                                                placeholder="Ex: Corporate Law, Real Estate Law"
                                                value={companyData?.keywords}
                                                readOnly
                                            />{" "}
                                        </LightTooltip>
                                    )}
                                </div>
                                {keywordsError && <p className="error">{keywordsError}</p>}
                            </div>
                            <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                            <div>
                                <div className="mb-3">
                                    <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                                        Job Title
                                    </h5>
                                    <div>
                                        {userData?.designation?.map(
                                            (designation, index) => (
                                                <div className="d-flex align-items-center  mb-3">
                                                    <input
                                                        className="onetap_conn_general_information_input_syn"
                                                        type="text"
                                                        placeholder=""
                                                        name="designation"
                                                        value={userData?.designation?.[index]}
                                                        onChange={(e) => { handleDesignationChange(index, e.target.value); setDesignationErrors(""); }
                                                        }
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleRemoveJobTitle(index)}
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                                            fill="#8A8A8A"
                                                        />
                                                    </svg>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    {designationErrors && (
                                        <p className="error">{designationErrors}</p>
                                    )}
                                </div>
                                {designationlength === 6 ? (
                                    <button
                                        className="onetap_conn_general_information_input_syn_addanotherbtn"
                                        style={{ opacity: 0.5 }}
                                        disabled
                                    >
                                        <svg
                                            width="14"
                                            height="15"
                                            viewBox="0 0 14 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                                                fill="white"
                                            />
                                        </svg>{" "}
                                        &nbsp; Add another
                                    </button>
                                ) : (
                                    <button
                                        className="onetap_conn_general_information_input_syn_addanotherbtn"
                                        onClick={() => {
                                            setModalOpen(true);
                                        }}
                                    >
                                        <svg
                                            width="14"
                                            height="15"
                                            viewBox="0 0 14 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                                                fill="white"
                                            />
                                        </svg>{" "}
                                        &nbsp; Add another
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <div className="row" style={{ marginBottom: "20px" }}>
                            <div className="onetap_conn_general_information_syn">
                                <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                                    Profile picture
                                </h5>
                                <p className="onetap_conn_general_information_para_syn">
                                    Upload a high quality picture of yourself to show on your
                                    OneTapConnect card.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-center">
                                    <div className="image-uploader">
                                        {!selectedFile && !imageURL ? (
                                            <label
                                                htmlFor="upload-input"
                                                className="upload-box"
                                                onClick={handleUploaderClick}
                                            >
                                                <div className="image-preview">
                                                    {userData?.avatar ? (
                                                        <img
                                                            src={`${url}/profile/img/${userData?.avatar}`}
                                                            alt="Default"
                                                            style={{ borderRadius: "none", width: "90%" }}
                                                        />
                                                    ) : (
                                                        <span className="upload-icon">
                                                            <svg
                                                                width="46"
                                                                height="34"
                                                                viewBox="0 0 46 34"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                                                    fill="#838383"
                                                                />
                                                            </svg>{" "}
                                                            <br />
                                                            <p className="onetap_conn_general_information_para_syn m-0">
                                                                Upload
                                                            </p>
                                                        </span>
                                                    )}
                                                </div>
                                            </label>
                                        ) : (
                                            <>
                                                <label
                                                    htmlFor="upload-input"
                                                    className="upload-box"
                                                    onClick={() => {
                                                        setOpenCropModal(true);
                                                    }}
                                                >
                                                    <div className="image-preview">
                                                        {selectedFile && (
                                                            <img
                                                                src={
                                                                    croppedImage ||
                                                                    URL.createObjectURL(selectedFile)
                                                                }
                                                                alt="Uploaded"
                                                                style={{ borderRadius: "none", width: "90%" }}
                                                            />
                                                        )}{" "}
                                                    </div>
                                                </label>
                                                <span
                                                    className="remove-icon"
                                                    onClick={handleRemoveImage}
                                                >
                                                    &#10005;
                                                </span>{" "}
                                            </>
                                        )}

                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            accept="image/*"
                                            name="profilePicture"
                                            style={{ display: "none" }}
                                            onChange={handleImageUpload}
                                        />
                                    </div>
                                </div>
                                {userData?.avatar && (
                                    <div className="d-flex justify-content-center m-2">
                                        <div style={{ marginRight: "10px" }}>
                                            <button
                                                style={{
                                                    width: "100%",
                                                    color: "white",
                                                    padding: "4px 20px",
                                                    margin: "2px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    backgroundColor: "#e65925",
                                                }}
                                                onClick={handleUploaderClick}
                                            >
                                                Upload
                                            </button>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                accept="image/*"
                                                name="profilePicture"
                                                style={{ display: "none" }}
                                                onChange={handleImageUpload}
                                            />
                                        </div>
                                        <div
                                            onClick={() => {
                                                setisdeletemodalopen(true);
                                            }}
                                        >
                                            <button
                                                style={{
                                                    width: "100%",
                                                    color: "white",
                                                    padding: "4px 20px",
                                                    margin: "2px",
                                                    borderRadius: "4px",
                                                    border: "none",
                                                    backgroundColor: "#e65925",
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-center">
                                    <p
                                        className="onetap_conn_general_information_para_syn"
                                        style={{ textAlign: "center", margin: "0" }}
                                    >
                                        Accepted file type: SVG, PNG, JPG
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {companyData?.make_private_permission === true ? (
                                <div className="onetap_conn_general_information_syn">
                                    <div className="d-flex justify-content-between">
                                        <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                                            Address
                                        </p>
                                        <p
                                            className="fs-6 fw-medium"
                                            style={{ color: "#E65925" }}
                                        >
                                            Make Private
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Country
                                        </p>
                                        <div
                                            className="dropdown countrydrop"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="select-container">
                                                <Select
                                                    styles={{
                                                        container: (provided) => ({
                                                            width: "100%",
                                                            paddingLeft: "0%",
                                                        }),
                                                        control: () => ({
                                                            margin: 0,
                                                            padding: 0,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                        input: (provided) => ({
                                                            ...provided,
                                                            margin: 0,
                                                            padding: 0,
                                                        }),
                                                        indicatorsContainer: () => ({
                                                            display: "none",
                                                        }),
                                                        option: (provided) => ({
                                                            ...provided,
                                                        }),
                                                    }}
                                                    className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                    placeholder="Select"
                                                    name="address.country"
                                                    options={
                                                        Country &&
                                                        Country.getAllCountries().map((item) => ({
                                                            value: item.isoCode,
                                                            label: item.name,
                                                        }))
                                                    }
                                                    value={{
                                                        value: userData?.address?.country,
                                                        label: Country.getCountryByCode(
                                                            userData?.address?.country
                                                        )?.name,
                                                    }}
                                                    onChange={(e) => handleCountryChange(e.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Address
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="line1"
                                                value={userData?.address?.line1}
                                                onBlur={(e) =>
                                                    handleAddressChange("line1", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("line1", e.target.value)
                                                }
                                            />

                                            <GenInfoToggleButton
                                                isOn={addressPermission}
                                                onToggle={handleToggleAddressPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Apt, suite, etc
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.line2"
                                                value={userData?.address?.line2}
                                                onBlur={(e) =>
                                                    handleAddressChange("line2", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("line2", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={apartmentPermission}
                                                onToggle={handleToggleApartmentPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            City
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.city"
                                                value={userData?.address?.city}
                                                onBlur={(e) =>
                                                    handleAddressChange("city", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("city", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={cityPermission}
                                                onToggle={handleToggleCityPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            State
                                        </p>
                                        <div className="d-flex justify-content-between ">
                                            <div
                                                className="dropdown countrydrop"
                                                style={{ width: "90%" }}
                                            >
                                                <div className="select-container">
                                                    <Select
                                                        styles={{
                                                            container: (provided) => ({
                                                                width: "100%",
                                                                paddingLeft: "0%",
                                                            }),
                                                            control: () => ({
                                                                margin: 0,
                                                                padding: 0,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0,
                                                                padding: 0,
                                                            }),
                                                            indicatorsContainer: () => ({
                                                                display: "none",
                                                            }),
                                                            option: (provided) => ({
                                                                ...provided,
                                                            }),
                                                        }}
                                                        className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                        placeholder="Select"
                                                        name="address.state"
                                                        options={State.getStatesOfCountry(
                                                            userData?.address?.country
                                                        ).map((item) => ({
                                                            value: item.isoCode,
                                                            label: item.name,
                                                        }))}
                                                        value={{
                                                            value: userData?.address?.state,
                                                            label: State.getStateByCodeAndCountry(
                                                                userData?.address?.state,
                                                                userData?.address?.country
                                                            )?.name,
                                                        }}
                                                        onChange={(selectedOption) =>
                                                            handleStateChange(selectedOption.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <GenInfoToggleButton
                                                isOn={statePermission}
                                                onToggle={handleToggleStatePermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Postal code
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.postal_code"
                                                value={userData?.address?.postal_code}
                                                onBlur={(e) =>
                                                    handleAddressChange("postal_code", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("postal_code", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={postalCodePermission}
                                                onToggle={handleTogglePostalCodePermission}
                                            />
                                        </div>
                                        {postalCodeError && (
                                            <p className="error_message">{postalCodeError}</p>
                                        )}
                                    </div>
                                </div>
                            ) : userAllData?.subscription_details?.plan !== "Team" && userData?.role !== 'teammember' ? (
                                <div className="onetap_conn_general_information_syn">
                                    <div className="d-flex justify-content-between">
                                        <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                                            Address
                                        </p>
                                        <p
                                            className="fs-6 fw-medium"
                                            style={{ color: "#E65925" }}
                                        >
                                            Make Private
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Country
                                        </p>
                                        <div
                                            className="dropdown countrydrop"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="select-container">
                                                <Select
                                                    styles={{
                                                        container: (provided) => ({
                                                            width: "100%",
                                                            paddingLeft: "0%",
                                                        }),
                                                        control: () => ({
                                                            margin: 0,
                                                            padding: 0,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                        input: (provided) => ({
                                                            ...provided,
                                                            margin: 0,
                                                            padding: 0,
                                                        }),
                                                        indicatorsContainer: () => ({
                                                            display: "none",
                                                        }),
                                                        option: (provided) => ({
                                                            ...provided,
                                                        }),
                                                    }}
                                                    className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                    placeholder="Select"
                                                    name="address.country"
                                                    options={
                                                        Country &&
                                                        Country.getAllCountries().map((item) => ({
                                                            value: item.isoCode,
                                                            label: item.name,
                                                        }))
                                                    }
                                                    value={{
                                                        value: userData?.address?.country,
                                                        label: Country.getCountryByCode(
                                                            userData?.address?.country
                                                        )?.name,
                                                    }}
                                                    onChange={(e) => handleCountryChange(e.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Address
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="line1"
                                                value={userData?.address?.line1}
                                                onBlur={(e) =>
                                                    handleAddressChange("line1", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("line1", e.target.value)
                                                }
                                            />

                                            <GenInfoToggleButton
                                                isOn={addressPermission}
                                                onToggle={handleToggleAddressPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Apt, suite, etc
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.line2"
                                                value={userData?.address?.line2}
                                                onBlur={(e) =>
                                                    handleAddressChange("line2", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("line2", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={apartmentPermission}
                                                onToggle={handleToggleApartmentPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            City
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.city"
                                                value={userData?.address?.city}
                                                onBlur={(e) =>
                                                    handleAddressChange("city", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("city", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={cityPermission}
                                                onToggle={handleToggleCityPermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            State
                                        </p>
                                        <div className="d-flex justify-content-between ">
                                            <div
                                                className="dropdown countrydrop"
                                                style={{ width: "90%" }}
                                            >
                                                <div className="select-container">
                                                    <Select
                                                        styles={{
                                                            container: (provided) => ({
                                                                width: "100%",
                                                                paddingLeft: "0%",
                                                            }),
                                                            control: () => ({
                                                                margin: 0,
                                                                padding: 0,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0,
                                                                padding: 0,
                                                            }),
                                                            indicatorsContainer: () => ({
                                                                display: "none",
                                                            }),
                                                            option: (provided) => ({
                                                                ...provided,
                                                            }),
                                                        }}
                                                        className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                        placeholder="Select"
                                                        name="address.state"
                                                        options={State.getStatesOfCountry(
                                                            userData?.address?.country
                                                        ).map((item) => ({
                                                            value: item.isoCode,
                                                            label: item.name,
                                                        }))}
                                                        value={{
                                                            value: userData?.address?.state,
                                                            label: State.getStateByCodeAndCountry(
                                                                userData?.address?.state,
                                                                userData?.address?.country
                                                            )?.name,
                                                        }}
                                                        onChange={(selectedOption) =>
                                                            handleStateChange(selectedOption.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <GenInfoToggleButton
                                                isOn={statePermission}
                                                onToggle={handleToggleStatePermission}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <p className="onetap_conn_general_information_para_syn mb-1">
                                            Postal code
                                        </p>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                className="onetap_conn_general_information_input_syn_withicon"
                                                type="text"
                                                placeholder=""
                                                name="address.postal_code"
                                                value={userData?.address?.postal_code}
                                                onBlur={(e) =>
                                                    handleAddressChange("postal_code", e.target.value)
                                                }
                                                onChange={(e) =>
                                                    handleAddressField("postal_code", e.target.value)
                                                }
                                            />
                                            <GenInfoToggleButton
                                                isOn={postalCodePermission}
                                                onToggle={handleTogglePostalCodePermission}
                                            />
                                        </div>
                                        {postalCodeError && (
                                            <p className="error_message">{postalCodeError}</p>
                                        )}
                                    </div>
                                </div>
                            ) : <></>}
                        </div>
                        <div></div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={handleSave}
                            className="onetap_connect__comp_profile_save_and_cont_button_syn"
                        >
                            Save
                        </button>
                    </div>
                </div>

                <Modal
                    open={isModalOpen}
                    onClose={(e) => {
                        setModalOpen(false);
                        setNewJobTitle(null);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="Modal-section">
                            <h2 className="header" style={{ marginBottom: "1rem" }}>
                                Add Title{" "}
                            </h2>

                            <input
                                type="text"
                                name="designation"
                                className="input-field m-0"
                                placeholder="Ex: Marketing"
                                value={newJobTitle}
                                onChange={handleNewJobTitleChange}
                            />
                            <p id="err" className="error_message mt-1 mb-0"></p>
                            <div className="Modal-btn-group">
                                <button
                                    className="Modal-btn first-modal-btn"
                                    onClick={handleAddJobTitle}
                                >
                                    Add
                                </button>
                                <button
                                    className="Modal-btn sec-modal-btn"
                                    onClick={(e) => {
                                        setModalOpen(false);
                                        setNewJobTitle(null);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal open={openCropModal} onClose={handlecropmodalclose}>
                    <div>
                        <div style={cropingstyle} className="modal-content">
                            {selectedDp && (
                                <div style={{ width: "100%", height: "80%" }}>
                                    <img
                                        src={croppedImage}
                                        ref={cropperRef}
                                        alt="Selected Image"
                                        style={{ maxWidth: "50%" }}
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    margin: "10px 0px",
                                }}
                            >
                                <Tooltip title="Flip vertical" arrow>
                                    <SwapVertOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handleswapupdown}
                                    />
                                </Tooltip>
                                <Tooltip title="Flip horizontal" arrow>
                                    <SwapHorizOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handleswaprightleft}
                                    />
                                </Tooltip>
                                <Tooltip title="Zoom in" arrow>
                                    <ZoomInIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlezoominlogo}
                                    />
                                </Tooltip>
                                <Tooltip title="Zoom out" arrow>
                                    <ZoomOutIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlezoomoutlogo}
                                    />
                                </Tooltip>
                                <Tooltip title="Move Up" arrow>
                                    <ArrowUpwardOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlelogoup}
                                    />
                                </Tooltip>
                                <Tooltip title="Move down" arrow>
                                    <ArrowDownwardOutlined
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlelogodown}
                                    />
                                </Tooltip>
                                <Tooltip title="Move left" arrow>
                                    <ArrowBackOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlelogoleft}
                                    />
                                </Tooltip>
                                <Tooltip title="Move right" arrow>
                                    <ArrowForwardOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlelogoright}
                                    />
                                </Tooltip>
                                <Tooltip title="Rotate left" arrow>
                                    <Rotate90DegreesCcwIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlerotate}
                                    />
                                </Tooltip>
                                <Tooltip title="Rotate right" arrow>
                                    <Rotate90DegreesCwOutlinedIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handlerotateback}
                                    />
                                </Tooltip>
                                <Tooltip title="Reset" arrow>
                                    <RestartAltIcon
                                        fontSize="large"
                                        style={{
                                            backgroundColor: "#E65925",
                                            color: "#ffff",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            margin: "0px 5px",
                                        }}
                                        onClick={handleresetlogo}
                                    />
                                </Tooltip>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    onClick={handleCrop}
                                    style={{
                                        color: "#ffff",
                                        backgroundColor: "#E65925",
                                        width: "50%",
                                        margin: "5px",
                                    }}
                                >
                                    Crop Image
                                </Button>
                                <Button
                                    onClick={handleuploadorignal}
                                    style={{
                                        color: "#ffff",
                                        backgroundColor: "#E65925",
                                        width: "50%",
                                        margin: "5px",
                                    }}
                                >
                                    Upload
                                </Button>
                                <Button
                                    onClick={handlecropmodalclose}
                                    style={{
                                        color: "#ffff",
                                        backgroundColor: "#E65925",
                                        width: "50%",
                                        margin: "5px",
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {isdeletemodalopen && (
                    <div style={delete_account_modalStyle}>
                        <div style={delete_account_modalContentStyle}>
                            <div style={delete_accountText}>
                                Are you sure you want to delete your profile picture?
                            </div>
                            <div style={delete_account_iconContainer}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    bounce
                                    size="2xl"
                                    style={delete_account_trashIconStyle}
                                />
                            </div>
                            <div style={delete_account_buttonContainer}>
                                <button
                                    style={deleteAccountbuttonStyle}
                                    onClick={handleimagedelete}
                                >
                                    Delete
                                </button>
                                <button
                                    style={deleteAccountcanclebuttonStyle}
                                    onClick={() => {
                                        setisdeletemodalopen(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                <Modal
                    open={apiSuccess}
                    onClose={() => setApiSuccess(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={successModalStyle} style={{ textAlign: "center" }}>
                        <div className="Modal-section">
                            <div className="checkmark-container">
                                <div class="checkmark-container">
                                    {messageIcon}
                                </div>
                            </div>
                            <span className="team_created_success">
                                {successMessage}
                            </span>
                        </div>
                        <button
                            className="Modal-btn sec-modal-btn"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                width: "13%",
                            }}
                            onClick={() => setApiSuccess(false)}
                        >
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                                <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
                            </span>
                        </button>
                    </Box>
                </Modal>
            </>

            )}
        </>
    )
}



export default UserProfileGeneral
