import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import ClientProfileHeaderWithIcons from "./ClientProfileHeaderWithIcons";
import ClientProfileUsers from "./ClientProfileUsers";
import ClientProfileInformation from "./ClientProfileInformation";
import ClientProfileSmartAccessories from "./ClientProfileSmartAccessories";
import ClientProfileCompanyInformation from "./ClientProfileCompanyInformation";
import ClientProfileBillingInformation from "./ClientProfileBillingInformation";
import ClientProfileSettings from "./ClientProfileSettings";
const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("Overview");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  return (
    <div className="otc_onetap_conn_user" style={{ padding: "14px 0px" }}>
      <ClientProfileHeaderWithIcons
        onChange={getTabValue}
        tab={companyProfileTab}
      />
      {companyProfileTab === "Overview" && (
        <ClientProfileInformation onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyProfileTab === "Users" && (
        <ClientProfileUsers onClickSave={getValueOfSaveAndContinueTab} />
      )}
      {companyProfileTab === "Smart Accessories" && (
        <ClientProfileSmartAccessories
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
      {companyProfileTab === "Information" && (
        <ClientProfileCompanyInformation
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
      {companyProfileTab === "Orders & Billing" && (
        <ClientProfileBillingInformation
          onClickSave={getValueOfSaveAndContinueTab}
        />
      )}
      {companyProfileTab === "Settings" && (
        <ClientProfileSettings onClickSave={getValueOfSaveAndContinueTab} />
      )}
    </div>
  );
};

export default function Clientprofile() {
  return (
    <>
      <Otc_Sidebar Component={Component} />
    </>
  );
}
