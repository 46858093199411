import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const TeamMemberHeader = (props) => {
    const [companyProfileTab, setCompanyProfileTab] = useState("General");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { tab } = props;

    useEffect(() => {
        setCompanyProfileTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCompanyProfileTab(value);
        props.onChange(value);
    };

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setCompanyProfileTab(tab);
    }, [tab]);


    return (
        <>
            <p className="onetap_conn_user_header">
                <Link
                    to="/dashboard"
                    style={{ textDecoration: "none", color: "#2C2C2C" }}
                >
                    Dashboard
                </Link>
                &nbsp; {">"} &nbsp;   <Link
                    to="/users"
                    style={{ textDecoration: "none", color: "#2C2C2C" }}
                    onClick={() => handleClickOfTab("Users")}
                >
                    Users
                </Link>
                &nbsp; {">"} &nbsp; {""}
                <span className="onetap_conn_header_highlight">User Information</span>
            </p>
            <div className="onetap_conn_user_header_main">
                <div className="onetap_conn_user_header_icon">
                    <div
                        onClick={() => handleClickOfTab("UserInfo")}
                        className={
                            companyProfileTab === "UserInfo"
                                ? "onetap_conn_user_header_icon_and_header_active"
                                : "onetap_conn_user_header_icon_and_header_deactive"
                        }
                    >
                        <div
                            style={{
                                backgroundColor: companyProfileTab === "UserInfo" ? "#E65925" : "",
                                width: "34px",
                                height: "32px",
                                borderRadius: "7px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer" }}>
                                <path d="M9 0C10.1935 0 11.3381 0.474106 12.182 1.31802C13.0259 2.16193 13.5 3.30653 13.5 4.5C13.5 5.69347 13.0259 6.83807 12.182 7.68198C11.3381 8.52589 10.1935 9 9 9C7.80653 9 6.66193 8.52589 5.81802 7.68198C4.97411 6.83807 4.5 5.69347 4.5 4.5C4.5 3.30653 4.97411 2.16193 5.81802 1.31802C6.66193 0.474106 7.80653 0 9 0ZM9 11.25C13.9725 11.25 18 13.2638 18 15.75V18H0V15.75C0 13.2638 4.0275 11.25 9 11.25Z" fill={companyProfileTab === "UserInfo" ? "#ffff" : "#000000"} />
                            </svg>
                        </div>
                        <p
                            style={{ cursor: "pointer" }}
                            className={
                                companyProfileTab === "UserInfo"
                                    ? "onetap_conn_user_active_header"
                                    : "onetap_conn_user_deactive_header"
                            }
                        >
                            User Information
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeamMemberHeader;
