import { createReducer } from "@reduxjs/toolkit";
import { retriveLoginAccounts } from "../../actions/switchAccount/switchAccountAction";

const userInitailState = {
  user: null,
  loading: false,
  isAuthenticated: false,
  error: null,
  message: null,
  isPlanActive: false,
  cards: null,
  stripeSavedCards: []
};

export const userReducer = createReducer(userInitailState, (builder) => {
  builder.addCase("SIGN_UP1_REQUEST", (state, action) => {
    state.loading = true;
    state.user = null;
    state.isAuthenticated = false;
  });
  builder.addCase("SIGN_UP1_SUCCESS", (state, action) => {
    state.loading = false;
    state.user = action.payload.user;
    state.isAuthenticated = action.payload.success || true;
    state.message = action.payload.message;
  });
  builder.addCase("SIGN_UP1_FAILURE", (state, action) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
    state.error = action.payload;
  });

  builder.addCase("SIGN_UP2_REQUEST", (state, action) => {
    state.loading = true;
    state.user = null;
    state.isAuthenticated = false;
  });
  builder.addCase("SIGN_UP2_SUCCESS", (state, action) => {
    state.loading = false;
    state.user = action.payload.user;
    state.isAuthenticated = action.payload.success || true;
  });
  builder.addCase("SIGN_UP2_FAILURE", (state, action) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
  });

  //----------------------------------------------------------------

  builder.addCase("LOGIN_REQUEST", (state, action) => {
    state.loading = true;
    state.user = null;
    state.isAuthenticated = false;
  });
  builder.addCase("LOGIN_SUCCESS", (state, action) => {
    state.loading = false;
    state.user = action.payload.user;
    state.isAuthenticated = action.payload.success || true;
  });
  builder.addCase("LOGIN_FAILURE", (state, action) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
    state.error = action.payload;
  });

  //----------------------------------------------------------------

  builder.addCase("FORGOT_PASSWORD_REQUEST", (state, action) => {
    state.loading = true;
    state.user = null;
    state.isAuthenticated = false;
    state.error = null;
  });

  builder.addCase("FORGOT_PASSWORD_SUCCESS", (state, action) => {
    state.loading = false;
    state.user = action.payload.user;
    state.isAuthenticated = false;
    state.error = null;
    state.message = action.payload.message;
  });
  builder.addCase("FORGOT_PASSWORD_FAILURE", (state, action) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
    state.error = action.payload;
  });

  //----------------------------------------------------------------

  builder.addCase("RESET_PASSWORD_REQUEST", (state, action) => {
    state.loading = true;
    state.user = null;
    state.isAuthenticated = false;
    state.error = null;
  });

  builder.addCase("RESET_PASSWORD_SUCCESS", (state, action) => {
    state.loading = false;
    state.message = action.payload.message;
    state.error = null;
  });
  builder.addCase("RESET_PASSWORD_FAILURE", (state, action) => {
    state.loading = false;
    state.user = null;
    state.isAuthenticated = false;
    state.error = action.payload;
  });

  //  --------------------------------------------------------------

  builder.addCase("LOAD_USER_REQUEST", (state, action) => {
    state.user = null;
    state.loading = true;
    state.isAuthenticated = false;
  });

  builder.addCase("LOAD_USER_SUCCESS", (state, action) => {
    state.user = action.payload.user;
    state.loading = false;
    state.isAuthenticated = action.payload.success || true;
  });

  builder.addCase("LOAD_USER_FAIL", (state, action) => {
    state.user = null;
    state.loading = false;
    state.isAuthenticated = false;
  });

  builder.addCase("LOGOUT_SUCCESS", (state, action) => {
    state.user = null;
    state.loading = false;
    state.isAuthenticated = false;
  });

  builder.addCase("CLEAR_ERROR", (state, action) => {
    state.error = null;
  });

  builder.addCase("CLEAR_MESSAGE", (state, action) => {
    state.message = null;
  });

  builder.addCase("CHECKOUT_REQUEST", (state, action) => {
    state.user = null;
    state.loading = true;
    state.isAuthenticated = false;
  });

  builder.addCase("CHECKOUT_SUCCESS", (state, action) => {
    state.user = action.payload.user;
    state.loading = false;
    state.isAuthenticated = action.payload.success || true;
  });

  builder.addCase("CHECKOUT_FAILURE", (state, action) => {
    state.user = null;
    state.loading = false;
    state.isAuthenticated = false;
  });

  //----------------------------------------------------------------

  builder.addCase("UPDATE_USER_BILLING_ADDRESS_REQUEST", (state, action) => {
    state.loading = true;
  });

  builder.addCase("UPDATE_USER_BILLING_ADDRESS_SUCCESS", (state, action) => {
    state.loading = false;
    state.message = action.payload.message;
  });

  builder.addCase("UPDATE_USER_BILLING_ADDRESS_FAILED", (state, action) => {
    state.loading = false;
    state.error = action.payload.error;
  });
  builder.addCase("FETCH_SUBSCRIPTION_SUCCESS", (state, action) => {
    state.loading = false;
    state.isPlanActive = true;
  });
  builder.addCase("FETCH_SUBSCRIPTION_FAILURE", (state, action) => {
    state.loading = false;
    state.isPlanActive = false;
  });

  // -----------------------------------------------------------------------

  builder.addCase("CARDS_FETCHED_SUCCESS", (state, action) => {
    state.loading = false;
    state.stripeSavedCards = action.payload.data;
  });
  builder.addCase("CARDS_FETCHED_FAILURE", (state, action) => {
    state.loading = false;
    state.stripeSavedCards = null;
  });

});

const UsersInitailstate = {
  users_loading: false,
  users: null,
  message: null,
  error: null,
};

export const UsersReducer = createReducer(UsersInitailstate, (builder) => {
  builder.addCase("GET_USERS_REQUEST", (state, action) => {
    state.users_loading = true;
    state.users = null;
  });

  builder.addCase("GET_USERS_SUCCESS", (state, action) => {
    state.users_loading = false;
    state.users = action.payload.users;
  });

  builder.addCase("GET_USERS_FAILURE", (state, action) => {
    state.users_loading = false;
    state.users = null;
    state.error = action.payload;
  });

  // ---------------------------------------------------------------------------

  builder.addCase("DELETE_TEAMMEMBER_REQUEST", (state, action) => {
    state.message = null;
  });
  builder.addCase("DELETE_TEAMMEMBER_SUCCESS", (state, action) => {
    state.message = action.payload.message;
  });
  builder.addCase("DELETE_TEAMMEMBER_FAILURE", (state, action) => {
    state.message = null;
  });
});

const invitedUsersInitailstate = {
  invitedusers_loading: false,
  invitedusers: null,
  message: null,
  error: null,
};

export const invitedUsersReducer = createReducer(
  invitedUsersInitailstate,
  (builder) => {
    builder.addCase("GET_INVITEDUSERS_REQUEST", (state, action) => {
      state.invitedusers_loading = true;
      state.invitedusers = null;
    });

    builder.addCase("GET_INVITEDUSERS_SUCCESS", (state, action) => {
      state.invitedusers_loading = false;
      state.invitedusers = action.payload.invitedusers;
    });

    builder.addCase("GET_INVITEDUSERS_FAILURE", (state, action) => {
      state.invitedusers_loading = false;
      state.invitedusers = null;
      state.error = action.payload;
    });

    // ---------------------------------------------------------------------------

    builder.addCase("DELETE_TEAMMEMBER_REQUEST", (state, action) => {
      state.message = null;
    });
    builder.addCase("DELETE_TEAMMEMBER_SUCCESS", (state, action) => {
      state.message = action.payload.message;
    });
    builder.addCase("DELETE_TEAMMEMBER_FAILURE", (state, action) => {
      state.message = null;
    });
  }
);
const teamMemberDetailsInitialState = {
  loading: false,
  teamMemberDetails: {},
  message: null,
  error: null,
};

export const singleTeamMemberDetailsReducer = createReducer(
  teamMemberDetailsInitialState,
  (builder) => {
    builder
      .addCase("GET_TEAMEMBER_DETAILS_REQUEST", (state, action) => {
        state.loading = true;
        state.teamMemberDetails = null;
      })
      .addCase("GET_TEAMEMBER_DETAILS_SUCCESS", (state, action) => {
        state.loading = false;
        state.teamMemberDetails = action.payload.user;
      })
      .addCase("GET_TEAMEMBER_DETAILS_FAILURE", (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set appropriate error payload
      })
      .addCase("UPDATE_TEAM_MEMBER_DETAILS_REQUEST", (state, action) => {
        state.loading = true;
        state.message = null; // Clear any previous messages
        state.error = null; // Clear any previous errors
      })
      .addCase("UPDATE_TEAM_MEMBER_DETAILS_SUCCESS", (state, action) => {
        state.loading = false;
        state.message = action.payload.message; // Store success message
        state.teamMemberDetails = action.payload.updatedDetails;
      })
      .addCase("UPDATE_TEAM_MEMBER_DETAILS_FAILURE", (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      })
      .addCase("UPLOAD_PROFILE_PICTURE_REQUEST", (state, action) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("UPLOAD_PROFILE_PICTURE_SUCCESS", (state, action) => {
        state.loading = false;
        // You can handle profile picture updates here if needed
        // state.teamMemberDetails.profilePicture = action.payload.updatedProfilePicture;
        state.message = action.payload.message;
      })
      .addCase("UPLOAD_PROFILE_PICTURE_FAIL", (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
);

const userInformationinitialState = {
  loading: false,
  userInformation: {},
  message: null,
  error: null,
};

export const userInformationReducer = createReducer(
  userInformationinitialState,
  (builder) => {
    builder
      .addCase("GET_USER_INFORMATION_REQUEST", (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase("GET_USER_INFORMATION_SUCCESS", (state, action) => {
        state.loading = false;
        state.userInformation = action.payload.userinfo;
      })
      .addCase("GET_USER_INFORMATION_FAILURE", (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase("UPDATE_USER_INFORMATION_REQUEST", (state, action) => {
        state.loading = true;
        state.message = null;
        state.error = null;
      })
      .addCase("UPDATE_USER_INFORMATION_SUCCESS", (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.userInformation = action.payload.userInformation;
      })
      .addCase("UPDATE_USER_INFORMATION_FAILURE", (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  });


const CompanyShareReferDatainitaialState = {
  companyShareReferData: null,
  loading: false,
  error: null,
  message: null,
};
export const companyShareReferDataReducer = createReducer(
  CompanyShareReferDatainitaialState,
  (builder) => {
    builder.addCase("getcompanies_share_referral_data_REQUEST", (state, action) => {
      state.loading = true;
      state.companyShareReferData = null;
    });

    builder.addCase("getcompanies_share_referral_data_SUCCESS", (state, action) => {
      state.loading = false;
      state.companyShareReferData = action.payload.companyShareReferData;
    });

    builder.addCase("getcompanies_share_referral_data_FAILURE", (state, action) => {
      state.loading = false;
      state.companyShareReferData = null;
    });

    //---------------------------------------------------------------

    builder.addCase("updatecompany_referral_data_REQUEST", (state, action) => {
      state.loading = true;
      state.companyShareReferData = null;
    });

    builder.addCase("updatecompany_referral_data_SUCCESS", (state, action) => {
      state.loading = false;
      state.companyShareReferData = action.payload.companyShareReferData;
    });

    builder.addCase("updatecompany_referral_data_FAILURE", (state, action) => {
      state.loading = false;
      state.companyShareReferData = null;
    });

  });

const ComapnyinitaialState = {
  company: null,
  loading: false,
  error: null,
  message: null,
};

export const CompantProfileReducer = createReducer(
  ComapnyinitaialState,
  (builder) => {
    builder.addCase("GET_COMPANY_DETAILS_REQUEST", (state, action) => {
      state.loading = true;
      state.company = null;
    });

    builder.addCase("GET_COMPANY_DETAILS_SUCCESS", (state, action) => {
      state.loading = false;
      state.company = action.payload.company;
    });

    builder.addCase("GET_COMPANY_DETAILS_FAILURE", (state, action) => {
      state.loading = false;
      state.company = null;
    });

    // -------------------------------------------------------------------------
    builder.addCase("UPDATE_COMPANY_REQUEST", (state, action) => {
      state.loading = true;
    });
    builder.addCase("UPDATE_COMPANY_SUCCESS", (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase("UPDATE_COMPANY_FAILURE", (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    });
    //---------------------------------------------------------------

    builder.addCase("UPDATE_COMPANY_DETAILS_INFO_REQUEST", (state, action) => {
      state.loading = true;
      state.company = null;
    });

    builder.addCase("UPDATE_COMPANY_DETAILS_INFO_SUCCESS", (state, action) => {
      state.loading = false;
      state.company = action.payload.company;
    });

    builder.addCase("UPDATE_COMPANY_DETAILS_INFO_FAILURE", (state, action) => {
      state.loading = false;
      state.company = null;
    });

    //-----------------------------------------------------
    builder.addCase("UPLOAD_LOGO_REQUEST", (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    });
    builder.addCase("UPLOAD_LOGO_SUCCESS", (state, action) => {
      state.loading = false;
      // You can handle profile picture updates here if needed
      // state.company.logoimage = action.payload.updatedProfilePicture;
      state.message = action.payload.message;
    });
    builder.addCase("UPLOAD_LOGO_FAIL", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    //-----------------------------------------------------
    builder.addCase("UPLOAD_FAVICON_REQUEST", (state, action) => {
      state.loading = true;
      state.message = null;
      state.error = null;
    });
    builder.addCase("UPLOAD_FAVICON_SUCCESS", (state, action) => {
      state.loading = false;
      // You can handle profile picture updates here if needed
      // state.company.logoimage = action.payload.updatedProfilePicture;
      state.message = action.payload.message;
    });
    builder.addCase("UPLOAD_FAVICON_FAIL", (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase("CLEAR_ERROR", (state, action) => {
      state.error = null;
    });

    builder.addCase("CLEAR_MESSAGE", (state, action) => {
      state.message = null;
    });
  }
);



const shippingAddressInitialState = {
  loading: false,
  shippingAddresses: null,
  success : false,
};

export const shippingAddressReducer = createReducer(
  shippingAddressInitialState,
  (builder) => {
    builder.addCase("CREATE_SHIPPING_ADDRESS_REQUEST", (state, action) => {
      state.shippingAddresses = null;
      state.loading = true;
      state.isAuthenticated = false;
      state.success = false;
    });

    builder.addCase("CREATE_SHIPPING_ADDRESS_SUCCESS", (state, action) => {
      state.shippingAddresses = action.payload.message;
      state.loading = false;
      state.isAuthenticated = action.payload.success || true;
      state.success = true;
    });

    builder.addCase("CREATE_SHIPPING_ADDRESS_FAILURE", (state, action) => {
      state.shippingAddresses = null;
      state.loading = false;
      state.isAuthenticated = false;
      state.success = false;
    });
    //--------------------------------------------------------
    builder.addCase("SHIPPINGADDRESSES_REQUEST", (state, action) => {
      state.shippingAddresses = null;
      state.loading = true;
      state.isAuthenticated = false;
    });

    builder.addCase("SHIPPINGADDRESSES_SUCCESS", (state, action) => {
      state.shippingAddresses = action.payload.shippingAddresses;
      state.loading = false;
      state.isAuthenticated = action.payload.success || true;
    });

    builder.addCase("SHIPPINGADDRESSES_FAILURE", (state, action) => {
      state.shippingAddresses = null;
      state.loading = false;
      state.isAuthenticated = false;
    });
  }
);

const billingAddressInitialState = {
  loading: false,
  billingAddresses: null,
  isAuthenticated: false,
};

export const billingAddressReducer = createReducer(
  billingAddressInitialState,
  (builder) => {
    builder.addCase("FETCH_BILLING_ADDRESS_REQUEST", (state, action) => {
      state.billingAddresses = null;
      state.loading = true;
      state.isAuthenticated = false;
    });

    builder.addCase("FETCH_BILLING_ADDRESS_SUCCESS", (state, action) => {
      state.billingAddresses = action.payload.billingData.billing_address;
      state.loading = false;
      state.isAuthenticated = action.payload.success || true;
    });

    builder.addCase("FETCH_BILLING_ADDRESS_FAILED", (state, action) => {
      state.billingAddresses = null;
      state.loading = false;
      state.isAuthenticated = false;
    });
  }
);

const userInformation = {
  userInfo: null,
  loading: false,
};

export const fetchUserInformationReducer = createReducer(userInformation, (builder) => {
  builder.addCase("FETCH_USER_INFORMATION_REQUEST", (state, action) => {
    state.loading = true;
  });

  builder.addCase("FETCH_USER_INFORMATION_SUCCESS", (state, action) => {
    state.loading = false;
    state.userInfo = action.payload.firstuserInfo;
  });

  builder.addCase("FETCH_USER_INFORMATION_FAILURE", (state, action) => {
    state.loading = false;
  });
});


const teamNameInitialState = {
  loading: false,
  team: null,
  error: null
}
export const teamNameReducer = createReducer(teamNameInitialState, (builder) => {
  builder.addCase("GET_TEAMNAME_REQUEST", (state, action) => {
    state.loading = true;
    state.team = null;
  })
  builder.addCase("GET_TEAMNAME_SUCCESS", (state, action) => {
    state.loading = false;
    state.team = action.payload.team;
    state.error = null
  })
  builder.addCase("GET_TEAMNAME_FAILURE", (state, action) => {
    state.team = null;
    state.loading = false;
    state.error = action.payload
  })
})

//----------------------------------------------------------------
const getProductsInitialState = {
  loading: false,
  products: null,
  message: null,
  error: null
}
export const getProductsReducer = createReducer(getProductsInitialState, (builder) => {
  builder.addCase("GET_PRODUCTS_REQUEST", (state, action) => {
    state.loading = true;
    state.products = null;
    state.error = null;
  });

  builder.addCase("GET_PRODUCTS_SUCCESS", (state, action) => {
    state.loading = false;
    state.products = action.payload.products;
    state.error = null;
  });
  builder.addCase("GET_PRODUCTS_FAILURE", (state, action) => {
    state.loading = false;
    state.products = null;
    state.error = action.payload;
  });
})
//  --------------------------------------------------------------
const fetchCartInitialState = {
  loading: false,
  Cartproducts: null,
  message: null,
  cartProductCounts: null,
};
export const fetchCartReducer = createReducer(
  fetchCartInitialState,
  (builder) => {
    //---------------------------------------------------------------------------
    builder.addCase("FETCH_CART_REQUEST", (state, action) => {
      state.loading = true;
      state.Cartproducts = null;
    })
    builder.addCase("FETCH_CART_SUCCESS", (state, action) => {
      state.loading = false;
      state.Cartproducts = action.payload.Cartproducts;
      state.cartProductCounts = action.payload.Cartproducts.length;
      state.error = null
    })
    builder.addCase("FETCH_CART_FAILURE", (state, action) => {
      state.Cartproducts = null;
      state.loading = false;
      state.error = action.payload
    })
  }
)
const useravatarState = {
  userprofileimage: null,

};

// Create the user reducer using createReducer
export const getProfileImageReducer = createReducer(useravatarState, (builder) => {
  builder
    .addCase('GET_PROFILE_IMAGE_REQUEST', (state, action) => {
      state.userprofileimage = null;
    })
    .addCase('GET_PROFILE_IMAGE_SUCCESS', (state, action) => {

      state.userprofileimage = action.payload;
    })
    .addCase('GET_PROFILE_IMAGE_FAILURE', (state, action) => {
    });
});

const unique_slugInitailstate = {
  unique_slug_loading: false,
  unique_slug: null,
  message: null,
  error: null,
};

export const Unique_slugReducer = createReducer(
  unique_slugInitailstate,
  (builder) => {
    builder.addCase("GET_UNIQUE_SLUG_REQUEST", (state, action) => {
      state.unique_slug_loading = true;
      state.unique_slug = null;
    });

    builder.addCase("GET_UNIQUE_SLUG_SUCCESS", (state, action) => {
      state.unique_slug_loading = false;
      state.unique_slug = action.payload;
    });

    builder.addCase("GET_UNIQUE_SLUG_FAILURE", (state, action) => {
      state.unique_slug_loading = false;
      state.unique_slug = null;
      state.error = action.payload;
    });
  }
);

const getOrdersInitialState = {
  loading: false,
  orders: null,
  message: null,
};

export const getOrdersReducer = createReducer(
  getOrdersInitialState,
  (builder) => {
    builder.addCase("GET_ORDERS_REQUEST", (state, action) => {
      state.loading = true;
      state.orders = null;
    });

    builder.addCase("GET_ORDERS_SUCCESS", (state, action) => {
      state.loading = false;
      state.orders = action.payload.orders;
    });

    builder.addCase("GET_ORDERS_FAILURE", (state, action) => {
      state.loading = false;
      state.orders = null;
      state.error = action.payload;
    });
  }
);

// -----------------
const localStorageChangeInitialState = {
  loading: false,
  cartData: null,
  error: null,
  localStorageChangeFlag: false,
};

export const localStorageChangeReducer = createReducer(
  localStorageChangeInitialState,
  (builder) => {
    builder.addCase("LOCAL_STORAGE_CHANGE_REQUEST", (state, action) => {
      state.loading = true;
      state.cartData = null;
      state.localStorageChangeFlag = false;
    });

    builder.addCase("LOCAL_STORAGE_CHANGE_SUCCESS", (state, action) => {
      state.loading = false;
      state.cartData = action.payload;
    });

    builder.addCase("LOCAL_STORAGE_CHANGE_FAILURE", (state, action) => {
      state.loading = false;
      state.cartData = null;
      state.error = action.payload;
    });

    builder.addCase("SET_LOCAL_STORAGE_CHANGE_FLAG", (state, action) => {
      state.localStorageChangeFlag = action.payload;
    });
  }
);

// plans initial state
const plansIntialData = {
  loading: false,
  planData: null,
  error: null,
};

export const getPlanDataReducer = createReducer(
  plansIntialData,
  (builder) => {
    builder.addCase("FETCH_PLANS_REQUEST", (state, action) => {
      state.loading = true;
      state.planData = null;
    });

    builder.addCase("FETCH_PLANS_SUCCESS", (state, action) => {
      state.loading = false;
      state.planData = action.payload;
    });

    builder.addCase("FETCH_PLANS_FAILURE", (state, action) => {
      state.loading = false;
      state.planData = null;
      state.error = action.payload;
    });
  }
);

// reducer for multiple accounts fetching
const initialValuesofAccounts = {
  loading: false,
  userData: null,
  error: null,
}

export const activeUsers = createReducer(
  initialValuesofAccounts,
  (builder) => {
    builder.addCase("ACCOUNTS_FETCH_SUCCESS", (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    });

    builder.addCase("ACCOUNTS_FETCH_FAILURE", (state, action) => {
      state.loading = false;
      state.userData = null;
      state.error = action.payload;
    });
  }
);