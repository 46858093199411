import React, { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import MyAccount from "./MyAccount";

const Component = () => {
  const [cardInfoTab, setCardInfoTab] = useState("My Account");
  const getValueOfSaveAndContinueTab = (value) => {
    setCardInfoTab(value);
  };

  return (
    <div className="">
      <div className="onetap_company_card_syn">
        <div>
          {cardInfoTab === "My Account" && (
            <MyAccount
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};

export default Dashboard;
