import Dashboard from "../Pages/company/Dashboard/Dashboard";
import MyCard from "../components/Customers/Mycard/MyCard"
import ComingSoon from "../components/ComingSoon/ComingSoon";
import Setting from "../Pages/company/Setting/Setting";
import CompanyProfile from "../components/Customers/CompanyProfile/CompanyProfile";
import EditCompanyProfile from "../components/Customers/CompanyProfile/EditCompany/CompanyProfile";
import Users from "../components/Customers/User/User";
import Billing from "../components/Customers/Billing/Billing";
import TeamMember from "../components/Customers/UserInformation/TeamMember";
import SmartAccessories from "../components/Customers/SmartAccessories/SmartAccessories";
import ProfileMember from "../components/Customers/Profile/ProfileMember";
import CardProfile from "../components/Customers/Profile/CardProfileInfo/CardProfile";
import ClientOrderSummary from "../components/Customers/Billing/ClientOrderSummary";

const superadminFreeRoute = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/shop-smart-accessories", element: <ComingSoon /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/billing", element: <Billing /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/integrations", element: <Dashboard /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];

  const superadminTeamRoute = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/analytics", element: <ComingSoon /> },
    { path: "/shop-smart-accessories", element: <ComingSoon /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/templates", element: <ComingSoon /> },
    { path: "/settings", element: <Setting /> },
    { path: "/users", element: <Users /> },
    { path: "/user/profile/:id", element: <TeamMember /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/user/information/:id", element: <CompanyProfile /> },
    { path: "/company/information", element: <EditCompanyProfile /> },
    { path: "/account-setting", element: <Dashboard /> },
    { path: "/billing", element: <Billing /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/integrations", element: <Dashboard /> },
    { path: "/academy", element: <ComingSoon /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];

  const administratorTeamRoutes = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/analytics", element: <ComingSoon /> },
    { path: "/shop-smart-accessories", element: <ComingSoon /> },
    { path: "/templates", element: <ComingSoon /> },
    { path: "/settings", element: <Setting /> },
    { path: "/users", element: <Users /> },
    { path: "/user/profile/:id", element: <TeamMember /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/user/information/:id", element: <CompanyProfile /> },
    { path: "/company/information", element: <EditCompanyProfile /> },
    { path: "/account-setting", element: <Dashboard /> },
    { path: "/billing", element: <Billing /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/integrations", element: <Dashboard /> },
    { path: "/academy", element: <ComingSoon /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];
  const superadminProfessionalRoute = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/analytics", element: <ComingSoon /> },
    { path: "/academy", element: <ComingSoon /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/account-setting", element: <Dashboard /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/settings", element: <Setting /> },
    { path: "/billing", element: <Billing /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];


  const managerTeamRoutes = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/analytics", element: <ComingSoon /> },
    { path: "/shop-smart-accessories", element: <ComingSoon /> },
    { path: "/settings", element: <Setting /> },
    { path: "/users", element: <Users /> },
    { path: "/user/profile/:id", element: <TeamMember /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/user/information/:id", element: <CompanyProfile /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/academy", element: <ComingSoon /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];



  const teammemberFreeRoutes = [
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/my-cards", element: <MyCard /> },
    { path: "/analytics", element: <ComingSoon /> },
    { path: "/smart-accessories", element: <SmartAccessories /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/tutorials", element: <ComingSoon /> },
    { path: "/billing", element: <Billing /> },
    { path: "/profile", element: <ProfileMember /> },
    { path: "/profile/information/:id", element: <CardProfile /> },
    { path: "/*", element: "" },
    { path: "/ordersummary/:orderNumber", element: <ClientOrderSummary/>},
  ];

  export {
    superadminFreeRoute,
    superadminTeamRoute,
    superadminProfessionalRoute,
    managerTeamRoutes,
    administratorTeamRoutes,
    teammemberFreeRoutes
  };