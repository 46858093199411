import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Addonicon from "../../Otc_assets/OTC_ICONS/addonicon.svg";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../components/Layout/Loader/Loader";
import PlanCard from "../../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../../components/PlanCard/PlanCard";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Noimagefound from "../../../OTC-AdminPanel/Otc_assets/images/Noimagefound.png";
import { getAllUsersOfCompany } from "../../Redux/Otc_UserActions";
import Select from "react-select";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { getPlan } from "../../../actions/userAction/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const url = process.env.REACT_APP_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "#FDE9E9",
  outline: "none",
  borderRadius: "10px",
  border: "1px solid #E62525",
  paddingTop: "5px",
  paddingRight: "30px",
  paddingBottom: "30px",
  paddingLeft: "30px",
};
const Enable_subscription_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "#e9fdeb",
  outline: "none",
  borderRadius: "10px",
  border: "1px solid #b6ffc0",
  paddingTop: "5px",
  paddingRight: "30px",
  paddingBottom: "30px",
  paddingLeft: "30px",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "#F2F2F2",
  p: 4,
  outline: "none",
  // borderRadius: "10px",
  overflowY: "scroll",
  maxHeight: "95%",
};
const popupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "60%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  // borderRadius: "10px",
  overflow: "auto",
};

const ClientCompanySubscriptionandaddons = (props) => {
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  const frontendURL = window.location.origin;
  const [isActive, setIsActive] = useState(false);
  const [plandata, setPlanData] = useState([]);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [cardType, setCardType] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { id } = useParams();
  const stripe = useStripe();
  const elements = useElements();

  const { loading, companydata, userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  const {planData} = useSelector((state) => state.planData);
  const [savedCard, setSavedCard] = useState([]);
  const [autoRenewalPopupOpen, setAutoRenewalPopupOpen] = React.useState(false);
  const [addAddonsPopupOpen, setAddAddonsPopupOpen] = React.useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [renualLoad, setRenualLoad] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [isAutoRenewal, setIsAutoRenewal] = useState(null);
  const [popupContent, SetpopupContent] = useState({
    popupLable: null,
    message: null,
    checkboxLable: null,
    buttonText: null,
    parameter: null,
  });
  const superAdminUser =
    userInformationTeamData &&
    userInformationTeamData.find((user) => user.user_id?.role === "superadmin");
  const dropdownRef = useRef(null);
  const baseUser =
    superAdminUser &&
    superAdminUser?.subscription_details?.total_user &&
    superAdminUser?.subscription_details?.total_user[0]?.baseUser;
  const additionalUser =
    superAdminUser &&
    superAdminUser?.subscription_details?.total_user &&
    superAdminUser?.subscription_details?.total_user[0]?.additionalUser;
  const total_user = baseUser + additionalUser;
  const planName = superAdminUser && superAdminUser?.subscription_details?.plan;

  const superAdminUserid = superAdminUser?.user_id?._id;
  const superAdminUserplan = superAdminUser?.subscription_details?.plan;
  const superAdminUsercustomerid =
    superAdminUser?.subscription_details?.customer_id;
  const superAdminUsersubscriptionid =
    superAdminUser?.subscription_details?.subscription_schedules_id;

  const [addons, setAddOns] = useState(null);
  const [excludingAddons, setExcludingAddOns] = useState(null);
  const [userOrderstatedata, setUserOrderstatedata] = useState([]);

  const getOrderssofcompany = async () => {
    
    try {
      const { data } = await axios.post(`${url}/admin/getorderssofcompany`, {
        id,
      });
      setUserOrderstatedata(data.Orderssofcompany);
      
    } catch (error) {
      ;
    }
  };
  const fetchSavedCards = async () => {
    if (!stripe || !elements || !superAdminUsercustomerid) return;

    try {
      const response = await axios.post(
        `${url}/admin/payment/get-saved-cards`,
        { customerID: superAdminUsercustomerid },{
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const cardsData = response.data.data;

      // Filter cards based on isPrimary field
      const primaryCard = cardsData.find((card) => card.isPrimary);

      // Update state with only the primary card
      setSavedCard([primaryCard]);
    } catch (error) {
      ;
    }
  };

  useEffect(() => {
    fetchSavedCards();
  }, [userInformationTeamData]);

  useEffect(() => {
    if (savedCard && savedCard.length > 0) {
      const lastCard = savedCard[0];
      setCardNumber(lastCard?.card?.last4);
      setCardType(lastCard?.card?.brand);
    }
  }, [savedCard]);

  useEffect(() => {
    getOrderssofcompany();
  }, [id]);
  let ordernum;
  if (userOrderstatedata) {
    const subscriptionOrders = userOrderstatedata.filter(
      (order) => order.type === "Subscription"
    );
    if (subscriptionOrders.length > 0) {
      const selectedOrder = subscriptionOrders.reduce(
        (prevOrder, currentOrder) => {
          const prevPaymentDate = new Date(prevOrder.paymentDate);
          const currentPaymentDate = new Date(currentOrder.paymentDate);
          return prevPaymentDate > currentPaymentDate
            ? prevOrder
            : currentOrder;
        }
      );
      ordernum = selectedOrder.orderNumber;
    }
  }
  

  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const redirecttopaymentmethod = () => {
    setTimeout(() => {
      props.onClickSave("Payment Methods");
    }, 500);
  };
  const fetchandSetPlans = ()=>{
    if(planData){
      setPlanData(planData.plans);
    }else{
      dispatch(getPlan());
    }
  }
    // fetch all addons data
  const fetchAddons = async () => {
    try {
      const { data } = await axios.get(`${url}/addons`);
      const allAddons = data.addOns;
      const selectedAddons = allAddons.filter((addon) =>
      superAdminUser?.subscription_details.addones.some(
          (subscriptionAddon) => subscriptionAddon.addonId === addon._id
        )
      );
      const excludingAddOn = allAddons.filter(
        (addon) => !selectedAddons.includes(addon)
      );
      setAddOns(selectedAddons);
      setExcludingAddOns(excludingAddOn);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchandSetPlans();
  }, [planData])

  useEffect(() => {
    fetchAddons();
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!companydata) {
      dispatch(getAllUsersOfCompany(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (superAdminUser?.subscription_details?.auto_renewal !== undefined) {
      setIsAutoRenewal(superAdminUser?.subscription_details?.auto_renewal);
    }
  }, [userInformationTeamData]);

  const dateObject = new Date(
    superAdminUser?.subscription_details?.endDate * 1000
  );

  //this one is calculate for "December 12th 2023" for this format
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formatted_day = dateObject.getDate();
  const formatted_month = months[dateObject.getMonth()];
  const formatted_year = dateObject.getFullYear();
  let dayFormatted;

  if (formatted_day === 1) {
    dayFormatted = "1st";
  } else if (formatted_day === 2) {
    dayFormatted = "2nd";
  } else if (formatted_day === 3) {
    dayFormatted = "3rd";
  } else {
    dayFormatted = `${formatted_day}th`;
  }

  const formatted_Date = `${formatted_month} ${dayFormatted} ${formatted_year}`;

  //calculate the 7 days before date
  const showpopupDate = new Date(dateObject);

  // Calculate the number of milliseconds remaining until the formattedDate
  const today = new Date();
  const timeDifference = showpopupDate - today;
  const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Calculate the day before for "December 12th 2023" this format
  const dayBefore = new Date(dateObject);
  dayBefore.setDate(formatted_day - 1);
  const dayBeforeDay = dayBefore.getDate();
  const dayBeforeMonth = months[dayBefore.getMonth()];
  const dayBeforeYear = dayBefore.getFullYear();
  let dayBeforeFormatted;

  if (dayBeforeDay === 1) {
    dayBeforeFormatted = "1st";
  } else if (dayBeforeDay === 2) {
    dayBeforeFormatted = "2nd";
  } else if (dayBeforeDay === 3) {
    dayBeforeFormatted = "3rd";
  } else {
    dayBeforeFormatted = `${dayBeforeDay}th`;
  }

  const formattedDayBefore = `${dayBeforeMonth} ${dayBeforeFormatted} ${dayBeforeYear}`;

  //this one is calculate for "12/04/2023" for this format
  const day = dateObject.getUTCDate().toString().padStart(2, "0");
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getUTCFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  // const formattedDate = `${month}/${day}/${year}`;

  const subcriptionHandler = async (value) => {
    const userData = {
      subscription_id: superAdminUsersubscriptionid,
      userId: superAdminUserid,
      type: value,
    };
    if (value != undefined) {
      try {
        setRenualLoad(true)
        const response = await axios.post(
          `${url}/admin/payment/switchToManualRenewal`,
          { userData },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.data.success) {
          setApiSuccess(true);
          setSuccessMessage("Data updated successfully.");

          setTimeout(() => {
            dispatch(getAllUsersOfCompany(id));
          }, 2000);
          setAutoRenewalPopupOpen(false);
          setIsCheckboxChecked(false);
          setisSuccess(true);
          setTimeout(() => {
            setisSuccess(false);
          }, 5000);

        }
      } catch (error) {
        
      }
      finally{
        setRenualLoad(false)
      }
    }
  };

  const showToastMessage = () => {
    toast.success("Auto Renewal Cancel Successfully!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleAutoRenewalButtonClick = (isCancel) => {
    setAutoRenewalPopupOpen(true);
    SetpopupContent(
      isCancel
        ? {
            popupLable: "Warning!",
            message: `Your account and all users associated with it will be automatically deactivated on the ${formattedDate}, unless you manually process the payment from your account.`,
            checkboxLable: "Yes, I wish to cancel the auto-renewal",
            buttonText: "Cancel auto-renewal",
            parameter: "cancel",
          }
        : {
            popupLable: "Enable auto-renewal",
            message: `Your account and all users associated with it will be automatically renewed on the ${formattedDate}, unless you choose to disable auto-renewal.`,
            checkboxLable: "Yes, I wish to Enable the auto-renewal",
            buttonText: "Enable auto-renewal",
            parameter: "enable",
          }
    );
  };
  
  const handleAddoneCheckBox = (e, item) => {
    // Handle checkbox change and update selected addons state
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedAddons((prevSelectedAddons) => [...prevSelectedAddons, item]);
    } else {
      setSelectedAddons((prevSelectedAddons) =>
        prevSelectedAddons.filter((addon) => addon !== item)
      );
    }
  };
  const handleContinue = () => {
    // Navigate to checkout with selected addons state
    
    Navigate("/checkout", {
      state: { selectedAddons },
    });
  };
  const handleCloseOfLinkModal = () => {
    setShowLinkModal(false);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Format date
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;

    // Format time
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "pm" : "am";
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} @ ${formattedTime}`;
  }
  function Dateformate(inputDate) {
    const date = new Date(inputDate);

    // Format date
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;

    // Format time
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "pm" : "am";
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate}`;
  }

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    // Format date
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;

    // Format time
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${formattedDate} @ ${hours}:${minutes}`;
  }
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {planName !== "Free" &&
            (superAdminUser?.subscription_details?.billing_cycle === "monthly"
              ? daysRemaining <= 7
              : daysRemaining <= 30) && (
              <div className="onetap-conn-subscription-payment-syn">
                <div className="onetap-conn-subscription-payment-download-syn">
                  <h1>Upcoming payment</h1>
                  <div className="onetap-conn-subscription-download-syn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="21"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM0 16V11H2V14H14V11H16V16H0Z"
                        fill="black"
                      />
                    </svg>
                    <p>Download invoice</p>
                  </div>
                </div>
                <div className="onetap-conn-subscription-payment-text-syn">
                  {isAutoRenewal ? (
                    <div style={{ fontSize: "15px" }}>
                      This is a friendly reminder that your subscription will
                      automatically renew on <b>{formatted_Date}</b> for a total
                      of{" "}
                      <b>
                        $
                        {superAdminUser?.subscription_details?.recurring_amount}
                        . If your payment or billing information have changed,
                        make sure to update them before {formatted_Date}.
                      </b>
                    </div>
                  ) : (
                    <div style={{ fontSize: "14px" }}>
                      This is a friendly reminder that your subscription is due
                      for renewal on <b>{formatted_Date}</b> for a total of{" "}
                      <b>
                        $
                        {superAdminUser?.subscription_details?.recurring_amount}
                        . As your subscription is set for manual payment
                        renewal, you must process the payment before on or{" "}
                        {formattedDayBefore} by clicking on “Renew now”.
                      </b>
                      <br />
                      Alternatively you may enable the auto-renewal and our
                      system process the payment on the renewal date.
                    </div>
                  )}
                </div>
                <div className="onetap-conn-subscription-renew-syn">
                  <button>Renew now</button>
                </div>
              </div>
            )}
          <div className="row" style={{ gap: "" }}>
            <div className="col-md-9">
              {planName !== "Free" && (
                <div className="onetap-conn-subscription-overview-syn">
                  <div className="onetap-conn-subscription-syn">
                    <div className="onetap-conn-subscription-text-id-syn">
                      <h2 style={{ fontSize: "24px" }}>
                        Subscription overview
                      </h2>
                      {/* <p>ID: {userInformationTeamData && userInformationTeamData[0]?.subscription_details?.subscription_id || (userInfo && userInformationTeamData && userInformationTeamData[0]?.subscription_details ? ordernum : '')}</p> */}
                      <p>ID: {ordernum}</p>
                      {isSuccess && (
                        <h6 style={{ color: "#e65925" }}>
                          Subscription Updated
                        </h6>
                      )}
                    </div>
                    <div style={{ position: "relative" }} ref={dropdownRef}>
                      <div
                        className="onetap-conn-subscription-manage-syn"
                        onClick={toggleDropdown}
                        style={{ padding: "10px" }}
                      >
                        Manage Subscription{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                        </svg>
                      </div>

                      {isDropdownOpen && (
                        <div className="onetap-conn-subscription-manage-dropdown-syn">
                          {planName !== "Team" ? (
                            <></>
                          ) : (
                            <a
                              href="#"
                              className="onetap-conn-subscription-manage-dropdown-item"
                            >
                              Add users
                            </a>
                          )}

                          <a
                            // onClick={() => setShowLinkModal(true)}
                            className="onetap-conn-subscription-manage-dropdown-item"
                          >
                            Change plan
                          </a>
                          <a
                            href="#"
                            className="onetap-conn-subscription-manage-dropdown-cancel-syn"
                          >
                            Cancel subscription
                          </a>
                          {planName === "Free" ? (
                            <></>
                          ) : (
                            <a
                              href="#"
                              className="onetap-conn-subscription-manage-dropdown-item"
                              onClick={redirecttopaymentmethod}
                            >
                              Update Payment Method
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {planName !== "Free" && (
                    <div className="onetap-conn-subscription-group-card-syn" style={{gap:'21px',padding:'5px'}}>
                      <div className="onetap-conn-subscription-card-syn">
                        <div>
                          <p>Current Plan</p>
                          <span>
                            {superAdminUser?.subscription_details?.plan}
                          </span>
                        </div>
                        <span
                          role="button"
                          // onClick={() => setShowLinkModal(true)}
                        >
                          Change plan
                        </span>
                      </div>
                      {planName !== "Team" ? (
                        <></>
                      ) : (
                        <div className="onetap-conn-subscription-card-syn">
                          <div>
                            <p>Users</p>
                            <span>{total_user}</span>
                          </div>
                          <a href="#">Add user</a>
                        </div>
                      )}

                      <div className="onetap-conn-subscription-card-syn">
                        <div>
                          <p>Billing Cycle</p>
                          <span>
                            {
                              superAdminUser?.subscription_details
                                ?.billing_cycle
                            }
                          </span>
                        </div>
                      </div>
                      <div className="onetap-conn-subscription-card-syn">
                        <div>
                          <p>Recurring Amount</p>
                          <span>
                            $
                            {
                              superAdminUser?.subscription_details
                                ?.recurring_amount
                            }
                          </span>
                        </div>
                      </div>
                      <div className="onetap-conn-subscription-card-syn">
                        <div>
                          <p>Next Payment</p>
                          <span>{formattedDate}</span>
                        </div>
                        {isAutoRenewal ? (
                          <button
                            onClick={() => handleAutoRenewalButtonClick(true)}
                          >
                            Cancel auto-renewal
                          </button>
                        ) : (
                          <button
                            onClick={() => handleAutoRenewalButtonClick(false)}
                          >
                            Enable auto-renewal
                          </button>
                        )}
                      </div>
                      {superAdminUser?.subscription_details.creditBalance ? (
                        <div className="onetap-conn-subscription-card-syn">
                          <div>
                            <p>Credit Balance</p>
                            <span>
                              {Math.abs(
                                superAdminUser?.subscription_details
                                  ?.creditBalance
                              )}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      <div className="onetap-conn-subscription-card-syn">
                        <div>
                          <p>Payment Method</p>
                          <span>
                            {cardType || cardNumber
                              ? `${cardType}**${cardNumber
                                  .toString()
                                  ?.slice(-4)}`
                              : "Visa**4242"}
                          </span>
                        </div>
                        <a onClick={redirecttopaymentmethod}>Update</a>
                      </div>
                    </div>
                  )}
                  {planName !== "Free" && (
                    <>
                      <div className="onetap-conn-subscription-addons-syn">
                        <span>Active Add-ons</span>
                        <button
                        // onClick={() => {
                        //   setAddAddonsPopupOpen(true);
                        // }}
                        >
                          Add-ons
                        </button>
                      </div>
                      <div className="onetap-conn-subscription-addons-list">
                        {addons && addons.length > 0 ? (
                          <>
                            <div className="onetap-conn-subscription-addon-title">
                              <div>Items</div>
                              <div>Price</div>
                            </div>
                            {addons.map((item, i) => (
                              <div
                                key={i}
                                className="onetap-conn-subscription-addon-item"
                              >
                                <div className="onetap-conn-subscription-addon-name">
                                  <img
                                    src={
                                      item?.image !== ""
                                        ? item?.image
                                        : Addonicon
                                    }
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "4%",
                                    }}
                                  />
                                  <span style={{ marginLeft: "10px" }}>
                                    {item.publicname}
                                  </span>
                                </div>
                                <div className="onetap-conn-subscription-addon-price">
                                  ${" "}
                                  {item.paymentType === "onetimepayment"
                                    ? item?.Addonspaymentdata?.price
                                    : plandata?.type === "yearly"
                                    ? item.Addonspaymentdata?.yearlyprice
                                    : item.Addonspaymentdata?.monthlyprice}
                                  {item.paymentType === "onetimepayment"
                                    ? "(One time)"
                                    : plandata?.type === "yearly"
                                    ? "/year"
                                    : "/month"}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="no-addons-message">
                            No addons available!
                          </div>
                        )}
                      </div>
                    </>
                  )}{" "}
                </div>
              )}
              
              {planName !== "Free" && (
                <div className="onetap-conn-subscription-overview-syn mt-2" style={{alignItems:'flex-start'}}>
                <div className="onetap-conn-subscription-syn mb-2">
                    <div className="onetap-conn-subscription-text-id-syn mb-2">
                      <h2 style={{ fontSize: "24px" }}>Related Order</h2>
                    </div>
                  </div>
                  <table
                    style={{
                      border: "none",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left", fontSize: "20px" }}>
                          Id
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Status
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Payment Date
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userOrderstatedata
                        ?.filter((e) => e.type === "Subscription")
                        ?.map((valueoforder, index) => (
                          <tr
                            key={valueoforder?.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "white",
                              borderRadius: "12px",
                            }}
                          >
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              {valueoforder?.orderNumber}
                            </td>
                            <td
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#F0FEEB",
                                  color: "#48AF25",
                                  border: "1px solid #48AF25",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  width: "150px",
                                  display: "inline-block",
                                  margin: "0px",
                                }}
                              >
                                {valueoforder.status}
                              </p>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            >
                              {Dateformate(valueoforder?.paymentDate)}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                fontWeight: "600",
                              }}
                            >
                              {valueoforder?.totalAmount
                                ? "$" + valueoforder?.totalAmount
                                : "$" +
                                  valueoforder?.subscription_details
                                    ?.total_amount}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {planName !== "Free" && (
              <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                <div
                  className="onetap-conn-subscription-overview-syn"
                  style={{ display: "inherit",padding:'15px 15px' }}
                >
                  <div className="onetap-conn-subscription-syn mb-2">
                    <div className="onetap-conn-subscription-text-id-syn">
                      <h4 style={{ fontSize: "18px" }}>Information</h4>
                    </div>
                  </div>
                  <p
                    className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0"
                    style={{ fontSize: "14px" }}
                  >
                    Deal Owner
                  </p>
                  <input
                    type="text"
                    className="w-100 border-0 p-2 no-spinners mb-3"
                    name=""
                    id=""
                    style={{
                      borderRadius: " 4px",
                      appearance: "textfield",
                      fontSize: "14px",
                      background: "#f2f2f2",
                    }}
                    value={
                      companydata?.owner_first_name
                        ? companydata.owner_first_name
                            ?.charAt(0)
                            .toUpperCase() +
                          companydata.owner_first_name?.slice(1) +
                          " " +
                          companydata.owner_last_name?.charAt(0).toUpperCase() +
                          companydata.owner_last_name?.slice(1)
                        : ""
                    }
                    readOnly
                  />
                </div>
                <div
                  className="onetap-conn-subscription-overview-syn mt-2"
                  style={{ display: "inherit",padding:'15px 15px'}}
                >
                  <div className="onetap-conn-subscription-syn mb-2">
                    <div className="onetap-conn-subscription-text-id-syn">
                      <h4 style={{ fontSize: "18px" }}>Schedule</h4>
                    </div>
                  </div>
                  <p
                    className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0"
                    style={{ fontSize: "14px" }}
                  >
                    Created date
                  </p>
                  <input
                    type="text"
                    className="w-100 border-0 p-2 no-spinners mb-3"
                    name=""
                    id=""
                    style={{
                      borderRadius: " 4px",
                      appearance: "textfield",
                      fontSize: "14px",
                      background: "#f2f2f2",
                    }}
                    value={formatDate(companydata?.createdAt)}
                    readOnly
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="mb-3"
                  >
                    {" "}
                    <p className="m-0" style={{ fontSize: "14px" }}>
                      Billing period
                    </p>
                    <input
                      type="text"
                      className="border-0 p-2 no-spinners "
                      style={{
                        borderRadius: " 4px",
                        appearance: "textfield",
                        fontSize: "14px",
                        width: "62%",
                        background: "#f2f2f2",
                      }}
                      value={
                        superAdminUser?.subscription_details?.billing_cycle
                          ?.charAt(0)
                          .toUpperCase() +
                        superAdminUser?.subscription_details?.billing_cycle?.slice(
                          1
                        )
                      }
                      readOnly
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="mb-3"
                  >
                    {" "}
                    <p className="m-0" style={{ fontSize: "14px" }}>
                      Next payment
                    </p>
                    <input
                      type="text"
                      className="border-0 p-2 no-spinners "
                      style={{
                        borderRadius: " 4px",
                        appearance: "textfield",
                        background: "#f2f2f2",
                        fontSize: "14px",
                      }}
                      value={formatDateFromTimestamp(
                        superAdminUser?.subscription_details?.endDate
                      )}
                      readOnly
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="mb-3"
                  >
                    {" "}
                    <p className="m-0" style={{ fontSize: "14px" }}>
                      End date
                    </p>
                    <input
                      type="text"
                      className="border-0 p-2 no-spinners "
                      style={{
                        borderRadius: " 4px",
                        appearance: "textfield",
                        background: "#f2f2f2",
                        fontSize: "14px",
                      }}
                      value={formatDateFromTimestamp(
                        superAdminUser?.subscription_details?.endDate
                      )}
                      readOnly
                    />
                  </div>
                  <p
                    className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0"
                    style={{ fontSize: "14px" }}
                  >
                    Time Zone
                  </p>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      className="border-0 p-2 no-spinners "
                      style={{
                        borderRadius: " 4px",
                        appearance: "textfield",
                        fontSize: "14px",
                        width: "100%",
                        background: "#f2f2f2",
                      }}
                      value={companydata?.timeZone}
                      readOnly
                    />
                  </div>
                </div>
                <div
                  className="onetap-conn-subscription-overview-syn mt-2"
                  style={{ display: "inherit" ,padding:'15px 15px'}}
                >
                  <div className="onetap-conn-subscription-syn mb-2">
                    <div className="onetap-conn-subscription-text-id-syn">
                      <h4 style={{ fontSize: "18px" }}>Order note</h4>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="w-100 border-0  no-spinners mb-3"
                    name=""
                    id=""
                    style={{
                      borderRadius: " 4px",
                      appearance: "textfield",
                      fontSize: "14px",
                      padding: "5px 5px 50px 5px",
                      background: "#f2f2f2",
                    }}
                    value="Include shipping and add ons note"
                    readOnly
                  />
                </div>
                <div
                  className="onetap-conn-subscription-overview-syn mt-2"
                  style={{ display: "inherit",padding:'15px 15px' }}
                >
                  <div className="onetap-conn-subscription-syn mb-2">
                    <div className="onetap-conn-subscription-text-id-syn">
                      <h4 style={{ fontSize: "18px" }}>Activity log</h4>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="w-100 border-0  no-spinners mb-3"
                    name=""
                    id=""
                    style={{
                      borderRadius: " 4px",
                      appearance: "textfield",
                      fontSize: "14px",
                      padding: "5px 5px 50px 5px",
                      background: "#f2f2f2",
                    }}
                    value="Show log"
                    readOnly
                  />
                </div>
              </div>
            )}
          </div>
          {planName === "Free" && (
            <div className="upgrade-btn-billSub-group">
              <h2
                className="freeAccount-header"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                Upgrade plan
              </h2>
              <div className="d-flex justify-content-center">
                <div
                  className="upgrade-btn-billSub"
                  style={{ width: "20%" }}
                  // onClick={() => setShowLinkModal(true)}
                >
                  Click Here to Upgrade your Plan
                </div>
              </div>
            </div>
          )}

          <div>
            <Modal
              open={autoRenewalPopupOpen}
              onClose={() => {
                setAutoRenewalPopupOpen(false);
                setIsCheckboxChecked(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={
                  popupContent.parameter === "cancel"
                    ? style
                    : Enable_subscription_style
                }
              >
                <div
                  className={`onetap_conn_subscription_renewal_popup_header ${popupContent.parameter}`}
                >
                  <div
                    className={`onetap_conn_subscription_renewal_popup_header_name ${popupContent.parameter}`}
                  >
                    {popupContent.popupLable}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setAutoRenewalPopupOpen(false)}
                  ></div>
                </div>
                <div className="onetap_conn_subscription_renewal_popup_content">
                  {popupContent.message}
                </div>
                <div className="onetap_conn_subscription_renewal_popup_checkbox">
                  <input
                    type="checkbox"
                    className={`onetap_conn_subscription_renewal_popup_checkbox_input ${popupContent.parameter}`}
                    onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                  />
                  <div>{popupContent.checkboxLable}</div>
                  {/* <div>Yes, I wish to cancel the auto-renewal</div> */}
                </div>
                <div className="onetap_conn_subscription_renewal_popup_button">
                  <button
                    className={`onetap_conn_subscription_renewal_popup_button_cancel ${
                      popupContent.parameter
                    } ${!isCheckboxChecked ? "disabled" : ""}`}
                    disabled={!isCheckboxChecked || renualLoad}
                    onClick={() => {
                      if (isCheckboxChecked) {
                        subcriptionHandler(popupContent.parameter);
                      }
                    }}
                  >
                   {renualLoad ? "Loading..." : popupContent.buttonText} 
                  </button>
                  <button
                    className={`onetap_conn_subscription_renewal_popup_button_back ${popupContent.parameter}`}
                    onClick={() => {
                      setAutoRenewalPopupOpen(false);
                      setIsCheckboxChecked(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
          <div>
            <Modal
              open={addAddonsPopupOpen}
              onClose={() => setAddAddonsPopupOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={popupStyle}>
                <div
                  style={{
                    borderBottom: "1px solid #F2F2F2",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="onetap_conn_send_email_modal_header">
                    Select Add-ons
                  </div>
                </div>
                <div className="options">
                  {excludingAddons &&
                    excludingAddons
                      .filter(
                        (item) =>
                          item.visibility === "Public" &&
                          item.status === "Published"
                      )
                      .map((item, i) => {
                        return (
                          <div key={i} className="p-3">
                            <div
                              className="option"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                onChange={(e) => handleAddoneCheckBox(e, item)}
                              />
                              <p style={{ fontWeight: "700" }}>
                                {item.publicname} - $
                                {item.paymentType === "onetimepayment"
                                  ? item?.Addonspaymentdata?.price
                                  : plandata?.type === "yearly"
                                  ? item.Addonspaymentdata?.yearlyprice
                                  : item.Addonspaymentdata?.monthlyprice}
                                {item.paymentType === "onetimepayment"
                                  ? "(One time)"
                                  : plandata?.type === "yearly"
                                  ? "/yearly"
                                  : "/monthly"}
                              </p>
                            </div>

                            <p className="message">
                              {item.shortDescription ? (
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "14px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<style> p { margin: 0; } </style> ${item.shortDescription}`,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              <Link
                                to={`${frontendURL}/addons/${item.CustomPermalink}`}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Learn more
                              </Link>
                            </p>
                          </div>
                        );
                      })}{" "}
                </div>
                <div className="Modal-btn-group">
                  {selectedAddons.length > 0 ? (
                    <button
                      className="Modal-btn first-modal-btn"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      className="Modal-btn first-modal-btn"
                      style={{ opacity: "0.6" }}
                      disabled
                    >
                      Continue
                    </button>
                  )}

                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => {
                      setAddAddonsPopupOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div></div>
              </Box>
            </Modal>
          </div>
          <Modal
            open={showLinkModal}
            onClose={handleCloseOfLinkModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="Modal-section">
                <h2 className="header" style={{ backgroundColor: "#F2F2F2" }}>
                  Change plan
                  <CloseIcon
                    className="icon"
                    onClick={handleCloseOfLinkModal}
                  />
                </h2>
                <div className={`sliding-switch ${isActive ? "active" : ""}`}>
                  <div className="switch-handle"></div>
                  <div className="switch-text">
                    <span
                      className={`switch-text-option ${
                        isActive ? "active" : ""
                      }`}
                      onClick={handleToggle}
                    >
                      Pay Monthly
                    </span>
                    <span
                      className={`switch-text-option ${
                        !isActive ? "active" : ""
                      }`}
                      onClick={handleToggle}
                    >
                      Yearly and save 20%
                    </span>
                  </div>
                </div>
                {isActive ? (
                  <>
                    <div
                      className="plan-selection-section"
                      style={{ width: "max-content" }}
                    >
                      {plandata &&
                        plandata.map((plans, i) => {
                          if (plans.isActive === false) {
                            return;
                          }
                          if (plans.type === "monthly") {
                            return;
                          }
                          if (plans.planName === "Free") {
                            return (
                              <YearlyCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Professional") {
                            return (
                              <YearlyCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Team") {
                            return (
                              <YearlyCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Enterprise") {
                            return (
                              <YearlyCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                        })}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="plan-selection-section"
                      style={{ width: "max-content" }}
                    >
                      {plandata &&
                        plandata.map((plans, i) => {
                          if (plans.isActive === false) {
                            return;
                          }
                          if (plans.type === "yearly") {
                            return;
                          }
                          if (plans.planName === "Free") {
                            return (
                              <PlanCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Professional") {
                            return (
                              <PlanCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Team") {
                            return (
                              <PlanCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                          if (plans.planName === "Enterprise") {
                            return (
                              <PlanCard
                                props={plans}
                                key={i}
                                cycle={isActive}
                              />
                            );
                          }
                        })}
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Modal>
          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: "center" }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      class="circle"
                    >
                      <path
                        d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                        fill="#E65925"
                      />
                      <path
                        d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
                        stroke="white"
                        stroke-width="6.64"
                        stroke-linecap="round"
                        class="checkmark-path"
                      />
                    </svg>
                  </div>
                </div>
                <span className="team_created_success">{successMessage}</span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  width: "13%",
                }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: "#e65925" }}
                  />
                </span>
              </button>
            </Box>
          </Modal>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
        </>
      )}
    </>
  );
};

export default ClientCompanySubscriptionandaddons;
