import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import Otc_CreateOrder from "./Otc_CreateOrder";
import Otc_CreateOrdersHeadersWithIcons from "./Otc_CreateOrderHeaderWithIcon";

const Otc_Orders = () => {
    const [OtcOrdersTab, setOtcOrdersTab] = useState("Create Order");
    const getTabValue = (tabVal) => {
        setOtcOrdersTab(tabVal);
    };
    const getValueOfSaveAndContinueTab = (value) => {
        setOtcOrdersTab(value);
    };

    return (
        <div>
            <div className="otc_onetap_otcorders_profile_syn">
                <Otc_CreateOrdersHeadersWithIcons onChange={getTabValue} tab={OtcOrdersTab} />
                {OtcOrdersTab === "Create Order" && (
                    <Otc_CreateOrder
                        onClickSave={getValueOfSaveAndContinueTab}
                    />
                )}
            </div>
        </div>
    );
};

const CreateOrderMain = () => {
    return (
        <>
            <Otc_Sidebar Component={Otc_Orders} />
        </>
    );
};

export default CreateOrderMain;
