import { createReducer } from "@reduxjs/toolkit";

const otcAdmininitialState = {
  adminuser: null,
  adminloading: false,
  isOtcAdminAuthenticated: false,
  Adminerror: null,
  message: null,
};

export const OtcAdminReducer = createReducer(
  otcAdmininitialState,
  (builder) => {
    builder
      .addCase("LOGIN_ADMIN_USER_REQUEST", (state, action) => {
        state.adminloading = true;
        state.adminuser = null;
        state.isOtcAdminAuthenticated = false;
        state.Adminerror = null;
      })
      .addCase("LOGIN_ADMIN_USER_SUCCESS", (state, action) => {
        state.adminloading = false;
        state.adminuser = action.payload.user;
        state.isOtcAdminAuthenticated = action.payload.success || true;
      })
      .addCase("LOGIN_ADMIN_USER_FAILURE", (state, action) => {
        state.adminloading = false;
        state.adminuser = null;
        state.isOtcAdminAuthenticated = false;
        state.Adminerror = action.payload.message;
      })
      .addCase("LOGOUT_ADMIN_USER", (state) => {
        state.adminuser = null;
        state.adminloading = false;
        state.isOtcAdminAuthenticated = false;
        state.Adminerror = null;
      });

    builder.addCase("LOAD_ADMIN_USER_REQUEST", (state, action) => {
      state.adminuser = null;
      state.adminloading = true;
      state.isOtcAdminAuthenticated = false;
    });

    builder.addCase("LOAD_ADMIN_USER_SUCCESS", (state, action) => {
      state.adminuser = action.payload.user;
      state.adminloading = false;
      state.isOtcAdminAuthenticated = action.payload.success || true;
    });

    builder.addCase("LOAD_ADMIN_USER_FAIL", (state, action) => {
      state.adminuser = null;
      state.adminloading = false;
      state.isOtcAdminAuthenticated = false;
    });

    builder.addCase("ADMIN_FORGOT_PASSWORD_REQUEST", (state, action) => {
      state.adminloading = true;
      state.adminuser = null;
      state.isOtcAdminAuthenticated = false;
      state.Adminerror = null;
    });

    builder.addCase("ADMIN_FORGOT_PASSWORD_SUCCESS", (state, action) => {
      state.adminloading = false;
      state.adminuser = action.payload.user;
      state.isOtcAdminAuthenticated = false;
      state.Adminerror = null;
      state.message = action.payload.message;
    });
    builder.addCase("ADMIN_FORGOT_PASSWORD_FAILURE", (state, action) => {
      state.adminloading = false;
      state.adminuser = null;
      state.isOtcAdminAuthenticated = false;
      state.Adminerror = action.payload;
    });
    builder.addCase("ADMIN_RESET_PASSWORD_REQUEST", (state, action) => {
      state.adminloading = true;
      state.adminuser = null;
      state.isOtcAdminAuthenticated = false;
      state.Adminerror = null;
    });

    builder.addCase("ADMIN_RESET_PASSWORD_SUCCESS", (state, action) => {
      state.adminloading = false;
      state.message = action.payload.message;
      state.Adminerror = null;
    });
    builder.addCase("ADMIN_RESET_PASSWORD_FAILURE", (state, action) => {
      state.adminloading = false;
      state.adminuser = null;
      state.isOtcAdminAuthenticated = false;
      state.Adminerror = action.payload;
    });
    builder.addCase("ADMIN_CLEAR_ERROR", (state, action) => {
      state.Adminerror = null;
    });
    builder.addCase("ADMIN_CLEAR_MESSAGE", (state, action) => {
      state.message = null;
    });
  }
);

const allcompanyusersinitialState = {
  loading: false,
  userInformationTeamData: null,
  companydata: null,
  allteamdata: null,
  error: null,
};

export const usersOfCompanyReducer = createReducer(
  allcompanyusersinitialState,
  (builder) => {
    builder.addCase("GET_USERS_OF_COMPANY_REQUEST", (state, action) => {
      state.loading = true;
      state.userInformationTeamData = null;
      state.companydata = null;
      state.error = null;
    });
    builder.addCase("GET_USERS_OF_COMPANY_SUCCESS", (state, action) => {
      state.loading = false;
      state.userInformationTeamData = action.payload.userInformationTeamData;
      state.companydata = action.payload.companydata;
      state.allteamdata = action.payload.allteamdata;
      state.error = null;
    });
    builder.addCase("GET_USERS_OF_COMPANY_FAIL", (state, action) => {
      state.loading = false;
      state.userInformationTeamData = null;
      state.companydata = null;
      state.error = action.payload;
    });
    builder.addCase("UPDATE_COMPANY_DATA", (state, action) => {
      state.companydata = action.payload;
    });
  }
);

const getPlansInitialState = {
  loading: false,
  plans: null,
  error: null,
};

export const getPlanReducer = createReducer(getPlansInitialState, (builder) => {
  builder.addCase("GET_PLANS_REQUEST", (state, action) => {
    state.loading = true;
    state.plans = null;
  });

  builder.addCase("GET_PLANS_SUCCESS", (state, action) => {
    state.loading = false;
    state.plans = action.payload.plans;
  });

  builder.addCase("GET_PLANS_FAILURE", (state, action) => {
    state.loading = false;
    state.plans = null;
    state.error = action.payload;
  });
  builder.addCase("UPLOAD_PROFILE_PICTURE_REQUEST", (state, action) => {
    state.loading = true;
    state.message = null;
    state.error = null;
  });
  builder.addCase("UPLOAD_PROFILE_PICTURE_SUCCESS", (state, action) => {
    state.loading = false;
    state.message = action.payload.message;
  });
  builder.addCase("UPLOAD_PROFILE_PICTURE_FAIL", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
  builder.addCase("UPLOAD_LOGO_REQUEST", (state, action) => {
    state.loading = true;
    state.message = null;
    state.error = null;
  });
  builder.addCase("UPLOAD_LOGO_SUCCESS", (state, action) => {
    state.loading = false;
    state.message = action.payload.message;
  });
  builder.addCase("UPLOAD_LOGO_FAIL", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
});

const invitedUsersInitailstate = {
  invitedusers_loading: false,
  invitedusers: null,
  message: null,
  error: null,
};

export const invitedUsersReducerforAdminpanel = createReducer(
  invitedUsersInitailstate,
  (builder) => {
    builder.addCase("GET_INVITEDUSERS_REQUEST", (state, action) => {
      state.invitedusers_loading = true;
      state.invitedusers = null;
    });

    builder.addCase("GET_INVITEDUSERS_SUCCESS", (state, action) => {
      state.invitedusers_loading = false;
      state.invitedusers = action.payload.invitedusers;
    });

    builder.addCase("GET_INVITEDUSERS_FAILURE", (state, action) => {
      state.invitedusers_loading = false;
      state.invitedusers = null;
      state.error = action.payload;
    });
  }
);

const getAllOrdersInitialState = {
  loading: false,
  allOrders: null,
  message: null,
};

export const getAllOrdersReducer = createReducer(
  getAllOrdersInitialState,
  (builder) => {
    builder.addCase("GET_ALLORDERS_REQUEST", (state, action) => {
      state.loading = true;
      state.allOrders = null;
    });

    builder.addCase("GET_ALLORDERS_SUCCESS", (state, action) => {
      state.loading = false;
      state.allOrders = action.payload.allOrders;
    });

    builder.addCase("GET_ALLORDERS_FAILURE", (state, action) => {
      state.loading = false;
      state.allOrders = null;
      state.error = action.payload;
    });
  }
);

const getOrderInitialState = {
  loading: false,
  order: null,
  message: null,
};

export const getSingleOrderReducer = createReducer(
  getOrderInitialState,
  (builder) => {
    builder.addCase("GET_ORDER_REQUEST", (state, action) => {
      state.loading = true;
      state.order = null;
    });

    builder.addCase("GET_ORDER_SUCCESS", (state, action) => {
      state.loading = false;
      state.order = action.payload.order;
    });

    builder.addCase("GET_ORDER_FAILURE", (state, action) => {
      state.loading = false;
      state.order = null;
      state.error = action.payload;
    });
  }
);

const allusersinitialState = {
  loading: false,
  usersofcompany: null,
  teamsofcompany: null,
  error: null,
};

export const allusersOfCompanyReducer = createReducer(
  allusersinitialState,
  (builder) => {
    builder.addCase(
      "GET_TEAM_AND_USERS_OF_COMPANY_REQUEST",
      (state, action) => {
        state.loading = true;
        state.usersofcompany = null;
        state.error = null;
      }
    );
    builder.addCase(
      "GET_TEAM_AND_USERS_OF_COMPANY_SUCCESS",
      (state, action) => {
        state.loading = false;
        state.usersofcompany = action.payload.usersofcompany;
        state.teamsofcompany = action.payload.teamsofcompany;
        state.error = null;
      }
    );
    builder.addCase("GET_TEAM_AND_USERS_OF_COMPANY_FAIL", (state, action) => {
      state.loading = false;
      state.usersofcompany = null;
      state.teamsofcompany = null;
      state.error = action.payload;
    });
  }
);

const otcshippingAddressInitialState = {
  loading: false,
  otcshippingAddress: null,
};

export const OtcshippingAddressReducer = createReducer(
  otcshippingAddressInitialState,
  (builder) => {
    builder.addCase("OTC_CREATE_SHIPPING_ADDRESS_REQUEST", (state, action) => {
      state.otcshippingAddress = null;
      state.loading = true;
    });

    builder.addCase("OTC_CREATE_SHIPPING_ADDRESS_SUCCESS", (state, action) => {
      state.otcshippingAddress = action.payload.message;
      state.loading = false;
    });

    builder.addCase("OTC_CREATE_SHIPPING_ADDRESS_FAILURE", (state, action) => {
      state.otcshippingAddress = null;
      state.loading = false;
    });
    //--------------------------------------------------------
    builder.addCase("OTC_SHIPPINGADDRESSES_REQUEST", (state, action) => {
      state.otcshippingAddress = null;
      state.loading = true;
    });

    builder.addCase("OTC_SHIPPINGADDRESSES_SUCCESS", (state, action) => {
      state.otcshippingAddress = action.payload.shippingAddresses;
      state.loading = false;
    });

    builder.addCase("OTC_SHIPPINGADDRESSES_FAILURE", (state, action) => {
      state.otcshippingAddress = null;
      state.loading = false;
    });
  }
);

const otcbillingAddressInitialState = {
  loading: false,
  otcbillingAddresses: null,
};

export const otcbillingAddressReducer = createReducer(
  otcbillingAddressInitialState,
  (builder) => {
    builder.addCase("OTC_FETCH_BILLING_ADDRESS_REQUEST", (state, action) => {
      state.otcbillingAddresses = null;
      state.loading = true;
    });

    builder.addCase("OTC_FETCH_BILLING_ADDRESS_SUCCESS", (state, action) => {
      state.otcbillingAddresses = action.payload.billingData.billing_address;
      state.loading = false;
    });

    builder.addCase("OTC_FETCH_BILLING_ADDRESS_FAILED", (state, action) => {
      state.otcbillingAddresses = null;
      state.loading = false;
    });
  }
);

const otcadminteamsInitialState = {
  loading: false,
  otcadminteams: null,
};

export const otcadminteamsReducer = createReducer(
  otcadminteamsInitialState,
  (builder) => {
    builder.addCase("GET_OTC_ADMIN_TEAMS_REQUEST", (state, action) => {
      state.otcadminteams = null;
      state.loading = true;
    });

    builder.addCase("GET_OTC_ADMIN_TEAMS_SUCCESS", (state, action) => {
      state.otcadminteams = action.payload.teams;
      state.loading = false;
    });

    builder.addCase("GET_OTC_ADMIN_TEAMS_FAILURE", (state, action) => {
      state.otcadminteams = null;
      state.loading = false;
    });
  }
);