import React from 'react'
import Header2 from '../../Layout/Header/Header2'

export default function Refundpolicy() {
  return (
    <>
      <div className="tncmaindiv" style={{ backgroundColor: "#f2f2f2" }}>
        <Header2 />
        <div
          className="container mt-4"
          style={{ backgroundColor: "white", maxWidth: "1140px" }}
        >
          <div className="" style={{ padding: "50px 50px 30px 50px" }}>
            <h1 className="tnch1">Refund Policy</h1>
            <div className="coming_main_h">
              <div className="coming_h">
                <h1>Coming Soon...!</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
