import React, { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import UserHeadersWithIcons from "./UserHeadersWithIcons";
import UsersInfoPage from "./UsersInfoPage";
import UserInvite from "./UserinvitePage";
import TeamsOverview from "./TeamsOverview";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("Users");
  const [teamName, setteamName] = useState("");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };

  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };

  const navigateToUsersPage = () => {
    setCompanyProfileTab("Users");
  };

  const getTeamNameforfilter = (value) => {
    setteamName(value);
  };

  return (
    <div className="onetap_conn_user">
      <UserHeadersWithIcons onChange={getTabValue} tab={companyProfileTab} />
      {companyProfileTab === "Users" && (
        <UsersInfoPage
          onClickSave={getValueOfSaveAndContinueTab}
          teamName={teamName}
        />
      )}
      {companyProfileTab === "Team" && (
        <TeamsOverview
          onClickSave={getValueOfSaveAndContinueTab}
          onViewUsersClick={navigateToUsersPage}
          getTeamNameforfilter={getTeamNameforfilter}
        />
      )}
      {companyProfileTab === "Invited" && (
        <UserInvite onClickSave={getValueOfSaveAndContinueTab} />
      )}
    </div>
  );
};

const User = () => {
  return <Sidebar Component={Component} />;
};

export default User;
