import React from "react";

import "./otc_comingsoon.css";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";

const Component = () => {

  return (
    <>
      <div className="otc_coming_main_h">
        <div className="otc_coming_h">
          <h1>Coming Soon...!</h1>
        </div>
      </div>
    </>
  );
};
const Otc_comigsoon = () => {
  return (
    <>
      <Otc_Sidebar Component={Component} productsServicesOpen={false}/>
    </>
  );
};
export default Otc_comigsoon;

const Otc_comigsoonforaddons = () => {
  return (
    <>
      <Otc_Sidebar Component={Component} productsServicesOpen2={true}/>
    </>
  );
};
const Otc_comigsoonforplans = () => {
  return (
    <>
      <Otc_Sidebar Component={Component} productsServicesOpen={true}/>
    </>
  );
};
export  {Otc_comigsoonforaddons , Otc_comigsoonforplans };
