import React, { useEffect, useState } from "react";
import "./comingsoon.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import PageNotFound from "../404/PageNotFound";


const url = process.env.REACT_APP_URL;

const ViewCardComingsoon = () => {

  const { slug } = useParams();
  const paramsdata = useParams()
  const [userFound, setUserFound] = useState(true);

  const navigate = useNavigate();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  
  
  const redirectAPI = async (slug) => {
    try {
      const { data } = await axios.post(
        `${url}/user/redirect`,
        { slug: slug },
        config
      );
      if (data.success) {
        if (data.slug !== slug) {
          if(paramsdata?.companyname){
            navigate(`/${data?.companyslug}/${data.slug}`);
          }else{
            navigate(`/${data.slug}`);
          }
        }
      } else {
      setUserFound(false)
        
      }
    } catch (error) {
      setUserFound(false)
    }
  };
  useEffect(()=>{
    if(slug){
      redirectAPI(slug)
    }else{
      
    }
  },[])

  return (
    <>
     {userFound ? (
        <div className="coming_main_h">
          <div className="coming_h">
            <h1>Coming Soon...!</h1>
          </div>
        </div>
      ) : (
        <>
        {/* <PageNotFound /> */}
        </>
      )}
    </>
  );
};
export default ViewCardComingsoon;
