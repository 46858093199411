import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'
import axios from "axios";
import './subdetails.css'
import { useParams } from 'react-router-dom';
const url = process.env.REACT_APP_URL;
function SubscriptionDetailsInfo() {
  const [rows, setRows] = useState([]);
  const dropdownRef = useRef(null);
  const id = useParams();
  const fetchSubscriptiondetails = async () => {
    try {
      const response = await axios.post(`${url}/admin/SubsciptiondetialsofiD`, id, {
        header: {
          "Content-Type": "application/json"
        },
        withCredentials: true,
      })

      setRows(response.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    fetchSubscriptiondetails()
  }, [id])


  const formatDate = (dateString) => {
    if (!dateString) return ''; // handle null or undefined case

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);

    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div className="row gap-3">
      <div className="col-8 gap-3">
        <div className="row mb-3">
          <div className='abcd'>
            <div className='abcd2'>
              <p className='upcoming_p'>Upcoming Payment</p>
              <div style={{ display: 'flex' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM4 20V15H6V18H18V15H20V20H4Z" fill="black" />
                </svg>
                <p className='d_invoice'>Download Invoice</p>
              </div>
            </div>
            <p className='text_pay'>This section only appear when payment is due in less than 30 days on yearly plans and 7 days on monthly plans and provide overview of the upcoming charge. Important for manual renewals.</p>
            <div className='abcd3'>
              <div >
                <Button variant="contained" style={{ backgroundColor: '#E65925', color: '#F7F5BB' }} className='button_r'>Renew Now</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="onetap-conn-subscription-overview-syn" style={{ padding: '20px' }}>
            <div className="onetap-conn-subscription-syn">
              <div className="onetap-conn-subscription-text-id-syn">
                <h1>Subscription overview</h1>
                <p className='_id'>ID #{rows?.orderNumber}</p>
              </div>
              <div style={{ position: "relative" }} ref={dropdownRef}>
                <div
                  className="onetap-conn-subscription-manage-syn"
                  style={{ height: '45px' }}
                >
                  Manage Subscription{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="onetap-conn-subscription-group-card-syn" style={{ gap: '10px' }}>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Current Plan</p>
                  <span style={{ fontSize: '15px' }}>{rows?.subscription_details?.plan}</span>
                </div>
                <span role="button"  >
                  Change plan
                </span>
              </div>
              <></>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Users</p>
                  <span style={{ fontSize: '15px' }}>{rows?.subscription_details?.total_user[0]?.baseUser + rows?.subscription_details?.total_user[0]?.additionalUser}</span>
                </div>
                <a href="#">Add user</a>
              </div>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Billing Cycle</p>
                  <span style={{ fontSize: '15px' }}>{rows?.subscription_details?.billing_cycle}</span>
                </div>
              </div>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Recurring Amount</p>
                  <span style={{ fontSize: '15px' }}>

                  </span>
                </div>
              </div>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Next Payment</p>
                  <span style={{ fontSize: '15px' }}></span>
                </div>
              </div>
              <div className="onetap-conn-subscription-card-syn">
                <div>
                  <p style={{ fontSize: '10px' }}>Payment Method</p>
                  <span style={{ fontSize: '15px' }}>

                  </span>
                </div>
                <a >Update</a>
              </div>
            </div>
            <>
              <div className="onetap-conn-subscription-addons-syn">
                <span>Add-ons</span>
                <button
                  onClick={() => {
                  }}
                >
                  Add-ons
                </button>
              </div>
              <div className="onetap-conn-subscription-addons-list">

                <>
                  <div className="onetap-conn-subscription-addon-title">
                    <div>Name</div>
                    <div>Price</div>
                  </div>

                  <div
                    className="onetap-conn-subscription-addon-item"
                  >
                    <div className="onetap-conn-subscription-addon-name">
                    </div>
                    <div className="onetap-conn-subscription-addon-price">
                      ${" "}

                    </div>
                  </div>
                </>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="row mb-3">
          <div style={{ padding: "20px 25px 25px 25px", height: '405px', borderRadius: '4px', backgroundColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ fontSize: '20px', fontWeight: '600', fontStyle: 'normal', lineHeight: 'normal' }}>Client Information</p>
              <p style={{ fontSize: '12px', fontStyle: 'normal', fontWeight: '700', lineHeight: '20px', color: '#48AF25' }}>Active</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ height: '100%', width: '22%' }}>
                <div className="profile-info_OTC_Admin">
                  <div className="user-info-avatar-container_OTC_Admin">
                    <img
                      alt="Profile"
                      style={{
                        width: "54px",
                        height: "54px",
                        borderRadius: "100%",
                      }}
                    />
                    <div
                      className="user-info-initials-overlay_OTC_Admin"
                      style={{ width: "54px", height: "54px" }}
                    >
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className='name_john'>{rows.first_name + rows.last_name}</div>
                  <div className='nick_nmae'>{rows.last_name}</div>
                </div>
              </div>
            </div>
            <div className='role'>Role : <span className='nick_nmae'>Administrator</span></div>
            <div className='role2'>Plan : <span className='nick_nmae' style={{ color: '#2572E6' }}>Team</span></div>
            <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '90%' }}>
                <div className='role'>Email:</div>
                <div className='abc'>{rows.email}</div>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
                  <path d="M26.9984 3.00121C26.9984 1.53477 25.7986 0.334961 24.3322 0.334961H3.00219C1.53575 0.334961 0.335938 1.53477 0.335938 3.00121V18.9987C0.335938 20.4651 1.53575 21.665 3.00219 21.665H24.3322C25.7986 21.665 26.9984 20.4651 26.9984 18.9987V3.00121ZM24.3322 3.00121L13.6672 9.66684L3.00219 3.00121H24.3322ZM24.3322 18.9987H3.00219V5.66746L13.6672 12.3331L24.3322 5.66746V18.9987Z" fill="#333333" />
                </svg>
              </div>
            </div>
            <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '90%' }}>
                <div className='role'>Cell phone:</div>
                <div className='abc'>+1 704 415 3944</div>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="31" viewBox="0 0 21 31" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33698 3.49962C2.33698 3.19023 2.45989 2.89352 2.67865 2.67475C2.89742 2.45598 3.19414 2.33308 3.50352 2.33308H17.502C17.8114 2.33308 18.1081 2.45598 18.3269 2.67475C18.5456 2.89352 18.6685 3.19023 18.6685 3.49962V26.8304C18.6685 27.1398 18.5456 27.4365 18.3269 27.6553C18.1081 27.874 17.8114 27.9969 17.502 27.9969H3.50352C3.19414 27.9969 2.89742 27.874 2.67865 27.6553C2.45989 27.4365 2.33698 27.1398 2.33698 26.8304V3.49962ZM3.50352 0C2.57537 0 1.68523 0.368709 1.02892 1.02501C0.372615 1.68132 0.00390625 2.57146 0.00390625 3.49962V26.8304C0.00390625 27.7585 0.372615 28.6487 1.02892 29.305C1.68523 29.9613 2.57537 30.33 3.50352 30.33H17.502C18.4301 30.33 19.3203 29.9613 19.9766 29.305C20.6329 28.6487 21.0016 27.7585 21.0016 26.8304V3.49962C21.0016 2.57146 20.6329 1.68132 19.9766 1.02501C19.3203 0.368709 18.4301 0 17.502 0H3.50352ZM7.00314 24.8473C6.78657 24.8473 6.57887 24.9333 6.42573 25.0864C6.27259 25.2396 6.18656 25.4473 6.18656 25.6638C6.18656 25.8804 6.27259 26.0881 6.42573 26.2413C6.57887 26.3944 6.78657 26.4804 7.00314 26.4804H14.0024C14.2189 26.4804 14.4266 26.3944 14.5798 26.2413C14.7329 26.0881 14.8189 25.8804 14.8189 25.6638C14.8189 25.4473 14.7329 25.2396 14.5798 25.0864C14.4266 24.9333 14.2189 24.8473 14.0024 24.8473H7.00314Z" fill="#333333" />
                </svg>
              </div>
            </div>
            <div style={{ marginTop: '5px', marginBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ width: '90%' }}>
                <div className='role'>Office number :</div>
                <div className='abc'>+1 704 415 3944</div>
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                  <path d="M22 16.6708V20.9435C22.0001 21.2494 21.8842 21.544 21.6757 21.7678C21.4671 21.9916 21.1814 22.128 20.8762 22.1494C20.3482 22.1856 19.9168 22.205 19.5833 22.205C8.90529 22.205 0.25 13.5497 0.25 2.87162C0.25 2.53812 0.268125 2.10675 0.305583 1.57871C0.326998 1.27353 0.463369 0.987844 0.687179 0.779283C0.91099 0.570723 1.20558 0.454818 1.5115 0.454956H5.78417C5.93405 0.454805 6.07863 0.510369 6.18984 0.610856C6.30104 0.711343 6.37093 0.849577 6.38592 0.998706C6.41371 1.27662 6.43908 1.49775 6.46325 1.66571C6.70339 3.34157 7.1955 4.9715 7.92292 6.50025C8.03771 6.74192 7.96279 7.03071 7.74529 7.18537L5.13771 9.04862C6.73205 12.7636 9.69258 15.7241 13.4075 17.3185L15.2684 14.7157C15.3444 14.6094 15.4554 14.5331 15.5819 14.5002C15.7085 14.4673 15.8425 14.4799 15.9607 14.5357C17.4893 15.2618 19.1188 15.7527 20.7941 15.9917C20.962 16.0159 21.1832 16.0412 21.4587 16.069C21.6076 16.0843 21.7455 16.1543 21.8458 16.2655C21.946 16.3767 22.0002 16.5211 22 16.6708Z" fill="#333333" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div style={{ padding: "20px 25px 25px 25px", height: '170px', borderRadius: '4px', backgroundColor: 'white' }}>
            <div style={{ borderBottom: '1px solid #F2F2F2', marginBottom: '10px' }}>
              <p className='infomation_'>Information</p>
            </div>
            <p style={{ fontSize: '14px', fontWeight: '400', fontStyle: 'normal', lineHeight: 'normal' }}>Deal Owner:</p>
            <div className="onetap_conn_filter_search_OTC_Admin">
              <select
                name="status"
                id="status"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                  height: '45px'
                }}
              >
                <option value="Status">Chapman</option>
                <option
                  style={{ textTransform: "capitalize" }}
                >
                </option>
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg_OTC_Admin"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div style={{ padding: "20px 25px 25px 25px", height: '504px', borderRadius: '4px', backgroundColor: 'white' }}>
            <div style={{ borderBottom: '1px solid #F2F2F2', marginBottom: '10px' }}>
              <p className='infomation_'>Schedule</p>

            </div>
            <p className='dealer'>Created Date:</p>
            <div style={{ width: '100%', backgroundColor: '#F2F2F2', height: '45px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', padding: '10px', borderRadius: '4px' }}>
              {formatDate(rows.createdAt)}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px', marginBottom: '15px' }}>
              <div className='dealer2'>Billing Period:</div>
              <div className="onetap_conn_filter_search_OTC_Admin" style={{ width: '34%', height: '45px' }}>
                <select
                  name="status"
                  id="status"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <option value="Yearly">Yearly</option>
                  <option
                    style={{ textTransform: "capitalize" }}
                  >
                  </option>
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>

            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
              <div className='dealer2'>Trail end:</div>
              <div style={{ width: '200px', height: '45px', backgroundColor: '#F2F2F2', borderRadius: '4px', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <input type='text' style={{ width: '100%', height: '40px', backgroundColor: '#F2F2F2', border: 'none', outline: 'none' }} />
              </div>
            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='dealer2'>Next payment:</div>
              <div style={{ width: '200px', height: '45px', backgroundColor: '#F2F2F2', borderRadius: '4px', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <input type='text' style={{ width: '200px', height: '40px', backgroundColor: '#F2F2F2', border: 'none', outline: 'none' }} />
              </div>
            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className='dealer2'>End date:</div>
              <div style={{ width: '200px', height: '45px', backgroundColor: '#F2F2F2', borderRadius: '4px', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                <input type='text' style={{ width: '200px', height: '40px', backgroundColor: '#F2F2F2', border: 'none', outline: 'none' }} />
              </div>
            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
              <div className='dealer2'>Timezone</div>
              <div className="onetap_conn_filter_search_OTC_Admin">
                <select
                  name="status"
                  id="status"
                  style={{
                    appearance: "none",
                    outline: "none",
                    cursor: "pointer",
                    height: '45px'
                  }}
                >
                  <option value="Status">America/Los_Angeles</option>
                  <option
                    style={{ textTransform: "capitalize" }}
                  >
                  </option>
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  className="onetap_conn_filter_svg_OTC_Admin"
                >
                  <path
                    d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                    fill="#000000"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div style={{ padding: "20px 25px 25px 25px", height: '204px', borderRadius: '4px', backgroundColor: 'white' }}>
            <div style={{ borderBottom: '1px solid #F2F2F2', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p className='infomation_'>Subscription Note</p>
            </div>
            <div style={{ width: '100%', height: '50px', borderRadius: '4px', backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
              <input type='text' style={{ backgroundColor: '#F2F2F2', outline: 'none', border: 'none', width: '100%' }} placeholder='Include shipping and add ons note' />
            </div>
            <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                variant="contained"
                size="small"
                style={{ backgroundColor: 'black', color: 'white' }}
              >
                Save
              </Button>
            </div>

          </div>
        </div>
        <div className='row mb-3'>
          <div style={{ padding: "20px 25px 25px 25px", height: '150px', borderRadius: '4px', backgroundColor: 'white' }}>
            <div style={{ borderBottom: '1px solid #F2F2F2', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p className='infomation_'>Activity log</p>
            </div>
            <div style={{ width: '100%', height: '50px', borderRadius: '4px', backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
              <input type='text' style={{ backgroundColor: '#F2F2F2', outline: 'none', border: 'none', width: '100%' }} placeholder='Show log' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionDetailsInfo
