import React, { useEffect, useState } from "react";
// import "./myCard.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import AdminMyAccount from "./AdminMyAccount";


const Component = () => {
  const [cardInfoTab, setCardInfoTab] = useState("My Account");
  const getValueOfSaveAndContinueTab = (value) => {
    setCardInfoTab(value);
  };

  return (
    <div className="">
      <div className="onetap_company_card_syn">
        <div>
          {cardInfoTab === "My Account" && (
            <AdminMyAccount
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const MyAccountMain = () => {
  return (
    <>
      <Otc_Sidebar Component={Component} />
    </>
  );
};

export default MyAccountMain;
