import React, { useEffect, useState } from "react";


const TemplatesHeaderWithIcons = (props) => {
    const [SmartAccessoriesTab, setSmartAccessoriesTab] = useState("Templates");
    const { tab } = props;
    
    useEffect(() => {
        setSmartAccessoriesTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setSmartAccessoriesTab(value);
        props.onChange(value);
    };

    return (
        <>
            <p className="onetap_conn_user_header">
                Dashboard &nbsp;{" > "} &nbsp;
                <span className="onetap_conn_header_highlight">Templates</span>
            </p>
            <div className="onetap_conn_user_header_main">
                <div className="onetap_conn_user_header_icon">
                    <div
                        onClick={() => handleClickOfTab("Templates")}
                        className={
                            SmartAccessoriesTab === "Templates"
                                ? "onetap_conn_user_header_icon_and_header_active"
                                : "onetap_conn_user_header_icon_and_header_deactive"
                        }
                    >
                        <div
                            style={{
                                backgroundColor: SmartAccessoriesTab === "Templates" ? "#E65925" : "",
                                width: "34px",
                                height: "32px",
                                borderRadius: "7px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V6H18V0H0ZM16 2H2V4H16V2ZM0 8V18H8V8H0ZM6 10H2V16H6V10Z" fill={SmartAccessoriesTab === "Templates" ? "#ffff" : "#000000"} />
                                <path d="M18 8H10V10H18V8ZM10 12H18V14H10V12ZM18 16H10V18H18V16Z" fill="white" />
                            </svg>
                        </div>
                        <p
                            style={{ cursor: "pointer" }}
                            className={
                                SmartAccessoriesTab === "Templates" ? "onetap_conn_user_active_header" : "onetap_conn_user_deactive_header"
                            }
                        > Templates
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TemplatesHeaderWithIcons;
