import React, { useState } from "react";
import Addusermanuallyheaderswithicon from "./Addusermanuallyheaderswithicon";
import Addusermanuallytab from "./addusermanuallytab";
import Sidebar from "../../../components/Layout/Sidebar/Sidebar";
const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  return (
    <div className="">
      <div className="onetap_company_profile_syn">
        <Addusermanuallyheaderswithicon
          onChange={getTabValue}
          tab={companyProfileTab}
        />
        <div className="">
          {companyProfileTab === "General" && (
            <Addusermanuallytab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};
export default Dashboard;
