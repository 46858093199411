import React from 'react'
import Header2 from '../Layout/Header/Header2';

const AddonsComingSoon = () => {
  return (
    <>
      <Header2 />
      <div className="coming_main_h">
        <div className="coming_h">
          <h1>Coming Soon...!</h1>
        </div>
      </div>
    </>
  );
};

export default AddonsComingSoon