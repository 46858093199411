import React, { useEffect, useState } from 'react'
import "./Categories.css"
import searchIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/searchIcon.svg"
import selectDropdownIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
import ProductIcon from "../../../OTC-AdminPanel/Otc_assets/images/ProductImg.svg"
import AddOnIcon from "../../../OTC-AdminPanel/Otc_assets/images/AddOnImage.svg"
import CouponIcon from "../../../OTC-AdminPanel/Otc_assets/images/CouponImage.svg"
import Noimagefound from "../../Otc_assets/images/Noimagefound.png"
import Loader from '../../../components/Layout/Loader/Loader'
import axios from 'axios'
import { Box } from '@mui/material'
import Modal from "@mui/material/Modal";


const ProductCategories = ({ showAddCategory, handleEditCategoryOpen, back }) => {
    const warningModalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "#FDE9E9",
        p: 3,
        outline: "none",
        border: "1px solid E62525",
        borderRadius: "10px",
    };
    const url = process.env.REACT_APP_URL;
    const [isPermalinkEditable, setIsPermalinkEditable] = useState("");
    const [filteredCategoriesData, setFilteredCategoriesData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSelectedStatus, setSearchSelectedStatus] = useState('Status');
    const [filteredCategoryList, setFilteredCategoryList] = useState([]);
    const [categoryNameError, setCategoryNameError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [visibilityError, setVisibilityError] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageUpload, setselectedImageUpload] = useState(null)
    const [selectedPCId, setSelectedPCId] = useState("")
    const [imageNameError, setImageNameError] = useState('');
    const [altTextError, setAltTextError] = useState('');
    const [categoryTypeError, setcategoryTypeError] = useState(null);
    const [openWarningModal, setOpenWarningModal] = React.useState(false);
    const handleWarningOpenModal = () => { setOpenWarningModal(true) }
    const handleCloseWarningdModal = () => {
        handleSubmitAddNewCategory('Archived');
        setOpenWarningModal(false);
    }

    useEffect(() => {
        if (!showAddCategory) {
            resetCategoryData();
        }
    }, [showAddCategory]);

    const resetCategoryData = () => {
        setCurrentDateTime('')
        setSelectedPCId('')
        setselectedImageUpload(null)
        setSelectedImage(null)
        setCategoryNameError('')
        setImageNameError('')
        setAltTextError('')
        setcategoryTypeError('')
        setVisibilityError(''); setStatusError('')
        setCategoryData({
            name: '',
            isActive: false,
            categoryType: '',
            CustomPermalink: '',
            description: '',
            image: "",
            imageName: '',
            altText: '',
            status: '',
            Visibility: '',
            activitylog: '',
        });
    };

    const [categoryData, setCategoryData] = useState({
        name: '',
        isActive: false,
        CustomPermalink: ``,
        description: '',
        image: "",
        imageName: '',
        altText: '',
        status: '', 
        Visibility: '', 
        activitylog: '',
        categoryType: ''
    });
    const clearFilters = () => {
        setSearchQuery("")
        setSearchSelectedStatus("Status");
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData({
            ...categoryData,
            [name]: value,
        });
        if (name === 'status') {
            setStatusError('');
        }
        if (name === 'categoryType') {
            setcategoryTypeError(null);
        }
        if (name === 'Visibility') {
            setVisibilityError('');
        }
        if (name === 'imageName') {
            setImageNameError('');
        }
        if (name === 'altText') {
            setAltTextError('');
        }
    };

    const handlePermalinkChange = (e) => {
        const slug = e.target.value
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9-]/g, '');
        setCategoryData({
            ...categoryData,
            CustomPermalink: slug
            ,
        });
    };

    const [currentDateTime, setCurrentDateTime] = useState('');
    const [loading, setLoading] = useState(true);
    const fetchcategory = async () => {
        try {
            const { data } = await axios.get(`${url}/admin/productCategory/fetch`);
            setFilteredCategoriesData(data.categories)
            setLoading(false)
        } catch (error) {
            ;
        }
    }
    useEffect(() => {
        fetchcategory()
    }, [])



    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            setselectedImageUpload(file);
        }
    };

    const handleSubmitAddNewCategory = async (publish) => {
        try {
            if (categoryData.name.trim() === "") {
                setCategoryNameError("Category name cannot be empty.");
                return
            }
            if (categoryData.categoryType === '') {
                setcategoryTypeError(true)
                return
            }

            if (selectedImageUpload) {
                if (categoryData.imageName.trim() === '') {
                    setImageNameError("Please enter Image name.");
                    if (categoryData.altText.trim() === '') {
                        setAltTextError("Please enter Alt text.");
                        return
                    }
                }

            }

            const formData = new FormData();
            formData.append("image", selectedImageUpload);

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true,
            };
            const { data } = await axios.post(
                `${url}/admin/imageUpload?imageType=category`,
                formData,
                config
            );
            if (data.success) {
                let productcategoryImage

                if (publish === 'Archived') {

                    productcategoryImage = {
                        ...categoryData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                        status: 'Archived',
                        Visibility: 'Public'
                    }
                } else if (categoryData.status === '' && categoryData.Visibility === '') {

                    productcategoryImage = {
                        ...categoryData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                        status: 'Published',
                        Visibility: 'Public'
                    }
                } else {

                    productcategoryImage = {
                        ...categoryData,
                        image: selectedImageUpload ? data.imageNames[0].name : '',
                    }
                }
                const response = await axios.post(`${url}/admin/productCategory/create`, { productcategoryImage, id: selectedPCId._id }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });
                setCategoryData({
                    name: '',
                    categoryType: '',
                    CustomPermalink: `https://onetapconnect.com/`,
                    description: '',
                    imageName: '',
                    altText: '',
                    status: '',
                    Visibility: '',
                    activitylog: '',
                })
                setselectedImageUpload(null)
                setSelectedImage(null)
                fetchcategory()
                back()
            } else {
            }
        } catch (error) {
            ;
        }
    };



    const filterCategories = () => {
        let filteredCategories = filteredCategoriesData;

        if (searchSelectedStatus !== 'Status') {
            filteredCategories = filteredCategories.filter(
                (category) => category.status === searchSelectedStatus
            );
        }

        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filteredCategories = filteredCategories?.filter(
                (category) =>
                    category.name?.toLowerCase().includes(query) ||
                    category.CustomPermalink?.split('/').pop().toLowerCase().includes(query)
            );
        }

        return filteredCategories;
    };

    useEffect(() => {
        const filteredCategories = filterCategories();
        setFilteredCategoryList(filteredCategories);
    }, [searchQuery, searchSelectedStatus, filteredCategoriesData]);

    const handleCardClick = (selectedCard) => {
        setCategoryNameError('')
        setImageNameError('')
        setAltTextError('')
        setcategoryTypeError('')
        setVisibilityError(''); setStatusError('')
        const rawPublishedDate = new Date(selectedCard.publishedDate);

        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(rawPublishedDate);

        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        }).format(rawPublishedDate);

        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
        setCurrentDateTime(formattedDateTime)
        setSelectedPCId(selectedCard)
        setCategoryData({
            ...selectedCard,
            CustomPermalink: selectedCard.CustomPermalink?.split('/').pop() || '',
        });
        if (selectedCard.image !== '') {
            setSelectedImage(`${url}/admin/productCategory/img/${selectedCard.image}`);
        } else {
            setSelectedImage(Noimagefound);
        }
    };
    return (
        <>
            {!showAddCategory ? (<div className="onetap_conn_users_tab_content_OTC_Admin">
                <div className="">
                    <div className="pc-search-and-status">
                        <div className="search-container">
                            <input
                                className="pc-search-input"
                                type="text"
                                name="search"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <img className='pc-search-icon' src={searchIcon} alt="" />
                        </div>
                        <div className="pc-status-dropdown-div">
                            <select
                                name="status"
                                id="status"
                                className='pc-status-dropdown'
                                value={searchSelectedStatus}
                                onChange={(e) => setSearchSelectedStatus(e.target.value)}
                            >
                                <option value="Status">Status</option>
                                <option value="Published">Published</option>
                                <option value="Draft">Draft</option>
                                <option value="Archived">Archived</option>
                            </select>
                            <img className='pc-downarrow-icon' src={selectDropdownIcon} alt="" />
                        </div>
                    </div>
                    <button className="clear-fillter" style={{ marginTop: "10px", display: (searchQuery || searchSelectedStatus !== "Status") ? 'block' : 'none' }} onClick={clearFilters}>
                        Clear filters
                    </button>
                    {
                        loading ? (
                            <Loader />
                        ) : (
                            <div className="pc-categories-main-section">
                                <div className="pc-categori-title">
                                    <div className="">
                                        <span></span>
                                        <div className="product-details">
                                            <span className="product-name-text"></span>
                                        </div>
                                    </div>
                                    <span className='pc-category-title-text'>Slug</span>
                                    <span className='pc-category-title-text'>Status</span>
                                </div>
                                <div className="pc-category-card-section">

                                    <div className="pc-category-header-card">
                                        <div className="pc-img-and-text-div">
                                            <div className="pc-img-div">
                                                <img className="pc-img" src={ProductIcon} alt="" />
                                            </div>
                                            <div style={{ cursor: "pointer" }} className="product-details">
                                                <span className="product-name-text">Product categories</span>
                                            </div>
                                        </div>

                                        <span className="pc-category-slug-text" >{ }
                                        </span>
                                        <span className={`status-text-smart-acc `}>{ } </span>
                                    </div>

                                    {filteredCategoryList && filteredCategoryList.filter((e) => e.categoryType === 'Smart-accessories').length > 0 ? (filteredCategoryList && filteredCategoryList.filter((e) => e.categoryType === 'Smart-accessories').map((e, index) => (<div key={index} className="pc-category-cards">
                                        <div className="pc-img-and-text-div">
                                            <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} className="pc-img-div">
                                                {e.image !== '' ? (<img className="pc-img" src={`${url}/admin/productCategory/img/${e.image}`} alt="" />) : (<img className="pc-img" src={Noimagefound} alt="" />)}

                                            </div>
                                            <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} style={{ cursor: "pointer" }} className="product-details">
                                                <span className="product-name-text">{e.name}</span>
                                            </div>
                                        </div>

                                        <span className="pc-category-slug-text" >{e.CustomPermalink?.split('/').pop()}
                                        </span>
                                        <span className={`status-text-smart-acc ${e.status === 'Published' ? 'published-text' : e.status === 'Archived' ? 'archived-text' : ''}`}>
                                            {e.status} </span>
                                    </div>))) : (<div style={{ marginTop: "10px", marginLeft: "20px" }} className="no-teams-message">
                                        No Smart Accessories categories available.
                                    </div>)}

                                    <div className="pc-category-header-card">
                                        <div className="pc-img-and-text-div">
                                            <div className="pc-img-div">
                                                <img className="pc-img" src={AddOnIcon} alt="" />
                                            </div>
                                            <div style={{ cursor: "pointer" }} className="product-details">
                                                <span className="product-name-text">Add-on categories</span>
                                            </div>
                                        </div>

                                        <span className="pc-category-slug-text" >{ }
                                        </span>
                                        <span className={`status-text-smart-acc `}>{ } </span>
                                    </div>

                                    {filteredCategoryList && filteredCategoryList.filter((e) => e.categoryType === 'Add-on').length > 0 ? (filteredCategoryList && filteredCategoryList.filter((e) => e.categoryType === 'Add-on').map((e, index) => (<div key={index} className="pc-category-cards">
                                        <div className="pc-img-and-text-div">
                                            <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} className="pc-img-div">
                                                {e.image !== '' ? (<img className="pc-img" src={`${url}/admin/productCategory/img/${e.image}`} alt="" />) : (<img className="pc-img" src={Noimagefound} alt="" />)}
                                            </div>
                                            <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} style={{ cursor: "pointer" }} className="product-details">
                                                <span className="product-name-text">{e.name}</span>
                                            </div>
                                        </div>

                                        <span className="pc-category-slug-text" >{e.CustomPermalink?.split('/').pop()}
                                        </span>
                                        <span className={`status-text-smart-acc ${e.status === 'Published' ? 'published-text' : e.status === 'Archived' ? 'archived-text' : ''}`}>
                                            {e.status} </span>
                                    </div>))) : (<div style={{ marginTop: "10px", marginLeft: "20px" }} className="no-teams-message">
                                        No add-on categories available.
                                    </div>)}

                                    <div className="pc-category-header-card">
                                        <div className="pc-img-and-text-div">
                                            <div className="pc-img-div">
                                                <img className="pc-img" src={CouponIcon} alt="" />
                                            </div>
                                            <div style={{ cursor: "pointer" }} className="product-details">
                                                <span className="product-name-text">Coupon categories</span>
                                            </div>
                                        </div>

                                        <span className="pc-category-slug-text" >{ }
                                        </span>
                                        <span className={`status-text-smart-acc `}>{ } </span>
                                    </div>
                                    {filteredCategoryList && filteredCategoryList
                                        .filter((e) => e.categoryType === 'Coupon')
                                        .length > 0 ? (
                                        filteredCategoryList
                                            .filter((e) => e.categoryType === 'Coupon')
                                            .map((e, index) => (
                                                <div key={index} className="pc-category-cards">
                                                    <div className="pc-img-and-text-div">
                                                        <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} className="pc-img-div">
                                                            {e.image !== '' ? (<img className="pc-img" src={`${url}/admin/productCategory/img/${e.image}`} alt="" />) : (<img className="pc-img" src={Noimagefound} alt="" />)}
                                                        </div>
                                                        <div onClick={() => { handleCardClick(e); handleEditCategoryOpen() }} style={{ cursor: "pointer" }} className="product-details">
                                                            <span className="product-name-text">{e.name}</span>
                                                        </div>
                                                    </div>
                                                    <span className="pc-category-slug-text">{e.CustomPermalink?.split('/').pop()}</span>
                                                    <span className={`status-text-smart-acc ${e.status === 'Published' ? 'published-text' : e.status === 'Archived' ? 'archived-text' : ''}`}>
                                                        {e.status}
                                                    </span>
                                                </div>
                                            ))
                                    ) : (
                                        <div style={{ marginTop: "10px", marginLeft: "20px" }} className="no-teams-message">
                                            No coupon categories available.
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>) : (
                <div className="container" style={{ padding: '0px' }}>
                    <div className="w-100  d-flex flex-row-reverse gap-3 mb-4 plan-btn-top">
                        <div class="dropdown">
                            <button class="btn px-3 py-2 dropdown-toggle mb-0 d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: '#000', color: '#fff', }}>
                                <span className='me-5'>Actions</span>
                            </button>
                            <div onClick={() => handleWarningOpenModal("Archived")} class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ left: 'unset' }}>
                                <span style={{ paddingLeft: "16px" }}  >Archive</span>
                            </div>
                        </div>
                        {selectedPCId && selectedPCId.status !== 'Draft' ? (<button
                            disabled={categoryData.status === 'Draft'}
                            className="btn px-3 py-2 text-white"
                            onClick={() => {
                                if (categoryData.status === 'Archived') {
                                    handleSubmitAddNewCategory("true"); 
                                } else {
                                    handleSubmitAddNewCategory(true); 
                                }
                            }}
                            style={{ background: '#E65925', width: '15%' }}
                        >
                            Update
                        </button>) : (<button
                            disabled={categoryData.status === 'Draft'}
                            className="btn px-3 py-2 text-white"
                            onClick={() => { handleSubmitAddNewCategory("Public") }}
                            style={{ background: '#48AF25', width: '15%' }}
                        >
                            {categoryData.status === 'Archived' ? 'Archived' : 'Publish'}
                        </button>)}

                        <button
                            disabled={categoryData.status !== 'Draft'}
                            className="btn px-3 py-2 text-white"
                            onClick={() => handleSubmitAddNewCategory()}
                            style={{
                                background: categoryData.status !== 'Draft' ? '#8A8A8A' : 'black',
                                width: '15%',
                            }}
                        >
                            Save draft
                        </button>

                    </div>
                    <div className="row">
                        <div className="col" style={{ padding: "0" }}>
                            <div className="bg-white p-4" style={{ borderRadius: '8px' }}>
                                <h5>Category Name</h5>
                                <input className="w-100 border-0 p-2" placeholder='Smart Card' type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='name' value={categoryData.name}
                                    onChange={(e) => {
                                        if (!isPermalinkEditable) {
                                            setCategoryNameError("")
                                            const slug = e.target.value
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9-]/g, '');
                                            setCategoryData({
                                                ...categoryData,
                                                name: e.target.value,
                                                CustomPermalink: slug,
                                            });
                                        } else {
                                            setCategoryData({
                                                ...categoryData,
                                                name: e.target.value,
                                            });
                                        }
                                    }} />
                                {/* {categoryNameError && (<div><div/>)} */}
                                {categoryNameError && (<div className="error">{categoryNameError} </div>
                                )}
                            </div>
                            <div className="bg-white mt-2 p-4" style={{ borderRadius: '8px' }}>
                                <h5>Permalink</h5>

                                {!isPermalinkEditable ? (<div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input
                                        type="text"
                                        name="name"
                                        value={categoryData.CustomPermalink}
                                        onChange={handleChange}
                                        readOnly={!isPermalinkEditable}
                                        className="w-100 border-0 me-2"
                                        style={{ background: "#f2f2f2" }}
                                    />
                                    <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(!isPermalinkEditable)} role="button">Edit</span>
                                </div>) : (<div className="w-100 border-0 p-2 d-flex align-items-center" style={{ borderRadius: " 4px", background: "#f2f2f2" }}>
                                    https://onetapconnect.com/
                                    <input
                                        type="text"
                                        name="permalink"
                                        value={categoryData.CustomPermalink}
                                        onChange={handlePermalinkChange}
                                        readOnly={!isPermalinkEditable}
                                        className="w-100 border-0 me-2"
                                        style={{ background: "#f2f2f2" }}
                                    />
                                    <span style={{ color: '#E65925' }} onClick={() => setIsPermalinkEditable(!isPermalinkEditable)} role="button">Edit</span>
                                </div>)}
                            </div>

                            <div className=" mt-2">
                                <div className="col" >
                                    <div className="bg-white p-4 h-100" style={{ borderRadius: '8px' }} >
                                        <div className="d-flex gap-2">
                                            <h5>Category Type</h5>
                                            <div className={`add-pc-status-dropdown-div ${categoryTypeError ? 'error-border' : ''}`}>
                                                <select
                                                    name="categoryType"
                                                    value={categoryData.categoryType}
                                                    onChange={handleChange}
                                                    id="status"
                                                    className={`pc-status-dropdown ${categoryTypeError ? 'error-border' : ''}`}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Smart-accessories">Smart accessories</option>
                                                    <option value="Add-on">Add-on</option>
                                                    <option value="Coupon">Coupon</option>
                                                </select>
                                                <img className="pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                            </div>
                                        </div>
                                        <p className='add-pc-description-text mt-3'>Description </p>
                                        <textarea
                                            name="description"
                                            value={categoryData.description}
                                            onChange={handleChange} className='add-pc-textarea w-100' id="" cols="30" rows="5"></textarea>
                                    </div>
                                </div>




                                <div className="bg-white p-4 mt-2" style={{ borderRadius: '8px' }}>
                                    <h5>Activity log</h5>
                                    <textarea className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='activitylog' value={categoryData.activitylog}
                                         readOnly />
                                </div>





                            </div>
                        </div>
                        <div className="col-4">
                            <div className="bg-white p-4" style={{ borderRadius: '4px' }}>
                                <h6 className="border-bottom pb-2 pc-Product information-text">Product information</h6>
                                <div className="gap-3 d-flex flex-column">
                                    <div style={{ position: "relative" }} className="mt-2 ">
                                        <span>Status</span>
                                        {/* <div  className={` ${categoryData.status === 'Published' ? 'published-background' : ''}`}> */}
                                        <div>
                                            <select onChange={handleChange} value={categoryData.status || 'Published'} name='status' className={`pc-status-dropdown ${categoryData.status === 'Published' ? 'published-background' : ''}`} >
                                                <option className={`${categoryData.status === 'Published' ? 'pc-published-text' : ''}`} value="Published">Published</option>
                                                <option value="Draft">Draft</option>
                                                <option value="Archived">Archived</option>
                                            </select>
                                        </div>

                                        {statusError && (<div className="error">{statusError} </div>
                                        )}

                                        <img className="add-pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                    </div>
                                    <div style={{ position: "relative" }} className=''>
                                        <span>Visibility</span>
                                        <div>

                                            <select onChange={handleChange} name='Visibility' value={categoryData.Visibility} className="pc-status-dropdown" style={{ backgroundColor: '#f2f2f2' }}>
                                                <option value="Public">Public</option>
                                                <option value="Hidden">Hidden</option>
                                            </select>
                                        </div>
                                        {visibilityError && (<div className="error">{visibilityError} </div>
                                        )}
                                        <img className="add-pc-downarrow-icon" src={selectDropdownIcon} alt="" />
                                    </div>
                                    <div>
                                        <span>Published date</span>
                                        <input readOnly value={currentDateTime} className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} />
                                    </div>
                                </div>
                            </div>




                            <div className="mt-2">
                                <div className="bg-white p-4 gap-2 d-flex flex-column " style={{ borderRadius: '8px' }}>
                                    <div>
                                        <h5>Featured Image</h5>
                                        {selectedImage ? (
                                            <label style={{ width: "100%" }} htmlFor="imageUpload" className="upload-label">
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    name='productcategoryimage'
                                                    style={{ display: 'none' }}
                                                    onChange={handleImageUpload}
                                                />
                                                <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '0' }}>
                                                    <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
                                                    {/* <img src={cancelIconBlack} onClick={() => setSelectedImage('')} alt="" className='add-pc-close-img' /> */}
                                                </div>
                                            </label>
                                        ) : (
                                            <label style={{ width: "100%" }} className="upload-label" htmlFor="imageUpload">
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept="image/*"
                                                    name='productcategoryimage'
                                                    style={{ display: 'none' }}
                                                    onChange={handleImageUpload}
                                                />
                                                <div className="upload-label w-100 border-0 d-flex align-items-center justify-content-center" style={{ position: 'relative', borderRadius: "4px", background: "#f2f2f2", padding: '50px' }}>
                                                    <div style={{ cursor: "pointer" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="34" viewBox="0 0 48 34" fill="none">
                                                            <path
                                                                d="M21.9193 33.3333H12.5443C9.38455 33.3333 6.68455 32.2396 4.44427 30.0521C2.20399 27.8646 1.08455 25.191 1.08594 22.0312C1.08594 19.3229 1.90191 16.9097 3.53386 14.7917C5.1658 12.6736 7.30122 11.3194 9.94011 10.7292C10.8082 7.53472 12.5443 4.94792 15.1484 2.96875C17.7526 0.989583 20.704 0 24.0026 0C28.0651 0 31.5116 1.41528 34.3422 4.24583C37.1728 7.07639 38.5873 10.5222 38.5859 14.5833C40.9818 14.8611 42.97 15.8944 44.5505 17.6833C46.1311 19.4722 46.9207 21.5639 46.9193 23.9583C46.9193 26.5625 46.0075 28.7764 44.1839 30.6C42.3603 32.4236 40.1471 33.3347 37.5443 33.3333H26.0859V18.4375L29.4193 21.6667L32.3359 18.75L24.0026 10.4167L15.6693 18.75L18.5859 21.6667L21.9193 18.4375V33.3333Z"
                                                                fill="#838383"
                                                            />
                                                        </svg>
                                                        <p>Upload</p>
                                                    </div>
                                                </div>
                                            </label>
                                        )}
                                    </div>
                                    {selectedImage && (<div className='pc-remove-img' onClick={() => setSelectedImage('')}>Remove image</div>)}
                                    <div>
                                        <span>Image name</span>
                                        <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='imageName' value={categoryData.imageName} onChange={handleChange} />
                                        {imageNameError && <div className="error">{imageNameError}</div>}
                                    </div>
                                    <div>
                                        <span>Alt text</span>
                                        <input className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name='altText' value={categoryData.altText} onChange={handleChange} />
                                        {altTextError && <div className="error">{altTextError}</div>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            )}
            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningdModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={warningModalStyle}>
                    <div className="video-Modal-section">
                        <h2 className="warning-header">
                            Warning
                        </h2>
                        <span className="warning-text-smart-acc">Are you sure you want to Archive this product?</span>


                        <div className="video-Modal-btn-group mt-4" >
                            <button className="video-Modal-btn video-first-modal-btn" onClick={handleCloseWarningdModal}>Archive</button>
                            <button className="video-Modal-btn video-sec-modal-btn" onClick={() => setOpenWarningModal(false)}>Cancel</button>
                        </div>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ProductCategories
