import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './CategoriesMain.css';

const CategoriesHeaderWithIcon = (props) => {
    const { showCreateCoupon, onCategoryClick, onAddNewCategoryClick, showAddCategory } = props;
    const [categoriesTab, setCategoriesTab] = useState("categories");
    const { tab } = props;
    useEffect(() => {
        setCategoriesTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCategoriesTab(value);
        props.onChange(value);
        if (value === 'Categories') {
            onCategoryClick()
        }
    };

    return (
        <>
            <div className="OTC_Admin_mycard_header">
                <div
                    onClick={() => handleClickOfTab("Categories")}
                    className={
                        categoriesTab === "Categories"
                            ? "OTC_Admin_mycard_header_icon_and_header_active"
                            : "OTC_Admin_mycard_header_icon_and_header_deactive"
                    }
                    style={{ zIndex:'1'}}
                >
                    <div
                        style={{
                            backgroundColor: categoriesTab === "Categories" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                            <path d="M0 16V12H4V16H0ZM6 16V12H20V16H6ZM0 10V6H4V10H0ZM6 10V6H20V10H6ZM0 4V0H4V4H0ZM6 4V0H20V4H6Z" fill={categoriesTab === "Categories" ? "#ffff" : "#000000"} />
                        </svg>
                    </div>
                    <p
                        className={
                            categoriesTab === "Categories"
                                ? "OTC_Admin_mycard_active_header"
                                : "OTC_Admin_mycard_headers"
                        }
                    >
                        Categories
                    </p>
                </div>
                <div className="OTC_Admin_user_header_right" >
                    {categoriesTab === "Categories" && (<div style={{ display: showAddCategory && "none" }} onClick={() => { onAddNewCategoryClick() }} className={`OTC_Admin_user_header_addusers`}>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                            >
                                <rect width="34" height="34" rx="9" fill="#E65925" />
                                <path
                                    d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <div>Add New Category</div>
                    </div>)}
                </div>
            </div >

        </>
    );
};

export default CategoriesHeaderWithIcon;
