import { useSelector } from "react-redux";
import DashboardIcon from '../assets/icons/DashboardIcon.svg';
import ordersIcon from '../assets/icons/ordersIcon.svg';
import subscriptionIcon from '../assets/icons/subscriptionIcon.svg';
import AnalyticsIcon from '../assets/icons/AnalyticsIcon.svg';
import companyIcon from '../assets/icons/companyIcon.svg';
import projectsIcon from '../assets/icons/projectsIcon.svg';
import AcademyIcon from '../assets/icons/AcademyIcon.svg';
import SmartAccessoriesIcon from '../assets/icons/SmartAccessoriesIcon.svg';
import CompanyProfileIcon from '../assets/icons/CompanyProfileIcon.svg';
import SettingsIcon from '../assets/icons/SettingsIcon.svg';
import BillingIcon from '../assets/icons/BillingIcon.svg';
import TeamMembersIcon from '../assets/icons/TeamMembersIcon.svg';

const OTCRoutes = () => {
    const { isAuthenticated, user } = useSelector((state) => state.user);

    const routes = [
        {path:'/admin/dashboard',element:"",title:'Dashboard',icon:DashboardIcon},
        {path:'#',element:"",title:'Orders',icon:ordersIcon},
        {path:'#',element:"",title:'Subscriptions',icon:subscriptionIcon},
        {path:'/admin/users',element:"",title:'Users',icon:TeamMembersIcon},
        {path:'#',element:"",title:'Companies',icon:companyIcon},
        {path:'#',element:"",title:'Analytics',icon:AnalyticsIcon},
        {path:'#',element:"",title:'Smart Accessories',icon:SmartAccessoriesIcon},
        {path:'/admin/profile',element:"",title:'OneTapConnect Profile',icon:CompanyProfileIcon},
        {path:'#',element:"",title:'Settings',icon:SettingsIcon},
        {path:'#',element:"",title:'Billing',icon:BillingIcon},
        {path:'#',element:"",title:'Account Settings',icon:SettingsIcon},
    ];

    return routes;
}

export default OTCRoutes;
