import React, { useEffect, useState } from "react";
import {
  getCompanyProfileDetails,
  getcompanies_share_referral_data,
  updatecompany_referral_data,
  fetchUserInformationDetails,
} from "../../../actions/userAction/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Layout/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Box, Modal } from "@mui/material";


const SettingShareReferTab = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company, loading, error, message } = useSelector(
    (state) => state.Company
  );
  const { userInfo } = useSelector((state) => state.userInformation);
  const { companyShareReferData } = useSelector(
    (state) => state.companyShareReferData
  );
  const [companyDetails, setCompanydetails] = useState({});
  const [companyShareReferDetails, setCompanyShareReferDetails] = useState({});
  const [apiSuccess, setApiSuccess] = useState(false);
  const [shareByText, setShareByText] = useState(
    "Hi there, it’s {user_name}. Please click the link below so we can share contact info. Talk soon! "
  );
  const [shareByEmail, setShareByEmail] = useState(
    `Hi! This is {user_name} at {company_name}. Please find below the link to my Digital Business Card on which you'll be able to learn more about me, my company and the services we provide.\nHave a great day!`
  );
  const [shareBySocial, setShareBySocial] = useState(
    "Hi! This is {user_name} at {company_name}. Please find below the link to my Digital Business Card on which you'll be able to learn more about me, my company and the services we provide. \nHave a great day!"
  );
  const [referByText, setReferByText] = useState(
    "I thought you may be interested in {user_name} OneTapConnect card. Click the link below to learn about his company {company_name} and services. "
  );
  const [referByEmail, setReferByEmail] = useState(
    `Hi! {referrer_name} is inviting you to see {user_name} Digital Business Card. Click the link below to learn more about his company {company_name} and services.\n{card URL}\nHave a great day!\nThe OneTapConnect team`
  );
  const [referBySocial, setReferBySocial] = useState(
    "I thought you may be interested in {user_name} OneTapConnect card. Click the link below to learn about his company {company_name} and services. "
  );
  const handleFieldClick = (value, setter, placeholder, fieldName) => {
    const updatedText = `${value} ${placeholder}`;
    setter(updatedText); // Update the local state
    // Update the corresponding field in companyDetails dynamically
    setCompanyShareReferDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: updatedText,
    }));
  };
  useEffect(() => {
    dispatch(getCompanyProfileDetails());
    if(!userInfo){
      dispatch(fetchUserInformationDetails());
    }
  }, []);

  useEffect(() => {
    setCompanydetails(company);
  }, [company]);
  useEffect(() => {
    setCompanyShareReferDetails(companyShareReferData);
  }, [companyShareReferData]);
  useEffect(() => {
    if (!companyShareReferData) {
      dispatch(getcompanies_share_referral_data());
    } else {
      setShareByText(companyShareReferData?.share_by_text);
      setShareByEmail(companyShareReferData?.share_by_email);
      setShareBySocial(companyShareReferData?.share_by_socialmedia);
      setReferByText(companyShareReferData?.refer_by_text);
      setReferByEmail(companyShareReferData?.refer_by_email);
      setReferBySocial(companyShareReferData?.refer_by_socialmedia);
    }
  }, [companyShareReferData]);

  const handleSave = () => {
    dispatch(updatecompany_referral_data(companyShareReferDetails))
      .then(() => {
        // Reset the form fields and update the state after the API call is successful
        setCompanyShareReferDetails({ ...companyShareReferDetails }); // Replace `initialFormState` with the initial state of your form
        setApiSuccess(true);
      })
      .catch((error) => {
        // Handle error if the API call fails
        ;
      });
    // 
  };
  // 
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />

      <>
        <div className="row mb-3">
          <div className="onetap_bg_box" style={{ padding: "25px 30px" }}>
            <h1 className="onetap_heading_main">Share & Referral options</h1>
            <hr style={{ color: "#F2F2F2", opacity: "1" }} />
            <p className="onetap_ptag">
              Here you can control and personalized the sharing options
              available to your team to share their cards through the mobile
              application as well as the referral options for clients and
              visitors who’d like to refer your services. See the video to
              learn more about how to setup and use the OneTapConnect sharing
              and referral features.
              <a
                href="https://support.onetapconnect.com/docs/how-to-use-the-onetap-connect-button"
                style={{ textDecoration: "none" }}
              >
                {" "}
                See the video to learn more about how to setup and use the
                OneTapConnect sharing and referral features.
              </a>
            </p>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row d-flex share_refer_container">
              <div
                className="col mb-3 w-50"
                style={{ "--bs-gutter-x": "unset", margin: "0", padding: "0" }}
              >
                <div className="onetap_bg_box">
                  {userInfo?.subscription_details?.plan === "Professional" ? <h2 className="onetap_heading_h2">
                    Your sharing options (through the mobile application):
                  </h2> : <h2 className="onetap_heading_h2">
                    Team member sharing options (through the mobile application):
                  </h2>}

                  <p className="usercustomallowfieldp">
                    NOTE: Leave empty to use the default message.
                  </p>
                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                  <h2 className="onetap_heading_h2">Share by Text Message:</h2>
                  <p className="onetap_ptag">Default message</p>

                  <div className="d-flex align-items-center">
                    <textarea
                      type="text"
                      className="lead_input w-100"
                      rows={2}
                      placeholder="Hi this is {user_name} at {company_name}. Please find below the link to my business card. "
                      value={shareByText}
                      onChange={(e) => {
                        setShareByText(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            share_by_text: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          shareByText,
                          setShareByText,
                          "{user_name}",
                          "share_by_text"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>

                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                  <h2 className="onetap_heading_h2">Share by Email:</h2>
                  <p className="onetap_ptag">Default message</p>

                  <div className="d-flex align-items-center">
                    <textarea
                      style={{ minHeight: "80px" }}
                      rows={4}
                      type="text"
                      className="lead_input w-100 border-0"
                      placeholder="Hi! This is {user_name} at {company_name}. Please find below the link to my Digital Business Card on which you'll be able to learn more about me, my company and the services we provide. 
              Have a great day!"
                      value={shareByEmail}
                      onChange={(e) => {
                        setShareByEmail(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            share_by_email: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          shareByEmail,
                          setShareByEmail,
                          "{user_name}",
                          "share_by_email"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>
                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />

                  <h2 className="onetap_heading_h2">Social Media Sharing:</h2>
                  <p className="onetap_ptag">Default message</p>

                  <div className="d-flex align-items-center">
                    <textarea
                      style={{ minHeight: "80px" }}
                      rows={4}
                      type="text"
                      className="lead_input w-100"
                      placeholder="Hi! This is {user_name} at {company_name}. Please find below the link to my Digital Business Card on which you'll be able to learn more about me, my company and the services we provide. 
              Have a great day!"
                      value={shareBySocial}
                      onChange={(e) => {
                        setShareBySocial(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            share_by_socialmedia: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          shareBySocial,
                          setShareBySocial,
                          "{user_name}",
                          "share_by_socialmedia"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>
                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                </div>
              </div>
              <div
                className="col mb-3 w-50"
                style={{ "--bs-gutter-x": "unset", margin: "0", padding: "0" }}
              >
                <div className="onetap_bg_box">
                  <h2 className="onetap_heading_h2">
                    Client and visitor referral options (through your business
                    card):
                  </h2>
                  <p className="usercustomallowfieldp">
                    NOTE: Leave empty to use the default message.
                  </p>
                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                  <h2 className="onetap_heading_h2">Referral by Text Message:</h2>
                  <p className="onetap_ptag">Default message</p>

                  <div className="d-flex align-items-center">
                    <textarea
                      type="text"
                      className="lead_input w-100"
                      rows={3}
                      placeholder="I thought you may be interested in {user_name} OneTapConnect card. Click the link below to learn about his company {company_name} and services."
                      value={referByText}
                      onChange={(e) => {
                        setReferByText(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            refer_by_text: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          referByText,
                          setReferByText,
                          "{user_name}",
                          "refer_by_text"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>

                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />
                  <h2 className="onetap_heading_h2">Referral by Email:</h2>
                  <p className="onetap_ptag">
                    NOTE: When sharing by email, the person (referrer) will fill
                    out a form allowing to gather his name and personalize the
                    message.
                  </p>
                  <p className="onetap_ptag">Default message</p>
                  <div className="d-flex align-items-center">
                    <textarea
                      style={{ minHeight: "200px" }}
                      rows={6}
                      type="text"
                      className="lead_input w-100"
                      placeholder="Hi! {referrer_name} is inviting you to see {user_name} Digital Business Card. Click the link below to learn more about his company {company_name} and services. 

                {card URL}
                
                Have a great day!
                
                The OneTapConnect team
                "
                      value={referByEmail}
                      onChange={(e) => {
                        setReferByEmail(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            refer_by_email: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          referByEmail,
                          setReferByEmail,
                          "{user_name}",
                          "refer_by_email"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          referByEmail,
                          setReferByEmail,
                          company?.referrer_name || "{referrer_name}",
                          "refer_by_email"
                        )
                      }
                    >
                      {company?.referrer_name || "{referrer_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>
                  <hr style={{ color: "#F2F2F2", opacity: "1" }} />

                  <h2 className="onetap_heading_h2">
                    Referral by Social Media Sharing:
                  </h2>
                  <p className="onetap_ptag">Default message</p>

                  <div className="d-flex align-items-center">
                    <textarea
                      type="text"
                      className="lead_input w-100"
                      rows={3}
                      placeholder="I thought you may be interested in {user_name} OneTapConnect card. Click the link below to learn about his company {company_name} and services. "
                      value={referBySocial}
                      onChange={(e) => {
                        setReferBySocial(e.target.value);
                        if (e.target.value !== "") {
                          setCompanyShareReferDetails((prevDetails) => ({
                            ...prevDetails,
                            refer_by_socialmedia: e.target.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <div
                      className="lead_dynamic_box"
                      onClick={() =>
                        handleFieldClick(
                          referBySocial,
                          setReferBySocial,
                          "{user_name}",
                          "refer_by_socialmedia"
                        )
                      }
                    >
                      {"{user_name}"}
                    </div>
                  </div>
                  <p className="usercustomallowfieldp">
                    **Drag and drop to add the dynamic field
                  </p>
                </div>
              </div>
            </div>
            <Modal
              open={apiSuccess}
              onClose={() => setApiSuccess(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
                <div className="Modal-section">
                  <div className="checkmark-container">
                    <div class="checkmark-container">
                      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                        class="circle">
                        <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                          fill="#E65925" />
                        <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                      </svg>
                    </div>
                  </div>
                  <span className="team_created_success">Data updated successfully!</span>
                </div>
                <button
                  className="Modal-btn sec-modal-btn"
                  style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
                  onClick={() => setApiSuccess(false)}
                >
                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
                </button>
              </Box>
            </Modal>
            <div className="row mt-3">
              <div className="d-flex justify-content-end">
                <button
                  className="onetap_connect__comp_profile_save_button_syn"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </>

    </>
  );
};

export default SettingShareReferTab;
