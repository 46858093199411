import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../components/Layout/Loader/Loader";
import axios from "axios";
import pdfIcon from "../../../assets/icons/pdfIcon.svg";
import eyeIcon from "../../../assets/icons/eyeIcon.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ProductIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/ProductIcon.svg";
import AddonIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_addons_icon.svg";
import UserIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_users_icon.svg";
import PlanIcon from "../../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Otc_plan_icon.svg";

const ClientCompanyTransactions = () => {
  const [loading, setLoading] = useState(false);
  const url = process.env.REACT_APP_URL;
  const [activeButton, setActiveButton] = useState(0);
  const { id } = useParams();
  const [orders, setOrders] = useState([]);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${url}/admin/getorderssofcompany`, {
          id,
        });

        setOrders(response.data.Orderssofcompany); // Assuming the response is an array of orders
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="onetap_conn_transaction_table">
            <div
              className="tran-title-main-div"
              style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
            >
              <span className="">Orders</span>
              <div className="trans-comm-text-center">
                <span>Status</span>
              </div>
              <span className="trans-comm-text-center">Order Date </span>
              <div className="trans-comm-text-center">
                <span>Total</span>
              </div>
              <div className="trans-comm-text-center">
                <span>Type</span>
              </div>
              <div className="trans-comm-text-center">
                <span>Fulfilment</span>
              </div>
            </div>
            <div className="trans-main-card-container">
              {orders?.map((e, index) => {
                const userName =
                  e.first_name && e.last_name
                    ? `${e.first_name} ${e.last_name}`
                    : e.first_name
                    ? e.first_name
                    : e.last_name
                    ? e.last_name
                    : "User";

                const paymentDate = new Date(e.paymentDate);
                const formattedPaymentDate = paymentDate.toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                );
                const totalAmount =
                  e.type === "Subscription"
                    ? !e.subscription_details.total_amount
                      ? "0"
                      : e.subscription_details.total_amount
                    : e.totalAmount;
                // const tax = e.type === 'Subscription' ? e.subscription_details.taxRate : e.tax;
                const taxRate =
                  e.type === "Subscription"
                    ? e.subscription_details.taxRate
                    : e.tax;
                const tax = (
                  (parseFloat(totalAmount) * parseFloat(taxRate)) /
                  100
                ).toFixed(2); // Convert percentage to decimal and calculate tax
                let shipping;
                if (e.shipping_method?.length > 0) {
                  shipping =
                    e.type === "Subscription"
                      ? e.shipping_method[0]?.price
                      : e.shipping_method[0]?.price;
                } else {
                  shipping = 0;
                }

                const total =
                  parseInt(totalAmount, 10) +
                  parseFloat(tax) +
                  parseInt(shipping, 10);

                let SubscriptionText = "";
                let Addons = "";
                let Accessories = "";
                let Usercount = "";
                if (e.type === "Subscription") {
                  if (
                    e?.subscription_details?.total_user[0]?.additionalUser >
                      0 &&
                    e?.subscription_details?.addones?.length > 0
                  ) {
                    Usercount = UserIcon;
                    SubscriptionText = PlanIcon;
                    Addons = AddonIcon;
                  } else if (e?.subscription_details?.addones?.length > 0) {
                    SubscriptionText = PlanIcon;
                    Addons = AddonIcon;
                  } else if (
                    e?.subscription_details?.total_user[0]?.additionalUser > 0
                  ) {
                    SubscriptionText = PlanIcon;
                    Usercount = UserIcon;
                  } else {
                    SubscriptionText = PlanIcon;
                  }
                } else if (e.type === "smartAccessories") {
                  Accessories = ProductIcon;
                } else if (e.type === "AddonPurchase") {
                  Addons = AddonIcon;
                } else if (e.type === "UserPurchase") {
                  Usercount = UserIcon;
                }
                return (
                  <div
                    key={index}
                    className="trans-cards"
                    style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}
                  >
                    <div className="add-new-btn-group">
                      <div className="product-details">
                        <Link
                          to={`/admin/clientordersummary/${e._id}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <span className="product-name-text trans-ordernumber-text">
                            #{e.orderNumber}
                          </span>
                        </Link>
                        <span className="trans-view-text"> {userName}</span>
                      </div>
                    </div>
                    <div className="trans-comm-text-center">
                      <span
                        className="trans-comm-text-status"
                        style={{ backgroundColor: "#F2F2F2" }}
                      >
                        <p
                          style={{
                            backgroundColor:
                              e.status === "Failed"
                                ? "#FDE9E9"
                                : e.status === "Pending"
                                ? "#FFF"
                                : "#F0FEEB",
                            color:
                              e.status === "Failed" ? "#E62525" : "#48AF25",
                            border:
                              e.status === "Pending"
                                ? "1px solid #333"
                                : "1px solid #48AF25",
                            padding: "10px",
                            borderRadius: "5px",
                            width: "150px",
                            display: "inline-block",
                            margin: "0px",
                          }}
                        >
                          {e.status}
                        </p>
                      </span>
                    </div>
                    <span className="trans-comm-text-center trans-amount-text">
                      {formattedPaymentDate}
                    </span>
                    <span className="trans-comm-text-center trans-amount-text">
                      ${total}
                    </span>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      {Accessories && <img src={Accessories} />}
                      {SubscriptionText && <img src={SubscriptionText} />}
                      {Addons && <img src={Addons} />}
                      {Usercount && <img src={Usercount} />}
                    </div>
                    <div className="trans-comm-text-center">
                      <span
                        className="trans-comm-text-status"
                        style={{ backgroundColor: "#f2f2f2" }}
                      >
                        {e.fulfillment}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientCompanyTransactions;
