
import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import OtcGuestUsersHeaderWithIcons from "./OtcGuestUsersHeaderWithIcons";
import OtcGuestUsers from "./OtcGuestUsers";

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Users");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user">
            <OtcGuestUsersHeaderWithIcons onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "Users" && (
                <OtcGuestUsers
                    onClickSave={getValueOfSaveAndContinueTab}
                   />

            )}
        </div>
    );
};


export default function OtcGuestUsersMain() {
    return (
        <>
            <Otc_Sidebar Component={Component} />
        </>
    )
}





