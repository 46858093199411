import React, { useEffect, useState, useRef } from "react";
import GenInfoToggleButton from "../../../../components/Customers/CompanyProfile/GenInfoToggleButton";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../../components/Layout/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import axios from 'axios';
import Select from "react-select";
import { City, Country, State } from "country-state-city";
import { Button, Tooltip, Modal, styled, tooltipClasses, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getAllUsersOfCompany } from "../../../Redux/Otc_UserActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 2,
  outline: "none",
  borderRadius: "10px",
};
const cropingstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  backgroundColor: "white", // Changed bgcolor to backgroundColor
  padding: "16px", // Added padding for better appearance
  outline: "none",
  borderRadius: "10px",
};

const AdminAddusermanuallyinfo = (props) => {
  const [imageURL, setImageURL] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {  companydata, userInformationTeamData , allteamdata } = useSelector(
    (state) => state.usersOfCompany
);

  const userEmails = userInformationTeamData && userInformationTeamData?.map((user) => user?.user_id.email);
  const [contact, setContact] = useState("");
  const [apiError, setApiError] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [newJobTitle, setNewJobTitle] = useState("");
  const [teamMemberDetails, setTeamMemberDetails] = useState({});
  const [addressPermission, setAddressPermission] = useState(false);
  const [apartmentPermission, setApartmentPermission] = useState(false);
  const [cityPermission, setCityPermission] = useState(false);
  const [statePermission, setStatePermission] = useState(false);
  const [postalCodeValid, setPostalCodeValid] = useState(true);
  const [postalCodePermission, setPostalCodePermission] = useState(false);
  const [profilePictureerrormsj, setprofilePictureerrormsj] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setcroppedImage] = useState(null);
  const [userInfoDetails, setUserInfoDetails] = useState({});
  const [openCropModal, setOpenCropModal] = useState(false);
  const [selectedDp, setSelectedDp] = useState(false);
  const { id } = useParams();
  const cropperRef = useRef(null);
  const url = process.env.REACT_APP_URL;
  const [validationErrors, setValidationErrors] = useState({});
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  const { userInformation } = useSelector(
    (state) => state.userInformationDetails
  );
  useEffect(() => {
    setUserInfoDetails(userInformation);
  }, [userInformation]);

  const [countryValue, setCountryValue] = useState(null); // Initialize the selected country state
  const [stateValue, setStateValue] = useState(""); // State for selected state
  const handleCountryChange = (value) => {
    setCountryValue(value);
    setStateValue("");
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        country: value,
      },
    }));
  };
  const handleStateChange = (value) => {
    setStateValue(value);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        state: value,
      },
    }));
  };
  const disableicon = (
    <span>
      Not editable. Your company has locked this field, and it is displayed for
      informational purposes.
    </span>
  );
  // 
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    // Custom styles for the tooltip
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#E65925",
      color: "#fff",
      fontSize: 13,
      maxWidth: "none",
      padding: "10px",
    },
  }));
  const handleToggleAddressPermission = () => {
    setAddressPermission(!addressPermission);
    
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_line1_address_permission: !addressPermission,
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      user_line1_address_permission: !addressPermission,
    }));
  };
  const handleToggleApartmentPermission = () => {
    setApartmentPermission(!apartmentPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_line2_apartment_permission: !apartmentPermission,
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      user_line2_apartment_permission: !apartmentPermission,
    }));
  };
  const handleToggleCityPermission = () => {
    setCityPermission(!cityPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_city_permission: !cityPermission,
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      user_city_permission: !cityPermission,
    }));
  };
  const handleToggleStatePermission = () => {
    setStatePermission(!statePermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_state_permission: !statePermission,
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      user_state_permission: !statePermission,
    }));
  };
  const handleTogglePostalCodePermission = () => {
    setPostalCodePermission(!postalCodePermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_postal_code_permission: !postalCodePermission,
    }));
    setFormData(prevFormData => ({
      ...prevFormData,
      user_postal_code_permission: !postalCodePermission,
    }));
  };
  const allowedImageTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg+xml",
    "image/jpg",
    "image/avif",
  ]
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the selected file type is not in the allowed types
      if (!allowedImageTypes.includes(file.type)) {
        alert("The selected file type is not supported.\nPlease use PNG, JPG, SVG format.");
        return; // Return early if the file type is not allowed
      }
      setOpenCropModal(true)
      setSelectedDp(file)
    }
  };
  useEffect(() => {
    if (openCropModal && selectedDp) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          cropperRef.current.src = e.target.result;
          const cropper = new Cropper(cropperRef.current, {
            dragMode: "move",
            aspectRatio: 1 / 1,
            viewMode: 1,
          });
          cropperRef.current.cropper = cropper;
        };
      };
      reader.readAsDataURL(selectedDp);
      setSelectedFile(selectedDp);
    }
  }, [openCropModal, selectedDp]);

  useEffect(() => {
    dispatch(getAllUsersOfCompany(id));
  }, [id]);

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";
      // Use the original image format for the cropped image
      const croppedImage = croppedCanvas.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );
      const cropper = cropperRef.current.cropper;
      await cropper.destroy();
      setcroppedImage(croppedImage);
      setOpenCropModal(false);
    }
  };
  const handlecropmodalclose = () => {
    setcroppedImage(null);
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setOpenCropModal(false);
  };
  const handleuploadorignal = () => {
    if (cropperRef.current) {
      const getorignalimages = cropperRef.current.cropper.reset(); // Clear the cropping area
      const getorignal = getorignalimages.clear(); // Clear the cropping area
      const getorignalimage = getorignal.getCroppedCanvas();
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";
      const croppedImage = getorignalimage.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );
      setcroppedImage(croppedImage)
      setOpenCropModal(false);
    }
  }
  const handlezoominlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1); // Clear the cropping area
    }
  };
  const handleswapupdown = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleY = cropper.getData().scaleY; // Get the current X scaling factor
      cropper.scaleY(-scaleY); // Toggle the X scaling factor
    }
  };
  const handleswaprightleft = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleX = cropper.getData().scaleX; // Get the current X scaling factor
      cropper.scaleX(-scaleX); // Toggle the X scaling factor
    }
  };
  const handlezoomoutlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1); // Clear the cropping area
    }
  };
  const handlerotate = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(-90); // Clear the cropping area
    }
  };
  const handlerotateback = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(90); // Clear the cropping area
    }
  };
  const handlelogoup = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, -10); // Clear the cropping area
    }
  };
  const handlelogodown = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, 10); // Clear the cropping area
    }
  };
  const handlelogoleft = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(-10, 0); // Clear the cropping area
    }
  };
  const handlelogoright = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(10, 0); // Clear the cropping area
    }
  };
  const handleresetlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset(); // Clear the cropping area
    }
  };

  const handleUploaderClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setprofilePictureerrormsj(null)

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
    ofcnumber: "",
    designation: [],
    website_url: "",
    team: "",
    address: {
      country: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
    },
    avatar: "",
    user_line1_address_permission: false,
    user_line2_apartment_permission: false,
    user_city_permission: false,
    user_state_permission: false,
    user_postal_code_permission: false,
  });

  const handleDesignationChange = (index, value) => {
    const updatedDesignations = [...formData?.designation];
    updatedDesignations[index] = value;
    setFormData((prevDetails) => ({
      ...prevDetails,
      designation: updatedDesignations,
    }));

  };
  const handleNewJobTitleChange = (e) => {
    setNewJobTitle(e.target.value);
    document.getElementById("err").innerHTML = null;
  };
  const MAX_JOB_TITLES = 6; // Define the maximum allowed job titles
  const designationlength = formData?.designation?.length;
  const handleAddJobTitle = () => {
    if (!newJobTitle) {
      document.getElementById("err").innerHTML = "Please enter job title";
      return; // Exit the function if newJobTitle is falsy
    }
    const isMatching = formData?.designation
    .map((designation) => designation.trim().toLowerCase())
    .includes(newJobTitle.trim().toLowerCase());
  if (isMatching) {
    document.getElementById("err").innerHTML =
      "Job title already in use. Please select another.";
    return; // Exit the function if newJobTitle is falsy
  }
    if (newJobTitle.trim() !== "") {
      // Create a copy of the designations array
      const updatedDesignations = [...formData.designation];

      if (updatedDesignations.length < MAX_JOB_TITLES) {
        // Add the new job title to the updatedDesignations array
        updatedDesignations.push(newJobTitle);

        // Update the state with the new designations array
        setFormData((prevDetails) => ({
          ...prevDetails,
          designation: updatedDesignations,
        }));
        setNewJobTitle("");
        setModalOpen(false);
      } else {
        document.getElementById("err").innerHTML =
          "Maximum six job titles are allowed";
      }
    }
  };
  const handleRemoveJobTitle = (indexToRemove) => {
    const updatedDesignations = [...formData.designation];
    updatedDesignations.splice(indexToRemove, 1); // Remove the job title at the specified index

    setFormData((prevDetails) => ({
      ...prevDetails,
      designation: updatedDesignations,
    }));
  };
  const handleInputChange = (e) => {
    if (e.target.name === 'contact') {
      const newValue = e.target.value.replace(/\D/g, '').slice(0, 25);
      setFormData({
        ...formData,
        [e.target.name]: newValue,
      });
    } else if (e.target.name === 'team') {
      const selectedTeam = allteamdata.find(team => team.team_name === e.target.value);
      if (selectedTeam) {
        setFormData({
          ...formData,
          team: selectedTeam._id, // Set team_id
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "",
      }));
    }
  };
  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!emailPattern.test(formData.email.trim())) {
      errors.email = "Please enter a valid email address.";
    } else if (userEmails?.includes(formData.email)) {
      errors.email = "Email is already existing.";
    }
    if (formData.firstname.trim() === "") {
      errors.firstname = "First name is required.";
    }
    if (!formData.lastname.trim()) {
      errors.lastname = "Last name is required.";
    }
    // Trim all values in the designation array
    const trimmedDesignations = formData?.designation.map(
      (designation) => designation?.trim().toLowerCase()
    );
    trimmedDesignations.forEach((designation, index) => {
      if (!designation) {
        errors.designationErrors = "Please enter job title.";
      } else if (trimmedDesignations.indexOf(designation) !== index) {
        errors.designationErrors = "Job title already in use. Please select another.";
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const handleSaveAndInvite = async () => {
    if (!validateForm()) {
      return
    } else {
      try {

        const { data } = await axios.post(
          `${url}/admin/invite/manual`,
          { formData: formData , id},
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (data.success) {
          handleImageSubmit(data.userID)
          setApiSuccess(true);
          setSuccessMessage("Invitation sent successfully!");
          setTimeout(() => {
            navigate(`/admin/user/profile/${data.userID}`);
          }, 3000);
          setShowPopup(true);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          if (error.response.data.message === "This email is already in use.") {
            setApiError("This email is already in use.")
          }
        } else {
          // setApiError("An error occurred while processing your request.");
        }
      }
    }
  };

  const handleImageSubmit = async (userID) => {
    try {
      const formDataWithImage = new FormData();
      formDataWithImage.append('avatar', selectedFile);
      formDataWithImage.append('userID', userID);

      const { data } = await axios.post(
        `${url}/users/image-upload`, // Replace with your image upload endpoint
        formDataWithImage,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      if (data.success) {
        // Optionally, you can handle the response, e.g., display a success message to the user.
        setApiSuccess(true);
        setSuccessMessage("Invitation sent successfully!");
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleCombinedSubmit = async () => {
    await handleSaveAndInvite();
  };

  return (
    <>
        <>
          <div class="row">
            <Companygeneralinfo />
          </div>
          <div className="row">
            <div className="col p-0 mt-2">
              <div className="onetap_conn_general_information_syn">
                <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                  Contact Information
                </h5>
                <div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      First name*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      name="firstname" // Add name attribute
                      value={formData.firstname} // Add value attribute
                      onChange={handleInputChange}
                    />
                    {validationErrors.firstname && (
                      <div className="error-message">{validationErrors.firstname}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Last name*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      name="lastname" // Add name attribute
                      value={formData.lastname} // Add value attribute
                      onChange={handleInputChange}
                    />
                    {validationErrors.lastname && (
                      <div className="error-message">{validationErrors.lastname}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Email*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="email"
                      placeholder=""
                      name="email" // Add name attribute
                      value={formData.email} // Add value attribute
                      onChange={(e)=>{handleInputChange(e);setApiError("")}}
                    />
                    {apiError ? 
                      (<div className="error-message">{apiError}</div>)
                      : validationErrors.email ?( <div className="error-message">{validationErrors.email}</div>
                    ): ""}
                  </div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Cell phone number
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      name="contact" // Add name attribute
                      value={formData.contact} // Add value attribute
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Website URL
                    </p>
                    {companydata?.website_url_permission === true ?
                      (<input
                        className="onetap_conn_general_information_input_syn"
                        type="text"
                        placeholder=""
                        name="website_url" // Add name attribute
                        value={formData.website_url} // Add value attribute
                        onChange={handleInputChange}
                      />) : (<LightTooltip placement="top" title={disableicon}>
                        <input
                          className="onetap_conn_general_information_input_syn"
                          type="text"
                          name="websiteUrl"
                          value={companydata?.website_url}
                          readOnly
                          style={{ opacity: "0.6" }}
                        />
                      </LightTooltip>)}
                  </div>
                </div>
                <div>
                  <hr />
                  <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                    Job Title
                  </h5>
                  {formData?.designation?.map(
                    (designation, index) => (
                      <div className="d-flex align-items-center  mb-3">
                        <input
                          className="onetap_conn_general_information_input_syn"
                          type="text"
                          placeholder=""
                          name="designation"
                          value={formData?.designation?.[index]} // Replace `index` with the actual index
                          onChange={(e) =>
                           { handleDesignationChange(index, e.target.value);     setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            designationErrors : "",
                          }));}
                          } // Define handleDesignationChange function
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemoveJobTitle(index)}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                            fill="#8A8A8A"
                          />
                        </svg>
                      </div>
                    )
                  )}
                   {validationErrors.designationErrors && (
                      <div className="error-message">{validationErrors.designationErrors}</div>
                    )}
                  {designationlength === 6 ? (
                    <button
                      className="onetap_conn_general_information_input_syn_addanotherbtn"
                      style={{ opacity: 0.5 }} // Reduce opacity when disabled
                      disabled
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                          fill="white"
                        />
                      </svg>{" "}
                      &nbsp; Add
                    </button>
                  ) : (
                    <button
                      className="onetap_conn_general_information_input_syn_addanotherbtn"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                          fill="white"
                        />
                      </svg>{" "}
                      &nbsp; Add another
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-2 ms-3">
              <div className="row mb-2">
                <div className="onetap_conn_general_information_syn">
                  <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                    Profile picture
                  </h5>
                  <p className="onetap_conn_general_information_para_syn">
                    Upload a high quality picture of yourself to show on your
                    OneTapConnect card.
                  </p>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <div className="image-uploader">
                      {!selectedFile && !imageURL ? (
                        <label
                          htmlFor="upload-input"
                          className="upload-box"
                          onClick={handleUploaderClick}
                        >
                          <div className="image-preview">
                            <span className="upload-icon">
                              <svg
                                width="46"
                                height="34"
                                viewBox="0 0 46 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                  fill="#838383"
                                />
                              </svg>{" "}
                              <br />
                              <p className="onetap_conn_general_information_para_syn m-0">
                                Upload
                              </p>
                            </span>
                          </div>
                        </label>
                      ) : (
                        <>
                          <label
                            htmlFor="upload-input"
                            className="upload-box"
                            onClick={() => { setOpenCropModal(true) }}
                          >
                            <div className="image-preview">
                              {selectedFile && (
                                <img
                                  src={croppedImage || URL.createObjectURL(selectedFile)}
                                  alt="Uploaded"
                                  style={{ borderRadius: "none", width: "90%" }}
                                />
                              )}
                            </div></label> <span
                              className="remove-icon"
                              onClick={handleRemoveImage}
                            >
                            &#10005;
                          </span></>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="onetap_conn_general_information_para_syn">
                      Accepted file type: SVG, PNG, JPG
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {companydata?.make_private_permission === true ?
                  (<div className="onetap_conn_general_information_syn">
                    <div className="d-flex justify-content-between">
                      <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                        Address
                      </p>
                      <p className="fs-6 fw-medium" style={{ color: "#E65925" }}>
                        Make Private
                      </p>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Country
                      </p>
                      <div
                        className="dropdown countrydrop"
                        style={{ width: "100%" }}
                      >
                        <div className="select-container">
                          <Select
                            styles={{
                              container: (provided) => ({
                                width: "100%", // Set the width of the container as needed
                              }),
                              control: () => ({
                                // No border or box-shadow
                                margin: 0,
                                padding: 0,
                                border: "none",
                                boxShadow: "none",
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: 0, // Remove margin
                                padding: 0, // Remove padding
                              }),
                              indicatorsContainer: () => ({
                                display: "none",
                              }),
                              option: (provided) => ({
                                ...provided,
                              }),
                            }}
                            className="onetap_conn_general_information_input_syn mb-3 form-select"
                            placeholder="country"
                            name="address.country"
                            options={Country &&
                              Country.getAllCountries().map((item) => ({
                                value: item.isoCode,
                                label: item.name,
                              }))}
                            value={{
                              value: teamMemberDetails?.address?.country || "", // Set an empty string if label is null
                              label: Country.getCountryByCode(
                                teamMemberDetails?.address?.country
                              )?.name || "Please Select Country", // Set placeholder text if label is null
                            }}
                            onChange={(e) => {
                              handleCountryChange(e.value);
                              setFormData(prevFormData => ({
                                ...prevFormData,
                                address: {
                                  ...prevFormData.address,
                                  country: e.value
                                }
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Address
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="line1"
                          value={formData.address.line1}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: {
                              ...formData.address,
                              line1: e.target.value
                            }
                          })}
                        />
                        <GenInfoToggleButton
                          isOn={addressPermission}
                          onToggle={handleToggleAddressPermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Apt, suite, etc
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="line2"
                          value={formData.address.line2}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: {
                              ...formData.address,
                              line2: e.target.value
                            }
                          })}
                        />
                        <GenInfoToggleButton
                          isOn={apartmentPermission}
                          onToggle={handleToggleApartmentPermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        City
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="city"
                          value={formData.address.city}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: {
                              ...formData.address,
                              city: e.target.value
                            }
                          })}
                        />
                        <GenInfoToggleButton
                          isOn={cityPermission}
                          onToggle={handleToggleCityPermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        State
                      </p>
                      <div className="d-flex justify-content-between ">
                        <div
                          className="dropdown countrydrop"
                          style={{ width: "90%" }}
                        >
                          <div className="select-container">
                            <Select
                              styles={{
                                container: (provided) => ({
                                  width: "100%", // Set the width of the container as needed
                                }),
                                control: () => ({
                                  margin: 0,
                                  padding: 0,
                                  border: "none",
                                  boxShadow: "none",
                                }),
                                input: (provided) => ({
                                  ...provided,
                                  margin: 0, // Remove margin
                                  padding: 0, // Remove padding
                                }),
                                indicatorsContainer: () => ({
                                  display: "none",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                }),
                              }}
                              className="onetap_conn_general_information_input_syn mb-3 form-select"
                              placeholder="Select"
                              name="address.state"
                              options={State.getStatesOfCountry(
                                teamMemberDetails?.address?.country
                              ).map((item) => ({
                                value: item.isoCode,
                                label: item.name,
                              }))}
                              value={{
                                value: teamMemberDetails?.address?.state || "", // Set an empty string if label is null
                                label: State.getStateByCodeAndCountry(
                                  teamMemberDetails?.address?.state,
                                  teamMemberDetails?.address?.country
                                )?.name || "Please select State", // Set placeholder text if label is null
                              }}
                              onChange={(e) => {
                                handleStateChange(e.value);
                                setFormData(prevFormData => ({
                                  ...prevFormData,
                                  address: {
                                    ...prevFormData.address,
                                    state: e.value
                                  }
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <GenInfoToggleButton
                          isOn={statePermission}
                          onToggle={handleToggleStatePermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Postal code
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="postal_code"
                          value={formData.address.postal_code}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: {
                              ...formData.address,
                              postal_code: e.target.value
                            }
                          })}
                        />
                        <GenInfoToggleButton
                          isOn={postalCodePermission}
                          onToggle={handleTogglePostalCodePermission}
                        />
                      </div>
                      {!postalCodeValid && (
                        <p className="error_message">Invalid Postal Code</p>
                      )}
                    </div>
                  </div>) : <></>}
              </div>
              <div></div>
            </div>
          </div>
          <Modal
            open={apiSuccess}
            onClose={() => setApiSuccess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
              <div className="Modal-section">
                <div className="checkmark-container">
                  <div class="checkmark-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                      class="circle">
                      <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                        fill="#E65925" />
                      <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                    </svg>
                  </div>
                </div>
                <span className="team_created_success">{successMessage}</span>
              </div>
              <button
                className="Modal-btn sec-modal-btn"
                style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
                onClick={() => setApiSuccess(false)}
              >
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
              </button>
            </Box>
          </Modal>
          <div className="row mt-5">
            <div className="d-flex justify-content-end">
              <button
                className="onetap_connect__comp_profile_save_button_syn"
                onClick={() => { navigate(-1) }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="onetap_connect__comp_profile_save_and_cont_button_syn"
                onClick={handleCombinedSubmit}
              >
                Save & Invite User
              </button>
            </div>
          </div>
          {showPopup && (
            <div className="popup">
              <div className="popup-content">
                <span onClick={() => setShowPopup(false)} className="close-popup">
                  &times;
                </span>
                <p>User invited successfully!</p>
              </div>
            </div>
          )}
        </>
      <Modal
        open={isModalOpen}
        onClose={(e) => {
          setModalOpen(false);
          setNewJobTitle(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Modal-section">
            <h2 className="header" style={{ marginBottom: "1rem" }}>
              Add Title{" "}
            </h2>
            <input
              type="text"
              name="teamName"
              className="input-field m-0"
              placeholder="Ex: Marketing"
              value={newJobTitle}
              onChange={handleNewJobTitleChange}
            />
            <p id="err" className="error_message mt-1 mb-0"></p>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn"
                onClick={handleAddJobTitle}
              >
                Add
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={(e) => {
                  setModalOpen(false);
                  setNewJobTitle(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openCropModal} onClose={handlecropmodalclose}>
        <div>
          <div style={cropingstyle} className="modal-content">
            {selectedDp && (
              <div style={{ width: "100%", height: "80%" }}>
                <img
                  src={croppedImage}
                  ref={cropperRef}
                  alt="Selected Image"
                  style={{ maxWidth: "50%" }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                margin: "10px 0px",
              }}
            >
              <Tooltip title="Flip vertical" arrow>
                <SwapVertOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswapupdown}
                />
              </Tooltip>
              <Tooltip title="Flip horizontal" arrow>
                <SwapHorizOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswaprightleft}
                />
              </Tooltip>
              <Tooltip title="Zoom in" arrow>
                <ZoomInIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoominlogo}
                />
              </Tooltip>
              <Tooltip title="Zoom out" arrow>
                <ZoomOutIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoomoutlogo}
                />
              </Tooltip>
              <Tooltip title="Move Up" arrow>
                <ArrowUpwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoup}
                />
              </Tooltip>
              <Tooltip title="Move down" arrow>
                <ArrowDownwardOutlined
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogodown}
                />
              </Tooltip>
              <Tooltip title="Move left" arrow>
                <ArrowBackOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoleft}
                />
              </Tooltip>
              <Tooltip title="Move right" arrow>
                <ArrowForwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoright}
                />
              </Tooltip>
              <Tooltip title="Rotate left" arrow>
                <Rotate90DegreesCcwIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotate}
                />
              </Tooltip>
              <Tooltip title="Rotate right" arrow>
                <Rotate90DegreesCwOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotateback}
                />
              </Tooltip>
              <Tooltip title="Reset" arrow>
                <RestartAltIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleresetlogo}
                />
              </Tooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleCrop}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Crop Image
              </Button>
              <Button
                onClick={handleuploadorignal}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Upload
              </Button>
              <Button
                onClick={handlecropmodalclose}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Companygeneralinfo = () => {
  return (
    <div className="onetap_conn_general_information_syn">
      <h4 className="onetap_conn_general_information_heading_syn">
        Add User Manually
      </h4>
      <p className="onetap_conn_general_information_para_syn">
        The below information are synchronized and displayed on the user
        OneTapConnect card. Any update will immediately be reflected on the user
        card.
      </p>
      <hr style={{ height: "px" }} />
    </div>
  );
};

export default AdminAddusermanuallyinfo;

export { Companygeneralinfo }
