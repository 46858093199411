import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Layout/Loader/Loader";
import addUserIcon from "../../assets/icons/addUsericon.svg";
import removeUserIcon from "../../assets/icons/removeUserIcon.svg";
import viewTemplateIcon from "../../assets/icons/viewTemplateIcon.svg";
import moreIcon from "../../assets/icons/moreIcon.svg";
import { Modal, Box } from "@mui/material";
import { Avatar } from "@mui/material";
import { getTeamsofOtcAdmin } from "../Redux/Otc_UserActions";

const renameModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};

const styleOfTeamOverview = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};

const OTC_adminTeams = () => {
  const url = process.env.REACT_APP_URL;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  const [teams, setTeams] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const [renameTeam, setRenameTeam] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openAddTeamModal, setOpenAddTeamModal] = useState(false);
  const [addUserErrors, setAddUserErrors] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersInSelectedTeam, setUsersInSelectedTeam] = useState("");
  const [remainingUsers, setRemainingUsers] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [Users, setUsers] = useState([]);
  const [removeUserErrors, setremoveUserErrors] = useState({});
  const [openEditTeamModal, setOpenEditTeamModal] = useState(false);
  const [renameUserErrors, setrenameUserErrors] = useState({});
  const dispatch = useDispatch();
  const { otcadminteams, loading } = useSelector((state) => state.otcadminteams);
  useEffect(() => {
    if (!otcadminteams) {
      dispatch(getTeamsofOtcAdmin())
    }
    else { setTeams(otcadminteams) }
  }, [otcadminteams]);

  const handleMoreOption = (teamName) => {
    if (showOptions === teamName) {
      setShowOptions(null);
    } else {
      setShowOptions(teamName);
    }
  };

  const allteam = teams?.map((e) => e.team_name)
  const handleRenameTeam = async () => {
    if (!renameTeam) {
      setrenameUserErrors({ renameUserError: "Please enter a team name." });
    } else if (selectedTeam === renameTeam) {
      setrenameUserErrors({
        renameUserError: "Updated team name already exist.",
      });
    } else if (
      allteam.some(
        (team) => team.trim().toLowerCase() === renameTeam.trim().toLowerCase()
      )
    ) {
      setrenameUserErrors({
        renameUserError: "Updated team name already exist.",
      });

    } else {
      const response = await axios.post(`${url}/admin/updateTeam`, {
        teamId: selectedTeam,
        newTeamName: renameTeam,
        type: 'renameTeam'
      }, config);
      dispatch(getTeamsofOtcAdmin())
      setOpenRenameModal(false);
    }
  };


  const handleRenameOpenModal = (teamName) => {
    setOpenRenameModal(true);
    setrenameUserErrors("")
    setSelectedTeam(teamName);
    // setShowOptions(null);
  };
  const handleRenameTeamName = (teamname) => {
    setRenameTeam(teamname);
  };

  //Add user
  const fetchAdminUsers = async () => {
    try {
      const response = await axios.get(`${url}/admin/otc_adminusers`, config);
      setUsers(response.data?.allUsers);
    } catch (error) {
      ;
    }
  };

  const teamCounts = {};
  Users?.forEach((user) => {
    const teamId = user?.team?._id;

    // Check if the teamId already exists in the userTeamCounts object
    if (!teamCounts[teamId]) {
      teamCounts[teamId] = 0; // If not, initialize the count to 0
    }

    teamCounts[teamId]++; // Increment the count for the team
  });

  const handleUserCheckbox = (user) => {
    const isSelected = selectedUsers.some(
      (selectedUser) => selectedUser._id === user._id
    );

    if (isSelected) {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter(
          (selectedUser) => selectedUser._id !== user._id
        )
      );
    } else {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
    }
  };

  const handleAddUserTeam = async () => {
    if (selectedUsers?.length <= 0) {
      setAddUserErrors({ addUserError: "Please select team member." });
    } else {

      const response = await axios.post(`${url}/admin/user-team`, {
        team: selectedTeam,
        selectedUsers: selectedUsers,
        type: 'Add'
      }, config);
      fetchAdminUsers();
      setOpenAddTeamModal(false);
    }
  };

  const filteredRemainingUsers =
    remainingUsers &&
    remainingUsers?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });

  const handleAddUserOpenModal = (teamId) => {
    setAddUserErrors({ addUserError: "" });
    setSelectedTeam(teamId);
    setSelectedUsers([]);
    setOpenAddTeamModal(true);
    const usersInSelectedTeam = Users?.filter((user) => !user?.team);
    setUsersInSelectedTeam(usersInSelectedTeam);
    const allUsersInCompany = Users?.map((user) => user);
    const remainingUsers = allUsersInCompany?.filter((user) =>
      usersInSelectedTeam?.includes(user)
    );
    setRemainingUsers(remainingUsers);
  };

  //remove user
  const filteredUsersInSelectedTeam =
    usersInSelectedTeam &&
    usersInSelectedTeam?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });

  const handleRemoveUserModal = (teamId) => {
    setremoveUserErrors({ removeUserError: "" });
    setSelectedTeam(teamId);
    setSelectedUsers([]);
    setOpenEditTeamModal(true);
    const usersInSelectedTeam = Users?.filter(
      (user) => user?.team?._id === teamId
    );
    setUsersInSelectedTeam(usersInSelectedTeam);
    const allUsersInCompany = Users?.map((user) => user);
    const remainingUsers = allUsersInCompany.filter((user) =>
      usersInSelectedTeam?.includes(user)
    );
    setUsersInSelectedTeam(remainingUsers);
  };

  const handleRemoveUserFromTeam = async () => {
    if (selectedUsers?.length <= 0) {
      setremoveUserErrors({ removeUserError: "Please select team member." });
    } else {
      const response = await axios.post(`${url}/admin/user-team`, {
        selectedUsers: selectedUsers,
        type: 'remove'
      }, config);
      setOpenEditTeamModal(false);
      fetchAdminUsers();
    }
  };

  //delete team
  const handleDeleteTeam = async (teamId) => {
    try {
      const response = await axios.post(`${url}/admin/updateTeam`, {
        teamId,
        type: 'delete'
      }, config);
      if (response.status === 200) {

        dispatch(getTeamsofOtcAdmin())
        fetchAdminUsers();
      }
    } catch (error) {
      ;
    }
  };

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        showOptions &&
        !e.target.classList.contains("more-options") &&
        !e.target.classList.contains("row-2-2") &&
        !e.target.classList.contains("more-icon")
      ) {
        setShowOptions(null);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showOptions]);

  return (
    <>
      <div
        className="main-container-teamOverview"
        style={{ paddingBottom: "1px" }}
      >
        <div className="main-inner-container">
          <div className="text_select">
            <span className="teams">Teams</span>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="four-card-conatiner">
              {teams && teams?.length > 0 ? (
                teams.map((team, i) => (
                  <div
                    className="first-card commonCard"
                    style={{ position: "relative" }}
                  >
                    <div className="first-card-inner">
                      <div className="title">
                        <span>Name:</span>{" "}
                      </div>
                      <span className="team-name">
                        {team.team_name?.charAt(0).toUpperCase() +
                          team.team_name?.slice(1)}
                      </span>
                      <div class="grid-container">
                        <div class="row-1">
                          <div
                            class="column row-1-1"
                          >
                            <span className="icon1-card count">
                              {teamCounts[team._id] || 0}
                            </span>
                            <span className="text-card">View users</span>
                          </div>
                          <div
                            class="column row-1-2"
                            onClick={() => {
                              handleAddUserOpenModal(team._id);
                            }}
                          >
                            <span>
                              <img
                                className="icon-card"
                                src={addUserIcon}
                                alt=""
                              />{" "}
                            </span>{" "}
                            <span className="text-card">Add users</span>
                          </div>
                          <div
                            onClick={() => handleRemoveUserModal(team._id)}
                            class="column row-1-3"
                          >
                            <span>
                              <img
                                className="icon-card"
                                src={removeUserIcon}
                                alt=""
                              />
                            </span>{" "}
                            <span className="text-card">Remove users</span>
                          </div>
                        </div>
                        <div class="row-2">
                          <div class="column row-2-1">
                            <span>
                              <img src={viewTemplateIcon} alt="" />
                            </span>{" "}
                            <span className="text-card">
                              View assigned template
                            </span>
                          </div>
                          <div
                            class="column row-2-2"
                            onClick={() => handleMoreOption(team.team_name)}
                          >
                            <span>
                              <img
                                className="more-icon"
                                src={moreIcon}
                                alt=""
                              />
                            </span>
                            <span className="text-card more-icon">More</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showOptions === team.team_name && (
                      <div className="more-options">
                        <div
                          className="rename-delete-option"
                          onClick={() => {
                            handleRenameOpenModal(team._id);
                            handleRenameTeamName(team.team_name);
                          }}
                        >
                          Rename team
                        </div>
                        <div
                          className="rename-delete-option"
                          onClick={() => {
                            handleDeleteTeam(team._id);
                          }}
                        >
                          Delete team
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="no-teams-message">No teams available.</div>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal
        open={openAddTeamModal}
        onClose={() => {
          setOpenAddTeamModal(false);
          setSelectedTeam("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleOfTeamOverview}>
          <div className="Modal-section">
            <div className="header">
              <div className="main_header">
                Add users to{" "}
                {renameTeam?.charAt(0).toUpperCase() + renameTeam?.slice(1)}{" "}
                team
              </div>

              <div className="sub_header">
                Select the users you’d like to add to this team
              </div>
            </div>

            <div className="onetap_conn_users_tab_content_addTeam">
              <div className="onetap_conn_filter_search">
                <input
                  type="text"
                  placeholder="Search user"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="onetap_conn_filter_svg"
                >
                  <path
                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="addUserText">Add users</div>
              {remainingUsers.length == 0 ? (
                <div>No user available</div>
              ) : (
                <>
                  <div className="mappingdiv">
                    {filteredRemainingUsers &&
                      filteredRemainingUsers?.map((user, index) => (
                        <div
                          style={{ width: "99%" }}
                          key={index}
                          className={`select-card-users ${selectedUsers?.some((item) => item._id === user._id)
                              ? "selected"
                              : ""
                            }`}
                          onClick={() => {
                            handleUserCheckbox(user);
                            setAddUserErrors({ addUserError: "" });
                          }}
                        >
                          <div className="info-page">
                            <div className="header-avatar-container">
                              {user?.avatar ? (
                                <Avatar
                                  style={{ width: "48px", height: "48px" }}
                                  src={`${url}/adminprofile/img/${user?.avatar}`}
                                />
                              ) : (
                                <div
                                  className="header-avatar-initials-overlay"
                                  style={{ width: "48px", height: "48px" }}
                                >
                                  {`${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`}
                                </div>
                              )}
                            </div>
                            <div className="select-card-users-info">
                              <p className="p1">{`${user?.firstName} ${user?.lastName}`}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}

              {addUserErrors.addUserError && (
                <div style={{ color: "red" }}>{addUserErrors.addUserError}</div>
              )}
            </div>
            <div className="Modal-btn-group" style={{ marginTop: "0px" }}>
              <button
                className="Modal-btn first-modal-btn"
                onClick={() => {
                  handleAddUserTeam(selectedUsers._id);
                }}
              >
                Add to team
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={() => {
                  setOpenAddTeamModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEditTeamModal}
        onClose={() => {
          setOpenEditTeamModal(false);
          setSelectedTeam("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleOfTeamOverview}>
          <div className="Modal-section">
            <div className="header">
              <div className="main_header">
                Remove users from{" "}
                {renameTeam?.charAt(0).toUpperCase() + renameTeam?.slice(1)}{" "}
                team
              </div>

              <div className="sub_header">
                Select the users you’d like to remove from this team
              </div>
            </div>

            <div className="onetap_conn_users_tab_content_addTeam">
              <div className="onetap_conn_filter_search">
                <input
                  type="text"
                  placeholder="Search user"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ marginBottom: "10px" }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  className="onetap_conn_filter_svg"
                >
                  <path
                    d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                    fill="#000000"
                  />
                </svg>
              </div>
              <div className="addUserText">Remove users</div>
              <div className="mappingdiv">
                {filteredUsersInSelectedTeam &&
                  filteredUsersInSelectedTeam.length > 0 ? (
                  filteredUsersInSelectedTeam.map((user, index) => {
                    return (
                      <div
                        key={index}
                        style={{ width: "99%" }}
                        className={`select-card-users ${selectedUsers.some((item) => item._id === user._id)
                            ? "selected"
                            : ""
                          }`}
                        onClick={() => {
                          handleUserCheckbox(user);
                          setremoveUserErrors({ removeUserError: "" });
                        }}
                      >
                        <div className="info-page">
                          <div className="header-avatar-container">
                            {user?.avatar ? (
                              <Avatar
                                style={{ width: "48px", height: "48px" }}
                                src={`${url}/adminprofile/img/${user?.avatar}`}
                              />
                            ) : (
                              <div
                                className="header-avatar-initials-overlay"
                                style={{ width: "48px", height: "48px" }}
                              >
                                {`${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}`}
                              </div>
                            )}
                          </div>

                          <div className="select-card-users-info">
                            <p className="p1">{`${user.firstName} ${user.lastName}`}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>No users found</div>
                )}
              </div>
              {removeUserErrors.removeUserError && (
                <div style={{ color: "red" }}>
                  {removeUserErrors.removeUserError}
                </div>
              )}
            </div>
            <div className="Modal-btn-group" style={{ marginTop: "0px" }}>
              <button
                className="Modal-btn first-modal-btn"
                onClick={() => {
                  handleRemoveUserFromTeam();
                }}
              >
                Remove from team
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={() => setOpenEditTeamModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openRenameModal}
        onClose={() => setOpenRenameModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={renameModalStyle}>
          <div className="Modal-section">
            <h2 className="header">
              Rename Team{" "}
            </h2>
            <span className="Team-Name-Text">Team Name</span>
            <br />
            <div>
              <input
                type="text"
                name="teamame"
                className="input-field"
                value={renameTeam}
                onChange={(e) => {
                  setRenameTeam(e.target.value);
                  setrenameUserErrors({ renameUserError: "" });
                }}
              />
              {renameUserErrors.renameUserError && (
                <div style={{ color: "red" }}>
                  {renameUserErrors.renameUserError}
                </div>
              )}
            </div>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn"
                onClick={() => {
                  handleRenameTeam();
                }}
              >
                Rename Team
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={() => setOpenRenameModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default OTC_adminTeams;
