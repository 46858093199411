import React, { useEffect, useState } from "react";
import '../../components/Customers/SmartAccessories/SmartAccessories.scss';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Select from 'react-select';
import Loader from '../../components/Layout/Loader/Loader';
import { useDispatch, useSelector } from "react-redux";
import { SingleTeamMemberDetails, getProducts, getTeamMember } from "../../actions/userAction/userAction";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import selectDropdownIcon from "../Otc_assets/OTC_ICONS/selectDropdownIcon.svg"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
};
const messageIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
>
    <path
        d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
        fill="#E65925"
    />
    <path
        d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
        stroke="white"
        stroke-width="6.64"
        stroke-linecap="round"
        class="checkmark-path"
    />
</svg>
const SmartAccessory = () => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "28.125rem",
        bgcolor: "background.paper",
        p: 4,
        outline: "none",
        borderRadius: ".625rem",
    };

    const url = process.env.REACT_APP_URL;
    const [loading, setLoading] = useState(true)
    const { users } = useSelector((state) => state.Users);
    const { products } = useSelector((state) => state.products);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [prefix, setPrefix] = useState('');
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const [count, setCount] = useState('');

    const [filtersApplied, setFiltersApplied] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedAccessory, setSelectedAccessory] = useState('');
    const [smartAccessories, setSmartAccessories] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [isGenerateClicked, setIsGenerateClicked] = useState(false);
    const [selectAccessoryValidationError, setselectAccessoryValidationError] = useState("");
    const [uniqueIdError, setUniqueIdError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedPages, setDisplayedPages] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleClearFilters = () => {
        setSelectedProduct('');
        setSelectedStatus('');
        setSelectedUser('');
        setSearchTerm('');
        setFiltersApplied(false); // Reset filters and hide "Clear filters" div
    };
    const [openModal, setOpenModal] = React.useState(false)
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setPrefix('');
        setCount('');
        setSelectedAccessory('');
    };
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredRows.length / itemsPerPage)

    useEffect(() => {
        if (!users) {
            dispatch(getTeamMember());
        }
    }, [dispatch, users])
    useEffect(() => {
        if (!user) {
            dispatch(SingleTeamMemberDetails());
        }
    }, [user, dispatch]);
    useEffect(() => {
        if (!products) {
            dispatch(getProducts())
        }
    }, [products])

    const productOptions = products
        ? products
            .filter(
                (item) =>
                    item?.status === 'Published' &&
                    item?.visibility === 'Public' &&
                    (
                        (item?.hasVariations === false && (item?.prefix !== undefined && item?.prefix !== null && item?.prefix !== '')) ||
                        (item?.hasVariations === true &&
                            item?.variations &&
                            Array.isArray(item.variations) &&
                            item.variations.some(variation => variation?.prefix !== undefined && variation?.prefix !== null && variation?.prefix !== '')
                        )
                    )
            )
            .map((item) => {
                const productLabel = `${item?.name.charAt(0).toUpperCase()}${item?.name.slice(1)}`;

                const options = [
                    ...(item?.hasVariations &&
                        item?.variations &&
                        Array.isArray(item.variations) &&
                        item.variations.some(variation => variation?.prefix !== undefined && variation?.prefix !== null && variation?.prefix !== '')
                        ? item.variations
                            .filter((variation) => variation?.prefix !== undefined && variation?.prefix !== null && variation?.prefix !== '')
                            .map((variation) => ({
                                value: { product: item?._id, variationId: variation?._id },
                                label: `${productLabel} - ${variation?.Type}`,
                            }))
                        : [{ value: { product: item?._id, variationId: null }, label: productLabel }]
                    )
                ];

                return options;
            })
            .flat()
        : [];
    const uniqueProductNames = [...new Set(smartAccessories && smartAccessories?.map(product => product?.productName))];
    const uniqueStatusNames = [...new Set(smartAccessories && smartAccessories?.map(product => product?.status))];
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFiltersApplied(true);
    };
    const handleProductChange = (e) => {
        setSelectedProduct(e.target.value);
        if (e.target.value !== "") {
            setFiltersApplied(true); // Filters applied, show "Clear filters" div
        } else if (selectedProduct === "" && selectedStatus === "" && selectedUser === "") {
            setFiltersApplied(false);
        }
    }
    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
        if (e.target.value !== "") {
            setFiltersApplied(true); // Filters applied, show "Clear filters" div
        } else if (selectedProduct === "" && selectedStatus === "" && selectedUser === "") {
            setFiltersApplied(false);
        }
    }
    const getUserAssignSmartAccessoriesForCompany = async () => {
        const { data } = await axios.get(
            `${url}/admin/getAllUserAssignSmartAccessories`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );
        setLoading(false)
        setSmartAccessories(data.AllSmartAccessories)
        setCompanyName(data.companyName)
    }
    useEffect(() => {
        getUserAssignSmartAccessoriesForCompany()
    }, [])
   
    const handleGenerate = async () => {
        if (!selectedAccessory) {
            setselectAccessoryValidationError("Please select a smart accessory before continuing.");
            return;
        }
        setselectAccessoryValidationError("");
        setIsGenerateClicked(true)
        const matchedProduct = products?.find((item) => item?._id === selectedAccessory?.value?.product)
        const matchedVariation = matchedProduct?.variations?.find((item) => item?._id === selectedAccessory?.value?.variationId)
        const response = await axios.post(
            `${url}/admin/generateSmartAccessoryIds`,
            {
                prefix,
                count: count || 1,
                year,
                productId: matchedProduct?._id,
                variationId: matchedVariation?._id || null,
                productName: matchedProduct?.name,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            }
        );


        if (response.data.success) {
            getUserAssignSmartAccessoriesForCompany()
            setUniqueIdError('')
            handleCloseModal()
            setPrefix('');
            setCount('');
            setSelectedAccessory('');
            setIsGenerateClicked(false)
            setApiSuccess(true)
            setSuccessMessage('Ids generated Successfully.')
            setTimeout(() => {
                setApiSuccess(false)
            }, 5000);
        }
    }
    const handleAccessoryOnChange = (selectedOption) => {
        setSelectedAccessory(selectedOption); setselectAccessoryValidationError('');

        const matchedProduct = products?.find((item) => item?._id === selectedOption?.value?.product)

        const matchedVariation = matchedProduct?.variations?.find((item) => item?._id === selectedOption?.value?.variationId)

        if (matchedProduct?.prefix) setPrefix(matchedProduct?.prefix)
        if (matchedVariation?.prefix) setPrefix(matchedVariation?.prefix)
    }

    useEffect(() => {
        // Filter the rows based on searchQuery, selectedIndustry, selectedCountry, etc.
        const filteredData = smartAccessories?.filter((e) => (
            (!selectedProduct || e.productName === selectedProduct) &&
            (!selectedStatus || e.status === selectedStatus) &&
            (!searchTerm ||
                e.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                e.uniqueId.toLowerCase().includes(searchTerm.toLowerCase())
            )
        ));

        setFilteredRows(filteredData);
        setCurrentPage(1);
    }, [searchTerm, selectedStatus, selectedProduct, selectedUser, smartAccessories]);


    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredRows.slice(startIndex, endIndex);

    useEffect(() => {
        if (totalPages <= 5) {
            // Display all pages if there are 5 or fewer
            setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
        } else {
            // Determine which pages to display when there are more than 5 pages
            if (currentPage <= 3) {
                setDisplayedPages([1, 2, 3, 4, 5, '. . .', totalPages]);
            } else if (currentPage >= totalPages - 2) {
                setDisplayedPages([1, '. . .', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
            } else {
                setDisplayedPages([1, '. . .', currentPage - 1, currentPage, currentPage + 1, '. . .', totalPages]);
            }
        }
    }, [currentPage, totalPages]);
    return (
        <>
            <div className="onetap_connect_const_smartaccessoriestab">
                <div className="onetap_conn_users_tab_content_OTC_Admin">

                    <div className="onetap_connct_filters_synr">
                        <div class="onetap_connect_search_box_synr">
                            <input
                                style={{ paddingRight: "50px" }}
                                className="onetap_connect_search_input_synr"
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <svg style={{ position: "absolute", top: "10px", right: "10px" }} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z" fill="#000000" />
                            </svg>
                        </div>

                        <div style={{ position: "relative" }} className="onetap_connect_select_option_synr">
                            <select
                                className="select-smart-acc pc-status-dropdown"
                                value={selectedProduct}
                                onChange={handleProductChange}
                            >
                                <option value="">Smart Accessories</option>
                                {uniqueProductNames.map((productName, index) => (
                                    <option key={index} value={productName}>
                                        {productName.charAt(0).toUpperCase() + productName.slice(1).toLowerCase()}
                                    </option>
                                ))}
                            </select>
                            <img className="order-downarrow-icon" src={selectDropdownIcon} alt="" />

                        </div>



                        <div style={{ position: "relative" }} className="onetap_connect_select_option_synr">
                            <select
                                className="select-smart-acc pc-status-dropdown"
                                value={selectedStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="">Status</option>
                                {uniqueStatusNames.map((statusName, index) => (
                                    <option key={index} value={statusName}>
                                        {statusName}
                                    </option>
                                ))}
                            </select>
                            <img className="order-downarrow-icon" src={selectDropdownIcon} alt="" />
                        </div>
                    </div>
                    <button className="clear-fillter" style={{ marginTop: "10px", display: (selectedProduct || selectedStatus || selectedUser || searchTerm) ? 'block' : 'none' }} onClick={handleClearFilters}>
                        Clear filters
                    </button>


                    <div className="main-smart-acc-section">
                        <div className="otc-smartAccessories-table">
                            <div className="add-new-btn-group" onClick={handleOpenModal}>
                                <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                                    <rect width="34" height="34" rx="9" fill="#E65925" />
                                    <path d="M25.4997 18.4138H18.4163V25.4972C18.4163 25.8729 18.2671 26.2332 18.0014 26.4989C17.7357 26.7646 17.3754 26.9138 16.9997 26.9138C16.624 26.9138 16.2636 26.7646 15.9979 26.4989C15.7323 26.2332 15.583 25.8729 15.583 25.4972V18.4138H8.49967C8.12395 18.4138 7.76362 18.2646 7.49794 17.9989C7.23226 17.7332 7.08301 17.3729 7.08301 16.9972C7.08301 16.6214 7.23226 16.2611 7.49794 15.9954C7.76362 15.7298 8.12395 15.5805 8.49967 15.5805H15.583V8.49717C15.583 8.12145 15.7323 7.76111 15.9979 7.49543C16.2636 7.22976 16.624 7.0805 16.9997 7.0805C17.3754 7.0805 17.7357 7.22976 18.0014 7.49543C18.2671 7.76111 18.4163 8.12145 18.4163 8.49717V15.5805H25.4997C25.8754 15.5805 26.2357 15.7298 26.5014 15.9954C26.7671 16.2611 26.9163 16.6214 26.9163 16.9972C26.9163 17.3729 26.7671 17.7332 26.5014 17.9989C26.2357 18.2646 25.8754 18.4138 25.4997 18.4138Z" fill="white" />
                                </svg>
                                <p style={{ marginLeft: "12px", cursor: "pointer" }} className="addNew-text-smart header-text-smart-acc" >Add new</p>
                            </div>
                            <span className="header-text-smart-acc">Smart Accessory</span>
                            <span className="header-text-smart-acc">Status</span>

                        </div>
                        {
                            loading ? (<Loader />) :
                                <>
                                    <ToastContainer
                                        position="top-center"
                                        autoClose={2000}
                                        hideProgressBar
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss={false}
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />
                                    <div className="all-products-section">
                                        {
                                            paginatedData.length > 0 ? (
                                                paginatedData.map((e, index) => {
                                                    const productsWithVariations = products?.filter((product) => product.variations.length > 0);
                                                    const matchedProduct = productsWithVariations?.find((product) => product._id === e.productId._id);
                                                    const matchedVariation = matchedProduct?.variations?.find((variation) => variation._id === e.variationId);
                                                    return (
                                                        <div key={index} className="otc-smartAccessories-table product-cards">
                                                            <span className="add-new-btn-group">{e.uniqueId}</span>
                                                            <div className="add-new-btn-group">
                                                                <img style={{ width: "25%", backgroundColor: "white", borderRadius: "4px" }} className="img-smart-acc" src={e?.variationId ? `${url}/product/img/${matchedVariation?.image[0]?.url}` : `${url}/product/img/${e.productId?.image[0]?.url}`} alt="" />
                                                                <div className="product-details">
                                                                    <span className="product-name-text">{e.productId.name}</span>
                                                                    {matchedVariation ? <span className=""> style: <span className="style-text-smart"> {matchedVariation && <span>{matchedVariation.Type || '-'}</span>}</span> </span> : <span>style: <span className="style-text-smart">Regular</span></span>}
                                                                </div>
                                                            </div>
                                                            <span className="status-text-smart-acc" style={{ color: e.status === 'Activate' ? '#48AF25' : '#E62525' }}>
                                                                {e.status}
                                                            </span>
                                                        </div>)
                                                }))
                                                : (
                                                    <div style={{ backgroundColor: "white" }}>No Smart Accessories Available.</div>
                                                )}

                                    </div>

                                </>
                        }

                    </div>
                    {
                        totalPages > 1 && (
                            <div className="" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {
                                    currentPage !== 1 ?
                                        <ArrowBackIosNewIcon onClick={() => handlePageChange(currentPage - 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                        :
                                        <ArrowBackIosNewIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />

                                }
                                {displayedPages.map((page, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(page)}
                                        style={{
                                            // Define your active button styles here
                                            backgroundColor: currentPage === page ? '#e65925' : 'transparent',
                                            color: currentPage === page ? 'white' : 'black',
                                            border: 'none', // Add a border when the button is active
                                            borderRadius: '4px',
                                            padding: '4px 20px',
                                            margin: '2px',
                                        }}

                                    >
                                        {page}
                                    </button>)
                                )}
                                {
                                    currentPage !== totalPages ?
                                        <ArrowForwardIosIcon onClick={() => handlePageChange(currentPage + 1)} fontSize='normal' style={{ margin: '10px', cursor: 'pointer' }} />
                                        :
                                        <ArrowForwardIosIcon fontSize='normal' style={{ margin: '10px', opacity: '0.2' }} />
                                }

                            </div>
                        )
                    }
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="sec-Modal-section">
                        <div className="sec-modal-header-group">
                            <h2 className="sec-modal-header">
                                New smart accessories
                            </h2>
                        </div>
                        <span role='button' className="sec-modal-dropdwon">
                            <Select
                                styles={{
                                    control: () => ({
                                        margin: 0,
                                        padding: 0,
                                        border: "none",
                                        boxShadow: "none",
                                    }),
                                    input: (provided) => ({
                                        ...provided,
                                        margin: 0,
                                        padding: 0,
                                    }),
                                    indicatorsContainer: () => ({
                                        display: "none",
                                    }),
                                    option: (provided) => ({
                                        ...provided,
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: "97%",
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0 0"
                                    }),
                                }}
                                className="onetap_conn_general_information_input_syn   mb-3 form-select"
                                placeholder="Select Accessory"
                                value={selectedAccessory}
                                onChange={(selectedOption) => { handleAccessoryOnChange(selectedOption) }}
                                options={productOptions}
                            />
                        </span>

                        {selectAccessoryValidationError && (
                            <span className="error-message">{selectAccessoryValidationError}</span>
                        )}
                        <input
                            className="w-100 smartAccessories_addnew_modal_input mb-3 mt-3"
                            type="text"
                            placeholder="Prefix"
                            value={prefix + '-' + new Date().getFullYear().toString()}
                            readOnly
                        />
                        <input
                            className="w-100 smartAccessories_addnew_modal_input"
                            type="text"
                            placeholder="count"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                        />


                        <div className="video-Modal-btn-group" >
                            <button className="video-Modal-btn video-first-modal-btn" disabled={isGenerateClicked} style={{ backgroundColor: isGenerateClicked ? '#464d4abf' : '#e65925' }} onClick={handleGenerate}>{isGenerateClicked ? 'Generating...' : 'Generate'}</button>

                            <button className="video-Modal-btn video-sec-modal-btn" disabled={isGenerateClicked} onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={apiSuccess}
                onClose={() => setApiSuccess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={successModalStyle} style={{ textAlign: "center" }}>
                    <div className="Modal-section">
                        <div className="checkmark-container">
                            <div class="checkmark-container">
                                {messageIcon}
                            </div>
                        </div>
                        <span className="team_created_success">
                            {successMessage}
                        </span>
                    </div>
                    <button
                        className="Modal-btn sec-modal-btn"
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            width: "13%",
                        }}
                        onClick={() => setApiSuccess(false)}
                    >
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                            <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
                        </span>
                    </button>
                </Box>
            </Modal>
        </>
    )
}

export default SmartAccessory
