
import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import Otc_UserHeaderWithIcons from "./Otc_UserHeaderWithIcons";
import Otc_Userinfo from "./Otc_Userinfo";

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Users");
    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="otc_onetap_conn_user">
            <Otc_UserHeaderWithIcons onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "Users" && (
                <Otc_Userinfo
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
        </div>
    );
};


export default function Otc_User() {
    return (
        <>
            <Otc_Sidebar Component={Component} />
        </>
    )
}





