import React, { useState, useEffect } from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Loader from "../../components/Layout/Loader/Loader";
import { styled } from '@mui/system';
import axios from "axios";

const OTC_Adminusersinfo = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("Team");
  const [selectedUserRole, setSelectedUserRole] = useState("UserRole");
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [displayedPages, setDisplayedPages] = useState([]);
  const url = process.env.REACT_APP_URL;
  const [loader, setLoader] = useState(false);

  const fetchAdminUsers = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${url}/admin/otc_adminusers`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      setData(response.data?.allUsers);
      setOriginalData(response.data?.allUsers);
      setLoader(false);
    } catch (error) {
      setLoader(true);
    }
  };
  useEffect(() => {
    fetchAdminUsers();
  }, []);
  // all drop-down filters here
  const getUniqueTeam = () => {
    const UniqueTeam = Array.from(
      new Set(data?.map((row) => row?.team ? row.team.team_name : 'Not Assign'))
    );
    return UniqueTeam?.filter(
      (team, index, self) => self.indexOf(team) === index
    );
  };

  const getUniqueUserRole = () => {
    const UniqueUserRole = Array.from(
      new Set(data?.map((row) => row.userRole))
    );
    return UniqueUserRole?.filter(
      (userRole, index, self) => self.indexOf(userRole) === index
    );
  };

  const getUniqueStatus = () => {
    const UniqueStatus = Array.from(new Set(data?.map((row) => row?.status)));
    return UniqueStatus?.filter(
      (status, index, self) => self.indexOf(status) === index
    );
  };

  //Serach Filter
  useEffect(() => {
    applyFilters();
  }, [
    searchTerm,
    selectedTeam,
    selectedUserRole,
    selectedStatus,
    originalData,
  ]);

  const applyFilters = () => {
    const filteredData = originalData?.filter(
      (item) =>
        (item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.lastName.toLowerCase().includes(searchTerm.toLowerCase())) &&
        // (selectedTeam !== "Team" ? item.team === selectedTeam : true) &&
        (selectedTeam !== "Team" ? (item.team ? item.team.team_name === selectedTeam : selectedTeam === 'Not Assign') : true)&&

        (selectedUserRole !== "UserRole"
          ? item.userRole === selectedUserRole
          : true) &&
        (selectedStatus !== "Status" ? item.status === selectedStatus : true)
    );

    setFilteredData(filteredData);
    const paginatedData = filteredData?.slice(
      (currentPage - 1) * perPage,
      currentPage * perPage
    );
    setData(paginatedData || filteredData);
  };

  //filter remove data will return
  const isFilterApplied =
    selectedTeam !== "Team" ||
    selectedUserRole !== "UserRole" ||
    selectedStatus !== "Status";

  //clear filter button
  const clearFilters = () => {
    setSelectedTeam("Team");
    setSelectedUserRole("UserRole");
    setSelectedStatus("Status");
    setCurrentPage(1);
    setFilteredData(originalData);
    const paginatedData = originalData?.slice(0, perPage);
    setData(paginatedData);
  };

  //Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData?.length / perPage);

  useEffect(() => {
    if (totalPages <= 5) {
      // Display all pages if there are 5 or fewer
      setDisplayedPages(Array.from({ length: totalPages }, (_, i) => i + 1));
    } else {
      // Determine which pages to display when there are more than 5 pages
      if (currentPage <= 3) {
        setDisplayedPages([1, 2, 3, 4, 5, ". . .", totalPages]);
      } else if (currentPage >= totalPages - 2) {
        setDisplayedPages([
          1,
          ". . .",
          totalPages - 4,
          totalPages - 3,
        totalPages - 2,
          totalPages - 1,
          totalPages,
        ]);
      } else {
        setDisplayedPages([
          1,
          ". . .",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          ". . .",
          totalPages,
        ]);
      }
    }
  }, [currentPage, totalPages]);

  // Format time as 'hh:mm am/pm'
  //if you want to use this format only Aug 3, 2023 then use this
  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  //Clear filter button style
  const filterbtnStyle = {
    marginTop: "5px",
    padding: "3px 15px",
    backgroundColor: "#e65925", // Change to your desired button color
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    display: isFilterApplied ? "block" : "none", // Show the button conditionally
    marginLeft: "auto",
    marginBottom: "auto",
    height: "30px",
  };

  const CustomTableContainer = styled(TableContainer)({
    overflowX: 'unset !important',
    // Add other custom styles as needed
  });

  return (
    <>
      <CContainer className="onetap_conn_users_tab_content_OTC_Admin">
        <CRow>
          <CCol>
            <div className="onetap_conn_filter_search_OTC_Admin">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ fontSize: "13px" }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                className="onetap_conn_filter_svg_OTC_Admin"
              >
                <path
                  d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </CCol>

          <CCol>
            <div className="onetap_conn_filter_search_OTC_Admin">
              <select
                name="industry"
                id="industry"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                <option value="Team">Team</option>
                {getUniqueTeam()?.map((group) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={group}
                    value={group}
                  >
                    {group}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg_OTC_Admin"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </CCol>

          <CCol>
            <div className="onetap_conn_filter_search_OTC_Admin">
              <select
                name="industry"
                id="industry"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
                value={selectedUserRole}
                onChange={(e) => setSelectedUserRole(e.target.value)}
              >
                <option value="UserRole">User Role</option>
                {getUniqueUserRole()?.map((group) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={group}
                    value={group}
                  >
                    {group}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg_OTC_Admin"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </CCol>
          <CCol>
            <div className="onetap_conn_filter_search_OTC_Admin">
              <select
                name="industry"
                id="industry"
                style={{
                  appearance: "none",
                  outline: "none",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="Status">Status</option>
                {/* {getUniqueStatus()?.map((group) => (
                  <option
                    style={{ textTransform: "capitalize" }}
                    key={group}
                    value={group}
                  >
                    {group}
                  </option>
                ))} */}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                className="onetap_conn_filter_svg_OTC_Admin"
              >
                <path
                  d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                  fill="#000000"
                />
              </svg>
            </div>
          </CCol>
        </CRow>
        {isFilterApplied && (
          <button style={filterbtnStyle} onClick={clearFilters}>
            Clear Filters
          </button>
        )}

        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="mt-3">
              <CustomTableContainer
                style={{ borderRadius: "0px", boxShadow: "none" }}
              >
                <Table
                  aria-label="collapsible table"
                  style={{  }}
                >
                  <TableHead sx={{ backgroundColor: "white !important" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingLeft: "0px",
                          width: "30%",
                          color: "#333",
                          fontSize: "24px",
                          fontWeight: 600,
                          borderBottom: "0px",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderBottom: "0px", fontWeight: "600" }}
                      >
                        Team
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderBottom: "0px", fontWeight: "600" }}
                      >
                        User Role
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderBottom: "0px", fontWeight: "600" }}
                      >
                        Created Date
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderBottom: "0px", fontWeight: "600" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      data?.map((res) => {
                        return (
                          <>
                            <TableRow
                              sx={{
                                borderBottom: "unset",
                                backgroundColor: "#F2F2F2 !important",
                              }}
                              className="p-5"
                            >
                              <TableCell className="onetap_conn_user_table_column1_OTC_Admin">
                                <div className="profile-info_OTC_Admin">
                                <div className="user-info-avatar-container_OTC_Admin">
                              {res?.avatar === "" ? <div className="otc-header-avatar-container ">

                                <div className="header-avatar-initials-overlay" style={{ width: "48px", height: "48px" }} >
                                  {(res?.firstName?.charAt(0).toUpperCase() + res?.lastName?.charAt(0).toUpperCase())}
                                </div>
                              </div>:
                                <img
                                  src={`${url}/adminprofile/img/${res?.avatar}`}
                                  alt="Profile"
                                  style={{
                                    width: "54px",
                                    height: "54px",
                                    borderRadius: "100%",
                                  }}
                                />
                             }
                               </div>

                                  <div className="details_OTC_Admin">
                                    <p
                                      style={{
                                        textTransform: "capitalize",
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/admin/otc-user-edit/${res._id}`,
                                        )
                                      }
                                    >
                                      {res.firstName} {res.lastName}
                                    </p>

                                    <p
                                      className="column1_text_OTC_Admin"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {res.jobTitles[0]}
                                    </p>
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  borderBottom: "none",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                              >
                                {!res?.team?.team_name
                                  ? "Not Assign"
                                  : res?.team?.team_name}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  borderBottom: "none",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {res.userRole}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  borderBottom: "none",
                                  textTransform: "capitalize",
                                  fontSize: "14px",
                                }}
                              >
                                {res.createdAt
                                  ? formatDate(new Date(res.createdAt))
                                  : "Invalid Date"}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  borderBottom: "none",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                              >
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ paddingBottom: "10px" }}>
                              <TableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "none",
                                }}
                                className="userinfo_pagetabl_OTC_Admin"
                              ></TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "none",
                                }}
                                className="userinfo_pagetabl_OTC_Admin"
                              ></TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "none",
                                }}
                                className="userinfo_pagetabl_OTC_Admin"
                              ></TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "none",
                                }}
                                className="userinfo_pagetabl_OTC_Admin"
                              ></TableCell>
                              <TableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "none",
                                }}
                                className="userinfo_pagetabl_OTC_Admin"
                              ></TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </div>
          </>
        )}
      </CContainer>

      {totalPages > 1 && (
        <div
          className=""
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {currentPage !== 1 ? (
            <ArrowBackIosNewIcon
              onClick={() => handlePageChange(currentPage - 1)}
              fontSize="normal"
              style={{ margin: "10px", cursor: "pointer" }}
            />
          ) : (
            <ArrowBackIosNewIcon
              fontSize="normal"
              style={{ margin: "10px", opacity: "0.2" }}
            />
          )}
          {displayedPages.map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              style={{
                // Define your active button styles here
                backgroundColor:
                  currentPage === page ? "#e65925" : "transparent",
                color: currentPage === page ? "white" : "black",
                border: "none", // Add a border when the button is active
                borderRadius: "4px",
                padding: "4px 20px",
                margin: "2px",
              }}
            >
              {page}
            </button>
          ))}
          {currentPage !== totalPages ? (
            <ArrowForwardIosIcon
              onClick={() => handlePageChange(currentPage + 1)}
              fontSize="normal"
              style={{ margin: "10px", cursor: "pointer" }}
            />
          ) : (
            <ArrowForwardIosIcon
              fontSize="normal"
              style={{ margin: "10px", opacity: "0.2" }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default OTC_Adminusersinfo;
