import React from 'react'


const CardProfileEditCardTab = () => {
  return (
    <div className="coming_main_h">
      <div className="coming_h">
        <h1>Coming Soon...!</h1>
      </div>
    </div>
  )
}

export default CardProfileEditCardTab