import DashboardIcon from '../assets/icons/DashboardIcon.svg'
import AnalyticsIcon from '../assets/icons/AnalyticsIcon.svg'
import upgradePlanIocn from "../assets/icons/upgradeicon.svg"
import TeamMembersIcon from '../assets/icons/TeamMembersIcon.svg'
import AcademyIcon from '../assets/icons/AcademyIcon.svg'
import SmartAccessoriesIcon from '../assets/icons/SmartAccessoriesIcon.svg'
import ShopIcon from '../assets/icons/ShopIcon.svg'
import CompanyProfileIcon from '../assets/icons/CompanyProfileIcon.svg'
import TemplatesIcon from '../assets/icons/TemplatesIcon.svg'
import SettingsIcon from '../assets/icons/SettingsIcon.svg'
import TutorialIcon from '../assets/icons/TutorialIcon.svg'
import BillingIcon from '../assets/icons/BillingIcon.svg'
import { useSelector } from "react-redux";

const CompanyRoutes = () => {
    const { user } = useSelector((state) => state.user);
    const { userInfo } = useSelector((state) => state.userInformation);
    const userRole = user && user.role;
    const userPlan = userInfo && userInfo?.subscription_details?.plan;
    const acc_status = user && user.Account_status;

    const baseRoutes = [
        {
            path: ['/dashboard'],
            element: "",
            title: 'Dashboard',
            icon: DashboardIcon,
            group: "",
            ...(userRole === 'superadmin' && (userPlan === 'Team' || userPlan === 'Professional' || userPlan === 'Free') && acc_status === 'is_Deactivated'
                ? { disabled: true }
                : {}
            ),
        },
    ];

    let userSpecificRoutes = [];

    if (userRole === 'superadmin' && (userPlan === 'Team' || userPlan === 'Professional' || userPlan === 'Free') && acc_status === 'is_Deactivated') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "", disabled: true },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, group: "", disabled: true },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, group: "", disabled: true },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "", disabled: true },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "", disabled: true },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "", disabled: true },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, group: "settings", disabled: true },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, group: "settings", disabled: true },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, group: "settings", disabled: true },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    } else if (userRole === 'superadmin' && userPlan === 'Professional') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "" },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, upgradePlanIocn, group: "", disabled: true },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, group: "" },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, upgradePlanIocn, group: "settings", disabled: true },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, upgradePlanIocn, group: "settings", disabled: true },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, group: "settings" },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    } else if (userRole === 'superadmin' && userPlan === 'Team') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "" },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, group: "" },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, group: "" },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, group: "settings" },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, group: "settings" },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, group: "settings" },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    }
    else if (userRole === 'administrator' && userPlan === 'Team') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "" },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, group: "" },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, group: "" },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, group: "settings" },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, group: "settings" },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, group: "settings" },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    } else if (userRole === 'manager' && userPlan === 'Team') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "" },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, group: "" },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, group: "" },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, group: "settings", disabled: true },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, group: "settings", disabled: true },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, group: "settings", disabled: true },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing", disabled: true },
        ];
    } else if (userRole === 'teammember') {
        userSpecificRoutes = [
            { path: ['/profile', '/profile/information/:id'], element: "", title: 'Profile', icon: CompanyProfileIcon, group: "" },
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, group: "" },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    } else if (userRole === 'superadmin' && userPlan === 'Free') {
        userSpecificRoutes = [
            { path: ['/analytics'], element: "", title: 'Analytics', icon: AnalyticsIcon, upgradePlanIocn, group: "", disabled: true },
            { path: ['/users', '/user/profile/:id', '/user/information/:id'], element: "", title: 'Users', icon: TeamMembersIcon, upgradePlanIocn, group: "", disabled: true },
            { path: ['/academy'], element: "", title: 'Academy', icon: AcademyIcon, upgradePlanIocn, group: "", disabled: true },
            { path: ['/smart-accessories'], element: "", title: 'Smart Accessories', icon: SmartAccessoriesIcon, group: "" },
            { path: ['/shop'], element: "", title: 'Shop Smart Accessories', icon: ShopIcon, group: "" },
            { path: ['/tutorials'], element: "", title: 'Tutorials', icon: TutorialIcon, group: "" },
            { path: ['/company/information'], element: "", title: 'Company Information', icon: CompanyProfileIcon, upgradePlanIocn, group: "settings", disabled: true },
            { path: ['/templates'], element: "", title: 'Templates', icon: TemplatesIcon, upgradePlanIocn, group: "settings", disabled: true },
            { path: ['/settings'], element: "", title: 'Settings', icon: SettingsIcon, upgradePlanIocn, group: "settings", disabled: true },
            { path: ['/billing'], element: "", title: 'Account & Billing', icon: BillingIcon, group: "billing" },
        ];
    }
    const allRoutes = [...baseRoutes, ...userSpecificRoutes];
    return allRoutes;
}

export default CompanyRoutes
