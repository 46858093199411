import React, { useState } from "react";
import "../CompanyProfile.css";
import CompanyProfileHeadersWithIcons from "./CompanyProfileHeadersWithIcons";
import CompanyProfileGeneralInfoTab from "./companyProfileGeneralInfoTab";
import CompanyProfileAboutInfoTab from "./CompanyProfileAboutInfoTab";
import CompanyProfileBrandingInfoTab from "./CompanyProfileBrandingInfoTab";
import Sidebar from "../../../../components/Layout/Sidebar/Sidebar";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };

  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };

  return (
    <div className="">
      <div className="onetap_company_profile_syn">
        <CompanyProfileHeadersWithIcons
          onChange={getTabValue}
          tab={companyProfileTab}
        />

        <div className="">
          {companyProfileTab === "General" && (
            <CompanyProfileGeneralInfoTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
          {companyProfileTab === "About" && (
            <CompanyProfileAboutInfoTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
          {companyProfileTab === "Branding" && (
            <CompanyProfileBrandingInfoTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Sidebar Component={Component} />
    </>
  );
};

export default Dashboard;
