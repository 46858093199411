import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const OTC_adminusers_profile_headers = (props) => {
  const [cardInfoTab, setCardInfoTab] = useState("OTC Users");
  const { tab } = props;
const navigate = useNavigate();
  useEffect(() => {
    setCardInfoTab(tab);
  }, [tab]);

  const handleClickOfTab = (value) => {
    setCardInfoTab(value);
    props.onChange(value);
  };

  return (
    <>
      <div className="onetap_connect_mycard_header_OTC_Admin  justify-content-between">
        <div className="d-flex">
          <div
            onClick={()=> navigate('/admin/otc-users')}
            className={
              cardInfoTab === "OTC Users"
                ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
            }
          >
            <div
              style={{
                backgroundColor: cardInfoTab === "OTC Users" ? "#E65925" : "",
                width: "34px",
                height: "32px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0C10.1935 0 11.3381 0.474106 12.182 1.31802C13.0259 2.16193 13.5 3.30653 13.5 4.5C13.5 5.69347 13.0259 6.83807 12.182 7.68198C11.3381 8.52589 10.1935 9 9 9C7.80653 9 6.66193 8.52589 5.81802 7.68198C4.97411 6.83807 4.5 5.69347 4.5 4.5C4.5 3.30653 4.97411 2.16193 5.81802 1.31802C6.66193 0.474106 7.80653 0 9 0ZM9 11.25C13.9725 11.25 18 13.2638 18 15.75V18H0V15.75C0 13.2638 4.0275 11.25 9 11.25Z"
                  fill={cardInfoTab === "OTC Users" ? "#ffff" : "#000000"}
                />
              </svg>
            </div>

            <p
              className={
                cardInfoTab === "OTC Users"
                  ? "onetap_connect_mycard_active_header_OTC_Admin"
                  : "onetap_connect_mycard_headers_OTC_Admin"
              }
            >
              General
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTC_adminusers_profile_headers;