import React from "react";

const Companyusersettting = () => {
  return (
    <div className="coming_main_h">
      <div className="coming_h">
        <h1>Coming Soon...!</h1>
      </div>
    </div>
  );
};

export default Companyusersettting;