import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getTeamsofOtcAdmin } from "../Redux/Otc_UserActions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const OTC_Adminusersheaders = (props) => {
  const url = process.env.REACT_APP_URL;
  const [cardInfoTab, setCardInfoTab] = useState("OTC Users");
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [CreateTeam, setCreateTeam] = useState("");
  const [teamDataErrors, setTeamDataErrors] = useState({});
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const handleClose = () => setOpenTeamModal(false);
  const handleOpen = () => {
    setOpenTeamModal(true);
    setCreateTeam("");
    setTeamDataErrors({ teamName: "" });
  };
  const dispatch = useDispatch();
  const { otcadminteams } = useSelector((state) => state.otcadminteams);
  const { tab } = props;
  useEffect(() => {
    setCardInfoTab(tab);
  }, [tab]);
  const handleClickOfTab = (value) => {
    setCardInfoTab(value);
    props.onChange(value);
  };
 
  const [teams, setTeams] = useState([]);
 
  useEffect(() => {
    if(!otcadminteams){
      dispatch (getTeamsofOtcAdmin())
    }
    else{setTeams(otcadminteams)}
  }, [otcadminteams]);
  const allTeamName = teams?.map((e) => e.team_name)
  const handleAddTeam = async () => {
    try {
      if (teams?.length >= 6) {
        setTeamDataErrors({ teamName: "You can create only 6 teams." });
      } else if (!CreateTeam) {
        setTeamDataErrors({ teamName: "Please enter a team name." });
      } else {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        };
        const normalizedCreateTeam = CreateTeam.trim().toLowerCase();
        const normalizedAllTeamName = allTeamName.map(team => team.toLowerCase());
        if (normalizedAllTeamName.includes(normalizedCreateTeam)) {
          setTeamDataErrors({ teamName: "Team already exists." });
        } else {
         setIsApiCallInProgress(true);
          const createTeamResponse = await axios.post(
            `${url}/admin/create-team`,
            { team_name: CreateTeam }, config
          );
          if (createTeamResponse) {
            dispatch (getTeamsofOtcAdmin())
            setTeamDataErrors({ error: "" });
            handleClose();
          }
        }
      }
    } catch (error) {
      ;
    } finally {
      setIsApiCallInProgress(false);
    }
  };
  return (
    <>
      <div className="onetap_connect_mycard_header_OTC_Admin  justify-content-between">
        <div className="d-flex">
          <div
            onClick={() => handleClickOfTab("OTC Users")}
            className={
              cardInfoTab === "OTC Users"
                ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
            }
          >
            <div
              style={{
                backgroundColor: cardInfoTab === "OTC Users" ? "#E65925" : "",
                width: "34px",
                height: "32px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M10 4V0H0V18H20V4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM18 16H10V14H12V12H10V10H12V8H10V6H18V16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z"
                  fill={cardInfoTab === "OTC Users" ? "#ffff" : "#000000"}
                />
              </svg>
            </div>
            <p
              className={
                cardInfoTab === "OTC Users"
                  ? "onetap_connect_mycard_active_header_OTC_Admin"
                  : "onetap_connect_mycard_headers_OTC_Admin"
              }
            >
              OTC Team Members
            </p>
          </div>
          <div
            onClick={() => handleClickOfTab("Teams")}
            className={
              cardInfoTab === "Teams"
                ? "onetap_connect_mycard_header_icon_and_header_active_OTC_Admin"
                : "onetap_connect_mycard_header_icon_and_header_deactive_OTC_Admin"
            }
          >
            <div
              style={{
                backgroundColor: cardInfoTab === "Teams" ? "#E65925" : "",
                width: "34px",
                height: "32px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginright: "0px",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM6 15.5C5.33696 15.5 4.70107 15.2366 4.23223 14.7678C3.76339 14.2989 3.5 13.663 3.5 13C3.5 12.337 3.76339 11.7011 4.23223 11.2322C4.70107 10.7634 5.33696 10.5 6 10.5C6.66304 10.5 7.29893 10.7634 7.76777 11.2322C8.23661 11.7011 8.5 12.337 8.5 13C8.5 13.663 8.23661 14.2989 7.76777 14.7678C7.29893 15.2366 6.66304 15.5 6 15.5ZM7.5 6C7.5 5.33696 7.76339 4.70107 8.23223 4.23223C8.70107 3.76339 9.33696 3.5 10 3.5C10.663 3.5 11.2989 3.76339 11.7678 4.23223C12.2366 4.70107 12.5 5.33696 12.5 6C12.5 6.66304 12.2366 7.29893 11.7678 7.76777C11.2989 8.23661 10.663 8.5 10 8.5C9.33696 8.5 8.70107 8.23661 8.23223 7.76777C7.76339 7.29893 7.5 6.66304 7.5 6ZM14 15.5C13.337 15.5 12.7011 15.2366 12.2322 14.7678C11.7634 14.2989 11.5 13.663 11.5 13C11.5 12.337 11.7634 11.7011 12.2322 11.2322C12.7011 10.7634 13.337 10.5 14 10.5C14.663 10.5 15.2989 10.7634 15.7678 11.2322C16.2366 11.7011 16.5 12.337 16.5 13C16.5 13.663 16.2366 14.2989 15.7678 14.7678C15.2989 15.2366 14.663 15.5 14 15.5Z"
                  fill={cardInfoTab === "Teams" ? "#ffff" : "#000000"}
                />
              </svg>
            </div>
            <p
              className={
                cardInfoTab === "Teams"
                  ? "onetap_connect_mycard_active_header_OTC_Admin"
                  : "onetap_connect_mycard_headers_OTC_Admin"
              }
            >
              Teams
            </p>
          </div>
        </div>
        {tab === "OTC Users" ? (
          <div className="d-flex align-items-center justify-content-end">
            <Link
              to="/admin/add-otcuser"
              style={{ textDecoration: "none", color: "#2C2C2C" }}
            >
              <div className="onetap_conn_user_header_right_OTC_Admin">
                <div className={`onetap_conn_user_header_addusers_OTC_Admin`}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                    >
                      <rect width="34" height="34" rx="9" fill="#E65925" />
                      <path
                        d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>Add team member</div>
                </div>
              </div>
            </Link>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-end">
            <div className="onetap_conn_user_header_right_OTC_Admin">
              <div className={`onetap_conn_user_header_addusers_OTC_Admin`} onClick={handleOpen}>
                <div  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    onClick={handleOpen}
                  >
                    <rect width="34" height="34" rx="9" fill="#E65925" />
                    <path
                      d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div >Add new team</div>
                </div>
                <Modal
                  open={openTeamModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="Modal-section">
                      <h2 className="header">
                        Create Team{" "}
                        <CloseIcon className="icon" onClick={handleClose} />
                      </h2>
                      <span className="Team-Name-Text">Team name</span>
                      <br />
                      <div>
                        <input
                          type="text"
                          name="teamName"
                          className="input-field"
                          placeholder="Ex: Sales"
                          value={CreateTeam}
                          onChange={(e) => {
                            setCreateTeam(e.target.value);
                          }}
                        />
                        {teamDataErrors.teamName && (
                          <div style={{ color: "red" }}>
                            {teamDataErrors.teamName}
                          </div>
                        )}
                      </div>
                      <div className="Modal-btn-group">
                        <button 
                          className="Modal-btn first-modal-btn"
                          onClick={() => {
                            handleAddTeam();
                          }}
                          disabled={isApiCallInProgress}
                        >
                          Create Team
                        </button>
                        <button
                          className="Modal-btn sec-modal-btn"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
          </div>
        )}
      </div>
    </>
  );
};
export default OTC_Adminusersheaders;
