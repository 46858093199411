import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./TnC.css";
import Header2 from "../../Layout/Header/Header2"

const Termsofuse = () => {
  const { hash } = useLocation();
  const refundRef = useRef(null);
  useEffect(() => {
    // Function to scroll to the element with the specified ID
    const scrollToRefund = () => {
      if (refundRef.current) {
        refundRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Check if the URL has a hash and it matches the desired ID
    if (hash && hash === "#refundpolicy") {
      // Scroll to the element with the specified ID after a short delay
      setTimeout(scrollToRefund, 100);
    }
  }, [hash]);

  return (
    <>
      <div className="tncmaindiv" style={{ backgroundColor: "#f2f2f2" }}>
        <Header2 />

        <div
          className="container mt-4"
          style={{ backgroundColor: "white", maxWidth: "1140px" }}
        >
          <div className="" style={{ padding: "50px 50px 30px 50px" }}>
            <h1 className="tnch1">Terms of use</h1>
            <h4 className="tnch4">Terms and Conditions</h4>

            <div className="mt-4">
              <h2 className="tnch2">
                Agreement between Client and OneTap Connect, LLC
              </h2>
              <p className="tncp">
                This Web Development Agreement (this “Agreement”) is made
                effective as of the purchase date of Client’s OneTapConnect
                Application, by and between Client (the “Client”), and One Tap
                Connect, LLC (the “Web Developer”) In this Agreement, the Client
                shall be referred to as “Client”, and the Web Developer shall be
                referred to as “One Tap Connect”.
              </p>
              <p className="tncp">
                The OneTapConnect.com website (the “Site”) is comprised of
                various web pages operated by One Tap Connect, LLC
                (“OneTapConnect “). OneTapConnect is offered to Client (the
                client) conditioned on Client’s acceptance without modification
                of the terms, conditions, and notices contained herein (the
                “Terms”). Client’s use of OneTapConnect constitutes Client
                agreement to all such Terms. Please read these terms carefully
                and keep a copy of them for your reference.
              </p>
              <p className="tncp">
                WHEREAS, OneTapConnect possesses technical expertise in the
                field of computer programming and, in particular, the creation
                and development of website technology; and
              </p>
              <p className="tncp">
                WHEREAS, Client desires to engage OneTapConnect, and
                OneTapConnect accepts the engagement, to design a Mobile Website
                (the “Application”) in accordance with the terms and conditions
                set forth in this Agreement.
              </p>
              <p className="tncp">
                NOW, THEREFORE, in consideration of the mutual covenants and
                agreements set forth herein, Client and OneTapConnect agree as
                follows:
              </p>
            </div>
            <div>
              <h2 className="tnch2">Description of Services</h2>
              <p className="tncp">
                OneTapConnect will provide the following services connected with
                the development of the Digital Card/Mobile Website
                (collectively, the “Services”):
              </p>
              <p className="tncp">
                Create, design, and deliver a custom digital card/ mobile
                website with all clients contact information including but not
                limited to phone numbers, email, social media links, client bio,
                calendar link, video, and written testimonials, photos, logo,
                and other marketing assets provided by the client. The Client
                will also receive a physical OneTapConnect NFC Smart Card to
                transfer their information to another mobile phone. The
                OneTapConnect Smart Card works on any Apple iPhone 10 plus or
                above and newer Andriod mobile device. Included are web hosting
                services for one year that begin on the delivery date of the
                Client’s digital OneTapConnect Card.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Payment for Services</h2>
              <p className="tncp">
                In consideration of the Services to be performed by
                OneTapConnect, the Client has submitted payment to OneTapConnect
                for the Services due as follows:
              </p>
              <p className="tncp">
                The Client’s payment includes a fully designed OneTapConnect
                Application with one year of hosting services included.
              </p>
              <p className="tncp">
                The Client understands and agrees that web hosting services are
                required after the completion of the Client’s first year of
                service. Thereafter, the client agrees to pay One Tap Connect’s
                hosting fee of $99 annually. This fee will also include
                maintenance and potential upgrades to the OneTapConnect
                Application. This fee will be automatically charged to the
                Client for renewal of Services unless the Client cancels
                Services 30 days prior to the renewal date.
              </p>
              <p className="tncp">
                Any additional services not specified in the Description of
                Services, above, will be charged to the Client on an hourly rate
                basis at OneTapConnect’s standard rate.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Definitions</h2>
              <p className="tncp">
                "User" Anyone engaged in delivering or receiving the Application
                through their mobile device or via the world wide web.
              </p>
              <p className="tncp">
                "Application" –OneTapConnect- a digital business card/mobile
                website, that can be transferred to the Client’s mobile device,
                and will allow the business card to be automatically dispatched
                through, NFC transfer, QR Code Transfer, or a SMS/MMS to the
                phone device of the caller to the User via the Users’ mobile
                device and cellular services which the User is subscribed to.
              </p>
              <p className="tncp">
                "OneTap Smart Card" – an NFC business card programmed by
                OneTapConnect for the Client or user to transfer contact
                information to an iPhone 10 Plus or above or a newer Andriod
                mobile phone device.
              </p>
              <p className="tncp">
                "QR Code" a readable code consisting of an array of black and
                white squares, typically used for storing URLs or other
                information for reading by the camera on a smartphone. ( used to
                transfer User contact info on older iPhones and Android mobile
                devices.)
              </p>
              <p className="tncp">
                "Delivery" – means the transfer of the User’s contact
                information through the Application.
              </p>
              <p className="tncp">
                "Design Materials" means the materials transferred by the Client
                to OneTapConnect for the formation of the digital business
                card/mobile website, including personal details, contact
                information, videos, and images.
              </p>
              <p className="tncp">
                "The Order" a paid transaction by the Client to OneTapConnect
                for Services.
              </p>
              <p className="tncp">"Device" – the User’s mobile telephone.</p>
            </div>
            <div>
              <h2 className="tnch2">The Order</h2>
              <p className="tncp">
                The Client agrees to cooperate with OneTapConnect and submit all
                Design Materials in a timely fashion. Client grants
                OneTapConnect permission to obtain any Client Design Materials
                from Client’s Websites, Social Media or other public information
                outlets that are relevant to creating and designing the Client’s
                Application.
              </p>
              <p className="tncp">
                It is the responsibility of the Client to own at their expense a
                Device operating on one of the latest Android operating system
                versions, and to connect the Device to a proper cellular network
                service including SMS/MMS and data services. All liabilities and
                expenses involved in purchasing the device and receiving the
                telecommunication services are the sole responsibility of
                Client.
              </p>
              <p className="tncp">
                The Client is aware that other mobile phone applications and
                changes in the Device settings may impair the Delivery.
              </p>
              <p className="tncp">
                OneTapConnect cannot guarantee the Delivery nor the receipt of
                the OneTap Smart Card transmission by the recipient, and
                OneTapConnect does not have any control over the Clients’
                Device, the mobile services, nor the recipient’s/Users’ device.
              </p>
              <p className="tncp">
                The initial Order paid by the Clients is a one-time fee for the
                creation and design of the Application. It is the Client’s
                responsibility to renew the service term prior to its
                termination, by making payments to OneTapConnect.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Delivery & Acceptance</h2>
              <p className="tncp">
                OneTapConnect will deliver the Application to the Client
                Digitally via text to the mobile number provided by Client. The
                Client will have 3 business days to request any design changes.
                If no design changes are requested, the Application will be
                accepted by the Client as complete.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Updates</h2>
              <p className="tncp">
                Updating Client Applications is part of OneTapConnect’s service.
                However, if over gratuitous updates start to take place,
                OneTapConnect at its discretion may charge the Client a fee for
                requested updates. The Client will pay for the updates to the
                Application as specified in the Order or the latest
                OneTapConnect price list, whichever is the latter.
              </p>
              <p className="tncp">
                Updates should be submitted by email to{" "}
                <EmailLink email="admin@onetapconnect.com" />
              </p>
              <p className="tncp">
                An Update is defined as deleting or adding a photo, video or
                logo. Changes to text, About profile, About Company, or the
                addition or removal of a link or links that are congruent with
                the company name and brand.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Re-Brand</h2>
              <p className="tncp">
                A Re-Brand would constitute a Client requesting the following to
                their OneTapConnect Application. A new design, a new name or
                company name, logo, profile copy, colors, new layout, a change
                of videos, pictures, assets or a combination thereof. In this
                case the Client may be required to order a new OneTapConnect
                Application or pay OneTapConnect a Re-Brand fee.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Mobile Website Design Materials</h2>
              <p className="tncp" style={{ fontWeight: "600" }}>
                The Client warrants that all the Design Materials, including web
                links, are the Client’s real contact information, and that they
                and their Delivery through the Application and their receipt by
                the recipient do not violate any law, do not create
                misrepresentation, do not misleading, and they are not:
                threatening, defamatory or libelous, obscene, connected to any
                illegal activity, or may constitute grounds for criminal or
                civil proceedings, or constitute something which the Client is
                not permitted to send, and that they comply with all laws
                pertaining to the display of minors images, as far as minors
                images appear in the Application.
              </p>
              <p className="tncp">
                The Client undertakes: that he has all the permissions,
                licenses, consents in accordance with intellectual property laws
                and any other law relevant to use in the Design Materials
                through the Application after taking into account all its
                performances and characteristics, and that he has paid all the
                payments required to make this use, or that he will make the
                payments on the designated dates agreed with the rights holders
                or anyone on their behalf.
              </p>
              <p className="tncp">
                The Client declares that the contact details within the Design
                Materials are true with regard to the Device holder. The Client
                undertakes that the Design Materials are in a good visual level
                and in the formats supported by the Application.
              </p>
              <p className="tncp">
                The Client must make available to OneTapConnect all the Design
                Materials at least 3 working days before the Delivery begins.
                OneTapConnect may refrain from executing the Delivery or require
                changes in the Application, if in its opinion the Design
                Materials are incompatible with OneTapConnect’s systems, or do
                not comply with its policy or are of a poor standard or are
                incompatible with any other provision of this Order.
              </p>
              <p className="tncp">
                The use of the Design Materials by OneTapConnect does not create
                any representation by OneTapConnect regarding the legality of
                the Design Materials, their use for the creation of the card,
                and its Delivery and does not relieve the Client from being
                solely responsible for any non-compliance with the law
                pertaining the Design Materials and their use.
              </p>
              <p className="tncp">
                If the Client fails to supply all proper Design Materials in a
                timely manner to OneTapConnect, it will not delay the beginning
                of the service term and will not constitute a ground for payment
                reduction or refund.
              </p>
              <p className="tncp">
                The Client will not make any changes in the Design Materials
                after they have been approved by the OneTapConnect, without the
                prior approval of OneTapConnect.
              </p>
              <p className="tncp">
                OneTapConnect is not responsible for the compatibility of the
                Application with any mobile screen type or operating system or
                SMS application.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Timing and Deliverables</h2>
              <p className="tncp">
                The estimated term for the design of the card is 5 business days
                from the date the Client has delivered the Design Materials to
                OneTapConnect unless OneTapConnect has postponed the formation
                date by notice to the Client.
              </p>
              <p className="tncp">
                OneTapConnect shall not be liable for any delay in the
                performance of his duties as a result of a reason beyond his
                control.
              </p>
              <p className="tncp">
                The service term shall start on the date the Application was
                designed, formed, and delivered to the Client via text or email
                to start using the Services digitally. The OneTap Smart Card
                will be sent to Client via US Mail or UPS to the shipping
                address provided by Client. Receipt of the OneTap Smart Card
                does not constitute the start of Client’s service term.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Privacy</h2>
              <p className="tncp">
                Your use of Application is subject to OneTapConnect’s Privacy
                Policy. Please review our Privacy Policy, which also governs the
                Site and informs users of our data collection practices.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Electronic Communications</h2>
              <p className="tncp">
                Visiting OneTapConnect.com or sending emails to
                OneTapConnect.com constitutes electronic communications. You
                consent to receive electronic communications and you agree that
                all agreements, notices, disclosures, and other communications
                that we provide to you electronically, via email, and on the
                Site, satisfy any legal requirement that such communications be
                in writing. Client can send all requests to &nbsp;
                <EmailLink email="admin@onetapconnect.com" />
              </p>
            </div>
            <div>
              <h2 className="tnch2">Children Under Eighteen</h2>
              <p className="tncp" >
                OneTapConnect does not knowingly collect, either online or
                offline, personal information from persons under the age of
                Eighteen. If you are under 18, you may use onetapconnect.com
                only with the permission of a parent or guardian.
              </p>
            </div>
            <div>
              <h2 className="tnch2" id='refundpolicy' ref={refundRef}>Cancellation/Refund Policy</h2>
              <p className="tncp">
                Upon the start, formation, and creation of services purchased,
                all sales are final, and no refund will be issued except where
                prohibited by law.
              </p>
            </div>
            <div>
              <h2 className="tnch2">
                Links to Third Party Sites/Third Party Services
              </h2>
              <p className="tncp">
                OneTapConnect may contain links to other websites (“Linked
                Sites”). The Linked Sites are not under the control of
                OneTapConnect and OneTapConnect is not responsible for the
                content of OneTapConnect or any Linked Site, including without
                limitation any link contained in a Linked Site, or any changes
                or updates to a Linked Site. OneTapConnect is providing these
                links to the Client only as a convenience, and the inclusion of
                any link does not imply endorsement by OneTapConnect of the site
                or any association with its operators.
              </p>
              <p className="tncp">
                Certain services made available via OneTapConnect are delivered
                by third-party sites and organizations. By using any product,
                service, or functionality originating from the onetapconnect.com
                domain, you hereby acknowledge and consent that OneTapConnect
                may share such information and data with any third party with
                whom OneTapConnect has a contractual relationship to provide the
                requested product, service, or functionality on behalf of
                OneTapConnect users and Clients.
              </p>
            </div>
            <div>
              <h2 className="tnch2">
                No Unlawful or Prohibited Use/Intellectual Property
              </h2>
              <p className="tncp">
                You are granted a non-exclusive, non-transferable, revocable
                license to access and use OneTapConnect strictly in accordance
                with these terms of use. As a condition of your use of the Site,
                you warrant to OneTapConnect that you will not use the Site for
                any purpose that is unlawful or prohibited by these Terms. You
                may not use the Site in any manner which could damage, disable,
                overburden, or impair the Site or interfere with any other
                party’s use and enjoyment of the Site. You may not obtain or
                attempt to obtain any materials or information through any means
                not intentionally made available or provided for through the
                Site.
              </p>
              <p className="tncp">
                All content included as part of the Service, such as text,
                graphics, logos, images, as well as the compilation thereof, and
                any software used on the Site, is the property of OneTapConnect
                or its suppliers and protected by copyright and other laws that
                protect intellectual property and proprietary rights. You agree
                to observe and abide by all copyright and other proprietary
                notices, legends, or other restrictions contained in any such
                content and will not make any changes thereto.
              </p>
              <p className="tncp">
                You will not modify, publish, transmit, reverse engineer,
                participate in the transfer or sale, create derivative works, or
                in any way exploit any of the content, in whole or in part,
                found on the Site. OneTapConnect.com content is not for resale.
                Your use of the Site does not entitle you to make any
                unauthorized use of any protected content, and in particular,
                you will not delete or alter any proprietary rights or
                attribution notices in any content. You will use protected
                content solely for your personal use and will make no other use
                of the content without the express written permission of
                OneTapConnect.com and the copyright owner. You agree that you do
                not acquire any ownership rights in any protected content. We do
                not grant you any licenses, express or implied, to the
                intellectual property of OneTapConnect or our licensors except
                as expressly authorized by these Terms.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Use of Communication Services</h2>
              <p className="tncp">
                The OneTapConnect may contain bulletin board services, chat
                areas, news groups, forums, communities, personal web pages,
                calendars, and/or other message or communication facilities
                designed to enable you to communicate with the public at large
                or with a group (collectively, “Communication Services”). You
                agree to use the Communication Services only to post, send and
                receive messages and material that are proper and related to the
                particular Communication Service.
              </p>
              <p className="tncp">
                By way of example, and not as a limitation, you agree that when
                using a Communication Service, you will not: defame, abuse,
                harass, stalk, threaten or otherwise violate the legal rights
                (such as rights of privacy and publicity) of others; publish,
                post, upload, distribute or disseminate any inappropriate,
                profane, defamatory, infringing, obscene, indecent or unlawful
                topic, name, material or information; upload files that contain
                software or other material protected by intellectual property
                laws (or by rights of privacy of publicity) unless you own or
                control the rights thereto or have received all necessary
                consents; upload files that contain viruses, corrupted files, or
                any other similar software or programs that may damage the
                operation of another’s computer; advertise or offer to sell or
                buy any goods or services for any business purpose, unless such
                Communication Service specifically allows such messages; conduct
                or forward surveys, contests, pyramid schemes or chain letters;
                download any file posted by another user of a Communication
                Service that you know, or reasonably should know, cannot be
                legally distributed in such manner; falsify or delete any author
                attributions, legal or other proper notices or proprietary
                designations or labels of the origin or source of software or
                other material contained in a file that is uploaded; restrict or
                inhibit any other user from using and enjoying the Communication
                Services; violate any code of conduct or other guidelines which
                may be applicable for any particular Communication Service;
                harvest or otherwise collect information about others, including
                e-mail addresses, without their consent; violate any applicable
                laws or regulations.
              </p>
              <p className="tncp">
                OneTapConnect has no obligation to monitor the Communication
                Services. However, OneTapConnect reserves the right to review
                materials posted to a Communication Service and to remove any
                materials in its sole discretion. OneTapConnect reserves the
                right to terminate your access to any or all of the
                Communication Services at any time without notice for any reason
                whatsoever.
              </p>
              <p className="tncp">
                OneTapConnect reserves the right at all times to disclose any
                information as necessary to satisfy any applicable law,
                regulation, legal process or governmental request, or to edit,
                refuse to post or to remove any information or materials, in
                whole or in part, in OneTapConnect’s sole discretion.
              </p>
              <p className="tncp">
                Always use caution when giving out any personally identifying
                information about yourself or your children in any Communication
                Service. OneTapConnect does not control or endorse the content,
                messages or information found in any Communication Service and,
                therefore, OneTapConnect specifically disclaims any liability
                with regard to the Communication Services and any act resulting
                from Client participation in any Communication Service. Managers
                and hosts are not authorized OneTapConnect spokespersons, and
                their views do not necessarily reflect those of OneTapConnect.
              </p>
              <p className="tncp">
                Materials uploaded to a Communication Service may be subject to
                posted limitations on usage, reproduction and/or dissemination.
                The Client is responsible for adhering to such limitations if
                you upload the materials.
              </p>
              <p className="tncp">
                Materials provided to OneTapConnect or posted on any
                OneTapConnect Web Pages
              </p>
              <p className="tncp">
                OneTapConnect does not claim ownership of the materials Client
                provides to OneTapConnect (including feedback and suggestions)
                or post, upload, input or submit to any OneTapConnect Site or
                our associated services (collectively “Submissions”). However,
                by posting, uploading, inputting, providing or submitting your
                Submission you are granting OneTapConnect our affiliated
                companies, and necessary sublicensees permission to use Client
                Submission in connection with the operation of Client’s Internet
                businesses including, without limitation, the rights to: copy,
                distribute, transmit, publicly display, publicly perform,
                reproduce, edit, translate and reformat your Submission; and to
                publish Client’s name in connection with Client’s Submission.
              </p>
              <p className="tncp">
                No compensation will be paid with respect to the use of your
                Submission, as provided herein. OneTapConnect is under no
                obligation to post or use any Submission you may provide and may
                remove any Submission at any time in OneTapConnect’s sole
                discretion.
              </p>
              <p className="tncp">
                By posting, uploading, inputting, providing or submitting your
                Submission you warrant and represent that you own or otherwise
                control all of the rights to your Submission as described in
                this section including, without limitation, all the rights
                necessary for you to provide, post, upload, input or submit the
                Submissions.
              </p>
            </div>
            <div>
              <h2 className="tnch2">SMS Communication</h2>
              <p className="tncp">
                This SMS message program is a service of One Tap Connect, LLC.
                By providing your cell phone number, you agree to receive
                recurring automated promotional and personalized marketing text
                messages (e.g., SMS/MMS cart reminders, sale notices, etc) from
                One Tap Connect, LLC. These messages include text messages that
                may be sent using an automatic telephone dialing system, to the
                mobile telephone number you provided when signing up or any
                other number that you designate. You give One Tap Connect, LLC
                permission to send text messages to the enrolled cell phone
                number through your wireless phone carrier, unless and until you
                end permission per these Terms & Conditions. Consent to receive
                automated marketing text messages is not a condition of any
                purchase. Message & data rates may apply.
              </p>
              <p className="tncp">
                Message frequency may vary. One Tap Connect, LLC reserves the
                right to alter the frequency of messages sent at any time, so as
                to increase or decrease the total number of sent messages. One
                Tap Connect, LLC also reserves the right to change the short
                code or phone number from which messages are sent and we will
                notify you if we do so.
              </p>
              <p className="tncp">
                Not all mobile devices or handsets may be supported and our
                messages may not be deliverable in all areas. One Tap Connect,
                LLC, its service providers and the mobile carriers supported by
                the program are not liable for delayed or undelivered messages.
              </p>
              <p className="tncp">
                By enrolling in the One Tap Connect, LLC messaging program, you
                also agree to these messaging terms & conditions (“Messaging
                Terms”), our One Tap Connect, LLC Terms of Use and One Tap
                Connect, LLC Privacy Policy.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Cancellation</h2>
              <p className="tncp">
                Text the keyword STOP, STOPALL, END, CANCEL, UNSUBSCRIBE or QUIT
                to the telephone number, long code, or short code that sends you
                our initial confirmation message to cancel. After texting STOP,
                STOPALL, END, CANCEL, UNSUBSCRIBE or QUIT to the telephone
                number, long code, or short code that sends you our initial
                confirmation message you will receive one additional message
                confirming that your request has been processed. If you change
                your preferences, it may take up to 48 hours for it to take
                effect. You acknowledge that our text message platform may not
                recognize and respond to unsubscribe requests that do not
                include the STOP, STOPALL, END, CANCEL, UNSUBSCRIBE or QUIT
                keyword commands and agree that One Tap Connect, LLC and its
                service providers will have no liability for failing to honor
                such requests. If you unsubscribe from one of our text message
                programs, you may continue to receive text messages from One Tap
                Connect, LLC through any other programs you have joined until
                you separately unsubscribe from those programs.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Help or Support</h2>
              <p className="tncp">
                Text the keyword HELP to the telephone number, long code, or
                short code that sends you our initial confirmation message to
                receive a text with information on how to unsubscribe.
              </p>
            </div>
            <div>
              <h2 className="tnch2">No Warranty</h2>
              <p className="tncp">
                TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, YOU ACKNOWLEDGE
                AND AGREE THAT THE MESSAGING PROGRAM IS PROVIDED ON AN “AS IS”
                AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Limitation of Liability</h2>
              <p className="tncp">
                TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT
                IN NO EVENT SHALL EITHER OF One Tap Connect, LLC OR ANY PARTY
                ACTING ON BEHALF OF One Tap Connect, LLC BE LIABLE FOR: (A) ANY
                CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES
                OR COSTS IN AN AGGREGATE AMOUNT EXCEEDING THE GREATER OF THE
                AMOUNT YOU PAID TO One Tap Connect, LLC HEREUNDER OR $100.00; OR
                (B) ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL,
                PUNITIVE OR ANY OTHER DAMAGES. YOU AGREE EVEN IF One Tap
                Connect, LLC HAS BEEN TOLD OF POSSIBLE DAMAGE OR LOSS ARISING OR
                RESULTING FROM OR IN ANY WAY RELATING TO YOUR USE OF THE One Tap
                Connect, LLC MESSAGING PROGRAM. One Tap Connect, LLC AND ITS
                REPRESENTATIVES ARE NOT LIABLE FOR THE ACTS OR OMISSIONS OF
                THIRD PARTIES, INCLUDING BUT NOT LIMITED TO DELAYS OR
                NON-DELIVERY IN THE TRANSMISSION OF MESSAGES.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Indemnity</h2>
              <p className="tncp">
                To the maximum extent allowed by applicable law, you agree to
                indemnify, defend and hold harmless One Tap Connect, LLC, its
                directors, officers, employees, servants, agents,
                representatives, independent contractors and affiliates from and
                against any and all claims, damages, liabilities, actions,
                causes of action, costs, expenses, including reasonable
                attorneys’ fees, judgments or penalties of any kind or nature
                arising from or in relation to the these Messaging Terms or your
                receipt of text messages from One Tap Connect, LLC or its
                service providers.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Dispute Resolution</h2>
              <p className="tncp">
                General. Any dispute or claim arising out of or in any way
                related to these Messaging Terms or your receipt of text
                messages from One Tap Connect, LLC or its service providers
                whether based in contract, tort, statute, fraud,
                misrepresentation, or any other legal theory, and regardless of
                when a dispute or claim arises will be resolved by binding
                arbitration. YOU UNDERSTAND AND AGREE THAT, BY AGREEING TO THESE
                MESSAGING TERMS, YOU AND One Tap Connect, LLC ARE EACH WAIVING
                THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION
                AND THAT THESE MESSAGING TERMS SHALL BE SUBJECT TO AND GOVERNED
                BY ARBITRATION. <br /> Exceptions. Notwithstanding subsection
                (a) above, nothing in these Messaging Terms will be deemed to
                waive, preclude, or otherwise limit the right of you or One Tap
                Connect, LLC to: (i) bring an individual action in small claims
                court; (ii) pursue an enforcement action through the applicable
                federal, state, or local agency if that action is available;
                (iii) seek injunctive relief in aid of arbitration from a court
                of competent jurisdiction; or (iv) file suit in a court of law
                to address an intellectual property infringement claim. <br />
                Arbitrator. Any arbitration between you and One Tap Connect, LLC
                will be governed by the JAMS, under the Optional Expedited
                Arbitration Procedures then in effect for JAMS, except as
                provided herein. JAMS may be contacted at www.jamsadr.com. The
                arbitrator has exclusive authority to resolve any dispute
                relating to the interpretation, applicability, or enforceability
                of this binding arbitration agreement. <br /> No Class Actions.
                YOU AND One Tap Connect, LLC AGREE THAT EACH MAY BRING CLAIMS
                AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING. Further, unless both you and One Tap
                Connect, LLC agree otherwise in a signed writing, the arbitrator
                may not consolidate more than one person’s claims, and may not
                otherwise preside over any form of a representative or class
                proceeding. You agree that, by agreeing to these Messaging
                Terms, you and One Tap Connect, LLC are each waiving the right
                to a trial by jury or to participate in a class action,
                collective action, private attorney general action, or other
                representative proceeding of any kind.
                <br /> No Class Actions. YOU AND One Tap Connect, LLC AGREE THAT
                EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN AN INDIVIDUAL
                CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and
                One Tap Connect, LLC agree otherwise in a signed writing, the
                arbitrator may not consolidate more than one person’s claims,
                and may not otherwise preside over any form of a representative
                or class proceeding.
                <br />
                Modifications to this Arbitration Provision. Notwithstanding
                anything to the contrary in these Messaging Terms, if One Tap
                Connect, LLC makes any future change to this arbitration
                provision, you may reject the change by sending us written
                notice within 30 days of the change to One Tap Connect, LLC’s
                contact information provided in the “Contact Us” section below,
                in which case this arbitration provision, as in effect
                immediately prior to the changes you rejected, will continue to
                govern any disputes between you and One Tap Connect, LLC.
                <br />
                Enforceability. If any provision of these Messaging Terms is
                found to be unenforceable, the applicable provision shall be
                deemed stricken and the remainder of these Messaging Terms shall
                remain in full force and effect. Changes to the Messaging Terms
                <br />
                We reserve the right to change these Messaging Terms or cancel
                the messaging program at any time. By using and accepting
                messages from One Tap Connect, LLC after we make changes to the
                Messaging Terms, you are accepting the Messaging Terms with
                those changes. Please check these Messaging Terms regularly.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Limitation of Liability</h2>
              <p className="tncp">
                TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT
                IN NO EVENT SHALL EITHER OF One Tap Connect, LLC OR ANY PARTY
                ACTING ON BEHALF OF One Tap Connect, LLC BE LIABLE FOR: (A) ANY
                CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES
                OR COSTS IN AN AGGREGATE AMOUNT EXCEEDING THE GREATER OF THE
                AMOUNT YOU PAID TO One Tap Connect, LLC HEREUNDER OR $100.00; OR
                (B) ANY DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL,
                PUNITIVE OR ANY OTHER DAMAGES. YOU AGREE EVEN IF One Tap
                Connect, LLC HAS BEEN TOLD OF POSSIBLE DAMAGE OR LOSS ARISING OR
                RESULTING FROM OR IN ANY WAY RELATING TO YOUR USE OF THE One Tap
                Connect, LLC MESSAGING PROGRAM. One Tap Connect, LLC AND ITS
                REPRESENTATIVES ARE NOT LIABLE FOR THE ACTS OR OMISSIONS OF
                THIRD PARTIES, INCLUDING BUT NOT LIMITED TO DELAYS OR
                NON-DELIVERY IN THE TRANSMISSION OF MESSAGES.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Indemnity</h2>
              <p className="tncp">
                To the maximum extent allowed by applicable law, you agree to
                indemnify, defend and hold harmless One Tap Connect, LLC, its
                directors, officers, employees, servants, agents,
                representatives, independent contractors and affiliates from and
                against any and all claims, damages, liabilities, actions,
                causes of action, costs, expenses, including reasonable
                attorneys’ fees, judgments or penalties of any kind or nature
                arising from or in relation to the these Messaging Terms or your
                receipt of text messages from One Tap Connect, LLC or its
                service providers.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Entire Agreement/Severability</h2>
              <p className="tncp">
                These Messaging Terms, together with any amendments and any
                additional agreements you may enter into with us in connection
                herewith, will constitute the entire agreement between you and
                One Tap Connect, LLC concerning the Messaging Program.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Third Party Accounts</h2>
              <p className="tncp">
                Client will be able to connect their OneTapConnect account to
                third-party accounts. By connecting your OneTapConnect account
                to your third-party account, you acknowledge and agree that you
                are consenting to the continuous release of information about
                you to others (in accordance with your privacy settings on those
                third party sites). If you do not want information about you to
                be shared in this manner, do not use this feature.
              </p>
            </div>
            <div>
              <h2 className="tnch2">International Users</h2>
              <p className="tncp">
                The Service is controlled, operated, and administered by
                OneTapConnect from our offices within the USA. If you access the
                Service from a location outside the USA, you are responsible for
                compliance with all local laws. You agree that you will not use
                the OneTapConnect content accessed through onetapconnect.com in
                any country or in any manner prohibited by any applicable laws,
                restrictions, or regulations.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Indemnification</h2>
              <p className="tncp">
                Client agrees to indemnify, defend and hold harmless
                OneTapConnect, its officers, directors, employees, agents, and
                third parties, for any losses, costs, liabilities, and expenses
                (including reasonable attorney’s fees) relating to or arising
                out of your use of or inability to use the Site or services, any
                user postings made by you, your violation of any terms of this
                Agreement or your violation of any rights of a third party, or
                your violation of any applicable laws, rules or regulations.
                OneTapConnect reserves the right, at its own cost, to assume the
                exclusive defense and control of any matter otherwise subject to
                indemnification by Client in which event Client will fully
                cooperate with OneTapConnect in asserting any available
                defenses.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Arbitration</h2>
              <p className="tncp">
                In the event, the parties are not able to resolve any dispute
                between them arising out of or concerning these Terms and
                Conditions, or any provisions hereof, whether in contract, tort,
                or otherwise at law or in equity for damages or any other
                relief, then such dispute shall be resolved only by final and
                binding arbitration pursuant to the Federal Arbitration Act,
                conducted by a single neutral arbitrator and administered by the
                American Arbitration Association, or a similar arbitration
                service selected by the parties, in a location mutually agreed
                upon by the parties. The arbitrator’s award shall be final, and
                judgment may be entered upon it in any court having
                jurisdiction. In the event that any legal or equitable action,
                proceeding, or arbitration arises out of or concerns these Terms
                and Conditions, the prevailing party shall be entitled to
                recover its costs and reasonable attorney’s fees. The parties
                agree to arbitrate all disputes and claims in regard to these
                Terms and Conditions or any disputes arising as a result of
                these Terms and Conditions, whether directly or indirectly,
                including Tort claims that are a result of these Terms and
                Conditions. The parties agree that the Federal Arbitration Act
                governs the interpretation and enforcement of this provision.
                The entire dispute, including the scope and enforceability of
                this arbitration provision, shall be determined by the
                Arbitrator. This arbitration provision shall survive the
                termination of these Terms and Conditions.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Class Action Waiver</h2>
              <p className="tncp">
                Any arbitration under these Terms and Conditions will take place
                on an individual basis; class arbitrations and
                class/representative/collective actions are not permitted. THE
                PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER
                ONLY IN EACH’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both
                Client and OneTapConnect agree otherwise, the arbitrator may not
                consolidate more than one person’s claims and may not otherwise
                preside over any form of a representative or class proceeding.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Liability Disclaimer</h2>
              <p className="tncp">
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
                AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                INFORMATION HEREIN. ONE TAP CONNECT, LLC AND/OR ITS SUPPLIERS
                MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
              </p>
              <p className="tncp">
                ONE TAP CONNECT, LLC AND/OR ITS SUPPLIERS MAKE NO
                REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON
                THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
                SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT
                WARRANTY OR CONDITION OF ANY KIND. ONE TAP CONNECT, LLC AND/OR
                ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
                RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT.
              </p>
              <p className="tncp">
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                SHALL ONE TAP CONNECT, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR
                ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
                WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
                SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES
                AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE
                ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,
                TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF C4
                PROMOTIONS, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
                POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
                OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO
                YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR
                WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
                IS TO DISCONTINUE USING THE SITE.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Termination/Access Restriction </h2>
              <p className="tncp">
                OneTapConnect reserves the right, in its sole discretion, to
                terminate Client access to the Site and the related services or
                any portion thereof at any time, without notice. To the maximum
                extent permitted by law, this agreement is governed by the laws
                of the State of Nevada and you hereby consent to the exclusive
                jurisdiction and venue of courts in Nevada in all disputes
                arising out of or relating to the use of the Site. Use of the
                Site is unauthorized in any jurisdiction that does not give
                effect to all provisions of these Terms, including, without
                limitation, this section.
              </p>
              <p className="tncp">
                Client agrees that no joint venture, partnership, employment, or
                agency relationship exists between you and OneTapConnect as a
                result of this agreement or use of the Site. OneTapConnect’s
                performance of this agreement is subject to existing laws and
                legal process, and nothing contained in this agreement is in
                derogation of OneTapConnect’s right to comply with governmental,
                court and law enforcement requests or requirements relating to
                the Client’s use of the Site or information provided to or
                gathered by OneTapConnect with respect to such use. If any part
                of this agreement is determined to be invalid or unenforceable
                pursuant to applicable law including, but not limited to, the
                warranty disclaimers and liability limitations set forth above,
                then the invalid or unenforceable provision will be deemed
                superseded by a valid, enforceable provision that most closely
                matches the intent of the original provision and the remainder
                of the agreement shall continue in effect.
              </p>
              <p className="tncp">
                Unless otherwise specified herein, this agreement constitutes
                the entire agreement between the Client and OneTapConnect with
                respect to the Site and it supersedes all prior or
                contemporaneous communications and proposals, whether
                electronic, oral or written, between the user and OneTapConnect
                with respect to the Site. A printed version of this agreement
                and of any notice given in electronic form shall be admissible
                in judicial or administrative proceedings based upon or relating
                to this agreement to the same extent and subject to the same
                conditions as other business documents and records originally
                generated and maintained in printed form. It is the express wish
                to the parties that this agreement and all related documents be
                written in English.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Changes to Terms</h2>
              <p className="tncp">
                OneTapConnect reserves the right, in its sole discretion, to
                change the Terms under which OneTapConnect is offered. The most
                current version of the Terms will supersede all previous
                versions. OneTapConnect encourages Client to periodically review
                the Terms to stay informed of our updates.
              </p>
              <div>
                <h2 className="tnch2">Contact Us</h2>
                <p className="tncp">
                  OneTapConnect welcomes your questions or comments regarding
                  the Terms:
                </p>
                <p className="tncp">
                  Email Address: <EmailLink email="admin@onetapconnect.com" /><br />
                  Telephone number: <TelLink phoneNumber="+1 702-907-2502" /> <br />
                  Effective as of June 30, 2021 <br />
                  Updated January 1, 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Termsofuse;

const EmailLink = ({ email }) => {
  const handleClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <a
      href={`mailto:${email}`}
      onClick={handleClick}
      style={{ textDecoration: "none", color: "#E65925" }}
    >
      {email}
    </a>
  );
};


const TelLink = ({ phoneNumber }) => {
  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <a
      href={`tel:${phoneNumber}`}
      onClick={handleClick}
      style={{ textDecoration: "none", color: "#E65925" }}
    >
      {phoneNumber}
    </a>
  );
};
