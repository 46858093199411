import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllUsersOfCompany } from "../Redux/Otc_UserActions";
import Loader from "../../components/Layout/Loader/Loader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientUser from "./ClientUsers/ClientUsers";
import ClientCompanyInformation from "./ClientCompanyInformation/ClientCompanyInformation";

const ClientProfileCompanyInformation = () => {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getAllUsersOfCompany(id));
  }, [dispatch, id]);

  const { loading, companydata, userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  function capitalizeWords(str) {
    const words = str?.split(" ");
    const capitalizedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords?.join(" ");
  }
  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {" "}
          <div
            className="onetap_conn_pointofcontect"
            style={{ padding: "20px", paddingTop: "0px" }}
          >
            <div
              className="row bg-white"
              style={{ marginLeft: "0", borderRadius: "4px" }}
            >
              <Accordion
                style={{ boxShadow: "none", padding: "0px" }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  className=""
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ minHeight: "70px" }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography
                    className="onetap_connect_free_user_accordian_heading_OTC_Admin"
                    style={{ flexShrink: 0 }}
                  >
                    Point of Contact
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    borderTop: "1px solid #F2F2F2",
                    padding: "16px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <div style={{ display: "flex", gap: "40px" }}>
                    {companydata?.primary_billing?.map((billingUser, index) => (
                      <div
                        className="profile-info_OTC_Admin"
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="user-info-avatar-container_OTC_Admin">
                          {billingUser?.avatar !== "" ? (
                            <img
                              src={`${url}/profile/img/${billingUser?.avatar}`}
                              alt="Profile"
                              style={{
                                width: "54px",
                                height: "54px",
                                borderRadius: "100%",
                              }}
                            />
                          ) : (
                            <div
                              className="user-info-initials-overlay_OTC_Admin"
                              style={{
                                width: "54px",
                                height: "54px",
                                position: "relative",
                              }}
                            >
                              {billingUser.first_name.charAt(0).toUpperCase()}
                              {billingUser.last_name.charAt(0).toUpperCase()}
                            </div>
                          )}
                        </div>

                        <div className="details_OTC_Admin">
                          <p
                            style={{
                              textTransform: "capitalize",
                              fontSize: "16px",
                              fontWeight: "600",
                              margin: "0px",
                            }}
                          >
                            {capitalizeWords(billingUser.first_name)}{" "}
                            {capitalizeWords(billingUser.last_name)}
                          </p>
                          <p
                            className=""
                            style={{ fontSize: "13px", margin: "0px" }}
                          >
                            {capitalizeWords(billingUser.designation[0])}
                          </p>
                          <p
                            className="column1_text_OTC_Admin"
                            style={{ fontSize: "12px", margin: "0px" }}
                          >
                            {capitalizeWords(billingUser.role)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <ClientCompanyInformation />
        </>
      )}
    </>
  );
};

export default ClientProfileCompanyInformation;
