import React from 'react'
import Sidebar from "../../Layout/Sidebar/Sidebar";
import ClinetOrderSummaryTab from './ClinetOrderSummaryTab';


function ClientOrderSummary() {
  return (
    <Sidebar Component={ClinetOrderSummaryTab}/>
  )
}

export default ClientOrderSummary