import React, { useState } from "react";
import TemplatesHeaderWithIcons from "./TemplatesHeaderWithIcons";
import TemplatesTab from "./TemplatesTab";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";

const Component = () => {
    const [newstate, Setnewstate] = useState("Templates");
    const getTabValue = (tabVal) => {
        Setnewstate(tabVal);
    };
    const getValueOfSaveAndContinueTab = (value) => {
        Setnewstate(value);
    };
    return (
        <>
            <div className="onetap_conn_user">
                <TemplatesHeaderWithIcons onChange={getTabValue} tab={newstate} />
                {newstate === "Templates" && (<TemplatesTab onClickSave={getValueOfSaveAndContinueTab} />)}
            </div>
        </>
    );
};
const Templates = () => {
    return <Otc_Sidebar Component={Component} />;
};
export default Templates;
