import React, { useState, useEffect } from "react";
import "./planCard.css";
import { useNavigate } from "react-router-dom";
import check from "../../assets/icons/check.svg";
import { useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";

const Check = () => {
  return (
    <>
      <img style={{ marginBottom: "", marginRight: "10px" }} src={check} alt="" />
    </>
  );
};

const renameModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};

const PlanCard = (props) => {
  const {
    _id,
    planType,
    description,
    monthlyPrice_perUser,
    users,
    features,
    monthly_fee,
    PublicPlanName,
  } = props.props;

  const { userInfo } = useSelector((state) => state.userInformation)
  const { user } = useSelector((state) => state.user);

  const [btnText, setBtnText] = useState("");
  const [tag, setTag] = useState("");
  const [listHeading, setListHeading] = useState("");
  const [addUserCount, setAddUsercount] = useState(users);
  const [planUpgradeModal, setPlanUpgradeModal] = useState(false);
  const [popupId, setPopupId] = useState(null);
  const [total, setTotal] = useState("");


  useEffect(() => {
    setTotal((users * monthlyPrice_perUser) + ((addUserCount - users) * monthlyPrice_perUser))
  }, [monthlyPrice_perUser, addUserCount])

  const BillingCycle = userInfo?.subscription_details?.billing_cycle

  const Navigate = useNavigate();

  const handleClick = (id) => {
    setTimeout(() => {
      if (planType === "Enterprise") {
        return window.open(
          "https://onetapconnect.com/contact-sales/",
          "_blank"
        );
      }
      if (!user) {
        Navigate("/sign-up", { state: { selectedPlan: { id: id, planName: planType, additionUser: addUserCount, cycle: "monthly" } || null } });
      } else {
        if ((userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") && planType === "Team") {
          setPopupId(id);
          setPlanUpgradeModal(true);
        } else {
          Navigate("/checkout", { state: { id: id, planName: planType, additionUser: addUserCount, cycle: "monthly" } });
        }
      }
    }, 500);
  };

  const handleRedirectCheckout = () => {
    Navigate("/checkout", { state: { id: popupId, planName: planType, additionUser: addUserCount, cycle: "monthly" } });
  }

  // handle user decrement function for additional user
  const handleDecrement = () => {
    if (addUserCount > users) {
      setAddUsercount((prevCount) => prevCount - 1);
    }
  };

  // handle user increment function for additional user
  const handleIncrement = () => {
    setAddUsercount((prevCount) => prevCount + 1);

  };


  useEffect(() => {
    if (userInfo?.subscription_details?.plan === planType) {
      setBtnText("current");
    }
  }, []);

  useEffect(() => {
    if (planType === "Free") {
      setBtnText("Select Plan");
      setTag("For Individuals");
      setListHeading("Included");
      // Navigate('/dashboard')
    }
    if (planType === "Professional") {
      setBtnText("Select Plan");
      setTag("For Individuals");
      setListHeading("Included");
    }
    if (planType === "Team") {
      setBtnText("Select Plan");
      setTag(users + "  users included");
      setListHeading("Everything in “Professional” plus");
    }
    if (planType === "Enterprise") {
      setBtnText("Talk To Sales");
      setTag("No user limits");
      setListHeading("Everything in “Team” plus");
    }
    if (planType === userInfo?.subscription_details?.plan && (BillingCycle === "monthly" || !BillingCycle)) {
      setBtnText("Current Plan");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Free') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Professional') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Enterprise') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Free' && user?.Account_status === 'is_Deactivated') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Professional' && user?.Account_status === 'is_Deactivated') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Team') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Professional') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Free') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Team') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Professional') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Free') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Team') {
      setBtnText("Upgrade");
    }
  }, [planType, users, userInfo?.subscription_details?.plan]);

  const paragraphs = features.split('</p>').map((paragraph, index) => (
    <div className="d-flex " key={index}>
      {paragraph.trim() && <Check />} {/* Display the check icon if the paragraph is not empty */}
      <p className="m-0" dangerouslySetInnerHTML={{ __html: paragraph + `<style> p { margin: 0; } </style> </p>` }} />
    </div>
  ));

  return (
    <>
      <div className="plan-card">
        <h3>{PublicPlanName}</h3>
        <span>{description}</span>
        <hr style={{ width: "112%", margin: 0 }}></hr>

        <div className="price-section">
          <span className="price-heading">{tag}</span>
          <span className="price">
            {planType === "Enterprise" ? "Dynamic Pricing" : "$" + (total || 0)}
            {/* {planType === "Enterprise" ? "+" + users + " users" : "$" + (total || 0)} */}
            {planType === "Team" || planType === "Professional" ? "/month" : ""}
          </span>
          {/* <span className="yearly-price">
            {planType === "Free" && ``}
            {planType === "Enterprise" && ""}
            {(planType === "Team" || planType === "Professional") &&
              `Billed at $${total * 12}/year`}
          </span> */}
          {/* <span className="user-count">{includedUsers}</span> */}
          {planType === "Free" && planType === "Enterprise" && (userInfo?.isInitailUser === true || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || !user) && <span className="yearly-price"></span>}
          {planType === "Professional" && monthly_fee && (userInfo?.isInitailUser === true || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || !user) && <span>+ ${monthly_fee || 0}.00 One Time Setup Fee</span>}
          {planType === "Professional" && !monthly_fee && <span className="yearly-price"></span>}
          {planType !== "Team" ? planType === "Professional" ? <></> : (
            <> <span className="yearly-price"></span></>
          ) : (
            <>
              {(userInfo?.isInitailUser === true || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || !user) && monthly_fee && <span>+ ${monthly_fee || 0}.00 One Time Setup Fee</span>}
              <div className="add-users-tab">
                <p>Add users</p>
                <span id="right-div">
                  <div class="button" id="minus-btn" onClick={handleDecrement}>
                    -
                  </div>
                  <div
                    style={{ outline: "none", width: '50px', overflowX: 'hidden' }}
                    id="center-number"
                    dangerouslySetInnerHTML={{ __html: addUserCount }} // Use dangerouslySetInnerHTML to render HTML content
                  />

                  <div class="button" id="plus-btn" onClick={handleIncrement}>
                    +
                  </div>
                </span>
              </div>
            </>
          )}
        </div>
        {props?.props?.isOtcAdminPanel ? (
          <button className="plan-card button" onClick={() => props.onSelectPlan(_id, addUserCount)}>
            Select Plan
          </button>
        ) : (
          user?.role !== "teammember" ? (
            btnText !== "Inactive" ? (
              btnText === "Current Plan" ? (
                <button className="plan-card button" disabled style={{ opacity: '0.6' }}>
                  {btnText}
                </button>
              ) : (
                <button className="plan-card button" onClick={() => handleClick(_id)}>
                  {btnText}
                </button>
              )
            ) : (
              null
            )
          ) : (
            null
          )
        )}


        <div className="bottom-list">
          <h3>{listHeading}:</h3>
          <ul style={{ marginTop: "10px" }}>
            {/* {features?.map((text, index) => {
              return (
                <li key={index}>
                  <Check />
                  {text}
                </li>
              );
            })} */}
            {/* <p
  className="mb-0"
  dangerouslySetInnerHTML={{
    __html: `<style> p { margin: 0; } </style> ${<img src={check} alt="" />} ${features}`,
  }}
/> */}


            {paragraphs}


          </ul>
        </div>

        <div
          className="border-top text-center mt-auto"
          style={{ width: "112%" }}
        >
          <h4 style={{ fontSize: '20px' }}>Compare Plan</h4>
        </div>
      </div>
      <Modal
        open={planUpgradeModal}
        onClose={() => setPlanUpgradeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={renameModalStyle}>
          <div className="Modal-section">
            <h4 className="header">
              {userInfo?.subscription_details?.plan} Plan to Team Plan Upgrade{" "}
            </h4>
            <p>Please note that once you've upgraded your plan, you won't be able to downgrade it. Make sure to choose the plan that suits your needs before upgrading.</p>


            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn"
                onClick={handleRedirectCheckout}
              >
                Continue
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                // onClick={setOpenRenameModal(false)}
                onClick={() => setPlanUpgradeModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const YearlyCard = (props) => {
  const {
    _id,
    planType,
    description,
    yearlyPrice_perUser,
    users,
    features,
    // discoutedPercentage,
    yearly_fee,
    PublicPlanName
  } = props.props;
  const { userInfo } = useSelector((state) => state.userInformation)
  const { user } = useSelector((state) => state.user);
  const [btnText, setBtnText] = useState("");
  const [tag, setTag] = useState("");
  const [listHeading, setListHeading] = useState("");
  const [addUserCount, setAddUsercount] = useState(users);
  const [planUpgradeModal, setPlanUpgradeModal] = useState(false);
  const [popupId, setPopupId] = useState(null);
  const [total, setTotal] = useState("");
  const [totalPerYear, setTotalPerYear] = useState("");

  const [recurringCycle, setRecurringCycle] = useState(null);


  // useEffect(()=> {
  //   setTotal((users * monthlyPrice_perUser) + ((addUserCount - users) * monthlyPrice_perUser))
  //     },[monthlyPrice_perUser, addUserCount])

  useEffect(() => {
    const discountAmount = 0;
    const discountAmounts = yearlyPrice_perUser - discountAmount;
    const discountedPricePerYear = (discountAmounts * users * 12) + (discountAmounts * (addUserCount - users) * 12)
    const discountedPrice = (discountAmounts * users) + (discountAmounts * (addUserCount - users))
    // const discountedPrice = (discountAmounts * users * 12) + (discountAmounts * (addUserCount - users) * 12)
    const roundedDiscountedPricePerYear = isNaN(discountedPricePerYear)
      ? 0
      : Math.floor(discountedPricePerYear);
    const roundedDiscountedPrice = isNaN(discountedPrice)
      ? 0
      : Math.floor(discountedPrice);
    // const roundedDiscountedPrice = isNaN(discountedPrice)
    //   ? 0
    //   : Math.floor(discountedPrice);
    setTotal(roundedDiscountedPrice)
    setTotalPerYear(roundedDiscountedPricePerYear)
  }, [yearlyPrice_perUser, addUserCount])

  let discoutedPercentage;
  discoutedPercentage = 20;
  const { cycle } = props;

  const Navigate = useNavigate();
  const BillingCycle = userInfo?.subscription_details?.billing_cycle
  // 
  // const discountAmount = (discoutedPercentage / 100) * yearlyPrice_perUser;
  const discountAmount = 0;
  const discountAmounts = yearlyPrice_perUser - discountAmount;
  const discountedPrice = discountAmounts * users * 12
  const roundedDiscountedPrice = isNaN(discountedPrice)
    ? 0
    : Math.floor(discountedPrice);

  const handleClick = (id) => {
    if (planType === "Enterprise") {
      return window.open("https://onetapconnect.com/contact-sales/", "_blank");
    }
    // else if (planType == "Free"){
    //   return Navigate("/dashboard")
    // }

    setTimeout(() => {
      let reccuringCycle;
      if (cycle) {
        reccuringCycle = "yearly";
      } else {
        reccuringCycle = "monthly";
      }
      if (!user) {
        // Navigate("/sign-up")

        // Navigate("/sign-up", { state: { id: id, cycle: reccuringCycle, planName: planName, additionUser: addUserCount  } });
        Navigate("/sign-up", { state: { selectedPlan: { id: id, planName: planType, additionUser: addUserCount, cycle: "yearly" } || null } });

      } else {
        // alert(userInfo?.subscription_details?.plan);
        // alert(planName);
        if ((userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") && planType === "Team") {
          setPopupId(id);
          setRecurringCycle(reccuringCycle);
          setPlanUpgradeModal(true);
        } else {
          // localStorage.setItem('planId', id);
          // localStorage.setItem('cycle', "yearly");
          // localStorage.setItem('additionalUser', addUserCount);
          Navigate("/checkout", {
            state: { id: id, cycle: "yearly", planName: planType, additionUser: addUserCount },
          });
        }
      }
    }, 500);
  };

  const handleRedirectCheckout = () => {
    // localStorage.setItem('planId', popupId);
    // localStorage.setItem('cycle', "yearly");
    // localStorage.setItem('additionalUser', addUserCount);
    Navigate("/checkout", {
      state: { id: popupId, cycle: "yearly", planName: planType, additionUser: addUserCount },
    });
  }
  // handle user decrement function for additional user
  const handleDecrement = () => {
    if (addUserCount > users) {
      setAddUsercount((prevCount) => prevCount - 1);
    }
  };

  // handle user increment function for additional user
  const handleIncrement = () => {
    setAddUsercount((prevCount) => prevCount + 1);
  };

  const handleKeyDown = (event) => {
    // Allow only numbers, backspace, and arrow keys
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight'];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.textContent.trim(); // Trim to handle empty string
    const parsedValue = parseInt(inputValue);

    if (inputValue === '') {
      setAddUsercount(0);
    } else if (!isNaN(parsedValue)) {
      setAddUsercount(parsedValue);
    }
  };

  useEffect(() => {
    if (planType === "Free") {
      setBtnText("Select Plan");
      setTag("For Individuals");
      setListHeading("Included");
    }
    if (planType === "Professional") {
      setBtnText("Select Plan");
      setTag("For Individuals");
      setListHeading("Included");
    }
    if (planType === "Team") {
      setBtnText("Select Plan");
      setTag(users + "  users included");
      setListHeading("Everything in “Professional” plus");
    }
    if (planType === "Enterprise") {
      setBtnText("Talk To Sales");
      setTag("No user limits");
      setListHeading("Everything in “Team” plus");
    }
    if (planType === userInfo?.subscription_details?.plan && (BillingCycle === "yearly" || !BillingCycle)) {
      setBtnText("Current Plan");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Free') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Professional') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Team' && planType === 'Enterprise') {
      setBtnText("Inactive");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Free' && user?.Account_status === 'is_Deactivated') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Professional' && user?.Account_status === 'is_Deactivated') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Team') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Free' && planType === 'Professional') {
      setBtnText("Upgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Free') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Team') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Enterprise' && planType === 'Professional') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Free') {
      setBtnText("Downgrade");
    }
    if (userInfo?.subscription_details?.plan === 'Professional' && planType === 'Team') {
      setBtnText("Upgrade");
    }
  }, [planType, users]);

  const paragraphs = features.split('</p>').map((paragraph, index) => (
    <div className="d-flex " key={index}>
      {paragraph.trim() && <Check />} {/* Display the check icon if the paragraph is not empty */}
      <p className="m-0" dangerouslySetInnerHTML={{ __html: paragraph + `<style> p { margin: 0; } </style> </p>` }} />
    </div>
  ));

  return (
    <>
      <div className="plan-card">
        <h3>{PublicPlanName}</h3>
        <span>{description}</span>
        <hr style={{ width: "112%", margin: 0 }}></hr>

        <div className="price-section">
          <span className="price-heading">{tag}</span>
          <span className="price">
            {planType === "Enterprise"
              ? "Dynamic Pricing"
              : "$" + (total)}
            {planType === "Team" || planType === "Professional" ? "/month " : ""}{" "}
            {(planType === "Team" || planType === "Professional") && (
              <span
                className="price"
                style={{ textDecoration: "line-through" }}
              >
              </span>
            )}
          </span>
          <span className="yearly-price">
            {planType === "Free" && ``}
            {planType === "Enterprise" && ""}
            {(planType === "Team" || planType === "Professional") &&
              `Billed at $${totalPerYear}/year`}
          </span>
          {planType === "Professional" && yearly_fee && (userInfo?.isInitailUser === true || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || !user) && <span>+ ${yearly_fee || 0}.00 One Time Setup Fee </span>}
          {planType === "Professional" && !yearly_fee && <span className="yearly-price" ></span>}
          {planType !== "Team" ? planType === "Professional" ? <></> : (
            <> <span className="yearly-price" ></span> </>
          ) : (
            <>
              {(userInfo?.isInitailUser === true || userInfo?.subscription_details?.plan === '' || userInfo?.subscription_details?.plan === null || !user) && yearly_fee && <span>+ ${yearly_fee || 0}.00 One Time Setup Fee </span>}

              <div className="add-users-tab">
                <p>Add users</p>
                <span id="right-div">
                  <div class="button" id="minus-btn" onClick={handleDecrement}>
                    -
                  </div>
                  <div
                    style={{ outline: "none", width: '50px', overflowX: 'hidden' }}
                    id="center-number"
                    dangerouslySetInnerHTML={{ __html: addUserCount }} // Use dangerouslySetInnerHTML to render HTML content
                  />

                  <div class="button" id="plus-btn" onClick={handleIncrement}>
                    +
                  </div>
                </span>
              </div>
            </>
          )}
        </div>

        {props?.props?.isOtcAdminPanel ? (
          <button className="plan-card button" onClick={() => props.onSelectPlan(_id, addUserCount)}>
            Select Plan
          </button>
        ) : (
          user?.role !== "teammember" ? (
            btnText !== "Inactive" ? (
              btnText === "Current Plan" ? (
                <button className="plan-card button" disabled style={{ opacity: '0.6' }}>
                  {btnText}
                </button>
              ) : (
                <button className="plan-card button" onClick={() => handleClick(_id)}>
                  {btnText}
                </button>
              )
            ) : (
              null
            )
          ) : (
            null
          )
        )}

        <div className="bottom-list">
          <h3>{listHeading}:</h3>
          <ul style={{ marginTop: "10px" }}>
            {paragraphs}
          </ul>
        </div>

        <div
          className="border-top text-center mt-auto"
          style={{ width: "112%" }}
        >
          <h4 style={{ fontSize: '20px' }}>Compare Plan</h4>
        </div>
      </div>
      <Modal
        open={planUpgradeModal}
        onClose={() => setPlanUpgradeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={renameModalStyle}>
          <div className="Modal-section">
            <h4 className="header">
              {userInfo?.subscription_details?.plan} Plan to Team Plan Upgrade{" "}
            </h4>
            <p>Please note that once you've upgraded your plan, you won't be able to downgrade it. Make sure to choose the plan that suits your needs before upgrading.</p>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn"
                onClick={handleRedirectCheckout}
              >
                Continue
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={() => setPlanUpgradeModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PlanCard;
export { YearlyCard };
