
import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import AddonsHeaderWithIcons from "./AddonsHeaderWithIcons";
import Otc_Addonsinfo from "./Otc_Addonsinfo";

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("Add-ons");
    const [showCreateAddon, setShowCreateAddon] = useState(false); 

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const handleAddonClick = () => {
        setShowCreateAddon(false);
    };

    const handleAddNewAddonClick = () => {
        setShowCreateAddon(true);
    }
    const handleAddonNameClick = () => {
        setShowCreateAddon(true);
    };

    return (
        <div className="onetap_conn_user">
            <AddonsHeaderWithIcons onChange={getTabValue} tab={companyProfileTab} 
             showCreateAddon={showCreateAddon}
             onAddonsClick={handleAddonClick} 
             onAddNewAddonClick={handleAddNewAddonClick}   />
             
            {companyProfileTab === "Add-ons" && (
                <Otc_Addonsinfo showCreateAddon={showCreateAddon} onAddonNameClick={handleAddonNameClick} onClickSave={handleAddonClick}
                />
            )}
        </div>
    );
};


export default function Otc_Addons() {
    return (
        <>
            <Otc_Sidebar Component={Component} productsServicesOpen={true}/>
        </>
    )
}