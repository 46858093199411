import React, { useState } from 'react'
import OtcSidebar from '../Layout/Sidebar/Otc_Sidebar';
import OTC_adminusers_profile_headers from './OTC_Editadminheaders';
import OTC_adminusers_edit_info from './OTC_Editadmin';


const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("OTC Users");

  const getTabValue = (tabVal) => {
      setCompanyProfileTab(tabVal);
  };

  const getValueOfSaveAndContinueTab = (value) => {
      setCompanyProfileTab(value);
  };
  return (
      <div className="p-2">
          <OTC_adminusers_profile_headers onChange={getTabValue} tab={companyProfileTab} />
          {companyProfileTab === "OTC Users" && (
              <OTC_adminusers_edit_info
                  onClickSave={getValueOfSaveAndContinueTab}
              />
          )}
      </div>
  );
};
const OTC_adminusers_edit_profile = () => {
  return (
    <OtcSidebar Component={Component} />
  )
}

export default OTC_adminusers_edit_profile