import React, { useEffect, useState } from "react";
import phoneImg from "../../../assets/images/card-image.svg";
import { useNavigate, useParams } from "react-router-dom";
import '../Login/login.scss'
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../actions/userAction/userAction";
import open_eye from '../../../assets/icons/eye.svg'
import close_eye from '../../../assets/icons/eye-off.svg'
import logo from '../../../assets/icons/logo_black.svg'
import PasswordChecklist from "react-password-checklist"

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;

const Inputs = ({ value, setvalue, error, placeholder, onClick }) => {

  const [type, setType] = useState("password")
  const [typeImg, setTypeImg] = useState(open_eye)
  const handlePasswordInputClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleShowPassword = () => {
    if (type === "password") {
      setType("text")
      setTypeImg(close_eye)
    }
    else {
      setType("password")
      setTypeImg(open_eye)
    }
  }

  return (
    <>
      <div className="login_inputs">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setvalue(e.target.value);
          }}
          autoComplete
          autoFocus
          onClick={handlePasswordInputClick}
        />
        <img
          src={typeImg}
          alt="Show"
          srcset=""
          className="login_eye"
          onClick={handleShowPassword}
        />
        <span className="login_error">{error}</span>
      </div>
    </>
  );
}

const ForgotPassword = () => {
  const ErrorMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#FDE9E9',
    color: "#ea4e4e",
    borderLeft: '5px solid'
  };

  const errorIconstyle = {
    position: 'absolute',
    top: '-8px',
    right: '12px',
    width: '25px',
  }
  const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="Error"><circle cx="32" cy="32" r="28" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></circle><line x1="32" x2="32" y1="18" y2="38" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line><line x1="32" x2="32" y1="42" y2="46" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line></svg>

  const loginInputsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
  };


  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const { token } = useParams()
  const { user, loading, message, error } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const [passwordError, setPasswordError] = useState('')
  const [passwordError2, setPasswordError2] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);
  const handlePasswordValidation = (isValid) => {
    setIsPasswordValid(isValid);
  };

  useEffect(() => {
    if (message) {
      navigate('/login')
    }
  }, [loading, navigate])



  const loginhandler = (e) => {
    e.preventDefault();
    if (!isPasswordValid) {
      return;
    }

    if (confirmPassword === "" || password === "") {
      if (!password) {
        setPasswordError("Please enter Password.");
        return false;
      }
      if (!confirmPassword) {
        setPasswordError2("Please enter Confirm password.");
        return false;
      }
    }



    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      return false;
    }
    if (!passwordRegex.test(password)) {
      return setPasswordError("Must contain at least one uppercase, lowercase letter, one special character, and one number.");
    }

    if (password !== confirmPassword) {
      setPasswordError2("Password does not match.")
      return false
    }

    dispatch(resetPassword(password, token));
  };

  useEffect(() => {

    if (error) {
      setPasswordError2(error);
      dispatch({ type: "CLEAR_ERROR" })
    }
    if (message) {

      dispatch({ type: "CLEAR_MESSAGE" })
    }

    if (user) {
      navigate("/dashboard");
    }
  }, [user, error, message, dispatch, navigate]);

  return (
    <div className="login_password_container">

      <div className="login_password_left">
        <div className="login_header">
          <img src={logo} alt="" srcset="" />
        </div>
        <div className="login_password_information">
          <h1>Reset Password</h1>
          <span className="reset_text">Enter your new password below, we’re just being extra safe.</span>


          <form onSubmit={loginhandler}>

            <Inputs onClick={() => setShowPasswordChecklist(true)} value={password} setvalue={setPassword} placeholder="Enter New Passowrd" />
            <Inputs onClick={() => setShowPasswordChecklist(true)} value={confirmPassword} setvalue={setConfirmPassword} placeholder="Confirm Password" />
            {showPasswordChecklist &&

              <PasswordChecklist className="password-checklist" style={{ color: "white", display: "block" }}
                rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                minLength={8}
                value={password}
                valueAgain={confirmPassword}
                messages={{
                  minLength: "At least 8 characters",
                  specialChar: "Contains one speacial character (@,#,$,%,etc.)",
                  number: "Contains one number",
                  capital: "Contains one uppercase letter",
                  lowercase: "Contains one lowercase letter",
                  match: "Passwords match.",
                }}
                invalidTextColor="red"
                onChange={handlePasswordValidation}
              />}

            <style jsx>{`
  /* Override display: flex for the li elements in the PasswordChecklist component */
  .password-checklist li {
    display: block !important;
    /* Use !important to ensure the override */
    /* Add any additional styles or modifications as needed */
  }
  .password-checklist li span{
padding: 2px 7px !important;
  }

  /* You may need to use even more specific selectors depending on the library's styles */
`}</style>

            {(passwordError || passwordError2) &&
              <div style={loginInputsStyle} className="login_inputs">
                <div style={ErrorMessageDiv}>{passwordError ? passwordError : passwordError2}</div>
                <span style={errorIconstyle} className="">{errorIcon}</span>
              </div>}
            <button style={{ opacity: isPasswordValid ? 1 : 0.5 }} disabled={!isPasswordValid} type="submit">{loading ? 'Loading...' : 'Reset Password'}</button>

          </form>

        </div>
      </div>
      <div className="login_password_right">
        <h3>Meet Kimba</h3>
        <img src={phoneImg} alt="phoneImg" />

      </div>
    </div>
  );
};

export default ForgotPassword;
