// Component.js
import React, { useEffect, useState } from "react";
import ClientComppanyBillingHeaders from "./ClientComppanyBillingHeaders";
import ClientCompanySubscriptionandaddons from "./ClientCompanySubscriptionandaddons";
import ClientCompanyBillingandContactinformation from "./ClientCompanyBillingandContactinformation";
import ClientCompanyTransactions from "./ClientCompanyTransactions";
import ClientCompanyPaymentMethod from "./ClientCompanyPaymentMethod";
import ClientCompanyShippingAddresses from "./ClientCompanyShippingAddresses";
import ClientCompanyUserroles from "./ClientCompanyUserroles";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllUsersOfSelectedCompany } from "../../Redux/Otc_UserActions";

const ClientCompanyBilling = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState(
    "Subscription & add-ons"
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  useEffect(() => {
    dispatch(getAllUsersOfSelectedCompany(id));
  }, []);
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };

  return (
    <div className="onetap_conn_user" style={{ padding: "0px" }}>
      <ClientComppanyBillingHeaders
        onChange={getTabValue}
        tab={companyProfileTab}
      />
      <div className="" style={{ marginTop: "5px" }}>
        {companyProfileTab === "Subscription & add-ons" && (
          <ClientCompanySubscriptionandaddons
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Billing & Contact information" && (
          <ClientCompanyBillingandContactinformation
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Transactions" && (
          <ClientCompanyTransactions
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Payment Methods" && (
          <ClientCompanyPaymentMethod
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Shipping Addresses" && (
          <ClientCompanyShippingAddresses
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "User roles" && (
          <ClientCompanyUserroles onClickSave={getValueOfSaveAndContinueTab} />
        )}
      </div>
    </div>
  );
};

export default ClientCompanyBilling;
