import React from "react";
import "./GenInfoToggleButton.scss";

function GenInfoToggleButton({ isOn, onToggle }) {
  const handleToggle = () => {
    if (onToggle) {
      onToggle(!isOn); 
    }
  };

  return (
    <div
      className={`toggle-btn mt-1 ${isOn ? "toggle-btn--on mt-1" : ""}`}
      onClick={handleToggle}
      style={{ cursor: "pointer" }}
    >
      <div
        className={`toggle-btn__inner ${isOn ? "toggle-btn__inner--on" : ""}`}
      >
        <div className="toggle-btn__icon">
          <span
            className={`toggle-btn__icon__line ${isOn ? "toggle-btn__icon__line--tick" : ""
              }`}
          ></span>
        </div>
      </div>
    </div>
  );
}

export default GenInfoToggleButton;
