import React, { useState } from "react";
import Otc_Sidebar from "../Layout/Sidebar/Otc_Sidebar";
import SmartAccessoryHeaderWithIcons from "./SmartAccessoryHeaderWithIcons";
import SmartAccessory from "./SmartAccessory";
import SmartAccessoryPrefix from "./smartAccessoryPrefix";

const Component = () => {
  const [newstate, Setnewstate] = useState("SmartAccessories");
  const getTabValue = (tabVal) => {
    Setnewstate(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    Setnewstate(value);
  };
  return (
    <>
      <div className="onetap_conn_user">
        <SmartAccessoryHeaderWithIcons onChange={getTabValue} tab={newstate} />
        {newstate === "SmartAccessories" && (<SmartAccessory onClickSave={getValueOfSaveAndContinueTab} />)}
        {newstate === "Smart Accessories Prefix" && (<SmartAccessoryPrefix onClickSave={getValueOfSaveAndContinueTab} />)}
      </div>
    </>
  );
};
const SmartAccessoryMain = () => {
  return <Otc_Sidebar Component={Component} />;
};
export default SmartAccessoryMain;
