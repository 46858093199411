import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Otc_Sidebar from "../../../Layout/Sidebar/Otc_Sidebar";
import AdminAddusermanuallyinfo from "./AdminAddusermanuallyinfo";
import AdminAddusermanuallyHeader from "./AdminAddusermanuallyHeader";

const Component = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  return (
    <div className="">
      <div className="onetap_company_profile_syn">
        <AdminAddusermanuallyHeader
          onChange={getTabValue}
          tab={companyProfileTab}
        />

        <div className="">
          {companyProfileTab === "General" && (
            <AdminAddusermanuallyinfo
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const AdminAddusermanually = () => {
  return (
    <>
      <Otc_Sidebar Component={Component} />
    </>
  );
};

export default AdminAddusermanually;
