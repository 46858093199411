import { Accordion, AccordionDetails, AccordionSummary, Box, Modal, Typography } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { useParams } from 'react-router-dom';
import { Country, State } from "country-state-city";
import { getAllUsersOfCompany } from '../Redux/Otc_UserActions';
import Loader from '../../components/Layout/Loader/Loader';
import axios from 'axios';
import Noimagefound from "../../OTC-AdminPanel/Otc_assets/images/Noimagefound.png";
import cross from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/uncheckedicon.svg";
import selected from "../../OTC-AdminPanel/Otc_assets/OTC_ICONS/Selectedicon.svg";
import TagsInput from './TagsInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  const messageIcon =     <svg
xmlns="http://www.w3.org/2000/svg"
width="80"
height="80"
viewBox="0 0 80 80"
fill="none"
class="circle"
>
<path
  d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
  fill="#E65925"
/>
<path
  d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
  stroke="white"
  stroke-width="6.64"
  stroke-linecap="round"
  class="checkmark-path"
/>
</svg>

const ClientProfileInformation = () => {
    const [Titlename, setTitlename] = useState("")
    const [TopAvatar, setTopAvatar] = useState("")
    const [teamplan, setTeamplan] = useState(false)
    const [ActiveUsers, setActiveUsers] = useState("")
    const [userinfostatedata, setUserinfostatedata] = useState([])
    const [userOrderstatedata, setUserOrderstatedata] = useState([])
    const [userInitialOrderstatedata, setInitialUserOrderstatedata] = useState([])
    const [recentOrder, setRecentOrder] = useState(null);
    const [companystatedata, setCompanystatedata] = useState({})
    const [viewAllorder, setViewAllorder] = useState(false)
    const [viewAllbtn, setViewAllbtn] = useState(true)
    const url = process.env.REACT_APP_URL;
    const [industries, setIndustries] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [referrer, setReferrer] = useState("");
    const [referrerName, setReferrerName] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        dispatch(getAllUsersOfCompany(id));
    }, [id]);
    const { loading, companydata, userInformationTeamData } = useSelector(
        (state) => state.usersOfCompany
    );

    useEffect(() => {
        if (userInformationTeamData && userInformationTeamData.length > 0) {
            setUserinfostatedata(userInformationTeamData)
            setCompanystatedata(companydata)
        }
    }, [id, userInformationTeamData, companydata])
    // 
    const getOrderssofcompany = async () => {
        
        try {
            const { data } = await axios.post(`${url}/admin/getorderssofcompany`, { id });
            setInitialUserOrderstatedata(data.Orderssofcompany)
            
        } catch (error) {
            ;
        }
    }

    useEffect(() => {
        getOrderssofcompany()
    }, [id])
    useEffect(() => {
        if (userInitialOrderstatedata.length > 0) {
            // Create a new sorted array without modifying the original state
            const sortedOrders = [...userInitialOrderstatedata].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            
            setUserOrderstatedata(sortedOrders)
            setRecentOrder(sortedOrders[0]);
        }
    }, [userInitialOrderstatedata]);
    
    // Determine the Titlename based on the conditions
    useEffect(() => {
        if (userinfostatedata && userinfostatedata.length > 0) {
            const hasTeamPlan = userinfostatedata.some(
                (user) => user.subscription_details.plan === "Team"
            );
            if (hasTeamPlan) {
                setTeamplan(true)
                setTitlename(companystatedata?.company_name);
                setTopAvatar(companystatedata?.logopath !== "" ? `${url}/logo/img/${companystatedata?.logopath}` : "")
            } else if (userinfostatedata[0].user_id?.first_name) {
                setTitlename(`${userinfostatedata[0]?.user_id?.first_name} ${userinfostatedata[0]?.user_id?.last_name}`);
                setTopAvatar(userinfostatedata[0]?.user_id?.avatar !== "" ? `${url}/profile/img/${userinfostatedata[0]?.user_id?.avatar}` : "")
            }
        }
    }, [userinfostatedata, companystatedata]);

    const fetchIndustries = async () => {
        try {
            const { data } = await axios.get(`${url}/industries`);
            setIndustries(data.industries);
        } catch (error) {
            
        }
    };
    useEffect(() => {
        fetchIndustries();
    }, []);
    const activeUsers = userInformationTeamData?.filter(item => item.user_id && item.user_id.status === "active");
    const numberOfActiveUsers = activeUsers?.length;

    const fullName = Titlename;
    const words = fullName.split(" ");
    const initials = words.slice(0, 2).map((word) => word.charAt(0).toUpperCase()).join("");
    const createdAt = companystatedata?.createdAt; // Assuming companydata is the object containing the date

    // Check if createdAt is not null or undefined before proceeding
    // const formattedDate = createdAt ? new Date(createdAt).toLocaleDateString('en-US') : '';
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Return an empty string if dateString is not provided

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });

        return formattedDate;
    };


    const totalactiveusers = userinfostatedata?.filter(user => user.user_id && user.user_id.status === 'active').length;
    const totalinactiveusers = userinfostatedata?.filter(user => user.user_id && user.user_id.status === 'inactive').length;
    const superAdminUser = userinfostatedata.find(user => user.user_id?.role === "superadmin");
    const baseUser = superAdminUser?.subscription_details?.total_user && superAdminUser?.subscription_details?.total_user[0]?.baseUser;
    // const baseUser = userinfostatedata && userinfostatedata[0]?.subscription_details?.total_user[0]?.baseUser;
    const additionalUser = superAdminUser?.subscription_details?.total_user && superAdminUser?.subscription_details?.total_user[0]?.additionalUser;
    const total_user = baseUser + additionalUser;
    // Get the current date
    const currentDate = new Date();
    // Extract the current month and year
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based (0 - 11)
    const currentYear = currentDate.getFullYear();
    // Filter objects where the "createdAt" date matches the current month and year
    const matchingUsers = userinfostatedata?.filter(user => {
        const userDate = new Date(user.createdAt);
        return userDate.getMonth() + 1 === currentMonth && userDate.getFullYear() === currentYear;
    });
    const thismonthtotalactiveusers = matchingUsers?.filter(user => user.user_id && user.user_id.status === 'active').length;
    const thismonthtotalinactiveusers = matchingUsers?.filter(user => user.user_id && user.user_id.status === 'inactive').length;
    // Get the count of matching users
    const matchingUsersCount = matchingUsers?.length;

    function capitalizeWords(str) {
        const words = str?.split(' ');
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords?.join(' ');
    }

    const handlesave = async () => {
        try {
            const dataToSend = {
                companyId: id,
                referrer: referrer === 'Other' || referrer === 'Existing OneTapConnect user' ? referrerName : referrer,
            };

            const response = await axios.post(`${url}/admin/addreferer`, dataToSend , {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              });
              if(response.data.success){
                setApiSuccess(true)
                setSuccessMessage('Data updated successfully.')
                setTimeout(() => {
                  setApiSuccess(false)
                }, 5000);
              }
              console.log(response)
            
        } catch (error) {
            
        }
    };

    useEffect(() => {
        const fetchReferer = async () => {
          try {
            const response = await axios.post(`${url}/admin/getreferer`,{
                companyId:id
            },{
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              });
            const refererData = response.data.referer;
            
            
            // Set the referrer state based on the API response
            if (refererData) {
              if (refererData.referer === 'Other' || refererData.referer === 'Existing OneTapConnect user') {
                setReferrerName(refererData.referer);
                setReferrer('Other'); // Assuming 'Other' is the value for the input field
              } else {
                setReferrer(refererData.referer);
              }
            }
          } catch (error) {
            
          }
        };
    
        // Call the fetchReferer function when the component mounts
        if (id) {
          fetchReferer();
        }
      }, [id]); 

      

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <>
                    {userinfostatedata && userinfostatedata.length > 0 &&
                        <div className="row" style={{ padding: '0px 14px' }} >
                            <div className="col-4">
                                <div className="row bg-white" style={{ borderRadius: '4px', padding: '20px' }}>
                                    <h5 style={{ fontSize: '20px', padding: '0px 0px 20px', borderBottom: '1px solid #f2f2f2', marginBottom: '10px' }}>Account Information</h5>
                                    {/* <hr style={{ marginLeft: '10px', width: '95%' }} /> */}
                                    {/* <p style={{

                            color: teamMemberDetails?.status === "active" ? "#5CE12F" : "#E62525",
                        }}>
                            {teamMemberDetails?.status?.charAt(0).toUpperCase() + teamMemberDetails?.status?.slice(1)}
                        </p> */}
                                    <div className="onetap_conn_dashbord_card_OTC_Admin" style={{ display: 'flex', alignItems: 'center',paddingLeft:'0px',paddingRight:'0px' }}>
                                        <div className="user-info-avatar-container_OTC_Admin">
                                            {TopAvatar !== "" ? (
                                                <img
                                                    src={TopAvatar}
                                                    alt="Profile"
                                                    style={{
                                                        width: "54px",
                                                        height: "54px",
                                                        borderRadius: "100%",
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    className="user-info-initials-overlay_OTC_Admin"
                                                    style={{ width: "54px", height: "54px" }}
                                                >
                                                    {initials}
                                                </div>
                                            )}
                                        </div>
                                        <p style={{ margin: '0px', fontSize: '18px', fontWeight: '600' }}> {Titlename
                                            .split(' ')
                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                            .join(' ')}</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between',paddingLeft:'0px',paddingRight:'0px' }} className='mb-2'>
                                        <p style={{ fontWeight: '600', fontSize: '18px', marginBottom: '0px' }} className='mt-2'>Plan</p>
                                        <p style={{ color: '#2572E6', fontWeight: '800', textTransform: 'capitalize', fontSize: "18px", marginBottom: '0px' }}>{userInformationTeamData && userInformationTeamData.some(
                                            (user) => user.subscription_details.plan === "Team"
                                        ) ? "Team" : userinfostatedata[0]?.subscription_details?.plan}</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between',paddingLeft:'0px',paddingRight:'0px' }} className='mb-2'>
                                        <p style={{ fontWeight: '600', marginBottom: '0px' }}>Active users</p>
                                        <p style={{ fontWeight: '800', textTransform: 'capitalize', marginBottom: '0px' }}>{numberOfActiveUsers} / {userinfostatedata.length}</p>
                                    </div>
                                    <div className='mb-2' style={{paddingLeft:'0px',paddingRight:'0px'}} >
                                        <p style={{ marginBottom: '0px' }}>Profile type</p>
                                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2", textAlign: 'center' }} value={userInformationTeamData && userInformationTeamData.some(
                                            (user) => user.subscription_details.plan === "Professional"
                                        ) || userInformationTeamData && userInformationTeamData.some(
                                            (user) => user.subscription_details.plan === "Free"
                                        ) ? "Individual" : "Company"} readOnly />
                                    </div>
                                    <div className='mb-2' style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <p style={{ marginBottom: '0px' }}>Created date</p>
                                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={formatDate(companystatedata?.createdAt)} readOnly />
                                    </div>
                                    <div className='mb-2' style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <p style={{ marginBottom: '0px' }}>Lifecycle stage</p>
                                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} readOnly />
                                    </div>
                                    <div className='mb-2' style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <p style={{ marginBottom: '0px' }}>Client owner</p>
                                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.owner_first_name + " " + companystatedata?.owner_last_name} readOnly />
                                    </div>
                                    <div className='mb-3' style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                        <span>Referrer</span>
                                        <select
                                            class="form-select py-2 p-2"
                                            style={{ backgroundColor: "#f2f2f2" }}
                                            name="referrer"
                                            value={referrer}
                                            onChange={(e) => setReferrer(e.target.value)}
                                        >
                                            <option selected>
                                                select
                                            </option>
                                            <option value="OTC connect form">
                                                OTC connect form
                                            </option>
                                            <option value="Bing">Bing</option>
                                            <option value="Blog post">Blog post</option>
                                            <option value="Co-worker">Co-worker</option>
                                            <option value="Friend and family">Friend and family</option>
                                            <option value="Google">Google</option>
                                            <option value="Direct mail">Direct mail</option>
                                            <option value="Convention">Convention</option>
                                            <option value="Tiktok">Tiktok</option>
                                            <option value="Instagram">Instagram</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Linkedin">Linkedin</option>
                                            <option value="Text Message">Text Message</option>
                                            <option value="Direct call">Direct call</option>
                                            <option value="Existing OneTapConnect user">
                                                Existing OneTapConnect user
                                            </option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {(referrer === 'Existing OneTapConnect user' || referrer === 'Other') && (
                                        <div className='mb-2'>
                                            <p style={{ marginBottom: '0px' }}>Other</p>
                                            <input type="text" className='w-100 border-0 p-2 no-spinners' name="referrerName" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} onChange={(e) => setReferrerName(e.target.value)} value={referrerName} />
                                        </div>
                                    )}
                                    <button
                                        onClick={handlesave}
                                        disabled={""}
                                        style={{
                                            background: "#e65925",
                                            color: "white",
                                            width: '100px',
                                            marginLeft: 'auto',
                                            display: 'block',
                                            border: 'none',
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="row bg-white mt-2" style={{ borderRadius: '4px', padding: '20px',paddingTop:'0px'  }}>
                                    <Accordion
                                        style={{ boxShadow: "none", padding: '0px' }}
                                        defaultExpanded={true}
                                    >
                                        <AccordionSummary
                                            className=""
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingLeft:'0px',paddingRight:'0px' }}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                Client Information
                                            </Typography>

                                        </AccordionSummary>
                                        <div style={{ borderBottom: "1px solid #F2F2F2", paddingBottom: '15px' }}>
                                            {
                                                userInformationTeamData && userInformationTeamData.some(
                                                    (user) => user.subscription_details.plan === "Professional") || userInformationTeamData && userInformationTeamData.some(
                                                        (user) => user.subscription_details.plan === "Free")
                                                    ? <>  <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                        <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                            Name
                                                        </p>
                                                        <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={userinfostatedata[0]?.user_id?.first_name + " " + userinfostatedata[0]?.user_id?.last_name} readOnly />
                                                    </AccordionDetails>
                                                        <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                            <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                                Email
                                                            </p>
                                                            <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={userinfostatedata[0]?.user_id?.email} readOnly />
                                                        </AccordionDetails>   <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                            <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                                Cell Phone Number
                                                            </p>
                                                            <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.website_url} readOnly />
                                                        </AccordionDetails></> : ""
                                            }
                                            <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Website
                                                </p>
                                                <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.website_url} readOnly />
                                            </AccordionDetails>
                                            <AccordionDetails className="" style={{ paddingBottom: '0px' ,paddingLeft:'0px',paddingRight:'0px'}}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Primary Office Number
                                                </p>
                                                <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.contact} readOnly />
                                            </AccordionDetails>
                                            <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Industry
                                                </p>
                                                <div style={{ position: "relative" }}>
                                                    {/* <Select
                                                        styles={{
                                                            container: (provided) => ({
                                                                // ...provided,
                                                                width: "100%", // Set the width of the container as needed
                                                            }),
                                                            control: () => ({
                                                                // No border or box-shadow
                                                                margin: 0,
                                                                padding: 0,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                padding: "0 0",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                marginLeft: 0,
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0, // Remove margin
                                                                padding: 0, // Remove padding
                                                            }),
                                                            indicatorsContainer: () => ({
                                                                display: "none",
                                                            }),
                                                            option: (provided) => ({
                                                                ...provided,
                                                                display: 'none'
                                                                // Your custom option styles here
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                width: "90%",
                                                                display: 'none'
                                                            }),
                                                            // More styles for other components if needed...
                                                        }}
                                                        className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                        placeholder="Select Industry"
                                                        // options={industries.map((item) => ({
                                                        //     value: item.name,
                                                        //     label: item.name,
                                                        // }))}
                                                        value={{
                                                            value: companystatedata?.industry,
                                                            label: companystatedata?.industry
                                                        }}
                                                    // onChange={(selectedOption) =>
                                                    //     handleCompanyInputChange({
                                                    //         target: {
                                                    //             name: "industry",
                                                    //             value: selectedOption.value,
                                                    //         },
                                                    //     })
                                                    // }
                                                    /> */}
                                                    <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.industry} readOnly />
                                                </div>
                                            </AccordionDetails>
                                            {
                                                userInformationTeamData && userInformationTeamData.some(
                                                    (user) => user.subscription_details.plan === "Team"
                                                ) && <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                    <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                        Primary Activities / Contact Keywords
                                                    </p>
                                                    <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.keywords} readOnly />
                                                </AccordionDetails>
                                            }

                                            <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Company size
                                                </p>
                                                <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.team_size} readOnly />
                                            </AccordionDetails>
                                            <AccordionDetails className="" style={{ paddingBottom: '0px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Anuall revenue
                                                </p>
                                                <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={'$' + superAdminUser?.subscription_details?.total_amount} readOnly />
                                            </AccordionDetails>
                                        </div>
                                        <div>
                                            <AccordionDetails className="" style={{ paddingBottom: '0px' ,paddingLeft:'0px',paddingRight:'0px'}}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Country
                                                </p>
                                                <div style={{ position: "relative" }}>
                                                    {/* <Select
                                                        styles={{
                                                            container: (provided) => ({
                                                                // ...provided,
                                                                width: "100%", // Set the width of the container as needed
                                                            }),
                                                            control: () => ({
                                                                // No border or box-shadow
                                                                margin: 0,
                                                                padding: 0,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                padding: "0 0",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                marginLeft: 0,
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0, // Remove margin
                                                                padding: 0, // Remove padding
                                                            }),
                                                            indicatorsContainer: () => ({
                                                                display: "none",
                                                            }),
                                                            option: (provided) => ({
                                                                ...provided,
                                                                display: 'none'
                                                                // Your custom option styles here
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                width: "90%",
                                                                display: 'none'
                                                            }),
                                                            // More styles for other components if needed...
                                                        }}
                                                        className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                        placeholder="Select Industry"
                                                        // options={industries.map((item) => ({
                                                        //     value: item.name,
                                                        //     label: item.name,
                                                        // }))}
                                                        value={{
                                                            value: companystatedata?.address?.country,
                                                            label: Country.getCountryByCode(
                                                                companystatedata?.address?.country
                                                            )?.name,
                                                        }}
                                                    // onChange={(selectedOption) =>
                                                    //     handleCompanyInputChange({
                                                    //         target: {
                                                    //             name: "industry",
                                                    //             value: selectedOption.value,
                                                    //         },
                                                    //     })
                                                    // }
                                                    /> */}
                                                    <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={Country.getCountryByCode(
                                                        companystatedata?.address?.country
                                                    )?.name} readOnly />
                                                </div>
                                            </AccordionDetails>
                                            <AccordionDetails className="" style={{ paddingBottom: '20px',paddingLeft:'0px',paddingRight:'0px' }}>
                                                <p className="onetap_connect_free_user_accordian_heading_text_OTC_Admin m-0">
                                                    Time Zone
                                                </p>
                                                <div style={{ position: "relative" }}>
                                                    {/* <Select
                                                        styles={{
                                                            container: (provided) => ({
                                                                // ...provided,
                                                                width: "100%", // Set the width of the container as needed
                                                            }),
                                                            control: () => ({
                                                                // No border or box-shadow
                                                                margin: 0,
                                                                padding: 0,
                                                                border: "none",
                                                                boxShadow: "none",
                                                            }),
                                                            valueContainer: (provided) => ({
                                                                ...provided,
                                                                padding: "0 0",
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                marginLeft: 0,
                                                            }),
                                                            input: (provided) => ({
                                                                ...provided,
                                                                margin: 0, // Remove margin
                                                                padding: 0, // Remove padding
                                                            }),
                                                            indicatorsContainer: () => ({
                                                                display: "none",
                                                            }),
                                                            option: (provided) => ({
                                                                ...provided,
                                                                display: 'none'
                                                                // Your custom option styles here
                                                            }),
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                width: "90%",
                                                                display: 'none'
                                                            }),
                                                            // More styles for other components if needed...
                                                        }}
                                                        className="onetap_conn_general_information_input_syn mb-3 form-select"
                                                        placeholder="Select Industry"
                                                        // options={industries.map((item) => ({
                                                        //     value: item.name,
                                                        //     label: item.name,
                                                        // }))}
                                                        value={{
                                                            value: companystatedata?.timeZone,
                                                            label: companystatedata?.timeZone
                                                        }}
                                                    // onChange={(selectedOption) =>
                                                    //     handleCompanyInputChange({
                                                    //         target: {
                                                    //             name: "industry",
                                                    //             value: selectedOption.value,
                                                    //         },
                                                    //     })
                                                    // }
                                                    /> */}
                                                    <input type="text" className='w-100 border-0 p-2 no-spinners' name="" id="" style={{ borderRadius: " 4px", appearance: 'textfield', background: "#f2f2f2" }} value={companystatedata?.timeZone} readOnly />
                                                </div>
                                            </AccordionDetails>
                                        </div>
                                    </Accordion>
                                </div>
                                <div className="row bg-white mt-2" style={{ borderRadius: '4px', padding: '20px',paddingTop:'0px'  }}>
                                    <Accordion
                                        style={{ boxShadow: "none", padding: '0px' }}
                                        defaultExpanded={true}
                                    >
                                        <AccordionSummary
                                            className=""
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingLeft:'0px',paddingRight:'0px'  }}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                Tags
                                            </Typography>
                                        </AccordionSummary>
                                        <TagsInput />
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-8">
                                {userInformationTeamData && userInformationTeamData.some(
                                    (user) => user.subscription_details.plan === "Team"
                                ) ?
                                    <div className='row bg-white mb-2' style={{ padding: '20px' , marginLeft: '0', borderRadius: '4px',paddingTop:'0px' }}>
                                        <Accordion
                                            style={{ boxShadow: "none", padding: '0px' }}
                                            defaultExpanded={true}
                                        >
                                            <AccordionSummary
                                                className=""
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingRight:'0px',paddingLeft:'0px' }}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                    Point of Contact
                                                </Typography>

                                            </AccordionSummary>
                                            <AccordionDetails style={{padding:'0px',paddingTop:'8px'}}>
                                                <div style={{ display: 'flex', gap: '40px' }}>
                                                    {companydata.primary_billing.map((billingUser, index) => (
                                                        <div className="profile-info_OTC_Admin" key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className="user-info-avatar-container_OTC_Admin">
                                                                {billingUser?.avatar !== "" ? (
                                                                    <img
                                                                        src={`${url}/profile/img/${billingUser?.avatar}`}
                                                                        alt="Profile"
                                                                        style={{
                                                                            width: "54px",
                                                                            height: "54px",
                                                                            borderRadius: "100%",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div
                                                                        className="user-info-initials-overlay_OTC_Admin"
                                                                        style={{ width: "54px", height: "54px", position: 'relative' }}
                                                                    >
                                                                        {billingUser.first_name.charAt(0).toUpperCase()}{billingUser.last_name.charAt(0).toUpperCase()}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="details_OTC_Admin">
                                                                <p style={{ textTransform: "capitalize", fontSize: '16px', fontWeight: '600', margin: '0px' }}>
                                                                    {capitalizeWords(billingUser.first_name)} {capitalizeWords(billingUser.last_name)}
                                                                </p>
                                                                <p className="" style={{ fontSize: '13px', margin: '0px' }}>
                                                                    {capitalizeWords(billingUser.designation[0])}
                                                                </p>
                                                                <p className="column1_text_OTC_Admin" style={{ fontSize: '12px', margin: '0px' }}>
                                                                    {capitalizeWords(billingUser.role)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div> : ""}
                                <div className='row bg-white mb-2' style={{ padding: '20px', marginLeft: '0', borderRadius: '4px',paddingTop:'0px'  }}>
                                    <Accordion
                                        style={{ boxShadow: "none", padding: '0px' }}
                                        defaultExpanded={true}
                                    >
                                        <AccordionSummary
                                            className=""
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingLeft:'0px',paddingRight:'0px' }}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                Account Setup statuses (this will include add ons the client purchased)
                                            </Typography>

                                        </AccordionSummary>
                                        <AccordionDetails style={{paddingLeft:'0px',paddingRight:'0px'}}>
                                            <table style={{ border: 'none', width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '10px', textAlign: 'left', width: '40%',paddingLeft:'0px' }}>To do</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px'}}>Assigned to</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px'}}>Status</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px'}}></th>
                                                        {/* <th style={{ padding: '10px', textAlign: 'center' }}>Fulfillment</th>
                                                        <th style={{ padding: '10px', textAlign: 'center' }}>Add ons</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ backgroundColor: '#f2f2f2', borderRadius: '12px' }}>
                                                        <td style={{ padding: '10px', textAlign: 'left', fontWeight: '600' }}>
                                                            {/* {TopAvatar !== "" ? <img src={TopAvatar} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} /> : <img src={Noimagefound} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} />} */}
                                                            <img src={Noimagefound} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} />
                                                            <span style={{ marginLeft: '10px' }}>Fulfillment</span>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>
                                                            <select style={{ backgroundColor: '#FFF', color: '#000', border: '1px solid #FFF', padding: '10px', borderRadius: '5px', width: '150px' }}>
                                                                <option value="Pending">
                                                                    Processing
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>
                                                            <select style={{ backgroundColor: '#FFF', color: '#000', border: '1px solid #FFF', padding: '10px', borderRadius: '5px', width: '150px' }}>
                                                                <option value="Pending">
                                                                    Processing
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}> <img
                                                            src={cross}
                                                            alt="OTC"
                                                            // onClick={handleClick}
                                                            className="checkBox"
                                                        /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style={{ border: 'none', width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '10px', textAlign: 'left', width: '40%',paddingLeft:'0px' }}>Completed</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px' }}>Assigned to</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px' }}>Status</th>
                                                        <th style={{ padding: '10px', textAlign: 'center',paddingLeft:'0px' }}></th>
                                                        {/* <th style={{ padding: '10px', textAlign: 'center' }}>Fulfillment</th>
                                                        <th style={{ padding: '10px', textAlign: 'center' }}>Add ons</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ backgroundColor: '#f2f2f2', borderRadius: '12px' }}>
                                                        <td style={{ padding: '10px', textAlign: 'left', fontWeight: '600' }}>
                                                            {/* {TopAvatar !== "" ? <img src={TopAvatar} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} /> : <img src={Noimagefound} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} />} */} <img src={Noimagefound} style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                borderRadius: "4%",
                                                            }} />
                                                            <span style={{ marginLeft: '10px' }}>Template design</span>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>
                                                            <select style={{ backgroundColor: '#FFF', color: '#000', border: '1px solid #FFF', padding: '10px', borderRadius: '5px', width: '150px' }}>
                                                                <option value="Pending">
                                                                    Processing
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>
                                                            <select style={{ backgroundColor: '#FFF', color: '#000', border: '1px solid #FFF', padding: '10px', borderRadius: '5px', width: '150px' }}>
                                                                <option value="Pending">
                                                                    Processing
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}> <img
                                                            src={selected}
                                                            alt="OTC"
                                                            // onClick={handleClick}
                                                            className="checkBox"
                                                        /></td> </tr>
                                                </tbody>
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='row bg-white mb-2' style={{ padding: '20px', marginLeft: '0', borderRadius: '4px',paddingTop:'0px'  }}>
                                    <Accordion
                                        style={{ boxShadow: "none", padding: '0px' }}
                                        defaultExpanded={true}
                                    >
                                        <AccordionSummary
                                            className=""
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingLeft:'0px',paddingRight:'0px' }}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                Analytics
                                            </Typography>

                                        </AccordionSummary>
                                        <AccordionDetails style={{padding:'0px' }}>
                                            <div className=" mt-3">
                                                <div className="" style={{ margin: '30px auto 20px', width: '100%' }}>
                                                    <div className="" style={{ display: 'flex', justifyContent: 'space-between', margin: "auto", width: '100%' }}>
                                                        <div className="onetap_connect_user_analytic_card_synr_Otc_Admin" style={{ backgroundColor: '#2572E6', color: "#FFFFFF" }}>
                                                            <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 6C12.9283 6 13.8185 6.36875 14.4749 7.02513C15.1313 7.6815 15.5 8.57174 15.5 9.5C15.5 10.4283 15.1313 11.3185 14.4749 11.9749C13.8185 12.6313 12.9283 13 12 13C11.0717 13 10.1815 12.6313 9.52513 11.9749C8.86875 11.3185 8.5 10.4283 8.5 9.5C8.5 8.57174 8.86875 7.6815 9.52513 7.02513C10.1815 6.36875 11.0717 6 12 6ZM5 8.5C5.56 8.5 6.08 8.65 6.53 8.92C6.38 10.35 6.8 11.77 7.66 12.88C7.16 13.84 6.16 14.5 5 14.5C4.20435 14.5 3.44129 14.1839 2.87868 13.6213C2.31607 13.0587 2 12.2956 2 11.5C2 10.7044 2.31607 9.94129 2.87868 9.37868C3.44129 8.81607 4.20435 8.5 5 8.5ZM19 8.5C19.7956 8.5 20.5587 8.81607 21.1213 9.37868C21.6839 9.94129 22 10.7044 22 11.5C22 12.2956 21.6839 13.0587 21.1213 13.6213C20.5587 14.1839 19.7956 14.5 19 14.5C17.84 14.5 16.84 13.84 16.34 12.88C17.2119 11.7544 17.6166 10.3362 17.47 8.92C17.92 8.65 18.44 8.5 19 8.5ZM5.5 18.75C5.5 16.68 8.41 15 12 15C15.59 15 18.5 16.68 18.5 18.75V20.5H5.5V18.75ZM0 20.5V19C0 17.61 1.89 16.44 4.45 16.1C3.86 16.78 3.5 17.72 3.5 18.75V20.5H0ZM24 20.5H20.5V18.75C20.5 17.72 20.14 16.78 19.55 16.1C22.11 16.44 24 17.61 24 19V20.5Z" fill="white" />
                                                            </svg>

                                                            <p style={{ color: "#FFFFFF", padding: '6px 0px', margin: '0px' }}>Active Users</p>
                                                            <p style={{ color: "#FFFFFF", fontWeight: '700' }}>{totalactiveusers} /{total_user}</p>
                                                        </div>
                                                        <div className="onetap_connect_user_analytic_card_synr_Otc_Admin" style={{ backgroundColor: '#48AF25', color: "#FFFFFF" }}>
                                                            <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 6C12.9283 6 13.8185 6.36875 14.4749 7.02513C15.1313 7.6815 15.5 8.57174 15.5 9.5C15.5 10.4283 15.1313 11.3185 14.4749 11.9749C13.8185 12.6313 12.9283 13 12 13C11.0717 13 10.1815 12.6313 9.52513 11.9749C8.86875 11.3185 8.5 10.4283 8.5 9.5C8.5 8.57174 8.86875 7.6815 9.52513 7.02513C10.1815 6.36875 11.0717 6 12 6ZM5 8.5C5.56 8.5 6.08 8.65 6.53 8.92C6.38 10.35 6.8 11.77 7.66 12.88C7.16 13.84 6.16 14.5 5 14.5C4.20435 14.5 3.44129 14.1839 2.87868 13.6213C2.31607 13.0587 2 12.2956 2 11.5C2 10.7044 2.31607 9.94129 2.87868 9.37868C3.44129 8.81607 4.20435 8.5 5 8.5ZM19 8.5C19.7956 8.5 20.5587 8.81607 21.1213 9.37868C21.6839 9.94129 22 10.7044 22 11.5C22 12.2956 21.6839 13.0587 21.1213 13.6213C20.5587 14.1839 19.7956 14.5 19 14.5C17.84 14.5 16.84 13.84 16.34 12.88C17.2119 11.7544 17.6166 10.3362 17.47 8.92C17.92 8.65 18.44 8.5 19 8.5ZM5.5 18.75C5.5 16.68 8.41 15 12 15C15.59 15 18.5 16.68 18.5 18.75V20.5H5.5V18.75ZM0 20.5V19C0 17.61 1.89 16.44 4.45 16.1C3.86 16.78 3.5 17.72 3.5 18.75V20.5H0ZM24 20.5H20.5V18.75C20.5 17.72 20.14 16.78 19.55 16.1C22.11 16.44 24 17.61 24 19V20.5Z" fill="white" />
                                                            </svg>

                                                            <p style={{ color: "#FFFFFF", padding: '6px 0px', margin: '0px' }}>New active users
                                                                this month</p>
                                                            <p style={{ color: "#FFFFFF", fontWeight: '700' }}>{thismonthtotalactiveusers}</p>
                                                        </div>
                                                        <div className="onetap_connect_user_analytic_card_synr_Otc_Admin">
                                                            <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 6C12.9283 6 13.8185 6.36875 14.4749 7.02513C15.1313 7.6815 15.5 8.57174 15.5 9.5C15.5 10.4283 15.1313 11.3185 14.4749 11.9749C13.8185 12.6313 12.9283 13 12 13C11.0717 13 10.1815 12.6313 9.52513 11.9749C8.86875 11.3185 8.5 10.4283 8.5 9.5C8.5 8.57174 8.86875 7.6815 9.52513 7.02513C10.1815 6.36875 11.0717 6 12 6ZM5 8.5C5.56 8.5 6.08 8.65 6.53 8.92C6.38 10.35 6.8 11.77 7.66 12.88C7.16 13.84 6.16 14.5 5 14.5C4.20435 14.5 3.44129 14.1839 2.87868 13.6213C2.31607 13.0587 2 12.2956 2 11.5C2 10.7044 2.31607 9.94129 2.87868 9.37868C3.44129 8.81607 4.20435 8.5 5 8.5ZM19 8.5C19.7956 8.5 20.5587 8.81607 21.1213 9.37868C21.6839 9.94129 22 10.7044 22 11.5C22 12.2956 21.6839 13.0587 21.1213 13.6213C20.5587 14.1839 19.7956 14.5 19 14.5C17.84 14.5 16.84 13.84 16.34 12.88C17.2119 11.7544 17.6166 10.3362 17.47 8.92C17.92 8.65 18.44 8.5 19 8.5ZM5.5 18.75C5.5 16.68 8.41 15 12 15C15.59 15 18.5 16.68 18.5 18.75V20.5H5.5V18.75ZM0 20.5V19C0 17.61 1.89 16.44 4.45 16.1C3.86 16.78 3.5 17.72 3.5 18.75V20.5H0ZM24 20.5H20.5V18.75C20.5 17.72 20.14 16.78 19.55 16.1C22.11 16.44 24 17.61 24 19V20.5Z" fill="#333333" />
                                                            </svg>
                                                            <p style={{ padding: '6px 0px', margin: '0px' }}>New users
                                                                this month</p>
                                                            <p style={{ fontWeight: '700' }}>{matchingUsersCount}</p>
                                                        </div>
                                                        <div className="onetap_connect_user_analytic_card_synr_Otc_Admin">
                                                            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_2765_27781)">
                                                                    <path d="M1.45521 0.691217C1.06521 0.383717 0.498961 0.454967 0.191461 0.844967C-0.116039 1.23497 -0.0447892 1.80122 0.345211 2.10872L22.5452 19.5087C22.9352 19.8162 23.5015 19.745 23.809 19.355C24.1165 18.965 24.0452 18.3987 23.6552 18.0912L16.5227 12.5H23.2015C23.644 12.5 24.0002 12.14 24.0002 11.7012C24.0002 9.49247 22.2077 7.69997 19.999 7.69997H18.4015C17.8052 7.69997 17.239 7.83122 16.729 8.06372C16.7777 8.33372 16.8002 8.61497 16.8002 8.89997C16.8002 10.0325 16.4065 11.075 15.7502 11.8962L14.8052 11.1575C15.304 10.5387 15.6002 9.75497 15.6002 8.89997C15.6002 6.91247 13.9877 5.29997 12.0002 5.29997C10.834 5.29997 9.79896 5.85497 9.13896 6.71372L7.61646 5.52122C8.10396 4.98872 8.40021 4.27997 8.40021 3.49997C8.40021 1.84247 7.05771 0.499967 5.40021 0.499967C4.36146 0.499967 3.44646 1.02872 2.90646 1.83122L1.45521 0.691217ZM4.00146 7.69997C1.79271 7.69997 0.000210787 9.49247 0.000210787 11.7012C0.000210787 12.14 0.360211 12.5 0.798961 12.5H8.82771C8.05521 11.8175 7.50771 10.895 7.29771 9.84497L4.56771 7.69997H4.00146ZM9.79896 13.7C7.03896 13.7 4.80021 15.9387 4.80021 18.6987C4.80021 19.25 5.24646 19.7 5.80146 19.7H18.199C18.5927 19.7 18.9302 19.475 19.0952 19.145L12.184 13.7H9.79896ZM19.2002 6.49997C19.9959 6.49997 20.7589 6.1839 21.3215 5.62129C21.8841 5.05868 22.2002 4.29562 22.2002 3.49997C22.2002 2.70432 21.8841 1.94126 21.3215 1.37865C20.7589 0.816037 19.9959 0.499967 19.2002 0.499967C18.4046 0.499967 17.6415 0.816037 17.0789 1.37865C16.5163 1.94126 16.2002 2.70432 16.2002 3.49997C16.2002 4.29562 16.5163 5.05868 17.0789 5.62129C17.6415 6.1839 18.4046 6.49997 19.2002 6.49997Z" fill="#333333" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2765_27781">
                                                                        <rect width="24" height="19.2" fill="white" transform="translate(0 0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                            <p style={{ padding: '6px 0px', margin: '0px' }}>Inactive Users</p>
                                                            <p style={{ fontWeight: '700' }}>{totalinactiveusers}</p>
                                                        </div>
                                                        <div className="onetap_connect_user_analytic_card_synr_Otc_Admin">
                                                            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_2765_27781)">
                                                                    <path d="M1.45521 0.691217C1.06521 0.383717 0.498961 0.454967 0.191461 0.844967C-0.116039 1.23497 -0.0447892 1.80122 0.345211 2.10872L22.5452 19.5087C22.9352 19.8162 23.5015 19.745 23.809 19.355C24.1165 18.965 24.0452 18.3987 23.6552 18.0912L16.5227 12.5H23.2015C23.644 12.5 24.0002 12.14 24.0002 11.7012C24.0002 9.49247 22.2077 7.69997 19.999 7.69997H18.4015C17.8052 7.69997 17.239 7.83122 16.729 8.06372C16.7777 8.33372 16.8002 8.61497 16.8002 8.89997C16.8002 10.0325 16.4065 11.075 15.7502 11.8962L14.8052 11.1575C15.304 10.5387 15.6002 9.75497 15.6002 8.89997C15.6002 6.91247 13.9877 5.29997 12.0002 5.29997C10.834 5.29997 9.79896 5.85497 9.13896 6.71372L7.61646 5.52122C8.10396 4.98872 8.40021 4.27997 8.40021 3.49997C8.40021 1.84247 7.05771 0.499967 5.40021 0.499967C4.36146 0.499967 3.44646 1.02872 2.90646 1.83122L1.45521 0.691217ZM4.00146 7.69997C1.79271 7.69997 0.000210787 9.49247 0.000210787 11.7012C0.000210787 12.14 0.360211 12.5 0.798961 12.5H8.82771C8.05521 11.8175 7.50771 10.895 7.29771 9.84497L4.56771 7.69997H4.00146ZM9.79896 13.7C7.03896 13.7 4.80021 15.9387 4.80021 18.6987C4.80021 19.25 5.24646 19.7 5.80146 19.7H18.199C18.5927 19.7 18.9302 19.475 19.0952 19.145L12.184 13.7H9.79896ZM19.2002 6.49997C19.9959 6.49997 20.7589 6.1839 21.3215 5.62129C21.8841 5.05868 22.2002 4.29562 22.2002 3.49997C22.2002 2.70432 21.8841 1.94126 21.3215 1.37865C20.7589 0.816037 19.9959 0.499967 19.2002 0.499967C18.4046 0.499967 17.6415 0.816037 17.0789 1.37865C16.5163 1.94126 16.2002 2.70432 16.2002 3.49997C16.2002 4.29562 16.5163 5.05868 17.0789 5.62129C17.6415 6.1839 18.4046 6.49997 19.2002 6.49997Z" fill="#333333" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_2765_27781">
                                                                        <rect width="24" height="19.2" fill="white" transform="translate(0 0.5)" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>


                                                            <p style={{ padding: '6px 0px', margin: '0px' }}>Deactivated this month</p>
                                                            <p style={{ fontWeight: '700' }}>{thismonthtotalinactiveusers}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                {
                                    userInformationTeamData && userInformationTeamData.some(
                                        (user) => user.subscription_details.plan === "Team"
                                    ) && <div className='row bg-white mb-2' style={{ padding: '20px', marginLeft: '0', borderRadius: '4px' ,paddingTop:'0px' }}>
                                        <Accordion
                                            style={{ boxShadow: "none", padding: '0px' }}
                                            // expanded={viewAllbtn}  // Use the separate state for the expanded state
                                            onChange={(event, expanded) => setViewAllbtn(expanded)}
                                            defaultExpanded={true}
                                        >
                                            <AccordionSummary
                                                className=""
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                style={{ borderBottom: "1px solid #F2F2F2", minHeight: "70px",paddingLeft:'0px',paddingRight:'0px' }}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography className="onetap_connect_free_user_accordian_heading_OTC_Admin" style={{ flexShrink: 0 }} >
                                                    Recent order
                                                    {
                                                        viewAllbtn && <span style={{ marginLeft: '30px', color: '#4154E9' }} onClick={(event) => {
                                                            // Stop event propagation to prevent the accordion from opening/closing
                                                            event.stopPropagation();
                                                            setViewAllorder(!viewAllorder);
                                                        }}> {viewAllorder ? "View Recent" : "View All"}</span>
                                                    }

                                                </Typography>


                                            </AccordionSummary>
                                            <AccordionDetails style={{paddingLeft:'0px',paddingRight:'0px' }}>
                                                <table style={{ border: 'none', width: '100%', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'left' }}>Orders #</th>
                                                            <th style={{ padding: '10px', textAlign: 'center' }}>Status</th>
                                                            <th style={{ padding: '10px', textAlign: 'center' }}>Payment Date</th>
                                                            <th style={{ padding: '10px', textAlign: 'center' }}>Total</th>
                                                            <th style={{ padding: '10px', textAlign: 'center' }}>Fulfillment</th>
                                                            <th style={{ padding: '10px', textAlign: 'center' }}>Add ons</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {userOrderstatedata && recentOrder.map((order) => ( */}
                                                        {!viewAllorder ?
                                                            <tr key={recentOrder?.id} style={{ backgroundColor: '#f2f2f2', borderRadius: '12px' }}>
                                                                <td style={{ padding: '10px', textAlign: 'left', fontWeight: '600' }}>{recentOrder?.orderNumber}</td>
                                                                <td style={{ padding: '10px', textAlign: 'center' }}>
                                                                    <p style={{ backgroundColor: '#F0FEEB', color: '#48AF25', border: '1px solid #48AF25', padding: '10px', borderRadius: '5px', width: '150px', display: 'inline-block', margin: '0px' }}>
                                                                        {recentOrder?.status}
                                                                    </p>
                                                                </td>
                                                                <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>{formatDate(recentOrder?.paymentDate)}</td>
                                                                <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>{recentOrder?.totalAmount
                                                                    ? "$" + recentOrder?.totalAmount : "$" + recentOrder?.subscription_details?.total_amount}</td>
                                                                <td style={{ padding: '10px', textAlign: 'center', color: '#E65925', fontWeight: '600' }}>{recentOrder?.fulfillment}</td>
                                                                <td style={{ padding: '10px', textAlign: 'center', color: '#2572E6', fontWeight: '600' }}>{(recentOrder?.type === "UserPurchase" || recentOrder?.type === "smartAccessories") ? "N/A" : "-" }</td>
                                                            </tr> : <>
                                                                {userOrderstatedata?.map((valueoforder, index) => (
                                                                    <tr key={valueoforder?.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white', borderRadius: '12px' }}>
                                                                        <td style={{ padding: '10px', textAlign: 'left', fontWeight: '600' }}>{valueoforder?.orderNumber}</td>
                                                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                                                            <p style={{ backgroundColor: '#F0FEEB', color: '#48AF25', border: '1px solid #48AF25', padding: '10px', borderRadius: '5px', width: '150px', display: 'inline-block', margin: '0px' }}>
                                                                                {valueoforder.status}
                                                                            </p>
                                                                        </td>
                                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>{formatDate(valueoforder?.paymentDate)}</td>
                                                                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: '600' }}>{valueoforder?.totalAmount
                                                                            ? "$" + valueoforder?.totalAmount : "$" + valueoforder?.subscription_details?.total_amount}</td>
                                                                        <td style={{ padding: '10px', textAlign: 'center', color: '#E65925', fontWeight: '600' }}>{valueoforder?.fulfillment}</td>
                                                                        <td style={{ padding: '10px', textAlign: 'center', color: '#2572E6', fontWeight: '600' }}>{(valueoforder?.type === "UserPurchase" || valueoforder?.type === "smartAccessories") ? "N/A" : "-" }</td>
                                                                    </tr>
                                                                ))}</>
                                                        }
                                                        {/* ))} */}
                                                    </tbody>
                                                </table>
                                            </AccordionDetails>
                                        </Accordion>

                                    </div>
                                }

                                {/* <div className='row bg-white mb-2' style={{ padding: '20px', marginLeft: '0', borderRadius: '4px' }}>
                                    <h5 style={{ padding: '0px' }}>Activity Log</h5>
                                    <textarea className="w-100 border-0 p-2" type="text" style={{ borderRadius: " 4px", background: "#f2f2f2" }} name="activityLog"
                                    />
                                </div> */}
                            </div>
                        </div>
                    }
                </>
            )
            }
               <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: "center" }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
            {messageIcon}
              </div>
            </div>
            <span className="team_created_success">
            {successMessage}
            </span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              width: "13%",
            }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
            </span>
          </button>
        </Box>
      </Modal>
        </>
    )
}

export default ClientProfileInformation