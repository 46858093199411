import React, { useEffect, useRef, useState } from "react";
import "../OTC_Addnewadmin/OTC_Addnewadmin.css";
import { CRow, CCol } from "@coreui/react";
import { Box, Button, Modal } from "@mui/material";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import Tooltip from "@mui/material/Tooltip";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import axios from "axios";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OtcUploadProfilePicture } from "../Redux/Otc_UserActions";
import Loader from "../../components/Layout/Loader/Loader";
import { getTeamsofOtcAdmin } from "../Redux/Otc_UserActions";
const OTC_adminusers_edit_info = () => {
  //add Job Title
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "background.paper",
    p: 2,
    outline: "none",
    borderRadius: "10px",
  };

  const cropingstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    backgroundColor: "white", // Changed bgcolor to backgroundColor
    padding: "16px", // Added padding for better appearance
    outline: "none",
    borderRadius: "10px",
  };

  //get data using params from OTC_Adminusersinfo
  const { otcadminteams } = useSelector((state) => state.otcadminteams);
  const [userData, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitles, setJobTitles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [teamMemberDetails, setTeamMemberDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    officenumber: '',
    jobTitles: '',
    userRole: '',
    avatar: '',
    team:'',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setcroppedImage] = useState(null);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [selectedDp, setSelectedDp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const fetchAdminUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/admin/otc_adminusers`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log(response.data?.allUsers)
      setData(response.data?.allUsers?.find((user) => user._id === id))
      setLoading(false)
    }
    catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {
    fetchAdminUsers();
  }, [id]);

  useEffect(() => {
    setTeamMemberDetails({
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
      officenumber: userData?.officenumber || '',
      jobTitles: userData?.jobTitles || '',
      userRole: userData?.userRole || '',
      avatar: userData?.avatar || '',
      team:userData?.team?._id || '',
    });
    setJobTitles(userData?.jobTitles)
  }, [userData]);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
  });
  
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);

  const allowedImageTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg+xml",
    "image/jpg",
    "image/avif",
  ];

  useEffect(() => {
    if (openCropModal && selectedDp) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          cropperRef.current.src = e.target.result;

          // Initialize Cropper.js here
          const cropper = new Cropper(cropperRef.current, {
            dragMode: "move",
            aspectRatio: 1 / 1,
            viewMode: 1,
          });

          // Store the Cropper instance in the ref
          cropperRef.current.cropper = cropper;
        };
      };
      // setIsCropping(true);
      reader.readAsDataURL(selectedDp);
      setSelectedFile(selectedDp);
      // setlogoerrormsj(selectedlogo);
    }
  }, [openCropModal, selectedDp]);

  const handleUploaderClick = () => {
    // Trigger the file input click event

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the selected file type is not in the allowed types
      if (!allowedImageTypes.includes(file.type)) {
        alert(
          "The selected file type is not supported.\nPlease use PNG, JPG, SVG format."
        );
        return; // Return early if the file type is not allowed
      }

      setSelectedDp(file);
      setOpenCropModal(true);
    }
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();

      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = croppedCanvas.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      const cropper = cropperRef.current.cropper;

      await cropper.destroy();
      setcroppedImage(croppedImage);
      setOpenCropModal(false);
    }
  };

  const handlezoominlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1); // Clear the cropping area
    }
  };
  const handleswapupdown = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleY = cropper.getData().scaleY; // Get the current X scaling factor
      cropper.scaleY(-scaleY); // Toggle the X scaling factor
    }
  };
  const handlecropmodalclose = () => {
    setcroppedImage(null);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setOpenCropModal(false);
  };

  const handleuploadorignal = () => {
    if (cropperRef.current) {
      const getorignalimages = cropperRef.current.cropper.reset(); // Clear the cropping area
      const getorignal = getorignalimages.clear(); // Clear the cropping area
      const getorignalimage = getorignal.getCroppedCanvas();
      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = getorignalimage.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      setcroppedImage(croppedImage);
      setOpenCropModal(false);
    }
  };
  const handleRemoveImage = () => {
    setSelectedDp(null);
    setSelectedFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleswaprightleft = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleX = cropper.getData().scaleX; // Get the current X scaling factor
      cropper.scaleX(-scaleX); // Toggle the X scaling factor
    }
  };
  const handlezoomoutlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1); // Clear the cropping area
    }
  };
  const handlerotate = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(-90); // Clear the cropping area
    }
  };
  const handlerotateback = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(90); // Clear the cropping area
    }
  };
  const handlelogoup = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, -10); // Clear the cropping area
    }
  };
  const handlelogodown = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, 10); // Clear the cropping area
    }
  };
  const handlelogoleft = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(-10, 0); // Clear the cropping area
    }
  };
  const handlelogoright = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(10, 0); // Clear the cropping area
    }
  };
  const handleresetlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset(); // Clear the cropping area
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamMemberDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error message when the user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
    setModalErrorMessage("");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddJobTitle = () => {
    if (jobTitles.length >= 6) {
      setModalErrorMessage("Maximum six job titles are allowed");
      return; // Exit the function to prevent adding more than 6 titles
    }

    if (jobTitle.trim() === "") {
      setModalErrorMessage("Job title cannot be empty");
      return; // Exit the function to prevent adding an empty title
    }

    // Check if the entered job title already exists (case-insensitive)
    const lowerCaseJobTitle = jobTitle.toLowerCase();
    if (jobTitles.some((title) => title.toLowerCase() === lowerCaseJobTitle)) {
      setModalErrorMessage("Job title already in use. Please select another.");
      return; // Exit the function to prevent adding duplicate titles
    }

    if (jobTitles.length < 6 && jobTitle.trim() !== "") {
      setJobTitles((prevTitles) => [...prevTitles, jobTitle]);
      setErrorMessages((prevErrors) => [...prevErrors, ""]); // Add an empty error message for the new job title
      setJobTitle(""); // Clear the input field after adding a job title
    }
    closeModal();
  };

  const handleEditJobTitle = (index, newValue) => {
    // Check if the edited job title is the same as the existing one
    if (jobTitles[index] === newValue) {
      setErrorMessages((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Job title unchanged";
        return newErrors;
      });
      return; // Exit the function without updating the title
    }

    // Check if the edited job title already exists (case-insensitive)
    const lowerCaseNewValue = newValue.toLowerCase();
    if (
      jobTitles.some(
        (title, i) => i !== index && title.toLowerCase() === lowerCaseNewValue
      )
    ) {
      setErrorMessages((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = "Job title already in use. Please select another.";
        return newErrors;
      });
      return; // Exit the function to prevent adding duplicate titles
    }

    // Update the job title at the specified index
    const updatedJobTitles = [...jobTitles];
    updatedJobTitles[index] = newValue;
    setJobTitles(updatedJobTitles);
    setErrorMessages((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = ""; // Clear the error message when successfully updating the title
      return newErrors;
    });
  };

  const handleDeleteJobTitle = (index) => {
    // Remove the job title and its corresponding error message at the specified index
    const updatedJobTitles = [...jobTitles];
    const updatedErrorMessages = [...errorMessages];
    updatedJobTitles.splice(index, 1);
    updatedErrorMessages.splice(index, 1);
    setJobTitles(updatedJobTitles);
    setErrorMessages(updatedErrorMessages);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    // Validate required fields
    const requiredFields = ["firstName", "lastName", "email", "userRole"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!teamMemberDetails[field]) {
        const formattedFieldName = field.replace(/([A-Z])/g, ' $1').toLowerCase();
        newErrors[field] = `${formattedFieldName.charAt(0).toUpperCase() + formattedFieldName.slice(1)} is required.`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      // Display error messages and prevent form submission
      setErrors(newErrors);
      return;
    }
    // Create a copy of the teamMemberDetails with trimmed designations
    let updatedTeamMemberDetails = {
      ...teamMemberDetails,
    };
    if (selectedFile && croppedImage) {
      const formData = new FormData();
      formData.append("image", croppedImage);
      formData.append("imageType", "adminprofile");
      const response = await dispatch(OtcUploadProfilePicture(id, formData));
      setSelectedFile(null);
      if (response.error) {
        // Handle other errors
        ;
      } else if (response.success) {
        const avtarpath = response.imagePath;
        updatedTeamMemberDetails = {
          ...updatedTeamMemberDetails,
          avatar: avtarpath,
        };

      }
    }

    try {
      setLoading(true);
      // Send the form data and job titles array to your server
      const responsedata = await axios.post(
        `${url}/admin/otc_updateAdminUser/${id}`,
        {
          ...updatedTeamMemberDetails,
          jobTitles: jobTitles,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (responsedata.status === 200) {
        setLoading(false);
        setTeamMemberDetails(updatedTeamMemberDetails);
      }

    } catch (error) {
      ;
      setLoading(false);
    }
  };

  //get all teams
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    if (!otcadminteams) {
      dispatch(getTeamsofOtcAdmin())
    }
    else { setTeams(otcadminteams) }
  }, [otcadminteams]);

  const handleDeleteClick = () => {
    // Ensure there is a selected image to delete
    if (teamMemberDetails?.avatar) {


      // Make a DELETE request to the server to delete the image
      axios.delete(`${url}/admin/imagedelete/${teamMemberDetails?.avatar}`, { withCredentials: true })
        .then((response) => {

          setTeamMemberDetails((prevDetails) => ({
            ...prevDetails,
            avatar: "", // Set the avatar to an empty string
          }));
          // Add any additional logic you need after successful deletion
        })
        .catch((error) => {
          // Handle error as needed
        });
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              background: "white",
              padding: "25px 30px",
              borderRadius: "8px",
            }}
          >
            <CRow>
              <CCol xs={12}>
                <span
                  style={{
                    fontSize: "24px",
                    lineHeight: "31.39px",
                    fontWeight: "600",
                  }}
                >
                  General Information
                </span>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#2B2B2B",
                    borderBottom: "1px solid #F2F2F2",
                    padding: "14px 0px",
                  }}
                >
                  The below information are synchronized and displayed on the
                  user OneTapConnect card. Any update will immediately be
                  reflected on the user card.
                </p>
              </CCol>
            </CRow>
          </div>

          <div className="d-flex otc_admin_create_edit_general_info gap-3 mt-2">
            <div
              className="w-50 otc_admin_create_edit_contact_info"
              style={{ background: "white", borderRadius: "8px" }}
            >
              <div
                style={{ background: "white", padding: "25px 30px" }}
                className="rounded"
              >
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "26.16px",
                    fontWeight: "600",
                  }}
                >
                  Contact Information
                </span>

                {/* Contact information Form  */}
                <form onSubmit={handleSubmit}>
                  <div className="otc_member_contact_form_info py-4">
                    <label htmlFor="firstName">First Name</label>
                    <br />
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={teamMemberDetails?.firstName}
                      onChange={handleChange}
                      style={{
                        background: "#F2F2F2",
                        border: "none",
                        fontSize: "15px",
                      }}
                    />
                    <p style={{ color: "red" }}>{errors.firstName}</p>

                    <label htmlFor="lastName">Last Name</label>
                    <br />
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={teamMemberDetails?.lastName}
                      onChange={handleChange}
                      style={{ background: "#F2F2F2", border: "none" }}
                    />
                    <p style={{ color: "red" }}>{errors.lastName}</p>

                    <label htmlFor="email">Email</label>
                    <br />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={teamMemberDetails?.email}
                      onChange={handleChange}
                      style={{ background: "#F2F2F2", border: "none" }}
                    />
                    <p style={{ color: "red" }}>{errors.email}</p>

                    <label htmlFor="cellPhone">Cell Phone Number</label>

                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={teamMemberDetails?.phoneNumber}
                      onChange={handleChange}
                      style={{ background: "#F2F2F2", border: "none" }}
                    />
                    <p></p>

                    <label htmlFor="officeNumber">Office Number</label>
                    <br />
                    <input
                      type="tel"
                      id="officenumber"
                      name="officenumber"
                      value={teamMemberDetails?.officenumber}
                      onChange={handleChange}
                      style={{ background: "#F2F2F2", border: "none" }}
                    />
                  </div>
                </form>

                {/* Job Title */}

                <div className="otc_member_contact_form_info_job mt-3">
                  <span
                    style={{
                      fontSize: "20px",
                      lineHeight: "26.16px",
                      fontWeight: "600",
                    }}
                  >
                    Job Title
                  </span>

                  {jobTitles && (
                    <div className="mt-3">
                      <div
                        className="otc_member_add_job_title"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        {jobTitles.map((title, index) => (
                          <div
                            key={index}
                            className="mb-0"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <input
                              type="text"
                              value={title}
                              onChange={(e) =>
                                handleEditJobTitle(index, e.target.value)
                              }
                              style={{
                                background: "#F2F2F2",
                                border: "none",
                                width: "100%",
                              }}
                              required
                              autoComplete="off"
                            />

                            <div className="d-flex align-items-center justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "10px",
                                }}
                                onClick={() => handleDeleteJobTitle(index)}
                              >
                                <path
                                  d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                  fill="#8A8A8A"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <button
                    type="submit"
                    onClick={openModal}
                    style={{
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "10px",
                      padding: "4px 16px",
                      marginTop: "10px",
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 7.99805H8V12.998C8 13.2633 7.89464 13.5176 7.70711 13.7052C7.51957 13.8927 7.26522 13.998 7 13.998C6.73478 13.998 6.48043 13.8927 6.29289 13.7052C6.10536 13.5176 6 13.2633 6 12.998V7.99805H1C0.734784 7.99805 0.48043 7.89269 0.292893 7.70515C0.105357 7.51762 0 7.26326 0 6.99805C0 6.73283 0.105357 6.47848 0.292893 6.29094C0.48043 6.1034 0.734784 5.99805 1 5.99805H6V0.998047C6 0.73283 6.10536 0.478476 6.29289 0.29094C6.48043 0.103403 6.73478 -0.00195313 7 -0.00195312C7.26522 -0.00195313 7.51957 0.103403 7.70711 0.29094C7.89464 0.478476 8 0.73283 8 0.998047V5.99805H13C13.2652 5.99805 13.5196 6.1034 13.7071 6.29094C13.8946 6.47848 14 6.73283 14 6.99805C14 7.26326 13.8946 7.51762 13.7071 7.70515C13.5196 7.89269 13.2652 7.99805 13 7.99805Z"
                        fill="white"
                      />
                    </svg>
                    <span>Add another</span>
                  </button>
                </div>
              </div>
            </div>
            <Modal
              open={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
                setModalErrorMessage("");
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="Modal-section">
                  <h2 className="header" style={{ marginBottom: "1rem" }}>
                    Add Title
                  </h2>
                  <input
                    type="text"
                    name="teamName"
                    className="input-field m-0"
                    placeholder="Ex: Marketing"
                    value={jobTitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                      setModalErrorMessage("");
                    }}
                    autoComplete="off"
                  />
                  <p id="err" className="error_message mt-1 mb-0">
                    {modalErrorMessage}
                  </p>
                  <div className="Modal-btn-group">
                    <button
                      className="Modal-btn first-modal-btn"
                      onClick={handleAddJobTitle}
                    >
                      Add
                    </button>
                    <button
                      className="Modal-btn sec-modal-btn"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>

            <div className="w-50 otc_admin_create_edit_team_info">
              {/* Team & User type */}
              <div
                style={{
                  background: "white",
                  padding: "25px 30px",
                  borderRadius: "8px",
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    lineHeight: "26.16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                    display: "inline-block",
                  }}
                >
                  Team & User type
                </span>

                <div
                  style={{
                    borderTop: "1px solid #F2F2F2",
                    padding: "10px 0px 30px 0px",
                  }}
                  className="pt-4  gap-3"
                >
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#333333",
                      display: "inline-block",
                    }}
                    className="mt-2"
                  >
                    Team
                  </span>
                  <div className="onetap_conn_filter_search_OTC_Admin">
                    <form method="post">
                      <select
                        name="team"
                        id="team"
                        style={{
                          appearance: "none",
                          outline: "none",
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                        value={teamMemberDetails?.team}
                        onChange={handleChange}
                      >
                         <option Selected value="">
                      select team
                    </option>
                        {teams.map((team) => (
                          <option key={team._id} value={team._id}>
                            {team.team_name}
                          </option>
                        ))}
                      </select>
                    </form>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="22"
                      viewBox="0 0 23 22"
                      fill="none"
                      className="onetap_conn_filter_svg_OTC_Admin"
                    >
                      <path
                        d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#333333",
                      display: "inline-block",
                    }}
                    className="mt-2"
                  >
                    User role
                  </span>
                  <div className="onetap_conn_filter_search_OTC_Admin">
                    <form onSubmit={handleSubmit}>
                      <select
                        name="userRole"
                        id="userRole"
                        value={teamMemberDetails?.userRole}
                        onChange={handleChange}
                        style={{
                          appearance: "none",
                          outline: "none",
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        <option Selected value="">
                          User Role
                        </option>
                        <option value="SuperAdmin">SuperAdmin</option>
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="Designer">Designer</option>
                      </select>
                    </form>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="22"
                      viewBox="0 0 23 22"
                      fill="none"
                      className="onetap_conn_filter_svg_OTC_Admin"
                    >
                      <path
                        d="M11.5003 13.75L6.91699 9.16663H16.0837L11.5003 13.75Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                  <p style={{ color: "red", marginTop: "5px" }}>
                    {errors.userRole}
                  </p>
                </div>
              </div>

              <div>
                <div
                  className="mt-3"
                  style={{
                    background: "white",
                    padding: "25px 30px",
                    borderRadius: "8px",
                  }}
                >
                  <CRow>
                    <CCol xs={12}>
                      <span
                        style={{
                          fontSize: "20px",
                          lineHeight: "27.47px",
                          fontWeight: "600",
                        }}
                      >
                        Profile picture
                      </span>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#333333",
                          borderBottom: "1px solid #F2F2F2",
                          padding: "1px 0px",
                        }}
                      >
                        Upload a high quality picture of yourself to show on
                        your OneTapConnect card.
                      </p>
                    </CCol>
                  </CRow>
                  <div className="d-flex justify-content-center">
                    <div className="image-uploader">
                      {!selectedFile ? (
                        <label
                          htmlFor="upload-input"
                          className="upload-box"
                          onClick={handleUploaderClick}
                        >
                          <div className="image-preview">
                            {teamMemberDetails?.avatar ? (
                              <img
                                src={`${url}/adminprofile/img/${teamMemberDetails?.avatar}`}
                                alt="Default"
                                style={{ borderRadius: "none", width: "90%" }}
                              />
                            ) : (
                              <span className="upload-icon">
                                <svg
                                  width="46"
                                  height="34"
                                  viewBox="0 0 46 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                    fill="#838383"
                                  />
                                </svg>{" "}
                                <br />
                                <p className="onetap_conn_general_information_para_syn m-0">
                                  Upload
                                </p>
                              </span>
                            )}
                          </div>
                        </label>
                      ) : (
                        <>
                          <label
                            htmlFor="upload-input"
                            className="upload-box"
                            onClick={() => {
                              setOpenCropModal(true);
                            }}
                          >
                            <div className="image-preview">
                              {selectedFile && (
                                <img
                                  src={
                                    croppedImage ||
                                    URL.createObjectURL(selectedFile)
                                  }
                                  alt="Uploaded"
                                  style={{ borderRadius: "none", width: "90%" }}
                                />
                              )}{" "}
                            </div>
                          </label>
                          <span
                            className="remove-icon"
                            onClick={handleRemoveImage}
                          >
                            &#10005;
                          </span>{" "}
                        </>
                      )}

                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#2C2C2C",
                      textAlign: "center",
                    }}
                  >
                    Accepted file type: SVG, PNG, JPG
                  </div>
                  {teamMemberDetails?.avatar && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      type="submit"
                      style={{
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "10px",
                        padding: "4px 16px",
                        marginTop: "10px",
                        backgroundColor: '#E65925',
                        color: 'white',
                        border: 'none',
                        marginRight: '10px'
                      }}
                      onClick={handleUploaderClick}
                    >
                      <span>Upload</span>
                    </button>
                    <button
                      type="submit"
                      style={{
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "10px",
                        padding: "4px 16px",
                        marginTop: "10px",
                        backgroundColor: '#E65925',
                        color: 'white',
                        border: 'none',
                        marginLeft: '10px'
                      }}
                      onClick={handleDeleteClick}
                    >
                      <span>Delete</span>
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </div>

          {/* Image Upload Model */}
          <Modal open={openCropModal} onClose={handlecropmodalclose}>
            <div>
              <div style={cropingstyle} className="modal-content">
                {/* Display the selected file */}
                {selectedDp && (
                  <div style={{ width: "100%", height: "80%" }}>
                    <img
                      src={croppedImage}
                      ref={cropperRef}
                      alt="Selected Image"
                      style={{ maxWidth: "50%" }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    margin: "10px 0px",
                  }}
                >
                  <Tooltip title="Flip vertical" arrow>
                    <SwapVertOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handleswapupdown}
                    />
                  </Tooltip>
                  <Tooltip title="Flip horizontal" arrow>
                    <SwapHorizOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handleswaprightleft}
                    />
                  </Tooltip>
                  <Tooltip title="Zoom in" arrow>
                    <ZoomInIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlezoominlogo}
                    />
                  </Tooltip>
                  <Tooltip title="Zoom out" arrow>
                    <ZoomOutIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlezoomoutlogo}
                    />
                  </Tooltip>
                  <Tooltip title="Move Up" arrow>
                    <ArrowUpwardOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlelogoup}
                    />
                  </Tooltip>
                  <Tooltip title="Move down" arrow>
                    <ArrowDownwardOutlined
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlelogodown}
                    />
                  </Tooltip>
                  <Tooltip title="Move left" arrow>
                    <ArrowBackOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlelogoleft}
                    />
                  </Tooltip>
                  <Tooltip title="Move right" arrow>
                    <ArrowForwardOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlelogoright}
                    />
                  </Tooltip>
                  <Tooltip title="Rotate left" arrow>
                    <Rotate90DegreesCcwIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlerotate}
                    />
                  </Tooltip>
                  <Tooltip title="Rotate right" arrow>
                    <Rotate90DegreesCwOutlinedIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handlerotateback}
                    />
                  </Tooltip>
                  <Tooltip title="Reset" arrow>
                    <RestartAltIcon
                      fontSize="large"
                      style={{
                        backgroundColor: "#E65925",
                        color: "#ffff",
                        borderRadius: "4px",
                        padding: "2px",
                        margin: "0px 5px",
                      }}
                      onClick={handleresetlogo}
                    />
                  </Tooltip>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleCrop}
                    style={{
                      color: "#ffff",
                      backgroundColor: "#E65925",
                      width: "50%",
                      margin: "5px",
                    }}
                  >
                    Crop Image
                  </Button>
                  <Button
                    onClick={handleuploadorignal}
                    style={{
                      color: "#ffff",
                      backgroundColor: "#E65925",
                      width: "50%",
                      margin: "5px",
                    }}
                  >
                    Upload
                  </Button>
                  <Button
                    onClick={handlecropmodalclose}
                    style={{
                      color: "#ffff",
                      backgroundColor: "#E65925",
                      width: "50%",
                      margin: "5px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <div className="d-flex justify-content-end mt-4">
            <button
              style={{
                width: "250px",
                height: "53px",
                background: "#081525",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "19px",
                border: "none",
                borderBottom: "1px solid #000000",
              }}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default OTC_adminusers_edit_info;
