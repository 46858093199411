import React, { useEffect } from "react";
import Header from '../../../components/Layout/Header/Header2'
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    getProducts,
} from "../../../actions/userAction/userAction";
import Loader from "../../../components/Layout/Loader/Loader";
import "./ThankyouPage.css"

const ThankyouPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { products, loading } = useSelector(
        (state) => state.products
    );
    const { user } = useSelector((state) => state.user);
    const guestEmail = location?.state?.email || ''
    const email = user && user.email ? user.email : guestEmail

    useEffect(() => {
        dispatch(getProducts());
    }, [])

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className=" mb-5" style={{ backgroundColor: "" }}>
                    <Header />
                    <div class="container-xxl  payment_success_bar">
                        <div class="container-lg">
                            <div class="container-lg payment_success_icon_bar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="110" height="80" viewBox="0 0 81 80" fill="none">
                                    <path d="M30.5008 68.0676L9.80078 47.3676L19.2341 37.9342L30.5008 49.2342L63.4341 16.2676L72.8674 25.7009L30.5008 68.0676Z" fill="#5CE12F" />
                                </svg>
                                <span>Payment successful</span>
                            </div>
                            <div class="container-lg payment_success_icon_bar2 ">
                                Thank you for your order. An email confirmation was sent to {email}.
                                For questions and inquiry about your order, please visit our  <a
                                    href="https://support.onetapconnect.com/" target="_blank"
                                    style={{ color: "#2572E6", textDecoration: "none" }}
                                >support center</a>.
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ThankyouPage;


