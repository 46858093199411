import React, { useEffect, useState, useRef } from "react";
import GenInfoToggleButton from "./GenInfoToggleButton";
import { useSelector, useDispatch } from "react-redux";
import { Country, State } from "country-state-city";
import {
  SingleTeamMemberDetails,
  updateTeamMemberDetails,
  getTeamMember,
  uploadProfilePicture,
  getCompanyProfileDetails,
  updateCompanyDetailsInfo,
  loadUser,
  getProfileImage,
} from "../../../../actions/userAction/userAction";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import { styled } from '@mui/material/styles';
import "react-toastify/dist/ReactToastify.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import Loader from "../../../Layout/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Box, Button, Modal } from "@mui/material";
import axios from "axios";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 2,
  outline: "none",
  borderRadius: "10px",
};
const cropingstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80%",
  backgroundColor: "white", // Changed bgcolor to backgroundColor
  padding: "16px", // Added padding for better appearance
  outline: "none",
  borderRadius: "10px",
};

const delete_account_modalStyle = {
  position: "fixed",
  zIndex: 1,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  display: "flex",         // Add flex display
  justifyContent: "center", // Center horizontally
  alignItems: "center",     // Center vertically
  backgroundColor: "rgba(0,0,0,0.4)",
};

const delete_account_modalContentStyle = {
  backgroundColor: "#fefefe",
  padding: "20px",
  border: "1px solid #888",
  width: "32%",
  borderRadius: "10px",
  textAlign: "center",
};

const deleteAccountbuttonStyle = {
  margin: "5px",
  width: "50%",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  backgroundColor: "#e65925",
  border: "#e65925",
  color: "#fff",
};
const deleteAccountcanclebuttonStyle = {
  margin: "5px",
  width: "50%",
  padding: "9px 47px",
  borderRadius: "5px",
  cursor: "pointer",
  backgroundColor: "#fff",
  border: "#e65925",
  color: "#e65925",
  border: "1px solid #e65925"
};
const delete_account_iconContainer = {
  textAlign: "center"
};
const delete_account_buttonContainer = {
  display: "flex",
  justifyContent: "space-around"
};
const delete_accountText = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "20px"
};
const delete_account_trashIconStyle = {
  color: "#e65925",
  marginBottom: "20px",
  marginTop: "20px",
};
const popupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const CardProfileGeneralInfoTab = (props) => {
  const url = process.env.REACT_APP_URL;
  const [teamMemberDetails, setTeamMemberDetails] = useState({});
  const [companyDetails, setCompanydetails] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [newJobTitle, setNewJobTitle] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [addressPermission, setAddressPermission] = useState(false);
  const [apartmentPermission, setApartmentPermission] = useState(false);
  const [cityPermission, setCityPermission] = useState(false);
  const [statePermission, setStatePermission] = useState(false);
  const [postalCodePermission, setPostalCodePermission] = useState(false);
  const [profilePictureerrormsj, setprofilePictureerrormsj] = useState("");
  const [industries, setIndustries] = useState([]);
  const [urlError, setUrlError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setcroppedImage] = useState(null);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isdeletemodalopen, setisdeletemodalopen] = useState(false);
  const [selectedDp, setSelectedDp] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [personalEmailError, setpersonalEmailError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [keywordsError, setKeywordsError] = useState("");
  const [designationErrors, setDesignationErrors] = useState("");
  const [contactError, setContactError] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [firstnameError, setfirstnameError] = useState("");
  const [lastnameError, setlastnameError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [openWelcomeModal, setOpenWelcomeModal] = React.useState(false);
  const cropperRef = useRef(null);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [line1Error, setLine1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const { user, loading: userLoading } = useSelector((state) => state.user);
  const isIndividual = user && user.isIndividual;
  const { loading, teamMemberDetails: teamMemberDetailsFromRedux } =
    useSelector((state) => state.TeammemberDetails);

  const { company, loading: companyLoading } = useSelector((state) => state.Company);
  const { userInfo } = useSelector((state) => state.userInformation);
  const plan = userInfo?.subscription_details?.plan


  useEffect(() => {
    if ((teamMemberDetailsFromRedux?.personlize_company_name === "" || teamMemberDetailsFromRedux?.businessemail === "") && (user?.role === "teammember")) {
      setTeamMemberDetails({
        ...teamMemberDetailsFromRedux,
        personlize_company_name: company?.company_name,
        businessemail: company?.global_email,
      });
    } else {
      setTeamMemberDetails(teamMemberDetailsFromRedux);
    }
  }, [teamMemberDetailsFromRedux, company?.company_name]);

  useEffect(() => {
    setCompanydetails(company);
  }, [company]);


  useEffect(() => {
    fetchIndustries();
  }, []);


  const fetchIndustries = async () => {
    try {
      const { data } = await axios.get(`${url}/industries`);
      setIndustries(data.industries);
    } catch (error) {

    }
  };

  const handleToggleAddressPermission = () => {
    const updatedPermission = !addressPermission;
    setAddressPermission(updatedPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_line1_address_permission: !addressPermission,
    }));
    saveUserFieldData('user_line1_address_permission', updatedPermission);
  };

  const handleToggleApartmentPermission = () => {
    const updatedPermission = !apartmentPermission;
    setApartmentPermission(updatedPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_line2_apartment_permission: !apartmentPermission,
    }));
    saveUserFieldData('user_line2_apartment_permission', updatedPermission);
  };

  const handleToggleCityPermission = () => {
    const updatedPermission = !cityPermission;
    setCityPermission(updatedPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_city_permission: !cityPermission,
    }));
    saveUserFieldData('user_city_permission', updatedPermission);
  };

  const handleToggleStatePermission = () => {
    const updatedPermission = !statePermission;
    setStatePermission(updatedPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_state_permission: !statePermission,
    }));
    saveUserFieldData('user_state_permission', updatedPermission);
  };

  const handleTogglePostalCodePermission = () => {
    const updatedPermission = !postalCodePermission;
    setPostalCodePermission(updatedPermission);
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      user_postal_code_permission: !postalCodePermission,
    }));
    saveUserFieldData('user_postal_code_permission', updatedPermission);
  };

  useEffect(() => {
    dispatch(SingleTeamMemberDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCompanyProfileDetails());
  }, []);


  useEffect(() => {
    if (!teamMemberDetails) {
      dispatch(SingleTeamMemberDetails(id));
    } else {
      setAddressPermission(teamMemberDetails?.user_line1_address_permission || false);
      setApartmentPermission(
        teamMemberDetails?.user_line2_apartment_permission || false
      );
      setCityPermission(teamMemberDetails?.user_city_permission || false);
      setStatePermission(teamMemberDetails?.user_state_permission || false);
      setPostalCodePermission(
        teamMemberDetails?.user_postal_code_permission || false
      );
    }
  }, [teamMemberDetails, id, dispatch]);

  const allowedImageTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg+xml",
    "image/jpg",
    "image/avif",
  ]
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the selected file type is not in the allowed types
      if (!allowedImageTypes.includes(file.type)) {
        alert("The selected file type is not supported.\nPlease use PNG, JPG, SVG format.");
        return; // Return early if the file type is not allowed
      }
      setSelectedDp(file);
      setOpenCropModal(true)
    }
  };
  useEffect(() => {
    if (openCropModal && selectedDp) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          cropperRef.current.src = e.target.result;

          // Initialize Cropper.js here
          const cropper = new Cropper(cropperRef.current, {
            dragMode: "move",
            aspectRatio: 1 / 1,
            viewMode: 1,
          });

          // Store the Cropper instance in the ref
          cropperRef.current.cropper = cropper;
        };
      };
      reader.readAsDataURL(selectedDp);
      setSelectedFile(selectedDp);
    }
  }, [openCropModal, selectedDp]);

  useEffect(() => {
    if (!loadUser) { dispatch(loadUser()) }

  }, [])

  const handleimagedelete = async () => {
    try {
      if (teamMemberDetails?.avatar) {
        // Send a request to your backend to delete the existing avatar
        const response = await fetch(`${url}/deleteProfileImage/${teamMemberDetails.avatar}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          // Delete was successful, update the state to clear the avatar
          setTeamMemberDetails((prevDetails) => ({
            ...prevDetails,
            avatar: '', // Set the avatar to an empty string
          }));

          setisdeletemodalopen(false)
          dispatch(SingleTeamMemberDetails(id))
          dispatch(loadUser())
        } else {
          ;
        }
      } else {

      }
    } catch (error) {
      ;
    }
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();

      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = croppedCanvas.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );



      const cropper = cropperRef.current.cropper;


      await cropper.destroy();
      setcroppedImage(croppedImage);
      setOpenCropModal(false);
    }
  };
  const handlezoominlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1); // Clear the cropping area

    }
  };
  const handleswapupdown = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleY = cropper.getData().scaleY; // Get the current X scaling factor
      cropper.scaleY(-scaleY); // Toggle the X scaling factor
    }
  };
  const handleswaprightleft = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleX = cropper.getData().scaleX; // Get the current X scaling factor
      cropper.scaleX(-scaleX); // Toggle the X scaling factor
    }
  };
  const handlezoomoutlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1); // Clear the cropping area

    }
  };
  const handlerotate = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(-90); // Clear the cropping area

    }
  };
  const handlerotateback = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(90); // Clear the cropping area

    }
  };
  const handlelogoup = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, -10); // Clear the cropping area

    }
  };
  const handlelogodown = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, 10); // Clear the cropping area

    }
  };
  const handlelogoleft = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(-10, 0); // Clear the cropping area

    }
  };
  const handlelogoright = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(10, 0); // Clear the cropping area

    }
  };
  const handleresetlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset(); // Clear the cropping area

    }
  };
  const handleuploadorignal = () => {
    if (cropperRef.current) {
      const getorignalimages = cropperRef.current.cropper.reset(); // Clear the cropping area
      const getorignal = getorignalimages.clear(); // Clear the cropping area
      const getorignalimage = getorignal.getCroppedCanvas();
      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = getorignalimage.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      setcroppedImage(croppedImage)
      setOpenCropModal(false);

    }
  }
  const handlecropmodalclose = () => {
    setcroppedImage(null);
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setOpenCropModal(false);
  };

  const handleUploaderClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setcroppedImage(null)
    setSelectedFile(null);
    setprofilePictureerrormsj(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (user?.first_login === true && (plan === 'Free' || plan === "Professional")) {
      setOpenWelcomeModal(true)
    }

  }, [user, plan]);

  const handleAddressChange = async (propertyName, value) => {

    if (!teamMemberDetails?.address?.postal_code && company?.make_private_permission === true) {
      setPostalCodeError("Postal code is required.");
    }
    if (!teamMemberDetails?.address?.postal_code && (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional")) {
      setPostalCodeError("Postal code is required.");
    }

    const postalCode = teamMemberDetails?.address?.postal_code;
    const state = State?.getStateByCodeAndCountry(
      teamMemberDetails?.address?.state,
      teamMemberDetails?.address?.country
    )?.name;
    let apiUrl;
    let response;
    if (company?.make_private_permission === true || userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      apiUrl = `https://api.zippopotam.us/${teamMemberDetails?.address?.country}/${postalCode.trim()}`;
      response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok !== true) {
        setPostalCodeError("Please enter a valid postal code.");
        return
      } else if (data.places[0].state !== state) {
        setPostalCodeError(`Please enter a valid postal code for ${state}`);
        return
      }
    }
  };
  const handleAddressField = (propertyName, value) => {
    setPostalCodeError("");
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        [propertyName]: value,
      },
    }));

  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (userInfo?.subscription_details?.plan === "Team") {
      if (name === "personlize_company_name" && value === "" && (company?.company_name_permission === true)) {
        return;
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);

      if (matchingCompany) {
        return;
      }
    }


    if (teamMemberDetails?.role === "teammember") {
      if (name === "businessemail" && value === "" && (company?.global_email_address_permission === true)) {
        return;
      }

      if (name === "businessemail" && !emailRegex.test(value)) {
        return;
      }
    }
    const isProfessionalPlan = userInfo?.subscription_details?.plan === "Professional";
    const isFreePlan = userInfo?.subscription_details?.plan === "Free";

    if (isProfessionalPlan && name === "businessemail") {
      if (value === "") {
        return;
      }

      if (!emailRegex.test(value)) {
        return;
      }
    }
    if (isFreePlan && name === "businessemail") {
      if (value !== "") {
        if (!emailRegex.test(value)) {
          return;
        }
      }
    }

    if (teamMemberDetails?.role === "teammember") {
      if (name === "personlize_primary_office_number" && value === "" && (company?.primary_office_number_permission === true)
      ) {
        return;
      }
    }

    if (isProfessionalPlan || isFreePlan) {
      if (name === "contact" && value === ""
      ) {
        return;
      }
    }

    if (name === 'personlize_Website_url') {
      const urlPattern = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/;
      if (value === "") {
        saveUserFieldData(name, value);
      }
      else if (!urlPattern.test(value)) {
        return;
      }
    }

    if (value !== "" || name === "contact" || name === "businessemail") {
      saveUserFieldData(name, value);
    }
  };



  const handleInputField = (e) => {
    const { name, value } = e.target;

    // If the input is for the office number, sanitize the value to allow only numeric characters
    const sanitizedValue = name === 'contact' ? value.replace(/[^0-9]/g, '') : value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {
      firstnameError: "",
      lastnameError: "",
      companyNameError: "",
      emailError: "",
      personalerror: "",
      contactError: "",
      keywordsError: "",
      urlError: "",
      addressPostalCodeError: "",
    };

    if (userInfo?.subscription_details?.plan === "Team") {
      if (name === "personlize_company_name" && sanitizedValue === "" && (company?.company_name_permission === true)) {
        errors.companyNameError = "Company name is required.";
      } else {
        setCompanyNameError(null);
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);

      if (matchingCompany) {
        errors.companyNameError = "Company name already exists.";
      }
      else {
        setCompanyNameError(null);
      }
    }

    if (name === "first_name" && sanitizedValue === "") {
      errors.firstnameError = "First name is required.";
    } else {
      setfirstnameError(null);
    }
    if (name === "last_name" && sanitizedValue === "") {
      errors.lastnameError = "Last name is required.";
    } else {
      setlastnameError(null);
    }
    if (teamMemberDetails?.role === "teammember") {
      if (name === "businessemail" && sanitizedValue === "" && (company?.global_email_address_permission === true)) {
        errors.emailError = "Business email is required.";
      } else {
        setEmailError(null);
      }
    }
    if (userInfo?.subscription_details?.plan === "Professional") {
      if (name === "businessemail" && sanitizedValue === "") {
        errors.emailError = "Business email is required.";
      } else {
        setEmailError(null);
      }
    }


    if (name === "businessemail" && sanitizedValue !== "") {
      if (!emailRegex.test(sanitizedValue)) {
        errors.emailError = "Please enter a valid email address.";
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError(null);
    }
    if (user?.role === 'teammember') {
      if (name === "email" && sanitizedValue !== "") {
        if (!emailRegex.test(sanitizedValue)) {

          errors.personalerror = "Please enter a valid email address.";
        } else {
          setpersonalEmailError(null);
        }
      } else {
        setpersonalEmailError(null);
      }
    }

    if (name === "keywords" && sanitizedValue === "" && (company?.primary_activities_permission === true)) {
      errors.keywordsError =
        "Primary Activities / Contact Keyword(s) is required.";
    } else if ((teamMemberDetails?.personlize_Primary_activities?.trim() === "" || !teamMemberDetails?.personlize_Primary_activities) && userInfo?.subscription_details?.plan === "Professional") {
      errors.keywordsError =
        "Primary Activities / Contact Keyword(s) is required.";
    } else {
      setKeywordsError(null);
    }
    // Validate "Primary Office Number"
    if (teamMemberDetails?.role === "teammember") {
      if (name === "contact" && sanitizedValue === ""
      ) {
        errors.contactError = "Cell phone number is required.";
      } else {
        setContactError("");
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {

      if (name === "contact" && teamMemberDetails?.contact === ""
      ) {

        errors.contactError = "Cell phone number is required.";
      } else {
        setContactError("");
      }
    }

    if (name === 'personlize_Website_url') {
      const urlPattern = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/;
      if (sanitizedValue === "") {
        errors.urlError = null;
      } else if (!urlPattern.test(sanitizedValue)) {
        errors.urlError = 'Please enter valid URL.';
      } else {
        setUrlError('');
      }
    }

    const hasErrors = Object.values(errors).some((error) => error !== "");

    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      [name]: sanitizedValue,
    }));

    if (hasErrors) {
      setlastnameError(errors.lastnameError);
      setfirstnameError(errors.firstnameError);
      setCompanyNameError(errors.companyNameError);
      setEmailError(errors.emailError);
      setpersonalEmailError(errors.personalerror);
      setContactError(errors.contactError);
      setKeywordsError(errors.keywordsError);
      setUrlError(errors.urlError);
      setPostalCodeError(errors.addressPostalCodeError);
      return;
    }
  };

  const saveUserFieldData = (fieldName, fieldValue) => {
    axios
      .post(`${url}/save_user_data/${id}`, {
        id,
        field_name: fieldName,
        field_value: fieldValue,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {

        } else {
          ;
        }
      })
      .catch((error) => {
        ;
      });
  };


  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = name === 'contact' ? value.replace(/[^0-9]/g, '') : value;

    if (userInfo?.subscription_details?.plan === "Team") {
      if (name === "company_name" && sanitizedValue === "" && (company?.company_name_permission === true)) {
        return;
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);
      if (matchingCompany) {
        return;
      }
    }

    if (sanitizedValue !== "" || name === "industry" || name === "company_name") {
      saveCompanyFieldData(name, sanitizedValue);
    }
  };
  const handleCompanyInputField = (e) => {
    const { name, value } = e.target;
    let isUrlValid = true;
    const sanitizedValue = name === 'contact' ? value.replace(/[^0-9]/g, '') : value;

    const errors = {
      companyNameError: "",
    };

    if (userInfo?.subscription_details?.plan === "Team") {
      if (name === "company_name" && sanitizedValue === "" && (company?.company_name_permission === true)) {
        errors.companyNameError = "Company name is required.";
      } else {
        setCompanyNameError(null);
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);
      if (matchingCompany) {
        errors.companyNameError = "Company name already exists.";
      }
      else {
        setCompanyNameError(null);
      }
    }

    if (name === 'website_url') {
      const urlPattern = /^(https?:\/\/)?[^\s/$.?#].[^\s]*$/;

      if (!urlPattern.test(value)) {
        setUrlError('Please enter valid URL.');
        isUrlValid = false;
      } else {
        setUrlError('');
      }
    }

    const hasErrors = Object.values(errors).some((error) => error !== "");


    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      [name]: sanitizedValue,
    }));

    if (hasErrors) {
      setCompanyNameError(errors.companyNameError);
      return;
    }
  };

  const saveCompanyFieldData = (fieldName, fieldValue) => {
    axios
      .post(`${url}/save_company_data`, {
        field_name: fieldName,
        field_value: fieldValue,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {

        } else {
          ;
        }
      })
      .catch((error) => {
        ;
      });
  };

  const [countryValue, setCountryValue] = useState(null); // Initialize the selected country state
  const [stateValue, setStateValue] = useState(""); // State for selected state
  const handleCountryChange = (value) => {
    setCountryValue(value);
    setPostalCodeError('')
    setStateValue(""); // Clear the selected state when the country changes
    // Update the state with the selected country
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        country: value,
        state: "",
        postal_code: ''
      },
    }));
    saveUserFieldData("address.country", value);

  };

  const handleStateChange = (value) => {
    setStateValue(value);
    setPostalCodeError('')
    setStateError("")
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        state: value,
        postal_code: ''
      },
    }));
    saveUserFieldData("address.state", value);
  };
  const handlfirstloginmodalclose = () => {
    updateFirstLogin();
  }

  const updateFirstLogin = async () => {
    try {
      // API call to update logged in user
      const { data } = await axios.post(
        `${url}/user/updatelogin`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      // Check the response data for success or handle it as needed
      if (data.success) {
        setOpenWelcomeModal(false)
        dispatch(loadUser())
      } else {
        // Handle an unsuccessful response, e.g., show an error message
        ;
      }
    } catch (error) {
      // Handle any network or Axios-related errors here
      ;
    }
  };
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const response = await axios.get(`${url}/getallcompanies`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }); // Adjust the URL if needed
        setCompanyNames(
          response.data.companies.map((company) => company.company_name)
        );
      } catch (error) {
        ;
      }
    };

    fetchCompanyNames();
  }, []);
  const handleSave = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {
      lastnameError: "",
      firstnameError: "",
      contactError: "",
      keywordsError: "",
      companyNameError: "",
      emailError: "",
      designationErrors: "",
      addressPostalCodeError: "",
    };

    if (userInfo?.subscription_details?.plan === "Team") {
      if (
        (teamMemberDetails?.personlize_company_name?.trim() === "" ||
          !teamMemberDetails?.personlize_company_name) &&
        company?.company_name_permission === true
      ) {
        errors.companyNameError = "Company name is required.";
      } else {
        setCompanyNameError(null);
      }
    }
    if (userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      const trimmedCompanyName = companyDetails?.company_name?.replace(/\s/g, '').toLowerCase();
      const matchingCompany = companyNames.find((name) => name.replace(/\s/g, '').toLowerCase() === trimmedCompanyName);

      if (matchingCompany) {
        errors.companyNameError = "Company name already exists.";
      }
      else {
        setCompanyNameError(null);
      }
    }
    if (teamMemberDetails?.first_name?.trim() === "") {
      errors.firstnameError = "First name is required.";
    } else {
      setfirstnameError(null);
    }
    if (teamMemberDetails?.last_name?.trim() === "") {
      errors.lastnameError = "Last name is required.";
    } else {
      setlastnameError(null);
    }
    const isTeamMember = user?.role === "teammember";
    const isProfessionalPlan = userInfo?.subscription_details?.plan === "Professional";
    const isFreePlan = userInfo?.subscription_details?.plan === "Free";
    const isEmailEmpty = !teamMemberDetails?.businessemail || teamMemberDetails?.businessemail.trim() === "";


    if ((isTeamMember && !isEmailEmpty && company?.global_email_address_permission) || (isProfessionalPlan && !isEmailEmpty) || (isFreePlan && !isEmailEmpty)) {
      if (!emailRegex.test(teamMemberDetails?.businessemail)) {
        errors.emailError = "Please enter a valid email address.";
      }
    } else {
      setEmailError(null);
    }
    if ((isTeamMember && isEmailEmpty && company?.global_email_address_permission) || (isProfessionalPlan && isEmailEmpty)) {
    } else {
      setEmailError(null);
    }
    if ((teamMemberDetails?.personlize_Primary_activities?.trim() === "" || !teamMemberDetails?.personlize_Primary_activities) && (company?.primary_activities_permission === true)) {
      errors.keywordsError =
        "Primary Activities / Contact Keyword(s) is required.";
    } else if ((teamMemberDetails?.personlize_Primary_activities?.trim() === "" || !teamMemberDetails?.personlize_Primary_activities) && isProfessionalPlan) {
      errors.keywordsError =
        "Primary Activities / Contact Keyword(s) is required.";
    } else {
      setKeywordsError(null);
    }

    if (user?.role === 'teammember') {
      if (teamMemberDetails?.email === "" || !teamMemberDetails?.email) {

        errors.personalerror = "Please enter a valid email address.";
      } else {
        setpersonalEmailError(null);
      }
    }

    if (user?.role === 'teammember') {
      if (
        isNaN(teamMemberDetails?.contact)
      ) {
      } else if (
        teamMemberDetails?.contact === null ||
        teamMemberDetails?.contact === ""
      ) {
        errors.contactError = "Cell phone number is required.";
      } else {
        setContactError("");
      }
      // }
    }
    else if (userInfo?.subscription_details?.plan === "Professional" || userInfo?.subscription_details?.plan === "Free") {
      if (
        isNaN(teamMemberDetails?.contact)
      ) {
        errors.contactError = "Cell phone number must be a number.";
      } else if (
        teamMemberDetails?.contact === null ||
        teamMemberDetails?.contact === ""
      ) {
        errors.contactError = "Cell phone number is required.";
      } else {
        setContactError("");
      }
    }

    // Trim all values in the designation array
    const trimmedDesignations = teamMemberDetails?.designation.map(
      (designation) => designation?.trim().toLowerCase()
    );
    trimmedDesignations?.forEach((designation, index) => {
      if (!designation) {
        errors.designationErrors = "Please enter job title.";
      } else if (trimmedDesignations.indexOf(designation) !== index) {
        errors.designationErrors = "Job title already in use. Please select another.";
      }
    });
    if (!teamMemberDetails?.address?.postal_code && company?.make_private_permission === true) {
      errors.addressPostalCodeError = "Postal code is required.";
    }

    if (errors.designationErrors.length > 0) {
      setDesignationErrors(errors.designationErrors);
      return;
    } else {
      setDesignationErrors("");
    }


    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (hasErrors) {


      setEmailError(errors.emailError);
      setCompanyNameError(errors.companyNameError);
      setlastnameError(errors.lastnameError);
      setfirstnameError(errors.firstnameError);
      setpersonalEmailError(errors.personalerror);
      setKeywordsError(errors.keywordsError);
      setDesignationErrors(errors.designationErrors);
      setContactError(errors.contactError);
      setPostalCodeError(errors.addressPostalCodeError);
      return;
    }
    const postalCode = teamMemberDetails?.address?.postal_code;
    const state = State?.getStateByCodeAndCountry(
      teamMemberDetails?.address?.state,
      teamMemberDetails?.address?.country
    )?.name;

    let apiUrl;
    let response;
    if (company?.make_private_permission === true || userInfo?.subscription_details?.plan === "Free" || userInfo?.subscription_details?.plan === "Professional") {
      apiUrl = `https://api.zippopotam.us/${teamMemberDetails?.address?.country}/${postalCode.trim()}`;
      response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok !== true) {
        setPostalCodeError("Please enter a valid postal code.");
        return
      } else if (data.places[0].state !== state) {
        setPostalCodeError(`Please enter a valid postal code for ${state}`);
        return
      }
    }

    if (e === "continue") {
      if (user?.role === "teammember") {
        setTimeout(() => {
          props.onClickSave("Edit Card");
        }, 3000);
      }
      else {
        setTimeout(() => {
          props.onClickSave("Edit About");
        }, 3000);
      }

    }
    // Trim all values in the designation array
    const trimmedDesignationss = teamMemberDetails?.designation.map(
      (designation) => designation.trim()
    );

    // Create a copy of the teamMemberDetails with trimmed designations
    let updatedTeamMemberDetails = {
      ...teamMemberDetails,
      designation: trimmedDesignationss,
    };

    if (selectedFile && croppedImage) {
      const formData = new FormData();
      formData.append("image", croppedImage); // Adjust the filename as needed
      formData.append("imageType", "profile");

      // Dispatch the upload action with the FormData
      const response = await dispatch(uploadProfilePicture(id, formData));
      setSelectedFile(null);
      if (response.error) {
        // Handle other errors
        ;
      } else if (response.success) {

        const avatarPath = response.imagePath;
        updatedTeamMemberDetails = {
          ...updatedTeamMemberDetails,
          avatar: avatarPath,
        };

      }
    }
    dispatch(updateCompanyDetailsInfo(companyDetails))
    dispatch(updateTeamMemberDetails(id, updatedTeamMemberDetails))
      .then(() => {
        setApiSuccess(true);
        // Reset the form fields and update the state after the API call is successful
        setTeamMemberDetails({ ...updatedTeamMemberDetails }); // Replace `initialFormState` with the initial state of your form
        dispatch(getTeamMember());
        setCompanydetails({ ...companyDetails }); // Replace `initialFormState` with the initial state of your form
        dispatch(getCompanyProfileDetails())
        dispatch(getProfileImage())
      })
      .catch((error) => {
        // Handle error if the API call fails
        ;
      });
  };


  const MAX_JOB_TITLES = 6; // Define the maximum allowed job titles
  const designationlength = teamMemberDetails?.designation?.length;
  const [jobTitleCount, setJobTitleCount] = useState(designationlength || 0);

  const handleDesignationChange = (index, value) => {
    // Create a copy of the designations array
    const updatedDesignations = [...teamMemberDetails?.designation];

    // Update the value at the specified index
    updatedDesignations[index] = value;
    // Update the state with the new designations array without empty strings
    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      designation: updatedDesignations,
    }));
    // saveUserFieldData("designation", updatedDesignations);
  };
  const handleNewJobTitleChange = (e) => {
    setNewJobTitle(e.target.value);
    document.getElementById("err").innerHTML = null;
  };
  const handleAddJobTitle = () => {
    if (!newJobTitle) {
      document.getElementById("err").innerHTML = "Please enter job title.";
      return; // Exit the function if newJobTitle is falsy
    }
    const isMatching = teamMemberDetails?.designation
      .map((designation) => designation.trim().toLowerCase())
      .includes(newJobTitle.trim().toLowerCase());
    if (isMatching) {
      document.getElementById("err").innerHTML =
        "Job title already in use. Please select another.";
      return; // Exit the function if newJobTitle is falsy
    }
    if (newJobTitle.trim() !== "") {
      // Create a copy of the designations array
      const updatedDesignations = [...teamMemberDetails.designation];

      if (updatedDesignations.length < MAX_JOB_TITLES) {
        // Add the new job title to the updatedDesignations array
        updatedDesignations.push(newJobTitle);

        // Update the state with the new designations array
        setTeamMemberDetails((prevDetails) => ({
          ...prevDetails,
          designation: updatedDesignations,
        }));
        // saveUserFieldData("designation", updatedDesignations);

        // Increment the job title count
        setJobTitleCount(jobTitleCount + 1);
        // Clear the input field and close the modal
        setNewJobTitle("");
        setModalOpen(false);
      } else {
        document.getElementById("err").innerHTML =
          "Maximum six job titles are allowed";
      }
    }
  };

  const handleRemoveJobTitle = (indexToRemove) => {
    const updatedDesignations = [...teamMemberDetails.designation];
    updatedDesignations.splice(indexToRemove, 1); // Remove the job title at the specified index

    setTeamMemberDetails((prevDetails) => ({
      ...prevDetails,
      designation: updatedDesignations,
    }));
  };

  const disableicon = (
    <span>
      Not editable. Your company has locked this field, and it is displayed for informational purposes.
    </span>
  );
  // 
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#E65925",
      color: '#fff',
      fontSize: 13,
      maxWidth: 'none',
      padding: '10px'
    },
  }));


  let email;

  if (userInfo?.subscription_details?.plan === 'Free' || userInfo?.subscription_details?.plan === 'Professional') {
    email = teamMemberDetails?.email;
  } else {
    email = teamMemberDetails?.businessemail;
  }

  return (
    <>
      {loading || companyLoading ? (
        <Loader />
      ) : (
        <>
          <div class="row mb-2">
            <Cardgeneralinfo />
          </div>
          <div className="row" style={{ gap: "20px" }}>
            <div className="col p-0 mt-2">
              <div className="onetap_conn_general_information_syn">
                <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                  Contact Information
                </h5>
                <div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      First name*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      name="first_name"
                      value={teamMemberDetails?.first_name}
                      onBlur={(e) => {
                        setfirstnameError(null);
                        handleInputChange(e);
                      }}
                      onChange={(e) => {
                        setfirstnameError(null);
                        handleInputField(e);
                      }}
                    />
                  </div>
                  {firstnameError && <p className="error">{firstnameError}</p>}
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Last name*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      name="last_name"
                      onBlur={(e) => {
                        setlastnameError(null);
                        handleInputChange(e);
                      }}
                      onChange={(e) => {
                        setlastnameError(null);
                        handleInputField(e);
                      }}
                      value={teamMemberDetails?.last_name}
                    />
                  </div>
                  {lastnameError && <p className="error">{lastnameError}</p>}
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      {isIndividual ? 'Company name' : 'Company name*'}
                    </p>
                    {userInfo?.subscription_details?.plan === "Professional" || userInfo?.subscription_details?.plan === "Free" ? (<input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      name="company_name"
                      value={companyDetails?.company_name}
                      onBlur={handleCompanyInputChange}
                      onChange={handleCompanyInputField}
                    />) : (<>{teamMemberDetails?.role !== "teammember" || (teamMemberDetails?.role === "teammember" && company?.company_name_permission === true) ?
                      <input
                        className="onetap_conn_general_information_input_syn"
                        type="text"
                        placeholder=""
                        name="personlize_company_name"
                        onBlur={(e) => {
                          setCompanyNameError(null);
                          handleInputChange(e);
                        }}
                        onChange={(e) => {
                          setCompanyNameError(null);
                          handleInputField(e);
                        }}
                        value={teamMemberDetails?.personlize_company_name}
                      /> : <LightTooltip placement="top" title={disableicon} >
                        <input
                          className="onetap_conn_general_information_input_syn"
                          style={{ opacity: '0.6' }}
                          type="text"
                          name="company_name"
                          value={companyDetails?.company_name}
                          readOnly
                        /> </LightTooltip>} </>)}

                  </div>
                  {companyNameError && (
                    <p className="error">{companyNameError}</p>
                  )}
                  {
                    user?.role === "teammember" &&
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Business email*
                      </p>
                      <input
                        className="onetap_conn_general_information_input_syn"
                        type="email"
                        placeholder=""
                        onChange={handleInputField}
                        name="email"
                        value={teamMemberDetails?.email}
                      />
                      {personalEmailError ? <p className="error">{personalEmailError}</p> : null}
                    </div>
                  }
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      {(userInfo?.subscription_details?.plan === "Professional" || userInfo?.subscription_details?.plan === "Free") ? 'Business email*' : 'Global email*'}
                    </p>
                    {user?.role === "teammember" ? <>
                      {company?.global_email_address_permission === true ? <input
                        className="onetap_conn_general_information_input_syn"
                        type="email"
                        placeholder=""
                        onBlur={(e) => {
                          setEmailError(null);
                          handleInputChange(e);
                        }}
                        onChange={(e) => {
                          setEmailError(null);
                          handleInputField(e);
                        }}
                        name="businessemail"
                        value={teamMemberDetails?.businessemail}
                      /> :
                        <LightTooltip placement="top" title={disableicon}>
                          <input
                            className="onetap_conn_general_information_input_syn"
                            type="email"
                            placeholder=""
                            readOnly
                            style={{ opacity: "0.7" }}
                            name="email"
                            value={companyDetails?.global_email}
                          /></LightTooltip>} </> :
                      <input
                        className="onetap_conn_general_information_input_syn"
                        type="email"
                        placeholder=""
                        onBlur={handleInputChange}
                        onChange={handleInputField}
                        name="email"
                        value={teamMemberDetails?.email}
                      />}

                  </div>
                  {emailError ? <p className="error">{emailError}</p> : null}
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Office Number
                    </p>
                    {teamMemberDetails?.role !== "teammember" || (teamMemberDetails?.role === "teammember" && company?.primary_office_number_permission === true) ? <input
                      className="onetap_conn_general_information_input_syn no-spinners"
                      type="number"
                      name="personlize_primary_office_number"
                      placeholder=""
                      value={teamMemberDetails?.personlize_primary_office_number}
                      onBlur={handleInputChange}
                      onChange={handleInputField}
                      minLength={10}
                      maxLength={20}
                      onKeyPress={(e) => {
                        if (e.key === 'e' || e.key === 'E') {
                          e.preventDefault(); // Prevent the 'e' character from being entered
                        }
                      }}
                    /> : <LightTooltip placement="top" title={disableicon} >
                      <input
                        className="onetap_conn_general_information_input_syn"
                        type="text"
                        name="contact"
                        placeholder=""
                        value={companyDetails?.contact}
                        style={{ opacity: "0.6" }}
                        readOnly
                        minLength={10}
                        maxLength={20}
                      />
                    </LightTooltip >}

                  </div>
                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Cell phone number*
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      placeholder=""
                      id=""
                      name="contact"
                      onBlur={handleInputChange}
                      onChange={handleInputField}
                      value={teamMemberDetails?.contact}
                      minLength={10}
                      maxLength={20}
                    />
                    {contactError ? <p className="error">{contactError}</p> : null}

                  </div>

                  <div className="mb-3">
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Website URL
                    </p>
                    {teamMemberDetails?.role !== "teammember" || (teamMemberDetails?.role === "teammember" && company?.website_url_permission === true) ? <input
                      className="onetap_conn_general_information_input_syn"
                      type="text"
                      name="personlize_Website_url"
                      placeholder="yourwebsite.com"
                      value={teamMemberDetails?.personlize_Website_url}
                      onBlur={handleInputChange}
                      onChange={handleInputField}
                    /> : <LightTooltip placement="top" title={disableicon} >
                      <input
                        className="onetap_conn_general_information_input_syn"
                        type="text"
                        name="website_url"
                        value={companyDetails?.website_url}
                        readOnly
                        style={{ opacity: '0.6' }}
                      />
                    </LightTooltip>}
                    {urlError && <p className="error">{urlError}</p>}
                  </div>
                </div>
                <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                <div>
                  <div className="mb-3">
                    {plan != "Free" ? (
                      <div>
                        <p className="onetap_conn_general_information_para_syn mb-1">
                          Industry
                        </p>
                        <div style={{ position: "relative" }}>
                          <Select
                            styles={{
                              container: (provided) => ({
                                width: "100%", // Set the width of the container as needed
                              }),
                              control: () => ({
                                // No border or box-shadow
                                margin: 0,
                                padding: 0,
                                border: "none",
                                boxShadow: "none",
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: 0, // Remove margin
                                padding: 0, // Remove padding
                              }),
                              indicatorsContainer: () => ({
                                display: "none",
                              }),
                              option: (provided) => ({
                                ...provided,
                                // Your custom option styles here
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "95%",
                              }),
                              // More styles for other components if needed...
                            }}
                            className="onetap_conn_general_information_input_syn mb-3 form-select left_space"
                            placeholder="Select Industry"
                            options={industries.map((item) => ({
                              value: item.name,
                              label: item.name,
                            }))}
                            value={{
                              value: companyDetails?.industry,
                              label: companyDetails?.industry || "Select Industry",
                            }}
                            onBlur={(selectedOption) =>
                              handleCompanyInputChange({
                                target: {
                                  name: "industry",
                                  value: selectedOption.value,
                                },
                              })
                            }
                            onChange={(selectedOption) =>
                              handleCompanyInputField({
                                target: {
                                  name: "industry",
                                  value: selectedOption.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                    <br />
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Primary Activities / Contact Keyword(s)*
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      {teamMemberDetails?.role !== "teammember" || (teamMemberDetails?.role === "teammember" && company?.primary_activities_permission === true) ?
                        <input
                          className="onetap_conn_general_information_input_syn"
                          type="text"
                          name="personlize_Primary_activities"
                          placeholder="Ex: Corporate Law, Real Estate Law"
                          value={teamMemberDetails?.personlize_Primary_activities}
                          onBlur={(e) => { setKeywordsError(null); handleInputChange(e) }}
                          onChange={(e) => { setKeywordsError(null); handleInputField(e) }}
                        /> :
                        <LightTooltip placement="top" title={disableicon} >
                          <input
                            className="onetap_conn_general_information_input_syn"
                            style={{ opacity: '0.6' }}
                            type="text"
                            name="keywords"
                            placeholder="Ex: Corporate Law, Real Estate Law"
                            value={companyDetails?.keywords}
                            readOnly
                          /></LightTooltip>}
                    </div>
                    {keywordsError && <p className="error">{keywordsError}</p>}
                  </div>
                </div>
                <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                <div>
                  <div className="mb-3">
                    <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                      Job Title
                    </h5>
                    <div>
                      {teamMemberDetails?.designation?.map(
                        (designation, index) => (
                          <div className="d-flex align-items-center  mb-3">
                            <input
                              className="onetap_conn_general_information_input_syn"
                              type="text"
                              placeholder=""
                              name="designation"
                              value={teamMemberDetails?.designation?.[index]} // Replace `index` with the actual index
                              onChange={(e) => { handleDesignationChange(index, e.target.value); setDesignationErrors(""); }}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveJobTitle(index)}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                fill="#8A8A8A"
                              />
                            </svg>

                          </div>
                        )
                      )}
                    </div>
                    {designationErrors && (
                      <p className="error">{designationErrors}</p>
                    )}
                  </div>
                  {designationlength === 6 ? (
                    <button
                      className="onetap_conn_general_information_input_syn_addanotherbtn"
                      style={{ opacity: 0.5 }} // Reduce opacity when disabled
                      disabled
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                          fill="white"
                        />
                      </svg>{" "}
                      &nbsp; Add another
                    </button>
                  ) : (
                    <button
                      className="onetap_conn_general_information_input_syn_addanotherbtn"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 8.99805H8V13.998C8 14.2633 7.89464 14.5176 7.70711 14.7052C7.51957 14.8927 7.26522 14.998 7 14.998C6.73478 14.998 6.48043 14.8927 6.29289 14.7052C6.10536 14.5176 6 14.2633 6 13.998V8.99805H1C0.734784 8.99805 0.48043 8.89269 0.292893 8.70515C0.105357 8.51762 0 8.26326 0 7.99805C0 7.73283 0.105357 7.47848 0.292893 7.29094C0.48043 7.1034 0.734784 6.99805 1 6.99805H6V1.99805C6 1.73283 6.10536 1.47848 6.29289 1.29094C6.48043 1.1034 6.73478 0.998047 7 0.998047C7.26522 0.998047 7.51957 1.1034 7.70711 1.29094C7.89464 1.47848 8 1.73283 8 1.99805V6.99805H13C13.2652 6.99805 13.5196 7.1034 13.7071 7.29094C13.8946 7.47848 14 7.73283 14 7.99805C14 8.26326 13.8946 8.51762 13.7071 8.70515C13.5196 8.89269 13.2652 8.99805 13 8.99805Z"
                          fill="white"
                        />
                      </svg>{" "}
                      &nbsp; Add another
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="onetap_conn_general_information_syn">
                  <h5 className="onetap_conn_contact_company_name_and_domain_syn mb-3">
                    Profile picture
                  </h5>
                  <p className="onetap_conn_general_information_para_syn">
                    Upload a high quality picture of yourself to show on your
                    OneTapConnect card.
                  </p>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <div className="image-uploader">
                      {!selectedFile && !imageURL ? (
                        <label
                          htmlFor="upload-input"
                          className="upload-box"
                          onClick={handleUploaderClick}
                        >
                          { }
                          <div className="image-preview">
                            {teamMemberDetails?.avatar ? (
                              <img
                                src={`${url}/profile/img/${teamMemberDetails?.avatar}`}
                                alt="Default"
                                style={{ borderRadius: "none", width: "90%" }}
                              />
                            ) : (
                              <span className="upload-icon">
                                <svg
                                  width="46"
                                  height="34"
                                  viewBox="0 0 46 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                    fill="#838383"
                                  />
                                </svg>{" "}
                                <br />
                                <p className="onetap_conn_general_information_para_syn m-0">
                                  Upload
                                </p>
                              </span>
                            )}
                          </div>
                        </label>
                      ) : (
                        <>
                          <label
                            htmlFor="upload-input"
                            className="upload-box"
                            onClick={() => { setOpenCropModal(true) }}
                          >
                            <div className="image-preview">
                              {selectedFile && (
                                <img
                                  src={croppedImage || URL.createObjectURL(selectedFile)}
                                  alt="Uploaded"
                                  style={{ borderRadius: "none", width: "90%" }}
                                />
                              )}


                            </div>
                          </label>   <span
                            className="remove-icon"
                            onClick={handleRemoveImage}
                          >
                            &#10005;
                          </span></>
                      )}

                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {teamMemberDetails?.avatar && <div className="d-flex justify-content-center m-2">
                    <div style={{ marginRight: '10px' }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} onClick={handleUploaderClick}>
                        Upload
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                    <div onClick={() => { setisdeletemodalopen(true) }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} >
                        Remove
                      </button>
                    </div>
                  </div>}
                  <div className="d-flex justify-content-center">
                    <p
                      className="onetap_conn_general_information_para_syn"
                      style={{ textAlign: "center" }}
                    >
                      {profilePictureerrormsj ? (
                        <p className="error_message">
                          {profilePictureerrormsj}
                        </p>
                      ) : null}
                      Accepted file type: SVG, PNG, JPG
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {teamMemberDetails?.role !== "teammember" || (teamMemberDetails?.role === "teammember" && company?.make_private_permission === true) ?
                  <div className="onetap_conn_general_information_syn">
                    <div className="d-flex justify-content-between">
                      <p className="onetap_conn_contact_company_name_and_domain_syn mb-3 fw-semibold">
                        Address
                      </p>
                      <p className="fs-6 fw-medium" style={{ color: "#E65925" }}>
                        Make Private
                      </p>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Country
                      </p>
                      <div
                        className="dropdown countrydrop"
                        style={{ width: "90%" }}
                      >
                        <div className="select-container">
                          <Select
                            styles={{
                              container: (provided) => ({
                                width: "100%", // Set the width of the container as needed
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                padding: "0 0",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                marginLeft: 0,
                              }),
                              control: () => ({
                                // No border or box-shadow
                                margin: 0,
                                padding: 0,
                                border: "none",
                                boxShadow: "none",
                              }),
                              input: (provided) => ({
                                ...provided,
                                margin: 0, // Remove margin
                                padding: 0, // Remove padding
                              }),
                              indicatorsContainer: () => ({
                                display: "none",
                              }),
                              option: (provided) => ({
                                ...provided,
                                // Your custom option styles here
                              }),
                              // More styles for other components if needed...
                            }}
                            className="onetap_conn_general_information_input_syn mb-3 form-select"
                            placeholder="Select"
                            name="address.country"
                            options={Country.getAllCountries().map((item) => ({
                              value: item.isoCode,
                              label: item.name,
                            }))}
                            value={{
                              value: teamMemberDetails?.address?.country,
                              label: Country.getCountryByCode(
                                teamMemberDetails?.address?.country
                              )?.name,
                            }}
                            onChange={(selectedOption) =>
                              handleCountryChange(selectedOption.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Address
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="line1"
                          value={teamMemberDetails?.address?.line1}
                          onBlur={(e) =>
                            handleAddressChange("line1", e.target.value)
                          }
                          onChange={(e) => { handleAddressField("line1", e.target.value); setLine1Error("") }
                          }
                        />

                        <GenInfoToggleButton
                          isOn={addressPermission}
                          onToggle={handleToggleAddressPermission}
                        />
                      </div>
                      {line1Error && (
                        <p className="error_message">{line1Error}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Apt, suite, etc
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.line2"
                          value={teamMemberDetails?.address?.line2}
                          onBlur={(e) =>
                            handleAddressChange("line2", e.target.value)
                          }
                          onChange={(e) =>
                            handleAddressField("line2", e.target.value)
                          }
                        />
                        <GenInfoToggleButton
                          isOn={apartmentPermission}
                          onToggle={handleToggleApartmentPermission}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        City
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.city"
                          value={teamMemberDetails?.address?.city}
                          onBlur={(e) =>
                            handleAddressChange("city", e.target.value)
                          }
                          onChange={(e) => { handleAddressField("city", e.target.value); setCityError("") }
                          }
                        />
                        <GenInfoToggleButton
                          isOn={cityPermission}
                          onToggle={handleToggleCityPermission}
                        />
                      </div>
                      {cityError && (
                        <p className="error_message">{cityError}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        State
                      </p>
                      <div className="d-flex justify-content-between ">
                        <div
                          className="dropdown countrydrop"
                          style={{ width: "90%" }}
                        >
                          <div className="select-container">
                            <Select
                              styles={{
                                container: (provided) => ({
                                  width: "100%", // Set the width of the container as needed
                                }),
                                control: () => ({
                                  // No border or box-shadow
                                  margin: 0,
                                  padding: 0,
                                  border: "none",
                                  boxShadow: "none",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0 0",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  marginLeft: 0,
                                }),
                                input: (provided) => ({
                                  ...provided,
                                  margin: 0, // Remove margin
                                  padding: 0, // Remove padding
                                }),
                                indicatorsContainer: () => ({
                                  display: "none",
                                }),
                                option: (provided) => ({
                                  ...provided,
                                }),
                              }}
                              className="onetap_conn_general_information_input_syn mb-3 form-select"
                              placeholder="Select"
                              name="address.state"
                              options={State.getStatesOfCountry(
                                teamMemberDetails?.address?.country
                              ).map((item) => ({
                                value: item.isoCode,
                                label: item.name,
                              }))}
                              value={{
                                value: companyDetails?.address?.state,
                                label: State.getStateByCodeAndCountry(
                                  teamMemberDetails?.address?.state,
                                  teamMemberDetails?.address?.country
                                )?.name,
                              }}
                              onChange={(selectedOption) => { handleStateChange(selectedOption.value); setStateError("") }
                              }
                            />
                          </div>
                        </div>

                        <GenInfoToggleButton
                          isOn={statePermission}
                          onToggle={handleToggleStatePermission}
                        />
                      </div>
                      {stateError && (
                        <p className="error_message">{stateError}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Postal code
                      </p>
                      <div className="d-flex justify-content-between">
                        <input
                          className="onetap_conn_general_information_input_syn_withicon"
                          type="text"
                          placeholder=""
                          name="address.postal_code"
                          value={teamMemberDetails?.address?.postal_code}
                          onBlur={(e) =>
                            handleAddressChange("postal_code", e.target.value)
                          }
                          onChange={(e) =>
                            handleAddressField("postal_code", e.target.value)
                          }
                        />
                        <GenInfoToggleButton
                          isOn={postalCodePermission}
                          onToggle={handleTogglePostalCodePermission}
                        />
                      </div>
                      {postalCodeError && (
                        <p className="error_message">{postalCodeError}</p>
                      )}
                    </div>
                  </div> : null
                }
              </div>
              <div></div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="d-flex justify-content-end">
              <button
                className="onetap_connect__comp_profile_save_button_syn"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                onClick={(e) => {
                  handleSave("continue");
                }}
                className="onetap_connect__comp_profile_save_and_cont_button_syn"
              >
                Save & Continue
              </button>
            </div>
          </div>
        </>
      )}
      <Modal
        open={isModalOpen}
        onClose={(e) => {
          setModalOpen(false);
          setNewJobTitle(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Modal-section">
            <h2 className="header" style={{ marginBottom: "1rem" }}>
              Add Title{" "}
            </h2>

            <input
              type="text"
              name="teamName"
              className="input-field m-0"
              placeholder="Ex: Marketing"
              value={newJobTitle}
              onChange={handleNewJobTitleChange}
            />
            <p id="err" className="error_message mt-1 mb-0"></p>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn"
                onClick={handleAddJobTitle}
              >
                Add
              </button>
              <button
                className="Modal-btn sec-modal-btn"
                onClick={(e) => {
                  setModalOpen(false);
                  setNewJobTitle(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={openCropModal} onClose={handlecropmodalclose}>
        <div>
          <div style={cropingstyle} className="modal-content">
            {selectedDp && (
              <div style={{ width: "100%", height: "80%" }}>
                <img
                  src={croppedImage}
                  ref={cropperRef}
                  alt="Selected Image"
                  style={{ maxWidth: "50%" }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                margin: "10px 0px",
              }}
            >
              <Tooltip title="Flip vertical" arrow>
                <SwapVertOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswapupdown}
                />
              </Tooltip>
              <Tooltip title="Flip horizontal" arrow>
                <SwapHorizOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswaprightleft}
                />
              </Tooltip>
              <Tooltip title="Zoom in" arrow>
                <ZoomInIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoominlogo}
                />
              </Tooltip>
              <Tooltip title="Zoom out" arrow>
                <ZoomOutIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoomoutlogo}
                />
              </Tooltip>
              <Tooltip title="Move Up" arrow>
                <ArrowUpwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoup}
                />
              </Tooltip>
              <Tooltip title="Move down" arrow>
                <ArrowDownwardOutlined
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogodown}
                />
              </Tooltip>
              <Tooltip title="Move left" arrow>
                <ArrowBackOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoleft}
                />
              </Tooltip>
              <Tooltip title="Move right" arrow>
                <ArrowForwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoright}
                />
              </Tooltip>
              <Tooltip title="Rotate left" arrow>
                <Rotate90DegreesCcwIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotate}
                />
              </Tooltip>
              <Tooltip title="Rotate right" arrow>
                <Rotate90DegreesCwOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotateback}
                />
              </Tooltip>
              <Tooltip title="Reset" arrow>
                <RestartAltIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleresetlogo}
                />
              </Tooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleCrop}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Crop Image
              </Button>
              <Button
                onClick={handleuploadorignal}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Upload
              </Button>
              <Button
                onClick={handlecropmodalclose}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {
        isdeletemodalopen && <div style={delete_account_modalStyle} >
          <div style={delete_account_modalContentStyle}>
            <div style={delete_accountText}>Are you sure you want to delete your profile picture?</div>
            <div style={delete_account_iconContainer}>
              <FontAwesomeIcon icon={faTrash} bounce size="2xl" style={delete_account_trashIconStyle} />
            </div>
            <div style={delete_account_buttonContainer}>
              <button style={deleteAccountbuttonStyle} onClick={handleimagedelete}>
                Delete
              </button>
              <button style={deleteAccountcanclebuttonStyle} onClick={() => { setisdeletemodalopen(false) }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      }
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                  class="circle">
                  <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                    fill="#E65925" />
                  <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                </svg>
              </div>
            </div>
            <span className="team_created_success">Data updated successfully!</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
          </button>
        </Box>
      </Modal>
      <Modal
        open={openWelcomeModal}
        onClose={handlfirstloginmodalclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <div className="Modal-section">
            <h4 className="header"> Onboarding
            </h4>
            <div className="w-100 mb-3">
              <iframe style={{ borderRadius: '8px' }} width="100%" height="200" src="https://www.youtube.com/embed/D0UnqGm_miA?si=ciAdQoP2ywrG9jYd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
             
            </div>

            <p>Hi {user?.first_name ? user?.first_name : ""}, welcome to the future of networking!</p>

            <b>Setting up your account is very simple:</b>
            <ol className="ps-4">
              <li>Enter your information</li>
              <li>Design your card</li>
              <li>Share it with the world!</li>
            </ol>


            <b>First let’s start with your Contact information...</b>
            <div className="Modal-btn-group">
              <button
                className="Modal-btn first-modal-btn w-100"

                onClick={handlfirstloginmodalclose}
              >
                Start now
              </button>
            </div>
          </div>
        </Box>
      </Modal>

    </>
  );
};

const Cardgeneralinfo = () => {
  const { userInfo } = useSelector((state) => state.userInformation);

  return (
    <div className="onetap_conn_general_information_syn">
      <h4 className="onetap_conn_general_information_heading_syn">
        {userInfo?.subscription_details?.plan !== "Team" ? "Contact Information" : "General Information"}
      </h4>
      <p className="onetap_conn_general_information_para_syn">

        {userInfo?.subscription_details?.plan !== "Team" ? 'Enter the contact information you would like to display and connect to your digital card. You may come back at any time to update the information. Any update will immediately be reflected on your digital card.' : 'Enter the contact information you would like to show and connect to your digital card. You may come back at any time to update the information. Any update will immediately be reflected on your digital card.'}
      </p>
      <hr style={{ height: "px" }} />
    </div>
  );
};

export default CardProfileGeneralInfoTab;
export { Cardgeneralinfo };
