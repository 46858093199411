
import React, { useRef, useState, useEffect } from "react";
import BrandingColorPicker from "./BrandingColorPicker";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import Cropper from "cropperjs";
import { Button, Tooltip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import "cropperjs/dist/cropper.min.css";
import {
  getCompanyProfileDetails,
  updateCompanyDetailsInfo,
  uploadFaviconPicture,
  uploadLogoPicture,
} from "../../../../actions/userAction/userAction";
import Loader from "../../../Layout/Loader/Loader";
import ArrowDownwardOutlined from "@mui/icons-material/ArrowDownwardOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

const CardProfileBrandingInfoTab = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    backgroundColor: "white",
    padding: "16px",
    outline: "none",
    borderRadius: "10px",
  };
  const delete_account_modalStyle = {
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",         // Add flex display
    justifyContent: "center", // Center horizontally
    alignItems: "center",     // Center vertically
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const delete_account_modalContentStyle = {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "32%",
    borderRadius: "10px",
    textAlign: "center",
  };

  const deleteAccountbuttonStyle = {
    margin: "5px",
    width: "50%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#e65925",
    border: "#e65925",
    color: "#fff",
  };
  const deleteAccountcanclebuttonStyle = {
    margin: "5px",
    width: "50%",
    padding: "9px 47px",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "#e65925",
    color: "#e65925",
    border: "1px solid #e65925"
  };
  const delete_account_iconContainer = {
    textAlign: "center"
  };
  const delete_account_buttonContainer = {
    display: "flex",
    justifyContent: "space-around"
  };
  const delete_accountText = {
    marginBottom: "20px",
    textAlign: "center",
    fontSize: "20px"
  };
  const delete_account_trashIconStyle = {
    color: "#e65925",
    marginBottom: "20px",
    marginTop: "20px",
  };

  const dispatch = useDispatch();
  const { company, loading, error, message } = useSelector(
    (state) => state.Company
  );
  const [companyDetails, setCompanydetails] = useState({});
  const url = process.env.REACT_APP_URL;
  const [colorAddError, setColorAddError] = useState(false);
  const colors = ["#E65925", "#000", "#6776ED", "#6EC1E4", "#54595F"];

  const [logoName, setLogoName] = useState("");
  const [logoAlt, setLogoAlt] = useState("");
  const [favIcon, setFavIcon] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [additionalColors, setAdditionalColors] = useState([]);
  const [colorNameErrorMessage, setColorNameErrorMessage] = useState("");
  const [colorCodeErrorMessage, setColorCodeErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [croppedImage, setcroppedImage] = useState(null);
  const [croppedfavImage, setcroppedfavImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [isdeletelogomodalopen, setisdeletelogomodalopen] = useState(false);
  const [isdeletefaviconmodalopen, setisdeletefaviconmodalopen] = useState(false);
  const [openCropfavModal, setOpenCropfavModal] = useState(false);
  const [selectedlogo, setSelectedlogo] = useState(false);
  const [selectedfavicon, setSelectedfavicon] = useState(false);
  const [logoerrormsj, setlogoerrormsj] = useState("");
  const [faviconerrormsj, setfaviconerrormsj] = useState("");
  const [customColorNames, setCustomColorNames] = useState({});
  const [customCount, setCustomCount] = useState(1);
  const [logo, setLogo] = useState(null);
  const [faviconicon, setFaviconicon] = useState(null);
  const [logoimageisthere, setlogoimageisthere] = useState(false);
  const [faviconisthere, setfaviconisthere] = useState(false);
  const cropperRef = useRef(null);
  const [apiSuccess, setApiSuccess] = useState(false);
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  useEffect(() => {
    setCompanydetails(company);
  }, [company]);

  useEffect(() => {
    if (!company) {
      dispatch(getCompanyProfileDetails());
    } else {
      setLogoName(company?.company_logo_name);
      setAdditionalColors(company?.additional_colors);
      setLogoAlt(company?.logo_alt);
      setFavIcon(company?.fav_icon);
      setCompanydetails(company);
    }
  }, [company]);

  useEffect(() => {
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      additional_colors: additionalColors,
    }));
  }, [additionalColors]);

  const addCustomColor = () => {
    // Find the next available custom count
    let nextCustomCount = customCount;
    while (
      additionalColors.some(
        (color) => color.name === `Custom color ${nextCustomCount}`
      )
    ) {
      nextCustomCount++;
    }

    const customColorName = `Custom color ${nextCustomCount}`;
    setAdditionalColors([
      ...additionalColors,
      { name: customColorName, code: "#000000" },
    ]);
    // Update the customColorNames state
    setCustomColorNames((prevNames) => ({
      ...prevNames,
      [customColorName]: customColorName,
    }));
  };

  useEffect(() => {
    const customNames = additionalColors.map((color) => color.name);
    setCustomColorNames(customNames);
  }, [additionalColors]);

  const handleColorNameChange = (index, newName) => {
    setCustomColorNames((prevNames) => {
      const newNames = [...prevNames];
      newNames[index] = newName;
      return newNames;
    });

    setAdditionalColors((prevColors) => {
      const newColors = prevColors.map((color, i) => {
        if (i === index) {
          return { ...color, name: newName };
        }
        return color;
      });
      return newColors;
    });
  };


  const updatePrimaryColor = (name, color) => {
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      primary_color: {
        name: "Primary",
        code: color,
      },
    }));
  };

  const updateAccentColor = (name, color) => {
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      accent_color: {
        name: "Accent",
        code: color,
      },
    }));
  };
  const addColorToAdditionalColors = (name, code) => {


    const updatedColors = additionalColors?.map((color, index) => {
      // Check if the color name matches the one being updated
      if (index === name) {
        return { ...color, code: code };
      }
      return color; // Return other colors unchanged
    });

    setAdditionalColors(updatedColors);
  };
  //delete additional color from array
  const handleRemoveAdditionalColor = (index) => {
    const updatedColors = [...additionalColors];
    updatedColors.splice(index, 1);
    setAdditionalColors(updatedColors);

    // Decrement customCount when a color is removed
    setColorAddError(false); // Reset the error state
    // Toggle the dummy state variable to force a re-render
    setForceUpdate(!forceUpdate);
  };
  const allowedImageTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg+xml",
    "image/jpg",
    "image/avif",
  ]
  const handleImageUpload2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the selected file type is not in the allowed types
      if (!allowedImageTypes.includes(file.type)) {
        alert("The selected file type is not supported.\nPlease use PNG, JPG, SVG format.");
        return; // Return early if the file type is not allowed
      }
      setSelectedlogo(file); // Set the selected file
      setOpenCropModal(true); // Open the modal
    }
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the selected file type is not in the allowed types
      if (!allowedImageTypes.includes(file.type)) {
        alert("The selected file type is not supported.\nPlease use PNG, JPG, SVG format.");
        return; // Return early if the file type is not allowed
      }
      setSelectedfavicon(file);
      setOpenCropfavModal(true);
    }
  };
  useEffect(() => {
    if (openCropModal && selectedlogo) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          cropperRef.current.src = e.target.result;

          // Initialize Cropper.js here
          const cropper = new Cropper(cropperRef.current, {
            dragMode: "move",
            aspectRatio: NaN,
            viewMode: 1,
          });

          // Store the Cropper instance in the ref
          cropperRef.current.cropper = cropper;
        };
      };
      setIsCropping(true);
      reader.readAsDataURL(selectedlogo);
      setLogo(selectedlogo);
    }
  }, [openCropModal, selectedlogo]);

  useEffect(() => {
    if (openCropfavModal && selectedfavicon) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          cropperRef.current.src = e.target.result;

          // Initialize Cropper.js here
          const cropper = new Cropper(cropperRef.current, {
            dragMode: "move",
            aspectRatio: 1 / 1,
            viewMode: 1,
          });

          // Store the Cropper instance in the ref
          cropperRef.current.cropper = cropper;
        };
      };
      setIsCropping(true);
      reader.readAsDataURL(selectedfavicon);
      setFaviconicon(selectedfavicon);
    }
  }, [openCropfavModal, selectedfavicon]);


  const handlefavCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();

      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = croppedCanvas.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      const cropper = cropperRef.current.cropper;

      await cropper.destroy();
      setcroppedfavImage(croppedImage);
      setIsCropping(false);
      setOpenCropfavModal(false);
    }
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();

      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = croppedCanvas.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      const cropper = cropperRef.current.cropper;

      await cropper.destroy();
      setcroppedImage(croppedImage);
      setIsCropping(false);
      setOpenCropModal(false);
    }
  };
  const handlecropmodalclose = () => {
    setcroppedImage(null);
    setSelectedlogo(null);
    setLogo(null);
    // Reset the file input by clearing its value
    if (fileInputRef2.current) {
      fileInputRef2.current.value = '';
    }
    setOpenCropModal(false);
  };
  const handlecropfavmodalclose = () => {
    setSelectedfavicon(null);
    setFaviconicon(null);
    setFaviconicon(null)
    // Reset the file input by clearing its value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setOpenCropfavModal(false);
  };
  const handlezoominlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(0.1); // Clear the cropping area

    }
  };
  const handleswapupdown = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleY = cropper.getData().scaleY; // Get the current X scaling factor
      cropper.scaleY(-scaleY); // Toggle the X scaling factor
    }
  };
  const handleswaprightleft = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const scaleX = cropper.getData().scaleX; // Get the current X scaling factor
      cropper.scaleX(-scaleX); // Toggle the X scaling factor
    }
  };
  const handlezoomoutlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.zoom(-0.1); // Clear the cropping area

    }
  };
  const handlerotate = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(-90); // Clear the cropping area

    }
  };
  const handlerotateback = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(90); // Clear the cropping area

    }
  };
  const handlelogoup = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, -10); // Clear the cropping area

    }
  };
  const handlelogodown = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(0, 10); // Clear the cropping area

    }
  };
  const handlelogoleft = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(-10, 0); // Clear the cropping area

    }
  };
  const handlelogoright = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.move(10, 0); // Clear the cropping area

    }
  };
  const handleresetlogo = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.reset(); // Clear the cropping area

    }
  };
  const handleUploaderClick2 = () => {
    // Trigger the file input click event
    if (fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };
  const handlelogoimagedelete = async () => {
    try {
      if (companyDetails?.logopath) {
        // Send a request to your backend to delete the existing avatar
        const response = await fetch(`${url}/deleteLogoImage/${companyDetails?.logopath}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          setCompanydetails((prevDetails) => ({
            ...prevDetails,
            logopath: '',
          }));
          setisdeletelogomodalopen(false)
          dispatch(getCompanyProfileDetails());
        } else {
          ;
        }
      } else {

      }
    } catch (error) {
      ;
    }
  };


  const handlefaviconimagedelete = async () => {
    try {
      if (companyDetails?.fav_icon_path) {
        // Send a request to your backend to delete the existing avatar
        const response = await fetch(`${url}/deleteFaviconImage/${companyDetails?.fav_icon_path}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          setCompanydetails((prevDetails) => ({
            ...prevDetails,
            fav_icon_path: '',
          }));
          setisdeletefaviconmodalopen(false)
          dispatch(getCompanyProfileDetails());
        } else {
          ;
        }
      } else {

      }
    } catch (error) {
      ;
    }
  };

  const handleRemoveImage2 = () => {
    setLogo(null);
    setlogoerrormsj(null);
    setcroppedImage(null);
    setIsCropping(false);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };
  const handleuploadorignal = () => {
    if (cropperRef.current) {
      const getorignalimages = cropperRef.current.cropper.reset(); // Clear the cropping area
      const getorignal = getorignalimages.clear(); // Clear the cropping area
      const getorignalimage = getorignal.getCroppedCanvas();
      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = getorignalimage.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      setcroppedImage(croppedImage)
      setOpenCropModal(false);

    }
  }
  const handleuploadorignalfavicon = () => {
    if (cropperRef.current) {
      const getorignalimages = cropperRef.current.cropper.reset(); // Clear the cropping area
      const getorignal = getorignalimages.clear(); // Clear the cropping area
      const getorignalimage = getorignal.getCroppedCanvas();
      // Get the source URL of the original image
      const originalImageSrc =
        cropperRef.current.cropper.getImageData()?.src || "";

      // Use the original image format for the cropped image
      const croppedImage = getorignalimage.toDataURL(
        originalImageSrc.includes(".svg") ? "image/svg+xml" : ""
      );

      setcroppedfavImage(croppedImage)
      setOpenCropfavModal(false);

    }
  }

  const handleUploaderClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setFaviconicon(null);
    setfaviconerrormsj("")
    setcroppedfavImage(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const logoNameChange = (value) => {
    setLogoName(value);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      company_logo_name: value,
    }));
  };
  const logoAltChange = (value) => {
    setLogoAlt(value);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      logo_alt: value,
    }));
  };
  const favIconChange = (value) => {
    setFavIcon(value);
    setCompanydetails((prevDetails) => ({
      ...prevDetails,
      fav_icon: value,
    }));
  };

  useEffect(() => {
    if (croppedImage !== null) {
      setlogoimageisthere(true)
    }
    else {
      setlogoimageisthere(false)
    }
  }, [logo, croppedImage, companyDetails])
  useEffect(() => {
    if (companyDetails?.logopath !== "") {
      setlogoimageisthere(true)
    }
    else {
      setlogoimageisthere(false)
    }
  }, [companyDetails])

  // for favIcon---------------------------------------------------------------------

  useEffect(() => {
    if (croppedfavImage !== null) {
      setfaviconisthere(true)
    }
    else {
      setfaviconisthere(false)
    }
  }, [logo, croppedfavImage, companyDetails])

  useEffect(() => {
    if (companyDetails?.fav_icon_path !== "") {
      setfaviconisthere(true)
    }
    else {
      setfaviconisthere(false)
    }
  }, [companyDetails])


  const handleSave = async () => {
    const errors = {
      logoerrormsj: "",
      faviconerrormsj: ""
    };
    if (logoimageisthere) {
      if (logoName === "" || logoName === null) {

        errors.logoerrormsj = "Logo name is required.";
      }
    }
    if (faviconisthere) {
      if (favIcon === "" || favIcon === null) {
        errors.faviconerrormsj = "Favicon name is required.";
      }
    }

    // Check if there are any errors
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (hasErrors) {

      setlogoerrormsj(errors.logoerrormsj);
      setfaviconerrormsj(errors.faviconerrormsj)
      return;
    }

    let updatedCompanyDetails = { ...companyDetails }; // Create a copy to update

    if (logo) {
      const formData = new FormData();
      const blob = new Blob([logo], { type: "image/jpeg" });
      formData.append("image", croppedImage);
      formData.append("imageType", "logo");
      const response = await dispatch(uploadLogoPicture(formData));
      setLogo(null);
      const logoimagepath = response.logoimagePath;
      updatedCompanyDetails = {
        ...updatedCompanyDetails,
        logopath: logoimagepath,
      };
      if (response.error) {
        ;
      } else if (response.success) {

      }
    }

    if (faviconicon) {
      const formData = new FormData();
      formData.append("image", croppedfavImage);
      formData.append("imageType", "favicon");
      const response = await dispatch(uploadFaviconPicture(formData));
      setFaviconicon(null);
      const faviconimagepath = response.faviconpath;
      updatedCompanyDetails = {
        ...updatedCompanyDetails,
        fav_icon_path: faviconimagepath,
      };
      if (response.error) {
        ;
      } else if (response.success) {

      }
    }

    // After all updates are done, dispatch the updateCompanyDetailsInfo action
    dispatch(updateCompanyDetailsInfo(updatedCompanyDetails))
      .then(() => {
        // Reset the form fields and update the state after the API call is successful
        setCompanydetails({ ...updatedCompanyDetails }); // Replace `initialFormState` with the initial state of your form
        dispatch(getCompanyProfileDetails());
        setApiSuccess(true);
      })
      .catch((error) => {
        // Handle error if the API call fails
        ;
      });
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column gap-3">
          <div className="onetap_conn_general_information_syn">
            <h4 className="onetap_conn_general_information_heading_syn">
              Branding
            </h4>
            <p className="onetap_conn_general_information_para_syn">
              Set your logos and colors. Those information will be used as default for your OneTapConnect card/microsite.
            </p>
            <hr style={{ height: "px", marginBottom: "unset" }} />
          </div>

          <div className="d-flex flex-column gap-3">
            <div className="onetap_conn_general_information_syn">
              <div className="branding_logo_and_upload_frame">
                <div className="branding_logo_div ">
                  <div className="border-bottom">
                    <h5 className="onetap_conn_contact_company_name_and_domain_syn">
                      Logo
                    </h5>
                    <p style={{ color: "#6776ED" }}>
                      The below information will be use to help make your card
                      accessible to visitors with visual impairment.
                    </p>
                  </div>
                  <div className="w-100 gap-3 d-flex flex-column">
                    <div>
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Logo name
                      </p>
                      <input
                        className="onetap_conn_general_information_input_syn_withicon"
                        type="text"
                        placeholder=""
                        name="LogoName"
                        value={logoName}
                        onChange={(e) => {
                          { logoNameChange(e.target.value); setlogoerrormsj("") }
                        }}
                      />
                      {logoerrormsj && <p className="error_message"> {logoerrormsj}</p>}
                    </div>
                    <div style={{ paddingBottom: "30px" }}>
                      <p className="onetap_conn_general_information_para_syn mb-1">
                        Logo alt text (optional)
                      </p>
                      <input
                        className="onetap_conn_general_information_input_syn_withicon"
                        type="text"
                        placeholder=""
                        name="LogoAlt"
                        value={logoAlt}
                        onChange={(e) => {
                          logoAltChange(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="branding_upload_div d-flex flex-column align-items-center">
                  <div className="image-uploader w-100">
                    {!logo && !imageURL ? (
                      <label
                        htmlFor="upload-input"
                        className="upload-box"
                        onClick={handleUploaderClick2}
                      >
                        <div className="image-preview">
                          {companyDetails?.logopath ? (
                            <img
                              src={`${url}/logo/img/${company?.logopath}`}
                              alt="Default"
                              style={{ borderRadius: "none" }}
                            />
                          ) : (
                            <span className="upload-icon">
                              <svg
                                width="46"
                                height="34"
                                viewBox="0 0 46 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                  fill="#838383"
                                />
                              </svg>{" "}
                              <br />
                              <p className="onetap_conn_general_information_para_syn m-0">
                                Upload
                              </p>
                            </span>
                          )}
                        </div>
                      </label>
                    ) : (
                      <>
                        <label
                          htmlFor="upload-input"
                          className="upload-box"
                          onClick={() => { setOpenCropModal(true) }}
                        >
                          <div className="image-preview">
                            {logo && (
                              <img
                                src={croppedImage || URL.createObjectURL(logo)}
                                alt="Uploaded"
                                style={{ borderRadius: "none" }}
                              />
                            )}
                          </div></label> <span
                            className="remove-icon"
                            onClick={handleRemoveImage2}
                          >
                          &#10005;
                        </span></>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef2}
                      accept="image/*"
                      name="logoimage"
                      style={{ display: "none" }}
                      onChange={handleImageUpload2}
                    />
                  </div>
                  {companyDetails?.logopath && <div className="d-flex justify-content-center m-2">
                    <div style={{ marginRight: '10px' }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} onClick={handleUploaderClick2}>
                        Upload
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef2}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload2}
                      />
                    </div>
                    <div onClick={() => { setisdeletelogomodalopen(true) }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} >
                        Remove
                      </button>
                    </div>
                  </div>}
                  <div style={{ textAlign: "center", color: "#000" }}>
                    Accepted file type: SVG, PNG, JPG
                  </div>
                </div>
              </div>
            </div>

            <div className="onetap_conn_general_information_syn">
              <div className="branding_logo_and_upload_frame">
                <div className="branding_logo_div ">
                  <h5 className="onetap_conn_contact_company_name_and_domain_syn">
                    Favicon
                  </h5>
                  <p style={{ color: "#000000" }}>
                    The Favicon (square format of your logo) will be use
                    throughout the platform and can be used as app icon when
                    sharing your card with your customer.
                  </p>
                  <div className="w-100" style={{ paddingBottom: "30px" }}>
                    <p className="onetap_conn_general_information_para_syn mb-1">
                      Favicon name
                    </p>
                    <input
                      className="onetap_conn_general_information_input_syn_withicon"
                      type="text"
                      placeholder=""
                      name="Favicon"
                      value={favIcon}
                      onChange={(e) => {
                        { favIconChange(e.target.value); setfaviconerrormsj("") }
                      }}
                    />
                    {faviconerrormsj ? (
                      <p className="error_message">{faviconerrormsj}</p>
                    ) : null}
                  </div>
                </div>
                <div className="branding_upload_div d-flex flex-column align-items-center">
                  <div className="image-uploader">
                    {!faviconicon && !imageURL ? (
                      <label
                        htmlFor="upload-input"
                        className="upload-box"
                        onClick={handleUploaderClick}
                      >
                        <div className="image-preview">
                          {companyDetails?.fav_icon_path ? (
                            <img
                              src={`${url}/favicon/img/${company?.fav_icon_path}`}
                              alt="Default"
                              style={{ borderRadius: "none", width: "90%" }}
                            />
                          ) : (
                            <span className="upload-icon">
                              <svg
                                width="46"
                                height="34"
                                viewBox="0 0 46 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.9173 33.6334H11.5423C8.3826 33.6334 5.6826 32.5396 3.44232 30.3521C1.20204 28.1646 0.0825968 25.491 0.0839857 22.3313C0.0839857 19.623 0.899958 17.2098 2.5319 15.0917C4.16385 12.9737 6.29926 11.6195 8.93815 11.0292C9.80621 7.83477 11.5423 5.24796 14.1465 3.2688C16.7507 1.28963 19.702 0.300049 23.0007 0.300049C27.0632 0.300049 30.5097 1.71533 33.3402 4.54588C36.1708 7.37644 37.5854 10.8223 37.584 14.8834C39.9798 15.1612 41.968 16.1945 43.5486 17.9834C45.1291 19.7723 45.9187 21.8639 45.9173 24.2584C45.9173 26.8625 45.0055 29.0764 43.1819 30.9C41.3583 32.7236 39.1451 33.6348 36.5423 33.6334H25.084V18.7375L28.4173 21.9667L31.334 19.05L23.0007 10.7167L14.6673 19.05L17.584 21.9667L20.9173 18.7375V33.6334Z"
                                  fill="#838383"
                                />
                              </svg>{" "}
                              <br />
                              <p className="onetap_conn_general_information_para_syn m-0">
                                Upload
                              </p>
                            </span>
                          )}
                        </div>
                      </label>
                    ) : (
                      <>
                        <label
                          htmlFor="upload-input"
                          className="upload-box"
                          onClick={() => { setOpenCropfavModal(true) }}
                        >
                          <div className="image-preview">
                            {faviconicon && (
                              <img
                                src={
                                  croppedfavImage ||
                                  URL.createObjectURL(faviconicon)
                                }
                                alt="Uploaded"
                                style={{ borderRadius: "none" }}
                              />
                            )}


                          </div></label> <span
                            className="remove-icon"
                            onClick={handleRemoveImage}
                          >
                          &#10005;
                        </span></>
                    )}

                    <input
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      name="faviconimage"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </div>
                  {companyDetails?.fav_icon_path && <div className="d-flex justify-content-center m-2">
                    <div style={{ marginRight: '10px' }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} onClick={handleUploaderClick}>
                        Upload
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        name="profilePicture"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                    </div>
                    <div onClick={() => { setisdeletefaviconmodalopen(true) }}>
                      <button style={{ width: '100%', color: 'white', padding: "4px 20px", margin: '2px', borderRadius: '4px', border: 'none', backgroundColor: "#e65925" }} >
                        Remove
                      </button>
                    </div>
                  </div>}
                  <div
                    className="d-flex justify-content-center"
                    style={{ textAlign: "center", color: "#000" }}
                  >
                    Accepted file type: SVG, PNG, JPG
                  </div>
                </div>
              </div>
            </div>

            <div className="onetap_conn_general_information_syn">
              <div className="branding_logo_and_upload_frame">
                <div className="branding_logo_div ">
                  <h5
                    className="onetap_conn_contact_informations_syn"
                    style={{ color: "#000" }}
                  >
                    Colors
                  </h5>
                  <p style={{ color: "#000" }}>
                    The primary and accent colors will be use as the default colors for your OneTapConnect microsite.
                    The “Additional colors” will also be available to select while designing your template/microsite allowing you to customize the color of specific elements.
                  </p>
                </div>
                <div
                  className="branding_upload_div d-flex flex-column"
                  style={{ padding: " 26px 10px 10px 10px" }}
                >
                  <span
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "600",
                      borderBottom: '1px solid #f2f2f2',
                      width: '100%'
                    }}
                  >
                    {" "}
                    Theme colors
                  </span>
                  <div className="branding_primary_secondary_div">
                    <div className="branding_color_tab">
                      <span className="branding_color_tab_text">
                        Primary color:
                      </span>
                      {company?.primary_color ? (
                        <BrandingColorPicker
                          key={company?.primary_color.name}
                          forceUpdate={forceUpdate}
                          defaultColor={
                            company?.primary_color.code || colors[0]
                          }
                          name={company?.primary_color.name}
                          addColorToAdditionalColors={updatePrimaryColor}
                        />
                      ) : (
                        <BrandingColorPicker
                          defaultColor={colors[0]}
                          addColorToAdditionalColors={updatePrimaryColor}
                          name="Primary"
                        />
                      )}
                    </div>

                    <div className="branding_color_tab">
                      <span className="branding_color_tab_text">
                        Accent color:
                      </span>
                      {company?.accent_color ? (
                        <BrandingColorPicker
                          key={company?.accent_color.name}
                          forceUpdate={forceUpdate}
                          defaultColor={
                            company?.accent_color.code || colors[3]
                          }
                          name={company?.accent_color.name}
                          addColorToAdditionalColors={updateAccentColor}
                        />
                      ) : (
                        <BrandingColorPicker
                          defaultColor={colors[3]}
                          addColorToAdditionalColors={updateAccentColor}
                          name="Accent"
                        />
                      )}
                    </div>
                  </div>
                  <span
                    style={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Additional colors
                  </span>

                  {additionalColors?.map((color, index) => (
                    <div key={index} className="branding_color_tab">
                      {/* <span className="branding_color_tab_text">{color.name}</span> */}
                      <input
                        type="text"
                        onChange={(e) =>
                          handleColorNameChange(index, e.target.value)
                        } // Use the index as the identifier
                        value={customColorNames[index] || color.name} // Use the stored name or the default name
                        className="onetap_connect_branding_comp_prof_color_default_picker_syn"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "50%",
                        }}
                      />
                      <BrandingColorPicker
                        key={color.name}
                        forceUpdate={forceUpdate}
                        index={index}
                        defaultColor={color.code}
                        name={index}
                        addColorToAdditionalColors={addColorToAdditionalColors}
                      />

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveAdditionalColor(index)}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                          fill="#8A8A8A"
                        />
                      </svg>
                    </div>
                  ))}
                  <div className="onetap_connect_branding_comp_prof_color_picker_with_hash_syn">
                    {additionalColors?.length >= 5 ? (
                      <> </>
                    ) : (
                      <>
                        {" "}
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.2499 10.6195H10.7916V17.0778C10.7916 17.4204 10.6555 17.7489 10.4133 17.9911C10.171 18.2334 9.84249 18.3695 9.49992 18.3695C9.15735 18.3695 8.82881 18.2334 8.58657 17.9911C8.34434 17.7489 8.20825 17.4204 8.20825 17.0778V10.6195H1.74992C1.40735 10.6195 1.07881 10.4834 0.836573 10.2411C0.594338 9.99891 0.458252 9.67037 0.458252 9.3278C0.458252 8.98523 0.594338 8.65669 0.836573 8.41445C1.07881 8.17222 1.40735 8.03613 1.74992 8.03613H8.20825V1.5778C8.20825 1.23523 8.34434 0.906687 8.58657 0.664453C8.82881 0.422218 9.15735 0.286133 9.49992 0.286133C9.84249 0.286133 10.171 0.422218 10.4133 0.664453C10.6555 0.906687 10.7916 1.23523 10.7916 1.5778V8.03613H17.2499C17.5925 8.03613 17.921 8.17222 18.1633 8.41445C18.4055 8.65669 18.5416 8.98523 18.5416 9.3278C18.5416 9.67037 18.4055 9.99891 18.1633 10.2411C17.921 10.4834 17.5925 10.6195 17.2499 10.6195Z"
                            fill="#E65925"
                          />
                        </svg>
                        <span
                          style={{
                            paddingLeft: "10px",
                            color: "#E65925",
                            marginBottom: "30px",
                          }}
                          onClick={addCustomColor}
                        >
                          Add Color
                        </span>
                      </>
                    )}
                    <Modal
                      open={openModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      onClose={() => {
                        setOpenModal(false);
                        setColorNameErrorMessage("");
                        setColorCodeErrorMessage("");
                      }}
                    >
                      <Box sx={style}>
                        <div className="Modal-section">
                          <h2 className="header">
                            Add Color{" "}
                            <CloseIcon
                              className="icon"
                              onClick={() => {
                                setOpenModal(false);
                                setColorNameErrorMessage("");
                                setColorCodeErrorMessage("");
                              }}
                            />
                          </h2>

                          <span className="Team-Name-Text">Color Name</span>
                          <br />
                          <div>
                            <input
                              type="text"
                              name="colorName"
                              className="input-field"
                              placeholder=""
                            />
                            {colorNameErrorMessage && (
                              <p className="error_message">
                                {colorNameErrorMessage}
                              </p>
                            )}
                            <span className="Team-Name-Text">Color Code</span>
                            <input
                              type="text"
                              name="colorcode"
                              className="input-field"
                              placeholder=""
                              maxLength={6}
                            />
                            {colorCodeErrorMessage && (
                              <p className="error_message">
                                {colorCodeErrorMessage}
                              </p>
                            )}
                          </div>
                          <div className="Modal-btn-group">
                            <button
                              className="Modal-btn first-modal-btn"
                              onClick={() => {
                                const colorNameInput = document.querySelector(
                                  'input[name="colorName"]'
                                );
                                const colorCodeInput = document.querySelector(
                                  'input[name="colorcode"]'
                                );
                                const colorName = colorNameInput.value.trim();
                                const colorCode = colorCodeInput.value
                                  .trim()
                                  .toUpperCase();
                                const validColorCode = /^[a-fA-F0-9]{6}$/i.test(
                                  colorCode
                                );

                                // Reset previous error messages
                                setColorNameErrorMessage("");
                                setColorCodeErrorMessage("");

                                if (
                                  colorName &&
                                  validColorCode &&
                                  additionalColors.length < 5
                                ) {
                                  const capitalizedColorName =
                                    colorName.charAt(0).toUpperCase() +
                                    colorName.slice(1);
                                  setAdditionalColors([
                                    ...additionalColors,
                                    {
                                      name: capitalizedColorName,
                                      code: colorCode,
                                    },
                                  ]);
                                  setOpenModal(false);
                                } else {
                                  // Handle errors
                                  if (!colorName) {
                                    setColorNameErrorMessage(
                                      "Please enter a color name."
                                    );
                                  }
                                  if (!colorCode) {
                                    setColorCodeErrorMessage(
                                      "Please enter color code."
                                    );
                                  } else if (!validColorCode) {
                                    setColorCodeErrorMessage(
                                      "Invalid color code."
                                    );
                                  }
                                }
                              }}
                            >
                              Add
                            </button>
                            <button
                              className="Modal-btn sec-modal-btn"
                              onClick={() => {
                                setOpenModal(false);
                                setColorNameErrorMessage("");
                                setColorCodeErrorMessage("");
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="d-flex justify-content-end">
                <button
                  className="onetap_connect__comp_profile_save_button_syn"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="onetap_connect__comp_profile_save_and_cont_button_syn"
                  onClick={async (e) => {
                    const saveSuccess = await handleSave();
                    if (saveSuccess) {
                      setTimeout(() => {
                        props.onClickSave("Edit Card");
                      }, 3000);
                    }
                  }}
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal open={openCropModal} onClose={handlecropmodalclose}>
        <div>
          <div style={style} className="modal-content">
            {/* Display the selected file */}
            {selectedlogo && (
              <div style={{ width: "100%", height: "80%" }}>
                <img
                  src={croppedImage}
                  ref={cropperRef}
                  alt="Selected Image"
                  style={{ maxWidth: "50%" }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                margin: "10px 0px",
              }}
            >
              <Tooltip title="Flip vertical" arrow>
                <SwapVertOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswapupdown}
                />
              </Tooltip>
              <Tooltip title="Flip horizontal" arrow>
                <SwapHorizOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswaprightleft}
                />
              </Tooltip>
              <Tooltip title="Zoom in" arrow>
                <ZoomInIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoominlogo}
                />
              </Tooltip>
              <Tooltip title="Zoom out" arrow>
                <ZoomOutIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoomoutlogo}
                />
              </Tooltip>
              <Tooltip title="Move Up" arrow>
                <ArrowUpwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoup}
                />
              </Tooltip>
              <Tooltip title="Move down" arrow>
                <ArrowDownwardOutlined
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogodown}
                />
              </Tooltip>
              <Tooltip title="Move left" arrow>
                <ArrowBackOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoleft}
                />
              </Tooltip>
              <Tooltip title="Move right" arrow>
                <ArrowForwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoright}
                />
              </Tooltip>
              <Tooltip title="Rotate left" arrow>
                <Rotate90DegreesCcwIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotate}
                />
              </Tooltip>
              <Tooltip title="Rotate right" arrow>
                <Rotate90DegreesCwOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotateback}
                />
              </Tooltip>
              <Tooltip title="Reset" arrow>
                <RestartAltIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleresetlogo}
                />
              </Tooltip>
            </div>
            <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={handleCrop}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Crop Image
              </Button>
              <Button
                onClick={handleuploadorignal}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Upload
              </Button>
              <Button
                onClick={handlecropmodalclose}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={openCropfavModal} onClose={handlecropfavmodalclose}>
        <div>
          <div style={style} className="modal-content">
            {selectedfavicon && (
              <div style={{ width: "100%", height: "80%" }}>
                <img
                  src={croppedfavImage}
                  ref={cropperRef}
                  alt="Selected Image"
                  style={{ maxWidth: "50%" }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              <Tooltip title="Flip vertical" arrow>
                <SwapVertOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswapupdown}
                />
              </Tooltip>
              <Tooltip title="Flip horizontal" arrow>
                <SwapHorizOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleswaprightleft}
                />
              </Tooltip>
              <Tooltip title="Zoom in" arrow>
                <ZoomInIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoominlogo}
                />
              </Tooltip>
              <Tooltip title="Zoom out" arrow>
                <ZoomOutIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlezoomoutlogo}
                />
              </Tooltip>
              <Tooltip title="Move Up" arrow>
                <ArrowUpwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoup}
                />
              </Tooltip>
              <Tooltip title="Move down" arrow>
                <ArrowDownwardOutlined
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogodown}
                />
              </Tooltip>
              <Tooltip title="Move left" arrow>
                <ArrowBackOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoleft}
                />
              </Tooltip>
              <Tooltip title="Move right" arrow>
                <ArrowForwardOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlelogoright}
                />
              </Tooltip>
              <Tooltip title="Rotate left" arrow>
                <Rotate90DegreesCcwIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotate}
                />
              </Tooltip>
              <Tooltip title="Rotate right" arrow>
                <Rotate90DegreesCwOutlinedIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handlerotateback}
                />
              </Tooltip>
              <Tooltip title="Reset" arrow>
                <RestartAltIcon
                  fontSize="large"
                  style={{
                    backgroundColor: "#E65925",
                    color: "#ffff",
                    borderRadius: "4px",
                    padding: "2px",
                    margin: "0px 5px",
                  }}
                  onClick={handleresetlogo}
                />
              </Tooltip>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handlefavCrop}
                style={{
                  color: "#ffff",
                  backgroundColor: "#E65925",
                  width: "50%", margin: '5px'
                }}
              >
                Crop Image
              </Button>
              <Button
                onClick={handleuploadorignalfavicon}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Upload
              </Button>
              <Button
                onClick={handlecropfavmodalclose}
                style={{ color: "#ffff", backgroundColor: "#E65925", width: "50%", margin: '5px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                  class="circle">
                  <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                    fill="#E65925" />
                  <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                </svg>
              </div>
            </div>
            <span className="team_created_success">Data updated successfully!</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
          </button>
        </Box>
      </Modal>
      {
        isdeletelogomodalopen && <div style={delete_account_modalStyle} >
          <div style={delete_account_modalContentStyle}>
            <div style={delete_accountText}>Are you sure you want to delete  logo?</div>
            <div style={delete_account_iconContainer}>
              <FontAwesomeIcon icon={faTrash} bounce size="2xl" style={delete_account_trashIconStyle} />
            </div>
            <div style={delete_account_buttonContainer}>
              <button style={deleteAccountbuttonStyle} onClick={handlelogoimagedelete}>
                Delete
              </button>
              <button style={deleteAccountcanclebuttonStyle} onClick={() => { setisdeletelogomodalopen(false) }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      }
      {
        isdeletefaviconmodalopen && <div style={delete_account_modalStyle} >
          <div style={delete_account_modalContentStyle}>
            <div style={delete_accountText}>Are you sure you want to delete favicon?</div>
            <div style={delete_account_iconContainer}>
              <FontAwesomeIcon icon={faTrash} bounce size="2xl" style={delete_account_trashIconStyle} />
            </div>
            <div style={delete_account_buttonContainer}>
              <button style={deleteAccountbuttonStyle} onClick={handlefaviconimagedelete}>
                Delete
              </button>
              <button style={deleteAccountcanclebuttonStyle} onClick={() => { setisdeletefaviconmodalopen(false) }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CardProfileBrandingInfoTab;
