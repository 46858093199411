import React, { useEffect, useRef, useState } from "react";
import "./user.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getCompanyProfileDetails,
  getTeamMember,
  getTeamName,
  getinvitedTeamMember
} from "../../../actions/userAction/userAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Avatar } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const Logstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  padding: "25px",
  outline: "none",
  borderRadius: "10px",
};
const CreateTeamModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};

const UserHeadersWithIcons = (props) => {
  useEffect(() => {
    if(team === null){
      dispatch(getTeamName());
    }
  }, [])
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCSVFilePopup, setOpenCSVFilePopup] = React.useState(false);
  const [addManualUserModal, setAddManualUserModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [newTeamId, setNewTeamId] = useState('');
  const [inviteButtonDisable, setInviteButtonDisable] = useState(false);
  const [memberData, setMemberData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    team: "",
  });
  const [CSVMemberData, setCSVMemberData] = useState([]);
  const [manuallyMemberData, setManuallyMemberData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    team: "",
  });
  const [addTeamMemberErrors, setAddTeamMemberErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    team: "",
  });
  const [inviteTeamMemberErrors, setInviteTeamMemberErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    team: "",
  });
  const [apiError, setApiError] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [invitedMemberList, setInvitedMemberList] = useState([]);
  const [fetchTeamData, SetfetchTeamData] = useState(false);
  const { userInfo } = useSelector((state) => state.userInformation);
  const baseUser = userInfo?.subscription_details?.total_user[0]?.baseUser;
  const additionalUser = userInfo?.subscription_details?.total_user[0]?.additionalUser;
  const total_user = baseUser + additionalUser;
  const { user } = useSelector((state) => state.user);
  const manager_firstname = user && user.first_name
  const manager_email = user && user.email;

  const handleInviteInputChange = (e) => {
    setApiError("");
    const { name, value } = e.target;
    setInviteTeamMemberErrors({ ...inviteTeamMemberErrors, [name]: "" });
    setMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };
  const handleManuallyInputChange = (e) => {
    setApiError("");
    const { name, value } = e.target;
    setAddTeamMemberErrors({ ...addTeamMemberErrors, [name]: "" });
    setManuallyMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

  const handleInviteSubmit = async () => {
    setInviteButtonDisable(true)
    let formIsValid = true;
    const NewErrors = { ...inviteTeamMemberErrors };

    // Check first_name
    if (memberData.first_name.trim() === "") {
      NewErrors.first_name = "Please enter first name.";
      formIsValid = false;
    } else {
      NewErrors.first_name = ""; // Clear the error
    }

    // Check last_name
    if (memberData.last_name.trim() === "") {
      NewErrors.last_name = "Please enter last name.";
      formIsValid = false;
    } else {
      NewErrors.last_name = ""; // Clear the error
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (memberData.email.trim() === "") {
      NewErrors.email = "Please enter email.";
      formIsValid = false;
    } else if (!emailPattern.test(memberData.email.trim())) {
      NewErrors.email = "Please enter a valid email address";
      formIsValid = false;
    } else if (userEmails?.includes(memberData.email)) {
      NewErrors.email = "Email is already existing.";
      formIsValid = false;
    }

    setInviteTeamMemberErrors(NewErrors);

    if (formIsValid) {
      try {
        const { data } = await axios.post(
          `${url}/invite/user`,
          {
            memberData: allInvitedMembers,
            manager_email: manager_email,
            manager_firstname: manager_firstname,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (data.success) {
          setInviteButtonDisable(false)
          setSuccessMessage(`Invitation sent successfully!`);
          setTimeout(() => {
            setMemberData({
              first_name: "",
              last_name: "",
              email: "",
              team: "",
            });
          }, 1000);
          setTimeout(() => {
            setSuccessMessage('');
            dispatch(getinvitedTeamMember());
          }, 3000);
        }
      } catch (error) {
        setInviteButtonDisable(false)
        if (error.response && error.response.data) {
          if (error.response.data.message === "This email is already in use.") {
            setApiError("This email is already in use.")
          }
        }
      }
    } else {
      setInviteButtonDisable(false);
    }
  };


  const handleAddTeamMemberManually = async () => {
    let formIsValid = true;
    const newErrors = { ...addTeamMemberErrors };

    // Check first_name
    if (manuallyMemberData.first_name.trim() === "") {
      newErrors.first_name = "Please enter first name.";
      formIsValid = false;
    }
    // Check last_name
    if (manuallyMemberData.last_name.trim() === "") {
      newErrors.last_name = "Please enter last name.";
      formIsValid = false;
    }

    // Check email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (manuallyMemberData.email.trim() === "") {
      newErrors.email = "Email is required.";
      formIsValid = false;
    } else if (!emailPattern.test(manuallyMemberData.email.trim())) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    }

    setAddTeamMemberErrors(newErrors);

    if (formIsValid) {
      try {

        const { data } = await axios.post(
          `${url}/invite/userByCSV`,
          { CSVMemberData: allManuallyMembers },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (data.success) {
          handleAddManualUserModalClose();
          setManuallyMemberData({
            first_name: "",
            last_name: "",
            email: "",
            team: "",
          });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setApiError(error.response.data.message);
        }
      }
    }
  };

  const allInvitedMembers = [...invitedMemberList, memberData];
  const allManuallyMembers = [...memberList, manuallyMemberData];


  function handleMemberInputChange(e, index) {
    const { name, value } = e.target;
    const updatedMemberList = [...memberList];
    updatedMemberList[index][name] = value;
    setMemberList(updatedMemberList);
  }

  const handleAddManualUserModalClose = () => {
    setAddManualUserModal(false);
    setAddTeamMemberErrors("");
    setApiError("");
    setMemberList([]);
    setManuallyMemberData({});
    setManuallyMemberData({
      first_name: "",
      last_name: "",
      email: "",
      team: "",
    });
  };
  const handleinvitedModalClose = () => {
    setApiError("")
    setOpen(false);
    setInviteTeamMemberErrors("");
    setInvitedMemberList([]);
    setMemberData({});
    setMemberData({
      first_name: "",
      last_name: "",
      email: "",
      team: "",
    });
  };

  const { users } = useSelector((state) => state.Users);
  const totalactiveusers = parseInt(users?.filter(user => user.status === 'active').length, 10);
  const totalDeactiveusers = parseInt(users?.filter(user => user.status === 'Deactivate').length, 10);
  const userEmails = users?.map((user) => user.email)
  const { company } = useSelector((state) => state.Company);
  const { team } = useSelector((state) => state.team);
  const allTeamName = team?.map((e) => e.team_name)
  const [selectedUser, setSelectedUser] = useState([]);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [Errors, setErrors] = useState({});
  const [openTeamModal, setOpenTeamModal] = React.useState(false);
  const handleOpen = () => {
    setOpenTeamModal(true);
    setCreateTeam("");
    setIsButtonClicked(false)
    setTeamDataErrors({ teamName: "" });
  };
  const handleClose = () => setOpenTeamModal(false);
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false);
  const [CreateTeam, setCreateTeam] = useState("");
  const [teamDataErrors, setTeamDataErrors] = useState({});
  const url = process.env.REACT_APP_URL;
  const teamOptions = [];
  const [isFileValid, setIsFileValid] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [logEntry, setLogEntry] = useState([]);
  const [openLogPopup, setOpenLogPopup] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const fetchData = async () => {
    try {
      if (team === null) {
        await dispatch(getTeamName());
        if(team){
          for (let i = 0; i < team?.length; i++) {
            teamOptions.push({
              value: team[i].team_name,
              label:
              team[i].team_name.charAt(0)?.toUpperCase() +
              team[i].team_name.slice(1),
            });
          }
        }
      } else {
        // Process the team data if it's already available
        for (let i = 0; i < team?.length; i++) {
          teamOptions.push({
            value: team[i].team_name,
            label:
              team[i].team_name.charAt(0)?.toUpperCase() +
              team[i].team_name.slice(1),
          });
        }
      }
    } catch (error) {
      // Handle errors if any
      console.error("Error fetching team data:", error);
    }
  };

  useEffect(() => {  
    if (team === null) {
      fetchData();
    }
  }, []);
  // useEffect(() => {  
  //   if (team === null) {
  //     fetchData();
  //   }
  // }, [team, teamOptions, dispatch]);
  // do not remove this commented code  
  

  function createData(
    id,
  ) {
    return {
      id,
    };
  }
  // Create a function to convert API response data to the desired format
  function convertAPIDataToRow(apiData) {
    return apiData?.map((item) => {
      const id = item._id;
      const avatar = item.avatar;
      const name = `${item.first_name} ${item.last_name}`;
      const role = item?.designation?.join(" | ");

      // Mapping the rest of the properties
      const group = item?.team; // You can fill this based on your requirement
      const location = item.billing_address?.state;
      const status = item?.status;

      return createData(
        id,
      );
    });
  }
  useEffect(() => {
    if (!users) {
      dispatch(getTeamMember());
    }
    setRows(convertAPIDataToRow(users));

    if (updatedFlag) {
      dispatch(getTeamMember());
      setUpdatedFlag(false);
    }
  }, [users, dispatch, updatedFlag]);
  const { tab } = props;

  const handleClickOfTab = (value) => {
    setCompanyProfileTab(value);
    props.onChange(value);
  };
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCompanyProfileTab(tab);
  }, [tab]);

  const handleAddtoTeamCreateModal = async () => {
    if (isApiCallInProgress) {
      // API call is already in progress, ignore the click
      return;
    }

    setIsApiCallInProgress(true);

    try {
      if (team?.length >= 6) {
        setTeamDataErrors({ teamName: "You can create only 6 teams." });
      } else if (!CreateTeam || CreateTeam.trim() === "") {
        setTeamDataErrors({ teamName: "Please enter a team name." });
      } else {
        const normalizedCreateTeam = CreateTeam.trim().toLowerCase();
        const normalizedAllTeamName = allTeamName.map(team => team.toLowerCase());

        if (normalizedAllTeamName.includes(normalizedCreateTeam)) {
          setTeamDataErrors({ teamName: "Team already exists." });
        } else {
          const createTeamResponse = await axios.post(
            `${url}/user/create/team`,
            { team_name: CreateTeam },
            { withCredentials: true }
          );

          setNewTeamId(createTeamResponse.data.team._id);

          if (createTeamResponse.data.message === "Team created successfully") {
            dispatch(getTeamName());
            dispatch(getTeamMember());
            dispatch(getCompanyProfileDetails());
            setOpenCreateTeamModal(true);
            setSelectedUser([]);
            setErrors({ error: "" });
            handleClose();
          }
        }
      }
    } catch (error) {
      ;
    } finally {
      setIsApiCallInProgress(false);
    }
  };

  const handleAddtoTeamCreate = async () => {
    if (!CreateTeam) {
      setTeamDataErrors({ teamName: "Please enter a team name" });
    } else {
      try {
        setIsButtonClicked(true)
        setOpenCreateTeamModal(false);
        const updateResponse = await axios.post(
          `${url}/user/update/users/team`,
          { team: newTeamId, selectedUsers: selectedUser , type: "add"}, // Pass the new team ID
          { withCredentials: true }
        );
        setApiSuccess(true);
        dispatch(getTeamName())
        dispatch(getTeamMember());
        dispatch(getCompanyProfileDetails());

      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setTeamDataErrors({ teamName: error.response.data.message });
        }
      }
    }
  };
  const successModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "background.paper",
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleUserCheckboxChangeForDiv = (user) => {

    const isSelected = selectedUser.some(
      (selectedUser) => selectedUser._id === user._id
    );

    if (isSelected) {
      setSelectedUser((prevSelectedUser) =>
        prevSelectedUser.filter((selectedUser) => selectedUser._id !== user._id)
      );
    } else {
      setSelectedUser((prevSelectedUser) => [...prevSelectedUser, {_id:user._id}]);
    }

  };

  const filteredUsers =
    users &&
    users?.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const designation = user.designation.join(" | ").toLowerCase();
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        designation.includes(searchQuery.toLowerCase())
      );
    });

  const handleCSVFileSubmit = async () => {
    setInviteButtonDisable(true);
    try {
      const { data } = await axios.post(
        `${url}/invite/userByCSV`,
        { CSVMemberData },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (data.success) {
        setInviteButtonDisable(false)
        setSuccessMessage(`Import successful...`);
        setTimeout(() => {
          setLogEntry(data.existingMails)
          setIsFileValid(false);
          setOpenCSVFilePopup(false);
          setOpenLogPopup(true);
          dispatch(getTeamMember());
        }, 3000);
      }
    } catch (error) {
      setInviteButtonDisable(false)
      setValidationError(error.response.data.message);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileError = document.getElementById("file-error");

    if (selectedFile) {
      const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "text/csv",
      ];

      if (!allowedFileTypes.includes(selectedFile.type)) {
        e.target.value = "";
        fileError.style.color = "red";
        fileError.textContent = "Please select a valid CSV or XLSX file.";
        setIsFileValid(false);
      } else {
        fileError.style.color = "black";
        fileError.textContent = "Valid types are .csv and .xlsx";

        if (
          selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          selectedFile.type === "application/vnd.ms-excel"
        ) {
          // Handle XLSX file
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            // Assuming there's only one sheet in the XLSX file
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert XLSX sheet data to an array of objects
            const xlsxData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Convert array of arrays into array of objects with headers
            const headers = xlsxData[0];
            const xlsxDataObjects = xlsxData.slice(1).map((row) => {
              const rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              return rowData;
            });

            handleData(xlsxDataObjects);
          };
          reader.readAsArrayBuffer(selectedFile);
        } else if (selectedFile.type === "text/csv") {
          // Handle CSV file
          Papa.parse(selectedFile, {
            header: true, // Use the first row as headers
            dynamicTyping: true, // Automatically detect data types
            skipEmptyLines: true, // Skip empty lines
            complete: function (results) {
              handleData(results.data);
            },
            error: function (error) {
              ;
            },
          });
        }
      }
    }
  };

  const handleData = (data) => {
    // Validate the data
    const validationErrors = validateData(data);

    if (validationErrors.length === 0) {
      setParsedData(data);
      setValidationError("");
      setIsFileValid(true);
      setCSVMemberData(data);
    } else {
      setIsFileValid(false);
      setParsedData([]);
      setValidationError(
        "The file must contain First name, Last name, Email, and Team fields, and none of them should be empty."
      );
      const fileInput = document.getElementById("csv_file");
      if (fileInput) {
        fileInput.value = null;
      }
    }
  };

  const validateData = (data) => {
    const requiredFields = ["First name", "Last name", "Email", "Team"];
    const validationErrors = [];

    data.forEach((row, index) => {
      requiredFields.forEach((field) => {
        if (!row[field] || row[field].trim() === "") {
          validationErrors.push(
            `Row ${index + 1}: ${field} is missing or empty.`
          );
        }
      });
    });

    return validationErrors;
  };

  // Function to generate CSV data from an array of objects
  const generateCSVData = () => {
    const data = [
      {
        "First name": "Zorita",
        "Last name": "Sharpe",
        "Email": "bycib@mailinator.com",
        "Team": "Sales",
      },
      {
        "First name": "Wynter",
        "Last name": "Dominguez",
        "Email": "lydedybufy@mailinator.com",
        "Team": "Designing",
      },
    ];

    const header = Object.keys(data[0]).join(",");
    const csv = [header];

    data.forEach((item) => {
      const row = Object.values(item).join(",");
      csv.push(row);
    });

    return csv.join("\n");
  };

  // Function to trigger the CSV download
  const handleDownload = () => {
    const csvData = generateCSVData();
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleAddUserManuallyTab = () => {
    navigate("/users/add-manual-user")
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <p className="onetap_conn_user_header">
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "#2C2C2C" }}
        >
          Dashboard
        </Link>
        &nbsp; {">"} &nbsp;   <Link
          to="/users"
          style={{ textDecoration: "none", color: "#2C2C2C" }}
          onClick={() => handleClickOfTab("Users")}
        >
          Users
        </Link>
        &nbsp; {">"} &nbsp; {""}
        <span className="onetap_conn_header_highlight">{tab}</span>
      </p>
      <div className="onetap_conn_user_header_main">
        <div className="onetap_conn_user_header_icon">
          <div
            onClick={() => handleClickOfTab("Users")}
            className={
              companyProfileTab === "Users"
                ? "onetap_conn_user_header_icon_and_header_active"
                : "onetap_conn_user_header_icon_and_header_deactive"
            }
          >
            <div
              style={{
                backgroundColor: companyProfileTab === "Users" ? "#E65925" : "",
                width: "34px",
                height: "32px",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M12 0.75C12.9283 0.75 13.8185 1.11875 14.4749 1.77513C15.1313 2.4315 15.5 3.32174 15.5 4.25C15.5 5.17826 15.1313 6.0685 14.4749 6.72487C13.8185 7.38125 12.9283 7.75 12 7.75C11.0717 7.75 10.1815 7.38125 9.52513 6.72487C8.86875 6.0685 8.5 5.17826 8.5 4.25C8.5 3.32174 8.86875 2.4315 9.52513 1.77513C10.1815 1.11875 11.0717 0.75 12 0.75ZM5 3.25C5.56 3.25 6.08 3.4 6.53 3.67C6.38 5.1 6.8 6.52 7.66 7.63C7.16 8.59 6.16 9.25 5 9.25C4.20435 9.25 3.44129 8.93393 2.87868 8.37132C2.31607 7.80871 2 7.04565 2 6.25C2 5.45435 2.31607 4.69129 2.87868 4.12868C3.44129 3.56607 4.20435 3.25 5 3.25ZM19 3.25C19.7956 3.25 20.5587 3.56607 21.1213 4.12868C21.6839 4.69129 22 5.45435 22 6.25C22 7.04565 21.6839 7.80871 21.1213 8.37132C20.5587 8.93393 19.7956 9.25 19 9.25C17.84 9.25 16.84 8.59 16.34 7.63C17.2119 6.50442 17.6166 5.0862 17.47 3.67C17.92 3.4 18.44 3.25 19 3.25ZM5.5 13.5C5.5 11.43 8.41 9.75 12 9.75C15.59 9.75 18.5 11.43 18.5 13.5V15.25H5.5V13.5ZM0 15.25V13.75C0 12.36 1.89 11.19 4.45 10.85C3.86 11.53 3.5 12.47 3.5 13.5V15.25H0ZM24 15.25H20.5V13.5C20.5 12.47 20.14 11.53 19.55 10.85C22.11 11.19 24 12.36 24 13.75V15.25Z"
                  fill={companyProfileTab === "Users" ? "#ffff" : "#000000"}
                />
              </svg>
            </div>
            <p
              style={{ cursor: "pointer" }}
              className={
                companyProfileTab === "Users"
                  ? "onetap_conn_user_active_header"
                  : "onetap_conn_user_deactive_header"
              }
            >
              Users
            </p>
          </div>

          <>
            <div
              onClick={() => handleClickOfTab("Team")}
              className={
                companyProfileTab === "Team"
                  ? "onetap_conn_user_header_icon_and_header_active"
                  : "onetap_conn_user_header_icon_and_header_deactive"
              }
            >
              <div
                style={{
                  backgroundColor:
                    companyProfileTab === "Team" ? "#E65925" : "",
                  width: "34px",
                  height: "32px",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ cursor: "pointer" }}
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM8 17.5C7.33696 17.5 6.70107 17.2366 6.23223 16.7678C5.76339 16.2989 5.5 15.663 5.5 15C5.5 14.337 5.76339 13.7011 6.23223 13.2322C6.70107 12.7634 7.33696 12.5 8 12.5C8.66304 12.5 9.29893 12.7634 9.76777 13.2322C10.2366 13.7011 10.5 14.337 10.5 15C10.5 15.663 10.2366 16.2989 9.76777 16.7678C9.29893 17.2366 8.66304 17.5 8 17.5ZM9.5 8C9.5 7.33696 9.76339 6.70107 10.2322 6.23223C10.7011 5.76339 11.337 5.5 12 5.5C12.663 5.5 13.2989 5.76339 13.7678 6.23223C14.2366 6.70107 14.5 7.33696 14.5 8C14.5 8.66304 14.2366 9.29893 13.7678 9.76777C13.2989 10.2366 12.663 10.5 12 10.5C11.337 10.5 10.7011 10.2366 10.2322 9.76777C9.76339 9.29893 9.5 8.66304 9.5 8ZM16 17.5C15.337 17.5 14.7011 17.2366 14.2322 16.7678C13.7634 16.2989 13.5 15.663 13.5 15C13.5 14.337 13.7634 13.7011 14.2322 13.2322C14.7011 12.7634 15.337 12.5 16 12.5C16.663 12.5 17.2989 12.7634 17.7678 13.2322C18.2366 13.7011 18.5 14.337 18.5 15C18.5 15.663 18.2366 16.2989 17.7678 16.7678C17.2989 17.2366 16.663 17.5 16 17.5Z"
                    fill={companyProfileTab === "Team" ? "#ffff" : "#000000"}
                  />
                </svg>
              </div>
              <p
                className={
                  companyProfileTab === "Team"
                    ? "onetap_conn_user_active_header"
                    : "onetap_conn_user_deactive_header"
                }
                style={{ cursor: "pointer" }}
              >
                Teams
              </p>
            </div>
            <div
              onClick={() => handleClickOfTab("Invited")}
              className={
                companyProfileTab === "Invited"
                  ? "onetap_conn_user_header_icon_and_header_active"
                  : "onetap_conn_user_header_icon_and_header_deactive"
              }
            >
              <div
                style={{
                  backgroundColor:
                    companyProfileTab === "Invited" ? "#E65925" : "",
                  width: "34px",
                  height: "32px",
                  borderRadius: "7px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ cursor: "pointer" }}
                >
                  <g clip-path="url(#clip0_996_5153)">
                    <path
                      d="M10.63 14.1C11.0107 13.2634 11.5525 12.51 12.2245 11.8828C12.8964 11.2556 13.6853 10.7669 14.5462 10.4447C15.407 10.1225 16.3229 9.97298 17.2415 10.0048C18.1601 10.0366 19.0635 10.249 19.9 10.63C20.7366 11.0107 21.49 11.5525 22.1172 12.2245C22.7444 12.8964 23.2331 13.6853 23.5553 14.5462C23.8775 15.407 24.027 16.3229 23.9952 17.2415C23.9634 18.1601 23.751 19.0635 23.37 19.9C22.8155 21.1241 21.9196 22.1621 20.7896 22.8893C19.6596 23.6166 18.3438 24.0023 17 24C14.3 24 11.83 22.44 10.67 20H1V18C1.06 16.86 1.84 15.93 3.34 15.18C4.84 14.43 6.72 14.04 9 14C9.57 14 10.11 14.05 10.63 14.1ZM9 4C10.12 4.03 11.06 4.42 11.81 5.17C12.56 5.92 12.93 6.86 12.93 8C12.93 9.14 12.56 10.08 11.81 10.83C11.06 11.58 10.12 11.95 9 11.95C7.88 11.95 6.94 11.58 6.19 10.83C5.44 10.08 5.07 9.14 5.07 8C5.07 6.86 5.44 5.92 6.19 5.17C6.94 4.42 7.88 4.03 9 4ZM17 22C18.3261 22 19.5979 21.4732 20.5355 20.5355C21.4732 19.5979 22 18.3261 22 17C22 15.6739 21.4732 14.4021 20.5355 13.4645C19.5979 12.5268 18.3261 12 17 12C15.6739 12 14.4021 12.5268 13.4645 13.4645C12.5268 14.4021 12 15.6739 12 17C12 18.3261 12.5268 19.5979 13.4645 20.5355C14.4021 21.4732 15.6739 22 17 22ZM16 14H17.5V16.82L19.94 18.23L19.19 19.53L16 17.69V14Z"
                      fill={
                        companyProfileTab === "Invited" ? "#ffff" : "#000000"
                      }
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_996_5153">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p
                className={
                  companyProfileTab === "Invited"
                    ? "onetap_conn_user_active_header"
                    : "onetap_conn_user_deactive_header"
                }
                style={{ cursor: "pointer" }}
              >
                Invited
              </p>
            </div>
          </>
        </div>

        <>
          {(companyProfileTab === "Users" ||
            companyProfileTab === "Invited") && (
              <div className="onetap_conn_user_header_right">
                <div className="onetap_conn_user_header_allusers">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M1 20V17.2C1 16.6334 1.146 16.1124 1.438 15.637C1.73 15.1617 2.11733 14.7994 2.6 14.55C3.63333 14.0334 4.68333 13.6457 5.75 13.387C6.81667 13.1284 7.9 12.9994 9 13C10.1 13 11.1833 13.1294 12.25 13.388C13.3167 13.6467 14.3667 14.034 15.4 14.55C15.8833 14.8 16.271 15.1627 16.563 15.638C16.855 16.1134 17.0007 16.634 17 17.2V20H1ZM19 20V17C19 16.2667 18.7957 15.5624 18.387 14.887C17.9783 14.2117 17.3993 13.6327 16.65 13.15C17.5 13.25 18.3 13.421 19.05 13.663C19.8 13.905 20.5 14.2007 21.15 14.55C21.75 14.8834 22.2083 15.254 22.525 15.662C22.8417 16.07 23 16.516 23 17V20H19ZM9 12C7.9 12 6.95833 11.6084 6.175 10.825C5.39167 10.0417 5 9.10003 5 8.00003C5 6.90003 5.39167 5.95836 6.175 5.17503C6.95833 4.39169 7.9 4.00003 9 4.00003C10.1 4.00003 11.0417 4.39169 11.825 5.17503C12.6083 5.95836 13 6.90003 13 8.00003C13 9.10003 12.6083 10.0417 11.825 10.825C11.0417 11.6084 10.1 12 9 12ZM19 8.00003C19 9.10003 18.6083 10.0417 17.825 10.825C17.0417 11.6084 16.1 12 15 12C14.8167 12 14.5833 11.979 14.3 11.937C14.0167 11.895 13.7833 11.8494 13.6 11.8C14.05 11.2667 14.396 10.675 14.638 10.025C14.88 9.37503 15.0007 8.70003 15 8.00003C15 7.30003 14.8793 6.62503 14.638 5.97503C14.3967 5.32503 14.0507 4.73336 13.6 4.20003C13.8333 4.11669 14.0667 4.06236 14.3 4.03703C14.5333 4.01169 14.7667 3.99936 15 4.00003C16.1 4.00003 17.0417 4.39169 17.825 5.17503C18.6083 5.95836 19 6.90003 19 8.00003Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div>
                    Users {parseInt(totalactiveusers, 10)}/{parseInt(users?.length, 10)}- ({parseInt(total_user, 10)})
                  </div>
                </div>
                <div style={{ position: "relative" }} ref={dropdownRef}>
                  <div
                    className="onetap_conn_user_header_addusers"
                    onClick={toggleDropdown}
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="34"
                        viewBox="0 0 34 34"
                        fill="none"
                      >
                        <rect width="34" height="34" rx="9" fill="#E65925" />
                        <path
                          d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>Add new Users</div>
                  </div>

                  {isDropdownOpen && (
                    <div className="onetap_conn_add_user_dropdown">
                      <div className="onetap_conn_add_user_dropdown_text">
                        How would you like to add the users?
                      </div>
                      <div
                        className="onetap_conn_add_user_dropdown_option"
                        onClick={() => setOpen(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M22 12V4.5H2V19.5H12M22 17H15M22 17L19.5 14.5M22 17L19.5 19.5"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2 4.5L12 12L22 4.5"
                            stroke="#000000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div>Email Invitation</div>
                      </div>
                      <div
                        className="onetap_conn_add_user_dropdown_option"
                        onClick={handleAddUserManuallyTab}

                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M8 12V10H16V12H8ZM8 8V6H16V8H8ZM6 14H13.5C13.9833 14 14.4333 14.1043 14.85 14.313C15.2667 14.5217 15.6167 14.8173 15.9 15.2L18 17.95V4H6V14ZM6 20H17.05L14.325 16.425C14.225 16.2917 14.1043 16.1873 13.963 16.112C13.8217 16.0367 13.6673 15.9993 13.5 16H6V20ZM18 22H6C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.98 2.195 5.45067 1.99934 6 2H18C18.55 2 19.021 2.196 19.413 2.588C19.805 2.98 20.0007 3.45067 20 4V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22Z"
                            fill="#000000"
                          />
                        </svg>
                        <div>Manually</div>
                      </div>
                      <div
                        className="onetap_conn_add_user_dropdown_option"
                        onClick={() => setOpenCSVFilePopup(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_971_12274)">
                            <path
                              d="M18.6668 2.66663H9.9135L5.3335 7.23996V9.99996H6.66683V9.07329H11.7402V3.99996H18.6668V20H5.3335C5.3335 20.3536 5.47397 20.6927 5.72402 20.9428C5.97407 21.1928 6.31321 21.3333 6.66683 21.3333H18.6668C19.0205 21.3333 19.3596 21.1928 19.6096 20.9428C19.8597 20.6927 20.0002 20.3536 20.0002 20V3.99996C20.0002 3.64634 19.8597 3.3072 19.6096 3.05715C19.3596 2.8071 19.0205 2.66663 18.6668 2.66663ZM10.6668 7.99996H6.66683V7.78663L10.4668 3.99996H10.6668V7.99996Z"
                              fill="#000000"
                            />
                            <path
                              d="M7.96016 17.52C7.89037 17.5798 7.83369 17.6533 7.79368 17.7361C7.75366 17.8188 7.73118 17.9088 7.72763 18.0007C7.72408 18.0925 7.73955 18.184 7.77307 18.2696C7.80659 18.3551 7.85743 18.4328 7.9224 18.4978C7.98737 18.5628 8.06506 18.6136 8.15061 18.6471C8.23616 18.6806 8.32772 18.6961 8.41953 18.6926C8.51134 18.689 8.60143 18.6665 8.68414 18.6265C8.76685 18.5865 8.8404 18.5298 8.90016 18.46L12.6668 14.6667L8.88016 10.88C8.75263 10.7708 8.58858 10.7137 8.42079 10.7202C8.25301 10.7267 8.09385 10.7963 7.97512 10.915C7.85639 11.0337 7.78684 11.1929 7.78036 11.3607C7.77387 11.5284 7.83095 11.6925 7.94016 11.82L10.1335 14H2.00016C1.82335 14 1.65378 14.0703 1.52876 14.1953C1.40373 14.3203 1.3335 14.4899 1.3335 14.6667C1.3335 14.8435 1.40373 15.0131 1.52876 15.1381C1.65378 15.2631 1.82335 15.3334 2.00016 15.3334H10.1535L7.96016 17.52Z"
                              fill="#000000"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_971_12274">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <div>Import from CSV</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </>
        {companyProfileTab === "Team" && (
          <div className="onetap_conn_user_header_right">
            <div>
              <a href="#" className="onetap_conn_user_header_addusers">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    onClick={handleOpen}
                  >
                    <rect width="34" height="34" rx="9" fill="#E65925" />
                    <path
                      d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div onClick={handleOpen}>Create new Team</div>
                <Modal
                  open={openTeamModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{}}
                >
                  <Box sx={style}>
                    <div className="Modal-section">
                      <h4 className="header">
                        Create Team{" "}
                        <CloseIcon className="icon" onClick={handleClose} />
                      </h4>

                      <span className="Team-Name-Text">Team name</span>
                      <br />
                      <div>
                        <input
                          type="text"
                          name="teamName"
                          className="input-field"
                          placeholder="Ex: Sales"
                          value={CreateTeam}
                          onChange={(e) => {
                            setCreateTeam(e.target.value);
                            setTeamDataErrors({});
                          }}
                        />
                        {teamDataErrors.teamName && (
                          <div style={{ color: "red" }}>
                            {teamDataErrors.teamName}
                          </div>
                        )}
                      </div>
                      <div className="Modal-btn-group">
                        <button
                          className="Modal-btn first-modal-btn"
                          onClick={() => {
                            handleAddtoTeamCreateModal();
                          }}
                          disabled={isApiCallInProgress}
                        >
                          {isApiCallInProgress ? "Loading..." : "Create Team"}
                        </button>
                        <button
                          className="Modal-btn sec-modal-btn"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  open={openCreateTeamModal}
                  onClose={() => {
                    setOpenCreateTeamModal(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={CreateTeamModalstyle}>
                    <div className="Modal-section">
                      <div className="header">
                        <div className="main_header">
                          Add users to{" "}
                          {CreateTeam?.charAt(0)?.toUpperCase() +
                            CreateTeam?.slice(1)}{" "}
                          team
                        </div>

                        <div className="sub_header">
                          Select the users you’d like to add to this team
                        </div>
                      </div>

                      <div className="onetap_conn_users_tab_content_addTeam">
                        <div className="onetap_conn_filter_search">
                          <input
                            type="text"
                            placeholder="Search user"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ marginBottom: "10px" }}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            className="onetap_conn_filter_svg"
                          >
                            <path
                              d="M20.35 21L14.05 14.7C13.55 15.1 12.975 15.4167 12.325 15.65C11.675 15.8833 10.9833 16 10.25 16C8.43333 16 6.896 15.3707 5.638 14.112C4.38 12.8533 3.75067 11.316 3.75 9.5C3.75 7.68333 4.37933 6.146 5.638 4.888C6.89667 3.63 8.434 3.00067 10.25 3C12.0667 3 13.604 3.62933 14.862 4.888C16.12 6.14667 16.7493 7.684 16.75 9.5C16.75 10.2333 16.6333 10.925 16.4 11.575C16.1667 12.225 15.85 12.8 15.45 13.3L21.75 19.6L20.35 21ZM10.25 14C11.5 14 12.5627 13.5623 13.438 12.687C14.3133 11.8117 14.7507 10.7493 14.75 9.5C14.75 8.25 14.3123 7.18733 13.437 6.312C12.5617 5.43667 11.4993 4.99933 10.25 5C9 5 7.93733 5.43767 7.062 6.313C6.18667 7.18833 5.74933 8.25067 5.75 9.5C5.75 10.75 6.18767 11.8127 7.063 12.688C7.93833 13.5633 9.00067 14.0007 10.25 14Z"
                              fill="#000000"
                            />
                          </svg>
                        </div>
                        <div className="addUserText">Add users</div>
                        <div className="mappingdiv">
                          {filteredUsers &&
                            filteredUsers?.map((user, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`select-card-users ${selectedUser?.some(
                                    (item) => item._id === user._id
                                  )
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    handleUserCheckboxChangeForDiv(user);
                                    setErrors({ error: "" });
                                  }}
                                >
                                  <div className="info-page">
                                    <div className="header-avatar-container">
                                      {user?.avatar ? (
                                        <Avatar
                                          style={{ width: "48px", height: "48px" }}
                                          src={`${url}/profile/img/${user?.avatar}`}
                                          alt={`${user?.first_name} ${user?.last_name}`}
                                        />
                                      ) : (
                                        <div
                                          className="header-avatar-initials-overlay"
                                          style={{ width: "48px", height: "48px" }}
                                        >
                                          {`${user?.first_name[0]?.toUpperCase()}${user?.last_name[0]?.toUpperCase()}`}
                                        </div>
                                      )}
                                    </div>
                                    <div className="select-card-users-info">
                                      <p className="p1">{`${user?.first_name} ${user?.last_name}`}</p>
                                      <p className="p2">
                                        {user?.designation.join(" | ")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {Errors.error && (
                          <div style={{ color: "red" }}>{Errors.error}</div>
                        )}
                      </div>
                      <div
                        className="Modal-btn-group"
                        style={{ marginTop: "0px" }}
                      >
                        <button
                          className="Modal-btn first-modal-btn"
                          disabled={isButtonClicked}
                          onClick={() => {
                            handleAddtoTeamCreate();
                          }}
                        >
                          Add to team
                        </button>
                        <button
                          className="Modal-btn sec-modal-btn"
                          onClick={() => setOpenCreateTeamModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  open={apiSuccess}
                  onClose={() => setApiSuccess(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={successModalStyle} style={{ textAlign: 'center' }}>
                    <div className="Modal-section">
                      <div className="checkmark-container">
                        <div class="checkmark-container">
                          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none"
                            class="circle">
                            <path d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
                              fill="#E65925" />
                            <path d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30" stroke="white" stroke-width="6.64" stroke-linecap="round" class="checkmark-path" />
                          </svg>
                        </div>
                      </div>
                      <span className="team_created_success">Your team has been created successfully!</span>
                    </div>
                    <button
                      className="Modal-btn sec-modal-btn"
                      style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', width: '13%' }}
                      onClick={() => setApiSuccess(false)}
                    >
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }}><FontAwesomeIcon icon={faXmark} style={{ color: "#e65925", }} /></span>
                    </button>
                  </Box>
                </Modal>

              </a>
            </div>
          </div>
        )}
      </div>
      <div>
        <Modal
          open={open}
          onClose={() => {
            handleinvitedModalClose();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Invite by email
              </div>
              <div className="onetap_conn_send_email_modal_subheader">
                How would you like to add the users?
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
                maxHeight: "380px",
                overflowY: "scroll",
              }}
            >
              <form style={{ paddingTop: "10px" }}>
                <div className="onetap_conn_send_email_form_section1">
                  <div className="onetap_conn_send_email_form_section1_firstname">
                    <label>First name*</label>
                    <input
                      type="text"
                      name="first_name"
                      value={memberData.first_name}
                      onChange={handleInviteInputChange}
                    />
                    <div className="error-message">
                      {inviteTeamMemberErrors.first_name}
                    </div>
                  </div>
                  <div className="onetap_conn_send_email_form_section1_lastname">
                    <label>Last name*</label>
                    <input
                      type="text"
                      name="last_name"
                      value={memberData.last_name}
                      onChange={handleInviteInputChange}
                    />
                    <div className="error-message">
                      {inviteTeamMemberErrors.last_name}
                    </div>
                  </div>
                </div>
                <div className="onetap_conn_send_email_form_section2">
                  <label>Email*</label>
                  <input
                    type="text"
                    name="email"
                    value={memberData.email}
                    onChange={handleInviteInputChange}
                  />
                  <div className="error-message">
                    {inviteTeamMemberErrors.email}
                  </div>
                  <div className="error-message">{apiError}</div>
                </div>
                <div className="onetap_conn_send_email_form_section3">
                  <label>Team</label>
                  <select
                    style={{
                      appearance: "none",
                      textAlign: "center",
                      outline: "none",
                    }}
                    name="team"
                    value={memberData.team}
                    onChange={handleInviteInputChange}
                  >
                    <option>Please select team</option>
                    {team?.map((team) => (
                      <option key={team._id} value={team._id}>{team.team_name}</option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="onetap_conn_send_email_medal_button">
              <button
                className="onetap_conn_send_email_medal_invite_button"
                onClick={handleInviteSubmit}
                disabled={inviteButtonDisable}
              >
                {inviteButtonDisable ? "Loading..." : "Invite users"}
              </button>
              <button
                className="onetap_conn_send_email_medal_cancel_button"
                onClick={() => {
                  handleinvitedModalClose();
                }}
              >
                Cancel
              </button>
            </div>
            {successMessage && (
              <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                {successMessage}
              </div>
            )}
          </Box>
        </Modal>
        <Modal
          open={addManualUserModal}
          onClose={() => {
            handleAddManualUserModalClose();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Manually Add Users
              </div>
              <div className="onetap_conn_send_email_modal_subheader">
                How would you like to add the users?
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
                maxHeight: "380px",
              }}
            >
              <form style={{ paddingTop: "10px" }}>
                {memberList.map((member, index) => (
                  <div>
                    <div
                      key={index}
                      className="onetap_conn_send_email_form_section1"
                    >
                      <div className="onetap_conn_send_email_form_section1_firstname">
                        <label>First name</label>
                        <input
                          type="text"
                          name={`first_name`}
                          value={member.first_name}
                          onChange={(e) => handleMemberInputChange(e, index)}
                        />
                        <div className="error-message">
                          {addTeamMemberErrors[`first_name${index}`]}
                        </div>
                      </div>
                      <div className="onetap_conn_send_email_form_section1_lastname">
                        <label>Last name</label>
                        <input
                          type="text"
                          name={`last_name`}
                          value={member.last_name}
                          onChange={(e) => handleMemberInputChange(e, index)}
                        />
                        <div className="error-message">
                          {addTeamMemberErrors[`last_name${index}`]}
                        </div>
                      </div>
                    </div>
                    <div className="onetap_conn_send_email_form_section2">
                      <label>Email</label>
                      <input
                        type="text"
                        name={`email`}
                        value={member.email}
                        onChange={(e) => handleMemberInputChange(e, index)}
                      />
                      <div className="error-message">
                        {addTeamMemberErrors[`email${index}`]}
                      </div>
                      <div className="error-message">{apiError}</div>
                    </div>
                    <div
                      className="onetap_conn_send_email_form_section3"
                      style={{
                        borderBottom: "1px solid #f2f2f2",
                        paddingBottom: "10px",
                      }}
                    >
                      <label>Team</label>
                      <select
                        style={{
                          appearance: "none",
                          textAlign: "center",
                          outline: "none",
                        }}
                        name={`team`}
                        value={member.team}
                        onChange={(e) => handleMemberInputChange(e, index)}
                      >
                        <option>Please select team.</option>
                        {company?.teams?.map((teams) => (
                          <option>{teams}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
                <div className="onetap_conn_send_email_form_section1">
                  <div className="onetap_conn_send_email_form_section1_firstname">
                    <label>First name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={manuallyMemberData.first_name}
                      onChange={handleManuallyInputChange}
                    />
                    <div className="error-message">
                      {addTeamMemberErrors.first_name}
                    </div>
                  </div>
                  <div className="onetap_conn_send_email_form_section1_lastname">
                    <label>Last name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={manuallyMemberData.last_name}
                      onChange={handleManuallyInputChange}
                    />
                    <div className="error-message">
                      {addTeamMemberErrors.last_name}
                    </div>
                  </div>
                </div>
                <div className="onetap_conn_send_email_form_section2">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    value={manuallyMemberData.email}
                    onChange={handleManuallyInputChange}
                  />
                  <div className="error-message">
                    {addTeamMemberErrors.email}
                  </div>
                  <div className="error-message">{apiError}</div>
                </div>
                <div
                  className="onetap_conn_send_email_form_section3"
                  style={{
                    borderBottom: "1px solid #f2f2f2",
                    paddingBottom: "10px",
                  }}
                >
                  <label>Team</label>
                  <select
                    style={{
                      appearance: "none",
                      textAlign: "center",
                      outline: "none",
                    }}
                    name="team"
                    value={manuallyMemberData.team}
                    onChange={handleManuallyInputChange}
                  >
                    <option>Please select team.</option>
                    {team?.map((team) => (
                      <option key={team._id} value={team.team_name}>{team.team_name}</option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="onetap_conn_send_email_medal_button">
              <button
                className="onetap_conn_send_email_medal_invite_button"
                onClick={handleAddTeamMemberManually}
              >
                Manually Add Users
              </button>
              <button
                className="onetap_conn_send_email_medal_cancel_button"
                onClick={() => handleAddManualUserModalClose()}
              >
                Cancel
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={openCSVFilePopup}
          onClose={() => {
            setOpenCSVFilePopup(false);
            setIsFileValid(false);
            setValidationError("");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Import from CSV
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <form style={{ paddingTop: "10px" }}>
                <div className="onetap_conn_send_email_form_section2">
                  <label>File</label>
                  <input
                    id="csv_file"
                    type="file"
                    name="csv_file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileChange}
                  />
                </div>
                <p id="file-error" style={{ fontSize: "15px" }}>
                  Valid types are .csv and .xlsx
                </p>
                {validationError && (
                  <p style={{ color: "red" }}>{validationError}</p>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div
                    style={{
                      color: "red",
                      cursor: "pointer",
                      display: "flex",
                      gap: "10px",
                    }}
                    onClick={handleDownload}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="21"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM0 16V11H2V14H14V11H16V16H0Z"
                        fill="red"
                      />
                    </svg>
                    <div>Click here to Downlaod CSV Format.</div>
                  </div>
                </div>
              </form>
            </div>

            <div className="onetap_conn_send_email_medal_button">
              <button
                className="onetap_conn_send_email_medal_invite_button_email"
                disabled={!isFileValid || inviteButtonDisable}
                style={{
                  background: isFileValid ? "#e65925" : "#fb8f68",
                  cursor: isFileValid ? "pointer" : "not-allowed",
                }}
                onClick={handleCSVFileSubmit}
              >
                {inviteButtonDisable ? "Loading..." : "Upload Files"}
              </button>
              <button
                className="onetap_conn_send_email_medal_cancel_button"
                onClick={() => {
                  setOpenCSVFilePopup(false);
                  setIsFileValid(false);
                  setValidationError("");
                  setSuccessMessage("");
                }}
              >
                Cancel
              </button>
            </div>
            {successMessage && (
              <div style={{ color: '#00ff00', fontSize: '16px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                {successMessage}
              </div>
            )}
          </Box>
        </Modal>
      </div>

      <div>
        <Modal
          open={openLogPopup}
          onClose={() => {
            setOpenLogPopup(false);
            setIsFileValid(false);
            setValidationError("");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Logstyle}>
            <div
              style={{
                borderBottom: "1px solid #F2F2F2",
                paddingBottom: "10px",
              }}
            >
              <div className="onetap_conn_send_email_modal_header">
                Import Completed
              </div>
            </div>

            <div style={{ overflowY: "auto", maxHeight: "200px" }}>

              {logEntry?.map((entry, index) => (
                <div key={index} className="log-entry">
                  <span className="log-entry-time" style={{ color: entry.emailAlreadyUsed ? "green" : "red" }}>{entry.emailAlreadyUsed ? "[Success]" : "[Failed]"}</span>
                  <span className="log-entry-text">{entry.emailAlreadyUsed ? `Added user '${entry.Email}'` : `Sorry, Email address '${entry.Email}' is already exists!`}</span>
                </div>
              ))}

            </div>
            <div className="onetap_conn_send_email_medal_button">
              <button
                className="onetap_conn_send_email_medal_log"

                style={{
                  background: "#e65925",
                  cursor: "pointer",
                }}
                onClick={() => setOpenLogPopup(false)}
              >
                OK
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default UserHeadersWithIcons;
