import React, { useEffect, useState } from "react";
import "./otc_login.scss";
import phoneImg from "../Otc_assets/images/card-image.svg";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Otc_assets/OTC_ICONS/logoblack.svg";
import open_eye from "../Otc_assets/OTC_ICONS/open_eye.svg";
import close_eye from "../Otc_assets/OTC_ICONS/close_eye.svg";
import { loginAdminUser } from "../Redux/Otc_UserActions";
import { useDispatch, useSelector } from "react-redux";

const Otc_login = () => {
  const ErrorMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#FDE9E9',
    color: "#ea4e4e",
    borderLeft:'5px solid'
};
const successMessageDiv = {
    width: '100%',
    padding: '12px 40px 12px 18px',
    boxSizing: 'border-box',
    fontSize: '18px',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#F0FEEB',
    color: "#48AF25",
    borderLeft:'5px solid'
};
const errorIconstyle = {
    position: 'absolute',
    top: '-8px',
    right: '12px',
    width: '25px',
}
const errorIcon = <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 64 64" id="Error"><circle cx="32" cy="32" r="28" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></circle><line x1="32" x2="32" y1="18" y2="38" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line><line x1="32" x2="32" y1="42" y2="46" fill="none" stroke="#ea4e4e" stroke-miterlimit="10" stroke-width="8" class="colorStroke010101 svgStroke"></line></svg>
const successIcon = <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99906 16.4209L0.789062 10.2109L3.61906 7.38086L6.99906 10.7709L16.8791 0.880859L19.7091 3.71086L6.99906 16.4209Z" fill="#48AF25"/>
</svg>    
const loginInputsStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative',
};
  const { isOtcAdminAuthenticated , adminuser ,Adminerror,adminloading} = useSelector((state) => state.OtcAdminuser);

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeImg, setTypeImg] = useState(open_eye);
  const [type, setType] = useState("password");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();
  const handleShowPassword = () => {
    if (type === "password") {
      setType("text");
      setTypeImg(close_eye);
    } else {
      setType("password");
      setTypeImg(open_eye);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    loginhandler(e);
  };

  const loginhandler = async (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      if (!email) {
        setEmailError("Please enter your email.");

        return false;
      }
      if (!password) {
        setPasswordError("Please enter password.");

        return false;
      }
    }

    if (email !== "") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(email) === false) {
        setEmailError("Please enter a valid email.");

        return false;
      }
    }

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters.");

      return false;
    }
    dispatch(loginAdminUser(email, password, showToastMessage));
  };
  
  useEffect(() => {

    if (Adminerror === "User not found." || Adminerror === "Please enter valid password.") {
        setPasswordError(Adminerror);
        
    }
}, [adminuser, Adminerror , dispatch, navigate]);
  useEffect(() => {
    if (isOtcAdminAuthenticated) {
      
      navigate('/admin/dashboard')
    }
  }, [isOtcAdminAuthenticated,navigate]);
  const showToastMessage = () => {
    setSuccessMessage('Login Successful!')
};
  return (
    <>
      <div className="otc_login_password_container">
        <div className="otc_login_password_left">
          <div className="otc_login_header">
            <Link to="/admin">
              {" "}
              <img src={logo} alt="oneTap Connect logo" srcset="" />
            </Link>
          </div>
          <div className="otc_login_password_information">
            <h1>Login to Your Account</h1>
            <div className="otc_line">
              <span></span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="otc_login_inputs">
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  autoComplete
                  autoFocus
                />
              </div>
              <div className="otc_login_inputs">
                <input
                  type={type}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                />
                <img
                  src={typeImg}
                  alt="Show"
                  srcSet=""
                  className="otc_login_eye"
                  onClick={handleShowPassword}
                />
              </div>
              {(emailError || passwordError) &&       
                         <div style={loginInputsStyle} className="login_inputs">
                            <div style={ErrorMessageDiv}>{emailError ? emailError : passwordError}</div>
                            <span style={errorIconstyle} className="">{errorIcon}</span>
                        </div>}

              {adminloading ? <button type="" disabled style={{opacity:'0.6' , cursor:'not-allowed'}}>Loading...</button> : <button style={{marginBottom:"0"}} type="submit">Login</button>}
              {   successMessage && 
                         <div style={loginInputsStyle} className="login_inputs">
                            <div style={successMessageDiv}>{successMessage}</div>
                            <span style={errorIconstyle} className="">{successIcon}</span>
                        </div>} 
            </form>

            <Link style={{marginTop:"40px"}} to="/admin/forgot-password">Forgot your password? </Link>
          </div>
        </div>
        <div className="otc_login_password_right">
          <h3>Meet Kimba</h3>
          <img src={phoneImg} alt="phoneImg" />
        </div>
      </div>
    </>
  );
};

export default Otc_login;
