import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/base";

import { useSelector, useDispatch } from "react-redux";
import { Box, Modal } from "@mui/material";
import Loader from "../../../components/Layout/Loader/Loader";
import card_image from "../../../assets/images/Card_image.png";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const successModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    class="circle"
  >
    <path
      d="M37.3419 1.66842C38.9671 0.648292 41.0329 0.648291 42.6581 1.66842L45.9504 3.7349C46.9519 4.36351 48.1438 4.61684 49.3144 4.44993L53.1625 3.90121C55.0622 3.63033 56.9493 4.47053 58.0192 6.06352L60.1863 9.29044C60.8456 10.272 61.8313 10.9883 62.9686 11.3119L66.7073 12.3758C68.5529 12.901 69.9351 14.4361 70.2645 16.3265L70.9318 20.1559C71.1348 21.3208 71.7441 22.3761 72.6514 23.1343L75.6341 25.6269C77.1065 26.8574 77.7449 28.822 77.2769 30.6829L76.3289 34.4527C76.0406 35.5994 76.168 36.8112 76.6884 37.8729L78.3994 41.3632C79.2441 43.0862 79.0282 45.1406 77.8437 46.6503L75.4444 49.7086C74.7146 50.6389 74.3381 51.7977 74.3817 52.9793L74.5252 56.8638C74.596 58.7814 73.5632 60.5703 71.8671 61.4678L68.4313 63.2858C67.3862 63.8388 66.5708 64.7443 66.1301 65.8415L64.6812 69.4485C63.966 71.2291 62.2948 72.4433 60.3803 72.5733L56.5021 72.8367C55.3224 72.9168 54.2092 73.4124 53.3603 74.2355L50.5696 76.9413C49.192 78.277 47.1714 78.7065 45.3696 78.0466L41.7195 76.7098C40.6092 76.3031 39.3908 76.3031 38.2805 76.7098L34.6304 78.0466C32.8286 78.7065 30.808 78.277 29.4304 76.9413L26.6397 74.2355C25.7908 73.4124 24.6776 72.9168 23.4979 72.8367L19.6197 72.5733C17.7052 72.4433 16.034 71.2291 15.3188 69.4485L13.8699 65.8415C13.4292 64.7443 12.6138 63.8388 11.5687 63.2858L8.13294 61.4678C6.43685 60.5703 5.40399 58.7814 5.47482 56.8638L5.61829 52.9793C5.66193 51.7977 5.2854 50.6389 4.55555 49.7086L2.15625 46.6503C0.971826 45.1406 0.755901 43.0862 1.60056 41.3632L3.31157 37.8729C3.83205 36.8112 3.95942 35.5994 3.67106 34.4527L2.7231 30.6829C2.25513 28.822 2.89347 26.8574 4.36591 25.6269L7.34862 23.1343C8.25594 22.3761 8.86518 21.3208 9.06817 20.1559L9.73546 16.3265C10.0649 14.4361 11.4471 12.901 13.2927 12.3758L17.0314 11.3119C18.1687 10.9883 19.1544 10.272 19.8137 9.29044L21.9808 6.06352C23.0507 4.47053 24.9378 3.63033 26.8375 3.90121L30.6856 4.44993C31.8562 4.61684 33.0481 4.36351 34.0496 3.7349L37.3419 1.66842Z"
      fill="#E65925"
    />
    <path
      d="M28 40.5263L35.3765 48.3368C36.1947 49.2031 37.5976 49.1175 38.3045 48.1582L51.6842 30"
      stroke="white"
      stroke-width="6.64"
      stroke-linecap="round"
      class="checkmark-path"
    />
  </svg>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "329px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const deleteWarningModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "#FDE9E9",
  p: 3,
  outline: "none",
  border: "1px solid E62525",

  borderRadius: "10px",
  // border: "#E62525"
};

const ClientCompanyPaymentMethod = () => {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  const superAdminUser =
    userInformationTeamData &&
    userInformationTeamData.find((user) => user.user_id?.role === "superadmin");
  const superAdminUserid = superAdminUser?.user_id?._id;
  const superAdminUserplan = superAdminUser?.subscription_details?.plan;
  const superAdminUsercustomerid =
    superAdminUser?.subscription_details?.customer_id;
  const superAdminUsersubscriptionid =
    superAdminUser?.subscription_details?.subscription_id;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [cardType, setCardType] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [btnText, setBtnText] = useState("Add card");
  const [deleteCardID, setdeleteCardID] = useState();
  const [headerText, setHeaderText] = useState("Add new card");
  // Create a formData object to hold form data
  const [formData, setFormData] = useState({
    id: "",
    cardName: "",
    cardNumber: null,
    cardExpiry: null,
    cardCVV: null,
  });
  const [openCardPrimaryModal, setOpenCardPrimaryModal] = React.useState(false);
  const handleOpen = () => {
    setOpenCardPrimaryModal(true);
  };
  const handleClose = () => setOpenCardPrimaryModal(false);
  const [sameCardError, setSameCardError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const [saveCards, setSavedCards] = useState([]);
  // Create a validation object to hold validation errors
  const [validationErrors, setValidationErrors] = useState({
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCVV: "",
  });

  const [openWarningModal, setOpenWarningModal] = React.useState(false);
  const handleWarningOpenModal = () => {
    setOpenWarningModal(true);
  };
  const handleCloseWarningdModal = () => setOpenWarningModal(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const fetchSavedCards = async () => {
    setLoading(true);
    if (!stripe || !elements) return;

    if (superAdminUsercustomerid) {
      const response = await axios.post(
        `${url}/admin/payment/get-saved-cards`,
        { customerID: superAdminUsercustomerid },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setSavedCards(response.data.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (saveCards.length === 0) {
      fetchSavedCards();
    }
  }, [userInformationTeamData, dispatch]);

  const deleteCardHandler = (paymentID) => {
    setdeleteCardID(paymentID);
    handleWarningOpenModal();
  };

  const handleCheckboxChange = () => {
    setIsPrimary(!isPrimary);
  };

  const handleChangeNumber = (event) => {
    const inputValue = event.error ? null : event.complete ? event.empty : null;

    // Update the formData object
    setFormData({
      ...formData,
      cardNumber: inputValue,
    });
  };

  // const value = saveCards.map((card) => card.isPrimary)
  //
  const [selectedCard, setSelectedCard] = useState(null);
  const handleSetAsPrimary = async (cardId) => {
    await handleOpen();
    setSelectedCard(cardId);
  };

  const handleSetAsPrimaryCard = async (cardId) => {
    if (!stripe || !elements) return;

    // updateCardData("create", payload.paymentMethod.id);
    const customerID = superAdminUsercustomerid;
    let paymentData = {};

    paymentData = {
      type: "create",
      customerID: customerID,
      paymentID: cardId.id,
      cardId,
    };

    const response = await axios.post(
      `${url}/admin/payment/update-card`,
      { paymentData, superAdminUserid },
      config
    );
    if (response.data.success) {
      setApiSuccess(true);
      setSuccessMessage(
        "The card has been successfully set as the primary card."
      );
      setTimeout(() => {
        setApiSuccess(false);
      }, 5000);
      setFormData({
        cardName: "",
        cardNumber: null,
        cardExpiry: null,
        cardCVV: null,
      });
      fetchSavedCards();
      setOpenWarningModal(false);
      handleClose();
      setOpen(false);
    } else {
    }
  };

  // Function to handle changes in the expiry input
  const handleExpiryChange = (event) => {
    const inputValue = event.error ? null : event.complete ? event.empty : null;
    // Update the formData object
    setFormData({
      ...formData,
      cardExpiry: inputValue,
    });
  };

  const handleStripeAddCard = async () => {
    if (!stripe || !elements) return;
    setButtonClicked(true);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      metadata: {
        custom_card_name: formData.cardName,
      },
    });

    let savedCardlast4Num;

    const enteredcard4num = payload.paymentMethod.card.last4;
    savedCardlast4Num = saveCards?.filter(
      (e) => e.card.last4 === enteredcard4num
    );

    let existingcard = false;
    if (savedCardlast4Num.length >= 1) {
      existingcard = true;
      setSameCardError(
        "This card already exists. If you want to add this card, please remove the existing card."
      );
      setButtonClicked(false);
      return;
    } else {
      existingcard = false;
    }

    updateCardData(
      "create",
      payload.paymentMethod.id,
      existingcard,
      savedCardlast4Num
    );
  };

  const updateCardData = async (
    type,
    data,
    existingcard,
    savedCardlast4Num
  ) => {
    const customerID = superAdminUsercustomerid;
    let paymentData = {};
    if (superAdminUserplan === "Free" && superAdminUsercustomerid === null) {
      paymentData = {
        type: "createNewCustomer",
        paymentID: data,
        isPrimary: true,
      };
    } else {
      if (type === "create") {
        paymentData = {
          subscriptionId: superAdminUsersubscriptionid,
          type: type,
          customerID: customerID,
          paymentID: data,
          isPrimary,
        };
      } else if (type === "delete") {
        paymentData = {
          type: type,
          paymentID: data,
        };
      }
    }
    try {
      const response = await axios.post(
        `${url}/admin/payment/update-card`,
        { paymentData, superAdminUserid },
        config
      );
      if (response.data.success) {
        if (type === "create") {
          setApiSuccess(true);
          setSuccessMessage("New card added successfully.");
          setTimeout(() => {
            setApiSuccess(true);
          }, 5000);
        } else if (type === "delete") {
          setApiSuccess(true);
          setSuccessMessage("Card deleted successfully.");
          setTimeout(() => {
            setApiSuccess(false);
          }, 5000);
        }
        setOpenWarningModal(false);
        setOpen(false);
        fetchSavedCards();
      } else {
      }
    } catch (error) {
      setSameCardError(error.response.data.message);
    }
  };

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const areAllFieldsFilled = () => {
    const isAccordion5Filled =
      formData.cardName?.trim() !== "" &&
      formData.cardNumber !== null &&
      formData.cardCVV !== null &&
      formData.cardExpiry !== null;
    setAllFieldsFilled(isAccordion5Filled);
  };
  useEffect(() => {
    areAllFieldsFilled();
  }, [formData]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  // Function to check if a date is less than the current date
  const isDateInPast = (month, year) => {
    if (year < currentYear) {
      return true;
    } else if (year === currentYear && month < currentMonth) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="onetap_conn_payment_table">
            <div className="onetap_conn_payment_header">
              <p>Payment Methods</p>
              <button
                onClick={() => {
                  setFormData({
                    cardName: "",
                    cardNumber: null,
                    cardExpiry: null,
                    cardCVV: null,
                  });
                  setIsPrimary(false);
                  setSameCardError("");
                  setOpen(true);
                  setBtnText("Add card");
                  setHeaderText("Add new card");
                }}
              >
                Add new
              </button>
            </div>
            <div className="onetap_conn_payment_card_table">
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: "white !important",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
              >
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          paddingLeft: "0px",
                          color: "#333",
                          fontSize: "18px",
                          fontWeight: 600,
                          color: "#000",
                        }}
                      >
                        Cards
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 600 }}>
                        Expiration date
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: 600 }}>
                        Status
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: 600 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ backgroundColor: "#F2F2F2 !important" }}>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {saveCards?.length > 0 ? (
                          saveCards?.map((card) => (
                            <React.Fragment>
                              <TableRow
                                key={card._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{ position: "relative" }}
                              >
                                <TableCell align="left">
                                  <div className="onetap_conn_payment_table_column1">
                                    <img
                                      src={card_image}
                                      alt="card_image"
                                      width={34}
                                      height={24}
                                      style={{ marginTop: "4px" }}
                                    />
                                    <div>
                                      {" "}
                                      <div className="onetap_conn_payment_table_column1_brandandnumber">
                                        {card.card.brand
                                          .charAt(0)
                                          .toUpperCase() +
                                          card.card.brand.slice(1)}{" "}
                                        ending in *{card.card.last4}
                                      </div>
                                      <div className="onetap_conn_payment_table_column1_name">
                                        {card.metadata.custom_card_name}
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {" "}
                                  <div
                                    className={`${
                                      isDateInPast(
                                        card.card.exp_month,
                                        card.card.exp_year
                                      )
                                        ? "onetap_conn_payment_table_column2_expired"
                                        : "onetap_conn_payment_table_column2_primary"
                                    }`}
                                  >
                                    {card.card.exp_month}/
                                    {card.card.exp_year.toString().slice(-2)}
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  {" "}
                                  <div
                                    className={`${
                                      card.status === "expired"
                                        ? "onetap_conn_payment_table_column3_expired"
                                        : "onetap_conn_payment_table_column3_primary"
                                    }`}
                                  >
                                    {card.isPrimary
                                      ? "Primary"
                                      : isDateInPast(
                                          card.card.exp_month,
                                          card.card.exp_year
                                        )
                                      ? "Expired"
                                      : "Active"}
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <div className="onetap_conn_payment_edit_delete_button">
                                    <Button
                                      style={{
                                        marginBottom: "0px",
                                        marginRight: card.isPrimary
                                          ? "45px"
                                          : "0px",
                                        display: card.isPrimary
                                          ? "none"
                                          : "block",
                                      }}
                                      className="onetap_conn_payment_delete_button"
                                      onClick={() => deleteCardHandler(card.id)}
                                      disabled={card.isPrimary}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      style={{
                                        marginBottom: "0px",
                                        opacity: card.isPrimary ? 0.5 : 1,
                                      }}
                                      className="onetap_conn_payment_primary_button"
                                      disabled={card.isPrimary}
                                      onClick={() => handleSetAsPrimary(card)}
                                    >
                                      {card.isPrimary
                                        ? "Primary"
                                        : "Set as Primary"}
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: "none",
                                  }}
                                ></TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))
                        ) : (
                          <h3
                            style={{
                              backgroundColor: "#ffff",
                              marginBottom: "0px",
                            }}
                          >
                            No cards available.
                          </h3>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
                setButtonClicked(false);
                setFormData({
                  cardName: "",
                  cardNumber: null,
                  cardExpiry: null,
                  cardCVV: null,
                });
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{
                    borderBottom: "1px solid #F2F2F2",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="onetap_conn_add_card_header">
                    {headerText}{" "}
                  </div>
                </div>
                <div>
                  <form style={{ paddingTop: "10px" }}>
                    <div className="onetap_conn_add_card_form_main">
                      <div className="onetap_conn_add_card_form">
                        <label>Name on card</label>
                        <input
                          className="card-inputs"
                          type="text"
                          name="card_name"
                          placeholder="John Doe"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              cardName: e.target.value,
                            })
                          }
                          value={formData.cardName}
                        />
                        <div className="onetap_conn_add_card_form_validation_error">
                          {validationErrors.cardName}
                        </div>
                      </div>

                      <div className="onetap_conn_add_card_form">
                        <label>Card number</label>
                        <CardNumberElement
                          className="card-inputs"
                          type="text"
                          name="card_number"
                          placeholder="4242 4242 4242 4242"
                          maxLength={19}
                          onChange={handleChangeNumber}
                          value={formData.cardNumber}
                        />
                        <div className="onetap_conn_add_card_form_validation_error">
                          {validationErrors.cardNumber}
                        </div>
                      </div>
                      <div class="onetap_conn_add_card_form">
                        <label for="expiry_date">Expiration date</label>
                        <CardExpiryElement
                          className="card-inputs"
                          type="text"
                          name="card_expiry"
                          placeholder="MM/YY"
                          maxLength={5}
                          onChange={handleExpiryChange}
                          value={formData.cardExpiry}
                        />
                        <div className="onetap_conn_add_card_form_validation_error">
                          {validationErrors.cardExpiry}
                        </div>
                      </div>
                      <div className="onetap_conn_add_card_form">
                        <label>Security code</label>
                        <CardCvcElement
                          className="card-inputs"
                          name="cvv"
                          onChange={(event) => {
                            // Set the cleaned numeric value in the state
                            setFormData({
                              ...formData,
                              cardCVV: event.error
                                ? null
                                : event.complete
                                ? event.empty
                                : null,
                            });
                          }}
                          value={formData.cardCVV}
                        />
                        <div className="onetap_conn_add_card_form_validation_error">
                          {validationErrors.cardCVV}
                        </div>
                      </div>
                      <div className="error">{sameCardError}</div>
                      <div className="onetap_conn_add_card_checkbox">
                        <input
                          type="checkbox"
                          className="onetap_conn_add_card_checkbox_input"
                          checked={isPrimary}
                          onClick={handleCheckboxChange}
                        />
                        <div>
                          Set as primary payment method for all new orders
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="onetap_conn_send_email_medal_button">
                  <button
                    className="onetap_conn_send_email_medal_invite_button"
                    style={
                      allFieldsFilled || buttonClicked ? {} : { opacity: 0.5 }
                    }
                    onClick={handleStripeAddCard}
                    disabled={!allFieldsFilled || buttonClicked}
                  >
                    {buttonClicked ? "Loading..." : btnText}
                  </button>
                  <button
                    className="onetap_conn_send_email_medal_cancel_button"
                    onClick={() => {
                      setOpen(false);
                      setSameCardError("");
                      setFormData({
                        cardName: "",
                        cardNumber: null,
                        cardExpiry: null,
                        cardCVV: null,
                      });
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
            <Modal
              open={openWarningModal}
              onClose={handleCloseWarningdModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={deleteWarningModalStyle}>
                <div className="video-Modal-section">
                  <h2 className="warning-header">Warning</h2>
                  <h6 className="warning-text-payment">
                    Are you sure you want to delete this saved debit/credit
                    card?
                  </h6>
                  <span>
                    Deleting this card will remove it from your payment options,
                    and you won't be able to use it for future purchases.
                  </span>
                  <div className="video-Modal-btn-group">
                    {/* <button className="video-Modal-btn video-first-modal-btn" >Delete Card</button> */}
                    <button
                      className="video-Modal-btn video-first-modal-btn"
                      onClick={() => {
                        updateCardData("delete", deleteCardID);
                      }}
                    >
                      Delete Card
                    </button>
                    <button
                      className="video-Modal-btn video-sec-modal-btn"
                      onClick={handleCloseWarningdModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
            <Modal
              open={openCardPrimaryModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style2}>
                <div className="Modal-section">
                  <p className="Confirmation-Message">
                    Are you sure you want to set this card as the primary card?
                  </p>
                  <div className="Modal-btn-group">
                    <button
                      className="Modal-btn first-modal-btn"
                      onClick={() => {
                        handleSetAsPrimaryCard(selectedCard);
                      }}
                    >
                      Set as Primary
                    </button>
                    <button
                      className="Modal-btn sec-modal-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </>
      )}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        open={apiSuccess}
        onClose={() => setApiSuccess(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={successModalStyle} style={{ textAlign: "center" }}>
          <div className="Modal-section">
            <div className="checkmark-container">
              <div class="checkmark-container">{messageIcon}</div>
            </div>
            <span className="team_created_success">{successMessage}</span>
          </div>
          <button
            className="Modal-btn sec-modal-btn"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              width: "13%",
            }}
            onClick={() => setApiSuccess(false)}
          >
            <span style={{ fontSize: "20px", fontWeight: "bold" }}>
              <FontAwesomeIcon icon={faXmark} style={{ color: "#e65925" }} />
            </span>
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default ClientCompanyPaymentMethod;
