import { configureStore } from '@reduxjs/toolkit'
import { CompantProfileReducer, UsersReducer, billingAddressReducer,  companyShareReferDataReducer, fetchUserInformationReducer, invitedUsersReducer, shippingAddressReducer, singleTeamMemberDetailsReducer, teamNameReducer, teamdetailsReducer, userReducer, userInformationReducer, getProductsReducer, fetchCartReducer, getProfileImageReducer, Unique_slugReducer, getOrdersReducer, localStorageChangeReducer,getPlanDataReducer, activeUsers } from './reducers/UserReducers/UserReducer'
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { getOtcTeamMembers, getUserInfoReducer } from './reducers/superAdminReducers/superAdminReducers';
import { OtcAdminReducer, getPlanReducer, invitedUsersReducerforAdminpanel, usersOfCompanyReducer, getSingleOrderReducer, getAllOrdersReducer, allusersOfCompanyReducer, OtcshippingAddressReducer, otcbillingAddressReducer, otcadminteamsReducer } from './OTC-AdminPanel/Redux/Otc_UserReducer';
const store = configureStore({
  reducer: {
    user: userReducer,
    Users: UsersReducer,
    invitedusers: invitedUsersReducer,
    TeammemberDetails: singleTeamMemberDetailsReducer,
    userInformationDetails: userInformationReducer,
    Company: CompantProfileReducer,
    companyShareReferData: companyShareReferDataReducer,
    OTCteam: getOtcTeamMembers,
    userInfo: getUserInfoReducer,
    shippingAddresses: shippingAddressReducer,
    billingAddresses: billingAddressReducer,
    userInformation: fetchUserInformationReducer,
    team: teamNameReducer,
    products: getProductsReducer,
    Cartproducts: fetchCartReducer,
    getavatar: getProfileImageReducer,
    unique_slug: Unique_slugReducer,
    orders: getOrdersReducer,
    OtcAdminuser: OtcAdminReducer,
    usersOfCompany: usersOfCompanyReducer,
    OtcPlans: getPlanReducer,
    invitedUsersforAdminpanel: invitedUsersReducerforAdminpanel,
    allOrders: getAllOrdersReducer,
    order: getSingleOrderReducer,
    AllcompanyUser: allusersOfCompanyReducer,
    otcshippingAddress: OtcshippingAddressReducer,
    otcbillingAddresses: otcbillingAddressReducer,
    otcadminteams: otcadminteamsReducer,
    localCartChange: localStorageChangeReducer,
    planData: getPlanDataReducer,
    activeUser: activeUsers,
  },
})
setupListeners(store.dispatch)
export default store;