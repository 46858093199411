// Component.js
import React, { useEffect, useState } from "react";
import ClientCompanyInformationHeader from "./ClientCompanyInformationHeader";
import ClientCompanyInformationGeneralTab from "./ClientCompanyInformationGeneralTab";
import ClientCompanyInformationAboutTab from "./ClientCompanyInformationAboutTab";
import ClientCompanyInformationBranding from "./ClientCompanyInformationBranding";

const ClientCompanyInformation = () => {
  const [companyProfileTab, setCompanyProfileTab] = useState("General");
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };
  
  return (
    <div className="onetap_conn_user" style={{padding:'0px'}}>
      <ClientCompanyInformationHeader onChange={getTabValue} tab={companyProfileTab} />
      <div className="">
          {companyProfileTab === "General" && (
            <ClientCompanyInformationGeneralTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
          {companyProfileTab === "About" && (
            <ClientCompanyInformationAboutTab
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
            {companyProfileTab === "Branding" && (
            <ClientCompanyInformationBranding
              onClickSave={getValueOfSaveAndContinueTab}
            />
          )}
        </div>
    </div>
  );
};

export default ClientCompanyInformation;
