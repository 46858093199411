// Component.js
import React, { useState } from "react";
import ClientCompanySettingsHeaders from "./ClientCompanySettingsHeaders";
import ClientCompanySettingsGeneral from "./ClientCompanySettingsGeneral";
import ClientCompanySettingsUserProfile from "./ClientCompanySettingsUserProfile";
import ClientCompanySettingLeadCapture from "./ClientCompanySettingLeadCapture";
import ClientCompanySettingsShareReferal from "./ClientCompanySettingsShareReferal";
import { useSelector } from "react-redux";

const ClientCompanySettings = () => {
  const { userInformationTeamData } = useSelector(
    (state) => state.usersOfCompany
  );
  const isProfessionalPlan =
    userInformationTeamData &&
    userInformationTeamData.some(
      (user) => user.subscription_details.plan === "Professional"
    );
  const [companyProfileTab, setCompanyProfileTab] = useState(
    isProfessionalPlan ? "Lead Capture" : "General"
  );
  const getTabValue = (tabVal) => {
    setCompanyProfileTab(tabVal);
  };
  const getValueOfSaveAndContinueTab = (value) => {
    setCompanyProfileTab(value);
  };

  return (
    <div className="onetap_conn_user" style={{ padding: "0px" }}>
      <ClientCompanySettingsHeaders
        onChange={getTabValue}
        tab={companyProfileTab}
      />
      <div className="">
        {companyProfileTab === "General" && (
          <ClientCompanySettingsGeneral
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "User profile" && (
          <ClientCompanySettingsUserProfile
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Lead Capture" && (
          <ClientCompanySettingLeadCapture
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
        {companyProfileTab === "Share/Referral" && (
          <ClientCompanySettingsShareReferal
            onClickSave={getValueOfSaveAndContinueTab}
          />
        )}
      </div>
    </div>
  );
};

export default ClientCompanySettings;
