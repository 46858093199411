import React from "react";
import Header from '../../Layout/Header/Header2'
import { useNavigate } from "react-router-dom";
import "./TnC.css";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="tncmaindiv" style={{ backgroundColor: "#f2f2f2" }}>
        <Header />
        <div
          className="container mt-4"
          style={{ backgroundColor: "white", maxWidth: "1140px" }}
        >
          <div className="" style={{ padding: "50px 50px 30px 50px" }}>
            <h1 className="tnch1">Privacy Policy</h1>
            <p className="tncp">
              Protecting your private information is our priority. This
              Statement of Privacy applies to onetapconnect.com and One Tap
              Connect, LLC and governs data collection and usage. For the
              purposes of this Privacy Policy, unless otherwise noted, all
              references to One Tap Connect, LLC include onetapconnect.com. The
              One Tap Connect, LLC website onetapconnect.com is a Digital Card
              /Mobile Website Design site. By using the One Tap Connect, LLC
              website, you consent to the data practices described in this
              statement.
            </p>
            <div className="mt-4">
              <h2 className="tnch2">Collection of your Personal Information</h2>
              <p className="tncp">
                In order to better provide you with products and services
                offered, One Tap Connect, LLC may collect personally
                identifiable information, such as your:
              </p>
              <ul className="tncul">
                <li className="tncli">First and Last Name </li>
                <li className="tncli">Mailing Address </li>
                <li className="tncli">E-mail Address </li>
                <li className="tncli">Phone Number </li>
                <li className="tncli">Employer </li>
                <li className="tncli">Job Title </li>
                <li className="tncli">
                  Social Media Links, Website Addresses, Calendar Booking Links,
                  Proprietary Videos and Photos{" "}
                </li>
              </ul>

              <p className="tncp">
                If you purchase One Tap Connect, LLC’s products, and services,
                we collect billing and credit card information. This information
                is used to complete the purchase transaction.
              </p>
              <p className="tncp">
                We do not collect any personal information about you unless you
                voluntarily provide it to us. However, you may be required to
                provide certain personal information to us when you elect to use
                certain products or services. These may include: (a) registering
                for an account; (b) sending us an email message; (e) submitting
                your credit card or other payment information when ordering and
                purchasing products and services. To wit, we will use your
                information for, but not limited to, communicating with you in
                relation to services and/or products you have requested from us.
                We also may gather additional personal or non-personal
                information in the future.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Use of your Personal Information</h2>
              <p className="tncp">
                One Tap Connect, LLC collects and uses your personal information
                to operate and deliver the services you have requested.
              </p>
              <p className="tncp">
                One Tap Connect, LLC may also use your personally identifiable
                information to inform you of other products or services
                available from One Tap Connect, LLC and its affiliates.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Sharing Information with Third Parties</h2>
              <p className="tncp">
                One Tap Connect, LLC does not sell, rent or lease its customer
                lists to third parties.
              </p>
              <p className="tncp">
                One Tap Connect, LLC may share data with trusted partners to
                help perform statistical analysis, send you email or postal
                mail, provide customer support, or arrange for deliveries. All
                such third parties are prohibited from using your personal
                information except to provide these services to One Tap Connect,
                LLC, and they are required to maintain the confidentiality of
                your information.
              </p>
              <p className="tncp">
                One Tap Connect, LLC may disclose your personal information,
                without notice, if required to do so by law or in the good faith
                belief that such action is necessary to (a) conform to the
                edicts of the law or comply with legal process served on One Tap
                Connect, LLC or the site; (b) protect and defend the rights or
                property of One Tap Connect, LLC; and/or (c) act under exigent
                circumstances to protect the personal safety of users of One Tap
                Connect, LLC, or the public.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Automatically Collected Information</h2>
              <p className="tncp">
                Information about your computer hardware and software may be
                automatically collected by One Tap Connect, LLC. This
                information can include: your IP address, browser type, domain
                names, access times, and referring website addresses. This
                information is used for the operation of the service, to
                maintain the quality of the service, and to provide general
                statistics regarding use of the One Tap Connect, LLC website.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Security of your Personal Information</h2>
              <p className="tncp">
                One Tap Connect, LLC secures your personal information from
                unauthorized access, use, or disclosure. One Tap Connect, LLC
                uses the following methods for this purpose:
              </p>
              <p className="tncp"> – SSL Protocol </p>
              <p className="tncp">
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use
                of encryption, such as the Secure Sockets Layer (SSL) protocol.
              </p>
              <p className="tncp">
                We strive to take appropriate security measures to protect
                against unauthorized access to or alteration of your personal
                information. Unfortunately, no data transmission over the
                Internet or any wireless network can be guaranteed to be 100%
                secure. As a result, while we strive to protect your personal
                information, you acknowledge that: (a) there are security and
                privacy limitations inherent to the Internet which are beyond
                our control; and (b) security, integrity, and privacy of any and
                all information and data exchanged between you and us through
                this Site cannot be guaranteed.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Right to Deletion</h2>
              <p className="tncp">
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
              <ul className="tncul">
                <li className="tncli">
                  Delete your personal information from our records; and
                </li>
                <li className="tncli">
                  Direct any service providers to delete your personal
                  information from their records.
                </li>
                <p className="tncp mt-2">
                  Please note that we may not be able to comply with requests to
                  delete your personal information if it is necessary to:
                </p>
                <li className="tncli">
                  Complete the transaction for which the personal information
                  was collected, fulfill the terms of a written warranty or
                  product recall conducted in accordance with federal law,
                  provide a good or service requested by you, or reasonably
                  anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform a contract between
                  you and us;{" "}
                </li>
                <li className="tncli">
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity; or prosecute those
                  responsible for that activity;{" "}
                </li>
                <li className="tncli">
                  Debug to identify and repair errors that impair existing
                  intended functionality.
                </li>
                <li className="tncli">
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her right of free speech, or exercise another
                  right provided for by law;
                </li>
                <li className="tncli">
                  Comply with the California Online Privacy Protection Act;
                </li>
                <li className="tncli">
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when our
                  deletion of the information is likely to render impossible or
                  seriously impair the achievement of such research, provided we
                  have obtained your informed consent;
                </li>
                <li className="tncli">
                  Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us;
                </li>
                <li className="tncli">
                  Comply with an existing legal obligation; or
                </li>
                <li className="tncli">
                  Otherwise use your personal information, internally, in a
                  lawful manner that is compatible with the context in which you
                  provided the information.
                </li>
              </ul>
            </div>
            <div>
              <h2 className="tnch2">Children Under Eighteen</h2>
              <p className="tncp">
                One Tap Connect, LLC does not knowingly collect personally
                identifiable information from children under the age of
                eighteen. If you are under the age of eighteen, you must ask
                your parent or guardian for permission to use this website.
              </p>
            </div>
            <div>
              {" "}
              <h2 className="tnch2">Automatically Collected Information</h2>
              <p className="tncp">
                Information about your computer hardware and software may be
                automatically collected by One Tap Connect, LLC. This
                information can include: your IP address, browser type, domain
                names, access times, and referring website addresses. This
                information is used for the operation of the service, to
                maintain the quality of the service, and to provide general
                statistics regarding use of the One Tap Connect, LLC website.
              </p>
            </div>
            <div>
              <h2 className="tnch2">
                Disconnecting your One Tap Connect, LLC Account from Third Party
                Websites
              </h2>
              <p className="tncp">
                You will be able to connect your One Tap Connect, LLC account to
                third-party accounts. BY CONNECTING YOUR ONE TAP CONNECT, LLC
                ACCOUNT TO YOUR THIRD-PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE
                THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION
                ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON
                THOSE THIRD-PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT
                YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED
                IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your
                account from a third-party account at any time. Customers can
                disconnect their One Tap Connect Mini Websites from any
                third-party sites by request at  <EmailLink email="admin@onetapconnect.com" />.
              </p>
            </div>
            <div>
              <h2 className="tnch2">E-mail Communications</h2>
              <p className="tncp">
                From time to time, One Tap Connect, LLC may contact you via
                email for the purpose of providing announcements, promotional
                offers, alerts, confirmations, surveys, and/or other general
                communication.
              </p>
            </div>
            <div>
              <h2 className="tnch2">External Data Storage Sites</h2>
              <p className="tncp">
                We may store your data on servers provided by third-party
                hosting vendors with whom we have contracted.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Changes to this Statement</h2>
              <p className="tncp">
                One Tap Connect, LLC reserves the right to change this Privacy
                Policy from time to time. We will notify you about significant
                changes in the way we treat personal information by sending a
                notice to the primary email address specified in your account,
                by placing a prominent notice on our website, and/or by updating
                any privacy information. Your continued use of the website
                and/or Services available after such modifications will
                constitute your: (a) acknowledgment of the modified Privacy
                Policy; and (b) agreement to abide and be bound by that Policy.
              </p>
            </div>
            <div>
              <h2 className="tnch2">Contact Information</h2>
              <p className="tncp">
                One Tap Connect, LLC welcomes your questions or comments
                regarding this Statement of Privacy. If you believe that One Tap
                Connect, LLC has not adhered to this Statement, please contact
                One Tap Connect, LLC at:
              </p>
              <p className="tncp">
                One Tap Connect, LLC <br />
                10866 Marandola St. <br />
                Las Vegas, Nevada 89141
              </p>
              <p className="tncp mb-0">Email Address:  <EmailLink email="admin@onetapconnect.com" /></p>
              <p className="tncp mb-0">Telephone number:  <TelLink phoneNumber="+1 702-907-2502" /></p>
              <p className="tncp">
                Effective as of June 30, 2021 <br />
                Updated January 1, 2022
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;

const EmailLink = ({ email }) => {
  const handleClick = () => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <a
      href={`mailto:${email}`}
      onClick={handleClick}
      style={{ textDecoration: "none", color: "#E65925" }}
    >
      {email}
    </a>
  );
};


const TelLink = ({ phoneNumber }) => {
  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <a
      href={`tel:${phoneNumber}`}
      onClick={handleClick}
      style={{ textDecoration: "none", color: "#E65925" }}
    >
      {phoneNumber}
    </a>
  );
};