import React, { useState } from 'react'
import OtcSidebar from '../Layout/Sidebar/Otc_Sidebar';
import OTCadmincreteeditheader from './OTC_Addnewadminheader';
import OTCadmincreateedit from './OTC_Addnewadmin';

const Component = () => {
    const [companyProfileTab, setCompanyProfileTab] = useState("OTC Users");

    const getTabValue = (tabVal) => {
        setCompanyProfileTab(tabVal);
    };

    const getValueOfSaveAndContinueTab = (value) => {
        setCompanyProfileTab(value);
    };
    return (
        <div className="onetap_conn_user">
            <OTCadmincreteeditheader onChange={getTabValue} tab={companyProfileTab} />
            {companyProfileTab === "OTC Users" && (
                <OTCadmincreateedit
                    onClickSave={getValueOfSaveAndContinueTab}
                />
            )}
        </div>
    );
};


const OTC_admincreate_edit_teammember = () => {
  return (
    <OtcSidebar Component={Component} />
  )
}

export default OTC_admincreate_edit_teammember