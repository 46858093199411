import React, { useEffect, useState } from "react";

const CouponHeaderWithIcon = (props) => {
    const { showCreateCoupon, onCouponsClick, onAddNewCouponClick } = props;
    const [couponTab, setCouponTab] = useState("Coupons");
    const { tab } = props;

    useEffect(() => {
        setCouponTab(tab);
    }, [tab]);

    const handleClickOfTab = (value) => {
        setCouponTab(value);
        props.onChange(value);
        if (value === 'Coupons') {
            onCouponsClick()
        }
    };

    return (
        <>
            <div className="OTC_Admin_mycard_header">

                <div onClick={() => handleClickOfTab("Coupons")} className={
                    couponTab === "Coupons" ? "OTC_Admin_mycard_header_icon_and_header_active" : "OTC_Admin_mycard_header_icon_and_header_deactive"   
                }style={{ zIndex:'1'}} >
                    <div
                        style={{
                            backgroundColor: couponTab === "Coupons" ? "#E65925" : "",
                            width: "34px",
                            height: "32px",
                            borderRadius: "7px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19 3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.11 21 21 20.11 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3ZM8.83 7.05C9.81 7.05 10.6 7.84 10.6 8.83C10.6 9.81 9.81 10.6 8.83 10.6C7.84 10.6 7.05 9.81 7.05 8.83C7.05 7.84 7.84 7.05 8.83 7.05ZM15.22 17C14.24 17 13.45 16.2 13.45 15.22C13.45 14.24 14.24 13.45 15.22 13.45C16.2 13.45 17 14.24 17 15.22C17 16.2 16.2 17 15.22 17ZM8.5 17.03L7 15.53L15.53 7L17.03 8.5L8.5 17.03Z" fill={couponTab === "Coupons" ? "#ffff" : "#000000"} />
                        </svg>
                    </div>

                    <p className={couponTab === "Coupons" ? "OTC_Admin_mycard_active_header" : "OTC_Admin_mycard_headers"} >
                        Coupons
                    </p>
                </div>

                {!showCreateCoupon && (
                    <div className="OTC_Admin_user_header_right" >
                        {couponTab === "Coupons" && (
                            <div className={`OTC_Admin_user_header_addusers`} onClick={() => {

                                onAddNewCouponClick()
                            }}>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="34"
                                        height="34"
                                        viewBox="0 0 34 34"
                                        fill="none"
                                    >
                                        <rect width="34" height="34" rx="9" fill="#E65925" />
                                        <path
                                            d="M25.5002 18.4138H18.4168V25.4971C18.4168 25.8728 18.2676 26.2332 18.0019 26.4988C17.7362 26.7645 17.3759 26.9138 17.0002 26.9138C16.6244 26.9138 16.2641 26.7645 15.9984 26.4988C15.7328 26.2332 15.5835 25.8728 15.5835 25.4971V18.4138H8.50016C8.12444 18.4138 7.76411 18.2645 7.49843 17.9988C7.23275 17.7332 7.0835 17.3728 7.0835 16.9971C7.0835 16.6214 7.23275 16.2611 7.49843 15.9954C7.76411 15.7297 8.12444 15.5804 8.50016 15.5804H15.5835V8.49711C15.5835 8.12139 15.7328 7.76105 15.9984 7.49538C16.2641 7.2297 16.6244 7.08044 17.0002 7.08044C17.3759 7.08044 17.7362 7.2297 18.0019 7.49538C18.2676 7.76105 18.4168 8.12139 18.4168 8.49711V15.5804H25.5002C25.8759 15.5804 26.2362 15.7297 26.5019 15.9954C26.7676 16.2611 26.9168 16.6214 26.9168 16.9971C26.9168 17.3728 26.7676 17.7332 26.5019 17.9988C26.2362 18.2645 25.8759 18.4138 25.5002 18.4138Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <div>Add New Coupon </div>
                            </div>)}
                    </div>
                )}
            </div >

        </>
    );
}

export default CouponHeaderWithIcon
