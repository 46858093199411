import React, { useEffect, useRef, useState } from "react";
import "./billing.css";
import "../Planselection/planSelection.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserInformationDetails,
  getOrders,
  getPlan,
} from "../../../actions/userAction/userAction";
import Loader from "../../Layout/Loader/Loader";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlanCard from "../../../components/PlanCard/PlanCard";
import { YearlyCard } from "../../../components/PlanCard/PlanCard";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useElements, useStripe } from "@stripe/react-stripe-js";
const url = process.env.REACT_APP_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "#FDE9E9",
  outline: "none",
  borderRadius: "10px",
  border: "1px solid #E62525",
  paddingTop: "5px",
  paddingRight: "30px",
  paddingBottom: "30px",
  paddingLeft: "30px",
};
const addUserPopupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: "background.paper",
  p: 4,
  outline: "none",
  borderRadius: "10px",
};
const Enable_subscription_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "#e9fdeb",
  outline: "none",
  borderRadius: "10px",
  border: "1px solid #b6ffc0",
  paddingTop: "5px",
  paddingRight: "30px",
  paddingBottom: "30px",
  paddingLeft: "30px",
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "#F2F2F2",
  p: 4,
  outline: "none",
  // borderRadius: "10px",
  overflowY: "scroll",
  maxHeight: "95%",
};
const popupStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "60%",
  bgcolor: "background.paper",
  borderRadius: '4px',
  // p: 4,
  padding: '30px 30px 0px 30px',
  outline: "none",
  overflow: "hidden", // Hide the overflow at the modal level
};
const headerStyle = {
  borderBottom: "1px solid #F2F2F2",
  paddingBottom: "10px",
  position: "sticky",
  top: 0,
  backgroundColor: "background.paper",
  zIndex: 1,
};
const optionsContainerStyle = {
  // maxHeight: "calc(100% - 50px)", // Adjust this value based on your design
  maxHeight: "70%", // Adjust this value based on your design
  overflow: "auto",
};
const buttonGroupStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  backgroundColor: '#fff',
  zIndex: 1,
};
const warningModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28.125rem",
  bgcolor: "#FDE9E9",
  p: 3,
  outline: "none",
  border: "1px solid E62525",
  borderRadius: "10px",
  // border: "#E62525"
};
const CompanyBillingSubscriptionTab = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const frontendURL = window.location.origin;
  const [isActive, setIsActive] = useState(false);
  const [plandata, setPlanData] = useState([]);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [cardType, setCardType] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const popupOpenFromtheUserinfo = location.state?.popupValue || "";
  const { user } = useSelector((state) => state.user);
  const { planData } = useSelector((state) => state.planData);
  const { userInfo, loading } = useSelector((state) => state.userInformation);
  const [cardDetails, setCardDetails] = useState([]);
  const [autoRenewalPopupOpen, setAutoRenewalPopupOpen] = React.useState(false);
  const [cancelSubscriptionModal, setCancelSubScriptionModal] = React.useState(false);
  const [addAddonsPopupOpen, setAddAddonsPopupOpen] = React.useState(false);
  const [addUserPopupOpen, setAddUserPopupOpen] = React.useState(false);
  const [addUserCount, setAddUserCount] = useState(1);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [currentPlandata, setCurrentPlandata] = useState();
  const [adduserPrice, setAdduserPrice] = useState(0);
  const [isAutoRenewal, setIsAutoRenewal] = useState(null);
  const [renualLoad, setRenualLoad] = useState(false);
  const [popupContent, SetpopupContent] = useState({
    popupLable: null,
    message: null,
    checkboxLable: null,
    buttonText: null,
    parameter: null,
  });
  const dropdownRef = useRef(null);
  const baseUser = userInfo?.subscription_details?.total_user[0]?.baseUser;
  const additionalUser =
    userInfo?.subscription_details?.total_user[0]?.additionalUser;
  const total_user = baseUser + additionalUser;
  const planName = userInfo?.subscription_details?.plan;
  const [addons, setAddOns] = useState(null);
  const [excludingAddons, setExcludingAddOns] = useState(null);
  const [userOrderstatedata, setUserOrderstatedata] = useState([]);
  const customerid = userInfo?.subscription_details?.customer_id;
  const { moveToTransaction } = location.state || {};
  useEffect(() => {
    if (moveToTransaction) {
      props.onClickSave("Transactions");
      Navigate({ ...location, state: { moveToTransaction: false } });
    }
  }, [moveToTransaction]);
  const handleOpenCancelModal = () => {
    setCancelSubScriptionModal(true)
  }
  const getOrderssofcompany = async () => {
    try {
      const { data } = await axios.post(`${url}/admin/getorderssofcompany`, {
        id: userInfo?.company_ID,
      });
      setUserOrderstatedata(data.Orderssofcompany);
    } catch (error) {
    }
  };
  const fetchSavedCards = async () => {
    if (!customerid) return;
    try {
      const response = await axios.post(
        `${url}/payment/get-saved-cards`,
        { customerID: customerid }, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
      );
      const cardsData = response.data.data;
      // Filter cards based on isPrimary field
      const primaryCard = cardsData.find((card) => card.isPrimary);
      // Update state with only the primary card
      setCardDetails([primaryCard]);
    } catch (error) {
    }
  };
  useEffect(() => {
    getOrderssofcompany();
  }, []);
  useEffect(() => {
    fetchSavedCards();
  }, [userInfo]);
  useEffect(() => {
    if (popupOpenFromtheUserinfo === "openpopup") {
      setAddUserPopupOpen(true);
    }
  }, [])
  let ordernum;
  if (userOrderstatedata) {
    const subscriptionOrders = userOrderstatedata.filter(
      (order) => order.type === "Subscription"
    );
    if (subscriptionOrders.length > 0) {
      const selectedOrder = subscriptionOrders.reduce(
        (prevOrder, currentOrder) => {
          const prevPaymentDate = new Date(prevOrder.paymentDate);
          const currentPaymentDate = new Date(currentOrder.paymentDate);
          return prevPaymentDate > currentPaymentDate
            ? prevOrder
            : currentOrder;
        }
      );
      ordernum = selectedOrder.orderNumber;
    }
  }
  
  const handleToggle = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };
  const redirecttopaymentmethod = () => {
    setTimeout(() => {
      props.onClickSave("Payment");
    }, 500);
  };
  // fetch all addons data
  const fetchAddons = async () => {
    try {
      const { data } = await axios.get(`${url}/addons`);
      const allAddons = data.addOns;
      // Filter addons based on the IDs in userInfo
      const selectedAddons = allAddons.filter((addon) =>
        userInfo.subscription_details.addones.some(subscriptionAddon =>
          subscriptionAddon.addonId === addon._id
        )
      );
      const excludingAddOn = allAddons.filter(
        (addon) => !selectedAddons.includes(addon)
      );
      setAddOns(selectedAddons);
      setExcludingAddOns(excludingAddOn);
    } catch (error) {
    }
  };

  const fetchandSetPlans = () => {
    if (planData) {
      setPlanData(planData.plans);
    } else {
      dispatch(getPlan());
    }
  }

  React.useEffect(() => {
    fetchandSetPlans();
  }, [planData])

  useEffect(() => {
    fetchAddons();
  }, [userInfo?.subscription_details?.addones]);
  useEffect(() => {
    const CurrentPlan = plandata?.find(plan => plan?._id === userInfo?.subscription_details?.planID);
    setCurrentPlandata(CurrentPlan)
    setAdduserPrice(
      userInfo?.subscription_details?.billing_cycle === "monthly" ? CurrentPlan?.monthlyPrice_perUser
        : (CurrentPlan?.yearlyPrice_perUser) * 12
    )
  }, [plandata, userInfo])
  useEffect(() => {
    if (cardDetails && cardDetails?.length > 0) {
      const lastCard = cardDetails[0];
      setCardNumber(lastCard?.card?.last4);
      setCardType(lastCard?.card?.brand);
    }
  }, [cardDetails]);
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setDropdownOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    dispatch(fetchUserInformationDetails());
    dispatch(getOrders());
    fetchAddons();
  }, []);
  useEffect(() => {
    if (userInfo?.subscription_details?.auto_renewal !== undefined) {
      setIsAutoRenewal(userInfo?.subscription_details?.auto_renewal);
    }
  }, [userInfo]);
  const dateObject = new Date(userInfo?.subscription_details?.endDate * 1000);
  //this one is calculate for "December 12th 2023" for this format
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formatted_day = dateObject.getDate();
  const formatted_month = months[dateObject.getMonth()];
  const formatted_year = dateObject.getFullYear();
  let dayFormatted;
  if (formatted_day === 1) {
    dayFormatted = "1st";
  } else if (formatted_day === 2) {
    dayFormatted = "2nd";
  } else if (formatted_day === 3) {
    dayFormatted = "3rd";
  } else {
    dayFormatted = `${formatted_day}th`;
  }
  const formatted_Date = `${formatted_month} ${dayFormatted} ${formatted_year}`;
  //calculate the 7 days before date
  const showpopupDate = new Date(dateObject);
  // Calculate the number of milliseconds remaining until the formattedDate
  const today = new Date();
  const timeDifference = showpopupDate - today;
  const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  // Calculate the day before for "December 12th 2023" this format
  const dayBefore = new Date(dateObject);
  dayBefore.setDate(formatted_day - 1);
  const dayBeforeDay = dayBefore.getDate();
  const dayBeforeMonth = months[dayBefore.getMonth()];
  const dayBeforeYear = dayBefore.getFullYear();
  let dayBeforeFormatted;
  if (dayBeforeDay === 1) {
    dayBeforeFormatted = "1st";
  } else if (dayBeforeDay === 2) {
    dayBeforeFormatted = "2nd";
  } else if (dayBeforeDay === 3) {
    dayBeforeFormatted = "3rd";
  } else {
    dayBeforeFormatted = `${dayBeforeDay}th`;
  }
  const formattedDayBefore = `${dayBeforeMonth} ${dayBeforeFormatted} ${dayBeforeYear}`;
  //this one is calculate for "12/04/2023" for this format
  const day = dateObject.getUTCDate().toString().padStart(2, "0");
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getUTCFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  // const formattedDate = `${month}/${day}/${year}`;
  const subcriptionHandler = async (value) => {
    const userData = {
      subscription_id: userInfo.subscription_details.subscription_schedules_id,
      userId: userInfo.user_id,
      type: value,
    };
    if (value !== undefined) {
      try {
        setRenualLoad(true)
        const response = await axios.post(
          `${url}/payment/switchToManualRenewal`,
          { userData },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.data.success) {
          dispatch(fetchUserInformationDetails());
          setAutoRenewalPopupOpen(false);
          setIsCheckboxChecked(false);
          setisSuccess(true);
          setTimeout(() => {
            setisSuccess(false);
          }, 5000);
        }
      } catch (error) {
      }
      finally{
        setRenualLoad(false)
      }
    }
  };
  const handleAutoRenewalButtonClick = (isCancel) => {
    setAutoRenewalPopupOpen(true);
    SetpopupContent(
      isCancel
        ? {
          popupLable: "Warning!",
          message: `Your account and all users associated with it will be automatically deactivated on the ${formattedDate}, unless you manually process the payment from your account.`,
          checkboxLable: "Yes, I wish to cancel the auto-renewal",
          buttonText: "Cancel auto-renewal",
          parameter: "cancel",
        }
        : {
          popupLable: "Enable auto-renewal",
          message: `Your account and all users associated with it will be automatically renewed on the ${formattedDate}, unless you choose to disable auto-renewal.`,
          checkboxLable: "Yes, I wish to Enable the auto-renewal",
          buttonText: "Enable auto-renewal",
          parameter: "enable",
        }
    );
  };
  const handleCancelSubscription = async () => {
    try {
      const { data } = await axios.post(`${url}/payment/cancel-Subscription`, {
        subId: userInfo?.subscription_details.subscription_schedules_id,
        currentPlan: userInfo?.subscription_details.plan,
        userid: userInfo?.user_id,
        companyid: userInfo?.company_ID,
      });
      setUserOrderstatedata(data.Orderssofcompany);
      setCancelSubScriptionModal(false)
    } catch (error) {
    }
  }
  const handleAddoneCheckBox = (e, item) => {
    // Handle checkbox change and update selected addons state
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedAddons((prevSelectedAddons) => [...prevSelectedAddons, item]);
    } else {
      setSelectedAddons((prevSelectedAddons) =>
        prevSelectedAddons.filter((addon) => addon !== item)
      );
    }
  };
  const handleContinue = () => {
    // Navigate to checkout with selected addons state
    Navigate("/checkout", {
      state: { selectedAddons },
    });
  };
  const handleAddUserContinue = () => {
    Navigate("/checkout", {
      state: { addUserCount, adduserPrice },
    });
  };
  const handleCloseOfLinkModal = () => {
    setShowLinkModal(false);
  };
  const handleNavigateCheckout = () => {
    Navigate("/checkout", { state: { id: userInfo?.subscription_details?.planID, existingAddons: addons, planName: userInfo?.subscription_details?.plan, additionUser: userInfo?.subscription_details?.total_user[0].additionalUser, cycle: userInfo?.subscription_details?.billing_cycle, oldUser: 'true' } });
  }
  return (
    <>
      {(loading || !cardDetails) ? (
        <Loader />
      ) : (
        <>
          {planName !== "Free" && daysRemaining <= 7 && user?.Account_status === 'is_Activated' ? (
            <div className="onetap-conn-subscription-payment-syn">
              <div className="onetap-conn-subscription-payment-download-syn">
                <h1>Upcoming payment</h1>
                <div className="onetap-conn-subscription-download-syn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="21"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12ZM0 16V11H2V14H14V11H16V16H0Z"
                      fill="black"
                    />
                  </svg>
                  <p>Download invoice</p>
                </div>
              </div>
              <div className="onetap-conn-subscription-payment-text-syn">
                {isAutoRenewal ? (
                  <div style={{ fontSize: "15px" }}>
                    This is a friendly reminder that your subscription will
                    automatically renew on <b>{formatted_Date}</b> for a total
                    of{" "}
                    <b>
                      ${userInfo?.subscription_details?.recurring_amount}. If
                      your payment or billing information has changed, make
                      sure to update them before {formatted_Date}.
                    </b>
                  </div>
                ) : (
                  <div style={{ fontSize: "14px" }}>
                    This is a friendly reminder that your subscription is due
                    for renewal on <b>{formatted_Date}</b> for a total of{" "}
                    <b>
                      ${userInfo?.subscription_details?.recurring_amount}. As
                      your subscription is set for manual payment renewal, you
                      must process the payment before on or {formattedDayBefore}{" "}
                      by clicking on “Renew now”.
                    </b>
                    <br />
                    Alternatively, you may enable the auto-renewal, and our system
                    will process the payment on the renewal date.
                  </div>
                )}
              </div>
              <div className="onetap-conn-subscription-renew-syn">
                <button onClick={handleNavigateCheckout}>Renew now</button>
              </div>
            </div>
          ) : (planName === "Team" || planName === 'Professional' || planName === 'Free') && user?.Account_status === 'is_Deactivated' ? (
            <div className="onetap-conn-subscription-payment-syn">
              <div className="onetap-conn-subscription-payment-download-syn">
                <h1>Subcription canceled</h1>
              </div>
              <div className="onetap-conn-subscription-payment-text-syn">
                Your subscription has been canceled. To regain access, kindly renew your plan.
              </div>
              {planName === 'Team' && user?.Account_status === 'is_Deactivated' &&
                <div className="onetap-conn-subscription-renew-syn">
                  <button onClick={handleNavigateCheckout}>Renew now</button>
                </div>}
            </div>
          ) : null}
          {user?.Account_status === 'is_Activated' &&
            <div className="onetap-conn-subscription-overview-syn">
              <div className="onetap-conn-subscription-syn">
                <div className="onetap-conn-subscription-text-id-syn">
                  <h1>Subscription overview</h1>
                  {user?.Account_status === 'is_Activated' ? (
                    <p>ID: {ordernum}</p>
                  ) : null}
                  {isSuccess && (
                    <h6 style={{ color: "#e65925" }}>Subscription Updated</h6>
                  )}
                </div>
                <div style={{ position: "relative" }} ref={dropdownRef}>
                  <div
                    className="onetap-conn-subscription-manage-syn"
                    onClick={toggleDropdown}
                  >
                    Manage Subscription{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path d="M12 15L7 10H17L12 15Z" fill="#49454F" />
                    </svg>
                  </div>
                  {isDropdownOpen && (
                    <div className="onetap-conn-subscription-manage-dropdown-syn">
                      {planName !== "Team" ? (
                        <></>
                      ) : (
                        <a
                          onClick={() => { setAddUserPopupOpen(true); setDropdownOpen(false) }}
                          className="onetap-conn-subscription-manage-dropdown-item"
                        >
                          Add users
                        </a>
                      )}
                      <a
                        onClick={() => setShowLinkModal(true)}
                        className="onetap-conn-subscription-manage-dropdown-item"
                      >
                        Change plan
                      </a>
                      {planName === "Free" ? (
                        <></>
                      ) : (
                        <a
                          href="#"
                          className="onetap-conn-subscription-manage-dropdown-item"
                          onClick={redirecttopaymentmethod}
                        >
                          Update Payment Method
                        </a>
                      )}
                      {planName === 'Free' ? (
                        <></>
                      ) : (
                        <a
                          onClick={handleOpenCancelModal}
                          href="#"
                          className="onetap-conn-subscription-manage-dropdown-item"
                        >
                          Cancel subscription
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {planName === "Free" && (
                <div className=" d-flex flex-column align-items-start w-100" style={{ height: '65px', backgroundColor: '#f2f2f2', padding: '10px' }}>
                  <div style={{ fontSize: '12px' }}>Current Plan</div>
                  <div style={{ fontSize: '18px', fontWeight: 'bolder' }}>Free</div>
                </div>
              )}
              {planName !== "Free" && user?.Account_status === 'is_Activated' && (
                <div className="onetap-conn-subscription-group-card-syn">
                  <div className="onetap-conn-subscription-card-syn">
                    <div>
                      <p>Current Plan</p>
                      <span>{userInfo?.subscription_details?.plan}</span>
                    </div>
                    <span role="button" onClick={() => setShowLinkModal(true)}>
                      Change plan
                    </span>
                  </div>
                  {planName !== "Team" ? (
                    <></>
                  ) : (
                    <div className="onetap-conn-subscription-card-syn">
                      <div>
                        <p>Users</p>
                        <span>{total_user}</span>
                      </div>
                      <span role="button" onClick={() => setAddUserPopupOpen(true)}>
                        Add user
                      </span>
                    </div>
                  )}
                  <div className="onetap-conn-subscription-card-syn">
                    <div>
                      <p>Billing Cycle</p>
                      <span>{userInfo?.subscription_details?.billing_cycle}</span>
                    </div>
                  </div>
                  <div className="onetap-conn-subscription-card-syn">
                    <div>
                      <p>Recurring Amount</p>
                      <span>
                        ${userInfo?.subscription_details?.recurring_amount}
                      </span>
                    </div>
                  </div>
                  <div className="onetap-conn-subscription-card-syn">
                    <div>
                      <p>Next Payment</p>
                      <span>{formattedDate}</span>
                    </div>
                    {isAutoRenewal ? (
                      <button onClick={() => handleAutoRenewalButtonClick(true)}>
                        Cancel auto-renewal
                      </button>
                    ) : (
                      <button onClick={() => handleAutoRenewalButtonClick(false)}>
                        Enable auto-renewal
                      </button>
                    )}
                  </div>
                  {userInfo?.subscription_details.creditBalance ? (
                    <div className="onetap-conn-subscription-card-syn">
                      <div>
                        <p>Credit Balance</p>
                        <span>
                          {Math.abs(
                            userInfo?.subscription_details?.creditBalance
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="onetap-conn-subscription-card-syn">
                    <div>
                      <p>Payment Method</p>
                      <span>
                        {cardType || cardNumber
                          ? `${cardType} **${cardNumber.toString().slice(-4)}`
                          : "undefined"}
                      </span>
                    </div>
                    <a onClick={redirecttopaymentmethod}>Update</a>
                  </div>
                </div>
              )}
              {planName !== "Free" && (
                <>
                  <div className="onetap-conn-subscription-addons-syn">
                    <span>Add-ons</span>
                    <button
                      onClick={() => {
                        setAddAddonsPopupOpen(true);
                      }}
                    >
                      Explore Add-ons
                    </button>
                  </div>
                  <div className="onetap-conn-subscription-addons-list">
                    {addons && addons.length > 0 ? (
                      <>
                        <div className="onetap-conn-subscription-addon-title">
                          <div>Name</div>
                          <div>Price</div>
                        </div>
                        {addons.map((item, i) => {
                          const matchingAddon = userInfo?.subscription_details?.addones.find(
                            (addon) => addon.addonId === item?._id
                          );
                          return (
                            <div
                              key={i}
                              className="onetap-conn-subscription-addon-item"
                            >
                              <div className="onetap-conn-subscription-addon-name">
                                {item?.publicname}
                              </div>
                              <div className="onetap-conn-subscription-addon-price">
                                ${matchingAddon?.price || 0}
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <div className="no-addons-message">
                        No addons available!
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>}
          {(planName === "Free") && (
            <div className="upgrade-btn-billSub-group">
              <h2
                className="freeAccount-header"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                Upgrade plan
              </h2>
              <div
                className="upgrade-btn-billSub"
                onClick={() => setShowLinkModal(true)}
              >
                Click Here to Upgrade your Plan
              </div>
            </div>
          )}
          {(planName === "Professional" && user.Account_status === 'is_Deactivated') && (
            <div className="upgrade-btn-billSub-group">
              <h2
                className="freeAccount-header"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                Upgrade plan
              </h2>
              <div
                className="upgrade-btn-billSub"
                onClick={() => setShowLinkModal(true)}
              >
                Click Here to Upgrade your Plan
              </div>
            </div>
          )}
          <div>
            <Modal
              open={autoRenewalPopupOpen}
              onClose={() => { setAutoRenewalPopupOpen(false); setIsCheckboxChecked(false); }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={
                  popupContent.parameter === "cancel"
                    ? style
                    : Enable_subscription_style
                }
              >
                <div
                  className={`onetap_conn_subscription_renewal_popup_header ${popupContent.parameter}`}
                >
                  <div
                    className={`onetap_conn_subscription_renewal_popup_header_name ${popupContent.parameter}`}
                  >
                    {popupContent.popupLable}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setAutoRenewalPopupOpen(false)}
                  ></div>
                </div>
                <div className="onetap_conn_subscription_renewal_popup_content">
                  {popupContent.message}
                </div>
                <div className="onetap_conn_subscription_renewal_popup_checkbox">
                  <input
                    type="checkbox"
                    className={`onetap_conn_subscription_renewal_popup_checkbox_input ${popupContent.parameter}`}
                    onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                  />
                  <div>{popupContent.checkboxLable}</div>
                  {/* <div>Yes, I wish to cancel the auto-renewal</div> */}
                </div>
                <div className="onetap_conn_subscription_renewal_popup_button">
                  <button
                    className={`onetap_conn_subscription_renewal_popup_button_cancel ${popupContent.parameter
                      } ${!isCheckboxChecked ? "disabled" : ""}`}
                    disabled={!isCheckboxChecked || renualLoad}
                    onClick={() => {
                      if (isCheckboxChecked) {
                        subcriptionHandler(popupContent.parameter);
                      }
                    }}
                  >
                    {renualLoad ? "Loading..." : popupContent.buttonText} 
                  </button>
                  <button
                    className={`onetap_conn_subscription_renewal_popup_button_back ${popupContent.parameter}`}
                    onClick={() => { setAutoRenewalPopupOpen(false); setIsCheckboxChecked(false); }}
                  >
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
          <div>
            <Modal
              open={addAddonsPopupOpen}
              onClose={() => setAddAddonsPopupOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={popupStyle}>
                <div style={headerStyle}>
                  <div className="onetap_conn_send_email_modal_header">
                    Select Add-ons
                  </div>
                </div>
                {excludingAddons
                  && excludingAddons.filter(
                    (item) =>
                      item.visibility === "Public" &&
                      item.status === "Published"
                  ).length > 0 ? <div style={optionsContainerStyle} className="options">
                  {excludingAddons &&
                    excludingAddons.filter(
                      (item) =>
                        item.visibility === "Public" &&
                        item.status === "Published"
                    )
                      .map((item, i) => {
                        let price;
                        let frequency;
                        if (item.paymentType === "onetimepayment") {
                          price = item?.Addonspaymentdata?.price;
                          frequency = "(One time)";
                        } else {
                          const isYearly = userInfo?.subscription_details?.billing_cycle === "yearly";
                          const isUserBased = item?.Addonspaymentdata?.isuserbased;
                          const userPrice = isYearly ? item?.Addonspaymentdata?.yearlyuserprice : item?.Addonspaymentdata?.monthlyuserprice;
                          const basePrice = isYearly ? item?.Addonspaymentdata?.yearlyprice : item?.Addonspaymentdata?.monthlyprice;
                          if (isUserBased && total_user >= item?.Addonspaymentdata?.numofuser) {
                            price = basePrice + ((total_user - item?.Addonspaymentdata?.numofuser) * userPrice);
                          } else {
                            price = basePrice;
                          }
                          frequency = isYearly ? "/yearly" : "/monthly";
                        }
                        return (
                          <div key={i} className="p-3">
                            <div
                              className="option"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                onChange={(e) => handleAddoneCheckBox(e, item)}
                              />
                              <p style={{ fontWeight: "700" }}>
                                {item.publicname} - ${price} {frequency}
                              </p>
                            </div>
                            <p className="message">
                              {item.shortDescription ? (
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "14px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<style> p { margin: 0; } </style> ${item.shortDescription}`,
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              <Link
                                to={`${frontendURL}/addons/${item.CustomPermalink}`}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                Learn more
                              </Link>
                            </p>
                          </div>
                        );
                      })}{" "}
                </div> : (
                  <div className="no-addons-message">
                    No addons available!
                  </div>
                )}
                <div
                  className="Modal-btn-group p-4"
                  style={buttonGroupStyle}
                >
                  {selectedAddons.length > 0 ? (
                    <button
                      className="Modal-btn first-modal-btn"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      className="Modal-btn first-modal-btn"
                      style={{ opacity: "0.6" }}
                      disabled
                    >
                      Continue
                    </button>
                  )}
                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => {
                      setAddAddonsPopupOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div></div>
              </Box>
            </Modal>
          </div>
          <Modal
            open={showLinkModal}
            onClose={handleCloseOfLinkModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="Modal-section">
                <h2 className="header" style={{ backgroundColor: "#F2F2F2" }}>
                  Change plan
                  <CloseIcon
                    className="icon"
                    onClick={handleCloseOfLinkModal}
                  />
                </h2>
                <div className={`sliding-switch ${isActive ? "active" : ""}`}>
                  <div className="switch-handle"></div>
                  <div className="switch-text">
                    <span
                      className={`switch-text-option ${isActive ? "active" : ""
                        }`}
                      onClick={handleToggle}
                    >
                      Pay Monthly
                    </span>
                    <span
                      className={`switch-text-option ${!isActive ? "active" : ""
                        }`}
                      onClick={handleToggle}
                    >
                      Yearly and save 20%
                    </span>
                  </div>
                </div>
                {isActive ? (
                  <>
                    <div className="plan-selection-section">
                      {plandata &&
                        plandata
                          .filter((plans) => plans.isActive !== false && plans.type !== "monthly")
                          .map((plans, i) => (
                            <YearlyCard props={plans} key={i} cycle={isActive} />
                          ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="plan-selection-section">
                      {plandata &&
                        plandata.map((plans, i) => {
                          if (plans.isActive === false) {
                            return;
                          }
                          if (plans.type === "yearly") {
                            return;
                          }
                          if (plans.planType === "Free") {
                            return <PlanCard props={plans} key={i} cycle={isActive} />;
                          }
                          if (plans.planType === "Professional") {
                            return <PlanCard props={plans} key={i} cycle={isActive} />;
                          }
                          if (plans.planType === "Team") {
                            return <PlanCard props={plans} key={i} cycle={isActive} />;
                          }
                          if (plans.planType === "Enterprise") {
                            return <PlanCard props={plans} key={i} cycle={isActive} />;
                          }
                        })}
                    </div>
                  </>
                )}
              </div>
            </Box>
          </Modal>
          <Modal
            open={addUserPopupOpen}
            onClose={() => { setAddUserPopupOpen(false); setAddUserCount(1) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={addUserPopupStyle}>
              <div className="Modal-section">
                <h3 className="header">
                  Managed user licenses{" "}
                  <CloseIcon className="icon" onClick={() => { setAddUserPopupOpen(false); setAddUserCount(1) }} />
                </h3>
                <div>
                  <div className="sign-up-plan-card">
                    <span className="subscription-type">
                      Current subscription: {total_user} users
                    </span>
                    <span className="price">
                      ${userInfo?.subscription_details?.recurring_amount}
                      {userInfo?.subscription_details?.billing_cycle === "monthly" && "/month"}
                      {userInfo?.subscription_details?.billing_cycle === "yearly" && "/year"}
                    </span>
                    <span className="user-count"></span>
                    <p style={{ marginBottom: "0rem", color: '#000000' }}>
                      {userInfo?.subscription_details?.billing_cycle === "monthly" ? currentPlandata?.monthlyPrice_perUser
                        : (currentPlandata?.yearlyPrice_perUser) * 12}$ per additional user
                    </p>
                    <div id="main-div">
                      <div id="left-div">
                        <span>How many users would you like to add?</span>
                      </div>
                      <div style={{ top: "0px" }} id="right-div">
                        <div
                          class="button"
                          id="minus-btn"
                          onClick={() => {
                            if (addUserCount > 1) {
                              setAddUserCount(addUserCount - 1);
                            }
                          }}
                        >
                          -
                        </div>
                        <div
                          style={{ outline: "none" }}
                          id="center-number"
                        >
                          {addUserCount}
                        </div>
                        <div
                          class="button"
                          id="plus-btn"
                          onClick={() => { setAddUserCount(addUserCount + 1) }}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Modal-btn-group">
                  <button
                    className="Modal-btn first-modal-btn"
                    onClick={handleAddUserContinue}
                  >
                    Continue
                  </button>
                  <button
                    className="Modal-btn sec-modal-btn"
                    onClick={() => { setAddUserPopupOpen(false); setAddUserCount(1) }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={cancelSubscriptionModal}
            onClose={() => { setCancelSubScriptionModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={
                warningModalStyle
              }
            >
              <div
                className={`onetap_conn_subscription_renewal_popup_header ${popupContent.parameter}`}
              >
                <div
                  className={`onetap_conn_subscription_renewal_popup_header_name ${popupContent.parameter}`}
                >
                  {"Warning!"}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setAutoRenewalPopupOpen(false)}
                ></div>
              </div>
              <div className="onetap_conn_subscription_renewal_popup_content">
                Upon cancellation, your account and associated users will be automatically deactivated, resulting in an immediate loss of access to all premium features, and no refunds will be issued for any remaining subscription period.
              </div>
              <div className="onetap_conn_subscription_renewal_popup_button">
                <button
                  style={{ backgroundColor: "#E65925" }}
                  className={`onetap_conn_subscription_renewal_popup_button_cancel`}
                  onClick={() => {
                    handleCancelSubscription()
                  }}
                >
                  {"Cancel subscription"}
                </button>
                <button
                  style={{ borderColor: "black" }}
                  className={`onetap_conn_subscription_renewal_popup_button_back ${popupContent.parameter}`}
                  onClick={() => { setCancelSubScriptionModal(false) }}
                >
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
export default CompanyBillingSubscriptionTab;
